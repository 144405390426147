import * as React from "react";
import {
  makeStyles,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { EntityManager } from "src/app/entity.service";
import { TypeBloc } from "./type.bloc";
import { TypeEntity } from "./type.entity";
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from "../../bloc/crud/crud.event";
import { CrudComponent } from "../crud.component";

export interface TypeComponentProps {
  entities: EntityManager;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

export const TypeComponent: React.FC<TypeComponentProps> = ({ entities }) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new TypeBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: TypeEntity) => (
    <Typography className={classes.heading}>
      <strong>{entity.code || "CODE"}</strong> &mdash; {entity.description}{" "}
      {entity.isDirty && <i>(niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: TypeEntity) => (
    <div className={classes.column}>
      <div className={classes.row}>
        <TextField
          variant="outlined"
          label="Code"
          value={entity.code}
          className={classes.textField}
          onChange={(event) => {
            entity.code = event.target.value;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />

        <div className={classes.textDivider}></div>

        <TextField
          className={classes.textField}
          variant="outlined"
          label="Omschrijving"
          value={entity.description}
          onChange={(event) => {
            entity.description = event.target.value;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />
      </div>

      <div className={classes.row}>
        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={entity.storeInSystem}
              onChange={(event) => {
                entity.storeInSystem = event.target.checked;

                bloc.add(new CrudModifiedEvent({ entity }));
              }}
              name="storeInSystem"
              color="primary"
            />
          }
          label="Opslaan in Metacom"
        />

        <div className={classes.textDivider}></div>

        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={entity.isStandard}
              onChange={(event) => {
                entity.isStandard = event.target.checked;

                bloc.add(new CrudModifiedEvent({ entity }));
              }}
              name="isStandard"
              color="primary"
            />
          }
          label="Standaard"
        />
      </div>
    </div>
  );

  return (
    <CrudComponent
      bloc={bloc}
      renderTitle={(entity: TypeEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: TypeEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: TypeEntity, siblings: TypeEntity[]) =>
        !entity.code ||
        !entity.description ||
        !!siblings.find((_sibling) => _sibling.code === entity.code) ||
        (entity.isStandard
          ? !!siblings.find((_sibling) => _sibling.isStandard)
          : false)
      }
    />
  );
};
