import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../auth.service";
import { RestService, RestResponse } from "../rest.service";
import { DialogService } from "../dialog.service";
import {
  FileUploadService,
  AttunedFile,
  AttunedFileResponse,
} from "../file-upload.service";
import { MdcSnackbar } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { AuditChecklistImage } from "./audit-checklist-image.entity";
import {
  AuditChecklistImageDialogComponent,
  AuditChecklistImageDialogComponentData,
} from "../audit-checklist-image-dialog/audit-checklist-image-dialog.component";
import { DocumentMeta } from "../documents/document-meta.entity";
import { orderBy } from "lodash";
import { CostCategory } from "../audit-sheet-table/cost-category.entity";
import { PageService } from "../page.service";
import {
  UploadProgressDialogComponent,
  UploadProgressDialogComponentData,
} from "../upload-progress-dialog/upload-progress-dialog.component";
import { UrlOpenService } from "../url-open.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-audit-checklist-images",
  templateUrl: "./audit-checklist-images.component.html",
  styleUrls: ["./audit-checklist-images.component.scss"],
})
export class AuditChecklistImagesComponent implements OnInit {
  response: RestResponse<AuditChecklistImage[]>;

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get costCategoryId() {
    return this.route.snapshot.paramMap.get("costCategoryId");
  }

  protected get auditChecklistImageService() {
    return this.entityManager.get(AuditChecklistImage);
  }

  protected get costCategoryService() {
    return this.entityManager.get(CostCategory);
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly dialogService: DialogService,
    protected readonly fileUploadService: FileUploadService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly snackbar: MdcSnackbar,
    protected readonly entityManager: EntityManager,
    protected readonly pageService: PageService,
    protected readonly urlOpenService: UrlOpenService
  ) {}

  async ngOnInit() {
    await this.fetchCategory();
    await this.fetch();
  }

  view(image: AuditChecklistImage, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.urlOpenService.open(
      `${environment.baseUrl}/documents/open/${image.documentMetaId}`,
      true
    );
  }

  download(image: AuditChecklistImage, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.urlOpenService.open(
      `${environment.baseUrl}/documents/download/${image.documentMetaId}`,
      true,
      image.__documentMeta__.description
    );
  }

  async upload() {
    const files = this.fileUploadService
      .convert(await this.promptForImages())
      .map((file) => new AttunedFile(file, this.projectId));

    const uploads: AttunedFileResponse[] = await this.dialogService.open(
      this.changeDetector,
      UploadProgressDialogComponent,
      {
        data: new UploadProgressDialogComponentData(files),
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    const concepts = uploads
      .filter((e) => !!e.response)
      .map((e) => this.createConcept(e.response));

    const images = (
      await Promise.all(
        concepts.map((c) => this.auditChecklistImageService.save(c))
      )
    )
      .filter((e) => !e.hasError())
      .map((e) => e.value)
      .map((e) => this.setImageUrl(e));

    this.response.value.unshift(...images);

    this.snackbar.open("Opgeslagen", "Ok");
  }

  async open(image: AuditChecklistImage) {
    const result = await this.dialogService.open(
      this.changeDetector,
      AuditChecklistImageDialogComponent,
      {
        data: new AuditChecklistImageDialogComponentData(
          image,
          this.response.value
        ),
      }
    );

    if (result !== "close") {
      await this.fetch();
    }
  }

  protected async promptForImages() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.setAttribute("accept", "image/*");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  protected async fetchCategory() {
    const response = await this.costCategoryService.findOne(
      this.costCategoryId
    );

    if (!response.hasError()) {
      this.pageService.addSegment(response.value.description);
    }
  }

  protected async fetch() {
    const response = await this.auditChecklistImageService.query({
      filters: [
        { field: "projectId", operator: "Equal", value: this.projectId },
        {
          field: "costCategoryId",
          operator: "Equal",
          value: this.costCategoryId,
        },
      ],
      orders: [{ field: "createdAt", direction: "ASC" }],
      relations: ["user", "documentMeta"],
    });

    if (!response.hasError()) {
      response.value.forEach((i) => this.setImageUrl(i));
      this.response = new RestResponse(
        orderBy(
          response.value,
          [(a) => a.createdAt, (a) => a.__documentMeta__.description],
          ["desc", "desc"]
        )
      );
    }
  }

  protected setImageUrl(image: AuditChecklistImage) {
    image.thumbUrl = `documents/resize/${image.documentMetaId}/256`;
    image.imageUrl = `documents/resize/${image.documentMetaId}/1048`;
    return image;
  }

  protected createConcept(documentMeta: DocumentMeta) {
    return this.auditChecklistImageService.concept({
      userId: this.authService.user.id,
      __user__: this.authService.user,
      costCategoryId: this.costCategoryId,
      projectId: this.projectId,
      documentMetaId: documentMeta.id,
      __documentMeta__: documentMeta,
    });
  }
}
