import { AppProjectContact } from "./../app-document-type-dialog/app-project-contact.entity";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { FileUploadService } from "../file-upload.service";
import { AuthService } from "../auth.service";
import { UrlOpenService } from "../url-open.service";
import { DialogService } from "../dialog.service";
import { RestResponse } from "../rest.service";
import { compact, first } from "lodash";

@Component({
  selector: "app-supplier-contact-dialog",
  templateUrl: "./supplier-contact-dialog.component.html",
  styleUrls: ["./supplier-contact-dialog.component.scss"],
})
export class SupplierContactDialogComponent implements OnInit {
  selectedSupplierContact: string;
  selectedSupplierContacts: string[] = [];

  responseSupplierContacts: RestResponse<AppProjectContact[]>;

  protected get appProjectContactService() {
    return this.entityManager.get(AppProjectContact);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: SupplierContactDialogProps,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly fileUploadService: FileUploadService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialogRef: MdcDialogRef<SupplierContactDialogComponent>
  ) {}

  isSelected(contact: string) {
    return this.selectedSupplierContacts.includes(contact);
  }

  remove(contact: string) {
    const index = this.selectedSupplierContact.indexOf(contact);

    index >= 0 && this.selectedSupplierContacts.splice(index, 1);
  }

  add() {
    !this.isSelected(this.selectedSupplierContact) &&
      this.selectedSupplierContacts.push(this.selectedSupplierContact);
  }

  async ngOnInit() {
    const responseSupplierContacts = await this.appProjectContactService.query({
      filters: [
        { field: "project_id", operator: "Equal", value: this.data.projectId },
      ],
    });

    if (!responseSupplierContacts.hasError()) {
      this.selectedSupplierContact = first(responseSupplierContacts.value).type;
      this.responseSupplierContacts = responseSupplierContacts;
    }
  }

  proceed() {
    const userIds = compact(
      this.selectedSupplierContacts.map((contactType) => {
        const selectedContact = this.responseSupplierContacts.value.find(
          (contact) => contact.type === contactType
        );

        return selectedContact && selectedContact.relation_id;
      })
    );

    this.dialogRef.close({
      contactTypes: this.selectedSupplierContacts,
      userIds,
    });
  }
}

export interface SupplierContactDialogProps {
  readonly projectId: string;
}

export interface SupplierContactDialogComponentResult {
  readonly contactTypes: string[];
  readonly userIds: string[];
}
