import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Component, Inject, OnInit } from "@angular/core";
import * as _ from "lodash";
import { first } from "lodash";
import * as moment from "moment";
import { CellaService } from "../cella.service";
import { MetacomService } from "../metacom.service";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { grants } from "./../app-grant-config";
import { GrantService } from "./../grant.service";
import { QualityAssuranceService } from "./../quality-assurance.service";
import { UrlOpenService } from "./../url-open.service";

@Component({
  selector: "app-work-order-dialog",
  templateUrl: "./work-order-dialog.component.html",
  styleUrls: ["./work-order-dialog.component.scss"],
})
export class WorkOrderDialogComponent implements OnInit {
  readonly customId = "900999";

  deliveryDate: string;
  completedAllChecked = false;
  response: RestResponse<WorkOrderLine[]>;

  isQaFilled = true;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: WorkOrderDialogComponentData,
    protected readonly cellaService: CellaService,
    protected readonly metacomService: MetacomService,
    protected readonly transactionService: TransactionService,
    protected readonly dialogRef: MdcDialogRef<WorkOrderDialogComponent>,
    protected readonly grantService: GrantService,
    protected readonly qaService: QualityAssuranceService,
    protected readonly urlOpenService: UrlOpenService
  ) {}

  get companyCodes() {
    return (
      this.grantService.var(grants.work_orders.required_company_codes, "") ?? ""
    )
      ?.split(",")
      .filter((segment) => !!segment.length);
  }

  async ngOnInit() {
    this.deliveryDate = moment(new Date()).format("YYYY-MM-DD");

    if (!!this.companyCodes.length) {
      this.isQaFilled = await this.qaService.companyCodesFilled(
        this.data.projectId,
        this.companyCodes
      );
    }

    await this.fetch();
  }

  openQa() {
    this.urlOpenService.openNew({
      url: `#/service/quality-assurance/${this.data.projectId}`,
      forceNewTab: true,
    });
  }

  isCustom(line: WorkOrderLine) {
    return line.middel === this.customId;
  }

  async save() {
    const format = "YYYY-MM-DD";

    this.response.value
      .filter((e) => this.isCustom(e))
      .forEach((e) => {
        e.isCompleted = true;
        e.quantityUpdate = e.middel_hv_open;
      });

    const lines = this.response.value.filter(
      (l) => l.quantityUpdate || l.isCompleted
    );

    await this.transactionService.perform(() =>
      this.cellaService.writeTransactionDocument({
        Company: "920",
        DocumentType: first(lines).doel_docsoort,
        DocumentDate: moment().format(format),
        DocumentDescription: "", // todo: assign
        DocumentLines: lines.map((l) => ({
          FromCompany: 920,
          ToCompany: l.naar_bedrijf,
          ToAdministrationType: l.naar_admtype,
          StartDate: moment(this.deliveryDate).format(format),
          StartTime: "000000",
          EndDate: moment(this.deliveryDate).format(format),
          EndTime: "000000",
          SourceCompany: 920,
          SourceDocumentType: l.bron_docsoort,
          SourceDocumentNumber: l.opdracht,
          SourceDocumentLine: l.bron_docregel,
          ToItem: l.post,
          ToCostCentre: l.project,
          ToCostUnit: l.kostensoort,
          FromAdministrationType: l.van_admtype,
          FromCostCentre: l.relatie,
          FromCostUnit: l.middel,
          FromQuantity: (+l.quantityUpdate).toFixed(3),
          FromRemainingQuantityCorrection:
            this.getCorrectOpenValue(l).toFixed(3),
          FromUnit: l.middel_eenheid,
          FromUnitPrice: l.middel_ehprijs,
          Description: l.middel_oms,
        })),
      })
    );

    return this.dialogRef.close("save");
  }

  completeAll() {
    for (const line of this.response.value) {
      line.isCompleted = this.completedAllChecked;
      this.complete(line);
    }
  }

  complete(row: WorkOrderLine) {
    row.quantityUpdate = row.isCompleted ? row.middel_hv_open * 1.0 : null;
  }

  copy(row: WorkOrderLine) {
    const copy = Object.assign({ isNew: true }, row);
    copy.middel_hv_open = null;
    copy.middel = this.customId;

    const index = this.response.value.indexOf(row);
    this.response.value.splice(index + 1, 0, copy);
  }

  remove(row: WorkOrderLine) {
    const index = this.response.value.indexOf(row);
    this.response.value.splice(index, 1);
  }

  lineTotal(row: WorkOrderLine) {
    return row.middel_hv_open * parseFloat(row.middel_ehprijs);
  }

  get totalAll() {
    return _.sumBy(
      this.response.value
        .filter((l) => l.isCompleted)
        .map((l) => this.lineTotal(l))
    );
  }

  get canSave() {
    return (
      this.response &&
      !!this.response.value.find((l) => l.quantityUpdate || l.isCompleted) &&
      !!this.deliveryDate &&
      this.isQaFilled
    );
  }

  protected getCorrectOpenValue(row: WorkOrderLine) {
    const open = row.middel_hv_open;

    return row.quantityUpdate > open || row.isCompleted
      ? open - row.quantityUpdate
      : 0;
  }

  protected async fetch() {
    const response = await this.metacomService.queryTableAsync<WorkOrderLine>({
      setName: "metacom",
      tableName: "werkopdrachten_regels",
      filter: `reg_mutatie.docnr = '${this.data.orderId}'`,
    });

    if (!response.hasError()) {
      response.value.forEach(
        (r) => (r.middel_ehprijs = parseFloat(r.middel_ehprijs).toFixed(2))
      );
      this.response = response;
    }
  }
}

export class WorkOrderDialogComponentData {
  projectId: string;
  orderId: string;
  orderName: string;
}

export interface WorkOrderLine {
  middel_hv_open: number;
  middel_ehprijs: string;

  quantityUpdate?: number;

  isNew?: boolean;
  isCompleted?: boolean;

  middel: string;
  relatie: string;
  project: string;
  opdracht: string;
  post: string;

  van_admtype: string;
  naar_bedrijf: number;
  naar_admtype: string;
  bron_docregel: string;
  kostensoort: string;
  kostensoort_oms: string;
  bron_docsoort: string;
  middel_eenheid: string;
  middel_oms: string;

  doel_docsoort: string;
}
