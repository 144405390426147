import { HourDeclaration } from './hour-declaration.entity';
import { first, last } from 'lodash';
import { HourDeclarationTemplateColumn } from './hour-declaration.template';

export class HourDeclarationHelper {
    constructor(protected readonly hourDeclaration: HourDeclaration) { }

    isCorrectlyFilled() {
        return false;
    }

    get isSaved() {
        return !!this.hourDeclaration.id;
    }

    isOk(columns: HourDeclarationTemplateColumn[]) {
        const baseColumnsOk = columns
            .filter(e => e.required)
            .every(e => !!this.hourDeclaration[e.id]);

        const activityOk = this.showActivity ? !!this.hourDeclaration.hourDeclarationActivityId : true;

        return baseColumnsOk && activityOk;
    }

    get showActivity() {
        return (
            this.hourDeclaration.__hourDeclarationType__ &&
            this.hourDeclaration.__hourDeclarationType__.hasActivities === 'ja'
        ) && (
                this.hourDeclaration.__priceAgreement__ ?
                    this.hourDeclaration.__priceAgreement__.kostensoort.startsWith('1') : true
            );
    }

    get totalPrice() {
        return this.isPriced ? (this.baseQuantity * this.pricePerUnit) : 0;
    }

    get isPricePerHour() {
        return this.isPriced && this.hourDeclaration.__priceAgreement__.prijs_eenheid === 'uur';
    }

    getTime(field: string) {
        return this.convertRawTime(this.hourDeclaration[field]);
    }

    protected convertRawTime(input: string) {
        if (input) {
            const split = input.split(':').map(e => parseFloat(e)),
                hours = first(split), minutes = last(split);

            return hours + (minutes / 60);
        }

        return 0;
    }

    protected get isPriced() {
        return !!this.hourDeclaration.__priceAgreement__;
    }

    protected get pricePerUnit() {
        return this.isPriced ? parseFloat(this.hourDeclaration.__priceAgreement__.prijs_inkoop) : 0;
    }

    protected get baseQuantity() {
        return this.isPricePerHour ? this.convertRawTime(this.hourDeclaration.amount) : parseFloat(this.hourDeclaration.amount);
    }
}
