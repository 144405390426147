import * as React from "react";

import { TableLayoutComponent } from "../table-layout.component";
import { TableLayoutProvider } from "./context/table-layout.context";
import { MainSplitter } from "./main.splitter";
import { MainBackdrop } from "./main.backdrop";

interface TableLayoutProps {
  component: TableLayoutComponent;
}

export const Main: React.FC<TableLayoutProps> = ({ component }) => {
  return (
    <TableLayoutProvider component={component}>
      <MainSplitter />
      <MainBackdrop />
    </TableLayoutProvider>
  );
};
