export class Job {
  source: string;
  cron: string;
  cronFinal: string;
  target: String;
  cronText: string;
  cronLocked: boolean;
  others?: JobOther[];
}

export interface JobOther {
  target: string;
  targetConnection?: string;
}
