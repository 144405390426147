import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class PrintService {
  isPrinting = false;

  constructor(protected readonly router: Router) {}

  print(name: string, data: any) {
    this.isPrinting = true;

    return this.router.navigate(
      ["/", { outlets: { print: ["print", name] } }],
      { state: data }
    );
  }

  onDataReady() {
    setTimeout(() => {
      window.print();

      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    }, 0);
  }
}
