import { Entity } from '../entity.service';
import { GrantsInfo } from '../app-grant-config';

export class Role extends Entity {
    type = 'roles';

    name: string;
    description: string;

    get grants() {
        return this.many(Role, Grant, 'grants');
    }

    __grants__?: Grant[];
    __grantConfigs__?: RoleGrantConfig[];

    __documentGrants__?: RoleDocumentGrant[];
}

export class Grant extends Entity {
    type = 'grants';

    roleId: string;
    permissionId: string;

    configs?: UserGrantConfig[] | RoleGrantConfig[];
}

export class UserGrantConfig extends Entity {
    type = 'user_grant_configs';

    configId: string;
    userId: string;
    value: string;
}

export class RoleGrantConfig extends Entity {
    type = 'role_grant_configs';

    configId: string;
    roleId: string;
    value: string;

    info?: GrantsInfo;
}

export class RoleDocumentGrant extends Entity {
    type = 'role_document_grants';

    roleId: string;
    match: string;

    isDeleted?: boolean;
}
