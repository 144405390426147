import { EntityManager } from "src/app/entity.service";
import { CrudBloc } from "../../bloc/crud/crud.bloc";
import { CrudEvent } from "../../bloc/crud/crud.event";
import { CrudInitialState } from "../../bloc/crud/crud.state";
import { StageEntity } from "./stage.entity";

export class StageBloc extends CrudBloc<StageEntity> {
  constructor(protected readonly entities: EntityManager) {
    super(new CrudInitialState());
  }

  get repo() {
    return this.entities.get(StageEntity);
  }

  async *mapEventToState(event: CrudEvent) {
    yield* super.mapEventToState(event);
  }
}
