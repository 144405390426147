import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { HousePartGroup } from "../cargo/house-part.entity";

export class Picklist extends Entity {
  type = "picklists";

  note: string;

  get elements() {
    return this.many(Picklist, PicklistElement, "elements");
  }
}

export class PicklistElement extends Entity {
  type = "picklist_elements";

  isPicked: boolean;

  elementId: string;
  elementType: string;

  pickState: string;
  pickedByUserId: any;
  pickedAt: Date;

  orderId: string;

  __housePartGroup__?: HousePartGroup;

  get picklist() {
    return this.one(Picklist, User, "picklist");
  }
}

export class PicklistComment extends Entity {
  type = "picklist_comments";

  userId: any;
  projectId: any;
  picklistDefinitionId: any;
  text: string;

  get user() {
    return this.one(PicklistComment, User, "user");
  }

  get picklistDefinition() {
    return this.one(PicklistComment, PicklistDefinition, "picklistDefinition");
  }
}

export class PicklistState extends Entity {
  type = "picklist_states";

  projectId: string;
  picklistDefinitionId: string;

  completedByUserId: string;

  get completedByUser() {
    return this.one(PicklistComment, User, "completedByUser");
  }

  get picklistDefinition() {
    return this.one(PicklistComment, PicklistDefinition, "picklistDefinition");
  }
}

export class PicklistDefinition extends Entity {
  type = "picklist_definitions";

  name: string;
  icon: string;
  competenceNumber: string;
  indeterminateToggles: boolean;
  metacomListTable: string;
  dateSource: string;

  hideColumns?: string[];
  elementTypes?: string[];
}
