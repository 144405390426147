import { DateGroup } from "../year-planning/entities/date-entities";
import { DateGroupItemExtended } from "./date-group-item-extended";
import { LogicFieldExtended } from "./logic-field-extended";

/**
 * Class used to extend the base @see {DateGroup} with extra properties required for this component.
 *
 * @class DateGroupExtended
 */
export class DateGroupExtended {
  /**
   * Indicates if the data of the @see {value} has been modified.
   *
   * @type {boolean}
   * @memberof DateGroupExtended
   */
  public isDirty: boolean;
  /**
   * Indicates if the group has expanded it's children to be visible.
   *
   * @type {boolean}
   * @memberof DateGroupExtended
   */
  public isExpanded: boolean;

  /**
   * Indicates if the group has been marked for deletion.
   *
   * @type {boolean}
   * @memberof DateGroupExtended
   */
  public isPendingDeletion: boolean;

  /**
   * Creates an instance of DateGroupExtended.
   * @param {DateGroup} value
   * @param {DateGroupItemExtended[]} dates
   * @memberof DateGroupExtended
   */
  constructor(
    public readonly value: DateGroup,
    public readonly dates: DateGroupItemExtended[],
    public readonly logicFields: LogicFieldExtended[]
  ) {
    this.isDirty = !value.id;
  }

  /**
   * Flips the state of the @see {isExpanded} property.
   *
   * @memberof DateGroupExtended
   */
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Flips the state of the @see {isPendingDeletion} property.
   *
   * @memberof DateGroupExtended
   */
  toggleDeletion() {
    this.isPendingDeletion = !this.isPendingDeletion;
  }

  /**
   * Marks the item as dirty.
   *
   * @memberof DateGroupItemExtended
   */
  markDirty() {
    this.isDirty = true;
  }

  set orderId(value: number) {
    this.value.orderId = value;
    this.isDirty = true;
  }
}
