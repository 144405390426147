import { User } from "src/app/accessibility-users/user.entity";

export class DeliveryListEvent {}

export class DeliveryListLoadRequestEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class DeliveryListAssignedUserUpdateEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      assignedUser: User;
    }
  ) {
    super();
  }
}

export class DeliveryListKeyAmountUpdateEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      keyAmount: number;
    }
  ) {
    super();
  }
}

export class DeliveryListHiredAgentUpdateEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      hiredAgent: string;
    }
  ) {
    super();
  }
}

export class DeliveryListIsDeliveredAtUpdateEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      isDeliveredAt: Date;
    }
  ) {
    super();
  }
}

export class DeliveryListHouseUpdateEvent extends DeliveryListEvent {
  constructor(
    readonly props: {
      file: File;
    }
  ) {
    super();
  }
}
