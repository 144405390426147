import * as React from 'react';
import { makeStyles, Typography, TextField } from '@material-ui/core';

import { EntityManager } from 'src/app/entity.service';
import { SpaceBloc } from './space.bloc';
import { SpaceEntity } from './space.entity';
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from '../../bloc/crud/crud.event';
import { CrudComponent } from '../crud.component';

export interface SpaceComponentProps {
  entities: EntityManager;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  addButton: {
    marginTop: 24,
  },
}));

export const SpaceComponent: React.FC<SpaceComponentProps> = ({ entities }) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new SpaceBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: SpaceEntity) => (
    <Typography className={classes.heading}>
      <strong>{entity.code || 'CODE'}</strong> &mdash; {entity.description}{' '}
      {entity.isDirty && <i>(niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: SpaceEntity) => (
    <React.Fragment>
      <TextField
        variant='outlined'
        label='Code'
        value={entity.code}
        className={classes.textField}
        onChange={(event) => {
          entity.code = event.target.value;

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />

      <div className={classes.textDivider}></div>

      <TextField
        className={classes.textField}
        variant='outlined'
        label='Omschrijving'
        value={entity.description}
        onChange={(event) => {
          entity.description = event.target.value;

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />
    </React.Fragment>
  );

  return (
    <CrudComponent
      bloc={bloc}
      renderTitle={(entity: SpaceEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: SpaceEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: SpaceEntity, siblings: SpaceEntity[]) =>
        !entity.code ||
        !entity.description ||
        !!siblings.find((sibling) => sibling.code === entity.code)
      }
    />
  );
};
