import gql from "graphql-tag";

export const pointUpdateMutation = gql`
  mutation pointUpdateMutation($input: InputDeliveryListPointEntity!) {
    point: DeliveryListPointEntityUpdateOne(input: $input) {
      id
      projectId

      code
      description

      serviceTicketId

      serviceTypeId
      serviceType {
        description
      }

      serviceSpaceId
      serviceSpaceIds
      servicePriorityId

      assignedUserId

      primaryCategoryId
      secondaryCategoryId

      images {
        id
        documentMetaId
      }
    }
  }
`;

export const pointDeleteMutation = gql`
  mutation pointDeleteMutation($input: InputDeleteDeliveryListPointEntity!) {
    isPointDeleted: DeliveryListPointEntityDeleteOne(input: $input)
  }
`;

export const pointImageCreateMutation = gql`
  mutation pointImageCreateMutation(
    $input: InputDeliveryListPointImageEntity!
  ) {
    image: DeliveryListPointImageEntityUpdateOne(input: $input) {
      id
      documentMetaId
    }
  }
`;

export const pointImageDeleteMutation = gql`
  mutation pointImageDeleteMutation(
    $input: InputDeleteDeliveryListPointImageEntity!
  ) {
    isPointDeleted: DeliveryListPointImageEntityDeleteOne(input: $input)
  }
`;
