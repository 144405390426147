import gql from 'graphql-tag';

export const serviceWizardCategoryQuery = gql`
  query serviceWizardCategoryQuery(
    $primaryCategoryId: String!
    $secondaryCategoryId: String!
  ) {
    primary: AppServiceCategoryEntityFindOne(
      query: {
        filters: [{ field: "id", operator: "Equal", value: $primaryCategoryId }]
      }
    ) {
      id
      name
    }
    secondary: AppServiceCategoryEntityFindOne(
      query: {
        filters: [
          { field: "id", operator: "Equal", value: $secondaryCategoryId }
        ]
      }
    ) {
      id
      name
    }
  }
`;
