import { Component, OnInit } from "@angular/core";
import { MetacomService } from "../metacom.service";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as moment from "moment";

@Component({
  selector: "app-metacom-project",
  templateUrl: "./metacom-project.component.html",
  styleUrls: ["./metacom-project.component.scss"],
})
export class MetacomProjectComponent implements OnInit {
  public filterQuery = "";
  public response: RestResponse<any[]>;

  protected filterQueryChanged = new Subject<string>();

  constructor(protected readonly metacomService: MetacomService) {}

  ngOnInit() {
    this.response = new RestResponse<any[]>([]);
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.fetchItems());
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  print() {
    window.print();
  }

  protected async fetchItems() {
    if (this.filterQuery.length) {
      const response = await this.metacomService.queryTableAsync<any>({
        setName: "metacom",
        tableName: "projecten_archief",
        filter: `prj_prj.oms contains "${this.filterQuery}" OR prj_prj.prj begins "S${this.filterQuery}"`,
      });

      if (!response.hasError()) {
        response.value.forEach(
          (r) =>
            (r.opleverdatum = r.opleverdatum
              ? moment(r.opleverdatum).format("DD-MM-YYYY")
              : "")
        );
        this.response = response;
      }
    }
  }
}
