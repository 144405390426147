import { Component, OnInit } from "@angular/core";
import { UrlOpenService } from "../url-open.service";
import { environment } from "../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { TransactionService } from "../transaction.service";
import { AppService } from "../app.service";

@Component({
  selector: "app-app-sso",
  templateUrl: "./app-sso.component.html",
  styleUrls: ["./app-sso.component.scss"],
})
export class AppSsoComponent implements OnInit {
  protected get projectId() {
    return this.activatedRoute.snapshot.paramMap.get("projectId") || "none";
  }

  get avatarUrl() {
    return this.app.isAuthenticated
      ? `${environment.appApiBaseUrl}/api/users/avatar/${this.app.userId}`
      : "/assets/imgs/logo-app-color.png";
  }

  token: string;

  constructor(
    readonly app: AppService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly transactions: TransactionService
  ) {
    this.app.tokenSubject.subscribe((_token) => (this.token = _token));
  }

  async ngOnInit() {
    await this.app.refreshToken(true);
  }

  open() {
    this.urlOpenService.openUrl({
      url: `${environment.appBaseUrl}/#/sso/${this.token}/${this.projectId}`,
      isNewTab: true,
    });

    window.close();
  }
}
