import { Component } from "@angular/core";
import { ReactComponent } from "../react-component/react.component";
import { WorkActionTemplates } from "./work-action-templates";
import { EntityManager } from "../entity.service";
import { RestService } from "../rest.service";

@Component({
  selector: "app-year-planning-columns",
  templateUrl: "./work-action-templates.component.html",
  styleUrls: ["./work-action-templates.component.scss"],
})
export class WorkActionTemplatesComponent extends ReactComponent {
  component = WorkActionTemplates;

  constructor(
    readonly entities: EntityManager,
    readonly restService: RestService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
