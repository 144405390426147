import { NgModule } from "@angular/core";

import {
  MdcTypographyModule,
  MdcCardModule,
  MdcButtonModule,
  MdcIconButtonModule,
  MdcIconModule,
  MdcListModule,
  MdcRippleModule,
  MdcTextFieldModule,
  MdcFormFieldModule,
  MdcElevationModule,
  MdcSnackbarModule,
  MdcTopAppBarModule,
  MdcDrawerModule,
  MdcMenuSurfaceModule,
  MdcFabModule,
  MdcTabBarModule,
  MdcDialogModule,
  MdcLinearProgressModule,
  MdcSelectModule,
  MdcCheckboxModule,
  MdcSliderModule,
  MdcRadioModule,
  MdcChipsModule,
  MdcMenuModule,
} from "@angular-mdc/web";

@NgModule({
  imports: [],
  exports: [
    MdcTypographyModule,
    MdcCardModule,
    MdcButtonModule,
    MdcIconButtonModule,
    MdcIconModule,
    MdcListModule,
    MdcRippleModule,
    MdcTextFieldModule,
    MdcFormFieldModule,
    MdcElevationModule,
    MdcSnackbarModule,
    MdcTopAppBarModule,
    MdcDrawerModule,
    MdcMenuSurfaceModule,
    MdcFabModule,
    MdcTabBarModule,
    MdcDialogModule,
    MdcLinearProgressModule,
    MdcSelectModule,
    MdcCheckboxModule,
    MdcSliderModule,
    MdcRadioModule,
    MdcMenuModule,
    MdcChipsModule,
  ],
})
export class AppMdcModule {}
