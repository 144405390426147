import { DrawerAbsent } from "./../drawers/drawer.entity";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { RestResponse } from "../rest.service";
import {
  HourDeclaration,
  AdministrationProject,
  PriceAgreement,
  HourDeclarationType,
  HourDeclarationApprovalRequest,
} from "./hour-declaration.entity";
import { EntityManager } from "../entity.service";
import { AuthService } from "../auth.service";
import { EntitySelectConfig } from "../entity-select/entity-select.component";
import { MetacomService } from "../metacom.service";
import {
  HourDeclarationTemplateColumn,
  HourDeclarationTemplate,
} from "./hour-declaration.template";
import { IMyDateModel } from "ngx-mydatepicker";
import { HourDeclarationHelper } from "./hour-declaration.helper";
import { ActivatedRoute, Router } from "@angular/router";
import { MdcSnackbar } from "@angular-mdc/web";
import { User } from "../accessibility-users/user.entity";
import { GrantHelper } from "../grant.helper";
import { grants } from "../app-grant-config";
import { formatNumber } from "@angular/common";
import { HourDeclarationDocument } from "./hour-declaration.document";
import { CellaService } from "../cella.service";
import { HourDeclarationParams } from "./hour-declaration.params";
import { TransactionService } from "../transaction.service";
import { DialogService } from "../dialog.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import {
  EntitySearchDialogComponent,
  EntitySearchDialogConfig,
} from "../entity-search-dialog/entity-search-dialog.component";
import { chain, first, uniqBy } from "lodash";
import { GrantService } from "../grant.service";
import * as _ from "lodash";
import * as moment from "moment";
import { Drawer, DrawOrder } from "../drawers/drawer.entity";

@Component({
  selector: "app-hour-declarations",
  templateUrl: "./hour-declarations.component.html",
  styleUrls: ["./hour-declarations.component.scss"],
})
export class HourDeclarationsComponent implements OnInit {
  readonly PERIOD_BACK = 14;
  readonly PERIOD_FRONT = 2;
  readonly WEEK_FORMAT = "GGGG-WW";
  readonly DATE_FORMAT = "DD-MM-YYYY";

  readonly templates = {
    external: new HourDeclarationTemplate(
      [
        new HourDeclarationTemplateColumn("date", "Datum", true),
        new HourDeclarationTemplateColumn(
          "hourDeclarationTypeId",
          "Soort",
          true
        ),
        new HourDeclarationTemplateColumn("projectId", "Project", true),
        new HourDeclarationTemplateColumn("priceAgreementId", "Middel", true),
        new HourDeclarationTemplateColumn("description", "Omschrijving", true),
        new HourDeclarationTemplateColumn(
          "hourDeclarationActivityId",
          "Activiteit",
          false
        ),
        new HourDeclarationTemplateColumn("amount", "Aantal", true),
        new HourDeclarationTemplateColumn("unit", "Eenh.", false),
        new HourDeclarationTemplateColumn("price", "Prijs", false),
        new HourDeclarationTemplateColumn("sum", "Bedrag", false),
      ],
      "920"
    ),
    office: new HourDeclarationTemplate(
      [
        new HourDeclarationTemplateColumn("date", "Datum", true),
        new HourDeclarationTemplateColumn(
          "hourDeclarationTypeId",
          "Soort",
          true
        ),
        new HourDeclarationTemplateColumn(
          "projectId",
          "Project / Materieel",
          true
        ),
        new HourDeclarationTemplateColumn(
          "hourDeclarationActivityId",
          "Afdeling",
          false
        ),
        new HourDeclarationTemplateColumn("baseHours", "Uren", true),
        new HourDeclarationTemplateColumn("savingHours", "Spaaruren", false),
        new HourDeclarationTemplateColumn("overTimeHours", "Overuren", false),
      ],
      "910"
    ),
    office_outside: new HourDeclarationTemplate(
      [
        new HourDeclarationTemplateColumn("date", "Datum", true),
        new HourDeclarationTemplateColumn(
          "hourDeclarationTypeId",
          "Soort",
          true
        ),
        new HourDeclarationTemplateColumn(
          "projectId",
          "Project / Materieel",
          true
        ),
        new HourDeclarationTemplateColumn("description", "Omschrijving", false),
        new HourDeclarationTemplateColumn(
          "hourDeclarationActivityId",
          "Activiteit",
          false
        ),
        new HourDeclarationTemplateColumn("baseHours", "Uren", true),
        new HourDeclarationTemplateColumn("overTimeHours", "Overuren", false),
        new HourDeclarationTemplateColumn("travelHours", "Reisuren", false),
      ],
      "930"
    ),
  };

  weeks: string[];
  weekModel: string;
  approveComment: string;
  approveRequested: boolean;
  invalidConfig: boolean;

  response: RestResponse<HourDeclaration[]>;
  responseUser: RestResponse<User>;
  responseDrawer: RestResponse<Drawer>;
  responsePriceAgreements: RestResponse<PriceAgreement[]>;
  responseAdministrationProjects: RestResponse<AdministrationProject[]>;
  responseHourDeclarationTypes: RestResponse<HourDeclarationType[]>;
  responseDefaultCostId: string;

  latestApprovalRequest: string;

  get hourDeclarationTypeConfig() {
    const typeFilters = this.typeFilters;
    const hasTypeFilters = typeFilters.length > 0;

    const fixedResponse = (
      this.responseHourDeclarationTypes
        ? this.responseHourDeclarationTypes.value.filter((e) =>
            hasTypeFilters
              ? !!typeFilters.find(
                  (tf) => e.kind === `${tf.companyId}-${tf.administrationType}`
                  // tf.companyId === e.companyId &&
                  // tf.administrationType === e.administrationType
                )
              : e.companyId === this.companyId
          )
        : []
    ).filter(
      (e) =>
        hasTypeFilters ||
        (this.templateId === "external" ? e.isExternal === "ja" : true)
    );

    const nullIfEmpty = (value: string) => (value === "" ? null : value);

    const uniqKinds = uniqBy(fixedResponse, (item) => item.kind);

    return {
      allowNothing: false,
      title: "",
      icon: "",
      entityName: "hour_declaration_types",
      nameField: "",
      descriptionField: "kindDescription",
      descriptionFormat: (entity) =>
        nullIfEmpty(entity.kindDescription) || entity.description,
      sortField: "kindDescription",
      sortDirection: "ASC",
      filterFields: ["kindDescription", "description"],
      fixedResponse: uniqKinds,
    };
  }

  priceAgreementConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "",
    icon: "",
    entityName: "",
    nameField: "middel",
    descriptionField: "middel_oms",
    sortField: "middel_oms",
    sortDirection: "ASC",
    filterFields: ["middel_oms"],
    fixedResponse: [],
  };

  get dateConfig() {
    return {
      dateRange: false,
      dateFormat: "dd-mm-yyyy",
      firstDayOfWeek: "su",
      selectorHeight: "auto",
      enableDates: Array.from(this.selectedWeekDays()),
      disableUntil: { year: 3000, month: 1, day: 1 },
      disableSince: { year: 1, month: 1, day: 1 },
    };
  }

  get defaultMonth() {
    return { defMonth: this.selectedWeek().format("MM/YYYY") };
  }

  get allowCopyHours() {
    return this.grantService.varIs(
      grants.hour_declarations.allow_copy_hours,
      "true"
    );
  }

  get copyHoursIds() {
    return this.grantService
      .var(grants.hour_declarations.copy_hours_ids, "")
      .split(",");
  }

  get manageOthers() {
    return this.grantService.varIs(
      grants.hour_declarations.manage_others,
      "true"
    );
  }

  get mode() {
    return this.route.snapshot.data.mode || "declare";
  }

  get isWeekDisabled() {
    return !(
      this.mode === "declare" ||
      (this.mode === "manage" && this.manageOthers)
    );
  }

  get isAllUnique() {
    if (this.response) {
      const uniques = uniqBy(this.activeItems, (item) => this.uniqueKey(item));

      return uniques.length === this.activeItems.length;
    }
  }

  protected get drawerService() {
    return this.entityManager.get(Drawer);
  }

  protected get drawOrderService() {
    return this.entityManager.get(DrawOrder);
  }

  protected get drawerAbsentService() {
    return this.entityManager.get(DrawerAbsent);
  }

  protected get userService() {
    return this.entityManager.get(User);
  }

  protected get hourDeclarationService() {
    return this.entityManager.get(HourDeclaration);
  }

  protected get hourDeclarationApprovalRequestService() {
    return this.entityManager.get(HourDeclarationApprovalRequest);
  }

  protected get hourDeclarationTypeService() {
    return this.entityManager.get(HourDeclarationType);
  }

  protected get typeFilters() {
    const text = this.responseUser
      ? new GrantHelper(this.responseUser.value).var(
          grants.hour_declarations.type_overrides,
          null
        )
      : null;
    return text ? TypeFilter.parse(text) : [];
  }

  protected get companyId() {
    return (
      (this.responseUser ? this.responseUser.value.companyId : null) ||
      (this.template ? this.template.companyId : null)
    );
  }

  protected get templateId() {
    return this.responseUser
      ? new GrantHelper(this.responseUser.value).var(
          grants.hour_declarations.template_id,
          null
        )
      : null;
  }

  protected get template(): HourDeclarationTemplate {
    return this.templateId ? this.templates[this.templateId] : null;
  }

  protected get userId() {
    return (
      this.route.snapshot.paramMap.get("userId") || this.authService.user.id
    );
  }

  protected get paramWeek() {
    return (
      this.route.snapshot.paramMap.get("week") ||
      this.currentWeek().format(this.WEEK_FORMAT)
    );
  }

  protected get hasParamUserId() {
    return this.route.snapshot.paramMap.has("userId");
  }

  constructor(
    protected readonly router: Router,
    protected readonly snackbar: MdcSnackbar,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly cellaService: CellaService,
    protected readonly dialogService: DialogService,
    protected readonly entityManager: EntityManager,
    protected readonly grantService: GrantService,
    protected readonly metacomService: MetacomService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactionService: TransactionService
  ) {}

  ngOnInit() {
    this.weeks = Array.from(this.getWeeks());
    this.weekModel = this.paramWeek;
    this.route.params.subscribe(() => this.fetchAll());
  }

  onWeekModelChanged() {
    this.fetchHours();

    if (this.templateId === "external") {
      this.latestApprovalRequest = null;
      this.fetchLatestApprovalRequest();
    }
  }

  openHrm() {
    window.open("https://werknemer.loket.nl", "_blank");
  }

  async resetWeek() {
    if (this.manageOthers) {
      const accepted =
        (await this.dialogService.open(
          this.changeDetector,
          ConfirmDialogComponent,
          {
            data: {
              title: "Weet u het zeker?",
              description: `De uren van ${this.responseUser.value.name} week ${this.weekModel} worden teruggezet, en worden hierdoor opnieuw indienbaar.`,
            },
            escapeToClose: false,
          }
        )) === "accept";

      if (accepted) {
        this.response.value.forEach((e) => (e.storedDocumentId = null));

        const results = await Promise.all(
          this.response.value.map((l) => this.hourDeclarationService.save(l))
        );

        const isOK = results.every((r) => !r.hasError());

        if (isOK) {
          this.snackbar.open(`Uren teruggezet`, "Ok", { leading: true });
        }
      }
    }
  }

  async goToUser() {
    if (this.manageOthers) {
      const data = new EntitySearchDialogConfig("users");
      data.nameField = "id";
      data.descriptionField = "name";
      data.filterFields = ["name"];
      data.filters = [{ field: "roleId", operator: "IsNull", isNot: true }];

      const user: User = await this.dialogService.open(
        this.changeDetector,
        EntitySearchDialogComponent,
        { data }
      );

      if (user && user.id) {
        await this.router.navigate([
          "office",
          "hour-declarations",
          user.id,
          "manage",
        ]);
      }
    }
  }

  async copyAll() {
    const data = new EntitySearchDialogConfig("users");
    data.nameField = "id";
    data.descriptionField = "name";
    data.filterFields = ["name"];
    data.filters = [{ field: "id", operator: "In", value: this.copyHoursIds }];

    const user: User = await this.dialogService.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (user && user.id) {
      const lines = chain(this.response.value)
        .map((e) =>
          this.hourDeclarationService.copy(e, ["id", "storedDocumentId"])
        )
        .forEach((e) => (e.userId = user.id))
        .value();

      const results = await Promise.all(
        lines.map((l) => this.hourDeclarationService.save(l))
      );

      const isOK = results.every((r) => !r.hasError());

      if (isOK) {
        this.snackbar.open(`Gekopierd naar: ${user.name}`, "Ok", {
          leading: true,
        });
      }
    }
  }

  async insert() {
    if (this.templateId !== "external") {
      const accepted =
        (await this.dialogService.open(
          this.changeDetector,
          ConfirmDialogComponent,
          {
            data: {
              title: "Weet u het zeker?",
              description:
                "Bij het definitief maken worden de uren doorgezet naar Metacom en is wijzigen niet meer mogelijk.",
            },
            escapeToClose: false,
          }
        )) === "accept";

      if (!accepted) {
        return;
      }
    }

    const candidates = Array.from(this.localDeclarations);
    const params = new HourDeclarationParams(
      this.responseUser.value,
      this.weekModel,
      this.companyId,
      this.templateId,
      this.responseDefaultCostId
    );
    const documentHelper = new HourDeclarationDocument(params, candidates);

    const response = await this.transactionService.perform(
      () =>
        this.cellaService.writeTransactionDocument(
          params.isExternal
            ? documentHelper.createCommitDocument()
            : documentHelper.createInsertDocument()
        ),
      "Definitief gemaakt"
    );

    if (!response.hasError()) {
      const documentId = response.value.CreatedDocumentNumber;
      candidates.forEach((e) => (e.storedDocumentId = documentId));
      await Promise.all(
        candidates.map((c) => this.hourDeclarationService.save(c))
      );

      return documentId;
    }
  }

  async approve() {
    const documentId = await this.insert();
    const params = new HourDeclarationParams(
      this.responseUser.value,
      this.weekModel,
      this.companyId,
      this.templateId,
      this.responseDefaultCostId
    );

    if (documentId) {
      await this.transactionService.perform(
        () =>
          this.cellaService.sendEmail({
            from: {
              address: "administratie@groothuisbouw.nl",
              name: "Groothuisbouw",
            },
            cc: "administratie@groothuisbouw.nl",
            to: this.responseUser.value.email,
            subject: "Uren declaratie goedgekeurd",
            text: "Om deze email te lezen, gebruik een HTML viewer",
            html: `
            De uren declaratie met nummer ${
              params.documentCommitNumber
            } is goedgekeurd door: ${
              this.authService.user.email || this.authService.user.name
            }<br>
            De factuur kan worden verzonden.${
              this.approveComment
                ? `<br><br>Opmerkingen:<br>${this.approveComment}`
                : ""
            }
          `,
          }),
        "Melding verstuurd"
      );

      await this.leave();
    }
  }

  async decline() {
    const params = new HourDeclarationParams(
      this.responseUser.value,
      this.weekModel,
      this.companyId,
      this.templateId,
      this.responseDefaultCostId
    );

    await this.transactionService.perform(
      () =>
        this.cellaService.sendEmail({
          from: {
            address: "administratie@groothuisbouw.nl",
            name: "Groothuisbouw",
          },
          cc: "administratie@groothuisbouw.nl",
          to: this.responseUser.value.email,
          subject: "Uren declaratie afgekeurd",
          text: "Om deze email te lezen, gebruik een HTML viewer",
          html: `
          De uren declaratie met nummer ${
            params.documentCommitNumber
          } is afgekeurd door: ${
            this.authService.user.email || this.authService.user.name
          }<br>
          ${
            this.approveComment
              ? `<br><br>Opmerkingen:<br>${this.approveComment}`
              : ""
          }
        `,
        }),
      "Melding verstuurd"
    );

    await this.leave();
  }

  async requestApprove() {
    const params = new HourDeclarationParams(
      this.responseUser.value,
      this.weekModel,
      this.companyId,
      this.templateId,
      this.responseDefaultCostId
    );
    const receiveEmail = this.responseUser.value.emailDeclaration;

    if (!!receiveEmail) {
      await this.transactionService.perform(
        () =>
          this.cellaService.sendEmail({
            from: {
              address: "administratie@groothuisbouw.nl",
              name: "Groothuisbouw",
            },
            cc: "administratie@groothuisbouw.nl",
            to: this.responseUser.value.emailDeclaration,
            subject: "Declaratie uren extern controle",
            text: "Om deze email te lezen, gebruik een HTML viewer",
            html: `Verzoek bijgevoegde declaratie te controleren: <a href="${this.url}">${params.documentCommitNumber}</a>`,
          }),
        "Verzoek verstuurd"
      );

      await this.hourDeclarationApprovalRequestService.save(<
        HourDeclarationApprovalRequest
      >{
        userId: this.userId,
        period: this.weekModel,
      });

      this.approveRequested = true;
      this.fetchLatestApprovalRequest();

      this.snackbar.open("Goedkeuring ingediend", "Ok", { leading: true });
    } else {
      this.snackbar.open("Controle emailadres ontbreekt.", "OK", {
        classes: ["error-snackbar"],
        leading: true,
      });
    }
  }

  leave() {
    return this.router.navigate(["dashboard"]);
  }

  projectFilterConfig(declaration: HourDeclaration) {
    const kind = declaration.__hourDeclarationType__
      ? declaration.__hourDeclarationType__.kind
      : undefined;

    const projectsToChoose = (this.responseAdministrationProjects.value || [])
      .filter((e) => e.stadiumNr >= 1000 && e.stadiumNr <= 1910)
      .filter((e) => (kind ? e.uren_type === kind : true));

    return {
      allowNothing: false,
      title: "",
      icon: "",
      entityName: "",
      nameField: "id",
      descriptionField: "omschrijving",
      sortField: "id",
      sortDirection: "ASC",
      filterFields: ["id", "omschrijving"],
      fixedResponse: projectsToChoose,
    };
  }

  hourDeclarationActivityConfig(declaration: HourDeclaration) {
    const companyId = declaration.__hourDeclarationType__
      ? declaration.__hourDeclarationType__.companyId
      : "";

    return {
      allowNothing: false,
      title: "",
      icon: "",
      entityName: "hour_declaration_activities",
      nameField: "administrationType",
      descriptionField: "description",
      sortField: "administrationType",
      sortDirection: "ASC",
      filterFields: ["description", "administrationType"],
      filters: [{ field: "companyId", operator: "Equal", value: companyId }],
    };
  }

  get canSave() {
    const items = this.response ? this.localDeclarations : [];
    return (
      items.length > 0 &&
      items.every((i) =>
        new HourDeclarationHelper(i).isOk(this.template.columns)
      ) &&
      this.isAllUnique
    );
  }

  get isFinal() {
    return (
      this.response &&
      this.response.value.length > 0 &&
      this.response.value.every((r) => !!r.storedDocumentId)
    );
  }

  get isSaved() {
    return (
      this.response &&
      this.response.value.length > 0 &&
      this.response.value.every((r) => new HourDeclarationHelper(r).isSaved)
    );
  }

  get totalAll() {
    return _.sumBy(
      this.response.value.filter((item) => this.showItem(item)),
      (e) => this.help(e).totalPrice
    );
  }

  tabIndex(declaration: HourDeclaration) {
    return declaration.storedDocumentId ? "-1" : "0";
  }

  totalOf(field: string) {
    const hoursAsNumber = _.sumBy(this.response.value, (e) =>
      this.help(e).getTime(field)
    );
    const minutesAsNumber = hoursAsNumber % 1;

    const hours = hoursAsNumber - minutesAsNumber,
      hoursFm = formatNumber(hours, "nl", "2.0-0");
    const minutes = minutesAsNumber * 60,
      minutesFm = formatNumber(minutes, "nl", "2.0-0");
    return `${hoursFm}:${minutesFm}`;
  }

  onTypeChanged(declaration: HourDeclaration) {
    declaration.projectId = null;
    declaration.hourDeclarationActivityId = null;
    declaration.__hourDeclarationActivity__ = null;
  }

  onPriceAgreementChanged(declaration: HourDeclaration) {
    declaration.hourDeclarationActivityId = null;
    declaration.__hourDeclarationActivity__ = null;

    declaration.amount = null;
    declaration.description = declaration.__priceAgreement__.middel_oms;
  }

  onDateChanged(event: IMyDateModel, declaration: HourDeclaration) {
    const date = event
      ? moment(event.formatted, "DD-MM-YYYY", true).toDate()
      : null;

    declaration.date = date;
  }

  async importHoursFromDrawers() {
    const startsAt = this.selectedWeek().startOf("week");
    const endsAt = this.selectedWeek().endOf("week");

    const drawerIdFilter = {
      field: "drawerId",
      operator: "Equal",
      value: this.responseDrawer?.value.id,
    };

    const dateRangeFilterValue = [
      startsAt.clone().add(-1, "week").toDate(),
      endsAt.clone().add(1, "week").toDate(),
    ];

    const orders = await this.drawOrderService.query({
      filters: [
        drawerIdFilter,
        {
          field: "plannedAt",
          operator: "Between",
          valueComplex: dateRangeFilterValue,
        },
      ],
    });

    const absents = await this.drawerAbsentService.query({
      filters: [
        drawerIdFilter,
        {
          field: "date",
          operator: "Between",
          valueComplex: dateRangeFilterValue,
        },
      ],
    });

    const createEndsAt = (original: Date, totalHours: number) =>
      moment(original)
        .add(totalHours, "hours")
        .add(moment(original).isBefore(startsAt) ? 2 : 0, "days")
        .toDate();

    if (this.response && !orders.hasError() && !absents.hasError()) {
      const eventsWithEndDate = orders.value
        .map<DrawerEvent>(
          (order) =>
            ({
              kind: "order",
              projectId: order.projectId,
              startsAt: order.plannedAt,
              endsAt: createEndsAt(
                order.plannedAt,
                (order.percentageCompleted >= 100
                  ? order.totalHoursSpend
                  : order.totalHours) * 3
              ),
            } as DrawerEvent)
        )
        .concat(
          absents.value.map<DrawerEvent>((absent) => ({
            kind: "absent",
            projectId: "absent",
            startsAt: absent.date,
            endsAt: createEndsAt(absent.date, absent.hours * 3),
          }))
        );

      const type = first(this.hourDeclarationTypeConfig.fixedResponse);
      const totals = this.countDrawerHoursPerDay(eventsWithEndDate);

      this.response.value.push(
        ...totals.map(({ date, projectId, totalHours }) =>
          this.hourDeclarationService.concept({
            baseHours: this.hoursToFormatted(totalHours * 60),
            projectId: projectId,
            hourDeclarationTypeId: type ? type.id : null,
            __hourDeclarationType__: type,
            userId: this.authService.user.id,
            datePicker: {
              jsDate: moment(date).toDate(),
              formatted: moment(date).format("DD-MM-YYYY"),
            },
            date: moment(date).toDate(),
          })
        )
      );
    }
  }

  countDrawerHoursPerDay(events: DrawerEvent[]) {
    const startDate = this.selectedWeek()
      .add(1, "day")
      .isoWeekday(1)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();

    let totals = {};

    for (let i = 0; i < 48 * 5; i++) {
      const start = moment(startDate)
        .add(i * 30, "minutes")
        .toDate();
      const startKey = moment(start).format("DD-MM-YYYY");
      const end = moment(start).add(30, "minutes").toDate();

      const eventsInPeriod = events.filter(
        (event) =>
          moment(event.startsAt).isSameOrBefore(start) &&
          moment(event.endsAt).isSameOrAfter(end)
      );

      if (
        eventsInPeriod.length === 0 ||
        !!eventsInPeriod.find((event) => event.kind === "absent")
      )
        continue;

      const orders = eventsInPeriod.filter((event) => event.kind === "order");

      for (const event of orders) {
        const current = totals[startKey] ?? {};
        const currentProject = current[event.projectId] ?? 0;
        current[event.projectId] = currentProject + 0.5;

        totals[startKey] = current;
      }
    }

    const results = [];

    for (const date in totals) {
      for (const projectId in totals[date]) {
        const totalHours = totals[date][projectId] / 3.0;

        results.push({
          projectId,
          date: moment(date, "DD-MM-YYYY").toDate().toDateString(),
          totalHours,
        });
      }
    }
    return results;
  }

  hoursToFormatted(timeMins: number, baseTime = "00:00") {
    return moment(baseTime, "H:m").add(timeMins, "minutes").format("HH:mm");
  }

  add() {
    if (this.response) {
      const type = first(this.hourDeclarationTypeConfig.fixedResponse);

      this.response.value.push(
        this.hourDeclarationService.concept({
          amount: "",
          hourDeclarationTypeId: type ? type.id : null,
          __hourDeclarationType__: type,
          userId: this.authService.user.id,
          datePicker: { isRange: false, singleDate: { jsDate: new Date() } },
        })
      );
    }
  }

  showItem(declaration: HourDeclaration) {
    return (
      this.mode === "manage" ||
      !(
        declaration.isDeleted ||
        (this.mode === "judge" && declaration.storedDocumentId)
      )
    );
  }

  remove(declaration: HourDeclaration) {
    declaration.isDeleted = true;

    if (declaration.isConcept) {
      const index = this.response.value.indexOf(declaration);
      this.response.value.splice(index, 1);
    }
  }

  copy(declaration: HourDeclaration) {
    const concept = this.hourDeclarationService.concept(declaration);
    concept.id = null;
    this.response.value.push(concept);
  }

  async save() {
    const toSave = this.response.value.filter((e) => !e.isDeleted);
    const toDelete = this.response.value
      .filter((e) => !!e.id)
      .filter((e) => e.isDeleted);

    await Promise.all(toSave.map((e) => this.hourDeclarationService.save(e)));
    await Promise.all(
      toDelete.map((e) => this.hourDeclarationService.delete(e.id))
    );

    this.snackbar.open("Opgeslagen", "Ok", { leading: true });

    await this.fetchHours();
  }

  colspan(columnId: string) {
    const column = this.template.columns.find((e) => e.id === columnId);
    return this.template.columns.indexOf(column);
  }

  show(columnId: string) {
    return (
      this.template && !!this.template.columns.find((e) => e.id === columnId)
    );
  }

  title(columnId: string) {
    return this.show(columnId)
      ? this.template.columns.find((e) => e.id === columnId).title
      : "";
  }

  help(declaration: HourDeclaration) {
    return new HourDeclarationHelper(declaration);
  }

  protected *getWeeks() {
    const top = this.currentWeek().add(this.PERIOD_FRONT, "w");

    for (let i = 0; i < this.PERIOD_BACK + this.PERIOD_FRONT; i++) {
      yield top.clone().add(-i, "w").format(this.WEEK_FORMAT);
    }
  }

  protected *selectedWeekDays() {
    const base = this.selectedWeek();

    for (let i = 0; i < 6; i++) {
      const date = base.clone().add(i, "d");

      yield { year: date.year(), month: date.month() + 1, day: date.date() };
    }
  }

  protected selectedWeek() {
    return moment(this.weekModel, this.WEEK_FORMAT).startOf("week");
  }

  protected currentWeek() {
    return moment(new Date()).startOf("week").add(1, "d");
  }

  protected async fetchHours() {
    this.response = null;

    const response = await this.hourDeclarationService.query({
      filters: [
        { field: "userId", operator: "Equal", value: this.userId },
        {
          field: "date",
          operator: "Between",
          value: [
            this.selectedWeek().startOf("week").toDate(),
            this.selectedWeek().endOf("week").toDate(),
          ],
        },
      ],
      relations: ["hourDeclarationType", "hourDeclarationActivity"],
      orders: [{ field: "date", direction: "ASC" }],
    });

    if (!response.hasError()) {
      _.chain(response.value)
        .forEach((r) => (r.datePicker = <any>this.makeDate(r)))
        .forEach(
          (r) =>
            (r.__priceAgreement__ = this.responsePriceAgreements.value.find(
              (e) => e.id === r.priceAgreementId
            ))
        )
        .orderBy((item) => moment(item.date).unix(), "asc")
        .value();

      this.response = response;
    }
  }

  protected async fetchPriceAgreements() {
    const personId = (this.userId as string).substr(1);
    const responsePersonalCosts =
      await this.metacomService.queryTableAsync<PriceAgreement>({
        setName: "metacom",
        tableName: "prijsafspraken",
        filter: `mtc_middel.middel = "${personId}"`,
      });

    if (!responsePersonalCosts.hasError()) {
      const firstOccurrence = first(responsePersonalCosts.value);

      if (firstOccurrence) {
        this.responseDefaultCostId = firstOccurrence.kostensoort;
      }
    }

    const response = await this.metacomService.queryTableAsync<PriceAgreement>({
      setName: "metacom",
      tableName: "prijsafspraken",
      filter: `mtc_middel.rel = "${this.userId}"`,
    });

    if (!response.hasError()) {
      response.value.forEach((e) => (e.id = e.middel));

      this.priceAgreementConfig.fixedResponse = response.value;
      this.responsePriceAgreements = response;
    }
  }

  protected async fetchProjects() {
    const response =
      await this.metacomService.queryTableAsync<AdministrationProject>({
        setName: "metacom",
        tableName: "uren_projecten",
      });

    if (!response.hasError()) {
      response.value.forEach((e) => (e.id = e.project));
      response.value.forEach((e) => (e.stadiumNr = parseFloat(e.stadium)));

      this.responseAdministrationProjects = response;
    }
  }

  protected async fetchUser() {
    if (this.hasParamUserId) {
      this.responseUser = await this.userService.queryFirst({
        filters: [{ field: "id", operator: "Equal", value: this.userId }],
        relations: ["role", "role.grantConfigs", "grantConfigs"],
      });
    } else {
      this.responseUser = new RestResponse(this.authService.user);
    }
  }

  protected async fetchHourDeclarationTypes() {
    this.responseHourDeclarationTypes =
      await this.hourDeclarationTypeService.query({});
  }

  protected async fetchLatestApprovalRequest() {
    const response =
      await this.hourDeclarationApprovalRequestService.queryFirst({
        filters: [
          {
            field: "userId",
            operator: "Equal",
            value: this.userId,
          },
          {
            field: "period",
            operator: "Equal",
            value: this.weekModel,
          },
        ],
        orders: [{ field: "createdAt", direction: "DESC" }],
      });

    if (!response || !response.value) {
      this.latestApprovalRequest = "Geen goedkeuring ingediend";
    } else {
      this.latestApprovalRequest = `Goedkeuring ingediend op <strong>${moment(
        response.value["createdAt"]
      ).format("DD-MM-YYYY hh:mm")}</strong>`;
    }
  }

  protected async fetchDrawer() {
    this.responseDrawer = await this.drawerService.queryFirst({
      filters: [
        {
          field: "userId",
          operator: "Equal",
          value: this.authService.user.id,
        },
      ],
    });
  }

  protected async fetchAll() {
    this.responseUser = null;
    this.responseDrawer = null;
    this.responseAdministrationProjects = null;
    this.priceAgreementConfig.fixedResponse = [];
    this.responsePriceAgreements = null;
    this.responseHourDeclarationTypes = null;
    this.response = null;

    await this.fetchUser();

    if (this.template) {
      await Promise.all([
        this.fetchProjects(),
        this.fetchDrawer(),
        this.fetchPriceAgreements(),
        this.fetchHourDeclarationTypes(),
        this.fetchLatestApprovalRequest(),
      ]);
      await this.fetchHours();
    }

    this.invalidConfig = !this.template || !this.typeFilters.length;
  }

  protected makeDate(row: HourDeclaration) {
    const parsed = row.date ? moment(row.date) : null;

    return {
      formatted: parsed ? parsed.format("DD-MM-YYYY") : null,
      jsDate: parsed ? parsed.toDate() : new Date(),
    };
  }

  protected get localDeclarations() {
    return this.response.value.filter((e) => !e.storedDocumentId);
  }

  protected get url() {
    return `${window.location.origin}/#/office/hour-declarations/${this.userId}/${this.weekModel}`;
  }

  isUnique(declaration: HourDeclaration) {
    const uniq = this.uniqueKey(declaration);

    return (
      this.activeItems
        .map((item) => this.uniqueKey(item))
        .filter((key) => key === uniq).length === 1
    );
  }

  protected uniqueKey(declaration: HourDeclaration) {
    return Array.from(this.uniqueKeyMaker(declaration))
      .map((segment) => segment || "#")
      .join("_");
  }

  protected *uniqueKeyMaker(declaration: HourDeclaration) {
    yield declaration.date ? moment(declaration.date).unix() : null;
    yield declaration.hourDeclarationTypeId;
    yield declaration.projectId;
    yield declaration.priceAgreementId;
    yield declaration.hourDeclarationActivityId;
  }

  protected get activeItems() {
    return this.response
      ? this.response.value.filter((item) => !item.isDeleted)
      : [];
  }
}

class TypeFilter {
  static readonly FILTER_DELIMITER = ",";
  static readonly FILTER_PART_DELIMITER = "-";

  constructor(
    readonly companyId: string,
    readonly administrationType: string
  ) {}

  static parse(text: string) {
    return text
      .split(TypeFilter.FILTER_DELIMITER)
      .map((e) => e.split(TypeFilter.FILTER_PART_DELIMITER))
      .map((e) => new TypeFilter(_.first(e), _.last(e)));
  }
}

type DrawerEvent = {
  projectId: string;
  startsAt: Date;
  endsAt: Date;

  kind?: "order" | "absent";
};
