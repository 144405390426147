import { OrderableEntity, EntityManager } from "../entity.service";
import { AppProjectFieldGroup } from "./app-project-field-group.entity";
import { RestResponse } from "../rest.service";
import { orderBy } from "lodash";

export class AppProjectFieldSheet extends OrderableEntity {
  type = "app.project_field_sheets";

  name: string;
  project_contact_type?: string;

  groups: AppProjectFieldGroup[];

  set __groups__(value: AppProjectFieldGroup[]) {
    const items = this.entityManager
      .get(AppProjectFieldGroup)
      .healManyResponse(new RestResponse(value)).value;

    this.groups = orderBy(items, (i) => i.order);
  }

  created_at?: Date;
  updated_at?: Date;
}
