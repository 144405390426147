import { Entity } from "../entity.service";

export class YearPlanningLine extends Entity {
  type = "year_planning_lines";

  id: string;
  description: string;
  weeks: number;
  colorCode: string;
  visibleOnConstructionPlanning: boolean;

  isDeleted?: boolean;
}
