import { Equatable } from '@comceptum-software/react-bloc';
import { CustomerQuestionDto } from './customer-question.dto';
import { CustomerQuestionsFilter } from './customer-questions.state';

export class CustomerQuestionsEvent extends Equatable {
  props = () => [];
}

export class CustomerQuestionsLoadRequestEvent extends CustomerQuestionsEvent {
  readonly filter: CustomerQuestionsFilter;

  constructor(props: { readonly filter: CustomerQuestionsFilter }) {
    super(props);
  }

  props = () => [this.filter];
}

export class CustomerQuestionsReadyRequestEvent extends CustomerQuestionsEvent {
  readonly question: CustomerQuestionDto;
  readonly internalComment: string;

  constructor(props: { readonly question?: CustomerQuestionDto, readonly internalComment?: string }) {
    super(props);
  }

  props = () => [this.question, this.internalComment];
}

export class CustomerQuestionsUpdateRequestEvent extends CustomerQuestionsEvent {
  readonly question: CustomerQuestionDto;
  readonly internalComment: string;

  constructor(props: { readonly question?: CustomerQuestionDto, readonly internalComment?: string }) {
    super(props);
  }

  props = () => [this.question, this.internalComment];
}

export class CustomerQuestionsReopenRequestEvent extends CustomerQuestionsEvent {
  readonly question: CustomerQuestionDto;

  constructor(props: { readonly question?: CustomerQuestionDto }) {
    super(props);
  }

  props = () => [this.question];
}

export class CustomerQuestionsTicketRequestEvent extends CustomerQuestionsEvent {
  readonly question: CustomerQuestionDto;

  constructor(props: { readonly question?: CustomerQuestionDto }) {
    super(props);
  }

  props = () => [this.question];
}
