import * as moment from "moment";
import * as React from "react";
import { ConstructionPlanningProjectDateGroupCommentDto } from "../construction-planning-date.dto";

export interface ConstructionPlanningCommentsTooltipProps {
  comments: ConstructionPlanningProjectDateGroupCommentDto[];
}

export function ConstructionPlanningCommentsTooltip({
  comments,
}: ConstructionPlanningCommentsTooltipProps) {
  return (
    <>
      {comments.length == 0 && <span>Geen opmerkingen gevonden.</span>}
      {comments.map((_comment) => (
        <>
          <div
            style={{
              minWidth: 128,
              display: "flex",
              flexDirection: "column",
              paddingBottom: 6,
              paddingTop: 6,
            }}
            key={_comment.id}
          >
            <strong>{_comment.__user__.name}</strong>
            <span style={{ fontWeight: "normal", padding: "4px 0" }}>
              {_comment.value}
            </span>
            <span style={{ fontWeight: "normal", fontSize: 11 }}>
              {moment(_comment.createdAt).format("DD-MM-YYYY")}
            </span>
          </div>
        </>
      ))}
    </>
  );
}
