import { MeterEntity } from "src/app/service-settings/react/meters/meter.entity";

export class MeterState {}

export class MeterLoadInProgressState extends MeterState {}

export class MeterLoadFailureState extends MeterState {
  constructor(
    readonly props: {
      readonly message: string;
    }
  ) {
    super();
  }
}

export class MeterLoadSuccessState extends MeterState {
  constructor(
    readonly props: {
      readonly meters: MeterEntity[];
    }
  ) {
    super();
  }
}
