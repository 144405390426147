import { Injector } from "@angular/core";
import { DateHelper, Mask } from "@bryntum/schedulerpro/schedulerpro.umd.js";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Chip,
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { AuthService } from "src/app/auth.service";
import {
  EntitySelect,
  EntitySelectSource,
} from "src/app/entity-select/entity-select";
import { MetacomService } from "src/app/metacom.service";
import { RestService } from "src/app/rest.service";
import { useOpenServiceTicketAdvancementDialogWithInjector } from "src/app/service/service.functions";
import { MechanicDto } from "../dtos/mechanic.dto";
import {
  ServiceEventDto,
  ServiceEventDtoWithModel,
} from "../dtos/service-event.dto";
import {
  generateEventStyle,
  isPhantomId,
  searchServiceTickets,
} from "../service-planning.functions";
import { SchedulerComponentDefaults } from "./SchedulerComponent";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useMemo } from "react";
import { assign, chain, first, pick } from "lodash";
import { servicePlanningFetchExecutivesQuery } from "../service-planning.query";
import { Apollo } from "apollo-angular";
import * as moment from "moment";
import { grants } from "src/app/app-grant-config";
import { GrantService } from "src/app/grant.service";
import { TaskDateTimeField } from "./TaskDateTimeField";

export interface SchedulerTaskEditComponentProps {
  injector: Injector;
  authService: AuthService;
  restService: RestService;
  metacomService: MetacomService;
  mechanics: MechanicDto[];
  task: ServiceEventDtoWithModel;
  onSaved: (task: ServiceEventDtoWithModel) => void;
  onDeleted: (task: ServiceEventDtoWithModel, silent?: boolean) => void;
  onCancelled: () => void;
  defaults?: SchedulerComponentDefaults;
}

export function SchedulerTaskEditComponent({
  task,
  injector,
  authService,
  restService,
  metacomService,
  mechanics,
  onSaved,
  onDeleted,
  onCancelled,
  defaults,
}: SchedulerTaskEditComponentProps) {
  const apollo = useMemo(() => injector.get(Apollo), [injector]);
  const grantService = useMemo(() => injector.get(GrantService), []);
  const readOnly = useMemo(
    () => !grantService.varIs(grants.service_planning.allow_modify, "true"),
    [grantService]
  );

  const { openServiceTicketAdvancementDialogWithInjector } =
    useOpenServiceTicketAdvancementDialogWithInjector(injector);

  const saveAllowed = () => {
    return (
      task.durationPlanned &&
      task.duration &&
      task.legacyProjectId &&
      task.stageId
    );
  };

  const backup = useMemo(
    () => ({
      startDate: task.startDate,
      endDate: task.endDate,
      assignments: task.assignments,
    }),
    []
  );

  const [, setUpdateId] = useState(() => Math.random());
  const ticketSource = useServiceTicketSource(metacomService, task);
  const [tickets, setTickets] = useState<{ [id: string]: any }>({});

  const ignoreTaskSetFields = ["startDate", "endDate"];

  const set = async (field: string, value: any, silent?: boolean) => {
    if (!ignoreTaskSetFields.includes(field)) {
      task.set(field, value);
      task[field] = value;
    }

    switch (field) {
      case "legacyProjectId":
        task.legacyProjectId && fetchTickets();
        break;
      case "durationPlanned":
        !task.isFinalized && set("duration", value);
        break;
      case "durationSpend":
        task.isFinalized && set("duration", value);
        break;
      case "isFinalized":
        set(
          "duration",
          task.isFinalized ? task.durationSpend : task.durationPlanned
        );

        set("style", generateEventStyle(task));
        break;
      case "stage":
        set("style", generateEventStyle(task));
        break;
      case "startDate":
        await task.setStartDate(value, true);
        break;
      case "endDate":
        await task.setEndDate(value, true);
        break;
    }

    !silent && refresh();
  };

  const refresh = () => setUpdateId(Math.random());

  const cancel = async (silent = false) => {
    if (isPhantomId(task.id)) {
      onDeleted(task, true);
    } else {
      await set("startDate", backup.startDate);
      await set("endDate", backup.endDate);

      task.cancelBatch();

      !silent && onCancelled();
    }
  };

  const isAssignedTo = (mechanic: MechanicDto) => {
    return task.editMechanics && task.editMechanics.includes(mechanic.id);
  };

  const toggleMechanic = (mechanic: MechanicDto) => {
    const newMechs = isAssignedTo(mechanic)
      ? task.editMechanics.filter((resourceId) => mechanic.id !== resourceId)
      : task.editMechanics.concat(mechanic.id);

    set("editMechanics", newMechs);
  };

  const openServiceTicket = async (ticketId: string) => {
    const mask = Mask.mask("Servicemelding laden...", document.body);

    /** todo: make this faster and find 1 ticket */
    const response = await searchServiceTickets(
      metacomService,
      task.legacyProjectId
    );

    if (!response.hasError()) {
      const ticket = response.value.find(
        (candidateTicket) => candidateTicket.servicepunt === ticketId
      );

      ticket.projectData = task.legacyProjectData;

      await mask.close();

      await openServiceTicketAdvancementDialogWithInjector(ticket);
    } else {
      await mask.close();
    }
  };

  const fetchTickets = async () => {
    const tickets = await ticketSource.onSearch(null, null);
    const executivesIds = chain(tickets)
      .map((ticket) => ticket["uitvoerende"])
      .uniq()
      .value();

    const response = await apollo
      .query<{
        executives: { id: string; name: string }[];
      }>({
        query: servicePlanningFetchExecutivesQuery,
        variables: {
          executivesIds,
        },
      })
      .toPromise();

    if (response.data) {
      tickets.forEach(
        (ticket) =>
          (ticket["uitvoerende_naam"] = response.data.executives.find(
            (executive) => executive.id === ticket["uitvoerende"]
          ).name)
      );
    }

    const mapping = tickets.reduce((previous, current) => {
      previous[current["servicepunt"]] = current;
      return previous;
    }, {});

    setTickets(mapping);
  };

  const desiredUnit = "hour";

  const store = async () => {
    const assignmentsToRemove = task.assignments.filter(
      (assignment) => !task.editMechanics.includes(assignment.resourceId)
    );

    const assignmentsToAdd = task.editMechanics.filter(
      (resourceId) => !task.isAssignedTo(resourceId)
    );

    task.assignmentIdsToRemove = assignmentsToRemove.map(
      (assignment) => assignment.id
    );

    assignmentsToRemove
      .map((assignment) => assignment.resourceId)
      .forEach((resourceId) => task.unassign(resourceId));

    task.assign(assignmentsToAdd);
    task.endBatch();

    onSaved(task);
  };

  useEffect(() => {
    task.beginBatch();

    set("userId", task.userId || authService.user.id, true);
    set("user", task.user || authService.user, true);

    set("serviceTicketIds", task.serviceTicketIds || [], true);
    set("assignmentIdsToRemove", task.assignmentIdsToRemove || [], true);
    set(
      "editMechanics",
      task.assignments.map((assignment) => assignment.resourceId),
      true
    );

    if (task.durationUnit != desiredUnit) {
      const newDuration = DateHelper.as(
        desiredUnit,
        task.duration as number,
        task.durationUnit
      );

      set("duration", newDuration, true);
      set("durationUnit", desiredUnit, true);
    }

    set("durationPlanned", task.durationPlanned || task.duration);

    if (defaults && isPhantomId(task.id)) {
      set("legacyProjectId", task.legacyProjectId || defaults.legacyProjectId);
      set(
        "serviceTicketIds",
        task.serviceTicketIds.concat([defaults.serviceTicketId])
      );
    }

    task.legacyProjectId && fetchTickets();
  }, []);

  return (
    <Dialog
      open
      style={{
        paddingTop: 12,
        minWidth: 720,
        zIndex: defaults && defaults.isDialogMode ? 106 : 103,
      }}
      onClose={cancel}
    >
      {task && (
        <DialogContent
          style={{
            paddingTop: 24,
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <EntitySelect
                disabled={readOnly}
                style={{ width: "100%" }}
                limit={20}
                title="Project"
                type="legacy_projects"
                searchFields={["id", "description"]}
                restService={restService}
                entityId={task.legacyProjectId}
                labelSelector={(entity) =>
                  `${entity["id"]} - ${entity["description"]}`
                }
                orders={[
                  { field: "regionId", direction: "ASC" },
                  { field: "id", direction: "DESC" },
                ]}
                onLoad={(entity) => {
                  if (!task.legacyProjectData) {
                    set("legacyProjectData", entity);
                  }
                }}
                onSelect={(entity) => {
                  set("legacyProjectId", entity ? entity.id : null);
                  set("legacyProjectData", entity);
                  set("serviceTicketIds", []);
                }}
              />
            </Grid>

            <Grid item>
              <EntitySelect
                limit={20}
                disabled={readOnly}
                title="Stadium"
                style={{ width: "100%" }}
                type="service_planning_stages"
                searchFields={["name"]}
                restService={restService}
                entityId={task.stageId}
                labelSelector={(entity) => `${entity["name"]}`}
                onLoad={(entity) => {
                  if (!task.stage) {
                    set("stage", entity);
                  }
                }}
                onSelect={(entity) => {
                  set("stageId", entity ? entity.id : null);
                  set("stage", entity);
                }}
              />
            </Grid>

            <Grid item>
              <Grid
                wrap="nowrap"
                container
                direction="row"
                spacing={1}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <TaskDateTimeField
                    readOnly={readOnly}
                    label="Van"
                    onChange={(newDate) => set("startDate", newDate)}
                    value={task.startDate}
                  />
                </Grid>
                <Grid item>
                  <TaskDateTimeField
                    readOnly={readOnly}
                    label="Tot"
                    onChange={(newDate) => set("endDate", newDate)}
                    value={task.endDate}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid wrap="nowrap" container direction="row" spacing={1}>
                <Grid item>
                  <TextField
                    fullWidth
                    disabled={task.isFinalized || readOnly}
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 0.5, step: "0.5" },
                      endAdornment: (
                        <InputAdornment position="start">uur</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Uren gepland"
                    type="number"
                    value={task.durationPlanned || ""}
                    onChange={(event) =>
                      set("durationPlanned", parseFloat(event.target.value))
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputProps: { min: 0.5, step: "0.5" },
                      endAdornment: (
                        <InputAdornment position="start">uur</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Uren besteed"
                    type="number"
                    value={task.durationSpend || ""}
                    onChange={(event) =>
                      set("durationSpend", parseFloat(event.target.value))
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    style={{ height: "100%", marginLeft: 8 }}
                    disabled={!task.durationSpend}
                    control={
                      <Checkbox
                        checked={task.isFinalized}
                        onChange={(event) =>
                          set("isFinalized", event.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Gereed"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                {mechanics.map((mechanic) => (
                  <Grid item key={mechanic.id}>
                    <Chip
                      disabled={readOnly}
                      variant="outlined"
                      size="small"
                      color={isAssignedTo(mechanic) ? "primary" : "default"}
                      onClick={() => toggleMechanic(mechanic)}
                      label={mechanic.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item>
              <EntitySelect
                limit={20}
                disabled={!task.legacyProjectId || readOnly}
                key={task.legacyProjectId}
                style={{ width: "100%" }}
                title="Melding koppelen"
                type="source"
                source={ticketSource}
                searchFields={[]}
                restService={restService}
                entityId={task.legacyProjectId}
                labelSelector={(entity) =>
                  `${entity["servicepunt"]} - ${entity["omschrijving"]}`
                }
                onSelect={(entity) => {
                  if (entity) {
                    const ticketId = entity["servicepunt"];

                    set(
                      "serviceTicketIds",
                      task.serviceTicketIds
                        .filter((candidateId) => candidateId != ticketId)
                        .concat([entity["servicepunt"]])
                    );
                  }
                }}
              />
            </Grid>
            <Grid item>
              <List
                style={{
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  padding: 0,
                }}
                dense
              >
                {(task.serviceTicketIds || []).map((ticketId) => (
                  <ListItem
                    button
                    key={ticketId}
                    disabled={!task.legacyProjectData}
                    onClick={() => openServiceTicket(ticketId)}
                  >
                    <ListItemText
                      primary={`${ticketId} - ${
                        (tickets[ticketId] &&
                          tickets[ticketId].uitvoerende_naam) ||
                        "Laden..."
                      }`}
                      secondary={
                        (tickets[ticketId] && tickets[ticketId].omschrijving) ||
                        "Laden..."
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        disabled={readOnly}
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          set(
                            "serviceTicketIds",
                            task.serviceTicketIds.filter(
                              (candidateId) => candidateId != ticketId
                            )
                          );
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item>
              <TextField
                disabled={readOnly}
                fullWidth
                variant="outlined"
                label="Memo"
                type="text"
                multiline
                rows={3}
                value={task.memo || ""}
                onChange={(event) => set("memo", event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <strong>Aangemaakt door:</strong>{" "}
                {(task.user && task.user.name) || "-"}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="text" onClick={() => cancel()}>
          Sluiten
        </Button>
        <Button
          disabled={readOnly}
          variant="text"
          onClick={() => onDeleted(task)}
        >
          Verwijderen
        </Button>
        <Button
          disabled={!saveAllowed()}
          variant="text"
          onClick={() => store()}
        >
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const useServiceTicketSource = (
  metacomService: MetacomService,
  task: ServiceEventDto
) => {
  return {
    onSearch: async (props, query) => {
      const response = await searchServiceTickets(
        metacomService,
        task.legacyProjectId
      );

      if (!response.hasError()) {
        return response.value;
      }
    },
    onSingle: (props, query) => Promise.resolve(null),
  } as EntitySelectSource;
};
