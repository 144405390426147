import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent {
  safeUrl: SafeResourceUrl;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    protected readonly url: string,
    protected readonly domSanitizer: DomSanitizer) {
    this.safeUrl = domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  open() {
    // window.location.href = this.url;
  }
}
