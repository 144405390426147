import { Component, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { MdcTextField } from '@angular-mdc/web';
import { FileUploadData } from '../file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements AfterViewInit {
  @Input() data: FileUploadData;
  @Output() dataChange = new EventEmitter<FileUploadData>();

  @ViewChild(MdcTextField, { static: true }) fileUpload: MdcTextField;

  constructor() { }

  protected get inputElement() {
    return (this.fileUpload._input as ElementRef<HTMLInputElement>).nativeElement;
  }

  ngAfterViewInit() {
    this.inputElement.multiple = true;
    this.inputElement.accept = 'image/*,application/pdf';
  }

  onChanged() {
    this.data = new FileUploadData(this.inputElement.files);
    this.dataChange.next(this.data);
  }
}
