import { Component, OnInit, Inject } from "@angular/core";
import { AppServiceCategory } from "../app-service-categories/app-service-category.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-app-service-category-dialog",
  templateUrl: "./app-service-category-dialog.component.html",
  styleUrls: ["./app-service-category-dialog.component.scss"],
})
export class AppServiceCategoryDialogComponent extends AppCrudDialogBoilerplate<
  AppServiceCategory
> {
  get canSave() {
    return !!this.data.model.name;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppServiceCategory>,
    protected readonly dialog: MdcDialogRef<AppServiceCategoryDialogComponent>
  ) {
    super(data, dialog);
  }
}
