import { DateWrapper } from "../date-stack-dialog.component";
import { DateState, DateStateField } from "./date-state";
import { setToday, valueOrToggle } from "./state.functions";

export class PortalDateState implements DateState {
  isFieldEnabled(wrapper: DateWrapper, field: DateStateField): boolean {
    switch (field) {
      case DateStateField.Activated:
        return wrapper.date.isOptional;
      case DateStateField.Completed:
        return this.isFieldToggled(wrapper, DateStateField.Activated);
      case DateStateField.SendNotification:
        return (
          this.isFieldToggled(wrapper, DateStateField.Completed) &&
          !!wrapper.date.notificationTemplateId
        );
      case DateStateField.ViewContent:
        return !!wrapper.date.notificationTemplateId;
    }
  }

  isFieldToggled(wrapper: DateWrapper, field: DateStateField): boolean {
    switch (field) {
      case DateStateField.Activated:
        return wrapper.date.isOptional ? wrapper.value.isActivated : true;
      case DateStateField.Completed:
        return wrapper.value.isCompleted;
      case DateStateField.SendNotification:
        return wrapper.sendNotification;
      case DateStateField.ViewContent:
        return false;
    }
  }

  toggleField(
    wrapper: DateWrapper,
    field: DateStateField,
    value?: boolean
  ): void {
    switch (field) {
      case DateStateField.Activated:
        {
          const isToggled = (wrapper.value.isActivated = valueOrToggle(
            wrapper.value.isActivated,
            value
          ));

          if (!isToggled) {
            this.toggleField(wrapper, DateStateField.Completed, false);
          }
        }
        break;
      case DateStateField.Completed:
        {
          const isToggled = (wrapper.value.isCompleted = valueOrToggle(
            wrapper.value.isCompleted,
            value
          ));

          if (isToggled) {
            setToday(wrapper, true);
          }

          this.toggleField(wrapper, DateStateField.SendNotification, isToggled);
        }
        break;
      case DateStateField.SendNotification:
        {
          wrapper.sendNotification = valueOrToggle(
            wrapper.sendNotification,
            value
          );
          wrapper.value.send_notification = wrapper.sendNotification;
        }
        break;
    }
  }
}
