import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { environment } from "../environments/environment";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    request: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {
    const hasBaseUrl =
      request.url.startsWith("http:") || request.url.startsWith("https:");

    const isAppApi = request.url.startsWith(environment.appApiBaseUrl);

    request = request.clone({
      setHeaders: {
        "ngsw-bypass": "true",
      },
      withCredentials: !isAppApi,
    });

    if (!hasBaseUrl) {
      request = this.setBaseUrl(request);
    }

    return next.handle(request);
  }

  protected setBaseUrl(request: HttpRequest<{}>) {
    if (!request.url.startsWith(environment.baseUrl)) {
      return request.clone({
        url: `${environment.baseUrl}/${request.url}`,
      });
    }

    return request;
  }
}
