import { User } from "../accessibility-users/user.entity";
import { DocumentMeta } from "../documents/document-meta.entity";
import { Entity, setRelationOne } from "../entity.service";
import { Project } from "../project/project.entity";
import { MeterEntity } from "../service-settings/react/meters/meter.entity";
import { SpaceEntity } from "../service-settings/react/spaces/space.entity";
import { TypeEntity } from "../service-settings/react/types/type.entity";
import { PriorityEntity } from "../service-settings/react/priorities/priority.entity";

export interface DeliveryListProjectEntity {
  id: string;
  regionId: string;
}

export interface ServiceCategoryEntity {
  id: number;
  name: string;
}

export interface ServiceSpaceEntity {
  id: string;
  code: string;
  description: string;
}

export interface DeliveryListPointEntity {
  id?: string;
  projectId?: string;
  project?: {
    description: string;
  };

  serviceTicketId?: string;

  serviceTypeId?: string;
  serviceType?: TypeEntity;

  serviceSpaceId?: string;
  serviceSpaceIds?: string[];
  serviceSpace?: SpaceEntity;

  servicePriorityId?: string;
  servicePriority?: PriorityEntity;

  code?: string;
  description?: string;

  primaryCategoryId?: number;
  secondaryCategoryId?: number;

  assignedUserId?: string;
  assignedUser?: User;

  images?: DeliveryListPointImageEntity[];

  __isExpanded?: boolean;
  __refId?: string;
}

export interface DeliveryListPointImageEntity {
  id?: string;
  pointId?: string;
  documentMetaId?: string;
  __staging?: {
    file: File;
    preview: string;
  };
  isPendingDelete?: boolean;
}

export interface DeliveryListMeterEntityProps {
  id?: string;
  projectId?: string;
  meterId?: string;
  meter?: MeterEntity;
  value?: string;
}

export class DeliveryListMeterEntity
  extends Entity
  implements DeliveryListMeterEntityProps
{
  type = "project_delivery_list_meters";

  projectId?: string;
  meterId?: string;
  meter?: MeterEntity;

  value: string;
}

export interface DeliveryListSignatureEntityProps {
  id?: string;
  projectId?: string;
  kind?: string;

  documentMetaId?: string;
  documentMeta?: DocumentMeta;

  isFinal?: boolean;
  isFinalAt?: Date;
}

export class DeliveryListSignatureEntity
  extends Entity
  implements DeliveryListSignatureEntityProps
{
  type = "project_delivery_list_signatures";

  projectId: string;
  kind: string;

  documentMetaId: string;
  documentMeta: DocumentMeta;

  imageHash?: string;

  set __documentMeta__(value: DocumentMeta) {
    this.documentMeta = setRelationOne(this, DocumentMeta, value);
  }
}

export class DeliveryListEntity extends Entity {
  type = "project_delivery_lists";

  isFinal?: boolean;
  isFinalAt?: Date;

  isDeliveredAt?: Date;

  project: Project;
  projectId: string;

  keyAmount: number;
  hiredAgent?: string;

  set __project__(value: Project) {
    this.project = setRelationOne(this, Project, value);
  }

  houseDocumentMetaId: string;
  houseDocumentMeta: DocumentMeta;

  set __houseDocumentMeta__(value: DocumentMeta) {
    this.houseDocumentMeta = setRelationOne(this, DocumentMeta, value);
  }

  assignedUser: User;
  assignedUserId: string;

  set __assignedUser__(value: User) {
    this.assignedUser = setRelationOne(this, User, value);
  }
}
