import { MdcDialog, MdcMenu } from "@angular-mdc/web";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  Optional,
  Output,
} from "@angular/core";
import {
  AccessibilityNew,
  AddAlert,
  Book as BookIcon,
  CalendarToday as CalendarTodayIcon,
  CalendarViewDay,
  ContactPhone as ContactPhoneIcon,
  Create as CreateIcon,
  Dashboard as DashboardIcon,
  Euro as EuroIcon,
  Folder as FolderIcon,
  Group as GroupIcon,
  ListAlt as ListAltIcon,
  LocalShipping as LocalShippingIcon,
  Mail as MailIcon,
  MarkunreadMailbox as MarkunreadMailboxIcon,
  Navigation as NavigationIcon,
  NotificationImportant as NotificationImportantIcon,
  Payment as PaymentIcon,
  PlayForWork as PlayForWorkIcon,
  Power as PowerIcon,
  RoomService as RoomServiceIcon,
  SvgIconComponent,
  VpnKey as VpnKeyIcon,
} from "@material-ui/icons";
import { EntityManager } from "../entity.service";
import { GrantService } from "../grant.service";
import { Project } from "../project/project.entity";
import { ReactComponent } from "../react-component/react.component";
import { RestResponse } from "../rest.service";
import { UrlOpenService } from "../url-open.service";
import { SendNotificationHandler } from "./handlers/send-notification.handler";
import { ProjectShortcutHandlerInfo } from "./project-shortcut.handler";
import { ProjectShortcuts, ProjectShortcutsItem } from "./ProjectShortcuts";

@Component({
  selector: "app-project-shortcuts",
  templateUrl: "./project-shortcuts.component.html",
  styleUrls: ["./project-shortcuts.component.scss"],
})
export class ProjectShortcutsComponent extends ReactComponent {
  containerId = `react-shortcut-container-${Math.random()}`;
  component = ProjectShortcuts;

  readonly componentShortcuts = () => [
    new ProjectShortcutsItem({
      title: "Mail opstellen",
      icon: MailIcon,
      onClick: () => this.openComposeMail(),
    }),
    new ProjectShortcutsItem({
      title: "Routebeschrijving",
      icon: NavigationIcon,
      onClick: () => this.openNavigation(),
    }),

    new ProjectShortcutsItem({
      title: "Gegevens",
      children: [
        this.composeRoute({
          title: "Afspraken",
          icon: BookIcon,
          route: "/office/metacom-project-agreement",
        }),
        this.composeRoute({
          title: "Facturen",
          icon: PaymentIcon,
          route: "/office/metacom-project-invoices",
        }),
        this.composeRoute({
          title: "Documenten",
          icon: FolderIcon,
          route: "/office/documents",
        }),
        this.composeRoute({
          title: "Mail correspondentie",
          icon: MarkunreadMailboxIcon,
          route: "/office/mail-traffic",
        }),
      ],
    }),

    new ProjectShortcutsItem({
      title: "Snelkoppelingen",
      children: [
        this.composeRoute({
          title: "Jaarplanning",
          icon: CalendarTodayIcon,
          route: "/planning/year-planning-project",
        }),

        // Planning
        this.composeRoute({
          title: "Planning",
          icon: CalendarViewDay,
          route: "/planning/project",
        }),

        // Project Relaties
        this.composeRoute({
          title: "Project Relaties",
          icon: GroupIcon,
          route: "/office/metacom-project-relations",
        }),

        // Bouw
        this.composeRoute({
          title: "WPI & checklist afbeeldingen",
          icon: CreateIcon,
          route: "/construction/audit-sheet-phases",
        }),

        // Toelevering
        this.composeRoute({
          title: "Vrachten",
          icon: LocalShippingIcon,
          route: "/supply/cargo",
        }),

        // Administratie
        this.composeRoute({
          title: "Werkopdrachten",
          icon: PlayForWorkIcon,
          route: "/office/work-orders",
          maker: (ctx) => `${ctx.projectId}/${ctx.relationId || ""}`,
        }),

        // Service
        this.composeRoute({
          title: "Service",
          icon: RoomServiceIcon,
          route: "/service/overview",
          maker: (ctx) => `S${ctx.projectId}`,
        }),

        this.composeRoute({
          title: "Opleverlijst",
          icon: ListAltIcon,
          route: "/service/delivery-list",
        }),

        this.composeRoute({
          title: "WKB",
          icon: AccessibilityNew,
          route: "/service/quality-assurance",
        }),

        this.composeRoute({
          title: "Onderhoudschecks",
          icon: ListAltIcon,
          route: "/service/maintenance-report-overview",
        }),

        this.composeRoute({
          title: "Inregelrapport",
          icon: PowerIcon,
          route: "/construction/installation-report",
        }),
      ],
    }),

    new ProjectShortcutsItem({
      title: "App",
      children: [
        this.composeRoute({
          title: "Inloggegevens",
          icon: VpnKeyIcon,
          route: "/app/users",
        }),

        this.composeRoute({
          title: "Gegevens",
          icon: ContactPhoneIcon,
          route: "/app/project-field-view",
        }),

        this.composeRoute({
          title: "Dashboard",
          icon: DashboardIcon,
          route: "/app/sso",
        }),

        this.composeRoute({
          title: "Financieel overzicht",
          icon: EuroIcon,
          route: "/app/project-costs",
        }),

        this.composeRoute({
          title: "Notificatie rooster",
          icon: NotificationImportantIcon,
          route: "/app/notification-resources",
        }),

        new ProjectShortcutsItem({
          title: "Notificatie versturen",
          icon: AddAlert,
          access: () => !!this.getHandler("send-notification"),
          onClick: () => this.openHandler("send-notification"),
        }),
      ],
    }),
    ...(this.custom
      ? [
          new ProjectShortcutsItem({
            title: "Opties",
            children: this.custom.map(
              (custom) =>
                new ProjectShortcutsItem({
                  title: custom.description,
                  onClick: () => {
                    this.onCustom.next({ id: custom.id });
                    this.onOpened.next({});
                  },
                })
            ),
          }),
        ]
      : []),
  ];

  readonly handlers: ProjectShortcutHandlerInfo[] = [
    {
      id: "send-notification",
      name: "App - Notificatie versturen",
      icon: "add_alert",
      type: SendNotificationHandler,
    },
  ];

  @Input()
  project: string | Project;

  @Input()
  context?: IShortcutContextData;

  @Input()
  forceNewTab: boolean;

  @Input()
  custom: CustomShortcut[];

  @Output()
  onCustom = new EventEmitter<{ id: string }>();

  @Output()
  onOpened = new EventEmitter<{}>();

  response: RestResponse<Project>;
  accessibleHandlers: ProjectShortcutHandlerInfo[];

  protected get projectService() {
    return this.entities.get(Project);
  }

  protected get nativeProjectId() {
    const projectId =
      typeof this.project === "string" ? (this.project as string) : null;

    return projectId
      ? projectId.charAt(0) === "S"
        ? projectId.substr(1)
        : projectId
      : this.response
      ? this.response.value.id
      : null;
  }

  constructor(
    @Optional()
    protected readonly menu: MdcMenu,
    @Optional()
    protected readonly dialog: MdcDialog,
    protected readonly injector: Injector,
    protected readonly granting: GrantService,
    protected readonly urls: UrlOpenService,
    protected readonly entities: EntityManager,
    protected readonly changes: ChangeDetectorRef
  ) {
    super();

    this.onOpened.subscribe(() => this.closeMenus());
  }

  async ngOnInit() {
    const promises = this.handlers
      .map((handler) => ({
        ...handler,
        instance: this.injector.get(handler.type),
      }))
      .map(async (handler) => ({
        handler,
        hasAccess: await handler.instance.access(),
      }));

    this.accessibleHandlers = (await Promise.all(promises))
      .filter((result) => result.hasAccess)
      .map((result) => result.handler);

    super.ngOnInit();
  }

  async onInViewportChange(isVisible: boolean) {
    if (this.project && isVisible) {
      this.response =
        typeof this.project === "string"
          ? await this.projectService.queryFirst({
              filters: [
                { field: "id", operator: "Equal", value: this.nativeProjectId },
              ],
              select: ["id", "description", "longitude", "latitude"],
            })
          : new RestResponse(this.project as Project);
    }

    this.changes.detectChanges();
  }

  params() {
    return Object.assign(
      {
        shortcuts: this.componentShortcuts(),
      },
      super.params()
    );
  }

  access(url: string) {
    return this.granting.isRouteAccessable(url);
  }

  composeRoute(config: {
    title: string;
    route: string;
    icon?: SvgIconComponent;
    maker?: IMakeRoute;
  }) {
    config.maker = config.maker || ((ctx) => ctx.projectId);

    return new ProjectShortcutsItem({
      title: config.title,
      access: () => this.access(config.route),
      onClick: () =>
        this.openRoute((ctx) => `${config.route}/${config.maker(ctx)}`),
      icon: config.icon,
    });
  }

  openRoute(maker: IMakeRoute) {
    const ctx: IShortcutContext = Object.assign(
      { projectId: this.nativeProjectId },
      this.context || {}
    );
    const url = maker(ctx);

    this.urls.openNew({
      url: `#${url}`,
      forceNewTab: this.forceNewTab,
    });

    this.onOpened.next({});
  }

  openNavigation() {
    this.urls.open(
      `https://maps.google.nl/maps/?daddr=${this.response.value.longitude}+${this.response.value.latitude}`
    );
    this.onOpened.next({});
  }

  openComposeMail() {
    this.urls.open(
      `mailto:?SUBJECT=${this.response.value.id} - ${this.response.value.description} | Onderwerp:`
    );
    this.onOpened.next({});
  }

  async openHandler(id: string) {
    const handler = this.getHandler(id);

    if (handler) {
      const response = await handler.instance.perform(
        this.changes,
        this.nativeProjectId
      );

      this.onOpened.next({});

      return response;
    }
  }

  getHandler(id: string) {
    return this.accessibleHandlers
      ? this.accessibleHandlers.find((h) => h.id === id)
      : null;
  }

  protected closeMenus() {
    if (this.menu) {
      this.menu.open = false;
      this.changes.detectChanges();
    }
  }
}

export class CustomShortcut {
  constructor(
    readonly id: string,
    readonly icon: string,
    readonly description: string
  ) {}
}

interface IShortcutContext extends IShortcutContextData {
  projectId: string;
}

export interface IShortcutContextData {
  relationId?: string;
}

type IMakeRoute = (ctx: IShortcutContext) => string;
