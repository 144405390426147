import { Entity } from "src/app/entity.service";

export abstract class CrudState {}

export class CrudInitialState extends CrudState {}

export class CrudErrorState extends CrudState {
  constructor(
    readonly props: {
      readonly message: string;
    }
  ) {
    super();
  }
}

interface CrudLoadedStateProps<T extends Entity> {
  readonly entities: T[];
}

export class CrudLoadedState<T extends Entity> extends CrudState {
  constructor(readonly props: CrudLoadedStateProps<T>) {
    super();
  }

  copyWith(props: CrudLoadedStateProps<T>) {
    return new CrudLoadedState(Object.assign(props, this.props));
  }
}

export class CrudLoadedWithLoadingState<
  T extends Entity
> extends CrudLoadedState<T> {}
