import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnChanges {
  @Input() time: string;
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() disabled: boolean;

  hours: number = 0;
  minutes: string = '00';

  ngOnChanges(changes: SimpleChanges) {
    const prop = changes.time;

    if (prop && prop.currentValue && prop.currentValue !== prop.previousValue) {
      this.hours = parseFloat(prop.currentValue.split(':').shift());
      this.minutes = prop.currentValue.split(':').pop();
    }
  }

  escalate() {
    if (this.hours !== null && this.minutes !== null) {
      this.time = `${formatNumber(this.hours, 'nl', '2.0-0')}:${this.minutes}`;
      this.timeChange.next(this.time);
    }
  }

  onClick() {
    if (!this.hours) {
      this.hours = null;
    }
  }

  onBlur() {
    if (!this.hours) {
      this.hours = 0;
    }
  }
}
