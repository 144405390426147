import { Entity } from "../entity.service";
import { Project } from "../project/project.entity";

export class PlanningProjectItem extends Entity {
  type = "planning_project_items";

  parentId?: string;
  dimension: string;
  projectId: string;

  competenceId: string;
  competenceNumber: string;
  competenceIndex: number;
  competenceStartsAt: Date;
  competenceEndsAt: Date;

  comments?: string;
  hours?: string;

  status?: string;

  humanResourceId: string;
  humanResourceNumber: string;
  humanResourceStartsAt: Date;
  humanResourceEndsAt: Date;

  __project__?: Project;
  __competence__?: PlanningCompetence;
  __humanResource__?: PlanningHumanResource;

  virtualResources?: PlanningProjectItem[];

  get project() {
    return this.one(PlanningProjectItem, Project, "project");
  }

  get competence() {
    return this.one(PlanningProjectItem, PlanningCompetence, "competence");
  }
}

export class PlanningCompetence extends Entity {
  type = "planning_competences";

  number: string;
  description: string;
  color: string;

  cargoIdField = "competenceId";
  cargoEntityField = "__competence__";
}

export class PlanningHumanResource extends Entity {
  type = "planning_human_resources";

  number: string;
  color: string;

  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  email: string;
}
