import { divIcon } from "leaflet";

export const createCustomLeafletPin = (
  color: string,
  text?: string,
  textColor?: string
) =>
  divIcon({
    className: "ghb-custom-pin",
    html: `<div><span style="background-color: ${color ?? "#fff"};
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    left: -0.75rem;
    top: -0.75rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #000000"></span>${
      (text && renderPinText(text, textColor)) || ""
    }</div>`,
    iconAnchor: [0, 0],
  });

const renderPinText = (
  text: string,
  textColor: string = "black"
) => `<span style="color:${textColor};font-weight: bold;
width: 1.25rem;
height: 1.25rem;
display: block;
left: -0.4rem;
top: -0.6rem;
position: absolute">${text}</span>`;
