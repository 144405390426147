import { Entity } from "../entity.service";

export class MailSchedulerEntity extends Entity {
  type = "mail_schedulers";

  cron: string;

  description: string;

  sourceType: string;
  sourceTable?: string;

  condition: string;
  mail: string;
  mailCC?: string;
  subject: string;
  template: string;
}
