import { Entity } from "src/app/entity.service";

export interface PriorityEntityProps {
  id: string;
  description: string;
  offsetDays: number;

  createdAt: Date;
}

export class PriorityEntity extends Entity implements PriorityEntityProps {
  type = "service_priorities";

  description: string;
  offsetDays: number;
  isStandard: boolean;

  createdAt: Date;
}
