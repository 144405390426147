import { HttpClient } from "@angular/common/http";
import { Injector } from "@angular/core";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { Apollo } from "apollo-angular";
import * as moment from "moment";
import * as React from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { ServiceCategoryEntity } from "../delivery-list/delivery-list.entity";
import { EntitySelect } from "../entity-select/entity-select";
import { Ops } from "../entity.service";
import { Project } from "../project/project.entity";
import { RestService } from "../rest.service";
import { PresetEntity } from "../service-settings/react/presets/preset.entity";
import { PriorityEntity } from "../service-settings/react/priorities/priority.entity";
import { SpaceEntity } from "../service-settings/react/spaces/space.entity";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { serviceWizardCategoryQuery } from "./service-wizard.query";
import {
  ServiceTransmitter,
  ServiceTransmitterTicket,
  ServiceTransmitterTicketKind,
} from "./service.transmitter";
import { SecureImage } from "../secured-image/secure-image";
import { Alert } from "@material-ui/lab";
import { User } from "../accessibility-users/user.entity";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    display: "block",
    marginTop: 12,
    marginBottom: 12,
  },
  fieldText: {
    width: "100%",
    marginTop: 0,
    marginBottom: 0,
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  dropzone: {
    flex: 1,
  },
  dropzoneUpload: {},
  images: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 0,
    paddingTop: 0,
  },
  imagePreview: {
    flex: 1,
    width: 100,
    height: 100,
    maxWidth: 100,
    objectFit: "cover",
    backgroundColor: "grey",
    border: "1px solid #eeeeee",
    borderRadius: 0,
    "&:hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
    position: "relative",
  },
  imageDelete: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: "white",
    zIndex: 999,
  },
}));

interface ServiceWizardImage {
  documentMetaId?: string;

  file?: File;
  preview?: string;
}

export interface ServiceWizardComponentProps {
  injector: Injector;
  fillIn?: {
    description?: string;
    internalMemo?: string;
    projectId?: string;
    space?: string;
    images?: ServiceWizardImage[];
  };

  noImageEdit?: boolean;
  onClosed: (ticket?: ServiceTransmitterTicket) => void;
}

export function ServiceWizardComponent(props: ServiceWizardComponentProps) {
  const { injector } = props;
  const rest = injector.get(RestService);
  const http = injector.get(HttpClient);
  const apollo = injector.get(Apollo);
  const transmitter = injector.get(ServiceTransmitter);
  const classes = useStyles();

  const [preset, setPreset] = useState<PresetEntity>();
  const [projectId, setProjectId] = useState<string>(
    props.fillIn && props.fillIn.projectId ? props.fillIn.projectId : null
  );
  const [project, setProject] = useState<Project>();
  const [assignedUserId, setAssignedUserId] = useState<string>();
  const [assignedUser, setAssignedUser] = useState<User>();
  const [priority, setPriority] = useState<PriorityEntity>();
  const [space, setSpace] = useState<SpaceEntity>(
    props.fillIn && props.fillIn.space
      ? ({ description: props.fillIn.space } as SpaceEntity)
      : null
  );
  const [description, setDescription] = useState<string>(
    props.fillIn && props.fillIn.description ? props.fillIn.description : ""
  );
  const [internalMemo, setInternelMemo] = useState<string>(
    props.fillIn && props.fillIn.internalMemo ? props.fillIn.internalMemo : ""
  );
  const [images, setImages] = useState<ServiceWizardImage[]>(
    props.fillIn && props.fillIn.images ? props.fillIn.images : []
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  async function submit() {
    setSaving(true);
    setErrorMessage(null);

    const categories = await apollo
      .query<{
        primary: ServiceCategoryEntity;
        secondary: ServiceCategoryEntity;
      }>({
        query: serviceWizardCategoryQuery,
        variables: {
          primaryCategoryId: `${preset.primaryCategoryId}`,
          secondaryCategoryId: `${preset.secondaryCategoryId}`,
        },
      })
      .toPromise();

    const model = new ServiceTransmitterTicket({
      libraryCode: preset.code,
      projectId: projectId,
      projectDescription: project ? project.description : "",
      executiveUserId: assignedUserId,
      executiveUserName: assignedUser ? assignedUser.name : "",
      date: moment().toDate(),
      dateEnd: moment().add(priority.offsetDays, "days").toDate(),
      space: space.description,
      description: description,
      internalMemo: internalMemo,
      primaryCategory: categories.data.primary.name,
      secondaryCategory: categories.data.secondary.name,
      imageIds: images
        .filter((_image) => _image.documentMetaId)
        .map((_image) => _image.documentMetaId),
    });

    try {
      const response = await transmitter.createTicket(model, {
        notify: true,
        kind: ServiceTransmitterTicketKind.Service,
      });

      props.onClosed(response);
    } catch (e) {
      setErrorMessage(e.toString());
    }

    setSaving(false);
  }

  function canSave() {
    return (
      preset && description && projectId && space && assignedUserId && priority
    );
  }

  function closeDialog() {
    if (!saving) {
      props.onClosed();
    }
  }

  function renderImage(image: ServiceWizardImage) {
    return (
      <div className={classes.imagePreview} style={{ margin: 4 }}>
        <React.Fragment>
          {!!image.documentMetaId ? (
            <SecureImage
              src={`documents/view/${image.documentMetaId}`}
              noCache
              noDebounce
              http={http}
              className={classes.imagePreview}
              placeholder={
                <div style={{ padding: 6, color: "white" }}>Laden..</div>
              }
            />
          ) : (
            <img src={image.preview} className={classes.imagePreview} />
          )}
          {props.noImageEdit !== true && (
            <div className={classes.imageDelete}>
              <IconButton
                disabled={saving}
                size="small"
                color="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  setImages(images.filter((_image) => _image !== image));
                }}
              >
                <DeleteIcon fontSize="inherit" color="action" />
              </IconButton>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }

  return (
    <>
      <PortalThemeProvider>
        <Dialog open onClose={closeDialog}>
          <DialogTitle>Servicemelding aanmaken</DialogTitle>
          <DialogContent style={{ minWidth: 512 }}>
            <EntitySelect
              disabled={saving}
              className={classes.field}
              style={{ width: "100%" }}
              limit={20}
              title="Kiezen vanuit Bibliotheek"
              type="service_presets"
              labelSelector={(_preset: PresetEntity) => _preset.description}
              searchFields={["code", "description"]}
              restService={rest}
              onSelect={(_preset: PresetEntity) => {
                setPreset(_preset);
                if (_preset && !description.length) {
                  setDescription(_preset.description);
                }
              }}
            />

            <TextField
              disabled={saving}
              multiline
              className={classes.fieldText}
              style={{ width: "100%" }}
              variant="outlined"
              label="Omschrijving*"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />

            <TextField
              disabled={saving}
              multiline
              className={classes.fieldText}
              style={{ width: "100%" }}
              variant="outlined"
              label="Interne opmerking"
              value={internalMemo}
              onChange={(event) => setInternelMemo(event.target.value)}
            />

            <EntitySelect
              disabled={saving}
              style={{ width: "100%" }}
              className={classes.field}
              title="Project*"
              type="projects"
              entityId={projectId}
              labelSelector={(type: Project) =>
                `${type.id} - ${type.description}`
              }
              searchFields={["id", "description"]}
              orders={[{ field: "id", direction: "ASC" }]}
              restService={rest}
              onSelect={(project: Project) => {
                setProjectId(project ? project.id : null);
                setProject(project);
              }}
            />

            <EntitySelect
              disabled={saving}
              style={{ width: "100%" }}
              className={classes.field}
              title="Ruimte*"
              type="service_spaces"
              entity={space}
              labelSelector={(_space: SpaceEntity) =>
                [_space.code, _space.description].filter((_) => !!_).join(" - ")
              }
              searchFields={["description"]}
              orders={[{ field: "code", direction: "ASC" }]}
              restService={rest}
              onSelect={(_space: SpaceEntity) => setSpace(_space)}
            />

            <EntitySelect
              disabled={saving}
              style={{ width: "100%" }}
              title="Uitvoerende*"
              type="users"
              labelSelector="name"
              searchFields={["name"]}
              restService={rest}
              filters={[Ops.Field("roleId").IsNull(true)]}
              onSelect={(user: User) => {
                setAssignedUserId(user ? user.id : null);
                setAssignedUser(user);
              }}
            />

            <EntitySelect
              disabled={saving}
              style={{ width: "100%" }}
              className={classes.field}
              title="Prioriteit*"
              type="service_priorities"
              labelSelector={(type: PriorityEntity) => `${type.description}`}
              searchFields={["description"]}
              restService={rest}
              onSelect={(_priority: PriorityEntity) => setPriority(_priority)}
            />

            <div className={classes.row}>
              <div className={classes.images}>
                {images.map((image, index) => (
                  <div key={`image-${index}`}>{renderImage(image)}</div>
                ))}
              </div>
            </div>

            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </DialogContent>
          <DialogActions>
            {props.noImageEdit !== true && (
              <Dropzone
                disabled={saving}
                accept="image/*"
                multiple
                onDrop={(acceptedFiles) => {
                  setImages(
                    images.concat(
                      acceptedFiles.map((_file) => ({
                        file: _file,
                        preview: URL.createObjectURL(_file),
                      }))
                    )
                  );
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className={classes.dropzoneUpload}>
                        <Button
                          disabled={saving}
                          style={{ width: "100%" }}
                          size="small"
                          variant="outlined"
                          type="button"
                        >
                          Afbeelding toevoegen
                        </Button>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}

            <Button disabled={saving} onClick={closeDialog} color="default">
              Terug
            </Button>
            <Button
              disabled={!canSave() || saving}
              onClick={submit}
              color="primary"
            >
              Aanmaken{" "}
              {saving && (
                <CircularProgress size="1.5rem" style={{ marginLeft: 8 }} />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </PortalThemeProvider>
    </>
  );
}
