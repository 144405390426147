import * as React from "react";
import * as moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  LinearProgress,
} from "@material-ui/core";

import MoldInterface from "../interfaces/mold.interface";
import DayInterface from "../interfaces/day.interface";
import { TaskDroppableComponent } from "./task-droppable.component";
import { TaskComponent } from "./task.component";
import { chain, first, sumBy } from "lodash";
import { WeekToolbarComponent } from "./week-toolbar.component";
import { formatNumber } from "@angular/common";
import { Ops } from "src/app/entity.service";
import { ProjectHousePart } from "src/app/cargo/house-part.entity";
import { composeTask } from "../functions/compose-task";
import { TableLayoutContext } from "../context/table-layout.context";
import { currentWeek } from "../functions/current-week";

export const PlanningComponent: React.FC<{}> = () => {
  const context = React.useContext(TableLayoutContext);

  const [dateStart, setDateStart] = React.useState<Date>(currentWeek());
  const [days, setDays] = React.useState<DayInterface[]>(null);
  const [focusDateId, setFocusDateId] = React.useState<string>();

  const [molds, setMolds] = React.useState<MoldInterface[]>(null);

  const tasks = context.tasks || [];

  const renderTask = (mold: MoldInterface, date: DayInterface) => {
    const tasksForMoldAndDay = tasks.filter(
      (t) => t.moldId === mold.id && t.dateId === date.id
    );

    if (tasksForMoldAndDay?.length) {
      return (
        <>
          {tasksForMoldAndDay.map((task) => (
            <TaskComponent task={task} />
          ))}
        </>
      );
    }
  };

  const dateCapacity = (dateId: string) => {
    return formatNumber(
      sumBy(
        tasks.filter((t) => t.dateId === dateId),
        (t) => t.capacity
      ),
      "nl",
      "1.2-2"
    );
  };

  const dateLength = (dateId: string) => {
    return formatNumber(
      sumBy(
        tasks.filter((t) => t.dateId === dateId),
        (t) => +(t.length ?? 0)
      ) / 1000,
      "nl",
      "1.2-2"
    );
  };

  const dateWidth = (dateId: string) => {
    return formatNumber(
      sumBy(
        tasks.filter((t) => t.dateId === dateId),
        (t) => +(t.width ?? 0)
      ) / 1000,
      "nl",
      "1.2-2"
    );
  };

  const composeDates = () => {
    setDays(undefined);

    const items = [];

    for (let i = 0; i < 6; i++) {
      const day = moment(dateStart).add(i, "days");

      items.push({
        id: day.format("YYYY-MM-DD"),
        index: i,
        date: day.toDate(),
      });
    }

    setDays(items);
  };

  const fetchMolds = async () => {
    const response = await context.component.metacom.queryTableAsync<{
      code: string;
      omschrijving: string;
      planning: string;
    }>({
      setName: "metacom",
      tableName: "GPB_locaties",
    });

    if (!response.hasError()) {
      setMolds(
        response.value
          .filter(
            (_item) =>
              _item.planning.toLowerCase() ===
              context.component.settings.moldCode
          )
          .map((item) => ({
            id: item.code,
            name: item.omschrijving,
          }))
      );
    }
  };

  const fetchTasks = async () => {
    context.setTasks(undefined);
    context.setBackdropOpen(true);

    const repo = context.component.entities.get(ProjectHousePart);
    const response = await repo.query({
      filters: [
        Ops.Field("dateId").Between(
          moment(dateStart).format("YYYY-MM-DD"),
          moment(dateStart).add(5, "days").format("YYYY-MM-DD")
        ),
        Ops.Field("moldId").IsNull(true),
        Ops.Field("costId").Equals(context.component.settings.costId),
        Ops.Field("supplierId").Equals(context.component.settings.supplierId),
        Ops.Field("wallId").IsNull(true),
      ],
      relations: ["housePart", "project", "project.tableLayoutComments"],
    });

    if (!response.hasError()) {
      const items = chain(response.value)
        .orderBy((item) => item.partCodeId, "asc")
        .groupBy((item) => `${item.projectId}-${item.wallId}`)
        .map((items, key) => composeTask(first(items), items))
        .orderBy((item) => item.wallId, "asc")
        .value();

      context.setTasks(items);
    }

    context.setBackdropOpen(false);
  };

  React.useEffect(() => {
    fetchMolds();
  }, []);

  React.useEffect(() => {
    if (dateStart) {
      composeDates();
      fetchTasks();
    }
  }, [dateStart]);

  const isLoading = () => !tasks || !molds || !days;

  return (
    <Container className="container" maxWidth={false} disableGutters={true}>
      <WeekToolbarComponent onDateChange={setDateStart} allowExpand />

      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table" className="table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "10%" }}
                key="spacer"
                className="header horizontal"
              />
              {(days || [])
                .filter((d) => (focusDateId ? d.id == focusDateId : true))
                .map((day) => (
                  <TableCell
                    style={{ width: day.id == focusDateId ? 128 : 128 }}
                    key={day.id}
                    className="header horizontal"
                    onClick={() => setFocusDateId(focusDateId ? null : day.id)}
                  >
                    <Typography
                      variant="subtitle2"
                      className="title"
                      display="inline"
                    >
                      {moment(day.date).locale("nl").format("dd")}
                    </Typography>
                    <Typography
                      style={{ marginLeft: 6 }}
                      display="inline"
                      variant="caption"
                      className="caption"
                    >
                      {moment(day.date).locale("nl").format("DD MMM")}
                    </Typography>
                    <Typography
                      style={{
                        float: "right",
                        fontSize: "10px",
                        lineHeight: "1.5",
                      }}
                      display="inline"
                      variant="caption"
                      className="caption"
                    >
                      {dateLength(day.id)} L {dateWidth(day.id)} B
                      <br />
                      {dateCapacity(day.id)} m³
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(molds || []).map((mold) => {
              return (
                <TableRow hover role="checkbox" key={mold.id}>
                  <TableCell key={mold.id} className="header">
                    <Typography variant="subtitle2" className="title">
                      {mold.name}
                    </Typography>
                  </TableCell>
                  {(days || [])
                    .filter((d) => (focusDateId ? d.id == focusDateId : true))
                    .map((day) => (
                      <TaskDroppableComponent
                        key={`${mold.id}-${day.id}`}
                        context={{ moldId: mold.id, dateId: day.id }}
                      >
                        {renderTask(mold, day)}
                      </TaskDroppableComponent>
                    ))}
                </TableRow>
              );
            })}

            {isLoading() && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
