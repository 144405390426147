import { Component, Inject } from "@angular/core";
import { AppConversationMessagePreset } from "../app-conversation-message-presets/app-conversation-message-preset.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";

@Component({
  selector: "app-app-conversation-message-preset-dialog",
  templateUrl: "./app-conversation-message-preset-dialog.component.html",
  styleUrls: ["./app-conversation-message-preset-dialog.component.scss"],
})
export class AppConversationMessagePresetDialogComponent extends AppCrudDialogBoilerplate<
  AppConversationMessagePreset
> {
  get canSave() {
    return !!this.data.model.title && !!this.data.model.message;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppConversationMessagePreset>,
    protected readonly dialog: MdcDialogRef<
      AppConversationMessagePresetDialogComponent
    >
  ) {
    super(data, dialog);
  }
}
