import { Routes } from "@angular/router";
import { AccessibilityRolesComponent } from "./accessibility-roles/accessibility-roles.component";
import { AccessibilityUsersComponent } from "./accessibility-users/accessibility-users.component";
import { AppActivitiesComponent } from "./app-activities/app-activities.component";
import { AppContentPreviewComponent } from "./app-content-preview/app-content-preview.component";
import { AppContentsComponent } from "./app-contents/app-contents.component";
import { AppConversationMessagePresetsComponent } from "./app-conversation-message-presets/app-conversation-message-presets.component";
import { AppDocumentDetailsComponent } from "./app-document-details/app-document-details.component";
import { AppDocumentTypesComponent } from "./app-document-types/app-document-types.component";
import { AppNotificationResourcesComponent } from "./app-notification-resources/app-notification-resources.component";
import { AppNotificationSchedulesComponent } from "./app-notification-schedules/app-notification-schedules.component";
import { AppNotificationTemplatesComponent } from "./app-notification-templates/app-notification-templates.component";
import { AppPhasesComponent } from "./app-phases/app-phases.component";
import { AppProjectCostPresetsComponent } from "./app-project-cost-presets/app-project-cost-presets.component";
import { AppProjectCostsComponent } from "./app-project-costs/app-project-costs.component";
import { AppProjectFieldViewComponent } from "./app-project-field-view/app-project-field-view.component";
import { AppProjectFieldsComponent } from "./app-project-fields/app-project-fields.component";
import { AppServiceCategoriesComponent } from "./app-service-categories/app-service-categories.component";
import { AppSsoComponent } from "./app-sso/app-sso.component";
import { AppUsersComponent } from "./app-users/app-users.component";
import { AuditChecklistImagesComponent } from "./audit-checklist-images/audit-checklist-images.component";
import { AuditSheetPhasesComponent } from "./audit-sheet-phases/audit-sheet-phases.component";
import { AuditSheetReportComponent } from "./audit-sheet-report/audit-sheet-report.component";
import { AuditSheetTableComponent } from "./audit-sheet-table/audit-sheet-table.component";
import { AuditSheetComponent } from "./audit-sheet/audit-sheet.component";
import { AuthGuard } from "./auth.guard";
import { CargoOverviewComponent } from "./cargo-overview/cargo-overview.component";
import { CargoPrintComponent } from "./cargo-print/cargo-print.component";
import { CargoComponent } from "./cargo/cargo.component";
import { ConstructionPlanningAngular } from "./construction-planning/construction-planning.angular";
import { ContractsComponent } from "./contracts/contracts.component";
import { CustomerQuestionsAngular } from "./customer-questions/customer-questions.angular";
import { DeliveriesComponent } from "./deliveries/deliveries.component";
import { DeliveryListComponent } from "./delivery-list/delivery-list.component";
import { DocumentsComponent } from "./documents/documents.component";
import { EmployeesComponent } from "./employees/employees.component";
import { GreetingsComponent } from "./greetings/greetings.component";
import { HomeComponent } from "./home/home.component";
import { HourDeclarationsComponent } from "./hour-declarations/hour-declarations.component";
import { InvoiceCreatorComponent } from "./invoice-creator/invoice-creator.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { JobsComponent } from "./jobs/jobs.component";
import { LoadingDocumentComponent } from "./loading-document/loading-document.component";
import { LoginComponent } from "./login/login.component";
import { MailSchedulersComponent } from "./mail-schedulers/mail-schedulers.component";
import { MailTrafficAngular } from "./mail-traffic/mail-traffic.angular";
import { MasterComponent } from "./master/master.component";
import { MetacomInvoicePaymentBatchComponent } from "./metacom-invoice-payment-batch/metacom-invoice-payment-batch.component";
import { MetacomInvoiceComponent } from "./metacom-invoice/metacom-invoice.component";
import { MetacomPriceComponent } from "./metacom-price/metacom-price.component";
import { MetacomProjectAgreementComponent } from "./metacom-project-agreement/metacom-project-agreement.component";
import { MetacomProjectInvoicesComponent } from "./metacom-project-invoices/metacom-project-invoices.component";
import { MetacomProjectRelationsComponent } from "./metacom-project-relations/metacom-project-relations.component";
import { MetacomProjectComponent } from "./metacom-project/metacom-project.component";
import { MetacomRelationComponent } from "./metacom-relation/metacom-relation.component";
import { MetacomStaffComponent } from "./metacom-staff/metacom-staff.component";
import { NotificationComponent } from "./notification/notification.component";
import { OffersComponent } from "./offers/offers.component";
import { OrdersComponent } from "./orders/orders.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { PendingChangesGuard } from "./pending-changes.guard";
import { PicklistDefinitionsAngularComponent } from "./picklist-definitions/picklist-definitions-angular.component";
import { PicklistOverviewComponent } from "./picklist-overview/picklist-overview.component";
import { PicklistComponentAngular } from "./picklist/picklist-component.angular";
import { PrintLayoutComponent } from "./print-layout/print-layout.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ServiceSettingsComponent } from "./service-settings/service-settings.component";
import { ServiceComponent } from "./service/service.component";
import { ShopComponent } from "./shop/shop.component";
import { TableLayoutComponent } from "./table-layout/table-layout.component";
import { WorkActionTemplatesComponent } from "./work-action-templates/work-action-templates.component";
import { WorkActionsComponent } from "./work-actions/work-actions.component";
import { WorksOrderAngular } from "./work-orders/work-orders.angular";
import { YearPlanningProjectComponent } from "./year-planning-project/year-planning-project.component";
import { YearPlanningComponent } from "./year-planning/year-planning.component";

export const routes: Routes = [
  {
    path: "print",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [{ path: "cargo", component: CargoPrintComponent }],
  },
  {
    path: "pdf-viewer",
    component: PdfViewerComponent,
  },
  {
    path: "",
    component: MasterComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "notification/:id",
        data: { title: "Notificatie" },
        component: NotificationComponent,
      },
      {
        path: "app",
        children: [
          {
            path: "users",
            data: { title: "App Inloggegevens" },
            component: AppUsersComponent,
          },
          {
            path: "users/:projectId",
            data: { title: "App Inloggegevens" },
            component: AppUsersComponent,
          },
          {
            path: "sso",
            data: { title: "App Inloggen" },
            component: AppSsoComponent,
          },
          {
            path: "sso/:projectId",
            data: { title: "App Inloggen" },
            component: AppSsoComponent,
          },
          {
            path: "conversation-message-presets",
            data: { title: "App Chatberichten" },
            component: AppConversationMessagePresetsComponent,
          },
          {
            path: "document-types",
            data: { title: "App Documentsoorten" },
            component: AppDocumentTypesComponent,
          },
          {
            path: "project-cost-presets",
            data: { title: "App Meerwerk" },
            component: AppProjectCostPresetsComponent,
          },
          {
            path: "activities",
            data: { title: "App Activiteiten" },
            component: AppActivitiesComponent,
          },
          {
            path: "contents",
            data: { title: "App Informatie Pagina's" },
            component: AppContentsComponent,
          },
          {
            path: "service-categories",
            data: { title: "App Service Categorieën" },
            component: AppServiceCategoriesComponent,
          },
          {
            path: "notification-templates",
            data: { title: "App Notificaties" },
            component: AppNotificationTemplatesComponent,
          },
          {
            path: "project-fields",
            data: { title: "App Project Gegevens" },
            component: AppProjectFieldsComponent,
          },

          {
            path: "project-field-view/:projectId",
            data: { title: "App Project Gegevens" },
            component: AppProjectFieldViewComponent,
          },
          {
            path: "phases",
            data: { title: "App Fases" },
            component: AppPhasesComponent,
          },
          {
            path: "notification-schedules",
            data: { title: "App Notificatie Rooster" },
            component: AppNotificationSchedulesComponent,
          },
          {
            path: "notification-resources/:projectId",
            data: { title: "App Project Notificatie Rooster" },
            component: AppNotificationResourcesComponent,
          },
          {
            path: "content-preview/:id",
            data: { title: "App Informatie Pagina Preview" },
            component: AppContentPreviewComponent,
          },
          {
            path: "document-details/:id",
            data: { title: "App Document Details" },
            component: AppDocumentDetailsComponent,
          },
          {
            path: "project-costs/:projectId",
            data: { title: "App Project Financieel overzicht" },
            component: AppProjectCostsComponent,
          },
          {
            path: "partners",
            data: { title: "Partners" },
            loadChildren: () =>
              import("./app-partners/app-partners.module").then(
                (m) => m.AppPartnersModule
              ),
          },
        ],
      },
      {
        path: "planning",
        children: [
          {
            path: "year-planning",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "year-planning-project/:projectId",
            data: { title: "Jaarplanning snelkoppeling" },
            component: YearPlanningProjectComponent,
          },
          {
            path: "construction-planning",
            data: { title: "Planning Constructief" },
            component: ConstructionPlanningAngular,
          },
          {
            path: "year-planning/:mode/:weekFrom/:weekTo",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "year-planning/:mode/:weekFrom/:weekTo/:projectLeaderId",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "year-planning/:mode/:weekFrom/:weekTo/:projectLeaderId/:buyAdvisorId",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "year-planning/:mode/:weekFrom/:weekTo/:projectLeaderId/:buyAdvisorId/:salesEmployeeId",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "year-planning/:mode/:weekFrom/:weekTo/:projectLeaderId/:buyAdvisorId/:salesEmployeeId/:projectMentorId",
            data: { title: "Jaarplanning" },
            component: YearPlanningComponent,
          },
          {
            path: "drawers",
            loadChildren: () =>
              import("./drawers/drawers.module").then((m) => m.DrawersModule),
          },
          {
            path: "table-layout/walls",
            data: {
              title: "Prefabin planning wanden",
              settings: {
                supplierId: "950",
                costId: "75000",
                moldCode: "wanden",
              },
            },
            component: TableLayoutComponent,
          },
          {
            path: "table-layout/misc",
            data: {
              title: "Prefabin planning overige",
              settings: {
                supplierId: "950",
                costId: "75100",
                moldCode: "overige",
              },
            },
            component: TableLayoutComponent,
          },
          {
            path: "",
            loadChildren: () =>
              import("./planning/planning.module").then(
                (m) => m.PlanningModule
              ),
          },
        ],
      },
      {
        path: "accessibility",
        children: [
          {
            path: "users",
            data: { title: "Gebruikers" },
            component: AccessibilityUsersComponent,
          },
          {
            path: "roles",
            data: { title: "Rollen" },
            component: AccessibilityRolesComponent,
          },
        ],
      },
      {
        path: "jobs",
        data: { title: "Taken" },
        component: JobsComponent,
      },
      {
        path: "installations",
        children: [
          {
            path: "picklist-overview",
            data: {
              title: "Picklijsten",
              settings: {
                grantKey: "installations_picklist",
                mainRouteSegment: "installations",
              },
            },
            component: PicklistOverviewComponent,
          },
          {
            path: "picklist-overview/:definitionId/:dateFrom",
            data: {
              title: "Picklijsten",
              settings: {
                grantKey: "installations_picklist",
                mainRouteSegment: "installations",
              },
            },
            component: PicklistOverviewComponent,
          },
          {
            path: "picklist/:projectId/:picklistDefinitionId",
            data: { title: "Picklijst" },
            component: PicklistComponentAngular,
          },
          {
            path: "picklist/:projectId/:picklistDefinitionId/:documentId",
            data: { title: "Picklijst" },
            component: PicklistComponentAngular,
          },
          {
            path: "i-shop",
            data: {
              title: "Bestellijst",
              settings: {
                projectTableName: "projects",
                tableName: "GBI_bestellijst_Installaties",
                conditionFilter: false,
                sourceDocumentNumber: "Installaties",
              },
            },
            component: ShopComponent,
          },
        ],
      },
      {
        path: "supply",
        children: [
          {
            path: "picklist-overview",
            data: {
              title: "Picklijsten",
              settings: {
                grantKey: "picklist",
                mainRouteSegment: "supply",
              },
            },
            component: PicklistOverviewComponent,
          },
          {
            path: "picklist-overview/:definitionId/:dateFrom",
            data: {
              title: "Picklijsten",
              settings: {
                grantKey: "picklist",
                mainRouteSegment: "supply",
              },
            },
            component: PicklistOverviewComponent,
          },
          {
            path: "picklist/:projectId/:picklistDefinitionId",
            data: { title: "Picklijst" },
            component: PicklistComponentAngular,
          },
          {
            path: "picklist/:projectId/:picklistDefinitionId/:documentId",
            data: { title: "Picklijst" },
            component: PicklistComponentAngular,
          },
          {
            path: "cargo-overview",
            data: { title: "Vrachten" },
            component: CargoOverviewComponent,
          },
          {
            path: "cargo-overview/:week",
            data: { title: "Vrachten" },
            component: CargoOverviewComponent,
          },
          {
            path: "cargo/:projectId",
            data: { title: "Vracht" },
            component: CargoComponent,
          },
          {
            path: "orders",
            data: {
              title: "Bestellingen",
              settings: {
                companyId: 930,
                orderTableName: "GTL_bestellijst",
                stockTableName: "GTL_bestellijst_voorraad",
                deliveriesTableName: "GTL_leveringen",
                departmentSyncSource: "metacom.GTL_afdelingen",
                departmentPrefix: "MID",
              },
            },
            component: OrdersComponent,
          },
          {
            path: "deliveries",
            data: {
              title: "Leveringen",
              settings: {
                companyId: 930,
                deliveriesTableName: "GTL_leveringen",
              },
            },
            component: DeliveriesComponent,
          },
          {
            path: "inspection-questions",
            loadChildren: () =>
              import(
                "./supply-inspection-questions/SupplyInspectionQuestions.module"
              ).then((m) => m.SupplyInspectionQuestionsModule),
          },
          {
            path: "inspection-departments",
            loadChildren: () =>
              import(
                "./supply-inspection-departments/SupplyInspectionDepartments.module"
              ).then((m) => m.SupplyInspectionDepartmentsModule),
          },
          {
            path: "inspection-overview",
            loadChildren: () =>
              import(
                "./supply-inspection-overview/SupplyInspectionOverview.module"
              ).then((m) => m.SupplyInspectionOverviewModule),
          },
          {
            path: "inspection-report",
            loadChildren: () =>
              import(
                "./supply-inspection-report/SupplyInspectionReport.module"
              ).then((m) => m.SupplyInspectionReportModule),
          },
        ],
      },
      {
        path: "prefabin",
        children: [
          {
            path: "orders",
            data: {
              title: "Prefabin Bestellingen",
              settings: {
                companyId: 950,
                orderTableName: "GPB_bestellijst",
                stockTableName: "GPB_bestellijst_voorraad",
                deliveriesTableName: "GPB_leveringen",
                departmentSyncSource: "metacom.GPB_afdelingen",
                departmentPrefix: "MIDP",
              },
            },
            component: OrdersComponent,
          },
          {
            path: "deliveries",
            data: {
              title: "Prefabin Leveringen",
              settings: {
                companyId: 950,
                deliveriesTableName: "GPB_leveringen",
              },
            },
            component: DeliveriesComponent,
          },
          {
            path: "p-shop",
            data: {
              title: "Bestellijst",
              settings: {
                projectTableName: "projects",
                tableName: "GTL_bestellijst_Kampen",
                conditionFilter: true,
                documentType: "30-80",
                fixedProject: { id: "790", description: "Prefabin" },
                fixedAddressType: "prefabin",
                companyId: 930,
                sourceDocumentNumber: "Prefabin",
              },
            },
            component: ShopComponent,
          },
        ],
      },
      {
        path: "office",
        children: [
          {
            path: "mail-traffic/:projectId",
            data: { title: "Mail correspondentie" },
            component: MailTrafficAngular,
          },
          {
            path: "metacom-project-list",
            data: { title: "Projecten" },
            component: MetacomProjectComponent,
          },
          {
            path: "metacom-price",
            data: { title: "Prijsafspraken" },
            component: MetacomPriceComponent,
          },
          {
            path: "metacom-invoice",
            data: { title: "Facturen" },
            component: MetacomInvoiceComponent,
          },
          {
            path: "metacom-payments",
            data: { title: "Factuur betaalbatches" },
            component: MetacomInvoicePaymentBatchComponent,
          },
          {
            path: "metacom-relation",
            data: { title: "Relaties" },
            component: MetacomRelationComponent,
          },
          {
            path: "metacom-staff",
            data: { title: "Personeel" },
            component: MetacomStaffComponent,
          },

          {
            path: "metacom-project-invoices/:projectId",
            data: { title: "Facturen Project" },
            component: MetacomProjectInvoicesComponent,
          },
          {
            path: "metacom-project-agreement/:projectId",
            data: { title: "Projectafspraken" },
            component: MetacomProjectAgreementComponent,
          },
          {
            path: "metacom-project-relations/:projectId",
            component: MetacomProjectRelationsComponent,
          },
          {
            path: "documents/:projectId",
            data: { title: "Documenten" },
            component: DocumentsComponent,
          },
          {
            path: "documents/:projectId/:segment",
            data: { title: "Documenten" },
            component: DocumentsComponent,
          },
          {
            path: "offers",
            data: { title: "Offertes" },
            component: OffersComponent,
          },
          {
            path: "contracts",
            data: { title: "Contracten" },
            component: ContractsComponent,
          },
          {
            path: "work-actions",
            data: { title: "Actielijst" },
            component: WorkActionsComponent,
          },
          {
            path: "work-actions/expired",
            data: {
              title: "Actielijst",
              showOnlyExpired: true,
            },
            component: WorkActionsComponent,
          },
          {
            path: "work-actions/:id",
            data: { title: "Actielijst" },
            component: WorkActionsComponent,
          },
          {
            path: "invoices",
            data: { title: "Facturen controleren" },
            component: InvoicesComponent,
          },
          {
            path: "invoice-creator",
            data: { title: "Facturen maken" },
            component: InvoiceCreatorComponent,
          },
          {
            path: "work-orders",
            data: { title: "Werkopdrachten" },
            component: WorksOrderAngular,
          },
          {
            path: "work-orders/:projectId",
            data: { title: "Werkopdrachten" },
            component: WorksOrderAngular,
          },
          {
            path: "work-orders/:projectId/:relationId",
            data: { title: "Werkopdrachten" },
            component: WorksOrderAngular,
          },
          {
            path: "hour-declarations",
            data: { title: "Urenregistratie", mode: "declare" },
            component: HourDeclarationsComponent,
          },
          {
            path: "hour-declarations/:userId/manage",
            data: { title: "Urenregistratie (beheren)", mode: "manage" },
            component: HourDeclarationsComponent,
          },
          {
            path: "hour-declarations/:userId/:week",
            data: { title: "Urenregistratie (goedkeuren)", mode: "judge" },
            component: HourDeclarationsComponent,
          },
        ],
      },
      {
        path: "service",
        children: [
          {
            path: "overview",
            data: { title: "Service" },
            component: ServiceComponent,
          },
          {
            path: "overview/:projectId",
            data: { title: "Service" },
            component: ServiceComponent,
          },
          {
            path: "delivery-list/:projectId",
            data: { title: "Opleverlijst" },
            component: DeliveryListComponent,
          },
          {
            path: "quality-assurance",
            data: { title: "WKB" },
            loadChildren: () =>
              import("./quality-assurance/quality-assurance.module").then(
                (m) => m.QualityAssuranceModule
              ),
          },
          {
            path: "maintenance-report-overview/:projectId",
            data: { title: "Onderhoudschecks" },
            loadChildren: () =>
              import(
                "./maintenance-report-overview/maintenance-report-overview.module"
              ).then((m) => m.MaintenanceReportOverviewModule),
          },
          {
            path: "maintenance-report",
            data: { title: "Onderhoudscheck" },
            loadChildren: () =>
              import("./maintenance-report/maintenance-report.module").then(
                (m) => m.MaintenanceReportModule
              ),
          },
          {
            path: "customer-questions",
            data: { title: "Klant Meldingen" },
            component: CustomerQuestionsAngular,
          },
          {
            path: "planning",
            data: { title: "Service Planning" },
            loadChildren: () =>
              import("./service-planning/service-planning.module").then(
                (m) => m.ServicePlanningModule
              ),
          },
          {
            path: "s-shop",
            data: {
              title: "Bestellijst",
              settings: {
                projectTableName: "legacy_projects",
                tableName: "GTL_bestellijst_service",
                projectIdPrefix: "S",
                conditionFilter: false,
                sourceDocumentNumber: "Service",
              },
            },
            component: ShopComponent,
          },
        ],
      },
      {
        path: "construction",
        children: [
          {
            path: "audit-sheet",
            data: { title: "WPI beheer" },
            component: AuditSheetComponent,
          },
          {
            path: "audit-sheet-table",
            data: { title: "WPI configuratie" },
            component: AuditSheetTableComponent,
          },
          {
            path: "audit-sheet-phases/:projectId",
            data: { title: "Bouwfases" },
            component: AuditSheetPhasesComponent,
          },
          {
            path: "audit-sheet-report/:projectId/:costCategoryId",
            data: { title: "WPI formulier" },
            canDeactivate: [PendingChangesGuard],
            component: AuditSheetReportComponent,
          },
          {
            path: "audit-checklist-images/:projectId/:costCategoryId",
            data: { title: "Checklist afbeeldingen" },
            component: AuditChecklistImagesComponent,
          },
          {
            path: "c-shop",
            data: {
              title: "Bestellijst",
              settings: {
                projectTableName: "projects",
                tableName: "GTL_bestellijst_derden",
                conditionFilter: true,
                sourceDocumentNumber: "Bouw",
              },
            },
            component: ShopComponent,
          },
          {
            path: "employees",
            data: { title: "Personeel" },
            component: EmployeesComponent,
          },
          {
            path: "employees/:relationId",
            data: { title: "Personeel" },
            component: EmployeesComponent,
          },
          {
            path: "installation-report/:projectId",
            data: { title: "Inregelrapport" },
            loadChildren: () =>
              import("./installation-report/installation-report.module").then(
                (m) => m.InstallationReportModule
              ),
          },
        ],
      },
      {
        path: "system",
        children: [
          {
            path: "greetings",
            data: { title: "Homepage teksten" },
            component: GreetingsComponent,
          },
          {
            path: "mail-schedulers",
            data: { title: "Mailing" },
            component: MailSchedulersComponent,
          },
          {
            path: "work-action-templates",
            data: { title: "Actielijst templates" },
            component: WorkActionTemplatesComponent,
          },
          {
            path: "service-settings",
            data: { title: "Service Instellingen" },
            component: ServiceSettingsComponent,
          },
          {
            path: "installation-report-settings",
            data: { title: "Inregelrapport Instellingen" },
            loadChildren: () =>
              import(
                "./installation-report-settings/installation-report-settings.module"
              ).then((m) => m.InstallationReportSettingsModule),
          },
          {
            path: "picklist-definitions",
            data: { title: "Picklijst Definities" },
            component: PicklistDefinitionsAngularComponent,
          },
          {
            path: "draw-order-descriptions",
            data: { title: "Tekenaars standaard omschrijvingen" },
            loadChildren: () =>
              import(
                "./draw-order-descriptions/draw-order-descriptions.module"
              ).then((m) => m.DrawOrderDescriptionsModule),
          },
          {
            path: "drawer-presets",
            data: { title: "Tekenkamerplanning presets" },
            loadChildren: () =>
              import("./drawer-presets/drawer-presets.module").then(
                (m) => m.DrawerPresetsModule
              ),
          },
          {
            path: "maintenance-presets",
            data: { title: "Onderhoud Instellingen" },
            loadChildren: () =>
              import("./maintenance-presets/maintenance-presets.module").then(
                (m) => m.MaintenancePresetsModule
              ),
          },
          {
            path: "quality-assurance-presets",
            data: { title: "WKB Instellingen" },
            loadChildren: () =>
              import(
                "./quality-assurance-presets/quality-assurance-presets.module"
              ).then((m) => m.QualityAssurancePresetsModule),
          },

          {
            path: "shop-presets",
            data: { title: "Bestellijst Instellingen" },
            loadChildren: () =>
              import("./shop-presets/shop-presets.module").then(
                (m) => m.ShopPresetsModule
              ),
          },
        ],
      },
    ],
  },
  { path: "login", component: LoginComponent },
  { path: "reset-password/:id", component: ResetPasswordComponent },
  { path: "loading-document", component: LoadingDocumentComponent },
  { path: "**", redirectTo: "" },
];
