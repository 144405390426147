import * as React from "react";
import { useState } from "react";
import { CustomerQuestionDto } from "./customer-question.dto";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ServiceWizardComponent } from "../service/service-wizard.component";
import { Injector } from "@angular/core";
import * as moment from "moment";
import { AvatarGroup } from "@material-ui/lab";
import { SecureImage } from "../secured-image/secure-image";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ImageViewerComponent } from "../image-viewer/image-viewer.component";
import { Replay as ReplayIcon } from "@material-ui/icons";
import { useMuiPortalTheme } from "../react-component/material.hooks";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";

enum CustomerQuestionsDialogViewMode {
  Details,
  ComposeTicket,
}

export enum CustomerQuestionsDialogCloseAction {
  Dismissed,
  CreatedTicket,
  MarkedReady,
  InternalComment,
  Reopen,
}

export interface CustomerQuestionsDialogCloseActionPayload {
  comment?: string;
  internalComment?: string;
}

interface CustomerQuestionsDialogProps {
  injector: Injector;
  question: CustomerQuestionDto;
  onClosed: (
    action: CustomerQuestionsDialogCloseAction,
    payload?: CustomerQuestionsDialogCloseActionPayload
  ) => void;
}

export function CustomerQuestionsDialog(props: CustomerQuestionsDialogProps) {
  const http = props.injector.get(HttpClient);
  const [viewMode, setViewMode] = useState(
    CustomerQuestionsDialogViewMode.Details
  );
  const [imageViewer, setImageViewer] = useState(false);
  const [internalComment, setInternalComment] = useState(
    props.question.internalComment || ""
  );

  const theme = useMuiPortalTheme();

  return (
    <PortalThemeProvider>
      {imageViewer && (
        <ImageViewerComponent
          http={http}
          onCloseRequest={() => setImageViewer(false)}
          images={props.question.imageIds.map((_imageId) => ({
            src: `${environment.baseUrl}/documents/resize/${_imageId}/1024`,
            isSecure: true,
          }))}
        />
      )}

      {viewMode === CustomerQuestionsDialogViewMode.Details && !imageViewer && (
        <Dialog fullWidth={true} maxWidth={"lg"} open={true} onClose={() => {}}>
          <DialogTitle>
            Melding: {props.question.customerName}
            {props.question.completedAt && (
              <Tooltip title="Melding heropenen">
                <IconButton
                  style={{
                    position: "absolute",
                    right: theme.spacing(1),
                    top: theme.spacing(1),
                    color: theme.palette.grey[500],
                  }}
                  onClick={() =>
                    props.onClosed(CustomerQuestionsDialogCloseAction.Reopen)
                  }
                >
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            )}
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <Divider />
            <Grid container>
              <Grid item xs={6} style={{ borderRight: "1px solid #eeeeee" }}>
                <List style={{ padding: 0 }}>
                  <ListItem>
                    <ListItemText
                      primary="Datum/Tijd"
                      secondary={moment(props.question.createdAt).format("L")}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Project"
                      secondary={props.question.projectId || "-"}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Naam"
                      secondary={props.question.customerName}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Plaats"
                      secondary={props.question.city}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Telefoon"
                      secondary={props.question.telephone}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    onClick={
                      props.question.emailAddress
                        ? () =>
                            (window.location.href = `mailto:${props.question.emailAddress}`)
                        : () => {}
                    }
                  >
                    <ListItemText
                      primary="E-mailadres"
                      secondary={props.question.emailAddress || "-"}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary="Ruimte"
                      secondary={props.question.space || "-"}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <List style={{ padding: 0 }}>
                  <List style={{ padding: 0, marginTop: 15 }}>
                    <ListItem>
                      <ListItemText
                        primary="Omschrijving"
                        secondary={props.question.message}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary="Interne opmerking"
                        secondary="Opmerking voor interne doeleinden. Niet zichtbaar voor de klant."
                      />
                    </ListItem>

                    <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                      <TextField
                        disabled={!!props.question.completedAt}
                        multiline
                        style={{ width: "100%" }}
                        variant="outlined"
                        label="Opmerking"
                        value={internalComment}
                        onChange={(event) =>
                          setInternalComment(event.target.value)
                        }
                      />
                    </div>
                  </List>

                  {props.question.imageIds &&
                    props.question.imageIds.length > 0 && (
                      <>
                        <ListItem style={{ marginTop: 15 }}>
                          <Typography>Afbeeldingen</Typography>
                        </ListItem>
                        <ListItem
                          button={true}
                          onClick={() => setImageViewer(true)}
                        >
                          <AvatarGroup max={6}>
                            {(props.question.imageIds || []).map((_imageId) => (
                              <Avatar
                                variant={"square"}
                                key={`cq-image-${_imageId}`}
                              >
                                <SecureImage
                                  noDebounce
                                  style={{
                                    width: 36,
                                    height: 36,
                                  }}
                                  src={`${environment.baseUrl}/documents/resize/${_imageId}/36`}
                                  http={http}
                                />
                              </Avatar>
                            ))}
                          </AvatarGroup>
                        </ListItem>
                      </>
                    )}
                </List>
              </Grid>
            </Grid>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                props.onClosed(CustomerQuestionsDialogCloseAction.Dismissed)
              }
              color="default"
            >
              Terug
            </Button>

            <Button
              disabled={!!props.question.completedAt}
              onClick={() =>
                props.onClosed(
                  CustomerQuestionsDialogCloseAction.InternalComment,
                  {
                    internalComment,
                  }
                )
              }
              color="primary"
            >
              Opslaan
            </Button>

            <Button
              disabled={!!props.question.completedAt}
              onClick={() =>
                setViewMode(CustomerQuestionsDialogViewMode.ComposeTicket)
              }
              color="primary"
            >
              Service melding
            </Button>
            <Button
              disabled={!!props.question.completedAt}
              onClick={() =>
                props.onClosed(CustomerQuestionsDialogCloseAction.MarkedReady, {
                  internalComment,
                })
              }
              color="primary"
            >
              Gereed
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {viewMode === CustomerQuestionsDialogViewMode.ComposeTicket && (
        <ServiceWizardComponent
          fillIn={{
            projectId: props.question.projectId,
            description: props.question.message,
            space: props.question.space,
            internalMemo: props.question.internalComment,
            images: (props.question.imageIds || []).map((_imageId) => ({
              documentMetaId: _imageId,
            })),
          }}
          noImageEdit
          injector={props.injector}
          onClosed={(ticket) => {
            setViewMode(CustomerQuestionsDialogViewMode.Details);

            if (ticket) {
              props.onClosed(CustomerQuestionsDialogCloseAction.CreatedTicket, {
                internalComment,
              });
            }
          }}
        />
      )}
    </PortalThemeProvider>
  );
}
