import { Apollo } from "apollo-angular";
import { DocumentMeta } from "src/app/documents/document-meta.entity";
import { EntityManager, Ops } from "src/app/entity.service";
import { Bloc } from "src/app/service-settings/bloc/bloc";
import { DeliveryListSignatureEntity } from "../../delivery-list.entity";
import {
  SignatureEvent,
  SignatureLoadRequestEvent,
  SignatureStrokeEndEvent,
} from "./signature.event";
import { uploadDocumentMutation } from "./signature.mutation";
import {
  SignatureState,
  SignatureLoadInProgressState,
  SignatureLoadFailureState,
  SignatureLoadSuccessState,
} from "./signature.state";

export class SignatureBloc extends Bloc<SignatureState, SignatureEvent> {
  constructor(readonly apollo: Apollo, readonly entities: EntityManager) {
    super(new SignatureLoadInProgressState());
  }

  async *mapEventToState(event: SignatureEvent) {
    if (event instanceof SignatureLoadRequestEvent) {
      const repo = this.entities.get(DeliveryListSignatureEntity);
      const response = await repo.query({
        filters: [Ops.Field("projectId").Equals(event.props.projectId)],
      });

      if (!response.hasError()) {
        const signatureEmployee =
          response.value.find((sig) => sig.kind === "employee") ||
          repo.concept({ projectId: event.props.projectId, kind: "employee" });

        const signatureCustomer =
          response.value.find((sig) => sig.kind === "customer") ||
          repo.concept({ projectId: event.props.projectId, kind: "customer" });

        yield new SignatureLoadSuccessState({
          signatureEmployee,
          signatureCustomer,
        });
      } else {
        yield new SignatureLoadFailureState({
          message: response.error.message,
        });
      }
    }

    if (event instanceof SignatureStrokeEndEvent) {
      const signature = event.props.signature;
      const signatureDataUri = event.props.canvas.toDataURL("image/png");
      const signatureData = this.dataURItoBlob(signatureDataUri, "image/png");

      const { data } = await this.apollo
        .mutate<{ document: DocumentMeta }>({
          mutation: uploadDocumentMutation,
          variables: {
            id: event.props.signature.documentMetaId,
            file: signatureData,
            projectId: signature.projectId,
          },
          context: {
            useMultipart: true,
          },
        })
        .toPromise();

      const { document } = data;

      if (document && document.id) {
        signature.documentMeta = document;
        signature.documentMetaId = document.id;
        signature.imageHash = Math.random().toString();

        const repo = this.entities.get(DeliveryListSignatureEntity);
        const response = await repo.save(signature);

        if (!response.hasError()) {
          const current = this.state.value as SignatureLoadSuccessState;

          yield new SignatureLoadSuccessState({
            signatureEmployee: signature.kind === 'employee' ? response.value : current.props.signatureEmployee,
            signatureCustomer: signature.kind === 'customer' ? response.value : current.props.signatureCustomer,
          });
        }
      }
    }
  }

  protected dataURItoBlob(dataURI, type: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;

    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], "signature.png", { type });
  }
}
