import { Equatable } from '@comceptum-software/react-bloc';
import { PicklistDefinitionDto } from './picklist-definition.dto';

export abstract class PicklistDefinitionsEvent extends Equatable {
}

export class PicklistDefinitionsLoadRequestEvent extends PicklistDefinitionsEvent {
  props = () => [];
}

export class PicklistDefinitionsAddRequestEvent extends PicklistDefinitionsEvent {
  props = () => [];
}

export class PicklistDefinitionsStoreRequestEvent extends PicklistDefinitionsEvent {
  readonly model: PicklistDefinitionDto;

  constructor(props: {
    readonly  model: PicklistDefinitionDto
  }) {
    super(props);
  }

  props = () => [this.model];
}

export class PicklistDefinitionsDeleteRequestEvent extends PicklistDefinitionsEvent {
  readonly model: PicklistDefinitionDto;

  constructor(props: {
    readonly  model: PicklistDefinitionDto
  }) {
    super(props);
  }

  props = () => [this.model];
}

