import gql from "graphql-tag";

export const picklistDefinitionsOverviewQuery = gql`
  query picklistDefinitionsOverviewQuery {
    items: PicklistDefinitionEntityFindMany(
      query: { filters: [], orders: [{ field: "createdAt", direction: "ASC" }] }
    ) {
      id
      name
      icon
      hideColumns
      competenceNumber
      elementTypes
      indeterminateToggles
      metacomListTable
      metacomLinesTable
      renameFilterName
      dateSource
      createdAt
      updatedAt
    }
  }
`;
