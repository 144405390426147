import { ProjectHousePart } from "./../cargo/house-part.entity";
import { MetacomService } from "./../metacom.service";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { EntityManager, EntityQueryFilter, Ops } from "../entity.service";
import {
  Cargo,
  HousePartGroup,
  CargoType,
  CargoState,
  cargoRoutes,
} from "../cargo/house-part.entity";
import { RestResponse } from "../rest.service";
import { MdcMenu } from "@angular-mdc/web";
import { Router, ActivatedRoute } from "@angular/router";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { PicklistDefinition } from "../picklist/picklist.entity";
import { GrantService } from "../grant.service";
import { User } from "../accessibility-users/user.entity";
import { chain, first } from "lodash";
import { EntitySelectConfig } from "../entity-select/entity-select.component";
import { CargoFunctions } from "../cargo/cargo.functions";
import { DialogService } from "../dialog.service";
import {
  CargoNotesDialogComponent,
  CargoNotesDialogComponentData,
} from "../cargo-notes-dialog/cargo-notes-dialog.component";
import { CustomShortcut } from "../project-shortcuts/project-shortcuts.component";
import { StorageObject, StorageService } from "../storage.service";
import { SelectDialogComponent } from "../select-dialog/select-dialog.component";
import { YearPlanningLineCalculator } from "../year-planning-lines-dialog/year-planning-line.calculator";
import * as moment from "moment";
import * as _ from "lodash";

@Component({
  selector: "app-cargo-overview",
  templateUrl: "./cargo-overview.component.html",
  styleUrls: ["./cargo-overview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoOverviewComponent implements OnInit {
  AFTER_DELIVERY_ID = "nalevering";
  AFTER_DELIVERY_CARGO_TYPE_ID = "9210003";

  dates: DateContainer[];
  picklistDefinitions: PicklistDefinition[];

  transporterId: string;
  transporters: RestResponse<User[]>;
  queryStorage: CargoQueryStorage;

  routeIds: string[] = [];
  routeIdsLabel: string;

  transporterConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "Transporteur",
    icon: "local_shipping",
    entityName: "",
    nameField: "name",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name"],
    fixedResponse: [],
  };

  customShortcuts: CustomShortcut[] = [
    new CustomShortcut("notes", "description", "Vracht notities"),
  ];

  get startOf() {
    return this.cursorDate.startOf("isoWeek").toDate();
  }

  get endOf() {
    return this.cursorDate.endOf("isoWeek").toDate();
  }

  get allowProjectFilter() {
    return (
      this.grantService.var(grants.cargo_overview.filter_projects, "false") ===
      "true"
    );
  }

  get allowPicking() {
    return this.grantService.varIs(grants.cargo_overview.allow_picking, "true");
  }

  get cursorDate() {
    return moment(this.week, YearPlanningLineCalculator.WEEK_FORMAT).clone();
  }

  get routes() {
    return cargoRoutes;
  }

  protected get cargoService() {
    return this.entityManager.get(Cargo);
  }

  protected get cargoTypeService() {
    return this.entityManager.get(CargoType);
  }

  protected get cargoStateService() {
    return this.entityManager.get(CargoState);
  }

  protected get picklistDefinitionService() {
    return this.entityManager.get(PicklistDefinition);
  }

  protected get week() {
    return (
      this.route.snapshot.paramMap.get("week") ||
      moment().format(YearPlanningLineCalculator.WEEK_FORMAT)
    );
  }

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly dialogService: DialogService,
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly storageService: StorageService,
    protected readonly metacom: MetacomService
  ) {
    changeDetector.detach();

    route.params.subscribe(() => this.fetchAll());
  }

  today = () => this.setDate();
  increment = () => this.setDate(this.cursorDate.add(1, "w"));
  decrement = () => this.setDate(this.cursorDate.add(-1, "w"));

  focusDay(day: DateContainer) {
    this.dates.forEach((d) => (d.isHidden = !!day && !(d === day)));
    this.dates.forEach((d) => (d.isFocussed = d === day));

    this.changeDetector.detectChanges();
  }

  async ngOnInit() {
    this.queryStorage = this.storageService.make(CargoQueryStorage);

    if (this.queryStorage.value) {
      this.setRoutes(this.queryStorage.value.routeIds || []);
      this.transporterId = this.queryStorage.value.transporterId;
    }
  }

  async fetchAll() {
    await this.fetchPicklistDefinitions();
    await this.fetchTransporters();
    await this.fetchAsync();
  }

  async pickRoutes() {
    const items = this.routes.map((route) => ({
      id: route.id,
      isChecked: this.routeIds.indexOf(route.id) >= 0,
      description: route.label,
    }));

    await this.dialogService.open(this.changeDetector, SelectDialogComponent, {
      data: {
        isMultiple: true,
        items,
      },
    });

    const selectedRoutes = items
      .filter((route) => route.isChecked)
      .map((route) => route.id);

    this.setRoutes(selectedRoutes);

    await this.fetchAll();
  }

  clickCargo(cargo: Cargo, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    cargo.isExpanded = !cargo.isExpanded;

    this.changeDetector.detectChanges();
  }

  clickGroup(group: HousePartGroup, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    group.isExpanded = !group.isExpanded;

    this.changeDetector.detectChanges();
  }

  groupWidth = CargoFunctions.groupWidth;
  groupLength = CargoFunctions.groupLength;
  groupWeight = CargoFunctions.groupWeight;

  isCargoPicked(cargo: Cargo) {
    return cargo.elementGroups.every((r) => r.state.value === "picked");
  }

  openMenu(menu: MdcMenu, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    menu.open = !menu.open;
  }

  onCustomShortcut(shortcut: { id: string }, cargo: Cargo) {
    if (shortcut.id === "notes") {
      this.dialogService.open(this.changeDetector, CargoNotesDialogComponent, {
        data: new CargoNotesDialogComponentData(cargo),
      });
    }
  }

  isCargoIndeterminate(cargo: Cargo) {
    return (
      !this.isCargoPicked(cargo) &&
      !!cargo.elementGroups.every((r) => r.state.value !== null)
    );
  }

  async pickGroup(cargo: Cargo, group: HousePartGroup) {
    switch (group.state.value) {
      case null:
        group.state.value = "indeterminate";
        break;
      case "indeterminate":
        group.state.value = "picked";
        break;
      case "picked":
        group.state.value = null;
        break;
    }

    group.isPicked = group.state.value === "picked";

    await this.cargoStateService.save(group.state);

    this.changeDetector.detectChanges();
  }

  protected setRoutes(ids: string[]) {
    const routes = this.routes.filter((e) => ids.indexOf(e.id) >= 0);

    this.routeIds = routes.map((e) => e.id);

    if (routes.length === 0) {
      this.routeIdsLabel = "Kies";
    } else if (routes.length === this.routes.length) {
      this.routeIdsLabel = "Alle";
    } else {
      this.routeIdsLabel = routes.map((e) => e.label).join(", ");
    }
  }

  protected async setDate(date: moment.Moment = moment()) {
    return await this.router.navigate([
      "supply/cargo-overview",
      date.format(YearPlanningLineCalculator.WEEK_FORMAT),
    ]);
  }

  protected forDate(date: Date, response: RestResponse<Cargo[]>) {
    const frmt = "MM.DD.YYYY";
    const cmpr = moment(date).format(frmt);

    const cargos = response.value.filter(
      (i) => moment(i.dateAt).format(frmt) === cmpr
    );

    cargos.forEach((c) => (c.elementGroups = this.elementGroups(c)));

    return _.orderBy(cargos, (c) => c.timeAt);
  }

  protected isToday(date: Date) {
    const frmt = "MM.DD.YYYY";
    const cmpr = moment(date).format(frmt);

    return moment().format(frmt) === cmpr;
  }

  protected createWeekDates() {
    return Array.apply(null, Array(5)).map((v: unknown, i: number) =>
      moment(this.startOf).add(i, "d")
    );
  }

  protected dateDiff(date: Date) {
    const from = moment(date).startOf("day");

    return Math.abs(
      moment.duration(moment().startOf("day").diff(from)).asDays()
    ).toFixed(0);
  }

  protected elementGroups(cargo: Cargo) {
    return _.chain(cargo.__projectHouseParts__)
      .filter((p) => !!p.__housePart__)
      .forEach(
        (item) => (item.length = item.__housePart__.length || item.length)
      )
      .forEach((item) => (item.width = item.__housePart__.width || item.width))
      .groupBy(
        (part) =>
          `${part.groupDivisionId}_${part.__housePart__.housePartGroupId}`
      )
      .map((items) => {
        const firstItem = first(items);
        const copy: HousePartGroup = Object.assign(
          {},
          firstItem.__housePart__.__housePartGroup__
        );
        copy.divisionId = firstItem.groupDivisionId
          ? parseFloat(firstItem.groupDivisionId)
          : null;
        copy.elements = items;
        copy.state =
          cargo.__cargoStates__.find(
            (state) =>
              state.housePartGroupId === copy.id &&
              state.divisionId === `${copy.divisionId}`
          ) ||
          this.cargoStateService.concept({
            cargoId: cargo.id,
            housePartGroupId: copy.id,
            divisionId: `${copy.divisionId}`,
            value: null,
          });
        copy.isPicked = copy.state.value === "picked";
        copy.highestLength = this.groupLength(copy);
        copy.highestWidth = this.groupWidth(copy);

        return copy;
      })
      .orderBy([(g) => !isNaN(parseFloat(g.id)), (g) => g.id], ["asc", "asc"])
      .value();
  }

  async storeAfterDeliveries() {
    const isRequested =
      this.routeIds.length > 0
        ? this.routeIds.includes(this.AFTER_DELIVERY_ID)
        : true;

    const METACOM_DATE_FILTER_FORMAT = "YYYYMMDD";

    if (isRequested) {
      const response = await this.metacom.queryTableAsync<any>({
        setName: "metacom",
        tableName: "picklijst_werkbouw",
        filter: `reg_mutatie.aanvang >= "${moment(this.startOf).format(
          METACOM_DATE_FILTER_FORMAT
        )}" AND reg_mutatie.aanvang <= "${moment(this.endOf).format(
          METACOM_DATE_FILTER_FORMAT
        )}"`,
      });

      const metacomIds = response.value.map(
        (item) => `${this.AFTER_DELIVERY_ID}_${item.prj}_${item.docnr}`
      );

      const afterDeliveriesToDelete =
        metacomIds.length > 0
          ? (
              await this.cargoService.query({
                filters: [
                  Ops.Field("routeId").Equals(this.AFTER_DELIVERY_ID),
                  this.createRangeFilter(),
                ],
              })
            ).value
              .filter((cargo) =>
                (cargo.id as string).startsWith(`${this.AFTER_DELIVERY_ID}_`)
              )
              .filter((cargo) => metacomIds.indexOf(cargo.id) === -1)
          : [];

      afterDeliveriesToDelete?.length &&
        (await Promise.all(
          afterDeliveriesToDelete.map((item) =>
            this.cargoService.delete(item.id)
          )
        ));

      const afterDeliveries =
        (!response?.hasError() &&
          response.value.map((item) =>
            this.cargoService.concept({
              id: `${this.AFTER_DELIVERY_ID}_${item.prj}_${item.docnr}`,
              projectId: item.prj,
              routeId: this.AFTER_DELIVERY_ID,
              dateAt: moment(item.aanvang).toDate(),
              timeAt: "NA",
              description: item.docnr,
              cargoTypeId: this.AFTER_DELIVERY_CARGO_TYPE_ID,
            })
          )) ??
        [];

      await this.cargoService.saveMany(afterDeliveries);
    }
  }

  createRangeFilter() {
    return {
      field: "dateAt",
      operator: "Between",
      value: [this.startOf, this.endOf],
    };
  }

  async fetchAsync() {
    this.dates = null;
    this.changeDetector.detectChanges();

    await this.storeAfterDeliveries();

    this.queryStorage.value = {
      routeIds: this.routeIds,
      transporterId: this.transporterId,
    };

    const filters: EntityQueryFilter[] = [this.createRangeFilter()];

    if (this.routeIds.length > 0) {
      filters.push({
        field: "routeId",
        operator: "In",
        value: this.routeIds,
      });
    }

    const responseWithoutFilter = await this.cargoService.query({
      filters,
      relations: [
        "project",
        "cargoType",
        "cargoStates",
        "competence",
        "phase",
        "projectHouseParts",
        "projectHouseParts.housePart",
        "projectHouseParts.housePart.housePartGroup",
      ],
      orders: [{ field: "timeAt", direction: "ASC" }],
    });

    if (!responseWithoutFilter.hasError()) {
      const response = new RestResponse(
        this.extendAfterDeliveries(responseWithoutFilter.value).filter(
          (cargo) =>
            this.transporterId
              ? cargo.__cargoType__.userId === this.transporterId
              : true
        )
      );

      const dates = [];

      for (const date of this.createWeekDates()) {
        dates.push({ date, cargo: this.forDate(date, response) });
      }

      const nearest = _.first(_.orderBy(dates, (d) => this.dateDiff(d.date)));

      if (nearest) {
        nearest.isFocus = true;
      }

      this.dates = dates;

      this.changeDetector.detectChanges();
    }
  }

  protected extendAfterDeliveries(cargos: Cargo[]) {
    const afterDeliveries = cargos.filter(
      (cargo) => cargo.routeId == this.AFTER_DELIVERY_ID
    );

    afterDeliveries.map((extra) => {
      Object.assign(extra, {
        __projectHouseParts__: [
          this.entityManager.get(ProjectHousePart).concept({
            partCodeId: "N01",
            quantity: "1",
            unit: "unit",
            __housePart__: {
              description: "Na-levering",
              housePartGroupId: "N01",
              divisionId: "",
              __housePartGroup__: {
                id: "N01",
                name: "Na-levering",
              },
            },
          }),
        ],
        __phase__: {
          description: "Na-levering",
        },
      });

      return extra;
    });

    return cargos;
  }

  protected async fetchPicklistDefinitions() {
    const response = await this.picklistDefinitionService.query();

    if (!response.hasError()) {
      this.picklistDefinitions = response.value;
    }
  }

  protected async fetchTransporters() {
    const response = await this.cargoTypeService.query({
      relations: ["user"],
    });

    if (!response.hasError()) {
      this.transporters = new RestResponse(
        chain(response.value)
          .uniqBy((c) => c.userId)
          .map((item) => item.__user__)
          .value()
      );
      this.transporterConfig.fixedResponse = this.transporters.value;
    }
  }

  routeLabel(id: string) {
    return id ? cargoRoutes.find((r) => r.id == id).label : "";
  }
}

class DateContainer {
  date: Date;
  cargo: Cargo[];

  isHidden: boolean;
  isFocussed: boolean;
}

class CargoQueryStorage extends StorageObject<CargoQuery> {
  key = "cargo-overview-query-storage";
  defaultValue = {};
}

interface CargoQuery {
  routeIds?: string[];
  transporterId?: string;
}
