import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Apollo } from "apollo-angular";
import { AuthService } from "../auth.service";
import { EntityManager } from "../entity.service";
import { FileUploadService } from "../file-upload.service";
import { ReactComponent } from "../react-component/react.component";
import { RestService } from "../rest.service";
import { ServiceTransmitter } from "../service/service.transmitter";
import { ReactDeliveryListComponent } from "./react/react-delivery-list.component";

@Component({
  selector: "app-delivery-list",
  templateUrl: "./delivery-list.component.html",
  styleUrls: ["./delivery-list.component.scss"],
})
export class DeliveryListComponent extends ReactComponent {
  containerId = "react-component--delivery-list";
  component = ReactDeliveryListComponent;

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  constructor(
    readonly auth: AuthService,
    readonly apollo: Apollo,
    readonly http: HttpClient,
    readonly rest: RestService,
    readonly entities: EntityManager,
    readonly serviceTransmitter: ServiceTransmitter,
    readonly fileUploads: FileUploadService,
    protected readonly route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
