import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MdcTab, MdcSnackbar } from "@angular-mdc/web";
import { AppDocument } from "../documents/app-document.entity";
import { UrlOpenService } from "../url-open.service";
import { DialogService } from "../dialog.service";
import { AppService } from "../app.service";
import { AppRestService } from "../app-rest.service";
import { EntityManager, Ops } from "../entity.service";
import {
  AppDocumentSignatureWizardDialogComponent,
  AppDocumentSignatureWizardDialogComponentData,
} from "../app-document-signature-wizard-dialog/app-document-signature-wizard-dialog.component";
import { RestResponse } from "../rest.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-app-document-details",
  templateUrl: "./app-document-details.component.html",
  styleUrls: ["./app-document-details.component.scss"],
})
export class AppDocumentDetailsComponent implements OnInit {
  response: RestResponse<AppDocument>;
  responseHistory: RestResponse<AppDocument[]>;

  activeTabId = "details";

  activateTab(event: { tab: MdcTab }) {
    this.activeTabId = event.tab.id;
  }

  protected get documentService() {
    return this.entities.get(AppDocument);
  }

  protected get documentId() {
    return this.route.snapshot.paramMap.get("id");
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly app: AppService,
    protected readonly appRest: AppRestService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly entities: EntityManager
  ) {}

  async ngOnInit() {
    await this.fetch();
  }

  openHistory(item: AppDocument) {
    this.urlOpenService.openNew({
      url: `#/app/document-details/${item.id}`,
      forceNewTab: true,
    });
  }

  download() {
    this.urlOpenService.openNew({
      url: this.response.value.downloadUrl,
      forceNewTab: true,
    });
  }

  open() {
    this.urlOpenService.openNew({
      url: this.response.value.viewUrl,
      forceNewTab: true,
    });
  }

  openReceipt() {
    this.urlOpenService.openNew({
      url: this.response.value.receiptUrl,
      forceNewTab: true,
    });
  }

  openSigned() {
    this.urlOpenService.openNew({
      url: this.response.value.signedUrl,
      forceNewTab: true,
    });
  }

  async requestSignature() {
    await this.dialogs.open(
      this.changeDetector,
      AppDocumentSignatureWizardDialogComponent,
      {
        data: new AppDocumentSignatureWizardDialogComponentData(
          this.documentId,
          this.response.value.project_id
        ),
      }
    );

    await this.fetch();
  }

  async deleteSignature() {
    if (await this.dialogs.confirm(this.changeDetector)) {
      const response = await this.appRest.request({
        url: `v2/document-signatures/${this.response.value.signature.id}`,
        method: "DELETE",
      });

      if (!response.hasError()) {
        this.response.value.signature = null;

        this.snackbar.open("Verwijderd", "Ok", { leading: true });
      }
    }
  }

  async fetch() {
    if (this.app.isAuthenticated) {
      await this.appRest.request({
        url: `documents/${this.documentId}`,
        method: "GET",
      });
    }

    this.response = await this.documentService.queryFirst({
      filters: [Ops.Field("id").Equals(this.documentId)],
      relations: ["signature", "signature.user", "documentType", "user"],
    });

    if (!this.response.hasError()) {
      await this.fetchHistory();
    }
  }

  protected async fetchHistory() {
    this.responseHistory = await this.documentService.query({
      filters: [
        Ops.Field("project_id").Equals(this.response.value.project_id),
        Ops.Field("document_kind").Equals(this.response.value.document_kind),
        this.response.value.document_type_id
          ? Ops.Field("document_type_id").Equals(
              this.response.value.document_type_id
            )
          : Ops.Field("document_type_id").IsNull(),
        Ops.Field("description").Equals(this.response.value.description),
      ],
      orders: [{ field: "created_at", direction: "asc" }],
    });
  }
}
