import { first, StringIterator } from "lodash";
import * as moment from 'moment';

export class EmployeeDto {
    relatie: string;
    relatie_naam: string;
    persoon: string;
    voornaam: string;
    tussenvoegsel: string;
    achternaam: string;
    voorletters: string;
    geslacht: string;
    type: string;
    geboortedatum: string;
    id_type: string;
    id_nummer: string;
    id_geldig: string;
    vca_diploma: string;
    vca_geldig: string;
    gb_training: string;
    gb_handboek: string;
    stadium: string;
    bhv_mtc: string;
    bhv: string;

    hasDocuments = false;

    get prettyBirthDay() {
        return this.dateOf('geboortedatum');
    }

    get hasVca() {
        return !!this.vca_diploma;
    }

    get hasVcaLink() {
        return this.hasVca && !!this.geboortedatum && !!this.achternaam;
    }

    dateOf(field: string) {
        const value = this[field];
        return value ? moment(value, 'YYYY-MM-DD', true).format('DD-MM-YYYY') : '';
    }

    constructor(body: any) {
        Object.assign(this, body);

        this.setNullIfEmpty(['id_type', 'vca_diploma']);

        if (this.geslacht) {
            this.geslacht = this.geslacht.toLowerCase();
        }
    }

    get vcaUrl() {
        return `https://cdr.ssvv.nl/#/zoek-naam/${this.achternaam}/${this.prettyBirthDay}`;
    }

    protected setNullIfEmpty(keys: string[]) {
        for(const key of keys) {
            const value = this[key];

            if (value === '' || value === 'Geen') {
                this[key] = null;
            }
        }
    }
}

export class EmployeeDtoGroup {
    constructor(
        readonly state: string,
        readonly items: EmployeeDto[],
    ) { }

    hasDocuments = false;

    get data() {
        return first(this.items);
    }

    get isArchived() {
        return this.state !== '1000';
    }
}