import {
  Component,
  OnInit,
  ChangeDetectorRef,
  NgZone,
  ViewEncapsulation,
} from "@angular/core";
import { EntityManager, Ops } from "../entity.service";
import { WorkAction, WorkActionExtentions } from "./work-action.entity";
import { RestResponse, RestService } from "../rest.service";
import { Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import {
  ComplexTableColumn,
  ComplexTableDataGroup,
  ComplexTableDataRow,
  ComplexTableAction,
  ComplexTableActionClicked,
  ComplexTableActionGroupClicked,
} from "../complex-table/complex-table.component";
import { EntityAdvancement } from "../offers/entity.advancement.entity";
import { DialogService } from "../dialog.service";
import {
  EntityAdvancementDialogComponentData,
  EntityAdvancementDialogComponent,
  EntityAdvancementDialogComponentDataOptions,
  EntityAdvancementDialogComponentDataExtentions,
} from "../entity-advancement-dialog/entity-advancement-dialog.component";
import { WorkActionDialogComponent } from "../work-action-dialog/work-action-dialog.component";
import { UserNotification } from "../notification/user-notification.entity";
import { User } from "../accessibility-users/user.entity";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { WorkActionCategoriesDialogComponent } from "../work-action-categories-dialog/work-action-categories-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { first, flatten } from "lodash";
import { WorkActionTemplateDeployDialogComponent } from "../work-action-template-deploy-dialog/work-action-template-deploy-dialog.component";

import * as moment from "moment";
import * as _ from "lodash";
import { NotificationGuard } from "./notification-guard";
import {
  WorkActionAdvancementExtension,
  WorkActionAdvancementExtensionProps,
} from "./work-action-advancement-extension.component";
import { DateDefinition } from "../year-planning/entities/date-entities";

export enum ViewModeEnum {
  TABLE = "table",
  CHART_GANTT = "chart-gantt",
}

@Component({
  selector: "app-work-actions",
  templateUrl: "./work-actions.component.html",
  styleUrls: ["./work-actions.component.scss"],
})
export class WorkActionsComponent implements OnInit {
  protected currentViewMode: ViewModeEnum = ViewModeEnum.TABLE;

  buttonFlex: number = 35;
  filterFlex: number = 40;
  showDateFilter: boolean = false;

  date: Date = new Date();

  get dateWeek() {
    return moment(this.date).isoWeek();
  }

  set viewMode(value: ViewModeEnum) {
    if (value === ViewModeEnum.CHART_GANTT) {
      this.buttonFlex = 50;
      this.filterFlex = 30;
      this.showDateFilter = true;
    } else {
      this.buttonFlex = 35;
      this.filterFlex = 40;
      this.showDateFilter = false;
    }

    this.currentViewMode = value;
  }

  get viewMode() {
    return this.currentViewMode;
  }

  showOnlyActive = true;
  showOnlyExpired = false;

  workActionCategoryId: string;
  workActionCategoryConfig = {
    allowNothing: true,
    title: "Categorie",
    icon: "category",
    entityName: "work_action_categories",
    nameField: "description",
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  projectId: string;
  projectFilterConfig = {
    allowNothing: true,
    title: "Project /Afdeling",
    icon: "archive",
    entityName: "projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  relationId: string;
  relationFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Uitvoerende",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "roleId", operator: "IsNull", isNot: true },
    ],
    relations: ["role", "role.grants"],
    filterAfter: (relation: User) => {
      return (
        relation.__role__ &&
        !!(relation.__role__.__grants__ || []).find(
          (e) => e.permissionId === "work_actions"
        )
      );
    },
  };

  response: RestResponse<WorkActionGroup[]>;

  protected filterQueryChanged = new Subject<{}>();

  get editCategories() {
    return this.grantService.varIs(grants.work_actions.edit_categories, "true");
  }

  get allowFilter() {
    return this.grantService.varIs(grants.work_actions.allow_filter, "true");
  }

  columns = [
    new ComplexTableColumn<WorkAction>({
      id: "date",
      title: "Datum",
      width: "100px",
      text: (value) => moment(value.date).format("DD-MM-YYYY"),
      sort: (value) => moment(value.date).unix(),
    }),
    new ComplexTableColumn<WorkAction>({
      id: "category",
      title: "Cat.",
      width: "auto",
      wrap: true,
      text: (value) =>
        value.__workActionCategory__
          ? value.__workActionCategory__.description
          : "-",
    }),
    new ComplexTableColumn<WorkAction>({
      id: "user",
      title: "Melder",
      width: "auto",
      text: (value) => value.__user__.name,
    }),
    new ComplexTableColumn<WorkAction>({
      id: "description",
      title: "Omschrijving",
      width: "auto",
      wrap: true,
      text: (value) => value.title,
      note: (value) => value.description,
    }),
    new ComplexTableColumn<WorkAction>({
      id: "executive",
      title: "Uitvoerende",
      width: "auto",
      text: (value) => value.__assignedUser__.name,
    }),
    new ComplexTableColumn<WorkAction>({
      id: "actionDate",
      title: "Voortgang",
      width: "100px",
      text: (value) =>
        value.active
          ? moment(value.active.createdAt).format("DD-MM-YYYY")
          : "-",
      sort: (value) =>
        value.active ? moment(value.active.createdAt).unix() : 0,
      note: (value) => (value.active ? value.active.comment : null),
    }),
    new ComplexTableColumn<WorkAction>({
      id: "targetDate",
      title: "Streefdatum",
      width: "100px",
      text: (value) =>
        value.active ? moment(value.active.date).format("DD-MM-YYYY") : "-",
      sort: (value) => (value.active ? moment(value.active.date).unix() : 0),
    }),
    new ComplexTableColumn<WorkAction>({
      id: "status",
      title: "Status",
      width: "1px",
      text: (value) =>
        new WorkActionExtentions(value).isCompleted() ? "gereed" : "open",
      cssClasses: (value) =>
        new WorkActionExtentions(value).isCompleted() ? ["is-completed"] : [],
    }),
  ];

  actions = [
    new ComplexTableAction<WorkAction>({
      id: "advancement",
      icon: "add_comment",
      title: "Voortgang melden",
      color: (item) =>
        item.__entityAdvancements__.length > 0 ? "green" : "grey",
    }),
    new ComplexTableAction<WorkAction>({
      id: "edit",
      icon: "edit",
      title: "Aanpassen",
    }),
    new ComplexTableAction<WorkAction>({
      id: "copy",
      icon: "file_copy",
      title: "Kopieren",
    }),
    new ComplexTableAction<{}>({
      id: "add",
      icon: "add",
      title: "Toevoegen",
      type: "group",
    }),
  ];

  protected get workActionService() {
    return this.entityManager.get(WorkAction);
  }

  protected get userNotificationService() {
    return this.entityManager.get(UserNotification);
  }

  protected get workActionId() {
    return this.route.snapshot.paramMap.get("id");
  }

  constructor(
    protected readonly zone: NgZone,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly entityManager: EntityManager,
    protected readonly grantService: GrantService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500)
      )
      .subscribe(() => this.fetch());

    this.relationId = this.authService.user.id;
    this.showOnlyExpired = this.route.snapshot.data.showOnlyExpired === true;

    await this.fetch();
    await this.openDeepLink();
  }

  onFilterQuery() {
    this.filterQueryChanged.next({});
  }

  action(event: ComplexTableActionClicked<WorkAction>) {
    switch (event.action.id) {
      case "advancement":
        return this.addAdvancement(event.item);
      case "edit":
        return this.editWorkAction(event.item);
      case "copy":
        return this.addWorkAction(event.item.projectId, event.item);
    }
  }

  actionGroup(event: ComplexTableActionGroupClicked<WorkAction>) {
    switch (event.action.id) {
      case "add":
        return this.addWorkAction(event.group.id);
    }
  }

  async addAdvancement(action: WorkAction) {
    const result: EntityAdvancement[] = await this.dialogService.open(
      this.changeDetector,
      EntityAdvancementDialogComponent,
      {
        data: new EntityAdvancementDialogComponentData(
          action.id,
          action.entityType,
          action.title,
          `${action.projectId ? `(${action.projectId}) ` : ""}${
            action.description
          }`,
          action.projectId,
          true,
          null,
          null,
          null,
          null,
          null,
          new EntityAdvancementDialogComponentDataOptions({
            disableImages: action.disableImageUpload,
            allowEmptyDescription: action.allowEmptyAdvancement,
            enableDocuments: true,
          }),
          new EntityAdvancementDialogComponentDataExtentions({
            component: WorkActionAdvancementExtension,
            componentProperties: {
              action,
              restService: this.restService,
              entities: this.entityManager,
              onSelected: (date: DateDefinition) => {
                action.advancementApplyDateId = date.id;
              },
            } as WorkActionAdvancementExtensionProps,
            beforeSave: async () => {
              if (action.isDirty && !!action.advancementApplyDateId) {
                await this.workActionService.save(action);
              }
            },
          })
        ),
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    if (result) {
      action.__entityAdvancements__ = result;
      action.active = new WorkActionExtentions(action).activeAdvancement();

      if (action.active.isNew && action.active.isCompleted) {
        const guard = new NotificationGuard({
          userId: this.authService.user.id,
          notifications: this.userNotificationService,
        });

        await guard.sendIfNotMe(
          this.userNotificationService.concept({
            userId: action.userId,
            subject: `Actiepunt gereed gemeld: ${action.title}`,
            content: action.description,
            channels: ["notification"],
            url: `${location.origin}/#/office/work-actions/${action.id}`,
          })
        );
      }

      await this.fetch();
    }
  }

  async deployTemplate() {
    await this.dialogService.open(
      this.changeDetector,
      WorkActionTemplateDeployDialogComponent,
      {
        data: {},
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    await this.fetch();
  }

  async addWorkAction(projectId: string = null, data: any = {}) {
    projectId = projectId || this.projectId;
    const assignedUserId = this.relationId;

    const action: WorkAction = await this.dialogService.open(
      this.changeDetector,
      WorkActionDialogComponent,
      {
        data: this.workActionService.concept(
          Object.assign(data, {
            id: null,
            projectId,
            userId: this.authService.user.id,
            assignedUserId,
          })
        ),
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    await this.fetch();
  }

  async editWorkAction(action: WorkAction) {
    await this.dialogService.open(
      this.changeDetector,
      WorkActionDialogComponent,
      {
        data: action,
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    this.update();
  }

  async openCategoriesDialog() {
    await this.dialogService.open(
      this.changeDetector,
      WorkActionCategoriesDialogComponent,
      { clickOutsideToClose: false, escapeToClose: false }
    );

    await this.fetch();
  }

  async toggleChartGantt() {
    this.viewMode =
      this.viewMode === ViewModeEnum.TABLE
        ? ViewModeEnum.CHART_GANTT
        : ViewModeEnum.TABLE;

    this.onFilterQuery();
  }

  openActionFromGantt(data: { id: string }) {
    if (data) {
      const all = flatten(this.response.value.map((e) => e.actions));
      const target = all.find((a) => a.id === data.id);

      if (target) {
        this.zone.run(async () => await this.addAdvancement(target));
      }
    }
  }

  resetDate() {
    this.date = new Date();
    this.onFilterQuery();
  }

  incrementDate() {
    this.date = moment(this.date).add(1, "weeks").toDate();
    this.onFilterQuery();
  }

  decrementDate() {
    this.date = moment(this.date).add(-1, "weeks").toDate();
    this.onFilterQuery();
  }

  protected async fetch() {
    const filters = [];

    if (this.projectId) {
      filters.push({
        field: "projectId",
        operator: "Equal",
        value: this.projectId,
      });
    }

    if (this.relationId) {
      filters.push({
        field: "assignedUserId",
        operator: "Equal",
        value: this.relationId,
      });
    }

    if (this.workActionCategoryId) {
      filters.push({
        field: "workActionCategoryId",
        operator: "Equal",
        value: this.workActionCategoryId,
      });
    }

    const response = await this.workActionService.query({
      filters,
      relations: [
        "project",
        "project.projectLeader",
        "user",
        "assignedUser",
        "entityAdvancements",
        "workActionCategory",
      ],
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        _.chain(response.value)
          .forEach(
            (action) =>
              (action.active = new WorkActionExtentions(
                action
              ).activeAdvancement())
          )
          .filter((action) => {
            const isCompleted = new WorkActionExtentions(action).isCompleted();

            if (this.viewMode === ViewModeEnum.CHART_GANTT) {
              return !isCompleted;
            }

            return this.showOnlyActive ? !isCompleted : true;
          })
          .filter((item) => this.filterForGantt(item))
          .filter((action) =>
            this.showOnlyExpired && this.viewMode === ViewModeEnum.TABLE
              ? new WorkActionExtentions(action).isExpired()
              : true
          )
          .groupBy((r) => r.projectId)
          .map((i, k) => new WorkActionGroup(k, i))
          .value()
      );
    }
  }

  protected filterForGantt(action: WorkAction) {
    const endOfWeek = moment(this.date).endOf("isoWeek").toDate();

    if (this.viewMode === ViewModeEnum.CHART_GANTT) {
      const date = action.active ? action.active.date : null;

      return date && moment(date).isSameOrBefore(endOfWeek);
    }

    return true;
  }

  protected update() {
    this.response = new RestResponse([...this.response.value]);
  }

  protected async openDeepLink() {
    if (this.workActionId) {
      const response = await this.workActionService.queryFirst({
        filters: [Ops.Field("id").Equals(this.workActionId)],
        relations: [
          "project",
          "project.projectLeader",
          "user",
          "assignedUser",
          "entityAdvancements",
          "workActionCategory",
        ],
      });

      if (!response.hasError()) {
        await this.addAdvancement(response.value);
      }
    }
  }
}

class WorkActionGroup extends ComplexTableDataGroup<WorkAction> {
  get buildingWeek() {
    return first(this.actions).__project__.buildingWeek;
  }

  constructor(readonly projectId: string, readonly actions: WorkAction[]) {
    super(
      actions.map((e) => new ComplexTableDataRow(e)),
      projectId,
      new WorkActionGroupLabel(projectId, actions).compose(),
      true
    );
  }
}

class WorkActionGroupLabel {
  constructor(
    protected readonly projectId: string,
    protected readonly actions: WorkAction[]
  ) {}

  compose() {
    return Array.from(this.iterate())
      .filter((segment) => !!segment)
      .join(" - ");
  }

  protected *iterate() {
    yield this.projectId;
    yield this.project.description;
    yield this.projectLeaderName;
    yield this.project.buildingWeek;
  }

  protected get project() {
    return first(this.actions).__project__;
  }

  protected get projectLeaderName() {
    return this.project.__projectLeader__
      ? this.project.__projectLeader__.name
      : null;
  }
}
