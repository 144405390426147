import { Entity } from "../entity.service";

export class AppNotificationTemplate extends Entity {
  type = "app.notification_templates";

  content_id: number;

  subject: string;
  body: string;

  created_at?: Date;
  updated_at?: Date;
}
