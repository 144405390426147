import { Component, ChangeDetectorRef } from "@angular/core";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager, Ops } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppServiceCategory } from "./app-service-category.entity";
import { AppServiceCategoryDialogComponent } from "../app-service-category-dialog/app-service-category-dialog.component";

@Component({
  selector: "app-app-service-categories",
  templateUrl: "./app-service-categories.component.html",
  styleUrls: ["./app-service-categories.component.scss"],
})
export class AppServiceCategoriesComponent extends AppCrudBoilerplate<
  AppServiceCategory
> {
  entity = AppServiceCategory;
  editDialog = AppServiceCategoryDialogComponent;

  get relations() {
    return ["service_categories"];
  }

  get filters() {
    return [Ops.Field("service_category_id").IsNull()];
  }

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }

  addChild(category: AppServiceCategory) {
    return this.edit(
      this.entityService.concept({
        service_category_id: category.id,
      })
    );
  }
}
