import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import * as Sentry from "@sentry/angular";
import { DeviceDetectorModule } from "ngx-device-detector";
import { NgxZendeskWebwidgetModule } from "ngx-zendesk-webwidget";
import { environment } from "../environments/environment";
import { AccessibilityPermissionExportDialogAngular } from "./accessibility-permission-export-dialog/accessibility-permission-export-dialog.angular";
import { AccessibilityRoleDialogComponent } from "./accessibility-role-dialog/accessibility-role-dialog.component";
import { AccessibilityRolesComponent } from "./accessibility-roles/accessibility-roles.component";
import { AccessibilityUserDialogComponent } from "./accessibility-user-dialog/accessibility-user-dialog.component";
import { AccessibilityUserPasswordDialogComponent } from "./accessibility-user-password-dialog/accessibility-user-password-dialog.component";
import { AccessibilityUsersComponent } from "./accessibility-users/accessibility-users.component";
import { AccountDialogComponent } from "./account-dialog/account-dialog.component";
import { AppActivitiesComponent } from "./app-activities/app-activities.component";
import { AppActivityDialogComponent } from "./app-activity-dialog/app-activity-dialog.component";
import { AppContentDialogComponent } from "./app-content-dialog/app-content-dialog.component";
import { AppContentPreviewComponent } from "./app-content-preview/app-content-preview.component";
import { AppContentsComponent } from "./app-contents/app-contents.component";
import { AppConversationMessagePresetDialogComponent } from "./app-conversation-message-preset-dialog/app-conversation-message-preset-dialog.component";
import { AppConversationMessagePresetsComponent } from "./app-conversation-message-presets/app-conversation-message-presets.component";
import { AppDocumentDetailsComponent } from "./app-document-details/app-document-details.component";
import { AppDocumentSignatureWizardDialogComponent } from "./app-document-signature-wizard-dialog/app-document-signature-wizard-dialog.component";
import { AppDocumentTypeDialogComponent } from "./app-document-type-dialog/app-document-type-dialog.component";
import { AppDocumentTypesComponent } from "./app-document-types/app-document-types.component";
import { AppDocumentUploadDialogComponent } from "./app-document-upload-dialog/app-document-upload-dialog.component";
import { AppInviteTextDialogComponent } from "./app-invite-text-dialog/app-invite-text-dialog.component";
import { AppLoginDialogComponent } from "./app-login-dialog/app-login-dialog.component";
import { AppNotificationResourcesComponent } from "./app-notification-resources/app-notification-resources.component";
import { AppNotificationScheduleDialogComponent } from "./app-notification-schedule-dialog/app-notification-schedule-dialog.component";
import { AppNotificationSchedulesComponent } from "./app-notification-schedules/app-notification-schedules.component";
import { AppNotificationSendDialogComponent } from "./app-notification-send-dialog/app-notification-send-dialog.component";
import { AppNotificationTemplateDialogComponent } from "./app-notification-template-dialog/app-notification-template-dialog.component";
import { AppNotificationTemplatesComponent } from "./app-notification-templates/app-notification-templates.component";
import { AppPhaseDialogComponent } from "./app-phase-dialog/app-phase-dialog.component";
import { AppPhaseSectionDialogComponent } from "./app-phase-section-dialog/app-phase-section-dialog.component";
import { AppPhaseSectionMarkDialogComponent } from "./app-phase-section-mark-dialog/app-phase-section-mark-dialog.component";
import { AppPhasesComponent } from "./app-phases/app-phases.component";
import { AppProjectCostDialogComponent } from "./app-project-cost-dialog/app-project-cost-dialog.component";
import { AppProjectCostHistoryDialogComponent } from "./app-project-cost-history-dialog/app-project-cost-history-dialog.component";
import { AppProjectCostPresetDialogComponent } from "./app-project-cost-preset-dialog/app-project-cost-preset-dialog.component";
import { AppProjectCostPresetsComponent } from "./app-project-cost-presets/app-project-cost-presets.component";
import { AppProjectCostsComponent } from "./app-project-costs/app-project-costs.component";
import { AppProjectFieldGroupAssignmentDialogComponent } from "./app-project-field-group-assignment-dialog/app-project-field-group-assignment-dialog.component";
import { AppProjectFieldGroupDialogComponent } from "./app-project-field-group-dialog/app-project-field-group-dialog.component";
import { AppProjectFieldSheetDialogComponent } from "./app-project-field-sheet-dialog/app-project-field-sheet-dialog.component";
import { AppProjectFieldViewComponent } from "./app-project-field-view/app-project-field-view.component";
import { AppProjectFieldsComponent } from "./app-project-fields/app-project-fields.component";
import { routes } from "./app-routes";
import { AppServiceCategoriesComponent } from "./app-service-categories/app-service-categories.component";
import { AppServiceCategoryDialogComponent } from "./app-service-category-dialog/app-service-category-dialog.component";
import { AppSsoComponent } from "./app-sso/app-sso.component";
import { AppUsersComponent } from "./app-users/app-users.component";
import { AppVendorModule } from "./app-vendor.module";
import { AppComponent } from "./app.component";
import { AsyncUrlOpenDialogComponent } from "./async-url-open-dialog/async-url-open-dialog.component";
import { AuditChecklistImageDialogComponent } from "./audit-checklist-image-dialog/audit-checklist-image-dialog.component";
import { AuditChecklistImagesComponent } from "./audit-checklist-images/audit-checklist-images.component";
import { AuditEntityDialogComponent } from "./audit-entity-dialog/audit-entity-dialog.component";
import { AuditSheetPhasesComponent } from "./audit-sheet-phases/audit-sheet-phases.component";
import { AuditSheetReportComponent } from "./audit-sheet-report/audit-sheet-report.component";
import { AuditSheetTableComponent } from "./audit-sheet-table/audit-sheet-table.component";
import { AuditSheetComponent } from "./audit-sheet/audit-sheet.component";
import { JwtInterceptor } from "./auth.interceptor";
import { AuthService } from "./auth.service";
import { BlockedCalendarWeeksDialogComponent } from "./blocked-calendar-weeks-dialog/blocked-calendar-weeks-dialog.component";
import { CargoDialogComponent } from "./cargo-dialog/cargo-dialog.component";
import { CargoElementGroupsDialogComponent } from "./cargo-element-groups-dialog/cargo-element-groups-dialog.component";
import { CargoNotesDialogComponent } from "./cargo-notes-dialog/cargo-notes-dialog.component";
import { CargoOverviewComponent } from "./cargo-overview/cargo-overview.component";
import { CargoPhaseDialogComponent } from "./cargo-phase-dialog/cargo-phase-dialog.component";
import { CargoPrintComponent } from "./cargo-print/cargo-print.component";
import { CargoSplitDialogComponent } from "./cargo-split-dialog/cargo-split-dialog.component";
import { CargoComponent } from "./cargo/cargo.component";
import { ChangelogDialogComponent } from "./changelog-dialog/changelog-dialog.component";
import { ComplexTableComponent } from "./complex-table/complex-table.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ConstructionPlanningAngular } from "./construction-planning/construction-planning.angular";
import { ContractDateDialogComponent } from "./contract-date-dialog/contract-date-dialog.component";
import { ContractsComponent } from "./contracts/contracts.component";
import { CustomerQuestionsAngular } from "./customer-questions/customer-questions.angular";
import { DateGroupCommentDialogComponent } from "./date-group-comment-dialog/date-group-comment-dialog.component";
import { DateGroupResponsibleDialogComponent } from "./date-group-responsible-dialog/date-group-responsible-dialog.component";
import { DateGroupsDialogComponent } from "./date-groups-dialog/date-groups-dialog.component";
import { DateStackDialogComponent } from "./date-stack-dialog/date-stack-dialog.component";
import { DeliveriesComponent } from "./deliveries/deliveries.component";
import { DeliveryListComponent } from "./delivery-list/delivery-list.component";
import { DeliveryOrderDialogComponent } from "./delivery-order-dialog/delivery-order-dialog.component";
import { DialogService } from "./dialog.service";
import { DocumentsComponent } from "./documents/documents.component";
import { DrawOrderDialogComponent } from "./draw-order-dialog/draw-order-dialog.component";
import { DrawerAbsentDialogComponent } from "./drawer-absent-dialog/drawer-absent-dialog.component";
import { DrawOrderPlanService } from "./drawers/draw-order-plan.service";
import { EmployeeDialogComponent } from "./employee-dialog/employee-dialog.component";
import { EmployeeFileDialogComponent } from "./employee-file-dialog/employee-file-dialog.component";
import { EmployeesComponent } from "./employees/employees.component";
import { EntityAdvancementDialogComponent } from "./entity-advancement-dialog/entity-advancement-dialog.component";
import { EntitySearchDialogComponent } from "./entity-search-dialog/entity-search-dialog.component";
import { EntitySelectComponent } from "./entity-select/entity-select.component";
import { EntityManager } from "./entity.service";
import { ErrorInterceptor } from "./error.interceptor";
import { FetchRelationAsyncPipePipe } from "./fetch-relation-async-pipe.pipe";
import { FileSizePipe } from "./file-size.pipe";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { GraphQLModule } from "./graphql.module";
import { GreetingsComponent } from "./greetings/greetings.component";
import { HomeComponent } from "./home/home.component";
import { HourDeclarationsComponent } from "./hour-declarations/hour-declarations.component";
import { ImageDialogComponent } from "./image-dialog/image-dialog.component";
import { ImageSlideshowDialogComponent } from "./image-slideshow-dialog/image-slideshow-dialog.component";
import { InformationDialogComponent } from "./information-dialog/information-dialog.component";
import { InvoiceCreatorAttachmentDialogComponent } from "./invoice-creator-attachment-dialog/invoice-creator-attachment-dialog.component";
import { InvoiceCreatorComponent } from "./invoice-creator/invoice-creator.component";
import { InvoiceJudgeDialogComponent } from "./invoice-judge-dialog/invoice-judge-dialog.component";
import { InvoicesComponent } from "./invoices/invoices.component";
import { JobCronDialogComponent } from "./job-cron-dialog/job-cron-dialog.component";
import { JobsComponent } from "./jobs/jobs.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { LoadingDocumentComponent } from "./loading-document/loading-document.component";
import { LoginComponent } from "./login/login.component";
import { MailSchedulerEditorDialogComponent } from "./mail-scheduler-editor-dialog/mail-scheduler-editor-dialog.component";
import { MailSchedulersComponent } from "./mail-schedulers/mail-schedulers.component";
import { MailTrafficAngular } from "./mail-traffic/mail-traffic.angular";
import { MasterComponent } from "./master/master.component";
import { MdcDataTableComponent } from "./mdc-data-table/mdc-data-table.component";
import { MetacomInvoicePaymentBatchComponent } from "./metacom-invoice-payment-batch/metacom-invoice-payment-batch.component";
import { MetacomInvoiceComponent } from "./metacom-invoice/metacom-invoice.component";
import { MetacomPriceComponent } from "./metacom-price/metacom-price.component";
import { MetacomProjectAgreementComponent } from "./metacom-project-agreement/metacom-project-agreement.component";
import { MetacomProjectInvoicesComponent } from "./metacom-project-invoices/metacom-project-invoices.component";
import { MetacomProjectRelationsComponent } from "./metacom-project-relations/metacom-project-relations.component";
import { MetacomProjectComponent } from "./metacom-project/metacom-project.component";
import { MetacomRelationContactDialogComponent } from "./metacom-relation-contact-dialog/metacom-relation-contact-dialog.component";
import { MetacomRelationComponent } from "./metacom-relation/metacom-relation.component";
import { MetacomStaffComponent } from "./metacom-staff/metacom-staff.component";
import { NotificationComponent } from "./notification/notification.component";
import { OffersScoreDialogComponent } from "./offers-score-dialog/offers-score-dialog.component";
import { OffersComponent } from "./offers/offers.component";
import { OrderExpectDialogComponent } from "./order-expect-dialog/order-expect-dialog.component";
import { OrdersComponent } from "./orders/orders.component";
import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { PicklistCommentDialogComponent } from "./picklist-comment-dialog/picklist-comment-dialog.component";
import { PicklistDefinitionsAngularComponent } from "./picklist-definitions/picklist-definitions-angular.component";
import { PicklistOverviewComponent } from "./picklist-overview/picklist-overview.component";
import { PicklistComponentAngular } from "./picklist/picklist-component.angular";
import { PicklistComponent } from "./picklist/picklist.component";
import { PlanningFilterDialogComponent } from "./planning-filter-dialog/planning-filter-dialog.component";
import { PopupDialogComponent } from "./popup-dialog/popup-dialog.component";
import { PrintLayoutComponent } from "./print-layout/print-layout.component";
import { ProjectPaymentDialogComponent } from "./project-payment-dialog/project-payment-dialog.component";
import { ProjectShortcutsDialogComponent } from "./project-shortcuts-dialog/project-shortcuts-dialog.component";
import { ProjectShortcutsComponent } from "./project-shortcuts/project-shortcuts.component";
import { ReconnectInterceptor } from "./reconnect.interceptor";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ScanGoodsComponent } from "./scan-goods/scan-goods.component";
import { SecuredImageComponent } from "./secured-image/secured-image.component";
import { SelectDialogComponent } from "./select-dialog/select-dialog.component";
import { ServiceSettingsComponent } from "./service-settings/service-settings.component";
import { ServiceTicketDialogComponent } from "./service-ticket-dialog/service-ticket-dialog.component";
import { ServiceComponent } from "./service/service.component";
import { ShopComponent } from "./shop/shop.component";
import { SupplierContactDialogComponent } from "./suppier-contact-dialog/supplier-contact-dialog.component";
import { TableLayoutComponent } from "./table-layout/table-layout.component";
import { TestComponent } from "./test/test.component";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { TransactionDialogComponent } from "./transaction-dialog/transaction-dialog.component";
import { UploadProgressDialogComponent } from "./upload-progress-dialog/upload-progress-dialog.component";
import { WorkActionCategoriesDialogComponent } from "./work-action-categories-dialog/work-action-categories-dialog.component";
import { WorkActionDialogComponent } from "./work-action-dialog/work-action-dialog.component";
import { WorkActionTemplateDeployDialogComponent } from "./work-action-template-deploy-dialog/work-action-template-deploy-dialog.component";
import { WorkActionTemplatesComponent } from "./work-action-templates/work-action-templates.component";
import { WorkActionsChartGanttComponent } from "./work-actions-chart-gantt/work-actions-chart-gantt.component";
import { WorkActionsComponent } from "./work-actions/work-actions.component";
import { WorkOrderDialogComponent } from "./work-order-dialog/work-order-dialog.component";
import { WorksOrderAngular } from "./work-orders/work-orders.angular";
import { YearPlanningLinesDialogComponent } from "./year-planning-lines-dialog/year-planning-lines-dialog.component";
import { YearPlanningModifyWeekDialogComponent } from "./year-planning-modify-week-dialog/year-planning-modify-week-dialog.component";
import { YearPlanningProjectComponent } from "./year-planning-project/year-planning-project.component";
import { YearPlanningComponent } from "./year-planning/year-planning.component";
import { ZendeskConfig } from "./zendesk-config";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    MasterComponent,
    AccountDialogComponent,
    AccessibilityUsersComponent,
    AccessibilityRolesComponent,
    AccessibilityUserDialogComponent,
    AccessibilityRoleDialogComponent,
    PicklistComponent,
    MdcDataTableComponent,
    PicklistCommentDialogComponent,
    FetchRelationAsyncPipePipe,
    PicklistOverviewComponent,
    JobsComponent,
    CargoComponent,
    CargoDialogComponent,
    CargoSplitDialogComponent,
    CargoOverviewComponent,
    EntitySearchDialogComponent,
    LoadingDialogComponent,
    MetacomProjectComponent,
    DrawOrderDialogComponent,
    EntitySelectComponent,
    PrintLayoutComponent,
    CargoPrintComponent,
    ConfirmDialogComponent,
    DrawerAbsentDialogComponent,
    CargoElementGroupsDialogComponent,
    AuditSheetComponent,
    AuditEntityDialogComponent,
    MetacomRelationComponent,
    MetacomStaffComponent,
    SecuredImageComponent,
    ImageDialogComponent,
    AuditSheetTableComponent,
    YearPlanningComponent,
    PopupDialogComponent,
    DocumentsComponent,
    LoadingDocumentComponent,
    BlockedCalendarWeeksDialogComponent,
    YearPlanningLinesDialogComponent,
    FileSizePipe,
    DateGroupsDialogComponent,
    SelectDialogComponent,
    TestComponent,
    DateStackDialogComponent,
    DateGroupCommentDialogComponent,
    DateGroupResponsibleDialogComponent,
    PlanningFilterDialogComponent,
    FileUploadComponent,
    AuditSheetPhasesComponent,
    AuditSheetReportComponent,
    MetacomPriceComponent,
    MetacomProjectAgreementComponent,
    AuditChecklistImagesComponent,
    AuditChecklistImageDialogComponent,
    YearPlanningModifyWeekDialogComponent,
    YearPlanningProjectComponent,
    OrdersComponent,
    DeliveriesComponent,
    OrderExpectDialogComponent,
    OffersScoreDialogComponent,
    DeliveryOrderDialogComponent,
    ComplexTableComponent,
    OffersComponent,
    EntityAdvancementDialogComponent,
    WorkActionsComponent,
    WorkActionDialogComponent,
    InvoicesComponent,
    ImageSlideshowDialogComponent,
    InvoiceJudgeDialogComponent,
    NotificationComponent,
    WorkOrderDialogComponent,
    HourDeclarationsComponent,
    PdfViewerComponent,
    InvoiceCreatorComponent,
    InvoiceCreatorAttachmentDialogComponent,
    ServiceComponent,
    ServiceTicketDialogComponent,
    TimePickerComponent,
    GreetingsComponent,
    ShopComponent,
    EmployeesComponent,
    EmployeeDialogComponent,
    EmployeeFileDialogComponent,
    WorkActionCategoriesDialogComponent,
    UploadProgressDialogComponent,
    ChangelogDialogComponent,
    ContractsComponent,
    ContractDateDialogComponent,
    ProjectShortcutsComponent,
    MailSchedulersComponent,
    MailSchedulerEditorDialogComponent,
    CargoNotesDialogComponent,
    MetacomInvoiceComponent,
    MetacomInvoicePaymentBatchComponent,
    ProjectShortcutsDialogComponent,
    AppUsersComponent,
    AppSsoComponent,
    ResetPasswordComponent,
    AccessibilityUserPasswordDialogComponent,
    AppLoginDialogComponent,
    AppInviteTextDialogComponent,
    AppConversationMessagePresetsComponent,
    AppConversationMessagePresetDialogComponent,
    AppDocumentTypesComponent,
    AppDocumentTypeDialogComponent,
    AppProjectCostPresetsComponent,
    AppProjectCostPresetDialogComponent,
    AppActivitiesComponent,
    AppActivityDialogComponent,
    AppContentsComponent,
    AppContentDialogComponent,
    AppServiceCategoriesComponent,
    AppServiceCategoryDialogComponent,
    AppNotificationTemplatesComponent,
    AppNotificationTemplateDialogComponent,
    AppProjectFieldsComponent,
    AppProjectFieldSheetDialogComponent,
    AppProjectFieldGroupDialogComponent,
    AppProjectFieldGroupAssignmentDialogComponent,
    AppPhasesComponent,
    AppPhaseDialogComponent,
    AppPhaseSectionDialogComponent,
    AppPhaseSectionMarkDialogComponent,
    AppNotificationSchedulesComponent,
    AppNotificationScheduleDialogComponent,
    TransactionDialogComponent,
    AppNotificationResourcesComponent,
    AppContentPreviewComponent,
    AppDocumentUploadDialogComponent,
    AppDocumentSignatureWizardDialogComponent,
    AppDocumentDetailsComponent,
    AppProjectCostsComponent,
    InformationDialogComponent,
    AppProjectCostDialogComponent,
    AppProjectCostHistoryDialogComponent,
    ProjectPaymentDialogComponent,
    MetacomRelationContactDialogComponent,
    JobCronDialogComponent,
    AppNotificationSendDialogComponent,
    TableLayoutComponent,
    WorkActionTemplatesComponent,
    WorkActionTemplateDeployDialogComponent,
    WorkActionsChartGanttComponent,
    AsyncUrlOpenDialogComponent,
    AppProjectFieldViewComponent,
    ScanGoodsComponent,
    DeliveryListComponent,
    ServiceSettingsComponent,
    CustomerQuestionsAngular,
    PicklistDefinitionsAngularComponent,
    MetacomProjectRelationsComponent,
    ConstructionPlanningAngular,
    MailTrafficAngular,
    PicklistComponentAngular,
    MetacomProjectInvoicesComponent,
    CargoPhaseDialogComponent,
    SupplierContactDialogComponent,
    WorksOrderAngular,
    AccessibilityPermissionExportDialogAngular,
  ],
  entryComponents: [
    AccountDialogComponent,
    AccessibilityUserDialogComponent,
    AccessibilityRoleDialogComponent,
    PicklistCommentDialogComponent,
    CargoDialogComponent,
    CargoSplitDialogComponent,
    EntitySearchDialogComponent,
    LoadingDialogComponent,
    DrawOrderDialogComponent,
    ConfirmDialogComponent,
    DrawerAbsentDialogComponent,
    CargoElementGroupsDialogComponent,
    AuditEntityDialogComponent,
    ImageDialogComponent,
    PopupDialogComponent,
    BlockedCalendarWeeksDialogComponent,
    YearPlanningLinesDialogComponent,
    DateGroupsDialogComponent,
    SelectDialogComponent,
    DateStackDialogComponent,
    DateGroupCommentDialogComponent,
    DateGroupResponsibleDialogComponent,
    PlanningFilterDialogComponent,
    AuditChecklistImageDialogComponent,
    YearPlanningModifyWeekDialogComponent,
    OrderExpectDialogComponent,
    OffersScoreDialogComponent,
    DeliveryOrderDialogComponent,
    EntityAdvancementDialogComponent,
    WorkActionDialogComponent,
    ImageSlideshowDialogComponent,
    InvoiceJudgeDialogComponent,
    WorkOrderDialogComponent,
    InvoiceCreatorAttachmentDialogComponent,
    ServiceTicketDialogComponent,
    EmployeeDialogComponent,
    EmployeeFileDialogComponent,
    WorkActionCategoriesDialogComponent,
    UploadProgressDialogComponent,
    ChangelogDialogComponent,
    ContractDateDialogComponent,
    MailSchedulerEditorDialogComponent,
    CargoNotesDialogComponent,
    ProjectShortcutsDialogComponent,
    AccessibilityUserPasswordDialogComponent,
    AppLoginDialogComponent,
    AppInviteTextDialogComponent,
    AppConversationMessagePresetDialogComponent,
    AppDocumentTypeDialogComponent,
    AppProjectCostPresetDialogComponent,
    AppActivityDialogComponent,
    AppContentDialogComponent,
    AppServiceCategoryDialogComponent,
    AppNotificationTemplateDialogComponent,
    AppProjectFieldSheetDialogComponent,
    AppProjectFieldGroupDialogComponent,
    AppProjectFieldGroupAssignmentDialogComponent,
    AppPhaseDialogComponent,
    AppPhaseSectionDialogComponent,
    AppPhaseSectionMarkDialogComponent,
    AppNotificationScheduleDialogComponent,
    TransactionDialogComponent,
    AppDocumentUploadDialogComponent,
    AppDocumentSignatureWizardDialogComponent,
    InformationDialogComponent,
    AppProjectCostDialogComponent,
    AppProjectCostHistoryDialogComponent,
    ProjectPaymentDialogComponent,
    MetacomRelationContactDialogComponent,
    JobCronDialogComponent,
    AppNotificationSendDialogComponent,
    WorkActionTemplateDeployDialogComponent,
    WorkActionsChartGanttComponent,
    AsyncUrlOpenDialogComponent,
    ScanGoodsComponent,
    CargoPhaseDialogComponent,
    SupplierContactDialogComponent,
    AccessibilityPermissionExportDialogAngular,
  ],
  imports: [
    AppVendorModule,

    DeviceDetectorModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true }),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    GraphQLModule,
    HttpClientModule,
  ],
  providers: [
    AuthService,
    EntityManager,
    DrawOrderPlanService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ReconnectInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
