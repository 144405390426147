import * as React from "react";
import { AppBar, IconButton, Typography, Toolbar } from "@material-ui/core";

import {
  Today as TodayIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  CallToAction as CallToActionIcon,
} from "@material-ui/icons";

import * as moment from "moment";
import { TableLayoutContext } from "../context/table-layout.context";
import { currentWeek } from "../functions/current-week";

enum WeekToolbarComponentChange {
  Decrement,
  Baseline,
  Increment,
}

export interface WeekToolbarComponentProps {
  allowExpand?: boolean;
  onDateChange: (value: Date) => void | Promise<any>;
}

export const WeekToolbarComponent: React.FC<WeekToolbarComponentProps> = ({
  allowExpand,
  onDateChange,
}) => {
  const context = React.useContext(TableLayoutContext);
  const [dateStart, setDateStart] = React.useState(currentWeek());

  const change = (value: WeekToolbarComponentChange) => {
    let newDate = dateStart;

    switch (value) {
      case WeekToolbarComponentChange.Decrement:
        newDate = moment(dateStart).add(-1, "weeks").toDate();
        break;
      case WeekToolbarComponentChange.Baseline:
        newDate = currentWeek();
        break;
      case WeekToolbarComponentChange.Increment:
        newDate = moment(dateStart).add(1, "weeks").toDate();
        break;
    }

    setDateStart(newDate);
    onDateChange(newDate);
  };

  React.useEffect(() => {
    onDateChange(dateStart);
  }, [dateStart]);

  return (
    <AppBar className={"control-bar"} color={"default"} position="static">
      <Toolbar>
        <div>
          <Typography variant="subtitle2" className="title">
            Week {moment(dateStart).format("W")}
          </Typography>
          <Typography variant="caption" className="caption">
            {moment(dateStart).format("DD-MM-YYYY")} &bull;{" "}
            {moment(dateStart).add(5, "days").format("DD-MM-YYYY")}
          </Typography>
        </div>

        <div style={{ flexGrow: 1 }} />

        {allowExpand && (
          <IconButton
            aria-label="Expand"
            className="button"
            onClick={() => context.setProjectsVisible(!context.projectsVisible)}
          >
            <CallToActionIcon />
          </IconButton>
        )}

        <IconButton
          aria-label="Vorige pagina"
          className="button"
          onClick={() => change(WeekToolbarComponentChange.Decrement)}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
          aria-label="Deze week"
          className="button"
          onClick={() => change(WeekToolbarComponentChange.Baseline)}
        >
          <TodayIcon />
        </IconButton>
        <IconButton
          aria-label="Volgende pagina"
          className="button"
          onClick={() => change(WeekToolbarComponentChange.Increment)}
        >
          <NavigateNextIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
