import { Component, Inject } from "@angular/core";
import { EmployeeDto } from "../employees/employee.dto";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { CellaService } from "../cella.service";
import { last } from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-employee-dialog",
  templateUrl: "./employee-dialog.component.html",
  styleUrls: ["./employee-dialog.component.scss"],
})
export class EmployeeDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: EmployeeDialogComponentData,
    protected readonly cellaService: CellaService,
    protected readonly dialog: MdcDialogRef<EmployeeDialogComponent>
  ) {}

  get isNew() {
    return !this.id;
  }

  get id() {
    return this.data.employee.persoon;
  }

  get origin() {
    return this.isNew
      ? null
      : `bdr:999¡rel:${this.data.relationId}¡prs:${this.id}`;
  }

  get canSave() {
    return (
      this.data.employee.voorletters &&
      this.data.employee.voornaam &&
      this.data.employee.achternaam &&
      this.data.employee.geslacht &&
      this.data.employee.stadium &&
      this.data.employee.type &&
      this.data.employee.bhv
    );
  }

  async save() {
    if (this.isNew) {
      this.data.employee.persoon = await this.saveNew();
    }

    if (!!this.id) {
      const fieldsResponse = await this.cellaService.writeCustomFields({
        Company: "999",
        CustomField: [
          this.makeCustomField(
            "CPER-010",
            this.correctDate(this.data.employee.geboortedatum)
          ),
          this.makeCustomField("CPER-011", this.data.employee.id_type),
          this.makeCustomField("CPER-012", this.data.employee.id_nummer),
          this.makeCustomField(
            "CPER-013",
            this.correctDate(this.data.employee.id_geldig)
          ),
          this.makeCustomField("CPER-014", this.data.employee.vca_diploma),
          this.makeCustomField(
            "CPER-015",
            this.correctDate(this.data.employee.vca_geldig)
          ),
          this.makeCustomField("CPER-016", this.data.employee.type),
          this.makeCustomField(
            "CPER-017",
            this.correctDate(this.data.employee.gb_training)
          ),
          this.makeCustomField(
            "CPER-018",
            this.correctDate(this.data.employee.gb_handboek)
          ),
          this.makeCustomField("CPER-019", this.data.employee.bhv),
        ],
      });

      if (!fieldsResponse.hasError()) {
        this.dialog.close();
      }
    }
  }

  protected async saveNew() {
    const responsePerson = await this.cellaService.writePerson({
      Company: "999",
      Code: null,
      Name: this.data.employee.achternaam,
      Prefix: this.data.employee.tussenvoegsel,
      FirstName: this.data.employee.voornaam,
      LanguageCode: "1043",
      Gender: this.data.employee.geslacht,
      Initials: this.data.employee.voorletters,
      Stage: this.data.employee.stadium,
    });

    if (!responsePerson.hasError()) {
      const id = last(responsePerson.value.Origin.split(":"));

      await this.cellaService.writeContactPerson({
        Company: "999",
        Person: id,
        Relation: this.data.relationId,
      });

      return id;
    }
  }

  protected makeCustomField(code: string, value: string) {
    return {
      Entity: "0014",
      Origin: this.origin,
      Code: code,
      SerialNumber: 1,
      SerialNumberNRelation: "",
      Contents: value,
    };
  }

  protected correctDate(value) {
    return value ? moment(value).format("DD-MM-YYYY") : null;
  }
}
export class EmployeeDialogComponentData {
  constructor(readonly relationId: string, readonly employee: EmployeeDto) {}
}
