import { orderBy } from "lodash";
import { AppNotification } from "../app-notification-resources/app-notification.entity";
import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";

export class AppUser extends Entity {
  type = "app.users";

  email: string;
  telephone: string;
  google_identity: string;

  /**
   * NOTICE
   * --------------------------------------
   * This field is deprecated since app 2.x
   * --------------------------------------
   */
  facebook_identity: string;

  project_id: string;

  first_name: string;
  last_name_prefix: string;
  last_name: string;

  created_at?: Date;
  updated_at?: Date;

  get fullName() {
    return [this.first_name, this.last_name_prefix, this.last_name].join(" ");
  }

  notifications: AppNotification[];

  set __notifications__(value: AppNotification[]) {
    const items = this.entityManager
      .get(AppNotification)
      .healManyResponse(new RestResponse(value)).value;

    this.notifications = orderBy(items, (item) => item.created_at, "desc");
  }
}
