import { Injectable, ChangeDetectorRef } from "@angular/core";
import { RestService } from "./rest.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Router } from "@angular/router";
import { MdcDialog, MdcSnackbar } from "@angular-mdc/web";
import { BehaviorSubject } from "rxjs";
import { AsyncUrlOpenDialogComponent } from "./async-url-open-dialog/async-url-open-dialog.component";
import { DialogService } from "./dialog.service";

@Injectable({
  providedIn: "root",
})
export class UrlOpenService {
  readonly FETCH_TOKEN_URL = "auth/temporary-access-token";
  readonly tokenSubject: BehaviorSubject<string>;

  constructor(
    protected readonly router: Router,
    protected readonly restService: RestService,
    protected readonly deviceDetectorService: DeviceDetectorService,
    protected readonly dialog: MdcDialog,
    protected readonly dialogService: DialogService,
    protected readonly snackbar: MdcSnackbar
  ) {
    this.tokenSubject = new BehaviorSubject<string>(null);
    this.fetchToken();
  }

  get token() {
    return this.tokenSubject.value;
  }

  get isAccessible() {
    return !!this.token;
  }

  refresh() {
    return this.fetchToken();
  }

  openLocalFile(file: File, changeDetector: ChangeDetectorRef) {
    const URL = window.URL || window["webkitURL"];
    const url = URL.createObjectURL(file);

    this.dialogService.open(changeDetector, AsyncUrlOpenDialogComponent, {
      data: {
        onOpened: () =>
          this.openUrl({
            url: url,
            isNewTab: true,
          }),
      },
    });
  }

  openNew(config: {
    readonly url: string;
    readonly forceNewTab?: boolean;
    readonly withToken?: boolean;
    readonly downloadFileName?: string;
  }) {
    return this.open(
      config.url,
      config.withToken,
      config.downloadFileName,
      config.forceNewTab
    );
  }

  open(
    url: string,
    withToken?: boolean,
    fileName?: string,
    forceNewTab?: boolean
  ) {
    this.openUrl({
      url,
      isNewTab: forceNewTab,
      isTokenRequired: withToken,
      fileName,
    });
  }

  openUrl(request: OpenUrlRequest) {
    const isExternal = this.isExternal(request.url),
      isStandalone = this.isStandalone();

    if (isExternal) {
      request.isNewTab = true;
    }

    if (isStandalone && !request.isTokenRequired) {
      request.isNewTab = false;
    }

    if (request.isTokenRequired) {
      request.url = `${request.url}${this.tokenQuery}`;
    }

    var win: any = window;

    if (!request.isNewTab) {
      if (this.dialog.openDialogs.length > 0) {
        this.dialog.closeAll();
      }

      window.location.href = request.url;
    } else {
      win = window.open(request.url, "_blank");
    }

    if (request.isTokenRequired) {
      win.addEventListener("pagehide", () => this.fetchToken(true));
    }
  }

  protected get tokenQuery() {
    return "";

    //    return this.token ? `?token=${this.token}` : "";
  }

  protected async fetchToken(isForced = false) {
    // if (!this.token || isForced) {
    //   const response = await this.restService.get<{ token: string }>(
    //     this.FETCH_TOKEN_URL
    //   );
    //   if (!response.hasError()) {
    //     this.tokenSubject.next(response.value.token);
    //   }
    // }
  }

  protected isExternal(url: string) {
    return url.startsWith("http://") || url.startsWith("https://");
  }

  protected isStandalone() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      !!window["standalone"]
    );
  }

  protected trimHash(url: string) {
    return url.indexOf("#") === 0 ? url.substr(1) : url;
  }
}

export interface OpenUrlRequest {
  url: string;

  isNewTab?: boolean;
  isTokenRequired?: boolean;
  fileName?: string;
}
