import * as React from "react";
import { Injector } from "@angular/core";
import { omit } from "lodash";
import { PropsWithChildren } from "react";
import { createContext } from "react";
import { useContext } from "react";

interface InjectorContextProps {
  injector: Injector;
}

export const InjectorContext = createContext<InjectorContextProps>({
  injector: null,
});

type InjectorContextProviderProps = PropsWithChildren<InjectorContextProps>;

export const InjectorContextProvider = (
  props: InjectorContextProviderProps
) => {
  return (
    <InjectorContext.Provider value={omit(props, ["children"])}>
      {props.children}
    </InjectorContext.Provider>
  );
};

export const useInjector = () => useContext(InjectorContext).injector;
