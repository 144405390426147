import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from "../react-component/react-attuned.component";
import { WorkOrders, WorkOrdersProps } from "./WorkOrders";

@Component({
  template: reactAttunedComponentTemplate,
})
export class WorksOrderAngular extends ReactAttunedComponent<WorkOrdersProps> {
  component = WorkOrders;

  constructor(
    protected readonly injector: Injector,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector,
      changeDetectorRef: this.changeDetectorRef,
    };
  }
}
