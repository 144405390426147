import * as React from "react";
import * as moment from "moment";
import { gantt } from "dhtmlx-gantt";
import { useEffect } from "react";
import { orderBy, flatten } from "lodash";
import { WorkActionsChartGanttComponent } from "../work-actions-chart-gantt.component";

interface WorkActionsChartGanttProps {
  component: WorkActionsChartGanttComponent;
}

export const Main: React.FC<WorkActionsChartGanttProps> = ({ component }) => {
  const container = React.useRef();
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const format = (data: any[]) => {
    data = orderBy(
      data,
      [(d) => d.buildingWeek, (d) => d.projectId],
      ["asc", "asc"]
    );

    const generated = data.map((group) => {
      const eligible = group.actions.filter(
        (a) => !!a.active && !!a.active.date
      );

      const subTasks = orderBy(
        eligible,
        (e) => moment(e.active.date).unix(),
        "asc"
      ).map((action) => ({
        id: action.id,
        parent: group.id,
        text: action.title,
        end_date: moment(action.active.date).add(1, "d").format("DD-MM-YYYY"),
        duration: 1,
        progress: 0,
      }));

      return [
        {
          id: group.id,
          text: group.name,
          open: expanded.length === 0 ? true : expanded.indexOf(group.id) >= 0,
        },
        ...subTasks,
      ];
    });

    return {
      data: flatten(generated),
    };
  };

  const mount = () => {
    gantt.init(container.current as HTMLElement);
  };

  // Render on enter
  useEffect(() => {
    gantt.plugins({
      tooltip: true,
      marker: true,
    });

    gantt.config.readonly = true;
    gantt.config.show_tasks_outside_timescale = true;
    gantt.config.open_tree_initially = true;
    gantt.config.columns = [
      { name: "text", label: "Taak", tree: true, width: "*" },
    ];

    gantt.config.scales = [{ unit: "day", step: 1, format: "%D %d %M" }];
    gantt.i18n.setLocale("nl");

    // @ts-ignore
    gantt.attachEvent("onTaskClick", (id) => {
      component.workActionAdvancement(id);
      return true;
    });

    gantt.templates.tooltip_text = (start, end, task) => {
      return `<b>Taak:</b> ${task.text}<br/><b>Streefdatum:</b> ${moment(
        start
      ).format("DD-MM-YYYY")}`;
    };

    gantt.templates.task_class = (start, end, task) => {
      const className = ["task"];

      if (moment(end).isBefore(moment())) {
        className.push("task-expired");
      }

      if (!task.parent) {
        className.push("task-hide");
      }

      return className.join(" ");
    };

    gantt.attachEvent(
      "onTaskOpened",
      (id) => {
        setExpanded([...expanded, id]);
      },
      {}
    );

    gantt.attachEvent(
      "onTaskClosed",
      (id) => {
        setExpanded(expanded.filter((_id) => _id !== id));
      },
      {}
    );

    gantt.showDate(new Date());
    gantt.addMarker({
      start_date: new Date(),
      css: "marker-today",
      text: "Vandaag",
      title: moment().toString(),
    });
    gantt.config.show_markers = component.data.length !== 0;

    mount();
  }, []);

  useEffect(() => {
    setExpanded([]);

    gantt.config.start_date = moment(component.date)
      .startOf("isoWeek")
      .toDate();

    gantt.config.end_date = moment(component.date).endOf("isoWeek").toDate();
  }, [component.date]);

  useEffect(() => {
    const { x, y } = gantt.getScrollState();

    gantt.clearAll();
    gantt.parse(format(component.data));
    gantt.scrollTo(x, y);
  }, [component.data]);

  return <div className="container" ref={container} />;
};
