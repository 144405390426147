import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DrawOrder, HouseModel } from "../drawers/drawer.entity";
import {
  MDC_DIALOG_DATA,
  MdcCheckbox,
  MdcCheckboxChange,
  MdcDialog,
  MdcDialogRef,
  MdcSlider,
} from "@angular-mdc/web";
import { EntityManager, Ops } from "../entity.service";
import { EntitySelectConfig } from "../entity-select/entity-select.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import * as moment from "moment";
import { DrawersComponentSettings } from "../drawers/drawers.component";

@Component({
  selector: "app-draw-order-dialog",
  templateUrl: "./draw-order-dialog.component.html",
  styleUrls: ["./draw-order-dialog.component.scss"],
})
export class DrawOrderDialogComponent implements OnInit, AfterViewInit {
  get drawOrderDescriptionsConfig() {
    return {
      allowNothing: true,
      title: "Omschrijvingen",
      icon: "description",
      entityName: "draw_order_descriptions",
      nameField: "",
      descriptionField: "description",
      sortField: "createdAt",
      sortDirection: "ASC",
      filterFields: ["description"],
    };
  }

  get drawOrderTypeConfig() {
    return {
      allowNothing: false,
      title: "Type",
      icon: "details",
      entityName: "draw_order_types",
      nameField: null,
      descriptionField: "name",
      sortField: "name",
      sortDirection: "ASC",
      filterFields: ["name"],
      filters: [
        Ops.Field("syncSource").Equals(this.data.settings.typeSource),
        Ops.Field("condition").Equals("1000"),
      ],
    };
  }

  salesEmployeeConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "Verkoopmedewerker",
    icon: "accessibility",
    entityName: "sales_employees",
    nameField: "firstName",
    descriptionField: "name",
    sortField: "firstName",
    sortDirection: "ASC",
    filterFields: ["firstName", "name"],
    filters: [
      { field: "condition", operator: "Equal", value: "1000" },
      { field: "isActive", operator: "Equal", value: "yes" },
    ],
  };

  projectConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "Project",
    icon: "archive",
    entityName: "projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [{ field: "condition", operator: "Equal", value: "1000" }],
  };

  houseModelConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "Woningtype",
    icon: "home",
    entityName: "house_models",
    nameField: null,
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name"],
  };

  @ViewChild(MdcSlider, { static: true }) percentageCompletedSlider: MdcSlider;
  @ViewChild(MdcCheckbox, { static: true }) isCompletedCheckbox: MdcCheckbox;

  get order() {
    return this.data.order;
  }

  get houseModelService() {
    return this.entityManager.get(HouseModel);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: DrawOrderDialogComponentData,
    protected readonly ngZone: NgZone,
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialog,
    protected readonly dialogRef: MdcDialogRef<DrawOrderDialogComponent>
  ) {}

  ngOnInit() {
    this.onPercentageCompletedChanged();
  }

  ngAfterViewInit() {
    setTimeout(() => this.fixLayout(), 300);
  }

  fixLayout() {
    window.dispatchEvent(new Event("resize"));
    this.percentageCompletedSlider["_foundation"].layout();
  }

  get canSave() {
    return (
      this.order.description &&
      this.order.drawOrderTypeId &&
      this.order.houseModelId &&
      (this.data.settings.excludeSales ? true : this.order.salesEmployeeId) &&
      this.order.projectId &&
      this.order.totalHours &&
      (this.order.percentageCompleted === 100
        ? this.order.totalHoursSpend > 0
        : true)
    );
  }

  async onProjectChange() {
    const query = await this.houseModelService.queryFirst({
      filters: [Ops.Field("name").Equals(this.order.__project__.houseType)],
    });

    if (!query.hasError() && !!query.value) {
      this.order.houseModelId = query.value.id;
      this.order.__houseModel__ = query.value;
    }
  }

  onDescriptionSelect(item?: { description: string }) {
    if (item) this.order.description = item.description;
  }

  onDrawOrderTypeChange() {
    const hours = this.order.__drawOrderType__.defaultHours;

    if (hours && this.order.totalHours <= 0) {
      this.order.totalHours = hours;
    }
  }

  onIsCompletedChanged(event: MdcCheckboxChange) {
    this.order.percentageCompleted = event.checked ? 100 : 90;

    // if (event.checked) {
    //   this.order.completedAt = moment().format("YYYY-MM-DD") as any;
    // }
  }

  onPercentageCompletedChanged() {
    this.isCompletedCheckbox.checked = this.order.percentageCompleted === 100;
  }

  async delete() {
    const confirmation = await this.dialog
      .open(ConfirmDialogComponent, { escapeToClose: false })
      .afterClosed()
      .toPromise();

    if (confirmation === "accept") {
      this.order.isDeletePrompt = true;
      this.save();
    }
  }

  save() {
    if (this.order.percentageCompleted === 100) {
      this.order.completedAt = this.order.completedAt || new Date();
    } else {
      this.order.completedAt = null;
    }

    this.dialogRef.close(this.order);
  }
}

export interface DrawOrderDialogComponentData {
  order: DrawOrder;
  settings: DrawersComponentSettings;
}
