import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { EntityManager, Ops } from "../entity.service";
import { MailSchedulerEntity } from "./mail-scheduler.entity";
import { RestResponse, RestService } from "../rest.service";
import { DialogService } from "../dialog.service";
import { MailSchedulerEditorDialogComponent } from "../mail-scheduler-editor-dialog/mail-scheduler-editor-dialog.component";
import { MdcSnackbar } from "@angular-mdc/web";

@Component({
  selector: "app-mail-schedulers",
  templateUrl: "./mail-schedulers.component.html",
  styleUrls: ["./mail-schedulers.component.scss"],
})
export class MailSchedulersComponent implements OnInit {
  response: RestResponse<MailSchedulerEntity[]>;

  protected get mailSchedulerService() {
    return this.entityManager.get(MailSchedulerEntity);
  }

  constructor(
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly restService: RestService,
    protected readonly snackbar: MdcSnackbar
  ) {}

  async run(scheduler: MailSchedulerEntity) {
    await this.restService.get(`mail-scheduler/run/${scheduler.id}`);

    this.snackbar.open("Taak in de wachtrij gezet.", "Ok", { leading: true });
  }

  async edit(scheduler?: MailSchedulerEntity) {
    await this.dialogService.open(
      this.changeDetector,
      MailSchedulerEditorDialogComponent,
      {
        data: scheduler || this.mailSchedulerService.concept({}),
      }
    );
    await this.fetch();
  }

  async delete(scheduler: MailSchedulerEntity) {
    if (await this.dialogService.confirm(this.changeDetector)) {
      await this.mailSchedulerService.delete(scheduler.id);
      await this.fetch();
    }
  }

  async ngOnInit() {
    await this.fetch();
  }

  protected async fetch() {
    this.response = await this.mailSchedulerService.query({
      orders: [{ field: "description", direction: "ASC" }],
    });
  }
}
