import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";
import { AppPhaseSectionMark } from "../app-phases/app-phase.entity";
import { AppNotificationTemplate } from "../app-notification-templates/app-notification-template.entity";

export class AppNotificationSchedule extends Entity {
  type = "app.notification_schedules";

  notification: AppNotificationTemplate;

  set __notification__(value: AppNotificationTemplate) {
    this.notification = this.entityManager
      .get(AppNotificationTemplate)
      .healOneResponse(new RestResponse(value)).value;
  }

  mark: AppPhaseSectionMark;

  set __mark__(value: AppPhaseSectionMark) {
    this.mark = this.entityManager
      .get(AppPhaseSectionMark)
      .healOneResponse(new RestResponse(value)).value;
  }

  phase_section_mark_id: number;
  phase_section_mark_cancel_id: number;
  notification_template_id: string;

  days_offset: number;
  time_of_day: string;

  created_at?: Date;
  updated_at?: Date;
}
