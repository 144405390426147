import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { DocumentMeta } from "../documents/document-meta.entity";

export class EmployeeDocumentEntity extends Entity {
  type = "employee_documents";

  userId: string;
  relationId: string;
  employeeId?: string;
  documentMetaId: string;
  comment: string;
  isInternal: boolean;

  __user__?: User;
  __documentMeta__?: DocumentMeta;

  updatedAt?: Date;
  createdAt?: Date;
}
