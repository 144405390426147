import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EntityManager } from '../entity.service';
import { WorkActionCategory } from '../work-actions/work-action.entity';
import { RestResponse } from '../rest.service';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-work-action-categories-dialog',
  templateUrl: './work-action-categories-dialog.component.html',
  styleUrls: ['./work-action-categories-dialog.component.scss']
})
export class WorkActionCategoriesDialogComponent implements OnInit {
  composeDescription: string;
  response: RestResponse<WorkActionCategory[]>;

  protected get workActionCategoryService() {
    return this.entityManager.get(WorkActionCategory);
  }

  constructor(
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
  ) { }

  async ngOnInit() {
    await this.fetch();
  }

  async delete(item: WorkActionCategory) {
    if (await this.dialogService.confirm(this.changeDetector)) {
      if (!(await this.workActionCategoryService.delete(item.id)).hasError()) {
        this.fetch();
      }
    }
  }

  async add() {
    const concept = this.workActionCategoryService.concept({
      description: this.composeDescription,
    });

    if (await this.workActionCategoryService.save(concept)) {
      this.composeDescription = null;
      await this.fetch();
    }
  }

  protected async fetch() {
    this.response = await this.workActionCategoryService.query({
      orders: [{ field: 'description', direction: 'asc' }]
    });
  }
}
