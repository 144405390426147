import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  ComplexTableDataRow,
  ComplexTableDataGroup,
  ComplexTableColumn,
  ComplexTableAction,
  ComplexTableActionClicked,
} from "../complex-table/complex-table.component";
import { MetacomService } from "../metacom.service";
import { formatNumber } from "@angular/common";
import { DialogService } from "../dialog.service";
import { InvoiceJudgeDialogComponent } from "../invoice-judge-dialog/invoice-judge-dialog.component";
import { AuthService } from "../auth.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import { Invoice } from "./invoice.entity";
import * as moment from "moment";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
  tableData: ComplexTableDataGroup<Invoice>[];

  columns = [
    new ComplexTableColumn<Invoice>({
      id: "id",
      title: "Nummer",
      width: "100px",
      text: (value) => value.factuur_nummer,
    }),
    new ComplexTableColumn<Invoice>({
      id: "executive",
      title: "Uitvoerende",
      width: "auto",
      text: (value) => `${value.relatie} - ${value.relatie_naam}`,
      sort: (value) => `${value.relatie_naam}`,
    }),
    new ComplexTableColumn<Invoice>({
      id: "date",
      title: "Datum",
      width: "128px",
      text: (value) => value.factuur_datum,
      sort: (value) =>
        value.factuur_datum
          ? moment(value.factuur_datum, "DD-MM-YYYY", true).unix()
          : 0,
    }),
    new ComplexTableColumn<Invoice>({
      id: "description",
      title: "Omschrijving",
      width: "auto",
      text: (value) => value.omschrijving,
      note: (item) => item.factuur_opmerking,
    }),
    new ComplexTableColumn<Invoice>({
      id: "sum",
      title: "Bedrag",
      width: "100px",
      align: "right",
      text: (value) => formatNumber(value.factuur_bedrag, "nl", "2.2-2"),
    }),
  ];

  actions = [
    new ComplexTableAction<Invoice>({
      id: "view",
      icon: "remove_red_eye",
      title: "Factuur weergeven",
    }),
    new ComplexTableAction<Invoice>({
      id: "judge",
      icon: "gavel",
      title: "Goedkeuren",
    }),
  ];

  protected get relationId() {
    return this.authService.user.id;
  }

  constructor(
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly metacomService: MetacomService
  ) {}

  ngOnInit() {
    this.fetch();
  }

  action(event: ComplexTableActionClicked<Invoice>) {
    switch (event.action.id) {
      case "judge":
        return this.judge(event.item);
      case "view":
        return this.view(event.item);
    }
  }

  protected async judge(invoice: Invoice) {
    const response = await this.dialogService.open(
      this.changeDetector,
      InvoiceJudgeDialogComponent,
      {
        data: invoice,
      }
    );

    if (response !== "close") {
      this.fetch();
    }
  }

  protected async view(invoice: Invoice) {
    const base64 = btoa(invoice.document);

    this.urlOpenService.open(
      `${environment.baseUrl}/volume-public/file-legacy/0/${base64}`,
      true
    );
  }

  protected async fetch() {
    this.tableData = null;

    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "facturen_controle",
      filter: `mtc_rubtab.inhoud = "${this.relationId}"`,
    });

    if (!response.hasError()) {
      this.tableData = [
        new ComplexTableDataGroup<Invoice>(
          response.value
            .map((e) => new Invoice(e))
            .map((e) => new InvoiceTableRow(e))
        ),
      ];
    }
  }
}

export class InvoiceTableRow extends ComplexTableDataRow<Invoice> {
  constructor(invoice: Invoice) {
    super(invoice);
  }
}
