import { Entity } from "../entity.service";

export class AppDocumentType extends Entity {
  type = "app.document_types";

  name: string;
  description?: string;
  color: string;
  document_kind: "photo" | "document";

  project_contact_type?: string;
  special_access?: boolean;

  order_id: number;

  created_at?: Date;
  updated_at?: Date;
}
