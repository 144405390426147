import { Component, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-async-url-open-dialog",
  templateUrl: "./async-url-open-dialog.component.html",
  styleUrls: ["./async-url-open-dialog.component.scss"],
})
export class AsyncUrlOpenDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AsyncUrlOpenDialogComponentProps,
    readonly sanitizer: DomSanitizer,
    readonly ref: MdcDialogRef<AsyncUrlOpenDialogComponent>
  ) {}

  open() {
    this.data.onOpened();
    this.ref.close();
  }
}

export interface AsyncUrlOpenDialogComponentProps {
  onOpened: () => void | Promise<any>;
}
