import gql from "graphql-tag";

export const constructionPlanningQuery = gql`
  query constructionPlanningQuery($filters: [EntityQueryFilter!]!) {
    blockedWeeks: BlockedCalendarWeekEntityFindMany {
      id
    }
    lines: YearPlanningLineEntityFindMany(
      query: {
        filters: [
          {
            field: "visibleOnConstructionPlanning"
            operator: "Equal"
            valueComplex: true
          }
        ]
      }
    ) {
      description
      colorCode
      weeks
    }
    items: ProjectEntityFindMany(
      query: {
        filters: $filters
        orders: [{ field: "buildingWeek", direction: "ASC" }]
      }
    ) {
      id
      regionId
      description
      buildingWeek
      longitude
      latitude
      foundation
      salesEmployeeId
      constructionProbeWork
      constructionAdvice
      constructionDrawingWork
      constructionSupplier
      constructionLevel
      constructionUnavailable
      cachedValue {
        dateValues
        dateGroupComments
      }
    }
    dates: DateEntityFindMany(
      query: {
        filters: [
          {
            field: "visibleOnConstructionPlanning"
            operator: "Equal"
            valueComplex: true
          }
        ]
      }
    ) {
      id
      dateGroupId
      description

      iconId
      stackId
      colorCode
      dateFormat
      isSynced
      orderId
      hash
      phaseSectionMarkId
      visibleOnDatePassed
    }
    commentDateGroup: DateGroupEntityFindOne(
      query: {
        filters: [
          {
            field: "visibleOnConstructionPlanning"
            operator: "Equal"
            valueComplex: true
          }
        ]
      }
    ) {
      id
    }
  }
`;
