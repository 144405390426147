import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { RestService, RestResponse } from "../rest.service";
import { MdcSnackbar, MdcDialog } from "@angular-mdc/web";
import { UrlOpenService } from "../url-open.service";
import { Job } from "./job.entity";
import { environment } from "../../environments/environment";
import { DialogService } from "../dialog.service";
import { JobCronDialogComponent } from "../job-cron-dialog/job-cron-dialog.component";

@Component({
  selector: "app-jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent implements OnInit {
  response: RestResponse<Job[]>;

  constructor(
    protected readonly snacks: MdcSnackbar,
    protected readonly dialogs: DialogService,
    protected readonly rest: RestService,
    protected readonly changes: ChangeDetectorRef,
    protected readonly urls: UrlOpenService
  ) {}

  ngOnInit() {
    this.fetchJobs();
  }

  openDashboard() {
    this.urls.openNew({
      url: environment.jobDashboardUrl,
      forceNewTab: true,
      withToken: false,
    });
  }

  getTarget(job: Job) {
    return [
      job.target,
      ...(job.others ?? []).map((o) => `${o.targetConnection}.${o.target}`),
    ].join(", ");
  }

  async edit(job: Job) {
    await this.dialogs.open(this.changes, JobCronDialogComponent, {
      data: job,
    });

    await this.rest.get<boolean>(`syncs/update-crons`);
    await this.fetchJobs();
  }

  async enqueue(job: Job) {
    const response = await this.rest.get<boolean>(
      `syncs/enqueue/${job.source}`
    );

    if (!response.hasError() && response.value) {
      this.snacks.open("Taak in de wachtrij gezet", "Ok", {
        leading: true,
        timeoutMs: 4000,
      });
    }
  }

  protected async fetchJobs() {
    this.response = await this.rest.get<Job[]>("syncs");
  }
}
