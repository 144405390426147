import { HourDeclaration } from './hour-declaration.entity';
import { HourDeclarationConverter } from './hour-declaration.converter';
import { HourDeclarationParams } from './hour-declaration.params';
import * as moment from 'moment';

export class HourDeclarationDocument {
    readonly DATE_FORMAT = 'YYYY-MM-DD';

    constructor(
        protected readonly params: HourDeclarationParams,
        protected readonly declarations: HourDeclaration[],
    ) { }

    createInsertDocument() {
        return ({
            Company: this.params.companyId,
            DocumentType: this.params.documentInsertType,
            DocumentDate: moment().format(this.DATE_FORMAT),
            DocumentNumber: this.params.documentInsertNumber,
            DocumentDescription: this.params.documentDescription,
            DocumentLines: Array.from(this.makeInsertLines()),
        });
    }

    createCommitDocument() {
        return ({
            Company: this.params.companyId,
            DocumentType: this.params.documentCommitType,
            DocumentDate: moment().format(this.DATE_FORMAT),
            DocumentDescription: this.params.documentDescription,
            DocumentLines: Array.from(this.makeCommitLines()),
        });
    }

    protected * makeInsertLines() {
        const helpers = this.declarations
            .map(e => new HourDeclarationConverter(e, this.params));

        for (const item of helpers) {
            yield* (Array.from(item.makeInsertLines()))
                .filter(e => parseFloat(e.FromQuantity) !== 0);
        }
    }

    protected makeCommitLines() {
        return this.declarations
            .map(e => new HourDeclarationConverter(e, this.params))
            .map(e => e.makeCommitLine())
            .filter(e => parseFloat(e.FromQuantity) !== 0);
    }
}
