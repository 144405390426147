import { Entity } from "src/app/entity.service";

export class TableLayoutComment extends Entity {
  type = "table_layout_comments";

  projectId: string;
  taskId: string;
  userId: string;
  comment: string;
}
