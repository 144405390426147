import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { EntityManager } from "../entity.service";
import {
  EntityAdvancement,
  EntityImage,
} from "../offers/entity.advancement.entity";
import { RestResponse, RestService } from "../rest.service";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import {
  ComplexTableColumn,
  ComplexTableDataGroup,
  ComplexTableDataRow,
} from "../complex-table/complex-table.component";
import { AuthService } from "../auth.service";
import { TransactionService } from "../transaction.service";
import {
  FileUploadService,
  AttunedFile,
  AttunedFileResponse,
} from "../file-upload.service";
import { DocumentMeta } from "../documents/document-meta.entity";
import { DialogService } from "../dialog.service";
import {
  ImageSlidershowItem,
  ImageSlideshowDialogComponent,
  ImageSlideshowDialogComponentData,
} from "../image-slideshow-dialog/image-slideshow-dialog.component";
import * as moment from "moment";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
import {
  UploadProgressDialogComponent,
  UploadProgressDialogComponentData,
} from "../upload-progress-dialog/upload-progress-dialog.component";
import { last } from "lodash";
import * as React from "react";
import * as ReactDOM from "react-dom";

@Component({
  selector: "app-entity-advancement-dialog",
  templateUrl: "./entity-advancement-dialog.component.html",
  styleUrls: ["./entity-advancement-dialog.component.scss"],
})
export class EntityAdvancementDialogComponent implements OnInit {
  @ViewChild("anchor", { static: false }) anchor: ElementRef;

  composeDate: string;
  composeComment: string;
  composeCompleted: boolean;

  columns = [
    new ComplexTableColumn<EntityAdvancement>({
      id: "createdAt",
      title: "Datum",
      width: "128px",
      text: (value) => moment(value.createdAt).format("DD-MM-YYYY"),
      sort: (value) => moment(value.createdAt).unix(),
    }),
    new ComplexTableColumn<EntityAdvancement>({
      id: "user",
      title: "Gemeld door",
      width: "192px",
      text: (value) => value.__user__.name,
    }),
    new ComplexTableColumn<EntityAdvancement>({
      id: "comment",
      title: "Opmerking",
      width: "80%",
      wrap: true,
      text: (value) => value.comment,
    }),
    new ComplexTableColumn<EntityAdvancement>({
      id: "actionDate",
      title: this.dateTitle,
      width: "128px",
      text: (value) =>
        value.date ? moment(value.date).format("DD-MM-YYYY") : "-",
      sort: (value) => (value.date ? moment(value.date).unix() : 0),
    }),
  ];

  response: RestResponse<EntityAdvancement[]>;
  responseImages: RestResponse<EntityImage[]>;

  tableData: ComplexTableDataGroup<EntityAdvancement>[];

  get acceptedMimeTypes() {
    const mimes = ["image/*"];

    if (this.options.props.enableDocuments) {
      mimes.push("application/pdf");
    }

    return mimes.join(",");
  }

  get dateTitle() {
    return this.data.overwriteDateTitle || "Streefdatum";
  }

  get isCommentFilled() {
    return (
      !!this.composeComment ||
      (this.composeCompleted && this.options.props.allowEmptyDescription)
    );
  }

  get canSave() {
    return (
      this.isCommentFilled &&
      (this.data.overwriteDateOptional ? true : !!this.composeDate)
    );
  }

  protected get entityImageService() {
    return this.entityManager.get(EntityImage);
  }

  protected get entityAdvancementService() {
    return this.entityManager.get(EntityAdvancement);
  }

  get isCompleted() {
    return this.response
      ? !!this.response.value.find((e) => e.isCompleted)
      : false;
  }

  get options() {
    return (
      this.data.options ||
      new EntityAdvancementDialogComponentDataOptions({
        disableImages: false,
        allowEmptyDescription: false,
      })
    );
  }

  get fields() {
    return this.data.fields;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: EntityAdvancementDialogComponentData,
    protected readonly snackbar: MdcSnackbar,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly entityManager: EntityManager,
    protected readonly transactionService: TransactionService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly fileUploadService: FileUploadService,
    protected readonly dialogRef: MdcDialogRef<EntityAdvancementDialogComponent>
  ) {}

  async ngOnInit() {
    await this.fetch();

    this.renderExtensions();
    this.setAimDate();
  }

  renderExtensions() {
    const ext = this.data.extensions;

    if (ext && ext.props) {
      const container = document.getElementById("react--extension");

      if (container) {
        ReactDOM.render(
          React.createElement(
            ext.props.component,
            Object.assign(
              {
                composeCompleted: this.composeCompleted,
              },
              ext.props.componentProperties
            )
          ),
          container
        );
      }
    }
  }

  async uploadFile(files: NgxFileDropEntry[]) {
    const promises = files
      .filter((e) => e.fileEntry.isFile)
      .map((e) => e.fileEntry as FileSystemFileEntry)
      .map((e) => this.destructFile(e));

    const filesToUpload = (await Promise.all(promises)).map(
      (e) => new AttunedFile(e, this.data.projectId)
    );

    if (filesToUpload.length > 0) {
      const fileResponses: AttunedFileResponse[] =
        await this.dialogService.open(
          this.changeDetector,
          UploadProgressDialogComponent,
          {
            data: new UploadProgressDialogComponentData(filesToUpload),
          }
        );

      const concepts = fileResponses
        .filter((e) => e.response)
        .map((e) => this.createConcept(e.response));

      await Promise.all(concepts.map((c) => this.entityImageService.save(c)))
        .then((d) => d.filter((e) => !e.hasError()).map((e) => e.value))
        .then((d) => d.map((e) => this.setImageUrl(e)))
        .then((d) => this.responseImages.value.unshift(...d))
        .then(() =>
          this.data.overwriteOnImageAdded
            ? this.data.overwriteOnImageAdded()
            : Promise.resolve()
        )
        .then(() => this.snackbar.open("Opgeslagen", "Ok"));
    }
  }

  protected destructFile(entry: FileSystemFileEntry) {
    return new Promise<File>((resolve) => entry.file((f) => resolve(f)));
  }

  async add() {
    const concept = this.entityAdvancementService.concept({
      entityId: this.data.entityId,
      entityType: this.data.entityType,
      __user__: this.authService.user,
      userId: this.authService.user.id,
      comment: this.composeComment,
      date: this.composeDate,
      isCompleted: this.composeCompleted,
    });

    if (this.data.extensions) {
      await this.data.extensions.props.beforeSave(concept);
    }

    const result = await this.transactionService.perform(() =>
      this.data.overwriteStore
        ? this.data.overwriteStore(concept)
        : this.entityAdvancementService.save(concept)
    );

    if (!result.hasError()) {
      result.value.isNew = true;
      this.response.value.push(result.value);

      this.composeComment = "";
      this.setAimDate();
      this.update();
    }
  }

  async gallery() {
    const images = this.responseImages.value.map<ImageSlidershowItem>((i) => ({
      thumbUrl: i.thumbUrl,
      imageUrl: i.imageUrl,
      createdAt: i.createdAt,
      isPdf: i.isPdf,
      description: i.__documentMeta__?.description ?? "Onbekend",
      documentMetaId: i.documentMetaId,
    }));

    if (images.length) {
      await this.dialogService.open(
        this.changeDetector,
        ImageSlideshowDialogComponent,
        {
          data: new ImageSlideshowDialogComponentData(images),
          clickOutsideToClose: false,
          escapeToClose: false,
        }
      );
    }
  }

  close() {
    this.dialogRef.close(this.response.value);
  }

  protected async fetch() {
    this.response = this.data.overwriteItems
      ? new RestResponse(this.data.overwriteItems)
      : await this.entityAdvancementService.query({
          filters: [
            {
              field: "entityType",
              operator: "Equal",
              value: this.data.entityType,
            },
            { field: "entityId", operator: "Equal", value: this.data.entityId },
          ],
          relations: ["user"],
        });

    const responseImages = await this.entityImageService.query({
      filters: [
        { field: "entityType", operator: "Equal", value: this.data.entityType },
        { field: "entityId", operator: "Equal", value: this.data.entityId },
      ],
      relations: ["user", "documentMeta"],
    });

    if (!responseImages.hasError()) {
      responseImages.value.forEach((i) => this.setImageUrl(i));
      this.responseImages = responseImages;
    } else {
      this.responseImages = new RestResponse([]);
    }

    this.update();
  }

  protected setAimDate() {
    if (!this.data.overwriteDateOptional) {
      this.composeDate = moment(this.getLatestDate() || new Date()).format(
        "YYYY-MM-DD"
      );
    }
  }

  protected update() {
    this.tableData = [
      new ComplexTableDataGroup(
        this.response.value.map((e) => new ComplexTableDataRow(e))
      ),
    ];
  }

  protected createConcept(documentMeta: DocumentMeta) {
    return this.entityImageService.concept({
      entityId: this.data.entityId,
      entityType: this.data.entityType,
      __user__: this.authService.user,
      userId: this.authService.user.id,
      documentMetaId: documentMeta.id,
      __documentMeta__: documentMeta,
    });
  }

  protected setImageUrl(image: EntityImage) {
    image.thumbUrl = image.isPdf
      ? `${window.origin}/assets/imgs/pdf.png`
      : `documents/thumb/${image.documentMetaId}`;

    image.imageUrl = `documents/open/${image.documentMetaId}`;

    return image;
  }

  protected getLatestDate() {
    const date =
      this.response && this.response.value
        ? last(this.response.value).date
        : null;

    return date && moment(date).isAfter(moment()) ? date : null;
  }
}

export class EntityAdvancementDialogComponentData {
  constructor(
    readonly entityId: string,
    readonly entityType: string,
    readonly title: string,
    readonly description: string,
    readonly projectId: string,
    readonly showCompleteButton: boolean = false,

    readonly overwriteItems: EntityAdvancement[] = null,
    readonly overwriteStore: (
      item: EntityAdvancement
    ) => Promise<RestResponse<any>> = null,
    readonly overwriteDateTitle: string = null,
    readonly overwriteDateOptional: boolean = false,
    readonly overwriteOnImageAdded: () => Promise<any> = null,
    readonly options: EntityAdvancementDialogComponentDataOptions = null,
    readonly extensions: EntityAdvancementDialogComponentDataExtentions = null,
    readonly fields: EntityAdvancementDialogComponentField[] = []
  ) {}
}

export interface EntityAdvancementDialogComponentField {
  key: string;
  value: string;
}

export class EntityAdvancementDialogComponentDataExtentions {
  constructor(
    readonly props: {
      readonly component: any;
      readonly componentProperties: EntityAdvancementExtensionProps;
      readonly beforeSave?: (
        advancement: EntityAdvancement
      ) => void | Promise<any>;
    }
  ) {}
}

export interface EntityAdvancementExtensionProps {
  composeCompleted?: boolean;
}

export class EntityAdvancementDialogComponentDataOptions {
  constructor(
    readonly props: {
      readonly disableImages: boolean;
      readonly allowEmptyDescription: boolean;
      readonly enableDocuments?: boolean;
    }
  ) {}
}
