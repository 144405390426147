import { LogicField } from "../year-planning/entities/date-entities";

export class LogicFieldExtended {
  public isDirty: boolean;
  public isPendingDeletion: boolean;

  constructor(public readonly value: LogicField) {
    this.isDirty = !value.id;
  }

  toggleDeletion() {
    this.isPendingDeletion = !this.isPendingDeletion;
  }

  markDirty() {
    this.isDirty = true;
  }

  set orderId(value: number) {
    this.value.orderId = value;
    this.isDirty = true;
  }
}
