import { Apollo } from "apollo-angular";
import { useState } from "react";
import { MechanicDto } from "./dtos/mechanic.dto";
import { ServiceEventDto } from "./dtos/service-event.dto";
import {
  servicePlanningFetchEventsQuery,
  servicePlanningFetchQuery,
  servicePlanningFetchRelatedEventsQuery,
} from "./service-planning.query";

export const useFetchServicePlanningMechanics = (apollo: Apollo) => {
  const [state, setState] = useState<{
    data: {
      mechanics: MechanicDto[];
    };
    errors: any;
    loading: boolean;
  }>({
    data: undefined,
    errors: [],
    loading: false,
  });

  return {
    data: state.data,
    loading: state.loading,
    fetchServicePlanningMechanics: async () => {
      setState({ ...state, loading: true });

      const response = await apollo
        .query<{
          mechanics: MechanicDto[];
        }>({ query: servicePlanningFetchQuery })
        .toPromise();

      if (!response.errors) {
        setState({ ...state, data: response.data, loading: false });
      }
    },
  };
};

export const useFetchServicePlanningEvents = (apollo: Apollo) => {
  const [state, setState] = useState<{
    data: {
      events: ServiceEventDto[];
    };
    errors: any;
    loading: boolean;
  }>({
    data: undefined,
    errors: [],
    loading: false,
  });

  return {
    data: state.data,
    loading: state.loading,
    fetchServicePlanningEvents: async (startDate: Date, endDate: Date) => {
      setState({ ...state, loading: true });

      const response = await apollo
        .query<{
          events: ServiceEventDto[];
        }>({
          query: servicePlanningFetchEventsQuery,
          variables: {
            dateRange: [startDate, endDate],
          },
        })
        .toPromise();

      if (!response.errors) {
        setState({ ...state, data: response.data, loading: false });
      }
    },
  };
};

export const useFetchServicePlanningFetchRelatedEvents = (apollo: Apollo) => {
  const [state, setState] = useState<{
    data: {
      related: ServiceEventDto[];
    };
    errors: any;
    loading: boolean;
  }>({
    data: undefined,
    errors: [],
    loading: false,
  });

  return {
    data: state.data,
    loading: state.loading,
    fetchServicePlanningFetchRelatedEvents: async (ticketId: string) => {
      setState({ ...state, loading: true });

      const response = await apollo
        .query<{
          related: ServiceEventDto[];
        }>({
          query: servicePlanningFetchRelatedEventsQuery,
          variables: {
            ticketId,
          },
        })
        .toPromise();

      if (!response.errors) {
        setState({ ...state, data: response.data, loading: false });
      }
    },
  };
};
