import { CompletionLoadSuccessState } from "./completion.state";

export class CompletionEvent {}

export class CompletionLoadRequestEvent extends CompletionEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class CompletionGeneratePdfRequestEvent extends CompletionEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class CompletionMakeFinalRequestEvent extends CompletionEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}
