import { Bloc } from '@comceptum-software/react-bloc';
import { Apollo } from 'apollo-angular';
import { omit } from 'lodash';
import { ServiceCategoryEntity } from '../delivery-list/delivery-list.entity';
import { CustomerQuestionDto } from './customer-question.dto';
import {
  CustomerQuestionsEvent,
  CustomerQuestionsLoadRequestEvent,
  CustomerQuestionsReadyRequestEvent,
  CustomerQuestionsReopenRequestEvent,
  CustomerQuestionsTicketRequestEvent,
  CustomerQuestionsUpdateRequestEvent,
} from './customer-questions.event';
import { customerQuestionsUpdateMutation } from './customer-questions.mutation';
import { customerQuestionsOverviewQuery } from './customer-questions.query';
import { CustomerQuestionsLoadingState, CustomerQuestionsState, CustomerQuestionsSuccessState } from './customer-questions.state';

export class CustomerQuestionsBloc extends Bloc<CustomerQuestionsEvent,
  CustomerQuestionsState> {
  constructor(protected readonly apollo: Apollo) {
    super(new CustomerQuestionsLoadingState());
  }

  async* mapEventToState(event: CustomerQuestionsEvent) {
    if (event instanceof CustomerQuestionsLoadRequestEvent) {
      yield* this._mapLoadRequestEventToState(event);
    }

    if (event instanceof CustomerQuestionsReadyRequestEvent) {
      yield* this._mapReadyRequestEventToState(event);
    }

    if (event instanceof CustomerQuestionsUpdateRequestEvent) {
      yield* this._mapUpdateRequestEventToState(event);
    }

    if (event instanceof CustomerQuestionsReopenRequestEvent) {
      yield* this._mapReopenRequestEventToState(event);
    }

    if (event instanceof CustomerQuestionsTicketRequestEvent) {
      yield* this._mapTicketRequestEventToState(event);
    }

  }

  protected async* _mapLoadRequestEventToState(
    event: CustomerQuestionsLoadRequestEvent,
  ) {
    const _state = this.state.value;

    if (_state instanceof CustomerQuestionsSuccessState) {
      yield _state.copyWith({
        isLoading: true,
        questions: [],
      });
    }

    const response = await this.apollo
      .query<{
        categories: ServiceCategoryEntity[];
        questions: CustomerQuestionDto[];
      }>({
        query: customerQuestionsOverviewQuery,
        variables: {
          query: {
            filters: [{
              field: 'createdAt',
              operator: 'Between',
              valueComplex: [event.filter.dateFrom, event.filter.dateTo],
            },
              ...event.filter.withCompleted ? [] : [{
                field: 'completedAt',
                operator: 'IsNull',
              }],
            ],
          },
        },
      })
      .toPromise();

    yield new CustomerQuestionsSuccessState({
      ...response.data,
      filter: event.filter,
      isLoading: false,
    });
  }

  protected async* _mapReadyRequestEventToState(
    event: CustomerQuestionsReadyRequestEvent,
  ) {
    const _state = this.state.value;

    if (_state instanceof CustomerQuestionsSuccessState) {
      const response = await this.apollo
        .mutate({
          mutation: customerQuestionsUpdateMutation,
          variables: {
            input: {
              ...omit(event.question, '__typename'),
              internalComment: event.internalComment,
              completedAt: new Date(),
            },
          },
        })
        .toPromise();

      if (response.errors === undefined) {
        yield _state.copyWith({
          ticketComposerEnabled: false,
          ticketComposer: null,
          version: Math.random().toString(),
          questions: _state.questions
            .map((_question) => {
              if (_question.id === event.question.id) {
                _question.completedAt = new Date();
              }

              return { ..._question };
            })
            .filter(_question => _state.filter.withCompleted ? true : (!_question.completedAt)),
        });
      }
    }
  }

  protected async* _mapUpdateRequestEventToState(
    event: CustomerQuestionsUpdateRequestEvent,
  ) {
    const _state = this.state.value;

    if (_state instanceof CustomerQuestionsSuccessState) {
      const response = await this.apollo
        .mutate({
          mutation: customerQuestionsUpdateMutation,
          variables: {
            input: {
              ...omit(event.question, '__typename'),
              internalComment: event.internalComment,
            },
          },
        })
        .toPromise();

      if (response.errors === undefined) {
        yield _state.copyWith({
          ticketComposerEnabled: false,
          ticketComposer: null,
          version: Math.random().toString(),
          questions: _state.questions
            .map((_question) => {
              if (_question.id === event.question.id) {
                _question.internalComment = event.internalComment;
              }

              return { ..._question };
            }),
        });
      }
    }
  }

  protected async* _mapReopenRequestEventToState(
    event: CustomerQuestionsReopenRequestEvent,
  ) {
    const _state = this.state.value;

    if (_state instanceof CustomerQuestionsSuccessState) {
      const response = await this.apollo
        .mutate({
          mutation: customerQuestionsUpdateMutation,
          variables: {
            input: {
              ...omit(event.question, '__typename'),
              completedAt: null,
            },
          },
        })
        .toPromise();

      if (response.errors === undefined) {
        yield _state.copyWith({
          ticketComposerEnabled: false,
          ticketComposer: null,
          version: Math.random().toString(),
          questions: _state.questions
            .map((_question) => {
              if (_question.id === event.question.id) {
                _question.completedAt = null;
              }

              return { ..._question };
            }),
        });
      }
    }
  }

  protected async* _mapTicketRequestEventToState(
    event: CustomerQuestionsTicketRequestEvent,
  ) {
    const _state = this.state.value;

    if (_state instanceof CustomerQuestionsSuccessState) {
      yield _state.copyWith({
        ticketComposerEnabled: !(event.question === null),
        ticketComposer: event.question,
      });
    }
  }
}
