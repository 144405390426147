import { DeliveryListMeterEntity } from "src/app/delivery-list/delivery-list.entity";
import { Entity } from "src/app/entity.service";

export class MeterEntity extends Entity {
  type = "service_meters";

  unit: string;
  description: string;

  deliveryListMeter?: DeliveryListMeterEntity;

  orderId: number;

  createdAt: Date;
}
