import * as React from "react";
import { Injector } from "@angular/core";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { ServicePlanningComponent } from "../service-planning.component";
import { SchedulerComponentDefaults } from "./SchedulerComponent";
import { Button } from "@material-ui/core";

export interface SchedulerQuickDialogComponentProps {
  injector: Injector;
  defaults: SchedulerComponentDefaults;
  onClosed: () => void | Promise<void>;
}

export function SchedulerQuickDialogComponent({
  injector,
  defaults,
  onClosed,
}: SchedulerQuickDialogComponentProps) {
  return (
    <Dialog
      open
      maxWidth={false}
      fullScreen
      style={{ zIndex: 105, height: "100%" }}
      onClose={onClosed}
    >
      <DialogContent>
        <ServicePlanningComponent injector={injector} defaults={defaults} />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClosed}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
}
