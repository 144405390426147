import { Entity } from "../entity.service";
import { Project } from "../project/project.entity";

export class Drawer extends Entity {
  type = "drawers";

  alias: string;

  orders?: DrawOrder[];
  absents?: DrawerAbsent[];

  isChecked?: boolean;
}

export class DrawerAbsent extends Entity {
  type = "drawer_absents";

  drawerId?: string;
  conceptDrawerIds?: string[];
  isDeletePrompt?: boolean;

  reason: string;
  date: any;
  hours: number;
  color?: string;

  isCopy?: boolean;
  isRecurringCopy?: boolean;
}

export class DrawOrder extends Entity {
  type = "draw_orders";

  kind: string;
  projectId: string;
  drawOrderTypeId: string;
  salesEmployeeId: string;
  houseModelId: string;

  drawerId?: string;
  __drawer__: Drawer;

  description: string;
  memo?: string;
  memoLong?: string;

  completedAt?: Date;

  plannedAt?: Date;
  plannedAtOrder?: number;
  plannedAtCorrected?: boolean;

  totalHoursSpend?: number;
  totalHours: number;

  percentageCompleted?: number;

  /** Virtual Fields */
  isError?: boolean;
  isFrozen?: boolean;
  isFocussed?: boolean;
  isTooltipLoading?: boolean;
  isTooltipLoaded?: boolean;
  isDragging?: boolean;
  isPeeker?: boolean;
  isDeletePrompt?: boolean;
  isAdjective?: boolean;

  absent?: DrawerAbsent;
  errorDate?: Date;
  errorDateOrder?: number;

  __project__?: Project;
  __houseModel__?: HouseModel;
  __salesEmployee__?: SalesEmployee;
  __drawOrderType__?: DrawOrderType;
}

export class SalesEmployee extends Entity {
  type = "sales_employees";

  id: string;
  name: string;
}

export class DrawOrderType extends Entity {
  type = "draw_order_types";

  id: string;
  name: string;
  defaultHours?: number;
}

export class HouseModel extends Entity {
  type = "house_models";

  id: string;
  name: string;
}
