import { Component, Inject, OnInit } from "@angular/core";
import { AppContent } from "../app-contents/app-content.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { first } from "lodash";
import { environment } from "src/environments/environment";
import { AppService } from "../app.service";

@Component({
  selector: "app-app-content-dialog",
  templateUrl: "./app-content-dialog.component.html",
  styleUrls: ["./app-content-dialog.component.scss"],
})
export class AppContentDialogComponent
  extends AppCrudDialogBoilerplate<AppContent>
  implements OnInit {
  get canSave() {
    return !!this.data.model.title && !!this.data.model.html;
  }

  editorOptions = null;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppContent>,
    protected readonly dialog: MdcDialogRef<AppContentDialogComponent>,
    protected readonly app: AppService
  ) {
    super(data, dialog);
  }

  async ngOnInit() {
    this.editorOptions = await this.composeOptions();
  }

  save() {
    this.data.model.html = first(
      this.data.model.html.split('<p data-f-id="pbf"')
    );

    return super.save();
  }

  protected async composeOptions() {
    const token = await this.app.refreshToken(),
      allowUpload = !!token;
    const url = token
      ? `${environment.appApiBaseUrl}/api/content-attachments`
      : null;

    return {
      fullPage: false,
      imageManager: allowUpload,
      imageManagerLoadURL: url,
      imageManagerDeleteURL: url,
      imageManagerDeleteMethod: "DELETE",
      imageUpload: allowUpload,
      imageUploadURL: url,
      imageUploadParams: { type: "image" },
      imageMaxSize: 1024 * 1024 * 32,
      videoUpload: allowUpload,
      videoUploadURL: url,
      videoUploadParams: { type: "video" },
      fileUpload: allowUpload,
      fileUploadURL: url,
      fileUploadParams: { type: "file" },
      events: {
        "froalaEditor.image.error": (e, editor, error, response) => {
          console.log(error);
        },
      },
      fontFamily: {
        "Roboto,sans-serif": "Roboto",
        "Montserrat,sans-serif": "Montserrat",
      },
      requestHeaders: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      fontFamilySelection: true,
    };
  }
}
