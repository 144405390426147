import { Injectable } from "@angular/core";
import { RestService } from "./rest.service";

@Injectable({
  providedIn: "root",
})
export class CellaService {
  constructor(protected readonly restService: RestService) {}

  async mutex(entity: string, origin: string) {
    const response = await this.restService.get<Mutex[]>("api/mutex");

    return response.value?.find(
      (mu) => mu.mutex.indexOf(`${entity},${origin}`) >= 0
    );
  }

  async writeCustomFields(request: MetacomCustomFieldRequest) {
    return await this.restService.post<any>(
      "cella/metacom/write-custom-fields",
      request
    );
  }

  async createTransactionDocument(request: MetacomTransactionDocumentRequest) {
    return await this.restService.post<{ CreatedNumberDocument: string }>(
      "cella/metacom/create-transaction-document",
      request
    );
  }

  async writeTransactionDocument(request: MetacomTransactionDocumentRequest) {
    return await this.restService.post<{ CreatedDocumentNumber: string }>(
      "cella/metacom/write-transaction-document",
      request
    );
  }

  async setStage(request: MetacomStageRequest) {
    return await this.restService.post<any>("cella/metacom/set-stage", request);
  }

  async approveInvoice(request: MetacomInvoiceApprovalRequest) {
    return await this.restService.post<any>(
      "cella/metacom/approve-invoice",
      request
    );
  }

  async writePerson(request: MetacomWritePersonRequest) {
    return await this.restService.post<{ Origin: string }>(
      "cella/metacom/write-person",
      request
    );
  }

  async writeContactPerson(request: MetacomWriteContactPersonRequest) {
    return await this.restService.post<any>(
      "cella/metacom/write-contact-person",
      request
    );
  }

  async sendEmail(request: MailRequest) {
    return await this.restService.post<any>("cella/email/send", request);
  }

  async legacySyncServiceImages(projectId: string, ticketId: string) {
    return await this.restService.post<any>(
      "cella/legacy/sync-service-images",
      { projectId, ticketId }
    );
  }

  async legacyNotifyCustomerJourney(operation: string) {
    return await this.restService.post<any>(
      "cella/legacy/notify-customer-journey",
      { operation }
    );
  }

  async serviceTicketProgress(data: ServiceTicketProgress) {
    return await this.restService.post<any>(
      "cella/service/ticket-progress",
      data
    );
  }

  async appSso(userId: string) {
    return await this.restService.post<{ accessToken: string }>(
      "cella/legacy/app-sso",
      { userId }
    );
  }
}

export interface MetacomRequest {
  Company?: string;
}

export interface MetacomCustomFieldRequest extends MetacomRequest {
  CustomField: MetacomCustomField[];
}

export interface MetacomStageRequest extends MetacomRequest {
  Entity: string;
  Origin: string;
  Stage: string;
}

export interface MetacomCustomField {
  Entity: string;
  Origin: string;
  Code: string;
  LineId?: number;
  SerialNumber: number;
  SerialNumberNRelation?: string;
  Contents: string;
}

export interface MetacomTransactionDocumentRequest extends MetacomRequest {
  DocumentType: string;
  DocumentDate: string;
  DocumentDescription: string;
  DocumentNumber?: string;
  DocumentLines: MetacomTransactionDocumentLine[];
}

export interface MetacomTransactionDocumentLine {
  FromCompany?: number;
  FromAdministrationType?: string;
  ToCompany?: number;
  ToAdministrationType?: string;

  StartDate?: string;
  StartTime?: string;

  EndDate?: string;
  EndTime?: string;

  SourceCompany?: number;
  SourceDocumentType?: string;
  SourceDocumentNumber?: string;
  SourceDocumentLine?: string;

  ToCostCentre?: string;
  ToCostUnit?: string;
  ToItem?: string;
  ToLedgerAccount?: string;
  ToContract?: string;
  ToQuantityCode?: string;

  FromCostCentre?: string;
  FromCostUnit?: string;
  FromItem?: string;
  FromLedgerAccount?: string;
  FromContract?: string;
  FromQuantityCode?: string;
  FromQuantity?: string;

  FromRemainingQuantityCorrection?: string;

  FromUnit?: string;
  FromUnitPrice?: string;

  FreeCode?: string;

  UserDefinedField?: string;
  Description?: string;
}

export interface MetacomInvoiceApprovalRequest extends MetacomRequest {
  Entity: string;
  Origin: string;
  Stage: string;

  ipDate: string;
  Comments: string;

  tt_obligation: string;
  tt_obligationline: string;
  tt_bookingline: string;
}

export interface MetacomWritePersonRequest extends MetacomRequest {
  Code: string;
  Name: string;
  Prefix: string;
  FirstName: string;
  LanguageCode: string;
  Gender: string;
  Initials: string;
  Stage: string;
}

export interface MetacomWriteContactPersonRequest extends MetacomRequest {
  Relation: string;
  Person: string;
}

export interface MailRequest {}

export interface ServiceTicketProgress {
  readonly ticketId: string;
  readonly relationId: string;
  readonly description: string;
  readonly isNew: boolean;
  readonly notify: boolean;
  readonly source: "portal";

  readonly appointmentDate?: string;
  readonly isCompleted?: boolean;
}

export interface Mutex {
  mutex: string;
}
