import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RestService } from "./rest.service";
import { User } from "./accessibility-users/user.entity";
import * as jwt_decode from "jwt-decode";

/**
 * Service used to handle all authentication.
 *
 * @export
 * @class AuthService
 */
@Injectable({
  providedIn: "root",
})
export class AuthService {
  protected readonly jwtSubject: BehaviorSubject<JwtResponseBody>;

  readonly userSubject: BehaviorSubject<User>;
  readonly onlineSubject: BehaviorSubject<boolean>;
  readonly projectSubject: BehaviorSubject<string>;

  /**
   * Creates an instance of AuthService.
   * @param {RestService} restService
   * @memberof AuthService
   */
  constructor(protected readonly restService: RestService) {
    this.jwtSubject = new BehaviorSubject<JwtResponseBody>(null);
    this.onlineSubject = new BehaviorSubject<boolean>(true);
    this.projectSubject = new BehaviorSubject<string>(null);

    this.userSubject = new BehaviorSubject<User>(null);
  }

  get jwt() {
    return this.jwtSubject.value;
  }

  /**
   * Gets the user.
   *
   * @readonly
   * @memberof AuthService
   */
  get user() {
    return this.userSubject.value;
  }

  /**
   * Indicates if the user is signed in.
   *
   * @readonly
   * @memberof AuthService
   */
  get isSignedIn() {
    return !!this.jwt?.exp;
  }

  /**
   * Logs in the user with the given credentials.
   *
   * @param {string} identity
   * @param {string} password
   * @returns
   * @memberof AuthService
   */
  async login(identity: string, password: string) {
    const response = await this.restService.post<JwtResponse>(
      "auth/token",
      {
        identity,
        password,
      },
      true
    );

    if (!response.hasError()) {
      this.jwtSubject.next(jwt_decode<JwtResponseBody>(response.value.token));

      await this.fetchUserDetails();
    }

    return response;
  }

  async refreshAll(force?: boolean) {
    await this.refreshToken(force);
    await this.fetchUserDetails();
  }

  /**
   * Refreshes the token.
   *
   * @memberof AuthService
   */
  async refreshToken(force?: boolean) {
    const response = await this.restService.get<JwtResponse>(
      "auth/token",
      undefined,
      true
    );

    if (!response.hasError()) {
      this.jwtSubject.next(jwt_decode<JwtResponseBody>(response.value.token));
    }

    return response;
  }

  async fetchUserDetails() {
    if (this.isSignedIn) {
      const response = await this.restService.get<User>("auth/me");

      if (!response.hasError()) {
        this.userSubject.next(response.value);
      }
    }
  }

  /**
   * Logs out the current user.
   *
   * @param {boolean} [reload=false]
   * @memberof AuthService
   */
  async logout(reload: boolean = false, reason?: string) {
    if (!!this.jwt?.exp) {
      const response = await this.restService.get("auth/logout");

      if (!response.hasError()) {
        this.jwtSubject.next(null);

        if (reason) {
          localStorage.setItem("logout:reason", reason);
        }

        if (reload) {
          location.reload();
        }
      }
    }
  }

  async switchUser(id: string) {
    const response = await this.restService.get<JwtResponse>(
      `auth/sign-in-as/${id}`
    );

    if (!response.hasError()) {
      window.location.hash = "";
      window.location.reload();
    }
  }
}

/**
 * Interface which defines the JWT response.
 *
 * @export
 * @interface JwtResponse
 */
export interface JwtResponse {
  /**
   * JWT
   *
   * @type {string}
   * @memberof JwtResponse
   */
  token: string;
}

export interface JwtResponseBody {
  exp: number;

  /**
   * The route access table of the user.
   *
   * @type {{RouteAccess[]}}
   * @memberof JwtResponseBody
   */
  routeAccess: string[];
}
