import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { RestResponse, RestService } from "../rest.service";
import { User } from "../accessibility-users/user.entity";
import { Role, UserGrantConfig } from "../accessibility-roles/role.entity";
import { EntityManager, Ops } from "../entity.service";
import { grants } from "../app-grant-config";
import {
  PermissionRoute,
  PermissionGroup,
} from "../accessibility-role-dialog/accessibility-role-dialog.component";
import * as _ from "lodash";
import { DialogService } from "../dialog.service";
import { AccessibilityUserPasswordDialogComponent } from "../accessibility-user-password-dialog/accessibility-user-password-dialog.component";

@Component({
  selector: "app-accessibility-user-dialog",
  templateUrl: "./accessibility-user-dialog.component.html",
  styleUrls: ["./accessibility-user-dialog.component.scss"],
})
export class AccessibilityUserDialogComponent implements OnInit {
  roles: RestResponse<Role[]>;
  permissionGroups: RestResponse<PermissionGroup[]>;

  exportCardsActive = false;

  protected get userService() {
    return this.entityManager.get(User);
  }

  protected get roleService() {
    return this.entityManager.get(Role);
  }

  protected get userGrantConfigService() {
    return this.entityManager.get(UserGrantConfig);
  }

  workAsFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Werken namens",
    icon: "next_week",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "roleId", operator: "IsNull", isNot: true },
    ],
  };

  planningUserFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Planning gebruiken van",
    icon: "event",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "roleId", operator: "IsNull", isNot: true },
    ],
  };

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly user: User,

    protected readonly restService: RestService,
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialog: MdcDialogRef<AccessibilityUserDialogComponent>
  ) {}

  async ngOnInit() {
    const user = await this.userService.queryFirst({
      filters: [Ops.Field("id").Equals(this.user.id)],
      relations: ["role", "role.grants", "grantConfigs"],
    });

    this.user.__role__ = user.value.__role__;
    this.user.__grantConfigs__ = user.value.__grantConfigs__;

    this.fetchRoles();
    this.fetchPermissions();
  }

  async onRoleChanged() {
    this.user.__role__ = this.roles.value.find(
      (r) => r.id === this.user.roleId
    );

    await this.fetchPermissions();
  }

  permissionGroupHasValues(group: PermissionGroup): boolean {
    return group.routes.some(this.permissionRouteHasValues)
  }

  permissionRouteHasValues(route: PermissionRoute): boolean {
    return route.configs.some(config => !!config.value)
  }

  changePassword() {
    this.dialogService.open(
      this.changeDetector,
      AccessibilityUserPasswordDialogComponent,
      {
        data: this.user,
      }
    );
  }

  async save() {
    await this.userService.save(this.user);
    await this.saveGrantConfigs();

    this.dialog.close();
  }

  protected async setupToggle(toggle: PermissionRoute) {
    toggle.configs = Array.from(this.grantConfigs(toggle.id));

    return toggle;
  }

  protected async fetchRoles() {
    this.roles = await this.roleService.query({
      orders: [{ field: "id", direction: "ASC" }],
      relations: ["grants"],
    });
    this.roles.value.push(
      this.roleService.concept({ id: null, description: "Geen rol" })
    );
  }

  protected async fetchPermissions() {
    if (this.user.roleId && this.user.__role__) {
      const response = await this.restService.get<PermissionGroup[]>(
        "permission"
      );

      if (!response.hasError()) {
        response.value.forEach((g) =>
          g.routes.forEach((r) => this.setupToggle(r))
        );

        const permissionIds = this.user.__role__.__grants__.map(
          (e) => e.permissionId
        );

        response.value.forEach(
          (g) =>
            (g.routes = g.routes.filter(
              (r) => permissionIds.indexOf(r.id) >= 0 && r.configs.length
            ))
        );

        this.permissionGroups = new RestResponse(
          response.value
            .filter(
              (e) => !!e.routes.find((r) => permissionIds.indexOf(r.id) >= 0)
            )
            .filter((e) => !!e.routes.find((r) => r.configs.length))
        );
      }
    } else {
      this.permissionGroups = new RestResponse([]);
    }
  }

  protected *grantConfigs(permissionId: string) {
    const permissions = grants[permissionId] || {};
    const values = Object.keys(permissions).map((k) => permissions[k]);

    for (const configId of values) {
      yield (this.user.__grantConfigs__ || []).find(
        (e) => e.configId === configId
      ) ||
        this.userGrantConfigService.concept({ configId, userId: this.user.id });
    }
  }

  protected async saveGrantConfigs() {
    this.user.__grantConfigs__ = [];

    for (const permissionGroup of this.permissionGroups.value) {
      for (const permission of permissionGroup.routes) {
        for (const config of permission.configs as UserGrantConfig[]) {
          const newGrant = await this.userGrantConfigService.save(config);

          if (!newGrant.hasError()) {
            this.user.__grantConfigs__.push(newGrant.value);
          }
        }
      }
    }
  }
}
