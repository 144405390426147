import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from "@angular/cdk/drag-drop";
import { MdcSnackbar } from "@angular-mdc/web";
import { CargoDialogComponent } from "../cargo-dialog/cargo-dialog.component";
import { EntityManager } from "../entity.service";
import {
  ProjectHousePart,
  HousePart,
  Cargo,
  HousePartGroup,
  CargoType,
  cargoRoutes,
  ICargoPhase,
  CargoPhase,
  CargoPhaseType,
} from "./house-part.entity";
import { ActivatedRoute } from "@angular/router";
import { CargoSplitDialogComponent } from "../cargo-split-dialog/cargo-split-dialog.component";
import { Project } from "../project/project.entity";
import { HttpClient } from "@angular/common/http";
import { LoadingDialogComponent } from "../loading-dialog/loading-dialog.component";
import { PicklistState, PicklistDefinition } from "../picklist/picklist.entity";
import { PrintService } from "../print.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { CargoElementGroupsDialogComponent } from "../cargo-element-groups-dialog/cargo-element-groups-dialog.component";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { GrantService } from "../grant.service";
import { DialogService } from "../dialog.service";
import * as moment from "moment";
import * as _ from "lodash";
import {
  SelectDialogItem,
  SelectDialogComponent,
} from "../select-dialog/select-dialog.component";
import { CargoFunctions } from "./cargo.functions";
import {
  CargoNotesDialogComponent,
  CargoNotesDialogComponentData,
} from "../cargo-notes-dialog/cargo-notes-dialog.component";
import { orderBy, first, flatten, sumBy, chain } from "lodash";
import { CargoPhaseDialogComponent } from "../cargo-phase-dialog/cargo-phase-dialog.component";
import { PlanningCompetence } from "../picklist-overview/planning-project-item.entity";

@Component({
  selector: "app-cargo",
  templateUrl: "./cargo.component.html",
  styleUrls: ["./cargo.component.scss"],
})
export class CargoComponent implements OnInit {
  static readonly allowedCompetenceNumbers = ["300", "500", "700", "1000"];

  cargo: Cargo[];
  cargoPhases: ICargoPhase[];

  project: Project = null;

  picklistStates: PicklistState[] = [];
  picklistDefinitions: PicklistDefinition[];

  elementGroups: HousePartGroup[];
  elementGroupDefinitions: HousePartGroup[];

  isDragging = false;

  get allowElementGroupModify() {
    return this.grantService.varIs(grants.cargo.edit_element_groups, "true");
  }

  get isReadonly() {
    return this.grantService.varIs(grants.cargo.create_cargo, "true") === false;
  }

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get cargoService() {
    return this.entityManager.get(Cargo);
  }

  protected get cargoTypeService() {
    return this.entityManager.get(CargoType);
  }

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get projectHousePartService() {
    return this.entityManager.get(ProjectHousePart);
  }

  protected get housePartGroupService() {
    return this.entityManager.get(HousePartGroup);
  }

  protected get allElementGroups() {
    return this.elementGroups.concat(...this.cargo.map((c) => c.elementGroups));
  }

  protected get picklistStateService() {
    return this.entityManager.get(PicklistState);
  }

  protected get picklistDefinitionService() {
    return this.entityManager.get(PicklistDefinition);
  }

  protected get cargoPhaseService() {
    return this.entityManager.get(CargoPhase);
  }

  protected get planningCompetenceService() {
    return this.entityManager.get(PlanningCompetence);
  }

  constructor(
    protected readonly snackbar: MdcSnackbar,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly printService: PrintService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly entityManager: EntityManager,
    protected readonly httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.fetchAll();
  }

  /**
   * Called the the user starts dragging a @see HousePartGroup
   *
   * @memberof CargoComponent
   */
  onStartDrag() {
    this.isDragging = true;
  }

  /**
   * Called the the user stops dragging a @see HousePartGroup
   *
   * @memberof CargoComponent
   */
  onStopDrag() {
    this.isDragging = false;
  }

  getCargoByPhase(phase: ICargoPhase) {
    return chain(this.cargo || [])
      .filter((cargo) => cargo.isPhaseMatch(phase))
      .orderBy((cargo) => `${this.getDateString(cargo.dateAt)}-${cargo.timeAt}`)
      .value();
  }

  protected getDateString(date: Date) {
    return moment(date).format("YYYY/MM/DD");
  }

  async togglePermit(cargo: Cargo) {
    cargo.requiresPermit = !cargo.requiresPermit;

    await this.cargoService.save(cargo);
  }

  openNotes(cargo: Cargo) {
    this.dialogService.open(this.changeDetector, CargoNotesDialogComponent, {
      data: new CargoNotesDialogComponentData(cargo),
    });
  }

  async deletePhase(phase: ICargoPhase) {
    const confirmation = await this.dialogService.open(
      this.changeDetector,
      ConfirmDialogComponent,
      { escapeToClose: false }
    );

    if (confirmation === "accept") {
      /** Delete per cargo to move elements back to buffer. */
      const cargos = this.getCargoByPhase(phase);

      await Promise.all(
        cargos.map(async (cargo) => await this.deleteCargo(cargo, true))
      );

      const response = await this.cargoPhaseService.delete(phase.id);

      if (!response.hasError()) {
        this.cargoPhases = this.cargoPhases.filter(
          (cargoPhase) => cargoPhase.id !== phase.id
        );

        this.onSaved();
      }
    }
  }

  async createPhase() {
    const phase: ICargoPhase = await this.dialogService.open(
      this.changeDetector,
      CargoPhaseDialogComponent,
      {
        data: {
          projectId: this.projectId,
        },
      }
    );

    if (phase && phase.id) {
      this.cargoPhases.push(phase);
    }
  }

  async editPhase(phase: ICargoPhase) {
    await this.dialogService.open(
      this.changeDetector,
      CargoPhaseDialogComponent,
      {
        data: {
          original: phase,
        },
      }
    );
  }

  /**
   * Prompts the user to create a new @see Cargo
   *
   * @memberof CargoComponent
   */
  async createCargo(phase: ICargoPhase) {
    const cargo = await this.dialogService.open(
      this.changeDetector,
      CargoDialogComponent,
      {
        data: this.cargoService.concept({
          description: `Vracht`,
          projectId: this.projectId,
          [phase.cargoIdField]: phase.id,
        }),
      }
    );

    if (cargo instanceof Cargo) {
      const response = await this.cargoService.save(cargo);

      if (!response.hasError()) {
        cargo.id = response.value.id;
        cargo[phase.cargoEntityField] = phase;

        this.cargo.push(cargo);

        this.onSaved();
      }
    }
  }

  async editCargo(cargo: Cargo) {
    const updatedCargo = await this.dialogService.open(
      this.changeDetector,
      CargoDialogComponent,
      { data: this.cargoService.copy(cargo) }
    );

    if (updatedCargo instanceof Cargo) {
      this.cargoService.copyTo(updatedCargo, cargo);

      const response = await this.cargoService.save(cargo);

      if (!response.hasError()) {
        this.onSaved();
      }
    }
  }

  /**
   * Delest the given @see Cargo
   *
   * @param {Cargo} cargo
   * @memberof CargoComponent
   */
  async deleteCargo(cargo: Cargo, silent = false) {
    const confirmation = silent
      ? "accept"
      : await this.dialogService.open(
          this.changeDetector,
          ConfirmDialogComponent,
          { escapeToClose: false }
        );

    if (confirmation === "accept") {
      const groupsMoveResponse = await Promise.all(
        cargo.elementGroups.map(
          async (g) => await this.assignGroupToCargo(g, null)
        )
      );

      if (groupsMoveResponse) {
        const cargoDeleteResponse = await this.cargoService.delete(cargo.id);

        if (!cargoDeleteResponse.hasError()) {
          const index = this.cargo.indexOf(cargo);

          this.elementGroups.unshift(...cargo.elementGroups);
          this.cargo.splice(index, 1);

          !silent && this.onSaved();

          this.onUpdate(...cargo.elementGroups);
        }
      }
    }
  }

  async createDivision(group: HousePartGroup, parent: HousePartGroup[]) {
    const dialogResult = await this.dialogService.open(
      this.changeDetector,
      CargoSplitDialogComponent,
      { data: group }
    );

    if (dialogResult instanceof HousePartGroup) {
      const splitElements = group.elements.filter((e) => e.isSplit);

      if (splitElements.length) {
        const divisionId = this.getNextDivisionId(group.id);
        splitElements.forEach((e) => (e.groupDivisionId = `${divisionId}`));

        const isSaved = await this.saveElements(splitElements);

        if (isSaved) {
          group.elements = group.elements.filter((e) => !e.isSplit);

          const baseGroupIndex = parent.indexOf(group);
          const newGroup = this.housePartGroupService.concept({
            id: group.id,
            name: group.name,
            cargoId: group.cargoId,
            divisionId: divisionId,
            elements: splitElements,
            isUpdated: true,
            isPicked: group.isPicked,
          });
          parent.splice(baseGroupIndex + 1, 0, newGroup);

          this.onSaved();
          this.onUpdate(group);
        }
      }
    }
  }

  async deleteDivision(group: HousePartGroup, parent: HousePartGroup[]) {
    group.elements.forEach((e) => (e.groupDivisionId = null));

    let sourceGroup = this.findMasterGroup(group.id);

    if (!sourceGroup) {
      const baseGroupIndex = parent.indexOf(group);
      sourceGroup = this.housePartGroupService.concept({
        id: group.id,
        name: group.name,
        cargoId: group.cargoId,
        divisionId: null,
        elements: group.elements,
        isUpdated: true,
        isPicked: group.isPicked,
      });
      parent.splice(baseGroupIndex + 1, 0, sourceGroup);
    }

    const cargo = this.getCargoById(sourceGroup.cargoId);
    const assignToMasterResponse = await this.assignGroupToCargo(group, cargo);

    if (assignToMasterResponse) {
      sourceGroup.elements.unshift(...group.elements);

      const index = parent.indexOf(group);
      parent.splice(index, 1);
      this.onSaved();
      this.onUpdate(sourceGroup);
    }
  }

  async drop(event: CdkDragDrop<IDropList>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data.groups,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data.groups,
        event.container.data.groups,
        event.previousIndex,
        event.currentIndex
      );

      await this.assignGroupToCargo(
        event.item.data,
        event.container.data.cargo
      );

      this.onUpdate(event.item.data);
      this.onSaved();
    }
  }

  cargoCompletedAt(cargo: Cargo) {
    const timeMins = _.sumBy(cargo.elementGroups, (g) =>
      this.calculateGroupDuration(g)
    );

    return this.formatDuration(timeMins, cargo.timeAt);
  }

  groupDuration(group: HousePartGroup) {
    return this.formatDuration(this.calculateGroupDuration(group));
  }

  groupWidth = CargoFunctions.groupWidth;
  groupLength = CargoFunctions.groupLength;
  groupWeight = CargoFunctions.groupWeight;

  protected groupQuantity(eg: HousePartGroup) {
    const withUnit = eg.elements.find((e) => e.unit === "st");

    return withUnit ? withUnit.quantity : 0;
  }

  partDuration(part: ProjectHousePart) {
    return this.formatDuration(this.calculatePartDuration(part));
  }

  protected calculateGroupDuration(group: HousePartGroup) {
    return _.sumBy(
      group.elements.filter((e) => e.__housePart__),
      (e) => this.calculatePartDuration(e)
    );
  }

  protected calculatePartDuration(part: ProjectHousePart) {
    const timeInMins = part.__housePart__.releaseTimeMins;
    return part.unit === "st" ? timeInMins * part.quantity : timeInMins;
  }

  formatDuration(timeMins: number, baseTime = "00:00") {
    return moment(baseTime, "H:m").add(timeMins, "minutes").format("HH:mm");
  }

  isDropAllowed() {
    return (drag: CdkDrag<HousePartGroup>, drop: CdkDropList<IDropList>) => {
      return drop.data.cargo ? !drop.data.cargo.isLocked : true;
    };
  }

  isDragDisabled(cargo: Cargo) {
    return cargo ? cargo.isLocked : false;
  }

  async toggleLockCargo(cargo: Cargo) {
    cargo.isLocked = !cargo.isLocked;

    if (cargo.isLocked) {
      cargo.isHidden = true;
    }

    const response = await this.cargoService.save(cargo);

    if (!response.hasError()) {
      this.onSaved();
    }
  }

  information(cargo: Cargo) {
    this.message(cargo.description);
  }

  async openGroupDialog() {
    await this.dialogService.open(
      this.changeDetector,
      CargoElementGroupsDialogComponent,
      { clickOutsideToClose: false }
    );

    await this.fetchAll();
  }

  async print() {
    const companies = await this.chooseCargoCompanies();

    if (companies instanceof Array) {
      const loadingDialog = this.dialogService.mdcDialog.open(
        LoadingDialogComponent,
        { clickOutsideToClose: false }
      );

      const relationIds = companies.map((c) => c.relationId);
      const relationName = _.first(companies).description;

      const data = await this.createPrintData(relationIds, relationName);
      await this.printService.print("cargo", data);

      loadingDialog.close();
    }
  }

  protected async chooseCargoCompanies() {
    const relations = await this.cargoTypeService.query({
      relations: ["user"],
    });

    if (!relations.hasError()) {
      const options = _.chain(relations.value)
        .uniqBy((e) => e.userId)
        .map<ChooseRelationCargo>((e) => ({
          relationId: e.userId,
          description: e.__user__ ? e.__user__.name : e.description,
          isChecked: false,
        }))
        .filter(
          (e) =>
            !!this.cargo.find((c) => c.__cargoType__.userId === e.relationId)
        )
        .value();

      const result: ChooseRelationCargo[] = await this.dialogService.open(
        this.changeDetector,
        SelectDialogComponent,
        {
          data: {
            isMultiple: true,
            items: options,
          },
          clickOutsideToClose: false,
        }
      );

      if (result instanceof Array) {
        return result.filter((e) => e.isChecked);
      }
    }
  }

  protected async assignGroupToCargo(
    group: HousePartGroup,
    cargo: Cargo = null
  ) {
    group.cargoId = cargo ? cargo.id : null;
    group.isExpanded = false;

    return await this.assignElementsToCargo(group.elements, cargo);
  }

  protected async assignElementsToCargo(
    elements: ProjectHousePart[],
    cargo: Cargo = null
  ) {
    elements.forEach((e) => (e.cargoId = cargo ? cargo.id : null));

    return await this.saveElements(elements);
  }

  protected async saveElements(elements: ProjectHousePart[]) {
    return !!(await this.projectHousePartService.modifyMany(elements));
  }

  protected isPicked(group: HousePartGroup) {
    if (isNaN(parseFloat(group.id))) {
      const definition = this.picklistDefinitions.find(
        (d) => !!d.elementTypes.find((e) => e === group.id)
      );

      return definition
        ? !!this.picklistStates.find(
            (s) => s.picklistDefinitionId === definition.id
          )
        : false;
    }

    return true;
  }

  protected async fetchAll() {
    this.cargoPhases = await this.fetchCargoPhases();

    const response = await this.housePartGroupService.query({
      filters: [{ field: "isEnabled", operator: "Equal", value: true }],
    });

    if (!response.hasError()) {
      this.elementGroupDefinitions = response.value;

      await this.fetchProject();
      await this.fetchPicklistDefinitions();

      this.elementGroupDefinitions.forEach(
        (g) => (g.isPicked = this.isPicked(g))
      );

      await this.fetchCargos();
      await this.fetchFreeElementGroups();
    }
  }

  protected async fetchCargoPhases(): Promise<ICargoPhase[]> {
    const builder: ICargoPhase[] = [];

    const responseCompetences = await this.planningCompetenceService.query({
      filters: [
        {
          field: "number",
          operator: "In",
          value: CargoComponent.allowedCompetenceNumbers,
        },
      ],
      orders: [{ field: "number", direction: "ASC" }],
    });

    if (!responseCompetences.hasError()) {
      builder.push(
        ...orderBy(
          responseCompetences.value,
          (competence) => parseFloat(competence.number),
          "asc"
        )
      );
    }

    const responsePhases = await this.cargoPhaseService.query({
      filters: [
        {
          field: "projectId",
          operator: "Equal",
          value: this.projectId,
        },
      ],
      orders: [{ field: "createdAt", direction: "ASC" }],
    });

    if (!responsePhases.hasError()) {
      builder.push(...responsePhases.value);
    }

    return builder;
  }

  protected async fetchPicklistDefinitions() {
    const response = await this.picklistDefinitionService.query();

    if (!response.hasError()) {
      this.picklistDefinitions = response.value;
    }
  }

  protected async fetchProject() {
    const response = await this.projectService.findOne(this.projectId);

    if (!response.hasError()) {
      this.project = response.value;
      this.picklistStates = (await response.value.picklistStates).value;
    }
  }

  protected async fetchCargos() {
    const response = await this.cargoService.query({
      filters: [
        { field: "projectId", operator: "Equal", value: this.projectId },
      ],
      relations: [
        "cargoType",
        "cargoType.user",
        "competence",
        "projectHouseParts",
        "projectHouseParts.housePart",
      ],
    });

    if (!response.hasError()) {
      const withoutAutoGenerated = response.value.filter(
        (cargo) => !(cargo.id as string).startsWith("nalevering_")
      );

      for (const cargo of withoutAutoGenerated) {
        const elements = await cargo.projectHouseParts;

        if (!elements.hasError()) {
          cargo.elementGroups = this.getElementGroup(elements.value, cargo.id);
        }

        cargo.isHidden = cargo.isLocked;
      }

      this.cargo = response.value;
    }
  }

  protected async fetchFreeElementGroups() {
    const response = await this.projectHousePartService.query({
      filters: [
        { field: "projectId", operator: "Equal", value: this.projectId },
        { field: "cargoId", operator: "IsNull" },
      ],
      relations: ["housePart"],
    });

    if (!response.hasError()) {
      this.elementGroups = this.getElementGroup(response.value);
    }
  }

  protected getElementGroup(
    rawElements: ProjectHousePart[],
    cargoId: string = null
  ) {
    return _.chain(rawElements)
      .filter(this.isElementAllowed)
      .groupBy((e) => `${this.getElementGroupId(e)}_${e.groupDivisionId}`)
      .map((elements) => {
        const first = _.first(elements);
        const id = this.getElementGroupId(first);
        const definition = this.getElementGroupDefinition(id);

        if (definition) {
          elements.forEach((e) => (e.unit = definition.unit || e.unit));
          elements.forEach((e) => (e.width = e.width || e.__housePart__.width));
          elements.forEach(
            (e) => (e.length = e.length || e.__housePart__.length)
          );
        }

        const sortedElements = _.chain(elements)
          .orderBy((e) => e.partCodeId, ["asc"])
          .value();

        return definition
          ? this.housePartGroupService.concept({
              id,
              cargoId,
              name: definition.name,
              elements: sortedElements,
              divisionId: first.groupDivisionId
                ? parseFloat(first.groupDivisionId)
                : null,
              isPicked: definition.isPicked,
            })
          : null;
      })
      .filter((g) => !!g)
      .orderBy([(g) => !isNaN(parseFloat(g.id)), (g) => g.id], ["asc", "asc"])
      .value() as HousePartGroup[];
  }

  protected isElementAllowed(part: ProjectHousePart) {
    const housePart = part.oneStale(HousePart, "housePart");

    return (
      housePart &&
      housePart.value.id &&
      housePart.value.housePartGroupId &&
      (housePart.value.rootElement || "").toLowerCase() !== "nee"
    );
  }

  protected getElementGroupId(part: ProjectHousePart) {
    return (
      part.oneStale(HousePart, "housePart").value.housePartGroupId || "###"
    );
  }

  protected getElementGroupName(id: string) {
    return this.getElementGroupDefinition(id).name;
  }

  protected getElementGroupDefinition(id: string) {
    return this.elementGroupDefinitions.find((d) => d.id === id);
  }

  protected getCargoById(id: string) {
    return this.cargo.find((c) => c.id === id);
  }

  protected getNextDivisionId(groupId: string) {
    const highest = _.chain(this.allElementGroups)
      .filter((g) => g.id === groupId)
      .maxBy((g) => g.divisionId)
      .value() || { divisionId: 0 };

    return highest.divisionId + 1;
  }

  protected findMasterGroup(groupId: string) {
    return this.allElementGroups.find((g) => g.id === groupId && !g.divisionId);
  }

  protected onSaved() {
    this.message("Opgeslagen");
  }

  protected message(message: string) {
    this.snackbar.open(message, "Ok", { timeoutMs: 4000, leading: true });
  }

  protected onUpdate(...groups: HousePartGroup[]) {
    setTimeout(() => groups.forEach((g) => (g.isUpdated = false)), 0);
    setTimeout(() => groups.forEach((g) => (g.isUpdated = true)), 100);
    setTimeout(() => groups.forEach((g) => (g.isUpdated = false)), 2500);
  }

  protected async createPrintData(relationIds: string[], relationName: string) {
    const cargo = _.chain(this.cargo)
      .filter((c) => relationIds.indexOf(c.__cargoType__.userId) >= 0)
      .groupBy((c) => c.competenceId)
      .map((items, key) => ({
        last: false,
        type: _.first(items).phaseDescription,
        cargo: _.chain(items)
          .map((item, index) => ({
            _sortable: this.getCargoSortable(item),
            dateAt: moment(item.dateAt).format("DD/MM/YYYY"),
            timeAt: item.timeAt,
            permit: item.requiresPermit,
            description: item.description,
            notes: item.notes,
            route: cargoRoutes.find((r) => r.id === item.routeId),
            trailerType: item.oneStale(CargoType, "cargoType").value
              .description,
            groups: _.chain(item.elementGroups)
              .map((eg) => ({
                description: `${eg.name} ${
                  eg.divisionId ? `(${eg.divisionId})` : ""
                }`,
                length: this.groupLength(eg),
                width: this.groupWidth(eg),
                quantity: this.groupQuantity(eg),
              }))
              .value(),
          }))
          .orderBy((c) => c._sortable)
          .forEach(
            (item, index) =>
              (item.description = `${item.description} (${index + 1})`)
          )
          .value(),
      }))
      .orderBy((group) => _.first(group.cargo)._sortable)
      .value();

    _.last(cargo).last = true;

    const projectLeader = (await this.project.projectLeader).value;

    return {
      templateFile: "cargo",
      project: this.project.toJson(),
      projectLeader: projectLeader ? projectLeader.toJson() : null,
      createdDate: moment().format("DD/MM/YYYY"),
      relationName: relationIds.length > 1 ? "" : relationName,
      cargo,
    };
  }

  protected getDateSortble(date: any) {
    return date ? moment(date).unix() : Number.POSITIVE_INFINITY;
  }

  protected getCargoSortable(cargo: Cargo) {
    return `${this.getDateSortble(cargo.dateAt)}_${cargo.timeAt || "99:99"}`;
  }

  routeLabel(id: string) {
    return id ? cargoRoutes.find((r) => r.id == id).label : "";
  }

  getTimeStart(phase: ICargoPhase) {
    const items = this.getCargoByPhase(phase),
      mapped = items.map((item) => item.timeAt);

    return first(orderBy(mapped, (item) => item, "asc")) || "00:00";
  }

  getTimeEnd(phase: ICargoPhase) {
    const items = this.getCargoByPhase(phase),
      mapped = items.map((item) => this.cargoCompletedAt(item));
    return first(orderBy(mapped, (item) => item, "desc")) || "00:00";
  }

  getTimeTotal(phase: ICargoPhase) {
    const items = this.getCargoByPhase(phase);

    const totalTimeMins = sumBy(
      flatten(
        flatten(items.map((_i) => _i.elementGroups)).map((_g) => _g.elements)
      ),
      (_element) => this.calculatePartDuration(_element)
    );

    return moment().startOf("day").add(totalTimeMins, "minutes").format("H:mm");
  }
}

export interface IDropList {
  cargo: Cargo;
  groups: HousePartGroup[];
}

export interface ChooseRelationCargo extends SelectDialogItem {
  relationId: string;
}
