import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "../auth.service";
import { RestService, RestResponse } from "../rest.service";
import { MdcDialog, MdcSnackbar } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { Greeting, setupAnchorOverwrite } from "./greeting.entity";
import { GrantService } from "../grant.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/file.min.js";
import * as _ from "lodash";

@Component({
  selector: "app-greetings",
  templateUrl: "./greetings.component.html",
  styleUrls: ["./greetings.component.scss"],
})
export class GreetingsComponent implements OnInit, OnDestroy {
  response: RestResponse<Greeting[]>;

  protected anchorOverwriteDestruct: Function;

  protected get greetingService() {
    return this.entityManager.get(Greeting);
  }

  get canSave() {
    return this.response && this.response.value.every((e) => !!e.id);
  }

  get editorOptions() {
    const url = `${environment.baseUrl}/documents/greeting`;

    return {
      imageUpload: false,
      imageMaxSize: 1024 * 1024 * 32,
      fileUploadParam: "file",
      fileUploadURL: url,
      fileUploadMethod: "POST",
      fileMaxSize: 20 * 1024 * 1024,
      fileAllowedTypes: ["*"],
      requestWithCredentials: true,
    };
  }

  constructor(
    readonly authService: AuthService,
    protected readonly mdcDialog: MdcDialog,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly urlOpenService: UrlOpenService
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    this.anchorOverwriteDestruct && this.anchorOverwriteDestruct();
  }

  async setup() {
    this.anchorOverwriteDestruct = setupAnchorOverwrite(this.urlOpenService);

    await this.fetchGreetings();
  }

  async saveGreeting() {
    const toBeSaved = this.response.value.filter((e) => !e.isDeleted);
    const toBeDeleted = this.response.value.filter((e) => e.isDeleted && e.id);

    await Promise.all(
      toBeSaved.map((greeting) => this.greetingService.save(greeting))
    );
    await Promise.all(
      toBeDeleted.map((greeting) => this.greetingService.delete(greeting.id))
    );

    await this.fetchGreetings();

    this.snackbar.open("Opgeslagen", "Ok", { leading: true });
  }

  addGreeting() {
    this.response.value.push(
      this.greetingService.concept({
        id: null,
        html: "<p>Greeting</p>",
      })
    );
  }

  protected async fetchGreetings() {
    this.response = await this.greetingService.query({});
  }
}
