import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import {
  Grant,
  Role,
  RoleDocumentGrant,
  RoleGrantConfig,
  UserGrantConfig,
} from "../accessibility-roles/role.entity";
import { grants, GrantsInfo } from "../app-grant-config";
import { EntityManager } from "../entity.service";
import { GrantService } from "../grant.service";
import { RestResponse, RestService } from "../rest.service";

@Component({
  selector: "app-accessibility-role-dialog",
  templateUrl: "./accessibility-role-dialog.component.html",
  styleUrls: ["./accessibility-role-dialog.component.scss"],
})
export class AccessibilityRoleDialogComponent implements OnInit, AfterViewInit {
  permissionGroups: RestResponse<PermissionGroup[]>;

  exportCardsActive = false;

  protected get roleService() {
    return this.entityManager.get(Role);
  }

  protected get grantService() {
    return this.entityManager.get(Grant);
  }

  protected get roleGrantConfigService() {
    return this.entityManager.get(RoleGrantConfig);
  }

  protected get roleDocumentGrantService() {
    return this.entityManager.get(RoleDocumentGrant);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly role: Role,
    protected readonly restService: RestService,
    protected readonly entityManager: EntityManager,
    protected readonly grantSystemService: GrantService,
    protected readonly dialog: MdcDialogRef<AccessibilityRoleDialogComponent>
  ) {
    role.__documentGrants__ = role.__documentGrants__ || [];
  }

  ngOnInit() {
    this.fetchPermissions();
  }

  ngAfterViewInit() {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
  }

  async save() {
    const newRole = await (this.role.isConcept
      ? this.roleService.save(this.role)
      : this.roleService.modify(this.role));

    if (!newRole.hasError()) {
      this.roleService.copyTo(newRole.value, this.role);

      this.role.__grants__ = await this.saveGrants();
      this.role.__grantConfigs__ = await this.saveGrantConfigs();
      this.role.__documentGrants__ = await this.saveDocumentGrants();

      this.dialog.close(this.role.isConcept ? this.role : null);
    }
  }

  addDocumentGrant() {
    this.role.__documentGrants__ = this.role.__documentGrants__ || [];
    this.role.__documentGrants__.push(
      this.roleDocumentGrantService.concept({})
    );
  }

  removeDocumentGrant(grant: RoleDocumentGrant) {
    grant.isDeleted = true;
  }

  protected setupToggle(toggle: PermissionRoute) {
    const grant = this.findGrant(toggle);

    toggle.grantId = grant ? grant.id : null;
    toggle.checked = !!grant;
    toggle.configs = Array.from(this.grantConfigs(toggle.id));

    return toggle;
  }

  protected findGrant(permission: PermissionRoute) {
    return (this.role.__grants__ || []).find(
      (__) => __.permissionId === permission.id
    );
  }

  protected async fetchPermissions() {
    const response = await this.restService.get<PermissionGroup[]>(
      "permission"
    );

    if (!response.hasError()) {
      response.value.forEach((g) =>
        g.routes.forEach((e) => this.setupToggle(e))
      );

      this.permissionGroups = response;
    }
  }

  protected *grantConfigs(permissionId: string) {
    const permissions = grants[permissionId] || {};
    const values = Object.keys(permissions).map((k) => permissions[k]);

    for (const configId of values) {
      const item =
        (this.role.__grantConfigs__ || []).find(
          (e) => e.configId === configId
        ) ||
        this.roleGrantConfigService.concept({ configId, roleId: this.role.id });

      item.info =
        this.grantSystemService.getInfo(item.configId) ||
        new GrantsInfo({
          description: item.configId,
          type: "text",
        });

      yield item;
    }
  }

  protected async saveGrants() {
    const items = [];

    for (const group of this.permissionGroups.value) {
      for (const toggle of group.routes) {
        if (toggle.checked) {
          let grant = this.grantService.concept({
            id: toggle.grantId,
            permissionId: toggle.id,
            roleId: this.role.id,
          });

          if (!grant.id) {
            grant = (await this.grantService.save(grant)).value;
          }

          items.push(grant);
        } else if (!toggle.checked && toggle.grantId) {
          await this.grantService.delete(toggle.grantId);
        }
      }
    }

    return items;
  }

  protected async saveGrantConfigs() {
    const items = [];

    for (const group of this.permissionGroups.value) {
      for (const permission of group.routes) {
        for (const config of permission.configs as RoleGrantConfig[]) {
          config.roleId = this.role.id;
          const newGrant = await this.roleGrantConfigService.save(config);

          if (!newGrant.hasError()) {
            items.push(newGrant.value);
          }
        }
      }
    }

    return items;
  }

  protected async saveDocumentGrants() {
    const items = [];

    for (const grant of this.role.__documentGrants__) {
      if (grant.isDeleted) {
        if (grant.id) {
          await this.roleDocumentGrantService.delete(grant.id);
        }
      } else {
        grant.roleId = this.role.id;
        const toAdd = (await this.roleDocumentGrantService.save(grant)).value;
        items.push(toAdd);
      }
    }

    return items;
  }
}

export interface PermissionGroup {
  id: string;
  name: string;
  description: string;
  routes: PermissionRoute[];

  isExpanded?: boolean;
}

export interface PermissionRoute {
  id: string;
  description: string;
  url: string;
  requiredEntities?: string[];
  configs: UserGrantConfig[] | RoleGrantConfig[];

  /** Toggle Bag */
  grantId: string;
  checked: boolean;

  isExpanded?: boolean;
}
