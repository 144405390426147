import { Component, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { PicklistComment } from '../picklist/picklist.entity';

@Component({
  selector: 'app-picklist-comment-dialog',
  templateUrl: './picklist-comment-dialog.component.html',
  styleUrls: ['./picklist-comment-dialog.component.scss']
})
export class PicklistCommentDialogComponent {

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly comment: PicklistComment,

    protected readonly dialog: MdcDialogRef<PicklistCommentDialogComponent>) { }

  add() {
    this.dialog.close(this.comment);
  }
}
