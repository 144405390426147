import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  AppCrudBoilerplateInstance,
  AppCrudBoilerplateInstanceData,
} from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager, OrderableEntity } from "../entity.service";
import { DialogService } from "../dialog.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AppPhase,
  AppPhaseSection,
  AppPhaseSectionMark,
} from "./app-phase.entity";
import { AppPhaseDialogComponent } from "../app-phase-dialog/app-phase-dialog.component";
import { sumBy } from "lodash";
import { AppPhaseSectionDialogComponent } from "../app-phase-section-dialog/app-phase-section-dialog.component";
import { AppPhaseSectionMarkDialogComponent } from "../app-phase-section-mark-dialog/app-phase-section-mark-dialog.component";

@Component({
  selector: "app-app-phases",
  templateUrl: "./app-phases.component.html",
  styleUrls: ["./app-phases.component.scss"],
})
export class AppPhasesComponent implements OnInit {
  phaseCrud: AppCrudBoilerplateInstance<AppPhase>;
  phaseSectionCrud: AppCrudBoilerplateInstance<AppPhaseSection>;
  phaseSectionMarkCrud: AppCrudBoilerplateInstance<AppPhaseSectionMark>;

  tree: RecursiveFieldItem[];
  treeExpansions = new Map<string, boolean>();

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    this.phaseCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppPhase,
        editDialog: AppPhaseDialogComponent,
        relations: ["contacts", "sections", "sections.marks"],
        orderByField: "order",
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.phaseSectionCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppPhaseSection,
        editDialog: AppPhaseSectionDialogComponent,
        disableFetch: true,
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.phaseSectionMarkCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppPhaseSectionMark,
        editDialog: AppPhaseSectionMarkDialogComponent,
        disableFetch: true,
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.phaseCrud.onUpdated.subscribe(() => this.update(false));
    this.phaseSectionCrud.onUpdated.subscribe(() => this.update(true));
    this.phaseSectionMarkCrud.onUpdated.subscribe(() => this.update(true));
  }

  async ngOnInit() {
    await this.update(true);
  }

  async update(fetch = true) {
    if (fetch) {
      await this.phaseCrud.ngOnInit();
    }

    this.setupTree();
  }

  protected getExpandId(item: RecursiveFieldItem) {
    const entityName = item.data.crud
      ? item.data.crud.entityService.name
      : "root";

    return `${entityName}_${item.data.entity.id}`;
  }

  isExpanded(item: RecursiveFieldItem) {
    return this.treeExpansions.get(this.getExpandId(item)) || false;
  }

  toggleExpand(item: RecursiveFieldItem) {
    this.setExpand(item, !this.isExpanded(item));
  }

  setExpand(item: RecursiveFieldItem, value: boolean) {
    this.treeExpansions.set(this.getExpandId(item), value);
  }

  protected setupTree() {
    const root = new RecursiveFieldItem({
      name: "",
      entity: this.phaseCrud.entityService.concept({
        id: "root",
      }),
      crud: null,
      crudChildren: this.phaseCrud,
      children: Array.from(this.mapPhases(this.phaseCrud.response.value)),
    });

    this.setExpand(root, true);

    this.tree = [root];
  }

  async addChild(item: RecursiveFieldItem) {
    const concept = { order: 999 };

    if (!!item.data.foreignKey) {
      concept[item.data.foreignKey] = item.data.entity.id;
    }

    await item.data.crudChildren.edit(
      item.data.crudChildren.entityService.concept(concept)
    );
  }

  protected *mapPhases(items: AppPhase[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.name,
        percentage: item.percentage,
        entity: item,
        crud: this.phaseCrud,
        crudChildren: this.phaseSectionCrud,
        foreignKey: "phase_id",
        children: Array.from(this.mapSections(item.sections)),
      });
    }
  }

  protected *mapSections(items: AppPhaseSection[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.name,
        percentage: item.percentage,
        entity: item,
        crud: this.phaseSectionCrud,
        foreignKey: "phase_section_id",
        crudChildren: this.phaseSectionMarkCrud,
        children: Array.from(this.mapMarks(item.marks)),
      });
    }
  }

  protected *mapMarks(items: AppPhaseSectionMark[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.name,
        percentage: item.percentage,
        entity: item,
        crud: this.phaseSectionMarkCrud,
      });
    }
  }

  dragStarted(items: RecursiveFieldItem[]) {
    items.forEach((item) => (item.isExpandedRecovery = this.isExpanded(item)));
    items.forEach((item) => this.setExpand(item, false));

    this.changeDetector.detectChanges();
  }

  dragStopped(items: RecursiveFieldItem[]) {
    items.forEach((item) => this.setExpand(item, item.isExpandedRecovery));
  }

  async drop(
    event: CdkDragDrop<RecursiveFieldItem[]>,
    crud: AppCrudBoilerplateInstance<OrderableEntity>
  ) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

    const entities = event.container.data.map((e) => e.data.entity);

    this.setOrderIds(entities);

    await this.phaseCrud.transactions.perform(() =>
      crud.entityService.modifyMany(entities)
    );
  }

  protected setOrderIds(items: OrderableEntity[]) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order = i;
    }
  }
}

class RecursiveFieldItem {
  isExpandedRecovery: boolean;

  constructor(readonly data: RecursiveFieldItemData) {}

  get canModify() {
    return !!this.data.crud && !!this.data.crud.editDialog;
  }

  get hasChildren() {
    return !!this.data.crudChildren;
  }

  get totalPercentage() {
    return this.hasChildren
      ? sumBy(this.data.children, (e) => e.data.percentage)
      : 0;
  }
}

interface RecursiveFieldItemData {
  readonly name: string;
  readonly percentage?: number;
  readonly entity: OrderableEntity;
  readonly crud: AppCrudBoilerplateInstance<OrderableEntity>;
  readonly crudChildren?: AppCrudBoilerplateInstance<OrderableEntity>;
  readonly foreignKey?: string;
  readonly children?: RecursiveFieldItem[];
}
