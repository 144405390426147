import { Entity } from "src/app/entity.service";

export class SpaceEntity extends Entity {
  type = "service_spaces";

  code: string;
  description: string;

  createdAt: Date;
}
