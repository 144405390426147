import { Component, ChangeDetectorRef } from "@angular/core";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { AppDocumentType } from "./app-document-type.entity";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppDocumentTypeDialogComponent } from "../app-document-type-dialog/app-document-type-dialog.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-app-document-types",
  templateUrl: "./app-document-types.component.html",
  styleUrls: ["./app-document-types.component.scss"],
})
export class AppDocumentTypesComponent extends AppCrudBoilerplate<AppDocumentType> {
  entity = AppDocumentType;
  editDialog = AppDocumentTypeDialogComponent;

  orderByField = "order_id";

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }

  async drop(event: CdkDragDrop<AppDocumentType[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

    const entities = event.container.data.map((e) => e);

    this.setOrderIds(event.container.data);

    await this.transactions.perform(() =>
      this.entityService.modifyMany(entities)
    );
  }

  protected setOrderIds(items: AppDocumentType[]) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order_id = i;
    }
  }
}
