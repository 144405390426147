import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { SvgIconComponent } from "@material-ui/icons";

interface ProjectShortcutsProps {
  shortcuts: ProjectShortcutsItem[];
}

export const ProjectShortcuts: React.FC<ProjectShortcutsProps> = ({
  shortcuts,
}) => {
  const renderItem = (
    item: ProjectShortcutsItem,
    index: string,
    nested = false
  ) => {
    const hasAccess = item.data.access ? item.data.access() : true;

    return hasAccess ? (
      <React.Fragment key={index}>
        <ListItem
          onClick={async (event) => {
            event.preventDefault();
            event.stopPropagation();

            item.data.onClick && (await item.data.onClick());
          }}
          style={{
            height: item.data.children ? 32 : null,
            borderBottom: "1px solid #eeeeee",
            paddingLeft: nested ? 16 : null,
            pointerEvents: item.data.children ? "none" : null,
            backgroundColor: item.data.children ? "#eeeeee" : null,
          }}
          button
        >
          {item.data.icon && (
            <ListItemIcon>
              <item.data.icon />
            </ListItemIcon>
          )}
          <ListItemText primary={item.data.title} />
        </ListItem>
        {item.data.children && (
          <List dense disablePadding>
            {item.data.children.map((child, childIndex) =>
              renderItem(child, `${index}_${childIndex}`, true)
            )}
          </List>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment key={index}></React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <List
        dense
        style={{
          zIndex: 200,
        }}
      >
        {shortcuts.map((item, index) => renderItem(item, `${index}`))}
      </List>
    </React.Fragment>
  );
};

export class ProjectShortcutsItem {
  constructor(
    readonly data: {
      readonly title: string;
      readonly icon?: SvgIconComponent;
      readonly children?: ProjectShortcutsItem[];
      readonly access?: () => boolean;
      readonly onClick?: () => void | Promise<any>;
    }
  ) {}
}
