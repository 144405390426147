import { Entity } from "../entity.service";
import { AppInvite } from "./app-invite.entity";
import { AppUser } from "./app-user.entity";

export class AppLogin extends Entity {
  type = "app.project_logins";

  email: string;
  role_id: string;
  project_id: string;

  syncVersion: string;

  __user__?: AppUser;
  __invite__?: AppInvite;

  transferred_at?: Date;
  created_at?: Date;
  updated_at?: Date;
}

export enum AppLoginRoles {
  Employee = "10",
  Customer = "40",
}
