import { Component, Injector } from '@angular/core';
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from '../react-component/react-attuned.component';
import { PicklistDefinitionsComponent, PicklistDefinitionsComponentProps } from './picklist-definitions.component';

@Component({
  template: reactAttunedComponentTemplate,
})
export class PicklistDefinitionsAngularComponent extends ReactAttunedComponent<PicklistDefinitionsComponentProps> {
  component = PicklistDefinitionsComponent;

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector,
    };
  }
}
