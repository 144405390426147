import gql from "graphql-tag";

export const pointsQuery = gql`
  query pointsQuery($projectId: String!) {
    points: DeliveryListPointEntityFindMany(
      query: {
        filters: [{ field: "projectId", operator: "Equal", value: $projectId }]
        orders: [{ field: "createdAt", direction: "ASC" }]
      }
    ) {
      id
      projectId

      code
      description

      serviceTypeId
      serviceType {
        description
      }
      serviceSpaceId
      serviceSpaceIds
      servicePriorityId
      serviceTicketId

      assignedUserId

      primaryCategoryId
      secondaryCategoryId

      images {
        id
        documentMetaId
      }
    }
    defaultPriority: ServicePriorityEntityFindOne(
      query: {
        filters: [{ field: "isStandard", operator: "Equal", value: "1" }]
      }
    ) {
      id
      description
    }
    defaultType: ServiceTypeEntityFindOne(
      query: {
        filters: [{ field: "isStandard", operator: "Equal", value: "1" }]
      }
    ) {
      id
      description
    }
  }
`;
