import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppUser } from "./app-user.entity";
import { EntityManager, Ops } from "../entity.service";
import { AppLogin, AppLoginRoles } from "./app-login.entity";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { tap, debounceTime } from "rxjs/operators";
import { MdcSnackbar } from "@angular-mdc/web";
import { environment } from "src/environments/environment";
import { AppInvite } from "./app-invite.entity";
import { CellaService } from "../cella.service";
import { composeAppMail } from "./app-mail-template";
import { ActivatedRoute } from "@angular/router";
import { UrlOpenService } from "../url-open.service";
import { DialogService } from "../dialog.service";
import {
  AppLoginDialogComponent,
  AppLoginDialogComponentData,
} from "../app-login-dialog/app-login-dialog.component";
import { AppInviteTextDialogComponent } from "../app-invite-text-dialog/app-invite-text-dialog.component";
import { AppText } from "./app-text.entity";
import { TransactionService } from "../transaction.service";
import { ConfirmDialogComponentConfig } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-app-users",
  templateUrl: "./app-users.component.html",
  styleUrls: ["./app-users.component.scss"],
})
export class AppUsersComponent implements OnInit {
  users: RestResponse<AppUser[]>;
  logins: RestResponse<AppLogin[]>;
  invitation: RestResponse<AppText>;

  query = {
    userQuery: "",
    projectId: "",

    loginQuery: "",
    loginState: "all",
  };

  projectFilterConfig = {
    allowNothing: true,
    title: "Project",
    icon: "archive",
    entityName: "projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  protected onQueryChanged = new Subject<{}>();

  protected get appUserService() {
    return this.entityManager.get(AppUser);
  }

  protected get appLoginService() {
    return this.entityManager.get(AppLogin);
  }

  protected get appInviteService() {
    return this.entityManager.get(AppInvite);
  }

  protected get appTextService() {
    return this.entityManager.get(AppText);
  }

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  constructor(
    protected readonly cella: CellaService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly route: ActivatedRoute,
    protected readonly entityManager: EntityManager,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactions: TransactionService
  ) {}

  ngOnInit() {
    this.query.projectId = this.projectId;

    this.onQueryChanged
      .pipe(
        debounceTime(500),
        tap(() => (this.users = null)),
        tap(() => (this.logins = null))
      )
      .subscribe(() => this.fetch());

    this.invalidate();
  }

  invalidate() {
    this.onQueryChanged.next({});
  }

  searchUser(login: AppLogin) {
    this.query.userQuery = login.email;
    this.invalidate();
  }

  async editInvitation() {
    const result = await this.dialogService.open(
      this.changeDetector,
      AppInviteTextDialogComponent,
      {}
    );

    if (result !== "close") {
      this.invitation = await this.getInvitation();
    }
  }

  async addLogin() {
    const result = await this.dialogService.open(
      this.changeDetector,
      AppLoginDialogComponent,
      {
        data: new AppLoginDialogComponentData({
          projectId: this.query.projectId,
        }),
      }
    );

    if (result !== "close") {
      await this.fetch();
    }
  }

  async revokeInvite(login: AppLogin) {
    await this.appInviteService.delete(login.__invite__.id);

    login.__invite__ = null;

    this.snackbar.open("Uitnodiging teruggetrokken", "Ok", { leading: true });
  }

  copyInvite(login: AppLogin) {
    this.copyToClipboard(this.makeUrl(login.__invite__));
  }

  async sendInvite(login: AppLogin) {
    await this.transactions.performMany(
      [
        () => this.setRoleId(login),
        () => this.setInvite(login),
        () => this.sendMail(login),
      ],
      "Uitnodiging verstuurd"
    );
  }

  async revokeAccess(user: AppUser) {
    const confirm = await this.dialogService.confirm(
      this.changeDetector,
      ConfirmDialogComponentConfig.withDescription(
        "Dit kan niet ongedaan gemaakt worden."
      )
    );

    if (confirm) {
      user.email = null;
      user.google_identity = null;
      user.facebook_identity = null;

      await this.transactions.perform(
        () => this.appUserService.save(user),
        "Authorisatie ingetrokken"
      );
    }
  }

  protected setRoleId(login: AppLogin) {
    login.role_id = AppLoginRoles.Customer;
    login.syncVersion = null;

    return this.appLoginService.save(login);
  }

  protected async setInvite(login: AppLogin) {
    if (!login.__invite__) {
      login.__invite__ = (
        await this.appInviteService.save(
          this.appInviteService.concept({
            email: login.email,
            project_id: login.project_id,
          })
        )
      ).value;
    }

    return new RestResponse({});
  }

  protected sendMail(login: AppLogin) {
    return this.cella.sendEmail({
      from: {
        address: "info@groothuisbouw.info",
        name: "Groothuisbouw Emmeloord",
      },
      to: login.email,
      subject: "Uitnodiging Groothuisbouw App",
      html: composeAppMail({
        title: "Uitnodiging Groothuisbouw App",
        bodyHtml: this.composeMailHtml(),
        actionButtonTitle: "Registreren",
        actionButtonUrl: this.makeUrl(login.__invite__),
      }),
    });
  }

  getState(login: AppLogin) {
    if (!!login.__user__) return "registered";
    if (!!login.__invite__) return "invited";

    return "ready";
  }

  getIcon(login: AppLogin) {
    switch (this.getState(login)) {
      case "registered":
        return "how_to_reg";
      case "invited":
        return "markunread_mailbox";
      default:
        return "bookmark";
    }
  }

  getAvatar(user: AppUser) {
    return `url(${environment.appApiBaseUrl}/api/users/avatar/${user.id})`;
  }

  protected makeUrl(invite: AppInvite) {
    return `${environment.appBaseUrl}/#/invite/${invite.id}`;
  }

  protected copyToClipboard(value: string) {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    this.snackbar.open("Gekopierd", "Ok", { leading: true });
  }

  protected async fetch() {
    this.users = await this.getUsers();
    this.logins = await this.getLogins();
    this.invitation = await this.getInvitation();
  }

  protected async getUsers() {
    const filters = []
      .concat(
        this.query.userQuery
          ? [Ops.Field("email").Like(this.query.userQuery)]
          : []
      )
      .concat(
        this.query.projectId
          ? [Ops.Field("project_id").Equals(this.query.projectId)]
          : []
      );

    return this.appUserService.query({
      filters,
      relations: ["login"],
      orders: [{ field: "email", direction: "asc" }],
      take: filters.length ? null : 30,
    });
  }

  protected matchesState(login: AppLogin) {
    return (
      this.query.loginState === "all" ||
      this.query.loginState === this.getState(login)
    );
  }

  protected async getLogins() {
    const filters = []
      .concat(
        this.query.loginQuery
          ? [Ops.Field("email").Like(this.query.loginQuery)]
          : []
      )
      .concat(
        this.query.projectId
          ? [Ops.Field("project_id").Equals(this.query.projectId)]
          : []
      );

    const hasStateFilter = this.query.loginState != "all";

    const response = await this.appLoginService.query({
      filters,
      relations: ["user", "invite"],
      orders: [{ field: "email", direction: "asc" }],
      take: filters.length || hasStateFilter ? null : 30,
    });

    if (!response.hasError()) {
      return new RestResponse(
        response.value.filter((e) => this.matchesState(e))
      );
    }
  }

  protected async getInvitation() {
    const response = await this.appTextService.findOne(AppText.INVITATION_ID);

    if (!response.hasError()) {
      return response;
    }

    return new RestResponse(
      this.appTextService.concept({
        value: "",
      })
    );
  }

  protected composeMailHtml() {
    return this.invitation.value.value
      .split("\n")
      .map((l) => `<p>${l}</p>`)
      .join("");
  }
}
