import { Table, Entity } from '../entity.service';
import { User } from '../accessibility-users/user.entity';
import { Project } from '../project/project.entity';
import { DocumentMeta } from '../documents/document-meta.entity';

@Table('audit_checklist_images')
export class AuditChecklistImage extends Entity {
    userId: string;
    projectId: string;
    documentMetaId: string;
    costCategoryId: string;
    comment: string;

    __user__?: User;
    __project__?: Project;
    __documentMeta__?: DocumentMeta;

    thumbUrl?: string;
    imageUrl?: string;

    createdAt: Date;

    get project() {
        return this.one(AuditChecklistImage, Project, 'project');
    }
}
