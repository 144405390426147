import * as React from 'react';
import { TextField } from '@material-ui/core';
import { MeterEntity } from 'src/app/service-settings/react/meters/meter.entity';
import { MeterUpdatedEvent } from './meter.event';
import { MeterBloc } from './meter.bloc';

export interface MeterFieldComponentProps {
  bloc: MeterBloc;
  meter: MeterEntity;
  disabled?: boolean;
}

export const MeterFieldComponent: React.FC<MeterFieldComponentProps> = ({
                                                                          bloc,
                                                                          meter,
                                                                          disabled,
                                                                        }) => {
  const [value, setValue] = React.useState(
    meter.deliveryListMeter.value || '',
  );

  return (
    <TextField
      disabled={disabled}
      style={{
        flex: 1,
        minWidth: 256,
        margin: 6,
      }}
      variant='outlined'
      type='string'
      label={`${meter.description} (${meter.unit})`}
      value={value}
      onBlur={() => bloc.add(new MeterUpdatedEvent({ meter, value }))}
      onChange={(event) => setValue(event.target.value)}
    />
  );
};
