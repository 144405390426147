import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlOpenService } from '../url-open.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  dataUrl: SafeResourceUrl;

  protected get path() {
    return this.data.path;
  }

  protected get name() {
    return this.data.name;
  }

  protected get data() {
    return JSON.parse(localStorage.getItem('pdf-viewer'));
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly sanitizer: DomSanitizer,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly httpClient: HttpClient,
  ) { }

  ngOnInit() {
    document.title = this.name;
    
    this.fetch();
  }

  protected async fetch() {
    try {
      const response = await this.httpClient.get(this.path, { responseType: 'blob' }).toPromise();
      const blob = new Blob([response], { type: 'application/pdf' });
      this.dataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    } catch (e) {
      window.close();
    } finally {
    }
  }
}
