import * as React from "react";
import { Grid, Tooltip, withStyles } from "@material-ui/core";
import { GridCellParams } from "@material-ui/data-grid";
import { ConstructionPlanningProjectDto } from "../construction-planning-date.dto";

import { Edit as EditIcon } from "@material-ui/icons";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#0099cc",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: 4,
    padding: 8,
  },
  arrow: {
    color: "#0099cc",
  },
}))(Tooltip);

export interface ConstructionPlanningEditColumnProps {
  project: ConstructionPlanningProjectDto;
}

export function ConstructionPlanningEditColumn({
  project,
}: ConstructionPlanningEditColumnProps) {
  return (
    <HtmlTooltip
      placement="left"
      arrow
      title={<ConstructionPlanningEditTooltip project={project} />}
    >
      <EditIcon style={{ width: "100%", height: "100%" }} color={"primary"} />
    </HtmlTooltip>
  );
}

interface ConstructionPlanningEditTooltipProps {
  project: ConstructionPlanningProjectDto;
}

const ConstructionPlanningEditTooltip = ({
  project,
}: ConstructionPlanningEditTooltipProps) => {
  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <strong>Sondeerwerk:</strong> {project.constructionProbeWork || "-"}
      </Grid>
      <Grid item>
        <strong>Advies:</strong> {project.constructionAdvice || "-"}
      </Grid>
      <Grid item>
        <strong>Tekenwerk:</strong> {project.constructionDrawingWork || "-"}
      </Grid>
      <Grid item>
        <strong>E-construct:</strong> {project.constructionSupplier || "-"}
      </Grid>
      <Grid item>
        <strong>Peil:</strong> {project.constructionLevel || "-"}
      </Grid>
      <Grid item>
        <strong>Tijdelijk niet bereikbaar:</strong>{" "}
        {project.constructionUnavailable}
      </Grid>
    </Grid>
  );
};
