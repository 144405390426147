import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, from, of, iif } from "rxjs";
import { tap, retryWhen, concatMap, delay, catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class ReconnectInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) { }

  intercept(
    request: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {
    const fromApi = request.url.startsWith(environment.baseUrl);

    return fromApi
      ? next.handle(request).pipe(
        tap((event) => this.onTap(event)),
        retryWhen((error) => this.onRetry(error))
      )
      : next.handle(request);
  }

  protected onRetry(errors: any) {
    return errors.pipe(
      concatMap((e: any, i) =>
        // Executes a conditional Observable depending on the result
        // of the first argument
        iif(
          () => e.status !== 0,
          // If the condition is true we throw the error (the last error)
          throwError(e),
          // Otherwise we pipe this back into our stream and delay the retry
          of(e).pipe(
            tap((error: any) =>
              this.authService.onlineSubject.next(error.status !== 0)
            ),
            delay(1000)
          )
        )
      )
    );
  }

  protected onTap(event) {
    if (event instanceof HttpResponse) {
      this.authService.onlineSubject.next(true);
    }
  }
}
