import { Component, ChangeDetectorRef } from "@angular/core";
import { AppProjectCostPreset } from "./app-project-cost-preset.entity";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppProjectCostPresetDialogComponent } from "../app-project-cost-preset-dialog/app-project-cost-preset-dialog.component";

@Component({
  selector: "app-app-project-cost-presets",
  templateUrl: "./app-project-cost-presets.component.html",
  styleUrls: ["./app-project-cost-presets.component.scss"],
})
export class AppProjectCostPresetsComponent extends AppCrudBoilerplate<
  AppProjectCostPreset
> {
  entity = AppProjectCostPreset;
  editDialog = AppProjectCostPresetDialogComponent;

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }
}
