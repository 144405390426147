import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  ComplexTableDataRow,
  ComplexTableDataGroup,
  ComplexTableColumn,
  ComplexTableAction,
  ComplexTableActionClicked,
} from "../complex-table/complex-table.component";
import { MetacomService } from "../metacom.service";
import { formatNumber } from "@angular/common";
import { DialogService } from "../dialog.service";
import { AuthService } from "../auth.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import { Invoice } from "../invoices/invoice.entity";
import * as moment from "moment";
import { StorageService, StorageObject } from "../storage.service";

@Component({
  selector: "app-metacom-invoices",
  templateUrl: "./metacom-invoice.component.html",
  styleUrls: ["./metacom-invoice.component.scss"],
})
export class MetacomInvoiceComponent implements OnInit {
  companies = ["910", "920", "930", "950"];

  tableData: ComplexTableDataGroup<Invoice>[];

  columns = [
    new ComplexTableColumn<Invoice>({
      id: "bedrijf",
      title: "Bedrijf",
      width: "100px",
      text: (value) => value.bedrijf,
    }),
    new ComplexTableColumn<Invoice>({
      id: "executive",
      title: "Relatie",
      width: "auto",
      text: (value) => `${value.relatie} - ${value.relatie_naam}`,
      sort: (value) => `${value.relatie_naam}`,
    }),
    new ComplexTableColumn<Invoice>({
      id: "id",
      title: "Nummer",
      width: "100px",
      text: (value) => value.factuur_nummer,
    }),
    new ComplexTableColumn<Invoice>({
      id: "date",
      title: "Datum",
      width: "128px",
      text: (value) => value.factuur_datum,
      sort: (value) =>
        value.factuur_datum ? moment(value.factuur_datum).unix() : 0,
    }),
    new ComplexTableColumn<Invoice>({
      id: "description",
      title: "Omschrijving",
      width: "auto",
      text: (value) => value.omschrijving,
    }),
    new ComplexTableColumn<Invoice>({
      id: "sum",
      title: "Bedrag",
      width: "100px",
      align: "right",
      text: (value) => formatNumber(value.factuur_bedrag, "nl", "2.2-2"),
    }),
  ];

  actions = [
    new ComplexTableAction<Invoice>({
      id: "view",
      icon: "remove_red_eye",
      title: "Factuur weergeven",
    }),
  ];

  query = {
    relationId: null,
    companyId: null,
    dateFrom: null,
    dateTo: null,
  };

  queryStore: QueryStore;

  relationFilterConfig = {
    allowNothing: true,
    title: "Relatie",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "stage", operator: "Equal", value: "1000" },
      { field: "isSupplier", operator: "Equal", value: "t" },
    ],
  };

  constructor(
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly metacomService: MetacomService,
    protected readonly storageService: StorageService
  ) {}

  ngOnInit() {
    this.queryStore = this.storageService.make(QueryStore);

    if (this.queryStore.value) {
      this.query.relationId = this.queryStore.value.relationId;
      this.query.companyId = this.queryStore.value.companyId;
    } else {
      this.query.relationId = this.authService.user.id;
    }

    this.query.dateFrom = moment().startOf("year").format("YYYY-MM-DD");
    this.query.dateTo = moment().format("YYYY-MM-DD");

    this.fetch();
  }

  action(event: ComplexTableActionClicked<Invoice>) {
    switch (event.action.id) {
      case "view":
        return this.view(event.item);
    }
  }

  async fetch() {
    this.tableData = null;
    this.queryStore.value = {
      relationId: this.query.relationId,
      companyId: this.query.companyId,
    };

    const filters = [];

    if (this.query.relationId) {
      filters.push(`wfn-op.zknaam = "${this.query.relationId}"`);
    }

    if (this.query.dateFrom && this.query.dateTo) {
      const from = moment(this.query.dateFrom).format("DD-MM-YYYY");
      const to = moment(this.query.dateTo).format("DD-MM-YYYY");

      filters.push(`wfn-op.fakdat > "${from}" AND wfn-op.fakdat < "${to}"`);
    }

    if (this.query.companyId) {
      filters.push(`wfn-op.kode = "${this.query.companyId}"`);
    }

    if (this.query.relationId) {
      const response = await this.metacomService.queryTableAsync<any>({
        setName: "metacom",
        tableName: "facturen_inkoop",
        filter: filters.join(" AND "),
      });

      if (!response.hasError()) {
        this.tableData = [
          new ComplexTableDataGroup<Invoice>(
            response.value
              .map((e) => new Invoice(e))
              .map((e) => new InvoiceTableRow(e))
          ),
        ];
      }
    } else {
      this.tableData = [];
    }
  }

  protected async view(invoice: Invoice) {
    const base64 = btoa(invoice.document);

    this.urlOpenService.open(
      `${environment.baseUrl}/volume-public/file-legacy/0/${base64}`,
      true
    );
  }
}

export class InvoiceTableRow extends ComplexTableDataRow<Invoice> {
  constructor(invoice: Invoice) {
    super(invoice);
  }
}

class QueryStore extends StorageObject<{
  relationId: string;
  companyId: string;
}> {
  key = "invoice-filter-storage";
  defaultValue = null;
}
