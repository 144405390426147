import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Apollo } from "apollo-angular";
import { RestResponse } from "../rest.service";
import { AuthService } from "../auth.service";
import { DateValue } from "../year-planning/entities/date-entities";
import { CellaService } from "../cella.service";
import { first, last, chain } from "lodash";
import { EntityManager } from "../entity.service";
import { Project } from "../project/project.entity";
import { DialogService } from "../dialog.service";
import {
  ContractDateDialogComponent,
  ContractDateDialogComponentData,
} from "../contract-date-dialog/contract-date-dialog.component";
import { TransactionService } from "../transaction.service";
import { UrlOpenService } from "../url-open.service";
import { YearPlanningLine } from "../year-planning-lines-dialog/year-planning-line.entity";
import { BlockedCalendarWeek } from "../blocked-calendar-weeks-dialog/blocked-calendar-week.entity";
import { YearPlanningLineCalculator } from "../year-planning-lines-dialog/year-planning-line.calculator";

import gql from "graphql-tag";
import * as moment from "moment";

@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
  styleUrls: ["./contracts.component.scss"],
})
export class ContractsComponent implements OnInit {
  readonly COLUMNS: DateColumn[] = [
    { name: "BK/IT verz.", dateId: "PRJ-VRG-001__16" },
    { name: "Afronding", dateId: "PRJ-VRG-001__1" },
    { name: "Concept", dateId: "PRJ-VRG-001__2" },
    { name: "Definitief", dateId: "PRJ-VRG-001__4" },
    { name: "Fin.ond", dateId: "PRJ-103__1" },
    { name: "Keuken", dateId: "PRJ-006__3" },
    { name: "Badkamer", dateId: "PRJ-006__6" },
  ];

  readonly FILTER_COLUMN = "PRJ-VRG-001__4";

  response: RestResponse<any[]>;
  lineMap: Map<string, YearPlanningLine>;

  relationId: string;
  relationFilterConfig = {
    allowNothing: true,
    title: "Kopersbegeleider",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "isBuyAdvisor", operator: "Equal", value: "True" },
    ],
  };

  protected get yearPlanningLineService() {
    return this.entites.get(YearPlanningLine);
  }

  protected get blockedCalendarWeekService() {
    return this.entites.get(BlockedCalendarWeek);
  }

  protected get dateValueService() {
    return this.entites.get(DateValue);
  }

  constructor(
    protected readonly apollo: Apollo,
    protected readonly auth: AuthService,
    protected readonly cella: CellaService,
    protected readonly entites: EntityManager,
    protected readonly dialog: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactions: TransactionService,
    protected readonly urlOpen: UrlOpenService
  ) {}

  documents(project: Project) {
    this.urlOpen.openNew({
      url: `/#/office/documents/${project.id}`,
      forceNewTab: true,
    });
  }

  getDateValue(dateId: string, project: Project): DateValue {
    return (
      (project.__dateValues__ || []).find(
        (d: DateValue) => d.dateId === dateId
      ) ||
      this.dateValueService.concept({
        projectId: project.id,
        dateId,
        isSynced: false,
      })
    );
  }

  getDate(dateId: string, item: any) {
    return this.getDateValue(dateId, item).value;
  }

  async ngOnInit() {
    if ((this.auth.user.isBuyAdvisor || "").toLowerCase() === "true") {
      this.relationId = this.auth.user.id;
    }

    await this.fetchLines();
    await this.fetch();
  }

  async fetch() {
    this.response = null;

    if (this.relationId) {
      const gqlResponse = await this.apollo
        .query<any>({
          variables: { buyAdvisorId: this.relationId },
          query: BuyAdvisorContracts,
          fetchPolicy: "no-cache",
        })
        .toPromise();

      if (!gqlResponse.errors) {
        const projects: any[] = gqlResponse.data.projects;

        projects.forEach(
          (p) =>
            (p.dateColumns = this.COLUMNS.map((c) => ({
              dateId: c.dateId,
              column: c,
              value: this.getDate(c.dateId, p),
            })))
        );

        projects.forEach((p) => (p.type = "project"));

        for (const [week, line] of Array.from(this.lineMap)) {
          projects.push({
            type: "line",
            buildingWeek: week,
            line,
            order: true,
          });
        }

        this.response = new RestResponse(
          chain(projects)
            .filter(
              (e) =>
                e.type === "line" ||
                !e.dateColumns.find(
                  (d) => d.dateId === this.FILTER_COLUMN && !!d.value
                )
            )
            .orderBy([(e) => e.buildingWeek, (e) => e.order], ["asc", "asc"])
            .value()
        );
      }
    } else {
      this.response = new RestResponse([]);
    }
  }

  async editDate(
    project: Project,
    projectDate: { dateId: string; column: DateColumn; value: Date }
  ) {
    const dialogResult: Date | string = await this.dialog.open(
      this.changeDetector,
      ContractDateDialogComponent,
      {
        data: new ContractDateDialogComponentData(
          projectDate.column.name,
          project,
          projectDate.value
        ),
      }
    );

    if (dialogResult !== "close") {
      await this.transactions.perform(() =>
        this.setDate(project, projectDate.column, dialogResult as Date)
      );

      await this.fetch();
    }
  }

  protected line(week: string) {
    return this.lineMap.get(week);
  }

  protected async setDate(project: Project, column: DateColumn, value: Date) {
    const segments = this.segments(column.dateId);
    const dateValue = this.getDateValue(column.dateId, project);

    dateValue.value = value;

    const mtcResponse = await this.cella.writeCustomFields({
      CustomField: [
        {
          Entity: "0401",
          Origin: `bdr:920¡adm:${project.regionId}¡prj:${project.id}`,
          LineId: 1,
          Code: segments.code,
          SerialNumber: segments.serial,
          Contents: moment(value).format("DD/MM/YYYY"),
        },
      ],
    });

    if (!mtcResponse.hasError()) {
      return await this.dateValueService.save(dateValue);
    }

    return new RestResponse({});
  }

  protected async fetchLines() {
    const blockedWeeks = await this.blockedCalendarWeekService.query();

    if (!blockedWeeks.hasError()) {
      const lines = await this.yearPlanningLineService.query();

      if (!lines.hasError()) {
        this.lineMap = new YearPlanningLineCalculator(blockedWeeks.value).map(
          lines.value
        );
      }
    }
  }

  protected segments(dateId: string) {
    const split = dateId.split("__");

    return {
      code: first(split),
      serial: parseFloat(last(split)),
    };
  }
}

const BuyAdvisorContracts = gql`
  query BuyAdvisorContracts($buyAdvisorId: String) {
    projects: ProjectEntityFindMany(
      options: {
        where: [{ buyAdvisorId: $buyAdvisorId, condition: "1000" }]
        order: { buildingWeek: "ASC" }
      }
    ) {
      id
      regionId
      buildingWeek
      description
      houseType
      houseShape
      buyAdvisor {
        name
        abbreviation
      }
      salesEmployee {
        name
        abbreviation
      }
      __dateValues__: dateValues {
        projectId
        dateId
        value
      }
    }
  }
`;

interface DateColumn {
  name: string;
  dateId: string;
}
