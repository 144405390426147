import * as moment from "moment";
import { ShopRequest } from "./shop-item.dto";

export class ShopDocument {
  readonly time = "000000";
  readonly dateFormat = "YYYY-MM-DD";
  readonly defaultDocumentType = "60-80";

  create(request: ShopRequest) {
    const date = moment().format(this.dateFormat);
    const deliveryDate = moment(request.data.deliveryDate).format(
      this.dateFormat
    );

    return {
      Company: request.data.companyId?.toString(),
      DocumentType: request.data.documentType ?? this.defaultDocumentType,
      DocumentDate: date,
      DocumentDescription: `Besteld door: ${request.data.user.name}`,
      DocumentFooter: request.data.comment,
      DocumentHeader: request.data.addressType,
      DocumentLines: request.data.items.map((item) => ({
        FromCompany: request.data.companyId,
        ToCompany: request.data.companyId,
        ToAdministrationType: "PRJ",
        StartDate: deliveryDate,
        StartTime: this.time,
        EndDate: date,
        EndTime: this.time,
        SourceCompany: request.data.companyId,
        SourceDocumentNumber: request.data.sourceDocumentNumber,
        ToCostCentre: request.data.projectId,
        ToCostUnit: item.kostensoort,
        ToItem: "",
        FromCostCentre: item.leverancier,
        FromCostUnit: item.middel,
        FromQuantity: item.amount,
        Description: item.middel_naam,
        FromUnitPrice: item.eenheid_prijs,
        FromUnit: item.eenheid || "PCE",
        FreeCode: "",
        UserDefinedField: `${request.data.user.id} - ${request.data.user.name}`,
        ChargeCode: 0,
      })),
    };
  }
}
