import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";
import { orderBy } from "lodash";
import { AppPhaseContact } from "./app-phase-contact.entity";
import { AppNotificationTemplate } from "../app-notification-templates/app-notification-template.entity";

export class AppPhase extends Entity {
  type = "app.phases";

  name: string;
  order: number;
  percentage: number;

  is_visible_transferred: boolean;

  sections: AppPhaseSection[];

  set __sections__(value: AppPhaseSection[]) {
    const items = this.entityManager
      .get(AppPhaseSection)
      .healManyResponse(new RestResponse(value)).value;

    this.sections = orderBy(items, (i) => i.order);
  }

  contacts: AppPhaseContact[];

  set __contacts__(value: AppPhaseContact[]) {
    this.contacts = this.entityManager
      .get(AppPhaseContact)
      .healManyResponse(new RestResponse(value)).value;
  }

  start_project_date_type?: string;
  end_project_date_type?: string;

  created_at?: Date;
  updated_at?: Date;
}

export class AppPhaseSection extends Entity {
  type = "app.phase_sections";

  phase_id: number;
  more_info_content_id?: number;

  name: string;
  order: number;
  percentage: number;

  marks: AppPhaseSectionMark[];

  set __marks__(value: AppPhaseSectionMark[]) {
    const items = this.entityManager
      .get(AppPhaseSectionMark)
      .healManyResponse(new RestResponse(value)).value;

    this.marks = orderBy(items, (i) => i.order);
  }

  phase: AppPhase;

  set __phase__(value: AppPhase) {
    this.phase = this.entityManager
      .get(AppPhase)
      .healOneResponse(new RestResponse(value)).value;
  }

  created_at?: Date;
  updated_at?: Date;
}

export class AppPhaseSectionMark extends Entity {
  type = "app.phase_section_marks";

  phase_section_id: number;
  more_info_content_id?: number;
  notification_template_id?: number;
  project_field_group_id?: number;
  project_date_type?: string;
  document_type_id?: number;
  responsible?: string;

  name: string;
  order: number;
  percentage: number;

  is_milestone: boolean;
  is_standard: boolean;
  date_prompt: boolean;

  duration_days?: number;

  timeline_origin_date_type?: string;
  timeline_competence_number?: string;
  timeline_origin_diff_days?: number;

  section: AppPhaseSection;

  set __section__(value: AppPhaseSection) {
    this.section = this.entityManager
      .get(AppPhaseSection)
      .healOneResponse(new RestResponse(value)).value;
  }

  notificationTemplate: AppNotificationTemplate;

  set __notificationTemplate__(value: AppNotificationTemplate) {
    this.notificationTemplate = this.entityManager
      .get(AppNotificationTemplate)
      .healOneResponse(new RestResponse(value)).value;
  }

  created_at?: Date;
  updated_at?: Date;
}

export class AppPhaseSectionMarkValue extends Entity {
  type = "app.phase_section_mark_values";

  project_id: string;
  phase_section_mark_id: number;
  completed_user_id: string;

  send_notification: boolean;

  activated: boolean;
  completed: boolean;

  completed_at: Date;
  created_at?: Date;
  updated_at?: Date;
}
