import { Injectable } from "@angular/core";
import { flatMap } from "lodash";
import { AuthService } from "./auth.service";
import { Entity, EntityManager } from "./entity.service";
import {
  QualityAssurancePresets,
  QualityAssuranceProject,
} from "./quality-assurance/entities";

@Injectable({
  providedIn: "root",
})
export class QualityAssuranceService {
  constructor(
    protected readonly entities: EntityManager,
    protected readonly authService: AuthService
  ) {}

  protected get configurationEntity() {
    return this.entities.get(ConfigurationEntity);
  }

  protected get qualityAssuranceService() {
    return this.entities.get(QualityAssuranceEntity);
  }

  protected getQuestionId(props: QualityAssurancePresets, companyCode: string) {
    const questionGroups = flatMap(props.departments, (dep) =>
      dep.questionGroups?.length
        ? dep.questionGroups
        : flatMap(dep.departments, (subDep) => subDep.questionGroups ?? [])
    );

    const questions = flatMap(questionGroups, (qg) => qg.questions);

    return questions.find((question) => question.companyCode === companyCode)
      ?.id;
  }

  async companyCodesFilled(projectId: string, companyCodes: string[]) {
    const responseConfiguration = await this.configurationEntity.findOne(
      "quality-assurance-preset"
    );

    const response = await this.qualityAssuranceService.queryFirst({
      filters: [{ field: "projectId", operator: "Equal", value: projectId }],
    });

    const presets = responseConfiguration?.value
      ?.data as QualityAssurancePresets;

    return companyCodes
      .map((companyCode) => this.getQuestionId(presets, companyCode))
      .every(
        (questionId) => !!response.value?.data?.answers?.[questionId]?.state
      );
  }
}

export class ConfigurationEntity extends Entity {
  type = "configurations";

  data: any;
}

export class QualityAssuranceEntity extends Entity {
  type = "quality_assurances";

  data: QualityAssuranceProject;
}
