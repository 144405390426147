import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  ComplexTableDataRow,
  ComplexTableDataGroup,
  ComplexTableColumn,
} from "../complex-table/complex-table.component";
import { MetacomService } from "../metacom.service";
import { formatNumber } from "@angular/common";
import { DialogService } from "../dialog.service";
import { AuthService } from "../auth.service";
import { UrlOpenService } from "../url-open.service";
import { StorageService } from "../storage.service";
import { EntityManager, Ops } from "../entity.service";
import { MetacomInvoicePaymentBatchEntity } from "./metacom-invoice-payment-batch.entity";
import { chain, sumBy } from "lodash";
import * as moment from "moment";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";

@Component({
  selector: "app-metacom-invoice-payment-batches",
  templateUrl: "./metacom-invoice-payment-batch.component.html",
  styleUrls: ["./metacom-invoice-payment-batch.component.scss"],
})
export class MetacomInvoicePaymentBatchComponent implements OnInit {
  tableData: ComplexTableDataGroup<MetacomInvoicePaymentBatchEntity>[];

  readonly dateFormat = "YYYY-MM-DD";

  columns = [
    new ComplexTableColumn<MetacomInvoicePaymentBatchEntity>({
      id: "factuurnummer",
      title: "Factuurnummer",
      width: "100px",
      text: (value) => value.name,
    }),
    new ComplexTableColumn<MetacomInvoicePaymentBatchEntity>({
      id: "bedrag",
      title: "Bedrag",
      width: "100px",
      align: "right",
      text: (value) => formatNumber(value.amount, "nl", "2.2-2"),
    }),
    new ComplexTableColumn<MetacomInvoicePaymentBatchEntity>({
      id: "bedrag_g_rekening",
      title: "Bedrag (G-rekening)",
      width: "100px",
      align: "right",
      text: (value) => formatNumber(value.amountBank, "nl", "2.2-2"),
    }),
  ];

  actions = [];

  query = {
    relationId: null,
  };

  relationFilterConfig = {
    allowNothing: false,
    title: "Relatie",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [{ field: "stage", operator: "Equal", value: "1000" }],
  };

  protected get service() {
    return this.entityManager.get(MetacomInvoicePaymentBatchEntity);
  }

  get allowFilter() {
    return this.grantService.varIs(
      grants.metacom_invoice_payment_batch.allow_filter,
      "true"
    );
  }

  constructor(
    protected readonly grantService: GrantService,
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly metacomService: MetacomService,
    protected readonly storageService: StorageService,
    protected readonly entityManager: EntityManager
  ) {}

  async ngOnInit() {
    this.query.relationId = this.authService.user.id;

    await this.fetch();
  }

  async fetch() {
    this.tableData = null;

    const filters = [];

    if (this.query.relationId) {
      filters.push(Ops.Field("relationId").Equals(this.query.relationId));
    }

    const response = await this.service.query({
      filters,
    });

    if (!response.hasError()) {
      this.tableData = chain(response.value)
        .groupBy(
          (row) => `${row.companyId}-${row.relationId}-${row.date}-${row.batch}`
        )
        .map(
          (items, groupKey) =>
            new ComplexTableDataGroup(
              items.map((item) => new ComplexTableDataRow(item)),
              groupKey,
              this.formatGroupHeader(items[0]),
              true,
              this.createColumns(items),
              false
            )
        )
        .orderBy(
          (g) => moment(g.name.substr(0, 10), "DD-MM-YYYY", true).unix(),
          "desc"
        )
        .value();
    }
  }

  formatGroupHeader(row: MetacomInvoicePaymentBatchEntity): string {
    const date = moment(row.date).format("DD-MM-YYYY");

    return `${date} - ${row.companyName} (${row.companyId}) - ${row.relationName} (${row.relationId})`;
  }

  createColumns(items: Array<MetacomInvoicePaymentBatchEntity>) {
    const amount = sumBy(items, (item) => parseFloat(<any>item.amount) || 0);
    const amountBank = sumBy(
      items,
      (item) => parseFloat(<any>item.amountBank) || 0
    );

    return [
      // { value: `Batch ${items[0].batch}`, align: "left" },
      { value: `${formatNumber(amount, "nl", "2.2-2")}`, align: "right" },
      { value: `${formatNumber(amountBank, "nl", "2.2-2")}`, align: "right" },
    ];
  }
}
