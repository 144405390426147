import * as React from "react";
import { Injector } from "@angular/core";
import { BlocContext } from "@comceptum-software/react-bloc/dist/bloc.context";
import { ConstructionPlanningBloc } from "./construction-planning.bloc";
import {
  ConstructionPlanningLoadInProgressState,
  ConstructionPlanningLoadSuccessState,
  ConstructionPlanningState,
} from "./construction-planning.state";
import { BlocBuilder } from "@comceptum-software/react-bloc";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { ConstructionPlanningMapMarker } from "./components/construction-planning-map.marker";

export interface ConstructionPlanningMapProps {
  injector: Injector;
}

export function ConstructionPlanningMap({
  injector,
}: ConstructionPlanningMapProps) {
  function build(
    context: BlocContext<ConstructionPlanningBloc>,
    state: ConstructionPlanningState
  ) {
    const loading =
      state instanceof ConstructionPlanningLoadInProgressState ||
      (state instanceof ConstructionPlanningLoadSuccessState &&
        state.isReloading);

    const rows =
      state instanceof ConstructionPlanningLoadSuccessState
        ? state.projects
        : [];

    const dates =
      state instanceof ConstructionPlanningLoadSuccessState ? state.dates : [];

    return (
      <Map
        zoom={7}
        trackResize
        center={[52.132633, 5.2912659]}
        style={{ height: "100%", zIndex: 1 }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {rows
          .filter((_project) => _project.latitude && _project.longitude)
          .map((_project) => (
            <ConstructionPlanningMapMarker
              key={_project.id}
              dates={dates}
              project={_project}
            />
          ))}
      </Map>
    );
  }

  return (
    <BlocBuilder<ConstructionPlanningBloc, ConstructionPlanningState>
      bloc={ConstructionPlanningBloc}
      builder={build}
    />
  );
}
