import * as React from 'react';
import { Avatar, Box, Chip, makeStyles, Paper } from '@material-ui/core';
import { BlocBuilder } from 'src/app/service-settings/bloc/bloc.builder';
import { Alert } from '@material-ui/lab';
import { Apollo } from 'apollo-angular';
import { DeliveryListPointEntity } from '../../delivery-list.entity';
import { PointAddedFromPresetEvent, PointLoadRequestEvent } from './point.event';
import { PointBloc } from './point.bloc';
import { PointLoadFailureState, PointLoadInProgressState, PointLoadSuccessState, PointState } from './point.state';
import { PointEditorComponent } from './point-editor.component';
import { RestService } from 'src/app/rest.service';
import { PointCreatorComponent } from './point-creator.component';
import { HttpClient } from '@angular/common/http';
import { chain, first } from 'lodash';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  points: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paper: {
    marginBottom: 12,
  },
}));

export interface PointComponentProps {
  disabled?: boolean;
  apollo: Apollo;
  projectId: string;
  rest: RestService;
  http: HttpClient;
}

export const PointComponent: React.FC<PointComponentProps> = ({
                                                                disabled,
                                                                projectId,
                                                                apollo,
                                                                rest,
                                                                http,
                                                              }) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new PointBloc(apollo).add(new PointLoadRequestEvent({ projectId })),
  );

  const mapPointToNode = (point: DeliveryListPointEntity, index: number) => {
    return (
      <PointEditorComponent
        disabled={disabled}
        rest={rest}
        http={http}
        bloc={bloc}
        key={`point-${index}`}
        pointIndex={index}
        point={point}
      />
    );
  };

  function hasUnSavedPoints(state: PointLoadSuccessState) {
    return !!state.props.points.find(_point => !_point.id);
  }

  function mapStateToAggregations(state: PointLoadSuccessState) {
    const results = chain(state.props.points)
      .groupBy((_point) => _point.serviceTypeId)
      .map((_items, _key) => ({
        typeName: first(_items).serviceType
          ? first(_items).serviceType.description
          : 'Niet toegewezen',
        amount: _items.length,
      }))
      .value();

    return [
      <Chip
        avatar={<Avatar>{state.props.points.length}</Avatar>}
        label={'Totaal'}
        style={{ marginRight: 8, fontWeight: 'bold' }}
      />,
      ...results.map((_item) => (
        <Chip
          avatar={<Avatar>{_item.amount}</Avatar>}
          label={_item.typeName}
          style={{ marginRight: 8 }}
        />
      )),
    ];
  }

  function* mapStateToNode(state: PointState) {
    if (state instanceof PointLoadInProgressState) {
      yield <p key='mtr-loading'>Laden...</p>;
    }

    if (state instanceof PointLoadSuccessState) {
      yield (
        <div key='tr-list' className={classes.points}>
          <div style={{ margin: 0, marginBottom: 12, width: '100%' }}>
            {mapStateToAggregations(state)}
          </div>

          {...state.props.points.map((point, index) =>
            mapPointToNode(point, index),
          )}

          <PointCreatorComponent
            rest={rest}
            disabled={(disabled || hasUnSavedPoints(state))}
            onAdd={(preset) =>
              bloc.add(new PointAddedFromPresetEvent({ projectId, preset }))
            }
          />
        </div>
      );
    }

    if (state instanceof PointLoadFailureState) {
      yield (
        <Alert key='mtr-errors' severity='error'>
          {state.props.message}
        </Alert>
      );
    }
  }

  return (
    <Paper className={classes.paper} elevation={1}>
      <Box p={2}>
        <BlocBuilder
          bloc={bloc}
          builder={(state) => <>{...Array.from(mapStateToNode(state))}</>}
        />
      </Box>
    </Paper>
  );
};
