import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserNotification } from './user-notification.entity';
import { RestResponse } from '../rest.service';
import { EntityManager } from '../entity.service';
import { last } from 'lodash';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  response: RestResponse<UserNotification>;

  get notificationId() {
    return this.route.snapshot.paramMap.get('id');
  }

  constructor(
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly entityManager: EntityManager,
  ) {
    route.params.subscribe(() => this.fetch());
  }

  protected get userNotificationService() {
    return this.entityManager.get(UserNotification);
  }

  ngOnInit() {
    this.fetch();
  }

  async open() {
    const relative = last(this.response.value.url.split('/#/'));

    await this.router.navigateByUrl(relative);
  }

  protected async fetch() {
    const response = await this.userNotificationService.findOne(this.notificationId);

    if (!response.hasError()) {
      response.value.readAt = new Date();
      this.response = await this.userNotificationService.save(response.value);
    }
  }
}
