import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { RestService } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { MdcSnackbar } from "@angular-mdc/web";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent {
  readonly passwordPattern = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  passwordForm = new FormGroup(
    {
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(this.passwordPattern),
      ]),
      passwordRepeat: new FormControl("", [Validators.required]),
    },
    this.checkPasswords
  );

  protected get userResetPasswordId() {
    return this.route.snapshot.paramMap.get("id");
  }

  constructor(
    protected readonly router: Router,
    protected readonly auth: AuthService,
    protected readonly route: ActivatedRoute,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly transactions: TransactionService
  ) {}

  async save() {
    const response = await this.transactions.perform(() =>
      this.restService.post("user-password-resets/reset-anonymous", {
        userResetPasswordId: this.userResetPasswordId,
        password: this.passwordForm.get("password").value,
      })
    );

    if (!response.hasError()) {
      this.snackbar.open("Opgeslagen", "Ok");
      this.auth.logout(false);
      this.router.navigate(["login"]);
    }
  }

  protected checkPasswords(group: FormGroup) {
    const password = group.get("password").value;
    const passwordRepeat = group.get("passwordRepeat").value;

    return password === passwordRepeat ? null : { notSame: true };
  }
}
