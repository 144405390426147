import {
  Divider,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Attachment as AttachmentIcon } from "@material-ui/icons";

import * as moment from "moment";
import * as React from "react";
import { useMemo } from "react";
import { MailTrafficItemDto } from "../dto/mail-traffic-item.dto";

const useStyles = makeStyles(() => ({
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "70%",
  },
  singleLine: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 12,
    margin: 0,
  },
  secondaryAction: {
    height: "70%",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  attachmentIcon: {
    color: "grey",
    fontSize: 16,
    position: "absolute",
    bottom: 0,
    right: 0,
    background: "white",
    borderRadius: "100%",
  },
  blockItem: {
    display: "bloc",
  },
}));

export interface MailTrafficListItemProps {
  item: MailTrafficItemDto;
  selected: boolean;
  onClick: () => void | Promise<any>;
}

export function MailTrafficListItem({
  item,
  selected,
  onClick,
}: MailTrafficListItemProps) {
  const classes = useStyles();
  const direction = useMemo(
    () => (item.isOutgoing ? "employee" : "customer"),
    [item]
  );

  const escapeMailBox = (value: string) => {
    return value
      ? value
          .replace(/\s*\[.*?\]\s*/g, "")
          .split("/")
          .shift()
      : "Onbekend";
  };

  const fromUser = () => {
    return escapeMailBox(item.isOutgoing ? item.folder : item.fromOrTo);
  };

  const toUser = () => {
    return escapeMailBox(item.isOutgoing ? item.fromOrTo : item.folder);
  };

  const getAvatarUrl = () => {
    switch (direction) {
      case "customer":
        return `https://eu.ui-avatars.com/api/?name=${fromUser()}&background=0099CC&color=fff`;
      case "employee":
        return "/assets/imgs/landing.jpg";
    }
  };

  return (
    <>
      <ListItem selected={selected} dense button onClick={onClick}>
        <ListItemAvatar>
          <Avatar src={getAvatarUrl()} />
        </ListItemAvatar>

        <ListItemText
          className={classes.text}
          primaryTypographyProps={{
            className: classes.ellipsis,
          }}
          secondaryTypographyProps={{
            className: classes.text,
          }}
          primary={fromUser()}
          secondary={
            <>
              <Typography component="span" className={classes.singleLine}>
                <strong>Aan: </strong> {toUser()}
              </Typography>
              <br />
              <span className={classes.singleLine}>{item.subject}</span>
            </>
          }
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <Typography component="span" variant="caption">
            {moment(item.date).locale("nl").format("ll")}
          </Typography>

          {item.hasAttachment && (
            <AttachmentIcon className={classes.attachmentIcon} />
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
}
