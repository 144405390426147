import gql from "graphql-tag";

export const picklistFetchDetails = gql`
  query picklistFetchDetails(
    $picklistDefinitionId: String!
    $projectId: String!
  ) {
    state: PicklistStateEntityFindOne(
      query: {
        filters: [
          { field: "projectId", operator: "Equal", value: $projectId }
          {
            field: "picklistDefinitionId"
            operator: "Equal"
            value: $picklistDefinitionId
          }
        ]
      }
    ) {
      id
      projectId
      picklistDefinitionId
      completedByUserId
    }
    definition: PicklistDefinitionEntityFindOne(
      query: {
        filters: [
          { field: "id", operator: "Equal", value: $picklistDefinitionId }
        ]
      }
    ) {
      icon
      name
      hideColumns
      elementTypes
      indeterminateToggles
      renameFilterName
      metacomListTable
      metacomLinesTable
    }
    project: ProjectEntityFindOne(
      query: {
        filters: [{ field: "id", operator: "Equal", value: $projectId }]
      }
    ) {
      id
      description
    }
  }
`;

export const picklistFetchComments = gql`
  query picklistFetchComments(
    $picklistDefinitionId: String!
    $projectId: String!
  ) {
    comments: PicklistCommentEntityFindMany(
      query: {
        filters: [
          { field: "projectId", operator: "Equal", value: $projectId }
          {
            field: "picklistDefinitionId"
            operator: "Equal"
            value: $picklistDefinitionId
          }
        ]
        orders: [{ field: "createdAt", direction: "DESC" }]
      }
    ) {
      id
      userId
      user {
        name
      }
      text
      createdAt
    }
  }
`;

export const picklistElementStatesQuery = gql`
  query picklistElementStatesQuery(
    $picklistDefinitionId: String!
    $projectId: String!
  ) {
    states: PicklistElementStateEntityFindMany(
      query: {
        filters: [
          { field: "projectId", operator: "Equal", value: $projectId }
          {
            field: "picklistDefinitionId"
            operator: "Equal"
            value: $picklistDefinitionId
          }
        ]
      }
    ) {
      id
      elementId
      state
    }
  }
`;
