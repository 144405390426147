import { Inject } from "@angular/core";
import { Entity } from "../entity.service";
import { AppCrudBoilerplate } from "./app-crud.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";

export abstract class AppCrudDialogBoilerplate<T extends Entity> {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<T>,
    protected readonly dialog: MdcDialogRef<AppCrudDialogBoilerplate<T>>
  ) {}

  abstract get canSave(): boolean;

  async save(closeDialog = true) {
    const response = await this.data.boilerplate.transactions.perform(() =>
      this.data.boilerplate.entityService.save(this.data.model)
    );

    if (!response.hasError()) {
      this.data.model.id = response.value.id;

      if (closeDialog) {
        this.close(response);
      }

      return response;
    }
  }

  close(response: any) {
    this.dialog.close(response);
  }
}

export class AppCrudDialogBoilerplateData<T extends Entity> {
  constructor(readonly model: T, readonly boilerplate: AppCrudBoilerplate<T>) {}
}

interface DeletableEntity extends Entity {
  isDeleted?: boolean;
}
