import { OnInit } from "@angular/core";
import * as React from "react";
import * as ReactDOM from "react-dom";

export interface ReactComponentProps<TComponent> {
  component: TComponent;
}

export abstract class ReactComponent implements OnInit {
  containerId: string = "react-container";

  abstract component: any;

  params(): ReactComponentProps<ReactComponent> {
    return {
      component: this,
    };
  }

  ngOnInit() {
    this.render();
  }

  protected render() {
    const container = document.getElementById(this.containerId);

    if (container) {
      ReactDOM.render(
        React.createElement(this.component, this.params()),
        container
      );
    }
  }
}
