import { EventEmitter, Injectable } from "@angular/core";
import { isArray } from "lodash";
import { DialogService } from "./dialog.service";
import { DocumentMeta } from "./documents/document-meta.entity";
import { RestService } from "./rest.service";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor(
    protected readonly restService: RestService,
    protected readonly dialogService: DialogService
  ) {}

  async attunedUpload(file: AttunedFile) {
    const onProgress = new EventEmitter<number>();
    onProgress.subscribe((value: number) => (file.progress = value));

    try {
      const response = await this.restService.postFiles<DocumentMeta>(
        `documents/upload-single/${this.projectIdOk(file.projectId)}${
          file.trackingId ? `/${file.trackingId}` : ""
        }`,
        [file.nativeFile as File],
        onProgress
      );

      return new AttunedFileResponse(file, response);
    } catch (e) {
      return new AttunedFileResponse(file, null, e);
    }
  }

  async upload(data: FileUploadData, projectId: string) {
    const onProgress = new EventEmitter<number>();
    onProgress.subscribe((val) => (data.progress = val));

    const files =
      data.fileList instanceof FileList
        ? this.convert(data.fileList)
        : isArray(data.fileList)
        ? data.fileList
        : [data.fileList];

    return await this.restService.postFiles<DocumentMeta[]>(
      `documents/upload/${this.projectIdOk(projectId)}`,
      files,
      onProgress
    );
  }

  async overwrite(data: FileUploadData, id: string) {
    const onProgress = new EventEmitter<number>();
    onProgress.subscribe((val) => (data.progress = val));

    return await this.restService.postFiles<DocumentMeta>(
      `documents/overwrite/${id}`,
      [data.fileList as File],
      onProgress
    );
  }

  async imagePreviews(files: FileList) {
    const images = this.convert(files)
      .filter((f) => f.type.match(/image\/*/))
      .map((f) => this.imagePreview(f));

    return await Promise.all(images);
  }

  async imagePreview(file: File) {
    return new Promise<string | ArrayBuffer>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => resolve(reader.result);
    });
  }

  convert(list: FileList): File[] {
    return Array.apply(null, Array(list.length)).map((v, i) => list.item(i));
  }

  protected projectIdOk(projectId: string) {
    return projectId.charAt(0) === "S" ? projectId.substring(1) : projectId;
  }
}

export class FileUploadData {
  progress?: 0;
  constructor(readonly fileList: FileList | File | File[]) {}
}

export class AttunedFile {
  progress: number;

  constructor(
    readonly nativeFile: File,
    readonly projectId: string,
    readonly trackingId: string = null
  ) {}
}

export class AttunedFileResponse {
  constructor(
    readonly file: AttunedFile,
    readonly response?: DocumentMeta,
    readonly error?: Error
  ) {}
}
