import { Component, OnInit, Inject } from "@angular/core";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { AppProjectFieldGroup } from "../app-project-fields/app-project-field-group.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-app-project-field-group-dialog",
  templateUrl: "./app-project-field-group-dialog.component.html",
  styleUrls: ["./app-project-field-group-dialog.component.scss"],
})
export class AppProjectFieldGroupDialogComponent extends AppCrudDialogBoilerplate<
  AppProjectFieldGroup
> {
  get canSave() {
    return !!this.data.model.name;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppProjectFieldGroup>,
    protected readonly dialog: MdcDialogRef<AppProjectFieldGroupDialogComponent>
  ) {
    super(data, dialog);
  }
}
