export class HourDeclarationTemplate {
    constructor(
        readonly columns: HourDeclarationTemplateColumn[],
        readonly companyId?: string,
    ) { }
}

export class HourDeclarationTemplateColumn {
    constructor(
        readonly id: string,
        readonly title: string,
        readonly required: boolean
    ) { }
}
