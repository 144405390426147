import { Component, OnInit, Inject } from "@angular/core";
import { MetacomService } from "../metacom.service";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { RestResponse } from "../rest.service";

@Component({
  selector: "app-metacom-relation-contact-dialog",
  templateUrl: "./metacom-relation-contact-dialog.component.html",
  styleUrls: ["./metacom-relation-contact-dialog.component.scss"],
})
export class MetacomRelationContactDialogComponent implements OnInit {
  response: RestResponse<RelationContact[]>;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: MetacomRelationContactDialogComponentData,

    protected readonly metacom: MetacomService
  ) {}

  icon(item: RelationContact) {
    switch (item.type) {
      case RelationContactType.Phone:
        return "call";
      case RelationContactType.Mail:
        return "alternate_email";
    }
  }

  href(item: RelationContact) {
    switch (item.type) {
      case RelationContactType.Phone:
        return `tel:${item.nummer}`;
      case RelationContactType.Mail:
        return `mailto:${item.nummer}`;
    }
  }

  async ngOnInit() {
    const response = await this.metacom.queryTableAsync<RelationContact>({
      setName: "metacom",
      tableName: "relaties_tel",
      filter: `mtc_telnr.rel = "${this.data.relationId}"`,
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        response.value
          .filter((item) => !!item.nummer)
          .filter((item) => item.type === this.data.type)
      );
    }
  }
}

export class MetacomRelationContactDialogComponentData {
  constructor(
    readonly type: RelationContactType,
    readonly relationId: string
  ) {}
}

export enum RelationContactType {
  Phone = "1",
  Mail = "3",
}

interface RelationContact {
  relatie: string;
  type: RelationContactType;
  nummer: string;
  omschrijving: string;
}
