import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { HousePartGroup } from '../cargo/house-part.entity';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';

@Component({
  selector: 'app-cargo-split-dialog',
  templateUrl: './cargo-split-dialog.component.html',
  styleUrls: ['./cargo-split-dialog.component.scss']
})
export class CargoSplitDialogComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly group: HousePartGroup,

    protected readonly dialog: MdcDialogRef<CargoSplitDialogComponent>) { }

  ngOnInit() {
    this.group.elements.forEach(e => e.isSplit = false);
  }

  ngAfterViewInit() {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
  }

  save() {
    this.dialog.close(this.group);
  }
}
