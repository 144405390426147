import * as React from "react";
import {
  ConstructionPlanningCommentEditRequest,
  ConstructionPlanningDateEditRequest,
  ConstructionPlanningTable,
} from "./construction-planning.table";
import { Injector } from "@angular/core";
import { BlocProvider } from "@comceptum-software/react-bloc";
import { ConstructionPlanningBloc } from "./construction-planning.bloc";
import { Apollo } from "apollo-angular";
import { Fab, Grid, ThemeProvider } from "@material-ui/core";
import { useState } from "react";
import { ConstructionPlanningMap } from "./construction-planning.map";
import {
  Map as MapIcon,
  RotateLeft as RotateLeftIcon,
} from "@material-ui/icons";
import { GrantService } from "../grant.service";
import { ConstructionPlanningHighlightProjectEvent } from "./construction-planning.event";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";

export interface ConstructionPlanningScreenProps {
  injector: Injector;
  onDateEditRequest: (
    request: ConstructionPlanningDateEditRequest
  ) => Promise<any>;
  onCommentEditRequest: (
    request: ConstructionPlanningCommentEditRequest
  ) => Promise<any>;
}

enum ConstructionPlanningScreenViewMode {
  TABLE,
  MAP,
}

export function ConstructionPlanningScreen({
  injector,
  onDateEditRequest,
  onCommentEditRequest,
}: ConstructionPlanningScreenProps) {
  const apollo = React.useMemo(() => injector.get(Apollo), []);
  const grantService = React.useMemo(() => injector.get(GrantService), []);
  const bloc = React.useMemo(
    () => new ConstructionPlanningBloc(apollo, grantService),
    [apollo, grantService]
  );
  const [viewMode, setViewMode] = useState(
    ConstructionPlanningScreenViewMode.TABLE
  );

  const undoHighlight = () => {
    bloc.add(
      new ConstructionPlanningHighlightProjectEvent({
        projectId: null,
      })
    );
  };

  const toggleViewMode = () => {
    const newViewMode =
      viewMode === ConstructionPlanningScreenViewMode.MAP
        ? ConstructionPlanningScreenViewMode.TABLE
        : ConstructionPlanningScreenViewMode.MAP;
    setViewMode(newViewMode);

    if (newViewMode === ConstructionPlanningScreenViewMode.TABLE) {
      undoHighlight();
    }
  };

  return (
    <BlocProvider<ConstructionPlanningBloc> create={() => bloc}>
      <PortalThemeProvider>
        <Grid
          container
          direction="column"
          style={{ height: "100%", fontSize: 12 }}
        >
          {viewMode === ConstructionPlanningScreenViewMode.MAP && (
            <Grid item style={{ flex: 1 }}>
              <ConstructionPlanningMap injector={injector} />
            </Grid>
          )}
          <Grid item style={{ flex: 1 }}>
            <ConstructionPlanningTable
              injector={injector}
              onCommentEditRequest={onCommentEditRequest}
              onDateEditRequest={onDateEditRequest}
            />
          </Grid>
        </Grid>
        <Fab
          style={{
            position: "absolute",
            bottom: 8,
            right: 256,
          }}
          size="small"
          color="primary"
          aria-label="map"
          onClick={toggleViewMode}
        >
          <MapIcon />
        </Fab>
        {viewMode === ConstructionPlanningScreenViewMode.MAP && (
          <Fab
            style={{
              position: "absolute",
              bottom: 8,
              right: 256 + 48,
            }}
            size="small"
            color="primary"
            aria-label="map"
            onClick={undoHighlight}
          >
            <RotateLeftIcon />
          </Fab>
        )}
      </PortalThemeProvider>
    </BlocProvider>
  );
}
