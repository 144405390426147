import { User } from "../accessibility-users/user.entity";
import { Entity } from "../entity.service";
import { Project } from "../project/project.entity";

export class YearPlanningModification extends Entity {
  type = "year_planning_modifications";

  userId: string;
  __user__?: User;

  projectId: string;
  __project__?: Project;

  oldWeek: string;
  newWeek: string;

  notes: string;

  updatedAt?: Date;
  createdAt?: Date;
}
