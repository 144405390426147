import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppPlanCompetenceSetting } from "./app-plan-competence-setting.entity";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppActivityDialogComponent } from "../app-activity-dialog/app-activity-dialog.component";

@Component({
  selector: "app-app-activities",
  templateUrl: "./app-activities.component.html",
  styleUrls: ["./app-activities.component.scss"],
})
export class AppActivitiesComponent extends AppCrudBoilerplate<
  AppPlanCompetenceSetting
> {
  entity = AppPlanCompetenceSetting;
  editDialog = AppActivityDialogComponent;

  get relations() {
    return ["plan_competence"];
  }

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }
}
