import { Entity } from '../entity.service';
import { DocumentMeta } from '../documents/document-meta.entity';

export class InvoiceAttachment extends Entity {
    type = 'invoice_attachments';

    metacomOrderId: string;
    documentMetaId: string;

    __documentMeta__?: DocumentMeta;
}
