import {
  Box,
  Button,
  Container,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { first } from "lodash";
import * as React from "react";
import Dropzone from "react-dropzone";
import { User } from "src/app/accessibility-users/user.entity";
import { EntitySelect } from "src/app/entity-select/entity-select";
import { Ops } from "src/app/entity.service";
import { ReactComponentProps } from "src/app/react-component/react.component";
import { SecureImage } from "src/app/secured-image/secure-image";
import { BlocBuilder } from "src/app/service-settings/bloc/bloc.builder";
import { DeliveryListComponent } from "../delivery-list.component";
import { CompletionComponent } from "./completion/completion.component";
import { DeliveredAtComponent } from "./core/delivered-at.component";
import { DeliveryListBloc } from "./core/delivery-list.bloc";
import {
  DeliveryListAssignedUserUpdateEvent,
  DeliveryListHouseUpdateEvent,
  DeliveryListLoadRequestEvent,
} from "./core/delivery-list.event";
import {
  DeliveryListLoadFailureState,
  DeliveryListLoadInProgressState,
  DeliveryListLoadSuccessState,
  DeliveryListState,
} from "./core/delivery-list.state";
import { HiredAgentComponent } from "./core/hired-agent.components";
import { KeyAmountComponent } from "./core/key-amount.components";
import { MeterComponent } from "./meter/meter.component";
import { PointComponent } from "./point/point.component";
import { SignatureComponent } from "./signature/signature.component";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  paper: {
    marginBottom: 12,
  },
  stepHidden: {
    display: "none",
  },
  stepVisible: {
    display: "block",
  },
  table: {
    width: "100%",
  },
  tableCell: {
    padding: 4,
  },
  tableCellValue: {
    padding: 4,
  },
  selectField: {
    marginTop: 12,
    margin: 4,
  },
}));

export interface ReactDeliveryListComponentProps
  extends ReactComponentProps<DeliveryListComponent> {}

export const ReactDeliveryListComponent: React.FC<
  ReactDeliveryListComponentProps
> = ({ component }) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new DeliveryListBloc(component.apollo, component.entities)
  );
  const [activeStep, setActiveStep] = React.useState(0);

  function composeHouseImageEditor(state: DeliveryListLoadSuccessState) {
    return (
      <>
        <Dropzone
          disabled={state.props.deliveryList.isFinal}
          accept="image/*"
          multiple={false}
          onDrop={(acceptedFiles) => {
            bloc.add(
              new DeliveryListHouseUpdateEvent({ file: first(acceptedFiles) })
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ padding: 4 }}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <Button
                    disabled={state.props.deliveryList.isFinal}
                    style={{ height: 56, width: "100%" }}
                    size="medium"
                    variant="outlined"
                  >
                    Woning afbeelding aanpassen
                  </Button>
                </div>
              </div>
            </section>
          )}
        </Dropzone>

        {state.props.deliveryList.houseDocumentMetaId &&
          state.props.imageHash && (
            <SecureImage
              style={{
                width: "100%",
                marginTop: 8,
                padding: 6,
                borderRadius: 10,
                border: "1px solid #eeeee",
              }}
              src={`documents/view/${state.props.deliveryList.houseDocumentMetaId}?v=${state.props.imageHash}`}
              noCache
              noDebounce
              http={component.http}
            />
          )}
      </>
    );
  }

  function mapStateToDetails(state: DeliveryListLoadSuccessState) {
    return (
      <table className={classes.table}>
        <tbody>
          <tr>
            <td className={classes.tableCell}>
              <strong>De ondernemer</strong>
            </td>
            <td className={classes.tableCellValue}>Groothuisbouw</td>
          </tr>
          <tr>
            <td className={classes.tableCell}>Straat</td>
            <td className={classes.tableCellValue}>Titaniumweg 10</td>
          </tr>
          <tr>
            <td className={classes.tableCell}>Postcode, plaats</td>
            <td className={classes.tableCellValue}>8304 BR Emmeloord</td>
          </tr>

          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>

          <tr>
            <td className={classes.tableCell}>
              <strong>De verkrijger</strong>
            </td>
            <td className={classes.tableCellValue}>
              {state.props.deliveryList.project.customerName}
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>Straat</td>
            <td className={classes.tableCellValue}>
              {state.props.deliveryList.project.buildingStreet}{" "}
              {state.props.deliveryList.project.buildingHouseNumber}
            </td>
          </tr>
          <tr>
            <td className={classes.tableCell}>Postcode, plaats</td>
            <td className={classes.tableCellValue}>
              {state.props.deliveryList.project.buildingZipCode}{" "}
              {state.props.deliveryList.project.buildingCity}{" "}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function* mapStateToNode(state: DeliveryListState) {
    if (state instanceof DeliveryListLoadInProgressState) {
      yield <p key="dl-loading">Laden...</p>;
    }

    if (state instanceof DeliveryListLoadSuccessState) {
      yield (
        <div
          key="dl-details"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 3 }}>{mapStateToDetails(state)}</div>
          <div style={{ flex: 2 }}>
            <DeliveredAtComponent
              deliveredAt={state.props.deliveryList.isDeliveredAt}
              disabled={state.props.deliveryList.isFinal}
              bloc={bloc}
            />

            <KeyAmountComponent
              keyAmount={state.props.deliveryList.keyAmount}
              disabled={state.props.deliveryList.isFinal}
              bloc={bloc}
            />

            <HiredAgentComponent
              hiredAgent={state.props.deliveryList.hiredAgent}
              disabled={state.props.deliveryList.isFinal}
              bloc={bloc}
            />

            <EntitySelect
              disabled={state.props.deliveryList.isFinal}
              style={{ width: "100%", paddingRight: 8 }}
              key="dl-assigned-user"
              className={classes.selectField}
              title="Uitvoerende"
              type="users"
              labelSelector="name"
              searchFields={["name"]}
              entityId={state.props.deliveryList.assignedUserId}
              restService={component.rest}
              filters={[Ops.Field("roleId").IsNull(true)]}
              onSelect={(user) => {
                bloc.add(
                  new DeliveryListAssignedUserUpdateEvent({
                    assignedUser: user as User,
                  })
                );
              }}
            />
            <div style={{ paddingTop: 8 }}>
              {composeHouseImageEditor(state)}
            </div>
          </div>
        </div>
      );
    }

    if (state instanceof DeliveryListLoadFailureState) {
      yield (
        <Alert key="dl-errors" severity="error">
          {state.props.message}
        </Alert>
      );
    }
  }

  React.useEffect(() => {
    bloc.add(
      new DeliveryListLoadRequestEvent({ projectId: component.projectId })
    );
  }, []);

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state) => (
        <Container>
          <Paper className={classes.paper}>
            <Stepper activeStep={activeStep}>
              <Step key="step-details" onClick={() => setActiveStep(0)}>
                <StepLabel>Gegevens</StepLabel>
              </Step>
              <Step key="step-meters" onClick={() => setActiveStep(1)}>
                <StepLabel>Meterstanden</StepLabel>
              </Step>
              <Step key="step-points" onClick={() => setActiveStep(2)}>
                <StepLabel>Punten</StepLabel>
              </Step>
              <Step key="step-finish" onClick={() => setActiveStep(3)}>
                <StepLabel>Afronding</StepLabel>
              </Step>
            </Stepper>
          </Paper>

          {activeStep === 0 && (
            <Paper className={classes.paper}>
              <Box p={1}>{...Array.from(mapStateToNode(state))}</Box>
            </Paper>
          )}

          {activeStep === 1 &&
            state instanceof DeliveryListLoadSuccessState && (
              <MeterComponent
                disabled={state.props.deliveryList.isFinal}
                projectId={component.projectId}
                entities={component.entities}
              />
            )}

          {activeStep === 2 &&
            state instanceof DeliveryListLoadSuccessState && (
              <PointComponent
                disabled={state.props.deliveryList.isFinal}
                http={component.http}
                rest={component.rest}
                projectId={component.projectId}
                apollo={component.apollo}
              />
            )}

          {activeStep === 2 &&
            state instanceof DeliveryListLoadSuccessState && (
              <SignatureComponent
                disabled={state.props.deliveryList.isFinal}
                apollo={component.apollo}
                http={component.http}
                projectId={component.projectId}
                entities={component.entities}
              />
            )}

          {activeStep === 3 &&
            state instanceof DeliveryListLoadSuccessState && (
              <CompletionComponent
                coreBloc={bloc}
                disabled={state.props.deliveryList.isFinal}
                auth={component.auth}
                projectId={component.projectId}
                apollo={component.apollo}
                rest={component.rest}
                serviceTransmitter={component.serviceTransmitter}
                fileUploadService={component.fileUploads}
              />
            )}
        </Container>
      )}
    />
  );
};
