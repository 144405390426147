import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EntityManager } from "../entity.service";
import { ReactComponent } from "../react-component/react.component";
import { AppProjectFieldView } from "./react/AppProjectFieldView";

@Component({
  selector: "app-app-project-field-view",
  templateUrl: "./app-project-field-view.component.html",
  styleUrls: ["./app-project-field-view.component.scss"],
})
export class AppProjectFieldViewComponent extends ReactComponent {
  containerId = "react--app-project-field-view";
  component = AppProjectFieldView;

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  constructor(
    readonly route: ActivatedRoute,
    readonly entities: EntityManager
  ) {
    super();
  }

  async ngOnInit() {
    super.ngOnInit();
  }
}
