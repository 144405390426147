import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { EntityManager } from "../entity.service";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Main } from "./react/main";
import {
  ComplexTableColumn,
  ComplexTableDataGroup,
} from "../complex-table/complex-table.component";
import { WorkAction } from "../work-actions/work-action.entity";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-work-actions-chart-gantt",
  templateUrl: "./work-actions-chart-gantt.component.html",
  styleUrls: ["./work-actions-chart-gantt.component.scss"],
})
export class WorkActionsChartGanttComponent implements OnInit, OnChanges {
  readonly renderId = "work-actions-chart-gantt";

  @Input() data: ComplexTableDataGroup<{}>[] = [];
  @Input() columns: ComplexTableColumn<{}>[] = [];
  @Input() date: Date = new Date();
  @Output() addAdvancement = new EventEmitter<{ id: string }>();

  protected get workActionService() {
    return this.entityManager.get(WorkAction);
  }

  constructor(protected readonly entityManager: EntityManager) {}

  ngOnInit() {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  protected render() {
    const root = document.getElementById(this.renderId);

    if (root) {
      ReactDOM.render(
        React.createElement(Main, {
          component: this,
        }),
        root
      );
    }
  }

  public workActionAdvancement(id: string) {
    if (id) {
      this.addAdvancement.next({ id });
    }
  }
}
