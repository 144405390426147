import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EntityManager } from '../entity.service';
import { MdcDialogRef } from '@angular-mdc/web';
import { YearPlanningLine } from './year-planning-line.entity';
import { RestResponse } from '../rest.service';

@Component({
  selector: 'app-year-planning-lines-dialog',
  templateUrl: './year-planning-lines-dialog.component.html',
  styleUrls: ['./year-planning-lines-dialog.component.scss']
})
export class YearPlanningLinesDialogComponent implements OnInit, AfterViewInit {
  response: RestResponse<YearPlanningLine[]>;

  protected get yearPlanningLineService() {
    return this.entityManager.get(YearPlanningLine);
  }

  constructor(
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialogRef<YearPlanningLinesDialogComponent>) { }

  ngOnInit() {
    this.fetch();
  }

  ngAfterViewInit() {
    setTimeout(() => window.dispatchEvent(
      new Event('resize')), 300);
  }

  get canSave() {
    return this.response && !this.response.value
      .find(e => !e.description || !e.colorCode || !e.weeks);
  }

  add() {
    this.response.value.push(
      this.yearPlanningLineService.concept({ weeks: 0, colorCode: 'grey' })
    );
  }

  remove(line: YearPlanningLine) {
    line.isDeleted = true;
  }

  close() {
    this.dialog.close();
  }

  async save() {
    for (const item of this.response.value) {
      if (item.isDeleted) {
        await this.yearPlanningLineService.delete(item.id);
      } else {
        await this.yearPlanningLineService.save(item);
      }
    }

    this.dialog.close(true);
  }

  protected async fetch() {
    this.response = await this.yearPlanningLineService.query();
  }
}
