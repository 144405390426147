import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import {
  DateGroupCommentDialogComponent,
  DateGroupCommentDialogComponentData,
} from "../date-group-comment-dialog/date-group-comment-dialog.component";
import {
  DateStackDialogComponent,
  DateStackDialogComponentData,
} from "../date-stack-dialog/date-stack-dialog.component";
import { DialogService } from "../dialog.service";
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from "../react-component/react-attuned.component";
import {
  ConstructionPlanningDateDto,
  ConstructionPlanningProjectDateValueDto,
  ConstructionPlanningProjectDto,
} from "./construction-planning-date.dto";
import {
  ConstructionPlanningScreen,
  ConstructionPlanningScreenProps,
} from "./construction-planning.screen";
import {
  ConstructionPlanningCommentEditRequest,
  ConstructionPlanningDateEditRequest,
} from "./construction-planning.table";

@Component({
  template: reactAttunedComponentTemplate,
  styles: [".attuned--component { overflow: hidden; }"],
})
export class ConstructionPlanningAngular extends ReactAttunedComponent<ConstructionPlanningScreenProps> {
  component = ConstructionPlanningScreen;

  constructor(
    protected readonly injector: Injector,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector,
      onDateEditRequest: async (
        request: ConstructionPlanningDateEditRequest
      ) => {
        await this.dialogs.open(this.changeDetector, DateStackDialogComponent, {
          data: new DateStackDialogComponentData(request.project as any, {
            id: null,
            title: "Datum aanpassen",
            dates: [
              {
                key: null,
                value: request.dateValue as any,
                date: request.date as any,
              },
            ],
          }),
        });
      },
      onCommentEditRequest: async (
        request: ConstructionPlanningCommentEditRequest
      ) => {
        const dateGroupId = request.commentDateGroup
          ? request.commentDateGroup.id
          : null;

        await this.dialogs.open(
          this.changeDetector,
          DateGroupCommentDialogComponent,
          {
            data: new DateGroupCommentDialogComponentData(
              request.project as any,
              {
                group: request.commentDateGroup,
                comments: request.project.cachedValue.dateGroupComments.filter(
                  (comment) => comment.dateGroupId === dateGroupId
                ),
              } as any
            ),
          }
        );
      },
    };
  }
}
