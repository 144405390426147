import { makeStyles } from "@material-ui/core";

export const useWorkOrdersGridStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  toolbar: {
    padding: 12,
  },
  grid: {
    backgroundColor: "white",
    borderRadius: "0px",
    border: "none",
    fontSize: 12,
    flex: "1 1 auto",
  },
  gridCell: {
    // fontSize: "0.8em",
    // padding: 0,
    // borderLeft: "1px solid #eeeeee",
    border: "none",
    // textAlign: "center",
    // background: "rgb(241, 242, 242)",
    borderBottom: "1px solid #dddede",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
  },
  gridCellClickable: {
    "&:hover ": {
      opacity: 0.5,
      cursor: "pointer",
    },
    textDecoration: "underline",
  },
  whiteBackground: {
    background: "white!important",
  },
  gridCellCentered: {
    textAlign: "center",
  },
  gridCellValueFilled: {
    widht: "100%",
    height: "100%",
  },
  gridCellCompleted: {
    color: "grey",
    textDecoration: "line-through",
  },
  gridCellHighlighted: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  header: {
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
  },
  headerFilterable: {
    lineHeight: 2,
    "& span": {
      height: "auto!important",
    },
  },
  gridToolbar: {
    backgroundColor: "#eeeeee",
  },
  textFieldContainer: {
    padding: 8,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
  checkboxFieldContainer: {
    padding: 8,
    paddingLeft: 16,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
}));
