import { Component, Inject, OnInit } from "@angular/core";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { RestResponse } from "../rest.service";
import { AppProjectDate } from "../app-phases/app-project-date.entity";
import { AppPhaseSectionMark } from "../app-phases/app-phase.entity";

@Component({
  selector: "app-app-phase-section-mark-dialog",
  templateUrl: "./app-phase-section-mark-dialog.component.html",
  styleUrls: ["./app-phase-section-mark-dialog.component.scss"],
})
export class AppPhaseSectionMarkDialogComponent
  extends AppCrudDialogBoilerplate<AppPhaseSectionMark>
  implements OnInit {
  dateTypes: RestResponse<AppProjectDate[]>;

  get canSave() {
    return (
      !!this.data.model.name &&
      this.data.model.percentage !== null &&
      this.data.model.percentage !== undefined
    );
  }

  documentTypeFilter = {
    allowNothing: false,
    title: "Documentsoort",
    icon: "pageview",
    entityName: "app.document_types",
    nameField: "description",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name", "description"],
  };

  contentFilter = {
    allowNothing: true,
    title: "Informatie Pagina",
    icon: "pageview",
    entityName: "app.contents",
    nameField: "id",
    descriptionField: "title",
    sortField: "title",
    sortDirection: "ASC",
    filterFields: ["title", "html"],
  };

  notiticationFilter = {
    allowNothing: true,
    title: "Melding",
    icon: "notifications_none",
    entityName: "app.notification_templates",
    nameField: "id",
    descriptionField: "subject",
    sortField: "subject",
    sortDirection: "ASC",
    filterFields: ["subject", "body"],
  };

  fieldGroupFilter = {
    allowNothing: true,
    title: "Invoer velden",
    icon: "assignment",
    entityName: "app.project_field_groups",
    nameField: "id",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name"],
  };

  planCompetenceFilter = {
    allowNothing: true,
    title: "Activiteit datum",
    icon: "local_activity",
    entityName: "app.plan_competences",
    nameField: "number",
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["description"],
  };

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppPhaseSectionMark>,
    protected readonly dialog: MdcDialogRef<AppPhaseSectionMarkDialogComponent>
  ) {
    super(data, dialog);
  }

  async ngOnInit() {
    const repo = this.data.boilerplate.entityManager.get(AppProjectDate);
    this.dateTypes = await repo.queryRaw(
      "select distinct type from project_dates"
    );
  }
}
