import { Apollo } from "apollo-angular";
import { SupplierDocument } from "./supplier-document.entity";
import { get } from "lodash";
import { CellaService } from "../cella.service";
import { AuthService } from "../auth.service";
import gql from "graphql-tag";

const ProjectDateStates = gql`
  query ProjectDateStates($projectId: String, $recipientUserId: String) {
    project: ProjectEntityFindById(id: $projectId) {
      id
      description
    }
    recipientUser: UserEntityFindById(id: $recipientUserId) {
      email
    }
  }
`;

export class DocumentSupplierMailer {
  readonly SERVICE_MAIL = "service@groothuisbouw.nl";

  constructor(
    protected readonly apollo: Apollo,
    protected readonly cellaService: CellaService,
    protected readonly authService: AuthService
  ) {}

  async mailNewUploads(
    projectId: string,
    recipientUserId: string,
    documents: SupplierDocument[]
  ) {
    const query = await this.query(projectId, recipientUserId);

    return await this.cellaService.sendEmail({
      from: { address: "noreply@groothuisbouw.nl", name: "Groothuisbouw" },
      to: query("recipientUser.email"),
      subject: `Nieuwe documenten geupload in leveranciersdossier: ${query(
        "project.id"
      )} - ${query("project.description")}`,
      html: `
            Er zijn nieuwe document(en) toegevoegd aan het leveranciersdossier van project ${query(
              "project.id"
            )} - ${query("project.description")}<br /><br />
            Geupload door: ${this.authService.user.name}<br /><br />
            ${documents
              .map((e) => `&bull; ${e.__documentMeta__.description}`)
              .join("<br />")}
            <br /><br />
            <a href="${
              window.origin
            }/#/office/documents/${projectId}">Leveranciersdossier openen</a>
            `,
    });
  }

  protected async query(
    projectId: string,
    recipientUserId: string
  ): Promise<IQuery> {
    const response = await this.apollo
      .query<any>({
        variables: { projectId, recipientUserId },
        query: ProjectDateStates,
        fetchPolicy: "no-cache",
      })
      .toPromise();

    return (path: string) => get(response.data, path, null);
  }
}

type IQuery = <T>(path: string) => T;
