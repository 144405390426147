import { Injector, Type } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Apollo } from "apollo-angular";
import { MutationOptions, QueryOptions } from "apollo-client";
import { GraphQLError } from "graphql";
import { useEffect, useMemo, useState } from "react";
import { Entity, EntityConstuctorTyped } from "../entity.service";
import { EntityManager, EntityService } from "./../entity.service";
import { GrantService } from "./../grant.service";

export const useProvider = <T>(injector: Injector, type: Type<T>) => {
  return useMemo(() => injector.get(type), [injector]) as T;
};

export const useGrant = (
  grantService: GrantService,
  configId: string,
  comparator: string
) => {
  return useMemo(
    () => grantService.varIs(configId, comparator),
    [grantService, configId, comparator]
  );
};

export const useRepository = <T extends Entity>(
  entities: EntityManager,
  ctor: EntityConstuctorTyped<T>
) => {
  return useMemo(() => entities.get(ctor), [entities]) as EntityService<T>;
};

export const useRouteParam = (injector: Injector, name: string) => {
  const activatedRoute = useMemo(
    () => injector.get(ActivatedRoute),
    [injector]
  );

  return useMemo(
    () => activatedRoute.snapshot.paramMap.get(name),
    [activatedRoute]
  );
};

export interface ApolloDataState<T> {
  data: T;
  loading: boolean;
  errors: readonly GraphQLError[];
}

export type ApolloQueryResult<T> = ApolloDataState<T> & {
  refetch: (variables?: any) => Promise<T>;
};

export const useApolloQuery = <TData>(
  injector: Injector,
  options: QueryOptions<any>
) => {
  const apollo = useProvider(injector, Apollo);
  const [state, setState] = useState<{
    data: TData;
    loading: boolean;
    errors: readonly GraphQLError[];
  }>({
    data: undefined,
    loading: true,
    errors: undefined,
  });

  const refetch = async (variables?: any) => {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await apollo
      .query<TData>({
        ...options,
        ...(variables ? { variables } : {}),
      })
      .toPromise();

    setState((prev) => ({
      ...prev,
      data: response.data,
      errors: response.errors,
      loading: false,
    }));

    return response.data;
  };

  useEffect(() => {
    options && refetch();
  }, []);

  return {
    ...state,
    refetch,
  } as ApolloQueryResult<TData>;
};

export type ApolloMutationResult<T> = [
  (variables?: any) => Promise<T>,
  ApolloDataState<T>
];

export const useApolloMutation = <TData>(
  injector: Injector,
  options: MutationOptions<TData>
) => {
  const apollo = useProvider(injector, Apollo);
  const [state, setState] = useState<{
    data: TData;
    loading: boolean;
    errors: readonly GraphQLError[];
  }>({
    data: undefined,
    loading: false,
    errors: undefined,
  });

  const mutate = async (variables?: any) => {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await apollo
      .mutate<TData>({
        ...options,
        ...(variables ? { variables } : {}),
      })
      .toPromise();

    setState((prev) => ({
      ...prev,
      data: response.data,
      errors: response.errors,
      loading: false,
    }));

    return response.data;
  };

  return [mutate, state] as ApolloMutationResult<TData>;
};
