import { Component, Inject, OnInit } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { CargoPhase } from "../cargo/house-part.entity";
import * as moment from "moment";

@Component({
  selector: "app-cargo-phase-dialog",
  templateUrl: "./cargo-phase-dialog.component.html",
  styleUrls: ["./cargo-phase-dialog.component.scss"],
})
export class CargoPhaseDialogComponent implements OnInit {
  readonly dateFormatting = "YYYY-MM-DD";

  model: CargoPhase;

  get saveDisabled() {
    return !(this.model && this.model.description && this.model.date);
  }

  protected get cargoPhaseService() {
    return this.entities.get(CargoPhase);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: CargoPhaseDialogComponentProps,

    protected readonly dialog: MdcDialogRef<CargoPhaseDialogComponent>,
    protected readonly entities: EntityManager
  ) {}

  async ngOnInit() {
    this.model =
      this.data.original ??
      this.cargoPhaseService.concept({
        description: "",
        date: new Date(),
        projectId: this.data.projectId,
      });

    this.model.date = moment(this.model.date).format(
      this.dateFormatting
    ) as any;
  }

  async save() {
    const response = await this.cargoPhaseService.save(this.model);

    if (!response.hasError()) {
      this.dialog.close(response.value);
    }
  }
}

interface CargoPhaseDialogComponentProps {
  readonly projectId: string;
  readonly original?: CargoPhase;
}
