import { MetacomService } from "../metacom.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { CellaService } from "../cella.service";
import { RestResponse } from "../rest.service";
import { EmployeeDto, EmployeeDtoGroup } from "./employee.dto";
import { chain } from "lodash";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { DialogService } from "../dialog.service";
import {
  EmployeeDialogComponent,
  EmployeeDialogComponentData,
} from "../employee-dialog/employee-dialog.component";
import { AuthService } from "../auth.service";
import { EmployeeFileDialogComponent } from "../employee-file-dialog/employee-file-dialog.component";
import { EmployeeDocumentEntity } from "../employee-file-dialog/employee-document.entity";
import { EntityManager } from "../entity.service";
import { MdcSnackbar } from "@angular-mdc/web";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
})
export class EmployeesComponent implements OnInit {
  readonly STATE_ACTIVE = "1000";
  readonly STATE_ACRHIVED = "8000";
  readonly STATE_DELETED = "9000";

  relationId: string;
  relationFilterConfig = {
    allowNothing: true,
    title: "Relatie",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [{ field: "identity", operator: "IsNull", isNot: true }],
  };

  get allowFilter() {
    return this.grant.varIs(grants.employees.allow_filter, "true");
  }

  get showInternalDocuments() {
    return this.grant.varIs(grants.employees.show_internal_documents, "true");
  }

  protected get employeeDocumentService() {
    return this.entities.get(EmployeeDocumentEntity);
  }

  protected get routeRelationId() {
    return this.route.snapshot.paramMap.get("relationId");
  }

  response: RestResponse<EmployeeDtoGroup[]>;
  responseDocuments: RestResponse<EmployeeDocumentEntity[]>;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly auth: AuthService,
    protected readonly metacom: MetacomService,
    protected readonly cella: CellaService,
    protected readonly grant: GrantService,
    protected readonly changes: ChangeDetectorRef,
    protected readonly dialogs: DialogService,
    protected readonly entities: EntityManager,
    protected readonly snacks: MdcSnackbar
  ) {}

  async ngOnInit() {
    this.relationId = this.allowFilter
      ? this.routeRelationId
      : this.auth.user.id;

    !!this.relationId && (this.response = new RestResponse([]));
    this.relationId && (await this.fetch());
  }

  async edit(employee: EmployeeDto) {
    await this.dialogs.open(this.changes, EmployeeDialogComponent, {
      data: new EmployeeDialogComponentData(this.relationId, employee),
    });

    await this.fetch();
  }

  async delete(employee: EmployeeDto) {
    if (await this.dialogs.confirm(this.changes)) {
      const origin = `bdr:999¡prs:${employee.persoon}`;
      const response = await this.cella.setStage({
        Company: "999",
        Entity: "0004",
        Origin: origin,
        Stage: this.STATE_DELETED,
      });

      if (!response.hasError()) {
        this.snacks.open("Verwijderd", "Ok");

        await this.fetch();
      }
    }
  }

  async add() {
    await this.dialogs.open(this.changes, EmployeeDialogComponent, {
      data: new EmployeeDialogComponentData(
        this.relationId,
        new EmployeeDto({})
      ),
    });

    await this.fetch();
  }

  async openDocuments(employeeId?: string) {
    await this.dialogs.open(this.changes, EmployeeFileDialogComponent, {
      data: {
        relationId: this.relationId,
        employeeId,
        showInternal: this.showInternalDocuments,
      },
    });

    await this.fetch();
  }

  async fetch() {
    this.response = null;

    const response = await this.metacom.queryTableAsync({
      setName: "metacom",
      tableName: "personeel_derden",
      filter: `mtc_relatie.rel = '${this.relationId}'`,
    });

    await this.fetchDocuments();

    if (!response.hasError()) {
      this.response = new RestResponse(
        chain(response.value)
          .map((e) => new EmployeeDto(e))
          .tap((a) =>
            a.forEach(
              (e) =>
                (e.hasDocuments = this.hasDocuments(this.relationId, e.persoon))
            )
          )
          .groupBy((r) => r.stadium)
          .map((items, key) => new EmployeeDtoGroup(key, items))
          .forEach((e) => (e.hasDocuments = this.hasDocuments(this.relationId)))
          .orderBy((e) => e.state, "asc")
          .value()
      );
    }
  }

  async fetchDocuments() {
    this.responseDocuments = await this.employeeDocumentService.query({
      filters: [
        { field: "relationId", operator: "Equal", value: this.relationId },
      ],
    });
  }

  protected hasDocuments(relationId: string, employeeId: string = null) {
    return (
      this.responseDocuments &&
      !!this.responseDocuments.value.find(
        (e) => e.relationId === relationId && e.employeeId === employeeId
      )
    );
  }
}
