import { User } from "../accessibility-users/user.entity";
import { ProjectHousePart } from "../cargo/house-part.entity";
import { DrawOrder } from "../drawers/drawer.entity";
import { Entity } from "../entity.service";
import { Picklist, PicklistState } from "../picklist/picklist.entity";
import { TableLayoutComment } from "../table-layout/react/interfaces/table-layout-comment";
import { YearPlanningLine } from "../year-planning-lines-dialog/year-planning-line.entity";
import {
  CachedValue,
  DateGroupComment,
  DateGroupContainer,
  DateGroupResponsible,
  DateValue,
  LogicFieldValue,
} from "../year-planning/entities/date-entities";

export class Project extends Entity {
  type = "projects";

  projectLeaderId: string;
  projectMentorId: string;
  salesEmployeeId: string;
  userId: string;

  description: string;
  buildingWeek: string;
  region: string;
  regionId: string;

  buildingStreet: string;
  buildingHouseNumber: string;
  buildingCity: string;
  buildingZipCode: string;

  houseType: string;
  houseShape: string;

  foundation: string;
  foundationAbbreviation: string;

  qualityAssurance?: string;

  prefabBathroom?: string;
  isolationCutting?: string;

  heating: string;
  heatingAbbreviation: string;

  frameType?: string;
  frameDescriptionWood?: string;
  frameDescriptionPlastic?: string;

  latitude: string;
  longitude: string;
  deliveryDate: string;
  score?: number;
  concrete?: string;

  customerEmail: string;
  customerName: string;

  installationReportDate: string;
  installationPerformerName: string;

  isVisible?: boolean;
  isDateFetched?: boolean;

  get picklists() {
    return this.many(Project, Picklist, "picklists");
  }

  get picklistStates() {
    return this.many(Project, PicklistState, "picklistStates");
  }

  get projectHouseParts() {
    return this.many(Project, ProjectHousePart, "projectHouseParts");
  }

  get projectLeader() {
    return this.one(Project, User, "projectLeader");
  }

  __picklists__?: Picklist[];
  __picklistStates__?: PicklistState[];
  __drawOrders__?: DrawOrder[];

  __dateValues__?: DateValue[];
  __dateGroupComments__?: DateGroupComment[];
  __dateGroupResponsibles__?: DateGroupResponsible[];
  __tableLayoutComments__?: TableLayoutComment[];

  __logicFieldValues__?: LogicFieldValue[];

  __projectLeader__?: User;
  __projectMentor__?: User;
  __salesEmployee__?: User;
  __buyAdvisor__?: User;

  projectLeaderAcronym?: string;
  salesEmployeeAcronym?: string;

  marker?: any;
  markerColor?: string;
  markerIconUrl?: string;

  isNewWeek?: boolean;
  isActiveWeek?: boolean;
  yearPlanningLine?: YearPlanningLine;
  scoreCategory?: number;
  scoreMovingAverage?: number;
  productionHighlight?: boolean;

  dateGroupContainers?: DateGroupContainer[];

  cachedValue?: CachedValue;
}

export class LegacyProject extends Project {
  type = "legacy_projects";
}
