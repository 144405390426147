import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PageService {
  readonly titleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  readonly titleSegmentsSubject: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  setTitle(value: string) {
    if (this.titleSubject.value !== value) {
      this.titleSubject.next(value);
      this.titleSegmentsSubject.next([]);
    }
  }

  addSegment(value: string) {
    this.titleSegmentsSubject.next(
      this.titleSegmentsSubject.value.concat(value)
    );
  }

  clearSegments() {
    this.titleSegmentsSubject.next([]);
  }
}
