import * as React from "react";
import { Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { useMemo } from "react";
import {
  Grid,
  List,
  makeStyles,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { MailTrafficListItem } from "./components/MailTrafficListItem";
import { RestService } from "../rest.service";
import {
  useFetchMail,
  useFetchMailMessage,
  useReadMailImage,
} from "./mail-traffic.hooks";
import { useEffect } from "react";
import { MailTrafficMessageHeader } from "./components/MailTrafficMessageHeader";
import { MailTrafficSearch } from "./components/MailTrafficSearch";
import { MailTrafficMessageBody } from "./components/MailTrafficMessageBody";
import { first } from "lodash";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";

export interface MailTrafficComponentProps {
  injector: Injector;
}

const useStyles = makeStyles(() => ({
  gridContainer: { height: "100%" },
  sticky: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    borderBottom: "1px solid #eeeeee",
    zIndex: 10,
  },
  gridMessage: {
    position: "relative",
  },
  paper: { height: "100%", overflowY: "scroll" },
  paperMessage: {
    height: "100%",
    left: 0,
    right: 0,
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
  },
  loader: { margin: "auto", marginTop: 16, marginBottom: 16, display: "block" },
  list: { padding: 0, height: "1px" },
  messageHtml: { padding: 24 },
}));

export function MailTrafficComponent({ injector }: MailTrafficComponentProps) {
  const classes = useStyles();
  const activatedRoute = useMemo(
    () => injector.get(ActivatedRoute),
    [injector]
  );

  const restService = useMemo(() => injector.get(RestService), [injector]);
  const authService = useMemo(() => injector.get(AuthService), [injector]);
  const cellaService = useMemo(() => injector.get(CellaService), [injector]);

  const projectId = useMemo(
    () => activatedRoute.snapshot.paramMap.get("projectId"),
    [activatedRoute]
  );

  const { data, fuzzyText, loading, fetchMail } = useFetchMail(restService);

  const {
    data: dataMessage,
    loading: loadingMessage,
    fetchMailMessage,
  } = useFetchMailMessage(restService);

  useEffect(() => {
    fetchMail(projectId);
  }, [projectId]);

  useEffect(() => {
    const message = first(data);

    if (message) {
      fetchMailMessage(message.gid, message.mid);
    }
  }, [data]);

  return (
    <PortalThemeProvider>
      <Grid className={classes.gridContainer} container spacing={0}>
        <Grid item xs={3}>
          <Paper className={classes.paper} square elevation={1}>
            <div className={classes.sticky}>
              <MailTrafficSearch
                onSubmit={(fuzzyText: string) =>
                  fetchMail(projectId, fuzzyText)
                }
              />
            </div>
            <List className={classes.list}>
              {loading && <CircularProgress className={classes.loader} />}

              {data &&
                data.map((mail, index) => (
                  <MailTrafficListItem
                    key={`${mail.mid}-${index}`}
                    item={mail}
                    selected={dataMessage && dataMessage.mailId === mail.mid}
                    onClick={() => fetchMailMessage(mail.gid, mail.mid)}
                  />
                ))}
            </List>
          </Paper>
        </Grid>
        <Grid className={classes.gridMessage} item xs={9}>
          <Paper className={classes.paperMessage} square elevation={1}>
            {loadingMessage && <CircularProgress className={classes.loader} />}

            {dataMessage && (
              <MailTrafficMessageHeader
                message={dataMessage}
                authService={authService}
                cellaService={cellaService}
                restService={restService}
              />
            )}

            {dataMessage && (
              <MailTrafficMessageBody
                message={dataMessage}
                restService={restService}
                fuzzyText={fuzzyText}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </PortalThemeProvider>
  );
}
