import { Component, OnInit } from '@angular/core';
import { RestResponse } from '../rest.service';
import { CostCategory } from '../audit-sheet-table/cost-category.entity';
import { EntityManager } from '../entity.service';
import { ActivatedRoute } from '@angular/router';
import { AuditReport } from '../audit-sheet-report/audit-report.entities';
import { AuditChecklistImage } from '../audit-checklist-images/audit-checklist-image.entity';
import { GrantService } from '../grant.service';

@Component({
  selector: 'app-audit-sheet-phases',
  templateUrl: './audit-sheet-phases.component.html',
  styleUrls: ['./audit-sheet-phases.component.scss']
})
export class AuditSheetPhasesComponent implements OnInit {
  response: RestResponse<CostCategory[]>;

  protected get auditReportService() {
    return this.entityManager.get(AuditReport);
  }

  protected get costCategoryService() {
    return this.entityManager.get(CostCategory);
  }

  protected get auditChecklistImageService() {
    return this.entityManager.get(AuditChecklistImage);
  }

  get projectId() {
    return this.route.snapshot.paramMap.get('projectId');
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager) { }

  ngOnInit() {
    this.fetch();
  }

  bliss(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  access(route: string) {
    return this.grantService.isRouteAccessable(route);
  }

  protected async fetch() {
    const categories = await this.costCategoryService.query({
      filters: [
        { field: 'isChecklist', operator: 'Equal', value: 'ja', isOr: true },
        { field: 'hasImages', operator: 'Equal', value: 'ja', isOr: true }
      ],
      orders: [{ field: 'checklistOrder', direction: 'ASC' }],
    });

    const reports = await this.auditReportService.query({
      filters: [
        { field: 'projectId', operator: 'Equal', value: this.projectId },
      ]
    });

    const checklistImages = await this.auditChecklistImageService.query({
      filters: [
        { field: 'projectId', operator: 'Equal', value: this.projectId },
      ]
    });

    if (!reports.hasError() && reports.value) {
      categories.value.forEach(e => e.report = reports.value
        .find(d => d.costCategoryId === e.id));
    }

    if (!checklistImages.hasError() && checklistImages.value) {
      categories.value.forEach(e => e.checklistImages = checklistImages.value
        .filter(d => d.costCategoryId === e.id));
    }

    this.response = categories;
  }
}
