import {
  DeliveryListSignatureEntity,
} from "../../delivery-list.entity";
import SignatureCanvas from "react-signature-canvas";

export class SignatureEvent { }

export class SignatureLoadRequestEvent extends SignatureEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class SignatureStrokeEndEvent extends SignatureEvent {
  constructor(
    readonly props: {
      readonly canvas: SignatureCanvas;
      readonly signature: DeliveryListSignatureEntity;
    }
  ) {
    super();
  }
}
