import { MdcSnackbar, MdcTab } from "@angular-mdc/web";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import * as _ from "lodash";
import { chain, startCase } from "lodash";
import { environment } from "../../environments/environment";
import {
  AppDocumentUploadDialogComponent,
  AppDocumentUploadDialogComponentArgs,
} from "../app-document-upload-dialog/app-document-upload-dialog.component";
import { grants } from "../app-grant-config";
import { AppRestService } from "../app-rest.service";
import { AppService } from "../app.service";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { DialogService } from "../dialog.service";
import { EntityManager } from "../entity.service";
import { FileUploadData, FileUploadService } from "../file-upload.service";
import { GrantService } from "../grant.service";
import { MetacomService } from "../metacom.service";
import { RestResponse, RestService } from "../rest.service";
import {
  SupplierContactDialogComponent,
  SupplierContactDialogComponentResult,
} from "../suppier-contact-dialog/supplier-contact-dialog.component";
import { UrlOpenService } from "../url-open.service";
import { AppDocument, AppDocumentGroup } from "./app-document.entity";
import { DocumentSupplierMailer } from "./document.supplier.mailer";
import {
  SupplierDocument,
  SupplierDocumentGroup,
} from "./supplier-document.entity";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit {
  response: RestResponse<ListingItem[]>;
  responseApp: RestResponse<AppDocumentGroup[]>;
  responseLegacy: RestResponse<LegacyListingItem[]>;
  responseSupplier: RestResponse<SupplierDocumentGroup[]>;

  tabs: { id: string; label: string; icon: string }[];

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get segment() {
    return (this.route.snapshot.paramMap.get("segment") || "") + "$";
  }

  get canPrevious() {
    return !this.isRoot;
  }

  get isRoot() {
    return this.segment === "$";
  }

  get allowLegacy() {
    return this.grantService.varIs(grants.documents.show_legacy, "true");
  }

  get allowSupplier() {
    return this.grantService.varIs(grants.documents.supplier_documents, "true");
  }

  get allowApp() {
    return this.grantService.varIs(grants.documents.app_documents, "true");
  }

  get allowSupplierShowAll() {
    return this.grantService.varIs(
      grants.documents.supplier_documents_show_all,
      "true"
    );
  }

  get defaultRoot() {
    return "$" + this.grantService.var(grants.documents.default_root, "");
  }
  protected get supplierDocumentService() {
    return this.entityManager.get(SupplierDocument);
  }

  protected get appDocumentService() {
    return this.entityManager.get(AppDocument);
  }

  constructor(
    readonly app: AppService,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly grantService: GrantService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly metacomService: MetacomService,
    protected readonly httpClient: HttpClient,
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly fileUploadService: FileUploadService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly snackbar: MdcSnackbar,
    protected readonly cellaService: CellaService,
    protected readonly apollo: Apollo,
    protected readonly appRest: AppRestService
  ) {
    route.params.subscribe(() => this.fetch());

    this.tabs = [
      { id: "default", label: "Algemeen", icon: "label" },
      ...(this.allowSupplier
        ? [{ id: "supplier", label: "Leveranciersdossier", icon: "store" }]
        : []),
      ...(this.allowApp
        ? [{ id: "app", label: "App", icon: "settings_cell" }]
        : []),
      ...(this.allowLegacy
        ? [{ id: "others", label: "Overig", icon: "list_alt" }]
        : []),
    ];
  }

  activeTabId = "default";
  activeTabIndex = 0;

  thumbnail(document: AppDocument) {
    return `${environment.appApiBaseUrl}/api/documents/thumbnail/${document.id}`;
  }

  activateTab(event: { tab: MdcTab }) {
    this.activeTabId = event.tab.id;
  }

  setTab(id: string) {
    const index = Math.max(this.tabs.map((t) => t.id).indexOf(id), 0);
    const finalId = this.tabs[index]?.id;

    if (this.activeTabId !== finalId) {
      this.activeTabId = finalId;
      this.activeTabIndex = index;
    }
  }

  ngOnInit() {
    this.setTab(this.route.snapshot.queryParams["activeTabId"] ?? "default");

    if (!!this.defaultRoot && this.isRoot) {
      this.goToSegment(this.defaultRoot);
    }

    this.fetch();

    if (this.allowApp) {
      this.fetchApp();
    }

    if (this.allowLegacy) {
      this.fetchLegacy();
    }

    if (this.allowSupplier) {
      this.fetchSupplier();
    }
  }

  /**
   * App
   */
  openApp(item: AppDocument, isDownload?: boolean, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.urlOpenService.openNew({
      url: isDownload ? item.downloadUrl : item.viewUrl,
      forceNewTab: true,
    });
  }

  async deleteApp(item: AppDocument, event: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (await this.dialogService.confirm(this.changeDetector)) {
      const response = await this.appRest.request({
        url: `documents/${item.id}`,
        method: "DELETE",
      });

      if (!response.hasError()) {
        this.snackbar.open("Verwijderd", "Ok", { leading: true });
        this.fetchApp();
      }
    }
  }

  async openAppDetails(item: AppDocument) {
    this.urlOpenService.openNew({
      url: `#/app/document-details/${item.id}`,
      forceNewTab: true,
    });
  }

  async uploadApp() {
    const result: string = await this.dialogService.open(
      this.changeDetector,
      AppDocumentUploadDialogComponent,
      { data: new AppDocumentUploadDialogComponentArgs(this.projectId) }
    );

    await this.fetchApp();
  }

  /**
   * Suppliers
   */
  openSupplier(item: SupplierDocument, event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.urlOpenService.open(
      `${environment.baseUrl}/documents/open/${item.documentMetaId}`,
      true
    );
  }

  async deleteSupplier(item: SupplierDocument, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (await this.dialogService.confirm(this.changeDetector)) {
      if (!(await this.supplierDocumentService.delete(item.id)).hasError()) {
        this.fetchSupplier();
      }
    }
  }

  async uploadSupplier() {
    const documents = await this.promptForDocuments();
    const documentIds = await this.fileUploadService.upload(
      new FileUploadData(documents),
      this.projectId
    );

    const uploadResult: SupplierContactDialogComponentResult | string =
      await this.dialogService.open(
        this.changeDetector,
        SupplierContactDialogComponent,
        {
          data: { projectId: this.projectId },
        }
      );

    if (uploadResult !== "close") {
      const { contactTypes, userIds } =
        uploadResult as SupplierContactDialogComponentResult;

      const documentsToStore = documentIds.map((e) =>
        this.supplierDocumentService.concept({
          userId: this.authService.user.id,
          projectId: this.projectId,
          informedContactTypes: contactTypes,
          documentMetaId: e.id,
          __documentMeta__: e,
        })
      );

      await Promise.all(
        documentsToStore.map((e) => this.supplierDocumentService.save(e))
      );

      this.snackbar.open("Geupload", "Ok", { leading: true });

      await Promise.all(
        userIds.map((recipientUserId) =>
          this.informSupplier(recipientUserId, documentsToStore)
        )
      );

      await this.fetchSupplier();
    } else {
      this.snackbar.open("Upload afgebroken", "Ok", { leading: true });
    }
  }

  getInformedName(document: SupplierDocument) {
    const candidate = document.__informedUser__
      ? document.__informedUser__.name
      : (document.informedContactTypes ?? []).map(startCase).join(", ");

    return candidate.length > 0 ? candidate : "Niemand";
  }

  protected async promptForDocuments() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  protected async informSupplier(
    recipientUserId: string,
    documents: SupplierDocument[]
  ) {
    await new DocumentSupplierMailer(
      this.apollo,
      this.cellaService,
      this.authService
    ).mailNewUploads(this.projectId, recipientUserId, documents);
  }

  async previous() {
    const pieces = this.segment.split("$");
    pieces.pop();
    pieces.pop();
    await this.goToSegment(pieces.join("$"));
  }

  open(item: ListingItem, event: Event) {
    switch (item.type) {
      case "d":
        return this.goToSegment(item.linkSegment);
      case "-":
        return this.openFile(item, false, event);
    }
  }

  openLegacy(item: LegacyListingItem, download: boolean, event: Event) {
    return this.openFile(item, download, event);
  }

  zipFolder() {
    const link = this.makeLink("zip", this.segment, true);

    this.openLink(link, "bestanden.zip", true);
  }

  protected async fetch() {
    this.response = null;
    const response = await this.restService.get<ListingItem[]>(
      `volume/documents/${this.projectId}/${this.segment}`
    );

    if (!response.hasError()) {
      const items = _.orderBy(
        response.value.map((e) => this.addLink(e)),
        [(i) => i.type, (i) => +i.name.replace(/\D/g, ""), (i) => i.name],
        ["desc", "asc", "asc"]
      );

      this.response = new RestResponse(items);
    }
  }

  protected addLink(item: ListingItem) {
    item.linkSegment = `${this.segment}${item.name}`;

    return item;
  }

  protected makeLink(type: string, segment: string, isPublic?: boolean) {
    return `volume${isPublic ? "-public" : ""}/${type}/${
      this.projectId
    }/${segment}`;
  }

  protected async goToSegment(segment: string) {
    return await this.router.navigate(
      ["office", "documents", this.projectId, segment],

      { replaceUrl: false, queryParams: { activeTabId: this.activeTabId } }
    );
  }

  protected openFile(item: ILinkable, download: boolean, event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const isDownload =
      download ||
      !(
        item.mimeType === "application/pdf" ||
        (item.mimeType ? item.mimeType.startsWith("image/") : false)
      );

    const link = this.makeLink(item.linkBase || "file", item.linkSegment, true);

    this.openLink(link, item.name, isDownload);
  }

  protected openLink(link: string, fileName: string, isDownload: boolean) {
    this.urlOpenService.open(`${environment.baseUrl}/${link}`, true, fileName);
  }

  protected async fetchLegacy() {
    if (this.allowLegacy) {
      const response =
        await this.metacomService.queryTableAsync<LegacyListingItem>({
          setName: "metacom",
          tableName: "project_documenten",
          filter: `prj_prj.prj = "${this.projectId}" OR prj_prj.prj = "S${this.projectId}"`,
        });

      if (!response.hasError()) {
        this.responseLegacy = new RestResponse(
          response.value
            .filter((r) => !r.document.endsWith(".dir"))
            .map((e) => this.makeLinkable(e))
        );
      }
    }
  }

  protected async fetchSupplier() {
    const response = await this.supplierDocumentService.query({
      filters: [
        { field: "projectId", operator: "Equal", value: this.projectId },
      ],
      orders: [{ field: "createdAt", direction: "desc" }],
      relations: ["user", "documentMeta", "informedUser"],
    });

    if (!response.hasError()) {
      this.responseSupplier = new RestResponse(
        chain(response.value)
          .filter((e) =>
            this.allowSupplierShowAll
              ? true
              : e.userId == this.authService.user.id
          )
          .groupBy((e) => e.userId)
          .map((items, key) => new SupplierDocumentGroup(items))
          .value()
      );
    }
  }

  protected async fetchApp() {
    const response = await this.appDocumentService.query({
      filters: [
        { field: "project_id", operator: "Equal", value: this.projectId },
      ],
      orders: [{ field: "created_at", direction: "desc" }],
      relations: ["documentType", "signature"],
    });

    if (!response.hasError()) {
      this.responseApp = new RestResponse(
        chain(response.value)
          .groupBy((e) => e.document_type_id)
          .map((items, key) => new AppDocumentGroup(items))
          .value()
      );

      const scrollToDocumentTypeId = -1;
      const scrollToElementId = this.responseApp.value.find(
        (g) => g.type.id === scrollToDocumentTypeId
      )?.scrollId;

      setTimeout(
        () =>
          document
            .querySelector(`#${scrollToElementId}`)
            ?.scrollIntoView({ behavior: "smooth" }),
        500
      );
    }
  }

  protected makeLinkable(item: LegacyListingItem) {
    item.name = item.doc_oms;
    item.linkSegment = btoa(item.document);
    item.linkBase = "file-legacy";
    return item;
  }
}

interface ILinkable {
  name: string;
  mimeType: string;
  linkSegment: string;
  linkBase?: string;
}

class ListingItem implements ILinkable {
  type: string;
  name: string;
  date: Date;
  size: number;
  mimeType: string;

  linkSegment: string;
}

class LegacyListingItem implements ILinkable {
  doc_oms: string;
  document: string;

  name: string;
  mimeType: string;
  linkBase: string;
  linkSegment: string;
}
