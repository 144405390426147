import gql from "graphql-tag";

export const createPicklistCommentMutation = gql`
  mutation createPicklistCommentMutation($input: InputPicklistCommentEntity!) {
    comment: PicklistCommentEntityUpdateOne(input: $input) {
      id
    }
  }
`;

export const createPicklistElementStateMutation = gql`
  mutation createPicklistElementStateMutation(
    $input: InputPicklistElementStateEntity!
  ) {
    state: PicklistElementStateEntityUpdateOne(input: $input) {
      id
      elementId
      state
    }
  }
`;

export const createPicklistStateMutation = gql`
  mutation createPicklistStateMutation($input: InputPicklistStateEntity!) {
    state: PicklistStateEntityUpdateOne(input: $input) {
      id
      projectId
      picklistDefinitionId
      completedByUserId
    }
  }
`;
