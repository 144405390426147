import { Button, FormControl, TextField } from "@material-ui/core";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { TableLayoutContext } from "../context/table-layout.context";
import { TableLayoutComment } from "../interfaces/table-layout-comment";
import { TaskInterface } from "../interfaces/task.interface";
import { useFetchTaskComment, useStoreTaskComment } from "./task-comment.hooks";

export interface TaskCommentDialogProps {
  task: TaskInterface;
  onClosed: () => void | Promise<void>;
  onSaved: (comment: TableLayoutComment) => void | Promise<void>;
}

export function TaskCommentDialog({
  task,
  onClosed,
  onSaved,
}: TaskCommentDialogProps) {
  const { component } = useContext(TableLayoutContext);
  const commentService = useMemo(
    () => component.entities.get(TableLayoutComment),
    [component]
  );

  const auth = useMemo(() => component.auth, [component]);

  const {
    commentData,
    commentError,
    commentLoading,
    fetchComment,
  } = useFetchTaskComment(task, commentService);

  const [comment, setComment] = useState("");

  const {
    commentStoreSuccess,
    commentStoreError,
    commentStoreLoading,
    storeComment,
  } = useStoreTaskComment(commentService);

  const isLoading = commentLoading || commentStoreLoading;

  const store = async () => {
    commentData.comment = comment;
    commentData.userId = auth.user.id;

    await onSaved(commentData);
    await storeComment(commentData);
  };

  useEffect(() => {
    task && fetchComment();
  }, [task]);

  useEffect(() => {
    commentData && setComment(commentData.comment);
  }, [commentData]);

  useEffect(() => {
    Boolean(commentStoreSuccess) && onClosed();
  }, [commentStoreSuccess]);

  return (
    <Dialog open onClose={onClosed}>
      <DialogContent>
        <TextField
          disabled={isLoading}
          label="Memo"
          multiline
          rows={4}
          variant="outlined"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClosed}>
          Sluiten
        </Button>
        <Button disabled={isLoading} variant="outlined" onClick={store}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
