import * as React from "react";
import { TextField } from "@material-ui/core";
import { DeliveryListBloc } from "./delivery-list.bloc";
import { DeliveryListHiredAgentUpdateEvent } from "./delivery-list.event";

export interface HiredAgentComponentProps {
  bloc: DeliveryListBloc;
  hiredAgent: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const HiredAgentComponent: React.FC<HiredAgentComponentProps> = ({
  bloc,
  hiredAgent,
  disabled,
}) => {
  const [value, setValue] = React.useState<string>(`${hiredAgent || ""}`);

  return (
    <TextField
      style={{
        margin: 4,
        width: "100%",
        paddingRight: 8,
      }}
      disabled={disabled}
      variant="outlined"
      type="text"
      label={`Ingehuurde expert`}
      value={value}
      onBlur={() =>
        bloc.add(new DeliveryListHiredAgentUpdateEvent({ hiredAgent: value }))
      }
      onChange={(event) => setValue(event.target.value)}
    />
  );
};
