import { useState } from "react";
import { EntityService } from "src/app/entity.service";
import { TableLayoutComment } from "../interfaces/table-layout-comment";
import { TaskInterface } from "../interfaces/task.interface";

export const useFetchTaskComment = (
  task: TaskInterface,
  service: EntityService<TableLayoutComment>
) => {
  const [state, setState] = useState<{
    data: TableLayoutComment | undefined;
    error: any;
    loading: boolean;
  }>({
    data: undefined,
    error: undefined,
    loading: false,
  });

  return {
    commentData: state.data,
    commentLoading: state.loading,
    commentError: state.error,
    fetchComment: async () => {
      setState({ ...state, error: undefined, loading: true });

      const response = await service.queryFirst({
        filters: [
          { field: "projectId", operator: "Equal", value: task.projectId },
          { field: "taskId", operator: "Equal", value: task.wallId },
        ],
      });

      setState({
        loading: false,
        error: response.error,
        data:
          response.value ||
          service.concept({
            projectId: task.projectId,
            taskId: task.wallId,
          }),
      });
    },
  };
};

export const useStoreTaskComment = (
  service: EntityService<TableLayoutComment>
) => {
  const [state, setState] = useState<{
    success: boolean | undefined;
    error: any;
    loading: boolean;
  }>({
    success: undefined,
    error: undefined,
    loading: false,
  });

  return {
    commentStoreSuccess: state.success,
    commentStoreLoading: state.loading,
    commentStoreError: state.error,
    storeComment: async (comment: TableLayoutComment) => {
      setState({
        ...state,
        success: undefined,
        error: undefined,
        loading: true,
      });

      const response = await service.save(comment);

      setState({
        success: !response.hasError(),
        loading: false,
        error: response.error,
      });
    },
  };
};
