import { orderBy } from "lodash";
import { EntityManager } from "src/app/entity.service";
import { CrudBloc } from "../../bloc/crud/crud.bloc";
import { CrudEvent } from "../../bloc/crud/crud.event";
import { CrudInitialState } from "../../bloc/crud/crud.state";
import { SpaceEntity } from "./space.entity";

export class SpaceBloc extends CrudBloc<SpaceEntity> {
  constructor(protected readonly entities: EntityManager) {
    super(new CrudInitialState());
  }

  get orders() {
    return [{ field: "code", direction: "ASC" }];
  }

  get repo() {
    return this.entities.get(SpaceEntity);
  }

  async *mapEventToState(event: CrudEvent) {
    yield* super.mapEventToState(event);
  }
}
