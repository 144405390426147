import { Component, Inject, OnInit } from "@angular/core";
import { WorkAction } from "../work-actions/work-action.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { TransactionService } from "../transaction.service";
import { User } from "../accessibility-users/user.entity";
import { EntityAdvancement } from "../offers/entity.advancement.entity";
import { AuthService } from "../auth.service";
import { WorkActionCreator } from "../work-actions/work-action.creator";
import * as moment from "moment";

@Component({
  selector: "app-work-action-dialog",
  templateUrl: "./work-action-dialog.component.html",
  styleUrls: ["./work-action-dialog.component.scss"],
})
export class WorkActionDialogComponent implements OnInit {
  sendNotification = false;

  workActionCategoryConfig = {
    allowNothing: true,
    title: "Categorie",
    icon: "category",
    entityName: "work_action_categories",
    nameField: "description",
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  projectFilterConfig = {
    allowNothing: true,
    title: "Project / Afdeling*",
    icon: "archive",
    entityName: "projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  reporterFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Melder*",
    icon: "ring_volume",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [{ field: "identity", operator: "IsNull", isNot: true }],
  };

  relationFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Uitvoerende*",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "roleId", operator: "IsNull", isNot: true },
    ],
    relations: ["role", "role.grants"],
    filterAfter: (relation: User) => {
      return (
        relation.__role__ &&
        !!(relation.__role__.__grants__ || []).find(
          (e) => e.permissionId === "work_actions"
        )
      );
    },
  };

  protected get workActionService() {
    return this.entities.get(WorkAction);
  }

  protected get entityAdvancementService() {
    return this.entities.get(EntityAdvancement);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly model: WorkAction,
    protected readonly auth: AuthService,
    protected readonly entities: EntityManager,
    protected readonly transactions: TransactionService,
    protected readonly dialog: MdcDialogRef<WorkActionDialogComponent>
  ) {}

  ngOnInit() {
    if (!this.model.date) {
      this.model.date = moment(new Date()).format("YYYY-MM-DD");
      this.model.aimDate = moment(new Date())
        .add(1, "month")
        .format("YYYY-MM-DD");
    } else {
      this.model.date = moment(this.model.date).format("YYYY-MM-DD");
    }
  }

  get canSave() {
    return (
      this.model.title &&
      this.model.description &&
      this.model.projectId &&
      this.model.assignedUserId &&
      this.model.date &&
      (this.model.isConcept ? this.model.aimDate : true)
    );
  }

  async save() {
    const creator = new WorkActionCreator({
      userId: this.auth.user.id,
      transactions: this.transactions,
      entities: this.entities,
    });

    const response = await creator.save(this.model, this.sendNotification);

    if (response) {
      this.dialog.close(response);
    }
  }
}
