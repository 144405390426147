import gql from "graphql-tag";

export const completionQuery = gql`
  query completionQuery($projectId: String!) {
    categories: AppServiceCategoryEntityFindMany {
      id
      name
    }
    project: ProjectEntityFindOne(
      query: {
        filters: [{ field: "id", operator: "Equal", value: $projectId }]
      }
    ) {
      id
      regionId
    }
    points: DeliveryListPointEntityFindMany(
      query: {
        filters: [{ field: "projectId", operator: "Equal", value: $projectId }]
        orders: [{ field: "createdAt", direction: "ASC" }]
      }
    ) {
      id
      projectId

      project {
        description
      }

      code
      description

      assignedUserId

      serviceTicketId

      serviceTypeId
      serviceType {
        code
        description
        storeInSystem
      }

      serviceSpace {
        code
        description
      }
      serviceSpaceIds

      servicePriority {
        description
        offsetDays
      }

      assignedUser {
        name
      }

      primaryCategoryId
      secondaryCategoryId

      images {
        documentMetaId
      }
    }
    meters: DeliveryListMeterEntityFindMany(
      query: {
        filters: [{ field: "projectId", operator: "Equal", value: $projectId }]
      }
    ) {
      id
      value
      meter {
        orderId
        description
        unit
        createdAt
      }
    }
    signatures: DeliveryListSignatureEntityFindMany(
      query: {
        filters: [{ field: "projectId", operator: "Equal", value: $projectId }]
      }
    ) {
      id
      kind
      documentMetaId
    }
    spaces: ServiceSpaceEntityFindMany {
      id
      code
      description
    }
  }
`;
