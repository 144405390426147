import * as _ from "lodash";
import * as moment from "moment";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MetacomService } from "../metacom.service";
import { CellaService } from "../cella.service";
import { EntityManager } from "../entity.service";
import { Department } from "./department.entity";
import { RestResponse } from "../rest.service";
import { INgxMyDpOptions, IMyDateModel } from "ngx-mydatepicker";
import { MdcSnackbar } from "@angular-mdc/web";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { DialogService } from "../dialog.service";
import { TransactionService } from "../transaction.service";
import { OrderExpectDialogComponent } from "../order-expect-dialog/order-expect-dialog.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  lines: RestResponse<Order[]>;

  orderUserName: string;

  stock: RestResponse<OrderStock[]>;

  departments: RestResponse<Department[]>;
  departmentId = null;
  department = null;
  departmentFilterConfig = () => ({
    limit: 100,
    allowNothing: false,
    title: "Afdeling",
    icon: "group_work",
    entityName: "departments",
    nameField: "departmentId",
    descriptionField: "description",
    sortField: "departmentId",
    sortDirection: "ASC",
    filterFields: ["departmentId", "description"],
    filters: [
      { field: "state", operator: "Equal", value: "1000" },
      { field: "isOrderDepartment", operator: "Equal", value: "ja" },
      {
        field: "syncSource",
        operator: "Equal",
        value: this.settings.departmentSyncSource,
      },
    ],
  });

  get settings() {
    return this.route.snapshot.data.settings as OrdersComponentSettings;
  }

  protected get departmentService() {
    return this.entityManager.get(Department);
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly cellaService: CellaService,
    protected readonly entityManager: EntityManager,
    protected readonly metacomService: MetacomService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly grantService: GrantService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialogService: DialogService,
    protected readonly transactionService: TransactionService
  ) {}

  ngOnInit() {
    this.departmentId = this.grantService.var<string>(
      grants.orders.department_id,
      null
    );

    if (this.departmentId) {
      this.fetch();
    } else {
      this.lines = new RestResponse<Order[]>([]);
    }
  }

  get canSave() {
    return (
      this.orderUserName &&
      this.departmentId &&
      this.lines &&
      this.lines.value.find((l) => l.quantity && l.date)
    );
  }

  async openExpectance(order: Order) {
    await this.dialogService.open(
      this.changeDetector,
      OrderExpectDialogComponent,
      {
        data: {
          elementId: order.leverancier_artikel,
          elementName: order.middel_naam,
          tableName: this.settings.deliveriesTableName,
        },
      }
    );
  }

  async save() {
    const format = "YYYY-MM-DD";

    const response = await this.transactionService.perform(() =>
      this.cellaService.createTransactionDocument({
        Company: this.settings.companyId.toString(),
        DocumentType: "30-50",
        DocumentDate: moment().format(format),
        DocumentDescription: `Bestellijst ${this.departmentId} - ${this.orderUserName}`,
        DocumentLines: this.lines.value
          .filter((l) => l.quantity && l.date)
          .map((l) => ({
            FromCompany: this.settings.companyId,
            ToCompany: this.settings.companyId,
            ToAdministrationType:
              l.afdeling.charAt(0).toUpperCase() === "M" ? "MAT" : "PRJ",
            StartDate: moment(l.date.jsdate).format(format),
            StartTime: "000000",
            EndDate: moment().format(format),
            EndTime: "000000",
            SourceCompany: this.settings.companyId,
            ToCostCentre: l.afdeling,
            ToCostUnit: l.kostensoort,
            FromCostCentre: l.leverancier,
            FromCostUnit: l.leverancier_artikel,
            FromQuantity: l.quantity.toFixed(2),
            UserDefinedField: l.eenheid,
            Description: l.middel_naam,
            FromUnit: l.eenheid_middel,
          })),
      })
    );

    if (!response.hasError()) {
      this.lines.value.forEach((l) => (l.quantity = null));
      this.lines.value.forEach((l) => (l.date = null));
    }
  }

  async entitySelected() {
    if(this.department) {
      this.departmentId = this.department.departmentId
      await this.fetch()
    }
  }

  async fetch(forceStock = false) {
    this.lines = null;

    this.departments =
      this.departments || (await this.departmentService.query({}));
    this.stock =
      (forceStock ? null : this.stock) ||
      (await this.metacomService.queryTableAsync<OrderStock>({
        setName: "metacom",
        tableName: this.settings.stockTableName,
      }));

    const lines = await this.metacomService.queryTableAsync<Order>({
      setName: "metacom",
      tableName: this.settings.orderTableName,
      filter: `mtc_rublog.rubcode = '${this.settings.departmentPrefix}-${this.departmentId}'`,
    });

    if (!lines.hasError()) {
      lines.value.forEach(
        (e) =>
          (e.stock = this.stock.value.find(
            (s) => s.leverancier === e.leverancier && s.middel === e.middel
          ))
      );

      lines.value.forEach((e) => (e.dateConfig = this.makeConfig(e)));

      this.lines = lines;
    }
  }

  protected makeConfig(order: Order) {
    const apex = this.nextOrderDate(order.levertijd);
    const today = moment();

    return {
      dateFormat: "dd-mm-yyyy",
      alignSelectorRight: true,
      disableUntil: this.transformDate(today),
      markDates: [
        {
          dates: [this.transformDate(apex)],
          color: "#ffa500",
        },
      ],
      highlightDates: Array.from(this.getDatesBetween(today, apex)),
    };
  }

  protected transformDate(date: moment.Moment) {
    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  protected *getDatesBetween(from: moment.Moment, to: moment.Moment) {
    const diff = Math.abs(from.startOf("day").diff(to.startOf("day"), "d"));

    for (let i = 1; i <= diff; i++) {
      const calculated = from.clone().add(i, "d");

      yield this.transformDate(calculated);
    }
  }

  protected nextOrderDate(input: number) {
    let pointer = input - 1,
      date = moment();

    while (pointer > 0) {
      date = date.add(1, "d");

      if ([0, 6].indexOf(date.day()) === -1) {
        pointer--;
      }
    }

    return date;
  }
}

interface Order {
  middel: string;
  middel_naam: string;
  eenheid: string;
  eenheid_middel: string;
  leverancier: string;
  leverancier_naam: string;
  leverancier_artikel: string;
  bestel_hv: number;
  bestel_eenheid: string;
  levertijd: number;
  afdeling: string;
  kostensoort: string;
  regel_memo: string;

  date?: IMyDateModel;
  quantity?: number;

  stock?: OrderStock;
  dateConfig?: INgxMyDpOptions;
}

interface OrderStock {
  middel: string;
  leverancier: string;

  in_bestelling?: number;
}

export interface OrdersComponentSettings {
  companyId: number;
  departmentSyncSource: string;
  deliveriesTableName: string;
  departmentPrefix: string;
  orderTableName: string;
  stockTableName: string;
}
