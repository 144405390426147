import { Entity } from "../entity.service";

export class UserNotification extends Entity {
  type = "user_notifications";

  userId: string;
  subject: string;
  content: string;
  channels: string[];
  url: string;

  readAt?: Date;
  isRead: boolean;

  createdAt?: Date;
  updatedAt?: Date;
}
