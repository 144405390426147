import * as React from "react";
import { TableCell } from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";

interface TaskDroppableComponentProps {
  context: TaskDroppableComponentContext;
}

export interface TaskDroppableComponentContext {
  id?: string;
  dateId?: string;
  moldId?: string;
}

export const TaskDroppableComponent: React.FC<TaskDroppableComponentProps> = ({
  context,
  children,
}) => {
  const id = JSON.stringify(context);

  return (
    <Droppable droppableId={id} direction="vertical">
      {(provided) => (
        <TableCell
          style={{ padding: 0, verticalAlign: "top" }}
          key={id}
          className="drop-zone"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </TableCell>
      )}
    </Droppable>
  );
};
