import { HourDeclaration } from "./hour-declaration.entity";
import { HourDeclarationHelper } from "./hour-declaration.helper";
import { HourDeclarationParams } from "./hour-declaration.params";
import * as moment from "moment";
import * as _ from "lodash";

export class HourDeclarationConverter {
  readonly TIME_DEFAULT = "000000";

  readonly DATE_FORMAT = "YYYY-MM-DD";

  readonly INSERT_ADMIN_TYPE = "PRJ";
  readonly COMMIT_ADMIN_TYPE = "CRE";

  protected readonly helper: HourDeclarationHelper;

  constructor(
    protected readonly declaration: HourDeclaration,
    protected readonly params: HourDeclarationParams
  ) {
    this.helper = new HourDeclarationHelper(declaration);
  }

  *makeInsertLines() {
    if (!!this.declaration.baseHours) {
      yield this.hoursFor(
        ["baseHours", "savingHours"],
        this.declaration.projectId === "9090" ? "909" : "100"
      );
    }

    if (!!this.declaration.overTimeHours) {
      yield this.hoursFor(["overTimeHours"], "110");
    }

    if (!!this.declaration.savingHours) {
      yield this.hoursFor(["savingHours"], "909", "9090", true, false);
    }

    if (!!this.declaration.travelHours) {
      yield this.hoursFor(["travelHours"], "190");
    }
  }

  makeCommitLine() {
    return {
      FromCompany: parseFloat(this.params.companyId),
      FromAdministrationType: this.COMMIT_ADMIN_TYPE,
      ToCompany: parseFloat(this.companyId),
      ToAdministrationType: this.INSERT_ADMIN_TYPE,
      StartDate: this.date,
      StartTime: this.TIME_DEFAULT,
      EndDate: this.date,
      EndTime: this.TIME_DEFAULT,
      SourceCompany: parseFloat(this.params.companyId),
      SourceDocumentType: this.params.documentInsertType,
      SourceDocumentNumber: this.params.documentCommitNumber,

      ToItem: this.activityId,

      ToCostCentre: this.declaration.projectId,
      ToCostUnit: this.costId,

      FromCostCentre: this.params.user.id,
      FromCostUnit: this.declaration.priceAgreementId,

      FromQuantity: (this.helper.isPricePerHour
        ? this.helper.getTime("amount")
        : this.declaration.amount
      ).toString(),
      FromUnit: this.declaration.__priceAgreement__.prijs_eenheid,
      FromUnitPrice: this.declaration.__priceAgreement__.prijs_inkoop,
      Description: this.declaration.description,
    };
  }

  protected hoursFor(
    names: string[],
    quantityCode: string,
    projectId: string = null,
    isNegative = false,
    withActivity = true
  ) {
    const hours = _.sumBy(names, (name) => this.helper.getTime(name));

    return {
      FromCompany: parseFloat(this.params.companyId),
      ToCompany: parseFloat(this.companyId),
      ToAdministrationType: this.INSERT_ADMIN_TYPE,
      StartDate: this.date,
      StartTime: this.TIME_DEFAULT,
      EndDate: this.date,
      EndTime: this.TIME_DEFAULT,
      SourceCompany: parseFloat(this.params.companyId),

      ToCostCentre: projectId || this.declaration.projectId,
      ToCostUnit: this.costId,

      ToItem: withActivity ? this.activityId : null,

      FromCostCentre: this.params.isExternal
        ? this.params.user.id
        : this.params.companyId,
      FromCostUnit: this.params.isExternal
        ? this.declaration.priceAgreementId
        : this.params.userId,

      FromQuantity: (isNegative ? -hours : hours).toString(),
      ToQuantityCode: quantityCode,
      Description: this.declaration.description,
      FreeCode: "",
    };
  }

  protected get costId() {
    return this.declaration.__priceAgreement__
      ? this.declaration.__priceAgreement__.kostensoort
      : this.params.defaultCostId;
  }

  protected get activityId() {
    return this.helper.showActivity &&
      this.declaration.__hourDeclarationActivity__
      ? this.declaration.__hourDeclarationActivity__.administrationType
      : "";
  }

  protected get date() {
    return moment(this.declaration.date).format(this.DATE_FORMAT);
  }

  protected get companyId() {
    return (
      (this.declaration.__hourDeclarationType__
        ? this.declaration.__hourDeclarationType__.companyId
        : null) || this.params.companyId
    );
  }
}
