import * as React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { ProjectHousePart } from "src/app/cargo/house-part.entity";
import { TableLayoutComponent } from "../../table-layout.component";
import { TaskDroppableComponentContext } from "../components/task-droppable.component";
import { TaskInterface } from "../interfaces/task.interface";

export interface IUndo {
  undo: (task: TaskInterface) => Promise<any>;
}

export interface ITableLayoutContext {
  component?: TableLayoutComponent;

  tasks?: TaskInterface[];
  setTasks?: (value: TaskInterface[] | undefined) => void;

  openTasks?: TaskInterface[];
  setOpenTasks?: (value: TaskInterface[] | undefined) => void;

  projectsVisible?: boolean;
  setProjectsVisible?: (value: boolean) => void;

  backdropOpen?: boolean;
  setBackdropOpen?: (value: boolean) => void;

  undo?: (task: TaskInterface) => Promise<void>;
}

export const TableLayoutContext = React.createContext<ITableLayoutContext>({
  projectsVisible: true,
  backdropOpen: false,
});

interface TableLayoutProviderProps {
  component?: TableLayoutComponent;
}

export const TableLayoutProvider: React.FC<TableLayoutProviderProps> = ({
  component,
  children,
}) => {
  const [tasks, setTasks] = React.useState<TaskInterface[]>();
  const [openTasks, setOpenTasks] = React.useState<TaskInterface[]>();
  const [projectsVisible, setProjectsVisible] = React.useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const setData = async (
    task: TaskInterface,
    dateId: string,
    moldId: string
  ) => {
    task.isUpdating = true;

    const repo = component.entities.get(ProjectHousePart);
    const target = await repo.findOne(task.id);

    if (!target.hasError()) {
      target.value.dateId = dateId;
      target.value.moldId = moldId;

      await repo.save(target.value);

      if (!!task.subTasks) {
        await Promise.all(
          task.subTasks
            .filter((t) => t.id !== task.id)
            .map((t) => setData(t, dateId, moldId))
        );
      }

      return target.value;
    }
  };

  const storeTask = async (task: TaskInterface) => {
    setBackdropOpen(true);

    const isNewlyAdded = !!openTasks.find((t) => t.id === task.id);

    task.isUpdating = isNewlyAdded;

    const item = await setData(task, task.dateId, task.moldId);

    if (item) {
      if (isNewlyAdded) {
        setOpenTasks(openTasks.filter((t) => t.id !== item.id));
      }

      if (!task.dateId && !task.moldId) {
        setOpenTasks([...openTasks, task]);
      }

      task.isUpdating = false;

      setTasks([...tasks, ...(isNewlyAdded ? [task] : [])]);
    }

    setBackdropOpen(false);
  };

  const onDragEnd = async (result: any) => {
    if (result.destination) {
      const dragged = tasks
        .concat(openTasks)
        .find((t) => t.id === result.draggableId);

      if (dragged) {
        const context = JSON.parse(
          result.destination.droppableId
        ) as TaskDroppableComponentContext;

        dragged.isUpdating = true;

        dragged.moldId = context.moldId;
        dragged.dateId = context.dateId;

        await storeTask(dragged);
      }
    }
  };

  const undo = async (task: TaskInterface) => {
    task.isUpdating = true;
    task.dateId = null;
    task.moldId = null;

    await storeTask(task);
  };

  return (
    <TableLayoutContext.Provider
      value={{
        component,
        tasks,
        setTasks,
        openTasks,
        setOpenTasks,
        projectsVisible,
        setProjectsVisible,
        backdropOpen,
        setBackdropOpen,
        undo,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd} enableDefaultSensors={true}>
        {children}
      </DragDropContext>
    </TableLayoutContext.Provider>
  );
};
