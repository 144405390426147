import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { AppPhase } from "../app-phases/app-phase.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { AppProjectDate } from "../app-phases/app-project-date.entity";
import { RestResponse } from "../rest.service";
import { AppPhaseContact } from "../app-phases/app-phase-contact.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import {
  AppCrudBoilerplateInstance,
  AppCrudBoilerplateInstanceData,
} from "../boilerplates/app-crud.boilerplate";
import { DialogService } from "../dialog.service";
import { AppProjectContact } from "../app-document-type-dialog/app-project-contact.entity";
import { TransactionPromise } from "../transaction.service";

@Component({
  selector: "app-app-phase-dialog",
  templateUrl: "./app-phase-dialog.component.html",
  styleUrls: ["./app-phase-dialog.component.scss"],
})
export class AppPhaseDialogComponent
  extends AppCrudDialogBoilerplate<AppPhase>
  implements OnInit
{
  dateTypes: RestResponse<AppProjectDate[]>;
  contactTypes: RestResponse<AppProjectContact[]>;

  phaseContactCrud: AppCrudBoilerplateInstance<AppPhaseContact>;

  get canSave() {
    return (
      !!this.data.model.name &&
      this.data.model.percentage !== null &&
      this.data.model.percentage !== undefined &&
      (this.data.model.contacts || []).every(
        (contact) => contact.title && contact.type
      )
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppPhase>,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialog: MdcDialogRef<AppPhaseDialogComponent>
  ) {
    super(data, dialog);

    this.phaseContactCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppPhaseContact,
        disableFetch: true,
      }),
      this.data.boilerplate.transactions,
      this.data.boilerplate.entityManager,
      this.dialogs,
      this.changeDetector
    );
  }

  async save(closeDialog = true) {
    const modelResponse = await super.save(false);

    const contacts = this.data.model.contacts || [];
    const entityService = this.phaseContactCrud.entityService;

    contacts.forEach((e) => (e.phase_id = this.data.model.id));

    const toSave = contacts.filter((e) => e.isDirty && !e.isDeleted);
    const toSaveOps = (() =>
      entityService.saveMany(toSave)) as TransactionPromise;

    const toDelete = contacts.filter((e) => !!e.id && e.isDeleted);
    const toDeleteOps = toDelete.map(
      (e) => () => entityService.delete(e)
    ) as TransactionPromise[];

    const response = await this.data.boilerplate.transactions.performMany(
      toDeleteOps.concat(toSaveOps)
    );

    if (!response || !response.hasError()) {
      this.close(modelResponse);

      return modelResponse;
    }
  }

  addContact() {
    this.data.model.contacts = this.data.model.contacts || [];
    this.data.model.contacts.push(
      this.phaseContactCrud.entityService.concept({})
    );
  }

  async ngOnInit() {
    const repo = this.data.boilerplate.entityManager.get(AppProjectDate);

    this.dateTypes = await repo.queryRaw(
      "select distinct type from project_dates"
    );

    const repoTypes =
      this.data.boilerplate.entityManager.get(AppProjectContact);

    this.contactTypes = await repoTypes.queryRaw(
      "select distinct type from project_contacts"
    );
  }
}
