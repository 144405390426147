import { Entity } from "src/app/entity.service";

export class PresetEntity extends Entity {
  type = "service_presets";

  primaryCategoryId: number;
  secondaryCategoryId: number;

  code: string;
  description: string;

  createdAt: Date;
}
