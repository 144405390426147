import * as moment from "moment";
import * as lodash from "lodash";
import { LogicField } from "./entities/date-entities";
import { orderBy } from "lodash";

class YearPlanningLogicExpression {
  protected compiled: (field: string, moment, lodash) => boolean;

  constructor(protected readonly expression: string) {
    this.compiled = <any>this.compile();
  }

  matches(field: string): boolean {
    return this.compiled(field, moment, lodash);
  }

  protected compile() {
    return Function(
      `"use strict"; return (function(field, moment, lodash) { return (${this.expression}); })`
    )();
  }
}
export class YearPlanningLogicFieldVisitor {
  constructor(protected readonly ops: LogicField[]) {}

  active(fieldValue: string) {
    return orderBy(this.ops, d => d.orderId, "desc").find(e =>
      new YearPlanningLogicExpression(e.expression).matches(fieldValue)
    );
  }
}
