import * as React from "react";
import { Injector } from "@angular/core";
import { useMemo, useEffect, useState } from "react";
import { MetacomService } from "../metacom.service";
import {
  Checkbox,
  List,
  Button,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Grid,
  InputLabel,
  Select,
  FormControl,
  Fab,
} from "@material-ui/core";
import { usePicklistGridStyles } from "./hooks";
import { Apollo } from "apollo-angular";
import { Skeleton } from "@material-ui/lab";
import {
  useElementFilterOptions,
  useFetchPicklistDetails,
  useFetchPicklistElementStates,
  useFetchPicklistLines,
  useFilterElementsByPickState,
} from "./picklist-component.hooks";
import { PicklistCommentDialog } from "./components/PicklistCommentDialog";
import { Comment as CommentIcon, Done as DoneIcon } from "@material-ui/icons";
import { AuthService } from "../auth.service";
import {
  PicklistElementStateMode,
  PicklistElementStateModeFilter,
} from "./dtos/picklist-element-state.dto";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";
import * as classNames from "classnames";
import DataGrid, {
  Column,
  SortableHeaderCell,
  SortColumn,
} from "react-data-grid";
import { PicklistElementDto } from "./dtos/picklist-element.dto";
import { ClassValue } from "classnames/types";

export interface PicklistComponentProps {
  projectId: string;
  documentId?: string;
  picklistDefinitionId: string;
  injector: Injector;
}

export const PicklistComponent = ({
  injector,
  picklistDefinitionId,
  projectId,
  documentId,
}: PicklistComponentProps) => {
  const tableStyles = usePicklistGridStyles();

  const metacom = useMemo(() => injector.get(MetacomService), [injector]);
  const apollo = useMemo(() => injector.get(Apollo), [injector]);
  const auth = useMemo(() => injector.get(AuthService), [injector]);

  const {
    definition,
    project,
    note,
    completedState,
    fetchPicklistDetails,
    setCompleted,
  } = useFetchPicklistDetails(metacom, apollo, projectId, picklistDefinitionId);

  const { lines, fetchPicklistLines } = useFetchPicklistLines(
    metacom,
    projectId,
    documentId
  );

  const {
    fetchPicklistElementStates,
    stateOfElement,
    toggleElementState,
    isReadyForCompletion,
  } = useFetchPicklistElementStates(apollo, projectId, picklistDefinitionId);

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const { elementsFiltered, filter, setFilter } = useFilterElementsByPickState(
    lines,
    stateOfElement
  );

  const elementsSorted = useMemo((): readonly PicklistElementDto[] => {
    if (sortColumns.length === 0) return elementsFiltered;

    const sortedRows = [...elementsFiltered];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const compResult = a[sort.columnKey] < b[sort.columnKey] ? -1 : 1;

        return sort.direction === "ASC" ? compResult : -compResult;
      }
      return 0;
    });
    return sortedRows;
  }, [elementsFiltered, sortColumns]);

  const { options: materialOptions } = useElementFilterOptions(lines, "middel");

  const [commentOpen, setCommentOpen] = useState(false);

  const cellPickedConditions = (extraClasses: ClassValue[] = []) => ({
    cellClass: (row: PicklistElementDto) =>
      classNames([
        tableStyles.gridCell,
        ...(stateOfElement(row.id) === PicklistElementStateMode.Picked
          ? [tableStyles.gridCellCompleted]
          : []),
        ...(stateOfElement(row.id) === PicklistElementStateMode.Indeterminate
          ? [tableStyles.gridCellIndeterminate]
          : []),
        ...(row.isHeader ? [tableStyles.header] : []),
        ...extraClasses,
      ]),
  });

  const toNumberFormat = (input: any) => {
    const candidate = parseFloat(input as string);

    return isNaN(candidate)
      ? input
      : candidate.toLocaleString("nl-NL", {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2,
        });
  };

  useEffect(() => {
    definition &&
      fetchPicklistLines(definition.metacomLinesTable, definition.elementTypes);
  }, [definition]);

  useEffect(() => {
    fetchPicklistDetails();
    fetchPicklistElementStates();
  }, []);

  const hideAmount = definition && definition.hideColumns.includes("aantal");
  const hideDimensions =
    definition && definition.hideColumns.includes("afmeting");

  const nrOfColumns = 7 - (hideAmount ? 1 : 0) - (hideDimensions ? 1 : 0);

  return (
    <PortalThemeProvider>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexFlow: "column",
          height: "100%",
        }}
      >
        <Paper>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <List dense disablePadding>
                <ListItem>
                  <Button
                    style={{ marginTop: 12 }}
                    variant="outlined"
                    color="primary"
                    onClick={() => window.history.back()}
                  >
                    Terug naar het overzicht
                  </Button>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    {definition ? (
                      <Avatar>
                        <span className="material-icons">
                          {definition.icon}
                        </span>
                      </Avatar>
                    ) : (
                      <Skeleton
                        animation="wave"
                        variant="circle"
                        width={40}
                        height={40}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      project && definition ? (
                        `${project.id} - ${
                          project.description
                        } - ${definition.name.toUpperCase()}`
                      ) : (
                        <Skeleton animation="wave" height={20} width="80%" />
                      )
                    }
                    secondary={
                      note == null ? (
                        <Skeleton animation="wave" height={20} width="40%" />
                      ) : (
                        note
                      )
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                spacing={1}
                style={{ padding: 12 }}
              >
                <Grid item xs={6}>
                  {materialOptions ? (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="material-filter-label">
                        {definition.renameFilterName ?? "Materiaal"}
                      </InputLabel>
                      <Select
                        native
                        label={definition.renameFilterName ?? "Materiaal"}
                        inputProps={{
                          id: "material-filter-label",
                        }}
                        value={filter.fieldFilter["middel"]}
                        onChange={(event) =>
                          setFilter((prev) => ({
                            ...prev,
                            fieldFilter: {
                              ...prev.fieldFilter,
                              middel: event.target.value as string,
                            },
                          }))
                        }
                      >
                        <option value={"filter.all"}>Alles</option>
                        {materialOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Skeleton
                      variant="rect"
                      height={56}
                      width="100%"
                      animation="wave"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="state-filter-label">
                      Weergave
                    </InputLabel>
                    <Select
                      native
                      label="Weergave"
                      inputProps={{
                        id: "state-filter-label",
                      }}
                      value={filter.stateFilter}
                      onChange={(event) =>
                        setFilter((prev) => ({
                          ...prev,
                          stateFilter: event.target
                            .value as PicklistElementStateModeFilter,
                        }))
                      }
                    >
                      <option value={"all"}>Alle elementen</option>
                      <option value={"idle,indeterminate"}>
                        Niet gepickte elementen
                      </option>
                      <option value={"picked"}>Gepickte elementen</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          style={{
            flex: "1 1 auto",
            backgroundColor: "white",
          }}
        >
          <DataGrid
            rowHeight={({ row }) =>
              (row as PicklistElementDto).isHeader
                ? 32
                : ((row["omschrijving"] && 16) || 0) + 52
            }
            headerRowHeight={32}
            enableVirtualization={false}
            className={classNames(["rdg-light", tableStyles.grid])}
            sortColumns={sortColumns}
            onSortColumnsChange={setSortColumns}
            columns={[
              {
                key: "checked",
                name: "",
                headerCellClass: tableStyles.header,
                ...cellPickedConditions(),
                width: 32,
                formatter: ({ row }) => {
                  return !row.isHeader ? (
                    <Checkbox
                      disabled={!!completedState}
                      style={{ margin: 0 }}
                      checked={
                        stateOfElement(row.id as string) ===
                        PicklistElementStateMode.Picked
                      }
                      indeterminate={
                        stateOfElement(row.id as string) ===
                        PicklistElementStateMode.Indeterminate
                      }
                      color="default"
                      onChange={() =>
                        toggleElementState(
                          row.id as string,
                          auth.user.id,
                          definition && definition.indeterminateToggles
                        )
                      }
                    />
                  ) : (
                    <></>
                  );
                },
              },
              {
                key: "middel",
                name: "Materiaal",
                sortable: true,
                headerCellClass: tableStyles.header,
                ...cellPickedConditions([tableStyles.gridCellSmallText]),
                width: 132,
                colSpan: (args) => {
                  if (args.type == "ROW") {
                    return args.row.isHeader ? nrOfColumns - 1 : undefined;
                  }

                  return undefined;
                },
              },
              {
                key: "materiaal",
                name: "Omschrijving",
                sortable: true,
                headerCellClass: tableStyles.header,
                ...cellPickedConditions(),
                formatter: ({ row }) => {
                  return (
                    <Grid
                      style={{ margin: "auto", height: "100%" }}
                      wrap="nowrap"
                      container
                      direction="column"
                      spacing={1}
                      alignContent="flex-start"
                      justifyContent="center"
                    >
                      <Grid item style={{ lineHeight: 1 }}>
                        {row["materiaal"]}
                      </Grid>
                      {row["omschrijving"] && (
                        <Grid
                          item
                          style={{
                            fontStyle: "italic",
                            color: "grey",
                            fontSize: 12,
                            maxHeight: 24,
                            lineHeight: 1,
                          }}
                        >
                          {row["omschrijving"]}
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              },
              ...(hideDimensions
                ? []
                : [
                    {
                      key: "afmeting",
                      name: "Afmeting",
                      sortable: true,
                      headerCellClass: classNames([
                        tableStyles.header,
                        tableStyles.gridCellRight,
                      ]),
                      ...cellPickedConditions([
                        tableStyles.gridCellRight,
                        tableStyles.gridCellSmallText,
                      ]),
                      formatter: ({ row }) => (
                        <Grid
                          style={{ margin: "auto", height: "100%" }}
                          wrap="nowrap"
                          container
                          direction="column"
                          spacing={1}
                          alignContent="flex-start"
                          justifyContent="center"
                        >
                          <Grid item style={{ lineHeight: 1 }}>
                            {row["afmeting"]}
                          </Grid>
                        </Grid>
                      ),
                      width: 200,
                    },
                  ]),
              ...(hideAmount
                ? []
                : [
                    {
                      key: "van_hv",
                      name: "Aantal",
                      sortable: true,
                      headerCellClass: classNames([
                        tableStyles.header,
                        tableStyles.gridCellRight,
                      ]),
                      ...cellPickedConditions([tableStyles.gridCellRight]),
                      formatter: ({ row }) => {
                        return (
                          (row["van_hv"] &&
                            toNumberFormat(row["van_hv"] as string)) || <></>
                        );
                      },
                      width: 32,
                    },
                  ]),
              {
                key: "van_eh",
                name: "",
                sortable: true,
                headerCellClass: classNames([
                  tableStyles.header,
                  tableStyles.gridCellCentered,
                ]),
                ...cellPickedConditions([tableStyles.gridCellCentered]),
                width: 32,
              },
              {
                key: "naar_hvcode",
                name: "Doelafdeling",
                sortable: true,
                headerCellClass: tableStyles.header,
                ...cellPickedConditions(),
                width: 48,
              },
            ]}
            rows={elementsSorted || []}
          ></DataGrid>
        </Paper>
      </div>

      {commentOpen && (
        <PicklistCommentDialog
          apollo={apollo}
          userId={auth.user.id}
          projectId={projectId}
          picklistDefinitionId={picklistDefinitionId}
          onClosed={() => setCommentOpen(false)}
        />
      )}

      {!completedState && isReadyForCompletion(lines) && (
        <Fab
          style={{
            position: "absolute",
            bottom: 128,
            right: 24,
          }}
          color="primary"
          aria-label="set-completed"
          onClick={() => setCompleted(auth.user.id)}
        >
          <DoneIcon />
        </Fab>
      )}

      <Fab
        style={{
          position: "absolute",
          bottom: 64,
          right: 24,
        }}
        color="primary"
        aria-label="comment"
        onClick={() => setCommentOpen(true)}
      >
        <CommentIcon />
      </Fab>
    </PortalThemeProvider>
  );
};
