import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../auth.service";
import { RestService, RestResponse } from "../rest.service";
import { MdcDialog, MdcSnackbar } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { UrlOpenService } from "../url-open.service";
import { Greeting, setupAnchorOverwrite } from "../greetings/greeting.entity";
import * as _ from "lodash";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  static SCREENS_INTERVAL = 15 * 1000 * 2;

  protected screensInterval: any;
  protected anchorOverwriteDestruct: Function;

  greeting: RestResponse<Greeting>;
  screens: RestResponse<ScreenContainer[]>;

  protected get greetingService() {
    return this.entityManager.get(Greeting);
  }

  get greetingId() {
    return this.grantService.var(grants.default.greeting_template_id, null);
  }

  constructor(
    readonly authService: AuthService,
    protected readonly mdcDialog: MdcDialog,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly urlOpenService: UrlOpenService
  ) {}

  ngOnInit() {
    this.setup();
  }

  ngOnDestroy() {
    if (this.screensInterval) {
      clearInterval(this.screensInterval);
    }

    this.anchorOverwriteDestruct && this.anchorOverwriteDestruct();
  }

  async setup() {
    await this.fetchScreens();
    await this.fetchGreetings();

    this.anchorOverwriteDestruct = setupAnchorOverwrite(this.urlOpenService);

    setTimeout(() => this.removeWatermark(), 0);
  }

  moveNext() {
    this.nextImage();
    this.resetInterval();
  }

  protected async fetchScreens() {
    const response = await this.restService.get<string[]>("volume/screens");

    if (!response.hasError()) {
      this.screens = new RestResponse(
        response.value
          .map((e) => `volume/screen/${e}`)
          .map((e) => new ScreenContainer(e))
      );

      this.nextImage();
      this.resetInterval();
    }
  }

  protected resetInterval() {
    if (this.screensInterval) {
      clearInterval(this.screensInterval);
    }

    this.screensInterval = setInterval(
      () => this.nextImage(),
      HomeComponent.SCREENS_INTERVAL
    );
  }

  protected activeIndex() {
    return this.screens.value.findIndex((e) => e.isVisible);
  }

  protected nextImage() {
    const index = this.activeIndex();
    const nextIndex = index < this.screens.value.length - 1 ? index + 1 : 0;

    this.screens.value.forEach((e, i) => (e.isVisible = i === nextIndex));
  }

  protected async fetchGreetings() {
    if (this.greetingId) {
      this.greeting = await this.greetingService.findOne(this.greetingId);
    }
  }

  protected removeWatermark() {
    const element = document.querySelector('[data-f-id="pbf"]');

    if (element) {
      element.remove();
    }
  }
}
class ScreenContainer {
  isVisible: boolean;

  constructor(public readonly url: string) {}
}
