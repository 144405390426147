import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AuthService } from "../auth.service";
import { MdcSnackbar } from "@angular-mdc/web";
import { environment } from "../../environments/environment";
import { DialogService } from "../dialog.service";
import {
  EntitySearchDialogConfig,
  EntitySearchDialogComponent,
} from "../entity-search-dialog/entity-search-dialog.component";
import { User } from "../accessibility-users/user.entity";

/**
 * Component used in the master component for controlling session.
 *
 * @export
 * @class AccountDialogComponent
 */
@Component({
  selector: "app-account-dialog",
  templateUrl: "./account-dialog.component.html",
  styleUrls: ["./account-dialog.component.scss"],
})
export class AccountDialogComponent implements OnInit {
  isOk = false;

  versions = {
    h: "",
    y: "",
  };

  /**
   * Creates an instance of AccountDialogComponent.
   * @param {AuthService} authService
   * @memberof AccountDialogComponent
   */
  constructor(
    readonly authService: AuthService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  /**
   * Logs out the user.
   *
   * @memberof AccountDialogComponent
   */
  logout = () => this.authService.logout(true);

  ngOnInit() {
    this.versions.h = this.authService.user.serverVersion;
    this.versions.y = environment.version;
  }

  async refreshSession() {
    await this.authService.refreshAll(true);

    this.snackbar.open("Sessie vernieuwd", "Ok", {
      leading: true,
      timeoutMs: 4000,
    });
  }

  async switchUser() {
    const data = new EntitySearchDialogConfig("users");
    data.nameField = "id";
    data.descriptionField = "name";
    data.filterFields = ["id", "name"];
    data.filterAfter = (user: User) => user.overwriteSignIn == false;
    data.filters = [
      { field: "roleId", operator: "IsNull", isNot: true },
      { field: "overwriteSignIn", operator: "Equal", value: false },
    ];

    const user: User = await this.dialogService.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (user && user.id) {
      await this.authService.switchUser(user.id);
    }
  }
}
