import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { MetacomService } from "../metacom.service";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PageService } from "../page.service";
import { DialogService } from "../dialog.service";
import {
  MetacomRelationContactDialogComponent,
  MetacomRelationContactDialogComponentData,
  RelationContactType,
} from "../metacom-relation-contact-dialog/metacom-relation-contact-dialog.component";

@Component({
  selector: "app-metacom-relation",
  templateUrl: "./metacom-relation.component.html",
  styleUrls: ["./metacom-relation.component.scss"],
})
export class MetacomRelationComponent implements OnInit {
  public filterQuery = "";
  public response: RestResponse<any[]>;

  protected filterQueryChanged = new Subject<string>();

  constructor(
    protected readonly metacomService: MetacomService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.response = new RestResponse<any[]>([]);
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.fetchItems());
  }

  openContacts(id: string, type: RelationContactType) {
    return this.dialogService.open(
      this.changeDetector,
      MetacomRelationContactDialogComponent,
      {
        data: new MetacomRelationContactDialogComponentData(type, id),
      }
    );
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  print() {
    window.print();
  }

  protected async fetchItems() {
    if (this.filterQuery.length) {
      this.response = await this.metacomService.queryTableAsync({
        setName: "metacom",
        tableName: "relaties",
        filter: `mtc_relatie.stadium = "1000" AND (mtc_relatie.naam MATCHES "*${this.filterQuery}*" OR mtc_relatie.vst_plaats MATCHES "*${this.filterQuery}*")`,
      });
    }
  }
}
