import { Injectable } from "@angular/core";
import { MdcSnackbar, MdcDialog, MdcDialogRef } from "@angular-mdc/web";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { RestResponse } from "./rest.service";

export type TransactionPromise = TransactionPromiseTyped<{}>;
export type TransactionPromiseTyped<T> = () => TransactionTypedUnWrapped<T>;

export type TransactionUnWrapped<T> = TransactionTypedUnWrapped<T>;
export type TransactionTypedUnWrapped<T> = Promise<RestResponse<T>>;

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(
    protected readonly dialog: MdcDialog,
    protected readonly snackbar: MdcSnackbar
  ) {}

  async perform<T>(
    transaction: TransactionPromiseTyped<T>,
    message = "Opgeslagen"
  ) {
    return (await this.performMany([transaction], message)) as RestResponse<T>;
  }

  async performMany(
    transactions: TransactionPromise[],
    message = "Opgeslagen"
  ) {
    let response: RestResponse<{}> = null;
    let loader: MdcDialogRef<LoadingDialogComponent> = null;

    try {
      loader = this.makeLoader();

      for (const promise of transactions) {
        response = await promise();

        if (response.hasError()) {
          throw new Error(response.error.message);
        }
      }

      this.snackbar.open(message, "OK", { leading: true });
    } catch (e) {
      this.showError(e.message);
    } finally {
      if (loader) loader.close();
    }

    return response;
  }

  async doLoading(func: () => Promise<void>) {
    let loader: MdcDialogRef<LoadingDialogComponent> = null;

    try {
      loader = this.makeLoader("Laden...");
      await func();
    } catch (e) {
      this.showError(e.message);
    } finally {
      if (loader) loader.close();
    }
  }

  protected makeLoader(title = "Opslaan...") {
    return this.dialog.open(LoadingDialogComponent, {
      data: { title },
    });
  }

  showError(error: string) {
    this.snackbar.open(error, "OK", {
      classes: ["error-snackbar"],
      leading: true,
    });
  }
}
export interface TransactionConfig {
  messageOk: string;
}
