import { Component, OnInit, Inject } from "@angular/core";
import { AppPlanCompetenceSetting } from "../app-activities/app-plan-competence-setting.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-app-activity-dialog",
  templateUrl: "./app-activity-dialog.component.html",
  styleUrls: ["./app-activity-dialog.component.scss"],
})
export class AppActivityDialogComponent extends AppCrudDialogBoilerplate<
  AppPlanCompetenceSetting
> {
  get canSave() {
    return !!this.data.model.plan_competence_id;
  }

  planCompetenceFilter = {
    allowNothing: true,
    title: "Activiteit",
    icon: "local_activity",
    entityName: "app.plan_competences",
    nameField: "number",
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["number", "description"],
  };

  contentFilter = {
    allowNothing: true,
    title: "Informatie Pagina",
    icon: "pageview",
    entityName: "app.contents",
    nameField: "id",
    descriptionField: "title",
    sortField: "title",
    sortDirection: "ASC",
    filterFields: ["title", "html"],
  };

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppPlanCompetenceSetting>,
    protected readonly dialog: MdcDialogRef<AppActivityDialogComponent>
  ) {
    super(data, dialog);
  }
}
