import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppProjectFieldSheet } from "./app-project-field-sheet.entity";
import { AppProjectFieldGroup } from "./app-project-field-group.entity";
import { AppProjectFieldGroupAssignment } from "./app-project-field-group-assignment.entity";
import { TransactionService } from "../transaction.service";
import { DialogService } from "../dialog.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { EntityManager, OrderableEntity } from "../entity.service";
import { AppProjectFieldSheetDialogComponent } from "../app-project-field-sheet-dialog/app-project-field-sheet-dialog.component";
import {
  AppCrudBoilerplateInstance,
  AppCrudBoilerplateInstanceData,
} from "../boilerplates/app-crud.boilerplate";
import { AppProjectFieldGroupDialogComponent } from "../app-project-field-group-dialog/app-project-field-group-dialog.component";
import { AppProjectFieldGroupAssignmentDialogComponent } from "../app-project-field-group-assignment-dialog/app-project-field-group-assignment-dialog.component";

@Component({
  selector: "app-app-project-fields",
  templateUrl: "./app-project-fields.component.html",
  styleUrls: ["./app-project-fields.component.scss"],
})
export class AppProjectFieldsComponent implements OnInit {
  fieldSheetCrud: AppCrudBoilerplateInstance<AppProjectFieldSheet>;
  fieldGroupCrud: AppCrudBoilerplateInstance<AppProjectFieldGroup>;
  fieldGroupAssignmentCrud: AppCrudBoilerplateInstance<
    AppProjectFieldGroupAssignment
  >;

  tree: RecursiveFieldItem[];
  treeExpansions = new Map<string, boolean>();

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    this.fieldSheetCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppProjectFieldSheet,
        editDialog: AppProjectFieldSheetDialogComponent,
        relations: [
          "groups",
          "groups.assignments",
          "groups.assignments.values",
        ],
        orderByField: "order",
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.fieldGroupCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppProjectFieldGroup,
        editDialog: AppProjectFieldGroupDialogComponent,
        disableFetch: true,
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.fieldGroupAssignmentCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppProjectFieldGroupAssignment,
        editDialog: AppProjectFieldGroupAssignmentDialogComponent,
        disableFetch: true,
      }),
      transactions,
      entityManager,
      dialogs,
      changeDetector
    );

    this.fieldSheetCrud.onUpdated.subscribe(() => this.update(false));
    this.fieldGroupCrud.onUpdated.subscribe(() => this.update(true));
    this.fieldGroupAssignmentCrud.onUpdated.subscribe(() => this.update(true));
  }

  async ngOnInit() {
    await this.update(true);
  }

  async update(fetch = true) {
    if (fetch) {
      await this.fieldSheetCrud.ngOnInit();
    }

    this.setupTree();
  }

  protected getExpandId(item: RecursiveFieldItem) {
    const entityName = item.data.crud
      ? item.data.crud.entityService.name
      : "root";

    return `${entityName}_${item.data.entity.id}`;
  }

  isExpanded(item: RecursiveFieldItem) {
    return this.treeExpansions.get(this.getExpandId(item)) || false;
  }

  toggleExpand(item: RecursiveFieldItem) {
    this.setExpand(item, !this.isExpanded(item));
  }

  setExpand(item: RecursiveFieldItem, value: boolean) {
    this.treeExpansions.set(this.getExpandId(item), value);
  }

  protected setupTree() {
    const root = new RecursiveFieldItem({
      name: "",
      entity: this.fieldGroupAssignmentCrud.entityService.concept({
        id: "root",
      }),
      crud: null,
      crudChildren: this.fieldSheetCrud,
      children: Array.from(this.mapSheets(this.fieldSheetCrud.response.value)),
    });

    this.setExpand(root, true);

    this.tree = [root];
  }

  async addChild(item: RecursiveFieldItem) {
    const concept = { order: 999 };

    if (!!item.data.foreignKey) {
      concept[item.data.foreignKey] = item.data.entity.id;
    }

    await item.data.crudChildren.edit(
      item.data.crudChildren.entityService.concept(concept)
    );
  }

  protected *mapSheets(items: AppProjectFieldSheet[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.name,
        entity: item,
        crud: this.fieldSheetCrud,
        crudChildren: this.fieldGroupCrud,
        foreignKey: "project_field_sheet_id",
        children: Array.from(this.mapGroups(item.groups)),
      });
    }
  }

  protected *mapGroups(items: AppProjectFieldGroup[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.name,
        entity: item,
        crud: this.fieldGroupCrud,
        foreignKey: "project_field_group_id",
        crudChildren: this.fieldGroupAssignmentCrud,
        children: Array.from(this.mapAssignments(item.assignments)),
      });
    }
  }

  protected *mapAssignments(items: AppProjectFieldGroupAssignment[]) {
    for (const item of items) {
      yield new RecursiveFieldItem({
        name: item.project_field_type,
        entity: item,
        crud: this.fieldGroupAssignmentCrud,
      });
    }
  }

  dragStarted(items: RecursiveFieldItem[]) {
    items.forEach((item) => (item.isExpandedRecovery = this.isExpanded(item)));
    items.forEach((item) => this.setExpand(item, false));

    this.changeDetector.detectChanges();
  }

  dragStopped(items: RecursiveFieldItem[]) {
    items.forEach((item) => this.setExpand(item, item.isExpandedRecovery));
  }

  async drop(
    event: CdkDragDrop<RecursiveFieldItem[]>,
    crud: AppCrudBoilerplateInstance<OrderableEntity>
  ) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

    const entities = event.container.data.map((e) => e.data.entity);

    this.setOrderIds(entities);

    await this.fieldSheetCrud.transactions.perform(() =>
      crud.entityService.modifyMany(entities)
    );
  }

  protected setOrderIds(items: OrderableEntity[]) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order = i;
    }
  }
}

class RecursiveFieldItem {
  isExpandedRecovery: boolean;

  constructor(readonly data: RecursiveFieldItemData) {}

  get canModify() {
    return !!this.data.crud && !!this.data.crud.editDialog;
  }

  get hasChildren() {
    return this.data.children && this.data.children.length > 0;
  }
}

interface RecursiveFieldItemData {
  readonly name: string;
  readonly entity: OrderableEntity;
  readonly crud: AppCrudBoilerplateInstance<OrderableEntity>;
  readonly crudChildren?: AppCrudBoilerplateInstance<OrderableEntity>;
  readonly foreignKey?: string;
  readonly children?: RecursiveFieldItem[];
}
