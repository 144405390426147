import { Component, Injector } from '@angular/core';
import { ReactAttunedComponent, reactAttunedComponentTemplate } from '../react-component/react-attuned.component';
import { CustomerQuestionsComponent, CustomerQuestionsComponentProps } from './customer-questions.component';

@Component({
  template: reactAttunedComponentTemplate,
  styles: ['.attuned--component { overflow: hidden; }'],
})
export class CustomerQuestionsAngular extends ReactAttunedComponent<CustomerQuestionsComponentProps> {
  component = CustomerQuestionsComponent;

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector, ///test
    };
  }
}
