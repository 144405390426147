import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { AppProjectFieldGroupAssignment } from "../app-project-fields/app-project-field-group-assignment.entity";
import { DialogService } from "../dialog.service";
import { RestResponse } from "../rest.service";
import { AppProjectField } from "../app-project-fields/app-project-field.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  AppCrudBoilerplateInstance,
  AppCrudBoilerplateInstanceData,
} from "../boilerplates/app-crud.boilerplate";
import { AppProjectFieldGroupAssignmentValue } from "../app-project-fields/app-project-field-group-assignment-value.entity";
import { TransactionPromise } from "../transaction.service";

@Component({
  selector: "app-app-project-field-group-assignment-dialog",
  templateUrl: "./app-project-field-group-assignment-dialog.component.html",
  styleUrls: ["./app-project-field-group-assignment-dialog.component.scss"],
})
export class AppProjectFieldGroupAssignmentDialogComponent extends AppCrudDialogBoilerplate<
  AppProjectFieldGroupAssignment
> {
  fieldTypes: RestResponse<AppProjectField[]>;
  fieldGroupAssignmentValueCrud: AppCrudBoilerplateInstance<
    AppProjectFieldGroupAssignmentValue
  >;

  get canSave() {
    return (
      !!this.data.model.project_field_type &&
      !!this.data.model.value_type &&
      (this.data.model.values || []).every((value) => value.value)
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppProjectFieldGroupAssignment>,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialog: MdcDialogRef<
      AppProjectFieldGroupAssignmentDialogComponent
    >
  ) {
    super(data, dialog);

    this.fieldGroupAssignmentValueCrud = new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppProjectFieldGroupAssignmentValue,
        disableFetch: true,
      }),
      this.data.boilerplate.transactions,
      this.data.boilerplate.entityManager,
      this.dialogs,
      this.changeDetector
    );
  }

  async save(closeDialog = true) {
    const modelResponse = await super.save(false);

    const values = this.data.model.values || [];
    const entityService = this.fieldGroupAssignmentValueCrud.entityService;

    values.forEach(
      (e) => (e.project_field_group_assignment_id = this.data.model.id)
    );

    const toSave = values.filter((e) => e.isDirty && !e.isDeleted);
    const toSaveOps = () =>
      entityService.saveMany(toSave) as Promise<RestResponse<{}>>;

    const toDelete = values.filter((e) => !!e.id && e.isDeleted);
    const toDeleteOps = toDelete.map((e) => () =>
      entityService.delete(e)
    ) as TransactionPromise[];

    const response = await this.data.boilerplate.transactions.performMany(
      toDeleteOps.concat(toSaveOps)
    );

    if (!response.hasError()) {
      this.close(modelResponse);

      return modelResponse;
    }
  }

  addValue() {
    this.data.model.values = this.data.model.values || [];
    this.data.model.values.push(
      this.fieldGroupAssignmentValueCrud.entityService.concept({})
    );
  }

  async ngOnInit() {
    const repo = this.data.boilerplate.entityManager.get(AppProjectField);
    this.fieldTypes = await repo.queryRaw(
      "select distinct type from project_fields"
    );
  }
}
