import { EntityManager } from "src/app/entity.service";
import { CrudBloc } from "../../bloc/crud/crud.bloc";
import { CrudEvent } from "../../bloc/crud/crud.event";
import { CrudInitialState } from "../../bloc/crud/crud.state";
import { MeterEntity } from "./meter.entity";

export class MeterBloc extends CrudBloc<MeterEntity> {
  constructor(protected readonly entities: EntityManager) {
    super(new CrudInitialState());
  }

  get repo() {
    return this.entities.get(MeterEntity);
  }

  get orders() {
    return [{ field: "orderId", direction: "ASC" }];
  }

  async *mapEventToState(event: CrudEvent) {
    yield* super.mapEventToState(event);
  }
}
