import { Component, OnInit, Inject } from "@angular/core";
import { AppProjectFieldSheet } from "../app-project-fields/app-project-field-sheet.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { RestResponse } from "../rest.service";
import { AppProjectContact } from "../app-document-type-dialog/app-project-contact.entity";

@Component({
  selector: "app-app-project-field-sheet-dialog",
  templateUrl: "./app-project-field-sheet-dialog.component.html",
  styleUrls: ["./app-project-field-sheet-dialog.component.scss"],
})
export class AppProjectFieldSheetDialogComponent
  extends AppCrudDialogBoilerplate<AppProjectFieldSheet>
  implements OnInit {
  contactTypes: RestResponse<AppProjectContact[]>;

  get canSave() {
    return !!this.data.model.name;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppProjectFieldSheet>,
    protected readonly dialog: MdcDialogRef<AppProjectFieldSheetDialogComponent>
  ) {
    super(data, dialog);
  }

  async ngOnInit() {
    const repo = this.data.boilerplate.entityManager.get(AppProjectContact);
    this.contactTypes = await repo.queryRaw(
      "select distinct type from project_contacts"
    );
  }
}
