import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA } from '@angular-mdc/web';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: any,
  ) { }
}
