import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { Project } from "../project/project.entity";
import { DocumentMeta } from "./document-meta.entity";
import { first } from "lodash";

export class SupplierDocument extends Entity {
  type = "supplier_documents";

  userId: string;

  informedUserId?: string;
  informedContactTypes?: string[];

  projectId: string;
  documentMetaId: string;

  __user__?: User;
  __informedUser__?: User;
  __project__?: Project;
  __documentMeta__?: DocumentMeta;

  updatedAt?: Date;
}

export class SupplierDocumentGroup {
  constructor(readonly items: SupplierDocument[]) {}

  get user() {
    return first(this.items).__user__;
  }

  get informedUserId() {
    return first(this.items).__informedUser__;
  }
}
