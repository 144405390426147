import gql from "graphql-tag";

export const constructionFoundationMutation = gql`
  mutation constructionFoundationMutation(
    $input: InputProjectEntity!
    $customFields: [CellaCustomField!]!
  ) {
    cella: CellaWriteCustomFields(CustomField: $customFields)
    portal: ProjectEntityUpdateOne(input: $input) {
      id
    }
  }
`;
