/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		GrantEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RoleGrantConfigEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RoleDocumentGrantEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		RoleEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserGrantConfigEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserNotificationSubscriptionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserNotificationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserTemporaryAccessTokenEntityEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistDefinitionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistStateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HousePartGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HousePartEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CargoPhaseEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CargoTypeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningHumanResourceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectAssignmentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectCompetencePlacementEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectRequestPlacementEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectRequestEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectCompetenceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningCompetenceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CargoStateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CargoEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectHousePartEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistElementEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DrawOrderTypeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HouseModelEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SalesEmployeeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DrawerAbsentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DrawerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DrawOrderEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateGroupCommentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateGroupResponsibleEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LogicFieldEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateValueEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LogicFieldValueEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DateCacheEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		YearPlanningModificationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectCachedValueEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		TableLayoutCommentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SyncCronEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MailSchedulerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectPartnerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PartnerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DocumentMetaEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		EntityAdvancementEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		WorkActionCategoryEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		WorkActionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		EntityImageEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CustomerQuestionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditCategoryEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditQuestionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditQuestionCostCategoryEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		CostCategoryEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditReportItemImageEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditReportItemEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditReportEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AuditChecklistImageEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InvoiceAttachmentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		EmployeeDocumentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		WorkActionTemplateGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		WorkActionTemplateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectCostInfoEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		BlockedCalendarWeekEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		MaintenanceReportEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ConfigurationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceMeterEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DeliveryListMeterEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceSpaceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceTypeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DeliveryListPointImageEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServicePriorityEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DeliveryListPointEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DeliveryListSignatureEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DeliveryListEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SupplyInspectionReportAnswerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SupplyInspectionReportEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DepartmentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		DrawOrderDescriptionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		GreetingEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HourDeclarationTypeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HourDeclarationActivityEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HourDeclarationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		HourDeclarationApprovalRequestEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportCommentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportDescriptionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportFinalEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportMeasurementTemplateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportMeasurementEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportQuestionGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportQuestionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InstallationReportQuestionAnswerEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		LegacyProjectEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistCommentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PicklistElementStateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		PlanningProjectItemEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectCargoStateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ProjectPaymentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		QualityAssuranceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServiceMapColorEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServicePlanningStageEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServicePlanningEventEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServicePlanningAssignmentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		ServicePresetEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SupplierDocumentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SupplyInspectionQuestionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		SupplyInspectionQuestionGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		UserPasswordResetEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		YearPlanningLineEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		InvoicePaymentBatchEntityEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPhaseContactEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPhaseEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPhaseSectionEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppNotificationTemplateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPhaseSectionMarkEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectDateEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppNotificationScheduleEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPhaseSectionMarkValueEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppInviteEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppLoginEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppNotificationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppUserEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectCostEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectContactEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppBuildingWeekEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppContentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppConversationMessagePresetEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppDocumentSignatureEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppDocumentTypeEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppDocumentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppNotificationScheduleResourceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPlanCompetenceEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppPlanCompetenceSettingEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectCostPresetEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectFieldSheetEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectFieldGroupEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectFieldGroupAssignmentEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectFieldGroupAssignmentValueEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppProjectFieldEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppServiceCategoryEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppTextEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		AppRelationEntityFindById:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityFindOne:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityFindMany:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityAggregateGroupCount:{
			options:{
				type:"JSON",
				array:false,
				arrayRequired:false,
				required:false
			},
			query:{
				type:"EntityQuery",
				array:false,
				arrayRequired:false,
				required:false
			},
			groupByField:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		bedrijvenMtcEntityFindMany:{
			pageNr:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			pageSize:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			filter:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Date: "String",
	JSON: "String",
	EntityQuery:{
		filters:{
			type:"EntityQueryFilter",
			array:true,
			arrayRequired:true,
			required:true
		},
		orders:{
			type:"EntityQueryOrder",
			array:true,
			arrayRequired:false,
			required:true
		},
		relations:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	EntityQueryFilter:{
		field:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		operator:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		valueComplex:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		isOr:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNot:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	EntityQueryOrder:{
		field:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		direction:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Mutation:{
		GrantEntityCreateOne:{
			input:{
				type:"InputGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityUpdateOne:{
			input:{
				type:"InputGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityUpdateMany:{
			input:{
				type:"InputGrantEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		GrantEntityDeleteOne:{
			input:{
				type:"InputDeleteGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityCreateOne:{
			input:{
				type:"InputRoleGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityUpdateOne:{
			input:{
				type:"InputRoleGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityUpdateMany:{
			input:{
				type:"InputRoleGrantConfigEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		RoleGrantConfigEntityDeleteOne:{
			input:{
				type:"InputDeleteRoleGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityCreateOne:{
			input:{
				type:"InputRoleDocumentGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityUpdateOne:{
			input:{
				type:"InputRoleDocumentGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityUpdateMany:{
			input:{
				type:"InputRoleDocumentGrantEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		RoleDocumentGrantEntityDeleteOne:{
			input:{
				type:"InputDeleteRoleDocumentGrantEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityCreateOne:{
			input:{
				type:"InputRoleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityUpdateOne:{
			input:{
				type:"InputRoleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityUpdateMany:{
			input:{
				type:"InputRoleEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		RoleEntityDeleteOne:{
			input:{
				type:"InputDeleteRoleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityCreateOne:{
			input:{
				type:"InputUserGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityUpdateOne:{
			input:{
				type:"InputUserGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityUpdateMany:{
			input:{
				type:"InputUserGrantConfigEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserGrantConfigEntityDeleteOne:{
			input:{
				type:"InputDeleteUserGrantConfigEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityCreateOne:{
			input:{
				type:"InputUserNotificationSubscriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityUpdateOne:{
			input:{
				type:"InputUserNotificationSubscriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityUpdateMany:{
			input:{
				type:"InputUserNotificationSubscriptionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationSubscriptionEntityDeleteOne:{
			input:{
				type:"InputDeleteUserNotificationSubscriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityCreateOne:{
			input:{
				type:"InputUserNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityUpdateOne:{
			input:{
				type:"InputUserNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityUpdateMany:{
			input:{
				type:"InputUserNotificationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserNotificationEntityDeleteOne:{
			input:{
				type:"InputDeleteUserNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityCreateOne:{
			input:{
				type:"InputUserTemporaryAccessTokenEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityUpdateOne:{
			input:{
				type:"InputUserTemporaryAccessTokenEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityUpdateMany:{
			input:{
				type:"InputUserTemporaryAccessTokenEntityEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserTemporaryAccessTokenEntityEntityDeleteOne:{
			input:{
				type:"InputDeleteUserTemporaryAccessTokenEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityCreateOne:{
			input:{
				type:"InputUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityUpdateOne:{
			input:{
				type:"InputUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityUpdateMany:{
			input:{
				type:"InputUserEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserEntityDeleteOne:{
			input:{
				type:"InputDeleteUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityCreateOne:{
			input:{
				type:"InputPicklistDefinitionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityUpdateOne:{
			input:{
				type:"InputPicklistDefinitionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityUpdateMany:{
			input:{
				type:"InputPicklistDefinitionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistDefinitionEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistDefinitionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityCreateOne:{
			input:{
				type:"InputPicklistStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityUpdateOne:{
			input:{
				type:"InputPicklistStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityUpdateMany:{
			input:{
				type:"InputPicklistStateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistStateEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityCreateOne:{
			input:{
				type:"InputHousePartGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityUpdateOne:{
			input:{
				type:"InputHousePartGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityUpdateMany:{
			input:{
				type:"InputHousePartGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HousePartGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteHousePartGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityCreateOne:{
			input:{
				type:"InputHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityUpdateOne:{
			input:{
				type:"InputHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityUpdateMany:{
			input:{
				type:"InputHousePartEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HousePartEntityDeleteOne:{
			input:{
				type:"InputDeleteHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityCreateOne:{
			input:{
				type:"InputCargoPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityUpdateOne:{
			input:{
				type:"InputCargoPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityUpdateMany:{
			input:{
				type:"InputCargoPhaseEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CargoPhaseEntityDeleteOne:{
			input:{
				type:"InputDeleteCargoPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityCreateOne:{
			input:{
				type:"InputCargoTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityUpdateOne:{
			input:{
				type:"InputCargoTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityUpdateMany:{
			input:{
				type:"InputCargoTypeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CargoTypeEntityDeleteOne:{
			input:{
				type:"InputDeleteCargoTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityCreateOne:{
			input:{
				type:"InputPlanningHumanResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityUpdateOne:{
			input:{
				type:"InputPlanningHumanResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityUpdateMany:{
			input:{
				type:"InputPlanningHumanResourceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningHumanResourceEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningHumanResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityCreateOne:{
			input:{
				type:"InputPlanningProjectAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectAssignmentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectAssignmentEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityCreateOne:{
			input:{
				type:"InputPlanningProjectCompetencePlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectCompetencePlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectCompetencePlacementEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetencePlacementEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectCompetencePlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityCreateOne:{
			input:{
				type:"InputPlanningProjectRequestPlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectRequestPlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectRequestPlacementEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestPlacementEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectRequestPlacementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityCreateOne:{
			input:{
				type:"InputPlanningProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityCreateOne:{
			input:{
				type:"InputPlanningProjectRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectRequestEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectRequestEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityCreateOne:{
			input:{
				type:"InputPlanningProjectCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectCompetenceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectCompetenceEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityCreateOne:{
			input:{
				type:"InputPlanningCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityUpdateOne:{
			input:{
				type:"InputPlanningCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityUpdateMany:{
			input:{
				type:"InputPlanningCompetenceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningCompetenceEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityCreateOne:{
			input:{
				type:"InputCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityUpdateOne:{
			input:{
				type:"InputCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityUpdateMany:{
			input:{
				type:"InputCargoStateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CargoStateEntityDeleteOne:{
			input:{
				type:"InputDeleteCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityCreateOne:{
			input:{
				type:"InputCargoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityUpdateOne:{
			input:{
				type:"InputCargoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityUpdateMany:{
			input:{
				type:"InputCargoEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CargoEntityDeleteOne:{
			input:{
				type:"InputDeleteCargoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityCreateOne:{
			input:{
				type:"InputProjectHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityUpdateOne:{
			input:{
				type:"InputProjectHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityUpdateMany:{
			input:{
				type:"InputProjectHousePartEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectHousePartEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectHousePartEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityCreateOne:{
			input:{
				type:"InputPicklistElementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityUpdateOne:{
			input:{
				type:"InputPicklistElementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityUpdateMany:{
			input:{
				type:"InputPicklistElementEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistElementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityCreateOne:{
			input:{
				type:"InputPicklistEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityUpdateOne:{
			input:{
				type:"InputPicklistEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityUpdateMany:{
			input:{
				type:"InputPicklistEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityCreateOne:{
			input:{
				type:"InputDrawOrderTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityUpdateOne:{
			input:{
				type:"InputDrawOrderTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityUpdateMany:{
			input:{
				type:"InputDrawOrderTypeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderTypeEntityDeleteOne:{
			input:{
				type:"InputDeleteDrawOrderTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityCreateOne:{
			input:{
				type:"InputHouseModelEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityUpdateOne:{
			input:{
				type:"InputHouseModelEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityUpdateMany:{
			input:{
				type:"InputHouseModelEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HouseModelEntityDeleteOne:{
			input:{
				type:"InputDeleteHouseModelEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityCreateOne:{
			input:{
				type:"InputSalesEmployeeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityUpdateOne:{
			input:{
				type:"InputSalesEmployeeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityUpdateMany:{
			input:{
				type:"InputSalesEmployeeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SalesEmployeeEntityDeleteOne:{
			input:{
				type:"InputDeleteSalesEmployeeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityCreateOne:{
			input:{
				type:"InputDrawerAbsentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityUpdateOne:{
			input:{
				type:"InputDrawerAbsentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityUpdateMany:{
			input:{
				type:"InputDrawerAbsentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DrawerAbsentEntityDeleteOne:{
			input:{
				type:"InputDeleteDrawerAbsentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityCreateOne:{
			input:{
				type:"InputDrawerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityUpdateOne:{
			input:{
				type:"InputDrawerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityUpdateMany:{
			input:{
				type:"InputDrawerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DrawerEntityDeleteOne:{
			input:{
				type:"InputDeleteDrawerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityCreateOne:{
			input:{
				type:"InputDrawOrderEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityUpdateOne:{
			input:{
				type:"InputDrawOrderEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityUpdateMany:{
			input:{
				type:"InputDrawOrderEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderEntityDeleteOne:{
			input:{
				type:"InputDeleteDrawOrderEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityCreateOne:{
			input:{
				type:"InputDateGroupCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityUpdateOne:{
			input:{
				type:"InputDateGroupCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityUpdateMany:{
			input:{
				type:"InputDateGroupCommentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupCommentEntityDeleteOne:{
			input:{
				type:"InputDeleteDateGroupCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityCreateOne:{
			input:{
				type:"InputDateGroupResponsibleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityUpdateOne:{
			input:{
				type:"InputDateGroupResponsibleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityUpdateMany:{
			input:{
				type:"InputDateGroupResponsibleEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupResponsibleEntityDeleteOne:{
			input:{
				type:"InputDeleteDateGroupResponsibleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityCreateOne:{
			input:{
				type:"InputLogicFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityUpdateOne:{
			input:{
				type:"InputLogicFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityUpdateMany:{
			input:{
				type:"InputLogicFieldEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldEntityDeleteOne:{
			input:{
				type:"InputDeleteLogicFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityCreateOne:{
			input:{
				type:"InputDateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityUpdateOne:{
			input:{
				type:"InputDateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityUpdateMany:{
			input:{
				type:"InputDateGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteDateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityCreateOne:{
			input:{
				type:"InputDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityUpdateOne:{
			input:{
				type:"InputDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityUpdateMany:{
			input:{
				type:"InputDateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateEntityDeleteOne:{
			input:{
				type:"InputDeleteDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityCreateOne:{
			input:{
				type:"InputDateValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityUpdateOne:{
			input:{
				type:"InputDateValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityUpdateMany:{
			input:{
				type:"InputDateValueEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateValueEntityDeleteOne:{
			input:{
				type:"InputDeleteDateValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityCreateOne:{
			input:{
				type:"InputLogicFieldValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityUpdateOne:{
			input:{
				type:"InputLogicFieldValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityUpdateMany:{
			input:{
				type:"InputLogicFieldValueEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		LogicFieldValueEntityDeleteOne:{
			input:{
				type:"InputDeleteLogicFieldValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityCreateOne:{
			input:{
				type:"InputDateCacheEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityUpdateOne:{
			input:{
				type:"InputDateCacheEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityUpdateMany:{
			input:{
				type:"InputDateCacheEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DateCacheEntityDeleteOne:{
			input:{
				type:"InputDeleteDateCacheEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityCreateOne:{
			input:{
				type:"InputYearPlanningModificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityUpdateOne:{
			input:{
				type:"InputYearPlanningModificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityUpdateMany:{
			input:{
				type:"InputYearPlanningModificationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningModificationEntityDeleteOne:{
			input:{
				type:"InputDeleteYearPlanningModificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityCreateOne:{
			input:{
				type:"InputProjectCachedValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityUpdateOne:{
			input:{
				type:"InputProjectCachedValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityUpdateMany:{
			input:{
				type:"InputProjectCachedValueEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCachedValueEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectCachedValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityCreateOne:{
			input:{
				type:"InputTableLayoutCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityUpdateOne:{
			input:{
				type:"InputTableLayoutCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityUpdateMany:{
			input:{
				type:"InputTableLayoutCommentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		TableLayoutCommentEntityDeleteOne:{
			input:{
				type:"InputDeleteTableLayoutCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityCreateOne:{
			input:{
				type:"InputProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityUpdateOne:{
			input:{
				type:"InputProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityUpdateMany:{
			input:{
				type:"InputProjectEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityCreateOne:{
			input:{
				type:"InputSyncCronEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityUpdateOne:{
			input:{
				type:"InputSyncCronEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityUpdateMany:{
			input:{
				type:"InputSyncCronEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SyncCronEntityDeleteOne:{
			input:{
				type:"InputDeleteSyncCronEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityCreateOne:{
			input:{
				type:"InputMailSchedulerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityUpdateOne:{
			input:{
				type:"InputMailSchedulerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityUpdateMany:{
			input:{
				type:"InputMailSchedulerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		MailSchedulerEntityDeleteOne:{
			input:{
				type:"InputDeleteMailSchedulerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityCreateOne:{
			input:{
				type:"InputProjectPartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityUpdateOne:{
			input:{
				type:"InputProjectPartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityUpdateMany:{
			input:{
				type:"InputProjectPartnerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPartnerEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectPartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityCreateOne:{
			input:{
				type:"InputPartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityUpdateOne:{
			input:{
				type:"InputPartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityUpdateMany:{
			input:{
				type:"InputPartnerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PartnerEntityDeleteOne:{
			input:{
				type:"InputDeletePartnerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityCreateOne:{
			input:{
				type:"InputDocumentMetaEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityUpdateOne:{
			input:{
				type:"InputDocumentMetaEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityUpdateMany:{
			input:{
				type:"InputDocumentMetaEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DocumentMetaEntityDeleteOne:{
			input:{
				type:"InputDeleteDocumentMetaEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityCreateOne:{
			input:{
				type:"InputEntityAdvancementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityUpdateOne:{
			input:{
				type:"InputEntityAdvancementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityUpdateMany:{
			input:{
				type:"InputEntityAdvancementEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		EntityAdvancementEntityDeleteOne:{
			input:{
				type:"InputDeleteEntityAdvancementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityCreateOne:{
			input:{
				type:"InputWorkActionCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityUpdateOne:{
			input:{
				type:"InputWorkActionCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityUpdateMany:{
			input:{
				type:"InputWorkActionCategoryEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionCategoryEntityDeleteOne:{
			input:{
				type:"InputDeleteWorkActionCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityCreateOne:{
			input:{
				type:"InputWorkActionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityUpdateOne:{
			input:{
				type:"InputWorkActionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityUpdateMany:{
			input:{
				type:"InputWorkActionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionEntityDeleteOne:{
			input:{
				type:"InputDeleteWorkActionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityCreateOne:{
			input:{
				type:"InputEntityImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityUpdateOne:{
			input:{
				type:"InputEntityImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityUpdateMany:{
			input:{
				type:"InputEntityImageEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		EntityImageEntityDeleteOne:{
			input:{
				type:"InputDeleteEntityImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityCreateOne:{
			input:{
				type:"InputCustomerQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityUpdateOne:{
			input:{
				type:"InputCustomerQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityUpdateMany:{
			input:{
				type:"InputCustomerQuestionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CustomerQuestionEntityDeleteOne:{
			input:{
				type:"InputDeleteCustomerQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityCreateOne:{
			input:{
				type:"InputAuditCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityUpdateOne:{
			input:{
				type:"InputAuditCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityUpdateMany:{
			input:{
				type:"InputAuditCategoryEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditCategoryEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityCreateOne:{
			input:{
				type:"InputAuditQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityUpdateOne:{
			input:{
				type:"InputAuditQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityUpdateMany:{
			input:{
				type:"InputAuditQuestionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityCreateOne:{
			input:{
				type:"InputAuditQuestionCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityUpdateOne:{
			input:{
				type:"InputAuditQuestionCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityUpdateMany:{
			input:{
				type:"InputAuditQuestionCostCategoryEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditQuestionCostCategoryEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditQuestionCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityCreateOne:{
			input:{
				type:"InputCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityUpdateOne:{
			input:{
				type:"InputCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityUpdateMany:{
			input:{
				type:"InputCostCategoryEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		CostCategoryEntityDeleteOne:{
			input:{
				type:"InputDeleteCostCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityCreateOne:{
			input:{
				type:"InputAuditReportItemImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityUpdateOne:{
			input:{
				type:"InputAuditReportItemImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityUpdateMany:{
			input:{
				type:"InputAuditReportItemImageEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemImageEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditReportItemImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityCreateOne:{
			input:{
				type:"InputAuditReportItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityUpdateOne:{
			input:{
				type:"InputAuditReportItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityUpdateMany:{
			input:{
				type:"InputAuditReportItemEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportItemEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditReportItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityCreateOne:{
			input:{
				type:"InputAuditReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityUpdateOne:{
			input:{
				type:"InputAuditReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityUpdateMany:{
			input:{
				type:"InputAuditReportEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditReportEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityCreateOne:{
			input:{
				type:"InputAuditChecklistImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityUpdateOne:{
			input:{
				type:"InputAuditChecklistImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityUpdateMany:{
			input:{
				type:"InputAuditChecklistImageEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AuditChecklistImageEntityDeleteOne:{
			input:{
				type:"InputDeleteAuditChecklistImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityCreateOne:{
			input:{
				type:"InputInvoiceAttachmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityUpdateOne:{
			input:{
				type:"InputInvoiceAttachmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityUpdateMany:{
			input:{
				type:"InputInvoiceAttachmentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InvoiceAttachmentEntityDeleteOne:{
			input:{
				type:"InputDeleteInvoiceAttachmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityCreateOne:{
			input:{
				type:"InputEmployeeDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityUpdateOne:{
			input:{
				type:"InputEmployeeDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityUpdateMany:{
			input:{
				type:"InputEmployeeDocumentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		EmployeeDocumentEntityDeleteOne:{
			input:{
				type:"InputDeleteEmployeeDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityCreateOne:{
			input:{
				type:"InputWorkActionTemplateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityUpdateOne:{
			input:{
				type:"InputWorkActionTemplateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityUpdateMany:{
			input:{
				type:"InputWorkActionTemplateGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteWorkActionTemplateGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityCreateOne:{
			input:{
				type:"InputWorkActionTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityUpdateOne:{
			input:{
				type:"InputWorkActionTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityUpdateMany:{
			input:{
				type:"InputWorkActionTemplateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		WorkActionTemplateEntityDeleteOne:{
			input:{
				type:"InputDeleteWorkActionTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityCreateOne:{
			input:{
				type:"InputProjectCostInfoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityUpdateOne:{
			input:{
				type:"InputProjectCostInfoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityUpdateMany:{
			input:{
				type:"InputProjectCostInfoEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCostInfoEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectCostInfoEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityCreateOne:{
			input:{
				type:"InputBlockedCalendarWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityUpdateOne:{
			input:{
				type:"InputBlockedCalendarWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityUpdateMany:{
			input:{
				type:"InputBlockedCalendarWeekEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		BlockedCalendarWeekEntityDeleteOne:{
			input:{
				type:"InputDeleteBlockedCalendarWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityCreateOne:{
			input:{
				type:"InputMaintenanceReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityUpdateOne:{
			input:{
				type:"InputMaintenanceReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityUpdateMany:{
			input:{
				type:"InputMaintenanceReportEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		MaintenanceReportEntityDeleteOne:{
			input:{
				type:"InputDeleteMaintenanceReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityCreateOne:{
			input:{
				type:"InputConfigurationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityUpdateOne:{
			input:{
				type:"InputConfigurationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityUpdateMany:{
			input:{
				type:"InputConfigurationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ConfigurationEntityDeleteOne:{
			input:{
				type:"InputDeleteConfigurationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityCreateOne:{
			input:{
				type:"InputServiceMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityUpdateOne:{
			input:{
				type:"InputServiceMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityUpdateMany:{
			input:{
				type:"InputServiceMeterEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMeterEntityDeleteOne:{
			input:{
				type:"InputDeleteServiceMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityCreateOne:{
			input:{
				type:"InputDeliveryListMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityUpdateOne:{
			input:{
				type:"InputDeliveryListMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityUpdateMany:{
			input:{
				type:"InputDeliveryListMeterEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListMeterEntityDeleteOne:{
			input:{
				type:"InputDeleteDeliveryListMeterEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityCreateOne:{
			input:{
				type:"InputServiceSpaceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityUpdateOne:{
			input:{
				type:"InputServiceSpaceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityUpdateMany:{
			input:{
				type:"InputServiceSpaceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServiceSpaceEntityDeleteOne:{
			input:{
				type:"InputDeleteServiceSpaceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityCreateOne:{
			input:{
				type:"InputServiceTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityUpdateOne:{
			input:{
				type:"InputServiceTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityUpdateMany:{
			input:{
				type:"InputServiceTypeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServiceTypeEntityDeleteOne:{
			input:{
				type:"InputDeleteServiceTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityCreateOne:{
			input:{
				type:"InputDeliveryListPointImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityUpdateOne:{
			input:{
				type:"InputDeliveryListPointImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityUpdateMany:{
			input:{
				type:"InputDeliveryListPointImageEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointImageEntityDeleteOne:{
			input:{
				type:"InputDeleteDeliveryListPointImageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityCreateOne:{
			input:{
				type:"InputServicePriorityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityUpdateOne:{
			input:{
				type:"InputServicePriorityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityUpdateMany:{
			input:{
				type:"InputServicePriorityEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServicePriorityEntityDeleteOne:{
			input:{
				type:"InputDeleteServicePriorityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityCreateOne:{
			input:{
				type:"InputDeliveryListPointEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityUpdateOne:{
			input:{
				type:"InputDeliveryListPointEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityUpdateMany:{
			input:{
				type:"InputDeliveryListPointEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListPointEntityDeleteOne:{
			input:{
				type:"InputDeleteDeliveryListPointEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityCreateOne:{
			input:{
				type:"InputDeliveryListSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityUpdateOne:{
			input:{
				type:"InputDeliveryListSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityUpdateMany:{
			input:{
				type:"InputDeliveryListSignatureEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListSignatureEntityDeleteOne:{
			input:{
				type:"InputDeleteDeliveryListSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityCreateOne:{
			input:{
				type:"InputDeliveryListEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityUpdateOne:{
			input:{
				type:"InputDeliveryListEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityUpdateMany:{
			input:{
				type:"InputDeliveryListEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DeliveryListEntityDeleteOne:{
			input:{
				type:"InputDeleteDeliveryListEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityCreateOne:{
			input:{
				type:"InputSupplyInspectionReportAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityUpdateOne:{
			input:{
				type:"InputSupplyInspectionReportAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityUpdateMany:{
			input:{
				type:"InputSupplyInspectionReportAnswerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportAnswerEntityDeleteOne:{
			input:{
				type:"InputDeleteSupplyInspectionReportAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityCreateOne:{
			input:{
				type:"InputSupplyInspectionReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityUpdateOne:{
			input:{
				type:"InputSupplyInspectionReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityUpdateMany:{
			input:{
				type:"InputSupplyInspectionReportEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionReportEntityDeleteOne:{
			input:{
				type:"InputDeleteSupplyInspectionReportEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityCreateOne:{
			input:{
				type:"InputDepartmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityUpdateOne:{
			input:{
				type:"InputDepartmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityUpdateMany:{
			input:{
				type:"InputDepartmentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DepartmentEntityDeleteOne:{
			input:{
				type:"InputDeleteDepartmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityCreateOne:{
			input:{
				type:"InputDrawOrderDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityUpdateOne:{
			input:{
				type:"InputDrawOrderDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityUpdateMany:{
			input:{
				type:"InputDrawOrderDescriptionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		DrawOrderDescriptionEntityDeleteOne:{
			input:{
				type:"InputDeleteDrawOrderDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityCreateOne:{
			input:{
				type:"InputGreetingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityUpdateOne:{
			input:{
				type:"InputGreetingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityUpdateMany:{
			input:{
				type:"InputGreetingEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		GreetingEntityDeleteOne:{
			input:{
				type:"InputDeleteGreetingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityCreateOne:{
			input:{
				type:"InputHourDeclarationTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityUpdateOne:{
			input:{
				type:"InputHourDeclarationTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityUpdateMany:{
			input:{
				type:"InputHourDeclarationTypeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationTypeEntityDeleteOne:{
			input:{
				type:"InputDeleteHourDeclarationTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityCreateOne:{
			input:{
				type:"InputHourDeclarationActivityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityUpdateOne:{
			input:{
				type:"InputHourDeclarationActivityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityUpdateMany:{
			input:{
				type:"InputHourDeclarationActivityEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationActivityEntityDeleteOne:{
			input:{
				type:"InputDeleteHourDeclarationActivityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityCreateOne:{
			input:{
				type:"InputHourDeclarationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityUpdateOne:{
			input:{
				type:"InputHourDeclarationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityUpdateMany:{
			input:{
				type:"InputHourDeclarationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationEntityDeleteOne:{
			input:{
				type:"InputDeleteHourDeclarationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityCreateOne:{
			input:{
				type:"InputHourDeclarationApprovalRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityUpdateOne:{
			input:{
				type:"InputHourDeclarationApprovalRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityUpdateMany:{
			input:{
				type:"InputHourDeclarationApprovalRequestEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		HourDeclarationApprovalRequestEntityDeleteOne:{
			input:{
				type:"InputDeleteHourDeclarationApprovalRequestEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityCreateOne:{
			input:{
				type:"InputInstallationReportCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityUpdateOne:{
			input:{
				type:"InputInstallationReportCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityUpdateMany:{
			input:{
				type:"InputInstallationReportCommentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportCommentEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityCreateOne:{
			input:{
				type:"InputInstallationReportDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityUpdateOne:{
			input:{
				type:"InputInstallationReportDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityUpdateMany:{
			input:{
				type:"InputInstallationReportDescriptionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportDescriptionEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportDescriptionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityCreateOne:{
			input:{
				type:"InputInstallationReportFinalEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityUpdateOne:{
			input:{
				type:"InputInstallationReportFinalEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityUpdateMany:{
			input:{
				type:"InputInstallationReportFinalEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportFinalEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportFinalEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityCreateOne:{
			input:{
				type:"InputInstallationReportMeasurementTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityUpdateOne:{
			input:{
				type:"InputInstallationReportMeasurementTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityUpdateMany:{
			input:{
				type:"InputInstallationReportMeasurementTemplateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementTemplateEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportMeasurementTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityCreateOne:{
			input:{
				type:"InputInstallationReportMeasurementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityUpdateOne:{
			input:{
				type:"InputInstallationReportMeasurementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityUpdateMany:{
			input:{
				type:"InputInstallationReportMeasurementEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportMeasurementEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportMeasurementEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityCreateOne:{
			input:{
				type:"InputInstallationReportQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityUpdateOne:{
			input:{
				type:"InputInstallationReportQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityUpdateMany:{
			input:{
				type:"InputInstallationReportQuestionGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityCreateOne:{
			input:{
				type:"InputInstallationReportQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityUpdateOne:{
			input:{
				type:"InputInstallationReportQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityUpdateMany:{
			input:{
				type:"InputInstallationReportQuestionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityCreateOne:{
			input:{
				type:"InputInstallationReportQuestionAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityUpdateOne:{
			input:{
				type:"InputInstallationReportQuestionAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityUpdateMany:{
			input:{
				type:"InputInstallationReportQuestionAnswerEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InstallationReportQuestionAnswerEntityDeleteOne:{
			input:{
				type:"InputDeleteInstallationReportQuestionAnswerEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityCreateOne:{
			input:{
				type:"InputLegacyProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityUpdateOne:{
			input:{
				type:"InputLegacyProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityUpdateMany:{
			input:{
				type:"InputLegacyProjectEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		LegacyProjectEntityDeleteOne:{
			input:{
				type:"InputDeleteLegacyProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityCreateOne:{
			input:{
				type:"InputPicklistCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityUpdateOne:{
			input:{
				type:"InputPicklistCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityUpdateMany:{
			input:{
				type:"InputPicklistCommentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistCommentEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistCommentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityCreateOne:{
			input:{
				type:"InputPicklistElementStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityUpdateOne:{
			input:{
				type:"InputPicklistElementStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityUpdateMany:{
			input:{
				type:"InputPicklistElementStateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PicklistElementStateEntityDeleteOne:{
			input:{
				type:"InputDeletePicklistElementStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityCreateOne:{
			input:{
				type:"InputPlanningProjectItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityUpdateOne:{
			input:{
				type:"InputPlanningProjectItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityUpdateMany:{
			input:{
				type:"InputPlanningProjectItemEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		PlanningProjectItemEntityDeleteOne:{
			input:{
				type:"InputDeletePlanningProjectItemEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityCreateOne:{
			input:{
				type:"InputProjectCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityUpdateOne:{
			input:{
				type:"InputProjectCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityUpdateMany:{
			input:{
				type:"InputProjectCargoStateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectCargoStateEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectCargoStateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityCreateOne:{
			input:{
				type:"InputProjectPaymentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityUpdateOne:{
			input:{
				type:"InputProjectPaymentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityUpdateMany:{
			input:{
				type:"InputProjectPaymentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ProjectPaymentEntityDeleteOne:{
			input:{
				type:"InputDeleteProjectPaymentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityCreateOne:{
			input:{
				type:"InputQualityAssuranceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityUpdateOne:{
			input:{
				type:"InputQualityAssuranceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityUpdateMany:{
			input:{
				type:"InputQualityAssuranceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		QualityAssuranceEntityDeleteOne:{
			input:{
				type:"InputDeleteQualityAssuranceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityCreateOne:{
			input:{
				type:"InputServiceMapColorEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityUpdateOne:{
			input:{
				type:"InputServiceMapColorEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityUpdateMany:{
			input:{
				type:"InputServiceMapColorEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServiceMapColorEntityDeleteOne:{
			input:{
				type:"InputDeleteServiceMapColorEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityCreateOne:{
			input:{
				type:"InputServicePlanningStageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityUpdateOne:{
			input:{
				type:"InputServicePlanningStageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityUpdateMany:{
			input:{
				type:"InputServicePlanningStageEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningStageEntityDeleteOne:{
			input:{
				type:"InputDeleteServicePlanningStageEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityCreateOne:{
			input:{
				type:"InputServicePlanningEventEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityUpdateOne:{
			input:{
				type:"InputServicePlanningEventEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityUpdateMany:{
			input:{
				type:"InputServicePlanningEventEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningEventEntityDeleteOne:{
			input:{
				type:"InputDeleteServicePlanningEventEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityCreateOne:{
			input:{
				type:"InputServicePlanningAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityUpdateOne:{
			input:{
				type:"InputServicePlanningAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityUpdateMany:{
			input:{
				type:"InputServicePlanningAssignmentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServicePlanningAssignmentEntityDeleteOne:{
			input:{
				type:"InputDeleteServicePlanningAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityCreateOne:{
			input:{
				type:"InputServicePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityUpdateOne:{
			input:{
				type:"InputServicePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityUpdateMany:{
			input:{
				type:"InputServicePresetEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		ServicePresetEntityDeleteOne:{
			input:{
				type:"InputDeleteServicePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityCreateOne:{
			input:{
				type:"InputSupplierDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityUpdateOne:{
			input:{
				type:"InputSupplierDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityUpdateMany:{
			input:{
				type:"InputSupplierDocumentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SupplierDocumentEntityDeleteOne:{
			input:{
				type:"InputDeleteSupplierDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityCreateOne:{
			input:{
				type:"InputSupplyInspectionQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityUpdateOne:{
			input:{
				type:"InputSupplyInspectionQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityUpdateMany:{
			input:{
				type:"InputSupplyInspectionQuestionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionEntityDeleteOne:{
			input:{
				type:"InputDeleteSupplyInspectionQuestionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityCreateOne:{
			input:{
				type:"InputSupplyInspectionQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityUpdateOne:{
			input:{
				type:"InputSupplyInspectionQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityUpdateMany:{
			input:{
				type:"InputSupplyInspectionQuestionGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		SupplyInspectionQuestionGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteSupplyInspectionQuestionGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityCreateOne:{
			input:{
				type:"InputUserPasswordResetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityUpdateOne:{
			input:{
				type:"InputUserPasswordResetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityUpdateMany:{
			input:{
				type:"InputUserPasswordResetEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		UserPasswordResetEntityDeleteOne:{
			input:{
				type:"InputDeleteUserPasswordResetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityCreateOne:{
			input:{
				type:"InputYearPlanningLineEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityUpdateOne:{
			input:{
				type:"InputYearPlanningLineEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityUpdateMany:{
			input:{
				type:"InputYearPlanningLineEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		YearPlanningLineEntityDeleteOne:{
			input:{
				type:"InputDeleteYearPlanningLineEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityCreateOne:{
			input:{
				type:"InputInvoicePaymentBatchEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityUpdateOne:{
			input:{
				type:"InputInvoicePaymentBatchEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityUpdateMany:{
			input:{
				type:"InputInvoicePaymentBatchEntityEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		InvoicePaymentBatchEntityEntityDeleteOne:{
			input:{
				type:"InputDeleteInvoicePaymentBatchEntityEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityCreateOne:{
			input:{
				type:"InputAppPhaseContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityUpdateOne:{
			input:{
				type:"InputAppPhaseContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityUpdateMany:{
			input:{
				type:"InputAppPhaseContactEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseContactEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPhaseContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityCreateOne:{
			input:{
				type:"InputAppPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityUpdateOne:{
			input:{
				type:"InputAppPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityUpdateMany:{
			input:{
				type:"InputAppPhaseEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPhaseEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityCreateOne:{
			input:{
				type:"InputAppPhaseSectionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityUpdateOne:{
			input:{
				type:"InputAppPhaseSectionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityUpdateMany:{
			input:{
				type:"InputAppPhaseSectionEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPhaseSectionEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityCreateOne:{
			input:{
				type:"InputAppNotificationTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityUpdateOne:{
			input:{
				type:"InputAppNotificationTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityUpdateMany:{
			input:{
				type:"InputAppNotificationTemplateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationTemplateEntityDeleteOne:{
			input:{
				type:"InputDeleteAppNotificationTemplateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityCreateOne:{
			input:{
				type:"InputAppPhaseSectionMarkEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityUpdateOne:{
			input:{
				type:"InputAppPhaseSectionMarkEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityUpdateMany:{
			input:{
				type:"InputAppPhaseSectionMarkEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPhaseSectionMarkEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityCreateOne:{
			input:{
				type:"InputAppProjectDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityUpdateOne:{
			input:{
				type:"InputAppProjectDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityUpdateMany:{
			input:{
				type:"InputAppProjectDateEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectDateEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectDateEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityCreateOne:{
			input:{
				type:"InputAppNotificationScheduleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityUpdateOne:{
			input:{
				type:"InputAppNotificationScheduleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityUpdateMany:{
			input:{
				type:"InputAppNotificationScheduleEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleEntityDeleteOne:{
			input:{
				type:"InputDeleteAppNotificationScheduleEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityCreateOne:{
			input:{
				type:"InputAppPhaseSectionMarkValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityUpdateOne:{
			input:{
				type:"InputAppPhaseSectionMarkValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityUpdateMany:{
			input:{
				type:"InputAppPhaseSectionMarkValueEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPhaseSectionMarkValueEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPhaseSectionMarkValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityCreateOne:{
			input:{
				type:"InputAppInviteEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityUpdateOne:{
			input:{
				type:"InputAppInviteEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityUpdateMany:{
			input:{
				type:"InputAppInviteEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppInviteEntityDeleteOne:{
			input:{
				type:"InputDeleteAppInviteEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityCreateOne:{
			input:{
				type:"InputAppLoginEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityUpdateOne:{
			input:{
				type:"InputAppLoginEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityUpdateMany:{
			input:{
				type:"InputAppLoginEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppLoginEntityDeleteOne:{
			input:{
				type:"InputDeleteAppLoginEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityCreateOne:{
			input:{
				type:"InputAppNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityUpdateOne:{
			input:{
				type:"InputAppNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityUpdateMany:{
			input:{
				type:"InputAppNotificationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationEntityDeleteOne:{
			input:{
				type:"InputDeleteAppNotificationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityCreateOne:{
			input:{
				type:"InputAppProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityUpdateOne:{
			input:{
				type:"InputAppProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityUpdateMany:{
			input:{
				type:"InputAppProjectEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityCreateOne:{
			input:{
				type:"InputAppUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityUpdateOne:{
			input:{
				type:"InputAppUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityUpdateMany:{
			input:{
				type:"InputAppUserEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppUserEntityDeleteOne:{
			input:{
				type:"InputDeleteAppUserEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityCreateOne:{
			input:{
				type:"InputAppProjectCostEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityUpdateOne:{
			input:{
				type:"InputAppProjectCostEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityUpdateMany:{
			input:{
				type:"InputAppProjectCostEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectCostEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityCreateOne:{
			input:{
				type:"InputAppProjectContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityUpdateOne:{
			input:{
				type:"InputAppProjectContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityUpdateMany:{
			input:{
				type:"InputAppProjectContactEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectContactEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectContactEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityCreateOne:{
			input:{
				type:"InputAppBuildingWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityUpdateOne:{
			input:{
				type:"InputAppBuildingWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityUpdateMany:{
			input:{
				type:"InputAppBuildingWeekEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppBuildingWeekEntityDeleteOne:{
			input:{
				type:"InputDeleteAppBuildingWeekEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityCreateOne:{
			input:{
				type:"InputAppContentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityUpdateOne:{
			input:{
				type:"InputAppContentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityUpdateMany:{
			input:{
				type:"InputAppContentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppContentEntityDeleteOne:{
			input:{
				type:"InputDeleteAppContentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityCreateOne:{
			input:{
				type:"InputAppConversationMessagePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityUpdateOne:{
			input:{
				type:"InputAppConversationMessagePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityUpdateMany:{
			input:{
				type:"InputAppConversationMessagePresetEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppConversationMessagePresetEntityDeleteOne:{
			input:{
				type:"InputDeleteAppConversationMessagePresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityCreateOne:{
			input:{
				type:"InputAppDocumentSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityUpdateOne:{
			input:{
				type:"InputAppDocumentSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityUpdateMany:{
			input:{
				type:"InputAppDocumentSignatureEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentSignatureEntityDeleteOne:{
			input:{
				type:"InputDeleteAppDocumentSignatureEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityCreateOne:{
			input:{
				type:"InputAppDocumentTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityUpdateOne:{
			input:{
				type:"InputAppDocumentTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityUpdateMany:{
			input:{
				type:"InputAppDocumentTypeEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentTypeEntityDeleteOne:{
			input:{
				type:"InputDeleteAppDocumentTypeEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityCreateOne:{
			input:{
				type:"InputAppDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityUpdateOne:{
			input:{
				type:"InputAppDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityUpdateMany:{
			input:{
				type:"InputAppDocumentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppDocumentEntityDeleteOne:{
			input:{
				type:"InputDeleteAppDocumentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityCreateOne:{
			input:{
				type:"InputAppNotificationScheduleResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityUpdateOne:{
			input:{
				type:"InputAppNotificationScheduleResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityUpdateMany:{
			input:{
				type:"InputAppNotificationScheduleResourceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppNotificationScheduleResourceEntityDeleteOne:{
			input:{
				type:"InputDeleteAppNotificationScheduleResourceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityCreateOne:{
			input:{
				type:"InputAppPlanCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityUpdateOne:{
			input:{
				type:"InputAppPlanCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityUpdateMany:{
			input:{
				type:"InputAppPlanCompetenceEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPlanCompetenceEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityCreateOne:{
			input:{
				type:"InputAppPlanCompetenceSettingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityUpdateOne:{
			input:{
				type:"InputAppPlanCompetenceSettingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityUpdateMany:{
			input:{
				type:"InputAppPlanCompetenceSettingEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppPlanCompetenceSettingEntityDeleteOne:{
			input:{
				type:"InputDeleteAppPlanCompetenceSettingEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityCreateOne:{
			input:{
				type:"InputAppProjectCostPresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityUpdateOne:{
			input:{
				type:"InputAppProjectCostPresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityUpdateMany:{
			input:{
				type:"InputAppProjectCostPresetEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectCostPresetEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectCostPresetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityCreateOne:{
			input:{
				type:"InputAppProjectFieldSheetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityUpdateOne:{
			input:{
				type:"InputAppProjectFieldSheetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityUpdateMany:{
			input:{
				type:"InputAppProjectFieldSheetEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldSheetEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectFieldSheetEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityCreateOne:{
			input:{
				type:"InputAppProjectFieldGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityUpdateOne:{
			input:{
				type:"InputAppProjectFieldGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityUpdateMany:{
			input:{
				type:"InputAppProjectFieldGroupEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectFieldGroupEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityCreateOne:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityUpdateOne:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityUpdateMany:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectFieldGroupAssignmentEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityCreateOne:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityUpdateOne:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityUpdateMany:{
			input:{
				type:"InputAppProjectFieldGroupAssignmentValueEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldGroupAssignmentValueEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectFieldGroupAssignmentValueEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityCreateOne:{
			input:{
				type:"InputAppProjectFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityUpdateOne:{
			input:{
				type:"InputAppProjectFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityUpdateMany:{
			input:{
				type:"InputAppProjectFieldEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppProjectFieldEntityDeleteOne:{
			input:{
				type:"InputDeleteAppProjectFieldEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityCreateOne:{
			input:{
				type:"InputAppServiceCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityUpdateOne:{
			input:{
				type:"InputAppServiceCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityUpdateMany:{
			input:{
				type:"InputAppServiceCategoryEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppServiceCategoryEntityDeleteOne:{
			input:{
				type:"InputDeleteAppServiceCategoryEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityCreateOne:{
			input:{
				type:"InputAppTextEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityUpdateOne:{
			input:{
				type:"InputAppTextEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityUpdateMany:{
			input:{
				type:"InputAppTextEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppTextEntityDeleteOne:{
			input:{
				type:"InputDeleteAppTextEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityCreateOne:{
			input:{
				type:"InputAppRelationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityUpdateOne:{
			input:{
				type:"InputAppRelationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityUpdateMany:{
			input:{
				type:"InputAppRelationEntity",
				array:true,
				arrayRequired:false,
				required:false
			}
		},
		AppRelationEntityDeleteOne:{
			input:{
				type:"InputDeleteAppRelationEntity",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		UploadDocument:{
			id:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			},
			file:{
				type:"Upload",
				array:false,
				arrayRequired:false,
				required:true
			},
			projectId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			trackingId:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		CellaWriteCustomFields:{
			CustomField:{
				type:"CellaCustomField",
				array:true,
				arrayRequired:true,
				required:true
			}
		}
	},
	InputGrantEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		permissionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		roleId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteGrantEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputRoleGrantConfigEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		roleId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		configId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteRoleGrantConfigEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputRoleDocumentGrantEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		roleId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		match:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteRoleDocumentGrantEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputRoleEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteRoleEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserGrantConfigEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		configId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserGrantConfigEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserNotificationSubscriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		endpoint:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys_auth:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys_p256dh:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserNotificationSubscriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserNotificationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		channels:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		readAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserNotificationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserTemporaryAccessTokenEntityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		user:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserTemporaryAccessTokenEntityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		roleId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		companyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		workAsUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		abbreviation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overwriteSignIn:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		passwordHash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stage:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailService:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailDeclaration:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		telephone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSupplier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSalesEmployee:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isServiceMechanic:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isBuyAdvisor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isProjectMentor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isProjectLeader:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		addressStreet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		addressHouseNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		addressCity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		addressZipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastSeenAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistDefinitionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		metacomListTable:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		metacomLinesTable:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		renameFilterName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementTypes:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		hideColumns:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		indeterminateToggles:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistDefinitionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		picklistDefinitionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedByUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHousePartGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isEnabled:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHousePartGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHousePartEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		partId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		housePartGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		supplierId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rootElement:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		releaseTimeMins:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		length:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		capacityFactor:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHousePartEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCargoPhaseEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCargoPhaseEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCargoTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		colorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCargoTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningHumanResourceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		resourceTypeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastNamePrefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lastName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNotProjectLeader:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningHumanResourceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectAssignmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		humanResourceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningCompetencePlacementId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		hours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectAssignmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectCompetencePlacementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningProjectCompetenceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningProjectRequestPlacementId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectCompetencePlacementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectRequestPlacementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningProjectRequestId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectRequestPlacementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectRequestEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningProjectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectRequestEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectCompetenceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningCompetenceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		planningProjectRequestId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectCompetenceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningCompetenceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningCompetenceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCargoStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		cargoId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		housePartGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		divisionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCargoStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCargoEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		phaseId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		cargoTypeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeAt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		requiresPermit:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		routeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		isLocked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCargoEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectHousePartEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		cargoId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		supplierId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partCodeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groupDivisionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		weight:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		length:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		moldId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		wallId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		costId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectHousePartEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistElementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		picklistId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		housePartGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		pickedByUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementGroup:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		quantity:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dimensions:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pickState:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isPicked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		pickedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistElementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDrawOrderTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultHours:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDrawOrderTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHouseModelEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHouseModelEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSalesEmployeeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		firstName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isActive:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSalesEmployeeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDrawerAbsentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		drawerId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		reason:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		hours:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDrawerAbsentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDrawerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isHSB:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isBK:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isIT:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDrawerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDrawOrderEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		drawOrderTypeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		houseModelId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		salesEmployeeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		drawerId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		memo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		memoLong:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		plannedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		plannedAtOrder:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		totalHours:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		totalHoursSpend:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentageCompleted:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDrawOrderEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateGroupCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		referenceId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateGroupCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateGroupResponsibleEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateGroupResponsibleEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputLogicFieldEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		fieldId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fieldName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		conditionDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		expression:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		colorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteLogicFieldEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		label:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasResponsible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasComments:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		visibleOnConstructionPlanning:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stackId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateFormat:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		colorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		visibleOnDatePassed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isOptional:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		visibleOnConstructionPlanning:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		phaseSectionMarkId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notificationTemplateId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		drawOrderTypeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSynced:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		hash:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		isActivated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isCompleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSynced:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSyncUpdate:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		send_notification:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputLogicFieldValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		fieldId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		isSyncUpdate:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteLogicFieldValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDateCacheEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDateCacheEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputYearPlanningModificationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		oldWeek:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		newWeek:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteYearPlanningModificationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectCachedValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateValues:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectLeader:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		salesEmployee:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupResponsibles:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		buyAdvisor:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectMentor:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		dateGroupComments:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		logicFieldValues:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectCachedValueEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputTableLayoutCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		taskId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteTableLayoutCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		houseType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		houseShape:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingWeek:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingStreet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingHouseNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingCity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingZipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		deliveryDate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		longitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		latitude:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		concrete:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		foundation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heating:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionProbeWork:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionAdvice:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionDrawingWork:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionSupplier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionLevel:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		constructionUnavailable:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		score:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		topicalDocumentRoot:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		archiveDocumentRoot:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		administrationType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectLeaderId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		salesEmployeeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		buyAdvisorId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectMentorId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		installationReportDate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		installationPerformerName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSyncCronEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		cron:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSyncCronEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputMailSchedulerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		cron:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sourceTable:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mailCC:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteMailSchedulerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectPartnerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		partnerId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isContactAllowed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectPartnerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPartnerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		contactUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePartnerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDocumentMetaEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		mimeType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDocumentMetaEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputEntityAdvancementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		entityType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		entityId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		isCompleted:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteEntityAdvancementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputWorkActionCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteWorkActionCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputWorkActionEntity:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		allowEmptyAdvancement:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		disableImageUpload:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		responsibleDateGroupId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advancementApplyDateId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		entityType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		workActionCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		assignedUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteWorkActionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputEntityImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		entityType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		entityId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteEntityImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCustomerQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		telephone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		space:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		emailAddress:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalComment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageIds:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		completedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCustomerQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		auditCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditQuestionCostCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		auditQuestionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		costCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditQuestionCostCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputCostCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		condition:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isChecklist:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasImages:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		checklistOrder:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteCostCategoryEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditReportItemImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		auditReportItemId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditReportItemImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditReportItemEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		auditReportId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		auditQuestionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditReportItemEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		costCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		signatureDocumentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		inspectedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		inspectedAtTime:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lockedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAuditChecklistImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		costCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAuditChecklistImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInvoiceAttachmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		metacomOrderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInvoiceAttachmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputEmployeeDocumentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		employeeId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		isInternal:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		comment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteEmployeeDocumentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputWorkActionTemplateGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteWorkActionTemplateGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputWorkActionTemplateEntity:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		allowEmptyAdvancement:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		disableImageUpload:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		responsibleDateGroupId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		advancementApplyDateId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		workActionTemplateGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		workActionCategoryId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		targetDateOffset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteWorkActionTemplateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectCostInfoEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		appProjectCostId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		conceptUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectCostInfoEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputBlockedCalendarWeekEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteBlockedCalendarWeekEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputMaintenanceReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteMaintenanceReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputConfigurationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteConfigurationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServiceMeterEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServiceMeterEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeliveryListMeterEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		meterId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDeliveryListMeterEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServiceSpaceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServiceSpaceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServiceTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		storeInSystem:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isStandard:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServiceTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeliveryListPointImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		pointId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDeliveryListPointImageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServicePriorityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		offsetDays:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		isStandard:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServicePriorityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeliveryListPointEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryCategoryId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryCategoryId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceTypeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceSpaceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceSpaceIds:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		servicePriorityId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		assignedUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceTicketId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDeliveryListPointEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeliveryListSignatureEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		signDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		signLocation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDeliveryListSignatureEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeliveryListEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		assignedUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		hiredAgent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		keyAmount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		isDeliveredAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		houseDocumentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		isFinal:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isFinalAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDeliveryListEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSupplyInspectionReportAnswerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		supplyInspectionQuestionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		supplyInspectionReportId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		explanation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageDocumentMetaIds:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSupplyInspectionReportAnswerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSupplyInspectionReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		timestamp:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		departmentId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		executedByUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		signatureDocumentMetaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSupplyInspectionReportEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDepartmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		departmentId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isOrderDepartment:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDepartmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDrawOrderDescriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteDrawOrderDescriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputGreetingEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		html:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteGreetingEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHourDeclarationTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		companyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		administrationType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		kindDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		hasActivities:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isExternal:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHourDeclarationTypeEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHourDeclarationActivityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		companyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		administrationType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHourDeclarationActivityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHourDeclarationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		hourDeclarationTypeId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		hourDeclarationActivityId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		priceAgreementId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		storedDocumentId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		amount:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		baseHours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		savingHours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		overTimeHours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		travelHours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHourDeclarationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputHourDeclarationApprovalRequestEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		period:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteHourDeclarationApprovalRequestEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stepId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		performerName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportDescriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		stepId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportDescriptionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportFinalEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportFinalEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportMeasurementTemplateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		measureTableId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		measureId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		defaultFields:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportMeasurementTemplateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportMeasurementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		measureTableId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		measureId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fields:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportMeasurementEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportQuestionGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stepId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportQuestionGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		questionGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		tooltip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInstallationReportQuestionAnswerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		questionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInstallationReportQuestionAnswerEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputLegacyProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		houseType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		regionId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingStreet:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingCity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		buildingZipCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		deliveryDate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customerEmail:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncSource:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteLegacyProjectEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		picklistDefinitionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistCommentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPicklistElementStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		elementId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		picklistDefinitionId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		pickedByUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePicklistElementStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputPlanningProjectItemEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		dimension:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceIndex:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceStartsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		competenceEndsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		hours:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		humanResourceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		humanResourceNumber:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		humanResourceStartsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		humanResourceEndsAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		comments:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		version:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeletePlanningProjectItemEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectCargoStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectCargoStateEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputProjectPaymentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteProjectPaymentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputQualityAssuranceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteQualityAssuranceEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServiceMapColorEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		days:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServiceMapColorEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServicePlanningStageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		colorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServicePlanningStageEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServicePlanningEventEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		legacyProjectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		stageId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		serviceTicketIds:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		memo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		durationUnit:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		durationPlanned:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		durationSpend:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		isFinalized:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		startDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		endDate:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServicePlanningEventEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServicePlanningAssignmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		eventId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		resourceId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServicePlanningAssignmentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputServicePresetEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryCategoryId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryCategoryId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteServicePresetEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSupplierDocumentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		informedUserId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		informedContactTypes:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		projectId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		documentMetaId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		contextId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSupplierDocumentEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSupplyInspectionQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		supplyInspectionQuestionGroupId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		departmentIds:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSupplyInspectionQuestionEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputSupplyInspectionQuestionGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteSupplyInspectionQuestionGroupEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputUserPasswordResetEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		userId:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		expiresAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteUserPasswordResetEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputYearPlanningLineEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		weeks:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		colorCode:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		visibleOnConstructionPlanning:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteYearPlanningLineEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputInvoicePaymentBatchEntityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		batch:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		document:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		amount:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		amountBank:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		companyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		companyName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relationName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updatedAt:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteInvoicePaymentBatchEntityEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPhaseContactEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		receive_notifications:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPhaseContactEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPhaseEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_visible_transferred:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		start_project_date_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		end_project_date_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		days:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPhaseEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPhaseSectionEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info_content_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPhaseSectionEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppNotificationTemplateEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		subject:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		body:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppNotificationTemplateEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPhaseSectionMarkEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_section_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		document_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		more_info_content_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_template_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_group_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_date_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		responsible:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		percentage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_milestone:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_standard:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		date_prompt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		duration_days:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeline_origin_date_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeline_competence_number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		timeline_origin_diff_days:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPhaseSectionMarkEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectDateEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectDateEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppNotificationScheduleEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_section_mark_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_section_mark_cancel_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_template_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		days_offset:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		time_of_day:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppNotificationScheduleEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPhaseSectionMarkValueEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		phase_section_mark_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		completed_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		send_notification:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		activated:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		completed:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		completed_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPhaseSectionMarkValueEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppInviteEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppInviteEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppLoginEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		relation_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		transferred_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		role_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppLoginEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppNotificationEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifiable_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		notifiable_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		},
		read_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppNotificationEntity:{
		id:{
			type:"ID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		contract_sum:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppUserEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		relation_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		telephone:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		facebook_identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		google_identity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		first_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name_prefix:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		last_name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppUserEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectCostEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		approved_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		declined_user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		parent_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		memo:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		approved_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		declined_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		requested_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectCostEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectContactEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		relation_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectContactEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppBuildingWeekEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		isBlocked:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		syncVersion:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppBuildingWeekEntity:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppContentEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		html:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppContentEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppConversationMessagePresetEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		message:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppConversationMessagePresetEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppDocumentSignatureEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		document_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		payload:{
			type:"JSON",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppDocumentSignatureEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppDocumentTypeEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		document_kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		special_access:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_contact_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppDocumentTypeEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppDocumentEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		user_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		document_type_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		document_kind:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		confidential:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		mime_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		extension:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		storage_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppDocumentEntity:{
		id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppNotificationScheduleResourceEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		notification_schedule_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		schedule_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppNotificationScheduleResourceEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPlanCompetenceEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		index:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPlanCompetenceEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppPlanCompetenceSettingEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		plan_competence_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		content_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_visible:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppPlanCompetenceSettingEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectCostPresetEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectCostPresetEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectFieldSheetEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_contact_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectFieldSheetEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectFieldGroupEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_sheet_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectFieldGroupEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectFieldGroupAssignmentEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_group_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_copy_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		value_type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_required:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		tooltip:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectFieldGroupAssignmentEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectFieldGroupAssignmentValueEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_field_group_assignment_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectFieldGroupAssignmentValueEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppProjectFieldEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		project_id:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppProjectFieldEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppServiceCategoryEntity:{
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		service_category_id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppServiceCategoryEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppTextEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppTextEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputAppRelationEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		is_blacklisted:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sync_version:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		created_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		},
		updated_at:{
			type:"Date",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	InputDeleteAppRelationEntity:{
		id:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Upload: "String",
	CellaCustomField:{
		Origin:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		Entity:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		LineId:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:true
		},
		Code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		SerialNumber:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		Contents:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		GrantEntityFindById:"GrantEntity",
		GrantEntityFindOne:"GrantEntity",
		GrantEntityFindMany:"GrantEntity",
		GrantEntityAggregateGroupCount:"AggregateGroup",
		RoleGrantConfigEntityFindById:"RoleGrantConfigEntity",
		RoleGrantConfigEntityFindOne:"RoleGrantConfigEntity",
		RoleGrantConfigEntityFindMany:"RoleGrantConfigEntity",
		RoleGrantConfigEntityAggregateGroupCount:"AggregateGroup",
		RoleDocumentGrantEntityFindById:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityFindOne:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityFindMany:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityAggregateGroupCount:"AggregateGroup",
		RoleEntityFindById:"RoleEntity",
		RoleEntityFindOne:"RoleEntity",
		RoleEntityFindMany:"RoleEntity",
		RoleEntityAggregateGroupCount:"AggregateGroup",
		UserGrantConfigEntityFindById:"UserGrantConfigEntity",
		UserGrantConfigEntityFindOne:"UserGrantConfigEntity",
		UserGrantConfigEntityFindMany:"UserGrantConfigEntity",
		UserGrantConfigEntityAggregateGroupCount:"AggregateGroup",
		UserNotificationSubscriptionEntityFindById:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityFindOne:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityFindMany:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityAggregateGroupCount:"AggregateGroup",
		UserNotificationEntityFindById:"UserNotificationEntity",
		UserNotificationEntityFindOne:"UserNotificationEntity",
		UserNotificationEntityFindMany:"UserNotificationEntity",
		UserNotificationEntityAggregateGroupCount:"AggregateGroup",
		UserTemporaryAccessTokenEntityEntityFindById:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityFindOne:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityFindMany:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityAggregateGroupCount:"AggregateGroup",
		UserEntityFindById:"UserEntity",
		UserEntityFindOne:"UserEntity",
		UserEntityFindMany:"UserEntity",
		UserEntityAggregateGroupCount:"AggregateGroup",
		PicklistDefinitionEntityFindById:"PicklistDefinitionEntity",
		PicklistDefinitionEntityFindOne:"PicklistDefinitionEntity",
		PicklistDefinitionEntityFindMany:"PicklistDefinitionEntity",
		PicklistDefinitionEntityAggregateGroupCount:"AggregateGroup",
		PicklistStateEntityFindById:"PicklistStateEntity",
		PicklistStateEntityFindOne:"PicklistStateEntity",
		PicklistStateEntityFindMany:"PicklistStateEntity",
		PicklistStateEntityAggregateGroupCount:"AggregateGroup",
		HousePartGroupEntityFindById:"HousePartGroupEntity",
		HousePartGroupEntityFindOne:"HousePartGroupEntity",
		HousePartGroupEntityFindMany:"HousePartGroupEntity",
		HousePartGroupEntityAggregateGroupCount:"AggregateGroup",
		HousePartEntityFindById:"HousePartEntity",
		HousePartEntityFindOne:"HousePartEntity",
		HousePartEntityFindMany:"HousePartEntity",
		HousePartEntityAggregateGroupCount:"AggregateGroup",
		CargoPhaseEntityFindById:"CargoPhaseEntity",
		CargoPhaseEntityFindOne:"CargoPhaseEntity",
		CargoPhaseEntityFindMany:"CargoPhaseEntity",
		CargoPhaseEntityAggregateGroupCount:"AggregateGroup",
		CargoTypeEntityFindById:"CargoTypeEntity",
		CargoTypeEntityFindOne:"CargoTypeEntity",
		CargoTypeEntityFindMany:"CargoTypeEntity",
		CargoTypeEntityAggregateGroupCount:"AggregateGroup",
		PlanningHumanResourceEntityFindById:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityFindOne:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityFindMany:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectAssignmentEntityFindById:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityFindOne:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityFindMany:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectCompetencePlacementEntityFindById:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityFindOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityFindMany:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectRequestPlacementEntityFindById:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityFindOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityFindMany:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectEntityFindById:"PlanningProjectEntity",
		PlanningProjectEntityFindOne:"PlanningProjectEntity",
		PlanningProjectEntityFindMany:"PlanningProjectEntity",
		PlanningProjectEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectRequestEntityFindById:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityFindOne:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityFindMany:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectCompetenceEntityFindById:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityFindOne:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityFindMany:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityAggregateGroupCount:"AggregateGroup",
		PlanningCompetenceEntityFindById:"PlanningCompetenceEntity",
		PlanningCompetenceEntityFindOne:"PlanningCompetenceEntity",
		PlanningCompetenceEntityFindMany:"PlanningCompetenceEntity",
		PlanningCompetenceEntityAggregateGroupCount:"AggregateGroup",
		CargoStateEntityFindById:"CargoStateEntity",
		CargoStateEntityFindOne:"CargoStateEntity",
		CargoStateEntityFindMany:"CargoStateEntity",
		CargoStateEntityAggregateGroupCount:"AggregateGroup",
		CargoEntityFindById:"CargoEntity",
		CargoEntityFindOne:"CargoEntity",
		CargoEntityFindMany:"CargoEntity",
		CargoEntityAggregateGroupCount:"AggregateGroup",
		ProjectHousePartEntityFindById:"ProjectHousePartEntity",
		ProjectHousePartEntityFindOne:"ProjectHousePartEntity",
		ProjectHousePartEntityFindMany:"ProjectHousePartEntity",
		ProjectHousePartEntityAggregateGroupCount:"AggregateGroup",
		PicklistElementEntityFindById:"PicklistElementEntity",
		PicklistElementEntityFindOne:"PicklistElementEntity",
		PicklistElementEntityFindMany:"PicklistElementEntity",
		PicklistElementEntityAggregateGroupCount:"AggregateGroup",
		PicklistEntityFindById:"PicklistEntity",
		PicklistEntityFindOne:"PicklistEntity",
		PicklistEntityFindMany:"PicklistEntity",
		PicklistEntityAggregateGroupCount:"AggregateGroup",
		DrawOrderTypeEntityFindById:"DrawOrderTypeEntity",
		DrawOrderTypeEntityFindOne:"DrawOrderTypeEntity",
		DrawOrderTypeEntityFindMany:"DrawOrderTypeEntity",
		DrawOrderTypeEntityAggregateGroupCount:"AggregateGroup",
		HouseModelEntityFindById:"HouseModelEntity",
		HouseModelEntityFindOne:"HouseModelEntity",
		HouseModelEntityFindMany:"HouseModelEntity",
		HouseModelEntityAggregateGroupCount:"AggregateGroup",
		SalesEmployeeEntityFindById:"SalesEmployeeEntity",
		SalesEmployeeEntityFindOne:"SalesEmployeeEntity",
		SalesEmployeeEntityFindMany:"SalesEmployeeEntity",
		SalesEmployeeEntityAggregateGroupCount:"AggregateGroup",
		DrawerAbsentEntityFindById:"DrawerAbsentEntity",
		DrawerAbsentEntityFindOne:"DrawerAbsentEntity",
		DrawerAbsentEntityFindMany:"DrawerAbsentEntity",
		DrawerAbsentEntityAggregateGroupCount:"AggregateGroup",
		DrawerEntityFindById:"DrawerEntity",
		DrawerEntityFindOne:"DrawerEntity",
		DrawerEntityFindMany:"DrawerEntity",
		DrawerEntityAggregateGroupCount:"AggregateGroup",
		DrawOrderEntityFindById:"DrawOrderEntity",
		DrawOrderEntityFindOne:"DrawOrderEntity",
		DrawOrderEntityFindMany:"DrawOrderEntity",
		DrawOrderEntityAggregateGroupCount:"AggregateGroup",
		DateGroupCommentEntityFindById:"DateGroupCommentEntity",
		DateGroupCommentEntityFindOne:"DateGroupCommentEntity",
		DateGroupCommentEntityFindMany:"DateGroupCommentEntity",
		DateGroupCommentEntityAggregateGroupCount:"AggregateGroup",
		DateGroupResponsibleEntityFindById:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityFindOne:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityFindMany:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityAggregateGroupCount:"AggregateGroup",
		LogicFieldEntityFindById:"LogicFieldEntity",
		LogicFieldEntityFindOne:"LogicFieldEntity",
		LogicFieldEntityFindMany:"LogicFieldEntity",
		LogicFieldEntityAggregateGroupCount:"AggregateGroup",
		DateGroupEntityFindById:"DateGroupEntity",
		DateGroupEntityFindOne:"DateGroupEntity",
		DateGroupEntityFindMany:"DateGroupEntity",
		DateGroupEntityAggregateGroupCount:"AggregateGroup",
		DateEntityFindById:"DateEntity",
		DateEntityFindOne:"DateEntity",
		DateEntityFindMany:"DateEntity",
		DateEntityAggregateGroupCount:"AggregateGroup",
		DateValueEntityFindById:"DateValueEntity",
		DateValueEntityFindOne:"DateValueEntity",
		DateValueEntityFindMany:"DateValueEntity",
		DateValueEntityAggregateGroupCount:"AggregateGroup",
		LogicFieldValueEntityFindById:"LogicFieldValueEntity",
		LogicFieldValueEntityFindOne:"LogicFieldValueEntity",
		LogicFieldValueEntityFindMany:"LogicFieldValueEntity",
		LogicFieldValueEntityAggregateGroupCount:"AggregateGroup",
		DateCacheEntityFindById:"DateCacheEntity",
		DateCacheEntityFindOne:"DateCacheEntity",
		DateCacheEntityFindMany:"DateCacheEntity",
		DateCacheEntityAggregateGroupCount:"AggregateGroup",
		YearPlanningModificationEntityFindById:"YearPlanningModificationEntity",
		YearPlanningModificationEntityFindOne:"YearPlanningModificationEntity",
		YearPlanningModificationEntityFindMany:"YearPlanningModificationEntity",
		YearPlanningModificationEntityAggregateGroupCount:"AggregateGroup",
		ProjectCachedValueEntityFindById:"ProjectCachedValueEntity",
		ProjectCachedValueEntityFindOne:"ProjectCachedValueEntity",
		ProjectCachedValueEntityFindMany:"ProjectCachedValueEntity",
		ProjectCachedValueEntityAggregateGroupCount:"AggregateGroup",
		TableLayoutCommentEntityFindById:"TableLayoutCommentEntity",
		TableLayoutCommentEntityFindOne:"TableLayoutCommentEntity",
		TableLayoutCommentEntityFindMany:"TableLayoutCommentEntity",
		TableLayoutCommentEntityAggregateGroupCount:"AggregateGroup",
		ProjectEntityFindById:"ProjectEntity",
		ProjectEntityFindOne:"ProjectEntity",
		ProjectEntityFindMany:"ProjectEntity",
		ProjectEntityAggregateGroupCount:"AggregateGroup",
		SyncCronEntityFindById:"SyncCronEntity",
		SyncCronEntityFindOne:"SyncCronEntity",
		SyncCronEntityFindMany:"SyncCronEntity",
		SyncCronEntityAggregateGroupCount:"AggregateGroup",
		MailSchedulerEntityFindById:"MailSchedulerEntity",
		MailSchedulerEntityFindOne:"MailSchedulerEntity",
		MailSchedulerEntityFindMany:"MailSchedulerEntity",
		MailSchedulerEntityAggregateGroupCount:"AggregateGroup",
		ProjectPartnerEntityFindById:"ProjectPartnerEntity",
		ProjectPartnerEntityFindOne:"ProjectPartnerEntity",
		ProjectPartnerEntityFindMany:"ProjectPartnerEntity",
		ProjectPartnerEntityAggregateGroupCount:"AggregateGroup",
		PartnerEntityFindById:"PartnerEntity",
		PartnerEntityFindOne:"PartnerEntity",
		PartnerEntityFindMany:"PartnerEntity",
		PartnerEntityAggregateGroupCount:"AggregateGroup",
		DocumentMetaEntityFindById:"DocumentMetaEntity",
		DocumentMetaEntityFindOne:"DocumentMetaEntity",
		DocumentMetaEntityFindMany:"DocumentMetaEntity",
		DocumentMetaEntityAggregateGroupCount:"AggregateGroup",
		EntityAdvancementEntityFindById:"EntityAdvancementEntity",
		EntityAdvancementEntityFindOne:"EntityAdvancementEntity",
		EntityAdvancementEntityFindMany:"EntityAdvancementEntity",
		EntityAdvancementEntityAggregateGroupCount:"AggregateGroup",
		WorkActionCategoryEntityFindById:"WorkActionCategoryEntity",
		WorkActionCategoryEntityFindOne:"WorkActionCategoryEntity",
		WorkActionCategoryEntityFindMany:"WorkActionCategoryEntity",
		WorkActionCategoryEntityAggregateGroupCount:"AggregateGroup",
		WorkActionEntityFindById:"WorkActionEntity",
		WorkActionEntityFindOne:"WorkActionEntity",
		WorkActionEntityFindMany:"WorkActionEntity",
		WorkActionEntityAggregateGroupCount:"AggregateGroup",
		EntityImageEntityFindById:"EntityImageEntity",
		EntityImageEntityFindOne:"EntityImageEntity",
		EntityImageEntityFindMany:"EntityImageEntity",
		EntityImageEntityAggregateGroupCount:"AggregateGroup",
		CustomerQuestionEntityFindById:"CustomerQuestionEntity",
		CustomerQuestionEntityFindOne:"CustomerQuestionEntity",
		CustomerQuestionEntityFindMany:"CustomerQuestionEntity",
		CustomerQuestionEntityAggregateGroupCount:"AggregateGroup",
		AuditCategoryEntityFindById:"AuditCategoryEntity",
		AuditCategoryEntityFindOne:"AuditCategoryEntity",
		AuditCategoryEntityFindMany:"AuditCategoryEntity",
		AuditCategoryEntityAggregateGroupCount:"AggregateGroup",
		AuditQuestionEntityFindById:"AuditQuestionEntity",
		AuditQuestionEntityFindOne:"AuditQuestionEntity",
		AuditQuestionEntityFindMany:"AuditQuestionEntity",
		AuditQuestionEntityAggregateGroupCount:"AggregateGroup",
		AuditQuestionCostCategoryEntityFindById:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityFindOne:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityFindMany:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityAggregateGroupCount:"AggregateGroup",
		CostCategoryEntityFindById:"CostCategoryEntity",
		CostCategoryEntityFindOne:"CostCategoryEntity",
		CostCategoryEntityFindMany:"CostCategoryEntity",
		CostCategoryEntityAggregateGroupCount:"AggregateGroup",
		AuditReportItemImageEntityFindById:"AuditReportItemImageEntity",
		AuditReportItemImageEntityFindOne:"AuditReportItemImageEntity",
		AuditReportItemImageEntityFindMany:"AuditReportItemImageEntity",
		AuditReportItemImageEntityAggregateGroupCount:"AggregateGroup",
		AuditReportItemEntityFindById:"AuditReportItemEntity",
		AuditReportItemEntityFindOne:"AuditReportItemEntity",
		AuditReportItemEntityFindMany:"AuditReportItemEntity",
		AuditReportItemEntityAggregateGroupCount:"AggregateGroup",
		AuditReportEntityFindById:"AuditReportEntity",
		AuditReportEntityFindOne:"AuditReportEntity",
		AuditReportEntityFindMany:"AuditReportEntity",
		AuditReportEntityAggregateGroupCount:"AggregateGroup",
		AuditChecklistImageEntityFindById:"AuditChecklistImageEntity",
		AuditChecklistImageEntityFindOne:"AuditChecklistImageEntity",
		AuditChecklistImageEntityFindMany:"AuditChecklistImageEntity",
		AuditChecklistImageEntityAggregateGroupCount:"AggregateGroup",
		InvoiceAttachmentEntityFindById:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityFindOne:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityFindMany:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityAggregateGroupCount:"AggregateGroup",
		EmployeeDocumentEntityFindById:"EmployeeDocumentEntity",
		EmployeeDocumentEntityFindOne:"EmployeeDocumentEntity",
		EmployeeDocumentEntityFindMany:"EmployeeDocumentEntity",
		EmployeeDocumentEntityAggregateGroupCount:"AggregateGroup",
		WorkActionTemplateGroupEntityFindById:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityFindOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityFindMany:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityAggregateGroupCount:"AggregateGroup",
		WorkActionTemplateEntityFindById:"WorkActionTemplateEntity",
		WorkActionTemplateEntityFindOne:"WorkActionTemplateEntity",
		WorkActionTemplateEntityFindMany:"WorkActionTemplateEntity",
		WorkActionTemplateEntityAggregateGroupCount:"AggregateGroup",
		ProjectCostInfoEntityFindById:"ProjectCostInfoEntity",
		ProjectCostInfoEntityFindOne:"ProjectCostInfoEntity",
		ProjectCostInfoEntityFindMany:"ProjectCostInfoEntity",
		ProjectCostInfoEntityAggregateGroupCount:"AggregateGroup",
		BlockedCalendarWeekEntityFindById:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityFindOne:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityFindMany:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityAggregateGroupCount:"AggregateGroup",
		MaintenanceReportEntityFindById:"MaintenanceReportEntity",
		MaintenanceReportEntityFindOne:"MaintenanceReportEntity",
		MaintenanceReportEntityFindMany:"MaintenanceReportEntity",
		MaintenanceReportEntityAggregateGroupCount:"AggregateGroup",
		ConfigurationEntityFindById:"ConfigurationEntity",
		ConfigurationEntityFindOne:"ConfigurationEntity",
		ConfigurationEntityFindMany:"ConfigurationEntity",
		ConfigurationEntityAggregateGroupCount:"AggregateGroup",
		ServiceMeterEntityFindById:"ServiceMeterEntity",
		ServiceMeterEntityFindOne:"ServiceMeterEntity",
		ServiceMeterEntityFindMany:"ServiceMeterEntity",
		ServiceMeterEntityAggregateGroupCount:"AggregateGroup",
		DeliveryListMeterEntityFindById:"DeliveryListMeterEntity",
		DeliveryListMeterEntityFindOne:"DeliveryListMeterEntity",
		DeliveryListMeterEntityFindMany:"DeliveryListMeterEntity",
		DeliveryListMeterEntityAggregateGroupCount:"AggregateGroup",
		ServiceSpaceEntityFindById:"ServiceSpaceEntity",
		ServiceSpaceEntityFindOne:"ServiceSpaceEntity",
		ServiceSpaceEntityFindMany:"ServiceSpaceEntity",
		ServiceSpaceEntityAggregateGroupCount:"AggregateGroup",
		ServiceTypeEntityFindById:"ServiceTypeEntity",
		ServiceTypeEntityFindOne:"ServiceTypeEntity",
		ServiceTypeEntityFindMany:"ServiceTypeEntity",
		ServiceTypeEntityAggregateGroupCount:"AggregateGroup",
		DeliveryListPointImageEntityFindById:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityFindOne:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityFindMany:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityAggregateGroupCount:"AggregateGroup",
		ServicePriorityEntityFindById:"ServicePriorityEntity",
		ServicePriorityEntityFindOne:"ServicePriorityEntity",
		ServicePriorityEntityFindMany:"ServicePriorityEntity",
		ServicePriorityEntityAggregateGroupCount:"AggregateGroup",
		DeliveryListPointEntityFindById:"DeliveryListPointEntity",
		DeliveryListPointEntityFindOne:"DeliveryListPointEntity",
		DeliveryListPointEntityFindMany:"DeliveryListPointEntity",
		DeliveryListPointEntityAggregateGroupCount:"AggregateGroup",
		DeliveryListSignatureEntityFindById:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityFindOne:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityFindMany:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityAggregateGroupCount:"AggregateGroup",
		DeliveryListEntityFindById:"DeliveryListEntity",
		DeliveryListEntityFindOne:"DeliveryListEntity",
		DeliveryListEntityFindMany:"DeliveryListEntity",
		DeliveryListEntityAggregateGroupCount:"AggregateGroup",
		SupplyInspectionReportAnswerEntityFindById:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityFindOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityFindMany:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityAggregateGroupCount:"AggregateGroup",
		SupplyInspectionReportEntityFindById:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityFindOne:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityFindMany:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityAggregateGroupCount:"AggregateGroup",
		DepartmentEntityFindById:"DepartmentEntity",
		DepartmentEntityFindOne:"DepartmentEntity",
		DepartmentEntityFindMany:"DepartmentEntity",
		DepartmentEntityAggregateGroupCount:"AggregateGroup",
		DrawOrderDescriptionEntityFindById:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityFindOne:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityFindMany:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityAggregateGroupCount:"AggregateGroup",
		GreetingEntityFindById:"GreetingEntity",
		GreetingEntityFindOne:"GreetingEntity",
		GreetingEntityFindMany:"GreetingEntity",
		GreetingEntityAggregateGroupCount:"AggregateGroup",
		HourDeclarationTypeEntityFindById:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityFindOne:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityFindMany:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityAggregateGroupCount:"AggregateGroup",
		HourDeclarationActivityEntityFindById:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityFindOne:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityFindMany:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityAggregateGroupCount:"AggregateGroup",
		HourDeclarationEntityFindById:"HourDeclarationEntity",
		HourDeclarationEntityFindOne:"HourDeclarationEntity",
		HourDeclarationEntityFindMany:"HourDeclarationEntity",
		HourDeclarationEntityAggregateGroupCount:"AggregateGroup",
		HourDeclarationApprovalRequestEntityFindById:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityFindOne:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityFindMany:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportCommentEntityFindById:"InstallationReportCommentEntity",
		InstallationReportCommentEntityFindOne:"InstallationReportCommentEntity",
		InstallationReportCommentEntityFindMany:"InstallationReportCommentEntity",
		InstallationReportCommentEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportDescriptionEntityFindById:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityFindOne:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityFindMany:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportFinalEntityFindById:"InstallationReportFinalEntity",
		InstallationReportFinalEntityFindOne:"InstallationReportFinalEntity",
		InstallationReportFinalEntityFindMany:"InstallationReportFinalEntity",
		InstallationReportFinalEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportMeasurementTemplateEntityFindById:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityFindOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityFindMany:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportMeasurementEntityFindById:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityFindOne:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityFindMany:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportQuestionGroupEntityFindById:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityFindOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityFindMany:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportQuestionEntityFindById:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityFindOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityFindMany:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityAggregateGroupCount:"AggregateGroup",
		InstallationReportQuestionAnswerEntityFindById:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityFindOne:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityFindMany:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityAggregateGroupCount:"AggregateGroup",
		LegacyProjectEntityFindById:"LegacyProjectEntity",
		LegacyProjectEntityFindOne:"LegacyProjectEntity",
		LegacyProjectEntityFindMany:"LegacyProjectEntity",
		LegacyProjectEntityAggregateGroupCount:"AggregateGroup",
		PicklistCommentEntityFindById:"PicklistCommentEntity",
		PicklistCommentEntityFindOne:"PicklistCommentEntity",
		PicklistCommentEntityFindMany:"PicklistCommentEntity",
		PicklistCommentEntityAggregateGroupCount:"AggregateGroup",
		PicklistElementStateEntityFindById:"PicklistElementStateEntity",
		PicklistElementStateEntityFindOne:"PicklistElementStateEntity",
		PicklistElementStateEntityFindMany:"PicklistElementStateEntity",
		PicklistElementStateEntityAggregateGroupCount:"AggregateGroup",
		PlanningProjectItemEntityFindById:"PlanningProjectItemEntity",
		PlanningProjectItemEntityFindOne:"PlanningProjectItemEntity",
		PlanningProjectItemEntityFindMany:"PlanningProjectItemEntity",
		PlanningProjectItemEntityAggregateGroupCount:"AggregateGroup",
		ProjectCargoStateEntityFindById:"ProjectCargoStateEntity",
		ProjectCargoStateEntityFindOne:"ProjectCargoStateEntity",
		ProjectCargoStateEntityFindMany:"ProjectCargoStateEntity",
		ProjectCargoStateEntityAggregateGroupCount:"AggregateGroup",
		ProjectPaymentEntityFindById:"ProjectPaymentEntity",
		ProjectPaymentEntityFindOne:"ProjectPaymentEntity",
		ProjectPaymentEntityFindMany:"ProjectPaymentEntity",
		ProjectPaymentEntityAggregateGroupCount:"AggregateGroup",
		QualityAssuranceEntityFindById:"QualityAssuranceEntity",
		QualityAssuranceEntityFindOne:"QualityAssuranceEntity",
		QualityAssuranceEntityFindMany:"QualityAssuranceEntity",
		QualityAssuranceEntityAggregateGroupCount:"AggregateGroup",
		ServiceMapColorEntityFindById:"ServiceMapColorEntity",
		ServiceMapColorEntityFindOne:"ServiceMapColorEntity",
		ServiceMapColorEntityFindMany:"ServiceMapColorEntity",
		ServiceMapColorEntityAggregateGroupCount:"AggregateGroup",
		ServicePlanningStageEntityFindById:"ServicePlanningStageEntity",
		ServicePlanningStageEntityFindOne:"ServicePlanningStageEntity",
		ServicePlanningStageEntityFindMany:"ServicePlanningStageEntity",
		ServicePlanningStageEntityAggregateGroupCount:"AggregateGroup",
		ServicePlanningEventEntityFindById:"ServicePlanningEventEntity",
		ServicePlanningEventEntityFindOne:"ServicePlanningEventEntity",
		ServicePlanningEventEntityFindMany:"ServicePlanningEventEntity",
		ServicePlanningEventEntityAggregateGroupCount:"AggregateGroup",
		ServicePlanningAssignmentEntityFindById:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityFindOne:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityFindMany:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityAggregateGroupCount:"AggregateGroup",
		ServicePresetEntityFindById:"ServicePresetEntity",
		ServicePresetEntityFindOne:"ServicePresetEntity",
		ServicePresetEntityFindMany:"ServicePresetEntity",
		ServicePresetEntityAggregateGroupCount:"AggregateGroup",
		SupplierDocumentEntityFindById:"SupplierDocumentEntity",
		SupplierDocumentEntityFindOne:"SupplierDocumentEntity",
		SupplierDocumentEntityFindMany:"SupplierDocumentEntity",
		SupplierDocumentEntityAggregateGroupCount:"AggregateGroup",
		SupplyInspectionQuestionEntityFindById:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityFindOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityFindMany:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityAggregateGroupCount:"AggregateGroup",
		SupplyInspectionQuestionGroupEntityFindById:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityFindOne:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityFindMany:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityAggregateGroupCount:"AggregateGroup",
		UserPasswordResetEntityFindById:"UserPasswordResetEntity",
		UserPasswordResetEntityFindOne:"UserPasswordResetEntity",
		UserPasswordResetEntityFindMany:"UserPasswordResetEntity",
		UserPasswordResetEntityAggregateGroupCount:"AggregateGroup",
		YearPlanningLineEntityFindById:"YearPlanningLineEntity",
		YearPlanningLineEntityFindOne:"YearPlanningLineEntity",
		YearPlanningLineEntityFindMany:"YearPlanningLineEntity",
		YearPlanningLineEntityAggregateGroupCount:"AggregateGroup",
		InvoicePaymentBatchEntityEntityFindById:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityFindOne:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityFindMany:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityAggregateGroupCount:"AggregateGroup",
		AppPhaseContactEntityFindById:"AppPhaseContactEntity",
		AppPhaseContactEntityFindOne:"AppPhaseContactEntity",
		AppPhaseContactEntityFindMany:"AppPhaseContactEntity",
		AppPhaseContactEntityAggregateGroupCount:"AggregateGroup",
		AppPhaseEntityFindById:"AppPhaseEntity",
		AppPhaseEntityFindOne:"AppPhaseEntity",
		AppPhaseEntityFindMany:"AppPhaseEntity",
		AppPhaseEntityAggregateGroupCount:"AggregateGroup",
		AppPhaseSectionEntityFindById:"AppPhaseSectionEntity",
		AppPhaseSectionEntityFindOne:"AppPhaseSectionEntity",
		AppPhaseSectionEntityFindMany:"AppPhaseSectionEntity",
		AppPhaseSectionEntityAggregateGroupCount:"AggregateGroup",
		AppNotificationTemplateEntityFindById:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityFindOne:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityFindMany:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityAggregateGroupCount:"AggregateGroup",
		AppPhaseSectionMarkEntityFindById:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityFindOne:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityFindMany:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityAggregateGroupCount:"AggregateGroup",
		AppProjectDateEntityFindById:"AppProjectDateEntity",
		AppProjectDateEntityFindOne:"AppProjectDateEntity",
		AppProjectDateEntityFindMany:"AppProjectDateEntity",
		AppProjectDateEntityAggregateGroupCount:"AggregateGroup",
		AppNotificationScheduleEntityFindById:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityFindOne:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityFindMany:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityAggregateGroupCount:"AggregateGroup",
		AppPhaseSectionMarkValueEntityFindById:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityFindOne:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityFindMany:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityAggregateGroupCount:"AggregateGroup",
		AppInviteEntityFindById:"AppInviteEntity",
		AppInviteEntityFindOne:"AppInviteEntity",
		AppInviteEntityFindMany:"AppInviteEntity",
		AppInviteEntityAggregateGroupCount:"AggregateGroup",
		AppLoginEntityFindById:"AppLoginEntity",
		AppLoginEntityFindOne:"AppLoginEntity",
		AppLoginEntityFindMany:"AppLoginEntity",
		AppLoginEntityAggregateGroupCount:"AggregateGroup",
		AppNotificationEntityFindById:"AppNotificationEntity",
		AppNotificationEntityFindOne:"AppNotificationEntity",
		AppNotificationEntityFindMany:"AppNotificationEntity",
		AppNotificationEntityAggregateGroupCount:"AggregateGroup",
		AppProjectEntityFindById:"AppProjectEntity",
		AppProjectEntityFindOne:"AppProjectEntity",
		AppProjectEntityFindMany:"AppProjectEntity",
		AppProjectEntityAggregateGroupCount:"AggregateGroup",
		AppUserEntityFindById:"AppUserEntity",
		AppUserEntityFindOne:"AppUserEntity",
		AppUserEntityFindMany:"AppUserEntity",
		AppUserEntityAggregateGroupCount:"AggregateGroup",
		AppProjectCostEntityFindById:"AppProjectCostEntity",
		AppProjectCostEntityFindOne:"AppProjectCostEntity",
		AppProjectCostEntityFindMany:"AppProjectCostEntity",
		AppProjectCostEntityAggregateGroupCount:"AggregateGroup",
		AppProjectContactEntityFindById:"AppProjectContactEntity",
		AppProjectContactEntityFindOne:"AppProjectContactEntity",
		AppProjectContactEntityFindMany:"AppProjectContactEntity",
		AppProjectContactEntityAggregateGroupCount:"AggregateGroup",
		AppBuildingWeekEntityFindById:"AppBuildingWeekEntity",
		AppBuildingWeekEntityFindOne:"AppBuildingWeekEntity",
		AppBuildingWeekEntityFindMany:"AppBuildingWeekEntity",
		AppBuildingWeekEntityAggregateGroupCount:"AggregateGroup",
		AppContentEntityFindById:"AppContentEntity",
		AppContentEntityFindOne:"AppContentEntity",
		AppContentEntityFindMany:"AppContentEntity",
		AppContentEntityAggregateGroupCount:"AggregateGroup",
		AppConversationMessagePresetEntityFindById:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityFindOne:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityFindMany:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityAggregateGroupCount:"AggregateGroup",
		AppDocumentSignatureEntityFindById:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityFindOne:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityFindMany:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityAggregateGroupCount:"AggregateGroup",
		AppDocumentTypeEntityFindById:"AppDocumentTypeEntity",
		AppDocumentTypeEntityFindOne:"AppDocumentTypeEntity",
		AppDocumentTypeEntityFindMany:"AppDocumentTypeEntity",
		AppDocumentTypeEntityAggregateGroupCount:"AggregateGroup",
		AppDocumentEntityFindById:"AppDocumentEntity",
		AppDocumentEntityFindOne:"AppDocumentEntity",
		AppDocumentEntityFindMany:"AppDocumentEntity",
		AppDocumentEntityAggregateGroupCount:"AggregateGroup",
		AppNotificationScheduleResourceEntityFindById:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityFindOne:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityFindMany:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityAggregateGroupCount:"AggregateGroup",
		AppPlanCompetenceEntityFindById:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityFindOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityFindMany:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityAggregateGroupCount:"AggregateGroup",
		AppPlanCompetenceSettingEntityFindById:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityFindOne:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityFindMany:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityAggregateGroupCount:"AggregateGroup",
		AppProjectCostPresetEntityFindById:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityFindOne:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityFindMany:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityAggregateGroupCount:"AggregateGroup",
		AppProjectFieldSheetEntityFindById:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityFindOne:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityFindMany:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityAggregateGroupCount:"AggregateGroup",
		AppProjectFieldGroupEntityFindById:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityFindOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityFindMany:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityAggregateGroupCount:"AggregateGroup",
		AppProjectFieldGroupAssignmentEntityFindById:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityFindOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityFindMany:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityAggregateGroupCount:"AggregateGroup",
		AppProjectFieldGroupAssignmentValueEntityFindById:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityFindOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityFindMany:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityAggregateGroupCount:"AggregateGroup",
		AppProjectFieldEntityFindById:"AppProjectFieldEntity",
		AppProjectFieldEntityFindOne:"AppProjectFieldEntity",
		AppProjectFieldEntityFindMany:"AppProjectFieldEntity",
		AppProjectFieldEntityAggregateGroupCount:"AggregateGroup",
		AppServiceCategoryEntityFindById:"AppServiceCategoryEntity",
		AppServiceCategoryEntityFindOne:"AppServiceCategoryEntity",
		AppServiceCategoryEntityFindMany:"AppServiceCategoryEntity",
		AppServiceCategoryEntityAggregateGroupCount:"AggregateGroup",
		AppTextEntityFindById:"AppTextEntity",
		AppTextEntityFindOne:"AppTextEntity",
		AppTextEntityFindMany:"AppTextEntity",
		AppTextEntityAggregateGroupCount:"AggregateGroup",
		AppRelationEntityFindById:"AppRelationEntity",
		AppRelationEntityFindOne:"AppRelationEntity",
		AppRelationEntityFindMany:"AppRelationEntity",
		AppRelationEntityAggregateGroupCount:"AggregateGroup",
		bedrijvenMtcEntityFindMany:"bedrijvenMtcEntity"
	},
	GrantEntity:{
		id:"ID",
		permissionId:"String",
		roleId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		role:"RoleEntity"
	},
	RoleEntity:{
		id:"ID",
		name:"String",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date",
		grants:"GrantEntity",
		grantConfigs:"RoleGrantConfigEntity",
		documentGrants:"RoleDocumentGrantEntity"
	},
	RoleGrantConfigEntity:{
		id:"ID",
		roleId:"ID",
		configId:"String",
		value:"String",
		createdAt:"Date",
		updatedAt:"Date",
		role:"RoleEntity"
	},
	RoleDocumentGrantEntity:{
		id:"ID",
		roleId:"ID",
		match:"String",
		createdAt:"Date",
		updatedAt:"Date",
		role:"RoleEntity"
	},
	AggregateGroup:{
		key:"String",
		count:"Int"
	},
	UserGrantConfigEntity:{
		id:"ID",
		userId:"ID",
		configId:"String",
		value:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity"
	},
	UserEntity:{
		id:"ID",
		roleId:"ID",
		companyId:"String",
		workAsUserId:"ID",
		planningUserId:"ID",
		abbreviation:"String",
		overwriteSignIn:"Boolean",
		identity:"String",
		password:"String",
		passwordHash:"String",
		name:"String",
		stage:"String",
		email:"String",
		emailService:"String",
		emailDeclaration:"String",
		telephone:"String",
		isSupplier:"String",
		isSalesEmployee:"String",
		isServiceMechanic:"String",
		isBuyAdvisor:"String",
		isProjectMentor:"String",
		isProjectLeader:"String",
		addressStreet:"String",
		addressHouseNumber:"String",
		addressCity:"String",
		addressZipCode:"String",
		createdAt:"Date",
		updatedAt:"Date",
		lastSeenAt:"Date",
		workAsUser:"UserEntity",
		planningUser:"UserEntity",
		role:"RoleEntity",
		temporaryAccessTokens:"UserTemporaryAccessTokenEntityEntity",
		grantConfigs:"UserGrantConfigEntity",
		notificationSubscriptions:"UserNotificationSubscriptionEntity",
		userNotifications:"UserNotificationEntity"
	},
	UserTemporaryAccessTokenEntityEntity:{
		id:"ID",
		value:"String",
		createdAt:"Date",
		user:"UserEntity"
	},
	UserNotificationSubscriptionEntity:{
		id:"ID",
		userId:"ID",
		endpoint:"String",
		keys_auth:"String",
		keys_p256dh:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity"
	},
	UserNotificationEntity:{
		id:"ID",
		userId:"ID",
		subject:"String",
		content:"String",
		url:"String",
		channels:"JSON",
		readAt:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity"
	},
	PicklistDefinitionEntity:{
		id:"ID",
		name:"String",
		icon:"String",
		metacomListTable:"String",
		metacomLinesTable:"String",
		renameFilterName:"String",
		competenceNumber:"String",
		elementTypes:"JSON",
		hideColumns:"JSON",
		indeterminateToggles:"Boolean",
		dateSource:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	PicklistStateEntity:{
		id:"ID",
		projectId:"ID",
		picklistDefinitionId:"ID",
		completedByUserId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		picklistDefinition:"PicklistDefinitionEntity",
		completedByUser:"UserEntity"
	},
	ProjectEntity:{
		id:"ID",
		description:"String",
		userId:"String",
		region:"String",
		regionId:"String",
		houseType:"String",
		houseShape:"String",
		condition:"String",
		buildingWeek:"String",
		buildingType:"String",
		buildingStreet:"String",
		buildingHouseNumber:"String",
		buildingCity:"String",
		buildingZipCode:"String",
		deliveryDate:"String",
		longitude:"String",
		latitude:"String",
		concrete:"String",
		customerEmail:"String",
		customerName:"String",
		foundation:"String",
		heating:"String",
		constructionProbeWork:"String",
		constructionAdvice:"String",
		constructionDrawingWork:"String",
		constructionSupplier:"String",
		constructionLevel:"String",
		constructionUnavailable:"String",
		score:"Int",
		topicalDocumentRoot:"String",
		archiveDocumentRoot:"String",
		administrationType:"String",
		projectLeaderId:"ID",
		salesEmployeeId:"ID",
		buyAdvisorId:"ID",
		projectMentorId:"ID",
		installationReportDate:"String",
		installationPerformerName:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		projectLeader:"UserEntity",
		salesEmployee:"UserEntity",
		buyAdvisor:"UserEntity",
		projectMentor:"UserEntity",
		picklists:"PicklistEntity",
		picklistStates:"PicklistStateEntity",
		projectHouseParts:"ProjectHousePartEntity",
		tableLayoutComments:"TableLayoutCommentEntity",
		drawOrders:"DrawOrderEntity",
		logicFieldValues:"LogicFieldValueEntity",
		dateValues:"DateValueEntity",
		dateGroupComments:"DateGroupCommentEntity",
		dateGroupResponsibles:"DateGroupResponsibleEntity",
		cachedValue:"ProjectCachedValueEntity",
		dateCache:"DateCacheEntity",
		modifications:"YearPlanningModificationEntity"
	},
	PicklistEntity:{
		id:"ID",
		projectId:"ID",
		state:"String",
		note:"String",
		createdAt:"Date",
		updatedAt:"Date",
		syncSource:"String",
		syncVersion:"String",
		project:"ProjectEntity",
		elements:"PicklistElementEntity"
	},
	PicklistElementEntity:{
		id:"ID",
		picklistId:"ID",
		housePartGroupId:"ID",
		pickedByUserId:"ID",
		elementId:"String",
		elementType:"String",
		elementGroup:"String",
		elementName:"String",
		description:"String",
		quantity:"Int",
		unit:"String",
		dimensions:"String",
		orderId:"String",
		pickState:"String",
		isPicked:"Boolean",
		pickedAt:"Date",
		syncSource:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date",
		picklist:"PicklistEntity",
		housePartGroup:"HousePartGroupEntity",
		pickedByUser:"UserEntity"
	},
	HousePartGroupEntity:{
		id:"ID",
		name:"String",
		isEnabled:"Boolean",
		unit:"String",
		syncSource:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ProjectHousePartEntity:{
		id:"ID",
		projectId:"ID",
		cargoId:"ID",
		supplierId:"String",
		partId:"String",
		partCodeId:"String",
		groupDivisionId:"String",
		quantity:"String",
		weight:"String",
		unit:"String",
		length:"String",
		width:"String",
		moldId:"String",
		wallId:"String",
		dateId:"String",
		costId:"String",
		syncSource:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date",
		cargo:"CargoEntity",
		project:"ProjectEntity",
		housePart:"HousePartEntity"
	},
	CargoEntity:{
		id:"ID",
		projectId:"ID",
		competenceId:"ID",
		phaseId:"ID",
		cargoTypeId:"ID",
		description:"String",
		timeAt:"String",
		requiresPermit:"Boolean",
		routeId:"String",
		dateAt:"Date",
		isLocked:"Boolean",
		notes:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		competence:"PlanningCompetenceEntity",
		phase:"CargoPhaseEntity",
		cargoType:"CargoTypeEntity",
		projectHouseParts:"ProjectHousePartEntity",
		cargoStates:"CargoStateEntity"
	},
	PlanningCompetenceEntity:{
		id:"ID",
		number:"String",
		color:"String",
		description:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		planningProjectCompetences:"PlanningProjectCompetenceEntity"
	},
	PlanningProjectCompetenceEntity:{
		id:"ID",
		planningCompetenceId:"ID",
		planningProjectRequestId:"ID",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		planningCompetence:"PlanningCompetenceEntity",
		planningProjectRequest:"PlanningProjectRequestEntity",
		planningProjectCompetencePlacements:"PlanningProjectCompetencePlacementEntity"
	},
	PlanningProjectRequestEntity:{
		id:"ID",
		planningProjectId:"ID",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		planningProject:"PlanningProjectEntity",
		planningProjectRequestPlacements:"PlanningProjectRequestPlacementEntity",
		planningProjectCompetences:"PlanningProjectCompetenceEntity"
	},
	PlanningProjectEntity:{
		id:"ID",
		projectId:"ID",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		planningProjectRequests:"PlanningProjectRequestEntity"
	},
	PlanningProjectRequestPlacementEntity:{
		id:"ID",
		planningProjectRequestId:"ID",
		startDate:"Date",
		endDate:"Date",
		status:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		planningProjectRequest:"PlanningProjectRequestEntity",
		planningProjectCompetencePlacements:"PlanningProjectCompetencePlacementEntity"
	},
	PlanningProjectCompetencePlacementEntity:{
		id:"ID",
		planningProjectCompetenceId:"ID",
		planningProjectRequestPlacementId:"ID",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		planningProjectCompetence:"PlanningProjectCompetenceEntity",
		planningProjectRequestPlacement:"PlanningProjectRequestPlacementEntity",
		planningCompetenceAssignments:"PlanningProjectAssignmentEntity"
	},
	PlanningProjectAssignmentEntity:{
		id:"ID",
		humanResourceId:"ID",
		planningCompetencePlacementId:"ID",
		comments:"String",
		startsAt:"Date",
		endsAt:"Date",
		hours:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		humanResource:"PlanningHumanResourceEntity",
		planningCompetencePlacement:"PlanningProjectCompetencePlacementEntity"
	},
	PlanningHumanResourceEntity:{
		id:"ID",
		number:"String",
		resourceTypeId:"String",
		color:"String",
		firstName:"String",
		lastNamePrefix:"String",
		lastName:"String",
		email:"String",
		isNotProjectLeader:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	CargoPhaseEntity:{
		id:"ID",
		projectId:"ID",
		description:"String",
		date:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity"
	},
	CargoTypeEntity:{
		id:"ID",
		description:"String",
		userId:"ID",
		price:"String",
		unit:"String",
		colorCode:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity"
	},
	CargoStateEntity:{
		id:"ID",
		cargoId:"ID",
		housePartGroupId:"ID",
		divisionId:"String",
		value:"String",
		createdAt:"Date",
		updatedAt:"Date",
		cargo:"CargoEntity",
		housePartGroup:"HousePartGroupEntity"
	},
	HousePartEntity:{
		id:"ID",
		partId:"String",
		housePartGroupId:"ID",
		supplierId:"String",
		description:"String",
		rootElement:"String",
		releaseTimeMins:"Int",
		length:"String",
		width:"String",
		capacityFactor:"Float",
		syncSource:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date",
		housePartGroup:"HousePartGroupEntity"
	},
	TableLayoutCommentEntity:{
		id:"ID",
		taskId:"String",
		projectId:"ID",
		userId:"ID",
		comment:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		user:"UserEntity"
	},
	DrawOrderEntity:{
		id:"ID",
		projectId:"ID",
		kind:"String",
		drawOrderTypeId:"ID",
		houseModelId:"ID",
		salesEmployeeId:"ID",
		drawerId:"ID",
		description:"String",
		memo:"String",
		memoLong:"String",
		completedAt:"Date",
		plannedAt:"Date",
		plannedAtOrder:"Int",
		totalHours:"Float",
		totalHoursSpend:"Float",
		percentageCompleted:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		drawOrderType:"DrawOrderTypeEntity",
		houseModel:"HouseModelEntity",
		salesEmployee:"SalesEmployeeEntity",
		drawer:"DrawerEntity"
	},
	DrawOrderTypeEntity:{
		id:"ID",
		name:"String",
		condition:"String",
		defaultHours:"Int",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	HouseModelEntity:{
		id:"ID",
		name:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	SalesEmployeeEntity:{
		id:"ID",
		name:"String",
		firstName:"String",
		condition:"String",
		isActive:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	DrawerEntity:{
		id:"ID",
		userId:"String",
		alias:"String",
		condition:"String",
		isHSB:"String",
		isBK:"String",
		isIT:"String",
		createdAt:"Date",
		updatedAt:"Date",
		drawerAbsents:"DrawerAbsentEntity"
	},
	DrawerAbsentEntity:{
		id:"ID",
		drawerId:"ID",
		reason:"String",
		color:"String",
		date:"Date",
		hours:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		drawer:"DrawerEntity"
	},
	LogicFieldValueEntity:{
		id:"ID",
		fieldId:"String",
		projectId:"ID",
		value:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		isSyncUpdate:"Boolean",
		project:"ProjectEntity"
	},
	DateValueEntity:{
		id:"ID",
		dateId:"ID",
		projectId:"ID",
		userId:"ID",
		value:"JSON",
		isActivated:"Boolean",
		isCompleted:"Boolean",
		isSynced:"Boolean",
		syncSource:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date",
		isSyncUpdate:"Boolean",
		send_notification:"Boolean",
		date:"DateEntity",
		project:"ProjectEntity",
		user:"UserEntity"
	},
	DateEntity:{
		id:"ID",
		dateGroupId:"ID",
		description:"String",
		stackId:"Int",
		iconId:"String",
		dateFormat:"String",
		colorCode:"String",
		orderId:"Int",
		visibleOnDatePassed:"Boolean",
		isOptional:"Boolean",
		visibleOnConstructionPlanning:"Boolean",
		phaseSectionMarkId:"Int",
		notificationTemplateId:"Int",
		drawOrderTypeId:"String",
		isSynced:"Boolean",
		hash:"String",
		createdAt:"Date",
		updatedAt:"Date",
		dateGroup:"DateGroupEntity",
		dateValues:"DateValueEntity"
	},
	DateGroupEntity:{
		id:"ID",
		description:"String",
		label:"String",
		hasResponsible:"Boolean",
		hasComments:"Boolean",
		visibleOnConstructionPlanning:"Boolean",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		dates:"DateEntity",
		logicFields:"LogicFieldEntity",
		comments:"DateGroupCommentEntity",
		responsibles:"DateGroupResponsibleEntity"
	},
	LogicFieldEntity:{
		id:"ID",
		fieldId:"String",
		fieldName:"String",
		dateGroupId:"ID",
		conditionDescription:"String",
		expression:"String",
		iconId:"String",
		colorCode:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		dateGroup:"DateGroupEntity"
	},
	DateGroupCommentEntity:{
		id:"ID",
		dateGroupId:"ID",
		projectId:"ID",
		userId:"ID",
		value:"String",
		referenceId:"String",
		createdAt:"Date",
		updatedAt:"Date",
		dateGroup:"DateGroupEntity",
		project:"ProjectEntity",
		user:"UserEntity"
	},
	DateGroupResponsibleEntity:{
		id:"ID",
		userId:"ID",
		projectId:"ID",
		dateGroupId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		project:"ProjectEntity",
		dateGroup:"DateGroupEntity"
	},
	ProjectCachedValueEntity:{
		id:"ID",
		projectId:"ID",
		dateValues:"JSON",
		projectLeader:"JSON",
		salesEmployee:"JSON",
		dateGroupResponsibles:"JSON",
		buyAdvisor:"JSON",
		projectMentor:"JSON",
		dateGroupComments:"JSON",
		logicFieldValues:"JSON",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity"
	},
	DateCacheEntity:{
		id:"ID",
		projectId:"ID",
		values:"JSON",
		comments:"JSON",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity"
	},
	YearPlanningModificationEntity:{
		id:"ID",
		userId:"ID",
		projectId:"ID",
		oldWeek:"String",
		newWeek:"String",
		notes:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		project:"ProjectEntity"
	},
	SyncCronEntity:{
		id:"ID",
		cron:"String"
	},
	MailSchedulerEntity:{
		id:"ID",
		description:"String",
		cron:"String",
		sourceType:"String",
		sourceTable:"String",
		condition:"String",
		mail:"String",
		mailCC:"String",
		subject:"String",
		template:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ProjectPartnerEntity:{
		id:"ID",
		projectId:"String",
		partnerId:"String",
		mode:"String",
		isContactAllowed:"Boolean",
		createdAt:"Date",
		updatedAt:"Date"
	},
	PartnerEntity:{
		id:"ID",
		name:"String",
		contentId:"Int",
		contactUserId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		contactUser:"UserEntity"
	},
	DocumentMetaEntity:{
		id:"ID",
		description:"String",
		mimeType:"String",
		projectId:"ID",
		trackingId:"String",
		userId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		user:"UserEntity"
	},
	EntityAdvancementEntity:{
		id:"ID",
		entityType:"String",
		entityId:"ID",
		userId:"ID",
		comment:"String",
		date:"Date",
		isCompleted:"Boolean",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		workAction:"WorkActionEntity"
	},
	WorkActionEntity:{
		title:"String",
		description:"String",
		allowEmptyAdvancement:"Boolean",
		disableImageUpload:"Boolean",
		responsibleDateGroupId:"String",
		advancementApplyDateId:"String",
		id:"ID",
		entityType:"String",
		userId:"ID",
		projectId:"ID",
		workActionCategoryId:"ID",
		assignedUserId:"ID",
		date:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		project:"ProjectEntity",
		workActionCategory:"WorkActionCategoryEntity",
		assignedUser:"UserEntity",
		entityAdvancements:"EntityAdvancementEntity",
		entityImages:"EntityImageEntity"
	},
	WorkActionCategoryEntity:{
		id:"ID",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date",
		workActions:"WorkActionEntity"
	},
	EntityImageEntity:{
		id:"ID",
		entityType:"String",
		entityId:"ID",
		userId:"ID",
		documentMetaId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		documentMeta:"DocumentMetaEntity",
		workAction:"WorkActionEntity"
	},
	CustomerQuestionEntity:{
		id:"ID",
		message:"String",
		kind:"String",
		projectId:"String",
		customerName:"String",
		city:"String",
		telephone:"String",
		space:"String",
		emailAddress:"String",
		internalComment:"String",
		imageIds:"JSON",
		completedAt:"Date",
		createdAt:"Date",
		updatedAt:"Date"
	},
	AuditCategoryEntity:{
		id:"ID",
		description:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		auditQuestions:"AuditQuestionEntity"
	},
	AuditQuestionEntity:{
		id:"ID",
		auditCategoryId:"ID",
		description:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		auditCategory:"AuditCategoryEntity",
		auditQuestionCostCategories:"AuditQuestionCostCategoryEntity"
	},
	AuditQuestionCostCategoryEntity:{
		id:"ID",
		auditQuestionId:"ID",
		costCategoryId:"ID",
		auditQuestion:"AuditQuestionEntity",
		costCategory:"CostCategoryEntity"
	},
	CostCategoryEntity:{
		id:"ID",
		description:"String",
		group:"String",
		condition:"String",
		isChecklist:"String",
		hasImages:"String",
		checklistOrder:"String",
		createdAt:"Date",
		updatedAt:"Date",
		auditQuestionCostCategories:"AuditQuestionCostCategoryEntity"
	},
	AuditReportItemImageEntity:{
		id:"ID",
		auditReportItemId:"ID",
		documentMetaId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		auditReportItem:"AuditReportItemEntity",
		documentMeta:"DocumentMetaEntity"
	},
	AuditReportItemEntity:{
		id:"ID",
		userId:"ID",
		auditReportId:"ID",
		auditQuestionId:"ID",
		state:"String",
		comment:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		auditReport:"AuditReportEntity",
		auditQuestion:"AuditQuestionEntity",
		auditReportItemImages:"AuditReportItemImageEntity"
	},
	AuditReportEntity:{
		id:"ID",
		userId:"ID",
		projectId:"ID",
		costCategoryId:"ID",
		signatureDocumentMetaId:"ID",
		comment:"String",
		inspectedAt:"Date",
		inspectedAtTime:"String",
		lockedAt:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		project:"ProjectEntity",
		costCategory:"CostCategoryEntity",
		signatureDocumentMeta:"DocumentMetaEntity",
		auditReportItems:"AuditReportItemEntity"
	},
	AuditChecklistImageEntity:{
		id:"ID",
		userId:"ID",
		costCategoryId:"ID",
		projectId:"ID",
		documentMetaId:"ID",
		comment:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		costCategory:"CostCategoryEntity",
		project:"ProjectEntity",
		documentMeta:"DocumentMetaEntity"
	},
	InvoiceAttachmentEntity:{
		id:"ID",
		metacomOrderId:"String",
		documentMetaId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		documentMeta:"DocumentMetaEntity"
	},
	EmployeeDocumentEntity:{
		id:"ID",
		userId:"ID",
		relationId:"String",
		employeeId:"String",
		documentMetaId:"ID",
		isInternal:"Boolean",
		comment:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		documentMeta:"DocumentMetaEntity"
	},
	WorkActionTemplateGroupEntity:{
		id:"ID",
		name:"String",
		createdAt:"Date",
		updatedAt:"Date",
		workActionTemplates:"WorkActionTemplateEntity"
	},
	WorkActionTemplateEntity:{
		title:"String",
		description:"String",
		allowEmptyAdvancement:"Boolean",
		disableImageUpload:"Boolean",
		responsibleDateGroupId:"String",
		advancementApplyDateId:"String",
		id:"ID",
		workActionTemplateGroupId:"ID",
		workActionCategoryId:"ID",
		targetDateOffset:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		workActionTemplateGroup:"WorkActionTemplateGroupEntity",
		workActionCategory:"WorkActionCategoryEntity"
	},
	ProjectCostInfoEntity:{
		id:"Int",
		appProjectCostId:"Int",
		conceptUserId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		conceptUser:"UserEntity"
	},
	BlockedCalendarWeekEntity:{
		id:"ID",
		createdAt:"Date",
		updatedAt:"Date"
	},
	MaintenanceReportEntity:{
		id:"ID",
		projectId:"String",
		data:"JSON",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ConfigurationEntity:{
		id:"ID",
		data:"JSON"
	},
	ServiceMeterEntity:{
		id:"ID",
		description:"String",
		unit:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date"
	},
	DeliveryListMeterEntity:{
		id:"ID",
		projectId:"ID",
		meterId:"ID",
		value:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		meter:"ServiceMeterEntity"
	},
	ServiceSpaceEntity:{
		id:"ID",
		code:"String",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ServiceTypeEntity:{
		id:"ID",
		code:"String",
		description:"String",
		storeInSystem:"Boolean",
		isStandard:"Int",
		createdAt:"Date",
		updatedAt:"Date"
	},
	DeliveryListPointImageEntity:{
		id:"ID",
		pointId:"ID",
		documentMetaId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		point:"DeliveryListPointEntity",
		documentMeta:"DocumentMetaEntity"
	},
	DeliveryListPointEntity:{
		id:"ID",
		code:"String",
		projectId:"ID",
		primaryCategoryId:"Int",
		secondaryCategoryId:"Int",
		serviceTypeId:"ID",
		serviceSpaceId:"ID",
		serviceSpaceIds:"JSON",
		servicePriorityId:"ID",
		assignedUserId:"ID",
		serviceTicketId:"String",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		serviceType:"ServiceTypeEntity",
		serviceSpace:"ServiceSpaceEntity",
		servicePriority:"ServicePriorityEntity",
		assignedUser:"UserEntity",
		images:"DeliveryListPointImageEntity"
	},
	ServicePriorityEntity:{
		id:"ID",
		description:"String",
		offsetDays:"Int",
		isStandard:"Int",
		createdAt:"Date",
		updatedAt:"Date"
	},
	DeliveryListSignatureEntity:{
		id:"ID",
		projectId:"ID",
		kind:"String",
		documentMetaId:"ID",
		signDate:"Date",
		signLocation:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		documentMeta:"DocumentMetaEntity"
	},
	DeliveryListEntity:{
		id:"ID",
		projectId:"ID",
		assignedUserId:"ID",
		hiredAgent:"String",
		keyAmount:"Int",
		isDeliveredAt:"Date",
		houseDocumentMetaId:"ID",
		isFinal:"Boolean",
		isFinalAt:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		assignedUser:"UserEntity",
		houseDocumentMeta:"DocumentMetaEntity"
	},
	SupplyInspectionReportAnswerEntity:{
		id:"ID",
		supplyInspectionQuestionId:"String",
		supplyInspectionReportId:"ID",
		status:"String",
		explanation:"String",
		imageDocumentMetaIds:"JSON",
		supplyInspectionReport:"SupplyInspectionReportEntity"
	},
	SupplyInspectionReportEntity:{
		id:"ID",
		timestamp:"Date",
		departmentId:"ID",
		executedByUserId:"ID",
		signatureDocumentMetaId:"String",
		createdAt:"Date",
		updatedAt:"Date",
		department:"DepartmentEntity",
		executedByUser:"UserEntity",
		supplyInspectionReportAnswers:"SupplyInspectionReportAnswerEntity"
	},
	DepartmentEntity:{
		id:"ID",
		departmentId:"String",
		description:"String",
		state:"String",
		isOrderDepartment:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date",
		supplyInspectionReports:"SupplyInspectionReportEntity"
	},
	DrawOrderDescriptionEntity:{
		id:"ID",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	GreetingEntity:{
		id:"ID",
		html:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	HourDeclarationTypeEntity:{
		id:"ID",
		companyId:"String",
		administrationType:"String",
		kind:"String",
		kindDescription:"String",
		description:"String",
		hasActivities:"String",
		isExternal:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	HourDeclarationActivityEntity:{
		id:"ID",
		companyId:"String",
		administrationType:"String",
		description:"String",
		syncVersion:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	HourDeclarationEntity:{
		id:"ID",
		userId:"ID",
		hourDeclarationTypeId:"ID",
		projectId:"ID",
		hourDeclarationActivityId:"ID",
		priceAgreementId:"String",
		storedDocumentId:"String",
		date:"Date",
		description:"String",
		amount:"String",
		baseHours:"String",
		savingHours:"String",
		overTimeHours:"String",
		travelHours:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		hourDeclarationType:"HourDeclarationTypeEntity",
		project:"ProjectEntity",
		hourDeclarationActivity:"HourDeclarationActivityEntity"
	},
	HourDeclarationApprovalRequestEntity:{
		id:"ID",
		userId:"String",
		period:"String",
		createdAt:"Date"
	},
	InstallationReportCommentEntity:{
		id:"ID",
		projectId:"String",
		stepId:"String",
		userId:"String",
		message:"String",
		date:"String",
		performerName:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	InstallationReportDescriptionEntity:{
		id:"ID",
		stepId:"String",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	InstallationReportFinalEntity:{
		id:"ID",
		projectId:"ID",
		userId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		user:"UserEntity"
	},
	InstallationReportMeasurementTemplateEntity:{
		id:"ID",
		measureTableId:"String",
		measureId:"String",
		defaultFields:"JSON",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date"
	},
	InstallationReportMeasurementEntity:{
		id:"ID",
		projectId:"String",
		measureTableId:"String",
		measureId:"String",
		fields:"JSON",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date"
	},
	InstallationReportQuestionGroupEntity:{
		id:"ID",
		name:"String",
		stepId:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		questions:"InstallationReportQuestionEntity"
	},
	InstallationReportQuestionEntity:{
		id:"ID",
		questionGroupId:"ID",
		content:"String",
		tooltip:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		questionGroup:"InstallationReportQuestionGroupEntity"
	},
	InstallationReportQuestionAnswerEntity:{
		id:"ID",
		projectId:"String",
		questionId:"ID",
		content:"String",
		createdAt:"Date",
		updatedAt:"Date",
		question:"InstallationReportQuestionEntity"
	},
	LegacyProjectEntity:{
		id:"ID",
		description:"String",
		houseType:"String",
		regionId:"String",
		buildingStreet:"String",
		buildingCity:"String",
		buildingZipCode:"String",
		deliveryDate:"String",
		customerEmail:"String",
		syncVersion:"String",
		syncSource:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	PicklistCommentEntity:{
		id:"ID",
		projectId:"ID",
		picklistDefinitionId:"ID",
		userId:"ID",
		text:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		picklistDefinition:"PicklistDefinitionEntity",
		user:"UserEntity"
	},
	PicklistElementStateEntity:{
		id:"ID",
		elementId:"String",
		projectId:"ID",
		picklistDefinitionId:"ID",
		state:"String",
		pickedByUserId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		picklistDefinition:"PicklistDefinitionEntity",
		pickedByUser:"UserEntity"
	},
	PlanningProjectItemEntity:{
		id:"ID",
		parentId:"String",
		dimension:"String",
		projectId:"ID",
		competenceId:"ID",
		competenceIndex:"Int",
		competenceNumber:"String",
		competenceStartsAt:"Date",
		competenceEndsAt:"Date",
		hours:"String",
		humanResourceId:"ID",
		humanResourceNumber:"String",
		humanResourceStartsAt:"Date",
		humanResourceEndsAt:"Date",
		comments:"String",
		status:"String",
		version:"String",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity",
		competence:"PlanningCompetenceEntity",
		humanResource:"PlanningHumanResourceEntity"
	},
	ProjectCargoStateEntity:{
		id:"ID",
		projectId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		project:"ProjectEntity"
	},
	ProjectPaymentEntity:{
		id:"ID",
		projectId:"String",
		userId:"String",
		description:"String",
		payedAt:"Date",
		amount:"Float",
		createdAt:"Date",
		updatedAt:"Date"
	},
	QualityAssuranceEntity:{
		id:"ID",
		projectId:"String",
		data:"JSON",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ServiceMapColorEntity:{
		id:"ID",
		days:"Int",
		color:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ServicePlanningStageEntity:{
		id:"ID",
		name:"String",
		colorCode:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	ServicePlanningEventEntity:{
		id:"ID",
		legacyProjectId:"ID",
		stageId:"ID",
		userId:"ID",
		serviceTicketIds:"JSON",
		memo:"String",
		duration:"Float",
		durationUnit:"String",
		durationPlanned:"Float",
		durationSpend:"Float",
		isFinalized:"Boolean",
		startDate:"Date",
		endDate:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		assignments:"ServicePlanningAssignmentEntity",
		legacyProject:"LegacyProjectEntity",
		stage:"ServicePlanningStageEntity",
		user:"UserEntity"
	},
	ServicePlanningAssignmentEntity:{
		id:"ID",
		eventId:"ID",
		resourceId:"ID",
		createdAt:"Date",
		updatedAt:"Date",
		event:"ServicePlanningEventEntity",
		resource:"UserEntity"
	},
	ServicePresetEntity:{
		id:"ID",
		primaryCategoryId:"Int",
		secondaryCategoryId:"Int",
		code:"String",
		description:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	SupplierDocumentEntity:{
		id:"ID",
		userId:"ID",
		informedUserId:"ID",
		informedContactTypes:"JSON",
		projectId:"ID",
		documentMetaId:"ID",
		contextId:"String",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity",
		informedUser:"UserEntity",
		project:"ProjectEntity",
		documentMeta:"DocumentMetaEntity"
	},
	SupplyInspectionQuestionEntity:{
		id:"ID",
		supplyInspectionQuestionGroupId:"ID",
		departmentIds:"JSON",
		description:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		supplyInspectionQuestionGroup:"SupplyInspectionQuestionGroupEntity"
	},
	SupplyInspectionQuestionGroupEntity:{
		id:"ID",
		description:"String",
		orderId:"Int",
		createdAt:"Date",
		updatedAt:"Date",
		supplyInspectionQuestions:"SupplyInspectionQuestionEntity"
	},
	UserPasswordResetEntity:{
		id:"ID",
		userId:"ID",
		expiresAt:"Date",
		createdAt:"Date",
		updatedAt:"Date",
		user:"UserEntity"
	},
	YearPlanningLineEntity:{
		id:"ID",
		description:"String",
		weeks:"Int",
		colorCode:"String",
		visibleOnConstructionPlanning:"Boolean",
		createdAt:"Date",
		updatedAt:"Date"
	},
	InvoicePaymentBatchEntityEntity:{
		id:"ID",
		batch:"Int",
		document:"String",
		name:"String",
		date:"Date",
		amount:"Float",
		amountBank:"Float",
		companyId:"String",
		companyName:"String",
		relationId:"String",
		relationName:"String",
		createdAt:"Date",
		updatedAt:"Date"
	},
	AppPhaseContactEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		phase_id:"Int",
		type:"String",
		title:"String",
		receive_notifications:"Boolean",
		phase:"AppPhaseEntity"
	},
	AppPhaseEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		name:"String",
		order:"Int",
		percentage:"Int",
		is_visible_transferred:"Boolean",
		start_project_date_type:"String",
		end_project_date_type:"String",
		days:"Int",
		sections:"AppPhaseSectionEntity",
		contacts:"AppPhaseContactEntity"
	},
	AppPhaseSectionEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		phase_id:"Int",
		more_info_content_id:"Int",
		name:"String",
		order:"Int",
		percentage:"Int",
		phase:"AppPhaseEntity",
		marks:"AppPhaseSectionMarkEntity"
	},
	AppPhaseSectionMarkEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		phase_section_id:"Int",
		document_type_id:"Int",
		more_info_content_id:"Int",
		notification_template_id:"Int",
		project_field_group_id:"Int",
		project_date_type:"String",
		responsible:"String",
		name:"String",
		order:"Int",
		percentage:"Int",
		is_milestone:"Boolean",
		is_standard:"Boolean",
		date_prompt:"Boolean",
		duration_days:"Int",
		timeline_origin_date_type:"String",
		timeline_competence_number:"String",
		timeline_origin_diff_days:"Int",
		section:"AppPhaseSectionEntity",
		notificationTemplate:"AppNotificationTemplateEntity"
	},
	AppNotificationTemplateEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		content_id:"Int",
		subject:"String",
		body:"String"
	},
	AppProjectDateEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_id:"String",
		value:"String",
		type:"String"
	},
	AppNotificationScheduleEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		phase_section_mark_id:"Int",
		phase_section_mark_cancel_id:"Int",
		notification_template_id:"Int",
		days_offset:"Int",
		time_of_day:"String",
		mark:"AppPhaseSectionMarkEntity",
		markCancel:"AppPhaseSectionMarkEntity",
		notification:"AppNotificationTemplateEntity"
	},
	AppPhaseSectionMarkValueEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_id:"String",
		phase_section_mark_id:"Int",
		completed_user_id:"Int",
		send_notification:"Boolean",
		activated:"Boolean",
		completed:"Boolean",
		completed_at:"Date"
	},
	AppInviteEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"ID",
		project_id:"String",
		email:"String",
		login:"AppLoginEntity"
	},
	AppLoginEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		relation_id:"String",
		project_id:"String",
		transferred_at:"Date",
		email:"String",
		role_id:"String",
		user:"AppUserEntity",
		invite:"AppInviteEntity"
	},
	AppUserEntity:{
		id:"Int",
		project_id:"Int",
		relation_id:"String",
		email:"String",
		telephone:"String",
		facebook_identity:"String",
		google_identity:"String",
		password:"String",
		first_name:"String",
		last_name_prefix:"String",
		last_name:"String",
		created_at:"Date",
		updated_at:"Date",
		project:"AppProjectEntity",
		login:"AppLoginEntity",
		notifications:"AppNotificationEntity"
	},
	AppProjectEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		contract_sum:"String",
		users:"AppUserEntity"
	},
	AppNotificationEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"ID",
		type:"String",
		notifiable_id:"Int",
		notifiable_type:"String",
		data:"JSON",
		read_at:"Date",
		user:"AppUserEntity"
	},
	AppProjectCostEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		user_id:"Int",
		approved_user_id:"Int",
		declined_user_id:"Int",
		project_id:"Int",
		parent_id:"Int",
		title:"String",
		memo:"String",
		value:"String",
		approved_at:"Date",
		declined_at:"Date",
		requested_at:"Date",
		user:"AppUserEntity",
		approvedUser:"AppUserEntity",
		declinedUser:"AppUserEntity",
		parent:"AppProjectCostEntity",
		project:"AppProjectEntity",
		children:"AppProjectCostEntity"
	},
	AppProjectContactEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_id:"String",
		relation_id:"String",
		type:"String"
	},
	AppBuildingWeekEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"String",
		isBlocked:"Boolean",
		syncVersion:"String"
	},
	AppContentEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		title:"String",
		html:"String"
	},
	AppConversationMessagePresetEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		title:"String",
		message:"String"
	},
	AppDocumentSignatureEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		user_id:"Int",
		document_id:"String",
		payload:"JSON",
		user:"AppUserEntity"
	},
	AppDocumentTypeEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		name:"String",
		description:"String",
		color:"String",
		document_kind:"String",
		order_id:"Int",
		special_access:"Boolean",
		project_contact_type:"String"
	},
	AppDocumentEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"String",
		project_id:"String",
		user_id:"Int",
		document_type_id:"Int",
		document_kind:"String",
		description:"String",
		confidential:"Boolean",
		mime_type:"String",
		extension:"String",
		storage_id:"String",
		user:"AppUserEntity",
		documentType:"AppDocumentTypeEntity",
		signature:"AppDocumentSignatureEntity"
	},
	AppNotificationScheduleResourceEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_id:"String",
		notification_schedule_id:"Int",
		schedule_at:"Date",
		schedule:"AppNotificationScheduleEntity"
	},
	AppPlanCompetenceEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		number:"String",
		description:"String",
		index:"Int",
		color:"String"
	},
	AppPlanCompetenceSettingEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		plan_competence_id:"Int",
		content_id:"Int",
		is_visible:"Boolean",
		name:"String",
		plan_competence:"AppPlanCompetenceEntity"
	},
	AppProjectCostPresetEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		title:"String",
		description:"String"
	},
	AppProjectFieldSheetEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_contact_type:"String",
		name:"String",
		order:"Int",
		groups:"AppProjectFieldGroupEntity"
	},
	AppProjectFieldGroupEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_field_sheet_id:"Int",
		name:"String",
		order:"Int",
		sheet:"AppProjectFieldSheetEntity",
		assignments:"AppProjectFieldGroupAssignmentEntity"
	},
	AppProjectFieldGroupAssignmentEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_field_group_id:"Int",
		project_field_type:"String",
		project_field_copy_type:"String",
		value_type:"String",
		is_required:"Boolean",
		order:"Int",
		tooltip:"String",
		group:"AppProjectFieldGroupEntity",
		values:"AppProjectFieldGroupAssignmentValueEntity"
	},
	AppProjectFieldGroupAssignmentValueEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		project_field_group_assignment_id:"Int",
		value:"String",
		assignment:"AppProjectFieldGroupAssignmentEntity"
	},
	AppProjectFieldEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		value:"String",
		project_id:"String",
		type:"String"
	},
	AppServiceCategoryEntity:{
		created_at:"Date",
		updated_at:"Date",
		id:"Int",
		service_category_id:"Int",
		name:"String",
		service_category:"AppServiceCategoryEntity",
		service_categories:"AppServiceCategoryEntity"
	},
	AppTextEntity:{
		id:"Int",
		value:"String",
		created_at:"Date",
		updated_at:"Date"
	},
	AppRelationEntity:{
		id:"Int",
		name:"String",
		email:"String",
		is_blacklisted:"String",
		sync_version:"String",
		created_at:"Date",
		updated_at:"Date"
	},
	bedrijvenMtcEntity:{
		_rowid:"String",
		bedrijf:"String",
		bedrijf_oms:"String",
		bedrijf_straat:"String",
		bedrijf_huisnummer:"String",
		bedrijf_postcode:"String",
		bedrijf_plaats:"String",
		bedrijf_gln:"String",
		bedrijf_btw:"String",
		bedrijf_email:"String",
		bedrijf_email_adm:"String"
	},
	Mutation:{
		GrantEntityCreateOne:"GrantEntity",
		GrantEntityUpdateOne:"GrantEntity",
		GrantEntityUpdateMany:"GrantEntity",
		GrantEntityDeleteOne:"Boolean",
		RoleGrantConfigEntityCreateOne:"RoleGrantConfigEntity",
		RoleGrantConfigEntityUpdateOne:"RoleGrantConfigEntity",
		RoleGrantConfigEntityUpdateMany:"RoleGrantConfigEntity",
		RoleGrantConfigEntityDeleteOne:"Boolean",
		RoleDocumentGrantEntityCreateOne:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityUpdateOne:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityUpdateMany:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityDeleteOne:"Boolean",
		RoleEntityCreateOne:"RoleEntity",
		RoleEntityUpdateOne:"RoleEntity",
		RoleEntityUpdateMany:"RoleEntity",
		RoleEntityDeleteOne:"Boolean",
		UserGrantConfigEntityCreateOne:"UserGrantConfigEntity",
		UserGrantConfigEntityUpdateOne:"UserGrantConfigEntity",
		UserGrantConfigEntityUpdateMany:"UserGrantConfigEntity",
		UserGrantConfigEntityDeleteOne:"Boolean",
		UserNotificationSubscriptionEntityCreateOne:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityUpdateOne:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityUpdateMany:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityDeleteOne:"Boolean",
		UserNotificationEntityCreateOne:"UserNotificationEntity",
		UserNotificationEntityUpdateOne:"UserNotificationEntity",
		UserNotificationEntityUpdateMany:"UserNotificationEntity",
		UserNotificationEntityDeleteOne:"Boolean",
		UserTemporaryAccessTokenEntityEntityCreateOne:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityUpdateOne:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityUpdateMany:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityDeleteOne:"Boolean",
		UserEntityCreateOne:"UserEntity",
		UserEntityUpdateOne:"UserEntity",
		UserEntityUpdateMany:"UserEntity",
		UserEntityDeleteOne:"Boolean",
		PicklistDefinitionEntityCreateOne:"PicklistDefinitionEntity",
		PicklistDefinitionEntityUpdateOne:"PicklistDefinitionEntity",
		PicklistDefinitionEntityUpdateMany:"PicklistDefinitionEntity",
		PicklistDefinitionEntityDeleteOne:"Boolean",
		PicklistStateEntityCreateOne:"PicklistStateEntity",
		PicklistStateEntityUpdateOne:"PicklistStateEntity",
		PicklistStateEntityUpdateMany:"PicklistStateEntity",
		PicklistStateEntityDeleteOne:"Boolean",
		HousePartGroupEntityCreateOne:"HousePartGroupEntity",
		HousePartGroupEntityUpdateOne:"HousePartGroupEntity",
		HousePartGroupEntityUpdateMany:"HousePartGroupEntity",
		HousePartGroupEntityDeleteOne:"Boolean",
		HousePartEntityCreateOne:"HousePartEntity",
		HousePartEntityUpdateOne:"HousePartEntity",
		HousePartEntityUpdateMany:"HousePartEntity",
		HousePartEntityDeleteOne:"Boolean",
		CargoPhaseEntityCreateOne:"CargoPhaseEntity",
		CargoPhaseEntityUpdateOne:"CargoPhaseEntity",
		CargoPhaseEntityUpdateMany:"CargoPhaseEntity",
		CargoPhaseEntityDeleteOne:"Boolean",
		CargoTypeEntityCreateOne:"CargoTypeEntity",
		CargoTypeEntityUpdateOne:"CargoTypeEntity",
		CargoTypeEntityUpdateMany:"CargoTypeEntity",
		CargoTypeEntityDeleteOne:"Boolean",
		PlanningHumanResourceEntityCreateOne:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityUpdateOne:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityUpdateMany:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityDeleteOne:"Boolean",
		PlanningProjectAssignmentEntityCreateOne:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityUpdateOne:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityUpdateMany:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityDeleteOne:"Boolean",
		PlanningProjectCompetencePlacementEntityCreateOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityUpdateOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityUpdateMany:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityDeleteOne:"Boolean",
		PlanningProjectRequestPlacementEntityCreateOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityUpdateOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityUpdateMany:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityDeleteOne:"Boolean",
		PlanningProjectEntityCreateOne:"PlanningProjectEntity",
		PlanningProjectEntityUpdateOne:"PlanningProjectEntity",
		PlanningProjectEntityUpdateMany:"PlanningProjectEntity",
		PlanningProjectEntityDeleteOne:"Boolean",
		PlanningProjectRequestEntityCreateOne:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityUpdateOne:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityUpdateMany:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityDeleteOne:"Boolean",
		PlanningProjectCompetenceEntityCreateOne:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityUpdateOne:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityUpdateMany:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityDeleteOne:"Boolean",
		PlanningCompetenceEntityCreateOne:"PlanningCompetenceEntity",
		PlanningCompetenceEntityUpdateOne:"PlanningCompetenceEntity",
		PlanningCompetenceEntityUpdateMany:"PlanningCompetenceEntity",
		PlanningCompetenceEntityDeleteOne:"Boolean",
		CargoStateEntityCreateOne:"CargoStateEntity",
		CargoStateEntityUpdateOne:"CargoStateEntity",
		CargoStateEntityUpdateMany:"CargoStateEntity",
		CargoStateEntityDeleteOne:"Boolean",
		CargoEntityCreateOne:"CargoEntity",
		CargoEntityUpdateOne:"CargoEntity",
		CargoEntityUpdateMany:"CargoEntity",
		CargoEntityDeleteOne:"Boolean",
		ProjectHousePartEntityCreateOne:"ProjectHousePartEntity",
		ProjectHousePartEntityUpdateOne:"ProjectHousePartEntity",
		ProjectHousePartEntityUpdateMany:"ProjectHousePartEntity",
		ProjectHousePartEntityDeleteOne:"Boolean",
		PicklistElementEntityCreateOne:"PicklistElementEntity",
		PicklistElementEntityUpdateOne:"PicklistElementEntity",
		PicklistElementEntityUpdateMany:"PicklistElementEntity",
		PicklistElementEntityDeleteOne:"Boolean",
		PicklistEntityCreateOne:"PicklistEntity",
		PicklistEntityUpdateOne:"PicklistEntity",
		PicklistEntityUpdateMany:"PicklistEntity",
		PicklistEntityDeleteOne:"Boolean",
		DrawOrderTypeEntityCreateOne:"DrawOrderTypeEntity",
		DrawOrderTypeEntityUpdateOne:"DrawOrderTypeEntity",
		DrawOrderTypeEntityUpdateMany:"DrawOrderTypeEntity",
		DrawOrderTypeEntityDeleteOne:"Boolean",
		HouseModelEntityCreateOne:"HouseModelEntity",
		HouseModelEntityUpdateOne:"HouseModelEntity",
		HouseModelEntityUpdateMany:"HouseModelEntity",
		HouseModelEntityDeleteOne:"Boolean",
		SalesEmployeeEntityCreateOne:"SalesEmployeeEntity",
		SalesEmployeeEntityUpdateOne:"SalesEmployeeEntity",
		SalesEmployeeEntityUpdateMany:"SalesEmployeeEntity",
		SalesEmployeeEntityDeleteOne:"Boolean",
		DrawerAbsentEntityCreateOne:"DrawerAbsentEntity",
		DrawerAbsentEntityUpdateOne:"DrawerAbsentEntity",
		DrawerAbsentEntityUpdateMany:"DrawerAbsentEntity",
		DrawerAbsentEntityDeleteOne:"Boolean",
		DrawerEntityCreateOne:"DrawerEntity",
		DrawerEntityUpdateOne:"DrawerEntity",
		DrawerEntityUpdateMany:"DrawerEntity",
		DrawerEntityDeleteOne:"Boolean",
		DrawOrderEntityCreateOne:"DrawOrderEntity",
		DrawOrderEntityUpdateOne:"DrawOrderEntity",
		DrawOrderEntityUpdateMany:"DrawOrderEntity",
		DrawOrderEntityDeleteOne:"Boolean",
		DateGroupCommentEntityCreateOne:"DateGroupCommentEntity",
		DateGroupCommentEntityUpdateOne:"DateGroupCommentEntity",
		DateGroupCommentEntityUpdateMany:"DateGroupCommentEntity",
		DateGroupCommentEntityDeleteOne:"Boolean",
		DateGroupResponsibleEntityCreateOne:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityUpdateOne:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityUpdateMany:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityDeleteOne:"Boolean",
		LogicFieldEntityCreateOne:"LogicFieldEntity",
		LogicFieldEntityUpdateOne:"LogicFieldEntity",
		LogicFieldEntityUpdateMany:"LogicFieldEntity",
		LogicFieldEntityDeleteOne:"Boolean",
		DateGroupEntityCreateOne:"DateGroupEntity",
		DateGroupEntityUpdateOne:"DateGroupEntity",
		DateGroupEntityUpdateMany:"DateGroupEntity",
		DateGroupEntityDeleteOne:"Boolean",
		DateEntityCreateOne:"DateEntity",
		DateEntityUpdateOne:"DateEntity",
		DateEntityUpdateMany:"DateEntity",
		DateEntityDeleteOne:"Boolean",
		DateValueEntityCreateOne:"DateValueEntity",
		DateValueEntityUpdateOne:"DateValueEntity",
		DateValueEntityUpdateMany:"DateValueEntity",
		DateValueEntityDeleteOne:"Boolean",
		LogicFieldValueEntityCreateOne:"LogicFieldValueEntity",
		LogicFieldValueEntityUpdateOne:"LogicFieldValueEntity",
		LogicFieldValueEntityUpdateMany:"LogicFieldValueEntity",
		LogicFieldValueEntityDeleteOne:"Boolean",
		DateCacheEntityCreateOne:"DateCacheEntity",
		DateCacheEntityUpdateOne:"DateCacheEntity",
		DateCacheEntityUpdateMany:"DateCacheEntity",
		DateCacheEntityDeleteOne:"Boolean",
		YearPlanningModificationEntityCreateOne:"YearPlanningModificationEntity",
		YearPlanningModificationEntityUpdateOne:"YearPlanningModificationEntity",
		YearPlanningModificationEntityUpdateMany:"YearPlanningModificationEntity",
		YearPlanningModificationEntityDeleteOne:"Boolean",
		ProjectCachedValueEntityCreateOne:"ProjectCachedValueEntity",
		ProjectCachedValueEntityUpdateOne:"ProjectCachedValueEntity",
		ProjectCachedValueEntityUpdateMany:"ProjectCachedValueEntity",
		ProjectCachedValueEntityDeleteOne:"Boolean",
		TableLayoutCommentEntityCreateOne:"TableLayoutCommentEntity",
		TableLayoutCommentEntityUpdateOne:"TableLayoutCommentEntity",
		TableLayoutCommentEntityUpdateMany:"TableLayoutCommentEntity",
		TableLayoutCommentEntityDeleteOne:"Boolean",
		ProjectEntityCreateOne:"ProjectEntity",
		ProjectEntityUpdateOne:"ProjectEntity",
		ProjectEntityUpdateMany:"ProjectEntity",
		ProjectEntityDeleteOne:"Boolean",
		SyncCronEntityCreateOne:"SyncCronEntity",
		SyncCronEntityUpdateOne:"SyncCronEntity",
		SyncCronEntityUpdateMany:"SyncCronEntity",
		SyncCronEntityDeleteOne:"Boolean",
		MailSchedulerEntityCreateOne:"MailSchedulerEntity",
		MailSchedulerEntityUpdateOne:"MailSchedulerEntity",
		MailSchedulerEntityUpdateMany:"MailSchedulerEntity",
		MailSchedulerEntityDeleteOne:"Boolean",
		ProjectPartnerEntityCreateOne:"ProjectPartnerEntity",
		ProjectPartnerEntityUpdateOne:"ProjectPartnerEntity",
		ProjectPartnerEntityUpdateMany:"ProjectPartnerEntity",
		ProjectPartnerEntityDeleteOne:"Boolean",
		PartnerEntityCreateOne:"PartnerEntity",
		PartnerEntityUpdateOne:"PartnerEntity",
		PartnerEntityUpdateMany:"PartnerEntity",
		PartnerEntityDeleteOne:"Boolean",
		DocumentMetaEntityCreateOne:"DocumentMetaEntity",
		DocumentMetaEntityUpdateOne:"DocumentMetaEntity",
		DocumentMetaEntityUpdateMany:"DocumentMetaEntity",
		DocumentMetaEntityDeleteOne:"Boolean",
		EntityAdvancementEntityCreateOne:"EntityAdvancementEntity",
		EntityAdvancementEntityUpdateOne:"EntityAdvancementEntity",
		EntityAdvancementEntityUpdateMany:"EntityAdvancementEntity",
		EntityAdvancementEntityDeleteOne:"Boolean",
		WorkActionCategoryEntityCreateOne:"WorkActionCategoryEntity",
		WorkActionCategoryEntityUpdateOne:"WorkActionCategoryEntity",
		WorkActionCategoryEntityUpdateMany:"WorkActionCategoryEntity",
		WorkActionCategoryEntityDeleteOne:"Boolean",
		WorkActionEntityCreateOne:"WorkActionEntity",
		WorkActionEntityUpdateOne:"WorkActionEntity",
		WorkActionEntityUpdateMany:"WorkActionEntity",
		WorkActionEntityDeleteOne:"Boolean",
		EntityImageEntityCreateOne:"EntityImageEntity",
		EntityImageEntityUpdateOne:"EntityImageEntity",
		EntityImageEntityUpdateMany:"EntityImageEntity",
		EntityImageEntityDeleteOne:"Boolean",
		CustomerQuestionEntityCreateOne:"CustomerQuestionEntity",
		CustomerQuestionEntityUpdateOne:"CustomerQuestionEntity",
		CustomerQuestionEntityUpdateMany:"CustomerQuestionEntity",
		CustomerQuestionEntityDeleteOne:"Boolean",
		AuditCategoryEntityCreateOne:"AuditCategoryEntity",
		AuditCategoryEntityUpdateOne:"AuditCategoryEntity",
		AuditCategoryEntityUpdateMany:"AuditCategoryEntity",
		AuditCategoryEntityDeleteOne:"Boolean",
		AuditQuestionEntityCreateOne:"AuditQuestionEntity",
		AuditQuestionEntityUpdateOne:"AuditQuestionEntity",
		AuditQuestionEntityUpdateMany:"AuditQuestionEntity",
		AuditQuestionEntityDeleteOne:"Boolean",
		AuditQuestionCostCategoryEntityCreateOne:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityUpdateOne:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityUpdateMany:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityDeleteOne:"Boolean",
		CostCategoryEntityCreateOne:"CostCategoryEntity",
		CostCategoryEntityUpdateOne:"CostCategoryEntity",
		CostCategoryEntityUpdateMany:"CostCategoryEntity",
		CostCategoryEntityDeleteOne:"Boolean",
		AuditReportItemImageEntityCreateOne:"AuditReportItemImageEntity",
		AuditReportItemImageEntityUpdateOne:"AuditReportItemImageEntity",
		AuditReportItemImageEntityUpdateMany:"AuditReportItemImageEntity",
		AuditReportItemImageEntityDeleteOne:"Boolean",
		AuditReportItemEntityCreateOne:"AuditReportItemEntity",
		AuditReportItemEntityUpdateOne:"AuditReportItemEntity",
		AuditReportItemEntityUpdateMany:"AuditReportItemEntity",
		AuditReportItemEntityDeleteOne:"Boolean",
		AuditReportEntityCreateOne:"AuditReportEntity",
		AuditReportEntityUpdateOne:"AuditReportEntity",
		AuditReportEntityUpdateMany:"AuditReportEntity",
		AuditReportEntityDeleteOne:"Boolean",
		AuditChecklistImageEntityCreateOne:"AuditChecklistImageEntity",
		AuditChecklistImageEntityUpdateOne:"AuditChecklistImageEntity",
		AuditChecklistImageEntityUpdateMany:"AuditChecklistImageEntity",
		AuditChecklistImageEntityDeleteOne:"Boolean",
		InvoiceAttachmentEntityCreateOne:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityUpdateOne:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityUpdateMany:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityDeleteOne:"Boolean",
		EmployeeDocumentEntityCreateOne:"EmployeeDocumentEntity",
		EmployeeDocumentEntityUpdateOne:"EmployeeDocumentEntity",
		EmployeeDocumentEntityUpdateMany:"EmployeeDocumentEntity",
		EmployeeDocumentEntityDeleteOne:"Boolean",
		WorkActionTemplateGroupEntityCreateOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityUpdateOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityUpdateMany:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityDeleteOne:"Boolean",
		WorkActionTemplateEntityCreateOne:"WorkActionTemplateEntity",
		WorkActionTemplateEntityUpdateOne:"WorkActionTemplateEntity",
		WorkActionTemplateEntityUpdateMany:"WorkActionTemplateEntity",
		WorkActionTemplateEntityDeleteOne:"Boolean",
		ProjectCostInfoEntityCreateOne:"ProjectCostInfoEntity",
		ProjectCostInfoEntityUpdateOne:"ProjectCostInfoEntity",
		ProjectCostInfoEntityUpdateMany:"ProjectCostInfoEntity",
		ProjectCostInfoEntityDeleteOne:"Boolean",
		BlockedCalendarWeekEntityCreateOne:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityUpdateOne:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityUpdateMany:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityDeleteOne:"Boolean",
		MaintenanceReportEntityCreateOne:"MaintenanceReportEntity",
		MaintenanceReportEntityUpdateOne:"MaintenanceReportEntity",
		MaintenanceReportEntityUpdateMany:"MaintenanceReportEntity",
		MaintenanceReportEntityDeleteOne:"Boolean",
		ConfigurationEntityCreateOne:"ConfigurationEntity",
		ConfigurationEntityUpdateOne:"ConfigurationEntity",
		ConfigurationEntityUpdateMany:"ConfigurationEntity",
		ConfigurationEntityDeleteOne:"Boolean",
		ServiceMeterEntityCreateOne:"ServiceMeterEntity",
		ServiceMeterEntityUpdateOne:"ServiceMeterEntity",
		ServiceMeterEntityUpdateMany:"ServiceMeterEntity",
		ServiceMeterEntityDeleteOne:"Boolean",
		DeliveryListMeterEntityCreateOne:"DeliveryListMeterEntity",
		DeliveryListMeterEntityUpdateOne:"DeliveryListMeterEntity",
		DeliveryListMeterEntityUpdateMany:"DeliveryListMeterEntity",
		DeliveryListMeterEntityDeleteOne:"Boolean",
		ServiceSpaceEntityCreateOne:"ServiceSpaceEntity",
		ServiceSpaceEntityUpdateOne:"ServiceSpaceEntity",
		ServiceSpaceEntityUpdateMany:"ServiceSpaceEntity",
		ServiceSpaceEntityDeleteOne:"Boolean",
		ServiceTypeEntityCreateOne:"ServiceTypeEntity",
		ServiceTypeEntityUpdateOne:"ServiceTypeEntity",
		ServiceTypeEntityUpdateMany:"ServiceTypeEntity",
		ServiceTypeEntityDeleteOne:"Boolean",
		DeliveryListPointImageEntityCreateOne:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityUpdateOne:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityUpdateMany:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityDeleteOne:"Boolean",
		ServicePriorityEntityCreateOne:"ServicePriorityEntity",
		ServicePriorityEntityUpdateOne:"ServicePriorityEntity",
		ServicePriorityEntityUpdateMany:"ServicePriorityEntity",
		ServicePriorityEntityDeleteOne:"Boolean",
		DeliveryListPointEntityCreateOne:"DeliveryListPointEntity",
		DeliveryListPointEntityUpdateOne:"DeliveryListPointEntity",
		DeliveryListPointEntityUpdateMany:"DeliveryListPointEntity",
		DeliveryListPointEntityDeleteOne:"Boolean",
		DeliveryListSignatureEntityCreateOne:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityUpdateOne:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityUpdateMany:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityDeleteOne:"Boolean",
		DeliveryListEntityCreateOne:"DeliveryListEntity",
		DeliveryListEntityUpdateOne:"DeliveryListEntity",
		DeliveryListEntityUpdateMany:"DeliveryListEntity",
		DeliveryListEntityDeleteOne:"Boolean",
		SupplyInspectionReportAnswerEntityCreateOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityUpdateOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityUpdateMany:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityDeleteOne:"Boolean",
		SupplyInspectionReportEntityCreateOne:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityUpdateOne:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityUpdateMany:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityDeleteOne:"Boolean",
		DepartmentEntityCreateOne:"DepartmentEntity",
		DepartmentEntityUpdateOne:"DepartmentEntity",
		DepartmentEntityUpdateMany:"DepartmentEntity",
		DepartmentEntityDeleteOne:"Boolean",
		DrawOrderDescriptionEntityCreateOne:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityUpdateOne:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityUpdateMany:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityDeleteOne:"Boolean",
		GreetingEntityCreateOne:"GreetingEntity",
		GreetingEntityUpdateOne:"GreetingEntity",
		GreetingEntityUpdateMany:"GreetingEntity",
		GreetingEntityDeleteOne:"Boolean",
		HourDeclarationTypeEntityCreateOne:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityUpdateOne:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityUpdateMany:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityDeleteOne:"Boolean",
		HourDeclarationActivityEntityCreateOne:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityUpdateOne:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityUpdateMany:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityDeleteOne:"Boolean",
		HourDeclarationEntityCreateOne:"HourDeclarationEntity",
		HourDeclarationEntityUpdateOne:"HourDeclarationEntity",
		HourDeclarationEntityUpdateMany:"HourDeclarationEntity",
		HourDeclarationEntityDeleteOne:"Boolean",
		HourDeclarationApprovalRequestEntityCreateOne:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityUpdateOne:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityUpdateMany:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityDeleteOne:"Boolean",
		InstallationReportCommentEntityCreateOne:"InstallationReportCommentEntity",
		InstallationReportCommentEntityUpdateOne:"InstallationReportCommentEntity",
		InstallationReportCommentEntityUpdateMany:"InstallationReportCommentEntity",
		InstallationReportCommentEntityDeleteOne:"Boolean",
		InstallationReportDescriptionEntityCreateOne:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityUpdateOne:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityUpdateMany:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityDeleteOne:"Boolean",
		InstallationReportFinalEntityCreateOne:"InstallationReportFinalEntity",
		InstallationReportFinalEntityUpdateOne:"InstallationReportFinalEntity",
		InstallationReportFinalEntityUpdateMany:"InstallationReportFinalEntity",
		InstallationReportFinalEntityDeleteOne:"Boolean",
		InstallationReportMeasurementTemplateEntityCreateOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityUpdateOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityUpdateMany:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityDeleteOne:"Boolean",
		InstallationReportMeasurementEntityCreateOne:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityUpdateOne:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityUpdateMany:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityDeleteOne:"Boolean",
		InstallationReportQuestionGroupEntityCreateOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityUpdateOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityUpdateMany:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityDeleteOne:"Boolean",
		InstallationReportQuestionEntityCreateOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityUpdateOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityUpdateMany:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityDeleteOne:"Boolean",
		InstallationReportQuestionAnswerEntityCreateOne:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityUpdateOne:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityUpdateMany:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityDeleteOne:"Boolean",
		LegacyProjectEntityCreateOne:"LegacyProjectEntity",
		LegacyProjectEntityUpdateOne:"LegacyProjectEntity",
		LegacyProjectEntityUpdateMany:"LegacyProjectEntity",
		LegacyProjectEntityDeleteOne:"Boolean",
		PicklistCommentEntityCreateOne:"PicklistCommentEntity",
		PicklistCommentEntityUpdateOne:"PicklistCommentEntity",
		PicklistCommentEntityUpdateMany:"PicklistCommentEntity",
		PicklistCommentEntityDeleteOne:"Boolean",
		PicklistElementStateEntityCreateOne:"PicklistElementStateEntity",
		PicklistElementStateEntityUpdateOne:"PicklistElementStateEntity",
		PicklistElementStateEntityUpdateMany:"PicklistElementStateEntity",
		PicklistElementStateEntityDeleteOne:"Boolean",
		PlanningProjectItemEntityCreateOne:"PlanningProjectItemEntity",
		PlanningProjectItemEntityUpdateOne:"PlanningProjectItemEntity",
		PlanningProjectItemEntityUpdateMany:"PlanningProjectItemEntity",
		PlanningProjectItemEntityDeleteOne:"Boolean",
		ProjectCargoStateEntityCreateOne:"ProjectCargoStateEntity",
		ProjectCargoStateEntityUpdateOne:"ProjectCargoStateEntity",
		ProjectCargoStateEntityUpdateMany:"ProjectCargoStateEntity",
		ProjectCargoStateEntityDeleteOne:"Boolean",
		ProjectPaymentEntityCreateOne:"ProjectPaymentEntity",
		ProjectPaymentEntityUpdateOne:"ProjectPaymentEntity",
		ProjectPaymentEntityUpdateMany:"ProjectPaymentEntity",
		ProjectPaymentEntityDeleteOne:"Boolean",
		QualityAssuranceEntityCreateOne:"QualityAssuranceEntity",
		QualityAssuranceEntityUpdateOne:"QualityAssuranceEntity",
		QualityAssuranceEntityUpdateMany:"QualityAssuranceEntity",
		QualityAssuranceEntityDeleteOne:"Boolean",
		ServiceMapColorEntityCreateOne:"ServiceMapColorEntity",
		ServiceMapColorEntityUpdateOne:"ServiceMapColorEntity",
		ServiceMapColorEntityUpdateMany:"ServiceMapColorEntity",
		ServiceMapColorEntityDeleteOne:"Boolean",
		ServicePlanningStageEntityCreateOne:"ServicePlanningStageEntity",
		ServicePlanningStageEntityUpdateOne:"ServicePlanningStageEntity",
		ServicePlanningStageEntityUpdateMany:"ServicePlanningStageEntity",
		ServicePlanningStageEntityDeleteOne:"Boolean",
		ServicePlanningEventEntityCreateOne:"ServicePlanningEventEntity",
		ServicePlanningEventEntityUpdateOne:"ServicePlanningEventEntity",
		ServicePlanningEventEntityUpdateMany:"ServicePlanningEventEntity",
		ServicePlanningEventEntityDeleteOne:"Boolean",
		ServicePlanningAssignmentEntityCreateOne:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityUpdateOne:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityUpdateMany:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityDeleteOne:"Boolean",
		ServicePresetEntityCreateOne:"ServicePresetEntity",
		ServicePresetEntityUpdateOne:"ServicePresetEntity",
		ServicePresetEntityUpdateMany:"ServicePresetEntity",
		ServicePresetEntityDeleteOne:"Boolean",
		SupplierDocumentEntityCreateOne:"SupplierDocumentEntity",
		SupplierDocumentEntityUpdateOne:"SupplierDocumentEntity",
		SupplierDocumentEntityUpdateMany:"SupplierDocumentEntity",
		SupplierDocumentEntityDeleteOne:"Boolean",
		SupplyInspectionQuestionEntityCreateOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityUpdateOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityUpdateMany:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityDeleteOne:"Boolean",
		SupplyInspectionQuestionGroupEntityCreateOne:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityUpdateOne:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityUpdateMany:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityDeleteOne:"Boolean",
		UserPasswordResetEntityCreateOne:"UserPasswordResetEntity",
		UserPasswordResetEntityUpdateOne:"UserPasswordResetEntity",
		UserPasswordResetEntityUpdateMany:"UserPasswordResetEntity",
		UserPasswordResetEntityDeleteOne:"Boolean",
		YearPlanningLineEntityCreateOne:"YearPlanningLineEntity",
		YearPlanningLineEntityUpdateOne:"YearPlanningLineEntity",
		YearPlanningLineEntityUpdateMany:"YearPlanningLineEntity",
		YearPlanningLineEntityDeleteOne:"Boolean",
		InvoicePaymentBatchEntityEntityCreateOne:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityUpdateOne:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityUpdateMany:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityDeleteOne:"Boolean",
		AppPhaseContactEntityCreateOne:"AppPhaseContactEntity",
		AppPhaseContactEntityUpdateOne:"AppPhaseContactEntity",
		AppPhaseContactEntityUpdateMany:"AppPhaseContactEntity",
		AppPhaseContactEntityDeleteOne:"Boolean",
		AppPhaseEntityCreateOne:"AppPhaseEntity",
		AppPhaseEntityUpdateOne:"AppPhaseEntity",
		AppPhaseEntityUpdateMany:"AppPhaseEntity",
		AppPhaseEntityDeleteOne:"Boolean",
		AppPhaseSectionEntityCreateOne:"AppPhaseSectionEntity",
		AppPhaseSectionEntityUpdateOne:"AppPhaseSectionEntity",
		AppPhaseSectionEntityUpdateMany:"AppPhaseSectionEntity",
		AppPhaseSectionEntityDeleteOne:"Boolean",
		AppNotificationTemplateEntityCreateOne:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityUpdateOne:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityUpdateMany:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityDeleteOne:"Boolean",
		AppPhaseSectionMarkEntityCreateOne:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityUpdateOne:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityUpdateMany:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityDeleteOne:"Boolean",
		AppProjectDateEntityCreateOne:"AppProjectDateEntity",
		AppProjectDateEntityUpdateOne:"AppProjectDateEntity",
		AppProjectDateEntityUpdateMany:"AppProjectDateEntity",
		AppProjectDateEntityDeleteOne:"Boolean",
		AppNotificationScheduleEntityCreateOne:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityUpdateOne:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityUpdateMany:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityDeleteOne:"Boolean",
		AppPhaseSectionMarkValueEntityCreateOne:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityUpdateOne:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityUpdateMany:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityDeleteOne:"Boolean",
		AppInviteEntityCreateOne:"AppInviteEntity",
		AppInviteEntityUpdateOne:"AppInviteEntity",
		AppInviteEntityUpdateMany:"AppInviteEntity",
		AppInviteEntityDeleteOne:"Boolean",
		AppLoginEntityCreateOne:"AppLoginEntity",
		AppLoginEntityUpdateOne:"AppLoginEntity",
		AppLoginEntityUpdateMany:"AppLoginEntity",
		AppLoginEntityDeleteOne:"Boolean",
		AppNotificationEntityCreateOne:"AppNotificationEntity",
		AppNotificationEntityUpdateOne:"AppNotificationEntity",
		AppNotificationEntityUpdateMany:"AppNotificationEntity",
		AppNotificationEntityDeleteOne:"Boolean",
		AppProjectEntityCreateOne:"AppProjectEntity",
		AppProjectEntityUpdateOne:"AppProjectEntity",
		AppProjectEntityUpdateMany:"AppProjectEntity",
		AppProjectEntityDeleteOne:"Boolean",
		AppUserEntityCreateOne:"AppUserEntity",
		AppUserEntityUpdateOne:"AppUserEntity",
		AppUserEntityUpdateMany:"AppUserEntity",
		AppUserEntityDeleteOne:"Boolean",
		AppProjectCostEntityCreateOne:"AppProjectCostEntity",
		AppProjectCostEntityUpdateOne:"AppProjectCostEntity",
		AppProjectCostEntityUpdateMany:"AppProjectCostEntity",
		AppProjectCostEntityDeleteOne:"Boolean",
		AppProjectContactEntityCreateOne:"AppProjectContactEntity",
		AppProjectContactEntityUpdateOne:"AppProjectContactEntity",
		AppProjectContactEntityUpdateMany:"AppProjectContactEntity",
		AppProjectContactEntityDeleteOne:"Boolean",
		AppBuildingWeekEntityCreateOne:"AppBuildingWeekEntity",
		AppBuildingWeekEntityUpdateOne:"AppBuildingWeekEntity",
		AppBuildingWeekEntityUpdateMany:"AppBuildingWeekEntity",
		AppBuildingWeekEntityDeleteOne:"Boolean",
		AppContentEntityCreateOne:"AppContentEntity",
		AppContentEntityUpdateOne:"AppContentEntity",
		AppContentEntityUpdateMany:"AppContentEntity",
		AppContentEntityDeleteOne:"Boolean",
		AppConversationMessagePresetEntityCreateOne:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityUpdateOne:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityUpdateMany:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityDeleteOne:"Boolean",
		AppDocumentSignatureEntityCreateOne:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityUpdateOne:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityUpdateMany:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityDeleteOne:"Boolean",
		AppDocumentTypeEntityCreateOne:"AppDocumentTypeEntity",
		AppDocumentTypeEntityUpdateOne:"AppDocumentTypeEntity",
		AppDocumentTypeEntityUpdateMany:"AppDocumentTypeEntity",
		AppDocumentTypeEntityDeleteOne:"Boolean",
		AppDocumentEntityCreateOne:"AppDocumentEntity",
		AppDocumentEntityUpdateOne:"AppDocumentEntity",
		AppDocumentEntityUpdateMany:"AppDocumentEntity",
		AppDocumentEntityDeleteOne:"Boolean",
		AppNotificationScheduleResourceEntityCreateOne:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityUpdateOne:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityUpdateMany:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityDeleteOne:"Boolean",
		AppPlanCompetenceEntityCreateOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityUpdateOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityUpdateMany:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityDeleteOne:"Boolean",
		AppPlanCompetenceSettingEntityCreateOne:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityUpdateOne:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityUpdateMany:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityDeleteOne:"Boolean",
		AppProjectCostPresetEntityCreateOne:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityUpdateOne:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityUpdateMany:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityDeleteOne:"Boolean",
		AppProjectFieldSheetEntityCreateOne:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityUpdateOne:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityUpdateMany:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityDeleteOne:"Boolean",
		AppProjectFieldGroupEntityCreateOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityUpdateOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityUpdateMany:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityDeleteOne:"Boolean",
		AppProjectFieldGroupAssignmentEntityCreateOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityUpdateOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityUpdateMany:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityDeleteOne:"Boolean",
		AppProjectFieldGroupAssignmentValueEntityCreateOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityUpdateOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityUpdateMany:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityDeleteOne:"Boolean",
		AppProjectFieldEntityCreateOne:"AppProjectFieldEntity",
		AppProjectFieldEntityUpdateOne:"AppProjectFieldEntity",
		AppProjectFieldEntityUpdateMany:"AppProjectFieldEntity",
		AppProjectFieldEntityDeleteOne:"Boolean",
		AppServiceCategoryEntityCreateOne:"AppServiceCategoryEntity",
		AppServiceCategoryEntityUpdateOne:"AppServiceCategoryEntity",
		AppServiceCategoryEntityUpdateMany:"AppServiceCategoryEntity",
		AppServiceCategoryEntityDeleteOne:"Boolean",
		AppTextEntityCreateOne:"AppTextEntity",
		AppTextEntityUpdateOne:"AppTextEntity",
		AppTextEntityUpdateMany:"AppTextEntity",
		AppTextEntityDeleteOne:"Boolean",
		AppRelationEntityCreateOne:"AppRelationEntity",
		AppRelationEntityUpdateOne:"AppRelationEntity",
		AppRelationEntityUpdateMany:"AppRelationEntity",
		AppRelationEntityDeleteOne:"Boolean",
		UploadDocument:"DocumentMetaEntity",
		CellaWriteCustomFields:"Boolean"
	},
	Subscription:{
		GrantEntityOnCreatedOne:"GrantEntity",
		GrantEntityOnUpdatedOne:"GrantEntity",
		GrantEntityOnDeletedOne:"GrantEntity",
		RoleGrantConfigEntityOnCreatedOne:"RoleGrantConfigEntity",
		RoleGrantConfigEntityOnUpdatedOne:"RoleGrantConfigEntity",
		RoleGrantConfigEntityOnDeletedOne:"RoleGrantConfigEntity",
		RoleDocumentGrantEntityOnCreatedOne:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityOnUpdatedOne:"RoleDocumentGrantEntity",
		RoleDocumentGrantEntityOnDeletedOne:"RoleDocumentGrantEntity",
		RoleEntityOnCreatedOne:"RoleEntity",
		RoleEntityOnUpdatedOne:"RoleEntity",
		RoleEntityOnDeletedOne:"RoleEntity",
		UserGrantConfigEntityOnCreatedOne:"UserGrantConfigEntity",
		UserGrantConfigEntityOnUpdatedOne:"UserGrantConfigEntity",
		UserGrantConfigEntityOnDeletedOne:"UserGrantConfigEntity",
		UserNotificationSubscriptionEntityOnCreatedOne:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityOnUpdatedOne:"UserNotificationSubscriptionEntity",
		UserNotificationSubscriptionEntityOnDeletedOne:"UserNotificationSubscriptionEntity",
		UserNotificationEntityOnCreatedOne:"UserNotificationEntity",
		UserNotificationEntityOnUpdatedOne:"UserNotificationEntity",
		UserNotificationEntityOnDeletedOne:"UserNotificationEntity",
		UserTemporaryAccessTokenEntityEntityOnCreatedOne:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityOnUpdatedOne:"UserTemporaryAccessTokenEntityEntity",
		UserTemporaryAccessTokenEntityEntityOnDeletedOne:"UserTemporaryAccessTokenEntityEntity",
		UserEntityOnCreatedOne:"UserEntity",
		UserEntityOnUpdatedOne:"UserEntity",
		UserEntityOnDeletedOne:"UserEntity",
		PicklistDefinitionEntityOnCreatedOne:"PicklistDefinitionEntity",
		PicklistDefinitionEntityOnUpdatedOne:"PicklistDefinitionEntity",
		PicklistDefinitionEntityOnDeletedOne:"PicklistDefinitionEntity",
		PicklistStateEntityOnCreatedOne:"PicklistStateEntity",
		PicklistStateEntityOnUpdatedOne:"PicklistStateEntity",
		PicklistStateEntityOnDeletedOne:"PicklistStateEntity",
		HousePartGroupEntityOnCreatedOne:"HousePartGroupEntity",
		HousePartGroupEntityOnUpdatedOne:"HousePartGroupEntity",
		HousePartGroupEntityOnDeletedOne:"HousePartGroupEntity",
		HousePartEntityOnCreatedOne:"HousePartEntity",
		HousePartEntityOnUpdatedOne:"HousePartEntity",
		HousePartEntityOnDeletedOne:"HousePartEntity",
		CargoPhaseEntityOnCreatedOne:"CargoPhaseEntity",
		CargoPhaseEntityOnUpdatedOne:"CargoPhaseEntity",
		CargoPhaseEntityOnDeletedOne:"CargoPhaseEntity",
		CargoTypeEntityOnCreatedOne:"CargoTypeEntity",
		CargoTypeEntityOnUpdatedOne:"CargoTypeEntity",
		CargoTypeEntityOnDeletedOne:"CargoTypeEntity",
		PlanningHumanResourceEntityOnCreatedOne:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityOnUpdatedOne:"PlanningHumanResourceEntity",
		PlanningHumanResourceEntityOnDeletedOne:"PlanningHumanResourceEntity",
		PlanningProjectAssignmentEntityOnCreatedOne:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityOnUpdatedOne:"PlanningProjectAssignmentEntity",
		PlanningProjectAssignmentEntityOnDeletedOne:"PlanningProjectAssignmentEntity",
		PlanningProjectCompetencePlacementEntityOnCreatedOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityOnUpdatedOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectCompetencePlacementEntityOnDeletedOne:"PlanningProjectCompetencePlacementEntity",
		PlanningProjectRequestPlacementEntityOnCreatedOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityOnUpdatedOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectRequestPlacementEntityOnDeletedOne:"PlanningProjectRequestPlacementEntity",
		PlanningProjectEntityOnCreatedOne:"PlanningProjectEntity",
		PlanningProjectEntityOnUpdatedOne:"PlanningProjectEntity",
		PlanningProjectEntityOnDeletedOne:"PlanningProjectEntity",
		PlanningProjectRequestEntityOnCreatedOne:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityOnUpdatedOne:"PlanningProjectRequestEntity",
		PlanningProjectRequestEntityOnDeletedOne:"PlanningProjectRequestEntity",
		PlanningProjectCompetenceEntityOnCreatedOne:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityOnUpdatedOne:"PlanningProjectCompetenceEntity",
		PlanningProjectCompetenceEntityOnDeletedOne:"PlanningProjectCompetenceEntity",
		PlanningCompetenceEntityOnCreatedOne:"PlanningCompetenceEntity",
		PlanningCompetenceEntityOnUpdatedOne:"PlanningCompetenceEntity",
		PlanningCompetenceEntityOnDeletedOne:"PlanningCompetenceEntity",
		CargoStateEntityOnCreatedOne:"CargoStateEntity",
		CargoStateEntityOnUpdatedOne:"CargoStateEntity",
		CargoStateEntityOnDeletedOne:"CargoStateEntity",
		CargoEntityOnCreatedOne:"CargoEntity",
		CargoEntityOnUpdatedOne:"CargoEntity",
		CargoEntityOnDeletedOne:"CargoEntity",
		ProjectHousePartEntityOnCreatedOne:"ProjectHousePartEntity",
		ProjectHousePartEntityOnUpdatedOne:"ProjectHousePartEntity",
		ProjectHousePartEntityOnDeletedOne:"ProjectHousePartEntity",
		PicklistElementEntityOnCreatedOne:"PicklistElementEntity",
		PicklistElementEntityOnUpdatedOne:"PicklistElementEntity",
		PicklistElementEntityOnDeletedOne:"PicklistElementEntity",
		PicklistEntityOnCreatedOne:"PicklistEntity",
		PicklistEntityOnUpdatedOne:"PicklistEntity",
		PicklistEntityOnDeletedOne:"PicklistEntity",
		DrawOrderTypeEntityOnCreatedOne:"DrawOrderTypeEntity",
		DrawOrderTypeEntityOnUpdatedOne:"DrawOrderTypeEntity",
		DrawOrderTypeEntityOnDeletedOne:"DrawOrderTypeEntity",
		HouseModelEntityOnCreatedOne:"HouseModelEntity",
		HouseModelEntityOnUpdatedOne:"HouseModelEntity",
		HouseModelEntityOnDeletedOne:"HouseModelEntity",
		SalesEmployeeEntityOnCreatedOne:"SalesEmployeeEntity",
		SalesEmployeeEntityOnUpdatedOne:"SalesEmployeeEntity",
		SalesEmployeeEntityOnDeletedOne:"SalesEmployeeEntity",
		DrawerAbsentEntityOnCreatedOne:"DrawerAbsentEntity",
		DrawerAbsentEntityOnUpdatedOne:"DrawerAbsentEntity",
		DrawerAbsentEntityOnDeletedOne:"DrawerAbsentEntity",
		DrawerEntityOnCreatedOne:"DrawerEntity",
		DrawerEntityOnUpdatedOne:"DrawerEntity",
		DrawerEntityOnDeletedOne:"DrawerEntity",
		DrawOrderEntityOnCreatedOne:"DrawOrderEntity",
		DrawOrderEntityOnUpdatedOne:"DrawOrderEntity",
		DrawOrderEntityOnDeletedOne:"DrawOrderEntity",
		DateGroupCommentEntityOnCreatedOne:"DateGroupCommentEntity",
		DateGroupCommentEntityOnUpdatedOne:"DateGroupCommentEntity",
		DateGroupCommentEntityOnDeletedOne:"DateGroupCommentEntity",
		DateGroupResponsibleEntityOnCreatedOne:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityOnUpdatedOne:"DateGroupResponsibleEntity",
		DateGroupResponsibleEntityOnDeletedOne:"DateGroupResponsibleEntity",
		LogicFieldEntityOnCreatedOne:"LogicFieldEntity",
		LogicFieldEntityOnUpdatedOne:"LogicFieldEntity",
		LogicFieldEntityOnDeletedOne:"LogicFieldEntity",
		DateGroupEntityOnCreatedOne:"DateGroupEntity",
		DateGroupEntityOnUpdatedOne:"DateGroupEntity",
		DateGroupEntityOnDeletedOne:"DateGroupEntity",
		DateEntityOnCreatedOne:"DateEntity",
		DateEntityOnUpdatedOne:"DateEntity",
		DateEntityOnDeletedOne:"DateEntity",
		DateValueEntityOnCreatedOne:"DateValueEntity",
		DateValueEntityOnUpdatedOne:"DateValueEntity",
		DateValueEntityOnDeletedOne:"DateValueEntity",
		LogicFieldValueEntityOnCreatedOne:"LogicFieldValueEntity",
		LogicFieldValueEntityOnUpdatedOne:"LogicFieldValueEntity",
		LogicFieldValueEntityOnDeletedOne:"LogicFieldValueEntity",
		DateCacheEntityOnCreatedOne:"DateCacheEntity",
		DateCacheEntityOnUpdatedOne:"DateCacheEntity",
		DateCacheEntityOnDeletedOne:"DateCacheEntity",
		YearPlanningModificationEntityOnCreatedOne:"YearPlanningModificationEntity",
		YearPlanningModificationEntityOnUpdatedOne:"YearPlanningModificationEntity",
		YearPlanningModificationEntityOnDeletedOne:"YearPlanningModificationEntity",
		ProjectCachedValueEntityOnCreatedOne:"ProjectCachedValueEntity",
		ProjectCachedValueEntityOnUpdatedOne:"ProjectCachedValueEntity",
		ProjectCachedValueEntityOnDeletedOne:"ProjectCachedValueEntity",
		TableLayoutCommentEntityOnCreatedOne:"TableLayoutCommentEntity",
		TableLayoutCommentEntityOnUpdatedOne:"TableLayoutCommentEntity",
		TableLayoutCommentEntityOnDeletedOne:"TableLayoutCommentEntity",
		ProjectEntityOnCreatedOne:"ProjectEntity",
		ProjectEntityOnUpdatedOne:"ProjectEntity",
		ProjectEntityOnDeletedOne:"ProjectEntity",
		SyncCronEntityOnCreatedOne:"SyncCronEntity",
		SyncCronEntityOnUpdatedOne:"SyncCronEntity",
		SyncCronEntityOnDeletedOne:"SyncCronEntity",
		MailSchedulerEntityOnCreatedOne:"MailSchedulerEntity",
		MailSchedulerEntityOnUpdatedOne:"MailSchedulerEntity",
		MailSchedulerEntityOnDeletedOne:"MailSchedulerEntity",
		ProjectPartnerEntityOnCreatedOne:"ProjectPartnerEntity",
		ProjectPartnerEntityOnUpdatedOne:"ProjectPartnerEntity",
		ProjectPartnerEntityOnDeletedOne:"ProjectPartnerEntity",
		PartnerEntityOnCreatedOne:"PartnerEntity",
		PartnerEntityOnUpdatedOne:"PartnerEntity",
		PartnerEntityOnDeletedOne:"PartnerEntity",
		DocumentMetaEntityOnCreatedOne:"DocumentMetaEntity",
		DocumentMetaEntityOnUpdatedOne:"DocumentMetaEntity",
		DocumentMetaEntityOnDeletedOne:"DocumentMetaEntity",
		EntityAdvancementEntityOnCreatedOne:"EntityAdvancementEntity",
		EntityAdvancementEntityOnUpdatedOne:"EntityAdvancementEntity",
		EntityAdvancementEntityOnDeletedOne:"EntityAdvancementEntity",
		WorkActionCategoryEntityOnCreatedOne:"WorkActionCategoryEntity",
		WorkActionCategoryEntityOnUpdatedOne:"WorkActionCategoryEntity",
		WorkActionCategoryEntityOnDeletedOne:"WorkActionCategoryEntity",
		WorkActionEntityOnCreatedOne:"WorkActionEntity",
		WorkActionEntityOnUpdatedOne:"WorkActionEntity",
		WorkActionEntityOnDeletedOne:"WorkActionEntity",
		EntityImageEntityOnCreatedOne:"EntityImageEntity",
		EntityImageEntityOnUpdatedOne:"EntityImageEntity",
		EntityImageEntityOnDeletedOne:"EntityImageEntity",
		CustomerQuestionEntityOnCreatedOne:"CustomerQuestionEntity",
		CustomerQuestionEntityOnUpdatedOne:"CustomerQuestionEntity",
		CustomerQuestionEntityOnDeletedOne:"CustomerQuestionEntity",
		AuditCategoryEntityOnCreatedOne:"AuditCategoryEntity",
		AuditCategoryEntityOnUpdatedOne:"AuditCategoryEntity",
		AuditCategoryEntityOnDeletedOne:"AuditCategoryEntity",
		AuditQuestionEntityOnCreatedOne:"AuditQuestionEntity",
		AuditQuestionEntityOnUpdatedOne:"AuditQuestionEntity",
		AuditQuestionEntityOnDeletedOne:"AuditQuestionEntity",
		AuditQuestionCostCategoryEntityOnCreatedOne:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityOnUpdatedOne:"AuditQuestionCostCategoryEntity",
		AuditQuestionCostCategoryEntityOnDeletedOne:"AuditQuestionCostCategoryEntity",
		CostCategoryEntityOnCreatedOne:"CostCategoryEntity",
		CostCategoryEntityOnUpdatedOne:"CostCategoryEntity",
		CostCategoryEntityOnDeletedOne:"CostCategoryEntity",
		AuditReportItemImageEntityOnCreatedOne:"AuditReportItemImageEntity",
		AuditReportItemImageEntityOnUpdatedOne:"AuditReportItemImageEntity",
		AuditReportItemImageEntityOnDeletedOne:"AuditReportItemImageEntity",
		AuditReportItemEntityOnCreatedOne:"AuditReportItemEntity",
		AuditReportItemEntityOnUpdatedOne:"AuditReportItemEntity",
		AuditReportItemEntityOnDeletedOne:"AuditReportItemEntity",
		AuditReportEntityOnCreatedOne:"AuditReportEntity",
		AuditReportEntityOnUpdatedOne:"AuditReportEntity",
		AuditReportEntityOnDeletedOne:"AuditReportEntity",
		AuditChecklistImageEntityOnCreatedOne:"AuditChecklistImageEntity",
		AuditChecklistImageEntityOnUpdatedOne:"AuditChecklistImageEntity",
		AuditChecklistImageEntityOnDeletedOne:"AuditChecklistImageEntity",
		InvoiceAttachmentEntityOnCreatedOne:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityOnUpdatedOne:"InvoiceAttachmentEntity",
		InvoiceAttachmentEntityOnDeletedOne:"InvoiceAttachmentEntity",
		EmployeeDocumentEntityOnCreatedOne:"EmployeeDocumentEntity",
		EmployeeDocumentEntityOnUpdatedOne:"EmployeeDocumentEntity",
		EmployeeDocumentEntityOnDeletedOne:"EmployeeDocumentEntity",
		WorkActionTemplateGroupEntityOnCreatedOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityOnUpdatedOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateGroupEntityOnDeletedOne:"WorkActionTemplateGroupEntity",
		WorkActionTemplateEntityOnCreatedOne:"WorkActionTemplateEntity",
		WorkActionTemplateEntityOnUpdatedOne:"WorkActionTemplateEntity",
		WorkActionTemplateEntityOnDeletedOne:"WorkActionTemplateEntity",
		ProjectCostInfoEntityOnCreatedOne:"ProjectCostInfoEntity",
		ProjectCostInfoEntityOnUpdatedOne:"ProjectCostInfoEntity",
		ProjectCostInfoEntityOnDeletedOne:"ProjectCostInfoEntity",
		BlockedCalendarWeekEntityOnCreatedOne:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityOnUpdatedOne:"BlockedCalendarWeekEntity",
		BlockedCalendarWeekEntityOnDeletedOne:"BlockedCalendarWeekEntity",
		MaintenanceReportEntityOnCreatedOne:"MaintenanceReportEntity",
		MaintenanceReportEntityOnUpdatedOne:"MaintenanceReportEntity",
		MaintenanceReportEntityOnDeletedOne:"MaintenanceReportEntity",
		ConfigurationEntityOnCreatedOne:"ConfigurationEntity",
		ConfigurationEntityOnUpdatedOne:"ConfigurationEntity",
		ConfigurationEntityOnDeletedOne:"ConfigurationEntity",
		ServiceMeterEntityOnCreatedOne:"ServiceMeterEntity",
		ServiceMeterEntityOnUpdatedOne:"ServiceMeterEntity",
		ServiceMeterEntityOnDeletedOne:"ServiceMeterEntity",
		DeliveryListMeterEntityOnCreatedOne:"DeliveryListMeterEntity",
		DeliveryListMeterEntityOnUpdatedOne:"DeliveryListMeterEntity",
		DeliveryListMeterEntityOnDeletedOne:"DeliveryListMeterEntity",
		ServiceSpaceEntityOnCreatedOne:"ServiceSpaceEntity",
		ServiceSpaceEntityOnUpdatedOne:"ServiceSpaceEntity",
		ServiceSpaceEntityOnDeletedOne:"ServiceSpaceEntity",
		ServiceTypeEntityOnCreatedOne:"ServiceTypeEntity",
		ServiceTypeEntityOnUpdatedOne:"ServiceTypeEntity",
		ServiceTypeEntityOnDeletedOne:"ServiceTypeEntity",
		DeliveryListPointImageEntityOnCreatedOne:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityOnUpdatedOne:"DeliveryListPointImageEntity",
		DeliveryListPointImageEntityOnDeletedOne:"DeliveryListPointImageEntity",
		ServicePriorityEntityOnCreatedOne:"ServicePriorityEntity",
		ServicePriorityEntityOnUpdatedOne:"ServicePriorityEntity",
		ServicePriorityEntityOnDeletedOne:"ServicePriorityEntity",
		DeliveryListPointEntityOnCreatedOne:"DeliveryListPointEntity",
		DeliveryListPointEntityOnUpdatedOne:"DeliveryListPointEntity",
		DeliveryListPointEntityOnDeletedOne:"DeliveryListPointEntity",
		DeliveryListSignatureEntityOnCreatedOne:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityOnUpdatedOne:"DeliveryListSignatureEntity",
		DeliveryListSignatureEntityOnDeletedOne:"DeliveryListSignatureEntity",
		DeliveryListEntityOnCreatedOne:"DeliveryListEntity",
		DeliveryListEntityOnUpdatedOne:"DeliveryListEntity",
		DeliveryListEntityOnDeletedOne:"DeliveryListEntity",
		SupplyInspectionReportAnswerEntityOnCreatedOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityOnUpdatedOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportAnswerEntityOnDeletedOne:"SupplyInspectionReportAnswerEntity",
		SupplyInspectionReportEntityOnCreatedOne:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityOnUpdatedOne:"SupplyInspectionReportEntity",
		SupplyInspectionReportEntityOnDeletedOne:"SupplyInspectionReportEntity",
		DepartmentEntityOnCreatedOne:"DepartmentEntity",
		DepartmentEntityOnUpdatedOne:"DepartmentEntity",
		DepartmentEntityOnDeletedOne:"DepartmentEntity",
		DrawOrderDescriptionEntityOnCreatedOne:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityOnUpdatedOne:"DrawOrderDescriptionEntity",
		DrawOrderDescriptionEntityOnDeletedOne:"DrawOrderDescriptionEntity",
		GreetingEntityOnCreatedOne:"GreetingEntity",
		GreetingEntityOnUpdatedOne:"GreetingEntity",
		GreetingEntityOnDeletedOne:"GreetingEntity",
		HourDeclarationTypeEntityOnCreatedOne:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityOnUpdatedOne:"HourDeclarationTypeEntity",
		HourDeclarationTypeEntityOnDeletedOne:"HourDeclarationTypeEntity",
		HourDeclarationActivityEntityOnCreatedOne:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityOnUpdatedOne:"HourDeclarationActivityEntity",
		HourDeclarationActivityEntityOnDeletedOne:"HourDeclarationActivityEntity",
		HourDeclarationEntityOnCreatedOne:"HourDeclarationEntity",
		HourDeclarationEntityOnUpdatedOne:"HourDeclarationEntity",
		HourDeclarationEntityOnDeletedOne:"HourDeclarationEntity",
		HourDeclarationApprovalRequestEntityOnCreatedOne:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityOnUpdatedOne:"HourDeclarationApprovalRequestEntity",
		HourDeclarationApprovalRequestEntityOnDeletedOne:"HourDeclarationApprovalRequestEntity",
		InstallationReportCommentEntityOnCreatedOne:"InstallationReportCommentEntity",
		InstallationReportCommentEntityOnUpdatedOne:"InstallationReportCommentEntity",
		InstallationReportCommentEntityOnDeletedOne:"InstallationReportCommentEntity",
		InstallationReportDescriptionEntityOnCreatedOne:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityOnUpdatedOne:"InstallationReportDescriptionEntity",
		InstallationReportDescriptionEntityOnDeletedOne:"InstallationReportDescriptionEntity",
		InstallationReportFinalEntityOnCreatedOne:"InstallationReportFinalEntity",
		InstallationReportFinalEntityOnUpdatedOne:"InstallationReportFinalEntity",
		InstallationReportFinalEntityOnDeletedOne:"InstallationReportFinalEntity",
		InstallationReportMeasurementTemplateEntityOnCreatedOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityOnUpdatedOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementTemplateEntityOnDeletedOne:"InstallationReportMeasurementTemplateEntity",
		InstallationReportMeasurementEntityOnCreatedOne:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityOnUpdatedOne:"InstallationReportMeasurementEntity",
		InstallationReportMeasurementEntityOnDeletedOne:"InstallationReportMeasurementEntity",
		InstallationReportQuestionGroupEntityOnCreatedOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityOnUpdatedOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionGroupEntityOnDeletedOne:"InstallationReportQuestionGroupEntity",
		InstallationReportQuestionEntityOnCreatedOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityOnUpdatedOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionEntityOnDeletedOne:"InstallationReportQuestionEntity",
		InstallationReportQuestionAnswerEntityOnCreatedOne:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityOnUpdatedOne:"InstallationReportQuestionAnswerEntity",
		InstallationReportQuestionAnswerEntityOnDeletedOne:"InstallationReportQuestionAnswerEntity",
		LegacyProjectEntityOnCreatedOne:"LegacyProjectEntity",
		LegacyProjectEntityOnUpdatedOne:"LegacyProjectEntity",
		LegacyProjectEntityOnDeletedOne:"LegacyProjectEntity",
		PicklistCommentEntityOnCreatedOne:"PicklistCommentEntity",
		PicklistCommentEntityOnUpdatedOne:"PicklistCommentEntity",
		PicklistCommentEntityOnDeletedOne:"PicklistCommentEntity",
		PicklistElementStateEntityOnCreatedOne:"PicklistElementStateEntity",
		PicklistElementStateEntityOnUpdatedOne:"PicklistElementStateEntity",
		PicklistElementStateEntityOnDeletedOne:"PicklistElementStateEntity",
		PlanningProjectItemEntityOnCreatedOne:"PlanningProjectItemEntity",
		PlanningProjectItemEntityOnUpdatedOne:"PlanningProjectItemEntity",
		PlanningProjectItemEntityOnDeletedOne:"PlanningProjectItemEntity",
		ProjectCargoStateEntityOnCreatedOne:"ProjectCargoStateEntity",
		ProjectCargoStateEntityOnUpdatedOne:"ProjectCargoStateEntity",
		ProjectCargoStateEntityOnDeletedOne:"ProjectCargoStateEntity",
		ProjectPaymentEntityOnCreatedOne:"ProjectPaymentEntity",
		ProjectPaymentEntityOnUpdatedOne:"ProjectPaymentEntity",
		ProjectPaymentEntityOnDeletedOne:"ProjectPaymentEntity",
		QualityAssuranceEntityOnCreatedOne:"QualityAssuranceEntity",
		QualityAssuranceEntityOnUpdatedOne:"QualityAssuranceEntity",
		QualityAssuranceEntityOnDeletedOne:"QualityAssuranceEntity",
		ServiceMapColorEntityOnCreatedOne:"ServiceMapColorEntity",
		ServiceMapColorEntityOnUpdatedOne:"ServiceMapColorEntity",
		ServiceMapColorEntityOnDeletedOne:"ServiceMapColorEntity",
		ServicePlanningStageEntityOnCreatedOne:"ServicePlanningStageEntity",
		ServicePlanningStageEntityOnUpdatedOne:"ServicePlanningStageEntity",
		ServicePlanningStageEntityOnDeletedOne:"ServicePlanningStageEntity",
		ServicePlanningEventEntityOnCreatedOne:"ServicePlanningEventEntity",
		ServicePlanningEventEntityOnUpdatedOne:"ServicePlanningEventEntity",
		ServicePlanningEventEntityOnDeletedOne:"ServicePlanningEventEntity",
		ServicePlanningAssignmentEntityOnCreatedOne:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityOnUpdatedOne:"ServicePlanningAssignmentEntity",
		ServicePlanningAssignmentEntityOnDeletedOne:"ServicePlanningAssignmentEntity",
		ServicePresetEntityOnCreatedOne:"ServicePresetEntity",
		ServicePresetEntityOnUpdatedOne:"ServicePresetEntity",
		ServicePresetEntityOnDeletedOne:"ServicePresetEntity",
		SupplierDocumentEntityOnCreatedOne:"SupplierDocumentEntity",
		SupplierDocumentEntityOnUpdatedOne:"SupplierDocumentEntity",
		SupplierDocumentEntityOnDeletedOne:"SupplierDocumentEntity",
		SupplyInspectionQuestionEntityOnCreatedOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityOnUpdatedOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionEntityOnDeletedOne:"SupplyInspectionQuestionEntity",
		SupplyInspectionQuestionGroupEntityOnCreatedOne:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityOnUpdatedOne:"SupplyInspectionQuestionGroupEntity",
		SupplyInspectionQuestionGroupEntityOnDeletedOne:"SupplyInspectionQuestionGroupEntity",
		UserPasswordResetEntityOnCreatedOne:"UserPasswordResetEntity",
		UserPasswordResetEntityOnUpdatedOne:"UserPasswordResetEntity",
		UserPasswordResetEntityOnDeletedOne:"UserPasswordResetEntity",
		YearPlanningLineEntityOnCreatedOne:"YearPlanningLineEntity",
		YearPlanningLineEntityOnUpdatedOne:"YearPlanningLineEntity",
		YearPlanningLineEntityOnDeletedOne:"YearPlanningLineEntity",
		InvoicePaymentBatchEntityEntityOnCreatedOne:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityOnUpdatedOne:"InvoicePaymentBatchEntityEntity",
		InvoicePaymentBatchEntityEntityOnDeletedOne:"InvoicePaymentBatchEntityEntity",
		AppPhaseContactEntityOnCreatedOne:"AppPhaseContactEntity",
		AppPhaseContactEntityOnUpdatedOne:"AppPhaseContactEntity",
		AppPhaseContactEntityOnDeletedOne:"AppPhaseContactEntity",
		AppPhaseEntityOnCreatedOne:"AppPhaseEntity",
		AppPhaseEntityOnUpdatedOne:"AppPhaseEntity",
		AppPhaseEntityOnDeletedOne:"AppPhaseEntity",
		AppPhaseSectionEntityOnCreatedOne:"AppPhaseSectionEntity",
		AppPhaseSectionEntityOnUpdatedOne:"AppPhaseSectionEntity",
		AppPhaseSectionEntityOnDeletedOne:"AppPhaseSectionEntity",
		AppNotificationTemplateEntityOnCreatedOne:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityOnUpdatedOne:"AppNotificationTemplateEntity",
		AppNotificationTemplateEntityOnDeletedOne:"AppNotificationTemplateEntity",
		AppPhaseSectionMarkEntityOnCreatedOne:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityOnUpdatedOne:"AppPhaseSectionMarkEntity",
		AppPhaseSectionMarkEntityOnDeletedOne:"AppPhaseSectionMarkEntity",
		AppProjectDateEntityOnCreatedOne:"AppProjectDateEntity",
		AppProjectDateEntityOnUpdatedOne:"AppProjectDateEntity",
		AppProjectDateEntityOnDeletedOne:"AppProjectDateEntity",
		AppNotificationScheduleEntityOnCreatedOne:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityOnUpdatedOne:"AppNotificationScheduleEntity",
		AppNotificationScheduleEntityOnDeletedOne:"AppNotificationScheduleEntity",
		AppPhaseSectionMarkValueEntityOnCreatedOne:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityOnUpdatedOne:"AppPhaseSectionMarkValueEntity",
		AppPhaseSectionMarkValueEntityOnDeletedOne:"AppPhaseSectionMarkValueEntity",
		AppInviteEntityOnCreatedOne:"AppInviteEntity",
		AppInviteEntityOnUpdatedOne:"AppInviteEntity",
		AppInviteEntityOnDeletedOne:"AppInviteEntity",
		AppLoginEntityOnCreatedOne:"AppLoginEntity",
		AppLoginEntityOnUpdatedOne:"AppLoginEntity",
		AppLoginEntityOnDeletedOne:"AppLoginEntity",
		AppNotificationEntityOnCreatedOne:"AppNotificationEntity",
		AppNotificationEntityOnUpdatedOne:"AppNotificationEntity",
		AppNotificationEntityOnDeletedOne:"AppNotificationEntity",
		AppProjectEntityOnCreatedOne:"AppProjectEntity",
		AppProjectEntityOnUpdatedOne:"AppProjectEntity",
		AppProjectEntityOnDeletedOne:"AppProjectEntity",
		AppUserEntityOnCreatedOne:"AppUserEntity",
		AppUserEntityOnUpdatedOne:"AppUserEntity",
		AppUserEntityOnDeletedOne:"AppUserEntity",
		AppProjectCostEntityOnCreatedOne:"AppProjectCostEntity",
		AppProjectCostEntityOnUpdatedOne:"AppProjectCostEntity",
		AppProjectCostEntityOnDeletedOne:"AppProjectCostEntity",
		AppProjectContactEntityOnCreatedOne:"AppProjectContactEntity",
		AppProjectContactEntityOnUpdatedOne:"AppProjectContactEntity",
		AppProjectContactEntityOnDeletedOne:"AppProjectContactEntity",
		AppBuildingWeekEntityOnCreatedOne:"AppBuildingWeekEntity",
		AppBuildingWeekEntityOnUpdatedOne:"AppBuildingWeekEntity",
		AppBuildingWeekEntityOnDeletedOne:"AppBuildingWeekEntity",
		AppContentEntityOnCreatedOne:"AppContentEntity",
		AppContentEntityOnUpdatedOne:"AppContentEntity",
		AppContentEntityOnDeletedOne:"AppContentEntity",
		AppConversationMessagePresetEntityOnCreatedOne:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityOnUpdatedOne:"AppConversationMessagePresetEntity",
		AppConversationMessagePresetEntityOnDeletedOne:"AppConversationMessagePresetEntity",
		AppDocumentSignatureEntityOnCreatedOne:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityOnUpdatedOne:"AppDocumentSignatureEntity",
		AppDocumentSignatureEntityOnDeletedOne:"AppDocumentSignatureEntity",
		AppDocumentTypeEntityOnCreatedOne:"AppDocumentTypeEntity",
		AppDocumentTypeEntityOnUpdatedOne:"AppDocumentTypeEntity",
		AppDocumentTypeEntityOnDeletedOne:"AppDocumentTypeEntity",
		AppDocumentEntityOnCreatedOne:"AppDocumentEntity",
		AppDocumentEntityOnUpdatedOne:"AppDocumentEntity",
		AppDocumentEntityOnDeletedOne:"AppDocumentEntity",
		AppNotificationScheduleResourceEntityOnCreatedOne:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityOnUpdatedOne:"AppNotificationScheduleResourceEntity",
		AppNotificationScheduleResourceEntityOnDeletedOne:"AppNotificationScheduleResourceEntity",
		AppPlanCompetenceEntityOnCreatedOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityOnUpdatedOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceEntityOnDeletedOne:"AppPlanCompetenceEntity",
		AppPlanCompetenceSettingEntityOnCreatedOne:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityOnUpdatedOne:"AppPlanCompetenceSettingEntity",
		AppPlanCompetenceSettingEntityOnDeletedOne:"AppPlanCompetenceSettingEntity",
		AppProjectCostPresetEntityOnCreatedOne:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityOnUpdatedOne:"AppProjectCostPresetEntity",
		AppProjectCostPresetEntityOnDeletedOne:"AppProjectCostPresetEntity",
		AppProjectFieldSheetEntityOnCreatedOne:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityOnUpdatedOne:"AppProjectFieldSheetEntity",
		AppProjectFieldSheetEntityOnDeletedOne:"AppProjectFieldSheetEntity",
		AppProjectFieldGroupEntityOnCreatedOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityOnUpdatedOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupEntityOnDeletedOne:"AppProjectFieldGroupEntity",
		AppProjectFieldGroupAssignmentEntityOnCreatedOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityOnUpdatedOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentEntityOnDeletedOne:"AppProjectFieldGroupAssignmentEntity",
		AppProjectFieldGroupAssignmentValueEntityOnCreatedOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityOnUpdatedOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldGroupAssignmentValueEntityOnDeletedOne:"AppProjectFieldGroupAssignmentValueEntity",
		AppProjectFieldEntityOnCreatedOne:"AppProjectFieldEntity",
		AppProjectFieldEntityOnUpdatedOne:"AppProjectFieldEntity",
		AppProjectFieldEntityOnDeletedOne:"AppProjectFieldEntity",
		AppServiceCategoryEntityOnCreatedOne:"AppServiceCategoryEntity",
		AppServiceCategoryEntityOnUpdatedOne:"AppServiceCategoryEntity",
		AppServiceCategoryEntityOnDeletedOne:"AppServiceCategoryEntity",
		AppTextEntityOnCreatedOne:"AppTextEntity",
		AppTextEntityOnUpdatedOne:"AppTextEntity",
		AppTextEntityOnDeletedOne:"AppTextEntity",
		AppRelationEntityOnCreatedOne:"AppRelationEntity",
		AppRelationEntityOnUpdatedOne:"AppRelationEntity",
		AppRelationEntityOnDeletedOne:"AppRelationEntity"
	}
}