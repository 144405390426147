import { Bloc } from "../../bloc/bloc";
import { Apollo } from "apollo-angular";
import { DocumentMeta } from "src/app/documents/document-meta.entity";
import { uploadDocumentMutation } from "src/app/delivery-list/react/signature/signature.mutation";

export class TemplateBlocState {
  constructor(
    readonly props: {
      readonly imageVersion: number;
    }
  ) {}
}

export enum TemplateDocumentId {
  Front = "service-settings_front-template",
  Intro = "service-settings_intro-template",
}

export class TemplateBlocEvent {
  constructor(
    readonly props: {
      readonly file: File;
      readonly documentId: TemplateDocumentId;
    }
  ) {}
}

export class TemplateBloc extends Bloc<TemplateBlocEvent, TemplateBlocState> {
  constructor(protected readonly apollo: Apollo) {
    super(new TemplateBlocState({ imageVersion: Math.random() }));
  }

  async *mapEventToState(event: TemplateBlocEvent) {
    const {} = await this.apollo
      .mutate<{ document: DocumentMeta }>({
        mutation: uploadDocumentMutation,
        variables: {
          id: event.props.documentId.toString(),
          file: event.props.file,
          projectId: "3000",
        },
        context: {
          useMultipart: true,
        },
      })
      .toPromise();

    yield new TemplateBlocState({ imageVersion: Math.random() });
  }
}
