import * as React from "react";
import { HttpClient } from "@angular/common/http";
import { useState, useEffect } from "react";
import { DebouncePromise } from "../tools/debounce-promise";
import { environment } from "src/environments/environment";

interface SercureImageProps {
  src: string;
  http: HttpClient;
  noCache?: boolean;
  noDebounce?: boolean;
  style?: React.CSSProperties;
  placeholder?: JSX.Element;
  className?: string;
}

export const SecureImage: React.FC<SercureImageProps> = ({
  src,
  http,
  style,
  className,
  noCache,
  placeholder,
  noDebounce,
}) => {
  const fullSrc = React.useMemo(() => {
    const isFromApi = !src?.startsWith("data") && !src?.startsWith("http");

    return isFromApi ? `${environment.baseUrl}/${src}` : src;
  }, [src]);

  return src && <img className={className} style={style || {}} src={fullSrc} />;
};
