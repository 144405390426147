import { Component, OnInit } from '@angular/core';
import { EntityManager } from '../entity.service';
import { CostCategory, AuditQuestionCostCategory } from './cost-category.entity';
import { RestResponse } from '../rest.service';
import { AuditQuestion } from '../audit-sheet/audit-question.entity';
import { AuditCategory } from '../audit-sheet/audit-category.entity';
import { AuditOrderableEntity } from '../audit-sheet/audit-orderable.entity';
import * as _ from 'lodash';

@Component({
  selector: 'app-audit-sheet-table',
  templateUrl: './audit-sheet-table.component.html',
  styleUrls: ['./audit-sheet-table.component.scss']
})
export class AuditSheetTableComponent implements OnInit {
  responseCategories: RestResponse<CostCategory[]>;
  responseQuestionCategories: RestResponse<AuditCategory[]>;

  protected get auditCategoryService() {
    return this.entityManager.get(AuditCategory);
  }

  protected get costCategoryService() {
    return this.entityManager.get(CostCategory);
  }

  protected get auditQuestionCostCategoryService() {
    return this.entityManager.get(AuditQuestionCostCategory);
  }

  constructor(protected readonly entityManager: EntityManager) { }

  ngOnInit() {
    this.fetchCategories();
    this.fetchQuestionCategories();
  }

  isChecked(cost: CostCategory, question: AuditQuestion) {
    return !!this.findCheck(cost, question);
  }

  async onToggle(cost: CostCategory, question: AuditQuestion) {
    const item = this.findCheck(cost, question);

    if (item) {
      const response = await this.auditQuestionCostCategoryService.delete(item.id);

      if (!response.hasError()) {
        const index = cost.__auditQuestionCostCategories__.indexOf(item);
        cost.__auditQuestionCostCategories__.splice(index, 1);
      }
    } else {
      const candidate = this.auditQuestionCostCategoryService.concept({
        costCategoryId: cost.id,
        auditQuestionId: question.id,
      });
      const response = await this.auditQuestionCostCategoryService.save(candidate);

      if (!response.hasError()) {
        cost.__auditQuestionCostCategories__.push(response.value);
      }
    }
  }

  protected findCheck(cost: CostCategory, question: AuditQuestion) {
    return cost.__auditQuestionCostCategories__
      .find(d => d.auditQuestionId === question.id);
  }

  protected async fetchCategories() {
    this.responseCategories = await this.costCategoryService.query({
      filters: [{ field: 'isChecklist', operator: 'Equal', value: 'ja' }],
      orders: [{ field: 'checklistOrder', direction: 'ASC' }],
      relations: ['auditQuestionCostCategories']
    });
  }

  protected async fetchQuestionCategories() {
    const response = await this.auditCategoryService.query({
      relations: ['auditQuestions']
    });

    if (!response.hasError()) {
      const candidate = new RestResponse(
        this.sort(response.value)
      );

      candidate.value.forEach(c => c.__auditQuestions__ =
        this.sort(c.__auditQuestions__));

      this.responseQuestionCategories = candidate;
    }
  }

  protected sort<T extends AuditOrderableEntity>(items: T[]) {
    return _.orderBy(items, i => i.orderId);
  }
}
