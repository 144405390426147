import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrintService } from '../print.service';

@Component({
  selector: 'app-cargo-print',
  templateUrl: './cargo-print.component.html',
  styleUrls: ['./cargo-print.component.scss']
})
export class CargoPrintComponent implements OnInit {
  data: any;

  constructor(
    protected readonly router: Router,
    protected readonly printService: PrintService,
  ) {
    const currentNav = router.getCurrentNavigation();
    this.data = currentNav ? currentNav.extras.state : null;
  }

  ngOnInit() {
    if (this.data) {
      this.printService.onDataReady();
    }
  }
}
