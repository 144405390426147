import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import { Job } from "../jobs/job.entity";
import { EntityManager, Entity } from "../entity.service";

@Component({
  selector: "app-job-cron-dialog",
  templateUrl: "./job-cron-dialog.component.html",
  styleUrls: ["./job-cron-dialog.component.scss"],
})
export class JobCronDialogComponent {
  cron: string;

  get service() {
    return this.entities.get(SyncCron);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly job: Job,
    protected readonly entities: EntityManager,
    protected readonly ref: MdcDialogRef<JobCronDialogComponent>,
    protected readonly snack: MdcSnackbar
  ) {
    this.cron = job.cronFinal;
  }

  async save() {
    const candidate = this.service.concept({ cron: this.cron });
    candidate.isConcept = false;
    candidate.id = this.job.source;

    if (!(await this.service.save(candidate)).hasError()) {
      this.ref.close();
      this.snack.open("Opgeslagen", "Ok");
    }
  }
}

class SyncCron extends Entity {
  type = "sync_crons";

  cron: string;
}
