import { first, last } from "lodash";
import * as React from "react";
import { useContext, useState } from "react";
import SplitPane from "react-split-pane";
import Pane from "react-split-pane";
import { PlanningComponent } from "./components/planning.component";
import { ProjectsComponent } from "./components/projects.component";
import { TableLayoutContext } from "./context/table-layout.context";

export function MainSplitter() {
  const { projectsVisible } = useContext(TableLayoutContext);
  const [currentSize, setCurrentSize] = useState<string[]>(null);

  return (
    <SplitPane
      className="split-pane"
      split="horizontal"
      onChange={(value: any) => setCurrentSize(value)}
    >
      <Pane size={first(currentSize)}>
        <PlanningComponent />
      </Pane>
      <Pane size={projectsVisible ? last(currentSize) : "0px"}>
        <ProjectsComponent />
      </Pane>
    </SplitPane>
  );
}
