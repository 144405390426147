import { ComplexTableDataRow } from "../complex-table/complex-table.component";

export class MetacomProjectRelation {
  project: string;
  project_oms: string;
  relatie: string;
  relatie_naam: string;
  telefoon: string;
  email: string;
}

export class ProjectRelationTableRow extends ComplexTableDataRow<MetacomProjectRelation> {
  constructor(data: MetacomProjectRelation) {
    super(data);
  }
}