import { useMemo } from "react";
import { createTheme } from "@material-ui/core";

export const useMuiPortalTheme = () =>
  useMemo(
    () =>
      createTheme({
        palette: {
          primary: { main: "#0099cc" },
          secondary: { main: "#666" },
        },
      }),
    []
  );
