import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { RestResponse } from "../rest.service";
import { MetacomService } from "../metacom.service";
import { Subject } from "rxjs";
import { tap, debounceTime } from "rxjs/operators";
import { EntityManager } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { DeliveryOrderDialogComponent } from "../delivery-order-dialog/delivery-order-dialog.component";
import { DialogService } from "../dialog.service";
import * as _ from "lodash";
import * as moment from "moment";
import { ScanGoodsComponent } from "../scan-goods/scan-goods.component";
import { flatten } from "lodash";
import { ActivatedRoute } from "@angular/router";

enum GROUP_TYPE {
  SUPPLIER = "relatie",
  PROJECT = "project",
  DELIVERY_DATE = "datum_gepland",
}

@Component({
  selector: "app-deliveries",
  templateUrl: "./deliveries.component.html",
  styleUrls: ["./deliveries.component.scss"],
})
export class DeliveriesComponent implements OnInit {
  readonly FILTER_FIELDS = ["project"];

  filterQuery = "";
  groupType: string = GROUP_TYPE.SUPPLIER;
  response: RestResponse<DeliveryGroup[]>;

  relationIdModel: string;
  relationFilterConfig = {
    allowNothing: true,
    title: "Relatie",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "isSupplier", operator: "Equal", value: "t" },
    ],
  };

  get settings() {
    return this.route.snapshot.data.settings as DeliveriesComponentSettings;
  }

  protected filterQueryChanged = new Subject<string>();

  protected groups = [
    { id: GROUP_TYPE.SUPPLIER, makeLabel: (d: DeliveryRow) => d.relatie_oms },
    { id: GROUP_TYPE.PROJECT, makeLabel: (d: DeliveryRow) => d.project },
    {
      id: GROUP_TYPE.DELIVERY_DATE,
      makeLabel: (d: DeliveryRow) => d.datum_gepland,
      sortField: "datum_gepland_sortable",
    },
  ];

  protected get userService() {
    return this.entityManager.get(User);
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly dialogService: DialogService,
    protected readonly entityManager: EntityManager,
    protected readonly metacomService: MetacomService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500)
      )
      .subscribe(() => this.fetch());

    this.onFilterQuery();
  }

  async scanGoods() {
    const goodsId = await this.dialogService.open(
      this.changeDetector,
      ScanGoodsComponent,
      {}
    );

    if (goodsId !== "close") {
      const items = flatten(this.response.value.map((group) => group.items));
      const targetGoods = items.find((item) => item.data.opdracht == goodsId);

      await this.openOrder(targetGoods);
    }
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  async openOrder(delivery: Delivery) {
    const result = await this.dialogService.open(
      this.changeDetector,
      DeliveryOrderDialogComponent,
      {
        data: {
          orderId: delivery.data.opdracht,
          orderName: delivery.data.opdracht_oms,
          relationName: delivery.data.relatie_oms,
          rows: delivery.rows,
          settings: this.settings,
        },
      }
    );

    if (result === "save") {
      return this.fetch();
    }
  }

  async fetch() {
    const response = await this.metacomService.queryTableAsync<DeliveryRow>({
      setName: "metacom",
      tableName: this.settings.deliveriesTableName,
      filter: this.relationIdModel
        ? `reg_mutatie.van_kpl = '${this.relationIdModel}'`
        : "",
    });

    if (!response.hasError()) {
      const group = this.getGroup(this.groupType);

      this.response = new RestResponse(
        _.chain(response.value)
          .filter((r) => this.matchQuery(r))
          .forEach((r) => this.reformatDates(r))
          .groupBy((r) => r[group.id])
          .map(
            (a, b) =>
              new DeliveryGroup(
                b,
                group.makeLabel(_.first(a)),
                _.chain(a)
                  .groupBy((d) => d.opdracht)
                  .map((sub, ey) => new Delivery(sub))
                  .value()
              )
          )
          .orderBy((g) => _.first(g.items).data[group.sortField || group.id])
          .value()
      );
    }
  }

  protected getGroup(id: string) {
    return this.groups.find((d) => d.id === id);
  }

  protected matchQuery(row: DeliveryRow) {
    return this.filterQuery
      ? !!this.FILTER_FIELDS.find(
          (f) =>
            row[f].toLowerCase().indexOf(this.filterQuery.toLowerCase()) >= 0
        )
      : true;
  }

  protected reformatDates(row: DeliveryRow) {
    const format = "DD-MM-YYYY";
    row.datum_opdracht = moment(row.datum_opdracht).format(format);
    row.datum_gepland = moment(row.datum_gepland).format(format);
    row.datum_gepland_sortable = moment(row.datum_gepland, format).unix();
  }
}

class DeliveryGroup {
  constructor(
    readonly groupId: string,
    readonly groupName: string,
    readonly items: Delivery[]
  ) {}
}

class Delivery {
  constructor(readonly rows: DeliveryRow[]) {}

  get data() {
    return _.first(this.rows);
  }
}

export interface DeliveryRow {
  opdracht: string;
  opdracht_oms: string;
  project: string;
  project_oms: string;
  relatie: string;
  relatie_oms: string;
  datum_opdracht: string;
  datum_gepland: string;
  datum_gepland_sortable: number;
  opmerking: string;
  memo: string;
  middel: string;
  middel_oms: string;
  middel_hv_besteld: number;
  middel_hv_open: number;
  middel_eenheid: string;
  bestel_eenheid: string;

  naar_bedrijf: number;
  bron_doctype: string;
  bron_regel: string;
  kostensoort: string;
  eenheidsprijs: string;

  naar_administratie: string;

  isCompleted: boolean;
  quantityUpdate: number;
}

export interface DeliveriesComponentSettings {
  companyId: number;
  deliveriesTableName: string;
}
