import { Entity } from "../entity.service";

export class ProjectPayment extends Entity {
  type = "project_payments";

  projectId: string;
  userId: string;
  description: string;
  payedAt: Date | string;
  amount: number;

  onLoad() {
    this.amount = parseFloat(<any>this.amount);
  }
}
