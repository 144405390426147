import { Component } from "@angular/core";

@Component({
  selector: "app-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.scss"],
})
export class TestComponent {
  data = {
    isStandalone: !!window["standalone"],
    isStandaloneMedia: window.matchMedia("(display-mode: standalone)").matches,
  };

  protected isExternal(url: string) {
    return url.startsWith("http://") || url.startsWith("https://");
  }

  protected isStandalone() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      !!window["standalone"]
    );
  }
}
