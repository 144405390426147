import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { RestResponse } from "../rest.service";

@Component({
  selector: "app-transaction-dialog",
  templateUrl: "./transaction-dialog.component.html",
  styleUrls: ["./transaction-dialog.component.scss"],
})
export class TransactionDialogComponent implements OnInit {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: TransactionDialogComponentData,
    readonly dialogRef: MdcDialogRef<TransactionDialogComponent>
  ) {}

  isCompleted = false;

  async ngOnInit() {
    await this.exec();
  }

  show(transaction: ITransaction) {
    return transaction.executionContext || !!transaction.children.length;
  }

  protected async exec() {
    try {
      await this.data.root.exec();

      if (!this.data.root.errorMessage) {
        setTimeout(() => this.dialogRef.close(), 1000);
      }
    } finally {
      this.isCompleted = true;
    }
  }
}

export class TransactionDialogComponentData {
  constructor(readonly root: ITransaction) {}
}

interface ITransaction {
  name: string;
  result: any;
  executing: boolean;
  errorMessage: string;
  children?: ITransaction[];
  executionContext?: any;

  exec(): Promise<any> | void;
}

export class Transaction implements ITransaction {
  result: any;
  executing: boolean;
  errorMessage: string;

  constructor(
    readonly name: string,
    readonly executionContext?: () => Promise<RestResponse<any>>,
    readonly children?: ITransaction[]
  ) {
    this.children = this.children || [];
  }

  static group(childs: ITransaction[], name: string = "Transactie") {
    return new Transaction(name, null, childs);
  }

  static execution(
    name: string,
    executionContext?: () => Promise<RestResponse<any>>
  ) {
    return new Transaction(name, executionContext);
  }

  async exec() {
    try {
      this.executing = true;

      if (this.executionContext) {
        const response = await this.executionContext();

        if (response.hasError()) {
          throw new Error(response.error.message);
        }

        this.result = response.value || {};
      } else {
        for (const child of this.children) {
          await child.exec();
        }

        this.result = true;
      }
    } catch (e) {
      this.errorMessage = e.message;
      throw e;
    } finally {
      this.executing = false;
    }
  }
}
