import { Equatable } from "@comceptum-software/react-bloc";
import {
  ConstructionPlanningColorLineDto,
  ConstructionPlanningDateDto,
  ConstructionPlanningDateGroupDto,
  ConstructionPlanningProjectDto,
} from "./construction-planning-date.dto";
import { ConstructionPlanningFilter } from "./construction-planning.event";

export abstract class ConstructionPlanningState extends Equatable {}

export class ConstructionPlanningLoadInProgressState extends ConstructionPlanningState {
  props = () => [];
}

export class ConstructionPlanningLoadSuccessState extends ConstructionPlanningState {
  readonly isReloading?: boolean;

  readonly filter: ConstructionPlanningFilter;
  readonly commentDateGroup: ConstructionPlanningDateGroupDto;
  readonly projects: ConstructionPlanningProjectDto[];
  readonly dates: ConstructionPlanningDateDto[];
  readonly lines: ConstructionPlanningColorLineDto[];

  constructor(props: {
    readonly isReloading?: boolean;
    readonly filter: ConstructionPlanningFilter;
    readonly commentDateGroup: ConstructionPlanningDateGroupDto;
    readonly projects: ConstructionPlanningProjectDto[];
    readonly dates: ConstructionPlanningDateDto[];
    readonly lines: ConstructionPlanningColorLineDto[];
  }) {
    super(props);
  }

  props = () => [
    this.isReloading,
    this.filter,
    this.commentDateGroup,
    this.projects,
    this.dates,
    this.lines,
  ];
}

export class ConstructionPlanningLoadFailureState extends ConstructionPlanningState {
  readonly errorMessage: string;

  constructor(props: { readonly errorMessage: string }) {
    super(props);
  }

  props = () => [this.errorMessage];
}
