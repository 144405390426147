import { AuditQuestion } from './audit-question.entity';
import { AuditOrderableEntity } from './audit-orderable.entity';
import { Table } from '../entity.service';

@Table('audit_categories')
export class AuditCategory extends AuditOrderableEntity {
    description: string;

    __auditQuestions__?: AuditQuestion[];
}
