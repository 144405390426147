import { Entity } from "../entity.service";
import { Role, UserGrantConfig } from "../accessibility-roles/role.entity";

export class User extends Entity {
  type = "users";

  name: string;
  identity: string;
  roleId: string;
  companyId: string;
  workAsUserId: string;
  planningUserId: string;

  lastSeenAt: Date;
  serverVersion?: string;
  telephone: string;
  abbreviation: string;
  isSalesEmployee: string;
  isBuyAdvisor: string;
  overwriteSignIn: boolean;

  email: string;
  emailService: string;
  emailDeclaration: string;

  addressStreet: string;
  addressHouseNumber: string;
  addressCity: string;
  addressZipCode: string;

  __role__?: Role;
  __grantConfigs__?: UserGrantConfig[];

  isConfigured?: boolean;
  isDetailsFetched?: boolean;

  get role() {
    return this.one(User, Role, "role");
  }
}
