import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { InViewportModule } from "@thisissoon/angular-inviewport";
import { MomentModule } from "ngx-moment";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ColorPickerModule } from "ngx-color-picker";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SignaturePadModule } from "ngx-signaturepad";
import { NgxMyDatePickerModule } from "ngx-mydatepicker";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { NgxFileDropModule } from "ngx-file-drop";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppMdcModule } from "./app-mdc.module";

@NgModule({
  imports: [LeafletModule.forRoot(), NgxMyDatePickerModule.forRoot()],
  exports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppMdcModule,

    MomentModule,
    DragDropModule,
    InViewportModule,
    InfiniteScrollModule,
    ColorPickerModule,
    FlexLayoutModule,
    SignaturePadModule,
    NgxMyDatePickerModule,
    LeafletModule,

    FroalaViewModule,
    FroalaEditorModule,
    NgxFileDropModule,
  ],
})
export class AppVendorModule {}
