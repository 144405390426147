import gql from 'graphql-tag';

export const entityAdvancementImageCreateMutation = gql`
  mutation entityAdvancementImageCreateMutation(
    $input: InputEntityImageEntity!
  ) {
    image: EntityImageEntityUpdateOne(input: $input) {
      id
    }
  }
`;

export const deliveryListCompletionMutation = gql`
    mutation deliveryListCompletionMutation(
      $customFields: [CellaCustomField!]!,
      $points: [InputDeliveryListPointEntity!]!,
      $deliveryList: InputDeliveryListEntity!,
      $images: [InputEntityImageEntity!]!,
    ) {
      cella: CellaWriteCustomFields(CustomField: $customFields)
      points: DeliveryListPointEntityUpdateMany(input: $points) {
        id
      }
      deliveryList: DeliveryListEntityUpdateOne(input: $deliveryList) {
        id
      }
      images: EntityImageEntityUpdateMany(input: $images) {
        id
      }
    }
`;
