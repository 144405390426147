import { ChangeDetectorRef, Injector } from "@angular/core";
import { Backdrop, CircularProgress, Grid, Paper } from "@material-ui/core";
import * as classNames from "classnames";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import DataGrid, { CalculatedColumn, SortColumn } from "react-data-grid";
import { User } from "../accessibility-users/user.entity";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { DialogService } from "../dialog.service";
import { EntitySelect } from "../entity-select/entity-select";
import { EntityManager, Ops } from "../entity.service";
import { GrantService } from "../grant.service";
import { MetacomService } from "../metacom.service";
import { PlanningHumanResource } from "../picklist-overview/planning-project-item.entity";
import {
  ProjectShortcutsDialogComponent,
  ProjectShortcutsDialogComponentModel,
} from "../project-shortcuts-dialog/project-shortcuts-dialog.component";
import { Project } from "../project/project.entity";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";
import {
  useGrant,
  useProvider,
  useRepository,
  useRouteParam,
} from "../react-component/react.hooks";
import { RestService } from "../rest.service";
import { UrlOpenService } from "../url-open.service";
import { WorkOrderDialogComponent } from "../work-order-dialog/work-order-dialog.component";
import { WorkOrderDto } from "./WorkOrderDto";
import {
  composeOrderColumns,
  composeWorkOrderFilters,
  sortOrders,
  WorkOrderQuery,
} from "./WorkOrders.functions";
import { useWorkOrdersGridStyles } from "./WorkOrders.hooks";

export interface WorkOrdersProps {
  readonly injector: Injector;
  readonly changeDetectorRef: ChangeDetectorRef;
}

export const WorkOrders = ({
  injector,
  changeDetectorRef,
}: WorkOrdersProps) => {
  const classes = useWorkOrdersGridStyles();
  const authService = useProvider(injector, AuthService);
  const restService = useProvider(injector, RestService);
  const grantService = useProvider(injector, GrantService);
  const urlOpenService = useProvider(injector, UrlOpenService);
  const dialogService = useProvider(injector, DialogService);
  const metacomService = useProvider(injector, MetacomService);
  const entities = useProvider(injector, EntityManager);
  const planningHumanResourceRepository = useRepository(
    entities,
    PlanningHumanResource
  );

  const isFilteringAllowed = useGrant(
    grantService,
    grants.work_orders.allow_filter,
    "true"
  );

  const paramProjectId = useRouteParam(injector, "projectId");
  const paramRelationId = useRouteParam(injector, "relationId");

  const [isLoading, setIsLoading] = useState<boolean>();
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

  const [query, setQuery] = useState<WorkOrderQuery>(() => ({
    relationId: isFilteringAllowed ? paramRelationId : authService.user.id,
    projectId: paramProjectId,
  }));

  const filter = useMemo(() => composeWorkOrderFilters(query), [query]);

  const [rows, setRows] = useState<WorkOrderDto[]>([]);

  const sortedRows = useMemo(
    () => sortOrders(rows, sortColumns),
    [rows, sortColumns]
  );

  const fetch = async () => {
    setIsLoading(true);

    if (filter && !!filter.length) {
      const response = await metacomService.queryTableAsync<WorkOrderDto>({
        setName: "metacom",
        tableName: "werkopdrachten",
        filter,
      });

      !response.hasError() && setRows(response.value);
    } else {
      setRows([]);
    }

    setIsLoading(false);
  };

  const openOrder = async (order: WorkOrderDto) => {
    const result: "close" | "save" = await dialogService.open(
      changeDetectorRef,
      WorkOrderDialogComponent,
      {
        data: {
          projectId: order.project,
          orderId: order.opdracht,
          orderName: `${order.opdracht} - ${order.opdracht_oms}`,
        },
      }
    );

    result === "save" && (await fetch());
  };

  const openPlanning = async (order: WorkOrderDto) => {
    const response = await planningHumanResourceRepository.queryFirst({
      filters: [{ field: "number", operator: "Equal", value: order.relatie }],
    });

    if (!response.hasError()) {
      urlOpenService.open(
        `${location.origin}/#/planning/employee/${response.value.id}`,
        false
      );
    }
  };

  const openShortcuts = async (order: WorkOrderDto) => {
    return await dialogService.open(
      changeDetectorRef,
      ProjectShortcutsDialogComponent,
      {
        data: new ProjectShortcutsDialogComponentModel(order.project, {
          subject: order.project,
          description: order.project_oms,
        }),
      }
    );
  };

  const onRowClick = async (
    row: WorkOrderDto,
    column: CalculatedColumn<WorkOrderDto, unknown>
  ) => {
    switch (column.key) {
      case "opdracht":
        return await openOrder(row);
      case "relatie":
        return await openPlanning(row);
      case "shortcuts":
        return await openShortcuts(row);
    }
  };

  useEffect(() => {
    filter && fetch();
  }, [filter]);

  return (
    <PortalThemeProvider>
      <Paper className={classes.container}>
        <Grid container className={classes.toolbar} spacing={1}>
          {isFilteringAllowed && (
            <Grid item xs={6}>
              <EntitySelect
                style={{ width: "100%" }}
                title="Relatie"
                type="users"
                autoFocus={!paramRelationId}
                labelSelector="name"
                searchFields={["identity", "name"]}
                restService={restService}
                entityId={query.relationId}
                filters={[
                  Ops.Field("identity").IsNull(true),
                  Ops.Field("stage").Equals("1000"),
                  Ops.Field("isSupplier").Equals("t"),
                ]}
                onSelect={(user: User) =>
                  setQuery((prev) => ({ ...prev, relationId: user && user.id }))
                }
              />
            </Grid>
          )}
          <Grid item xs={isFilteringAllowed ? 6 : 12}>
            <EntitySelect
              style={{ width: "100%" }}
              limit={20}
              autoFocus={paramRelationId && !paramProjectId}
              title="Project"
              type="projects"
              searchFields={["id", "description"]}
              restService={restService}
              entityId={query.projectId}
              labelSelector={(entity) =>
                `${entity["id"]} - ${entity["description"]}`
              }
              onSelect={(project: Project) =>
                setQuery((prev) => ({
                  ...prev,
                  projectId: project && project.id,
                }))
              }
            />
          </Grid>
        </Grid>

        <DataGrid
          rowHeight={32}
          headerRowHeight={32}
          enableVirtualization={false}
          className={classNames(["rdg-light", classes.grid])}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          columns={composeOrderColumns({
            headerClass: classes.header,
            cellClass: classes.gridCell,
            cellClickableClass: classes.gridCellClickable,
            showRelationDescription: isFilteringAllowed,
          })}
          rows={sortedRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowClick={onRowClick}
        />

        <Backdrop
          style={{
            width: "100%",
            height: "100%",
            color: "#fff",
            zIndex: 10,
            position: "absolute",
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Paper>
    </PortalThemeProvider>
  );
};
