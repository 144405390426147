import { Component, Inject } from '@angular/core';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { AuditEntity } from '../audit-sheet/audit-orderable.entity';

@Component({
  selector: 'app-audit-entity-dialog',
  templateUrl: './audit-entity-dialog.component.html',
  styleUrls: ['./audit-entity-dialog.component.scss']
})
export class AuditEntityDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly audit: AuditEntity,
    protected readonly dialog: MdcDialogRef<AuditEntityDialogComponent>) { }

  get canSave() {
    return this.audit.description;
  }

  save() {
    this.dialog.close(this.audit);
  }
}
