import { Entity } from "../entity.service";

export class AppPhaseContact extends Entity {
  type = "app.phase_contacts";

  phase_id: number;
  title: string;
  receive_notifications: boolean;

  created_at?: Date;
  updated_at?: Date;

  isDeleted?: boolean;
}
