import { Entity } from "../entity.service";

export class AppConversationMessagePreset extends Entity {
  type = "app.conversation_message_presets";

  title: string;
  message: string;

  created_at?: Date;
  updated_at?: Date;
}
