import { DeliveryListEntity } from "../../delivery-list.entity";

export class DeliveryListState {}

export class DeliveryListLoadInProgressState extends DeliveryListState {}

export class DeliveryListLoadFailureState extends DeliveryListState {
  constructor(
    readonly props: {
      readonly message: string;
    }
  ) {
    super();
  }
}

export class DeliveryListLoadSuccessState extends DeliveryListState {
  constructor(
    readonly props: {
      readonly deliveryList: DeliveryListEntity;
      readonly imageHash: number;
    }
  ) {
    super();
  }
}
