import * as React from "react";
import * as moment from "moment";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { EntitySelect } from "src/app/entity-select/entity-select";
import { RestService } from "src/app/rest.service";
import { Apollo } from "apollo-angular";
import { ServiceEventDto } from "../dtos/service-event.dto";
import { servicePlanningFetchProjectRelatedEventsQuery } from "../service-planning.query";

export interface SchedulerSearchDialogComponentProps {
  onClose: () => void;
  onSelected: (date: Date, eventId: string) => void;
  rest: RestService;
  apollo: Apollo;
}

const useStyles = makeStyles(() => ({
  events: {
    maxHeight: "60%",
    overflow: "auto",
  },
  event: {
    cursor: "pointer",
    padding: 15,
    color: "#0b77cf",
    background: "#d9edfd",
    transition: "all .2s linear",
    "&:hover": {
      background: "#b3dbfb",
      color: "#fff",
    },
  },
}));

export function SchedulerSearchDialogComponent({
  apollo,
  onClose,
  onSelected,
  rest,
}: SchedulerSearchDialogComponentProps) {
  const [project, setProject] = useState<any>();
  const [events, setEvents] = useState<ServiceEventDto[]>([]);
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const fetchData = async (legacyProjectId: string) => {
    const response = await apollo
      .query<{
        related: ServiceEventDto[];
      }>({
        query: servicePlanningFetchProjectRelatedEventsQuery,
        variables: {
          legacyProjectId,
        },
      })
      .toPromise();

    if (!response.errors) {
      setEvents(response.data.related);
    }
  };

  useEffect(() => {
    if (project && project.id) {
      fetchData(project.id);
    }
  }, [project]);

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        <EntitySelect
          limit={20}
          autoFocus
          title="Project"
          type="legacy_projects"
          searchFields={["id", "description"]}
          restService={rest}
          entity={project}
          labelSelector={(entity) =>
            `${entity["id"]} - ${entity["description"]}`
          }
          onSelect={(entity) => setProject(entity)}
        />
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={1}
          className={classes.events}
        >
          {events.map((_event) => (
            <Grid item key={_event.id}>
              <SchedulerSearchDialogEventTile
                onClick={
                  _event.startDate
                    ? () => onSelected(_event.startDate, _event.id)
                    : () => {}
                }
                event={_event}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface SchedulerSearchDialogEventTileProps {
  onClick: () => void;
  event: ServiceEventDto;
}

export function SchedulerSearchDialogEventTile({
  event,
  onClick,
}: SchedulerSearchDialogEventTileProps) {
  const classes = useStyles();

  return (
    <Grid
      onClick={onClick}
      container
      justifyContent="space-between"
      classes={{ root: classes.event }}
    >
      <Grid item>
        <Typography style={{ fontWeight: "bold" }}>
          {event.serviceTicketIds.join(", ")}
        </Typography>
        <Typography variant="body2">{event.stage.name}</Typography>
      </Grid>
      <Grid item style={{ textAlign: "right" }}>
        {event.startDate && (
          <Typography variant="body2">
            {moment(event.startDate).format("DD-MM-YYYY")}
          </Typography>
        )}
        {event.isFinalized && (
          <Typography variant="body2">{"Gereed"}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
