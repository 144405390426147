import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { DocumentMeta } from "../documents/document-meta.entity";

export class EntityAdvancement extends Entity {
  type = "entity_advancements";

  entityType: string;
  entityId: string;
  userId: string;

  comment: string;
  date: Date;
  isCompleted: boolean;

  isNew?: boolean;

  __user__?: User;

  updatedAt: Date;
  createdAt: Date;
}

export class EntityImage extends Entity {
  type = "entity_images";

  entityType: string;
  entityId: string;
  userId: string;
  documentMetaId: string;

  __user__?: User;
  __documentMeta__?: DocumentMeta;

  get isPdf() {
    return (
      this.__documentMeta__ &&
      this.__documentMeta__.mimeType === "application/pdf"
    );
  }

  thumbUrl?: string;
  imageUrl?: string;

  updatedAt: Date;
  createdAt: Date;
}
