import gql from 'graphql-tag';

export const serviceTicketDialogCategoriesQuery = gql`
query serviceTicketDialogCategoriesQuery {
    categories: AppServiceCategoryEntityFindMany {
      id
      service_category_id
      name
    }
}`;
