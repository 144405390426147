import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { AuthService } from "../auth.service";
import { MdcDrawer, MdcMenuSurface, MdcDialog } from "@angular-mdc/web";
import { AccountDialogComponent } from "../account-dialog/account-dialog.component";
import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import {
  filter,
  delay,
  distinctUntilChanged,
  debounceTime,
  delayWhen,
} from "rxjs/operators";
import { environment } from "../../environments/environment";
import { NgxZendeskWebwidgetService } from "ngx-zendesk-webwidget";
import { DialogService } from "../dialog.service";
import { GrantService } from "../grant.service";
import { MenuGroup, MenuItem, menuDefinition } from "./menu";
import { RestResponse, RestService } from "../rest.service";
import { Project } from "../project/project.entity";
import { EntityManager } from "../entity.service";
import { SwPush } from "@angular/service-worker";
import { UserNotification } from "../notification/user-notification.entity";
import { PageService } from "../page.service";
import { ChangelogDialogComponent } from "../changelog-dialog/changelog-dialog.component";
import { StorageService } from "../storage.service";
import { ZendeskConfig } from "../zendesk-config";
import { grants } from "../app-grant-config";
import { PrintService } from "../print.service";
import { TransactionService } from "../transaction.service";
import { BehaviorSubject } from "rxjs";
import { UrlOpenService } from "../url-open.service";

import * as html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { AppService } from "../app.service";
import {
  WorkAction,
  WorkActionExtentions,
} from "../work-actions/work-action.entity";

/**
 * Component used for master-operations like navigation etc.
 *
 * @export
 * @class MasterComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-master",
  templateUrl: "./master.component.html",
  styleUrls: ["./master.component.scss"],
})
export class MasterComponent implements OnInit {
  readonly NOTIFICATION_BATCH_SIZE = 30;
  notificationScrollPointer = 0;

  isSubUpdated = false;

  title: string = null;
  titleSegments: string[] = [];

  todoCount = 0;

  menu: MenuGroup[] = [];
  projectResponse: RestResponse<Project>;
  notificationResponse: RestResponse<UserNotification[]>;

  isSmall = new BehaviorSubject<{ mediaMatches?: boolean }>({});

  @ViewChild(MdcDrawer, { static: true }) drawer: MdcDrawer;
  @ViewChild(MdcMenuSurface, { static: true }) notifications: MdcMenuSurface;

  get headerColor() {
    return environment.headerColor;
  }

  get version() {
    return environment.version;
  }

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get userNotificationService() {
    return this.entityManager.get(UserNotification);
  }

  protected get workActionService() {
    return this.entityManager.get(WorkAction);
  }

  get unreadNotifications() {
    return this.notificationResponse
      ? this.notificationResponse.value.filter((e) => !e.readAt).length
      : 0;
  }

  get isScreenSmall() {
    return this.isSmall.value.mediaMatches === true;
  }

  get isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  get showPrinter() {
    return (
      !this.isSafari &&
      !this.isScreenSmall &&
      this.dialogs.openDialogs.length > 0
    );
  }

  get isWorkActionsAccessible() {
    return this.grantService.isRouteAccessable("/office/work-actions");
  }

  /**
   * Creates an instance of MasterComponent.
   * @param {NgZone} ngZone
   * @param {MdcDialog} mdcDialog
   * @param {AuthService} authService
   * @param {ZendeskWebwidgetService} zendesk
   * @memberof MasterComponent
   */
  constructor(
    readonly app: AppService,
    readonly authService: AuthService,
    protected readonly printer: PrintService,
    protected readonly swPush: SwPush,
    protected readonly ngZone: NgZone,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly pageService: PageService,
    protected readonly restService: RestService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly storageService: StorageService,
    protected readonly zendesk: NgxZendeskWebwidgetService,
    protected readonly transactionService: TransactionService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly dialogs: MdcDialog
  ) {}

  /**
   * Init method of @see {OnInit}
   *
   * @memberof MasterComponent
   */
  ngOnInit() {
    this.setMenu();

    const SMALL_WIDTH_BREAKPOINT = 512;

    this.isSmall.subscribe(() => this.setDrawerType());

    const watch = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
    watch.addListener((event: MediaQueryListEvent) => {
      this.isSmall.next({ mediaMatches: event.matches });
    });

    this.isSmall.next({ mediaMatches: watch.matches });

    if (this.swPush.isEnabled && !this.isSafari) {
      this.swPush.messages.subscribe(() => this.fetchNotifications());
      this.swPush.subscription.subscribe((sub) =>
        this.saveNotificationsSub(sub)
      );
    }

    this.router.events
      .pipe(
        filter(() => this.isScreenSmall),
        filter((_) => _ instanceof NavigationStart)
      )
      .subscribe((_) => (this.drawer.open = false));

    this.authService.projectSubject
      .pipe(distinctUntilChanged(), delay(0))
      .subscribe((val) => this.onProjectChanged(val));

    this.pageService.titleSubject
      .pipe(distinctUntilChanged(), delay(0))
      .subscribe((value) => {
        this.title = value;

        document.title = value || "Groothuisbouw";
      });

    this.pageService.titleSegmentsSubject
      .pipe(distinctUntilChanged(), delay(0))
      .subscribe((value) => (this.titleSegments = value));

    this.authService.userSubject
      .pipe(distinctUntilChanged(), delay(0))
      .subscribe(() => {
        this.setMenu();
        this.app.connect();
      });

    this.fetchNotifications();
    this.subscribeToNotifications();

    if (this.isWorkActionsAccessible) {
      this.fetchTodoCount();
    }

    this.calcViewport();

    const url = this.getZendesk(
      this.grantService.var(
        grants.default.zendesk_environment,
        "groothuisbouwonderaannemers"
      )
    );

    this.zendesk.initZendesk(ZendeskConfig.set(url));
  }

  protected getZendesk(id: string) {
    return `${id}.zendesk.com`;
  }

  @HostListener("window:orientationchange", ["$event"])
  onRotate() {
    this.calcViewport();
  }

  @HostListener("window:resize", ["$event"])
  calcViewport() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  openChangelog() {
    this.dialogService.openStateless(ChangelogDialogComponent);
  }

  protected async exportToPdf() {
    const dialog = document.querySelector(
      ".mdc-dialog__surface:not(.dialog-loading)"
    ) as HTMLElement;

    const canvas = await html2canvas.default(dialog || document.body, {
      scrollX: 0,
      scrollY: 0,
    });
    const dataUrl = canvas.toDataURL("image/jpg", 1);
    const pdf = new jsPDF("l", "px", [canvas.width, canvas.height]);
    pdf.addImage(
      dataUrl,
      "jpg",
      0,
      0,
      pdf.internal.pageSize.getWidth(),
      pdf.internal.pageSize.getHeight(),
      "none"
    );
    pdf.save("printscreen.pdf");
  }

  async saveScreenAsPdf() {
    await this.transactionService.doLoading(this.exportToPdf);
  }

  /**
   * Opens the @see {AccountDialogComponent} for the user.
   *
   * @memberof MasterComponent
   */
  async openAccount() {
    await this.dialogService.open(
      this.changeDetector,
      AccountDialogComponent,
      {}
    );
  }

  async openNotification(notification: UserNotification) {
    await this.router.navigate(["notification", notification.id]);
    await this.fetchNotifications();

    this.notifications.open = false;

    await this.readNotification(notification);
  }

  async readNotification(notification: UserNotification) {
    notification.isRead = true;
    notification.readAt = new Date();

    await this.userNotificationService.save(notification);
  }

  async fetchNotifications() {
    const response = await this.userNotificationService.query({
      filters: [
        { field: "userId", operator: "Equal", value: this.authService.user.id },
      ],
      orders: [{ field: "createdAt", direction: "DESC" }],
      take: this.NOTIFICATION_BATCH_SIZE,
      skip: this.notificationScrollPointer,
    });

    if (!response.hasError()) {
      response.value.forEach((r) => (r.content = this.decodeHtml(r.content)));
      response.value.forEach((r) => (r.isRead = !!r.readAt));
      this.notificationResponse = response;
    }
  }

  async fetchTodoCount() {
    const response = await this.workActionService.query({
      filters: [
        {
          field: "assignedUserId",
          operator: "Equal",
          value: this.authService.user.id,
        },
      ],
      relations: ["entityAdvancements"],
    });

    if (!response.hasError()) {
      this.todoCount = response.value
        .map((action) => new WorkActionExtentions(action))
        .filter((ext) => !ext.isCompleted() && ext.isExpired()).length;
    }
  }

  protected decodeHtml(html: string) {
    const temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || "";
  }

  openHelp() {
    this.zendesk.zE("webWidget", "show");
    this.zendesk.zE("webWidget", "open");
  }

  isActive(item: MenuItem) {
    return item.isDesktopOnly ? !this.isScreenSmall : true;
  }

  protected setMenu() {
    this.menu = menuDefinition
      .map(
        (group) =>
          new MenuGroup(
            group.name,
            group.children.filter((item) =>
              this.grantService.isRouteAccessable(item.route)
            )
          )
      )
      .filter((group) => group.children.length);
  }

  protected async onProjectChanged(id: string) {
    if (id) {
      this.projectResponse = await this.projectService.findOne(
        this.getNativeId(id)
      );
    } else {
      this.projectResponse = null;
    }
  }

  protected getNativeId(id: string) {
    return id.charAt(0) === "S" ? id.substr(1) : id;
  }

  protected async subscribeToNotifications() {
    if (this.swPush.isEnabled) {
      let sub = null;

      try {
        sub = await this.swPush.requestSubscription({
          serverPublicKey: environment.vapidPublicKey,
        });
      } catch (e) {}

      this.saveNotificationsSub(sub);
    }
  }

  protected async saveNotificationsSub(sub: PushSubscription) {
    if (sub && !this.isSubUpdated) {
      await this.restService.post(
        "user-notification-subscription",
        sub.toJSON()
      );

      this.isSubUpdated = true;
    }
  }

  protected setDrawerType() {
    const condition = this.isSmall.value;

    if (typeof condition.mediaMatches === "boolean") {
      const isInitialSetup = this.drawer.drawer === "";
      const isScreenSmall = condition.mediaMatches;

      this.drawer.drawer = isScreenSmall ? "modal" : "dismissible";

      if (isInitialSetup) {
        this.drawer.open = !isScreenSmall;
      }
    }
  }
}
