import { Entity } from "../entity.service";

export class AppContent extends Entity {
  type = "app.contents";

  title: string;
  html: string;

  created_at?: Date;
  updated_at?: Date;
}
