import * as React from "react";
import { Injector } from "@angular/core";
import { Apollo } from "apollo-angular";
import { useMemo } from "react";
import { useFetchServicePlanningMechanics } from "./service-planning.hooks";
import { useEffect } from "react";
import {
  SchedulerComponent,
  SchedulerComponentDefaults,
} from "./components/SchedulerComponent";
import { HttpClient } from "@angular/common/http";
import { MetacomService } from "../metacom.service";
import { AuthService } from "../auth.service";
import { RestService } from "../rest.service";

export interface ServicePlanningComponentProps {
  injector: Injector;
  defaults?: SchedulerComponentDefaults;
}

export function ServicePlanningComponent({
  injector,
  defaults,
}: ServicePlanningComponentProps) {
  const http = useMemo(() => injector.get(HttpClient), []);
  const restService = useMemo(() => injector.get(RestService), []);
  const authService = useMemo(() => injector.get(AuthService), []);
  const metacomService = useMemo(() => injector.get(MetacomService), []);
  const apollo = useMemo(() => injector.get(Apollo), []);
  const { data, fetchServicePlanningMechanics } =
    useFetchServicePlanningMechanics(apollo);

  useEffect(() => {
    fetchServicePlanningMechanics();
  }, []);

  return (
    <>
      {data && (
        <SchedulerComponent
          injector={injector}
          http={http}
          restService={restService}
          authService={authService}
          apollo={apollo}
          metacomService={metacomService}
          mechanics={data.mechanics}
          defaults={defaults}
        />
      )}
    </>
  );
}
