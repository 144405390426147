import * as React from "react";
import { makeStyles, Typography, TextField, Chip } from "@material-ui/core";
import { EntityManager } from "src/app/entity.service";
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from "../../bloc/crud/crud.event";
import { CrudComponent } from "../crud.component";
import { MapBloc } from "./map.bloc";
import { MapColorEntity } from "./map.entity";
import ColorPicker from "material-ui-color-picker";

export interface MeterComponentProps {
  entities: EntityManager;
}

const useStyles = makeStyles<any, any>((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  addButton: {
    marginTop: 24,
  },
  chip: {
    width: 12,
    height: 12,
    borderRadius: 16,
    marginRight: 8,
    display: "inline-block",
    border: "1px solid #e0e0e0",
    backgroundColor: (props) => props && props.color,
  },
}));

const ColorChip: React.FC<{ color: string }> = ({ color }) => {
  const classes = useStyles({ color });

  return <span className={classes.chip} />;
};

export const MapComponent: React.FC<MeterComponentProps> = ({ entities }) => {
  const classes = useStyles({});
  const [bloc] = React.useState(
    new MapBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: MapColorEntity) => (
    <Typography className={classes.heading}>
      {entity.color && <ColorChip color={entity.color} />}
      <strong>{`Ouder dan ${
        entity.days
          ? +entity.days === 1
            ? "1 dag"
            : `${entity.days} dagen`
          : "x aantal dagen"
      }`}</strong>
      {entity.isDirty && <i> (niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: MapColorEntity) => (
    <React.Fragment>
      <TextField
        variant="outlined"
        label="Aantal dagen"
        value={entity.days || ""}
        className={classes.textField}
        onChange={(event) => {
          entity.days = +event.target.value;

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />

      <div className={classes.textDivider}></div>

      <ColorPicker
        className={classes.textField}
        variant="outlined"
        label="Kleur"
        InputProps={{ value: entity.color }}
        InputLabelProps={{ shrink: !!entity.color }}
        value={entity.color || ""}
        onChange={(color) => {
          entity.color = color.toUpperCase();

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />
    </React.Fragment>
  );

  return (
    <CrudComponent
      bloc={bloc}
      renderTitle={(entity: MapColorEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: MapColorEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: MapColorEntity, siblings: MapColorEntity[]) =>
        !entity.color ||
        !entity.days ||
        isNaN(entity.days) ||
        !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(entity.color) ||
        !!siblings.find((sibling) => sibling.days === entity.days) ||
        !!siblings.find((sibling) => sibling.color === entity.color)
      }
    />
  );
};
