import { Component, Injector } from "@angular/core";
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from "../react-component/react-attuned.component";
import {
  MailTrafficComponent,
  MailTrafficComponentProps,
} from "./mail-traffic.component";

@Component({
  template: reactAttunedComponentTemplate,
  styles: [".attuned--component { overflow: hidden; }"],
})
export class MailTrafficAngular extends ReactAttunedComponent<MailTrafficComponentProps> {
  component = MailTrafficComponent;

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector,
    };
  }
}
