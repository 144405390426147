import {
  getPicklistOverviewLines,
  PicklistOverviewLine,
  PicklistOverviewLineFetchMode,
} from "./picklist-overview.functions";
import { MetacomService } from "./../metacom.service";
import { Component, Injector, OnInit } from "@angular/core";
import { EntityManager } from "../entity.service";
import { PlanningProjectItem } from "./planning-project-item.entity";
import { RestResponse } from "../rest.service";
import { PicklistDefinition } from "../picklist/picklist.entity";
import * as moment from "moment";
import { AuthService } from "../auth.service";
import { grants } from "../app-grant-config";
import * as _ from "lodash";
import { GrantService } from "../grant.service";
import { ActivatedRoute, Router } from "@angular/router";
import { uniqBy } from "lodash";

@Component({
  selector: "app-picklist-overview",
  templateUrl: "./picklist-overview.component.html",
  styleUrls: ["./picklist-overview.component.scss"],
})
export class PicklistOverviewComponent implements OnInit {
  public response: RestResponse<PicklistOverviewLine[]>;
  public picklistDefinitions: PicklistDefinition[];

  queryModel = new PicklistOverviewComponentQueryModel();

  protected get planningProjectItemService() {
    return this.entityManager.get(PlanningProjectItem);
  }

  protected get picklistDefinitionService() {
    return this.entityManager.get(PicklistDefinition);
  }

  protected get excludeDefinitionIds() {
    return this.grantService
      .var(grants[this.settings.grantKey].exclude_picklist_definition_id, "")
      .split(",")
      .filter((e) => e.length);
  }

  protected get includeDefinitionIds() {
    return this.grantService
      .var(grants[this.settings.grantKey].include_picklist_definition_id, "")
      .split(",")
      .filter((e) => e.length);
  }

  get definitionId() {
    return this.route.snapshot.paramMap.get("definitionId") || "NONE";
  }

  get dateFrom() {
    return (
      this.route.snapshot.paramMap.get("dateFrom") ||
      moment().startOf("week").format("YYYY-MM-DD")
    );
  }

  get settings() {
    return this.route.snapshot.data.settings as PicklistOverviewSettings;
  }

  constructor(
    protected readonly router: Router,
    protected readonly injector: Injector,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly metacomService: MetacomService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      () => this.selectedDefinition && this.fetchPlanning()
    );

    this.fetchPicklistDefinitions().then(
      () => this.selectedDefinition && this.fetchPlanning()
    );

    this.queryModel = {
      definitionId: this.definitionId,
      dateFrom: this.dateFrom,
    };
  }

  refetch() {
    return this.updateRoute();
  }

  async updateRoute(delta?: {}) {
    Object.assign(this.queryModel, delta || {});

    return await this.router.navigate([
      `${this.settings.mainRouteSegment}/picklist-overview`,
      this.queryModel.definitionId,
      this.queryModel.dateFrom,
    ]);
  }

  protected isCompleted(item: PicklistOverviewLine) {
    return (
      item.project &&
      !!item.project.__picklistStates__.find(
        (state) => state.picklistDefinitionId === this.selectedDefinition.id
      )
    );
  }

  protected get selectedDefinition() {
    return this.picklistDefinitions
      ? this.picklistDefinitions.find((d) => d.id === this.definitionId)
      : null;
  }

  protected async fetchPicklistDefinitions() {
    const response = await this.picklistDefinitionService.query();

    if (!response.hasError()) {
      this.picklistDefinitions = response.value.filter((d) =>
        this.isDefinitionAllowed(d.id)
      );

      if (
        this.picklistDefinitions.length === 1 &&
        this.definitionId == "NONE"
      ) {
        this.updateRoute({
          definitionId: _.first(this.picklistDefinitions).id,
        });
      }
    }
  }

  async fetchPlanning() {
    this.response = null;

    this.response = new RestResponse(
      await getPicklistOverviewLines({
        injector: this.injector,
        definition: this.selectedDefinition,
        mode: this.selectedDefinition
          .dateSource as PicklistOverviewLineFetchMode,
        dateFrom: moment(this.dateFrom).toDate(),
      })
    );
  }

  protected isDefinitionAllowed(definitionId: string) {
    if (this.includeDefinitionIds.length > 0) {
      return this.includeDefinitionIds.indexOf(definitionId) >= 0;
    }

    if (this.excludeDefinitionIds.length > 0) {
      return this.excludeDefinitionIds.indexOf(definitionId) < 0;
    }

    return true;
  }

  routerLink(item: PicklistOverviewLine) {
    const intermediate = [
      `/${this.settings.mainRouteSegment}/picklist`,
      item.project.id,
      item.definitionId,
    ];

    item.documentId && intermediate.push(item.documentId);

    return intermediate;
  }
}

class PicklistOverviewComponentQueryModel {
  definitionId?: string;
  dateFrom?: string;
}

export type PicklistOverviewSettings = {
  grantKey: string;
  mainRouteSegment: string;
};
