import * as React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { BlocBuilder } from "src/app/service-settings/bloc/bloc.builder";
import { Alert } from "@material-ui/lab";
import { MeterBloc } from "./meter.bloc";
import {
  MeterLoadFailureState,
  MeterLoadInProgressState,
  MeterLoadSuccessState,
  MeterState,
} from "./meter.state";
import { EntityManager } from "src/app/entity.service";
import { MeterEntity } from "src/app/service-settings/react/meters/meter.entity";
import { MeterFieldComponent } from "./meter-field.component";
import { MeterLoadRequestEvent } from "./meter.event";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  meters: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  paper: {
    marginBottom: 12,
  },
}));

export interface MeterComponentProps {
  disabled?: boolean;
  projectId: string;
  entities: EntityManager;
}

export const MeterComponent: React.FC<MeterComponentProps> = ({
  disabled,
  projectId,
  entities,
}) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new MeterBloc(entities).add(new MeterLoadRequestEvent({ projectId }))
  );

  const mapMeterToNode = (meter: MeterEntity) => {
    return (
      <MeterFieldComponent
        disabled={disabled}
        bloc={bloc}
        key={meter.refId}
        meter={meter}
      />
    );
  };

  function* mapStateToNode(state: MeterState) {
    if (state instanceof MeterLoadInProgressState) {
      yield <p key="mtr-loading">Laden...</p>;
    }

    if (state instanceof MeterLoadSuccessState) {
      yield (
        <div key="mtr-list" className={classes.meters}>
          {...state.props.meters.map((meter) => mapMeterToNode(meter))}
        </div>
      );
    }

    if (state instanceof MeterLoadFailureState) {
      yield (
        <Alert key="mtr-errors" severity="error">
          {state.props.message}
        </Alert>
      );
    }
  }

  return (
    <Paper className={classes.paper} elevation={1}>
      <Box p={1}>
        <BlocBuilder
          bloc={bloc}
          builder={(state) => (
            <React.Fragment>
              {...Array.from(mapStateToNode(state))}
            </React.Fragment>
          )}
        />
      </Box>
    </Paper>
  );
};
