import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private readonly authService: AuthService) { }

    intercept(request: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
        return next.handle(request).pipe(catchError(_ => this.onError(_)));
    }

    protected onError(error: any) {
        if (error.status === 401) {
            this.authService.logout(this.authService.isSignedIn, 'unauthorized');
        }

        return throwError(error);
    }
}
