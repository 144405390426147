import { CargoPhase } from "./../cargo/house-part.entity";
import { Component, Inject, OnInit } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Cargo, CargoPhaseType, cargoRoutes } from "../cargo/house-part.entity";
import { EntityManager } from "../entity.service";
import { EntitySelectConfig } from "../entity-select/entity-select.component";
import { PlanningProjectItem } from "../picklist-overview/planning-project-item.entity";
import * as moment from "moment";

@Component({
  selector: "app-cargo-dialog",
  templateUrl: "./cargo-dialog.component.html",
  styleUrls: ["./cargo-dialog.component.scss"],
})
export class CargoDialogComponent implements OnInit {
  get routes() {
    return cargoRoutes;
  }

  cargoTypeConfig: EntitySelectConfig = {
    allowNothing: false,
    title: "Type",
    icon: "details",
    entityName: "cargo_types",
    nameField: null,
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["description"],
  };

  dates: Date[];

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly cargo: Cargo,

    protected readonly dialog: MdcDialogRef<CargoDialogComponent>,
    protected readonly entityManager: EntityManager
  ) {}

  async ngOnInit() {
    this.dates = await resolveDates(this.cargo, this.entityManager);

    if (this.cargo && this.cargo.dateAt) {
      this.cargo.dateAt = moment(this.cargo.dateAt).toDate();
    }
  }

  get canSave() {
    return (
      this.cargo.description &&
      this.cargo.cargoTypeId &&
      this.cargo.routeId &&
      (this.cargo.competenceId || this.cargo.phaseId) &&
      this.cargo.dateAt &&
      this.cargo.timeAt
    );
  }

  save() {
    this.dialog.close(this.cargo);
  }
}

function* convertPlanningItemsToDates(dates: PlanningProjectItem[]) {
  for (const date of dates) {
    const from = moment(date.competenceStartsAt),
      to = moment(date.competenceEndsAt);
    const diff = to.diff(from, "days");

    for (let i = 0; i < diff; i++) {
      yield from.clone().add(i, "days").toDate();
    }
  }
}

const resolveDates = async (
  cargo: Cargo,
  entities: EntityManager
): Promise<Date[]> => {
  switch (cargo.phaseType) {
    case CargoPhaseType.Competence: {
      const repo = entities.get(PlanningProjectItem);
      const response = await repo.query({
        filters: [
          { field: "projectId", operator: "Equal", value: cargo.projectId },
          {
            field: "competenceId",
            operator: "Equal",
            value: cargo.competenceId,
          },
          { field: "dimension", operator: "Equal", value: "competence" },
        ],
        orders: [{ field: "competenceIndex", direction: "ASC" }],
      });

      return response.hasError()
        ? []
        : Array.from(convertPlanningItemsToDates(response.value));
    }
    case CargoPhaseType.Custom: {
      const repo = entities.get(CargoPhase);
      const response = await repo.findOne(cargo.phaseId);

      return response.hasError() ? [] : [moment(response.value.date).toDate()];
    }
  }
};
