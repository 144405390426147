import * as React from "react";
import { makeStyles, Typography, TextField } from "@material-ui/core";

import { EntityManager } from "src/app/entity.service";
import { MeterBloc } from "./meter.bloc";
import { MeterEntity } from "./meter.entity";
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from "../../bloc/crud/crud.event";
import { CrudComponent } from "../crud.component";

export interface MeterComponentProps {
  entities: EntityManager;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  addButton: {
    marginTop: 24,
  },
}));

export const MeterComponent: React.FC<MeterComponentProps> = ({ entities }) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new MeterBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: MeterEntity) => (
    <Typography className={classes.heading}>
      <strong>{entity.description || "METER"}</strong> &mdash; (
      {entity.unit || "eenheid"}) {entity.isDirty && <i>(niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: MeterEntity) => (
    <React.Fragment>
      <TextField
        variant="outlined"
        label="Meter"
        value={entity.description}
        className={classes.textField}
        onChange={(event) => {
          entity.description = event.target.value;

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />

      <div className={classes.textDivider}></div>

      <TextField
        className={classes.textField}
        variant="outlined"
        label="Eenheid"
        value={entity.unit}
        onChange={(event) => {
          entity.unit = event.target.value;

          bloc.add(new CrudModifiedEvent({ entity }));
        }}
      />
    </React.Fragment>
  );

  return (
    <CrudComponent
      bloc={bloc}
      isOrderable
      renderTitle={(entity: MeterEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: MeterEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: MeterEntity, siblings: MeterEntity[]) =>
        !entity.description ||
        !entity.unit ||
        !!siblings.find((sibling) => sibling.description === entity.description)
      }
    />
  );
};
