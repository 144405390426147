import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";
import {
  WorkActionCategory,
  WorkActionProperties,
} from "../work-actions/work-action.entity";

export class WorkActionTemplate extends Entity implements WorkActionProperties {
  type = "work_action_templates";

  workActionTemplateGroupId: string;
  workActionCategoryId: string;
  title: string;
  description: string;
  targetDateOffset: number;

  __workActionCategory__: WorkActionCategory;

  allowEmptyAdvancement?: boolean;
  disableImageUpload?: boolean;

  responsibleDateGroupId?: string;
  advancementApplyDateId?: string;

  updatedAt?: Date;
  createdAt?: Date;
}

export class WorkActionTemplateGroup extends Entity {
  type = "work_action_template_groups";

  name: string;

  workActionTemplates: WorkActionTemplate[];

  set __workActionTemplates__(value: WorkActionTemplate[]) {
    this.workActionTemplates = this.entityManager
      .get(WorkActionTemplate)
      .healManyResponse(new RestResponse(value)).value;
  }

  updatedAt?: Date;
  createdAt?: Date;
}
