import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import { DialogService } from "../dialog.service";
import { AppRestService } from "../app-rest.service";
import {
  EntitySearchDialogComponent,
  EntitySearchDialogConfig,
} from "../entity-search-dialog/entity-search-dialog.component";
import { AppUser } from "../app-users/app-user.entity";

@Component({
  selector: "app-app-document-signature-wizard-dialog",
  templateUrl: "./app-document-signature-wizard-dialog.component.html",
  styleUrls: ["./app-document-signature-wizard-dialog.component.scss"],
})
export class AppDocumentSignatureWizardDialogComponent {
  model: Signature = {
    document_id: null,
    _executionType: "sequential",
    users: [],
  };

  isRequesting = false;

  get canRequest() {
    return this.model._executionType && this.model.users.length > 0;
  }

  get show2FAWarning() {
    return (
      this.model.users.length > 0 &&
      !this.model.users
        .filter((_user) => _user.is2FARequired)
        .every((_user) => !!_user.telephone)
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    protected readonly data: AppDocumentSignatureWizardDialogComponentData,
    protected readonly dialogs: DialogService,
    protected readonly appRest: AppRestService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly snackbar: MdcSnackbar,
    protected readonly dialogRef: MdcDialogRef<AppDocumentSignatureWizardDialogComponent>
  ) {
    this.model.document_id = data.documentId;
  }

  protected fixPhone(input: string) {
    return input
      ? !input.startsWith("+")
        ? `+31${input.replace(/^0+|"+$/g, "")}`
        : input
      : null;
  }

  signatureUserIcon(user: SignatureUser) {
    if (user.is2FARequired && !user.telephone) {
      return "device_unknown";
    }

    return "check";
  }

  signatureUserIconColor(user: SignatureUser) {
    return this.signatureUserIcon(user) !== "check" ? "orange" : "green";
  }

  removeUser(user: SignatureUser) {
    this.model.users.splice(this.model.users.indexOf(user), 1);
  }

  async addEmail(projectSpecific: boolean) {
    const data = new EntitySearchDialogConfig("app.users");
    data.nameField = "last_name";
    data.descriptionField = "first_name";
    data.filterFields = ["email", "first_name"];
    data.filters = [
      {
        field: "email",
        operator: "IsNull",
        isNot: true,
      },
      {
        field: "email",
        operator: "Equal",
        value: " ",
        isNot: true,
      },

      projectSpecific
        ? {
            field: "project_id",
            operator: "Equal",
            value: this.data.projectId,
          }
        : {
            field: "project_id",
            operator: "IsNull",
          },
    ];

    const user: AppUser = await this.dialogs.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (user && !!user.id && !!user.email) {
      this.model.users.push({
        email: user.email,
        name: [user.first_name, user.last_name_prefix, user.last_name].join(
          " "
        ),
        is2FARequired: !user.email.endsWith("@groothuisbouw.nl"),
        telephone: this.fixPhone(user.telephone),
      });
    }
  }

  async request() {
    this.isRequesting = true;

    const response = await this.appRest.request({
      url: "v2/document-signatures",
      method: "POST",
      body: this.model,
    });

    if (!response.hasError()) {
      this.snackbar.open("Verstuurd", "Ok", { leading: true });
      this.dialogRef.close("close");
    }

    this.isRequesting = false;
  }
}

export class AppDocumentSignatureWizardDialogComponentData {
  constructor(readonly documentId: string, readonly projectId: string) {}
}

interface Signature {
  document_id: string;
  _executionType: string;
  users: SignatureUser[];
}

interface SignatureUser {
  email: string;
  name: string;

  is2FARequired?: boolean;
  telephone?: string;
}
