import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcTextField } from "@angular-mdc/web";
import { RestResponse, RestService } from "../rest.service";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { Entity, EntityQueryFilter } from "../entity.service";
import * as _ from "lodash";

@Component({
  selector: "app-entity-search-dialog",
  templateUrl: "./entity-search-dialog.component.html",
  styleUrls: ["./entity-search-dialog.component.scss"],
})
export class EntitySearchDialogComponent implements OnInit {
  public filterQuery = "";
  public response: RestResponse<Entity[]>;

  @ViewChild("search", { static: true }) search: MdcTextField;

  protected filterQueryChanged = new Subject<string>();

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly config: EntitySearchDialogConfig,
    protected readonly restService: RestService,
    protected readonly dialog: MdcDialogRef<EntitySearchDialogComponent>
  ) {}

  ngOnInit() {
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.fetchEntities());

    this.fetchEntities().then(() => this.search.focus());
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  onEnter() {
    if (this.response && this.response.value) {
      const item = _.first(this.response.value);

      this.choose(item);
    }
  }

  leave(setNull: boolean) {
    if (setNull) {
      this.choose(null);
    } else {
      this.dialog.close("close");
    }
  }

  description(entity: Entity) {
    if (this.config.descriptionFormat) {
      return this.config.descriptionFormat(entity);
    }

    return entity[this.config.descriptionField];
  }

  makeName(entity: Entity) {
    if (this.config.nameFormat) {
      return this.config.nameFormat(entity);
    }

    return entity[this.config.nameField];
  }

  protected choose(entity: Entity) {
    this.dialog.close(entity);
  }

  protected async fetchEntities() {
    const limit = this.config.limit || 50;

    if (this.config.fixedResponse) {
      this.response = new RestResponse(
        _.chain(this.config.fixedResponse)
          .filter((e) =>
            this.filterQuery
              ? this.toText(e)
                  .toLowerCase()
                  .indexOf(this.filterQuery.toLowerCase()) >= 0
              : true
          )
          .take(limit)
          .value()
      );
    } else {
      const orders = [];
      const filters = Array.from(this.makeFilters());

      if (this.config.sortField) {
        orders.push({
          field: this.config.sortField,
          direction: this.config.sortDirection,
        });
      }

      const response = await this.restService.post<Entity[]>(
        `entity/${this.config.entityName}/query`,
        {
          take: limit,
          filters,
          orders,
          relations: this.config.relations || [],
        }
      );

      if (!response.hasError()) {
        this.response = new RestResponse(
          response.value.filter((e) =>
            this.config.filterAfter ? this.config.filterAfter(e) : true
          )
        );
      }
    }
  }

  protected *makeFilters() {
    for (const field of this.config.filterFields) {
      yield* Array.from(this.makeFilter(field));
    }
  }

  protected *makeFilter(field: string) {
    if (this.config.filters) {
      yield* this.config.filters;
    }

    yield {
      field: field,
      isOr: true,
      operator: "Like",
      value: this.filterQuery,
    };
  }

  protected toText(item: any) {
    return JSON.stringify(
      Object.values(item).filter((val) => typeof val === "string")
    );
  }
}

export class EntitySearchDialogConfig {
  allowNothing = false;
  wrapDescription?: boolean;

  limit?: number;

  filters?: EntityQueryFilter[];
  filterFields: string[] = ["id"];

  relations?: string[];

  nameField: string;
  nameFormat?: (entity) => string;

  descriptionField = "description";
  descriptionFormat?: (entity) => string;

  sortField = "id";
  sortDirection = "ASC";

  filterAfter?: (item: any) => boolean;

  fixedResponse?: any[];

  constructor(public entityName: string) {}
}
