import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { EntityManager } from "../entity.service";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Main } from "./react/main";
import { MetacomService } from "../metacom.service";
import { DialogService } from "../dialog.service";
import {
  ProjectShortcutsDialogComponent,
  ProjectShortcutsDialogComponentModel,
} from "../project-shortcuts-dialog/project-shortcuts-dialog.component";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-table-layout",
  templateUrl: "./table-layout.component.html",
  styleUrls: ["./table-layout.component.scss"],
})
export class TableLayoutComponent implements OnInit {
  readonly renderId = "table-layout-container";

  get canEdit() {
    return this.grantService.varIs(
      grants.planning_table_layout.allow_modify,
      "true"
    );
  }

  get settings() {
    return this.route.snapshot.data.settings as TableLayoutSettings;
  }

  constructor(
    readonly auth: AuthService,
    readonly route: ActivatedRoute,
    readonly grantService: GrantService,
    readonly entities: EntityManager,
    readonly metacom: MetacomService,
    readonly dialogs: DialogService,
    readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.render();
  }

  async shortcuts(projectId: string, projectName: string) {
    return this.dialogs.open(
      this.changeDetector,
      ProjectShortcutsDialogComponent,
      {
        data: new ProjectShortcutsDialogComponentModel(projectId, {
          subject: projectId,
          description: projectName,
        }),
      }
    );
  }

  protected render() {
    const root = document.getElementById(this.renderId);

    if (root) {
      ReactDOM.render(
        React.createElement(Main, {
          component: this,
        }),
        root
      );
    }
  }
}

export interface TableLayoutSettings {
  supplierId: string;
  costId: string;
  moldCode: string;
}
