import { Backdrop, CircularProgress } from "@material-ui/core";
import * as React from "react";
import { TableLayoutContext } from "./context/table-layout.context";

export function MainBackdrop() {
  const { backdropOpen } = React.useContext(TableLayoutContext);

  return (
    <Backdrop
      style={{
        zIndex: 200,
        color: "#fff",
      }}
      open={backdropOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
