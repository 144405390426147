import { Entity } from "../entity.service";

export class AppProjectContact extends Entity {
  type = "app.project_contacts";

  relation_id: string;

  created_at?: Date;
  updated_at?: Date;
}
