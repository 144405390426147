import gql from "graphql-tag";

export const storeServicePlanningAssignmentMutation = gql`
  mutation storeServicePlanningAssignmentMutation(
    $input: InputServicePlanningAssignmentEntity!
  ) {
    result: ServicePlanningAssignmentEntityUpdateOne(input: $input) {
      id
    }
  }
`;

export const storeServicePlanningEventMutation = gql`
  mutation storeServicePlanningEventMutation(
    $input: InputServicePlanningEventEntity!
  ) {
    result: ServicePlanningEventEntityUpdateOne(input: $input) {
      id
    }
  }
`;

export const deleteServicePlanningEventMutation = gql`
  mutation deleteServicePlanningEventMutation($id: ID!) {
    result: ServicePlanningEventEntityDeleteOne(input: { id: $id })
  }
`;

export const deleteServicePlanningAssignmentMutation = gql`
  mutation deleteServicePlanningAssignmentMutation($id: ID!) {
    result: ServicePlanningAssignmentEntityDeleteOne(input: { id: $id })
  }
`;
