import { User } from './accessibility-users/user.entity';

export class GrantHelper {
    constructor(protected readonly user: User) { }

    var<T>(configId: string, defaultValue: T) {
        const userLevel = this.userGrantCfg(configId);

        if (userLevel && userLevel.value) {
            return userLevel.value;
        }

        const grantLevel = this.roleGrantCfg(configId);

        if (grantLevel && grantLevel.value) {
            return grantLevel.value;
        }

        return defaultValue;
    }

    varIs(configId: string, comparator: string) {
        return this.var(configId, '') === comparator;
    }

    protected userGrantCfg(configId: string) {
        return (
            this.hasUser() ? (this.user.__grantConfigs__ || []) : []
        ).find(e => e.configId === configId);
    }

    protected roleGrantCfg(configId: string) {
        return (
            this.hasRole() ? (this.user.__role__.__grantConfigs__ || []) : []
        ).find(e => e.configId === configId);
    }

    protected hasRole() {
        return this.hasUser() && !!this.user.__role__;
    }

    protected hasUser() {
        return !!this.user;
    }
}
