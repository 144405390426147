import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  ComplexTableDataRow,
  ComplexTableDataGroup,
  ComplexTableColumn,
  ComplexTableAction,
  ComplexTableActionClicked,
} from "../complex-table/complex-table.component";
import { MetacomService } from "../metacom.service";
import { formatNumber } from "@angular/common";
import { DialogService } from "../dialog.service";
import { AuthService } from "../auth.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import { Invoice } from "../invoices/invoice.entity";
import { StorageService } from "../storage.service";
import * as moment from "moment";
import { ActivatedRoute } from "@angular/router";
import { chain, first, orderBy } from "lodash";

@Component({
  selector: "app-metacom-project-invoices",
  templateUrl: "./metacom-project-invoices.component.html",
  styleUrls: ["./metacom-project-invoices.component.scss"],
})
export class MetacomProjectInvoicesComponent implements OnInit {
  tableData: ComplexTableDataGroup<Invoice>[];

  columns = [
    new ComplexTableColumn<Invoice>({
      id: "bedrijf",
      title: "Bedrijf",
      width: "100px",
      text: (value) => value.bedrijf,
    }),
    new ComplexTableColumn<Invoice>({
      id: "executive",
      title: "Relatie",
      width: "auto",
      text: (value) => `${value.relatie_naam}`,
      sort: (value) => `${value.relatie_naam}`,
    }),
    new ComplexTableColumn<Invoice>({
      id: "id",
      title: "Nummer",
      width: "100px",
      text: (value) => value.doknr,
    }),
    new ComplexTableColumn<Invoice>({
      id: "date",
      title: "Datum",
      width: "128px",
      text: (value) => value.factuur_datum,
      sort: (value) =>
        value.factuur_datum ? moment(value.factuur_datum).unix() : 0,
    }),
    new ComplexTableColumn<Invoice>({
      id: "description",
      title: "Omschrijving",
      width: "auto",
      text: (value) => value.omschrijving,
    }),
    new ComplexTableColumn<Invoice>({
      id: "sum",
      title: "Bedrag",
      width: "100px",
      align: "right",
      text: (value) => formatNumber(value.factuur_bedrag, "nl", "2.2-2"),
    }),
  ];

  actions = [
    new ComplexTableAction<Invoice>({
      id: "view",
      icon: "remove_red_eye",
      title: "Factuur weergeven",
    }),
  ];

  protected get projectId() {
    return this.activatedRoute.snapshot.paramMap.get("projectId");
  }

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly metacomService: MetacomService,
    protected readonly storageService: StorageService
  ) {}

  async ngOnInit() {
    await this.fetch();
  }

  action(event: ComplexTableActionClicked<Invoice>) {
    switch (event.action.id) {
      case "view":
        return this.view(event.item);
    }
  }

  async fetch() {
    this.tableData = null;

    const responseInvoices = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "project_facturen",
      filter: `wfn-jour.zknaam = "${this.projectId}"`,
    });

    const responseReceipts = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "project_bonnen",
      filter: `wfn-jour.zknaam = "${this.projectId}"`,
    });

    if (!responseInvoices.hasError() && !responseReceipts.hasError()) {
      const concatted = responseInvoices.value
        .map((item) => ({ ...item, prio: 0 }))
        .concat(responseReceipts.value.map((item) => ({ ...item, prio: 1 })));

      this.tableData = [
        new ComplexTableDataGroup<Invoice>(
          chain(concatted)
            .groupBy((item) =>
              [item.bedrijf, item.project, item.relatie, item.factuur].join("-")
            )
            .map((items) => orderBy(items, (i) => i.prio, "asc"))
            .map((items) => ({
              ...first(items),
              factuur_bedrag: first(items).bedrag,
              items,
            }))
            .value()
            .map((e) => new Invoice(e))
            .map((e) => new InvoiceTableRow(e))
        ),
      ];
    }
  }

  protected async view(invoice: Invoice) {
    const base64 = btoa(invoice.document);

    this.urlOpenService.open(
      `${environment.baseUrl}/volume-public/file-legacy/0/${base64}`,
      true
    );
  }
}

export class InvoiceTableRow extends ComplexTableDataRow<Invoice> {
  constructor(invoice: Invoice) {
    super(invoice);
  }
}
