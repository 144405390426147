import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-secured-image",
  template: `
    <div snInViewport>
      <img oncontextmenu="return false" [src]="fullSrc" />
    </div>
  `,
  styles: ["div { display: flex; }"],
})
export class SecuredImageComponent {
  @Input() protected src: string;
  @Input() protected disableViewport = false;

  get fullSrc() {
    const isFromApi =
      !this.src?.startsWith("data") && !this.src?.startsWith("http");

    return isFromApi ? `${environment.baseUrl}/${this.src}` : this.src;
  }

  @Input() protected disableCache = false;
}
