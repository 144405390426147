import { MeterEntity } from "src/app/service-settings/react/meters/meter.entity";

export class MeterEvent {}

export class MeterLoadRequestEvent extends MeterEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class MeterLoadSuccessEvent extends MeterEvent {}

export class MeterUpdatedEvent extends MeterEvent {
  constructor(
    readonly props: {
      readonly meter: MeterEntity;
      readonly value: string;
    }
  ) {
    super();
  }
}
