import { Entity } from "../entity.service";

export class HourDeclaration extends Entity {
  type = "hour_declarations";

  userId: string;
  projectId: string;
  priceAgreementId?: string;
  storedDocumentId?: string;

  hourDeclarationTypeId?: string;
  hourDeclarationActivityId?: string;

  date: Date;
  description: string;

  amount?: string;
  baseHours?: string;
  savingHours?: string;
  overTimeHours?: string;
  travelHours?: string;

  __hourDeclarationType__?: HourDeclarationType;
  __hourDeclarationActivity__?: HourDeclarationActivity;
  __priceAgreement__?: PriceAgreement;

  datePicker;
  isDeleted: boolean;
}

export class HourDeclarationApprovalRequest extends Entity {
  type = "hour_declaration_approval_requests";

  userId: string;
  period: string;
}

export class HourDeclarationType extends Entity {
  type = "hour_declaration_types";

  companyId: string;
  hasActivities: string;
  administrationType: string;

  kind: string;
  kindDescription: string;

  isExternal: string;
}

export class HourDeclarationActivity extends Entity {
  type = "hour_declaration_activities";

  administrationType: string;
}

export interface PriceAgreement {
  id: string;
  middel: string;
  middel_oms: string;
  kostensoort: string;

  prijs_inkoop: string;
  prijs_eenheid: string;
}

export interface AdministrationProject {
  id: string;
  project: string;
  omschrijving: string;
  prjadm: string;
  bedrijf: string;
  stadium: string;
  stadiumNr: number;
  uren_type: string;
}
