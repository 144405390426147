import { AppProjectCost } from "./app-project-cost.entity";
import { first } from "lodash";
import { formatNumber } from "@angular/common";

export class AppProjectCostCsvGenerator {
  constructor(protected readonly root: AppProjectCost) {}

  generate() {
    return this.generateCsv(Array.from(this.makeAllCsvItems()));
  }

  protected *makeAllCsvItems() {
    let i = 0;
    for (const cost of this.root.children) {
      yield* this.makeCsvItems(cost, i++);
    }
  }

  protected *makeCsvItems(cost: AppProjectCost, index: number) {
    if (!cost.children || !cost.children.length) {
      const indexText = formatNumber(index, "nl", "3.0-0");

      yield {
        project: this.root.project_id,
        regelCode: cost.id ? `M${indexText}` : "",
        omschrijving: `"${cost.title || ""}"`,
        memo: `"${cost.memo || ""}"`,
        bedrag: cost.value,
      };
    } else {
      for (const item of cost.children) {
        yield* this.makeCsvItems(item, index);
      }
    }
  }

  protected generateCsv(items: any[]) {
    return Array.from(this.generateCsvLines(items)).join("\r\n") + "\r\n";
  }

  protected *generateCsvLines(items: any[]) {
    yield Object.keys(first(items)).join(";");

    for (const item of items) {
      yield this.generateCsvRow(item);
    }
  }

  protected generateCsvRow(item: any) {
    return Object.keys(item)
      .map((k) => item[k])
      .join(";");
  }
}
