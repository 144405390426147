import { orderBy } from "lodash";
import { EntityManager, Ops } from "src/app/entity.service";
import { Bloc } from "src/app/service-settings/bloc/bloc";
import { MeterEntity } from "src/app/service-settings/react/meters/meter.entity";
import { DeliveryListMeterEntity } from "../../delivery-list.entity";
import {
  MeterEvent,
  MeterLoadRequestEvent,
  MeterUpdatedEvent,
} from "./meter.event";
import {
  MeterLoadFailureState,
  MeterLoadInProgressState,
  MeterLoadSuccessState,
  MeterState,
} from "./meter.state";

export class MeterBloc extends Bloc<MeterEvent, MeterState> {
  constructor(readonly entities: EntityManager) {
    super(new MeterLoadInProgressState());
  }

  async *mapEventToState(event: MeterEvent) {
    if (event instanceof MeterLoadRequestEvent) {
      yield* await this._mapLoadRequestToState(event);
    }

    if (event instanceof MeterUpdatedEvent) {
      yield* await this._mapMeterUpdatedToState(event);
    }
  }

  async *_mapLoadRequestToState(event: MeterLoadRequestEvent) {
    const repo = this.entities.get(MeterEntity);
    const response = await repo.query();

    if (!response.hasError()) {
      const meters = orderBy(response.value, (meter) => meter.orderId);

      const repoValues = this.entities.get(DeliveryListMeterEntity);
      const responseValues = await repoValues.query({
        filters: [Ops.Field("projectId").Equals(event.props.projectId)],
        orders: [{ field: "createdAt", direction: "ASC" }],
      });

      if (!responseValues.hasError()) {
        meters.forEach(
          (meter) =>
            (meter.deliveryListMeter =
              responseValues.value.find(
                (dlMeter) => dlMeter.meterId === meter.id
              ) ||
              repoValues.concept({
                meterId: meter.id,
                projectId: event.props.projectId,
                value: "",
              }))
        );

        yield new MeterLoadSuccessState({ meters });
      } else {
        yield new MeterLoadFailureState({
          message: response.error.message,
        });
      }
    } else {
      yield new MeterLoadFailureState({
        message: response.error.message,
      });
    }
  }

  async *_mapMeterUpdatedToState(event: MeterUpdatedEvent) {
    event.props.meter.deliveryListMeter.value = event.props.value;

    const repo = this.entities.get(DeliveryListMeterEntity);
    const response = await repo.save(event.props.meter.deliveryListMeter);
    const successState = this.state.value as MeterLoadSuccessState;

    if (!response.hasError()) {
      yield new MeterLoadSuccessState({
        meters: [...successState.props.meters],
      });
    }
  }
}
