import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Component, Inject } from "@angular/core";
import * as moment from "moment";
import { Cargo } from "../cargo/house-part.entity";
import { EntityManager } from "../entity.service";
import { AuthService } from "./../auth.service";

@Component({
  selector: "app-cargo-notes-dialog",
  templateUrl: "./cargo-notes-dialog.component.html",
  styleUrls: ["./cargo-notes-dialog.component.scss"],
})
export class CargoNotesDialogComponent {
  notesToAdd: String = "";
  isEdit = false;

  protected get cargoService() {
    return this.entityManager.get(Cargo);
  }

  get sanitizedNotes() {
    return (this.data.cargo.notes ?? "")
      .split("\r\n")
      .map((line) => (line.startsWith("@") ? `<strong>${line}</strong>` : line))
      .join("<br />");
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: CargoNotesDialogComponentData,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly dialogRef: MdcDialogRef<CargoNotesDialogComponent>
  ) {}

  toggleEdit() {
    this.isEdit = !this.isEdit;
  }

  async save() {
    this.data.cargo.notes =
      (this.isEdit
        ? ""
        : [
            this.notesToAdd,
            `@${this.authService.user.name} - ${moment().format(
              "DD-MM-YYYY HH:mm"
            )}`,
            ...[" ", " "],
          ].join("\r\n")) + (this.data.cargo.notes ?? "");

    await this.cargoService.save(this.data.cargo);

    this.dialogRef.close(this.data.cargo);
  }
}

export class CargoNotesDialogComponentData {
  constructor(readonly cargo: Cargo) {}
}
