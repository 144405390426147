import * as React from "react";
import {
  ConstructionPlanningColorLineDto,
  ConstructionPlanningProjectDto,
} from "../construction-planning-date.dto";
import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";

const useStyles = makeStyles({
  column: (props: any) => ({
    color: props.color,
  }),
});

export interface ConstructionPlanningBuildingWeekColumnProps {
  project: ConstructionPlanningProjectDto;
  lines: ConstructionPlanningColorLineDto[];
}

export function ConstructionPlanningBuildingWeekColumn({
  project,
  lines,
}: ConstructionPlanningBuildingWeekColumnProps) {
  const color = useMemo(() => getColor(), [project, lines]);
  const classes = useStyles({ color });

  function getColor() {
    const match = lines.find(
      (_line) => _line.calculatedWeek > project.buildingWeek
    );

    return match ? match.colorCode : null;
  }

  return <span className={classes.column}>{project.buildingWeek}</span>;
}
