import * as moment from "moment";
import { RestService } from "src/app/rest.service";
import {
  DeliveryListMeterEntityProps,
  DeliveryListPointEntity,
} from "../../delivery-list.entity";
import { DeliveryListLoadSuccessState } from "../core/delivery-list.state";
import { CompletionLoadSuccessState } from "./completion.state";
import { TemplateDocumentId } from "src/app/service-settings/react/template/template.bloc";
import { formatNumber } from "@angular/common";
import { chain, first, flatten, orderBy } from "lodash";

async function unWrapAsyncIterator(iterator: AsyncIterableIterator<{}>) {
  const arr = [];
  for await (const i of iterator) arr.push(i);
  return arr;
}

async function* mapStateToHouse(
  rest: RestService,
  stateCore: DeliveryListLoadSuccessState
) {
  if (stateCore.props.deliveryList.houseDocumentMetaId) {
    yield {
      width: "100%",
      alignment: "left",
      text: "Uw woning",
      margin: [0, 10, 0, 10],
      fontSize: 15,
    };
    yield {
      image: await rest.getFileAsDataUri(
        `documents/resize/${stateCore.props.deliveryList.houseDocumentMetaId}/512`
      ),
      width: 500,
      alignment: "center",
    };
    yield { text: "", pageBreak: "after" };
  }
}

async function* mapPointToImagesRow(
  rest: RestService,
  point: DeliveryListPointEntity
) {
  yield {
    text: "",
    border: [false, false, true, true],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  yield {
    colSpan: 3,
    border: [false, false, false, true],
    columnGap: 4,
    columns: await Promise.all(
      point.images.map(async (_image) => ({
        image: await rest.getFileAsDataUri(
          `documents/resize/${_image.documentMetaId}/75`
        ),
        // alignment: 'right',
        width: 75,
        heigh: 75,
        fit: [75, 75],
      }))
    ),
    margin: [0, 0, 0, 4],
    alignment: "right",
  };

  yield {
    text: "",
    border: [false, false, true, true],
    style: ["small"],
    alignment: "right",
  };

  yield {
    text: "",
    border: [false, false, true, true],
    style: ["small"],
  };
}

function* mapPointToRow(point: DeliveryListPointEntity, index: number) {
  yield {
    text: formatNumber(index + 1, "nl", "3.0-0"),
    border: [false, false, true, false],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  yield {
    text: point.serviceType.description,
    border: [false, false, false, false],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  yield {
    text: point.serviceSpace.description,
    border: [false, false, false, false],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  yield {
    text: point.description,
    border: [false, false, false, false],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  // const chunks = chunk(point.images, 2);

  // yield await Promise.all(chunks.map(async (_chunk) => ({
  //   columnGap: 6,
  //   border: [false, false, false, true],
  //   columns: await Promise.all(_chunk.map(async (_image) => ({
  //     image: await rest.getFileAsDataUri(
  //       `documents/view/${_image.documentMetaId}`
  //     ),
  //     width: 75,
  //   })))
  // })));
}

function* mapMeterToRow(meter: DeliveryListMeterEntityProps) {
  yield {
    text: meter.meter.description,
    border: [false, false, true, true],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };

  yield {
    text: `${meter.value} ${meter.meter.unit}`,
    border: [false, false, false, true],
    // margin: [0, 5, 0, 5],
    style: ["small"],
  };
}

function getSignatureDocumentId(
  state: CompletionLoadSuccessState,
  kind: string
) {
  const signature = state.props.signatures.find(
    (_signature) => _signature.kind === kind
  );

  return signature ? signature.documentMetaId : "";
}

async function mapStateToPoints(
  rest: RestService,
  state: CompletionLoadSuccessState
) {
  const results = chain(state.props.points)
    .groupBy((_point) => _point.serviceTypeId)
    .map((_items, _key) => ({
      typeName: first(_items).serviceType
        ? first(_items).serviceType.description
        : "Niet toegewezen",
      amount: _items.length,
    }))
    .value();

  return [
    {
      width: "100%",
      alignment: "left",
      text: "Rapportage",
      margin: [0, 10, 0, 10],
      fontSize: 15,
    },
    {
      table: {
        headerRows: 1,
        widths: [32, 64, 128, "*"],
        body: [
          [
            {
              text: "",
              fillColor: "#09c",
              border: [false, false, false, false],
              // margin: [0, 5, 0, 5],
              textTransform: "uppercase",
              style: ["small"],
            },
            {
              text: "Type".toUpperCase(),
              border: [false, false, false, false],
              fillColor: "#09c",
              color: "white",
              // margin: [0, 5, 0, 5],
              textTransform: "uppercase",
              style: ["small"],
            },
            {
              text: "Ruimte".toUpperCase(),
              border: [false, false, false, false],
              fillColor: "#09c",
              color: "white",
              // margin: [0, 5, 0, 5],
              textTransform: "uppercase",
              style: ["small"],
            },
            {
              text: "Gebrek".toUpperCase(),
              border: [false, false, false, false],
              fillColor: "#09c",
              color: "white",
              // margin: [0, 5, 0, 5],
              textTransform: "uppercase",
              style: ["small"],
            },
          ],
          ...flatten(
            await Promise.all(
              state.props.points.map(async (_point, _index) => [
                Array.from(mapPointToRow(_point, _index)),
                await unWrapAsyncIterator(mapPointToImagesRow(rest, _point)),
              ])
            )
          ),
        ],
      },
    },
    "\n",
    /** Total gebreken */
    {
      table: {
        headerRows: 1,
        widths: ["*", "auto"],
        body: [
          ...results.map((_group) => [
            {
              text: `Totaal aantal ${_group.typeName}`,
              border: [false, true, false, true],
              borderColor: "#eeeeee",
              italics: true,
              alignment: "left",
              // margin: [0, 5, 0, 5],
              style: ["small"],
            },
            {
              border: [false, true, false, true],
              borderColor: "#eeeeee",
              text: _group.amount.toString(),
              alignment: "right",
              // margin: [0, 5, 0, 5],
              style: ["small"],
            },
          ]),
          [
            {
              text: "Totaal aantal",
              border: [false, true, false, true],
              borderColor: "#eeeeee",
              alignment: "left",
              // margin: [0, 5, 0, 5],
              style: ["small"],
            },
            {
              border: [false, true, false, true],
              borderColor: "#eeeeee",
              text: state.props.points.length.toString(),
              alignment: "right",
              // margin: [0, 5, 0, 5],
              style: ["small"],
            },
          ],
        ],
      },
    },
  ];
}

function mapStateToMeters(state: CompletionLoadSuccessState) {
  return [
    /** Meters */
    {
      width: "100%",
      alignment: "left",
      text: "Meterstanden",
      margin: [0, 10, 0, 10],
      fontSize: 15,
    },
    {
      table: {
        // heights: 5,
        headerRows: 1,
        widths: [200, "*"],
        body: [
          [
            {
              text: "Meter".toUpperCase(),
              border: [false, false, false, false],
              fillColor: "#09c",
              color: "white",
              textTransform: "uppercase",
              style: ["small"],
            },

            {
              text: "Stand".toUpperCase(),
              border: [false, false, false, false],
              fillColor: "#09c",
              color: "white",
              textTransform: "uppercase",
              style: ["small"],
            },
          ],
          ...orderBy(
            state.props.meters.filter((_meter) => !!_meter.meter),
            (_meter) => _meter.meter.orderId
          ).map((_meter) => Array.from(mapMeterToRow(_meter))),
        ],
      },
    },
    { text: "", pageBreak: "after" },
  ];
}

export const completionPdfBlueprint = async (
  rest: RestService,
  state: CompletionLoadSuccessState,
  stateCore: DeliveryListLoadSuccessState
) => ({
  pageSize: "A4",
  pageMargins: [48, 48, 48, 60],
  footer: true,
  content: [
    {
      image: await rest.getFileAsDataUri(
        `documents/view/${TemplateDocumentId.Front}`
      ),
      width: 595,
      height: 842,
      absolutePosition: { x: 0, y: 0 },
    },
    {
      columns: [
        {
          stack: [
            {
              text: "PROJECT",
              color: "#09c",
              bold: true,
            },
            {
              text: `${stateCore.props.deliveryList.projectId.toString()}`,
              // bold: true,
              color: "#333333",
            },
            {
              text: stateCore.props.deliveryList.project.customerName,
            },
            {
              text: `${stateCore.props.deliveryList.project.buildingStreet} ${stateCore.props.deliveryList.project.buildingHouseNumber} \n ${stateCore.props.deliveryList.project.buildingZipCode} ${stateCore.props.deliveryList.project.buildingCity}`,
            },
          ],
        },
        {
          stack: [
            {
              text: "DATUM",
              color: "#09c",
              bold: true,
            },
            {
              text: moment(
                stateCore.props.deliveryList.isDeliveredAt || new Date()
              )
                .locale("nl")
                .format("DD MMMM YYYY"),
            },
          ],
        },
      ],
      absolutePosition: { x: 48, y: 650 },
    },
    { text: "", pageBreak: "after" },
    {
      image: await rest.getFileAsDataUri(
        `documents/view/${TemplateDocumentId.Intro}`
      ),
      width: 595,
      height: 842,
      absolutePosition: { x: 0, y: 0 },
    },
    { text: "", pageBreak: "after" },
    ...(await unWrapAsyncIterator(mapStateToHouse(rest, stateCore))),
    {
      columns: [
        {
          text: "De ondernemer",
          color: "#09c",
          bold: true,
          fontSize: 14,
          alignment: "left",
          margin: [0, 20, 0, 5],
        },
        {
          text: "De verkrijger",
          color: "#09c",
          bold: true,
          fontSize: 14,
          alignment: "left",
          margin: [0, 20, 0, 5],
        },
      ],
    },
    {
      columns: [
        {
          text: "Groothuisbouw",
          bold: true,
          color: "#333333",
          alignment: "left",
        },
        {
          text: stateCore.props.deliveryList.project.customerName,
          bold: true,
          color: "#333333",
          alignment: "left",
        },
      ],
    },
    {
      columns: [
        {
          text: "Adres",
          color: "#09c",
          bold: true,
          margin: [0, 7, 0, 3],
        },
        {
          text: "Adres",
          color: "#09c",
          bold: true,
          margin: [0, 7, 0, 3],
        },
      ],
    },
    {
      columns: [
        {
          text: "Titaniumweg 10 \n 8304 BR Emmeloord",
          style: "invoiceBillingAddress",
        },
        {
          text: `${stateCore.props.deliveryList.project.buildingStreet} ${stateCore.props.deliveryList.project.buildingHouseNumber} \n ${stateCore.props.deliveryList.project.buildingZipCode} ${stateCore.props.deliveryList.project.buildingCity}`,
          style: "invoiceBillingAddress",
        },
      ],
    },
    "\n\n",
    `Opgeleverd door: ${stateCore.props.deliveryList.assignedUser.name}\n`,
    `Aangeleverde sleutels: ${stateCore.props.deliveryList.keyAmount || 0} st`,
    "\n",
    ...mapStateToMeters(state),
    ...(await mapStateToPoints(rest, state)),
    { text: "", pageBreak: "after" },
    /** Signatures */
    {
      width: "100%",
      alignment: "left",
      text: "Handtekening",
      margin: [0, 10, 0, 10],
      fontSize: 15,
    },
    {
      text: `Datum: ${moment(
        stateCore.props.deliveryList.isDeliveredAt || new Date()
      )
        .locale("nl")
        .format("DD MMMM YYYY")}`,
      style: "notesText",
    },
    {
      columns: [
        {
          text: "De ondernemer",
          color: "#09c",
          bold: true,
          margin: [0, 7, 0, 3],
          width: 256,
        },
        {
          text: "De verkrijger",
          color: "#09c",
          bold: true,
          margin: [0, 7, 0, 3],
          width: 256,
        },
      ],
    },
    {
      columns: [
        {
          image: await rest.getFileAsDataUri(
            `documents/view/${getSignatureDocumentId(state, "employee")}`
          ),
          margin: [0, 16],
          width: 256,
        },
        {
          image: await rest.getFileAsDataUri(
            `documents/view/${getSignatureDocumentId(state, "customer")}`
          ),
          margin: [0, 16],
          width: 256,
        },
      ],
    },
  ],
  styles: {
    table: {
      fontSize: 12,
    },
    notesTitle: {
      fontSize: 10,
      bold: true,
      margin: [0, 50, 0, 3],
    },
    notesText: {
      fontSize: 10,
    },
    small: {
      fontSize: 10,
      borderColor: "#eeeeee",
    },
  },
  defaultStyle: {
    fontSize: 12,
    columnGap: 20,
  },
});
