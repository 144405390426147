import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { EmployeeDocumentEntity } from "./employee-document.entity";
import { RestResponse } from "../rest.service";
import { FileUploadData, FileUploadService } from "../file-upload.service";
import { AuthService } from "../auth.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import { chain } from "lodash";
import * as moment from "moment";
import { DialogService } from "../dialog.service";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";

@Component({
  selector: "app-employee-file-dialog",
  templateUrl: "./employee-file-dialog.component.html",
  styleUrls: ["./employee-file-dialog.component.scss"],
})
export class EmployeeFileDialogComponent implements OnInit {
  response: RestResponse<EmployeeDocumentEntity[]>;

  isInternalUpload: boolean = false;

  protected get employeeDocumentService() {
    return this.entityManager.get(EmployeeDocumentEntity);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: EmployeeFileDialogComponentData,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly fileUploadService: FileUploadService,
    protected readonly dialogService: DialogService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.fetch();
  }

  open(item: EmployeeDocumentEntity) {
    this.urlOpenService.open(
      `${environment.baseUrl}/documents/open/${item.documentMetaId}`,
      true
    );
  }

  async delete(item: EmployeeDocumentEntity) {
    if (await this.dialogService.confirm(this.changeDetector)) {
      if (!(await this.employeeDocumentService.delete(item.id)).hasError()) {
        this.fetch();
      }
    }
  }

  async addFiles(files: NgxFileDropEntry[]) {
    const documents = await Promise.all(
      (files || [])
        .filter((e) => e.fileEntry.isFile)
        .map((e) => e.fileEntry as FileSystemFileEntry)
        .map((e) => this.destructFile(e))
    );

    const documentIds = await this.fileUploadService.upload(
      new FileUploadData(documents),
      "3000"
    );

    const documentsToStore = documentIds.map((e) =>
      this.employeeDocumentService.concept({
        userId: this.authService.user.id,
        relationId: this.data.relationId,
        employeeId: this.data.employeeId,
        documentMetaId: e.id,
        comment: e.description,
        isInternal: this.isInternalUpload,
        __user__: this.authService.user,
        __documentMeta__: e,
      })
    );

    const entities = await Promise.all(
      documentsToStore.map((e) => this.employeeDocumentService.save(e))
    );

    this.response.value.push(...entities.map((e) => e.value));
  }

  protected destructFile(entry: FileSystemFileEntry) {
    return new Promise<File>((resolve) => entry.file((f) => resolve(f)));
  }

  protected async promptForDocuments() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  protected async fetch() {
    const response = await this.employeeDocumentService.query({
      filters: [
        { field: "relationId", operator: "Equal", value: this.data.relationId },
        {
          field: "employeeId",
          operator: this.data.employeeId ? "Equal" : "IsNull",
          value: this.data.employeeId,
        },
        ...(this.data.showInternal
          ? []
          : [{ field: "isInternal", operator: "Equal", value: false }]),
      ],
      relations: ["documentMeta", "user"],
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        chain(response.value)
          .orderBy((e) => moment(e.createdAt).unix(), "desc")
          .value()
      );
    }
  }
}

export interface EmployeeFileDialogComponentData {
  readonly relationId: string;
  readonly employeeId?: string;
  readonly showInternal?: boolean;
}
