import { OnInit, ChangeDetectorRef } from "@angular/core";
import { EntityManager, Entity, EntityService } from "../entity.service";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { DialogService } from "../dialog.service";
import {
  AppCrudDialogBoilerplateData,
  AppCrudDialogBoilerplate,
} from "./app-crud.dialog.boilerplate";
import { Subject } from "rxjs";
import { orderBy } from "lodash";

export abstract class AppCrudBoilerplate<T extends Entity> implements OnInit {
  response: RestResponse<T[]>;
  orderByField: string = "id";

  get entityService() {
    return this.entityManager.get(this.entity);
  }

  get relations() {
    return [];
  }

  get filters() {
    return [];
  }

  abstract get entity(): ICrudEntity<T>;
  abstract get editDialog(): ICrudEditDialog<T>;

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.fetch();
  }

  async edit(entity: T) {
    const copy = this.entityService.copy(entity);
    const response = await this.dialogs.open(
      this.changeDetector,
      this.editDialog,
      { data: new AppCrudDialogBoilerplateData<T>(copy, this) }
    );

    if (response !== "close") {
      await this.fetch();
    }
  }

  async delete(event: Event, entity: T) {
    event.preventDefault();
    event.stopPropagation();

    if (await this.dialogs.confirm(this.changeDetector)) {
      const response = await this.transactions.perform(
        () => this.entityService.delete(entity.id),
        "Verwijderd"
      );

      if (!response.hasError()) {
        await this.fetch();
      }
    }
  }

  protected async fetch() {
    const response = await this.entityService.query({
      orders: [{ field: this.orderByField, direction: "asc" }],
      relations: this.relations,
      filters: this.filters,
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        orderBy(response.value, (entity) => entity[this.orderByField], "asc")
      );
    }
  }
}

export interface ICrudEditDialog<T extends Entity> {
  new (...args: any[]): AppCrudDialogBoilerplate<T>;
}

export interface ICrudEntity<T extends Entity> {
  new (entityManager: EntityManager): T;
}

export class AppCrudBoilerplateInstance<
  T extends Entity
> extends AppCrudBoilerplate<T> {
  get entity() {
    return this.data.properties.entity;
  }

  get editDialog() {
    return this.data.properties.editDialog;
  }

  get relations() {
    return this.data.properties.relations || [];
  }

  get filters() {
    return this.data.properties.filters || [];
  }

  onUpdated = new Subject<{}>();

  constructor(
    readonly data: AppCrudBoilerplateInstanceData<T>,
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);

    this.orderByField = this.data.properties.orderByField || "id";
  }

  async fetch() {
    if (!this.data.properties.disableFetch) {
      await super.fetch();
    }

    this.onUpdated.next({});
  }
}

export class AppCrudBoilerplateInstanceData<T extends Entity> {
  constructor(
    readonly properties: {
      readonly entity: ICrudEntity<T>;
      readonly editDialog?: ICrudEditDialog<T>;
      readonly relations?: string[];
      readonly filters?: any[];
      readonly orderByField?: string;
      readonly disableFetch?: boolean;
    }
  ) {}
}
