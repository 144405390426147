import { MdcSnackbar } from "@angular-mdc/web";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import * as moment from "moment";
import { SignaturePad } from "ngx-signaturepad/signature-pad";
import {
  AuditQuestionCostCategory,
  CostCategory,
} from "../audit-sheet-table/cost-category.entity";
import { AuditCategory } from "../audit-sheet/audit-category.entity";
import { AuthService } from "../auth.service";
import { DialogService } from "../dialog.service";
import { DocumentMeta } from "../documents/document-meta.entity";
import { EntityManager } from "../entity.service";
import { FileUploadData, FileUploadService } from "../file-upload.service";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { ComponentCanDeactivate } from "../pending-changes.guard";
import { Project } from "../project/project.entity";
import { RestResponse, RestService } from "../rest.service";
import {
  AuditReport,
  AuditReportItem,
  AuditReportItemImage,
} from "./audit-report.entities";

@Component({
  selector: "app-audit-sheet-report",
  templateUrl: "./audit-sheet-report.component.html",
  styleUrls: ["./audit-sheet-report.component.scss"],
})
export class AuditSheetReportComponent
  implements OnInit, ComponentCanDeactivate
{
  @ViewChild("signature", { static: false }) signature: SignaturePad;

  signatureUrl: string;
  report: RestResponse<AuditReport>;
  auditCategories: RestResponse<AuditCategory[]>;

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get costCategoryService() {
    return this.entityManager.get(CostCategory);
  }

  protected get documentMetaService() {
    return this.entityManager.get(DocumentMeta);
  }

  protected get auditCategoryService() {
    return this.entityManager.get(AuditCategory);
  }

  protected get auditReportService() {
    return this.entityManager.get(AuditReport);
  }

  protected get auditReportItemService() {
    return this.entityManager.get(AuditReportItem);
  }

  protected get auditReportItemImageService() {
    return this.entityManager.get(AuditReportItemImage);
  }

  protected get auditQuestionCostCategoryService() {
    return this.entityManager.get(AuditQuestionCostCategory);
  }

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get costCategoryId() {
    return this.route.snapshot.paramMap.get("costCategoryId");
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly dialogService: DialogService,
    protected readonly fileUploadService: FileUploadService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly snackbar: MdcSnackbar,
    protected readonly entityManager: EntityManager
  ) {}

  ngOnInit() {
    this.fetch();
  }

  canDeactivate() {
    return false;
  }

  async viewImage(image: AuditReportItemImage, parent: AuditReportItem) {
    const result = await this.dialogService.open(
      this.changeDetector,
      ImageDialogComponent,
      {
        data: {
          url: image.dataUrl,
          buttons: this.canEdit
            ? [{ buttonText: "Verwijderen", buttonAction: "delete" }]
            : [],
        },
      }
    );

    if (result === "delete") {
      if (image.id) {
        image.isMarkedFormDeletion = true;
      } else {
        const index = parent.__auditReportItemImages__.indexOf(image);
        parent.__auditReportItemImages__.splice(index, 1);
      }
    }
  }

  setUser(item: AuditReportItem) {
    item.userId = this.authService.user.id;
    item.__user__ = this.authService.user;
  }

  get canSave() {
    return (
      this.canEdit &&
      this.allItemsChecked &&
      (this.signatureUrl || (this.signature && !this.signature.isEmpty()))
    );
  }

  get allItemsChecked() {
    return this.report.value.__auditReportItems__.every((r) => !!r.state);
  }

  get canEdit() {
    return !this.report.value.lockedAt;
  }

  async setAllState(state: string) {
    for (const item of this.report.value.__auditReportItems__) {
      if (item.state !== "ok") {
        item.state = state;
        this.setUser(item);
      }
    }
  }

  async save(isFinal = false) {
    this.report.value.id = (
      await this.auditReportService.save(this.report.value)
    ).value.id;

    this.report.value.isConcept = false;

    await this.saveSignature();

    const filledItems = this.report.value.__auditReportItems__.filter(
      (i) => i.userId
    );

    for (const item of filledItems) {
      item.auditReportId = this.report.value.id;
      item.id = (await this.auditReportItemService.save(item)).value.id;
      item.isConcept = false;

      for (const image of item.__auditReportItemImages__) {
        image.auditReportItemId = item.id;

        if (image.isMarkedFormDeletion && !image.isConcept) {
          await this.auditReportItemImageService.delete(image.id);
        } else if (image.isConcept) {
          const uploadData = new FileUploadData(image.dataFile);

          image.__documentMeta = _.first(
            await this.fileUploadService.upload(uploadData, this.projectId)
          );
          image.documentMetaId = image.__documentMeta.id;

          delete image.dataUrl;

          image.id = (
            await this.auditReportItemImageService.save(image)
          ).value.id;

          image.dataFile = null;
          image.dataUrl = `documents/view/${image.documentMetaId}`;
          image.isConcept = false;
        }
      }

      item.__auditReportItemImages__ = item.__auditReportItemImages__.filter(
        (e) => !e.isMarkedFormDeletion
      );
    }

    this.report.value.lockedAt = isFinal ? new Date() : null;

    await this.auditReportService.save(this.report.value);

    this.snackbar.open("Ogeslagen", "Ok");
  }

  clearSignature() {
    this.signatureUrl = null;
    this.signature.clear();
  }

  async saveSignature() {
    if (!this.signature.isEmpty()) {
      const signatureDataUrl = this.signature.toDataURL("image/png");
      const signatureData = this.dataURItoBlob(signatureDataUrl);

      const uploadData = new FileUploadData(signatureData);
      this.report.value.__signatureDocumentMeta__ = this.report.value
        .signatureDocumentMetaId
        ? await this.fileUploadService.overwrite(
            uploadData,
            this.report.value.signatureDocumentMetaId
          )
        : _.first(
            await this.fileUploadService.upload(uploadData, this.projectId)
          );
      this.report.value.signatureDocumentMetaId =
        this.report.value.__signatureDocumentMeta__.id;

      await this.auditReportService.save(this.report.value);

      this.makeSignatureUrl();
    }
  }

  async addImages(item: AuditReportItem) {
    const images = await this.promptForImages();

    if (images) {
      for (let i = 0; i < images.length; i++) {
        const dataFile = images.item(i);

        item.__auditReportItemImages__.push(
          this.auditReportItemImageService.concept({
            dataFile,
            auditReportItemId: item.id,
            dataUrl: await this.fileUploadService.imagePreview(dataFile),
          })
        );
      }
    }
  }

  protected async fetch() {
    await this.fetchReport();

    this.makeSignatureUrl();

    const categoryResponse = await this.auditCategoryService.query({
      relations: ["auditQuestions"],
      orders: [{ field: "orderId", direction: "ASC" }],
    });

    if (!categoryResponse.hasError()) {
      const mappingResponse = await this.auditQuestionCostCategoryService.query(
        {
          filters: [
            {
              field: "costCategoryId",
              operator: "Equal",
              value: this.costCategoryId,
            },
          ],
        }
      );

      if (!mappingResponse.hasError()) {
        const ids = mappingResponse.value.map((r) => r.auditQuestionId);

        categoryResponse.value.forEach(
          (c) =>
            (c.__auditQuestions__ = _.chain(c.__auditQuestions__)
              .filter((q) => ids.indexOf(q.id) >= 0)
              .forEach((q) => (q.item = this.getReportItem(q.id)))
              .filter((q) => (this.canEdit ? true : !!q.item && !!q.item.state))
              .orderBy((q) => q.orderId)
              .value())
        );

        this.auditCategories = new RestResponse(
          categoryResponse.value.filter((r) => r.__auditQuestions__.length > 0)
        );
      }
    }
  }

  protected makeSignatureUrl() {
    if (this.report.value.signatureDocumentMetaId) {
      const v = new Date().toISOString();
      this.signatureUrl = null;
      this.signatureUrl = `documents/view/${this.report.value.signatureDocumentMetaId}?v=${v}`;
    }
  }

  protected dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], "signature.png", { type: mimeString });
  }

  protected async promptForImages() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.setAttribute("accept", "image/*");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  protected async fetchReport() {
    const reportResponse = await this.auditReportService.queryFirst({
      filters: [
        { field: "projectId", operator: "Equal", value: this.projectId },
        {
          field: "costCategoryId",
          operator: "Equal",
          value: this.costCategoryId,
        },
      ],
      relations: [
        "project",
        "costCategory",
        "signatureDocumentMeta",
        "auditReportItems",
        "auditReportItems.auditReportItemImages",
      ],
    });

    this.report = reportResponse.value
      ? reportResponse
      : new RestResponse(
          this.auditReportService.concept({
            userId: this.authService.user.id,
            projectId: this.projectId,
            costCategoryId: this.costCategoryId,

            __user__: this.authService.user,
            __project__: (await this.projectService.findOne(this.projectId))
              .value,
            __costCategory__: (
              await this.costCategoryService.findOne(this.costCategoryId)
            ).value,
            __auditReportItems__: [],
          })
        );

    if (!!this.report.value.lockedAt && !this.report.value.inspectedAt) {
      this.report.value.inspectedAt = this.report.value.createdAt;
      this.report.value.inspectedAtTime = moment(
        this.report.value.inspectedAt
      ).format("HH:mm");
    }

    this.report.value.inspectedAt = moment(
      this.report.value.inspectedAt || new Date()
    ).format("YYYY-MM-DD");

    this.report.value.inspectedAtTime =
      this.report.value.inspectedAtTime || moment().format("HH:mm");
  }

  protected getReportItem(auditQuestionId: string) {
    const item =
      this.report.value.__auditReportItems__.find(
        (e) => e.auditQuestionId === auditQuestionId
      ) ||
      this.auditReportItemService.concept({
        userId: null, // be set when state changed :3
        reportId: this.report.value.id,
        auditQuestionId,
        __auditReportItemImages__: [],
      });

    if (item.isConcept && this.canEdit) {
      this.report.value.__auditReportItems__.push(item);
    } else {
      this.report.value.__auditReportItems__.forEach((e) => {
        e.__auditReportItemImages__.forEach(
          (i) => (i.dataUrl = `documents/view/${i.documentMetaId}`)
        );
      });
    }

    return item;
  }
}
