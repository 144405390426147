import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { chain } from "lodash";
import { MetacomService } from "../metacom.service";
import {
  MetacomProjectRelation,
  ProjectRelationTableRow,
} from "./metacom-project-relations.dto";
import {
  ComplexTableColumn,
  ComplexTableDataGroup,
} from "../complex-table/complex-table.component";

@Component({
  selector: "app-metacom-project-relations",
  templateUrl: "./metacom-project-relations.component.html",
  styleUrls: ["./metacom-project-relations.component.scss"],
})
export class MetacomProjectRelationsComponent implements OnInit {
  tableData: ComplexTableDataGroup<MetacomProjectRelation>[];

  columns = [
    new ComplexTableColumn<MetacomProjectRelation>({
      id: "relatie",
      title: "Relatie",
      width: "100px",
      text: (value) => value.relatie,
    }),
    new ComplexTableColumn<MetacomProjectRelation>({
      id: "relatie_naam",
      title: "Relatie naam",
      text: (value) => value.relatie_naam,
    }),
    new ComplexTableColumn<MetacomProjectRelation>({
      id: "telefoon",
      title: "Telefoon",
      text: (value) => `<a href='tel:${value.telefoon}' class='clickable'>${value.telefoon}</a>`,
    }),
    new ComplexTableColumn<MetacomProjectRelation>({
      id: "email",
      title: "E-mail",
      text: (value) => `<a href='mailto:${value.email}' class='clickable'>${value.email}</a>`,
    }),
    new ComplexTableColumn<MetacomProjectRelation>({
      id: "spacer",
      title: "",
      width: "100%",
      sortable: false,
      text: (value) => "&nbsp;",
    }),
  ];

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  constructor(
    protected readonly metacom: MetacomService,
    protected readonly route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const response = await this.metacom.queryTableAsync<MetacomProjectRelation>(
      {
        setName: "metacom",
        tableName: "project_relaties",
        filter: `prj_prj.prj = "${this.projectId}"`,
      }
    );

    if (!response.hasError()) {
      this.tableData = [
        new ComplexTableDataGroup<MetacomProjectRelation>(
          chain(response.value)
            .orderBy((e) => e.relatie, "asc")
            .map((value) => new ProjectRelationTableRow(value))
            .value()
        ),
      ];
    }
  }
}
