import { Component, Inject, OnInit } from "@angular/core";
import { AppDocumentType } from "../app-document-types/app-document-type.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { AppProjectContact } from "./app-project-contact.entity";
import { RestResponse } from "../rest.service";

@Component({
  selector: "app-app-document-type-dialog",
  templateUrl: "./app-document-type-dialog.component.html",
  styleUrls: ["./app-document-type-dialog.component.scss"],
})
export class AppDocumentTypeDialogComponent
  extends AppCrudDialogBoilerplate<AppDocumentType>
  implements OnInit {
  contactTypes: RestResponse<AppProjectContact[]>;

  get canSave() {
    return !!this.data.model.name;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppDocumentType>,
    protected readonly dialog: MdcDialogRef<AppDocumentTypeDialogComponent>
  ) {
    super(data, dialog);
  }

  async ngOnInit() {
    const repo = this.data.boilerplate.entityManager.get(AppProjectContact);
    this.contactTypes = await repo.queryRaw(
      "select distinct type from project_contacts"
    );
  }
}
