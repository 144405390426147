import { MdcDialogRef } from "@angular-mdc/web";
import { Component } from "@angular/core";
import { ReactComponent } from "../react-component/react.component";
import { ScanGoods } from "./react/ScanGoods";

@Component({
  selector: "app-scan-goods",
  templateUrl: "./scan-goods.component.html",
  styleUrls: ["./scan-goods.component.scss"],
})
export class ScanGoodsComponent extends ReactComponent {
  containerId = "react--scan-goods";
  component = ScanGoods;

  constructor(protected readonly dialogRef: MdcDialogRef<ScanGoodsComponent>) {
    super();
  }

  setGoodsId(id: string) {
    this.dialogRef.close(id);
  }
}
