import * as React from "react";
import * as moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { Apollo } from "apollo-angular";
import { useEffect, useState } from "react";
import {
  useCreatePicklistComment,
  useFetchPicklistComments,
} from "../picklist-component.hooks";
import { Alert, Skeleton } from "@material-ui/lab";
import { PicklistCommentDto } from "../dtos/picklist-comment.dto";

export interface PicklistCommentDialogProps {
  apollo: Apollo;
  userId: string;
  projectId: string;
  picklistDefinitionId: string;
  onClosed: () => void | Promise<any>;
}

export const PicklistCommentDialog = ({
  apollo,
  userId,
  projectId,
  picklistDefinitionId,
  onClosed,
}: PicklistCommentDialogProps) => {
  const { comments, fetchPicklistComments } = useFetchPicklistComments(
    apollo,
    projectId,
    picklistDefinitionId
  );

  const fetchComments = () => fetchPicklistComments();

  useEffect(() => {
    projectId && picklistDefinitionId && fetchComments();
  }, [projectId, picklistDefinitionId]);

  return (
    <Dialog open onClose={onClosed}>
      <DialogTitle>Opmerkingen</DialogTitle>
      <DialogContent style={{ minWidth: 400 }}>
        {comments && !comments.length && (
          <Alert icon={false} severity="info">
            Geen opmerkingen gevonden.
          </Alert>
        )}

        <List
          dense
          style={{
            border: "1px solid #eeeeee",
            marginBottom: 12,
            maxHeight: 500,
            overflowY: "scroll",
          }}
          disablePadding
        >
          {!comments && <PicklistCommentSkeleton />}
          {comments &&
            comments.map((comment) => (
              <PicklistCommentListItem key={comment.id} comment={comment} />
            ))}
        </List>

        <PicklistCommentCreator
          apollo={apollo}
          userId={userId}
          projectId={projectId}
          picklistDefinitionId={picklistDefinitionId}
          onAdded={fetchComments}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClosed}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PicklistCommentSkeleton = () => {
  return (
    <ListItem>
      <ListItemText
        primary={<Skeleton animation="wave" height={20} width="40%" />}
        secondary={<Skeleton animation="wave" height={20} width="50%" />}
      />
      <ListItemSecondaryAction>
        <Skeleton animation="wave" height={20} width={64} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface PicklistCommentListItemProps {
  comment: PicklistCommentDto;
}

const PicklistCommentListItem = ({ comment }: PicklistCommentListItemProps) => {
  return (
    <>
      <ListItem>
        <ListItemText
          style={{ maxWidth: "80%" }}
          primary={comment.user.name}
          secondary={comment.text}
        />
        <ListItemSecondaryAction>
          <Typography variant="caption" display="block">
            {moment(comment.createdAt).locale("nl").format("LL")}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider />
    </>
  );
};

interface PicklistCommentCreatorProps {
  apollo: Apollo;
  projectId: string;
  picklistDefinitionId: string;
  userId: string;
  onAdded: () => void | Promise<void>;
}

const PicklistCommentCreator = ({
  apollo,
  projectId,
  picklistDefinitionId,
  userId,
  onAdded,
}: PicklistCommentCreatorProps) => {
  const [comment, setComment] = useState("");
  const { loading, createPicklistComment } = useCreatePicklistComment(apollo);

  const create = async () => {
    const response = await createPicklistComment({
      userId,
      projectId,
      picklistDefinitionId,
      text: comment,
    });

    if (!response.errors) {
      setComment("");
      onAdded();
    }
  };

  return (
    <>
      <TextField
        fullWidth
        label="Opmerking"
        multiline
        rows={2}
        disabled={loading}
        value={comment}
        onChange={(event) => setComment(event.target.value)}
        variant="outlined"
      />
      <Grid
        style={{ marginTop: 8 }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          disabled={!comment.length || loading}
          size="small"
          variant="outlined"
          onClick={create}
        >
          {loading && <CircularProgress size="1rem" />} &nbsp; Toevoegen
        </Button>
      </Grid>
    </>
  );
};
