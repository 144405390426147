import { last } from "lodash";
import { UrlOpenService } from "../url-open.service";
import { HttpClient } from "@angular/common/http";
import { User } from "../accessibility-users/user.entity";
import { formatNumber } from "@angular/common";
import { chain, first, sum } from "lodash";
import * as moment from "moment";

export class InvoiceCreatorTemplate {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly urlOpenService: UrlOpenService
  ) {}

  async createPdf(params: InvoiceCreatorParams) {
    const logo = await this.fetchLogo(params);

    const total = sum(
      params.invoiceLines.map((e) => parseFloat(e.middel_bedrag))
    );
    const vatPercentage = parseFloat(params.invoice.vat);
    const vat = (vatPercentage / 100) * total;
    const totalIncl = total + vat;

    return {
      header: "",
      footer: (currentPage, pageCount) => {
        return {
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: `Pagina ${currentPage}/${[pageCount]}`,
                  alignment: "right",
                },
              ],
            ],
          },
          margin: [25, 0, 25, 0],
          height: "auto",
        };
      },
      content: [
        // Header
        {
          columns: [
            logo
              ? {
                  image: logo,
                  width: 160,
                }
              : {},

            [
              {
                text: "FACTUUR",
                style: "invoiceTitle",
                width: "*",
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: "Factuurnummer:",
                        style: "invoiceSubTitle",
                        width: "*",
                      },
                      {
                        text: params.invoice.factuurNummer,
                        style: "invoiceSubValue",
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: "Factuurdatum:",
                        style: "invoiceSubTitle",
                        width: "*",
                      },
                      {
                        text: moment(params.invoice.datum).format("DD-MM-YYYY"),
                        style: "invoiceSubValue",
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: "Debiteurennummer:",
                        style: "invoiceSubTitle",
                        width: "*",
                      },
                      {
                        text: params.financial.relatie_debiteurnummer,
                        style: "invoiceSubValue",
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        // Billing Headers
        {
          columns: [
            {
              text: "",
              style: "invoiceBillingTitle",
            },
            {
              text: "",
              style: "invoiceBillingTitle",
            },
          ],
        },
        // Billing Address Title
        {
          columns: [
            {
              text: "",
              style: "invoiceBillingAddressTitle",
            },
            {
              text: "",
              style: "invoiceBillingAddressTitle",
            },
          ],
        },
        // Billing Address
        {
          columns: [
            {
              text: `${params.user.name}
                            ${params.financial.relatie_straat} ${params.financial.relatie_huisnummer} 
                            ${params.financial.relatie_postcode} ${params.financial.relatie_plaats}

                            BTW-Nummer: ${params.financial.relatie_btwnummer}
                            Telefoon: ${params.user.telephone}`,
              style: "invoiceBillingAddress",
            },
            {
              text: "Aan:",
              style: "invoiceBillingAddress",
              width: 32,
            },
            {
              text: `${params.company.bedrijf_oms}
                            ${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer} 
                            ${params.company.bedrijf_postcode} ${params.company.bedrijf_plaats}

                            BTW-Nummer: ${params.company.bedrijf_btw}`,
              style: "invoiceBillingAddress",
            },
          ],
        },
        "\n",
        {
          columns: [
            {
              text: `Werkopdracht`,
              style: "invoiceBillingAddressTitle",
              width: "15%",
            },
            {
              text: `${params.invoice.opdracht} - ${params.invoice.opdracht_oms}`,
              style: "invoiceBillingAddress",
            },
          ],
        },
        // Line breaks
        "\n",
        // Items
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["auto", "auto", "*", "auto", "auto", "auto", "auto"],

            body: [
              // Table Header
              [
                {
                  text: "Datum",
                  style: "itemsHeader",
                },
                {
                  text: "Middel",
                  style: "itemsHeader",
                },
                {
                  text: "Omschrijving",
                  style: "itemsHeader",
                },
                {
                  text: "Aantal",
                  style: ["itemsHeader", "right"],
                },
                {
                  text: "Eenheid",
                  style: ["itemsHeader", "right"],
                },
                {
                  text: "Prijs",
                  style: ["itemsHeader", "right"],
                },
                {
                  text: "Bedrag",
                  style: ["itemsHeader", "right"],
                },
              ],
              // Items
              // Item 1
              ...this.createPdfLines(params),
              // END Items
            ],
          }, // table
          layout: "lightHorizontalLines",
        },
        // TOTAL
        "\n\n",
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ["*", 80],

            body: [
              // Total
              [
                {
                  text: "Totaal (excl. BTW):",
                  style: ["itemsFooterSubTitle", "right", "itemCell"],
                },
                {
                  text: formatNumber(total, "nl", "1.2-2"),
                  style: ["itemsFooterSubValue", "right", "itemCell"],
                },
              ],
              [
                {
                  text: `BTW ${
                    vatPercentage === 0 ? "verlegd" : `${vatPercentage}%`
                  }:`,
                  style: ["itemsFooterSubTitle", "right", "itemCell"],
                },
                {
                  text: formatNumber(vat, "nl", "1.2-2"),
                  style: ["itemsFooterSubValue", "right", "itemCell"],
                },
              ],
              [
                {
                  text: "Totaal:",
                  style: ["itemsFooterTotalTitle", "right", "itemCell"],
                },
                {
                  text: formatNumber(totalIncl, "nl", "1.2-2"),
                  style: ["itemsFooterTotalValue", "right", "itemCell"],
                },
              ],
            ],
          }, // table
          layout: "lightHorizontalLines",
        },
        {
          layout: "lightHorizontalLines",
          text: `\n
                    ${params.financial.relatie_voettekst}\n\n
                    BANK: ${params.financial.relatie_bank} - KvK: ${params.financial.relatie_kvk} - BTW: ${params.financial.relatie_btwnummer}
                    `,
          style: "notesText",
        },
      ],
      styles: {
        // Document Header
        documentHeaderLeft: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "left",
        },
        documentHeaderCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
        },
        documentHeaderRight: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
        },
        // Document Footer
        documentFooterLeft: {
          fontSize: 10,
          padding: [25, 25, 25, 25],
          alignment: "left",
        },
        documentFooterCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
        },
        documentFooterRight: {
          borderTop: "1px solid black",
          fontSize: 10,
          padding: [25, 25, 25, 25],
          alignment: "right",
        },
        // Invoice Title
        invoiceTitle: {
          fontSize: 22,
          bold: true,
          alignment: "right",
          margin: [0, 0, 0, 15],
        },
        // Invoice Details
        invoiceSubTitle: {
          fontSize: 11,
          alignment: "right",
        },
        invoiceSubValue: {
          fontSize: 11,
          alignment: "right",
        },
        // Billing Headers
        invoiceBillingTitle: {
          fontSize: 14,
          bold: true,
          alignment: "left",
          margin: [0, 20, 0, 5],
        },
        // Billing Details
        invoiceBillingDetails: {
          alignment: "left",
        },
        invoiceBillingAddressTitle: {
          // margin: [0, 7, 0, 3],
          bold: true,
        },
        invoiceBillingAddress: {},
        // Items Header
        itemsHeader: {
          margin: [0, 5, 0, 5],
          bold: true,
        },
        // Item Title
        itemTitle: {
          bold: true,
        },
        itemSubTitle: {
          italics: true,
          fontSize: 11,
        },
        itemNumber: {
          fontSize: 10,
          margin: [0, 5, 0, 5],
          alignment: "center",
        },
        itemTotal: {
          fontSize: 10,
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: "center",
        },

        // Items Footer (Subtotal, Total, Tax, etc)
        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: "right",
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: "center",
        },
        itemsFooterTotalTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: "right",
        },
        itemsFooterTotalValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: "center",
        },
        signaturePlaceholder: {
          margin: [0, 70, 0, 0],
        },
        signatureName: {
          bold: true,
          alignment: "center",
        },
        signatureJobTitle: {
          italics: true,
          fontSize: 10,
          alignment: "center",
        },
        notesTitle: {
          fontSize: 10,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 10,
        },
        center: {
          alignment: "center",
        },
        right: {
          alignment: "right",
        },
        left: {
          alignment: "left",
        },
        itemCell: {
          fontSize: 9,
          margin: [0, 0],
        },
        project: {
          bold: true,
          fontSize: 11,
          color: "darkGrey",
        },
      },
      defaultStyle: {
        columnGap: 20,
        fontSize: 10,
      },
      pageSize: "A4",
      pageMargins: [30, 30, 30, 30],
    };
  }

  createXml(params: InvoiceCreatorParams) {
    const total = sum(
      params.invoiceLines.map((e) => parseFloat(e.middel_bedrag))
    );
    const vatPercentage = parseFloat(params.invoice.vat);
    const vat = (vatPercentage / 100) * total;
    const totalIncl = total + vat;
    const grekAmount =
      !!params.financial.relatie_perc_grek &&
      !!params.financial.relatie_perc_grek.length
        ? total * (parseFloat(params.financial.relatie_perc_grek || "0") / 100)
        : 0;

    const buyerProps = {
      GLN: params.company.bedrijf_gln,
      Name: params.company.bedrijf_oms,
      StreetAndNumber: `${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer}`,
      City: params.company.bedrijf_plaats,
      PostalCode: params.company.bedrijf_postcode,
    };

    const isVatShifted = !params.financial.relatie_btw_perc;

    const composeLine = (line: InvoiceCreatorItemLine, index: number) => ({
      LineNumber: index + 1,
      DeliveredQuantity: line.middel_hv,
      NumberOfInvoicingUnits: line.middel_hv,
      NetLineAmount: line.middel_bedrag,
      TradeItemIdentification: {
        SuppliersTradeItemIdentification: line.middel,
        AdditionalItemIdentification: {
          TradeItemDescription: line.middel_oms,
        },
      },
      PriceInformation: {
        Price: line.middel_ehprijs,
        PriceBase: {
          NumberOfUnitsInPriceBasis: 1,
          MeasureUnitPriceBasis: line.middel_eenheid, // Meestal HUR (uur) of PCE (stuks)
        },
      },
      VATInformation: {
        VATRate: isVatShifted ? "AE" : "S", // S (standaard), E (vrijgesteld), AE (verlegd)
        VATpercentage: params.invoice.vat,
      },
    });

    return {
      _declaration: {
        _attributes: {
          version: "1.0",
          encoding: "utf-8",
          standalone: "yes",
        },
      },
      Invoice: {
        _attributes: {
          "xmlns:xalan": "http://xml.apache.org/xalan",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
          "xmlns:xs": "http://www.w3.org/2001/XMLSchema",
          "xsi:noNamespaceSchemaLocation": "Factuur_insbou003.xsd",
        },
        InvoiceType: "380", // 380 normaal, 384 correctie
        InvoiceNumber: params.invoice.factuurNummer,
        InvoiceDate: moment(params.invoice.datum).format("YYYY-MM-DD"),
        Currency: "EUR",
        ChainLiabilityIndicator: isVatShifted ? "J" : "N", // J als btw verlegd en anders N
        ...(isVatShifted
          ? { ReverseChargeIndicator: "AC" } // AC als btw verlegd en anders element weglaten
          : {}),
        // Attachment:
        // FreeText:
        // DespatchAdviceNumber:
        BuyersOrderNumber: params.invoice.opdracht,
        Buyer: buyerProps,
        Supplier: {
          GLN: params.financial.relatie_gln,
          Name: params.user.name,
          StreetAndNumber: `${params.financial.relatie_straat} ${params.financial.relatie_huisnummer}`,
          City: params.financial.relatie_plaats,
          PostalCode: params.financial.relatie_postcode,
          VATRegistrationNumber: params.financial.relatie_btwnummer,
          ChamberOfCommerceNumber: params.financial.relatie_kvk,
          AccountInformation: {
            IBAN: params.financial.relatie_bank,
          },
        },
        Invoicee: buyerProps,
        InvoiceLine: params.invoiceLines.map(composeLine),
        PaymentOnBlockedAccount: {
          Amount: grekAmount,
        },
        InvoiceTotals: {
          TotalInvoiceAmount: totalIncl,
          TotalNetLineAmount: total,
          TotalVATAmount: vat,
        },
        VATSubTotal: {
          VATAmount: vat,
          VATInformation: {
            VATRate: "S", // S (standaard), E (vrijgesteld)
            VATpercentage: params.invoice.vat,
            VATBaseAmount: total,
          },
        },
      },
    };

    return {
      _declaration: {
        _attributes: {
          version: "1.0",
          encoding: "utf-8",
        },
      },
      "pay:invoice": {
        _attributes: {
          "xmlns:eanucc": "urn:ean.ucc:2",
          "xmlns:pay": "urn:ean.ucc:pay:2",
          "xmlns:px": "urn:ean.ucc:vat:2",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
          creationDateTime: params.invoice.datum,
          documentStatus: "ORIGINAL",
          "xsi:schemaLocation": "urn:ean.ucc:pay:2 Factuur_bou002.xsd",
        },
        documentStructureVersion: {
          versionIdentification: "002",
        },
        invoiceIdentification: {
          uniqueCreatorIdentification: params.invoice.factuurNummer,
          contentOwner: {
            gln: params.financial.relatie_gln,
          },
        },
        invoiceCurrency: {
          currencyISOCode: "EUR",
        },
        invoiceType: "INVOICE",
        isSupplyChainResponsibilityLawNL: "false",
        isReverseChargeProcedureNL: !params.financial.relatie_btw_perc
          ? "true"
          : "false",

        /** Unknown */
        shipTo: {
          gln: "0000000000000",
          nameAndAddressNL: {
            city: "",
            countryCode: {
              countryISOCode: "NL",
            },
            languageOfTheParty: {
              languageISOCode: "NL",
            },
            name: "",
            postalCode: "",
            streetAddressOne: "",
          },
        },
        /** Groothuisbouw Company */
        payer: {
          partyIdentification: {
            gln: params.company.bedrijf_gln,
            nameAndAddressNL: {
              city: params.company.bedrijf_plaats,
              countryCode: {
                countryISOCode: "NL",
              },
              languageOfTheParty: {
                languageISOCode: "NL",
              },
              name: params.company.bedrijf_oms,
              postalCode: params.company.bedrijf_postcode,
              streetAddressOne: `${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer}`,
            },
          },
          nameAndAddress: {
            city: params.company.bedrijf_plaats,
            countryCode: {
              countryISOCode: "NL",
            },
            languageOfTheParty: {
              languageISOCode: "NL",
            },
            name: params.company.bedrijf_oms,
            postalCode: params.company.bedrijf_postcode,
            streetAddressOne: `${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer}`,
          },
        },
        buyer: {
          partyIdentification: {
            gln: params.company.bedrijf_gln,
            nameAndAddressNL: {
              city: params.company.bedrijf_plaats,
              countryCode: {
                countryISOCode: "NL",
              },
              languageOfTheParty: {
                languageISOCode: "NL",
              },
              name: params.company.bedrijf_oms,
              postalCode: params.company.bedrijf_postcode,
              streetAddressOne: `${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer}`,
            },
          },
          nameAndAddress: {
            city: params.company.bedrijf_plaats,
            countryCode: {
              countryISOCode: "NL",
            },
            languageOfTheParty: {
              languageISOCode: "NL",
            },
            name: params.company.bedrijf_oms,
            postalCode: params.company.bedrijf_postcode,
            streetAddressOne: `${params.company.bedrijf_straat} ${params.company.bedrijf_huisnummer}`,
          },
          companyRegistrationNumber: params.company.bedrijf_btw,
        },
        /** Relatie */
        seller: {
          partyIdentification: {
            gln: params.financial.relatie_gln,
            nameAndAddressNL: {
              city: params.financial.relatie_straat,
              countryCode: {
                countryISOCode: "NL",
              },
              languageOfTheParty: {
                languageISOCode: "NL",
              },
              name: params.user.name,
              postalCode: params.financial.relatie_postcode,
              streetAddressOne: `${params.financial.relatie_straat} ${params.financial.relatie_huisnummer}`,
            },
            contactNL: {
              communicationChannel: {
                _attributes: {
                  communicationChannelCode: "TELEPHONE",
                  communicationNumber: "relatie_telefoon",
                },
              },
              personOrDepartmentName: {
                description: {
                  language: {
                    languageISOCode: "NL",
                  },
                  text: "",
                },
              },
            },
          },
          nameAndAddress: {
            city: params.financial.relatie_plaats,
            countryCode: {
              countryISOCode: "NL",
            },
            languageOfTheParty: {
              languageISOCode: "NL",
            },
            name: params.user.name,
            postalCode: params.financial.relatie_postcode,
            streetAddressOne: `${params.financial.relatie_straat} ${params.financial.relatie_huisnummer}`,
          },
          companyRegistrationNumber: params.financial.relatie_btwnummer,
        },
        invoiceLineItem: this.createXmlLines(params),
        invoiceTotals: {
          totalInvoiceAmount: totalIncl,
          totalLineAmountInclusiveAllowancesCharges: total,
          totalTaxAmount: vat,
          basisAmountForDiscountPaymentAndCreditRestrictionNL: totalIncl,
          taxSubTotal: {
            taxableAmount: total,
            taxAmount: vat,
            vATInformationNL: {
              rate: params.invoice.vat,
              vATCategory: "STANDARD_RATE",
            },
          },
        },
        depositOnGAccountNL: {
          amount: {
            currencyCode: {
              currencyISOCode: "EUR",
            },
            monetaryAmount: grekAmount,
          },
          accountNumber: "",
        },
        paymentTerms: {
          _attributes: {
            paymentTermsEvent: "DATE_OF_INVOICE",
            paymentTermsType: "DISCOUNT",
          },
          paymentMethod: {
            paymentMethodType: "",
          },
        },
      },
    };
  }

  protected createPdfLines(params: InvoiceCreatorParams) {
    return chain(params.invoiceLines)
      .groupBy((e) => e.project)
      .map((d) => {
        const data = first(d);

        return [
          [
            {
              text: `Project: ${data.project} - ${data.project_oms}`,
              style: ["itemNumber", "left", "project"],
              colSpan: 7,
            },
          ],
        ].concat(
          d.map(
            (e) =>
              <any>[
                {
                  text: e.datum,
                  style: ["itemNumber", "left", "itemCell"],
                },
                {
                  text: e.middel,
                  style: ["itemNumber", "left", "itemCell"],
                },
                {
                  text: e.middel_oms,
                  style: ["itemNumber", "left", "itemCell"],
                },
                {
                  text: formatNumber(parseFloat(e.middel_hv), "nl", "1.2-2"),
                  style: ["itemNumber", "right", "itemCell"],
                },
                {
                  text: e.middel_eenheid,
                  style: ["itemNumber", "right", "itemCell"],
                },
                {
                  text: formatNumber(
                    parseFloat(e.middel_ehprijs),
                    "nl",
                    "1.2-2"
                  ),
                  style: ["itemNumber", "right", "itemCell"],
                },
                {
                  text: formatNumber(
                    parseFloat(e.middel_bedrag),
                    "nl",
                    "1.2-2"
                  ),
                  style: ["itemTotal", "right", "itemCell"],
                },
              ]
          )
        );
      })
      .flatten()
      .value();
  }

  protected createXmlLines(params: InvoiceCreatorParams) {
    return params.invoiceLines.map((line, index) => ({
      _attributes: {
        number: index + 1,
      },
      tradeItemIdentification: {
        gtin: "00000000000000",
        additionalTradeItemIdentification: {
          additionalTradeItemIdentificationValue: line.middel,
          additionalTradeItemIdentificationType: "SUPPLIER_ASSIGNED",
        },
      },
      invoicedQuantity: {
        value: line.middel_hv,
        unitOfMeasure: {
          measurementUnitCodeValue: line.middel_eenheid,
        },
      },
      invoicedQuantityNL: {
        value: line.middel_hv,
        unitOfMeasure: {
          measurementUnitCodeValue: line.middel_eenheid,
        },
      },
      transferOfOwnershipDate: moment().format("YYYY-MM-DD"),
      amountInclusiveAllowancesCharges: line.middel_bedrag,
      itemDescription: {
        language: {
          languageISOCode: "NL",
        },
        text: line.middel_oms,
      },
      itemPriceBaseQuantity: {
        value: 1,
        unitOfMeasure: {
          measurementUnitCodeValue: line.middel_eenheid,
        },
      },
      itemPriceInclusiveAllowancesCharges: line.middel_ehprijs,
      despatchAdvice: {
        documentLineReference: {
          _attributes: { number: 1 },
          documentReference: {
            uniqueCreatorIdentification: params.invoice.opdracht,
            contentOwner: {
              gln: params.company.bedrijf_gln,
            },
          },
        },
      },
      customerOrderNL: {
        uniqueCreatorIdentification: params.invoice.opdracht,
        contentOwner: {
          gln: params.financial.relatie_gln,
        },
      },
      invoiceLineTaxInformation: {
        vATInformationNL: {
          rate: params.invoice.vat,
          vATCategory: "STANDARD_RATE",
        },
      },
    }));
  }

  protected async fetchLogo(params: InvoiceCreatorParams) {
    await this.urlOpenService.refresh();
    const path = btoa(params.financial.relatie_logo);

    if (path && path !== "") {
      const url = `volume-public/file-legacy/0/${path}?token=${this.urlOpenService.token}`;

      const blob = await this.httpClient
        .get(url, { responseType: "blob" })
        .toPromise();

      return await this.getBlobData(blob);
    }
  }

  protected getBlobData(blob: Blob) {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string);
    });
  }
}

export class InvoiceCreatorItem {
  opdracht: string;
  opdracht_oms: string;

  bedrijf: string;

  project: string;
  project_oms: string;

  relatie: string;
  relatie_oms: string;

  datum: string;
  factuurNummer: string;
  vat: string;
  isStored: boolean;

  get id() {
    return this["TRA-500__1"] as string;
  }

  get nativeProjectId() {
    return this.project.charAt(0) === "S"
      ? this.project.substr(1)
      : this.project;
  }

  get sendOn() {
    return this["TRA-501__1"] as string;
  }

  get sendOnDate() {
    const sendOn = this.sendOn;
    return sendOn
      ? moment(last(sendOn.split(": ")), "DD-MM-YYYY").format("DD-MM-YY")
      : null;
  }

  get isCompleted() {
    return !!this.sendOn;
  }

  get canSave() {
    return (
      !this.isDuplicated(this.factuurNummer) &&
      !!this.factuurNummer &&
      !!this.vat
    );
  }

  get canSend() {
    return this.canSave && !this.isCompleted && this.isStored;
  }

  constructor(
    readonly data: any,
    readonly defaultVat: string,
    protected readonly isDuplicated: (id: string) => boolean
  ) {
    Object.assign(this, data);

    this.factuurNummer = this.id;
    this.vat = data["TRA-502__1"] || defaultVat;

    this.isStored = !!this.canSave;
  }

  setSend() {
    this["TRA-501__1"] = moment().format("DD-MM-YYYY");
  }
}

export class InvoiceCreatorItemLine {
  datum: string;

  middel: string;
  middel_oms: string;
  middel_hv: string;
  middel_eenheid: string;
  middel_ehprijs: string;
  middel_bedrag: string;

  project: string;
  project_oms: string;

  constructor(protected readonly data: any) {
    Object.assign(this, data);

    this.datum = moment(this.datum).format("DD-MM-YYYY");
  }
}

export class InvoiceCreatorFinancial {
  vat: string;
  relatie_straat: string;
  relatie_huisnummer: string;
  relatie_postcode: string;
  relatie_plaats: string;
  relatie_logo: string;
  relatie_btwnummer: string;
  relatie_btw_perc: string;
  relatie_debiteurnummer: string;
  relatie_voettekst: string;
  relatie_bank: string;
  relatie_kvk: string;
  relatie_gln: string;
  relatie_perc_grek: string;
  relatie_email: string;
  relatie_email_alg: string;

  constructor(protected readonly data: any) {
    Object.assign(this, data);

    this.vat = data.relatie_btw_perc || "0";
  }
}

export class InvoiceCreatorParams {
  constructor(
    readonly user: User,
    readonly invoice: InvoiceCreatorItem,
    readonly invoiceLines: InvoiceCreatorItemLine[],
    readonly financial: InvoiceCreatorFinancial,
    readonly company: InvoiceCompany
  ) {}
}

export class InvoiceCompany {
  bedrijf: string;
  bedrijf_oms: string;
  bedrijf_straat: string;
  bedrijf_huisnummer: string;
  bedrijf_postcode: string;
  bedrijf_plaats: string;
  bedrijf_gln: string;
  bedrijf_btw: string;
  bedrijf_email: string;
  bedrijf_email_adm: string;

  constructor(protected readonly data: any) {
    Object.assign(this, data);
  }
}
