import * as moment from "moment";

import {
  AssignmentModel,
  EventModel,
  SchedulerEventModel,
} from "@bryntum/schedulerpro/schedulerpro.umd.js";
import { Apollo } from "apollo-angular";
import { first, pick } from "lodash";
import { MetacomService } from "../metacom.service";
import { MechanicDto } from "./dtos/mechanic.dto";
import { ServiceAssignmentDto } from "./dtos/service-assignment.dto";
import {
  ServiceEventDto,
  ServiceEventDtoWithModel,
} from "./dtos/service-event.dto";
import {
  deleteServicePlanningAssignmentMutation,
  deleteServicePlanningEventMutation,
  storeServicePlanningAssignmentMutation,
  storeServicePlanningEventMutation,
} from "./service-planning.mutation";
import { servicePlanningFetchEventsQuery } from "./service-planning.query";
import { Seal } from "../seal";

const toRad = (value) => (value * Math.PI) / 180;

export const generateEventStyle = (
  event: ServiceEventDto,
  hightLightEventId?: string
) => {
  const colorCode = event.isFinalized ? "darkgrey" : event.stage.colorCode;
  const isHighlighted = event.id === hightLightEventId;

  return `
    background-color: ${colorCode};
    ${isHighlighted && `border: 2px solid red;`}
  `;
};

export const calculateDistance = (event: ServiceEventDto) => {
  const latSource = 52.687576,
    latTarget = parseFloat(event.legacyProjectData.longitude);

  const lonSource = 5.7403821,
    lonTarget = parseFloat(event.legacyProjectData.latitude);

  var R = 6371; // km
  var dLat = toRad(latTarget - latSource);
  var dLon = toRad(lonTarget - lonSource);
  var lat1 = toRad(latSource);
  var lat2 = toRad(latTarget);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  return isNaN(d) ? "0" : Math.round(d);
};

export const renderEventBody = (event: ServiceEventDto) => {
  const isConfigured = event.legacyProjectId && event.legacyProjectData;

  return isConfigured
    ? `
    <section>
      <div class='b-sch-event-header' style='font-weight: bold;'>${
        event.legacyProjectId || "?"
      }${
        event.memo && event.memo.length > 0
          ? `<span class="event-note material-icons">note</span></div>`
          : ``
      }
      <div class='b-sch-event-footer' style="font-size:12px;margin-top:4px;">${
        (event.legacyProjectData && event.legacyProjectData.description) || "?"
      }</div>
    </section>
  `
    : "Instellen";
};

export const renderEventTooltip = ({
  eventRecord,
}: {
  eventRecord: ServiceEventDto;
}) => {
  const tooltipLine = (name: string, value: string) => {
    return value
      ? `
      <tr>
        <td style='text-align: end;padding-right: 12px;'>
          <strong>${name}</strong>
        </td>
        <td><div style='max-width: 200px;'>${value}</div></td>
      </tr>`
      : "";
  };

  const isConfigured =
    eventRecord.legacyProjectId && eventRecord.legacyProjectData;

  return isConfigured
    ? `
    <div style='position: relative; margin:0; padding:0; display: block; font-size: 12px; line-height: 14px;'>
      <table>
        <tbody>
          ${tooltipLine("Aangemaakt door:", eventRecord.user.name)}
          ${tooltipLine("Stadium", eventRecord.stage.name)}
          ${tooltipLine("Uren gepland", `${eventRecord.durationPlanned} uur`)}
          ${tooltipLine(
            "Uren besteed",
            `${eventRecord.durationSpend || 0} uur`
          )}
          ${tooltipLine("Project", eventRecord.legacyProjectData.description)}
          ${tooltipLine("Meldingen", eventRecord.serviceTicketIds.join(", "))}
          ${tooltipLine("Memo", eventRecord.memo)}
        </tbody>
      </table>
    </div>
  `
    : "Instellen...";
};

export const defaultCalendar = () => {
  return [
    {
      recurrentStartDate: "on Sat at 0:00",
      recurrentEndDate: "on Mon at 0:00",
      isWorking: false,
    },
    {
      recurrentStartDate: "at 7:00",
      recurrentEndDate: "at 16:00",
      isWorking: true,
    },
  ];
};

export const absentCalendar = () => {
  return [
    {
      recurrentStartDate: "on Sat at 0:00",
      recurrentEndDate: "on Mon at 0:00",
      isWorking: false,
    },
  ];
};

export const searchServiceTickets = async (
  metacomService: MetacomService,
  legacyProjectId: string
) => {
  const nativeProjectId = legacyProjectId.substr(1);

  return await metacomService.queryTableAsync<any>({
    setName: "metacom",
    tableName: "service_meldingen",
    filter: `reg_doc.stadium < '1000' AND (prj_prj.prj = '${legacyProjectId}' OR prj_prj.prj = '${nativeProjectId}')`,
  });
};

export const calculateResourceHours = (
  events: SchedulerEventModel[],
  scheduler
) => {
  return Math.round(
    events
      .filter((task) => scheduler.isInTimeAxis(task))
      .reduce(
        (total, task) =>
          (total += moment
            .duration(task.duration, `${task.durationUnit}s` as any)
            .asHours()),
        0
      )
  );
};

export const isPhantomId = (id: string) => !id || id.startsWith("_");

export const isServicePlanningAssignmentValid = (
  assignment: AssignmentModel & ServiceAssignmentDto
) => {
  return (
    assignment.eventId &&
    assignment.resourceId &&
    !isPhantomId(assignment.eventId)
  );
};

export const storeServicePlanningAssignment = async (
  apollo: Apollo,
  assignment: AssignmentModel & ServiceAssignmentDto,
  eventId?: string
) => {
  const isNew = isPhantomId(assignment.id);
  const response = await apollo
    .mutate<{
      result: { id: string };
    }>({
      mutation: storeServicePlanningAssignmentMutation,
      variables: {
        input: {
          ...pick(assignment, [
            ...(isNew ? [] : ["id"]),
            "eventId",
            "resourceId",
          ]),
          ...(eventId ? { eventId } : {}),
        },
      },
    })
    .toPromise();

  if (isNew && response.data) {
    assignment.set("id", response.data.result.id);
  }

  return response;
};

export const deleteServicePlanningAssignment = async (
  apollo: Apollo,
  assignmentId: string
) => {
  const response = await apollo
    .mutate({
      mutation: deleteServicePlanningAssignmentMutation,
      variables: {
        id: assignmentId,
      },
    })
    .toPromise();

  return response;
};

export const isServicePlanningEventValid = (
  event: EventModel & ServiceEventDto
) => {
  return (
    event.startDate &&
    event.endDate &&
    event.duration &&
    event.durationUnit &&
    event.legacyProjectId
  );
};

export const storeServicePlanningEvent = async (
  apollo: Apollo,
  event: EventModel & ServiceEventDto
) => {
  const isNew = isPhantomId(event.id);
  const response = await apollo
    .mutate<{
      result: { id: string };
    }>({
      mutation: storeServicePlanningEventMutation,
      variables: {
        input: pick(event, [
          ...(isNew ? [] : ["id"]),
          "userId",
          "stageId",
          "legacyProjectId",
          "serviceTicketIds",
          "memo",
          "duration",
          "durationPlanned",
          "durationSpend",
          "durationUnit",
          "isFinalized",
          "startDate",
          "endDate",
        ]),
      },
    })
    .toPromise();

  const eventId = response.data && response.data.result.id;

  if (isNew) {
    event.set("id", eventId);
  }

  await Promise.all(
    (event.assignmentIdsToRemove || []).map((assignmentId) =>
      deleteServicePlanningAssignment(apollo, assignmentId)
    )
  );

  await Promise.all(
    event.assignments.map((assignment) =>
      storeServicePlanningAssignment(apollo, assignment as any, eventId)
    )
  );

  return response;
};

export const deleteServicePlanningEvent = async (
  apollo: Apollo,
  event: ServiceEventDtoWithModel
) => {
  if (!isPhantomId(event.id)) {
    const response = await apollo
      .mutate({
        mutation: deleteServicePlanningEventMutation,
        variables: {
          id: event.id,
        },
      })
      .toPromise();

    await Promise.all(
      event.assignments.map((assignment) =>
        deleteServicePlanningAssignment(apollo, assignment.id as string)
      )
    );

    return response;
  }
};

export const onResizeServiceEvent = (event: ServiceEventDtoWithModel) => {
  event.isFinalized && event.set("durationSpend", event.duration);
  !event.isFinalized && event.set("durationPlanned", event.duration);
};

export const generateDefaultCalendar = () => {
  return [
    {
      id: "rooster",
      name: `Standaard Rooster`,
      unspecifiedTimeIsWorking: false,
      intervals: defaultCalendar(),
    },
  ];
};

export const mapMechanicsToResources = (mechanics: MechanicDto[]) => {
  return mechanics.map((mechanic) => ({
    id: mechanic.id,
    name: mechanic.name,
    calendar: "rooster",
    companyId: mechanic.companyId,
    role: mechanic.role ? mechanic.role.name : "Geen rol",
  }));
};

export const useFetchServicePlanningEvents = (apollo: Apollo) => {
  return {
    fetchServicePlanningEvents: async (startDate: Date, endDate: Date) =>
      await apollo
        .query<{
          events: ServiceEventDto[];
        }>({
          query: servicePlanningFetchEventsQuery,
          variables: {
            dateRange: [startDate, endDate],
          },
        })
        .toPromise(),
  };
};

export const createCopyOfEvent = (sourceEvent: ServiceEventDtoWithModel) => {
  Seal.isNotSet(sourceEvent, "sourceEvent");

  const pickedProps = pick(sourceEvent, [
    "userId",
    "user",
    "stageId",
    "stage",
    "legacyProjectId",
    "legacyProjectData",
    "serviceTicketIds",
    "editMechanics",
    "memo",
    "duration",
    "durationPlanned",
    "durationSpend",
    "durationUnit",
    "isFinalized",
    "startDate",
    "endDate",
    "style",
  ]);
  const tasks = sourceEvent.eventStore.add(pickedProps);
  const candidate = first(tasks) as ServiceEventDtoWithModel;

  candidate.editMechanics =
    sourceEvent.editMechanics ||
    sourceEvent.assignments.map((assignment) => assignment.resourceId);

  for (var resourceId of candidate.editMechanics) {
    candidate.assign(resourceId);
  }

  return candidate;
};
