import { Injector } from "@angular/core";
import {
  Button,
  ChakraProvider,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { flatMap, uniqBy } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { FiSave } from "react-icons/fi";
import { PermissionGroup } from "../accessibility-role-dialog/accessibility-role-dialog.component";
import {
  InjectorContextProvider,
  useInjector,
} from "../react-component/InjectorContext";
import { useProvider } from "../react-component/react.hooks";
import { useApolloZeusQuery } from "../react-component/useApolloZeusQuery";
import { RestService } from "../rest.service";
import {
  AccessibilityPermissionExportDocument,
  AccessibilityPermissionExportDocumentRole,
  AccessibilityPermissionExportDocumentUser,
} from "./accessibilityPdfExport";

export enum AccessibilityPermissionExportDialogMode {
  User = "user",
  Role = "role",
  All = "all",
}

export type AccessibilityPermissionExportDialogProps = {
  mode: AccessibilityPermissionExportDialogMode;
  modeSelectionId?: string;
  onClose: () => void;
  injector: Injector;
};

export const AccessibilityPermissionExportDialog = ({
  injector,
  ...props
}: AccessibilityPermissionExportDialogProps) => {
  return (
    <InjectorContextProvider injector={injector}>
      <ChakraProvider>
        <AccessibilityPermissionExportDialogComponent {...props} />
      </ChakraProvider>
    </InjectorContextProvider>
  );
};

const AccessibilityPermissionExportDialogComponent = ({
  onClose,
  mode,
  modeSelectionId,
}: Omit<AccessibilityPermissionExportDialogProps, "injector">) => {
  const injector = useInjector();
  const restService = useProvider(injector, RestService);

  const [permissionGroups, setPermissionGroups] =
    useState<PermissionGroup[]>(null);

  const fetchPermissionGroups = async () => {
    const response = await restService.get<PermissionGroup[]>("permission");

    !response.hasError() && setPermissionGroups(response.value);
  };

  useEffect(() => {
    fetchPermissionGroups();
  }, []);

  const filters = useMemo(() => {
    switch (mode) {
      case "user":
        return [{ field: "id", operator: "Equal", value: modeSelectionId }];
      case "role":
        return [{ field: "roleId", operator: "Equal", value: modeSelectionId }];
      default:
      case "all":
        return [{ field: "roleId", operator: "IsNull", isNot: true }];
    }
  }, [mode, modeSelectionId]);

  const { data, loading, refetch } = useApolloZeusQuery(injector, {
    UserEntityFindMany: [
      {
        query: {
          filters: filters,
        },
      },
      {
        id: true,
        name: true,
        overwriteSignIn: true,
        lastSeenAt: true,
        stage: true,
        role: {
          id: true,
          name: true,
          grants: { id: true, permissionId: true },
          grantConfigs: { configId: true, value: true },
        },
        grantConfigs: { id: true, configId: true, value: true },
      },
    ],
  });

  const composeGrantsForPage = (
    pageId: string,
    grantConfigs: { configId?: string; value?: string }[],
    grantConfigsUser: { configId?: string; value?: string }[]
  ) => {
    const pageSearchId = `${pageId}:`;

    const grantsFlatten = grantConfigs
      .filter(
        (grant) => grant.configId.startsWith(pageSearchId) && !!grant.value
      )
      .reduce(
        (prev, cur) => ({
          ...prev,
          [cur.configId.replace(pageSearchId, "")]: cur.value ?? "-",
        }),
        {} as any
      );

    return grantConfigsUser
      .filter(
        (grant) => grant.configId.startsWith(pageSearchId) && !!grant.value
      )
      .reduce(
        (prev, cur) => ({
          ...prev,
          [cur.configId.replace(pageSearchId, "").replace("_", " ")]:
            cur.value ?? "-",
        }),
        grantsFlatten
      );
  };

  const composePages = (
    permissionGroups: PermissionGroup[],
    permissionIds: string[],
    grantConfigs: { configId?: string; value?: string }[],
    grantConfigsUser: { configId?: string; value?: string }[],
    onlyMutations = false
  ) => {
    const pages = flatMap(permissionGroups, (group) =>
      group.routes.map((route) => ({ ...route, groupName: group.name }))
    );

    const allowedPages = pages.filter((page) =>
      permissionIds.includes(page.id)
    );

    const pagesWithGrants = allowedPages
      .filter((page) =>
        onlyMutations
          ? !!grantConfigsUser.find(
              (grant) =>
                grant.configId.startsWith(`${page.id}:`) && !!grant.value
            )
          : true
      )
      .map((page) => ({
        path: page.url,
        name: `${page.groupName} - ${page.description}`,
        options: composeGrantsForPage(
          page.id,
          onlyMutations ? [] : grantConfigs,
          grantConfigsUser
        ),
      }));

    return pagesWithGrants;
  };

  const users = useMemo(
    () =>
      data?.UserEntityFindMany?.filter(
        (user) => !!user.role
      )?.map<AccessibilityPermissionExportDocumentUser>((user) => ({
        id: user.id,
        name: user.name,
        role: user.role.name,
        lastOnline: user.lastSeenAt,
        stage: user.stage,
        impersonation: user.overwriteSignIn,
        pages: composePages(
          permissionGroups,
          user.role.grants?.map((grant) => grant.permissionId),
          user.role.grantConfigs ?? [],
          user.grantConfigs ?? [],
          true
        ),
      })) ?? [],
    [data, permissionGroups]
  );

  const roles = useMemo(
    () =>
      uniqBy(
        data?.UserEntityFindMany?.filter((user) => !!user.role),
        (user) => user.role?.id
      ).map<AccessibilityPermissionExportDocumentRole>((user) => ({
        id: user.role.id,
        name: user.role.name,
        pages: composePages(
          permissionGroups,
          user.role.grants?.map((grant) => grant.permissionId),
          user.role.grantConfigs ?? [],
          []
        ),
      })) ?? [],
    [data, permissionGroups]
  );

  const isLoading = useMemo(
    () => !permissionGroups || loading,
    [permissionGroups, loading]
  );

  return (
    <Modal isOpen size="3xl" onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Permissies Exporteren</ModalHeader>
        <ModalCloseButton />
        <Skeleton isLoaded={!isLoading}>
          <ModalBody>
            {mode === "user" && !isLoading && (
              <PDFViewer width={"100%"} height="720px" showToolbar={false}>
                <AccessibilityPermissionExportDocument
                  roles={roles}
                  users={users}
                />
              </PDFViewer>
            )}
            {mode !== "user" && <Text>Geen preview voor deze modus.</Text>}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => onClose()}>
              Sluiten
            </Button>

            {!isLoading && (
              <PDFDownloadLink
                document={
                  <AccessibilityPermissionExportDocument
                    roles={roles}
                    users={users}
                  />
                }
                fileName={`Permissies.pdf`}
              >
                {({ loading }) => (
                  <Button isLoading={loading} leftIcon={<Icon as={FiSave} />}>
                    Downloaden
                  </Button>
                )}
              </PDFDownloadLink>
            )}
          </ModalFooter>
        </Skeleton>
      </ModalContent>
    </Modal>
  );
};
