import { Component, Inject, ChangeDetectorRef } from "@angular/core";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import {
  AppProjectCost,
  AppProjectCostValueType,
  ProjectCostInfo,
} from "../app-project-costs/app-project-cost.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { DialogService } from "../dialog.service";
import {
  EntitySearchDialogConfig,
  EntitySearchDialogComponent,
} from "../entity-search-dialog/entity-search-dialog.component";
import { AppProjectCostPreset } from "../app-project-cost-presets/app-project-cost-preset.entity";
import { MetacomService } from "../metacom.service";
import { EntityManager } from "../entity.service";
import { AuthService } from "../auth.service";
import { formatNumber } from "@angular/common";
import { truncate } from "lodash";

interface MtcInstallLibraryItem {
  groep: string;
  middel: string;
  omschrijving: string;
  bedrag: string;
}

interface MtcDefaultLibraryItem {
  titel: string;
  memo: string;
  bedrag: string;
}

@Component({
  selector: "app-app-project-cost-dialog",
  templateUrl: "./app-project-cost-dialog.component.html",
  styleUrls: ["./app-project-cost-dialog.component.scss"],
})
export class AppProjectCostDialogComponent extends AppCrudDialogBoilerplate<AppProjectCost> {
  calculatedValue: boolean;

  get canSave() {
    return !!this.data.model.title && this.isValueOk;
  }

  get isValueAllowed() {
    return this.data.model.isParent
      ? this.data.model.isConcept || this.data.model.isDeclined
      : !this.data.model.parentHasValue;
  }

  get isValueOk() {
    return (
      !this.isValueAllowed ||
      (this.data.model.parentHasValue ? this.data.model.isChild : true)
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppProjectCost>,
    protected readonly dialogs: DialogService,
    protected readonly dialog: MdcDialogRef<AppProjectCostDialogComponent>,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly metacom: MetacomService,
    protected readonly entities: EntityManager,
    protected readonly auth: AuthService
  ) {
    super(data, dialog);
  }

  async assignFromBank() {
    const response = await this.metacom.queryTableAsync<MtcDefaultLibraryItem>({
      setName: "metacom",
      tableName: "optielijst_meerwerk",
    });

    const items = response.value;

    const data = new EntitySearchDialogConfig("nvt");
    data.nameField = "bedrag";
    data.wrapDescription = true;

    data.descriptionFormat = (item) =>
      `${item.titel}${item.memo && ` - ${item.memo}`}`;
    data.nameFormat = (item) => `€${formatNumber(item.bedrag, "nl", "1.2-2")}`;

    data.filterFields = ["titel", "memo"];
    data.fixedResponse = items;

    const preset: MtcDefaultLibraryItem = await this.dialogs.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (preset) {
      this.data.model.title = truncate(preset.titel, { length: 150 });
      this.data.model.memo = preset.memo;

      if (this.isValueAllowed) {
        this.data.model.value = parseFloat(preset.bedrag);
      }
    }
  }

  async assignFromInstallBank() {
    const response = await this.metacom.queryTableAsync<MtcInstallLibraryItem>({
      setName: "metacom",
      tableName: "optielijst_installaties",
    });

    const items = response.value.filter((item) => item.groep === "no");

    const data = new EntitySearchDialogConfig("nvt");
    data.nameField = "bedrag";
    data.wrapDescription = true;
    data.nameFormat = (item) => `€${formatNumber(item.bedrag, "nl", "1.2-2")}`;

    data.descriptionField = "omschrijving";
    data.filterFields = ["middel", "omschrijving"];
    data.fixedResponse = items;

    const preset: MtcInstallLibraryItem = await this.dialogs.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (preset && !!preset.omschrijving) {
      this.data.model.title = truncate(
        `${preset.middel} - ${preset.omschrijving}`,
        {
          length: 150,
        }
      );

      if (this.isValueAllowed) {
        this.data.model.value = parseFloat(preset.bedrag);
      }
    }
  }

  async save(closeDialog = true) {
    const isConcept =
      this.data.model.isConcept === true && this.data.model.isPersistentConcept;

    const response = await super.save(closeDialog);

    if (!response.hasError() && isConcept) {
      await this.storeConceptInfo(response.value.id);
    }

    return response;
  }

  protected storeConceptInfo(id: any) {
    const repo = this.entities.get(ProjectCostInfo);
    const concept = repo.concept({
      conceptUserId: this.auth.user.id,
      appProjectCostId: id,
    });

    return repo.save(concept);
  }
}
