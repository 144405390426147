import {
  DeliveryListPointEntity,
  DeliveryListMeterEntityProps,
  DeliveryListSignatureEntityProps,
  ServiceCategoryEntity,
  DeliveryListProjectEntity,
  ServiceSpaceEntity,
} from "../../delivery-list.entity";

export class CompletionState {}

export class CompletionLoadInProgressState extends CompletionState {}

export class CompletionLoadSuccessState extends CompletionState {
  constructor(
    readonly props: {
      readonly project: DeliveryListProjectEntity;
      readonly categories: ServiceCategoryEntity[];
      readonly meters: DeliveryListMeterEntityProps[];
      readonly points: DeliveryListPointEntity[];
      readonly signatures: DeliveryListSignatureEntityProps[];
      readonly spaces: ServiceSpaceEntity[];
      readonly isGeneratingPdf?: boolean;
      readonly isSavingToSystem?: boolean;
    }
  ) {
    super();
  }
}
