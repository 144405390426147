import gql from "graphql-tag";
import { Project } from "../project/project.entity";

export interface YearPlanningQueryData {
  items?: Project[];
  count: number;
}

export const yearPlanningQuery = gql`
  query yearPlanningQuery(
    $filters: [EntityQueryFilter!]!
    $includeDates: Boolean!
  ) {
    items: ProjectEntityFindMany(
      query: {
        filters: $filters
        orders: [
          { field: "buildingWeek", direction: "ASC" }
          { field: "region", direction: "ASC" }
        ]
        relations: ["cachedValue"]
      }
    ) {
      id
      projectLeaderId
      projectMentorId
      salesEmployeeId
      description
      region
      regionId
      buildingWeek
      buildingStreet
      buildingHouseNumber
      buildingCity
      buildingZipCode
      deliveryDate
      houseType
      houseShape
      concrete
      score
      foundation
      frameType
      frameDescriptionWood
      frameDescriptionPlastic
      qualityAssurance
      prefabBathroom
      isolationCutting
      heating
      longitude
      latitude
      cachedValue {
        projectLeader
        projectMentor
        salesEmployee
        buyAdvisor
        dateValues @include(if: $includeDates)
        dateGroupResponsibles @include(if: $includeDates)
        dateGroupComments @include(if: $includeDates)
        logicFieldValues @include(if: $includeDates)
      }
    }
  }
`;
