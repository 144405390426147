import { Component, Inject } from "@angular/core";
import { AppNotificationSchedule } from "../app-notification-schedules/app-notification-schedule.entity";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { StringIterator } from "lodash";

@Component({
  selector: "app-app-notification-schedule-dialog",
  templateUrl: "./app-notification-schedule-dialog.component.html",
  styleUrls: ["./app-notification-schedule-dialog.component.scss"],
})
export class AppNotificationScheduleDialogComponent extends AppCrudDialogBoilerplate<
  AppNotificationSchedule
> {
  get canSave() {
    return true;
  }

  notificationFilter = {
    title: "Melding",
    allowNothing: true,
    icon: "pageview",
    entityName: "app.notification_templates",
    nameField: "id",
    descriptionField: "subject",
    sortField: "subject",
    sortDirection: "ASC",
    filterFields: ["subject"],
  };

  get phaseMarkFilter() {
    return this.markFilter("Datum fase");
  }

  get phaseMarkCancelFilter() {
    return this.markFilter("Conditie (gereed) fase");
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppNotificationSchedule>,
    protected readonly dialog: MdcDialogRef<
      AppNotificationScheduleDialogComponent
    >
  ) {
    super(data, dialog);
  }

  protected markFilter(title: string) {
    return {
      title,
      allowNothing: true,
      icon: "pageview",
      entityName: "app.phase_section_marks",
      nameField: "id",
      descriptionField: "name",
      sortField: "name",
      sortDirection: "ASC",
      filterFields: ["name"],
    };
  }
}
