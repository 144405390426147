import { Component } from "@angular/core";
import { ReactComponent } from "../react-component/react.component";
import { EntityManager } from "../entity.service";
import { WorkActionTemplateDeployDialog } from "./work-action-template-deploy-dialog";
import { RestService } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { AuthService } from "../auth.service";
import { WorkActionCreator } from "../work-actions/work-action.creator";
import { MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-work-action-template-deploy-dialog",
  templateUrl: "./work-action-template-deploy-dialog.component.html",
  styleUrls: ["./work-action-template-deploy-dialog.component.scss"],
})
export class WorkActionTemplateDeployDialogComponent extends ReactComponent {
  component = WorkActionTemplateDeployDialog;

  creator() {
    return new WorkActionCreator({
      userId: this.auth.user.id,
      transactions: this.transactions,
      entities: this.entities,
    });
  }

  params() {
    return Object.assign(
      {
        onClose: () => this.dialogRef.close(),
      },
      super.params()
    );
  }

  constructor(
    readonly auth: AuthService,
    readonly entities: EntityManager,
    readonly transactions: TransactionService,
    readonly restService: RestService,
    readonly dialogRef: MdcDialogRef<WorkActionTemplateDeployDialogComponent>
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
