import { Apollo } from "apollo-angular";
import { omit } from "lodash";
import { DocumentMeta } from "src/app/documents/document-meta.entity";
import { Bloc } from "src/app/service-settings/bloc/bloc";
import { PriorityEntity } from "src/app/service-settings/react/priorities/priority.entity";
import { TypeEntity } from "src/app/service-settings/react/types/type.entity";
import {
  DeliveryListPointEntity,
  DeliveryListPointImageEntity,
} from "../../delivery-list.entity";
import { uploadDocumentMutation } from "../signature/signature.mutation";
import {
  PointAddedFromPresetEvent,
  PointEvent,
  PointLoadRequestEvent,
  PointRemovedEvent,
  PointUpdatedEvent,
  PointUpdatedSuccessEvent,
} from "./point.event";
import {
  pointDeleteMutation,
  pointImageCreateMutation,
  pointImageDeleteMutation,
  pointUpdateMutation,
} from "./point.mutation";
import { pointsQuery } from "./point.query";
import {
  PointLoadInProgressState,
  PointLoadFailureState,
  PointLoadSuccessState,
  PointState,
} from "./point.state";

export class PointBloc extends Bloc<PointEvent, PointState> {
  constructor(readonly apollo: Apollo) {
    super(new PointLoadInProgressState());
  }

  async *mapEventToState(event: PointEvent) {
    if (event instanceof PointLoadRequestEvent) {
      yield await this._mapLoadRequestToState(event);
    }

    if (event instanceof PointUpdatedEvent) {
      yield await this._mapPointUpdatedToState(event);
    }

    if (event instanceof PointAddedFromPresetEvent) {
      yield await this._mapPointAddedToState(event);
    }

    if (event instanceof PointRemovedEvent) {
      yield await this._mapPointRemovedToState(event);
    }
  }

  protected async _mapLoadRequestToState(event: PointLoadRequestEvent) {
    const { data, errors } = await this.apollo
      .query<{
        points: DeliveryListPointEntity[];
        defaultType: TypeEntity;
        defaultPriority: PriorityEntity;
      }>({
        query: pointsQuery,
        variables: {
          projectId: event.props.projectId,
        },
      })
      .toPromise();

    if (errors && errors.length > 0) {
      return new PointLoadFailureState({ message: "oops" });
    }

    if (data) {
      return new PointLoadSuccessState({
        points: data.points.map((point) => this.pointWithRef(point)),
        defaultType: data.defaultType,
        defaultPriority: data.defaultPriority,
      });
    }
  }

  protected async _mapPointUpdatedToState(event: PointUpdatedEvent) {
    const { data, errors } = await this.apollo
      .mutate<{ point: DeliveryListPointEntity }>({
        mutation: pointUpdateMutation,
        variables: {
          input: omit(event.props.point, [
            "images",
            "serviceType",
            "__typename",
            "__isExpanded",
            "__refId",
          ]),
        },
      })
      .toPromise();

    if (errors && errors.length > 0) {
      return new PointLoadFailureState({ message: "oops" });
    }

    if (data) {
      const images = await Promise.all(
        (event.props.point.images || [])
          .filter((_image) => !_image.isPendingDelete)
          .map((_image) =>
            _image.id
              ? Promise.resolve(_image)
              : this._storeImage(data.point, _image)
          )
      );

      await Promise.all(
        (event.props.point.images || [])
          .filter((_image) => _image.isPendingDelete)
          .map((_image) => this._deleteImage(_image))
      );

      const newPoint = {
        ...data.point,
        __refId: event.props.point.__refId,
        images,
      };

      this.add(new PointUpdatedSuccessEvent({ point: newPoint }));

      const stateNow = this.stateOf<PointLoadSuccessState>();

      return stateNow.copyWith({
        points: [
          ...stateNow.props.points.map((_point) =>
            _point.__refId === newPoint.__refId ? newPoint : _point
          ),
        ],
      });
    }
  }

  protected async _mapPointRemovedToState(event: PointRemovedEvent) {
    if (event.props.point) {
      const { errors } = await this.apollo
        .mutate<{ isPointDeleted: boolean }>({
          mutation: pointDeleteMutation,
          variables: {
            input: {
              id: event.props.point.id,
            },
          },
        })
        .toPromise();

      if (errors && errors.length > 0) {
        return new PointLoadFailureState({ message: "oops" });
      }
    }

    const stateNow = this.stateOf<PointLoadSuccessState>();

    return stateNow.copyWith({
      points: stateNow.props.points.filter(
        (_point) => _point.__refId !== event.props.point.__refId
      ),
    });
  }

  protected async _mapPointAddedToState(event: PointAddedFromPresetEvent) {
    const stateNow = this.stateOf<PointLoadSuccessState>();

    return stateNow.copyWith({
      points: [
        ...stateNow.props.points,
        this.pointWithRef({
          projectId: event.props.projectId,
          code: event.props.preset.code,
          description: event.props.preset.description,
          primaryCategoryId: event.props.preset.primaryCategoryId,
          secondaryCategoryId: event.props.preset.secondaryCategoryId,
          serviceTypeId: stateNow.props.defaultType
            ? stateNow.props.defaultType.id
            : null,
          servicePriorityId: stateNow.props.defaultPriority
            ? stateNow.props.defaultPriority.id
            : null,
          __isExpanded: true,
        }),
      ],
    });
  }

  protected pointWithRef(point: DeliveryListPointEntity) {
    return { ...point, __refId: Math.random().toString() };
  }

  protected async _storeImage(
    point: DeliveryListPointEntity,
    image: DeliveryListPointImageEntity
  ) {
    const { data: uploadData } = await this.apollo
      .mutate<{ document: DocumentMeta }>({
        mutation: uploadDocumentMutation,
        variables: {
          file: image.__staging.file,
          projectId: point.projectId,
        },
        context: {
          useMultipart: true,
        },
      })
      .toPromise();

    const { data: entityData } = await this.apollo
      .mutate<{ image: DeliveryListPointImageEntity }>({
        mutation: pointImageCreateMutation,
        variables: {
          input: {
            pointId: point.id,
            documentMetaId: uploadData.document.id,
          },
        },
      })
      .toPromise();

    return entityData.image;
  }

  protected async _deleteImage(image: DeliveryListPointImageEntity) {
    const { errors } = await this.apollo
      .mutate<{ document: DocumentMeta }>({
        mutation: pointImageDeleteMutation,
        variables: {
          input: {
            id: image.id,
          },
        },
      })
      .toPromise();

    return errors;
  }
}
