import { RestService } from "./../rest.service";
import { routes } from "./../app-routes";
import { CellaService } from "../cella.service";
import { ShopRequest, ShopItemDto } from "./shop-item.dto";
import { formatNumber } from "@angular/common";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { first } from "lodash";
import { DocumentMeta } from "../documents/document-meta.entity";

export class ShopMailer {
  constructor(
    protected readonly restService: RestService,
    protected readonly cellaService: CellaService
  ) {}

  async send(request: ShopRequest, shopOrderId: string) {
    const attachments = [];

    if (request.data.image) {
      const files = await this.restService.postFiles<DocumentMeta[]>(
        `documents/upload/${request.data.projectId}`,
        [request.data.image]
      );

      attachments.push(
        files.map((doc) => ({
          fileName: "afbeelding.jpg",
          path: `${environment.baseUrl}/documents/open/${doc.id}`,
        }))
      );
    }

    return await this.cellaService.sendEmail({
      from: { address: "noreply@groothuisbouw.nl", name: "Groothuisbouw B.V." },
      to: Array.from(this.to(request)),
      attachments,
      subject: `Bestelling`,
      html: this.template(request, shopOrderId),
    });
  }

  protected template(request: ShopRequest, shopOrderId: string) {
    return `
            <table style="width:100%;" border="1">
                <thead>
                    <tr>
                        <th style="width:50%;text-align:left;">Gegevens</th>
                        <th style="width:50%;text-align:left;">Afleveradres</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="vertical-align:top;">
                            Bestelbonnummer: ${shopOrderId}<br>
                            Project: ${request.data.projectId} - ${
      request.data.projectDescription
    }<br>
                            Besteller: ${request.data.user.name}<br>
                            Leverdatum: ${moment(
                              request.data.deliveryDate
                            ).format("DD-MM-YYYY")}
                        </td>
                        <td style="vertical-align:top;">
                            ${request.data.address.data.address}<br>
                            ${request.data.address.data.zipCode}<br>
                            ${request.data.address.data.city}
                        </td>
                    </tr>
                </tbody>
            </table><br><br>${request.data.comment}<br><br>
            <table style="width:100%;" border="1">
                <thead>
                    <tr>
                        <th style="width:1px;vertical-align:top;text-align:left;">Middel</th>
                        <th style="vertical-align:top;text-align:left;">Omschrijving</th>
                        <th style="width:1px;vertical-align:top;text-align:right;">Aantal</th>
                        <th style="width:1px;vertical-align:top;text-align:left;">Eenheid</th>
                    </tr>
                </thead>
                <tbody>
                    ${request.data.items.map((e) => this.itemRow(e)).join("")}
                </tbody>
            </table>
        `;
  }

  protected itemRow(item: ShopItemDto) {
    return `
        <tr>
            <td style="white-space:nowrap;vertical-align:top;">${
              item.middel
            }</td>
            <td style="vertical-align:top;">${item.middel_naam}</td>
            <td style="white-space:nowrap;vertical-align:top;text-align:right;">${formatNumber(
              item.amountNr,
              "nl",
              "1.0-0"
            )}</td>
            <td style="white-space:nowrap;vertical-align:top;">${
              item.eenheid
            }</td>
        </tr>
        `;
  }

  protected *to(request: ShopRequest) {
    if (environment.production) {
      if (!!request.data.user.email) {
        yield request.data.user.email;
      }

      yield* first(request.data.items).emails;
    } else {
      yield "wriezebos@groothuisbouw.nl";
    }
  }
}
