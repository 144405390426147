import { Component, ChangeDetectorRef } from "@angular/core";
import { AppContent } from "./app-content.entity";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager, Ops } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppContentDialogComponent } from "../app-content-dialog/app-content-dialog.component";
import { UrlOpenService } from "../url-open.service";

@Component({
  selector: "app-app-contents",
  templateUrl: "./app-contents.component.html",
  styleUrls: ["./app-contents.component.scss"],
})
export class AppContentsComponent extends AppCrudBoilerplate<AppContent> {
  entity = AppContent;
  editDialog = AppContentDialogComponent;

  filterQuery: string;

  get filters() {
    return this.filterQuery ? [Ops.Field("title").Like(this.filterQuery)] : [];
  }

  onFilterQuery() {
    this.fetch();
  }

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly openUrls: UrlOpenService
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }

  async preview(event: Event, content: AppContent) {
    event.preventDefault();
    event.stopPropagation();

    this.openUrls.openNew({
      url: `#/app/content-preview/${content.id}`,
      forceNewTab: true,
    });
  }
}
