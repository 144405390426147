import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from "@angular/core";
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from "../react-component/react-attuned.component";
import {
  AccessibilityPermissionExportDialog,
  AccessibilityPermissionExportDialogMode,
  AccessibilityPermissionExportDialogProps,
} from "./AccessibilityPermissionExportDialog";
@Component({
  selector: "app-accessibility-permission-export-dialog",
  template: reactAttunedComponentTemplate,
  styles: [".attuned--component { overflow: hidden; }"],
})
export class AccessibilityPermissionExportDialogAngular extends ReactAttunedComponent<AccessibilityPermissionExportDialogProps> {
  component = AccessibilityPermissionExportDialog;

  @Input() mode = "";
  @Input() modeSelectionId = "";

  @Output() onCloseEvent = new EventEmitter<any>();

  constructor(protected readonly injector: Injector) {
    super(injector);
  }

  props() {
    return {
      mode: this.mode as AccessibilityPermissionExportDialogMode,
      modeSelectionId: this.modeSelectionId,
      onClose: () => this.onCloseEvent.emit({}),
      injector: this.injector,
    };
  }
}
