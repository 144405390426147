import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { GrantService } from "./grant.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    protected readonly router: Router,
    protected readonly auth: AuthService,
    protected readonly grant: GrantService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.auth.refreshToken(true);

    const returnUrl = state.url !== "/" ? state.url : null;
    const hasAccess = this.grant.isRouteAccessable(state.url);

    if (!hasAccess) {
      return await this.router.navigate(["/login"], {
        queryParams: { returnUrl: returnUrl },
      });
    } else if (!this.auth.user) {
      await this.auth.fetchUserDetails();
    }

    return true;
  }
}
