import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-changelog-dialog",
  templateUrl: "./changelog-dialog.component.html",
  styleUrls: ["./changelog-dialog.component.scss"]
})
export class ChangelogDialogComponent {
  get version() {
    return environment.version;
  }
}
