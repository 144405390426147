import { AfterViewInit, Injector, OnDestroy } from "@angular/core";
import * as React from "react";
import * as ReactDOM from "react-dom";

export const reactAttunedComponentTemplate = `<div class='attuned--component' [id]='key'></div>`;

export abstract class ReactAttunedComponent<TComponentProps>
  implements AfterViewInit, OnDestroy
{
  get key() {
    return `${this.component.name}--${this._hash}`;
  }

  abstract component: (props: TComponentProps) => JSX.Element;

  protected constructor(protected readonly injector: Injector) {}

  protected readonly _hash = Math.random().toString();

  abstract props(): TComponentProps;

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy(): void {
    const container = document.getElementById(this.key);

    if (container) {
      ReactDOM.unmountComponentAtNode(container);
    }
  }

  protected render() {
    const container = document.getElementById(this.key);

    if (container) {
      ReactDOM.render(
        React.createElement(this.component, this.props()),
        container
      );
    }
  }
}
