import { EntityManager } from "src/app/entity.service";
import { CrudBloc } from "../../bloc/crud/crud.bloc";
import { CrudEvent } from "../../bloc/crud/crud.event";
import { CrudInitialState } from "../../bloc/crud/crud.state";
import { PriorityEntity } from "./priority.entity";

export class PriorityBloc extends CrudBloc<PriorityEntity> {
  constructor(protected readonly entities: EntityManager) {
    super(new CrudInitialState());
  }

  get repo() {
    return this.entities.get(PriorityEntity);
  }

  async *mapEventToState(event: CrudEvent) {
    yield* super.mapEventToState(event);
  }
}
