import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { InvoiceAttachment } from "./invoice-attachment.entity";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { FileUploadService, FileUploadData } from "../file-upload.service";
import { InvoiceCreatorItem } from "../invoice-creator/invoice-creator.template";
import { UrlOpenService } from "../url-open.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-invoice-creator-attachment-dialog",
  templateUrl: "./invoice-creator-attachment-dialog.component.html",
  styleUrls: ["./invoice-creator-attachment-dialog.component.scss"],
})
export class InvoiceCreatorAttachmentDialogComponent implements OnInit {
  response: RestResponse<InvoiceAttachment[]>;

  protected get invoiceAttachmentService() {
    return this.entityManager.get(InvoiceAttachment);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: InvoiceCreatorAttachmentDialogComponentData,
    protected readonly transactionService: TransactionService,
    protected readonly fileUploadService: FileUploadService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly entityManager: EntityManager
  ) {}

  ngOnInit() {
    this.fetchAttachments();
  }

  async ops(type: string, item?: InvoiceAttachment) {
    switch (type) {
      case "upload":
        return await this.transactionService.perform(() => this.upload());
      case "remove":
        return await this.transactionService.perform(() => this.remove(item));
      case "open":
        this.urlOpenService.open(
          `${environment.baseUrl}/documents/open/${item.documentMetaId}`,
          true
        );
        break;
    }
  }

  protected async upload() {
    const documents = await this.promptForDocuments();
    const documentIds = await this.fileUploadService.upload(
      new FileUploadData(documents),
      this.data.item.project
    );

    const documentsToStore = documentIds.map((e) =>
      this.invoiceAttachmentService.concept({
        metacomOrderId: this.data.item.opdracht,
        documentMetaId: e.id,
        __documentMeta__: e,
      })
    );

    const entities = await Promise.all(
      documentsToStore.map((e) => this.invoiceAttachmentService.save(e))
    );

    this.response.value.push(...entities.map((e) => e.value));

    return new RestResponse({});
  }

  protected async promptForDocuments() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.setAttribute("accept", "application/pdf");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  protected async remove(item: InvoiceAttachment) {
    const response = await this.invoiceAttachmentService.delete(item.id);

    if (!response.hasError()) {
      const index = this.response.value.indexOf(item);
      this.response.value.splice(index, 1);
    }

    return new RestResponse({});
  }

  protected async fetchAttachments() {
    this.response = await this.invoiceAttachmentService.query({
      filters: [
        {
          field: "metacomOrderId",
          operator: "Equal",
          value: this.data.item.opdracht,
        },
      ],
      relations: ["documentMeta"],
    });
  }
}

export class InvoiceCreatorAttachmentDialogComponentData {
  constructor(readonly item: InvoiceCreatorItem) {}
}
