import { Apollo } from "apollo-angular";
import { flatten } from "lodash";
import * as moment from "moment";
import { AuthService } from "src/app/auth.service";
import { RestService } from "src/app/rest.service";
import { Bloc } from "src/app/service-settings/bloc/bloc";
import {
  ServiceTransmitter,
  ServiceTransmitterDepartmentMail,
  ServiceTransmitterTicket,
  ServiceTransmitterTicketKind,
} from "src/app/service/service.transmitter";
import { environment } from "src/environments/environment";
import {
  DeliveryListMeterEntityProps,
  DeliveryListPointEntity,
  DeliveryListProjectEntity,
  DeliveryListSignatureEntityProps,
  ServiceCategoryEntity,
  ServiceSpaceEntity,
} from "../../delivery-list.entity";
import { DeliveryListBloc } from "../core/delivery-list.bloc";
import { DeliveryListLoadRequestEvent } from "../core/delivery-list.event";
import { DeliveryListLoadSuccessState } from "../core/delivery-list.state";
import { AttunedFile, FileUploadService } from "./../../../file-upload.service";
import { completionPdfBlueprint } from "./completion-pdf.blueprint";
import {
  CompletionEvent,
  CompletionGeneratePdfRequestEvent,
  CompletionLoadRequestEvent,
  CompletionMakeFinalRequestEvent,
} from "./completion.event";
import { deliveryListCompletionMutation } from "./completion.mutation";
import { completionQuery } from "./completion.query";
import {
  CompletionLoadInProgressState,
  CompletionLoadSuccessState,
  CompletionState,
} from "./completion.state";

export class CompletionBloc extends Bloc<CompletionEvent, CompletionState> {
  constructor(
    readonly coreBloc: DeliveryListBloc,
    readonly apollo: Apollo,
    readonly auth: AuthService,
    readonly rest: RestService,
    readonly fileUploadService: FileUploadService,
    readonly serviceTransmitter: ServiceTransmitter
  ) {
    super(new CompletionLoadInProgressState());
  }

  async *mapEventToState(event: CompletionEvent) {
    if (event instanceof CompletionLoadRequestEvent) {
      yield* await this._mapLoadRequestEventToState(event);
    }

    if (event instanceof CompletionGeneratePdfRequestEvent) {
      yield* await this._mapGeneratePdfEventToState(event);
    }

    if (event instanceof CompletionMakeFinalRequestEvent) {
      yield* await this._mapMakeFinalRequestEventToState(event);
    }
  }

  protected async *_mapLoadRequestEventToState(
    event: CompletionLoadRequestEvent
  ) {
    const { data, errors } = await this.apollo
      .query<{
        project: DeliveryListProjectEntity;
        categories: ServiceCategoryEntity[];
        meters: DeliveryListMeterEntityProps[];
        points: DeliveryListPointEntity[];
        signatures: DeliveryListSignatureEntityProps[];
        spaces: ServiceSpaceEntity[];
      }>({
        query: completionQuery,
        variables: {
          projectId: event.props.projectId,
        },
      })
      .toPromise();

    if (errors && errors.length > 0) {
      //   return new PointLoadFailureState({ message: "oops" });
    }

    if (data) {
      yield new CompletionLoadSuccessState({
        ...data,
        points: data.points.map((point) =>
          this._withVirtualSpaceNames(data.spaces, point)
        ),
      });
    }
  }

  protected async *_mapGeneratePdfEventToState(
    event: CompletionGeneratePdfRequestEvent
  ) {
    const stateNow = this.stateOf<CompletionLoadSuccessState>();

    yield new CompletionLoadSuccessState({
      ...stateNow.props,
      isGeneratingPdf: true,
    });

    const buffer = await this.rest.postNative(
      `tools/create-pdf`,
      await completionPdfBlueprint(
        this.rest,
        stateNow,
        this.coreBloc.stateOf<DeliveryListLoadSuccessState>()
      )
    );

    const file = this.rest.arrayBufferToFile(
      buffer,
      "application/pdf",
      "document.pdf"
    );

    const uploadResponse = await this.fileUploadService.attunedUpload(
      new AttunedFile(file, stateNow.props.project?.id, "export:delivery-list")
    );

    window.open(
      `${environment.baseUrl}/documents/open/${uploadResponse.response.id}`
    );

    yield new CompletionLoadSuccessState({
      ...stateNow.props,
      isGeneratingPdf: false,
    });
  }

  protected async *_mapMakeFinalRequestEventToState(
    event: CompletionMakeFinalRequestEvent
  ) {
    const stateNow = this.stateOf<CompletionLoadSuccessState>();
    const stateCoreNow = this.coreBloc.stateOf<DeliveryListLoadSuccessState>();

    yield new CompletionLoadSuccessState({
      ...stateNow.props,
      isSavingToSystem: true,
    });

    const pointsNotSaved = stateNow.props.points
      .filter((_point) => _point.serviceType.storeInSystem === true)
      .filter((_point) => !_point.serviceTicketId);

    for (const point of pointsNotSaved) {
      const responseSystem = await this.serviceTransmitter.createTicket(
        new ServiceTransmitterTicket({
          libraryCode: point.code,
          projectId: event.props.projectId,
          projectDescription: point.project.description,
          date: moment().toDate(),
          dateEnd: moment()
            .add(point.servicePriority.offsetDays, "days")
            .toDate(),
          executiveUserId: point.assignedUserId,
          executiveUserName: point.assignedUser.name,
          description: point.description,
          space: point.serviceSpace.description,
          primaryCategory: stateNow.props.categories.find(
            (_cat) => _cat.id === point.primaryCategoryId
          ).name,
          secondaryCategory: stateNow.props.categories.find(
            (_cat) => _cat.id === point.secondaryCategoryId
          ).name,
        }),
        {
          notify: false,
          kind: ServiceTransmitterTicketKind.Delivery,
        }
      );

      point.serviceTicketId = responseSystem.props.id;
    }

    const _points = pointsNotSaved.map((_point) => ({
      id: _point.id,
      serviceTicketId: _point.serviceTicketId,
    }));

    const _images = flatten(
      pointsNotSaved.map((_point) =>
        _point.images.map((_image) => ({
          entityType: "service",
          entityId: this.serviceTransmitter.mapTicketIdToOrigin(
            _point.serviceTicketId
          ),
          userId: this.auth.user.id,
          documentMetaId: _image.documentMetaId,
        }))
      )
    );

    const response = await this.apollo
      .mutate({
        mutation: deliveryListCompletionMutation,
        variables: {
          customFields: [
            this._composeProjectField(
              event.props.projectId,
              stateNow.props.project.regionId,
              "PRJ-215",
              stateCoreNow.props.deliveryList.assignedUserId
            ),
            this._composeProjectField(
              event.props.projectId,
              stateNow.props.project.regionId,
              "PRJ-006",
              moment(
                stateCoreNow.props.deliveryList.isDeliveredAt || new Date()
              ).format("DD-MM-YYYY")
            ),
          ],
          points: _points,
          images: _images,
          deliveryList: {
            id: stateCoreNow.props.deliveryList.id,
            isFinal: true,
            isFinalAt: new Date(),
          },
        },
      })
      .toPromise();

    await this.serviceTransmitter.mailDepartment(
      new ServiceTransmitterDepartmentMail({
        subject: `Opleverlijst`,
        html: `
        Zojuist is de volgende opleverlijst definitief gemeld:<br /><br />
        Project: ${event.props.projectId} - ${stateCoreNow.props.deliveryList.project.description}<br />
        Door: ${this.auth.user.id} - ${this.auth.user.name}<br />`,
      })
    );

    this.coreBloc.add(
      new DeliveryListLoadRequestEvent({ projectId: event.props.projectId })
    );

    yield new CompletionLoadSuccessState({
      ...stateNow.props,
      isSavingToSystem: false,
    });
  }

  protected _composeProjectField(
    projectId: string,
    regionId: string,
    key: string,
    value: string
  ) {
    return {
      Entity: "0401",
      Origin: `bdr:920¡adm:${regionId}¡prj:${projectId}`,
      LineId: 1,
      Code: key,
      SerialNumber: 1,
      Contents: value,
    };
  }

  protected _withVirtualSpaceNames(
    spaces: ServiceSpaceEntity[],
    point: DeliveryListPointEntity
  ) {
    if (!point.serviceSpace) {
      const description = point.serviceSpaceIds
        .map((spaceId) => {
          const space = spaces.find((space) => space.id === spaceId);
          return (space && space.description) || "Onbekend";
        })
        .join(", ");

      point.serviceSpace = {
        id: undefined,
        code: undefined,
        description,
      } as any;
    }

    return point;
  }
}
