import * as React from "react";
import * as moment from "moment";
import { MechanicDto } from "../dtos/mechanic.dto";
import {
  LocaleManager,
  Mask,
  SchedulerPro,
  Toast,
} from "@bryntum/schedulerpro/schedulerpro.umd.js";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react";

import { useRef, useState } from "react";
import { HttpClient } from "@angular/common/http";
import {
  renderEventBody,
  storeServicePlanningEvent,
  storeServicePlanningAssignment,
  useFetchServicePlanningEvents,
  deleteServicePlanningEvent,
  onResizeServiceEvent,
  mapMechanicsToResources,
  generateEventStyle,
  renderEventTooltip,
  generateDefaultCalendar,
} from "../service-planning.functions";
import { MetacomService } from "src/app/metacom.service";
import { Apollo } from "apollo-angular";
import { AuthService } from "src/app/auth.service";
import { RestService } from "src/app/rest.service";
import { useEffect } from "react";
import { flatMap } from "lodash";

import { useMemo } from "react";
import { SchedulerResourceComponent } from "./SchedulerResourceComponent";
import { ServiceEventDtoWithModel } from "../dtos/service-event.dto";
import { SchedulerTaskEditComponent } from "./SchedulerTaskEditComponent";
import { Injector } from "@angular/core";

import Nl from "@bryntum/schedulerpro/locales/schedulerpro.locale.Nl.js";
import { GrantService } from "src/app/grant.service";
import { grants } from "src/app/app-grant-config";
import { SchedulerSearchDialogComponent } from "./SchedulerSearchDialogComponent";
import { SchedulerConfirmDialogComponent } from "./SchedulerConfirmDialogComponent";
import {
  SchedulerTaskMoveDialogComponent,
  SchedulerTaskMoveDialogComponentMode,
  SchedulerTaskMoveOperation,
} from "./SchedulerTaskMoveDialogComponent";

LocaleManager.locale = Nl;

export interface SchedulerComponentDefaults {
  legacyProjectId: string;
  serviceTicketId: string;

  highlight?: {
    eventId: string;
    baseDate: Date;
  };

  isDialogMode?: boolean;
}

export interface SchedulerComponentProps {
  injector: Injector;
  http: HttpClient;
  apollo: Apollo;
  authService: AuthService;
  restService: RestService;
  metacomService: MetacomService;
  mechanics: MechanicDto[];
  defaults?: SchedulerComponentDefaults;
}

interface IntermediateContext {
  finalize: (result: boolean) => void;
}

export function SchedulerComponent({
  injector,
  http,
  authService,
  apollo,
  metacomService,
  restService,
  mechanics,
  defaults,
}: SchedulerComponentProps) {
  const schedulerRef = useRef();

  const grantService = useMemo(() => injector.get(GrantService), []);
  const readOnly = useMemo(
    () => !grantService.varIs(grants.service_planning.allow_modify, "true"),
    [grantService]
  );

  const startDate = moment(
    defaults && defaults.highlight && defaults.highlight.baseDate
  )
    .startOf("isoWeek")
    .startOf("day")
    .toDate();

  const endDate = moment(
    defaults && defaults.highlight && defaults.highlight.baseDate
  )
    .endOf("isoWeek")
    .endOf("day")
    .toDate();

  const [searchOpen, setSearchOpen] = useState(false);
  const [taskEdit, setTaskEdit] = useState<ServiceEventDtoWithModel>();
  const [taskMove, setTaskMove] = useState<SchedulerTaskMoveOperation>();

  const [confirmContext, setConfirmContext] = useState<IntermediateContext>();

  const project = useMemo(() => makeProject(mechanics), [mechanics]);

  const createContextMenu = () => ({
    eventMenuFeature: {
      disabled: readOnly,
      items: {
        unassignEvent: false,
        editEvent: {
          text: "Bewerken",
          icon: "b-fa b-fa-fw b-fa-edit",
          onItem: ({ eventRecord }) => setTaskEdit(eventRecord),
        },
        copyEvent: {
          text: "Kopieren",
          icon: "b-fa b-fa-fw b-fa-copy",
          onItem: ({ eventRecord }) =>
            setTaskMove({
              sourceEvent: eventRecord,
              mode: SchedulerTaskMoveDialogComponentMode.Copy,
            }),
        },
        moveEvent: {
          text: "Verplaatsen",
          icon: "b-fa b-fa-fw b-fa-share",
          onItem: ({ eventRecord }) =>
            setTaskMove({
              sourceEvent: eventRecord,
              mode: SchedulerTaskMoveDialogComponentMode.Move,
            }),
        },
        deleteEvent: {
          text: "Verwijderen",
          onItem: async ({ eventRecord }) => await removeEvent(eventRecord),
        },
      },
    },
  });

  const schedulerProps = useMemo(
    () => ({
      ...makeBryntumProps(readOnly, apollo, setTaskEdit, setConfirmContext),
      ...createContextMenu(),
    }),
    [readOnly, apollo]
  );

  const { fetchServicePlanningEvents } = useFetchServicePlanningEvents(apollo);

  const storeEvent = async (
    event: ServiceEventDtoWithModel,
    silent?: boolean
  ) => {
    const response = await storeServicePlanningEvent(apollo, event);

    if (!response.errors) {
      event.set("name", Math.random().toString());

      !silent && Toast.show("Opgeslagen");
    }

    return response;
  };

  const removeEvent = async (
    event: ServiceEventDtoWithModel,
    silent?: boolean
  ) => {
    const scheduler = (schedulerRef.current as any).instance as SchedulerPro;
    const response = await deleteServicePlanningEvent(apollo, event);

    if (!response.errors) {
      scheduler.project.assignmentStore.remove(event.assignments);
      scheduler.project.eventStore.remove(event);

      !silent && Toast.show("Verwijderd");
    }

    return response;
  };

  const selectSearchedEvent = async (startDate: Date, eventId?: string) => {
    setSearchOpen(false);

    const startsAt = moment(startDate)
      .startOf("isoWeek")
      .startOf("day")
      .toDate();

    const endsAt = moment(startDate).endOf("isoWeek").endOf("day").toDate();

    return await fetchEvents(startsAt, endsAt, eventId);
  };

  const fetchEvents = async (
    newStartDate: Date,
    newEndDate: Date,
    eventId?: string
  ) => {
    /** TODO: remove nasty code and move to react e.g. */
    document.querySelector(".app-week-number").textContent = `Week ${moment(
      newStartDate
    ).format("WW")}`;

    const response = await fetchServicePlanningEvents(newStartDate, newEndDate);
    const scheduler = (schedulerRef.current as any).instance as SchedulerPro;
    scheduler.setTimeSpan(newStartDate, newEndDate);

    if (response.data.events.length) {
      scheduler.project.eventStore.add(
        response.data.events.map((event) => ({
          ...event,
          style: generateEventStyle(
            event,
            eventId ||
              (defaults && defaults.highlight && defaults.highlight.eventId)
          ),
        }))
      );
    }
    if (response.data.events.length) {
      scheduler.project.assignmentStore.add(
        flatMap(response.data.events, (event) => event.assignments)
      );
    }
  };

  const modifyDateRange = async (delta: number) => {
    const scheduler = (schedulerRef.current as any).instance as SchedulerPro;

    const newStartDate = moment(delta === 0 ? startDate : scheduler.startDate)
      .add(delta, "weeks")
      .toDate();

    const newEndDate = moment(delta === 0 ? endDate : scheduler.endDate)
      .add(delta, "weeks")
      .toDate();

    await fetchEvents(newStartDate, newEndDate);
  };

  useEffect(() => {
    fetchEvents(startDate, endDate);
  }, [project]);

  return (
    <>
      {taskEdit && (
        <SchedulerTaskEditComponent
          injector={injector}
          defaults={defaults}
          authService={authService}
          metacomService={metacomService}
          restService={restService}
          task={taskEdit}
          mechanics={mechanics}
          onSaved={(task) => storeEvent(task) && setTaskEdit(null)}
          onDeleted={(task, silent) =>
            removeEvent(task, silent) && setTaskEdit(undefined)
          }
          onCancelled={() => setTaskEdit(null)}
        />
      )}

      {confirmContext && (
        <SchedulerConfirmDialogComponent
          onResult={(result) => {
            confirmContext.finalize(result);
            setConfirmContext(undefined);
          }}
        />
      )}

      {taskMove && (
        <SchedulerTaskMoveDialogComponent
          operation={taskMove}
          onCancel={() => setTaskMove(undefined)}
          onConfirm={(event) => storeEvent(event) && setTaskMove(undefined)}
        />
      )}

      {searchOpen && (
        <SchedulerSearchDialogComponent
          apollo={apollo}
          rest={restService}
          onSelected={(date, eventId) => selectSearchedEvent(date, eventId)}
          onClose={() => setSearchOpen(false)}
        />
      )}

      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <BryntumSchedulerPro
          ref={schedulerRef}
          {...schedulerProps}
          startDate={startDate}
          endDate={endDate}
          eventBodyTemplate={renderEventBody}
          tbar={[
            {
              type: "widget",
              cls: "app-week-number",
              html: "Week #",
            },
            {
              type: "widget",
              ref: "dateLabel",
              flex: 1,
            },
            {
              icon: "b-icon b-fa-search",
              cls: "b-transparent",
              onAction: () => setSearchOpen(true),
            },
            {
              icon: "b-icon b-fa-caret-left",
              onAction: () => modifyDateRange(-1),
            },
            {
              icon: "b-icon b-fa-calendar-day",
              onAction: () => modifyDateRange(0),
            },
            {
              icon: "b-icon b-fa-caret-right",
              onAction: () => modifyDateRange(+1),
            },
          ]}
          columns={[
            {
              type: "resourceInfo",
              field: "totalHours",
              text: "Monteur",
              width: 164,
              editor: false,
              sortable: false,
              renderer: ({ record, grid: scheduler }) => {
                return (
                  <SchedulerResourceComponent
                    http={http}
                    record={record}
                    scheduler={scheduler}
                  />
                );
              },
            },
          ]}
          project={project}
        />
      </div>
    </>
  );
}

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator["msMaxTouchPoints"] > 0
  );
}

export const makeProject = (mechanics: MechanicDto[]) => ({
  eventsData: [],
  assignmentsData: [],
  resourceTimeRangesData: [],
  resourcesData: mapMechanicsToResources(mechanics),
  calendarsData: generateDefaultCalendar(),
});

export const makeBryntumProps = (
  readOnly: boolean,
  apollo: Apollo,
  onTaskEdit: (task: ServiceEventDtoWithModel) => void,
  setConfirmContext: (context: IntermediateContext) => void
) => {
  return {
    readOnly,
    fillTicks: true,
    rowHeight: 80,
    scheduleTooltipFeature: false,
    listeners: {
      beforeEventResizeFinalize: ({ context }) => {
        if (isTouchDevice()) {
          context.async = true;
          setConfirmContext(context);
        }
      },
      beforeEventDropFinalize: ({ context }) => {
        if (isTouchDevice()) {
          context.async = true;
          setConfirmContext(context);
        }
      },
      eventResizeEnd: async ({ eventRecord, changed }) => {
        if (changed) {
          onResizeServiceEvent(eventRecord);
          await storeServicePlanningEvent(apollo, eventRecord);
        }
      },
      afterEventSave: async ({ eventRecord }) => {
        await storeServicePlanningEvent(apollo, eventRecord);
      },
      dragCreateEnd: ({ newEventRecord }) => {
        setTimeout(() => onTaskEdit(newEventRecord), 250);
      },
      eventClick: ({ eventRecord }) => onTaskEdit(eventRecord),
      afterEventDrop: async ({ assignmentRecords, eventRecords, valid }) => {
        if (valid) {
          await Promise.all(
            eventRecords.map((record) =>
              storeServicePlanningEvent(apollo, record)
            )
          );
          await Promise.all(
            assignmentRecords.map((assignment) =>
              storeServicePlanningAssignment(apollo, assignment)
            )
          );
        }
      },
    },
    eventTooltipFeature: {
      template: renderEventTooltip,
    },
    timeAxisHeaderMenuFeature: { disabled: true },
    taskEditFeature: false,
    percentBarFeature: false,
    enableSticky: true,
    stickyEventsFeature: true,
    eventStyle: "plain",
    columnLinesFeature: true,
    regionResizeFeature: false,
    allowOverlap: true,
    snap: true,
    eventLayout: "pack",
    headerMenuFeature: { disabled: true },
    scheduleMenuFeature: false,
    resourceMenuFeature: false,
    cellTooltipFeature: false,
    headerZoomFeature: false,
    columnResizeFeature: false,
    zoomOnTimeAxisDoubleClick: false,
    barMargin: 12,
    eventColor: "blue",
    forceFit: true,
    dependenciesFeature: false,
    dependencyEditFeature: false,
    nonWorkingTimeFeature: true,
    resourceNonWorkingTimeFeature: true,
    resourceTimeRangesFeature: false,
    viewPreset: {
      base: "hourAndDay",
      timeResolution: {
        unit: "minutes",
        increment: 30,
      },
      headers: [
        {
          unit: "day",
          dateFormat: "ddd DD/MM",
        },
        {
          unit: "minutes",
          increment: 30,
          renderer: (start) => {
            const hour = moment(start).format("HH"),
              minute = moment(start).minute();

            const value = minute === 0 ? hour : "";

            return `<div style="font-size:12px;">${value}</div>`;
          },
        },
      ],
    },
    workingTime: {
      fromDay: 1,
      fromHour: 6,
      toDay: 6,
      toHour: 17,
    },
  };
};
