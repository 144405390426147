import { Entity } from "src/app/entity.service";

export class MapColorEntity extends Entity {
  type = "service_map_colors";

  days: number;
  color: string;

  createdAt: Date;
}
