import { Component, ChangeDetectorRef } from "@angular/core";
import { AppNotificationTemplate } from "./app-notification-template.entity";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AppNotificationTemplateDialogComponent } from "../app-notification-template-dialog/app-notification-template-dialog.component";
import { AppRestService } from "../app-rest.service";
import { AppService } from "../app.service";
import { MdcSnackbar } from "@angular-mdc/web";

@Component({
  selector: "app-app-notification-templates",
  templateUrl: "./app-notification-templates.component.html",
  styleUrls: ["./app-notification-templates.component.scss"],
})
export class AppNotificationTemplatesComponent extends AppCrudBoilerplate<
  AppNotificationTemplate
> {
  entity = AppNotificationTemplate;
  editDialog = AppNotificationTemplateDialogComponent;

  constructor(
    readonly app: AppService,
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly appRest: AppRestService,
    protected readonly snackbar: MdcSnackbar
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }

  async test(event: Event, notification: AppNotificationTemplate) {
    event.preventDefault();
    event.stopPropagation();

    const response = await this.appRest.request<boolean>({
      url: `notification-templates/${notification.id}/test`,
      method: "PUT",
    });

    if (!response.hasError() && response.value) {
      this.snackbar.open("Verzonden", "Ok", { leading: true });
    }
  }
}
