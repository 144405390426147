import { DrawOrder, Drawer, DrawOrderType } from './drawer.entity';
import { Injectable, EventEmitter } from '@angular/core';
import { EntityManager } from '../entity.service';
import * as moment from 'moment';
import * as _ from 'lodash';
/**
 * Service used for planning @see {DrawOrder} for @see {Drawer}
 *
 * @export
 * @class DrawOrderPlanService
 */
@Injectable()
export class DrawOrderPlanService {
  public readonly totalHoursPerDay = 8.0;

  onUpdated: EventEmitter<any> = new EventEmitter<any>();

  protected get drawOrderService() {
    return this.entityManager.get(DrawOrder);
  }

  /**
  * Creates a new @see {DrawOrderPlanService}
  *
  * @param {Date} monday
  * @param {DrawerPlanning} planning
  * @returns
  * @memberof DrawOrderPlanService
  */
  constructor(protected readonly entityManager: EntityManager) { }

  /**
   * Makes a week planning for the given drawers planning container.
   *
   * @param {Date} monday
   * @param {DrawerPlanning} planning
   * @returns
   * @memberof DrawOrderPlanService
   */
  plan(monday: Date, planning: DrawerPlanning) {
    planning.daysOfWeek = {};
    planning.drawer.absents = planning.drawer.absents || [];

    planning.drawer.orders
      .filter(e => e.totalHours)
      .forEach(e => e.totalHours = e.totalHours * 1.0);

    planning.drawer.orders
      .filter(e => e.totalHoursSpend)
      .forEach(e => e.totalHoursSpend = e.totalHoursSpend * 1.0);

    planning.drawer.orders
      .filter(e => e.plannedAt && !e.plannedAtCorrected)
      .forEach(e => {
        e.plannedAt = moment(e.plannedAt).utc().toDate(),
          e.plannedAtCorrected = true;
      });

    const sortedOrders = _.orderBy(planning.drawer.orders, o => this.getOrderKey(o));
    const sortedOrdersCompleted = sortedOrders.filter(e => e.percentageCompleted === 100);
    const sortedOrdersUnCompleted = sortedOrders.filter(e => e.percentageCompleted !== 100);

    for (const absent of planning.drawer.absents) {
      Array.from(this.createBlocks(this.drawOrderService.concept({
        description: absent.reason || 'Absent',
        totalHours: absent.hours * 1.0,
        plannedAt: absent.date,
        isFrozen: true,
        __drawOrderType__: {
          name: 'N/A'
        }, absent
      }), planning));
    }

    for (const orderList of [sortedOrdersCompleted, sortedOrdersUnCompleted]) {
      for (const order of orderList) {
        order.isPeeker = order.plannedAt ? (new Date(order.plannedAt) < monday) : false;
        Array.from(this.createBlocks(order, planning));
      }
    }

    const daysOfWeek = {};

    for (const day of Array.from(this.getWeekDays(monday))) {
      const key = this.getDateKey(day);
      daysOfWeek[key] = planning.daysOfWeek[key] || [];
    }

    planning.daysOfWeek = daysOfWeek;

    this.onUpdated.next({});

    return planning;
  }

  protected * createBlocks(order: DrawOrder, planning: DrawerPlanning, args: DrawOrderCreateArgs = {}) {
    args.date = args.date || order.plannedAt;
    args.sequenceId = args.sequenceId || 0;
    args.hours = args.hours || (
      order.percentageCompleted >= 100 ? order.totalHoursSpend : order.totalHours
    );

    const blocks = this.getOrAddList(args.date, planning),
      hoursOccupied = _.sumBy(blocks, o => o.hours),
      hoursToSpend = (this.totalHoursPerDay - hoursOccupied);

    const hoursPlanned = order.totalHours - args.hours;
    const candidateBlock: DrawOrderBlock = { isOk: false, hours: 0.0, order, sequenceId: args.sequenceId };

    if (args.sequenceId === 0) {
      candidateBlock.order.isError = !this.isDateEqual(args.date, order.plannedAt);

      if (candidateBlock.order.isError) {
        candidateBlock.order.errorDate = args.date;
      }
    }

    if ((!planning.drawer.id) || args.hours <= hoursToSpend) {
      candidateBlock.isOk = true;
      candidateBlock.hours = args.hours;
      candidateBlock.isLast = true;

      blocks.push(candidateBlock);
    } else {
      if (hoursToSpend > 0) {
        candidateBlock.isOk = true;
        candidateBlock.hours = hoursToSpend;

        blocks.push(candidateBlock);

        args.sequenceId++;
      }

      args.hours -= hoursToSpend;
      args.date = this.moveToNextDate(args.date);

      if (args.hours > 0) {
        Array.from(this.createBlocks(order, planning, args));
      } else {
        candidateBlock.isLast = true;
      }
    }

    if (candidateBlock.isOk) {
      candidateBlock.width = (candidateBlock.order.totalHours / this.totalHoursPerDay) * 100;
      candidateBlock.widthSequence = (candidateBlock.hours / this.totalHoursPerDay) * 100;
      candidateBlock.percentage = this.makeBlockPercentage(hoursPlanned, candidateBlock);

      yield candidateBlock;
    }
  }

  protected getOrderKey(order: DrawOrder) {
    return `${order.isFrozen ? '0' : '1'}-${this.getDateKey(order.plannedAt)}-${order.plannedAtOrder || 0}`;
  }

  protected getDateKey(date: Date) {
    return date ? moment(date).format('YYYY.MM.DD') : '';
  }

  protected isDateEqual(left: Date, right: Date) {
    return this.getDateKey(left) === this.getDateKey(right);
  }

  protected moveToNextDate(date: Date) {
    do {
      date = moment(date).add(1, 'days').toDate();
    } while (this.isDateWeekend(date));
    return date;
  }

  protected isDateWeekend(date: Date) {
    const weekDay = moment(date).isoWeekday();

    return weekDay === 6 || weekDay === 7;
  }

  protected getOrAddList(date: Date, week: DrawerPlanning) {
    const key = this.getDateKey(date);
    return week.daysOfWeek[key] || (week.daysOfWeek[key] = []);
  }

  protected makeBlockPercentage(hoursPlanned: number, block: DrawOrderBlock) {
    const mod = (block.order.percentageCompleted / 100.0);
    const virtualSpend = (block.order.totalHours * mod);
    const sequenceHours = virtualSpend - hoursPlanned;

    if (sequenceHours > 0) {
      const result = (sequenceHours / block.hours) * 100.0;
      return result > 100 ? 100 : result;
    }

    return 0;
  }

  protected * getWeekDays(monday: Date) {
    for (let i = 0; i < 5; i++) {
      yield moment(monday).add(i, 'days').toDate();
    }

    /* Todo: more visible dasys*/
    /*const nextMonday = moment(monday)
      .add(1, 'weeks').toDate();
  
    for (let i = 0; i < 5; i++) {
      yield moment(nextMonday).add(i, 'days').toDate();
    }*/
  }
}

export class DrawerPlanning {
  drawer: Drawer;
  daysOfWeek?: { [date: string]: DrawOrderBlock[] };
}

export class DrawOrderBlock {
  hours: number;
  order: DrawOrder;

  isOk?: boolean;
  isLast?: boolean;

  width?: number;
  widthSequence?: number;

  percentage?: number;

  sequenceId?: number;
  sequenceHours?: number;
}

export class DrawOrderCreateArgs {
  date?: Date;
  hours?: number;
  sequenceId?: number;
}

export interface IDrawOrderDayList {
  key: string;
  value: DrawOrderBlock[];

  planning: DrawerPlanning;
}

export interface IDrawOrderBufferList extends IDrawOrderDayList {
  drawOrderType: DrawOrderType;
}
