import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import * as moment from "moment";
import * as React from "react";
import { registerPdfExportFonts } from "../pdf-export.fonts";

registerPdfExportFonts();

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontSize: 10,
    fontFamily: "Montserrat",
    padding: 24,
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  rowStyled: { border: "1px solid #eeeeee" },
  column: {
    flexDirection: "column",
    width: "100%",
  },
  columnPadded: {
    padding: 4,
    fontSize: 8,
  },
  columnStyled: {
    backgroundColor: "grey",
    color: "white",
    padding: 4,
    fontSize: 8,
  },
  section: {
    flexGrow: 1,
    marginVertical: 8,
  },
  boldText: {
    fontWeight: "bold",
  },
  mediumText: {
    fontWeight: "medium",
  },
});

export type AccessibilityPermissionExportDocumentRole = {
  id: string;
  name: string;
  pages: AccessibilityPermissionExportDocumentUserPage[];
};

export type AccessibilityPermissionExportDocumentUser = {
  id: string;
  name: string;
  role: string;
  stage: string;
  impersonation: boolean;
  lastOnline?: Date;
  pages: AccessibilityPermissionExportDocumentUserPage[];
};

export type AccessibilityPermissionExportDocumentUserPage = {
  path: string;
  name: string;
  options: { [key: string]: string };
};

type AccessibilityPermissionExportDocumentProps = {
  roles: AccessibilityPermissionExportDocumentRole[];
  users: AccessibilityPermissionExportDocumentUser[];
};

export const AccessibilityPermissionExportDocument = ({
  users,
  roles,
}: AccessibilityPermissionExportDocumentProps) => {
  return (
    <Document subject={"Permissies Export"}>
      {roles.map((role) => (
        <Page
          key={role.id}
          size="A4"
          style={styles.page}
          bookmark={`${role.id} - ${role.name}`}
        >
          <AccessibilityPermissionExportDocumentFooter
            prefix={`Rol ${role.name}`}
          />

          <View style={styles.column}>
            <AccessibilityPermissionExportDocumentHeader title="Rol" />
            <AccessibilityPermissionExportDocumentHeaderTable
              lines={[
                { title: "Code", value: role.id },
                { title: "Naam", value: role.name },
              ]}
            />

            <AccessibilityPermissionExportDocumentPages pages={role.pages} />
          </View>
        </Page>
      ))}
      {users.map((user) => (
        <Page
          key={user.id}
          size="A4"
          style={styles.page}
          bookmark={`${user.id} - ${user.name}`}
        >
          <AccessibilityPermissionExportDocumentFooter
            prefix={`Stamkaart ${user.name}`}
          />

          <View style={styles.column}>
            <AccessibilityPermissionExportDocumentHeader title="Stamkaart" />
            <AccessibilityPermissionExportDocumentHeaderTable
              lines={[
                { title: "Code", value: user.id },
                { title: "Naam", value: user.name },
                { title: "Rol", value: user.role },
                { title: "Stadium", value: user.stage },
                {
                  title: "Impersonatie",
                  value: user.impersonation ? "Ja" : "Nee",
                },
                { title: "Omgeving", value: location.host },
                {
                  title: "Laatst online",
                  value: user.lastOnline
                    ? moment(user.lastOnline).format("DD-MM-YYYY HH:mm:ss")
                    : "nooit",
                },
                { title: "Datume", value: moment().format("DD-MM-YYYY") },
              ]}
            />

            <AccessibilityPermissionExportDocumentPages
              pages={[
                {
                  path: "nvt.",
                  name: `Alle pagina's van rol: ${user.role}`,
                  options: {},
                },
                ...user.pages,
              ]}
            />
          </View>
        </Page>
      ))}
    </Document>
  );
};

type AccessibilityPermissionExportDocumentPagesProps = {
  pages: AccessibilityPermissionExportDocumentUserPage[];
};

const AccessibilityPermissionExportDocumentPages = ({
  pages,
}: AccessibilityPermissionExportDocumentPagesProps) => {
  return (
    <>
      <View style={{ ...styles.row, marginTop: 6 }}>
        <View
          style={{
            ...styles.column,
            ...styles.columnStyled,
            flex: 1,
          }}
        >
          <Text>Pagina</Text>
        </View>
        <View
          style={{
            ...styles.column,
            ...styles.columnStyled,
            flex: 1,
          }}
        >
          <Text>Url</Text>
        </View>
        <View
          style={{
            ...styles.column,
            ...styles.columnStyled,
            flex: 1,
          }}
        >
          <Text>Opties</Text>
        </View>
      </View>
      {pages.map((page) => (
        <View
          wrap={false}
          key={page.path}
          style={{ ...styles.row, ...styles.rowStyled }}
        >
          <View
            style={{
              ...styles.column,
              ...styles.columnPadded,
              flex: 1,
            }}
          >
            <Text style={styles.mediumText}>{page.name}</Text>
          </View>
          <View
            style={{
              ...styles.column,
              ...styles.columnPadded,
              flex: 1,
            }}
          >
            <Text>{page.path}</Text>
          </View>
          <View
            style={{
              ...styles.column,
              ...styles.columnPadded,
              flex: 1,
            }}
          >
            {Object.entries(page.options).map(([key, value]) => (
              <View key={key} style={{ ...styles.row }}>
                <View
                  style={{
                    ...styles.section,
                    marginVertical: 0,
                    flex: 2,
                  }}
                >
                  <Text style={styles.mediumText}>{key}</Text>
                </View>
                <View
                  style={{
                    ...styles.section,
                    marginVertical: 0,
                    flex: 1,
                  }}
                >
                  <Text>{value}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      ))}
    </>
  );
};

type AccessibilityPermissionExportDocumentFooterProps = {
  prefix: string;
};

const AccessibilityPermissionExportDocumentFooter = ({
  prefix,
}: AccessibilityPermissionExportDocumentFooterProps) => {
  return (
    <>
      <Text
        fixed
        style={{
          position: "absolute",
          bottom: 10,
          left: 24,
          fontSize: 8,
          fontWeight: "light",
        }}
        render={({ subPageNumber, subPageTotalPages }) =>
          `${prefix} - Pagina ${subPageNumber} van ${subPageTotalPages}`
        }
      />
      <Text
        fixed
        style={{
          position: "absolute",
          bottom: 10,
          right: 24,
          fontSize: 8,
          fontWeight: "light",
        }}
        render={({ pageNumber, totalPages }) =>
          `Pagina ${pageNumber} van ${totalPages}`
        }
      />
    </>
  );
};

type AccessibilityPermissionExportDocumentHeaderProps = {
  title: string;
};

const AccessibilityPermissionExportDocumentHeader = ({
  title,
}: AccessibilityPermissionExportDocumentHeaderProps) => {
  return (
    <>
      <View style={styles.row}>
        <View style={styles.section}>
          <Text style={{ fontSize: 24, fontWeight: "medium" }}>{title}</Text>
        </View>

        <Image
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "42px",
            height: "42px",
            borderRadius: "100%",
            objectFit: "cover",
          }}
          src={"/assets/imgs/landing.jpg"}
        />
      </View>
    </>
  );
};

type AccessibilityPermissionExportDocumentHeaderTableProps = {
  lines: { title: string; value: string }[];
};

const AccessibilityPermissionExportDocumentHeaderTable = ({
  lines,
}: AccessibilityPermissionExportDocumentHeaderTableProps) => {
  return (
    <View style={styles.row}>
      <View style={styles.section}>
        {lines.map((line) => (
          <Text key={line.title} style={styles.boldText}>
            {line.title}
          </Text>
        ))}
      </View>
      <View style={styles.section}>
        {lines.map((line) => (
          <Text key={line.value}>{line.value ?? "-"}</Text>
        ))}
      </View>
      <View style={styles.section} />
      <View style={styles.section} />
    </View>
  );
};
