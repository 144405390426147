import * as moment from "moment";
import { ChangeDetectorRef, Injector } from "@angular/core";
import { chain } from "lodash";
import { User } from "../accessibility-users/user.entity";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { DialogService } from "../dialog.service";
import {
  EntityAdvancementDialogComponent,
  EntityAdvancementDialogComponentData,
  EntityAdvancementDialogComponentDataExtentions,
} from "../entity-advancement-dialog/entity-advancement-dialog.component";
import { EntityManager, EntityService } from "../entity.service";
import { MetacomService } from "../metacom.service";
import { EntityAdvancement } from "../offers/entity.advancement.entity";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { ServiceItem } from "./service.dto";

export const useFetchServiceTicketAdvancements = (
  metacomService: MetacomService,
  userService: EntityService<User>,
  advancementService: EntityService<EntityAdvancement>
) => {
  const fetchUserData = async (advancement: EntityAdvancement) => {
    const response = await userService.findOne(advancement.userId);
    advancement.__user__ = response.hasError()
      ? userService.concept({ name: "Onbekend" })
      : response.value;

    return advancement;
  };

  return {
    fetchServiceTicketAdvancements: async (origin: string, stadium: string) => {
      const response = await metacomService.queryTableAsync<any>({
        setName: "metacom",
        tableName: "service_voortgang",
        filter: `mtc_rubdat.herkomst = "${origin}"`,
      });

      if (!response.hasError()) {
        const items = await Promise.all(
          chain(response.value)
            .map((ticket) =>
              advancementService.concept({
                userId: ticket.gemeld_door,
                comment: ticket.voortgang,
                entityId: ticket.herkomst_m,
                entityType: "service",
                isCompleted: stadium === "1000",
                date: ticket.datum_afspraak
                  ? moment(ticket.datum_afspraak).toDate()
                  : null,
                createdAt: moment(ticket.datum_voortgang).toDate(),
                updatedAt: moment(ticket.datum_voortgang).toDate(),
              })
            )
            .map((e) => fetchUserData(e))
            .value()
        );

        return new RestResponse(items);
      }
    },
  };
};

export const useStoreServiceTicketAdvancement = (
  authService: AuthService,
  cellaService: CellaService,
  onUpdated?: () => void | Promise<void>
) => {
  return {
    storeServiceTicketAdvancement: async (
      ticket: ServiceItem,
      advancement: EntityAdvancement,
      isNew = false
    ) => {
      advancement.createdAt = new Date();
      advancement.updatedAt = new Date();

      const responseStore = await cellaService.serviceTicketProgress({
        relationId: authService.user.id,
        isCompleted: advancement.isCompleted,
        description: advancement.comment,
        isNew,
        notify: true,
        ticketId: ticket.servicepunt,
        source: "portal",
        appointmentDate:
          advancement.date && moment(advancement.date).format("DD-MM-YYYY"),
      });

      if (!responseStore.hasError()) {
        if (!isNew) {
          onUpdated && (await onUpdated());
        }

        return new RestResponse(advancement);
      }
    },
  };
};

export const useOpenServiceTicketAdvancementDialogWithInjector = (
  injector: Injector,
  onUpdated?: () => void | Promise<void>,
  changeDetector?: ChangeDetectorRef
) => {
  const entities = injector.get(EntityManager);
  const { openServiceTicketAdvancementDialog } =
    useOpenServiceTicketAdvancementDialog(
      injector.get(AuthService),
      injector.get(CellaService),
      injector.get(MetacomService),
      injector.get(DialogService),
      injector.get(TransactionService),
      entities.get(User),
      entities.get(EntityAdvancement),
      onUpdated,
      changeDetector
    );

  return {
    openServiceTicketAdvancementDialogWithInjector: (
      ticket: ServiceItem,
      extensions?: EntityAdvancementDialogComponentDataExtentions
    ) => openServiceTicketAdvancementDialog(ticket, extensions),
  };
};

export const useOpenServiceTicketAdvancementDialog = (
  authService: AuthService,
  cellaService: CellaService,
  metacomService: MetacomService,
  dialogService: DialogService,
  transactionService: TransactionService,
  userService: EntityService<User>,
  advancementService: EntityService<EntityAdvancement>,
  onUpdated?: () => void | Promise<void>,
  changeDetector?: ChangeDetectorRef
) => {
  const { fetchServiceTicketAdvancements } = useFetchServiceTicketAdvancements(
    metacomService,
    userService,
    advancementService
  );

  const { storeServiceTicketAdvancement } = useStoreServiceTicketAdvancement(
    authService,
    cellaService,
    onUpdated
  );

  const onImageAdded = async (ticket: ServiceItem) => {
    onUpdated && (await onUpdated());

    return await cellaService.legacyNotifyCustomerJourney(
      `service/ticket-image-added/${ticket.servicepunt}`
    );
  };

  return {
    openServiceTicketAdvancementDialog: async (
      ticket: ServiceItem,
      extensions?: EntityAdvancementDialogComponentDataExtentions
    ) => {
      let advancements = null;

      await transactionService.doLoading(async () => {
        advancements = await fetchServiceTicketAdvancements(
          ticket.herkomst,
          ticket.stadium
        );
      });

      await dialogService.open(
        changeDetector,
        EntityAdvancementDialogComponent,
        {
          data: new EntityAdvancementDialogComponentData(
            ticket.herkomst,
            "service",
            ticket.servicepunt,
            ticket.omschrijving,
            ticket.projectNativeId,
            true,
            advancements.value,
            (concept) => storeServiceTicketAdvancement(ticket, concept),
            "Afspraak",
            true,
            () => onImageAdded(ticket),
            null,
            extensions,
            [
              {
                key: "Project",
                value: `(${ticket.project}) ${ticket.projectData.description}`,
              },
              {
                key: "Categorien",
                value: `${ticket.hoofdcategorie || "Niet gekozen"} / ${
                  ticket.subcategorie || "Niet gekozen"
                }`,
              },
              {
                key: "Gepland",
                value: ticket.datum_gepland
                  ? moment(ticket.datum_gepland).format("DD-MM-YYYY")
                  : "Nog onbekend",
              },
            ]
          ),
          clickOutsideToClose: false,
          escapeToClose: false,
        }
      );
    },
  };
};
