import { Component, OnInit } from "@angular/core";
import { AppText } from "../app-users/app-text.entity";
import { EntityManager } from "../entity.service";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-app-invite-text-dialog",
  templateUrl: "./app-invite-text-dialog.component.html",
  styleUrls: ["./app-invite-text-dialog.component.scss"],
})
export class AppInviteTextDialogComponent implements OnInit {
  response: RestResponse<AppText>;

  protected get appTextService() {
    return this.entityManager.get(AppText);
  }

  constructor(
    protected readonly entityManager: EntityManager,
    protected readonly transactions: TransactionService,
    protected readonly dialog: MdcDialogRef<AppInviteTextDialogComponent>
  ) {}

  async ngOnInit() {
    const response = await this.appTextService.findOne(AppText.INVITATION_ID);

    if (!response.hasError()) {
      this.response = response;
    } else {
      this.response = new RestResponse(
        this.appTextService.concept({
          id: AppText.INVITATION_ID,
        })
      );
    }
  }

  async save() {
    this.response.value.id = AppText.INVITATION_ID;
    this.response.value.isConcept = false;

    const response = await this.transactions.perform(() =>
      this.appTextService.save(this.response.value)
    );

    if (!response.hasError()) {
      this.dialog.close(response);
    }
  }
}
