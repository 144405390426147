import { Entity } from "../entity.service";

export class AppInvite extends Entity {
  type = "app.invites";

  email: string;
  project_id: string;

  created_at?: Date;
  updated_at?: Date;
}
