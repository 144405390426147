import * as React from "react";
import { AppBar, Tab, Tabs, Box } from "@material-ui/core";
import { ReactComponentProps } from "src/app/react-component/react.component";
import { ServiceSettingsComponent } from "../service-settings.component";
import { SpaceComponent } from "./spaces/space.component";
import { MeterComponent } from "./meters/meter.component";
import { TypeComponent } from "./types/type.component";
import {
  Flare as FlareIcon,
  MenuBook as MenuBookIcon,
  AvTimer as AvTimerIcon,
  MeetingRoom as MeetingRoomIcon,
  PriorityHigh as PriorityHighIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Map as MapIcon,
  Schedule as ScheduleIcon,
} from "@material-ui/icons";
import { PresetComponent } from "./presets/preset.component";
import { PriorityComponent } from "./priorities/priority.component";
import { TemplateComponent } from "./template/template.component";
import { MapComponent } from "./map/map.component";
import { StageComponent } from "./stages/stage.component";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

export interface ServiceSettingsReactComponent
  extends ReactComponentProps<ServiceSettingsComponent> {}
export const ServiceSettingsReactComponent: React.FC<ServiceSettingsReactComponent> =
  ({ component }) => {
    const [value, setValue] = React.useState(0);

    return (
      <div>
        <AppBar
          position="static"
          color="default"
          style={{ backgroundColor: "white" }}
        >
          <Tabs
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            scrollButtons="auto"
          >
            <Tab label="Ruimtes" icon={<MeetingRoomIcon />} />
            <Tab label="Meterstanden" icon={<AvTimerIcon />} />
            <Tab label="Types" icon={<FlareIcon />} />
            <Tab label="Bibliotheek" icon={<MenuBookIcon />} />
            <Tab label="Prioriteiten" icon={<PriorityHighIcon />} />
            <Tab label="PDF" icon={<InsertDriveFileIcon />} />
            <Tab label="Kaart" icon={<MapIcon />} />
            <Tab label="Planning" icon={<ScheduleIcon />} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <SpaceComponent entities={component.entities} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <MeterComponent entities={component.entities} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <TypeComponent entities={component.entities} />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <PresetComponent
            entities={component.entities}
            rest={component.rest}
          />
        </TabPanel>

        <TabPanel value={value} index={4}>
          <PriorityComponent entities={component.entities} />
        </TabPanel>

        <TabPanel value={value} index={5}>
          <TemplateComponent http={component.http} apollo={component.apollo} />
        </TabPanel>

        <TabPanel value={value} index={6}>
          <MapComponent entities={component.entities} />
        </TabPanel>

        <TabPanel value={value} index={7}>
          <StageComponent entities={component.entities} />
        </TabPanel>
      </div>
    );
  };
