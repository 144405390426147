import { Injectable } from '@angular/core';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class MetacomService {
  constructor(protected readonly restService: RestService) { }

  queryTableAsync<T>(request: IMetacomRequest) {
    return this.restService.post<T[]>('metacom/query', request);
  }

  queryTablePagedAsync<T>(request: IMetacomPagedRequest) {
    return this.restService.post<T[]>('metacom/query-paged', request);
  }
}

export interface IMetacomRequest {
  setName: string;
  tableName: string;
  filter?: string;
}

export interface IMetacomPagedRequest extends IMetacomRequest {
  pageNr?: number;
  pageSize?: number;
}
