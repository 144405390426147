import * as React from "react";
import {
  LinearProgress,
  makeStyles,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionActions,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { Entity } from "src/app/entity.service";

import {
  CrudAddedEvent,
  CrudDeletedEvent,
  CrudOrderedEvent,
  CrudSavedEvent,
} from "../bloc/crud/crud.event";

import {
  CrudErrorState,
  CrudInitialState,
  CrudLoadedState,
  CrudState,
} from "../bloc/crud/crud.state";

import { CrudBloc } from "../bloc/crud/crud.bloc";
import { BlocBuilder } from "../bloc/bloc.builder";
import { Add } from "@material-ui/icons";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

export interface CrudComponentProps {
  bloc: CrudBloc<Entity>;
  isOrderable?: boolean;
  renderTitle: (entity: Entity) => React.ReactNode;
  renderEditor: (entity: Entity, bloc: CrudBloc<Entity>) => React.ReactNode;
  saveDisabled: (entity: Entity, siblings: Entity[]) => boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 720,
    padding: 16,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
}));

export const CrudComponent: React.FC<CrudComponentProps> = ({
  bloc,
  isOrderable,
  renderTitle,
  renderEditor,
  saveDisabled,
}) => {
  const classes = useStyles();
  const [entityToDelete, setEntityToDelete] = React.useState<Entity>(null);
  const [expanded, setExpanded] = React.useState<string>();

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onDragEnd = (result: DropResult) => {
    if (result.destination) {
      bloc.add(
        new CrudOrderedEvent({
          startIndex: result.source.index,
          endIndex: result.destination.index,
        })
      );
    }
  };

  const mapToEditor = (entity: Entity, state: CrudLoadedState<Entity>) => (
    <Accordion
      key={entity.refId}
      elevation={1}
      style={{ borderRadius: 0 }}
      expanded={expanded === entity.refId}
      onChange={handleExpand(entity.refId)}
    >
      <AccordionSummary>{renderTitle(entity)}</AccordionSummary>
      <Divider style={{ marginBottom: 16 }} />
      <AccordionDetails>
        {expanded === entity.refId ? renderEditor(entity, bloc) : <></>}
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" onClick={() => setEntityToDelete(entity)}>
          Verwijder
        </Button>
        <Button
          size="small"
          color="primary"
          disabled={saveDisabled(
            entity,
            state.props.entities.filter(
              (sibling) => sibling.refId !== entity.refId
            )
          )}
          onClick={() => {
            bloc.add(new CrudSavedEvent({ entity: entity }));
            handleExpand(entity.refId)(null, false);
          }}
        >
          Opslaan
        </Button>
      </AccordionActions>
    </Accordion>
  );

  function* mapStateToNode(state: CrudState) {
    if (state instanceof CrudInitialState) {
      yield <LinearProgress key="loading" />;
    }

    if (state instanceof CrudLoadedState) {
      if (isOrderable) {
        yield (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {state.props.entities.map((item, index) => (
                    <Draggable
                      key={item.refId}
                      draggableId={item.refId}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {mapToEditor(item, state)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        );
      } else {
        yield* state.props.entities.map((space) => mapToEditor(space, state));
      }

      yield (
        <Paper key="add-space" style={{ padding: 10, marginTop: 10 }}>
          <Button
            startIcon={<Add />}
            size="small"
            color="primary"
            onClick={() => bloc.add(new CrudAddedEvent())}
          >
            Toevoegen
          </Button>
        </Paper>
      );
    }

    if (state instanceof CrudErrorState) {
      yield <Alert severity="error">{state.props.message}</Alert>;
    }
  }

  return (
    <div className={classes.root}>
      <BlocBuilder
        bloc={bloc}
        builder={(state) => (
          <React.Fragment>
            {...Array.from(mapStateToNode(state))}
          </React.Fragment>
        )}
      />

      <Dialog open={!!entityToDelete} onClose={() => setEntityToDelete(null)}>
        <DialogTitle>Regel verwijderen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dit kan niet ongedaan worden gemaakt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEntityToDelete(null)} color="primary">
            Nee
          </Button>
          <Button
            onClick={() => {
              bloc.add(new CrudDeletedEvent({ entity: entityToDelete }));
              setEntityToDelete(null);
            }}
            color="primary"
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
