export class DebouncePromise {
  protected timeoutId: NodeJS.Timeout;

  constructor(
    readonly config?: {
      readonly backoffMs?: number;
    }
  ) {
    this.config = config || { backoffMs: 200 };
  }

  perform<T>(item: () => Promise<T>) {
    if (!!this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(
      async () => await item(),
      this.config.backoffMs
    );
  }
}
