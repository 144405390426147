import * as React from "react";
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import QrReader from "react-qr-reader";
import { ScanGoodsComponent } from "../scan-goods.component";

export interface ScanGoodsProps {
  component: ScanGoodsComponent;
}

export const ScanGoods: React.FC<ScanGoodsProps> = ({ component }) => {
  const [result, setResult] = React.useState<string>();

  const setResultWithCheck = (value: string) => {
    if (value && value !== "") {
      setResult(value);
    }
  };

  return (
    <Container style={{ paddingTop: 16 }}>
      <React.Fragment>
        <QrReader
          onScan={(value: string) => setResultWithCheck(value)}
          style={{ width: "100%", margin: "auto" }}
        />

        {result && (
          <List component="nav">
            <ListItem
              style={{ border: "1px solid #eeeeee" }}
              button
              onClick={() => component.setGoodsId(result)}
            >
              <ListItemText
                primary={result}
                secondary="Klik hier om te openen"
              />
            </ListItem>
          </List>
        )}
      </React.Fragment>
    </Container>
  );
};
