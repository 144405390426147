import { AuthService } from "../auth.service";
import { EntityService } from "../entity.service";
import { UserNotification } from "../notification/user-notification.entity";

export class NotificationGuard {
  constructor(
    readonly props: {
      readonly userId: string;
      readonly notifications: EntityService<UserNotification>;
    }
  ) {}

  async sendIfNotMe(notification: UserNotification) {
    if (notification.userId !== this.props.userId) {
      return await this.props.notifications.save(notification);
    }
  }
}
