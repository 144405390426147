import { Project } from "../project/project.entity";
import { User } from "../accessibility-users/user.entity";
import { first } from "lodash";
import * as moment from "moment";
import { ServiceEventDto } from "../service-planning/dtos/service-event.dto";

export class ServiceItemProjectData {
  constructor(
    readonly id: string,
    readonly project: Project,
    readonly projectLegacy: Project
  ) {}
}

export class ServiceItem {
  ruimte: string;
  stadium: string;
  servicepunt: string;

  interne_memo?: string;

  hasImages?: boolean;

  omschrijving: string;

  project: string;
  planningItem?: ServiceEventDto;

  get projectData() {
    return this.projectDataAll.project || this.projectDataAll.projectLegacy;
  }

  get projectDataLegacy() {
    return this.projectDataAll.projectLegacy;
  }

  get project_naam() {
    return this.projectDataLegacy
      ? this.projectDataLegacy.description
      : this.projectData.description;
  }

  projectDataAll: ServiceItemProjectData;

  klant_email: string;
  klant_telefoon: string;
  voortgang_memo: string;

  get projectNativeId() {
    return this.project.length > 4 ? this.project.substr(1, 4) : this.project;
  }

  get datumCount() {
    const cnt = moment().diff(moment(this.datum_gemeld), "days");
    return cnt < 0 ? 0 : cnt;
  }

  get isCompleted() {
    return this.stadium === "1000";
  }

  get isAppointmentExpired() {
    const appointment = this.voortgang_afspraak
      ? moment(this.voortgang_afspraak)
      : null;

    return appointment
      ? appointment.isBefore(moment().add(-1, "d")) &&
          appointment.isAfter(moment(this.voortgang_datum))
      : false;
  }

  get state() {
    if (this.isCompleted) {
      return "check";
    }

    if (this.isAppointmentExpired) {
      return "timer";
    }

    return "label";
  }

  uitvoerende: string;
  uitvoerendeData: User;

  hoofdcategorie: string;
  subcategorie: string;

  datum_gemeld: string;
  datum_gepland: string;

  voortgang_datum: string;
  voortgang_afspraak: string;

  herkomst: string;

  constructor(body: any) {
    delete body["project_naam"];

    Object.assign(this, body);
  }
}

export class ServiceItemGroup {
  constructor(readonly children: ServiceItem[]) {}

  marker: any;

  get isService() {
    return this.data.project.startsWith("S");
  }

  get data() {
    return first(this.children);
  }
}
