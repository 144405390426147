import { Entity } from '../entity.service';

export class AuditEntity extends Entity {
    description: string;
}

export class AuditOrderableEntity extends AuditEntity {
    type = null;

    orderId: number;
}
