import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@material-ui/core";
import * as moment from "moment";
import * as React from "react";
import { useState } from "react";
import { ServiceEventDtoWithModel } from "../dtos/service-event.dto";
import { createCopyOfEvent } from "../service-planning.functions";

export enum SchedulerTaskMoveDialogComponentMode {
  Move = "verplaatsen",
  Copy = "kopieren",
}

export interface SchedulerTaskMoveOperation {
  sourceEvent: ServiceEventDtoWithModel;
  mode: SchedulerTaskMoveDialogComponentMode;
}

interface SchedulerTaskMoveDialogComponentProps {
  operation: SchedulerTaskMoveOperation;
  onCancel: () => void;
  onConfirm: (event: ServiceEventDtoWithModel) => void;
}

export const SchedulerTaskMoveDialogComponent = ({
  operation,
  onCancel,
  onConfirm,
}: SchedulerTaskMoveDialogComponentProps) => {
  const { sourceEvent, mode } = operation;
  const [targetDate, setTargetDate] = useState(
    () => operation.sourceEvent.startDate
  );

  const onConfirmed = async () => {
    const targetEvent =
      mode === SchedulerTaskMoveDialogComponentMode.Copy
        ? createCopyOfEvent(sourceEvent)
        : sourceEvent;

    targetEvent.beginBatch();

    const newDateRaw = moment(targetDate);
    const newDate = moment(sourceEvent.startDate)
      .year(newDateRaw.year())
      .month(newDateRaw.month())
      .date(newDateRaw.date())
      .toDate();

    await targetEvent.setStartDate(newDate, true);

    targetEvent.endBatch();

    onConfirm(targetEvent);
  };

  return (
    <Dialog open onClose={onCancel} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{mode.toString()}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          label="Datum"
          type="date"
          onChange={(event) =>
            setTargetDate(moment(event.target.value).toDate())
          }
          value={moment(targetDate).format("YYYY-MM-DD")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Terug</Button>
        <Button onClick={onConfirmed} autoFocus>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
};
