import { Component, OnInit, Inject } from "@angular/core";
import {
  CellaService,
  MetacomTransactionDocumentLine,
  MetacomTransactionDocumentRequest,
} from "../cella.service";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { TransactionService } from "../transaction.service";
import { ServiceItem } from "../service/service.dto";
import * as moment from "moment";
import { Apollo } from "apollo-angular";
import { serviceTicketDialogCategoriesQuery } from "./service-ticket-dialog.query";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";

@Component({
  selector: "app-service-ticket-dialog",
  templateUrl: "./service-ticket-dialog.component.html",
  styleUrls: ["./service-ticket-dialog.component.scss"],
})
export class ServiceTicketDialogComponent implements OnInit {
  model = {
    projectId: null,
    userId: null,

    primaryCategoryId: "-1",
    secondaryCategoryId: "-1",

    space: null,
    description: null,
    date: null,
    memo: null,

    isReady: false,
  };
  projectFilterConfig = {
    allowNothing: true,
    title: "Project",
    icon: "archive",
    entityName: "legacy_projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  relationFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Uitvoerende",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "isSupplier", operator: "Equal", value: "t" },
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "stage", operator: "Equal", value: "1000" },
    ],
  };

  categories: ServiceCategory[];

  get isDisabled() {
    return this.data.model && this.data.model.isCompleted;
  }

  get primaryCategories() {
    return this.categories
      ? this.categories.filter((e) => e.service_category_id === null)
      : [];
  }

  get secondaryCategories() {
    return this.categories
      ? this.categories.filter(
          (e) => e.service_category_id === this.model.primaryCategoryId
        )
      : [];
  }

  get canReopen() {
    return this.grantService.varIs(
      grants.service_overview.allow_reopen,
      "true"
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: ServiceTicketDialogComponentData,

    protected readonly cellaService: CellaService,
    protected readonly transactionService: TransactionService,

    protected readonly apollo: Apollo,
    protected readonly grantService: GrantService,
    protected readonly dialogRef: MdcDialogRef<ServiceTicketDialogComponent>
  ) {}

  get canSave() {
    return (
      this.model.space &&
      this.model.description &&
      this.model.primaryCategoryId !== "-1" &&
      this.model.secondaryCategoryId !== "-1" &&
      this.model.date &&
      this.model.userId &&
      this.model.projectId
    );
  }

  get isNew() {
    return !this.data.model || !this.data.model.servicepunt;
  }

  ngOnInit() {
    this.model.date = moment(new Date()).format("YYYY-MM-DD");
    this.model.projectId = this.data.projectId;
    this.model.userId = this.data.userId;

    this.fetch().then(() => {
      if (this.data.model) {
        this.model.date = moment(this.data.model.datum_gemeld).format(
          "YYYY-MM-DD"
        );
        this.model.projectId = this.data.model.project;
        this.model.userId = this.data.model.uitvoerende;
        this.model.description = this.data.model.omschrijving;
        this.model.space = this.data.model.ruimte;
        this.model.memo = this.data.model.interne_memo;
        this.model.primaryCategoryId = this.categoryId(
          this.data.model.hoofdcategorie
        );
        this.model.secondaryCategoryId = this.categoryId(
          this.data.model.subcategorie,
          false
        );
      }

      this.model.isReady = true;
    });
  }

  async reOpen() {
    const response = await this.transactionService.perform(() =>
      this.cellaService.setStage({
        Entity: "0010",
        Origin: this.data.model.herkomst,
        Stage: "0",
      })
    );

    if (!response.hasError()) {
      this.dialogRef.close(
        new ServiceItem({
          stadium: "0",
          ...this.data.model,
        })
      );
    }
  }

  async save() {
    const response = await this.transactionService.perform(() =>
      this.cellaService.writeTransactionDocument(this.makeDocument())
    );

    const documentId = this.data.model
      ? this.data.model.servicepunt
      : response.value.CreatedDocumentNumber;

    if (!!documentId) {
      const origin = this.origin(documentId);
      const responseCategories = await this.storeExtraFields(origin);

      if (!responseCategories.hasError()) {
        this.dialogRef.close(
          new ServiceItem({
            stadium: "0",
            project: this.projectId,
            uitvoerende: this.model.userId,
            servicepunt: documentId,
            herkomst: origin,
            ruimte: this.model.space,
            omschrijving: this.model.description,
            datum_gemeld: new Date(),
          })
        );
      }
    }
  }

  protected makeDocument() {
    const mtcFormat = "YYYY-MM-DD",
      docType = "80-10";
    const candidate: MetacomTransactionDocumentRequest = {
      DocumentType: docType,
      DocumentNumber: this.data.model ? this.data.model.servicepunt : null,
      DocumentDate: moment().format(mtcFormat),
      DocumentDescription: this.model.description,
      DocumentLines: [
        {
          FromCompany: 920,
          ToCompany: 920,
          StartDate: moment(this.model.date).format(mtcFormat),
          StartTime: "000000",
          EndDate: moment(this.model.date).add(1, "month").format(mtcFormat),
          EndTime: "000000",
          SourceCompany: 920,
          ToCostCentre: this.projectId,
          FromCostCentre: this.model.userId,
          FromQuantity: "1",
          Description: this.model.description,
          FreeCode: this.model.space,
        },
      ],
    };

    if (!candidate.DocumentNumber) {
      delete candidate.DocumentNumber;
    }

    return candidate;
  }

  protected get projectId() {
    if (this.isNew) {
      return (this.model.projectId as string).startsWith("S")
        ? this.model.projectId
        : `S${this.model.projectId}`;
    }

    return this.model.projectId;
  }

  protected categoryName(id: string) {
    const category = this.categories
      ? this.categories.find((e) => e.id === id)
      : null;
    return category ? category.name : "";
  }

  protected categoryId(name: string, isRoot = true) {
    const category = this.categories
      ? this.categories.find(
          (e) =>
            (isRoot
              ? e.service_category_id === null
              : e.service_category_id !== null) && e.name === name
        )
      : null;

    return category ? category.id : "-1";
  }

  protected async storeExtraFields(origin: string) {
    return await this.transactionService.perform(() =>
      this.cellaService.writeCustomFields({
        CustomField: [
          {
            Entity: "0010",
            Origin: origin,
            LineId: 1,
            Code: "TRA-111",
            SerialNumber: 1,
            Contents: this.categoryName(this.model.primaryCategoryId),
          },
          {
            Entity: "0010",
            Origin: origin,
            LineId: 1,
            Code: "TRA-112",
            SerialNumber: 1,
            Contents: this.categoryName(this.model.secondaryCategoryId),
          },
          ...(this.model.memo
            ? [
                {
                  Entity: "0010",
                  Origin: origin,
                  LineId: 1,
                  Code: "TRA-122",
                  SerialNumber: 1,
                  Contents: this.model.memo,
                },
              ]
            : []),
        ],
      })
    );
  }

  protected origin(id: string) {
    return `bdr:920¡dst:80-10¡dnr:${id}¡rnr:0`;
  }

  protected async fetch() {
    const response = await this.apollo
      .query<{
        categories: any[];
      }>({
        query: serviceTicketDialogCategoriesQuery,
      })
      .toPromise();

    if (!response.errors) {
      this.categories = response.data.categories.map(
        (e) => new ServiceCategory(e)
      );
    }
  }
}

class ServiceCategory {
  id: string;
  service_category_id: string;
  name: string;

  constructor(body: any) {
    Object.assign(this, body);

    this.id = `${this.id}`;
    this.service_category_id = this.service_category_id
      ? `${this.service_category_id}`
      : null;
  }
}

export class ServiceTicketDialogComponentData {
  constructor(
    readonly userId: string = null,
    readonly projectId: string = null,
    readonly model?: ServiceItem
  ) {}
}
