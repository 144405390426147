import { Component, Inject } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly config: ConfirmDialogComponentConfig
  ) {
    this.config = config || new ConfirmDialogComponentConfig();
  }
}

interface DescriptionLine {
  title: string;
  description: string;
}

export class ConfirmDialogComponentConfig {
  title = "Weet je dit zeker?";
  declineText?: string;
  acceptText?: string;
  description?: string;
  lines?: DescriptionLine[];

  static withDescription(description: string) {
    const candidate = new ConfirmDialogComponentConfig();
    candidate.description = description;
    return candidate;
  }
}
