import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";
import { AppNotificationSchedule } from "../app-notification-schedules/app-notification-schedule.entity";

export class AppNotificationScheduleResource extends Entity {
  type = "app.notification_schedule_resources";

  schedule: AppNotificationSchedule;

  set __schedule__(value: AppNotificationSchedule) {
    this.schedule = this.entityManager
      .get(AppNotificationSchedule)
      .healOneResponse(new RestResponse(value)).value;
  }

  schedule_at: Date;
}
