import { Component, Inject, OnInit } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";

@Component({
  selector: "app-select-dialog",
  templateUrl: "./select-dialog.component.html",
  styleUrls: ["./select-dialog.component.scss"],
})
export class SelectDialogComponent implements OnInit {
  isAllCheckedModel = false;

  protected get isAnyChecked() {
    return !!this.data.items.find((e) => e.isChecked);
  }

  protected get isAllChecked() {
    return this.data.items.every((e) => e.isChecked);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: SelectDialogComponentData,
    protected readonly dialog: MdcDialogRef<SelectDialogComponent>
  ) {}

  ngOnInit() {
    this.validateAllChecked();
  }

  onSelect(item: SelectDialogItem) {
    if (!this.data.isMultiple) {
      this.data.items.forEach((e) => (e.isChecked = e === item));
    }

    this.validateAllChecked();
  }

  toggleAll() {
    this.data.items.forEach((e) => (e.isChecked = this.isAllCheckedModel));
  }

  async save() {
    this.dialog.close(this.data.items);
  }

  protected validateAllChecked() {
    this.isAllCheckedModel = this.isAllChecked;
  }
}

export interface SelectDialogItem {
  id?: string;
  description?: string;
  label?: string;
  isChecked: boolean;
}

export interface SelectDialogComponentData {
  disableSelectAll?: boolean;
  isMultiple: boolean;
  items: SelectDialogItem[];
}
