import { TextField } from "@material-ui/core";
import * as React from "react";
import { DeliveryListBloc } from "./delivery-list.bloc";
import { DeliveryListKeyAmountUpdateEvent } from "./delivery-list.event";
import { DeliveryListLoadSuccessState } from "./delivery-list.state";

export interface KeyAmountComponentProps {
    bloc: DeliveryListBloc;
    keyAmount: number;
    disabled?: boolean;
    style?: React.CSSProperties;
}

export const KeyAmountComponent: React.FC<KeyAmountComponentProps> = ({
    bloc,
    keyAmount,
    disabled,
}) => {
    const [value, setValue] = React.useState<string>(
        `${keyAmount || ""}`
    );

    return (
        <TextField
            style={{
                margin: 4,
                width: '100%',
                paddingRight: 8,
            }}
            disabled={disabled}
            variant="outlined"
            type="number"
            label={`Afgeleverde sleutels`}
            value={value}
            onBlur={() => bloc.add(new DeliveryListKeyAmountUpdateEvent({ keyAmount: parseFloat(value) }))}
            onChange={(event) => setValue(event.target.value)}
        />
    );
};
