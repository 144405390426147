import * as moment from "moment";
import { BlockedCalendarWeek } from "../blocked-calendar-weeks-dialog/blocked-calendar-week.entity";
import { YearPlanningLine } from "./year-planning-line.entity";

export class YearPlanningLineCalculator {
  static WEEK_FORMAT = "GGGG-WW";

  constructor(
    protected readonly blockedWeeks: BlockedCalendarWeek[],
    protected readonly baseWeek = moment().startOf("week")
  ) {}

  next(line: YearPlanningLine) {
    let cursor: moment.Moment = null,
      increment = 1;

    for (let i = 0; i < line.weeks; ) {
      cursor = this.baseWeek.clone().add(increment++, "w");
      const cursorId = cursor.format(YearPlanningLineCalculator.WEEK_FORMAT);

      if (!this.isBlocked(cursorId)) {
        i++;
      }
    }

    return cursor
      ? cursor.format(YearPlanningLineCalculator.WEEK_FORMAT)
      : null;
  }

  map(lines: YearPlanningLine[]) {
    const map = new Map<string, YearPlanningLine>();

    for (const line of lines) {
      const week = this.next(line);

      if (week) {
        map.set(week, line);
      }
    }

    return map;
  }

  protected isBlocked(id: string) {
    return !!this.blockedWeeks.find((e) => e.id === id);
  }
}
