import { Injectable, ChangeDetectorRef } from "@angular/core";
import { MdcDialog, MdcDialogConfig } from "@angular-mdc/web";
import { ComponentType } from "@angular/cdk/portal";
import {
  ConfirmDialogComponent,
  ConfirmDialogComponentConfig,
} from "./confirm-dialog/confirm-dialog.component";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(public readonly mdcDialog: MdcDialog) {}

  async openStateless<T, D, R>(
    ref: ComponentType<T>,
    config: MdcDialogConfig<D> = {}
  ) {
    return this.open<T, D, R>(null, ref, config);
  }

  async open<T, D, R>(
    changeDetection: ChangeDetectorRef,
    ref: ComponentType<T>,
    config: MdcDialogConfig<D>
  ) {
    return new Promise<R>((resolve) => {
      if (changeDetection) {
        changeDetection.detach();
      }
      config.autoFocus = false;

      const dialog = this.mdcDialog.open<T, D, R>(ref, config);
      dialog.afterOpened().subscribe(() => this.fixRipples());
      const onClosed = dialog.afterClosed();

      onClosed.subscribe((data) => resolve(data));

      if (changeDetection) {
        onClosed.subscribe(() => changeDetection.reattach());
      }
    });
  }

  async confirm(
    changeDetection: ChangeDetectorRef,
    data?: ConfirmDialogComponentConfig
  ) {
    return (
      (await this.open(changeDetection, ConfirmDialogComponent, {
        data: data || null,
      })) === "accept"
    );
  }

  protected fixRipples() {
    window.dispatchEvent(new Event("resize"));
  }
}
