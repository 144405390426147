import { MdcTabActivatedEvent } from "@angular-mdc/web";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as _ from "lodash";
import { compact, first, last, upperFirst } from "lodash";
import { DeviceDetectorService } from "ngx-device-detector";
import { CellaService } from "../cella.service";
import { DialogService } from "../dialog.service";
import { EntityManager } from "../entity.service";
import {
  MetacomRelationContactDialogComponent,
  MetacomRelationContactDialogComponentData,
  RelationContactType
} from "../metacom-relation-contact-dialog/metacom-relation-contact-dialog.component";
import { MetacomService } from "../metacom.service";
import { Project } from "../project/project.entity";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { grants } from "./../app-grant-config";
import { GrantService } from "./../grant.service";

@Component({
  selector: "app-metacom-project-agreement",
  templateUrl: "./metacom-project-agreement.component.html",
  styleUrls: ["./metacom-project-agreement.component.scss"],
})
export class MetacomProjectAgreementComponent implements OnInit {
  static PREFIX_GROUP = "c_";
  static PREFIX_TITLE = "b_";
  static PREFIX_VALUE = "a_";

  readonly stairHeightItems = [
    {
      name: "Begane grond",
      items: [
        {
          id: "PRJ-051a__1",
          title: "Verdeelhoogte",
          description:
            "Dekvloer naar dekvloer. (niet verrekenen met afwerkvloeren)",
        },
        {
          id: "PRJ-051a__7",
          title: "Trapgat lengte",
          description:
            "Looprichting trap! Betwonwand tot raveelijzer, strakke maat.",
        },
        {
          id: "PRJ-051a__8",
          title: "Trapgat breedte",
          description: "De kleinste maat tussen beton of fermacell wand.",
        },
      ],
    },
    {
      name: "Eerste verdieping (Betonverdieping GH1 en GH2)",
      items: [
        {
          id: "PRJ-051a__2",
          title: "Verdeelhoogte",
          description: "Dekvloer tot dekvloer.",
        },
        {
          id: "PRJ-051a__9",
          title: "Trapgat lengte",
          description:
            "Looprichting trap! Betwonwand tot raveelijzer, strakke maat.",
        },
        {
          id: "PRJ-051a__10",
          title: "Trapgat breedte",
          description: "De kleinste maat tussen beton of fermacell wand.",
        },
        // {
        //   id: "PRJ-051a__6",
        //   title: "Dekvloer 1e tot dekvloer bordes",
        //   description: "",
        // },
      ],
    },
    {
      name: "Eerste verdieping (HSB verdieping)",
      items: [
        {
          id: "PRJ-051a__3",
          title: "Verdeelhoogte",
          description: "Dekvloer tot houtenvloer.",
        },
        {
          id: "PRJ-051a__13",
          title: "Trapgat lengte",
          description: "Tussen de fermacel wanden.",
        },
        {
          id: "PRJ-051a__14",
          title: "Trapgat breedte",
          description: "Tussen de fermacel wanden.",
        },
      ],
    },
    {
      name: "Bijgebouw",
      items: [
        {
          id: "PRJ-051a__5",
          title: "Verdeelhoogte begane grond",
          description:
            "Dekvloer naar dekvloer (niet verrekenen met afwerkvloeren).",
        },
        {
          id: "PRJ-051a__11",
          title: "Trapgat lengte",
          description:
            "Looprichting trap! Betonwand tot raveelijzer, strakke maat.",
        },
        {
          id: "PRJ-051a__12",
          title: "Trapgat breedte",
          description: "De kleinste maat tussen beton of fermacell wand.",
        },
      ],
    },
    {
      name: "Kelder",
      items: [
        {
          id: "PRJ-051a__4",
          title: "Verdeelhoogte",
          description:
            "Dekvloer tot dekvloer (niet verrekenen met afwerkvloeren)",
        },
      ],
    },
  ];

  readonly UNSET_FIELDS = ["_rowid", "Entiteit", "Herkomst", "Volgnummer"];

  activeTabId = undefined;
  viewMode: "mobile" | "desktop" = "desktop";

  title: string;
  response: RestResponse<AgreementLine[]>;
  responseProject: RestResponse<Project>;
  responseStairs: RestResponse<any[]>;
  responseSpaces: RestResponse<any[]>;
  responseDoors: RestResponse<any[]>;
  responseStairHeights: RestResponse<any[]>;

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get regionId() {
    return (
      this.responseProject &&
      this.responseProject.value &&
      this.responseProject.value.regionId
    );
  }

  protected get projectService() {
    return this.entities.get(Project);
  }

  get hideTabs() {
    return compact(
      (
        this.grantService.var<string>(
          grants.metacom_project_agreement.hide_tabs,
          undefined
        ) ?? ""
      )
        .toLowerCase()
        .split(",")
    ).map((tabId) => tabId.trim());
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly grantService: GrantService,
    protected readonly metacomService: MetacomService,
    protected readonly deviceDetectorService: DeviceDetectorService,
    protected readonly cella: CellaService,
    protected readonly entities: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactionService: TransactionService
  ) {}

  isTabVisible(id: string) {
    return !this.hideTabs.includes(id && id.toLowerCase());
  }

  async ngOnInit() {
    this.viewMode = this.deviceDetectorService.isDesktop()
      ? "desktop"
      : "mobile";

    await this.fetchProject();

    this.fetch();
    this.fetchStairs();
    this.fetchStairHeights();
    this.fetchSpaces();
    this.fetchDoors();
  }

  openContacts(type: string) {
    if (this.responseProject && !this.responseProject.hasError()) {
      return this.dialogs.open(
        this.changeDetector,
        MetacomRelationContactDialogComponent,
        {
          data: new MetacomRelationContactDialogComponentData(
            type as RelationContactType,
            this.responseProject.value.userId
          ),
        }
      );
    }
  }

  activateTab(event: MdcTabActivatedEvent) {
    this.activeTabId = event.tab.id;
  }

  fields(item) {
    return Object.keys(item).map((k) => ({
      key: this.columnName(k),
      value: item[k] || "-",
    }));
  }

  columns(items: any[]) {
    return this.fields(first(items) || {}).map((e) => e.key);
  }

  columnName(original: string) {
    return upperFirst(original.replace(/_/g, " "));
  }

  async saveStairHeights() {
    const fields = this.responseStairHeights.value.map((e) => {
      const keySplit = (e.key as string).split("__");

      return {
        Entity: "0401",
        Origin: `bdr:920¡adm:${this.regionId}¡prj:${this.projectId}`,
        LineId: 1,
        Code: first(keySplit),
        SerialNumber: parseFloat(last(keySplit)),
        Contents: e.value,
      };
    });

    const response = await this.transactionService.perform(() =>
      this.cella.writeCustomFields({
        CustomField: fields,
      })
    );

    if (!response.hasError()) {
      await this.cella.sendEmail({
        from: {
          address: "info@groothuisbouw.info",
          name: "Groothuisbouw Emmeloord",
        },
        to: "r.koopmans@devriestrappen.nl",
        subject: `Verdeelhoogtes ${this.responseProject.value.description} - ${this.projectId}`,
        html: `Geachte relatie,<br /><br />
      Bij het project ${this.responseProject.value.description} - ${this.projectId} zijn de verdeelhoogtes ingemeten.<br />
      Deze kunt u op onze portal terug vinden ${location.origin}/#/office/metacom-project-agreement/${this.projectId}<br /><br />
      
      Indien u vragen over de verdeelhoogtes heeft kunt u contact opnemen met onze collega’s van de projectbegeleiding.<br /><br />
      
      Met vriendelijke groet,<br /><br />
      
      Team Groothuisbouw`,
      });
    }
  }

  protected async fetchProject() {
    this.responseProject = await this.projectService.findOne(this.projectId);
  }

  protected async fetch() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "project_afspraken",
      filter: `prj_prj.prj = "${this.projectId}" OR prj_prj.prj = "S${this.projectId}"`,
    });

    if (!response.hasError()) {
      const agreement = _.first(response.value);

      if (agreement) {
        this.response = new RestResponse(this.makeLines(agreement));
        this.title = `voor project ${agreement.c_project} - ${agreement.a_omschrijving}`;
      }
    }
  }

  protected async fetchStairs() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "afspraken_trappen",
      filter: `herkomst = 'bdr:920¡adm:${this.regionId}¡prj:${this.projectId}'`,
    });

    if (!response.hasError()) {
      this.responseStairs = this.unsetFields(response.value);
    }
  }

  protected async fetchStairHeights() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "project_wvb",
      filter: `prj_prj.prj = "${this.projectId}"`,
    });

    if (!response.hasError()) {
      const row = first(response.value);
      this.responseStairHeights = new RestResponse(
        row
          ? Object.keys(row)
              .filter((k) => k.startsWith("PRJ-"))
              .map((key) => ({
                key,
                value: row[key],
              }))
          : []
      );
    }
  }

  protected async fetchSpaces() {
    const response =
      (await this.fetchSpacesTable("afspraken_ruimtes_nw")) ||
      (await this.fetchSpacesTable("afspraken_ruimtes"));

    if (response) {
      this.responseSpaces = this.unsetFields(response.value);
    }
  }

  protected async fetchSpacesTable(tableName: string) {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName,
      filter: `herkomst = 'bdr:920¡adm:${this.regionId}¡prj:${this.projectId}'`,
    });

    return !response.hasError() && response.value.length > 0 ? response : null;
  }

  protected async fetchDoors() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "afspraken_deuren",
      filter: `herkomst = 'bdr:920¡adm:${this.regionId}¡prj:${this.projectId}'`,
    });

    if (!response.hasError()) {
      this.responseDoors = this.unsetFields(response.value);
    }
  }

  protected makeLines(data: any) {
    const keys = Object.keys(data),
      lines = [];

    for (const key of keys) {
      const prefix = key.substr(0, 2),
        name = _.upperFirst(key.replace(prefix, "").replace(/_/g, " ")),
        value = data[key];

      switch (prefix) {
        case MetacomProjectAgreementComponent.PREFIX_GROUP:
          lines.push(new AgreementLine(name, value, "group"));
          break;
        case MetacomProjectAgreementComponent.PREFIX_TITLE:
          lines.push(new AgreementLine(name, value, "title"));
          break;
        case MetacomProjectAgreementComponent.PREFIX_VALUE:
          lines.push(new AgreementLine(name, value, "value"));
          break;
      }
    }

    return lines;
  }

  getStairHeightField(key: string) {
    const match = this.responseStairHeights.value.find(
      (_item) => _item.key === key
    );

    return match || { value: "0" };
  }

  protected unsetFields(data: any[]) {
    data.forEach((d) => {
      for (const unset of this.UNSET_FIELDS) {
        delete d[unset];
      }
    });

    return new RestResponse(data);
  }
}

class AgreementLine {
  constructor(
    readonly name: string,
    readonly value: string,
    readonly type: string
  ) {}
}
