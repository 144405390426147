import { Component, Inject } from "@angular/core";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { AppPhaseSection } from "../app-phases/app-phase.entity";

@Component({
  selector: "app-app-phase-section-dialog",
  templateUrl: "./app-phase-section-dialog.component.html",
  styleUrls: ["./app-phase-section-dialog.component.scss"],
})
export class AppPhaseSectionDialogComponent extends AppCrudDialogBoilerplate<
  AppPhaseSection
> {
  get canSave() {
    return (
      !!this.data.model.name &&
      this.data.model.percentage !== null &&
      this.data.model.percentage !== undefined
    );
  }

  contentFilter = {
    allowNothing: true,
    title: "Informatie Pagina",
    icon: "pageview",
    entityName: "app.contents",
    nameField: "id",
    descriptionField: "title",
    sortField: "title",
    sortDirection: "ASC",
    filterFields: ["title", "html"],
  };

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppPhaseSection>,
    protected readonly dialog: MdcDialogRef<AppPhaseSectionDialogComponent>
  ) {
    super(data, dialog);
  }
}
