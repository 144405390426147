import { Equatable } from "@comceptum-software/react-bloc";

export abstract class ConstructionPlanningEvent extends Equatable {}

export enum ConstructionPlanningFilterValue {
  AllValues = "all",
  EmptyValues = "empty",
  Values = "values",
}

export interface ConstructionPlanningFilter {
  readonly fieldFilters?: { [key: string]: string };
  readonly dateFilters: { [key: string]: ConstructionPlanningFilterValue };
}

export class ConstructionPlanningLoadEvent extends ConstructionPlanningEvent {
  readonly filter: ConstructionPlanningFilter;

  constructor(props: { readonly filter: ConstructionPlanningFilter }) {
    super(props);
  }

  props = () => [this.filter];
}

export class ConstructionPlanningHighlightProjectEvent extends ConstructionPlanningEvent {
  readonly projectId: string;

  constructor(props: { readonly projectId: string }) {
    super(props);
  }

  props = () => [this.projectId];
}

export class ConstructionPlanningReloadEvent extends ConstructionPlanningEvent {
  props = () => [];
}
