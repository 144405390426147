import { Component, OnInit } from "@angular/core";
import { RestResponse } from "../rest.service";
import { EntityManager, Ops } from "../entity.service";
import { ActivatedRoute } from "@angular/router";
import { AppNotificationScheduleResource } from "./app-notification-schedule-resource.entity";
import { UrlOpenService } from "../url-open.service";
import { AppProject } from "../app-project-costs/app-project.entity";
import { AppUser } from "../app-users/app-user.entity";
import { UserNotification } from "../notification/user-notification.entity";
import { AppNotification } from "./app-notification.entity";
import { not } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-app-notification-resources",
  templateUrl: "./app-notification-resources.component.html",
  styleUrls: ["./app-notification-resources.component.scss"],
})
export class AppNotificationResourcesComponent implements OnInit {
  response: RestResponse<AppNotificationScheduleResource[]>;
  responseProject: RestResponse<AppProject>;

  protected get scheduleService() {
    return this.entityManager.get(AppNotificationScheduleResource);
  }

  protected get projectService() {
    return this.entityManager.get(AppProject);
  }

  protected get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  selectedUser: AppUser;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly entityManager: EntityManager,
    protected readonly openUrls: UrlOpenService
  ) {}

  notificationHasPage(notification: AppNotification) {
    return (
      notification.data &&
      notification.data.data &&
      notification.data.data.route_page === "content-view" &&
      notification.data.data.route_params &&
      notification.data.data.route_params.id
    );
  }

  openInfoPageForNotification(notification: AppNotification) {
    if (this.notificationHasPage(notification)) {
      this.openUrls.openNew({
        url: `#/app/content-preview/${notification.data.data.route_params.id}`,
        forceNewTab: true,
      });
    }
  }

  openInfoPage(resource: AppNotificationScheduleResource) {
    const id = resource.schedule.notification.content_id;

    if (id) {
      this.openUrls.openNew({
        url: `#/app/content-preview/${id}`,
        forceNewTab: true,
      });
    }
  }

  get notifications() {
    return this.selectedUser
      ? this.selectedUser.notifications.filter(
          (notification) => !!notification.data.subject
        )
      : [];
  }

  async ngOnInit() {
    this.response = await this.scheduleService.query({
      filters: [Ops.Field("project_id").Equals(this.projectId)],
      orders: [
        {
          field: "schedule_at",
          direction: "ASC",
        },
      ],
      relations: ["schedule", "schedule.notification"],
    });

    this.responseProject = await this.projectService.queryFirst({
      filters: [Ops.Field("id").Equals(this.projectId)],
      relations: ["users", "users.notifications"],
    });
  }
}

export interface AppNotificationScheduleResourceDialogComponentData {
  projectId: string;
}
