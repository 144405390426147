import { BehaviorSubject, Subject } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

export abstract class Bloc<TEvent, TState> {
  events: Subject<TEvent>;
  state: BehaviorSubject<TState>;

  constructor(initial: TState) {
    this.events = new Subject<TEvent>();
    this.events.subscribe((event) => this.processEvent(event));

    this.state = new BehaviorSubject<TState>(initial);
  }

  abstract mapEventToState(event: TEvent): AsyncIterable<TState>;

  add(event: TEvent) {
    this.events.next(event);

    return this;
  }

  stateOf<T extends TState>() {
    return this.state.value as T;
  }

  eventOf<T extends TEvent>(ctor: { new (...args): T }) {
    return this.events.pipe(
      filter((event) => event instanceof ctor),
      map((event) => event as T)
    );
  }

  protected async processEvent(event: TEvent) {
    const states = this.mapEventToState(event);

    if (!environment.production) {
      console.debug({ event });
    }

    for await (const item of states) {
      this.state.next(item);

      if (!environment.production) {
        console.debug({ event, item });
      }
    }
  }
}
