import * as React from "react";
import { ReactComponentProps } from "../react-component/react.component";
import { useState, useEffect } from "react";
import { WorkActionTemplatesComponent } from "./work-action-templates.component";
import {
  Container,
  Grid,
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionActions,
  Button,
  TextField,
  FormControl,
  Snackbar,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { EntitySelect } from "../entity-select/entity-select";
import { TreeView, TreeItem } from "@material-ui/lab";
import {
  ExpandMore as ExpandMoreIcon,
  ViewWeekOutlined as ViewWeekOutlinedIcon,
  AddOutlined as AddOutlinedIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import {
  WorkActionTemplateGroup,
  WorkActionTemplate,
} from "./work-action-template.entity";
import { orderBy } from "lodash";
import { DateDefinition } from "../year-planning/entities/date-entities";

export interface WorkActionTemplatesProps
  extends ReactComponentProps<WorkActionTemplatesComponent> {}

export const WorkActionTemplates: React.FC<WorkActionTemplatesProps> = ({
  component,
}) => {
  const { entities } = component;
  const [groups, setGroups] = useState<WorkActionTemplateGroup[]>();
  const [selectedGroup, setSelectedGroup] = useState<WorkActionTemplateGroup>();

  const [template, setTemplate] = useState<WorkActionTemplate>();
  const [templates, setTemplates] = useState<WorkActionTemplate[]>();
  const [expanded, setExpanded] = useState<string>();

  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [trashDialog, setTrashDialog] = useState<boolean>(false);

  const [groupTrashDialog, setGroupTrashDialog] = useState<boolean>(false);
  const [groupDialog, setGroupDialog] = useState<boolean>(false);
  const [groupDialogText, setGroupDialogText] = useState<string>("");

  const repo = entities.get(WorkActionTemplateGroup);
  const repoTemplates = entities.get(WorkActionTemplate);

  const fetch = async () => {
    const response = await repo.query({
      relations: ["workActionTemplates"],
    });

    if (!response.hasError()) {
      setGroups(response.value);
    }
  };

  const setTemplateValue = (templates: WorkActionTemplate[]) => {
    setTemplates(orderBy(templates, (t) => t.targetDateOffset, "asc"));
  };

  const setAccordion = (templateIn) => (event, newExpanded) => {
    if (!template || !templateIn) {
      setExpanded(newExpanded ? (templateIn ? templateIn.id : true) : false);
      setTemplate(templateIn);
    }
  };

  const selectGroup = (group: WorkActionTemplateGroup) => {
    setSelectedGroup(group);
    setTemplateValue(group.workActionTemplates);
    setAccordion(null)(null, false);
  };

  const store = async () => {
    if (template.id === "concept") {
      delete template.id;
    }

    const response = await repoTemplates.save(template);

    if (!response.hasError()) {
      await fetch();

      const templatesWithout = templates.filter(
        (t) => t.id !== response.value.id && !t.isConcept
      );

      response.value.isConcept = false;

      setTemplateValue([...templatesWithout, response.value]);
      setAccordion(null)(null, false);

      setSnackbar(true);
    }
  };

  const undo = () => {
    if (template.isConcept) {
      const withoutConcept = templates.filter((t) => t.id !== "concept");
      setTemplateValue([...withoutConcept]);
      setAccordion(null)(null, false);
    } else {
      setAccordion(null)(null, false);
    }
  };

  const trash = async () => {
    if (!template.isConcept) {
      const response = await repoTemplates.delete(template.id);

      if (!response.hasError()) {
        setSnackbar(true);
      }
    }

    await fetch();

    const withoutSelected = templates.filter((t) => t.id !== template.id);
    setTemplateValue([...withoutSelected]);
    setAccordion(null)(null, false);
  };

  const trashGroup = async () => {
    const response = await repo.delete(selectedGroup.id);

    if (!response.hasError()) {
      setSnackbar(true);

      await fetch();

      setSelectedGroup(null);
      setAccordion(null)(null, false);
    }
  };

  const addNew = () => {
    const concept = repoTemplates.concept({
      id: "concept",
      title: "",
      description: "",
      targetDateOffset: 0,
      workActionTemplateGroupId: selectedGroup.id,
    });
    setTemplateValue([...templates, concept]);
    setAccordion(concept)(null, true);
  };

  const addNewGroup = async () => {
    const response = await repo.save(
      repo.concept({
        name: groupDialogText,
      })
    );

    if (!response.hasError()) {
      response.value.__workActionTemplates__ = [];

      setGroups([...groups, response.value]);
      setGroupDialog(false);
      setGroupDialogText("");

      setSnackbar(true);
    }
  };

  const canSave = (item: WorkActionTemplate) => {
    return (
      item && item.title && item.description && item.targetDateOffset != null
    );
  };

  useEffect(() => {
    fetch();
  }, [component]);

  return (
    <React.Fragment>
      {groups && (
        <Container style={{ paddingTop: 16 }}>
          <Paper style={{ padding: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={4} style={{ borderRight: "1px solid #eeeeee" }}>
                <Typography color="textSecondary" gutterBottom>
                  Groepen
                </Typography>
                <TreeView
                  style={{ padding: 10 }}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {groups.map((group) => (
                    <React.Fragment key={group.id}>
                      <TreeItem
                        icon={<ViewWeekOutlinedIcon />}
                        nodeId={group.id}
                        label={group.name}
                        onClick={() => selectGroup(group)}
                      ></TreeItem>
                    </React.Fragment>
                  ))}
                </TreeView>

                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    marginTop: 16,
                  }}
                  startIcon={<AddOutlinedIcon />}
                  disabled={!!template}
                  onClick={() => setGroupDialog(true)}
                >
                  Toevoegen
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    marginTop: 16,
                    marginLeft: 16,
                  }}
                  disabled={!selectedGroup}
                  onClick={() => setGroupTrashDialog(true)}
                >
                  Verwijderen
                </Button>
              </Grid>
              {selectedGroup && templates && (
                <Grid item xs={8}>
                  <Typography color="textSecondary" gutterBottom>
                    Geselecteerd: <strong>{selectedGroup.name}</strong>
                  </Typography>

                  {templates.map((templateItem) => (
                    <Accordion
                      key={templateItem.id}
                      expanded={expanded === templateItem.id}
                      onChange={setAccordion(templateItem)}
                    >
                      <AccordionSummary id={templateItem.id}>
                        <Typography
                          style={{
                            flexBasis: "33.33%",
                            flexShrink: 0,
                          }}
                        >
                          {templateItem.title}
                        </Typography>
                        <Typography style={{ opacity: 0.6 }}>
                          ({templateItem.targetDateOffset >= 0 ? "+" : ""}
                          {templateItem.targetDateOffset})
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {template && (
                          <React.Fragment>
                            <FormControl fullWidth>
                              <TextField
                                label="Titel"
                                variant="outlined"
                                value={template.title}
                                onChange={(event) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      title: event.target.value,
                                    })
                                  );
                                }}
                                style={{ marginBottom: 16 }}
                              />
                              <TextField
                                label="Omschrijving"
                                variant="outlined"
                                multiline
                                value={template.description}
                                onChange={(event) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      description: event.target.value,
                                    })
                                  );
                                }}
                                style={{ marginBottom: 16 }}
                                rows={4}
                              />
                              <TextField
                                label="Streefdatum dag(en) relatief"
                                variant="outlined"
                                value={template.targetDateOffset}
                                onChange={(event) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      targetDateOffset: event.target.value,
                                    })
                                  );
                                }}
                                type="number"
                              />
                              <EntitySelect
                                style={{ width: "100%", marginTop: 16 }}
                                title="Categorie"
                                type="work_action_categories"
                                labelSelector="description"
                                searchFields={["description"]}
                                entityId={template.workActionCategoryId}
                                restService={component.restService}
                                onSelect={(category) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      __workActionCategory__: category,
                                      workActionCategoryId: category
                                        ? category.id
                                        : null,
                                    })
                                  );
                                }}
                              />

                              {/* <EntitySelect
                                style={{ width: "100%", marginTop: 16 }}
                                title="(Jaarplanning) uitvoerende doorzetten als verantwoordelijke voor kolom:"
                                type="date_groups"
                                labelSelector="label"
                                entityId={template.responsibleDateGroupId}
                                restService={component.restService}
                                onSelect={(group) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      responsibleDateGroupId: group
                                        ? group.id
                                        : null,
                                    })
                                  );
                                }}
                              /> */}

                              <EntitySelect
                                style={{ width: "100%", marginTop: 16 }}
                                title="(Jaarplanning) voortgang doorzetten voor kolom-datum:"
                                type="dates"
                                relations={["dateGroup"]}
                                labelSelector={(date: DateDefinition) =>
                                  `(${date.__dateGroup__.description}) ${date.description}`
                                }
                                searchFields={["description"]}
                                entityId={template.advancementApplyDateId}
                                restService={component.restService}
                                onSelect={(date) => {
                                  setTemplate(
                                    repoTemplates.extend(template, {
                                      advancementApplyDateId: date
                                        ? date.id
                                        : null,
                                    })
                                  );
                                }}
                              />

                              <FormControlLabel
                                style={{ marginTop: 16 }}
                                control={
                                  <Checkbox
                                    checked={!template.disableImageUpload}
                                    color="default"
                                    onChange={(event) =>
                                      setTemplate(
                                        repoTemplates.extend(template, {
                                          disableImageUpload: !event.target
                                            .checked,
                                        })
                                      )
                                    }
                                    name="disableImageUpload"
                                  />
                                }
                                label="Afbeeldingen uploaden toestaan"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!template.allowEmptyAdvancement}
                                    color="default"
                                    onChange={(event) =>
                                      setTemplate(
                                        repoTemplates.extend(template, {
                                          allowEmptyAdvancement: !event.target
                                            .checked,
                                        })
                                      )
                                    }
                                    name="allowEmptyAdvancement"
                                  />
                                }
                                label="Gereed melden vereist omschrijving"
                              />
                            </FormControl>
                          </React.Fragment>
                        )}
                      </AccordionDetails>
                      <Divider />
                      <AccordionActions>
                        {!templateItem.isConcept && (
                          <Button
                            size="small"
                            onClick={() => setTrashDialog(true)}
                          >
                            Verwijder
                          </Button>
                        )}
                        <Button size="small" onClick={() => undo()}>
                          Ongedaan maken
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          disabled={!canSave(template)}
                          onClick={() => store()}
                        >
                          Opslaan
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  ))}

                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      marginTop: 16,
                    }}
                    startIcon={<AddOutlinedIcon />}
                    disabled={!!template}
                    onClick={() => addNew()}
                  >
                    Toevoegen
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Container>
      )}
      {!groups && <LinearProgress />}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
        message="Opgeslagen"
      />

      <Dialog
        open={trashDialog}
        onClose={() => setTrashDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Regel verwijderen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dit kan niet ongedaan worden gemaakt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTrashDialog(false)} color="primary">
            Nee
          </Button>
          <Button
            onClick={() => {
              setTrashDialog(false);
              trash();
            }}
            color="primary"
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={groupTrashDialog}
        onClose={() => setGroupTrashDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Groep verwijderen?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dit kan niet ongedaan worden gemaakt.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupTrashDialog(false)} color="primary">
            Nee
          </Button>
          <Button
            onClick={() => {
              setGroupTrashDialog(false);
              trashGroup();
            }}
            color="primary"
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={groupDialog}
        onClose={() => setGroupDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nieuwe groep toevoegen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Naam"
            type="text"
            value={groupDialogText}
            onChange={(event) => setGroupDialogText(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupDialog(false)} color="primary">
            Terug
          </Button>
          <Button
            onClick={() => addNewGroup()}
            color="primary"
            disabled={!groupDialogText.length}
          >
            Toevoegen
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
