import { HousePartGroup } from "./house-part.entity";
import * as _ from "lodash";
import { formatNumber } from "@angular/common";

export class CargoFunctions {
  static groupLength(group: HousePartGroup) {
    const value =
      _.chain(group.elements || [])
        .map((g) => (g.length ? parseFloat(g.length) : null) || 0)
        .max()
        .value() || 0;

    return formatNumber(value, "nl", "2.0-0");
  }

  static groupWidth(group: HousePartGroup) {
    const value =
      _.chain(group.elements || [])
        .map((g) => (g.width ? parseFloat(g.width) : null) || 0)
        .max()
        .value() || 0;

    return formatNumber(value, "nl", "2.0-0");
  }

  static groupWeight(group: HousePartGroup) {
    const value =
      _.chain(group.elements || [])
        .map((g) => (g.weight ? parseFloat(g.weight) : null) || 0)
        .sum()
        .value() || 0;

    return formatNumber(value, "nl", "2.0-0");
  }
}
