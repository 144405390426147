import { Component, Inject } from "@angular/core";
import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";

@Component({
  selector: "app-offers-score-dialog",
  templateUrl: "./offers-score-dialog.component.html",
  styleUrls: ["./offers-score-dialog.component.scss"],
})
export class OffersScoreDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: OffersScoreDialogComponentData,
    protected readonly dialog: MdcDialogRef<OffersScoreDialogComponent>
  ) {}

  async save() {
    this.dialog.close(this.data.score);
  }
}

export interface OffersScoreDialogComponentData {
  score: number;
}
