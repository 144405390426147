import { NgxZendeskWebwidgetConfig } from "ngx-zendesk-webwidget";

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  protected static accountUrlShared: string = "not-set";

  lazyLoad = true;

  get accountUrl() {
    return ZendeskConfig.accountUrlShared;
  }

  static set(accountUrl: string) {
    ZendeskConfig.accountUrlShared = accountUrl;
    return new ZendeskConfig();
  }

  callback(zE: any) {
    zE("webWidget", "setLocale", "nl");
    zE("webWidget", "helpCenter:setSuggestions", { url: true });
    zE("webWidget", "hide");

    zE("webWidget:on", "close", () => zE("webWidget", "hide"));
  }
}
