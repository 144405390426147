import { OrderableEntity } from "../entity.service";

export class AppProjectFieldGroupAssignmentValue extends OrderableEntity {
  type = "app.project_field_group_assignment_values";

  project_field_group_assignment_id: number;
  value: string;

  created_at?: Date;
  updated_at?: Date;

  isDeleted?: boolean;
}
