import { Equatable } from '@comceptum-software/react-bloc';
import { ServiceCategoryEntity } from '../delivery-list/delivery-list.entity';
import { CustomerQuestionDto } from './customer-question.dto';

export class CustomerQuestionsState extends Equatable {
  props = () => [];
}

export class CustomerQuestionsLoadingState extends CustomerQuestionsState {
}

export interface CustomerQuestionsFilter {
  dateFrom: Date;
  dateTo: Date;
  withCompleted: boolean;
}

export class CustomerQuestionsSuccessState extends CustomerQuestionsState {
  readonly version: string;
  readonly isLoading: boolean;

  readonly filter: CustomerQuestionsFilter;

  readonly categories: ServiceCategoryEntity[];
  readonly questions: CustomerQuestionDto[];

  readonly ticketComposerEnabled: boolean;
  readonly ticketComposer: CustomerQuestionDto;

  constructor(props: {
    readonly version?: string;
    readonly isLoading?: boolean;
    readonly filter: CustomerQuestionsFilter;
    readonly categories: ServiceCategoryEntity[];
    readonly questions: CustomerQuestionDto[];

    readonly ticketComposerEnabled?: boolean;
    readonly ticketComposer?: CustomerQuestionDto;
  }) {
    super(props);
  }

  props = () => [
    this.version,
    this.isLoading,
    this.filter,
    this.categories,
    this.questions,
    this.ticketComposerEnabled,
    this.ticketComposer,
  ];
}
