import { HttpClient } from "@angular/common/http";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";

export interface ImageViewerComponentProps {
  http: HttpClient;
  onCloseRequest: Function;
  images: ImageViewerComponentImage[];
  defaultIndex?: number;
}

export interface ImageViewerComponentImage {
  src: string;
  isSecure?: boolean;
}

export function ImageViewerComponent(props: ImageViewerComponentProps) {
  const [index, setIndex] = useState(props.defaultIndex ?? 0);
  const [loaded, setLoaded] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);

  async function fetchSecure(src: string) {
    try {
      const response = await props.http
        .get(src, { responseType: "blob" })
        .toPromise();

      return URL.createObjectURL(response);
    } catch (e) {
      return src;
    }
  }

  async function loadImages() {
    const images = await Promise.all(
      props.images.map(async (_image) =>
        _image.isSecure ? fetchSecure(_image.src) : _image.src
      )
    );

    setImageUrls(images);
  }

  useEffect(() => {
    setLoaded(false);

    loadImages().then(() => setLoaded(true));
  }, [props.images]);

  (window as any).global = window;

  return loaded ? (
    <Lightbox
      mainSrc={imageUrls[index]}
      nextSrc={imageUrls[(index + 1) % imageUrls.length]}
      prevSrc={imageUrls[(index + imageUrls.length - 1) % imageUrls.length]}
      onCloseRequest={() => props.onCloseRequest()}
      onMovePrevRequest={() =>
        setIndex((index + imageUrls.length - 1) % imageUrls.length)
      }
      onMoveNextRequest={() => setIndex((index + 1) % imageUrls.length)}
    />
  ) : (
    <Dialog open>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
}
