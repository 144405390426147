import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EntityManager } from '../entity.service';
import { HousePartGroup } from '../cargo/house-part.entity';
import { RestResponse } from '../rest.service';

@Component({
  selector: 'app-cargo-element-groups-dialog',
  templateUrl: './cargo-element-groups-dialog.component.html',
  styleUrls: ['./cargo-element-groups-dialog.component.scss']
})
export class CargoElementGroupsDialogComponent implements OnInit, AfterViewInit {

  response: RestResponse<HousePartGroup[]>;

  protected get housePartGroupService() {
    return this.entityManager.get(HousePartGroup);
  }

  constructor(protected readonly entityManager: EntityManager) { }

  ngOnInit() {
    this.fetchAsync();
  }

  ngAfterViewInit() {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 300);
  }

  protected async onToggle(group: HousePartGroup) {
    await this.housePartGroupService.modify(group);
  }

  protected async fetchAsync() {
    this.response = await this.housePartGroupService.query({});
  }
}
