import { MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { Component, Inject, OnInit } from "@angular/core";
import { first } from "lodash";
import * as moment from "moment";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { EntityManager, Ops } from "../entity.service";
import { GrantService } from "../grant.service";
import { Project } from "../project/project.entity";
import { RestResponse } from "../rest.service";
import { TransactionService } from "../transaction.service";
import { User } from "./../accessibility-users/user.entity";
import { MetacomService } from "./../metacom.service";
import { YearPlanningModification } from "./year-planning-modification.entity";

@Component({
  selector: "app-year-planning-modify-week-dialog",
  templateUrl: "./year-planning-modify-week-dialog.component.html",
  styleUrls: ["./year-planning-modify-week-dialog.component.scss"],
})
export class YearPlanningModifyWeekDialogComponent implements OnInit {
  model = {
    oldWeek: null,
    oldRegion: null,
    oldProjectLeaderId: null,
    oldProjectMentorId: null,
    notes: null,
  };

  regions: string[] = null;
  projectLeaders: User[] = null;
  projectMentors: User[] = null;
  response: RestResponse<YearPlanningModification[]>;

  protected get modificationService() {
    return this.entityManager.get(YearPlanningModification);
  }

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get userService() {
    return this.entityManager.get(User);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly project: Project,
    protected readonly authService: AuthService,
    protected readonly cellaService: CellaService,
    protected readonly entityManager: EntityManager,
    protected readonly transactions: TransactionService,
    protected readonly grantService: GrantService,
    protected readonly metacomService: MetacomService,
    protected readonly dialog: MdcDialogRef<YearPlanningModifyWeekDialogComponent>
  ) {
    this.model.oldWeek = project.buildingWeek;
    this.model.oldRegion = project.region;
    this.model.oldProjectLeaderId = project.projectLeaderId;
    this.model.oldProjectMentorId = project.projectMentorId;
  }

  get canEditBuildingWeeks() {
    return this.grantService.varIs(
      grants.year_planning.edit_building_weeks,
      "true"
    );
  }

  get canSave() {
    return (
      moment(this.project.buildingWeek, "GGGG-WW", true).isValid() &&
      !!this.project.region
    );
  }

  async ngOnInit() {
    await Promise.all([
      this.fetchModifications(),
      this.fetchRegions(),
      this.fetchProjectLeaders(),
      this.fetchProjectMentors(),
    ]);
  }

  async fetchModifications() {
    this.response = await this.modificationService.query({
      relations: ["user"],
      filters: [Ops.Field("projectId").Equals(this.project.id)],
      orders: [{ field: "createdAt", direction: "DESC" }],
    });
  }

  async fetchRegions() {
    const response = await this.metacomService.queryTableAsync<{
      regios: string;
    }>({
      setName: "metacom",
      tableName: "project_regio",
    });

    if (!response.hasError() && response.value && response.value.length > 0) {
      this.regions = first(response.value)
        .regios.split(",")
        .filter((region) => region.length > 0);
    }
  }

  async fetchProjectLeaders() {
    const response = await this.userService.query({
      filters: [{ field: "isProjectLeader", operator: "Equal", value: "true" }],
    });

    if (!response.hasError()) {
      this.projectLeaders = response.value;
    }
  }

  async fetchProjectMentors() {
    const response = await this.userService.query({
      filters: [{ field: "isProjectMentor", operator: "Equal", value: "true" }],
    });

    if (!response.hasError()) {
      this.projectMentors = response.value;
    }
  }

  async save() {
    if (this.canEditBuildingWeeks) {
      this.project.buildingWeek !== this.model.oldWeek &&
        (await this.saveBuildingWeek());

      this.project.region !== this.model.oldRegion && (await this.saveRegion());
      this.project.projectLeaderId !== this.model.oldProjectLeaderId &&
        (await this.saveProjectLeader());
      this.project.projectMentorId !== this.model.oldProjectMentorId &&
        (await this.saveProjectMentor());
    }

    return this.dialog.close("save");
  }

  async saveBuildingWeek() {
    return await this.transactions.perform(() =>
      this.sequence(
        this.storeToMtc("PRJ-181", this.project.buildingWeek),
        this.storeToDb("buildingWeek"),
        this.storeToDbMofication()
      )
    );
  }

  async saveRegion() {
    return await this.transactions.perform(() =>
      this.sequence(
        this.storeToMtc("PRJ-002", this.project.region),
        this.storeToDb("region")
      )
    );
  }

  async saveProjectLeader() {
    return await this.transactions.perform(() =>
      this.sequence(
        this.storeToMtc("PRJ-012", this.project.projectLeaderId),
        this.storeToDb("projectLeaderId")
      )
    );
  }

  async saveProjectMentor() {
    return await this.transactions.perform(() =>
      this.sequence(
        this.storeToMtc("PRJ-173", this.project.projectMentorId),
        this.storeToDb("projectMentorId")
      )
    );
  }

  protected storeToMtc(code: string, contents: string) {
    return this.cellaService.writeCustomFields({
      CustomField: [
        {
          Entity: "0401",
          Origin: `bdr:920¡adm:${this.project.regionId}¡prj:${this.project.id}`,
          LineId: 1,
          Code: code,
          SerialNumber: 1,
          Contents: contents,
        },
      ],
    });
  }

  protected storeToDb(field: string) {
    return this.projectService.save(this.project, ["id", field]);
  }

  protected storeToDbMofication() {
    return this.modificationService.save(
      this.modificationService.concept({
        userId: this.authService.user.id,
        projectId: this.project.id,
        oldWeek: this.model.oldWeek,
        notes: this.model.notes,
        newWeek: this.project.buildingWeek,
      })
    );
  }

  protected async sequence(...promises: Promise<RestResponse<{}>>[]) {
    for (const promise of promises) {
      const response = await promise;

      if (!response || response.hasError()) {
        return new RestResponse(false);
      }
    }

    return new RestResponse(true);
  }
}
