import { Injector } from "@angular/core";
import { useApolloMutation } from "src/app/react-component/react.hooks";
import { GraphQLTypes, InputType, ValueTypes, Zeus } from "src/app/zeus";
import gql from "graphql-tag";

export const useApolloZeusMutation = <
  Z extends ValueTypes[O],
  O extends "Mutation"
>(
  injector: Injector,
  mutation: Z | ValueTypes[O],
  variables: Record<string, any> = {},
  operationName: string = undefined,
  useMultipart = false
) => {
  return useApolloMutation<InputType<GraphQLTypes[O], Z>>(injector, {
    mutation: gql(Zeus("mutation", mutation, operationName)),
    variables,
    context: {
      useMultipart,
    },
  });
};
