import { WorkAction } from "./work-action.entity";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { EntityAdvancement } from "../offers/entity.advancement.entity";
import { UserNotification } from "../notification/user-notification.entity";
import { NotificationGuard } from "./notification-guard";

export class WorkActionCreator {
  protected get actionService() {
    return this.props.entities.get(WorkAction);
  }

  protected get advancementService() {
    return this.props.entities.get(EntityAdvancement);
  }

  constructor(readonly props: WorkActionCreatorProps) {}

  async save(model: WorkAction, sendNotification = false) {
    const isConcept = model.isConcept;

    if (isConcept) {
      delete model.id;
    }

    const response = await this.props.transactions.perform(() =>
      this.actionService.save(model)
    );

    if (!response.hasError()) {
      if (isConcept) {
        await this.props.transactions.perform(() =>
          this.advancementService.save(this.makeAdvancement(response.value))
        );
      }

      const action = response.value;
      const notifications = this.props.entities.get(UserNotification);

      if (sendNotification) {
        const guard = new NotificationGuard({
          userId: this.props.userId,
          notifications,
        });

        await guard.sendIfNotMe(
          notifications.concept({
            userId: action.assignedUserId,
            subject: `Toegekend aan actiepunt: (${action.projectId}) ${action.title}`,
            content: `(${action.projectId}) ${action.description}`,
            channels: ["notification"],
            url: `${location.origin}/#/office/work-actions/${action.id}`,
          })
        );
      }

      return action;
    }
  }

  protected makeAdvancement(model: WorkAction) {
    return this.advancementService.concept({
      entityType: model.entityType,
      entityId: model.id,
      comment: "Actiepunt aangemaakt.",
      userId: this.props.userId,
      date: model.aimDate,
      isCompleted: false,
    });
  }
}

interface WorkActionCreatorProps {
  userId: string;
  transactions: TransactionService;
  entities: EntityManager;
}
