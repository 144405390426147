import { Entity } from "src/app/entity.service";

export class StageEntity extends Entity {
  type = "service_planning_stages";

  name: string;
  colorCode: string;

  createdAt: Date;
}
