import {
  Component,
  OnInit,
  Inject,
  HostListener,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { AuditChecklistImage } from "../audit-checklist-images/audit-checklist-image.entity";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import { EntityManager } from "../entity.service";
import { environment } from "../../environments/environment";
import { RestService } from "../rest.service";
import { UserNotification } from "../notification/user-notification.entity";
import { UrlOpenService } from "../url-open.service";

@Component({
  selector: "app-audit-checklist-image-dialog",
  templateUrl: "./audit-checklist-image-dialog.component.html",
  styleUrls: ["./audit-checklist-image-dialog.component.scss"],
})
export class AuditChecklistImageDialogComponent implements OnInit {
  @ViewChild("anchor", { static: false }) anchor: ElementRef;

  protected get auditChecklistImageService() {
    return this.entityManager.get(AuditChecklistImage);
  }

  protected get userNotificationService() {
    return this.entityManager.get(UserNotification);
  }

  sendNotification: boolean;
  active: AuditChecklistImage;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AuditChecklistImageDialogComponentData,

    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly entityManager: EntityManager,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly dialog: MdcDialogRef<AuditChecklistImageDialogComponent>
  ) {}

  ngOnInit() {
    this.select(this.data.active);
  }

  @HostListener("document:keydown", ["$event"])
  onKeyPress(event: KeyboardEvent) {
    if (event.keyCode === 37) {
      this.back();
    }

    if (event.keyCode === 39) {
      this.forward();
    }
  }

  back() {
    const index = this.data.images.indexOf(this.active);

    if (index > 0) {
      this.select(this.data.images[index - 1]);
    } else {
      this.select(this.data.images[this.data.images.length - 1]);
    }
  }

  forward() {
    const index = this.data.images.indexOf(this.active);

    if (index < this.data.images.length - 1) {
      this.select(this.data.images[index + 1]);
    } else {
      this.select(this.data.images[0]);
    }
  }

  async rotate() {
    const response = await this.restService.post(
      `documents/rotate/${this.active.documentMetaId}`,
      {}
    );

    if (!response.hasError()) {
      this.active.imageUrl += `?i=${Math.random()}`;
      this.active.thumbUrl += `?i=${Math.random()}`;
    }
  }

  select(image: AuditChecklistImage) {
    if (image) {
      this.active = image;

      const index = this.data.images.indexOf(image);
      const element = document.getElementById(`image__${index}`);

      if (element) {
        element.scrollIntoView();
        document.querySelector(".wrapper").scrollTo({ top: 0 });
      }
    }
  }

  async save() {
    const response = await this.auditChecklistImageService.modifyMany(
      this.data.images
    );

    if (!response.hasError()) {
      if (this.sendNotification) {
        await this.sendNotificationToProjectLeader();
        this.sendNotification = false;
      }

      this.snackbar.open("Opgeslagen", "Ok", { leading: true });
      this.dialog.close("saved");
    }
  }

  async delete() {
    const response = await this.auditChecklistImageService.delete(
      this.active.id
    );

    const index = this.data.images.indexOf(this.active);
    this.data.images.splice(index, 1);

    if (this.data.images.length > 0) {
      this.forward();
    } else {
      this.dialog.close("close");
    }

    if (!response.hasError()) {
      this.snackbar.open("Verwjderd", "Ok", { leading: true });
    }
  }

  protected async sendNotificationToProjectLeader() {
    const project = await this.active.project;

    if (project && !project.hasError() && project.value.projectLeaderId) {
      await this.userNotificationService.save(
        this.userNotificationService.concept({
          userId: project.value.projectLeaderId,
          subject: `Checklist afbeelding: '${this.active.__documentMeta__.description}' opmerking geplaatst`,
          content: this.active.comment,
          channels: ["notification"],
          url: `${location.origin}/#/construction/audit-checklist-images/${this.active.projectId}/${this.active.costCategoryId}`,
        })
      );
    }
  }

  protected async fullScreen() {
    this.urlOpenService.openUrl({
      isNewTab: true,
      isTokenRequired: true,
      url: `${environment.baseUrl}/documents/open/${this.active.documentMetaId}`,
    });
  }
}
export class AuditChecklistImageDialogComponentData {
  constructor(
    readonly active: AuditChecklistImage,
    readonly images: AuditChecklistImage[]
  ) {}
}
