import { Component, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";

@Component({
  selector: "app-image-dialog",
  templateUrl: "./image-dialog.component.html",
  styleUrls: ["./image-dialog.component.scss"],
})
export class ImageDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: ImageDialogComponentConfig,
    protected readonly dialog: MdcDialogRef<ImageDialogComponent>
  ) {}
}

export interface ImageDialogComponentConfig {
  url: string;
  title?: string;
  buttons?: ImageDialogComponentConfigButton[];
}

export interface ImageDialogComponentConfigButton {
  buttonTitle: string;
  buttonAction: string;
  buttonText?: string;
}
