import { Component, OnInit, Inject } from "@angular/core";
import { AppRestService } from "../app-rest.service";
import { MdcSnackbar, MdcDialogRef, MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager, Ops } from "../entity.service";
import { RestResponse } from "../rest.service";
import { AppUser } from "../app-users/app-user.entity";

@Component({
  selector: "app-app-notification-send-dialog",
  templateUrl: "./app-notification-send-dialog.component.html",
  styleUrls: ["./app-notification-send-dialog.component.scss"],
})
export class AppNotificationSendDialogComponent implements OnInit {
  model = {
    id: null,
    subject: "",
    body: "",
  };

  notificationFilter = {
    title: "Melding",
    allowNothing: true,
    icon: "pageview",
    entityName: "app.notification_templates",
    nameField: "id",
    descriptionField: "subject",
    sortField: "subject",
    sortDirection: "ASC",
    filterFields: ["subject"],
  };

  users: RestResponse<AppUser[]>;

  get disableSend() {
    return this.users && this.users.value.length === 0;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    protected readonly data: AppNotificationSendDialogComponentData,
    protected readonly appRest: AppRestService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly enities: EntityManager,
    protected readonly ref: MdcDialogRef<AppNotificationSendDialogComponent>
  ) {}

  ngOnInit() {
    this.fetchUsers();
  }

  async send() {
    const response = await this.appRest.request<boolean>({
      url: `dashboard/notification`,
      method: "POST",
      body: this.model.id
        ? { id: this.model.id }
        : {
            subject: this.model.subject,
            body: this.model.body,
          },
      headers: {
        "X-Project": this.data.projectId,
      },
    });

    if (!response.hasError() && response.value) {
      this.snackbar.open("Verzonden", "Ok", { leading: true });
      this.ref.close();
    }
  }

  protected async fetchUsers() {
    this.users = await this.enities.get(AppUser).query({
      filters: [Ops.Field("project_id").Equals(this.data.projectId)],
    });
  }
}

export interface AppNotificationSendDialogComponentData {
  readonly projectId: string;
}
