import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AccessibilityRoleDialogComponent } from "../accessibility-role-dialog/accessibility-role-dialog.component";
import { AuthService } from "../auth.service";
import { DialogService } from "../dialog.service";
import { EntityManager } from "../entity.service";
import { RestResponse } from "../rest.service";
import { Role } from "./role.entity";

@Component({
  selector: "app-accessibility-roles",
  templateUrl: "./accessibility-roles.component.html",
  styleUrls: ["./accessibility-roles.component.scss"],
})
export class AccessibilityRolesComponent implements OnInit {
  public filterQuery = "";
  public response: RestResponse<Role[]>;

  exportCardsActive = false;

  public get roleService() {
    return this.entityManager.get(Role);
  }

  protected filterQueryChanged = new Subject<string>();

  constructor(
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly entityManager: EntityManager
  ) {}

  ngOnInit() {
    this.fetchRoles();
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((_) => this.fetchRoles());
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  async openRole(role: Role) {
    const dialogResult = await this.dialogService.open(
      this.changeDetector,
      AccessibilityRoleDialogComponent,
      { data: role }
    );

    if (dialogResult instanceof Role) {
      dialogResult.isConcept = false;
      this.response.value.push(dialogResult);
    }

    await this.authService.refreshAll(true);
  }

  protected async fetchRoles() {
    this.response = await this.roleService.query({
      take: 30,
      filters: [
        { field: "description", operator: "Like", value: this.filterQuery },
      ],
      relations: ["grants", "grantConfigs", "documentGrants"],
      orders: [{ field: "id", direction: "ASC" }],
    });
  }
}
