import { Injector, Injectable, ComponentFactoryResolver } from "@angular/core";
import { omit, pick } from "lodash";
import { RestService, RestResponse } from "./rest.service";

/**
 * Generic service used to operate on entities.
 *
 * @export
 * @class EntityService
 * @template T
 */
export class EntityService<T extends Entity> {
  protected readonly baseUrl: string;

  get name() {
    return this.baseUrl;
  }

  /**
   * Creates an instance of EntityService.
   * @param {new (entityManager: EntityManager) => T} ctor
   * @param {RestService} restService
   * @memberof EntityService
   */
  constructor(
    protected readonly ctor: new (entityManager: EntityManager) => T,
    protected readonly entityManager: EntityManager,
    protected readonly restService: RestService
  ) {
    this.baseUrl = new this.ctor(entityManager).type;
  }

  fromGraphQL(object: any): T {
    const entity = new this.ctor(this.entityManager);

    Object.assign(entity, omit(object, ["__typeName"]));

    return entity;
  }

  /**
   * Creates a new concep entity.
   *
   * @returns
   * @memberof EntityService
   */
  concept(fields: { [key: string]: any } = {}) {
    const entity = new this.ctor(this.entityManager);
    entity.isConcept = true;

    for (const key of Object.keys(fields)) {
      entity[key] = fields[key];
    }

    return entity;
  }

  /**
   * Copies the entity to a new instance.
   *
   * @param {T} entityToCopy
   * @returns
   * @memberof EntityService
   */
  copy(entityToCopy: T, except?: string[]) {
    const entity = new this.ctor(this.entityManager);
    const keys = Object.keys(entityToCopy).filter((e) =>
      except ? except.indexOf(e) === -1 : true
    );

    for (const key of keys) {
      entity[key] = entityToCopy[key];
    }

    entity.setFingerprint();

    return entity;
  }

  /**
   * Copies the properties to the new entity.
   *
   * @param {T} entityToCopy
   * @param {T} entityToReceive
   * @memberof EntityService
   */
  copyTo(entityToCopy: T, entityToReceive: T) {
    for (const key of Object.keys(entityToCopy)) {
      entityToReceive[key] = entityToCopy[key];
    }
  }

  extend(base: T, fields: { [key: string]: any } = {}) {
    const origin = this.copy(base);

    for (const key of Object.keys(fields)) {
      origin[key] = fields[key];
    }

    return origin;
  }

  /**
   * Queries the desired entities.
   *
   * @param {EntityQuery} query
   * @returns
   * @memberof EntityService
   */
  async query(query: EntityQuery = {}) {
    const response = await this.restService.post<T[]>(this.url("query"), query);

    return this.healManyResponse(response);
  }

  async queryRaw(query: string) {
    const response = await this.restService.post<T[]>(this.url("query-raw"), {
      query,
    });

    return this.healManyResponse(response);
  }

  /**
   * Queries the desired entities.
   *
   * @param {EntityQuery} query
   * @returns
   * @memberof EntityService
   */
  async queryWithCount(query: EntityQuery = {}) {
    Object.assign(query, { withCount: true });

    const response = await this.restService.post<EntitiesWithCount<T>>(
      this.url("query"),
      query
    );

    if (!response.hasError()) {
      response.value.data = this.healMany(response.value.data);
    }

    return response;
  }

  /**
   * Queries the first desired entity.
   *
   * @param {EntityQuery} query
   * @returns
   * @memberof EntityService
   */
  async queryFirst(query: EntityQuery) {
    const response = await this.query(query);

    if (!response.hasError()) {
      return new RestResponse<T>(response.value.find((_) => true) || null);
    }
  }

  /**
   * Fetches the entity with the requested relations.
   *
   * @param {T} entity
   * @param {string[]} relations
   * @memberof EntityService
   */
  async fetchWithRelations(entity: T, relations: string[]) {
    const response = await this.queryFirst({
      filters: [
        {
          field: "id",
          operator: EntityQueryFilterOperator.Equal,
          value: entity.id,
        },
      ],
      relations,
    });

    if (!response.hasError()) {
      this.copyTo(response.value, entity);
    }
  }

  /**
   * Fetches a list of the desired entities.
   *
   * @param {string} [searchInput='']
   * @returns
   * @memberof EntityService
   */
  async search(searchInput: string = "", searchFields: string[] = []) {
    const response = await this.restService.post<T[]>(this.url("search"), {
      filterQuery: searchInput,
      filterFields: searchFields,
    });

    return this.healManyResponse(response);
  }

  /**
   * Fetches a single entity.
   *
   * @param {*} id
   * @returns
   * @memberof EntityService
   */
  async findOne(id: any) {
    const response = await this.restService.get<T>(this.url(id));

    if (response.value && response.value.id) {
      return this.healOneResponse(response);
    } else {
      return new RestResponse<T>(null, {
        message: `Entity (${this.name}:${id}) not found.`,
      });
    }
  }

  /**
   * Fetches a single entity its relation.
   *
   * @param {*} id
   * @returns
   * @memberof EntityService
   */
  async findRelationOne<TRel extends Entity>(
    ctor: new (injector: Injector) => TRel,
    id: any,
    relation: string
  ) {
    const service = this.entityManager.get(ctor);
    const response = await this.restService.get<TRel>(
      `${this.url(id)}/${relation}`
    );

    return service.healOneResponse(response);
  }

  /**
   * Fetches a single entity its relation.
   *
   * @param {*} id
   * @returns
   * @memberof EntityService
   */
  async findRelationMany<TRel extends Entity>(
    ctor: new (injector: Injector) => TRel,
    id: any,
    relation: string
  ) {
    const service = this.entityManager.get(ctor);
    const response = await this.restService.get<TRel[]>(
      `${this.url(id)}/${relation}`
    );

    return service.healManyResponse(response);
  }

  /**
   * Deletes a single entity.
   *
   * @param {*} id
   * @returns
   * @memberof EntityService
   */
  delete(id: any) {
    return this.restService.delete<T>(this.url(id));
  }

  /**
   * Saves a new entity.
   *
   * @param {T} entity
   * @returns
   * @memberof EntityService
   */
  async save(entity: T, fields?: string[]) {
    entity = this.healOne(entity);

    const entityPayload = entity.toJson();

    const response = await this.restService.post<T>(this.url(), [
      fields ? pick(entityPayload, fields) : entityPayload,
    ]);

    const healed = this.healOneResponse(response);

    if (!healed.hasError()) {
      this.copyTo(entity, healed.value);

      healed.value.isConcept = false;
      healed.value.setFingerprint();
    }

    return healed;
  }

  async saveMany(entities: T[], fields?: string[]) {
    entities = this.healMany(entities);

    const response = await this.restService.post<T[]>(
      this.url(),
      entities
        .map((e) => e.toJson())
        .map((payload) => (fields ? pick(payload, fields) : payload))
    );

    if (!response.hasError()) {
      const healed = this.healMany(
        Array.isArray(response.value) ? response.value : [response.value]
      );

      for (let i = 0; i < entities.length; i++) {
        this.copyTo(entities[i], healed[i]);
      }

      return new RestResponse(healed);
    }
  }

  /**
   * Modifies a single entity.
   *
   * @param {T} entity
   * @returns
   * @memberof EntityService
   */
  async modify(entity: T) {
    entity = this.healOne(entity);

    const response = await this.restService.put<T>(
      this.url(entity.id),
      entity.toJson()
    );

    return this.healOneResponse(response);
  }

  async modifyMany(entities: T[]) {
    const body = this.healMany(entities).map((e) => e.toJson());

    const response = await this.restService.put<T[]>(this.url(), body);

    return this.healManyResponse(response);
  }

  healOneResponse(response: RestResponse<T>) {
    if (!response.hasError()) {
      response = new RestResponse<T>(this.healOne(response.value));
    }

    return response;
  }

  healManyResponse(response: RestResponse<T[]>) {
    if (!response.hasError()) {
      response = new RestResponse<T[]>(this.healMany(response.value));
    }

    return response;
  }

  protected healOne(entity: T) {
    const workset = new this.ctor(this.entityManager);

    Object.assign(workset, entity);

    workset.setFingerprint();

    return workset;
  }

  protected healMany(entities: T[]) {
    return entities ? entities.map((_) => this.healOne(_)) : [];
  }

  protected url(id = null) {
    let builder = `entity/${this.baseUrl}`;

    if (id) {
      builder += `/${id}`;
    }

    return builder;
  }
}

/**
 * Class used to resolve relationships for @see Entity.
 *
 * @class RelationshipResolver
 */
class RelationshipResolver {
  /**
   * Creates an instance of RelationshipResolver.
   * @param {() => any} identityResolver
   * @param {EntityManager} entityManager
   * @memberof RelationshipResolver
   */
  constructor(
    protected readonly identityResolver: () => any,
    protected readonly entityManager: EntityManager
  ) {}

  oneStale<TRel extends Entity>(
    ctor: new (entityManager: Injector) => TRel,
    name: string
  ) {
    const raw = this[this.relationKey(name)] as TRel;

    return this.entityManager
      .get(ctor)
      .healOneResponse(new RestResponse<TRel>(raw));
  }

  manyStale<TRel extends Entity>(
    ctor: new (entityManager: Injector) => TRel,
    name: string
  ) {
    const raw = this[this.relationKey(name)] as TRel[];

    return this.entityManager
      .get(ctor)
      .healManyResponse(new RestResponse<TRel[]>(raw));
  }

  setStale(name: string, value: any) {
    this[this.relationKey(name)] = value;
  }

  /**
   * Defines a single entity relationship.
   *
   * @template TRel
   * @param {new (entityManager: Injector) => Entity} ctorBase
   * @param {new (entityManager: Injector) => TRel} ctor
   * @param {string} name
   * @returns
   * @memberof RelationshipResolver
   */
  one<TRel extends Entity>(
    ctorBase: new (entityManager: EntityManager) => Entity, // todo: erase
    ctor: new (entityManager: EntityManager) => TRel,
    name: string
  ) {
    const rel = this.entityManager.get(ctor);
    const promise = this.entityManager
      .get(ctorBase)
      .findRelationOne(ctor, this.identityResolver(), name);

    return this.getOrAdd(name, promise).then((response) =>
      rel.healOneResponse(response)
    );
  }

  /**
   * Defines a multi entity relationship.
   *
   * @template TRel
   * @param {new (entityManager: EntityManager) => Entity} ctorBase
   * @param {new (entityManager: EntityManager) => TRel} ctor
   * @param {string} name
   * @returns
   * @memberof RelationshipResolver
   */
  many<TRel extends Entity>(
    ctorBase: new (entityManager: EntityManager) => Entity, // todo: erase
    ctor: new (entityManager: EntityManager) => TRel,
    name: string
  ) {
    const rel = this.entityManager.get(ctor);
    const promise = this.entityManager
      .get(ctorBase)
      .findRelationMany(ctor, this.identityResolver(), name);

    return this.getOrAdd(name, promise).then((response) =>
      rel.healManyResponse(response)
    );
  }

  /**
   * Resets all the cached relations.
   *
   * @memberof RelationshipResolver
   */
  resetRelations() {
    for (const key of Object.keys(this)) {
      if (key.startsWith("__") && key.endsWith("__")) {
        delete this[key];
      }
    }
  }

  protected async getOrAdd<T>(
    name: string,
    restCall: Promise<RestResponse<T>>
  ) {
    let response = new RestResponse<T>(null, null);
    const propertyName = this.relationKey(name);

    if (this.hasOwnProperty(propertyName)) {
      response = new RestResponse<T>(this[propertyName] as T);
    } else {
      response = await restCall;

      if (!response.hasError()) {
        this[propertyName] = response.value;
      }
    }

    return response;
  }

  protected relationKey(name: string) {
    return `__${name}__`;
  }
}

/**
 * Definition of an entity with the required properties.
 *
 * @export
 * @class Entity
 */
export abstract class Entity extends RelationshipResolver {
  refId: string;

  /**
   * Identity of the entity.
   *
   * @memberof Entity
   */
  id: string | number | any;

  /**
   * Type (table) of the entity.
   *
   * @type {string}
   * @memberof Entity
   */
  type: string;

  /**
   * Indicates if the entity is a concept.
   *
   * @type {boolean}
   * @memberof Entity
   */
  isConcept?: boolean;

  allowedObjectFields?: string[] = [];

  protected intialFingerprint = {
    value: null,
  };

  get isDirty() {
    return (
      this.isConcept || this.intialFingerprint.value !== this.makeFingerprint()
    );
  }

  /**
   * Creates an instance of Entity.
   * @param {EntityManager} entityManager
   * @memberof Entity
   */
  constructor(protected readonly entityManager: EntityManager) {
    super(() => this.id, entityManager);

    this.refId = Math.random().toString();
  }

  setFingerprint() {
    this.intialFingerprint.value = this.makeFingerprint();
    this.onLoad();
  }

  onLoad(): void | Promise<void> {}

  toJson() {
    return Object.keys(this)
      .filter((e) => !this.isRelation(e))
      .map((e) => ({ key: e, value: this[e] }))
      .filter((i) =>
        this?.allowedObjectFields?.includes(i.key)
          ? true
          : this.isNative(i.value) || this.isNativeArray(i.value)
      )
      .reduce(
        (obj, item) => {
          obj[item.key] = item.value;
          return obj;
        },
        { id: this.id }
      );
  }

  protected isRelation(key: string) {
    return key.startsWith("__") && key.endsWith("__");
  }

  protected isNative(value) {
    return (
      value === null ||
      !(
        (typeof value === "object" && !(value instanceof Date)) ||
        typeof value === "function"
      )
    );
  }

  protected isNativeArray(value) {
    return Array.isArray(value) && value.every((e) => this.isNative(e));
  }

  protected makeFingerprint() {
    return JSON.stringify(this.toJson());
  }

  getEntities() {
    return this.entityManager;
  }
}

export interface EntityQuery {
  skip?: number;
  take?: number;
  orders?: EntityQueryOrder[];
  filters?: EntityQueryFilter[];
  relations?: string[];
  paths?: string[];
  select?: string[];
  withCount?: boolean;
}

export enum EntityQueryFilterOperator {
  Equal = "Equal",
  Between = "Between",
  In = "In",
  IsNull = "IsNull",
  Like = "Like",
  MoreThan = "MoreThan",
  MoreThanOrEqual = "MoreThanOrEqual",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
}

export namespace Ops {
  class FieldEtx {
    constructor(
      protected readonly field?: string,
      protected readonly isNot?: boolean
    ) {}

    Equals = <T>(value: T) =>
      this.final(EntityQueryFilterOperator.Equal, value);

    Between = <T>(valueFrom: T, valueTo: T) =>
      this.final(EntityQueryFilterOperator.Between, [valueFrom, valueTo]);

    In = <T>(value: T[]) => this.final(EntityQueryFilterOperator.In, value);

    IsNull = (isNot = false) =>
      this.IsNot(isNot).final(EntityQueryFilterOperator.IsNull);

    Like = (value: string) => this.final(EntityQueryFilterOperator.Like, value);

    MoreThan = <T>(value: T) =>
      this.final(EntityQueryFilterOperator.MoreThan, value);

    MoreThanOrEqual = <T>(value: T) =>
      this.final(EntityQueryFilterOperator.MoreThanOrEqual, value);

    LessThan = <T>(value: T) =>
      this.final(EntityQueryFilterOperator.LessThan, value);

    LessThanOrEqual = <T>(value: T) =>
      this.final(EntityQueryFilterOperator.LessThanOrEqual, value);

    IsNot = (value = true) => {
      return new FieldEtx(this.field, value);
    };

    protected final<T>(operator: EntityQueryFilterOperator, value?: T) {
      return {
        isNot: this.isNot,
        field: this.field,
        operator,
        value,
      };
    }
  }

  export const Field = (field: string) => new FieldEtx(field);
}

export interface EntityQueryFilter {
  field: string;
  operator: string | EntityQueryFilterOperator;
  value?: any;
  valueComplex?: any;

  isOr?: boolean;
  isNot?: boolean;
}

export interface EntityQueryOrder {
  field: string;
  direction: string;
}

export function Table(value: string) {
  return function <T extends new (...args: any[]) => {}>(constructor: T) {
    return class extends constructor {
      type = value;
    };
  };
}

export type EntityConstuctor = new (entityManager: EntityManager) => Entity;
export type EntityConstuctorTyped<T extends Entity> = new (
  entityManager: EntityManager
) => T;

@Injectable()
export class EntityManager {
  protected lifetimes = new Map<string, any>();

  constructor(
    protected readonly injector: Injector,
    protected readonly restService: RestService
  ) {}

  get<T extends Entity>(ctor: EntityConstuctorTyped<T>) {
    const entityName = new ctor(this).type;

    if (!this.lifetimes.has(entityName)) {
      this.lifetimes.set(
        entityName,
        new EntityService<T>(ctor, this, this.restService)
      );
    }

    return this.lifetimes.get(entityName) as EntityService<T>;
  }
}

export class EntitiesWithCount<T> {
  data: T[];
  totalCount: number;
}

export abstract class OrderableEntity extends Entity {
  order: number;
}

type EntityConstructor<T> = new (entityManager: EntityManager) => T;

export const setRelationOne = <TSource extends Entity, TTarget extends Entity>(
  base: TSource,
  ctor: EntityConstructor<TTarget>,
  value: TTarget
) => {
  return base.getEntities().get(ctor).healOneResponse(new RestResponse(value))
    .value;
};

export const setRelationMany = <TSource extends Entity, TTarget extends Entity>(
  base: TSource,
  ctor: EntityConstructor<TTarget>,
  value: TTarget[]
) => {
  return base.getEntities().get(ctor).healManyResponse(new RestResponse(value))
    .value;
};
