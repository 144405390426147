import * as moment from "moment";
import * as React from "react";

import { TextField } from "@material-ui/core";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

interface TaskDateTimeFieldProps {
  readOnly: boolean;
  value: Date;
  label: string;
  onChange: (date: Date) => void;
}

export const TaskDateTimeField = ({
  readOnly,
  label,
  value,
  onChange,
}: TaskDateTimeFieldProps) => {
  const [date, setDate] = useState(value);
  const emitChange = debounce((newDate: Date) => onChange(newDate), 250);

  const onFieldChanged = (newValue: string) => {
    const newDate = moment(newValue).toDate();

    setDate(newDate);
    emitChange(newDate);
  };

  useEffect(() => {
    setDate(value);
  }, [value]);

  return (
    <TextField
      fullWidth
      disabled={readOnly}
      variant="outlined"
      id="datetime-local"
      label={label}
      type="datetime-local"
      inputProps={{
        step: 60 * 30,
      }}
      onChange={(event) => onFieldChanged(event.target.value)}
      value={moment(date).format("YYYY-MM-DDTHH:mm")}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
