import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHeaders } from "@angular/common/http";
import { AppService } from "./app.service";
import { environment } from "src/environments/environment";
import { RestService } from "./rest.service";

@Injectable({ providedIn: "root" })
export class AppRestService {
  constructor(
    protected readonly app: AppService,
    protected readonly http: HttpClient,
    protected readonly rest: RestService
  ) {}

  async request<T>(req: AppRestServiceRequest) {
    const token = await this.app.refreshToken();

    return await this.rest.safeEvent<T>(
      this.http.request<T>(
        new HttpRequest<any>(req.method, this.url(req.url), this.body(req), {
          headers: new HttpHeaders(this.headers(token, req.headers)),
        })
      )
    );
  }

  protected body(req: AppRestServiceRequest) {
    if (req.file) {
      const form = new FormData();
      form.append("file", req.file);

      for (const key of Object.keys(req.body)) {
        const value = req.body[key];

        if (value !== undefined && value !== null) {
          form.append(key, value);
        }
      }

      return form;
    }

    return req.body;
  }

  protected url(relative: string) {
    return `${environment.appApiBaseUrl}/api/${relative}`;
  }

  protected headers(token: string, base?: any) {
    return Object.assign(base || {}, {
      Authorization: `Bearer ${token}`,
    });
  }
}

interface AppRestServiceRequest {
  url: string;
  method: string;
  headers?: any;
  body?: any;
  file?: File;
}
