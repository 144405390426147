import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { Project } from "../project/project.entity";
import {
  IShortcutContextData,
  CustomShortcut,
} from "../project-shortcuts/project-shortcuts.component";

@Component({
  selector: "app-project-shortcuts-dialog",
  templateUrl: "./project-shortcuts-dialog.component.html",
  styleUrls: ["./project-shortcuts-dialog.component.scss"],
})
export class ProjectShortcutsDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly model: ProjectShortcutsDialogComponentModel,
    readonly dialogRef: MdcDialogRef<ProjectShortcutsDialogComponent>
  ) {}

  async close() {
    this.dialogRef.close();
  }

  async onCustom(event: { id: string }) {
    if (event && this.model.customHandler) {
      await this.model.customHandler(event.id);
    }
  }
}

export class ProjectShortcutsDialogComponentModel {
  constructor(
    readonly project: Project | string,
    readonly title?: IShortcutDialogTitle,
    readonly context?: IShortcutContextData,
    readonly custom?: CustomShortcut[],
    readonly customHandler?: (id: string) => Promise<any> | any
  ) {}
}

export interface IShortcutDialogTitle {
  readonly subject: string;
  readonly description: string;
}
