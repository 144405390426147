import { Apollo } from "apollo-angular";
import { chain, first, omit, orderBy } from "lodash";
import { useMemo, useState } from "react";
import { MetacomService } from "../metacom.service";
import { PicklistCommentDto } from "./dtos/picklist-comment.dto";
import { PicklistDefinitionDto } from "./dtos/picklist-definition.dto";
import {
  PicklistElementStateDto,
  PicklistElementStateMode,
  PicklistElementStateModeFilter,
} from "./dtos/picklist-element-state.dto";
import { PicklistElementDto } from "./dtos/picklist-element.dto";
import { PicklistProjectDto } from "./dtos/picklist-project.dto";
import { PicklistStateDto } from "./dtos/picklist-state.dto";
import { determineNextPickState } from "./picklist-component.functions";
import {
  createPicklistCommentMutation,
  createPicklistElementStateMutation,
  createPicklistStateMutation,
} from "./picklist-component.mutation";
import {
  picklistElementStatesQuery,
  picklistFetchComments,
  picklistFetchDetails,
} from "./picklist-component.query";

export const useFetchPicklistDetails = (
  metacom: MetacomService,
  apollo: Apollo,
  projectId: string,
  picklistDefinitionId: string
) => {
  const [completedState, setCompletedState] = useState<PicklistStateDto>();
  const [definition, setDefinition] = useState<PicklistDefinitionDto>();
  const [project, setProject] = useState<PicklistProjectDto>();
  const [note, setNote] = useState<string>();

  const fetchPicklistDetails = async () => {
    const response = await apollo
      .query<{
        definition: PicklistDefinitionDto;
        project: PicklistProjectDto;
        state: PicklistStateDto;
      }>({
        query: picklistFetchDetails,
        variables: { picklistDefinitionId, projectId },
      })
      .toPromise();

    if (!response.errors && response.data) {
      setCompletedState(response.data.state);
      setProject(response.data.project);
      setDefinition(response.data.definition);

      const noteResponse = await metacom.queryTableAsync<{
        koptekst: string;
      }>({
        setName: "metacom",
        tableName: response.data.definition.metacomListTable,
        filter: `reg_mutatie.naar_kpl = '${projectId}'`,
      });

      if (!noteResponse.hasError() && noteResponse.value) {
        const document = first(noteResponse.value || []);

        setNote((document ? document.koptekst : null) || "Geen opmerking.");
      } else {
        setNote("Geen opmerking.");
      }
    }
  };

  const setCompleted = async (userId: string) => {
    const response = await apollo
      .mutate<{ state: PicklistStateDto }>({
        mutation: createPicklistStateMutation,
        variables: {
          input: {
            completedByUserId: userId,
            projectId,
            picklistDefinitionId,
          },
        },
      })
      .toPromise();

    if (!response.errors) {
      setCompletedState(response.data.state);
    }
  };

  return {
    definition,
    project,
    note,
    completedState,
    fetchPicklistDetails,
    setCompleted,
  };
};

const headerElementId = "990999";

export const useFetchPicklistLines = (
  metacom: MetacomService,
  projectId: string,
  documentId: string
) => {
  const [lines, setLines] = useState<PicklistElementDto[]>();

  const fetchPicklistLines = async (
    tableName: string,
    filterIds: string[] = []
  ) => {
    const response = await metacom.queryTableAsync<any>({
      setName: "metacom",
      tableName,
      filter: documentId
        ? `reg_mutatie.docnr = '${documentId}'`
        : `reg_mutatie.naar_kpl = '${projectId}'`,
    });

    setLines(
      orderBy(
        response.value
          .filter(
            (item) =>
              filterIds.length === 0 || filterIds.includes(item["naar_hvcode"])
          )
          .map((item) => ({
            ...item,
            id: item.origin || item._rowid,
            orderId: item.docregel,
            isHeader: item["middel"] === headerElementId,
            omschrijving: item["omschrijving"] || item["oms"],
          }))
          .map((line) =>
            line.isHeader
              ? {
                  id: line.id,
                  isHeader: true,
                  orderId: line.orderId,
                  middel: line["omschrijving"] || line["oms"],
                }
              : line
          ),
        (element) => parseFloat(element.orderId)
      )
    );
  };

  return { lines, fetchPicklistLines };
};

export const useFetchPicklistComments = (
  apollo: Apollo,
  projectId: string,
  picklistDefinitionId: string
) => {
  const [comments, setComments] = useState<PicklistCommentDto[]>();

  const fetchPicklistComments = async () => {
    const response = await apollo
      .query<{
        comments: PicklistCommentDto[];
      }>({
        query: picklistFetchComments,
        variables: { projectId, picklistDefinitionId },
      })
      .toPromise();

    if (!response.errors) {
      setComments(response.data.comments);
    }
  };

  return { comments, fetchPicklistComments };
};

export const useFetchPicklistElementStates = (
  apollo: Apollo,
  projectId: string,
  picklistDefinitionId: string
) => {
  const [states, setStates] = useState<PicklistElementStateDto[]>();

  const fetchPicklistElementStates = async () => {
    const response = await apollo
      .query<{
        states: PicklistElementStateDto[];
      }>({
        query: picklistElementStatesQuery,
        variables: { projectId, picklistDefinitionId },
      })
      .toPromise();

    if (!response.errors) {
      setStates(response.data.states);
    }
  };

  const findOrCreateState = (elementId: string) => {
    const match = states.find((state) => state.elementId === elementId);

    return (match || {
      id: null,
      elementId,
      projectId,
      picklistDefinitionId,
      state: PicklistElementStateMode.Idle,
    }) as PicklistElementStateDto;
  };

  const stateOfElement = (elementId: string) => {
    return findOrCreateState(elementId).state;
  };

  const toggleElementState = async (
    elementId: string,
    userId: string,
    indeterminateToggles: boolean
  ) => {
    const elementState = findOrCreateState(elementId);

    elementState.state = determineNextPickState(
      elementState.state,
      indeterminateToggles
    );
    elementState.pickedByUserId = userId;

    const response = await apollo
      .mutate<{ state: PicklistElementStateDto }>({
        mutation: createPicklistElementStateMutation,
        variables: {
          input: omit(elementState, [
            "__typeName",
            "__typename",
            ...(!elementState.id ? ["id"] : []),
          ]),
        },
      })
      .toPromise();

    if (!response.errors) {
      setStates((prev) => [
        ...prev.filter((candidate) => candidate.id !== response.data.state.id),
        response.data.state,
      ]);
    }
  };

  const isReadyForCompletion = (elements: PicklistElementDto[]) => {
    return (
      elements &&
      (elements || [])
        .filter((element) => !element.isHeader)
        .every(
          (element) =>
            stateOfElement(element.id) === PicklistElementStateMode.Picked
        )
    );
  };

  return {
    states,
    fetchPicklistElementStates,
    stateOfElement,
    toggleElementState,
    isReadyForCompletion,
  };
};

export const useCreatePicklistComment = (apollo: Apollo) => {
  const [loading, setLoading] = useState(false);

  const createPicklistComment = async (input: PicklistCommentDto) => {
    setLoading(true);

    const response = await apollo
      .mutate({
        mutation: createPicklistCommentMutation,
        variables: { input },
      })
      .toPromise();

    setLoading(false);

    return response;
  };

  return { loading, createPicklistComment };
};

export type ElementPickStateResolver = (
  elementId: string
) => PicklistElementStateMode;

interface FilterElementState {
  fieldFilter: { [key: string]: string };
  stateFilter: PicklistElementStateModeFilter;
}

export const useElementFilterOptions = (
  elements: PicklistElementDto[],
  field: string
) => {
  const composeOptions = () => {
    return (
      elements &&
      chain(elements)
        .filter((element) => !element.isHeader)
        .map((element) => element[field])
        .uniq()
        .value()
    );
  };

  const options = useMemo(() => composeOptions(), [elements, field]);

  return { options };
};

export const useFilterElementsByPickState = (
  elements: PicklistElementDto[],
  pickStateResolver: ElementPickStateResolver
) => {
  const [filter, setFilter] = useState<FilterElementState>({
    fieldFilter: {},
    stateFilter: PicklistElementStateModeFilter.All,
  });

  const applyStateFilterOnElement = (element: PicklistElementDto) => {
    return (
      filter.stateFilter === PicklistElementStateModeFilter.All ||
      filter.stateFilter.indexOf(pickStateResolver(element.id) as string) >= 0
    );
  };

  const applyFieldFilterOnElement = (element: PicklistElementDto) => {
    const fields = Object.keys(filter.fieldFilter).filter(
      (field) => filter.fieldFilter[field] != "filter.all"
    );

    return (
      fields.length === 0 ||
      fields.every((field) => element[field] === filter.fieldFilter[field])
    );
  };

  const applyFilters = () => {
    return (
      elements &&
      elements.filter(
        (element) =>
          applyStateFilterOnElement(element) &&
          applyFieldFilterOnElement(element)
      )
    );
  };

  const elementsFiltered = useMemo(() => applyFilters(), [elements, filter]);

  return { elementsFiltered, filter, setFilter };
};
