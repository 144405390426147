import * as React from "react";
import { makeStyles, Typography, TextField } from "@material-ui/core";

import { EntityManager, Ops } from "src/app/entity.service";
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from "../../bloc/crud/crud.event";
import { CrudComponent } from "../crud.component";
import { PresetBloc } from "./preset.bloc";
import { PresetEntity } from "./preset.entity";
import { EntitySelect } from "src/app/entity-select/entity-select";
import { RestService } from "src/app/rest.service";

export interface PresetComponentProps {
  rest: RestService;
  entities: EntityManager;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  selectField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  addButton: {
    marginTop: 24,
  },
  column: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    marginBottom: 12,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
}));

export const PresetComponent: React.FC<PresetComponentProps> = ({
  rest,
  entities,
}) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new PresetBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: PresetEntity) => (
    <Typography className={classes.heading}>
      <strong>{entity.code || "CODE"}</strong> &mdash; {entity.description}{" "}
      {entity.isDirty && <i>(niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: PresetEntity) => (
    <div className={classes.column} key={entity.refId}>
      <div className={classes.row}>
        <EntitySelect
          className={classes.selectField}
          title="Hoofd Categorie"
          type="app.service_categories"
          labelSelector="name"
          searchFields={["name"]}
          entityId={entity.primaryCategoryId}
          restService={rest}
          filters={[Ops.Field("service_category_id").IsNull()]}
          onSelect={(category) => {
            entity.primaryCategoryId = category ? category.id : null;
            entity.secondaryCategoryId = null;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />

        <div className={classes.textDivider}></div>

        {entity.primaryCategoryId && (
          <EntitySelect
            className={classes.selectField}
            title="Sub Categorie"
            type="app.service_categories"
            labelSelector="name"
            searchFields={["name"]}
            entityId={entity.secondaryCategoryId}
            restService={rest}
            filters={[
              Ops.Field("service_category_id").Equals(entity.primaryCategoryId),
            ]}
            onSelect={(category) => {
              entity.secondaryCategoryId = category ? category.id : null;

              bloc.add(new CrudModifiedEvent({ entity }));
            }}
          />
        )}

        {!entity.primaryCategoryId && (
          <TextField
            className={classes.textField}
            variant="outlined"
            disabled
            placeholder="Sub Categorie"
          ></TextField>
        )}
      </div>
      <div className={classes.row}>
        <TextField
          variant="outlined"
          label="Code"
          value={entity.code}
          className={classes.textField}
          onChange={(event) => {
            entity.code = event.target.value;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />

        <div className={classes.textDivider}></div>

        <TextField
          className={classes.textField}
          variant="outlined"
          label="Omschrijving"
          value={entity.description}
          onChange={(event) => {
            entity.description = event.target.value;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />
      </div>
    </div>
  );

  return (
    <CrudComponent
      bloc={bloc}
      renderTitle={(entity: PresetEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: PresetEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: PresetEntity, siblings: PresetEntity[]) =>
        !entity.code ||
        !entity.description ||
        !entity.primaryCategoryId ||
        !!siblings.find((sibling) => sibling.code === entity.code)
      }
    />
  );
};
