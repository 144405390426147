import { Component, OnInit, Inject } from "@angular/core";
import { RestResponse } from "../rest.service";
import {
  DeliveriesComponentSettings,
  DeliveryRow,
} from "../deliveries/deliveries.component";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { CellaService } from "../cella.service";
import { TransactionService } from "../transaction.service";
import * as moment from "moment";

@Component({
  selector: "app-delivery-order-dialog",
  templateUrl: "./delivery-order-dialog.component.html",
  styleUrls: ["./delivery-order-dialog.component.scss"],
})
export class DeliveryOrderDialogComponent implements OnInit {
  deliveryDate: string;
  completedAllChecked = false;
  response: RestResponse<DeliveryRow[]>;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: DeliveryOrderDialogComponentData,
    protected readonly cellaService: CellaService,
    protected readonly transactionService: TransactionService,
    protected readonly dialogRef: MdcDialogRef<DeliveryOrderDialogComponent>
  ) {}

  ngOnInit() {
    this.deliveryDate = moment(new Date()).format("YYYY-MM-DD");
  }

  completeAll() {
    for (const row of this.data.rows) {
      row.isCompleted = this.completedAllChecked;
      this.complete(row);
    }
  }

  complete(row: DeliveryRow) {
    row.quantityUpdate = row.isCompleted ? row.middel_hv_open * 1.0 : null;
  }

  async save() {
    const format = "YYYY-MM-DD";

    await this.transactionService.perform(() =>
      this.cellaService.writeTransactionDocument({
        Company: this.data.settings.companyId.toString(),
        DocumentType: "30-21",
        DocumentDate: moment().format(format),
        DocumentDescription: "",
        DocumentLines: this.data.rows
          .filter((l) => l.quantityUpdate || l.isCompleted)
          .map((l) => ({
            FromCompany: this.data.settings.companyId,
            ToCompany: l.naar_bedrijf,
            ToAdministrationType: l.naar_administratie,
            StartDate: moment(this.deliveryDate).format(format),
            StartTime: "000000",
            EndDate: moment(this.deliveryDate).format(format),
            EndTime: "000000",
            SourceCompany: this.data.settings.companyId,
            SourceDocumentType: l.bron_doctype,
            SourceDocumentNumber: l.opdracht,
            SourceDocumentLine: l.bron_regel,
            ToCostCentre: l.project,
            ToCostUnit: l.kostensoort,
            FromCostCentre: l.relatie,
            FromCostUnit: l.middel,
            FromQuantity: l.quantityUpdate.toFixed(2),
            FromRemainingQuantityCorrection: this.getCorrectOpenValue(
              l
            ).toFixed(2),
            FromUnit: l.middel_eenheid,
            FromUnitPrice: l.eenheidsprijs,
            Description: l.middel_oms,
          })),
      })
    );

    return this.dialogRef.close("save");
  }

  protected getCorrectOpenValue(row: DeliveryRow) {
    const open = row.middel_hv_open;

    return row.quantityUpdate > open || row.isCompleted
      ? open - row.quantityUpdate
      : 0;
  }
}
export class DeliveryOrderDialogComponentData {
  orderId: string;
  orderName: string;
  relationName: string;

  rows: DeliveryRow[];

  settings: DeliveriesComponentSettings;
}
