import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import * as React from "react";

export interface SchedulerConfirmDialogComponentProps {
  onResult: (result: boolean) => void;
}

export const SchedulerConfirmDialogComponent = ({
  onResult,
}: SchedulerConfirmDialogComponentProps) => {
  return (
    <Dialog
      open
      onClose={() => onResult(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Wijzigen"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Weet je zeker dat je deze wijziging wilt doen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onResult(false)}>Nee</Button>
        <Button onClick={() => onResult(true)} autoFocus>
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
};
