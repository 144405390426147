import { Entity } from "../entity.service";

export class AppProjectCostPreset extends Entity {
  type = "app.project_cost_presets";

  title: string;
  description: string;

  created_at?: Date;
  updated_at?: Date;
}
