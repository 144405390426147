import gql from "graphql-tag";

export const picklistDefinitionsStoreMutation = gql`
  mutation picklistDefinitionsStoreMutation(
    $input: InputPicklistDefinitionEntity!
  ) {
    item: PicklistDefinitionEntityUpdateOne(input: $input) {
      id
      name
      competenceNumber
      hideColumns
      indeterminateToggles
      renameFilterName
    }
  }
`;

export const picklistDefinitionsDeleteMutation = gql`
  mutation picklistDefinitionsDeleteMutation(
    $input: InputDeletePicklistDefinitionEntity!
  ) {
    isDeleted: PicklistDefinitionEntityDeleteOne(input: $input)
  }
`;
