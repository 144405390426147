import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { TaskInterface } from "../interfaces/task.interface";
import { formatNumber } from "@angular/common";
import { TableLayoutContext } from "../context/table-layout.context";

import {
  IconButton,
  Card,
  CardContent,
  Typography,
  Menu,
  Divider,
} from "@material-ui/core";

import {
  MoreHoriz as MoreHorizIcon,
  Undo as UndoIcon,
  FilterCenterFocus,
  Note as NoteIcon,
} from "@material-ui/icons";
import { sumBy } from "lodash";
import { TaskCommentDialog } from "./task-comment.dialog";
import { useEffect, useState } from "react";
import { TableLayoutComment } from "../interfaces/table-layout-comment";

interface TaskComponentProps {
  task: TaskInterface;
}

export const TaskComponent: React.FC<TaskComponentProps> = ({ task }) => {
  const typeThreshold = 175,
    lengthThreshold = 8500;

  const context = React.useContext(TableLayoutContext);

  const color = () => {
    const elementId = parseFloat(task.partCodeId);

    if (!isNaN(elementId) && elementId >= typeThreshold) {
      return "rgba(0, 174, 255, 0.2)";
    }
  };

  const titleColor = () => {
    const length = sumBy(
      task.subTasks,
      (_task) => parseFloat(_task.length) || 0
    );
    const width = sumBy(task.subTasks, (_task) => parseFloat(_task.width) || 0);

    const value = Math.max(length, width);

    if (value < lengthThreshold) {
      return "orange";
    } else {
      return "green";
    }
  };

  const isPlanned = () => !!task.moldId && !!task.dateId;

  const [expanded, setExpanded] = React.useState(false);
  const [memoOpen, setMemoOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const shortcuts = async () => {
    handleClose();

    await context.component.shortcuts(task.projectId, task.projectName);
  };

  const onCommentSaved = (comment: TableLayoutComment) => {
    if (isPlanned()) {
      context.setTasks(
        context.tasks.map((_task) =>
          _task.id === task.id ? { ..._task, comment } : _task
        )
      );
    } else {
      context.setOpenTasks(
        context.openTasks.map((_task) =>
          _task.id === task.id ? { ..._task, comment } : _task
        )
      );
    }
  };

  useEffect(() => {
    Boolean(memoOpen) && setAnchorEl(null);
  }, [memoOpen]);

  return (
    <Draggable
      draggableId={task.id}
      index={0}
      isDragDisabled={!context.component.canEdit}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!task.isUpdating && (
            <>
              <Card
                onClick={() => !menuOpen && setExpanded(!expanded)}
                variant="outlined"
                className="card"
                style={{
                  backgroundColor: "white",
                  padding: 0,
                }}
              >
                <CardContent
                  style={{
                    position: "relative",
                    backgroundColor: color(),
                    padding: 4,
                  }}
                >
                  <Typography color="textSecondary" className="title">
                    {isPlanned() && (
                      <Typography
                        style={{
                          whiteSpace: "pre",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        ({task.projectId}) {task.projectName}
                      </Typography>
                    )}
                    <Typography
                      variant="button"
                      style={{
                        color: titleColor(),
                        margin: "none",
                      }}
                      gutterBottom
                    >
                      {task.wallId || "??_??"}
                    </Typography>
                    <br />

                    <Typography variant="caption" style={{ fontSize: "12px" }}>
                      {formatNumber(+(task.length ?? 0) / 1000, "nl", "1.2-2")}{" "}
                      L {formatNumber(+(task.width ?? 0) / 1000, "nl", "1.2-2")}{" "}
                      B {formatNumber(task.capacity, "nl", "1.2-2")} m³
                    </Typography>

                    {task.comment && !!task.comment.comment && (
                      <NoteIcon
                        style={{
                          height: 16,
                          verticalAlign: "middle",
                          marginLeft: 8,
                          color: "grey",
                        }}
                        fontSize="small"
                      />
                    )}
                  </Typography>

                  {expanded && (
                    <div>
                      <Divider style={{ margin: "6px 0" }} />
                      {task.subTasks.map((t) => (
                        <div key={t.id} style={{ marginBottom: 8 }}>
                          <Typography
                            variant="caption"
                            display="block"
                            style={{
                              fontSize: 11,
                              fontWeight: 500,
                            }}
                          >
                            {t.name || t.partCodeId}
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            style={{
                              fontSize: 10,
                            }}
                            gutterBottom
                          >
                            L{t.length} x B{t.width}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}

                  <IconButton
                    style={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      backgroundColor: "white",
                    }}
                    size="small"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    style={{}}
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuOpen}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        paddingLeft: 10,
                        paddingRight: 10,
                      },
                    }}
                  >
                    {isPlanned() && (
                      <IconButton
                        size="small"
                        onClick={() => context.undo(task)}
                        disabled={!context.component.canEdit}
                        aria-label="terugzeten"
                      >
                        <UndoIcon />
                      </IconButton>
                    )}

                    <IconButton
                      size="small"
                      onClick={() => setMemoOpen(true)}
                      aria-label="memo"
                    >
                      <NoteIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => shortcuts()}
                      aria-label="snelkoppelingen"
                    >
                      <FilterCenterFocus />
                    </IconButton>
                  </Menu>
                </CardContent>
              </Card>
              {memoOpen && (
                <TaskCommentDialog
                  onSaved={onCommentSaved}
                  onClosed={() => setMemoOpen(false)}
                  task={task}
                />
              )}
            </>
          )}
        </div>
      )}
    </Draggable>
  );
};
