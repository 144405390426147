import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { User } from "./user.entity";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { AccessibilityUserDialogComponent } from "../accessibility-user-dialog/accessibility-user-dialog.component";
import { EntityManager, Ops, EntityQueryFilter } from "../entity.service";
import { DialogService } from "../dialog.service";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-accessibility-users",
  templateUrl: "./accessibility-users.component.html",
  styleUrls: ["./accessibility-users.component.scss"],
})
export class AccessibilityUsersComponent implements OnInit {
  public filterQuery = "";
  public response: RestResponse<User[]>;

  roleId: string;
  roleFilterConfig = {
    allowNothing: true,
    title: "Rol",
    icon: "people",
    entityName: "roles",
    nameField: "id",
    descriptionField: "description",
    sortField: "description",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
  };

  protected filterQueryChanged = new Subject<string>();

  protected get userService() {
    return this.entityManager.get(User);
  }

  constructor(
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly entityManager: EntityManager
  ) {}

  ngOnInit() {
    this.fetchUsers();
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.fetchUsers());
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  protected async openUser(user: User) {
    await this.dialogService.open(
      this.changeDetector,
      AccessibilityUserDialogComponent,
      { data: user }
    );
    await this.authService.refreshAll(true);
  }

  isConfigured(user: User) {
    return !!user.__grantConfigs__.find((c) => !!c.value);
  }

  async fetchUsers() {
    const filters: EntityQueryFilter[] = [];

    if (this.filterQuery) {
      filters.push(...Array.from(this.query("name")));
      filters.push(...Array.from(this.query("identity")));
    } else {
      filters.push(...Array.from(this.query()));
    }

    this.response = await this.userService.query({
      take: this.roleId ? 0 : 30,
      filters,
      orders: [{ field: "id", direction: "ASC" }],
      relations: ["role", "grantConfigs"],
    });

    this.response.value.forEach((u) => (u.isConfigured = this.isConfigured(u)));
  }

  protected *query(field?: string) {
    yield {
      field: "stage",
      operator: "Equal",
      value: "1000",
      isOr: false,
    };

    if (this.roleId) {
      yield {
        field: "roleId",
        operator: "Equal",
        value: this.roleId,
        isOr: false,
      };
    }

    if (field) {
      yield {
        field: field,
        operator: "Like",
        value: this.filterQuery,
        isOr: true,
      };
    }
  }
}
