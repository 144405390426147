import { Entity } from "src/app/entity.service";

export abstract class CrudEvent {}

export class CrudLoadRequestEvent extends CrudEvent {}

export class CrudWithPayloadEvent<T extends Entity> extends CrudEvent {
  constructor(
    readonly props: {
      readonly entity: T;
    }
  ) {
    super();
  }
}

export class CrudAddedEvent extends CrudEvent {}

export class CrudModifiedEvent<
  T extends Entity
> extends CrudWithPayloadEvent<T> {}

export class CrudSavedEvent<T extends Entity> extends CrudWithPayloadEvent<T> {}

export class CrudDeletedEvent<
  T extends Entity
> extends CrudWithPayloadEvent<T> {}

export class CrudOrderedEvent extends CrudEvent {
  constructor(
    readonly props: {
      readonly startIndex: number;
      readonly endIndex: number;
    }
  ) {
    super();
  }
}
