import { Entity } from "../entity.service";
import { AppPlanCompetence } from "./app-plan-competence.entity";
import { AppContent } from "../app-contents/app-content.entity";

export class AppPlanCompetenceSetting extends Entity {
  type = "app.plan_competence_settings";

  name: string;
  is_visible: boolean;

  plan_competence_id: number;
  content_id: number;

  __content__?: AppContent;
  __plan_competence__?: AppPlanCompetence;

  created_at?: Date;
  updated_at?: Date;

  get content() {
    return this.__content__;
  }

  get planCompetence() {
    return this.__plan_competence__;
  }

  get hasContent() {
    return !!this.content_id;
  }
}
