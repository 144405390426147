import { Entity } from "../entity.service";

export class AppNotification extends Entity {
  type = "app.notifications";

  data: {
    icon: string;
    subject: string;
    body: string;
    data?: {
      route_page?: string;
      route_params?: { id: string };
    };
  };

  read_at?: Date;
  created_at?: Date;
  updated_at?: Date;
}
