import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { Project } from "../project/project.entity";

export class DocumentMeta extends Entity {
  type = "document_metas";

  userId: string;
  projectId: string;

  description: string;
  mimeType: string;

  __user__?: User;
  __project__?: Project;
}
