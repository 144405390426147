import { EntityQueryFilter } from "./../../../entity.service";
import { orderBy } from "lodash";
import {
  Entity,
  EntityQueryOrder,
  EntityService,
} from "src/app/entity.service";
import { RestResponse } from "src/app/rest.service";
import { Bloc } from "../../bloc/bloc";
import {
  CrudAddedEvent,
  CrudDeletedEvent,
  CrudEvent,
  CrudLoadRequestEvent,
  CrudModifiedEvent,
  CrudOrderedEvent,
  CrudSavedEvent,
} from "./crud.event";
import { CrudErrorState, CrudLoadedState, CrudState } from "./crud.state";

export abstract class CrudBloc<T extends Entity> extends Bloc<
  CrudEvent,
  CrudState
> {
  constructor(protected readonly initial: CrudState) {
    super(initial);
  }

  get defaultProperties(): any {
    return {};
  }

  get filters(): EntityQueryFilter[] {
    return [];
  }

  get orders(): EntityQueryOrder[] {
    return [{ field: "createdAt", direction: "ASC" }];
  }

  abstract get repo(): EntityService<T>;

  async *mapEventToState(event: CrudEvent) {
    if (event instanceof CrudLoadRequestEvent) {
      const response = await this.repo.query({
        orders: this.orders,
        filters: this.filters,
      });

      if (!response.hasError()) {
        yield new CrudLoadedState({ entities: response.value || [] });
      } else {
        yield new CrudErrorState({ message: response.error.message });
      }
    }

    if (this.state.value instanceof CrudLoadedState) {
      if (event instanceof CrudModifiedEvent) {
        yield new CrudLoadedState({
          entities: [...this.state.value.props.entities],
        });
      }

      if (event instanceof CrudAddedEvent) {
        const orderId = this.state.value.props.entities.length;

        yield new CrudLoadedState({
          entities: [
            ...this.state.value.props.entities,
            this.repo.concept({ orderId, ...this.defaultProperties }),
          ],
        });
      }

      if (event instanceof CrudSavedEvent) {
        const response = await this.repo.save(event.props.entity);

        if (!response.hasError()) {
          yield new CrudLoadedState({
            entities: this.state.value.props.entities.map((entity) =>
              entity.refId !== event.props.entity.refId
                ? entity
                : response.value
            ),
          });
        } else {
          yield new CrudErrorState({ message: response.error.message });
        }
      }

      if (event instanceof CrudDeletedEvent) {
        const response = event.props.entity.isConcept
          ? new RestResponse({})
          : await this.repo.delete(event.props.entity.id);

        if (!response.hasError()) {
          yield new CrudLoadedState({
            entities: [
              ...this.state.value.props.entities.filter(
                (entity) => entity.refId !== event.props.entity.refId
              ),
            ],
          });
        } else {
          yield new CrudErrorState({ message: response.error.message });
        }
      }

      if (event instanceof CrudOrderedEvent) {
        const _state = this.state.value;

        if (_state instanceof CrudLoadedState) {
          const entities = Array.from(_state.props.entities);
          const [removed] = entities.splice(event.props.startIndex, 1);

          entities.splice(event.props.endIndex, 0, removed);
          entities.forEach((entity, index) => (entity.orderId = index));

          yield new CrudLoadedState({ entities });

          const response = await this.repo.saveMany(entities);

          if (!response.hasError()) {
            yield new CrudLoadedState({ entities });
          } else {
            yield new CrudErrorState({ message: response.error.message });
          }
        }
      }
    }
  }
}
