import { formatNumber } from "@angular/common";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import * as speeddate from "speed-date";
import { User } from "../accessibility-users/user.entity";
import { MetacomService } from "../metacom.service";
import { Project } from "../project/project.entity";
import { SecureImage } from "../secured-image/secure-image";
import {
  DateGroupResponsible,
  DateGroupStackStructureDate,
} from "./entities/date-entities";
import { YearPlanningComponent } from "./year-planning.component";
import { YearPlanningDateTooltip } from "./YearPlanningDateTooltip";
import YearPlanningWeekHistory from "./YearPlanningWeekHistory";

interface YearPlanningReactProps {
  scrollTo?: number;
  original: YearPlanningComponent;
}

interface DeliveryPointCount {
  _rowid?: string;
  project: string;
  aantal: string;
}

const useFetchDeliveryPointCounts = (metacom: MetacomService) => {
  const [value, setValue] = useState<DeliveryPointCount[]>();

  useEffect(() => {
    metacom
      .queryTableAsync<DeliveryPointCount>({
        setName: "groothuis_mtc",
        tableName: "metacom_service_open",
      })
      .then((value) => setValue(value.value));
  }, []);

  return {
    value,
    loading: !value,
    findDeliveryPointCount: (projectId: string) => {
      const v = value && value.find((x) => x.project === projectId);
      return v && Math.ceil(parseInt(v.aantal));
    },
  };
};

const YearPlanningReact: React.FC<YearPlanningReactProps> = (props) => {
  const { loading, findDeliveryPointCount } = useFetchDeliveryPointCounts(
    props.original.metacom
  );
  const getAbbr = (user: User) => {
    return user ? user.abbreviation : "";
  };

  const truncate = (value: string, length: number) => {
    return value.length > length ? value.substr(0, length - 1) + "…" : value;
  };

  const getName = (user: User) => {
    const text = user ? user.name : "";
    return truncate(text, 20);
  };

  const style = (project: Project) => {
    return project.yearPlanningLine
      ? {
          borderTopColor: project.yearPlanningLine.colorCode,
          borderTopWidth: "4px",
        }
      : {};
  };

  const formatDate = (date: DateGroupStackStructureDate) => {
    return date && date.value
      ? formatDateRaw(date.value.value, date.date.dateFormat)
      : "";
  };

  const formatDateRaw = (value: any, format: string) => {
    return value ? speeddate.cached(format, new Date(value)) : null;
  };

  const formatName = (date: DateGroupStackStructureDate) => {
    return date && date.value && date.value.__user__
      ? getName(date.value.__user__)
      : "";
  };

  const responsibleAbbr = (responsible: DateGroupResponsible) => {
    return responsible &&
      responsible.__user__ &&
      responsible.__user__.abbreviation
      ? responsible.__user__.abbreviation
      : null;
  };

  const classes = (project: Project) => {
    return classNames({
      "free-week": !project.id,
      "new-week": project.isNewWeek,
      "active-week": project.isActiveWeek,
      "production-highlight": project.productionHighlight,
      "ease-highlight": project.id === props.original.highLightProjectId,
    });
  };

  React.useEffect(() => {
    const scrollContainer = document.querySelector(".yp-scrollable");

    if (scrollContainer) {
      if (scrollContainer.scrollTo) {
        scrollContainer.scrollTo({ top: props.scrollTo, behavior: "smooth" });
      } else {
        scrollContainer.scrollTop = props.scrollTo;
      }
    }
  }, []);

  const getFrameTypeAbbreveation = (frameType: string) => {
    const firstWord = frameType?.toLowerCase()?.split(" ")?.[0];

    switch (firstWord) {
      case "hout":
        return "H";
      case "kunststof":
        return "KS";
    }
  };

  const getFrameTypeDescription = (frameType: string, project: Project) => {
    const abbreviation = getFrameTypeAbbreveation(frameType);

    switch (abbreviation) {
      case "H":
        return project.frameDescriptionWood ?? frameType;
      case "KS":
        return project.frameDescriptionPlastic ?? frameType;
    }
  };

  return (
    <React.Fragment>
      <tr className="tooltips">
        <td>
          <ReactTooltip
            id="week-changes"
            backgroundColor="#0099cc"
            textColor="white"
            getContent={(dataTip) => (
              <React.Fragment>
                {!!dataTip && (
                  <YearPlanningWeekHistory
                    entities={props.original.entities}
                    projectId={dataTip}
                  />
                )}
              </React.Fragment>
            )}
          />
          <ReactTooltip
            id="house-image"
            backgroundColor="#0099cc"
            textColor="white"
            getContent={(dataTip) => (
              <SecureImage
                key={dataTip}
                src={dataTip}
                http={props.original.http}
                style={{
                  width: 300,
                  height: 300,
                  objectFit: "scale-down",
                }}
              />
            )}
          />
          <ReactTooltip
            id="date-stack"
            backgroundColor="#0099cc"
            textColor="white"
            place="left"
            effect="solid"
            getContent={(dataTip) => (
              <YearPlanningDateTooltip
                segment={dataTip}
                entities={props.original.entities}
                projects={props.original.data.items}
              />
            )}
          />
        </td>
      </tr>
      {props.original.data.items.map((project, projectIndex) => (
        <tr
          key={projectIndex}
          style={style(project)}
          className={classes(project)}
        >
          <td
            className="actions action-column"
            onClick={() => props.original.openShortcuts(project)}
          >
            <button
              className={classNames({
                "action-button": true,
                locked: !project.id,
              })}
            >
              <div
                className="ngx-mdc-icon material-icons"
                data-icon={project.id ? "more_horiz" : null}
              />
            </button>
          </td>

          <td
            className={classNames({
              clickable: props.original.showBuildingWeeks,
            })}
            data-for="week-changes"
            data-tip={props.original.showBuildingWeeks ? project.id : null}
            onClick={() => props.original.showBuildingWeekDialog(project)}
          >
            <strong>
              {project.isNewWeek || props.original.showBuildingWeeks
                ? project.buildingWeek
                : ""}
            </strong>
          </td>

          <td>{project.region}</td>

          <td
            title={getName(
              project.cachedValue && project.cachedValue.projectLeader
            )}
          >
            <p className="project-leader">
              {getAbbr(
                project.cachedValue && project.cachedValue.projectLeader
              )}
            </p>
          </td>

          <td>
            {project.deliveryDate && (
              <div className="delivery-info">
                <div
                  onClick={() => props.original.openService(project)}
                  className="delivered ngx-mdc-icon material-icons"
                  data-icon="check_circle"
                />
                <span className="delivery-point-count">
                  {findDeliveryPointCount(project.id) || "\u00A0"}
                </span>
              </div>
            )}
          </td>

          <td
            title={getName(
              project.cachedValue && project.cachedValue.projectMentor
            )}
          >
            {getAbbr(project.cachedValue && project.cachedValue.projectMentor)}
          </td>

          <td
            title={getName(
              project.cachedValue && project.cachedValue.salesEmployee
            )}
          >
            {getAbbr(project.cachedValue && project.cachedValue.salesEmployee)}
          </td>
          <td
            title={getName(
              project.cachedValue && project.cachedValue.buyAdvisor
            )}
          >
            {getAbbr(project.cachedValue && project.cachedValue.buyAdvisor)}
          </td>

          <td className="clickable">
            {/* TODO; use generic url-open service */}
            {project.id && (
              <a
                target="_blank"
                href={`#/office/metacom-project-agreement/${project.id}`}
              >
                {project.id}
              </a>
            )}
          </td>
          <td
            className="clickable"
            onClick={() => props.original.openNavigation(project)}
          >
            {project.description || "Niets gepland"}
          </td>

          {props.original.showAddress && (
            <td
              className="clickable small-col"
              onClick={() => props.original.openNavigation(project)}
            >
              {project.buildingStreet
                ? `${project.buildingStreet} ${project.buildingHouseNumber}, ${project.buildingZipCode} ${project.buildingCity}`
                : ""}
            </td>
          )}

          <td align="center">
            {project.qualityAssurance === "yes" && (
              <i
                className="ngx-mdc-icon material-icons"
                style={{
                  color: "#F4B400",
                  fontSize: 13,
                  margin: "auto",
                }}
                data-icon={"check_circle"}
              />
            )}
          </td>

          <td align="center">
            {project.prefabBathroom === "Ja" && (
              <i
                className="ngx-mdc-icon material-icons"
                style={{
                  color: "#A020F0",
                  fontSize: 13,
                  margin: "auto",
                }}
                data-icon={"check_circle"}
              />
            )}
          </td>

          <td
            data-for="house-image"
            data-tip={`volume/house-image/${project.id}`}
          >
            {project.houseType} &mdash; {project.houseShape}
          </td>

          <td title={`${project.score}`}>{project.scoreCategory}</td>
          <td title={project.concrete}>
            {project.id
              ? formatNumber(parseFloat(project.concrete), "nl", "1.0-0")
              : ""}
          </td>
          <td title={project.foundation}>
            <strong>{project.foundationAbbreviation}</strong>
          </td>
          <td title={project.heating}>
            <strong>{project.heatingAbbreviation}</strong>
          </td>
          <td title={getFrameTypeDescription(project.frameType, project)}>
            <strong>{getFrameTypeAbbreveation(project.frameType)}</strong>
          </td>

          <td title={project.isolationCutting}>
            <strong>{project.isolationCutting}</strong>
          </td>

          {project.dateGroupContainers.map((group, groupIndex) => (
            <td className="data" key={groupIndex}>
              {project.id && (
                <div className="data-content-wrapper">
                  <div className="data-content-wrapper-dates">
                    {group.stacks.map((stack, stackIndex) => (
                      <div
                        key={stackIndex}
                        data-for="date-stack"
                        data-tip={`${project.id}#${groupIndex}#${stackIndex}`}
                        className={classNames({
                          "data-content-wrapper-dates-date": true,
                          "data-content-wrapper-dates-date-disabled":
                            !stack.editable,
                          active:
                            stack.active &&
                            stack.active.value &&
                            !!stack.active.value.value,
                        })}
                        onClick={() =>
                          stack.editable &&
                          props.original.editDateStack(project, stack)
                        }
                      >
                        {/* <div className="data-content-wrapper-dates-date__tooltip">
                          <strong>{stack.title}</strong>
                          <div className="data-content-wrapper-dates-date__tooltip__dates">
                            <table>
                              <tbody>
                                {stack.dates.map((date, dateIndex) => (
                                  <tr key={dateIndex}>
                                    <td> {date.date.description} </td>
                                    <td> {formatDate(date) || "-"} </td>
                                    <td> {formatName(date) || "-"}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                        <div
                          className="data-content-wrapper-dates-date__icon ngx-mdc-icon material-icons"
                          style={{
                            color: stack.active.isValid
                              ? stack.active.date.colorCode
                              : "#ee7762",
                          }}
                          data-icon={
                            stack.active.isValid
                              ? stack.active.date.iconId
                              : "close"
                          }
                        />
                        <p className="data-content-wrapper-dates-date__name">
                          {stack.active.isValid ? formatDate(stack.active) : ""}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="data-content-wrapper-options">
                    {group.logicFields.map((field, fieldIndex) => (
                      <div
                        key={fieldIndex}
                        className="data-content-wrapper-options-option is-set"
                      >
                        <div
                          className="ngx-mdc-icon material-icons data-content-wrapper-options-option__icon"
                          style={{
                            color: field.colorCode,
                          }}
                          data-icon={field.iconId}
                        />
                        <div className="data-content-wrapper-options-option__tooltip">
                          <strong>{field.fieldName}:</strong>
                          <p>{field.conditionDescription}</p>
                        </div>
                      </div>
                    ))}

                    {group.group.hasResponsible && (
                      <div
                        className={classNames({
                          "data-content-wrapper-options-option": true,
                          "is-set": group.responsible,
                        })}
                        onClick={() =>
                          props.original.editResponsible(project, group)
                        }
                      >
                        <div
                          className={classNames({
                            "ngx-mdc-icon": true,
                            "material-icons": true,
                            "data-content-wrapper-options-option__icon": true,
                            "mdc-typography": responsibleAbbr(
                              group.responsible
                            ),
                            abbreviation: responsibleAbbr(group.responsible),
                          })}
                          data-icon={
                            !responsibleAbbr(group.responsible)
                              ? "accessibility"
                              : null
                          }
                        >
                          {responsibleAbbr(group.responsible)}
                        </div>
                        {group.responsible && (
                          <div className="data-content-wrapper-options-option__tooltip">
                            <p>{getName(group.responsible.__user__)}</p>
                          </div>
                        )}
                      </div>
                    )}

                    {group.group.hasComments && (
                      <div
                        className={classNames({
                          "data-content-wrapper-options-option": true,
                          "is-set": group.comments && group.comments.length,
                        })}
                        onClick={() =>
                          props.original.editComments(project, group)
                        }
                      >
                        <div
                          className="ngx-mdc-icon material-icons data-content-wrapper-options-option__icon"
                          data-icon="note"
                        />
                        {group.activeComment && (
                          <div className="data-content-wrapper-options-option__tooltip">
                            <p>{group.activeComment.value}</p>
                            <p>
                              <strong>
                                {getName(group.activeComment.__user__)}
                              </strong>
                              {formatDateRaw(
                                group.activeComment.createdAt,
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </td>
          ))}
        </tr>
      ))}
    </React.Fragment>
  );
};

export default YearPlanningReact;
