import { Component, OnInit, Inject } from "@angular/core";
import { Project } from "../project/project.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import * as moment from "moment";

@Component({
  selector: "app-contract-date-dialog",
  templateUrl: "./contract-date-dialog.component.html",
  styleUrls: ["./contract-date-dialog.component.scss"]
})
export class ContractDateDialogComponent implements OnInit {
  compatibleModel: string;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: ContractDateDialogComponentData,
    protected readonly dialogRef: MdcDialogRef<ContractDateDialogComponent>
  ) {}

  ngOnInit() {
    if (this.data.value) {
      this.compatibleModel = moment(this.data.value).format("YYYY-MM-DD");
    }
  }

  save() {
    this.dialogRef.close(
      this.compatibleModel ? moment(this.compatibleModel).toDate() : null
    );
  }
}

export class ContractDateDialogComponentData {
  constructor(
    readonly description: string,
    readonly project: Project,
    readonly value?: string | Date
  ) {}
}
