import gql from 'graphql-tag';

export const serviceTicketImageCountQuery = gql`
  query serviceTicketImageCountQuery($entityIds: JSON) {
    groups: EntityImageEntityAggregateGroupCount(query:{
      filters:[{ field: "entityId", operator: "In", valueComplex: $entityIds }]
    }, groupByField: "entityId") {
      key
      count
    }
  }
`;
