import { useState } from "react";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { RestService } from "../rest.service";
import {
  MailTrafficItemMessageAttachmentDto,
  MailTrafficItemMessageDto,
} from "./dto/mail-traffic-item-message.dto";
import { MailTrafficItemDto } from "./dto/mail-traffic-item.dto";

export const useFetchMail = (restService: RestService) => {
  const [state, setState] = useState<{
    data: MailTrafficItemDto[];
    fuzzyText: string;
    errors: any;
    loading: boolean;
  }>({
    data: undefined,
    fuzzyText: undefined,
    errors: [],
    loading: false,
  });

  return {
    data: state.data,
    fuzzyText: state.fuzzyText,
    loading: state.loading,
    errors: state.errors,
    fetchMail: async (projectId: string, fuzzyText?: string) => {
      setState({ ...state, errors: [], loading: true });

      const response = await restService.post<{
        searchResultItems: MailTrafficItemDto[];
      }>(`mail-store/find`, {
        projectId,
        ...(fuzzyText && fuzzyText.length > 0 ? { fuzzyText } : {}),
      });

      setState({
        loading: false,
        data: response.hasError() ? null : response.value.searchResultItems,
        fuzzyText: fuzzyText,
        errors: response.hasError() ? response.error : null,
      });
    },
  };
};

export const useFetchMailMessage = (restService: RestService) => {
  const [state, setState] = useState<{
    data: MailTrafficItemMessageDto;
    errors: any;
    loading: boolean;
  }>({
    data: undefined,
    errors: [],
    loading: false,
  });

  return {
    data: state.data,
    loading: state.loading,
    errors: state.errors,
    fetchMailMessage: async (groupId: number, mailId: number) => {
      setState({ ...state, data: undefined, errors: [], loading: true });

      const response = await restService.get<MailTrafficItemMessageDto>(
        `mail-store/message/${groupId}/${mailId}`
      );

      setState({
        loading: false,
        data: response.hasError()
          ? null
          : { ...response.value, mailId, groupId },
        errors: response.hasError() ? response.error : null,
      });
    },
  };
};

const createAttachmentUrl = (
  groupId: number,
  mailId: number,
  attachment: MailTrafficItemMessageAttachmentDto
) => {
  return `mail-store/message/${groupId}/${mailId}/attachment/${attachment.downloadIdentifier}/${attachment.fileName}`;
};

export const useOpenMailAttachment = (restService: RestService) => {
  const [state, setState] = useState<{
    downloadIdentifier: string;
    loading: boolean;
  }>({
    downloadIdentifier: undefined,
    loading: false,
  });

  return {
    downloadIdentifier: state.downloadIdentifier,
    loading: state.loading,
    openMailAttachment: async (
      groupId: number,
      mailId: number,
      attachment: MailTrafficItemMessageAttachmentDto
    ) => {
      setState({
        ...state,
        loading: true,
        downloadIdentifier: attachment.downloadIdentifier,
      });

      const response = await restService.getFile(
        createAttachmentUrl(groupId, mailId, attachment),
        attachment.contentType,
        attachment.fileName
      );

      setState({ ...state, loading: false, downloadIdentifier: undefined });

      const url = URL.createObjectURL(response);
      window.open(url);
    },
  };
};

export const useReadMailImage = (restService: RestService) => {
  return {
    readMailImage: async (
      groupId: number,
      mailId: number,
      attachment: MailTrafficItemMessageAttachmentDto
    ) => {
      const response = await restService.getFile(
        createAttachmentUrl(groupId, mailId, attachment),
        attachment.contentType,
        attachment.fileName
      );

      return URL.createObjectURL(response);
    },
  };
};

export const useStoreMailToInbox = (
  authService: AuthService,
  cellaService: CellaService
) => {
  const [state, setState] = useState<{
    loading: boolean;
  }>({
    loading: false,
  });

  return {
    loading: state.loading,
    storeMailToInbox: async (message: MailTrafficItemMessageDto) => {
      setState({ loading: true });

      await cellaService.sendEmail({
        from: {
          address: "no-reply@groothuisbouw.nl",
          name: "Groothuisbouw Portaal",
        },
        to: authService.user.email,
        subject: message.subject,
        html: message.htmlBody,
        attachments: message.attachments.map((attachment) => ({
          filename: attachment.fileName,
          cid: attachment.contentID,
          path: `${environment.baseUrl}/${createAttachmentUrl(
            message.groupId,
            message.mailId,
            attachment
          )}`,
        })),
      });

      setState({ loading: false });
    },
  };
};
