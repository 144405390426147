export const grants = {
  default: {
    zendesk_environment: "default:zendesk_environment",
    greeting_template_id: "default:greeting_template_id",
  },

  year_planning: {
    show_all: "year_planning:show_all",

    disable_dates: "year_planning:disable_dates",

    set_dates: "year_planning:set_dates",
    set_date_comments: "year_planning:set_date_comments",
    set_date_responsibles: "year_planning:set_date_responsibles",

    edit_date_groups: "year_planning:edit_date_groups",
    edit_year_planning_lines: "year_planning:edit_year_planning_lines",
    edit_blocked_calendar_weeks: "year_planning:edit_blocked_calendar_weeks",

    production_hours_highlight_threshold:
      "year_planning:production_hours_highlight_threshold",
    edit_building_weeks: "year_planning:edit_building_weeks",
    show_building_weeks: "year_planning:show_building_weeks",

    columns_include: "year_planning:columns_include",
    columns_exclude: "year_planning:columns_exclude",
    edit_columns_include: "year_planning:edit_columns_include",
    edit_columns_exclude: "year_planning:edit_columns_exclude",

    show_adress: "year_planning:show_address",
  },

  documents: {
    show_legacy: "documents:show_legacy",
    supplier_documents: "documents:supplier_documents",
    supplier_documents_show_all: "documents:supplier_documents_show_all",
    app_documents: "documents:app_documents",
    default_root: "document:default_root",
  },

  drawers: {
    edit_orders: "drawers:edit_orders",
    create_orders: "drawers:create_orders",
    create_absents: "drawers:create_absents",
    arrange_drawers: "drawers:arrange_drawers",
  },

  metacom_staff: {
    show_bhv_info: "metacom_staff:show_bhv_info",
  },

  metacom_price: {
    allow_filter: "metacom_price:allow_filter",
    show_valid_from: "metacom_price:show_valid_from",
    show_internal_documents: "metacom_price:show_internal_documents",
  },

  service_overview: {
    allow_filter: "service_overview:allow_filter",
    allow_reopen: "service:allow_reopen",
  },

  picklist: {
    include_picklist_definition_id: "picklist:include_picklist_definition_id",
    exclude_picklist_definition_id: "picklist:exclude_picklist_definition_id",
  },

  cargo: {
    create_cargo: "cargo:create_cargo",
    edit_element_groups: "cargo:edit_element_groups",
  },

  cargo_overview: {
    filter_projects: "cargo_overview:filter_projects",
    allow_picking: "cargo_overview:allow_picking",
  },

  planning_employee: {
    allow_filter: "planning_employee:allow_filter",
  },

  orders: {
    department_id: "orders:department_id",
  },

  work_orders: {
    allow_filter: "work_orders:allow_filter",
    required_company_codes: "work_orders:required_company_codes",
  },

  hour_declarations: {
    template_id: "hour_declarations:template_id",
    type_overrides: "hour_declarations:type_overrides",
    allow_copy_hours: "hour_declarations:allow_copy_hours",
    copy_hours_ids: "hour_declarations:copy_hours_ids",
    manage_others: "hour_declarations:manage_others",
  },

  employees: {
    allow_filter: "employees:allow_filter",
    show_internal_documents: "employees:show_internal_documents",
  },

  work_actions: {
    allow_filter: "work_orders:allow_filter",
    edit_categories: "work_actions:edit_categories",
  },

  app_project_costs: {
    show_all: "app_project_costs:show_all",
    allow_payments: "app_project_costs:allow_payments",
    show_contract_sum: "app_project_costs:show_contract_sum",
    requires_approvement: "app_project_costs:requires_approvement",
  },

  metacom_project_agreement: {
    hide_tabs: "metacom_project_agreement:hide_tabs",
  },

  metacom_invoice_payment_batch: {
    allow_filter: "metacom_invoice_payment_batch:allow_filter",
  },

  planning_table_layout: {
    allow_modify: "planning_table_layout:allow_modify",
  },

  planning_construction: {
    max_week: "planning_construction:max_week",
  },

  service_planning: {
    allow_modify: "service_planning:allow_modify",
  },

  installations_picklist: {
    include_picklist_definition_id:
      "installations_picklist:include_picklist_definition_id",
    exclude_picklist_definition_id:
      "installations_picklist:exclude_picklist_definition_id",
  },

  construction_installation_report: {
    allow_unlock: "construction_installation_report:allow_unlock",
  },
};

export class GrantsInfo {
  constructor(
    readonly data: {
      readonly description: string;
      readonly type: "text" | "select" | "boolean";
      readonly options?: string[];
    }
  ) {}
}

export const grantsInfo = {
  hour_declarations: {
    type_overrides: new GrantsInfo({
      description: "Type overschrijven (920-10,920-20)",
      type: "text",
    }),
    template_id: new GrantsInfo({
      description: "Template",
      type: "select",
      options: ["office", "office_outside", "external"],
    }),
  },
  service_overview: {
    allow_filter: new GrantsInfo({
      description: "Filteren toestaan (true, false)",
      type: "text",
    }),
  },
  work_orders: {
    allow_filter: new GrantsInfo({
      description: "Filteren toestaan (true, false)",
      type: "text",
    }),
  },
  planning_employee: {
    allow_filter: new GrantsInfo({
      description: "Filteren toestaan (true, false)",
      type: "text",
    }),
  },
  orders: {
    department_id: new GrantsInfo({ description: "Afdeling", type: "text" }),
  },
  work_actions: {
    edit_categories: new GrantsInfo({
      description: "Categorien aanpassen (true, false)",
      type: "text",
    }),
  },
};
