import { async } from "@angular/core/testing";
import * as React from "react";
import * as speeddate from "speed-date";
import { User } from "../accessibility-users/user.entity";
import {
  AppPhaseSectionMark,
  AppPhaseSectionMarkValue,
} from "../app-phases/app-phase.entity";
import { EntityManager, Ops } from "../entity.service";
import { Project } from "../project/project.entity";
import { DebouncePromise } from "../tools/debounce-promise";
import {
  DateGroupStackStructure,
  DateGroupStackStructureDate,
} from "./entities/date-entities";

interface YearPlanningDateTooltipProps {
  segment: string;
  projects: Project[];
  entities: EntityManager;
}

const debouncer = new DebouncePromise();

export const YearPlanningDateTooltip: React.FC<YearPlanningDateTooltipProps> =
  ({ segment, entities, projects }) => {
    const [stack, setStack] = React.useState<DateGroupStackStructure>(null);
    const [marks, setMarks] = React.useState<AppPhaseSectionMark[]>([]);
    const [values, setValues] = React.useState<AppPhaseSectionMarkValue[]>([]);

    const fetchDates = async () => {
      const repo = entities.get(AppPhaseSectionMark);
      const response = await repo.query({});

      if (!response.hasError()) {
        setMarks(response.value);
      }
    };

    const fetchDateValues = async (projectId: string) => {
      const repo = entities.get(AppPhaseSectionMarkValue);
      const response = await repo.query({
        filters: [Ops.Field("project_id").Equals(projectId)],
      });

      if (!response.hasError()) {
        setValues(response.value);
      }
    };

    const aquire = () => {
      const [projectId, groupId, stackId] = segment.split("#");

      const stack: DateGroupStackStructure = projects.find(
        (p) => p.id === projectId
      ).dateGroupContainers[groupId].stacks[stackId];

      setStack(stack);
      setValues(undefined);

      debouncer.perform(() => fetchDateValues(projectId));
    };

    /** Legacy */
    const formatDate = (date: DateGroupStackStructureDate) => {
      return date && date.value
        ? formatDateRaw(date.value.value, date.date.dateFormat)
        : "";
    };

    const formatDateRaw = (value: any, format: string) => {
      return value ? speeddate.cached(format, new Date(value)) : null;
    };

    const formatName = (date: DateGroupStackStructureDate) => {
      return date && date.value && date.value.__user__
        ? getName(date.value.__user__)
        : "";
    };

    const truncate = (value: string, length: number) => {
      return value.length > length ? value.substr(0, length - 1) + "…" : value;
    };

    const getName = (user: User) => {
      const text = user ? user.name : "";
      return truncate(text, 20);
    };

    const isActive = (item: DateGroupStackStructureDate) => {
      if (!item.date.phaseSectionMarkId) {
        return item.date.isOptional
          ? item.value && item.value.isActivated === true
          : true;
      }

      if (values) {
        const current = values.find(
          (e) => e.phase_section_mark_id === item.date.phaseSectionMarkId
        );

        if (current) {
          return current.activated;
        }
      }

      const mark = marks.find((e) => e.id === item.date.phaseSectionMarkId);

      return mark ? mark.is_standard : true;
    };

    React.useEffect(() => {
      if (!!segment) {
        aquire();
      }
    }, [segment]);

    React.useEffect(() => {
      fetchDates();
    }, []);

    return (
      <React.Fragment>
        {stack && (
          <React.Fragment>
            <div
              style={{
                paddingBottom: 12,
              }}
            >
              <strong>{stack.title}</strong>
            </div>

            {stack.dates.map((date, dateIndex) => (
              <div
                key={dateIndex}
                style={{
                  fontSize: "0.7rem",
                  display: "flex",
                  flexDirection: "row",
                  borderTop: "1px solid white",
                  minWidth: 200,
                  fontStyle: isActive(date) ? "" : "italic",
                  opacity: isActive(date) ? "" : "0.75",
                  textDecoration: isActive(date) ? "" : "line-through",
                }}
              >
                <div
                  style={{
                    width: 128,
                    padding: 6,
                    paddingLeft: 0,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {date.date.description}
                </div>
                <div
                  style={{
                    width: 100,
                    padding: 6,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {formatDate(date) || "-"}
                </div>
                <div
                  style={{
                    width: 100,
                    padding: 6,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {formatName(date) || "-"}
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };
