import * as React from "react";
import { HttpClient } from "@angular/common/http";
import {
  ResourceModel,
  SchedulerPro,
} from "@bryntum/schedulerpro/schedulerpro.umd.js";
import { SecureImage } from "src/app/secured-image/secure-image";
import { MechanicDto } from "../dtos/mechanic.dto";
import { calculateResourceHours } from "../service-planning.functions";
import { useEffect, useMemo } from "react";

export interface SchedulerResourceComponentProps {
  http: HttpClient;
  record: ResourceModel & MechanicDto;
  scheduler: SchedulerPro;
}

export function SchedulerResourceComponent({
  http,
  record,
  scheduler,
}: SchedulerResourceComponentProps) {
  const newTotal = useMemo(
    () => calculateResourceHours(record.events, scheduler),
    [record, record.events, record.assignments]
  );

  const getPersonId = (id: string) => {
    return id.substr(1);
  };

  useEffect(() => {
    record.beginBatch();
    record.totalHours = newTotal;
    record.set("totalHours", newTotal);
    record.endBatch();
  }, [newTotal]);

  useEffect(() => {
    record.beginBatch();
    record.totalHours = newTotal;
    record.set("totalHours", newTotal);
    record.endBatch();
  }, []);

  return (
    <div className="b-resource-info">
      <SecureImage
        noDebounce
        http={http}
        placeholder={<></>}
        src={`volume/staff-image/${record.companyId}/${getPersonId(
          record.id as string
        )}`}
        className="b-resource-avatar b-resource-image"
        style={{ objectFit: "cover", border: "1px solid #eeeeee" }}
      />
      <dl>
        <dt>{record.name}</dt>
        <dd className="b-resource-events">{record.totalHours || 0} uur</dd>
      </dl>
    </div>
  );
}
