import * as React from "react";
import { Box, makeStyles, Paper, Button } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { EntityManager } from "src/app/entity.service";
import { SignatureBloc } from "./signature.bloc";
import { SignatureLoadSuccessState, SignatureState } from "./signature.state";
import {
  SignatureLoadRequestEvent,
  SignatureStrokeEndEvent,
} from "./signature.event";
import { BlocBuilder } from "src/app/service-settings/bloc/bloc.builder";
import SignatureCanvas from "react-signature-canvas";
import { DeliveryListSignatureEntity } from "../../delivery-list.entity";
import { SecureImage } from "src/app/secured-image/secure-image";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  meters: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  paper: {
    marginBottom: 12,
  },
  signature: {
    width: "100%",
    border: "1px solid #eeeeee",
  },
  signaturePreview: {
    height: 300,
    width: "100%",
    border: "1px solid #eeeeee",
  },
  signatures: {
    display: "flex",
    flexDirection: "row",
  },
  signatureContainer: {
    flex: 1,
    width: "50%",
  },
  signatureCheckMark: {
    fontSize: 16,
    color: "green",
  },
}));

export interface SignatureComponentProps {
  http: HttpClient;
  apollo: Apollo;
  projectId: string;
  entities: EntityManager;
  disabled?: boolean;
}

interface SignatureWrapperComponentProps {
  disabled?: boolean;
  className: string;
  editMode: boolean;
  preview: React.ReactNode;
  onChanged: (canvas: SignatureCanvas) => void;
}

function SignatureWrapperComponent(props: SignatureWrapperComponentProps) {
  const { onChanged, className, preview } = props;
  const ref = React.useRef<SignatureCanvas>();
  const [editMode, setEditMode] = React.useState(props.editMode);

  return (
    <>
      {editMode && (
        <SignatureCanvas
          ref={ref}
          penColor="black"
          clearOnResize={true}
          canvasProps={{ height: 300, className: className }}
        />
      )}


      {!editMode ? preview : <></>}

      {editMode && <>
        <Button
          disabled={props.disabled}
          style={{ marginTop: 16 }}
          variant="outlined"
          onClick={() => {
            onChanged(ref.current);
            setEditMode(false);
          }}
        >
          Opslaan
        </Button>

        <Button
          disabled={props.disabled}
          style={{ marginLeft: 16, marginTop: 16 }}
          variant="outlined"
          onClick={() => ref.current.clear()}
        >
          Leeg
        </Button>
      </>}

      {!editMode && (
        <>
          <Button
            disabled={props.disabled}
            style={{ marginTop: 16 }}
            variant="outlined"
            onClick={() => setEditMode(true)}
          >
            Bewerken
          </Button>
        </>
      )}
    </>
  );
}

export const SignatureComponent: React.FC<SignatureComponentProps> = ({
  http,
  entities,
  apollo,
  projectId,
  disabled,
}) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new SignatureBloc(apollo, entities).add(
      new SignatureLoadRequestEvent({ projectId })
    )
  );

  const getSignatureUrl = (signature: DeliveryListSignatureEntity) => {
    return `documents/view/${signature.documentMetaId}?v=${Math.random()}`;
  };

  function mapSignatureToNode(
    signature: DeliveryListSignatureEntity,
    title: string
  ) {
    return (
      <div className={classes.signatureContainer}>
        <h3 style={{ marginBottom: 12, marginTop: 0, fontWeight: "bold" }}>
          {title}{" "}
          {signature.documentMetaId && (
            <Check className={classes.signatureCheckMark} />
          )}
        </h3>

        <SignatureWrapperComponent
          disabled={disabled}
          editMode={!signature.documentMetaId}
          className={classes.signature}
          onChanged={(canvas) => {
            bloc.add(
              new SignatureStrokeEndEvent({
                signature,
                canvas,
              })
            );
          }}
          preview={
            <SecureImage
              className={classes.signaturePreview}
              noCache
              noDebounce
              http={http}
              src={getSignatureUrl(signature)}
            />
          }
        />
      </div>
    );
  }

  function* mapStateToNode(state: SignatureState) {
    if (state instanceof SignatureLoadSuccessState) {
      yield (
        <div key="dl-signatures" className={classes.signatures}>
          {mapSignatureToNode(state.props.signatureEmployee, "Werknemer")}

          <div style={{ width: 32 }}></div>

          {mapSignatureToNode(state.props.signatureCustomer, "Opdrachtgever")}
        </div>
      );
    }
  }

  return (
    <Paper className={classes.paper} elevation={1}>
      <Box p={2}>
        <BlocBuilder
          bloc={bloc}
          builder={(state) => <>{...Array.from(mapStateToNode(state))}</>}
        />
      </Box>
    </Paper>
  );
};
