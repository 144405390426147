import { OrderableEntity } from "../entity.service";
import { AppProjectFieldGroupAssignmentValue } from "./app-project-field-group-assignment-value.entity";
import { RestResponse } from "../rest.service";
import { orderBy } from "lodash";

export class AppProjectFieldGroupAssignment extends OrderableEntity {
  type = "app.project_field_group_assignments";

  project_field_group_id: number;
  project_field_type: string;
  project_field_copy_type?: string;

  value_type: "text" | "values" | "number" | "date";
  is_required: boolean;
  tooltip?: string;

  created_at?: Date;
  updated_at?: Date;

  values: AppProjectFieldGroupAssignmentValue[];

  set __values__(value: AppProjectFieldGroupAssignmentValue[]) {
    const items = this.entityManager
      .get(AppProjectFieldGroupAssignmentValue)
      .healManyResponse(new RestResponse(value)).value;

    this.values = orderBy(items, (i) => i.order);
  }
}
