import gql from 'graphql-tag';

export const customerQuestionsUpdateMutation = gql`
  mutation customerQuestionsUpdateMutation(
    $input: InputCustomerQuestionEntity!
  ) {
    update: CustomerQuestionEntityUpdateOne(input: $input) {
      id
    }
  }
`;
