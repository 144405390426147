import { Injectable, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpResponse,
  HttpEventType,
} from "@angular/common/http";
import { Observable } from "rxjs";

/**
 * Service used for safe rest api calls.
 *
 * @export
 * @class RestService
 */
@Injectable({
  providedIn: "root",
})
export class RestService {
  /**
   * Creates an instance of RestService.
   * @param {HttpClient} httpClient
   * @memberof RestService
   */
  constructor(protected readonly httpClient: HttpClient) {}

  /**
   * Executes a GET operation.
   *
   * @template T
   * @param {string} url
   * @param {*} [params=null]
   * @returns
   * @memberof RestService
   */
  get<T>(url: string, params: any = null, withCredentials = false) {
    return this.safe<T>(
      this.httpClient.get<T>(url, { params: params, withCredentials })
    );
  }

  async getFile(
    url: string,
    type: string,
    fileName: string,
    params: any = null
  ) {
    const buffer = await this.httpClient
      .get(url, { params: params, responseType: "arraybuffer" })
      .toPromise();

    return this.arrayBufferToFile(buffer, type, fileName);
  }

  async getFileAsBlob(url: string) {
    return await this.httpClient.get(url, { responseType: "blob" }).toPromise();
  }

  async getFileAsDataUri(url: string) {
    const blob = await this.getFileAsBlob(url);

    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string);
    });
  }

  /**
   * Executes a POST operation.
   *
   * @template T
   * @param {string} url
   * @param {*} body
   * @returns
   * @memberof RestService
   */
  post<T>(url: string, body: any, withCredentials = false) {
    return this.safe<T>(
      this.httpClient.post<T>(url, body, { withCredentials })
    );
  }

  postNative(url: string, body: any) {
    const options: any = { responseType: "arraybuffer" };

    return this.httpClient.post(url, body, options).toPromise();
  }

  postFilesNative(url: string, files: File[]) {
    const form = new FormData(),
      headers = new Headers({
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      }),
      options: any = { headers, responseType: "arraybuffer" };
    files.forEach((f) => form.append("file", f, f.name));

    return this.httpClient.post(url, form, options).toPromise();
  }

  /**
   * Executes a POST form-data operation.
   *
   * @template T
   * @param {string} url
   * @param {File[]} files
   * @returns
   * @memberof RestService
   */
  postFiles<T>(url: string, files: File[], progress?: EventEmitter<number>) {
    const form = new FormData(),
      headers = new Headers({
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      }),
      options: any = { headers, reportProgress: true, observe: "events" };
    files.forEach((f) => form.append("file", f, f.name));

    return new Promise<T>((resolve, reject) => {
      this.httpClient.post<T>(url, form, options).subscribe((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (progress) {
              progress.next(event.loaded / event.total);
            }
            break;
          case HttpEventType.Response:
            if (event.ok) {
              resolve(event.body);
            } else {
              reject(event);
            }
            break;
        }
      });
    });
  }

  /**
   * Executes a PUT operation.
   *
   * @template T
   * @param {string} url
   * @param {*} body
   * @returns
   * @memberof RestService
   */
  put<T>(url: string, body: any) {
    return this.safe<T>(this.httpClient.put<T>(url, body));
  }

  /**
   * Executes a DELETE operation.
   *
   * @template T
   * @param {string} url
   * @returns
   * @memberof RestService
   */
  delete<T>(url: string) {
    return this.safe<T>(this.httpClient.delete<T>(url));
  }

  arrayBufferToBlob(buffer: ArrayBuffer, type: string) {
    return new Blob([buffer], { type });
  }

  arrayBufferToFile(buffer: ArrayBuffer, type: string, fileName: string) {
    return this.blobToFile(this.arrayBufferToBlob(buffer, type), fileName);
  }

  blobToFile(blob: Blob, fileName: string) {
    (blob as any).lastModified = new Date();
    (blob as any).name = fileName;
    return blob as File;
  }

  dataUriToBlob(dataURI: string, fileName: string) {
    let byteString: string;

    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ia], fileName, { type: mimeString });
  }

  async safe<T>(obs: Observable<T>) {
    try {
      return new RestResponse<T>(await obs.toPromise());
    } catch (e) {
      return new RestResponse<T>(null, e);
    }
  }

  async safeEvent<T>(obs: Observable<HttpEvent<T>>) {
    try {
      const event: any = (await obs.toPromise()) as HttpResponse<T>;
      return new RestResponse<T>(event as T);
    } catch (e) {
      return new RestResponse<T>(null, e);
    }
  }
}

/**
 * Response class of the rest service.
 *
 * @export
 * @class RestResponse
 * @template T
 */
export class RestResponse<T> {
  /**
   * Creates an instance of RestResponse.
   * @param {T} value
   * @param {*} [error=null]
   * @memberof RestResponse
   */
  constructor(public readonly value: T, public readonly error: any = null) {}

  /**
   * Indicates if the response has an error.
   *
   * @returns
   * @memberof RestResponse
   */
  hasError() {
    return !!this.error;
  }
}

export interface FileUploadResult {
  name: string;
  id: string;
}
