import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import {
  EntitySearchDialogConfig,
  EntitySearchDialogComponent,
} from "../entity-search-dialog/entity-search-dialog.component";
import { Entity } from "../entity.service";
import { RestService } from "../rest.service";
import { DialogService } from "../dialog.service";

@Component({
  selector: "app-entity-select",
  templateUrl: "./entity-select.component.html",
  styleUrls: ["./entity-select.component.scss"],
})
export class EntitySelectComponent implements OnInit, OnChanges {
  @Input() entityIdField = "id";

  @Input() entityId: any;
  @Output() entityIdChange = new EventEmitter<any>();

  @Input() entity: any;
  @Output() entityChange = new EventEmitter<any>();

  @Input() config: EntitySelectConfig = new EntitySelectConfig("none");
  @Input() disabled = false;

  @Input() hideIcon = false;

  @Input() mode = "default";
  @Input() tabindex = "0";

  get description() {
    return this.entity ? this.getDescription() : "Kies";
  }

  constructor(
    protected readonly restService: RestService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fetch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const idChanges = changes["entityId"];

    if (idChanges && !idChanges.currentValue) {
      this.entity = null;
    }
  }

  clear(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.setEntity(null);
  }

  async choose() {
    if (!this.disabled) {
      const entity: any = await this.dialogService.open(
        this.changeDetector,
        EntitySearchDialogComponent,
        { data: this.config, escapeToClose: false }
      );

      if (
        entity !== "close" &&
        (this.config.allowNothing || (entity && entity.id))
      ) {
        this.setEntity(entity);
      }
    }
  }

  async fetch() {
    if (this.entityId) {
      if (this.config.fixedResponse) {
        this.entity = this.config.fixedResponse.find(
          (e) => this.getId(e) === this.entityId
        );
      } else {
        const response = await this.restService.get<Entity>(
          `entity/${this.config.entityName}/${this.entityId}`
        );

        const responseValue = !response.hasError() ? response.value : null;

        this.entity = responseValue || this.fakeEntity();
      }
    }
  }
  protected setEntity(entity: Entity) {
    this.entity = entity;
    this.entityChange.next(this.entity);

    this.entityId = entity ? this.getId(entity) : null;
    this.entityIdChange.next(this.entityId);
  }

  protected getDescription() {
    if (this.config.descriptionFormat) {
      return this.config.descriptionFormat(this.entity);
    }

    return this.entity[this.config.descriptionField];
  }

  protected getId(entity: any) {
    return entity[this.entityIdField];
  }

  protected fakeEntity() {
    const entity = {};

    entity[this.config.nameField] = `#${this.entityId}`;
    entity[this.config.descriptionField] = `#${this.entityId}`;

    return entity;
  }
}
export class EntitySelectConfig extends EntitySearchDialogConfig {
  icon: string;
  title: string;

  constructor(public entityName: string) {
    super(entityName);
  }
}
