import { Pipe, PipeTransform } from '@angular/core';
import { RestResponse } from './rest.service';

@Pipe({
  name: 'fetchRelationAsync',
  pure: false
})
export class FetchRelationAsyncPipePipe implements PipeTransform {

  isPending: boolean;
  response: RestResponse<{}>;

  transform(relation: Promise<RestResponse<{}>>) {
    if (!this.isPending) {
      this.isPending = true;
      relation.then(response => this.response = response);
    }

    return this.response;
  }
}
