import { ActivatedRoute } from "@angular/router";
import { Component, Injector } from "@angular/core";
import {
  ReactAttunedComponent,
  reactAttunedComponentTemplate,
} from "../react-component/react-attuned.component";
import { PicklistComponent, PicklistComponentProps } from "./PicklistComponent";

@Component({
  template: reactAttunedComponentTemplate,
  styleUrls: ["./picklist.component.scss"],
})
export class PicklistComponentAngular extends ReactAttunedComponent<PicklistComponentProps> {
  component = PicklistComponent;

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  get documentId() {
    return this.route.snapshot.paramMap.get("documentId");
  }

  get picklistDefinitionId() {
    return this.route.snapshot.paramMap.get("picklistDefinitionId");
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly injector: Injector
  ) {
    super(injector);
  }

  props() {
    return {
      injector: this.injector,
      projectId: this.projectId,
      documentId: this.documentId,
      picklistDefinitionId: this.picklistDefinitionId,
    };
  }
}
