import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { MdcSnackbar } from "@angular-mdc/web";
import { GrantHelper } from "./grant.helper";
import { grantsInfo, GrantsInfo } from "./app-grant-config";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class GrantService {
  constructor(
    protected readonly snackbar: MdcSnackbar,
    protected readonly authService: AuthService
  ) {}

  getInfo(id: string) {
    const data = _.chain(Object.keys(grantsInfo))
      .map((e) =>
        Object.entries<GrantsInfo>(grantsInfo[e]).map((j) => ({
          id: `${e}:${_.first(j)}`,
          info: _.last(j) as GrantsInfo,
        }))
      )
      .flatten()
      .value();

    const query = data.find((e) => e.id === id);
    return query ? query.info : null;
  }

  isRouteAccessable(route: string) {
    if (this.authService.isSignedIn) {
      const audit = this.authService.jwt.routeAccess.find(
        (_) => route === _ || (_.length > 1 && route.indexOf(_) === 0)
      );

      return !!audit;
    }
  }

  var<T>(configId: string, defaultValue: T) {
    if (this.authService.isSignedIn) {
      return new GrantHelper(this.authService.user).var<T>(
        configId,
        defaultValue
      );
    }

    return defaultValue;
  }

  varIs(configId: string, comparator: string, silent = true) {
    const result = new GrantHelper(this.authService.user).varIs(
      configId,
      comparator
    );

    if (!result && !silent) {
      this.noRights();
    }

    return result;
  }

  protected noRights() {
    this.snackbar.open("Je hebt hiervoor onvoldoende rechten.", "Ok");
  }
}
