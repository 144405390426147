import { DateDefinition } from "../year-planning/entities/date-entities";
import * as moment from "moment";
/**
 * Class used to extend the base @see {Date} with extra properties required for this component.
 *
 * @class DateGroupItemExtended
 */
export class DateGroupItemExtended {
  /**
   * Indicates if the data of the @see {value} has been modified.
   *
   * @type {boolean}
   * @memberof DateGroupItemExtended
   */
  public isDirty: boolean;

  /**
   * Indicates if the group has been marked for deletion.
   *
   * @type {boolean}
   * @memberof DateGroupItemExtended
   */
  public isPendingDeletion: boolean;
  public isPendingAppImport: boolean;

  /**
   * Creates an instance of DateGroupItemExtended.
   * @param {Date} value
   * @memberof DateGroupItemExtended
   */
  constructor(public readonly value: DateDefinition) {
    this.isDirty = !value.id;
  }

  /**
   * Flips the state of the @see {isPendingDeletion} property.
   *
   * @memberof DateGroupItemExtended
   */
  toggleDeletion() {
    this.isPendingDeletion = !this.isPendingDeletion;
  }

  toggleAppImport() {
    this.isPendingAppImport = !this.isPendingAppImport;
    this.value.hash = this.isPendingAppImport
      ? moment().unix().toString()
      : null;

    this.markDirty();
  }

  /**
   * Marks the item as dirty.
   *
   * @memberof DateGroupItemExtended
   */
  markDirty() {
    this.isDirty = true;
  }

  set orderId(value: number) {
    this.value.orderId = value;
    this.isDirty = true;
  }
}
