import { DateWrapper } from "../date-stack-dialog.component";
import { DateState, DateStateField } from "./date-state";
import { setToday, valueOrToggle } from "./state.functions";

export class AppDateState implements DateState {
  isFieldEnabled(wrapper: DateWrapper, field: DateStateField): boolean {
    switch (field) {
      case DateStateField.Activated:
        return !wrapper.mark.is_standard;
      case DateStateField.Completed:
        return this.isFieldToggled(wrapper, DateStateField.Activated);
      case DateStateField.SendNotification:
        return (
          this.isFieldToggled(wrapper, DateStateField.Completed) &&
          !!wrapper.mark.notification_template_id
        );
      case DateStateField.ViewContent:
        return !!wrapper.mark.notification_template_id;
    }
  }

  isFieldToggled(wrapper: DateWrapper, field: DateStateField): boolean {
    switch (field) {
      case DateStateField.Activated:
        return wrapper.mark.is_standard || wrapper.markValue.activated;
      case DateStateField.Completed:
        return wrapper.markValue.completed;
      case DateStateField.SendNotification:
        return wrapper.sendNotification;
      case DateStateField.ViewContent:
        return (
          wrapper.mark.notificationTemplate &&
          !!wrapper.mark.notificationTemplate.content_id
        );
    }
  }

  toggleField(
    wrapper: DateWrapper,
    field: DateStateField,
    value?: boolean
  ): void {
    switch (field) {
      case DateStateField.Activated:
        {
          const isToggled = (wrapper.markValue.activated = valueOrToggle(
            wrapper.markValue.activated,
            value
          ));

          if (!isToggled) {
            this.toggleField(wrapper, DateStateField.Completed, false);
          }
        }
        break;
      case DateStateField.Completed:
        {
          const isToggled = (wrapper.markValue.completed = valueOrToggle(
            wrapper.markValue.completed,
            value
          ));

          if (isToggled) {
            setToday(wrapper, true);
          }

          this.toggleField(wrapper, DateStateField.SendNotification, isToggled);
        }
        break;
      case DateStateField.SendNotification:
        {
          wrapper.sendNotification = valueOrToggle(
            wrapper.sendNotification,
            value
          );
          wrapper.markValue.send_notification = wrapper.sendNotification;
        }
        break;
    }
  }
}
