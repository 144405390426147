import { HttpLink, HttpLinkModule } from "apollo-angular-link-http";
import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { InMemoryCache } from "apollo-cache-inmemory";
import { environment } from "../environments/environment";
import {
  HttpBatchLinkModule,
  HttpBatchLink,
} from "apollo-angular-link-http-batch";
import { ApolloLink } from "apollo-link";
import { extractFiles } from "extract-files";

const httpOptions = {
  uri: `${environment.baseUrl}/graphql`,
  withCredentials: true,
};

export function createApollo(httpLink: HttpLink, httpBatchLink: HttpBatchLink) {
  const link = ApolloLink.split(
    (operation) => extractFiles(operation).files.size > 0,
    httpLink.create(httpOptions),
    httpBatchLink.create(httpOptions)
  );

  return {
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule, HttpBatchLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
