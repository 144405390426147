import { Entity } from "../entity.service";

export class AppServiceCategory extends Entity {
  type = "app.service_categories";

  service_category_id: number;
  __service_categories__?: AppServiceCategory[];

  get childs() {
    return this.__service_categories__;
  }

  name: string;

  created_at?: Date;
  updated_at?: Date;
}
