import { Component, OnInit, Inject } from "@angular/core";
import { Project } from "../project/project.entity";
import {
  DateGroupResponsible,
  DateGroupContainer,
} from "../year-planning/entities/date-entities";
import { AuthService } from "../auth.service";
import { EntityManager } from "../entity.service";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { EntitySelectConfig } from "../entity-select/entity-select.component";

@Component({
  selector: "app-date-group-responsible-dialog",
  templateUrl: "./date-group-responsible-dialog.component.html",
  styleUrls: ["./date-group-responsible-dialog.component.scss"],
})
export class DateGroupResponsibleDialogComponent implements OnInit {
  responsibleConfig: EntitySelectConfig = {
    allowNothing: true,
    title: "Gebruiker",
    icon: "accessibility",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      {
        field: "roleId",
        operator: "IsNull",
        isNot: true,
      },
    ],
  };

  responsible: DateGroupResponsible;

  protected get dateGroupResponsibleService() {
    return this.entityManager.get(DateGroupResponsible);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: DateGroupResponsibleDialogComponentData,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialogRef<DateGroupResponsibleDialogComponent>
  ) {}

  ngOnInit() {
    this.responsible =
      this.data.group.responsible ||
      this.dateGroupResponsibleService.concept({
        projectId: this.data.project.id,
        dateGroupId: this.data.group.group.id,
      });

    this.responsible.projectId = this.data.project.id;
    this.responsible.dateGroupId = this.data.group.group.id;
  }

  async save() {
    if (this.responsible.userId) {
      await this.dateGroupResponsibleService.save(this.responsible);
    } else if (this.responsible.id) {
      await this.dateGroupResponsibleService.delete(this.responsible.id);
    }

    this.dialog.close("save");
  }
}

export class DateGroupResponsibleDialogComponentData {
  constructor(
    public readonly project: Project,
    public readonly group: DateGroupContainer
  ) {}
}
