import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { RestResponse, RestService } from "../rest.service";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { environment } from "../../environments/environment";
import { CellaService } from "../cella.service";
import { first, last } from "lodash";
import { AuthService } from "../auth.service";
import { TransactionService } from "../transaction.service";
import { UrlOpenService } from "../url-open.service";
import { Invoice } from "../invoices/invoice.entity";
import { MetacomService } from "../metacom.service";
import * as moment from "moment";
import {
  EntitySearchDialogConfig,
  EntitySearchDialogComponent,
} from "../entity-search-dialog/entity-search-dialog.component";
import { User } from "../accessibility-users/user.entity";
import { DialogService } from "../dialog.service";

@Component({
  selector: "app-invoice-judge-dialog",
  templateUrl: "./invoice-judge-dialog.component.html",
  styleUrls: ["./invoice-judge-dialog.component.scss"],
})
export class InvoiceJudgeDialogComponent implements OnInit {
  composeComment: string;
  composeCompleted: boolean;
  composeTransferUser: User;

  get canSave() {
    return this.composeComment && this.composeComment.length > 1;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: Invoice,
    protected readonly restService: RestService,
    protected readonly cellaService: CellaService,
    protected readonly metacomService: MetacomService,
    protected readonly authService: AuthService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactionService: TransactionService,
    protected readonly dialogService: DialogService,
    protected readonly dialog: MdcDialogRef<InvoiceJudgeDialogComponent>
  ) {}

  ngOnInit() {}

  open() {
    this.urlOpenService.open(this.getDocumentBaseUrl(), true);
  }

  async save() {
    await this.transactionService.perform<any[]>(() => this.saveStates());

    this.dialog.close();
  }

  async transfer() {
    const data = new EntitySearchDialogConfig("users");
    data.nameField = "id";
    data.descriptionField = "name";
    data.filterFields = ["name"];
    data.filters = [
      { field: "id", operator: "StartsWith", value: "2" },
      { field: "roleId", operator: "IsNull", isNot: true },
      { field: "stage", operator: "Equal", value: "1000" },
    ];
    data.allowNothing = true;

    const user: User = await this.dialogService.open(
      this.changeDetector,
      EntitySearchDialogComponent,
      { data }
    );

    if (user && user.id) {
      this.composeTransferUser = user;
      this.composeCompleted = false;
    } else {
      this.composeTransferUser = null;
    }
  }

  protected async saveStates() {
    if (this.composeComment && this.composeComment.length > 0) {
      await this.saveComment();
    }

    if (this.composeCompleted) {
      await this.approveInvoice();
    }

    if (this.composeTransferUser) {
      await this.transactionService.perform<any>(() =>
        this.saveTransfer(this.composeTransferUser.id)
      );
    } else {
      await this.sendEmail();
    }

    return new RestResponse([]);
  }

  protected async saveComment() {
    const origin = this.parseOrigin(this.data.herkomst_rubriek);

    return await this.cellaService.writeCustomFields({
      Company: origin["bdr"],
      CustomField: [
        {
          Entity: "1012",
          Origin: this.data.herkomst_rubriek,
          Code: "FACT-001",
          LineId: 1,
          SerialNumber: 1,
          SerialNumberNRelation: "",
          Contents: this.getNewComment(),
        },
      ],
    });
  }

  protected async saveTransfer(id: string) {
    const origin = this.parseOrigin(this.data.herkomst_rubriek);

    return await this.cellaService.writeCustomFields({
      Company: origin["bdr"],
      CustomField: [
        {
          Entity: "1012",
          Origin: this.data.herkomst_rubriek,
          Code: "FACT-005",
          LineId: 1,
          SerialNumber: 1,
          SerialNumberNRelation: "",
          Contents: id,
        },
        {
          Entity: "1012",
          Origin: this.data.herkomst_rubriek,
          Code: "FACT-100",
          LineId: 1,
          SerialNumber: 1,
          SerialNumberNRelation: "",
          Contents: "",
        },
      ],
    });
  }

  protected async approveInvoice() {
    const origin = this.parseOrigin(this.data.herkomst_factuur);

    return await this.cellaService.approveInvoice({
      Company: origin["bdr"],
      Entity: "1037",
      Origin: this.data.herkomst_factuur,
      Stage: "4000",
      ipDate: moment(new Date()).format("YYYY-MM-DD"),
      Comments: "",
      tt_obligation: "",
      tt_obligationline: "",
      tt_bookingline: "",
    });
  }

  protected async sendEmail() {
    await this.cellaService.sendEmail({
      from: {
        address: "administratie@groothuisbouw.nl",
        name: "Groothuisbouw",
      },
      to: environment.production
        ? "facturen@groothuisbouw.nl"
        : "wriezebos@groothuisbouw.nl",
      subject: "Factuur aangepast",
      attachments: this.composeCompleted
        ? []
        : [{ filename: "factuur.pdf", path: this.getDocumentBaseUrl() }],
      html: `
      Factuurnummer: ${this.data.factuur_nummer}<br />
      Goedgekeurd: ${this.composeCompleted ? "Ja" : "Nee"}<br />
      Door: ${this.authService.user.name}<br />
      ${await this.emailSegment()}
      Opmerking:<br/><br/>${this.getNewComment() || ""}<br/><br/>
      `,
    });
  }

  protected async emailSegment() {
    const financial = await this.fetchFinancial();
    const email = financial
      ? financial.relatie_email || financial.relatie_email_alg
      : "onbekend";

    return this.composeCompleted
      ? ""
      : `E-mailadres leverancier: ${email}<br />`;
  }

  protected getDocumentBaseUrl() {
    const base64 = btoa(this.data.document);

    return `${environment.baseUrl}/volume-public/file-legacy/0/${base64}`;
  }

  protected parseOrigin(origin: string) {
    const data = {};

    for (const pair of origin.split("¡")) {
      const values = pair.split(":");

      data[first(values)] = last(values);
    }

    return data;
  }

  protected getNewComment() {
    let builder = this.data.factuur_opmerking;

    if (builder && builder.length > 0) {
      builder += "\n\n";
    }

    const date = moment().format("DD-MM-YYYY");

    builder += `${date}: ${this.authService.user.name}`;
    builder += `\nGoedgekeurd: ${this.composeCompleted ? "Ja" : "Nee"}`;
    builder += `\nOpmerking: ${this.composeComment}`;

    return builder;
  }

  protected async fetchFinancial() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "relaties_financieel",
      filter: `relatie = "${this.data.relatie}"`,
    });

    if (!response.hasError()) {
      const data = last(response.value);

      if (data) {
        return data;
      }
    }
  }
}
