import { Project } from "./../project/project.entity";
import { formatNumber } from "@angular/common";
import { AppProjectCost } from "./app-project-cost.entity";
import * as moment from "moment";

function* createAllTransactionDocumentLines(
  request: GenerateProjectCostsTransactionRequest
) {
  for (const cost of request.root.children) {
    const index = request.root.children.indexOf(cost);

    yield* createTransactionDocumentLines({
      ...request,
      current: cost,
      index,
    });
  }
}

const MTC_DATE_FORMAT = "YYYY-MM-DD";

function* createTransactionDocumentLines(
  cursor: GenerateProjectCostsTransactionCursor
) {
  const { current: cost, index } = cursor;

  if (!cost.children || !cost.children.length) {
    const indexText = formatNumber(index, "nl", "3.0-0");

    yield {
      ChargeCode: "10-1",
      ToCompany: 910,
      ToCostCentre: cursor.project.userId,
      FromSubproject: cost.id ? `M${indexText}` : "0000",
      FromCompany: 920,
      FromAdministrationType: "PRJ",
      FromCostCentre: cursor.project.id,
      StartTime: "000000",
      StartDate: moment().format(MTC_DATE_FORMAT),
      EndDate: moment().format(MTC_DATE_FORMAT),
      EndTime: "000000",

      Description: "test meerwerkregel",
      MemoText: cost.memo || "",
      FromAmount: cost.value,
    };
  } else {
    for (const item of cost.children) {
      yield* createTransactionDocumentLines({
        ...cursor,
        current: item,
      });
    }
  }
}

export const generateProjectCostsTransaction = (
  request: GenerateProjectCostsTransactionRequest
) => {
  const lines = Array.from(createAllTransactionDocumentLines(request));

  return {
    Company: "910",
    DocumentType: "10-10",
    DocumentDate: moment().format(MTC_DATE_FORMAT),
    DocumentDescription: `${request.project.id} - ${request.project.description}`,
    DocumentLines: lines,
  };
};

interface GenerateProjectCostsTransactionRequest {
  readonly root: AppProjectCost;
  readonly project: Project;
}

type GenerateProjectCostsTransactionCursor =
  GenerateProjectCostsTransactionRequest & {
    readonly current: AppProjectCost;
    readonly index: number;
  };
