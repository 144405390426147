import { OffersScoreDialogComponent } from "./../offers-score-dialog/offers-score-dialog.component";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MetacomService } from "../metacom.service";
import { RestResponse } from "../rest.service";
import {
  ComplexTableColumn,
  ComplexTableAction,
  ComplexTableActionClicked,
  ComplexTableDataRow,
  ComplexTableDataGroup,
} from "../complex-table/complex-table.component";
import { Subject } from "rxjs";
import { tap, debounceTime } from "rxjs/operators";
import { User } from "../accessibility-users/user.entity";
import { DialogService } from "../dialog.service";
import { SelectDialogComponent } from "../select-dialog/select-dialog.component";
import { TransactionService } from "../transaction.service";
import { CellaService } from "../cella.service";
import { EntityAdvancement } from "./entity.advancement.entity";
import { EntityManager, Entity } from "../entity.service";
import {
  EntityAdvancementDialogComponent,
  EntityAdvancementDialogComponentData,
} from "../entity-advancement-dialog/entity-advancement-dialog.component";
import { AuthService } from "../auth.service";
import * as _ from "lodash";
import * as moment from "moment";
import { PageService } from "../page.service";
import { first, sum, sumBy } from "lodash";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"],
})
export class OffersComponent implements OnInit {
  filterQuery = "";

  response: RestResponse<OfferSellerGroup[]>;
  responseStates: RestResponse<OfferState[]>;
  responseAdvancements: RestResponse<EntityAdvancement[]>;

  relationModel: User;
  relationIdModel: string;
  relationFilterConfig = {
    allowNothing: true,
    title: "Verkoper",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "isSalesEmployee", operator: "Equal", value: "True" },
    ],
  };

  protected filterQueryChanged = new Subject<string>();

  columns = [
    new ComplexTableColumn<Offer>({
      id: "klantdossier",
      title: "Klantdossier",
      width: "1px",
      text: (value) => value.klantdossier,
    }),
    new ComplexTableColumn<Offer>({
      id: "offerte",
      title: "Offerte",
      width: "92px",
      text: (value) => value.offerte,
    }),
    new ComplexTableColumn<Offer>({
      id: "versie",
      title: "Versie",
      width: "1px",
      align: "right",
      text: (value) => value.versie,
    }),
    new ComplexTableColumn<Offer>({
      id: "omschrijving",
      title: "Opdrachtgever",
      width: "256px",
      text: (value) => value.omschrijving,
    }),
    new ComplexTableColumn<Offer>({
      id: "relatie_telefoon",
      title: "Tel.",
      width: "300px",
      wrap: false,
      text: (value) => value.relatie_telefoon,
      sort: (value) => value.relatie_telefoon,
    }),
    new ComplexTableColumn<Offer>({
      id: "score",
      title: "Scoringskans",
      width: "120px",
      align: "right",
      text: (value) => `${value.score}%`,
      sort: (value) => value.score,
      footer: (values) => {
        const total = sumBy(values, (v) => v.data.score ?? 0);
        const average = values.length
          ? (total / values.length).toLocaleString("nl-NL", {
              maximumFractionDigits: 0,
            })
          : 0;

        return `${average}%`;
      },
    }),
    new ComplexTableColumn<Offer>({
      id: "price",
      title: "Aanneemsom",
      width: "120px",
      align: "right",
      text: (value) =>
        `${value.price.toLocaleString("nl-NL", {
          minimumFractionDigits: 2,
        })}`,
      sort: (value) => value.price,
      footer: (values) =>
        `${sumBy(values, (v) => v.data.price ?? 0).toLocaleString("nl-NL", {
          minimumFractionDigits: 2,
        })}`,
    }),
    new ComplexTableColumn<Offer>({
      id: "datum",
      title: "Datum",
      width: "120px",
      text: (value) => value.datum,
      sort: (value) => moment(value.datum, "DD-MM-YYYY", true).unix(),
    }),
    new ComplexTableColumn<Offer>({
      id: "callBackDate",
      title: "Terugbeldatum",
      width: "120px",
      text: (value) =>
        value.active ? moment(value.active.date).format("DD-MM-YYYY") : "-",
      sort: (value) => (value.active ? moment(value.active.date).unix() : 0),
      note: (value) => (value.active ? value.active.comment : null),
    }),
  ];

  actions = [
    new ComplexTableAction<Offer>({
      id: "score",
      icon: "sports_score",
      title: "Scoringskans aanpassen",
    }),
    new ComplexTableAction<Offer>({
      id: "state",
      icon: "edit_attributes",
      title: "Stadium aanpassen",
    }),
    new ComplexTableAction<Offer>({
      id: "advancement",
      icon: "add_comment",
      title: "Voortgang melden",
      color: (item) => (item.advancements.length > 0 ? "green" : "grey"),
    }),
  ];

  protected get entityAdvancementService() {
    return this.entityManager.get(EntityAdvancement);
  }

  constructor(
    protected readonly pageService: PageService,
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly transactionService: TransactionService,
    protected readonly cellService: CellaService,
    protected readonly metacomService: MetacomService,
    protected readonly entityManager: EntityManager
  ) {}

  get total() {
    return this.response ? sumBy(this.response.value, (r) => +r.total) : 0;
  }

  get totalIsNegotiating() {
    return this.response
      ? sumBy(this.response.value, (r) => +r.totalIsNegotiating)
      : 0;
  }

  get totalSum() {
    return this.response
      ? sumBy(this.response.value, (seller) =>
          sumBy(
            seller.groups?.filter((g) => g.isNegotiating),
            (group) => group.sumAsNumber
          )
        ).toLocaleString("nl", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 0;
  }

  action(event: ComplexTableActionClicked<Offer>) {
    switch (event.action.id) {
      case "score":
        return this.setScore(event.item);
      case "state":
        return this.setStage(event.item);
      case "advancement":
        return this.addAdvancement(event.item);
    }
  }

  ngOnInit() {
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500)
      )
      .subscribe(() => this.fetch());

    if (this.authService.user.isSalesEmployee === "true") {
      this.relationModel = this.authService.user;
      this.relationIdModel = this.authService.user.id;
    }

    this.fetch();
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  async setScore(offer: Offer) {
    const result = await this.dialogService.open(
      this.changeDetector,
      OffersScoreDialogComponent,
      {
        data: { score: +offer.scoringskans },
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    if (!isNaN(result as any) && result !== offer.scoringskans) {
      const response = await this.transactionService.perform(() =>
        this.cellService.writeCustomFields({
          CustomField: [
            {
              Entity: "2201",
              LineId: 1,
              Origin: `bdr:910¡prj:${offer.klantdossier}`,
              Code: "CAL-131",
              SerialNumber: 1,
              SerialNumberNRelation: "",
              Contents: result as any,
            },
          ],
        })
      );

      if (!response.hasError()) {
        offer.scoringskans = result as any;
        offer.score = +result;

        this.update();
      }
    }
  }

  async setStage(offer: Offer) {
    const items = this.responseStates.value.map((e) => ({
      state: e,
      description: e.omschrijving,
      isChecked: e.stadium === offer.stadium,
    }));

    const result = await this.dialogService.open(
      this.changeDetector,
      SelectDialogComponent,
      {
        data: { isMultiple: false, items },
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    if (result instanceof Array) {
      const selected: { state: OfferState } = result.find((e) => e.isChecked);

      if (offer.stadium !== selected.state.stadium) {
        const response = await this.transactionService.perform(() =>
          this.cellService.setStage({
            Company: "920",
            Entity: "2201",
            Origin: `bdr:910¡prj:${offer.klantdossier}`,
            Stage: selected.state.stadium,
          })
        );

        if (!response.hasError()) {
          await this.fetch();
        }
      }
    }
  }

  async addAdvancement(offer: Offer) {
    const result: EntityAdvancement[] = await this.dialogService.open(
      this.changeDetector,
      EntityAdvancementDialogComponent,
      {
        data: new EntityAdvancementDialogComponentData(
          offer.klantdossier,
          OFFER_ENTITY_TYPE,
          offer.klantdossier,
          offer.omschrijving,
          "3000"
        ),
        clickOutsideToClose: false,
        escapeToClose: false,
      }
    );

    if (result) {
      offer.advancements = result;
      offer.active = this.getActive(result);

      this.update();
    }
  }

  protected getStateName(id: string) {
    const state = this.responseStates.value.find((i) => i.stadium === id);

    return state ? state.omschrijving : "Onbekend";
  }

  protected async fetch() {
    this.pageService.setTitle(`Offertes`);
    this.pageService.clearSegments();

    this.responseStates =
      this.responseStates ??
      (await this.metacomService.queryTableAsync<OfferState>({
        setName: "metacom",
        tableName: "offertes_stadia",
      }));

    const abbreviation = this.relationModel?.abbreviation;

    const response = await this.metacomService.queryTableAsync<Offer>({
      setName: "metacom",
      tableName: "offertes",
      ...(abbreviation
        ? {
            filter: `clc_prj.besteknr = "${abbreviation}"`,
          }
        : {}),
    });

    if (!response.hasError()) {
      const filtered = response.value.filter((e) =>
        this.relationModel
          ? e.verkoper === this.relationModel.abbreviation
          : true
      );

      await this.fetchAdvancements(filtered);

      this.response = new RestResponse(
        _.chain(filtered)
          .forEach((e) => {
            e.score = +e.scoringskans;
            e.price = +e.aanneemsom;
          })
          .forEach((e) => (e.datum = moment(e.datum).format("DD-MM-YYYY")))
          .forEach(
            (e) => (e.advancements = this.getAdvancements(e.klantdossier))
          )
          .forEach((e) => (e.active = this.getActive(e.advancements)))
          .groupBy((i) => i.verkoper)
          .map(
            (sellerOffers, seller) =>
              new OfferSellerGroup(
                seller,
                _.chain(sellerOffers)
                  .groupBy((i) => i.stadium)
                  .map(
                    (stateOffers, state) =>
                      new OfferGroup(
                        state,
                        this.getStateName(state),
                        stateOffers
                      )
                  )
                  .value()
              )
          )
          .value()
      );

      if (!this.relationModel) {
        this.pageService.addSegment(`${this.totalSum} o.h.`);
        this.pageService.addSegment(`Aant. ${this.totalIsNegotiating}`);
      }
    }
  }

  protected getActive(advancements: EntityAdvancement[]) {
    return _.chain(advancements)
      .orderBy((e) => moment(e.date).unix(), "desc")
      .first()
      .value();
  }

  protected async fetchAdvancements(offers: Offer[]) {
    const ids = offers.map((o) => o.klantdossier);

    this.responseAdvancements = await this.entityAdvancementService.query({
      filters: [
        { field: "entityType", operator: "Equal", value: OFFER_ENTITY_TYPE },
        { field: "entityId", operator: "In", value: ids },
      ],
    });
  }

  protected getAdvancements(offerId: string) {
    return this.responseAdvancements.value.filter(
      (e) => e.entityId === offerId
    );
  }

  protected update() {
    this.response.value.forEach((e) => e.groups.forEach((g) => g.set()));
  }
}

export interface OfferState {
  stadium: string;
  omschrijving: string;
  entiteit: string;
}

export class OfferGroup {
  tableData: ComplexTableDataGroup<Offer>[];

  constructor(
    readonly state: string,
    readonly stateName: string,
    readonly offers: Offer[]
  ) {
    this.set();
  }

  set() {
    this.tableData = [
      new ComplexTableDataGroup<Offer>(
        this.offers.map((e) => new ComplexTableDataRow(e))
      ),
    ];
  }

  get isNegotiating() {
    return first(this.offers).stadium === "0";
  }

  get amount() {
    return this.offers.length.toLocaleString("nl", {
      minimumIntegerDigits: 2,
    });
  }

  get sumAsNumber() {
    return sum(
      this.offers.map((offer) => {
        const scoring = +offer.scoringskans / 100.0;
        return +offer.aanneemsom * scoring;
      })
    );
  }

  get sum() {
    return this.isNegotiating
      ? this.sumAsNumber.toLocaleString("nl", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0";
  }
}

export class OfferSellerGroup {
  constructor(readonly sellerName: string, readonly groups: OfferGroup[]) {}

  get total() {
    return sumBy(this.groups, (g) => g.offers.length).toLocaleString("nl", {
      minimumIntegerDigits: 2,
    });
  }

  get totalIsNegotiating() {
    return sumBy(
      this.groups.filter((g) => g.isNegotiating),
      (g) => g.offers.length
    ).toLocaleString("nl", {
      minimumIntegerDigits: 2,
    });
  }
}

export interface Offer {
  klantdossier: string;
  offerte: string;
  versie: number;
  omschrijving: string;
  relatie: string;
  verkoper: string;
  datum: string;
  stadium: string;

  scoringskans: string;
  score: number;

  aanneemsom: string;
  price: number;

  relatie_telefoon: string;

  active: EntityAdvancement;
  advancements: EntityAdvancement[];
}

export const OFFER_ENTITY_TYPE = "metacom:offer";
