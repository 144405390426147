import { Component, OnInit, Inject } from '@angular/core';
import { AttunedFile, FileUploadService, AttunedFileResponse } from '../file-upload.service';
import { MDC_DIALOG_DATA, MdcDialogRef } from '@angular-mdc/web';
import { chunk } from 'lodash';

@Component({
  selector: 'app-upload-progress-dialog',
  templateUrl: './upload-progress-dialog.component.html',
  styleUrls: ['./upload-progress-dialog.component.scss']
})
export class UploadProgressDialogComponent implements OnInit {
  readonly CONCURRENT = 5;

  response: AttunedFileResponse[];

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: UploadProgressDialogComponentData,
    protected readonly fileUploadService: FileUploadService,
    protected readonly dialog: MdcDialogRef<UploadProgressDialogComponent>,
  ) { }

  async ngOnInit() {
    const response = [];
    const chunks = chunk(this.data.files
      .map(file => this.fileUploadService.attunedUpload(file)), this.CONCURRENT);

    for (const chunk of chunks) {
      response.push(...await Promise.all(chunk));
    }

    response.forEach(r => r.file['bag'] = r);
    this.response = response;
  }

  close() {
    this.dialog.close(this.response);
  }
}

export class UploadProgressDialogComponentData {
  constructor(readonly files: AttunedFile[]) { }
}