import { Component, Inject, OnInit } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialog, MdcDialogRef } from "@angular-mdc/web";
import { Drawer, DrawerAbsent } from "../drawers/drawer.entity";
import { EntityManager } from "../entity.service";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { RestResponse } from "../rest.service";
import * as moment from "moment";
import { flatten } from "lodash";

@Component({
  selector: "app-drawer-absent-dialog",
  templateUrl: "./drawer-absent-dialog.component.html",
  styleUrls: ["./drawer-absent-dialog.component.scss"],
})
export class DrawerAbsentDialogComponent implements OnInit {
  responseDrawers: RestResponse<Drawer[]>;

  colorSelection = [
    { value: "#7D3C98", label: "Paars" },
    { value: "#F4D03F", label: "Geel" },
    { value: "#FF7F50", label: "Oranje" },
    { value: "#808000", label: "Olijf" },
    { value: "#008080", label: "Groenblauw" },
    { value: "#808080", label: "Grijs" },
    { value: "#000000", label: "Zwart" },
  ];

  protected get drawerService() {
    return this.entityManager.get(Drawer);
  }

  protected get drawerIds() {
    return this.responseDrawers.value
      .filter((d) => d.isChecked)
      .map((d) => d.id);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: DrawerAbsentDialogComponentData,
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialog,
    protected readonly dialogRef: MdcDialogRef<DrawerAbsentDialogComponent>
  ) {}

  ngOnInit() {
    this.data.absent.date = moment(this.data.absent.date).format("YYYY-MM-DD");

    this.fetchDrawers();
  }

  get canSave() {
    return (
      this.data.absent.reason &&
      this.data.absent.date &&
      this.data.absent.hours &&
      (this.data.absent.drawerId || this.drawerIds.length)
    );
  }

  copy() {
    this.dialogRef.close("copy");
  }

  async delete() {
    const confirmation = await this.dialog
      .open(ConfirmDialogComponent, { escapeToClose: false })
      .afterClosed()
      .toPromise();

    if (confirmation === "accept") {
      this.data.absent.isDeletePrompt = true;
      this.save();
    }
  }

  async fetchDrawers() {
    this.responseDrawers = await this.drawerService.query({
      filters: [
        ...flatten(
          this.data.drawerFilterField.map((drawerField) => [
            { field: "condition", operator: "Equal", value: "1000" },
            {
              field: drawerField,
              operator: "Equal",
              value: "waar",
              isOr: true,
            },
          ])
        ),
      ],
      relations: [],
      orders: [{ field: "id", direction: "ASC" }],
    });

    if (this.data.absent.drawerId) {
      this.responseDrawers.value
        .filter((e) => e.id === this.data.absent.drawerId)
        .forEach((d) => (d.isChecked = true));
    }
  }

  save() {
    if (this.data.absent.isConcept) {
      this.data.absent.conceptDrawerIds = this.drawerIds;
    }

    this.data.absent.date = moment(this.data.absent.date).toDate();

    this.dialogRef.close(this.data.absent);
  }
}

export interface DrawerAbsentDialogComponentData {
  readonly absent: DrawerAbsent;
  readonly drawerFilterField: string[];
}
