import { User } from "../accessibility-users/user.entity";
import * as moment from "moment";

export class HourDeclarationParams {
  readonly WEEK_FORMAT = "GGGG-WW";
  readonly COST_ID_DEFAULT = "10000";

  constructor(
    readonly user: User,
    readonly week: string,
    readonly companyId: string,
    readonly templateId: string,
    readonly defaultCostId: string
  ) {
    defaultCostId = defaultCostId || this.COST_ID_DEFAULT;
  }

  get isExternal() {
    return this.templateId === "external";
  }

  get documentInsertType() {
    switch (this.templateId) {
      case "external":
        return "60-12";
      case "office":
      case "office_outside":
        return "20-14";
    }
  }

  get documentCommitType() {
    switch (this.templateId) {
      case "external":
        return "60-20";
    }
  }

  get documentInsertNumber() {
    return `${this.week}-${this.userId}`;
  }

  get documentCommitNumber() {
    const week = moment(this.week, this.WEEK_FORMAT, true).format("YYYYWW");
    return `${week}-${this.userId}-`;
  }

  get documentDescription() {
    return this.isExternal
      ? `${this.documentCommitNumber} - ${this.user.name}`
      : "";
  }

  get userId() {
    return this.isExternal ? this.user.id : (this.user.id as string).substr(1);
  }
}
