import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";
import { Apollo } from "apollo-angular";
import * as moment from "moment";
import * as React from "react";
import { AuthService } from "src/app/auth.service";
import { FileUploadService } from "src/app/file-upload.service";
import { RestService } from "src/app/rest.service";
import { BlocBuilder } from "src/app/service-settings/bloc/bloc.builder";
import { ServiceTransmitter } from "src/app/service/service.transmitter";
import { DeliveryListBloc } from "../core/delivery-list.bloc";
import { DeliveryListLoadSuccessState } from "../core/delivery-list.state";
import { CompletionBloc } from "./completion.bloc";
import {
  CompletionGeneratePdfRequestEvent,
  CompletionLoadRequestEvent,
  CompletionMakeFinalRequestEvent,
} from "./completion.event";
import {
  CompletionLoadInProgressState,
  CompletionLoadSuccessState,
  CompletionState,
} from "./completion.state";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 12,
  },
  paper: {
    marginBottom: 12,
  },
}));

export interface CompletionComponentProps {
  rest: RestService;
  fileUploadService: FileUploadService;
  auth: AuthService;
  apollo: Apollo;
  coreBloc: DeliveryListBloc;
  projectId: string;
  disabled?: boolean;
  serviceTransmitter: ServiceTransmitter;
}

class CheckListItem {
  constructor(
    readonly props: {
      readonly title: string;
      readonly condition: (
        coreState: DeliveryListLoadSuccessState,
        state: CompletionLoadSuccessState
      ) => boolean;
    }
  ) {}
}

export const CompletionComponent: React.FC<CompletionComponentProps> = ({
  rest,
  fileUploadService,
  auth,
  apollo,
  coreBloc,
  projectId,
  disabled,
  serviceTransmitter,
}) => {
  const classes = useStyles();

  const [bloc] = React.useState(
    new CompletionBloc(
      coreBloc,
      apollo,
      auth,
      rest,
      fileUploadService,
      serviceTransmitter
    ).add(new CompletionLoadRequestEvent({ projectId }))
  );

  const checkListItems = [
    new CheckListItem({
      title: "Gegevens",
      condition: (coreState, state) =>
        !!coreState.props.deliveryList.assignedUserId &&
        !!coreState.props.deliveryList.houseDocumentMetaId &&
        !!coreState.props.deliveryList.keyAmount &&
        !!coreState.props.deliveryList.isDeliveredAt,
    }),
    new CheckListItem({
      title: "Meterstanden",
      condition: (coreState, state) => state.props.meters.length > 0,
    }),
    new CheckListItem({
      title: "Punten",
      condition: (coreState, state) => state.props.meters.length > 0,
    }),
    new CheckListItem({
      title: "Handtekeningen",
      condition: (coreState, state) =>
        !!state.props.signatures.find(
          (_signature) => _signature.kind === "employee"
        ) &&
        !!state.props.signatures.find(
          (_signature) => _signature.kind === "customer"
        ),
    }),
  ];

  const isFinalAllowed = (
    coreState: DeliveryListLoadSuccessState,
    state: CompletionLoadSuccessState
  ) => {
    return checkListItems.every((_checkListItem) =>
      _checkListItem.props.condition(coreState, state)
    );
  };

  function* mapStateToNode(state: CompletionState) {
    const stateCore = coreBloc.stateOf<DeliveryListLoadSuccessState>();

    if (state instanceof CompletionLoadInProgressState) {
      yield <p key="mtr-loading">Laden...</p>;
    }

    if (state instanceof CompletionLoadSuccessState) {
      yield (
        <List>
          {checkListItems.map((_checkListItem) => (
            <ListItem
              key={_checkListItem.props.title}
              style={{ paddingLeft: 0 }}
            >
              <ListItemIcon>
                {_checkListItem.props.condition(stateCore, state) ? (
                  <CheckCircle />
                ) : (
                  <RadioButtonUnchecked />
                )}
              </ListItemIcon>
              <ListItemText primary={_checkListItem.props.title} />
            </ListItem>
          ))}
        </List>
      );

      yield (
        <Button
          disabled={
            state.props.isGeneratingPdf || !isFinalAllowed(stateCore, state)
          }
          key="cmpl-pdf"
          variant="outlined"
          style={{ marginRight: 8 }}
          onClick={() =>
            bloc.add(new CompletionGeneratePdfRequestEvent({ projectId }))
          }
        >
          Pdf uitdraaien{" "}
          {state.props.isGeneratingPdf && (
            <CircularProgress size="1.5rem" style={{ marginLeft: 8 }} />
          )}
        </Button>
      );

      yield (
        <Button
          disabled={
            state.props.isSavingToSystem ||
            stateCore.props.deliveryList.isFinal ||
            !isFinalAllowed(stateCore, state)
          }
          key="cmpl-metacom"
          variant="outlined"
          onClick={() =>
            bloc.add(new CompletionMakeFinalRequestEvent({ projectId }))
          }
        >
          Definitief maken{" "}
          {state.props.isSavingToSystem && (
            <CircularProgress size="1.5rem" style={{ marginLeft: 8 }} />
          )}
        </Button>
      );

      if (stateCore.props.deliveryList.isFinal) {
        yield (
          <p>
            Definitief gemaakt:{" "}
            {moment(stateCore.props.deliveryList.isFinalAt)
              .locale("nl")
              .format("LL")}
          </p>
        );
      }
    }
  }

  return (
    <Paper className={classes.paper}>
      <Box p={2}>
        <BlocBuilder
          bloc={bloc}
          builder={(state) => <>{...Array.from(mapStateToNode(state))}</>}
        />
      </Box>
    </Paper>
  );
};
