import { Component, OnInit } from "@angular/core";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { MetacomService } from "../metacom.service";
import { tap, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MdcDialog } from "@angular-mdc/web";
import { ImageDialogComponent } from "../image-dialog/image-dialog.component";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import * as _ from "lodash";

@Component({
  selector: "app-metacom-staff",
  templateUrl: "./metacom-staff.component.html",
  styleUrls: ["./metacom-staff.component.scss"],
})
export class MetacomStaffComponent implements OnInit {
  public filterQuery = "";

  public response: RestResponse<any>;
  public responseGroups: RestResponse<StaffGroup[]>;

  protected filterQueryChanged = new Subject<string>();

  get showBhv() {
    return this.grantService.varIs(grants.metacom_staff.show_bhv_info, "true");
  }

  constructor(
    protected readonly mdcDialog: MdcDialog,
    protected readonly grantService: GrantService,
    protected readonly metacomService: MetacomService
  ) {}

  ngOnInit() {
    this.filterQueryChanged
      .pipe(
        tap(() => (this.responseGroups = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.postProcessing());

    this.fetchItems();
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  url(group: StaffGroup, item: any) {
    const personId = item.persoon.substr(0, 4);

    return `volume/staff-image/${group.id}/${personId}`;
  }

  openImage(group: StaffGroup, item: any) {
    return this.mdcDialog
      .open(ImageDialogComponent, {
        data: { url: this.url(group, item), title: item.persoon_voornaam },
      })
      .afterClosed()
      .toPromise();
  }

  protected async fetchItems() {
    this.response = await this.metacomService.queryTableAsync({
      setName: "metacom",
      tableName: "personeel_gb",
    });

    this.postProcessing();
  }

  protected postProcessing() {
    if (this.response && !this.response.hasError()) {
      const groups = _.chain(this.response.value)
        .filter((d) => this.match(d))
        .groupBy((d) => d.relatie)
        .map((items, group) => ({
          id: _.first(items).relatie,
          name: _.first(items).relatie_naam,
          isExpanded: !!this.filterQuery.length,
          items: _.sortBy(items, "persoon_voornaam"),
        }))
        .value();

      this.responseGroups = new RestResponse(groups);
    }
  }

  protected match(item) {
    if (this.filterQuery.length) {
      const fingerprint = JSON.stringify(item).toLowerCase();
      return fingerprint.indexOf(this.filterQuery.toLowerCase()) >= 0;
    }

    return true;
  }
}

class StaffGroup {
  id: string;
  name: string;
  items: any[];
  isExpanded?: boolean;
}
