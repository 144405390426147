import {
  InvoiceCreatorFinancial,
  InvoiceCreatorItem,
  InvoiceCreatorTemplate,
  InvoiceCreatorParams,
  InvoiceCreatorItemLine,
  InvoiceCompany,
} from "./invoice-creator.template";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MetacomService } from "../metacom.service";
import { RestResponse, RestService } from "../rest.service";
import { chain, last, first } from "lodash";
import { UrlOpenService } from "../url-open.service";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth.service";
import { TransactionService } from "../transaction.service";
import { DocumentMeta } from "../documents/document-meta.entity";
import { CellaService } from "../cella.service";
import { environment } from "../../environments/environment";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import {
  InvoiceCreatorAttachmentDialogComponent,
  InvoiceCreatorAttachmentDialogComponentData,
} from "../invoice-creator-attachment-dialog/invoice-creator-attachment-dialog.component";
import { InvoiceAttachment } from "../invoice-creator-attachment-dialog/invoice-attachment.entity";
import * as moment from "moment";

@Component({
  selector: "app-invoice-creator",
  templateUrl: "./invoice-creator.component.html",
  styleUrls: ["./invoice-creator.component.scss"],
})
export class InvoiceCreatorComponent implements OnInit {
  responseCompanies: RestResponse<InvoiceCompany[]>;
  responseInvoices: RestResponse<InvoiceCreatorItem[]>;
  responseFinancial: RestResponse<InvoiceCreatorFinancial>;

  readonly templateCreator: InvoiceCreatorTemplate;

  protected get documentMetaService() {
    return this.entityManager.get(DocumentMeta);
  }

  protected get invoiceAttachmentService() {
    return this.entityManager.get(InvoiceAttachment);
  }

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly authService: AuthService,
    protected readonly restService: RestService,
    protected readonly metacomService: MetacomService,
    protected readonly cellaService: CellaService,
    protected readonly transactionService: TransactionService,
    protected readonly entityManager: EntityManager,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    this.templateCreator = new InvoiceCreatorTemplate(
      httpClient,
      urlOpenService
    );
  }

  ngOnInit() {
    Promise.all([this.fetchFinancial(), this.fetchCompanies()]).then(() =>
      this.fetchInvoices()
    );
  }

  async export(item: InvoiceCreatorItem, format: string) {
    const params = await this.makeParams(item);

    switch (format) {
      case "pdf":
        return this.transactionService.perform(
          () => this.exportToPdf(params),
          "Gegenereerd"
        );
      case "xml":
        return this.transactionService.perform(
          () => this.exportXml(params),
          "Gegenereerd"
        );
      case "mail":
        return this.transactionService.perform(
          () => this.exportToMail(params),
          "Verstuurd"
        );
      case "save":
        return this.transactionService.perform(
          () => this.save(item),
          "Opgeslagen"
        );
    }
  }

  async openAttachments(item: InvoiceCreatorItem) {
    await this.dialogService.open(
      this.changeDetector,
      InvoiceCreatorAttachmentDialogComponent,
      {
        data: new InvoiceCreatorAttachmentDialogComponentData(item),
      }
    );
  }

  protected async exportToPdf(
    params: InvoiceCreatorParams,
    open = true,
    attachments = false
  ) {
    const pdfBlob = await this.generateBasePdf(params),
      name = "document.pdf";
    const documents = attachments
      ? await this.invoiceAttachmentService.query({
          filters: [
            {
              field: "metacomOrderId",
              operator: "Equal",
              value: params.invoice.opdracht,
            },
          ],
        })
      : new RestResponse([]);

    const documentBlobs = documents.hasError()
      ? []
      : await Promise.all(
          documents.value.map((e) =>
            this.restService.getFile(
              `${environment.baseUrl}/documents/open/${e.documentMetaId}`,
              "application/pdf",
              `${e.documentMetaId}.pdf`,
              { token: this.urlOpenService.token }
            )
          )
        );

    let mergedBuffer: ArrayBuffer = null;

    try {
      mergedBuffer = await this.restService.postFilesNative(
        "tools/merge-pdf",
        [pdfBlob].concat(documentBlobs)
      );
    } catch (e) {
      this.transactionService.showError(
        "Kan bijlages niet samenvoegen, formaat foutief."
      );
      throw e;
    }

    const mergedFile = this.restService.arrayBufferToFile(
      mergedBuffer,
      "application/pdf",
      name
    );

    if (open) {
      this.urlOpenService.openLocalFile(mergedFile, this.changeDetector);
    }

    return new RestResponse(mergedFile as File);
  }

  protected async exportXml(params: InvoiceCreatorParams, open = true) {
    const xmlBlob = await this.generateXml(params);

    if (open) {
      this.urlOpenService.openLocalFile(xmlBlob, this.changeDetector);
    }

    return new RestResponse(xmlBlob);
  }

  protected async exportToMail(params: InvoiceCreatorParams) {
    await this.save(params.invoice);

    const pdf = (await this.exportToPdf(params, false, true)).value;
    const xml = (await this.exportXml(params, false)).value;

    // store to files to hunter so cella can access them
    const files = await this.restService.postFiles<DocumentMeta[]>(
      `documents/upload/3000`,
      [pdf, xml]
    );

    const filePrefix = `${params.invoice.relatie}-${params.invoice.factuurNummer}`;

    // send mail to persons
    const response = await this.cellaService.sendEmail({
      from: { address: "info@groothuisbouw.nl", name: "Groothuisbouw" },
      to: this.receiveEmails(params),
      subject: `Factuur ${params.invoice.factuurNummer} - ${params.invoice.relatie_oms}`,
      attachments: [
        {
          filename: `${filePrefix}.pdf`,
          path: `${environment.baseUrl}/documents/open/${first(files).id}`,
        },
        {
          filename: `${filePrefix}.xml`,
          path: `${environment.baseUrl}/documents/open/${last(files).id}`,
        },
      ],
      html: `Hierbij de factuur van ${params.invoice.relatie_oms} met nummer ${params.invoice.factuurNummer}`,
    });

    await this.setSend(params.invoice);

    return response;
  }

  protected async save(item: InvoiceCreatorItem) {
    const origin = `bdr:${item.bedrijf}¡dst:60-20¡dnr:${item.opdracht}¡rnr:0`;
    const response = await this.cellaService.writeCustomFields({
      Company: item.bedrijf,
      CustomField: [
        {
          Entity: "0010",
          Origin: origin,
          LineId: 1,
          Code: "TRA-500",
          SerialNumber: 1,
          Contents: item.factuurNummer,
        },
        {
          Entity: "0010",
          Origin: origin,
          LineId: 1,
          Code: "TRA-502",
          SerialNumber: 1,
          Contents: item.vat,
        },
      ],
    });

    if (!response.hasError()) {
      item.isStored = true;
    }

    return response;
  }

  protected async setSend(item: InvoiceCreatorItem) {
    const origin = `bdr:${item.bedrijf}¡dst:60-20¡dnr:${item.opdracht}¡rnr:0`;
    const comment = await this.cellaService.writeCustomFields({
      Company: item.bedrijf,
      CustomField: [
        {
          Entity: "0010",
          Origin: origin,
          LineId: 1,
          Code: "TRA-501",
          SerialNumber: 1,
          Contents: `Verzonden op ${moment().format("DD-MM-YYYY")}`,
        },
      ],
    });

    if (!comment.hasError()) {
      const stage = await this.cellaService.setStage({
        Company: item.bedrijf,
        Entity: "0010",
        Origin: origin,
        Stage: "1000",
      });

      if (!stage.hasError()) {
        item.setSend();
      }
    }
  }

  protected async generateBasePdf(params: InvoiceCreatorParams) {
    const buffer = await this.restService.postNative(
      `tools/create-pdf`,
      await this.templateCreator.createPdf(params)
    );
    return this.restService.arrayBufferToFile(
      buffer,
      "application/type",
      "document.pdf"
    );
  }

  protected async generateXml(params: InvoiceCreatorParams) {
    const buffer = await this.restService.postNative(
      `tools/create-xml`,
      this.templateCreator.createXml(params)
    );
    return this.restService.arrayBufferToFile(
      buffer,
      "text/xml",
      "document.xml"
    );
  }

  protected async makeParams(item: InvoiceCreatorItem) {
    const lines = await this.fetchInvoiceLines(item.opdracht);
    const company = this.responseCompanies.value.find(
      (e) => e.bedrijf === item.bedrijf
    );

    return new InvoiceCreatorParams(
      this.authService.user,
      item,
      lines.value,
      this.responseFinancial.value,
      company
    );
  }

  protected receiveEmails(params: InvoiceCreatorParams) {
    const emails = [];

    if (environment.production) {
      emails.push(
        params.financial.relatie_email || params.financial.relatie_email_alg
      );
    } else {
      emails.push("fvboven@groothuisbouw.nl");
    }

    if (!!params.company.bedrijf_email_adm) {
      emails.push(params.company.bedrijf_email_adm);
    }

    return emails;
  }

  protected isDuplicateId(id: string) {
    return (
      this.responseInvoices &&
      this.responseInvoices.value.filter((e) => e.factuurNummer === id).length >
        1
    );
  }

  protected async fetchInvoices() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "werkopdrachten_facturen",
      filter: `relatie = "${this.authService.user.id}"`,
    });

    if (!response.hasError()) {
      this.responseInvoices = new RestResponse(
        chain(response.value)
          .map(
            (e) =>
              new InvoiceCreatorItem(
                e,
                this.responseFinancial.value.vat,
                (id) => this.isDuplicateId(id)
              )
          )
          .orderBy((e) => moment(e.datum).unix(), "desc")
          .value()
      );
    }
  }

  protected async fetchCompanies() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "bedrijven",
    });

    if (!response.hasError()) {
      this.responseCompanies = new RestResponse(
        response.value.map((e) => new InvoiceCompany(e))
      );
    }
  }

  protected async fetchFinancial() {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "relaties_financieel",
      filter: `relatie = "${this.authService.user.id}"`,
    });

    if (!response.hasError()) {
      const data = last(response.value);

      if (data) {
        this.responseFinancial = new RestResponse(
          new InvoiceCreatorFinancial(data)
        );
      }
    }
  }

  protected async fetchInvoiceLines(docNr: string) {
    const response = await this.metacomService.queryTableAsync<any>({
      setName: "metacom",
      tableName: "werkopdrachten_factuurregels",
      filter: `reg_mutatie.docnr = "${docNr}"`,
    });

    if (!response.hasError()) {
      return new RestResponse(
        chain(response.value)
          .map((e) => new InvoiceCreatorItemLine(e))
          .orderBy((e) => e.middel, "asc")
          .value()
      );
    }
  }
}
