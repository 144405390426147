import { Component, OnInit } from '@angular/core';
import { EntityManager } from '../entity.service';
import { AuditCategory } from './audit-category.entity';
import { RestResponse } from '../rest.service';
import { AuditOrderableEntity } from './audit-orderable.entity';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuditQuestion } from './audit-question.entity';
import { MdcDialog, MdcSnackbar } from '@angular-mdc/web';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { AuditEntityDialogComponent } from '../audit-entity-dialog/audit-entity-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-audit-sheet',
  templateUrl: './audit-sheet.component.html',
  styleUrls: ['./audit-sheet.component.scss']
})
export class AuditSheetComponent implements OnInit {
  response: RestResponse<AuditCategory[]>;

  protected get auditCategoryService() {
    return this.entityManager.get(AuditCategory);
  }

  protected get auditQuestionService() {
    return this.entityManager.get(AuditQuestion);
  }

  constructor(
    protected readonly mdcDialog: MdcDialog,
    protected readonly snackbar: MdcSnackbar,
    protected readonly entityManager: EntityManager) { }

  ngOnInit() {
    this.fetchCategories();
  }

  async createCategory() {
    const category = await this.mdcDialog
      .open(AuditEntityDialogComponent, {
        data: this.auditCategoryService.concept({
          orderId: this.response.value.length,
          __auditQuestions__: []
        })
      })
      .afterClosed()
      .toPromise();

    if (category instanceof AuditCategory) {
      const response = await this.auditCategoryService.save(category);

      if (!response.hasError()) {
        category.id = response.value.id;
        category.__auditQuestions__ = [];

        this.response.value.push(category);
        this.onSaved();
      }
    }
  }

  async addOrEditCategory(category?: AuditCategory) {
    const updatedCategory = await this.mdcDialog
      .open(AuditEntityDialogComponent, {
        data: category
          ? this.auditCategoryService.copy(category)
          : this.auditCategoryService.concept({
            orderId: this.response.value.length
          })
      })
      .afterClosed()
      .toPromise();

    if (updatedCategory instanceof AuditCategory) {
      const response = await this.auditCategoryService.save(updatedCategory);

      if (!response.hasError()) {
        if (updatedCategory.isConcept) {
          updatedCategory.id = response.value.id;
          this.response.value.push(updatedCategory);
        } else {
          this.auditCategoryService.copyTo(updatedCategory, category);
        }

        this.onSaved();
      }
    }
  }

  async addOrEditQuestion(category: AuditCategory, question?: AuditQuestion) {
    const updatedQuestion = await this.mdcDialog
      .open(AuditEntityDialogComponent, {
        data: question
          ? this.auditQuestionService.copy(question)
          : this.auditQuestionService.concept({
            auditCategoryId: category.id,
            orderId: category.__auditQuestions__.length
          })
      })
      .afterClosed()
      .toPromise();

    if (updatedQuestion instanceof AuditQuestion) {
      const response = await this.auditQuestionService.save(updatedQuestion);

      if (!response.hasError()) {
        if (updatedQuestion.isConcept) {
          updatedQuestion.id = response.value.id;
          category.__auditQuestions__.push(updatedQuestion);
        } else {
          this.auditQuestionService.copyTo(updatedQuestion, question);
        }

        this.onSaved();
      }
    }
  }

  async deleteCategory(id: string) {
    if (await this.confirm()) {
      const response = await this.auditCategoryService.delete(id);

      if (!response.hasError()) {
        const index = this.response.value
          .findIndex(c => c.id === id);

        this.response.value.splice(index, 1);
        this.onSaved();
      }
    }
  }

  async deleteQuestion(id: string, category: AuditCategory) {
    if (await this.confirm()) {
      const response = await this.auditQuestionService.delete(id);

      if (!response.hasError()) {
        const index = category.__auditQuestions__
          .findIndex(c => c.id === id);

        category.__auditQuestions__.splice(index, 1);
        this.onSaved();
      }
    }
  }
  async drop(event: CdkDragDrop<AuditOrderableEntity[]>, type: string) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    this.setOrderIds(event);

    let response = null;

    switch (type) {
      case 'AuditCategory':
        response = await this.auditCategoryService
          .modifyMany(event.container.data
            .map(d => d as AuditCategory));
        break;
      case 'AuditQuestion':
        response = await this.auditQuestionService
          .modifyMany(event.container.data
            .map(d => d as AuditQuestion));
        break;
    }

    if (response && !response.hasError()) {
      this.onSaved();
    }
  }

  protected setOrderIds(event: CdkDragDrop<AuditOrderableEntity[]>) {
    const items = event.container.data;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.orderId = i;
    }
  }

  protected async confirm() {
    return (await this.mdcDialog
      .open(ConfirmDialogComponent, { escapeToClose: false })
      .afterClosed()
      .toPromise()
    ) === 'accept';
  }

  protected onSaved() {
    this.snackbar.open('Opgeslagen', 'Ok', { timeoutMs: 4000, leading: true });
  }

  protected async fetchCategories() {
    const response = await this.auditCategoryService.query({
      relations: ['auditQuestions']
    });

    if (!response.hasError()) {
      const candidate = new RestResponse(
        this.sort(response.value)
      );

      candidate.value.forEach(c => c.__auditQuestions__ =
        this.sort(c.__auditQuestions__));

      this.response = candidate;
    }
  }

  protected sort<T extends AuditOrderableEntity>(items: T[]) {
    return _.orderBy(items, i => i.orderId);
  }
}
