import * as React from "react";
import { EntitySelect } from "../entity-select/entity-select";
import { WorkActionTemplateDeployDialogComponent } from "./work-action-template-deploy-dialog.component";
import { Project } from "../project/project.entity";
import {
  WorkActionTemplateGroup,
  WorkActionTemplate,
} from "../work-action-templates/work-action-template.entity";
import {
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { SaveAltOutlined as SaveAltOutlinedIcon } from "@material-ui/icons";

import * as moment from "moment";
import { WorkAction } from "../work-actions/work-action.entity";
import { orderBy } from "lodash";
import { BlockedCalendarWeek } from "../blocked-calendar-weeks-dialog/blocked-calendar-week.entity";

interface WorkActionTemplateDeployDialogProps {
  component: WorkActionTemplateDeployDialogComponent;
  onClose: () => void;
}

export const WorkActionTemplateDeployDialog: React.FC<WorkActionTemplateDeployDialogProps> = ({
  component,
  onClose,
}) => {
  const [blockedWeeks, setBlockedWeeks] = React.useState<
    BlockedCalendarWeek[]
  >();
  const [group, setGroup] = React.useState<WorkActionTemplateGroup>();
  const [project, setProject] = React.useState<Project>();
  const [dateRaw, setDateRaw] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [date, setDate] = React.useState<Date>();

  const canSave = () => !!group && !!project && !!date;

  const ordered = (templates: WorkActionTemplate[]) => {
    return orderBy(templates, (template) => template.targetDateOffset, "asc");
  };

  const store = async () => {
    const creator = component.creator();
    const actions = group.__workActionTemplates__.map((template) =>
      convert(template)
    );

    await Promise.all(actions.map((action) => creator.save(action, false)));

    onClose();
  };

  const aimDate = (template: WorkActionTemplate) => {
    let candidate = moment(date).add(template.targetDateOffset, "days");

    while (isDateBlocked(candidate)) {
      candidate = candidate.clone().add(1, "weeks");
    }

    return candidate;
  };

  const isDateBlocked = (date: moment.Moment) => {
    const weekNr = date.clone().format("GGGG-WW");

    return !!blockedWeeks.find((b) => b.id === weekNr);
  };

  const convert = (template: WorkActionTemplate) => {
    const userId = component.auth.user.id;
    const repo = component.entities.get(WorkAction);

    return repo.concept({
      title: template.title,
      description: template.description,

      workActionCategoryId: template.workActionCategoryId,

      projectId: project.id,

      userId,
      assignedUserId: userId,

      disableImageUpload: template.disableImageUpload,
      allowEmptyAdvancement: template.allowEmptyAdvancement,

      responsibleDateGroupId: template.responsibleDateGroupId,
      advancementApplyDateId: template.advancementApplyDateId,

      date,
      aimDate: aimDate(template).toDate(),
    });
  };

  const fetchBlockedWeeks = async () => {
    const repo = component.entities.get(BlockedCalendarWeek);
    const response = await repo.query({});

    if (!response.hasError()) {
      setBlockedWeeks(response.value);
    }
  };

  React.useEffect(() => {
    fetchBlockedWeeks();
  }, []);

  React.useEffect(() => {
    setDate(moment(dateRaw).toDate());
  }, [dateRaw]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={4} style={{ borderRight: "1px solid #eeeeee" }}>
          <TextField
            style={{ width: 300, marginBottom: 16 }}
            id="date"
            label="Datum"
            type="date"
            value={dateRaw}
            onChange={(event) => setDateRaw(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <EntitySelect
            type="work_action_template_groups"
            labelSelector="name"
            title="Template groep"
            searchFields={["id", "name"]}
            relations={["workActionTemplates"]}
            onSelect={(group: WorkActionTemplateGroup) => setGroup(group)}
            restService={component.restService}
          />
          <br />
          <EntitySelect
            type="projects"
            labelSelector={(project: Project) =>
              `(${project.id}) ${project.description}`
            }
            title="Project"
            searchFields={["id", "description"]}
            onSelect={(project: Project) => setProject(project)}
            restService={component.restService}
          />
        </Grid>
        <Grid item xs={8}>
          {group && (
            <React.Fragment>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Streefdatum</TableCell>
                        <TableCell>Titel</TableCell>
                        <TableCell>Omschrijving</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ordered(group.__workActionTemplates__).map(
                        (template) => (
                          <TableRow key={template.id}>
                            <TableCell component="th" scope="row">
                              {aimDate(template).format("DD-MM-YYYY")} (
                              {template.targetDateOffset})
                            </TableCell>
                            <TableCell>{template.title}</TableCell>
                            <TableCell>{template.description}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <Button
                variant="outlined"
                color="primary"
                style={{
                  marginTop: 16,
                }}
                disabled={!canSave()}
                startIcon={<SaveAltOutlinedIcon />}
                onClick={() => store()}
              >
                Opslaan
              </Button>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
