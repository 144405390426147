import * as React from "react";
import { InputBase, IconButton, makeStyles, Paper } from "@material-ui/core";
import { useState } from "react";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

export interface MailTrafficSearchProps {
  onSubmit: (value: string) => void | Promise<void>;
}

export function MailTrafficSearch({ onSubmit }: MailTrafficSearchProps) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const reset = () => {
    setValue("");
    onSubmit(null);
    setIsSubmitted(false);
  };

  const submit = () => {
    onSubmit(value);
    setIsSubmitted(true);
  };

  const onKeyDown = (event) => {
    setIsSubmitted(false);

    if (event.key === "Enter") {
      submit();
    }
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <InputBase
        className={classes.input}
        placeholder="Zoeken"
        value={value}
        onKeyDown={onKeyDown}
        onChange={(event) => setValue(event.target.value)}
        inputProps={{ "aria-label": "Zoeken" }}
      />
      <IconButton
        className={classes.iconButton}
        type="submit"
        aria-label="search"
        onClick={() => (isSubmitted ? reset() : submit())}
      >
        {isSubmitted ? <ClearIcon /> : <SearchIcon />}
      </IconButton>
    </Paper>
  );
}
