import { AppDocument } from "./../documents/app-document.entity";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  DateValue,
  ProjectDateCombo,
  DateDefinition,
  DateGroupStackStructure,
  DateGroupStackStructureDate,
} from "../year-planning/entities/date-entities";
import { EntityManager, Ops } from "../entity.service";
import { AuthService } from "../auth.service";
import * as moment from "moment";
import { Project } from "../project/project.entity";
import {
  AppPhaseSectionMark,
  AppPhaseSectionMarkValue,
} from "../app-phases/app-phase.entity";
import { CellaService, Mutex } from "../cella.service";
import { DialogService } from "../dialog.service";
import { AppProjectDate } from "../app-phases/app-project-date.entity";
import { UrlOpenService } from "../url-open.service";
import { TransactionService } from "../transaction.service";
import { DateState } from "./state/date-state";
import { AppDateState } from "./state/app-date-state";
import { PortalDateState } from "./state/portal-date-state";
import { AppNotificationSendDialogComponent } from "../app-notification-send-dialog/app-notification-send-dialog.component";
import { AppService } from "../app.service";

@Component({
  selector: "app-date-stack-dialog",
  templateUrl: "./date-stack-dialog.component.html",
  styleUrls: ["./date-stack-dialog.component.scss"],
})
export class DateStackDialogComponent implements OnInit {
  dates: DateWrapper[];

  appDateState = new AppDateState();
  portalDateState = new PortalDateState();
  mutex: Mutex = null;

  protected get dateValueService() {
    return this.entityManager.get(DateValue);
  }

  protected get phaseSectionMarkService() {
    return this.entityManager.get(AppPhaseSectionMark);
  }

  protected get phaseSectionMarkValueService() {
    return this.entityManager.get(AppPhaseSectionMarkValue);
  }

  protected get projectDateService() {
    return this.entityManager.get(AppProjectDate);
  }

  protected get appDocumentService() {
    return this.entityManager.get(AppDocument);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: DateStackDialogComponentData,
    readonly app: AppService,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly dialogService: DialogService,
    protected readonly dialog: MdcDialogRef<DateStackDialogComponent>,
    protected readonly cellaService: CellaService,
    protected readonly openUrls: UrlOpenService,
    protected readonly transactions: TransactionService
  ) {}

  get userId() {
    return this.authService.user.id;
  }

  get canSave() {
    return this.dates ? this.dates.every((d) => d.canSave) : false;
  }

  get isMutex() {
    return this.mutex != null;
  }

  async ngOnInit() {
    this.mutex = await this.cellaService.mutex(
      "0401",
      `bdr:920¡adm:${this.data.project.regionId}¡prj:${this.data.project.id}`
    );

    const dates = this.data.stack.dates.map(
      (i) => new DateWrapper(i.date, this.getValue(i), this.userId)
    );

    this.dates = await Promise.all(dates.map((item) => this.fetchMarks(item)));
  }

  getDateState(wrapper: DateWrapper): DateState {
    switch (wrapper.target) {
      case DateWrapperTarget.App:
        return this.appDateState;
      case DateWrapperTarget.Portal:
        return this.portalDateState;
    }
  }

  openInfoPage(date: DateWrapper) {
    if (date.isMarkConfigurable && date.mark.notificationTemplate) {
      const id = date.mark.notificationTemplate.content_id;

      if (id) {
        this.openUrls.openNew({
          url: `#/app/content-preview/${id}`,
          forceNewTab: true,
        });
      }
    }
  }

  openAppDocuments() {
    this.openUrls.openNew({
      url: `#/office/documents/${this.data.project.id}?activeTabId=app`,
      forceNewTab: true,
    });
  }

  async sendNotification() {
    await this.dialogService.open(
      this.changeDetector,
      AppNotificationSendDialogComponent,
      {
        data: { projectId: this.data.project.id },
      }
    );
  }

  clear(item: DateWrapper) {
    item.compatibleModel = null;
    item.updateModel();
  }

  async save() {
    const withModel = this.dates.filter((d) => d.isDirty);

    try {
      for (const model of withModel) {
        await this.dateValueService.save(model.value);
      }

      for (const model of withModel) {
        if (model.isMarkConfigurable) {
          await this.saveMarkValue(model);
        }
      }
    } catch (e) {
      this.transactions.showError((e as Error).message);
    }

    this.dialog.close("save");
  }

  protected getValue(combo: DateGroupStackStructureDate) {
    if (combo.value) {
      return this.dateValueService.copy(combo.value);
    }

    return this.dateValueService.concept({
      syncSource: "portal",
      dateId: combo.date.id,
      projectId: this.data.project.id,
      isSynced: combo.date.isSynced,
    });
  }

  protected async fetchMarks(item: DateWrapper) {
    if (item.date.phaseSectionMarkId) {
      const responseMark = await this.phaseSectionMarkService.queryFirst({
        filters: [Ops.Field("id").Equals(item.date.phaseSectionMarkId)],
        relations: ["notificationTemplate"],
      });

      if (!responseMark.hasError()) {
        item.mark = responseMark.value;

        if (responseMark.value.document_type_id !== null) {
          const responseDocuments = await this.appDocumentService.queryFirst({
            filters: [
              Ops.Field("document_type_id").Equals(
                responseMark.value.document_type_id
              ),
              Ops.Field("project_id").Equals(this.data.project.id),
            ],
          });

          item.hasDocuments = !!responseDocuments?.value?.id;
        }
      }

      const responseMarkValue =
        await this.phaseSectionMarkValueService.queryFirst(
          this.composeMarkValueQuery(item)
        );

      if (!responseMarkValue.hasError() && !!responseMarkValue.value) {
        item.markValue = responseMarkValue.value;
      } else if (item.mark) {
        item.markValue = this.phaseSectionMarkValueService.concept({
          project_id: this.data.project.id,
          phase_section_mark_id: item.date.phaseSectionMarkId,
          activated: item.mark.is_standard,
        });
      }
    }

    return item;
  }

  protected async saveMarkValue(date: DateWrapper) {
    date.markValue.completed_user_id = this.authService.user.id;

    const response = await this.phaseSectionMarkValueService.save(
      date.markValue
    );

    if (!response.hasError()) {
      date.markValue = response.value;
    }

    return response;
  }

  protected composeMarkValueQuery(item: DateWrapper) {
    return {
      filters: [
        {
          field: "project_id",
          operator: "Equal",
          value: this.data.project.id,
        },
        {
          field: "phase_section_mark_id",
          operator: "Equal",
          value: item.date.phaseSectionMarkId,
        },
      ],
    };
  }
}

enum DateWrapperTarget {
  App = "app",
  Portal = "portal",
}

export class DateWrapper extends ProjectDateCombo {
  compatibleModel: string;

  mark: AppPhaseSectionMark;
  markValue: AppPhaseSectionMarkValue;

  hasDocuments: boolean;

  sendNotification: boolean;

  get target() {
    return this.isMarkConfigurable
      ? DateWrapperTarget.App
      : DateWrapperTarget.Portal;
  }

  get isDirty() {
    return this.value.isDirty || (this.markValue && this.markValue.isDirty);
  }

  get isMarkCompleted() {
    return (
      this.isMarkConfigurable &&
      this.markValue.activated &&
      this.markValue.completed
    );
  }

  constructor(
    public readonly date: DateDefinition,
    public readonly value: DateValue,
    protected readonly userId: string
  ) {
    super(date, value);

    if (value.value) {
      this.compatibleModel = moment(value.value).format("YYYY-MM-DD");
    }
  }

  updateModel() {
    this.value.value = this.compatibleModel
      ? new Date(this.compatibleModel)
      : null;

    this.value.userId = this.value.value ? this.userId : null;
  }

  get metacomModel() {
    return this.value.value
      ? moment(this.value.value).format("DD-MM-YYYY")
      : "";
  }

  get isMarkConfigurable() {
    return this.mark && this.markValue;
  }

  get canSave() {
    return this.isMarkCompleted ? !!this.value.value : true;
  }
}

export class DateStackDialogComponentData {
  constructor(
    public readonly project: Project,
    public readonly stack: DateGroupStackStructure
  ) {}
}
