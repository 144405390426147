import { Component, Inject } from "@angular/core";
import { AppProjectCostPreset } from "../app-project-cost-presets/app-project-cost-preset.entity";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";

@Component({
  selector: "app-app-project-cost-preset-dialog",
  templateUrl: "./app-project-cost-preset-dialog.component.html",
  styleUrls: ["./app-project-cost-preset-dialog.component.scss"],
})
export class AppProjectCostPresetDialogComponent extends AppCrudDialogBoilerplate<
  AppProjectCostPreset
> {
  get canSave() {
    return !!this.data.model.title && !!this.data.model.description;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<AppProjectCostPreset>,
    protected readonly dialog: MdcDialogRef<AppProjectCostPresetDialogComponent>
  ) {
    super(data, dialog);
  }
}
