import { Entity } from "../entity.service";
import { UrlOpenService } from "../url-open.service";
import { environment } from "src/environments/environment";

export class Greeting extends Entity {
  type = "greetings";

  html: string;

  isDeleted?: boolean;
}

export const setupAnchorOverwrite = (urlOpenService: UrlOpenService) => {
  const isUploaded = (href: string) => {
    return (
      href &&
      href.startsWith(environment.baseUrl) &&
      href.indexOf("documents/open/") >= 0
    );
  };

  const handle = (e: Event) => {
    const element: HTMLElement = <any>e.target || e.srcElement;
    const href = element.getAttribute("href");

    if (element.className === "fr-file" || isUploaded(href)) {
      e.stopPropagation();
      e.preventDefault();

      urlOpenService.open(href, true);

      return false;
    }
  };

  document.addEventListener("click", handle);
  document.addEventListener("touchend", handle);

  return () => {
    document.removeEventListener("click", handle);
    document.removeEventListener("touchend", handle);
  };
};
