import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Many } from "lodash";
import * as _ from "lodash";

@Component({
  selector: "app-complex-table",
  templateUrl: "./complex-table.component.html",
  styleUrls: ["./complex-table.component.scss"],
})
export class ComplexTableComponent implements OnChanges {
  @Input() data: ComplexTableDataGroup<{}>[] = [];

  @Input() columns: ComplexTableColumn<{}>[] = [];
  @Input() actions?: ComplexTableAction<{}>[] = [];

  @Output() actionClicked = new EventEmitter<ComplexTableActionClicked<{}>>();
  @Output() actionGroupClicked = new EventEmitter<
    ComplexTableActionGroupClicked<{}>
  >();

  @Input() sortColumnId? = null;
  @Input() sortDirection?: Many<boolean | "asc" | "desc"> = "asc";

  state: ComplexTableDataGroup<{}>[] = [];
  stateHash: string;

  protected readonly decoder = document.createElement("textarea");

  hasFooter() {
    return this.columns.find((c) => !!c.footer);
  }

  ngOnChanges(changes: SimpleChanges) {
    const dataChange = changes["data"];

    if (dataChange && dataChange.currentValue !== dataChange.previousValue) {
      this.state = this.evaluateSort(this.evaluateProperties(this.data));
    }
  }

  protected evaluateProperties(groups: ComplexTableDataGroup<{}>[]) {
    const copy = Array.from(this.copyGroups(groups));

    this.columns.forEach((c) =>
      copy.forEach((g) => g.rows.map((r) => this.evalColumn(r, c)))
    );

    this.actions
      .filter((a) => a.type === "row")
      .forEach((a) =>
        copy.forEach((g) => g.rows.map((r) => this.evalAction(r, a)))
      );

    return copy;
  }

  protected evaluateSort(groups: ComplexTableDataGroup<{}>[]) {
    if (this.sortColumnId) {
      return _.chain(groups)
        .forEach(
          (group) =>
            (group.rows = _.orderBy(
              group.rows,
              (i) => this.getSortValue(i),
              this.sortDirection
            ))
        )
        .value();
    }

    return groups;
  }

  sort(column: ComplexTableColumn<{}>) {
    if (this.sortColumnId === column.id) {
      this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
    } else {
      this.sortDirection = "asc";
    }

    this.sortColumnId = column.id;
    this.state = this.evaluateSort(this.state);
  }

  actionClick(action: ComplexTableAction<{}>, row: ComplexTableDataRow<{}>) {
    this.actionClicked.next(
      new ComplexTableActionClicked<{}>(row.data, action)
    );
  }

  actionGroupClick(
    action: ComplexTableAction<{}>,
    group: ComplexTableDataGroup<{}>
  ) {
    this.actionGroupClicked.next(
      new ComplexTableActionGroupClicked<{}>(group, action)
    );
  }

  protected evalColumn(
    row: ComplexTableDataRow<{}>,
    column: ComplexTableColumn<{}>
  ) {
    row.values[column.id] = this.decode(column.text(row.data) || "-");
    row.notes[column.id] = column.note ? column.note(row.data) : null;

    if (column.cssClasses) {
      row.cssClasses.push(...column.cssClasses(row.data));
    }

    return row;
  }

  protected evalAction(
    row: ComplexTableDataRow<{}>,
    action: ComplexTableAction<{}>
  ) {
    row.colors[action.id] =
      (action.color ? action.color(row.data) : null) || "grey";

    return row;
  }

  protected decode(html: string) {
    this.decoder.innerHTML = html;
    return this.decoder.value;
  }

  protected *copyGroups(groups: ComplexTableDataGroup<{}>[]) {
    for (const group of groups) {
      const rows = Array.from(this.copyRows(group.rows));
      yield new ComplexTableDataGroup<{}>(
        rows,
        group.id,
        group.name,
        group.isVisible,
        group.columns,
        group.isExpanded
      );
    }
  }

  protected *copyRows(rows: ComplexTableDataRow<{}>[]) {
    for (const row of rows) {
      yield new ComplexTableDataRow<{}>(row.data);
    }
  }

  protected getSortValue(row: ComplexTableDataRow<{}>) {
    const column = this.columns.find((c) => c.id === this.sortColumnId);

    return column && column.sort
      ? column.sort(row.data)
      : row.values[this.sortColumnId];
  }
}

export interface ComplexTableColumnInfo<T> {
  id: string;
  title: string;
  align?: string;
  width?: string;
  sortable?: boolean;
  wrap?: boolean;
  text: (item: T) => any;
  sort?: (item: T) => any;
  note?: (item: T) => any;
  cssClasses?: (item: T) => string[];
  footer?: (rows: ComplexTableDataRow<T>[]) => any;
}

export class ComplexTableColumn<T> implements ComplexTableColumnInfo<T> {
  readonly id: string;
  readonly title: string;
  readonly align: string = "left";
  readonly width: string = "auto";
  readonly sortable: boolean = true;
  readonly wrap: boolean = false;

  readonly note?: (item: T) => any = null;
  readonly text: (item: T) => any = null;
  readonly sort: (item: T) => any = null;
  readonly cssClasses?: (item: T) => string[] = null;

  readonly footer?: (rows: ComplexTableDataRow<T>[]) => any = null;

  constructor(setter: ComplexTableColumnInfo<T>) {
    Object.assign(this, setter);
  }
}

export interface ComplexTableActionInfo<T> {
  id: string;
  icon: string;
  title: string;
  type?: string;

  color?: (item: T) => string;
}

export class ComplexTableAction<T> implements ComplexTableActionInfo<T> {
  readonly id: string;
  readonly icon: string;
  readonly title: string;
  readonly type: string = "row";

  readonly color: (item: T) => any;

  constructor(setter: ComplexTableActionInfo<T>) {
    Object.assign(this, setter);
  }
}

export class ComplexTableActionClicked<T> {
  constructor(readonly item: T, readonly action: ComplexTableAction<T>) {}
}

export class ComplexTableActionGroupClicked<T> {
  constructor(
    readonly group: ComplexTableDataGroup<T>,
    readonly action: ComplexTableAction<T>
  ) {}
}

export class ComplexTableDataGroup<T> {
  constructor(
    public rows: ComplexTableDataRow<T>[],
    readonly id: string = null,
    readonly name: string = null,
    readonly isVisible: boolean = false,
    readonly columns: ComplexTableGroupColumn[] = [],
    public isExpanded = true
  ) {}
}

export class ComplexTableDataRow<T> {
  values = {};
  notes = {};
  colors = {};
  cssClasses = [];

  constructor(readonly data: T) {}
}

export interface ComplexTableGroupColumn {
  align?: string;
  value: string;
}
