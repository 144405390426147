import { Component } from "@angular/core";
import { EntityManager } from "../entity.service";
import { ReactComponent } from "../react-component/react.component";
import { RestService } from "../rest.service";
import { ServiceSettingsReactComponent } from "./react/service-settings.react-component";
import { Apollo } from "apollo-angular";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-service-settings",
  templateUrl: "./service-settings.component.html",
  styleUrls: ["./service-settings.component.scss"],
})
export class ServiceSettingsComponent extends ReactComponent {
  containerId = "react-component--service-settings";
  component = ServiceSettingsReactComponent;

  constructor(
    readonly http: HttpClient,
    readonly apollo: Apollo,
    readonly rest: RestService,
    readonly entities: EntityManager) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
