import { ProjectShortcutHandler } from "../project-shortcut.handler";
import { Injectable, ChangeDetectorRef } from "@angular/core";
import { AppService } from "src/app/app.service";
import { DialogService } from "src/app/dialog.service";
import { AppNotificationSendDialogComponent } from "src/app/app-notification-send-dialog/app-notification-send-dialog.component";

@Injectable({ providedIn: "root" })
export class SendNotificationHandler implements ProjectShortcutHandler {
  constructor(
    protected readonly app: AppService,
    protected readonly dialogs: DialogService
  ) {}

  access() {
    return this.app.isAuthenticated;
  }

  async perform(changes: ChangeDetectorRef, id: string) {
    return await this.dialogs.open(
      changes,
      AppNotificationSendDialogComponent,
      {
        data: { projectId: id },
      }
    );
  }
}
