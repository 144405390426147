import { Injector } from "@angular/core";
import gql from "graphql-tag";
import { useApolloQuery } from "src/app/react-component/react.hooks";
import { GraphQLTypes, InputType, ValueTypes, Zeus } from "src/app/zeus";

export const useApolloZeusQuery = <Z extends ValueTypes[O], O extends "Query">(
  injector: Injector,
  query: Z | ValueTypes[O],
  variables: Record<string, any> = {},
  operationName: string = undefined
) => {
  return useApolloQuery<InputType<GraphQLTypes[O], Z>>(injector, {
    query: gql(Zeus("query", query, operationName)),
    variables,
  });
};
