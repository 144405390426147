import { Table, Entity } from "../entity.service";
import { AuditReport } from "../audit-sheet-report/audit-report.entities";
import { AuditChecklistImage } from "../audit-checklist-images/audit-checklist-image.entity";

@Table("cost_categories")
export class CostCategory extends Entity {
  __auditQuestionCostCategories__?: AuditQuestionCostCategory[];

  description: string;

  hasImages: string;
  isChecklist: string;

  report?: AuditReport;
  checklistImages?: AuditChecklistImage[];
}

@Table("audit_question_cost_categories")
export class AuditQuestionCostCategory extends Entity {
  costCategoryId: string;
  auditQuestionId: string;
}
