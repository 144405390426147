import { DialogService } from "./../dialog.service";
import { UrlOpenService } from "./../url-open.service";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { RestResponse } from "../rest.service";
import { Subject } from "rxjs";
import { MetacomService } from "../metacom.service";
import { tap, debounceTime } from "rxjs/operators";
import { EntityManager } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import * as _ from "lodash";
import * as moment from "moment";
import {
  EmployeeFileDialogComponent,
  EmployeeFileDialogComponentData,
} from "../employee-file-dialog/employee-file-dialog.component";

@Component({
  selector: "app-metacom-price",
  templateUrl: "./metacom-price.component.html",
  styleUrls: ["./metacom-price.component.scss"],
})
export class MetacomPriceComponent implements OnInit {
  filterQuery = "";
  response: RestResponse<any[]>;

  relationIdModel: string;
  relationFilterConfig = {
    allowNothing: true,
    title: "Relatie",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "isSupplier", operator: "Equal", value: "t" },
    ],
  };

  protected filterQueryChanged = new Subject<string>();

  protected get userService() {
    return this.entityManager.get(User);
  }

  protected get relationId() {
    return this.isFilteringAllowed
      ? this.relationIdModel
      : this.authService.user.identity;
  }

  get isFilteringAllowed() {
    return this.grantService.varIs(grants.metacom_price.allow_filter, "true");
  }

  get showInternalDocuments() {
    return this.grantService.varIs(
      grants.metacom_price.show_internal_documents,
      "true"
    );
  }

  get showValidFrom() {
    return this.grantService.varIs(
      grants.metacom_price.show_valid_from,
      "true"
    );
  }

  constructor(
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly entityManager: EntityManager,
    protected readonly metacomService: MetacomService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.response = new RestResponse<PriceGroup[]>([]);
    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500)
      )
      .subscribe(() => this.fetchItems());

    if (!this.isFilteringAllowed) {
      this.onFilterQuery();
    }
  }

  onFilterQuery() {
    this.filterQueryChanged.next(this.filterQuery);
  }

  openEmployees(relationId: string) {
    this.urlOpenService.open(
      `${location.origin}/#/construction/employees/${relationId}`,
      false
    );
  }

  async openDocuments(relationId: string) {
    await this.dialogs.open(this.changeDetector, EmployeeFileDialogComponent, {
      data: {
        relationId,
        showInternal: this.showInternalDocuments,
      },
    });
  }

  protected async fetchItems() {
    if (!!this.relationId || !!this.filterQuery.length) {
      const response = await this.metacomService.queryTableAsync<any>({
        setName: "metacom",
        tableName: "prijsafspraken",
        filter: this.makeQuery(),
      });

      if (!response.hasError()) {
        this.response = new RestResponse(
          await Promise.all(
            _.chain(response.value)
              .groupBy((p) => p.relatie)
              .forEach((i) =>
                i.forEach(
                  (l) =>
                    (l.prijs_datum = moment(l.prijs_datum).format("DD-MM-YYYY"))
                )
              )
              .map((items, relationId) => this.makeGroup(relationId, items))
              .value()
          )
        );
      }
    }
  }

  protected makeQuery() {
    const queries = [];

    if (this.relationId) {
      queries.push(`mtc_middel.rel = "${this.relationId}"`);
    }

    if (this.filterQuery) {
      queries.push(`mtc_middel.oms_t CONTAINS "${this.filterQuery}*"`);
    }

    return queries.join(" AND ");
  }

  protected async makeGroup(key: string, items: any[]) {
    const relation = await this.userService.findOne(key);

    return new PriceGroup(key, relation.value.name, items);
  }
}

export class PriceGroup {
  constructor(
    readonly groupId: string,
    readonly groupName: string,
    readonly items: any[]
  ) {}
}
