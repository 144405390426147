import * as React from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Paper,
  Box,
  Button,
} from "@material-ui/core";
import { Apollo } from "apollo-angular";
import {
  TemplateBloc,
  TemplateBlocState,
  TemplateBlocEvent,
  TemplateDocumentId,
} from "./template.bloc";
import { SecureImage } from "src/app/secured-image/secure-image";
import { HttpClient } from "@angular/common/http";
import { BlocBuilder } from "../../bloc/bloc.builder";
import { first } from "lodash";
import Dropzone from "react-dropzone";

export interface TemplateComponentProps {
  http: HttpClient;
  apollo: Apollo;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  paper: {
    flex: 1,
    padding: 16,
    margin: 6,
  },
}));

export const TemplateComponent: React.FC<TemplateComponentProps> = ({
  http,
  apollo,
}) => {
  const classes = useStyles();
  const [bloc] = React.useState(new TemplateBloc(apollo));

  function mapStateToDocumentId(
    state: TemplateBlocState,
    documentId: TemplateDocumentId,
    title: string
  ) {
    return (
      <Paper className={classes.paper}>
        <Box p={1}>
          <h3>{title}</h3>

          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles) => {
              bloc.add(
                new TemplateBlocEvent({
                  file: first(acceptedFiles),
                  documentId,
                })
              );
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section style={{ padding: 4 }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    <Button size="small" variant="outlined">
                      Aanpassen
                    </Button>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>

          <SecureImage
            style={{ maxWidth: "100%", marginTop: 16 }}
            http={http}
            noCache
            noDebounce
            placeholder={<></>}
            src={`documents/view/${documentId.toString()}?v=${
              state.props.imageVersion
            }`}
          />
        </Box>
      </Paper>
    );
  }

  function mapStateToNode(state: TemplateBlocState) {
    return (
      <div className={classes.root}>
        {mapStateToDocumentId(state, TemplateDocumentId.Front, "Voorblad")}

        <div style={{ width: 32 }} />

        {mapStateToDocumentId(state, TemplateDocumentId.Intro, "Introductie")}
      </div>
    );
  }

  return (
    <BlocBuilder
      bloc={bloc}
      builder={(state: TemplateBlocState) => mapStateToNode(state)}
    />
  );
};
