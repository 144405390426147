import * as React from 'react';
import { Injector } from '@angular/core';
import { BlocBuilder, BlocProvider } from '@comceptum-software/react-bloc';
import { Apollo } from 'apollo-angular';
import { PicklistDefinitionsBloc } from './picklist-definitions.bloc';
import {
  PicklistDefinitionsFailureState,
  PicklistDefinitionsLoadedState,
  PicklistDefinitionsLoadingState,
  PicklistDefinitionsState,
} from './picklist-definitions.state';
import {
  PicklistDefinitionsAddRequestEvent,
  PicklistDefinitionsDeleteRequestEvent,
  PicklistDefinitionsLoadRequestEvent,
  PicklistDefinitionsStoreRequestEvent,
} from './picklist-definitions.event';
import { BlocContext } from '@comceptum-software/react-bloc/dist/bloc.context';
import { PicklistDefinitionEditor } from './picklist-definition.editor';
import { Button, Grid, Paper } from '@material-ui/core';

export interface PicklistDefinitionsComponentProps {
  injector: Injector;
}

export function PicklistDefinitionsComponent(props: PicklistDefinitionsComponentProps) {
  function _mapStateToElements(
    context: BlocContext<PicklistDefinitionsBloc>,
    state: PicklistDefinitionsState) {

    if (state instanceof PicklistDefinitionsLoadingState) {
      return _mapLoadingStateToElements(context, state);
    }

    if (state instanceof PicklistDefinitionsFailureState) {
      return _mapFailureStateToElements(context, state);
    }

    if (state instanceof PicklistDefinitionsLoadedState) {
      return _mapLoadedStateToElements(context, state);
    }

    return <></>;
  }

  function _mapLoadingStateToElements(context: BlocContext<PicklistDefinitionsBloc>, state: PicklistDefinitionsLoadingState) {
    return <p>Laden...</p>;
  }

  function _mapFailureStateToElements(context: BlocContext<PicklistDefinitionsBloc>, state: PicklistDefinitionsFailureState) {
    return <p>{state.errorMessage}</p>;
  }

  function _mapLoadedStateToElements(context: BlocContext<PicklistDefinitionsBloc>, state: PicklistDefinitionsLoadedState) {
    return <>
      <Grid container spacing={2} style={{ padding: 24 }}>
        <Grid item xs={6}>
          {state.items.map((_item, _i) =>
            <PicklistDefinitionEditor key={`picklist-editor-${_i}`} model={_item}
                                      onDelete={() => context.bloc.add(new PicklistDefinitionsDeleteRequestEvent({
                                        model: _item,
                                      }))}
                                      onSave={(update) => context.bloc.add(new PicklistDefinitionsStoreRequestEvent({
                                        model: update,
                                      }))} />)}

          <Paper style={{ padding: 6, marginTop: 16 }}>
            <Button color={'primary'} onClick={() => context.bloc.add(new PicklistDefinitionsAddRequestEvent())}>Toevoegen</Button>
          </Paper>
        </Grid>
      </Grid>
    </>;
  }

  return <BlocProvider<PicklistDefinitionsBloc>
    create={() =>
      new PicklistDefinitionsBloc(props.injector.get(Apollo)).add(
        new PicklistDefinitionsLoadRequestEvent(),
      )
    }
  >
    <BlocBuilder<PicklistDefinitionsBloc, PicklistDefinitionsState>
      bloc={PicklistDefinitionsBloc}
      builder={(context, state) => _mapStateToElements(context, state)}
    />
  </BlocProvider>;
}
