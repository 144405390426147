import { Component, OnInit, HostListener } from "@angular/core";
import { PrintService } from "./print.service";
import { SwPush } from "@angular/service-worker";
import { MdcSnackbar, MdcDialogRef } from "@angular-mdc/web";
import { AuthService } from "./auth.service";
import { Router, ActivationEnd } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { MasterComponent } from "./master/master.component";
import { DialogService } from "./dialog.service";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { PageService } from "./page.service";
import { environment } from "../environments/environment";
import { GrantService } from "./grant.service";
import { ZendeskConfig } from "./zendesk-config";
import { grants } from "./app-grant-config";
import { fromEvent, interval, merge, Observable, Observer } from "rxjs";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    public readonly printService: PrintService,
    protected readonly router: Router,
    protected readonly snackbar: MdcSnackbar,
    protected readonly authService: AuthService,
    protected readonly grantService: GrantService,
    protected readonly pageService: PageService,
    protected readonly dialogService: DialogService,
    protected readonly http: HttpClient
  ) {
      interval(15 * 1000).subscribe(() => this.checkForUpdates());
  }

  isOnline = true;
  isStandalone = false;

  reconnectDialog: MdcDialogRef<LoadingDialogComponent, {}>;

  buffer: string[] = [];

  ngOnInit() {
    this.router.events
      .pipe(
        filter((_) => _ instanceof ActivationEnd),
        filter((_) => !!(_ as ActivationEnd).snapshot.component),
        filter(
          (_) => (_ as ActivationEnd).snapshot.component !== MasterComponent
        )
      )
      .subscribe((event) => this.onRoute(event as ActivationEnd));

    this.authService.onlineSubject.subscribe((_value) =>
      this.onOnline(_value, "Verbinding met de server is verbroken.")
    );
    this.createOnline$().subscribe((_value) =>
      this.onOnline(_value, "Controleer uw verbinding.")
    );
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, "offline").pipe(map(() => false)),
      fromEvent(window, "online").pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  protected async checkForUpdates() {
    const { version } = await this.http
      .get<{ version: string }>(`${location.origin}/assets/version.json`)
      .toPromise();

    if (version !== environment.version) {
      await this.snackbar
        .open("Nieuwe versie beschikbaar", "Update", { timeoutMs: 10000 })
        .afterDismiss()
        .toPromise();

      location.reload();
    }
  }

  protected onRoute(event: ActivationEnd) {
    const projectId =
      event.snapshot.params.projectId || event.snapshot.data.projectId || null;
    const title = event.snapshot.data.title;

    this.pageService.setTitle(title);
    this.authService.projectSubject.next(projectId);
  }

  protected onOnline(isOnline: boolean, message: string) {
    if (isOnline && this.reconnectDialog) {
      this.reconnectDialog.close();
      this.reconnectDialog = null;
    } else if (!isOnline && !this.reconnectDialog) {
      this.reconnectDialog = this.dialogService.mdcDialog.open(
        LoadingDialogComponent,
        {
          data: {
            icon: "power_off",
            title: "Verbinding verbroken",
            description: message || "Een ogenblik geduld",
          },
          clickOutsideToClose: false,
        }
      );
    }

    this.isOnline = isOnline;
  }
}
