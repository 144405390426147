import { Component, OnInit, Inject, HostListener } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { orderBy, first } from "lodash";
import { RestService } from "../rest.service";
import { environment } from "../../environments/environment";
import { UrlOpenService } from "../url-open.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-image-slideshow-dialog",
  templateUrl: "./image-slideshow-dialog.component.html",
  styleUrls: ["./image-slideshow-dialog.component.scss"],
})
export class ImageSlideshowDialogComponent implements OnInit {
  cursor: ImageSlidershowItem = null;
  cursorBlobUri: SafeResourceUrl = null;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: ImageSlideshowDialogComponentData,
    protected readonly restService: RestService,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.select(first(this.data.images));
  }

  @HostListener("document:keydown", ["$event"])
  onBack(event: KeyboardEvent) {
    if (event.keyCode === 37) {
      this.back();
    }

    if (event.keyCode === 39) {
      this.forward();
    }
  }

  back() {
    const index = this.data.images.indexOf(this.cursor);

    if (index > 0) {
      this.select(this.data.images[index - 1]);
    } else {
      this.select(this.data.images[this.data.images.length - 1]);
    }
  }

  forward() {
    const index = this.data.images.indexOf(this.cursor);

    if (index < this.data.images.length - 1) {
      this.select(this.data.images[index + 1]);
    } else {
      this.select(this.data.images[0]);
    }
  }

  async rotate() {
    const response = await this.restService.post(
      `documents/rotate/${this.cursor.documentMetaId}`,
      {}
    );

    if (!response.hasError()) {
      this.cursor.thumbUrl += `?i=${Math.random()}`;
    }
  }

  async select(image: ImageSlidershowItem) {
    if (image) {
      this.cursor = image;

      if (image.isPdf) {
        const blob = await this.restService.getFileAsBlob(image.imageUrl);
        const URL = window.URL || window["webkitURL"];

        this.cursorBlobUri = this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(blob)
        );
      }

      const index = this.data.images.indexOf(image);
      const element = document.getElementById(`image__${index}`);

      if (element) {
        element.scrollIntoView();
      }
    }
  }

  fullScreen() {
    this.urlOpenService.open(
      `${environment.baseUrl}/${this.cursor.imageUrl}`,
      true
    );
  }
}
export class ImageSlideshowDialogComponentData {
  constructor(readonly images: ImageSlidershowItem[]) {
    images = orderBy(images, (i) => i.createdAt, ["desc"]);
  }
}

export interface ImageSlidershowItem {
  thumbUrl: string;
  imageUrl: string;
  isPdf?: boolean;
  description?: string;
  documentMetaId?: string;
  createdAt: Date;
}
