import { User } from "../accessibility-users/user.entity";
import { ShopPresetsConfiguration } from "../shop-presets/ShopPresets";

export class ShopItemDto {
  docsoort: string;
  leverancier: string;
  middel: string;
  middel_naam: string;
  omschrijving: string;
  eenheid: string;
  regel: string;
  eenheid_prijs: string;
  kostensoort: string;
  email_adres: string;

  amount: string = null;
  imageDocumentMetaId: string = null;

  get amountNr() {
    return this.amount ? parseFloat(this.amount) : 0;
  }

  get orderId() {
    return parseFloat(this.regel);
  }

  get emails() {
    return this.email_adres ? this.email_adres.split(";") : [];
  }

  constructor(readonly data: any, readonly images: ShopPresetsConfiguration) {
    Object.assign(this, data);

    this.imageDocumentMetaId = images?.[data.middel]?.documentMetaId;
  }
}

export class Address {
  constructor(
    readonly data: {
      readonly address: string;
      readonly zipCode: string;
      readonly city: string;
    }
  ) {}
}

export class ShopRequest {
  constructor(
    readonly data: {
      readonly address: Address;
      readonly projectId: string;
      readonly projectDescription: string;
      readonly deliveryDate: Date;
      readonly addressType: string;
      readonly user: User;
      readonly image?: File;
      readonly comment?: string;
      readonly items: ShopItemDto[];
      readonly documentType?: string;
      readonly companyId: number;
      readonly sourceDocumentNumber: string;
    }
  ) {}
}
