import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import * as moment from "moment";
import { EntitySelectConfig } from "../entity-select/entity-select.component";

@Component({
  selector: "app-planning-filter-dialog",
  templateUrl: "./planning-filter-dialog.component.html",
  styleUrls: ["./planning-filter-dialog.component.scss"]
})
export class PlanningFilterDialogComponent implements OnInit {
  readonly WEEK_FORMAT = "GGGG-WW";

  entityFilterConfig: EntitySelectConfig;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: PlanningFilterDialogComponentData,
    protected readonly dialog: MdcDialogRef<PlanningFilterDialogComponent>
  ) {}

  get canSave() {
    return (
      moment(this.data.weekStartsAt, this.WEEK_FORMAT, true).isValid() &&
      moment(this.data.weekEndsAt, this.WEEK_FORMAT, true).isValid()
    );
  }

  ngOnInit() {
    this.entityFilterConfig = this.data.entityFilterConfig;

    this.data.weekStartsAt =
      this.data.weekStartsAt || moment().format(this.WEEK_FORMAT);

    this.data.weekEndsAt =
      this.data.weekEndsAt || moment().format(this.WEEK_FORMAT);
  }

  apply() {
    const result = this.data.entityId
      ? new PlanningFilterDialogComponentResult(
          this.data.entityId,
          this.data.weekStartsAt,
          this.data.weekEndsAt
        )
      : null;

    this.dialog.close(result);
  }
}
export class PlanningFilterDialogComponentData {
  entityId: string;
  entityFilterConfig: EntitySelectConfig;

  weekStartsAt: string;
  weekEndsAt: string;
}

export class PlanningFilterDialogComponentResult {
  constructor(
    public readonly entityId: string,
    public readonly weekStartsAt: string,
    public readonly weekEndsAt: string
  ) {}
}
