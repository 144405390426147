import * as React from "react";
import { EntityManager, Ops } from "../entity.service";
import { useEffect, useState } from "react";
import { YearPlanningModification } from "../year-planning-modify-week-dialog/year-planning-modification.entity";
import * as moment from "moment";
import { DebouncePromise } from "../tools/debounce-promise";

export interface YearPlanningWeekHistoryProps {
  readonly entities: EntityManager;
  readonly projectId: string;
}

const debouncer = new DebouncePromise();

const YearPlanningWeekHistory: React.FC<YearPlanningWeekHistoryProps> = ({
  entities,
  projectId,
}) => {
  const [lines, setLines] = useState<YearPlanningModification[]>(null);

  const fetch = async () => {
    if (projectId) {
      const response = await entities.get(YearPlanningModification).query({
        relations: ["user"],
        filters: [Ops.Field("projectId").Equals(projectId)],
        orders: [{ field: "createdAt", direction: "DESC" }],
      });

      if (!response.hasError()) {
        setLines(response.value);
      }
    }
  };

  useEffect(() => {
    debouncer.perform(() => fetch());
  }, [projectId]);

  return (
    <React.Fragment>
      {lines ? (
        lines.length > 0 ? (
          lines.map((modif) => (
            <div
              key={modif.id}
              style={{
                paddingTop: 6,
                paddingBottom: 6,
                borderBottom: "1px solid white",
              }}
            >
              van <strong>{modif.oldWeek}</strong> naar{" "}
              <strong>{modif.newWeek}</strong> <br />
              door <strong>{modif.__user__.name}</strong> <br />
              op <strong>{moment(modif.createdAt).format("DD-MM-YYYY")}</strong>
              {modif.notes && (
                <React.Fragment>
                  <br />
                  <i>{modif.notes}</i>
                </React.Fragment>
              )}
            </div>
          ))
        ) : (
          <React.Fragment>Geen wijzigingen</React.Fragment>
        )
      ) : (
        <p>Laden...</p>
      )}
    </React.Fragment>
  );
};

export default YearPlanningWeekHistory;
