import * as React from "react";
import * as moment from "moment";
import {
  Container,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { TaskComponent } from "./task.component";
import { TaskDroppableComponent } from "./task-droppable.component";
import { TaskInterface } from "../interfaces/task.interface";
import { chain, first, maxBy } from "lodash";
import { WeekToolbarComponent } from "./week-toolbar.component";
import { TableLayoutContext } from "../context/table-layout.context";
import { ProjectHousePart } from "src/app/cargo/house-part.entity";
import { PlanningProjectItem } from "src/app/picklist-overview/planning-project-item.entity";
import { Ops } from "src/app/entity.service";
import { composeTask } from "../functions/compose-task";
import { currentWeek } from "../functions/current-week";

import ProjectInterface from "../interfaces/project.interface";
import { useMemo } from "react";

interface ProjectGroup {
  planningId: string;
  tasks: TaskInterface[];
}

export const ProjectsComponent: React.FC<{}> = () => {
  const planCompetenceNumber = "270";
  const context = React.useContext(TableLayoutContext);

  const tasks = context.openTasks || [];

  const [dateStart, setDateStart] = React.useState<Date>(currentWeek());
  const [projects, setProjects] = React.useState<ProjectInterface[]>(null);

  const getPlanId = (task: TaskInterface) => {
    const itemId = parseFloat(task.partCodeId),
      threshold = 175;
    const index = itemId < threshold ? 0 : null;

    const planning = projects
      .map((p) => p.planning)
      .filter((p) => p.projectId === task.projectId)
      .find((p) =>
        index === null ? p.competenceIndex > 0 : index === p.competenceIndex
      );

    return planning ? planning.id : null;
  };

  const calcGrouped = () => {
    return chain(context.openTasks || [])
      .orderBy((t) => t.wallId)
      .groupBy((t) => getPlanId(t))
      .map((items, key) => ({
        planningId: key,
        tasks: items,
      }))
      .value();
  };

  const calcRowCount = () => {
    return grouped.length
      ? maxBy(grouped, (g) => g.tasks.length).tasks.length
      : 1;
  };

  const grouped = useMemo(() => calcGrouped(), [context.openTasks]);
  const rowCount = useMemo(() => calcRowCount(), [grouped]);

  const isLoading = () => !tasks || !projects;

  const renderCells = (row: number) => {
    const cells = [];

    for (const project of projects) {
      const column = grouped.find((p) => p.planningId === project.planning.id);
      const task = column ? column.tasks[row] : null;

      if (task) {
        cells.push(
          <TaskDroppableComponent
            key={`droppable-${task.id}`}
            context={{ id: task.id }}
          >
            <TaskComponent task={task} />
          </TaskDroppableComponent>
        );
      } else {
        cells.push(<TableCell key={`spacer-${project.planning.id}-${row}`} />);
      }
    }
    return cells;
  };

  const renderRows = () => {
    const rows = [];

    for (let currentRow = 0; currentRow < rowCount; currentRow++) {
      rows.push(
        <TableRow hover role="checkbox" key={`row-${currentRow}`}>
          {projects && renderCells(currentRow)}
        </TableRow>
      );
    }

    return rows;
  };

  const fetchProjects = async () => {
    setProjects(undefined);

    const repo = context.component.entities.get(PlanningProjectItem);

    const response = await repo.query({
      filters: [
        Ops.Field("dimension").Equals("competence"),
        Ops.Field("competenceNumber").Equals(planCompetenceNumber),
        Ops.Field("competenceStartsAt").MoreThanOrEqual(
          moment(dateStart).format("YYYY-MM-DD")
        ),
        Ops.Field("competenceEndsAt").LessThanOrEqual(
          moment(dateStart).add(1, "weeks").format("YYYY-MM-DD")
        ),
      ],
      relations: ["project"],
      orders: [{ field: "competenceStartsAt", direction: "ASC" }],
    });

    if (!response.hasError()) {
      setProjects(
        response.value.map((r) => ({
          id: r.projectId,
          name: r.__project__.description,
          planning: r,
          comments: r.__project__.__tableLayoutComments__ || [],
        }))
      );
    }
  };

  const fetchOpenTasks = async () => {
    context.setOpenTasks(undefined);
    context.setBackdropOpen(true);

    const projectIds = projects.map((p) => p.id);

    const repo = context.component.entities.get(ProjectHousePart);
    const response = await repo.query({
      filters: [
        Ops.Field("projectId").In(projectIds),
        Ops.Field("dateId").IsNull(),
        Ops.Field("moldId").IsNull(),
        Ops.Field("costId").Equals(context.component.settings.costId),
        Ops.Field("supplierId").Equals(context.component.settings.supplierId),
        Ops.Field("wallId").IsNull(true),
      ],
      relations: ["housePart", "project"],
    });

    if (!response.hasError()) {
      const items = chain(response.value)
        .filter((item) => isVisible(item))
        .orderBy((item) => item.partCodeId, "asc")
        .groupBy((item) => `${item.projectId}-${item.wallId}`)
        .map((_items, key) => composeTask(first(_items), _items))
        .orderBy((item) => item.wallId, "asc")
        .value();

      context.setOpenTasks(items);
    }

    context.setBackdropOpen(false);
  };

  const isVisible = (item: ProjectHousePart) => {
    const itemId = parseFloat(item.partCodeId),
      threshold = 175;

    const _planning = projects
      .filter((_project) => _project.id === item.projectId)
      .map((_project) => _project.planning);

    return !!_planning.find((_plan) => {
      return _plan.competenceIndex === 0
        ? itemId < threshold
        : itemId >= threshold;
    });
  };

  React.useEffect(() => {
    if (projects && projects.length) {
      fetchOpenTasks();
    }
  }, [projects]);

  React.useEffect(() => {
    fetchProjects();
  }, [dateStart]);

  return (
    <React.Fragment>
      <Container
        className="container overlay"
        maxWidth={false}
        disableGutters={true}
      >
        <WeekToolbarComponent onDateChange={setDateStart} />
        <TableContainer className="table-container overlay">
          <Table
            stickyHeader
            aria-label="sticky table"
            className="table projects"
          >
            <TableHead>
              <TableRow>
                {(projects || []).map((project) => (
                  <TableCell
                    key={project.planning.id}
                    className="header horizontal"
                  >
                    <Typography
                      style={{
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="caption"
                    >
                      {project.id} &bull;{" "}
                      <strong
                        style={{
                          whiteSpace: "inherit",
                        }}
                      >
                        {project.name}
                      </strong>
                    </Typography>
                    <Typography
                      style={{
                        whiteSpace: "pre",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="caption"
                      display="block"
                    >
                      {moment(project.planning.competenceStartsAt)
                        .locale("nl")
                        .format("DD-MM-YYYY")}
                      {project.planning.competenceIndex > 0 && <sup>2</sup>}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading() && (
                <TableRow>
                  <TableCell colSpan={projects ? projects.length : 1}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}

              {renderRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </React.Fragment>
  );
};
