import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { AuthService } from "../auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MdcSnackbar } from "@angular-mdc/web";
import { environment } from "../../environments/environment";
import { DialogService } from "../dialog.service";
import { RestService } from "../rest.service";
import { TransactionService } from "../transaction.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required),
  });

  returnUrl: string;
  logoutReason: string;
  isLoaded: boolean = false;

  isBrowserSupported: boolean;

  get relationId() {
    return this.loginForm.get("username").value;
  }

  /**
   * Creates an instance of LoginComponent.
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {AuthService} authService
   * @param {MdcSnackbar} snackbar
   * @memberof LoginComponent
   */
  constructor(
    readonly authService: AuthService,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly transactions: TransactionService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {}

  get isDebug() {
    return !environment.production;
  }

  async ngOnInit() {
    this.checkBrowser();

    await this.authService.refreshToken();

    if (this.authService.isSignedIn) {
      this.router.navigate(["/"]);
    }

    this.logoutReason = localStorage.getItem("logout:reason");

    if (this.logoutReason) {
      localStorage.removeItem("logout:reason");
    }

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    this.isLoaded = !this.authService.isSignedIn;
  }

  ngAfterViewInit() {
    if (document.body) {
      document.body.click();
    }
  }

  async resetPassword() {
    const isConfirmed = await this.dialogService.confirm(this.changeDetector, {
      title: `Wachtwoord instellen (${this.relationId})`,
      description:
        "Stuur je e-mailadres en je ontvangt binnen enkele minuten een link waarmee je een wachtwoord kunt instellen.",
    });

    if (isConfirmed) {
      const url = `user-password-resets/${this.relationId}`;

      const response = await this.transactions.perform(() =>
        this.restService.get(url)
      );

      if (!response.hasError()) {
        this.snackbar.open("E-mail verstuurd", "OK");
      }
    }
  }

  protected checkBrowser() {
    const allowedTerms = ["Chrome", "Safari", "Firefox", "Edge", "WebKit"];

    this.isBrowserSupported = !!allowedTerms.find(
      (e) => navigator.userAgent.indexOf(e) >= 0
    );
  }

  async login() {
    const response = await this.authService.login(
      this.loginForm.controls["username"].value,
      this.loginForm.controls["password"].value
    );

    if (response.hasError()) {
      this.snackbar.open("Gegevens onjuist", "OK");
    } else {
      const hasAccess = await this.router.navigate([this.returnUrl]);

      if (!hasAccess) {
        await this.router.navigate(["/"]);
      }
    }
  }

  async admin() {
    this.loginForm.controls["username"].setValue("admin");
    this.loginForm.controls["password"].setValue("admin");

    await this.login();
  }

  app() {
    window.location.href = "https://groothuisbouw.info";
  }
}
