import {
  EntityAdvancement,
  EntityImage,
} from "../offers/entity.advancement.entity";
import { Project } from "../project/project.entity";
import { Entity } from "../entity.service";
import { User } from "../accessibility-users/user.entity";
import { first, orderBy } from "lodash";
import * as moment from "moment";

export interface WorkActionProperties {
  title: string;
  description: string;

  allowEmptyAdvancement?: boolean;
  disableImageUpload?: boolean;

  responsibleDateGroupId?: string;
  advancementApplyDateId?: string;
}

export class WorkAction extends Entity implements WorkActionProperties {
  type = "work_actions";

  projectId: string;
  userId: string;
  assignedUserId: string;
  workActionCategoryId?: string;

  title: string;
  entityType: string;
  description: string;

  date: string;

  __user__?: User;
  __project__?: Project;
  __workActionCategory__?: WorkActionCategory;
  __assignedUser__?: User;

  __entityAdvancements__?: EntityAdvancement[];
  __entityImages__?: EntityImage[];

  allowEmptyAdvancement?: boolean;
  disableImageUpload?: boolean;

  responsibleDateGroupId?: string;
  advancementApplyDateId?: string;

  aimDate?: string;
  sendNotification?: boolean;
  active?: EntityAdvancement;
}

export class WorkActionCategory extends Entity {
  type = "work_action_categories";

  description: string;
}

export class WorkActionExtentions {
  constructor(readonly action: WorkAction) {}

  protected get advancements() {
    return this.action.__entityAdvancements__ || [];
  }

  activeAdvancement() {
    return first(
      orderBy(this.advancements, (advancement) => advancement.createdAt, "desc")
    );
  }

  isCompleted() {
    return !!this.advancements.find((advancement) => advancement.isCompleted);
  }

  isExpired() {
    const active = this.activeAdvancement();

    return !this.isCompleted()
      ? active
        ? moment(active.date).isBefore(moment())
        : false
      : false;
  }
}
