import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteInputChangeReason,
} from "@material-ui/lab/Autocomplete";
import { RestService } from "../rest.service";
import {
  Entity,
  EntityQueryFilter,
  EntityQueryOrder,
  Ops,
} from "../entity.service";
import { throttle } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Chip } from "@material-ui/core";

type LabelSelector = (entity: Entity) => string;

export interface EntitySelectSourceMulti {
  onSearch(props: EntitySelectMultiProps, query: string): Promise<Entity[]>;
  find(props: EntitySelectMultiProps, ids: string[]): Promise<Entity[]>;
}

interface EntitySelectMultiProps {
  restService: RestService;
  autoFocus?: boolean;

  source?: EntitySelectSourceMulti;

  disabled?: boolean;

  style?: React.CSSProperties;

  type: string;

  entityIds?: any[];
  entity?: Entity[];

  title?: string;
  limit?: number;
  labelSelector: string | LabelSelector;

  searchFields?: string[];
  filters?: EntityQueryFilter[];
  orders?: EntityQueryOrder[];
  relations?: string[];
  className?: string;

  onSelect: (entity: Entity[]) => void | Promise<any>;
  onLoad?: (entity: Entity[]) => void | Promise<void>;
}

export const EntitySelectMulti = (props: EntitySelectMultiProps) => {
  const [value, setValue] = useState<Entity[] | null>(props.entity || []);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState<Entity[]>([]);
  const [loading, setLoading] = useState(false);

  const [initialLoaded, setInitialLoaded] = useState(false);

  const source = useMemo<EntitySelectSourceMulti>(
    () => props.source || useEntitySourceMulti(),
    [props.source]
  );

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: Entity[]) => void
        ) => {
          setLoading(true);

          source
            .onSearch(props, request.input)
            .then((entities) => (entities ? callback(entities) : () => {}))
            .finally(() => setLoading(false));
        },
        200
      ),
    [source]
  );

  const fetchInitialEntities = async () => {
    const entity = await source.find(props, props.entityIds);

    if (entity) {
      props.onLoad && props.onLoad(entity);

      setValue(entity);
    }
  };

  const optionLabel = (entity: Entity) => {
    if (typeof props.labelSelector === "string") {
      return entity[props.labelSelector];
    }

    return props.labelSelector(entity);
  };

  useEffect(() => {
    let active = true;

    fetch({ input: inputValue }, (results?: Entity[]) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  useEffect(() => {
    if (props.entityIds && !initialLoaded) {
      fetchInitialEntities();
    }

    setInitialLoaded(true);
  }, [props.entityIds]);

  return (
    <Autocomplete
      multiple
      disabled={props.disabled}
      className={props.className}
      style={Object.assign({ width: 300 }, props.style || {})}
      getOptionLabel={(option) => optionLabel(option)}
      getOptionSelected={(option) =>
        !!(value || []).find((item) => item.id === option.id)
      }
      options={options}
      autoComplete
      autoHighlight
      loading={loading}
      loadingText="Laden..."
      noOptionsText="Geen resultaten om weer te geven"
      value={value}
      onChange={(event: any, newValue: Entity[] | null) => {
        setValue(newValue);

        props.onSelect(newValue);
      }}
      onInputChange={(
        event,
        newInputValue,
        reason: AutocompleteInputChangeReason
      ) => {
        if (reason !== "reset") {
          setInputValue(newInputValue);
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={optionLabel(option)}
            size="small"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={props.autoFocus}
          label={props.title || "Zoeken"}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

/**
 * TODO: move to file
 */
class FilterBuilder {
  constructor(
    readonly inputText: string,
    readonly props: EntitySelectMultiProps
  ) {}

  *makeFilters() {
    for (const field of this.props.searchFields) {
      yield* Array.from(this.makeFilter(field));
    }
  }

  protected *makeFilter(field: string) {
    if (this.props.filters) {
      yield* this.props.filters;
    }

    yield {
      field: field,
      isOr: true,
      operator: "Like",
      value: this.inputText,
    } as EntityQueryFilter;
  }
}

export const useEntitySourceMulti = () =>
  ({
    onSearch: async (props, query) => {
      const response = await props.restService.post<Entity[]>(
        `entity/${props.type}/query`,
        {
          take: props.limit || 10,
          filters: Array.from(new FilterBuilder(query, props).makeFilters()),
          orders: props.orders || [],
          relations: props.relations || [],
        }
      );

      if (!response.hasError()) {
        return response.value;
      }
    },
    find: async (props, ids) => {
      const response = await props.restService.post<Entity[]>(
        `entity/${props.type}/query`,
        {
          filters: [Ops.Field("id").In(ids)],
          relations: props.relations || [],
        }
      );

      if (!response.hasError()) {
        return response.value;
      }
    },
  } as EntitySelectSourceMulti);
