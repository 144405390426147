import { EntityManager, EntityService, Ops } from "src/app/entity.service";
import { Project } from "src/app/project/project.entity";
import { RestResponse } from "src/app/rest.service";
import { Bloc } from "src/app/service-settings/bloc/bloc";
import { DeliveryListEntity } from "../../delivery-list.entity";
import {
  DeliveryListAssignedUserUpdateEvent,
  DeliveryListEvent,
  DeliveryListHiredAgentUpdateEvent,
  DeliveryListHouseUpdateEvent,
  DeliveryListIsDeliveredAtUpdateEvent,
  DeliveryListKeyAmountUpdateEvent,
  DeliveryListLoadRequestEvent,
} from "./delivery-list.event";
import {
  DeliveryListLoadFailureState,
  DeliveryListLoadInProgressState,
  DeliveryListLoadSuccessState,
  DeliveryListState,
} from "./delivery-list.state";
import { Apollo } from "apollo-angular";
import { uploadDocumentMutation } from "../signature/signature.mutation";
import { DocumentMeta } from "src/app/documents/document-meta.entity";

export class DeliveryListBloc extends Bloc<
  DeliveryListEvent,
  DeliveryListState
> {
  constructor(readonly apollo: Apollo, readonly entities: EntityManager) {
    super(new DeliveryListLoadInProgressState());
  }

  async *mapEventToState(event: DeliveryListEvent) {
    if (event instanceof DeliveryListLoadRequestEvent) {
      yield* await this._mapLoadRequestToState(event);
    }

    if (event instanceof DeliveryListAssignedUserUpdateEvent) {
      yield* await this._mapAssignedUserUpdateToState(event);
    }

    if (event instanceof DeliveryListHouseUpdateEvent) {
      yield* await this._mapHouseUpdateToState(event);
    }

    if (event instanceof DeliveryListKeyAmountUpdateEvent) {
      yield* await this._mapKeyAmountUpdateToState(event);
    }

    if (event instanceof DeliveryListHiredAgentUpdateEvent) {
      yield* await this._mapHiredAgentUpdateToState(event);
    }

    if (event instanceof DeliveryListIsDeliveredAtUpdateEvent) {
      yield* await this._mapIsDeliveredAtUpdateToState(event);
    }
  }

  async *_mapLoadRequestToState(event: DeliveryListLoadRequestEvent) {
    const repo = this.entities.get(DeliveryListEntity);
    const response = await repo.queryFirst({
      filters: [Ops.Field("projectId").Equals(event.props.projectId)],
      relations: ["project", "assignedUser"],
    });

    yield this._mapDeliveryResponseToState(event, repo, response);
  }

  protected async _mapDeliveryResponseToState(
    event: DeliveryListLoadRequestEvent,
    repo: EntityService<DeliveryListEntity>,
    response: RestResponse<DeliveryListEntity>
  ) {
    if (response.hasError()) {
      return new DeliveryListLoadFailureState({
        message: response.error.message,
      });
    }

    let deliveryList = response.value;

    if (!deliveryList) {
      const repoProject = this.entities.get(Project);
      const responseProject = await repoProject.findOne(event.props.projectId);

      if (responseProject.hasError()) {
        return new DeliveryListLoadFailureState({
          message: responseProject.error.message,
        });
      }

      deliveryList = repo.concept({
        projectId: event.props.projectId,
        project: responseProject.value,
        isDeliveredAt: new Date(),
      });
    }

    if (deliveryList.isFinal && !deliveryList.isDeliveredAt) {
      deliveryList.isDeliveredAt = deliveryList.isFinalAt;
    }

    return new DeliveryListLoadSuccessState({
      deliveryList,
      imageHash: Math.random(),
    });
  }

  protected async *_mapAssignedUserUpdateToState(
    event: DeliveryListAssignedUserUpdateEvent
  ) {
    const repo = this.entities.get(DeliveryListEntity);
    const state = this.stateOf<DeliveryListLoadSuccessState>();

    state.props.deliveryList.assignedUser = event.props.assignedUser;
    state.props.deliveryList.assignedUserId = event.props.assignedUser
      ? event.props.assignedUser.id
      : null;

    const response = await repo.save(state.props.deliveryList);

    if (!response.hasError()) {
      yield new DeliveryListLoadSuccessState({
        deliveryList: response.value,
        imageHash: Math.random(),
      });
    }
  }

  protected async *_mapKeyAmountUpdateToState(
    event: DeliveryListKeyAmountUpdateEvent
  ) {
    const repo = this.entities.get(DeliveryListEntity);
    const state = this.stateOf<DeliveryListLoadSuccessState>();

    state.props.deliveryList.keyAmount = event.props.keyAmount;

    const response = await repo.save(state.props.deliveryList);

    if (!response.hasError()) {
      yield new DeliveryListLoadSuccessState({
        deliveryList: response.value,
        imageHash: Math.random(),
      });
    }
  }

  protected async *_mapHiredAgentUpdateToState(
    event: DeliveryListHiredAgentUpdateEvent
  ) {
    const repo = this.entities.get(DeliveryListEntity);
    const state = this.stateOf<DeliveryListLoadSuccessState>();

    state.props.deliveryList.hiredAgent = event.props.hiredAgent;

    const response = await repo.save(state.props.deliveryList);

    if (!response.hasError()) {
      yield new DeliveryListLoadSuccessState({
        deliveryList: response.value,
        imageHash: Math.random(),
      });
    }
  }

  protected async *_mapIsDeliveredAtUpdateToState(
    event: DeliveryListIsDeliveredAtUpdateEvent
  ) {
    const repo = this.entities.get(DeliveryListEntity);
    const state = this.stateOf<DeliveryListLoadSuccessState>();

    state.props.deliveryList.isDeliveredAt = event.props.isDeliveredAt;

    const response = await repo.save(state.props.deliveryList);

    if (!response.hasError()) {
      yield new DeliveryListLoadSuccessState({
        deliveryList: response.value,
        imageHash: Math.random(),
      });
    }
  }

  protected async *_mapHouseUpdateToState(event: DeliveryListHouseUpdateEvent) {
    const repo = this.entities.get(DeliveryListEntity);
    const state = this.stateOf<DeliveryListLoadSuccessState>();

    const { data: uploadData } = await this.apollo
      .mutate<{ document: DocumentMeta }>({
        mutation: uploadDocumentMutation,
        variables: {
          id: state.props.deliveryList.houseDocumentMetaId,
          file: event.props.file,
          projectId: state.props.deliveryList.projectId,
        },
        context: {
          useMultipart: true,
        },
      })
      .toPromise();

    state.props.deliveryList.houseDocumentMetaId = uploadData.document.id;

    const response = await repo.save(state.props.deliveryList);

    if (!response.hasError()) {
      yield new DeliveryListLoadSuccessState({
        deliveryList: response.value,
        imageHash: Math.random(),
      });
    }
  }
}
