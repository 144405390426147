import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { MetacomService } from "../metacom.service";
import { DeliveryRow } from "../deliveries/deliveries.component";
import { RestResponse } from "../rest.service";
import * as _ from "lodash";

@Component({
  selector: "app-order-expect-dialog",
  templateUrl: "./order-expect-dialog.component.html",
  styleUrls: ["./order-expect-dialog.component.scss"],
})
export class OrderExpectDialogComponent implements OnInit {
  response: RestResponse<DeliveryRow[]>;

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly data: OrderExpectDialogComponentData,
    protected readonly metacomService: MetacomService
  ) {}

  ngOnInit() {
    this.fetch();
  }

  protected async fetch() {
    this.response = await this.metacomService.queryTableAsync<DeliveryRow>({
      setName: "metacom",
      tableName: this.data.tableName,
      filter: `reg_mutatie.van_kdr = '${this.data.elementId}'`,
    });
  }
}

export class OrderExpectDialogComponentData {
  constructor(
    public readonly elementId: string,
    public readonly elementName: string,
    public readonly tableName: string
  ) {}
}
