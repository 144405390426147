import { ShopItemDto } from "./shop-item.dto";
import { sumBy } from "lodash";

export class ShopItemGroup {
  isExpanded: boolean;

  constructor(readonly data: ShopItemDto, readonly items: ShopItemDto[]) {}

  get amountNr() {
    return sumBy(this.items, (i) => i.amountNr);
  }
}
