import { Component, Inject, OnInit } from "@angular/core";
import {
  AppProjectCost,
  ProjectCostInfo,
} from "../app-project-costs/app-project-cost.entity";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";
import { EntityManager, Ops } from "../entity.service";
import { RestResponse } from "../rest.service";

@Component({
  selector: "app-app-project-cost-history-dialog",
  templateUrl: "./app-project-cost-history-dialog.component.html",
  styleUrls: ["./app-project-cost-history-dialog.component.scss"],
})
export class AppProjectCostHistoryDialogComponent implements OnInit {
  info: ProjectCostInfo;

  get createdBy() {
    return this.info
      ? this.info.conceptUser.name
      : this.data.model.user
      ? this.data.model.user.fullName
      : null;
  }

  get createdAt() {
    return this.info ? this.info.createdAt : this.data.model.created_at;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppProjectCostHistoryDialogComponentData,
    protected readonly entities: EntityManager
  ) {}

  async ngOnInit() {
    const repo = this.entities.get(ProjectCostInfo);
    const response = await repo.queryFirst({
      filters: [Ops.Field("appProjectCostId").Equals(this.data.model.id)],
      relations: ["conceptUser"],
    });

    this.info = response.value;
  }
}

export interface AppProjectCostHistoryDialogComponentData {
  model: AppProjectCost;
}
