import * as React from "react";
import {
  makeStyles,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import { EntityManager } from "src/app/entity.service";
import {
  CrudLoadRequestEvent,
  CrudModifiedEvent,
} from "../../bloc/crud/crud.event";
import { CrudComponent } from "../crud.component";
import { PriorityBloc } from "./priority.bloc";
import { PriorityEntity } from "./priority.entity";

export interface PriorityComponentProps {
  entities: EntityManager;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    flex: 1,
  },
  selectField: {
    flex: 1,
  },
  textDivider: {
    width: 16,
  },
  addButton: {
    marginTop: 24,
  },
  column: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  row: {
    marginBottom: 12,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
}));

export const PriorityComponent: React.FC<PriorityComponentProps> = ({
  entities,
}) => {
  const classes = useStyles();
  const [bloc] = React.useState(
    new PriorityBloc(entities).add(new CrudLoadRequestEvent())
  );

  const mapEntityToTitle = (entity: PriorityEntity) => (
    <Typography className={classes.heading}>
      <strong>{entity.description || "PRIO"}</strong>{" "}
      {entity.offsetDays > 0 && "+"}
      {entity.offsetDays} {entity.isDirty && <i>(niet opgeslagen)</i>}
    </Typography>
  );

  const mapEntityToEditor = (entity: PriorityEntity) => (
    <div className={classes.column} key={entity.refId}>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Omschrijving"
          value={entity.description}
          onChange={(event) => {
            entity.description = event.target.value;

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />

        <div className={classes.textDivider}></div>

        <TextField
          type="number"
          variant="outlined"
          label="Dag(en) na oplevering"
          value={entity.offsetDays}
          className={classes.textField}
          onChange={(event) => {
            entity.offsetDays = parseFloat(event.target.value);

            bloc.add(new CrudModifiedEvent({ entity }));
          }}
        />
      </div>
      <div className={classes.row}>
        <FormControlLabel
          className={classes.textField}
          control={
            <Checkbox
              checked={entity.isStandard}
              onChange={(event) => {
                entity.isStandard = event.target.checked;

                bloc.add(new CrudModifiedEvent({ entity }));
              }}
              name="isStandard"
              color="primary"
            />
          }
          label="Standaard"
        />
      </div>
    </div>
  );

  return (
    <CrudComponent
      bloc={bloc}
      renderTitle={(entity: PriorityEntity) => mapEntityToTitle(entity)}
      renderEditor={(entity: PriorityEntity) => mapEntityToEditor(entity)}
      saveDisabled={(entity: PriorityEntity, siblings: PriorityEntity[]) =>
        !entity.description ||
        entity.offsetDays == null ||
        entity.offsetDays == undefined ||
        !!siblings.find(
          (sibling) => sibling.description === entity.description
        ) ||
        (entity.isStandard
          ? !!siblings.find((_sibling) => _sibling.isStandard)
          : false)
      }
    />
  );
};
