import { PicklistDefinitionDto } from './picklist-definition.dto';
import { Equatable } from '@comceptum-software/react-bloc';

export abstract class PicklistDefinitionsState extends Equatable {
}

export class PicklistDefinitionsLoadingState extends PicklistDefinitionsState {
  props = () => [];
}

export class PicklistDefinitionsFailureState extends PicklistDefinitionsState {
  readonly errorMessage: string;

  constructor(props: {
    readonly errorMessage: string
  }) {
    super(props);
  }

  props = () => [this.errorMessage];
}

export class PicklistDefinitionsLoadedState extends PicklistDefinitionsState {
  readonly items: PicklistDefinitionDto[];

  constructor(props: {
    readonly  items: PicklistDefinitionDto[]
  }) {
    super(props);
  }

  props = () => [this.items];
}
