import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import {
  DateGroupComment,
  ProjectDateGroup,
  DateGroupContainer,
} from "../year-planning/entities/date-entities";
import { EntityManager } from "../entity.service";
import { AuthService } from "../auth.service";
import * as _ from "lodash";
import { Project } from "../project/project.entity";

@Component({
  selector: "app-date-group-comment-dialog",
  templateUrl: "./date-group-comment-dialog.component.html",
  styleUrls: ["./date-group-comment-dialog.component.scss"],
})
export class DateGroupCommentDialogComponent implements OnInit {
  comments: DateGroupComment[];
  commentText = "";

  @ViewChild("commentContent", { static: true }) commentContent: ElementRef;

  protected get dateGroupCommentService() {
    return this.entityManager.get(DateGroupComment);
  }

  get canAddComment() {
    return this.commentText.length;
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: DateGroupCommentDialogComponentData,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialogRef<DateGroupCommentDialogComponent>
  ) {}

  ngOnInit() {
    const comments = this.data.group.comments.map((c) =>
      this.dateGroupCommentService.copy(c)
    );

    this.comments = _.orderBy(comments, (c) => c.createdAt, "asc");
    this.scrollToBottom();
  }

  async save() {
    await this.saveComments();
    await this.dialog.close("save");
  }

  addComment() {
    if (this.canAddComment) {
      const concept = this.dateGroupCommentService.concept({
        projectId: this.data.project.id,
        userId: this.authService.user.id,
        dateGroupId: this.data.group.group.id,
        value: this.commentText,
        createdAt: new Date(),
        __user__: this.authService.user,
      });

      this.comments.push(concept);
      this.commentText = "";

      this.scrollToBottom();
    }
  }

  protected scrollToBottom() {
    setTimeout(() => {
      const element = this.commentContent.nativeElement;
      element.scrollTo(0, element.scrollHeight);
    }, 100);
  }

  protected async saveComments() {
    const newComments = this.comments.filter((d) => d.isConcept);

    for (const comment of newComments) {
      await this.dateGroupCommentService.save(comment);
    }
  }
}
export class DateGroupCommentDialogComponentData {
  constructor(
    public readonly project: Project,
    public readonly group: DateGroupContainer
  ) {}
}
