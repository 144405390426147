import { Bloc } from "@comceptum-software/react-bloc";
import { Apollo } from "apollo-angular";
import * as moment from "moment";
import { grants } from "../app-grant-config";
import { GrantService } from "../grant.service";
import { YearPlanningLineCalculator } from "../year-planning-lines-dialog/year-planning-line.calculator";
import {
  ConstructionPlanningBlockedWeekDto,
  ConstructionPlanningColorLineDto,
  ConstructionPlanningDateDto,
  ConstructionPlanningDateGroupDto,
  ConstructionPlanningProjectDto,
} from "./construction-planning-date.dto";
import {
  ConstructionPlanningEvent,
  ConstructionPlanningFilter,
  ConstructionPlanningFilterValue,
  ConstructionPlanningHighlightProjectEvent,
  ConstructionPlanningLoadEvent,
  ConstructionPlanningReloadEvent,
} from "./construction-planning.event";
import {
  constructionGetDate,
  createConstructionPlanningFilters,
} from "./construction-planning.helpers";
import { constructionPlanningQuery } from "./construction-planning.query";
import {
  ConstructionPlanningLoadFailureState,
  ConstructionPlanningLoadInProgressState,
  ConstructionPlanningLoadSuccessState,
  ConstructionPlanningState,
} from "./construction-planning.state";

export class ConstructionPlanningBloc extends Bloc<
  ConstructionPlanningEvent,
  ConstructionPlanningState
> {
  constructor(
    protected readonly apollo: Apollo,
    protected readonly grantService: GrantService
  ) {
    super(new ConstructionPlanningLoadInProgressState());
  }

  get maxBuildingWeek() {
    return this.grantService.var(grants.planning_construction.max_week, null);
  }

  async *mapEventToState(
    event: ConstructionPlanningEvent
  ): AsyncIterable<ConstructionPlanningState> {
    if (event instanceof ConstructionPlanningLoadEvent) {
      yield* this._mapLoadEventToState(event);
    }

    if (event instanceof ConstructionPlanningReloadEvent) {
      yield* this._mapReloadEventToState(event);
    }

    if (event instanceof ConstructionPlanningHighlightProjectEvent) {
      yield* this._mapProjectHighlightEventToState(event);
    }
  }

  protected async *_mapProjectHighlightEventToState(
    event: ConstructionPlanningHighlightProjectEvent
  ) {
    const _state = this.state.value;

    if (_state instanceof ConstructionPlanningLoadSuccessState) {
      yield _state.copyWith({
        projects: _state.projects.map((_project) => ({
          ..._project,
          isHighlighted: _project.id === event.projectId,
        })),
      });
    }
  }

  protected async *_mapReloadEventToState(
    event: ConstructionPlanningReloadEvent
  ) {
    const _state = this.state.value;

    if (_state instanceof ConstructionPlanningLoadSuccessState) {
      this.add(
        new ConstructionPlanningLoadEvent({
          filter: _state.filter,
        })
      );
    }
  }

  protected async *_mapLoadEventToState(event: ConstructionPlanningLoadEvent) {
    if (this.state.value instanceof ConstructionPlanningLoadSuccessState) {
      yield this.state.value.copyWith({
        isReloading: true,
      });
    }

    const filters = createConstructionPlanningFilters(
      event.filter,
      this.maxBuildingWeek
    );

    const response = await this.apollo
      .query<{
        blockedWeeks: ConstructionPlanningBlockedWeekDto[];
        lines: ConstructionPlanningColorLineDto[];
        items: ConstructionPlanningProjectDto[];
        dates: ConstructionPlanningDateDto[];
        commentDateGroup: ConstructionPlanningDateGroupDto;
      }>({
        query: constructionPlanningQuery,
        variables: {
          filters,
        },
      })
      .toPromise();

    if (!!response.errors) {
      yield new ConstructionPlanningLoadFailureState({
        errorMessage: response.errors.toString(),
      });
    } else {
      const calculator = new YearPlanningLineCalculator(
        response.data.blockedWeeks as any
      );

      yield new ConstructionPlanningLoadSuccessState({
        filter: event.filter,
        dates: response.data.dates,
        commentDateGroup: response.data.commentDateGroup,
        projects: response.data.items.filter((_item) =>
          this.isProjectOk(_item, event.filter)
        ),
        lines: response.data.lines.map((_line) => ({
          ..._line,
          calculatedWeek: calculator.next(_line as any),
        })),
      });
    }
  }

  protected isProjectOk(
    project: ConstructionPlanningProjectDto,
    filter: ConstructionPlanningFilter
  ) {
    for (const dateId of Object.keys(filter.dateFilters)) {
      const filterValue =
        filter.dateFilters[dateId] || ConstructionPlanningFilterValue.AllValues;

      if (!this.isDateOk(constructionGetDate(project, dateId), filterValue)) {
        return false;
      }
    }

    return true;
  }

  protected isDateOk(date: Date, filterValue: ConstructionPlanningFilterValue) {
    switch (filterValue) {
      case ConstructionPlanningFilterValue.AllValues:
        return true;
      case ConstructionPlanningFilterValue.EmptyValues:
        return !date;
      case ConstructionPlanningFilterValue.Values:
        return !!date;
    }
  }
}
