import { Component, Inject } from "@angular/core";
import { MailSchedulerEntity } from "../mail-schedulers/mail-scheduler.entity";
import { MDC_DIALOG_DATA, MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import { environment } from "../../environments/environment";
import { AuthService } from "../auth.service";
import { EntityManager } from "../entity.service";
import { RestResponse, RestService } from "../rest.service";
import { first } from "lodash";

@Component({
  selector: "app-mail-scheduler-editor-dialog",
  templateUrl: "./mail-scheduler-editor-dialog.component.html",
  styleUrls: ["./mail-scheduler-editor-dialog.component.scss"],
})
export class MailSchedulerEditorDialogComponent {
  get editorOptions() {
    return {
      fileUploadParam: "file",
      fileUploadURL: `${environment.baseUrl}/documents/greeting`,
      fileUploadMethod: "POST",
      fileMaxSize: 20 * 1024 * 1024,
      fileAllowedTypes: ["*"],
      requestWithCredentials: true,
    };
  }

  responsePreview: RestResponse<any>;

  protected get mailSchedulerService() {
    return this.entityManager.get(MailSchedulerEntity);
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly model: MailSchedulerEntity,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager,
    protected readonly snackbar: MdcSnackbar,
    protected readonly restService: RestService,
    protected readonly dialog: MdcDialogRef<MailSchedulerEditorDialogComponent>
  ) {
    if (model.sourceType) {
      this.fetchFields();
    }
  }

  async fetchFields() {
    const response = await this.restService.get(
      `mail-scheduler/preview/${this.model.sourceType}/${
        this.model.sourceTable || "not-provided"
      }`
    );

    if (!response.hasError()) {
      this.responsePreview = new RestResponse({
        row: response.value,
      });
    }
  }

  async save() {
    this.model.template = first(
      this.model.template.split('<p data-f-id="pbf"')
    );

    if (await this.mailSchedulerService.save(this.model)) {
      this.snackbar.open("Opgeslagen", "Ok", { leading: true });
      this.dialog.close();
    }
  }
}
