import * as React from "react";
import { EntitySelect } from "src/app/entity-select/entity-select";
import { RestService } from "src/app/rest.service";
import { PresetEntity } from "src/app/service-settings/react/presets/preset.entity";

export interface PointCreatorComponentProps {
  rest: RestService;
  onAdd: (preset: PresetEntity) => any;
  disabled: boolean;
}

export const PointCreatorComponent: React.FC<PointCreatorComponentProps> = ({
  rest,
  disabled,
  onAdd,
}) => {
  const [key, setKey] = React.useState(Math.random().toString());

  return (
    <div key={key}>
      <EntitySelect
        limit={20}
        disabled={disabled}
        style={{ marginTop: 32 }}
        title="Toevoegen vanuit Bibliotheek"
        type="service_presets"
        labelSelector={(preset: PresetEntity) => preset.description}
        searchFields={["code", "description"]}
        restService={rest}
        onSelect={(preset: PresetEntity) => {
          onAdd(preset);
          setKey(Math.random().toString());
        }}
      />
    </div>
  );
};
