import { EntityManager } from "./../entity.service";
import { Component, OnInit } from "@angular/core";
import { UrlOpenService } from "../url-open.service";
import { ActivatedRoute } from "@angular/router";
import { Project } from "../project/project.entity";

@Component({
  selector: "app-year-planning-project",
  template: ``,
})
export class YearPlanningProjectComponent implements OnInit {
  protected get projectId() {
    return this.activatedRoute.snapshot.paramMap.get("projectId") || "none";
  }

  protected get projectService() {
    return this.entities.get(Project);
  }

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly entities: EntityManager,
    readonly urlOpenService: UrlOpenService
  ) {}

  async ngOnInit() {
    const response = await this.projectService.findOne(this.projectId);

    if (!response.hasError()) {
      this.urlOpenService.openUrl({
        url: `#/planning/year-planning/list/${response.value.buildingWeek}/${response.value.buildingWeek}/?highlightProjectId=${this.projectId}`,
        isNewTab: false,
      });
    }
  }
}
