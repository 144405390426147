import {
  Paper,
  Grid,
  Container,
  LinearProgress,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import { AppProjectFieldSheet } from "src/app/app-project-fields/app-project-field-sheet.entity";
import { AppProjectField } from "src/app/app-project-fields/app-project-field.entity";
import { Ops } from "src/app/entity.service";
import { AppProjectFieldViewComponent } from "../app-project-field-view.component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export interface AppProjectFieldViewProps {
  component: AppProjectFieldViewComponent;
}

export const AppProjectFieldView: React.FC<AppProjectFieldViewProps> = ({
  component,
}) => {
  const classes = useStyles();
  const [sheets, setSheets] = React.useState<AppProjectFieldSheet[]>(null);
  const [fields, setFields] = React.useState<AppProjectField[]>(null);

  const fetchSheets = async () => {
    const repo = component.entities.get(AppProjectFieldSheet);
    const response = await repo.query({
      relations: ["groups", "groups.assignments"],
      orders: [{ field: "order", direction: "asc" }],
    });

    if (!response.hasError()) {
      setSheets(response.value);
    }
  };

  const fetchFields = async () => {
    const repo = component.entities.get(AppProjectField);
    const response = await repo.query({
      filters: [Ops.Field("project_id").Equals(component.projectId)],
    });

    if (!response.hasError()) {
      setFields(response.value);
    }
  };

  const getField = (type: string) => {
    const item = fields.find((field) => field.type === type);

    return item ? item.value : "niet ingevuld";
  };

  React.useEffect(() => {
    fetchSheets();
    fetchFields();
  }, []);

  if (sheets == null || fields == null) return <LinearProgress />;

  return (
    <Container style={{ paddingTop: 16 }}>
      <Paper style={{ padding: 0 }}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {sheets.map((sheet) => (
            <React.Fragment key={sheet.id}>
              <ListItem>
                <ListItemText primary={sheet.name} />
              </ListItem>

              <Divider component="li" />

              <List className={classes.nested} component="div" disablePadding>
                {sheet.groups.map((group) => (
                  <React.Fragment key={group.id}>
                    <ListItem>
                      <ListItemText primary={group.name} />
                    </ListItem>

                    <Divider component="li" />

                    <List
                      dense
                      className={classes.nested}
                      component="div"
                      disablePadding
                    >
                      {group.assignments.map((assignment) => (
                        <React.Fragment key={assignment.id}>
                          <ListItem>
                            <ListItemText
                              style={{ maxWidth: 300, width: 300 }}
                              primary={assignment.project_field_type}
                            />

                            <ListItemText
                              primary={getField(assignment.project_field_type)}
                            />
                          </ListItem>

                          <Divider component="li" />
                        </React.Fragment>
                      ))}
                    </List>
                  </React.Fragment>
                ))}
              </List>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Container>
  );
};
