import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth.service";
import { EntityManager, Ops } from "./entity.service";
import { AppLogin, AppLoginRoles } from "./app-users/app-login.entity";
import { AppUser } from "./app-users/app-user.entity";
import { first } from "lodash";
import { TransactionService } from "./transaction.service";
import { CellaService } from "./cella.service";
import { GrantService } from "./grant.service";

@Injectable({ providedIn: "root" })
export class AppService {
  readonly tokenSubject: BehaviorSubject<string>;
  readonly loginSubject: BehaviorSubject<AppLogin>;
  readonly userSubject: BehaviorSubject<AppUser>;

  get user() {
    return this.userSubject.value;
  }

  get userId() {
    return this.user ? this.user.id : null;
  }

  get isAuthenticated() {
    return !!this.tokenSubject.value && !!this.userId;
  }

  protected get relationId() {
    return this.auth.user.id;
  }

  protected get appUserService() {
    return this.entities.get(AppUser);
  }

  protected get appLoginService() {
    return this.entities.get(AppLogin);
  }

  get hasAccess() {
    return this.grants.isRouteAccessable("/app/sso");
  }

  get hasProperties() {
    return this.auth.user.id && this.auth.user.email;
  }

  constructor(
    protected readonly auth: AuthService,
    protected readonly cella: CellaService,
    protected readonly entities: EntityManager,
    protected readonly transactions: TransactionService,
    protected readonly grants: GrantService
  ) {
    this.tokenSubject = new BehaviorSubject<string>(null);
    this.loginSubject = new BehaviorSubject<AppLogin>(null);
    this.userSubject = new BehaviorSubject<AppUser>(null);
  }

  async connect() {
    if (this.hasAccess && this.hasProperties) {
      await this.authenticate();
    }
  }

  async refreshToken(force = false) {
    if (this.isAuthenticated || force) {
      const token = await this.cella.appSso(this.user.id);

      if (!token.hasError() && token.value && token.value.accessToken) {
        this.tokenSubject.next(token.value.accessToken);

        return token.value.accessToken;
      }
    }
  }

  protected async authenticate() {
    const login = await this.getOrSetLogin();

    if (login && !login.hasError()) {
      this.loginSubject.next(login.value);

      const user = await this.getOrSetUser();

      if (!user.hasError()) {
        this.userSubject.next(user.value);

        return await this.refreshToken(true);
      }
    }
  }

  protected async getOrSetLogin() {
    let response = await this.appLoginService.queryFirst({
      filters: [Ops.Field("email").Equals(this.auth.user.email)],
    });

    if (response && !response.value) {
      response = await this.appLoginService.save(
        this.appLoginService.concept({
          relation_id: this.relationId,
          email: this.auth.user.email,
          role_id: AppLoginRoles.Employee,
        })
      );
    }

    return response;
  }

  protected async getOrSetUser() {
    let response = await this.appUserService.queryFirst({
      filters: [Ops.Field("email").Equals(this.auth.user.email)],
    });

    if (response && !response.value) {
      const names = this.auth.user.name.split(" ");

      response = await this.appUserService.save(
        this.appUserService.concept({
          relation_id: this.relationId,
          email: this.auth.user.email,
          password: "sso",
          first_name: first(names),
          last_name: names.slice(1).join(" "),
        })
      );
    }

    return response;
  }
}
