import { makeStyles } from "@material-ui/core";

export const usePicklistGridStyles = makeStyles((theme) => ({
  grid: {
    backgroundColor: "white",
    borderRadius: "0px",
    border: "none",
    height: "100%",
  },
  gridCell: {
    border: "none",
    background: "white",
    borderBottom: "1px solid #dddede",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
  },
  gridCellSmallText: {
    fontSize: 12,
  },
  gridCellCompleted: {
    color: "grey",
    textDecoration: "line-through",
    backgroundColor: "rgba(56, 142, 60, 0.12)",
  },
  gridCellIndeterminate: {
    color: "grey",
    backgroundColor: "rgba(244, 160, 0, 0.12)",
  },
  gridCellHighlighted: {
    backgroundColor: theme.palette.primary.light,
    color: "white",
  },
  gridCellCentered: {
    textAlign: "center",
  },
  gridCellRight: {
    textAlign: "right",
  },
  header: {
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
  },
  headerText: {
    overflow: "visible!important",
    zIndex: "100!important" as any,
  },
  gridToolbar: {
    backgroundColor: "#eeeeee",
  },
  textFieldContainer: {
    padding: 8,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
  checkboxFieldContainer: {
    padding: 8,
    paddingLeft: 16,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
}));
