import { Component, ChangeDetectorRef } from "@angular/core";
import { EntityManager } from "../entity.service";
import { AppConversationMessagePreset } from "./app-conversation-message-preset.entity";
import { TransactionService } from "../transaction.service";
import { DialogService } from "../dialog.service";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { AppConversationMessagePresetDialogComponent } from "../app-conversation-message-preset-dialog/app-conversation-message-preset-dialog.component";

@Component({
  selector: "app-app-conversation-message-presets",
  templateUrl: "./app-conversation-message-presets.component.html",
  styleUrls: ["./app-conversation-message-presets.component.scss"],
})
export class AppConversationMessagePresetsComponent extends AppCrudBoilerplate<
  AppConversationMessagePreset
> {
  entity = AppConversationMessagePreset;
  editDialog = AppConversationMessagePresetDialogComponent;

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }
}
