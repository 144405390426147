import { Entity } from "../entity.service";
import { first, chain, orderBy } from "lodash";
import { AppDocumentType } from "../app-document-types/app-document-type.entity";
import { RestResponse } from "../rest.service";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { AppUser } from "../app-users/app-user.entity";
import { User } from "../accessibility-users/user.entity";

export class AppDocument extends Entity {
  type = "app.documents";

  project_id: string;

  user_id: number;
  document_type_id: number;
  document_kind: string;
  description: string;
  confidential?: boolean;

  documentType: AppDocumentType;
  signature: AppDocumentSignature;
  user: AppUser;

  get groupKey() {
    return [
      this.document_kind,
      this.document_type_id || "?",
      this.description,
    ].join("_");
  }

  get hasSignature() {
    return this.signature && !!this.signature.payload;
  }

  get canSignature() {
    return this.document_kind === "document";
  }

  set __user__(value: AppUser) {
    this.user = this.entityManager
      .get(AppUser)
      .healOneResponse(new RestResponse(value)).value;
  }

  set __documentType__(value: AppDocumentType) {
    this.documentType = this.entityManager
      .get(AppDocumentType)
      .healOneResponse(new RestResponse(value)).value;
  }

  set __signature__(value: AppDocumentSignature) {
    this.signature = this.entityManager
      .get(AppDocumentSignature)
      .healOneResponse(new RestResponse(value)).value;
  }

  get version() {
    return moment(this.created_at).locale("nl").format("YYYYMMDDHHmmss");
  }

  get viewUrl() {
    return `${environment.appApiBaseUrl}/api/documents/view/${this.id}`;
  }

  get downloadUrl() {
    return `${environment.appApiBaseUrl}/api/documents/download/${this.id}`;
  }

  get receiptUrl() {
    return `${environment.appApiBaseUrl}/api/documents/receipt/${this.id}`;
  }

  get signedUrl() {
    return `${environment.appApiBaseUrl}/api/documents/signed/${this.id}`;
  }

  created_at?: Date;
  updated_at?: Date;
}

export class AppDocumentSignature extends Entity {
  type = "app.document_signatures";

  statusses = {
    10: { text: "Wachten op tekenaar", color: "grey" },
    20: { text: "Aan het tekenen", color: "blue" },
    30: { text: "Getekend", color: "green" },
    40: { text: "Geweigerd", color: "red" },
    50: { text: "Verlopen", color: "red" },
    60: { text: "Geannuleerd", color: "red" },
    70: { text: "Foutief", color: "red" },
  };

  get isSigned() {
    return this.payload ? this.payload.Status === 30 : false;
  }

  get statusText() {
    return this.payload ? this.statusses[this.payload.Status].text : "Onbekend";
  }
  get statusColor() {
    return this.payload ? this.statusses[this.payload.Status].color : "grey";
  }

  user_id: number;
  user: AppUser;

  set __user__(value: AppUser) {
    this.user = this.entityManager
      .get(AppUser)
      .healOneResponse(new RestResponse(value)).value;
  }

  payload?: AppDocumentSignaturePayload;

  created_at?: Date;
  updated_at?: Date;
}

interface AppDocumentSignaturePayload {
  Id: string;
  Status: number;
  Signers: AppDocumentSignaturePayloadSigner[];
}

interface AppDocumentSignaturePayloadSigner {
  Id: string;
  Email: string;
  RejectReason: string;
  SignedDateTime: any;
}

export class AppDocumentGroup {
  constructor(readonly items: AppDocument[]) {
    this.items = chain(items)
      .groupBy((i) => i.groupKey)
      .map((items, key) => first(orderBy(items, (i) => i.version, "desc")))
      .orderBy((f) => f.created_at, "desc")
      .value();
  }

  get scrollId() {
    return `app-doc-type-${this.type.id}`;
  }

  get type() {
    return first(this.items).documentType;
  }
}
