import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA, MdcSnackbar, MdcDialogRef } from "@angular-mdc/web";
import { User } from "../accessibility-users/user.entity";
import { RestService } from "../rest.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { TransactionService } from "../transaction.service";

@Component({
  selector: "app-accessibility-user-password-dialog",
  templateUrl: "./accessibility-user-password-dialog.component.html",
  styleUrls: ["./accessibility-user-password-dialog.component.scss"],
})
export class AccessibilityUserPasswordDialogComponent {
  readonly passwordPattern = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
  );

  passwordForm = new FormGroup(
    {
      password: new FormControl("", [
        Validators.required,
        Validators.pattern(this.passwordPattern),
      ]),
      passwordRepeat: new FormControl("", [Validators.required]),
    },
    this.checkPasswords
  );

  constructor(
    @Inject(MDC_DIALOG_DATA)
    public readonly user: User,
    protected readonly restService: RestService,
    protected readonly snackbar: MdcSnackbar,
    protected readonly transactions: TransactionService,
    protected readonly dialog: MdcDialogRef<
      AccessibilityUserPasswordDialogComponent
    >
  ) {}

  async save() {
    const response = await this.transactions.perform(() =>
      this.restService.post("user-password-resets/reset", {
        userId: this.user.id,
        password: this.passwordForm.get("password").value,
      })
    );

    if (!response.hasError()) {
      this.snackbar.open("Opgeslagen", "Ok");
      this.dialog.close();
    }
  }

  protected checkPasswords(group: FormGroup) {
    const password = group.get("password").value;
    const passwordRepeat = group.get("passwordRepeat").value;

    return password === passwordRepeat ? null : { notSame: true };
  }
}
