import gql from "graphql-tag";

export const servicePlanningFetchQuery = gql`
  query {
    mechanics: UserEntityFindMany(
      query: {
        filters: [
          { field: "isServiceMechanic", operator: "Equal", value: "waar" }
        ]
      }
    ) {
      id
      name
      companyId
      role {
        name
      }
    }
  }
`;

export const servicePlanningFetchServiceOverviewEventsQuery = gql`
  query servicePlanningFetchServiceOverviewEventsQuery($projectIds: JSON!) {
    events: ServicePlanningEventEntityFindMany(
      query: {
        filters: [
          {
            field: "legacyProjectId"
            operator: "In"
            valueComplex: $projectIds
          }
        ]
      }
    ) {
      id
      legacyProjectId
      serviceTicketIds
      isFinalized
      startDate
      assignments {
        id
        resourceId
      }
      createdAt
      updatedAt
    }
  }
`;

export const servicePlanningFetchExecutivesQuery = gql`
  query servicePlanningFetchExecutivesQuery($executivesIds: JSON!) {
    executives: UserEntityFindMany(
      query: {
        filters: [{ field: "id", operator: "In", valueComplex: $executivesIds }]
      }
    ) {
      id
      name
    }
  }
`;

export const servicePlanningFetchRelatedEventsQuery = gql`
  query servicePlanningFetchRelatedEventsQuery($ticketId: String!) {
    related: ServicePlanningEventEntityFindMany(
      query: {
        filters: [
          { field: "serviceTicketIds", operator: "Like", value: $ticketId }
        ]
      }
    ) {
      id
      isFinalized
      startDate
      assignments {
        id
        resourceId
      }
      createdAt
      updatedAt
    }
  }
`;

export const servicePlanningFetchProjectRelatedEventsQuery = gql`
  query servicePlanningFetchProjectRelatedEventsQuery(
    $legacyProjectId: String!
  ) {
    related: ServicePlanningEventEntityFindMany(
      query: {
        filters: [
          {
            field: "legacyProjectId"
            operator: "Equal"
            value: $legacyProjectId
          }
        ]
      }
    ) {
      id
      isFinalized
      serviceTicketIds
      startDate
      endDate
      stage {
        name
      }
      assignments {
        id
        resourceId
      }
      createdAt
      updatedAt
    }
  }
`;

export const servicePlanningFetchEventsQuery = gql`
  query servicePlanningFetchEventsQuery($dateRange: JSON!) {
    events: ServicePlanningEventEntityFindMany(
      query: {
        filters: [
          {
            field: "startDate"
            operator: "Between"
            valueComplex: $dateRange
            isOr: true
          }
          {
            field: "endDate"
            operator: "Between"
            valueComplex: $dateRange
            isOr: true
          }
        ]
      }
    ) {
      id
      assignments {
        id
        eventId
        resourceId
      }
      legacyProjectId
      legacyProjectData: legacyProject {
        description
        buildingCity
        # longitude
        # latitude
      }
      stageId
      stage {
        name
        colorCode
      }
      userId
      user {
        name
      }
      memo
      isFinalized
      serviceTicketIds
      duration
      durationPlanned
      durationSpend
      durationUnit
      startDate
      endDate
    }
  }
`;

export const servicePlanningProjectSearchQuery = gql`
  query servicePlanningProjectSearchQuery($input: String!) {
    items: ProjectEntityFindMany(
      query: {
        filters: [
          { field: "id", operator: "Equal", value: $input, isOr: true }
          { field: "description", operator: "Like", value: $input, isOr: true }
        ]
      }
    ) {
      id
      text: description
    }
  }
`;
