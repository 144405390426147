import * as React from "react";
import * as moment from "moment";
import { Column, SortColumn } from "react-data-grid";
import { WorkOrderDto } from "./WorkOrderDto";
import { IconButton } from "@material-ui/core";
import { More as MoreIcon } from "@material-ui/icons";
import * as classNames from "classnames";

export interface WorkOrderQuery {
  readonly relationId: string;
  readonly projectId: string;
}

export const composeWorkOrderFilters = (query: WorkOrderQuery) =>
  [
    ...(query.relationId
      ? [`reg_mutatie.van_kpl = '${query.relationId}'`]
      : []),
    ...(query.projectId ? [`prj_prj.prj = "${query.projectId}"`] : []),
  ].join(" AND ");

export const sortOrders = (
  orders: WorkOrderDto[],
  sortColumns: SortColumn[]
) => {
  if (sortColumns.length === 0) return orders;

  const sortedRows = [...orders];
  sortedRows.sort((a, b) => {
    for (const sort of sortColumns) {
      const aValue = a[sort.columnKey].toString() as string;
      const bValue = b[sort.columnKey].toString() as string;

      const compResult = aValue.localeCompare(bValue);

      if (compResult !== 0) {
        return sort.direction === "ASC" ? compResult : -compResult;
      }
    }
    return 0;
  });
  return sortedRows;
};

const MOMENT_DATE_DISPLAY_FORMAT = "DD-MM-YYYY";

interface ComposerOrderColumnsProps {
  readonly headerClass: string;
  readonly cellClass: string;
  readonly cellClickableClass: string;
  readonly showRelationDescription: boolean;
}

export const composeOrderColumns = ({
  headerClass,
  cellClass,
  cellClickableClass,
  showRelationDescription,
}: ComposerOrderColumnsProps): Column<WorkOrderDto, unknown>[] =>
  [
    {
      key: "opdracht",
      name: "Opdracht",
      cellClass: cellClickableClass,
      sortable: true,
      width: 64,
    },

    {
      key: "week_opbouw",
      name: "Opbouw",
      sortable: true,
      width: 64,
    },
    {
      key: "project",
      name: "Project",
      sortable: true,
      minWidth: 256,
      formatter: ({ row }) => (
        <>
          {row.project} - {row.project_oms}
        </>
      ),
    },
    {
      key: "relatie",
      name: "Relatie",
      cellClass: cellClickableClass,
      sortable: true,
      width: 64,
    },
    ...(showRelationDescription
      ? [
          {
            key: "relatie_oms",
            name: "Omschrijving",
            sortable: true,
          },
        ]
      : []),
    {
      key: "opdracht_memo",
      name: "Opmerking",
      sortable: true,
    },
    {
      key: "datum",
      name: "Datum",
      sortable: true,
      formatter: ({ row }) => (
        <>{moment(row.datum).format(MOMENT_DATE_DISPLAY_FORMAT)}</>
      ),
      width: 64,
    },
    {
      key: "opdracht_oms",
      name: "Opdrachtomschrijving",
      sortable: true,
    },
    {
      key: "shortcuts",
      name: "",
      sortable: false,
      cellClass: cellClickableClass,
      width: 16,
      formatter: () => (
        <IconButton aria-label="snelkoppelingen" size="small">
          <MoreIcon fontSize="inherit" />
        </IconButton>
      ),
    },
  ].map((column: Column<WorkOrderDto, unknown>) => ({
    ...column,
    cellClass: column.cellClass
      ? classNames([column.cellClass, cellClass])
      : cellClass,
    headerCellClass: headerClass,
  }));
