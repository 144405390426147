import { useBloc } from "@comceptum-software/react-bloc";
import { orderBy } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { Marker, Popup } from "react-leaflet";
import { createCustomLeafletPin } from "src/app/year-planning/custom-leaflet-pin";
import {
  ConstructionPlanningDateDto,
  ConstructionPlanningProjectDto,
} from "../construction-planning-date.dto";
import { ConstructionPlanningBloc } from "../construction-planning.bloc";
import { ConstructionPlanningHighlightProjectEvent } from "../construction-planning.event";

export interface ConstructionPlanningMapMarkerProps {
  project: ConstructionPlanningProjectDto;
  dates: ConstructionPlanningDateDto[];
}

export function ConstructionPlanningMapMarker({
  project,
  dates,
}: ConstructionPlanningMapMarkerProps) {
  const bloc = useBloc(ConstructionPlanningBloc);

  const activeDate = () => {
    const ordered = orderBy(
      dates,
      (date) => `${date.stackId}.${date.orderId}`,
      "desc"
    );

    return ordered.find((date) => {
      const matchValue = (project.cachedValue.dateValues || []).find(
        (value) => value.dateId === date.id
      );

      return matchValue && matchValue.value;
    });
  };

  const calculateColor = () => {
    const match = activeDate();

    return match ? match.colorCode.replace("#", "") : "ffffff";
  };

  const calculateLetter = () => {
    const match = activeDate();

    return match ? match.description.substr(0, 1) : "";
  };

  const colorCode = useMemo(() => calculateColor(), [dates]);
  const letter = useMemo(() => calculateLetter(), [dates]);
  const textColor = useMemo(
    () => pickTextColorBasedOnBgColorAdvanced(colorCode, "ffffff", "000000"),
    [colorCode]
  );

  return (
    <Marker
      icon={createCustomLeafletPin(`#${colorCode}`, letter, `#${textColor}`)}
      onclick={() => {
        bloc.add(
          new ConstructionPlanningHighlightProjectEvent({
            projectId: project.id,
          })
        );
      }}
      position={[parseFloat(project.longitude), parseFloat(project.latitude)]}
    >
      <Popup offset={[0, -16]}>
        <strong>{project.id}</strong> {project.description}
      </Popup>
    </Marker>
  );
}

function pickTextColorBasedOnBgColorAdvanced(bgColor, lightColor, darkColor) {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}
