import { Injectable } from "@angular/core";
import * as moment from "moment";
import {
  CellaService,
  MetacomCustomField,
  MetacomTransactionDocumentRequest,
} from "../cella.service";
import { RestResponse } from "../rest.service";
import { Apollo } from "apollo-angular";
import { entityAdvancementImageCreateMutation } from "../delivery-list/react/completion/completion.mutation";
import { AuthService } from "../auth.service";

class Seal {
  static isNotSet(value: object, name?: string) {
    if (value === null || value === undefined) {
      throw new Error(`Argument ${name || ""} cannot be null or undefined`);
    }
  }

  static isNotSetOrEmpty(value: string, name?: string) {
    if (value === null || value === undefined || value === "") {
      throw new Error(
        `Argument ${name || ""} cannot be null, undefined or empty`
      );
    }
  }

  static isResponseOk(value: RestResponse<{}>) {
    if (value && value.hasError()) {
      throw new Error(`Response is null or has error.`);
    }
  }
}

export enum ServiceTransmitterTicketKind {
  Delivery,
  Service,
}

export class ServiceTransmitterDepartmentMail {
  constructor(
    readonly props: {
      readonly subject: string;
      readonly html: string;
    }
  ) {
    Seal.isNotSet(props, "props");
    Seal.isNotSetOrEmpty(props.subject, "props.subject");
    Seal.isNotSetOrEmpty(props.html, "props.html");
  }
}

export class ServiceTransmitterTicket {
  constructor(
    readonly props: {
      readonly id?: string;
      readonly projectId: string;
      readonly projectDescription?: string;
      readonly executiveUserId: string;
      readonly executiveUserName?: string;

      readonly date: Date;
      readonly dateEnd: Date;
      readonly space: string;
      readonly description: string;
      readonly internalMemo?: string;

      readonly primaryCategory: string;
      readonly secondaryCategory: string;

      readonly libraryCode?: string;
      readonly imageIds?: string[];
    }
  ) {
    Seal.isNotSet(props, "props");
    Seal.isNotSetOrEmpty(props.projectId, "props.projectId");
    Seal.isNotSetOrEmpty(props.executiveUserId, "props.executiveUserId");
    Seal.isNotSet(props.date, "props.date");
    Seal.isNotSet(props.dateEnd, "props.dateEnd");
    Seal.isNotSetOrEmpty(props.space, "props.space");
    Seal.isNotSetOrEmpty(props.description, "props.description");
    Seal.isNotSetOrEmpty(props.primaryCategory, "props.primaryCategory");
    Seal.isNotSetOrEmpty(props.secondaryCategory, "props.secondaryCategory");
  }

  copyWithId(id: string) {
    return new ServiceTransmitterTicket(Object.assign({ id }, this.props));
  }
}

export class ServiceTransmitterTicketProgress {
  constructor(
    readonly props: {
      readonly ticketId: string;
      readonly userId: string;
      readonly comment: string;
      readonly date?: Date;
      readonly isCompleted?: boolean;
    }
  ) {
    Seal.isNotSet(props);
    Seal.isNotSetOrEmpty(props.ticketId);
    Seal.isNotSetOrEmpty(props.comment);
  }
}

export class ServiceTransmitterTicketImage {
  constructor(
    readonly props: {
      readonly ticketId: string;
      readonly projectId: string;
      readonly file?: File;
    }
  ) {
    Seal.isNotSet(props);
    Seal.isNotSetOrEmpty(props.ticketId);
    Seal.isNotSetOrEmpty(props.projectId);
  }
}

interface IServiceTransmitter {
  mailDepartment(mail: ServiceTransmitterDepartmentMail): Promise<void>;

  createTicket(
    ticket: ServiceTransmitterTicket,
    props?: {
      notify?: boolean;
      kind?: ServiceTransmitterTicketKind;
    }
  ): Promise<ServiceTransmitterTicket>;

  createTicketProgress(
    ticketProgress: ServiceTransmitterTicketProgress,
    props?: { notify?: boolean }
  ): Promise<ServiceTransmitterTicketProgress>;
}

@Injectable({ providedIn: "root" })
export class ServiceTransmitter implements IServiceTransmitter {
  protected readonly DOC_TYPE = "80-10";
  protected readonly DOC_DATE_FORMAT = "YYYY-MM-DD";
  protected readonly DOC_SERVICE_PREFIX = "S";

  protected readonly FIELDS_ENTITY = "0010";
  protected readonly FIELDS_LINE_ID = 1;
  protected readonly FIELDS_SERIAL_NUMBER = 1;

  protected readonly PROGRESS_ENTITY_TYPE = "service";
  protected readonly PROGRESS_DATE_FORMAT = "DD-MM-YYYY";
  protected readonly PROGRESS_DEFAULT_COMMENT = "Servicepunt gemeld";

  constructor(
    protected readonly auth: AuthService,
    protected readonly apollo: Apollo,
    protected readonly cella: CellaService
  ) {
    Seal.isNotSet(auth, "auth");
    Seal.isNotSet(apollo, "apollo");
    Seal.isNotSet(cella, "cella");
  }

  async mailDepartment(mail: ServiceTransmitterDepartmentMail) {
    Seal.isNotSet(mail);

    Seal.isResponseOk(
      await this.cella.sendEmail({
        from: {
          address: "info@groothuisbouw.info",
          name: "Groothuisbouw Emmeloord",
        },
        to: "service@groothuisbouw.nl",
        subject: mail.props.subject,
        html: mail.props.html,
      })
    );
  }

  async createTicket(
    ticket: ServiceTransmitterTicket,
    props = {
      notify: true,
      kind: ServiceTransmitterTicketKind.Service,
    }
  ) {
    Seal.isNotSet(ticket);
    Seal.isNotSet(props);

    const ticketCreationResponse = await this.cella.writeTransactionDocument(
      this._mapTicketToDocument(ticket, props)
    );

    Seal.isResponseOk(ticketCreationResponse);

    ticket = ticket.props.id
      ? ticket
      : ticket.copyWithId(ticketCreationResponse.value.CreatedDocumentNumber);

    Seal.isResponseOk(
      await this.cella.writeCustomFields({
        CustomField: Array.from(this._mapTicketToCustomFields(ticket)),
      })
    );

    await this.createTicketProgress(
      new ServiceTransmitterTicketProgress({
        ticketId: ticket.props.id,
        comment: this.PROGRESS_DEFAULT_COMMENT,
        userId: this.auth.user.id,
        isCompleted: false,
      }),
      { notify: props.notify, isNew: true }
    );

    if (ticket.props.imageIds) {
      await Promise.all(
        ticket.props.imageIds.map((_imageId) =>
          this._storeImageId(ticket.props.id, _imageId)
        )
      );
    }

    return ticket;
  }

  async createTicketProgress(
    ticketProgress: ServiceTransmitterTicketProgress,
    props = { notify: true, isNew: false }
  ) {
    Seal.isNotSet(ticketProgress);
    Seal.isNotSet(props);

    Seal.isResponseOk(
      await this.cella.serviceTicketProgress({
        source: "portal",
        notify: props.notify,
        isNew: props.isNew,
        ticketId: ticketProgress.props.ticketId,
        description: ticketProgress.props.comment,
        relationId: ticketProgress.props.userId,
        isCompleted: ticketProgress.props.isCompleted,
        appointmentDate:
          ticketProgress.props.date &&
          moment(ticketProgress.props.date).format(this.PROGRESS_DATE_FORMAT),
      })
    );

    return ticketProgress;
  }

  protected async _storeImageId(ticketId: string, documentMetaId: string) {
    return await this.apollo
      .mutate({
        mutation: entityAdvancementImageCreateMutation,
        variables: {
          input: {
            entityType: "service",
            entityId: this.mapTicketIdToOrigin(ticketId),
            userId: this.auth.user.id,
            documentMetaId: documentMetaId,
          },
        },
      })
      .toPromise();
  }

  protected _mapTicketToDocument(
    ticket: ServiceTransmitterTicket,
    props = { kind: ServiceTransmitterTicketKind.Service }
  ): MetacomTransactionDocumentRequest {
    Seal.isNotSet(ticket);
    Seal.isNotSet(props);

    return {
      DocumentType: this.DOC_TYPE,
      DocumentDate: moment().format(this.DOC_DATE_FORMAT),
      DocumentDescription: ticket.props.description,
      DocumentLines: [
        {
          FromCompany: 920,
          ToCompany: 920,
          StartDate: moment(ticket.props.date).format(this.DOC_DATE_FORMAT),
          StartTime: "000000",
          EndDate: moment(ticket.props.dateEnd).format(this.DOC_DATE_FORMAT),
          EndTime: "000000",
          SourceCompany: 920,
          ToCostCentre: this._mapProjectIdToKind(ticket.props.projectId, {
            kind: props.kind,
          }),
          FromCostCentre: ticket.props.executiveUserId,
          FromQuantity: "1",
          Description: ticket.props.description,
          FreeCode: ticket.props.space,
        },
      ],
    };
  }

  protected *_mapTicketToCustomFields(
    ticket: ServiceTransmitterTicket
  ): Iterable<MetacomCustomField> {
    Seal.isNotSet(ticket);

    const origin = this.mapTicketIdToOrigin(ticket.props.id);

    if (ticket.props.primaryCategory) {
      yield {
        Entity: this.FIELDS_ENTITY,
        Origin: origin,
        LineId: this.FIELDS_LINE_ID,
        Code: "TRA-111",
        SerialNumber: this.FIELDS_SERIAL_NUMBER,
        Contents: ticket.props.primaryCategory,
      };
    }

    if (ticket.props.secondaryCategory) {
      yield {
        Entity: this.FIELDS_ENTITY,
        Origin: origin,
        LineId: this.FIELDS_LINE_ID,
        Code: "TRA-112",
        SerialNumber: this.FIELDS_SERIAL_NUMBER,
        Contents: ticket.props.secondaryCategory,
      };
    }

    if (ticket.props.libraryCode) {
      yield {
        Entity: this.FIELDS_ENTITY,
        Origin: origin,
        LineId: this.FIELDS_LINE_ID,
        Code: "TRA-113",
        SerialNumber: this.FIELDS_SERIAL_NUMBER,
        Contents: ticket.props.libraryCode,
      };
    }

    if (ticket.props.internalMemo) {
      yield {
        Entity: this.FIELDS_ENTITY,
        Origin: origin,
        LineId: this.FIELDS_LINE_ID,
        Code: "TRA-122",
        SerialNumber: this.FIELDS_SERIAL_NUMBER,
        Contents: ticket.props.internalMemo,
      };
    }
  }

  mapTicketIdToOrigin(ticketId: string) {
    Seal.isNotSetOrEmpty(ticketId);

    return `bdr:920¡dst:80-10¡dnr:${ticketId}¡rnr:0`;
  }

  protected _mapProjectIdToNativeId(projectId: string) {
    Seal.isNotSetOrEmpty(projectId);

    return projectId.startsWith(this.DOC_SERVICE_PREFIX)
      ? projectId.substr(1)
      : projectId;
  }

  protected _mapProjectIdToKind(
    projectId: string,
    props = { kind: ServiceTransmitterTicketKind.Service }
  ) {
    Seal.isNotSetOrEmpty(projectId);
    Seal.isNotSet(props);

    const nativeId = this._mapProjectIdToNativeId(projectId);

    switch (props.kind) {
      default:
      case ServiceTransmitterTicketKind.Service:
        return this.DOC_SERVICE_PREFIX + nativeId;
      case ServiceTransmitterTicketKind.Delivery:
        return nativeId;
    }
  }

  protected _composeMailForDepartment(ticket: ServiceTransmitterTicket) {
    return `
    Zojuist is de volgende melding aangemaakt:<br /><br />
    Nummer: ${ticket.props.id}<br />
    Project: ${ticket.props.projectId} - ${ticket.props.projectDescription}<br />
    Omschrijving: ${ticket.props.description}<br />
    Ruimte: ${ticket.props.space}<br />
    Door: ${ticket.props.executiveUserId} - ${ticket.props.executiveUserName}<br />
    `;
  }
}
