import { OrderableEntity } from "../entity.service";
import { AppProjectFieldGroupAssignment } from "./app-project-field-group-assignment.entity";
import { RestResponse } from "../rest.service";
import { orderBy } from "lodash";

export class AppProjectFieldGroup extends OrderableEntity {
  type = "app.project_field_groups";

  project_field_sheet_id: number;
  name: string;

  assignments: AppProjectFieldGroupAssignment[];

  set __assignments__(value: AppProjectFieldGroupAssignment[]) {
    const items = this.entityManager
      .get(AppProjectFieldGroupAssignment)
      .healManyResponse(new RestResponse(value)).value;

    this.assignments = orderBy(items, (i) => i.order);
  }

  created_at?: Date;
  updated_at?: Date;
}
