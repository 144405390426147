import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Apollo } from "apollo-angular";
import { latLng, Map, MapOptions, marker, tileLayer } from "leaflet";
import { chain, first, orderBy, sumBy, uniq } from "lodash";
import * as moment from "moment";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { User } from "../accessibility-users/user.entity";
import { grants } from "../app-grant-config";
import { AuthService } from "../auth.service";
import { CellaService } from "../cella.service";
import { DialogService } from "../dialog.service";
import { EntityManager } from "../entity.service";
import { GrantService } from "../grant.service";
import { MetacomService } from "../metacom.service";
import { EntityAdvancement } from "../offers/entity.advancement.entity";
import { LegacyProject, Project } from "../project/project.entity";
import { RestResponse } from "../rest.service";
import { SchedulerQuickDialogComponent } from "../service-planning/components/SchedulerQuickDialogComponent";
import { ServiceEventDto } from "../service-planning/dtos/service-event.dto";
import { servicePlanningFetchServiceOverviewEventsQuery } from "../service-planning/service-planning.query";
import { MapColorEntity } from "../service-settings/react/map/map.entity";
import {
  ServiceTicketDialogComponent,
  ServiceTicketDialogComponentData,
} from "../service-ticket-dialog/service-ticket-dialog.component";
import { TransactionService } from "../transaction.service";
import { UrlOpenService } from "../url-open.service";
import { createCustomLeafletPin } from "../year-planning/custom-leaflet-pin";
import {
  ServiceItem,
  ServiceItemGroup,
  ServiceItemProjectData,
} from "./service.dto";
import {
  useOpenServiceTicketAdvancementDialog,
  useStoreServiceTicketAdvancement,
} from "./service.functions";
import { serviceTicketImageCountQuery } from "./service.query";

@Component({
  selector: "app-service",
  templateUrl: "./service.component.html",
  styleUrls: ["./service.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceComponent implements OnInit {
  filterQuery = "";
  filterQueryChanged = new Subject<string>();

  viewMode = "list";
  showOnlyActive = true;
  showOnlyExpired = false;
  sortOnDays = false;

  mapActiveServiceItem: ServiceItem;

  projectId: string;
  projectFilterConfig = {
    allowNothing: true,
    title: "Project",
    icon: "archive",
    entityName: "legacy_projects",
    nameField: "id",
    descriptionField: "description",
    sortField: "id",
    sortDirection: "ASC",
    filterFields: ["id", "description"],
    filters: [],
  };

  relationId: string;
  relationFilterConfig = {
    limit: 100,
    allowNothing: true,
    title: "Uitvoerende",
    icon: "people",
    entityName: "users",
    nameField: "identity",
    descriptionField: "name",
    sortField: "identity",
    sortDirection: "ASC",
    filterFields: ["identity", "name"],
    filters: [
      { field: "identity", operator: "IsNull", isNot: true },
      { field: "isSupplier", operator: "Equal", value: "t" },
      { field: "stage", operator: "Equal", value: "1000" },
    ],
  };

  response: RestResponse<ServiceItemGroup[]>;
  responseImages: ServiceImageAggregateGroup[];

  map: MapOptions = {
    layers: [
      tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: "...",
      }),
    ],
    zoom: 7,
    trackResize: true,
    preferCanvas: true,
    center: latLng(52.132633, 5.2912659),
  };

  protected get userService() {
    return this.entityManager.get(User);
  }

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get legacyProjectService() {
    return this.entityManager.get(LegacyProject);
  }

  protected get entityAdvancementService() {
    return this.entityManager.get(EntityAdvancement);
  }

  protected get mapColorEntityService() {
    return this.entityManager.get(MapColorEntity);
  }

  get itemCount() {
    return this.response
      ? sumBy(this.response.value, (e) => e.children.length)
      : 0;
  }

  get itemDaysAverage() {
    return this.response
      ? sumBy(this.response.value, (e) =>
          sumBy(e.children, (d) => d.datumCount)
        ) / this.itemCount
      : 0;
  }

  get allowFilter() {
    return this.grantService.varIs(
      grants.service_overview.allow_filter,
      "true"
    );
  }

  get hasPlanningAccess() {
    return this.grantService.isRouteAccessable("/service/planning");
  }

  protected get routeProjectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  protected get nativeProjectId() {
    return this.projectId
      ? (this.projectId as string).startsWith("S")
        ? this.projectId.substr(1)
        : this.projectId
      : null;
  }

  constructor(
    protected readonly injector: Injector,
    protected readonly apollo: Apollo,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly dialogService: DialogService,
    protected readonly entityManager: EntityManager,
    protected readonly metacomService: MetacomService,
    protected readonly cellaService: CellaService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly urlOpenService: UrlOpenService,
    protected readonly grantService: GrantService,
    protected readonly transactionService: TransactionService
  ) {}

  get hasProperFilter() {
    return this.filterQuery || this.projectId || this.relationId;
  }

  ngOnInit() {
    if (!this.allowFilter) {
      this.relationId = this.authService.user.id;
    }
    if (this.allowFilter && this.routeProjectId) {
      this.projectId = this.routeProjectId;
    }

    this.filterQueryChanged
      .pipe(
        tap(() => (this.response = null)),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => this.onFilterQuery());

    this.onFilterQuery();
  }

  onFilterQueryChanged() {
    this.filterQueryChanged.next(this.filterQuery);
    this.changeDetector.markForCheck();
  }

  onFilterQuery() {
    if (this.hasProperFilter) {
      this.fetch();
    } else {
      this.showOnlyActive = true;
      this.response = new RestResponse([]);
    }

    this.changeDetector.markForCheck();
  }

  openDocuments(item: ServiceItem) {
    this.urlOpenService.open(
      `${location.origin}/#/office/documents/${item.projectNativeId}`,
      false
    );
  }

  openChecklistImages(item: ServiceItem) {
    this.urlOpenService.open(
      `${location.origin}/#/construction/audit-sheet-phases/${item.projectNativeId}`,
      false
    );
  }

  openMaps(item: ServiceItem) {
    if (item.projectData) {
      this.urlOpenService.open(
        `https://maps.google.nl/maps/?daddr=${item.projectData.longitude}+${item.projectData.latitude}`
      );
    }
  }

  toggleViewMode() {
    this.mapActiveServiceItem = null;
    this.viewMode = this.viewMode === "list" ? "map" : "list";
  }

  leafletReady(map: Map) {
    setInterval(() => map && map.invalidateSize({ animate: true }), 500);
  }

  isActiveGroup(group: ServiceItemGroup) {
    return (
      !!this.mapActiveServiceItem &&
      this.getProjectIdNative(group.data.project) ===
        this.getProjectIdNative(this.mapActiveServiceItem.project)
    );
  }

  isActiveItem(item: ServiceItem) {
    return (
      !!this.mapActiveServiceItem &&
      this.getProjectIdNative(item.project) ===
        this.getProjectIdNative(this.mapActiveServiceItem.project)
    );
  }

  async createTicket() {
    const { storeServiceTicketAdvancement } = useStoreServiceTicketAdvancement(
      this.authService,
      this.cellaService,
      () => this.onFilterQuery()
    );

    const result = await this.dialogService.open(
      this.changeDetector,
      ServiceTicketDialogComponent,
      {
        data: new ServiceTicketDialogComponentData(
          this.relationId,
          this.projectId
        ),
      }
    );
    const item = result as ServiceItem;
    const message = result as string;

    if (message !== "close" && !!item) {
      item.projectDataAll = first(
        await this.getProjects([item.projectNativeId])
      );
      item.uitvoerendeData = (
        await this.userService.findOne(item.uitvoerende)
      ).value;

      await storeServiceTicketAdvancement(
        item,
        this.entityAdvancementService.concept({
          entityType: "service",
          entityId: item.herkomst,
          comment: "Servicepunt gemeld",
          userId: this.authService.user.id,
          __user__: this.authService.user,
          isCompleted: false,
          createdAt: new Date(),
          updatedAt: new Date(),
        }),
        true
      );

      this.onFilterQuery();
    }
  }

  async editTicket(model: ServiceItem) {
    const result = await this.dialogService.open(
      this.changeDetector,
      ServiceTicketDialogComponent,
      {
        data: new ServiceTicketDialogComponentData(
          this.relationId,
          this.projectId,
          model
        ),
      }
    );

    const item = result as ServiceItem;
    const message = result as string;

    if (message !== "close" && !!item) {
      item.projectDataAll = first(
        await this.getProjects([item.projectNativeId])
      );
      item.uitvoerendeData = (
        await this.userService.findOne(item.uitvoerende)
      ).value;

      this.onFilterQuery();
    }
  }

  async openAdvancements(item: ServiceItem) {
    const { openServiceTicketAdvancementDialog } =
      useOpenServiceTicketAdvancementDialog(
        this.authService,
        this.cellaService,
        this.metacomService,
        this.dialogService,
        this.transactionService,
        this.userService,
        this.entityAdvancementService,
        () => this.onFilterQuery(),
        this.changeDetector
      );

    await openServiceTicketAdvancementDialog(item);
  }

  toLegacyProjectId(id: string) {
    return id.startsWith("S") ? id : `S${id}`;
  }

  openScheduler(ticket: ServiceItem) {
    const element = document.querySelector("#react--component-scheduler");

    if (element) {
      ReactDOM.render(
        React.createElement(SchedulerQuickDialogComponent, {
          injector: this.injector,
          defaults: {
            legacyProjectId: this.toLegacyProjectId(ticket.project),
            serviceTicketId: ticket.servicepunt,
            isDialogMode: true,
            ...(ticket.planningItem
              ? {
                  highlight: {
                    eventId: ticket.planningItem.id,
                    baseDate: ticket.planningItem.startDate,
                  },
                }
              : {}),
          },
          onClosed: () => {
            ReactDOM.unmountComponentAtNode(element);
            this.onFilterQuery();
          },
        }),
        element
      );
    }
  }

  protected async fetch() {
    const filters = [];

    if (this.showOnlyActive) {
      filters.push(`reg_doc.stadium < '1000'`);
    }

    if (this.relationId) {
      filters.push(`reg_mutatie.van_kpl = '${this.relationId}'`);
    }

    if (this.projectId) {
      filters.push(
        `(prj_prj.prj = 'S${this.nativeProjectId}' OR prj_prj.prj = '${this.nativeProjectId}')`
      );
    }

    if (this.filterQuery) {
      filters.push(
        `(reg_mutatie.oms MATCHES '*${this.filterQuery}*' OR prj_prj.oms MATCHES '*${this.filterQuery}*')`
      );
    }

    this.response = null;

    const response = await this.metacomService.queryTableAsync({
      setName: "metacom",
      tableName: "service_meldingen",
      filter: filters.join(" AND "),
    });

    if (!response.hasError()) {
      const items = response.value.map((e) => new ServiceItem(e));
      const projectIds = chain(items)
        .uniqBy((e) => e.projectNativeId)
        .map((e) => e.projectNativeId)
        .value();

      const projects = await this.getProjects(projectIds);

      const users = await this.getUsers(
        chain(items)
          .uniqBy((e) => e.uitvoerende)
          .map((e) => e.uitvoerende)
          .value()
      );

      const planningEvents = await this.getServicePlanningEvents(projectIds);

      const imageCounts = await this.fetchImageCounts(
        items.map((_item) => _item.herkomst)
      );
      const mapColors = await this.mapColorEntityService.query();

      this.response = new RestResponse(
        await Promise.all(
          chain(items)
            .map((e) =>
              this.fetchAdditionalItemData(
                e,
                projects,
                users,
                imageCounts,
                planningEvents
              )
            )
            .filter((e) =>
              this.showOnlyExpired ? e.isAppointmentExpired : true
            )
            .groupBy((e) => e.project)
            .map((_items, key) =>
              chain(_items)
                .orderBy(
                  [(e) => e.stadium, (e) => moment(e.datum_gemeld).unix()],
                  ["asc", "asc"]
                )
                .value()
            )
            .map((items) => new ServiceItemGroup(items))
            .forEach(
              (e) =>
                (e.marker = this.makeMarker(
                  e.data,
                  e.children,
                  mapColors.value
                ))
            )
            .orderBy(
              [
                ...(this.sortOnDays
                  ? [
                      (e) =>
                        moment((first(e.children) as any).datum_gemeld).unix(),
                    ]
                  : []),
                (e) =>
                  e.data.projectData ? e.data.projectData.description : "",
              ],
              [...(this.sortOnDays ? ["asc"] : []), "asc" as any]
            )
            .value()
        )
      );
    }

    this.changeDetector.detectChanges();
  }

  protected async fetchImageCounts(entityIds: string[]) {
    const response = await this.apollo
      .query<{
        groups: ServiceImageAggregateGroup[];
      }>({
        query: serviceTicketImageCountQuery,
        variables: {
          entityIds: uniq(entityIds),
        },
      })
      .toPromise();

    if (!response.errors) {
      return response.data.groups;
    }

    return [];
  }

  protected fetchAdditionalItemData(
    item: ServiceItem,
    projects: ServiceItemProjectData[],
    users: User[],
    imageCounts: ServiceImageAggregateGroup[],
    events: ServiceEventDto[]
  ) {
    item.projectDataAll = projects.find((e) => e.id === item.projectNativeId);
    item.uitvoerendeData = users.find((e) => e.id === item.uitvoerende);
    item.hasImages = !!imageCounts.find(
      (_group) => _group.key === item.herkomst
    );
    item.planningItem = orderBy(
      events,
      (event) => moment(event.updatedAt).unix(),
      "desc"
    ).find(
      (event) =>
        event.legacyProjectId === this.toLegacyProjectId(item.project) &&
        event.serviceTicketIds.indexOf(item.servicepunt) >= 0
    );

    return item;
  }

  protected async getProjects(ids: string[]) {
    ids = ids || [];

    if (ids.length > 0) {
      const response = await this.projectService.query({
        filters: [{ field: "id", operator: "In", value: ids }],
        relations: ["projectLeader"],
      });

      const legacyIds = ids.map((id) => `S${id}`);

      const responseLegacy = await this.legacyProjectService.query({
        filters: [{ field: "id", operator: "In", value: legacyIds }],
      });

      return ids.map(
        (id) =>
          new ServiceItemProjectData(
            id,
            response.value.find((item) => item.id === id),
            responseLegacy.value.find((item) => item.id === `S${id}`)
          )
      );
    }
  }

  protected async getUsers(ids: string[]) {
    return (
      (ids.length > 0
        ? (
            await this.userService.query({
              filters: [{ field: "id", operator: "In", value: ids }],
            })
          ).value
        : null) || []
    );
  }

  protected async getServicePlanningEvents(projectIds: string[]) {
    const response = await this.apollo
      .query<{
        events: ServiceEventDto[];
      }>({
        query: servicePlanningFetchServiceOverviewEventsQuery,
        variables: { projectIds: projectIds.map((id) => `S${id}`) },
      })
      .toPromise();

    return (response.data && response.data.events) || [];
  }

  protected makeMarker(
    item: ServiceItem,
    items: ServiceItem[],
    mapColors: MapColorEntity[]
  ) {
    const project = item.projectData;
    const earliestItem = items.reduce((curr, prev) =>
      Date.parse(curr.datum_gemeld) > Date.parse(prev.datum_gemeld)
        ? prev
        : curr
    );

    const mapColor = mapColors
      .sort((a, b) => b.days - a.days)
      .find((entity) =>
        moment(earliestItem.datum_gemeld).isSameOrBefore(
          moment().subtract(entity.days, "days")
        )
      );

    return project
      ? project.latitude && project.longitude
        ? marker(
            latLng(parseFloat(project.longitude), parseFloat(project.latitude)),
            {
              interactive: true,
              icon: createCustomLeafletPin(
                mapColor ? mapColor.color : "#ffffff"
              ),
            }
          ).addEventListener("click", () => {
            this.mapActiveServiceItem = item;
            this.changeDetector.detectChanges();
          })
        : null
      : null;
  }

  protected getProjectIdNative(projectId: string) {
    return (projectId as string).startsWith("S")
      ? projectId.substr(1)
      : projectId;
  }
}

interface ServiceImageAggregateGroup {
  key: string;
  count: number;
}
