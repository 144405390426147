import { MdcDialogRef, MdcSnackbar } from "@angular-mdc/web";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { flatten } from "lodash";
import { EntityManager, Ops } from "../entity.service";
import { RestResponse } from "../rest.service";
import {
  DateDefinition,
  DateGroup,
  LogicField,
} from "../year-planning/entities/date-entities";
import { DateGroupExtended } from "./date-group-extended";
import { DateGroupItemExtended } from "./date-group-item-extended";
import { LogicFieldExtended } from "./logic-field-extended";

@Component({
  selector: "app-date-groups-dialog",
  templateUrl: "./date-groups-dialog.component.html",
  styleUrls: ["./date-groups-dialog.component.scss"],
})
export class DateGroupsDialogComponent implements OnInit {
  /**
   * Response of the service with all @see {DateGroupExtended} and their children. Extended with some properties.
   *
   * @type {RestResponse<DateGroupExtended[]>}
   * @memberof DateGroupsDialogComponent
   */
  response: RestResponse<DateGroupExtended[]>;

  get filterMarks() {
    return {
      title: "Ijkpunt",
      allowNothing: true,
      icon: "pageview",
      entityName: "app.phase_section_marks",
      nameField: "id",
      descriptionField: "name",
      sortField: "name",
      sortDirection: "ASC",
      filterFields: ["name"],
      filters: [Ops.Field("id").IsNot().In(this.occupiedMarks)],
    };
  }

  filterNotifications = {
    title: "Melding",
    allowNothing: true,
    icon: "pageview",
    entityName: "app.notification_templates",
    nameField: "id",
    descriptionField: "subject",
    sortField: "subject",
    sortDirection: "ASC",
    filterFields: ["subject"],
  };

  filterDrawOrderTypes = {
    title: "Tekening",
    allowNothing: true,
    icon: "drawing",
    entityName: "draw_order_types",
    nameField: "name",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name"],
    filters: [Ops.Field("condition").Equals("1000")],
  };

  /**
   * Entity crud-service used for @see {DateGroup}
   *
   * @readonly
   * @protected
   * @memberof DateGroupsDialogComponent
   */
  protected get dateGroupService() {
    return this.entityManager.get(DateGroup);
  }

  /**
   * Entity crud-service used for @see {Date}
   *
   * @readonly
   * @protected
   * @memberof DateGroupsDialogComponent
   */
  protected get dateService() {
    return this.entityManager.get(DateDefinition);
  }

  protected get logicFieldService() {
    return this.entityManager.get(LogicField);
  }

  constructor(
    protected readonly snackbar: MdcSnackbar,
    protected readonly entityManager: EntityManager,
    protected readonly reference: MdcDialogRef<DateGroupsDialogComponent>
  ) {}

  ngOnInit() {
    this.fetch().then(() => this.fixRipples());
  }

  get canSave() {
    return (
      this.response &&
      !this.response.value
        .filter((g) => !g.isPendingDeletion)
        .find(
          (g) =>
            !g.value.description ||
            g.dates
              .filter((d) => !d.isPendingDeletion)
              .find(
                (d) =>
                  !d.value.id ||
                  !d.value.description ||
                  !d.value.dateFormat ||
                  !d.value.iconId
              )
        )
    );
  }

  get occupiedMarks() {
    return this.response
      ? flatten(
          this.response.value.map((e) =>
            e.dates.map((d) => d.value.phaseSectionMarkId)
          )
        ).filter((e) => e !== null)
      : [];
  }

  addGroup() {
    const native = this.dateGroupService.concept({
      hasResponsible: false,
      hasComments: false,
      alwaysVisible: false,
      orderId: this.response.value.length,
    });
    this.response.value.push(new DateGroupExtended(native, [], []));
  }

  addDate(group: DateGroupExtended) {
    const native = this.dateService.concept({
      stackId: 0,
      dateGroupId: group.value.id,
      iconId: "help_outline",
      colorCode: "grey",
      isSynced: false,
      dateFormat: "DD-MM-YYYY",
      orderId: group.dates.length,
    });
    group.dates.push(new DateGroupItemExtended(native));
    group.isDirty = true;
    group.isExpanded = true;
  }

  addLogicField(group: DateGroupExtended) {
    const native = this.logicFieldService.concept({
      dateGroupId: group.value.id,
      iconId: "help_outline",
      colorCode: "grey",
      orderId: group.logicFields.length,
    });
    group.logicFields.push(new LogicFieldExtended(native));
    group.isDirty = true;
    group.isExpanded = true;
  }

  drop(event: CdkDragDrop<IOrderable[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );

    for (let i = 0; i < event.container.data.length; i++) {
      event.container.data[i].orderId = i;
    }
  }

  async save() {
    for (const group of this.response.value) {
      await this.saveGroup(group);
    }

    this.reference.close("save");

    await this.snackbar.open("Opgeslagen", "Ok", { leading: true });
  }

  bliss(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  protected async saveGroup(group: DateGroupExtended) {
    if (group.isPendingDeletion) {
      if (group.value.id) {
        await this.dateGroupService.delete(group.value.id);
      }
    } else {
      if (group.isDirty) {
        group.value.id = (
          await this.dateGroupService.save(group.value)
        ).value.id;
      }

      for (const date of group.dates) {
        await this.saveDate(date, group);
      }

      for (const logicField of group.logicFields) {
        await this.saveLogicField(logicField, group);
      }
    }
  }

  protected async saveDate(
    date: DateGroupItemExtended,
    group: DateGroupExtended
  ) {
    if (date.isPendingDeletion) {
      if (date.value.id) {
        await this.dateService.delete(date.value.id);
      }
    } else {
      if (date.isDirty) {
        date.value.dateGroupId = group.value.id;
        await this.dateService.save(date.value);
      }
    }
  }

  protected async saveLogicField(
    logicField: LogicFieldExtended,
    group: DateGroupExtended
  ) {
    if (logicField.isPendingDeletion) {
      if (logicField.value.id) {
        await this.logicFieldService.delete(logicField.value.id);
      }
    } else {
      if (logicField.isDirty) {
        logicField.value.dateGroupId = group.value.id;
        await this.logicFieldService.save(logicField.value);
      }
    }
  }

  protected fixRipples() {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 500);
  }

  protected async fetch() {
    const response = await this.dateGroupService.query({
      orders: [{ field: "orderId", direction: "ASC" }],
      relations: ["dates", "logicFields"],
    });

    if (!response.hasError()) {
      this.response = new RestResponse(
        _.orderBy(response.value, (d) => d.orderId).map(
          (e) =>
            new DateGroupExtended(
              e,
              _.orderBy(e.__dates__, (d) => d.orderId).map(
                (d) => new DateGroupItemExtended(d)
              ),

              _.orderBy(e.__logicFields__, (d) => d.orderId).map(
                (d) => new LogicFieldExtended(d)
              )
            )
        )
      );
    }
  }
}
interface IOrderable {
  orderId: number;
}
