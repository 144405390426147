import { Bloc } from "@comceptum-software/react-bloc";
import {
  PicklistDefinitionsAddRequestEvent,
  PicklistDefinitionsDeleteRequestEvent,
  PicklistDefinitionsEvent,
  PicklistDefinitionsLoadRequestEvent,
  PicklistDefinitionsStoreRequestEvent,
} from "./picklist-definitions.event";
import {
  PicklistDefinitionsFailureState,
  PicklistDefinitionsLoadedState,
  PicklistDefinitionsLoadingState,
  PicklistDefinitionsState,
} from "./picklist-definitions.state";
import { Apollo } from "apollo-angular";
import { picklistDefinitionsOverviewQuery } from "./picklist-definitions.query";
import { PicklistDefinitionDto } from "./picklist-definition.dto";
import {
  picklistDefinitionsDeleteMutation,
  picklistDefinitionsStoreMutation,
} from "./picklist-definitions.mutation";
import { omit } from "lodash";

export class PicklistDefinitionsBloc extends Bloc<
  PicklistDefinitionsEvent,
  PicklistDefinitionsState
> {
  constructor(protected readonly apollo: Apollo) {
    super(new PicklistDefinitionsLoadingState());
  }

  async *mapEventToState(event: PicklistDefinitionsEvent) {
    if (event instanceof PicklistDefinitionsLoadRequestEvent) {
      yield* await this._mapLoadRequestToState(event);
    }

    if (event instanceof PicklistDefinitionsAddRequestEvent) {
      yield* await this._mapAddRequestToState(event);
    }

    if (event instanceof PicklistDefinitionsStoreRequestEvent) {
      yield* await this._mapStoreRequestToState(event);
    }

    if (event instanceof PicklistDefinitionsDeleteRequestEvent) {
      yield* await this._mapDeleteRequestToState(event);
    }
  }

  protected async *_mapLoadRequestToState(
    event: PicklistDefinitionsLoadRequestEvent
  ) {
    const response = await this.apollo
      .query<{
        items: PicklistDefinitionDto[];
      }>({
        query: picklistDefinitionsOverviewQuery,
      })
      .toPromise();

    if (!response.errors) {
      yield new PicklistDefinitionsLoadedState({
        items: response.data.items.map((_item) => this._dtoWithTrackId(_item)),
      });
    } else {
      yield new PicklistDefinitionsFailureState({
        errorMessage: response.errors.toString(),
      });
    }
  }

  protected async *_mapAddRequestToState(
    event: PicklistDefinitionsAddRequestEvent
  ) {
    const _state = this.stateOf<PicklistDefinitionsLoadedState>();

    if (_state instanceof PicklistDefinitionsLoadedState) {
      yield _state.copyWith({
        items: [
          ..._state.items,
          this._dtoWithTrackId({
            name: "Nieuwe Definitie",
            icon: "how_to_vote",
            metacomLinesTable: "",
            metacomListTable: "",
            hideColumns: [],
            elementTypes: [],
          }),
        ],
      });
    }
  }

  protected async *_mapStoreRequestToState(
    event: PicklistDefinitionsStoreRequestEvent
  ) {
    const _state = this.stateOf<PicklistDefinitionsLoadedState>();

    if (_state instanceof PicklistDefinitionsLoadedState) {
      const response = await this.apollo
        .mutate<{
          item: PicklistDefinitionDto;
        }>({
          mutation: picklistDefinitionsStoreMutation,
          variables: {
            input: omit(event.model, ["__trackId", "__typename"]),
          },
        })
        .toPromise();

      if (!response.errors) {
        yield _state.copyWith({
          items: _state.items.map((_item) =>
            _item.__trackId === event.model.__trackId
              ? {
                  _localId: event.model.__trackId,
                  ...response.data.item,
                }
              : _item
          ),
        });
      }
    }
  }

  protected async *_mapDeleteRequestToState(
    event: PicklistDefinitionsDeleteRequestEvent
  ) {
    const _state = this.stateOf<PicklistDefinitionsLoadedState>();

    if (_state instanceof PicklistDefinitionsLoadedState) {
      const response = await this.apollo
        .mutate<{
          item: PicklistDefinitionDto;
        }>({
          mutation: picklistDefinitionsDeleteMutation,
          variables: {
            input: { id: event.model.id },
          },
        })
        .toPromise();

      yield _state.copyWith({
        items: _state.items.filter(
          (_item) => _item.__trackId !== event.model.__trackId
        ),
      });
    }
  }

  protected _dtoWithTrackId(dto: PicklistDefinitionDto) {
    dto.__trackId = Math.random().toString();

    return dto;
  }
}
