import { Entity } from "../entity.service";

export class AppText extends Entity {
  static readonly INVITATION_ID = "invitation_mail";

  type = "app.texts";

  value: string;

  created_at?: Date;
  updated_at?: Date;
}
