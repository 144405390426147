import { TextField } from "@material-ui/core";
import * as moment from "moment";
import * as React from "react";
import { DeliveryListBloc } from "./delivery-list.bloc";
import { DeliveryListIsDeliveredAtUpdateEvent } from "./delivery-list.event";
// import { DeliveryListKeyAmountUpdateEvent } from "./delivery-list.event";
// import { DeliveryListLoadSuccessState } from "./delivery-list.state";

export interface DeliveredAtComponentProps {
  bloc: DeliveryListBloc;
  deliveredAt: Date;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const DeliveredAtComponent: React.FC<DeliveredAtComponentProps> = ({
  bloc,
  deliveredAt,
  disabled,
}) => {
  const [value, setValue] = React.useState<string>(
    `${moment(deliveredAt || new Date()).format("yyyy-MM-DD")}`
  );

  return (
    <TextField
      style={{
        margin: 4,
        width: "100%",
        paddingRight: 8,
      }}
      defaultValue={moment(new Date()).format("yyyy-MM-DD")}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      variant="outlined"
      type="date"
      label={`Opleverdatum`}
      value={value}
      onBlur={() =>
        bloc.add(
          new DeliveryListIsDeliveredAtUpdateEvent({
            isDeliveredAt: new Date(value),
          })
        )
      }
      onChange={(event) => setValue(event.target.value)}
    />
  );
};
