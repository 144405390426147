import * as React from "react";
import { BlocBuilder, BlocProvider } from "@comceptum-software/react-bloc";
import { CustomerQuestionsBloc } from "./customer-questions.bloc";
import {
  CustomerQuestionsLoadingState,
  CustomerQuestionsState,
  CustomerQuestionsSuccessState,
} from "./customer-questions.state";
import {
  GridCellParams,
  GridCellValue,
  DataGrid,
} from "@material-ui/data-grid";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import {
  CustomerQuestionsLoadRequestEvent,
  CustomerQuestionsReadyRequestEvent,
  CustomerQuestionsReopenRequestEvent,
  CustomerQuestionsTicketRequestEvent,
  CustomerQuestionsUpdateRequestEvent,
} from "./customer-questions.event";
import { Injector } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CustomerQuestionDto } from "./customer-question.dto";
import {
  CallReceived as CallReceivedIcon,
  Done as DoneIcon,
  Note as NoteIcon,
  OpenInNew,
} from "@material-ui/icons";
import { BlocContext } from "@comceptum-software/react-bloc/dist/bloc.context";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import {
  CustomerQuestionsDialog,
  CustomerQuestionsDialogCloseAction,
} from "./customer-questions-dialog.component";
import { PortalThemeProvider } from "../react-component/PortalThemeProvider";
import * as classNames from "classnames";

export interface CustomerQuestionsComponentProps {
  injector: Injector;
}

const useStyles = makeStyles(() => ({
  grid: {
    backgroundColor: "white",
    borderRadius: "0px",
    border: "none",
    fontSize: 12,
  },
  gridCell: {
    padding: 0,
    height: 36,
    borderLeft: "1px solid #eeeeee",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  gridCellCompleted: {
    color: "grey",
    textDecoration: "line-through",
  },
  header: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
  },
  gridToolbar: {
    backgroundColor: "#eeeeee",
  },
  textFieldContainer: {
    padding: 8,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
  checkboxFieldContainer: {
    padding: 8,
    paddingLeft: 16,
    height: "100%",
    backgroundColor: "#eeeeee",
    borderRight: "2px solid white",
    color: "grey",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#0099cc",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: 4,
    padding: 8,
  },
  arrow: {
    color: "#0099cc",
  },
}))(Tooltip);

export function CustomerQuestionsComponent(
  props: CustomerQuestionsComponentProps
) {
  const classes = useStyles();
  const http = props.injector.get(HttpClient);

  function _mapsStateToRows(state: CustomerQuestionsState) {
    if (state instanceof CustomerQuestionsLoadingState) {
      return [];
    }

    if (state instanceof CustomerQuestionsSuccessState) {
      return state.questions;
    }

    return [];
  }

  function _mapQuestionToState(question: CustomerQuestionDto) {
    if (question.completedAt) {
      return <Chip icon={<DoneIcon />} label="Afgehandeld" color="primary" />;
    } else {
      return (
        <Chip icon={<CallReceivedIcon />} label="Ontvangen" color="default" />
      );
    }
  }

  function _mapStateToElements(
    context: BlocContext<CustomerQuestionsBloc>,
    state: CustomerQuestionsState
  ) {
    const _stateLoaded = state as CustomerQuestionsSuccessState;
    const _isLoading = state instanceof CustomerQuestionsLoadingState;

    const cellClassName = (params) =>
      classNames([
        classes.gridCell,
        ...(!!params.row.completedAt ? [classes.gridCellCompleted] : []),
      ]);

    return (
      <>
        {_stateLoaded && _stateLoaded.ticketComposerEnabled && (
          <CustomerQuestionsDialog
            injector={props.injector}
            question={_stateLoaded.ticketComposer}
            onClosed={(action, payload) => {
              if (
                action !== CustomerQuestionsDialogCloseAction.Dismissed &&
                action !== CustomerQuestionsDialogCloseAction.InternalComment &&
                action !== CustomerQuestionsDialogCloseAction.Reopen
              ) {
                context.bloc.add(
                  new CustomerQuestionsReadyRequestEvent({
                    question: _stateLoaded.ticketComposer,
                    internalComment: payload ? payload.internalComment : "",
                  })
                );
              }

              if (
                action === CustomerQuestionsDialogCloseAction.InternalComment
              ) {
                context.bloc.add(
                  new CustomerQuestionsUpdateRequestEvent({
                    question: _stateLoaded.ticketComposer,
                    internalComment: payload ? payload.internalComment : "",
                  })
                );
              }

              if (action === CustomerQuestionsDialogCloseAction.Reopen) {
                context.bloc.add(
                  new CustomerQuestionsReopenRequestEvent({
                    question: _stateLoaded.ticketComposer,
                  })
                );
              }

              context.bloc.add(
                new CustomerQuestionsTicketRequestEvent({
                  question: null,
                })
              );
            }}
          />
        )}

        <PortalThemeProvider>
          <div
            style={{
              position: "relative",
              display: "flex",
              flexFlow: "column",
              height: "100%",
            }}
          >
            {_isLoading ? (
              <LinearProgress />
            ) : (
              <Grid
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.gridToolbar}
                container
              >
                <Grid item>
                  <div className={classes.textFieldContainer}>
                    <TextField
                      label="Datum vanaf"
                      type="date"
                      defaultValue={moment(_stateLoaded.filter.dateFrom).format(
                        "yyyy-MM-DD"
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) =>
                        context.bloc.add(
                          new CustomerQuestionsLoadRequestEvent({
                            filter: {
                              ..._stateLoaded.filter,
                              dateFrom: new Date(event.target.value),
                            },
                          })
                        )
                      }
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.textFieldContainer}>
                    <TextField
                      label="Datum tot"
                      type="date"
                      defaultValue={moment(_stateLoaded.filter.dateTo).format(
                        "yyyy-MM-DD"
                      )}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) =>
                        context.bloc.add(
                          new CustomerQuestionsLoadRequestEvent({
                            filter: {
                              ..._stateLoaded.filter,
                              dateTo: new Date(event.target.value),
                            },
                          })
                        )
                      }
                    />
                  </div>
                </Grid>

                <Grid item>
                  <div className={classes.checkboxFieldContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={_stateLoaded.filter.withCompleted}
                          name="completed"
                          onChange={(event) =>
                            context.bloc.add(
                              new CustomerQuestionsLoadRequestEvent({
                                filter: {
                                  ..._stateLoaded.filter,
                                  withCompleted: event.target.checked,
                                },
                              })
                            )
                          }
                        />
                      }
                      label="Afgehandeld"
                    />
                  </div>
                </Grid>
                <Grid item style={{ paddingLeft: 24 }}>
                  <strong>
                    {
                      _stateLoaded.questions.filter(
                        (_question) => !_question.completedAt
                      ).length
                    }
                  </strong>{" "}
                  openstaande meldingen
                </Grid>
              </Grid>
            )}

            <Paper
              style={{
                flex: "1 1 auto",
              }}
            >
              <DataGrid
                rowHeight={48}
                density={"compact"}
                paginationMode={"client"}
                loading={
                  state instanceof CustomerQuestionsLoadingState ||
                  _stateLoaded.isLoading
                }
                className={classes.grid}
                hideFooter={true}
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableColumnMenu={true}
                headerHeight={48}
                columns={[
                  { field: "id", hide: true },
                  {
                    field: "createdAt",
                    headerName: "Datum/Tijd",
                    type: "dateTime",
                    headerClassName: classes.header,
                    cellClassName: cellClassName,
                    flex: 1,
                    valueGetter: (params: GridCellParams) => {
                      return new Date(params.value as string);
                    },
                    sortComparator: (v1: GridCellValue, v2: GridCellValue) => {
                      return moment(v2 as Date).diff(moment(v1 as Date));
                    },
                  },
                  {
                    field: "customerName",
                    headerName: "Naam",
                    headerClassName: classes.header,
                    cellClassName: cellClassName,
                    flex: 1,
                  },
                  {
                    field: "city",
                    headerName: "Woonplaats",
                    headerClassName: classes.header,
                    cellClassName: cellClassName,
                    flex: 1,
                  },
                  {
                    field: "telephone",
                    headerName: "Tel.",
                    headerClassName: classes.header,
                    cellClassName: cellClassName,
                    flex: 1,
                  },
                  {
                    field: "message",
                    headerName: "Omschrijving",
                    headerClassName: classes.header,
                    cellClassName: cellClassName,
                    renderCell: (params) => {
                      return (
                        <HtmlTooltip
                          placement="bottom"
                          arrow
                          title={params.value as string}
                        >
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {params.value}
                          </span>
                        </HtmlTooltip>
                      );
                    },
                    flex: 1,
                  },
                  {
                    field: "internalComment",
                    headerName: "Memo",
                    headerClassName: classes.header,
                    width: 92,
                    cellClassName: cellClassName,
                    renderCell: (params) => {
                      return params.value ? (
                        <HtmlTooltip
                          placement="bottom"
                          arrow
                          title={params.value as string}
                        >
                          <IconButton disableTouchRipple color="primary">
                            <NoteIcon />
                          </IconButton>
                        </HtmlTooltip>
                      ) : null;
                    },
                  },
                  {
                    field: "action",
                    headerName: "...",
                    headerAlign: "center",
                    headerClassName: classes.header,
                    sortable: false,
                    width: 92,
                    cellClassName: cellClassName,
                    renderCell: (params: GridCellParams) => (
                      <>
                        <IconButton
                          style={{ margin: "0 auto" }}
                          onClick={() =>
                            context.bloc.add(
                              new CustomerQuestionsTicketRequestEvent({
                                question: params.row as CustomerQuestionDto,
                              })
                            )
                          }
                        >
                          <OpenInNew />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
                rows={_mapsStateToRows(state)}
              />
            </Paper>
          </div>
        </PortalThemeProvider>
      </>
    );
  }

  return (
    <BlocProvider<CustomerQuestionsBloc>
      create={() =>
        new CustomerQuestionsBloc(props.injector.get(Apollo)).add(
          new CustomerQuestionsLoadRequestEvent({
            filter: {
              dateFrom: moment().subtract(31, "d").toDate(),
              dateTo: moment().add(1, "d").toDate(),
              withCompleted: false,
            },
          })
        )
      }
    >
      <BlocBuilder<CustomerQuestionsBloc, CustomerQuestionsState>
        bloc={CustomerQuestionsBloc}
        builder={(context, state) => _mapStateToElements(context, state)}
      />
    </BlocProvider>
  );
}
