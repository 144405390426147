export class Seal {
  static isNotSet(value: object, name?: string) {
    if (value === null || value === undefined) {
      throw new Error(`Argument ${name || ''} cannot be null or undefined`);
    }
  }

  static isNotSetOrEmpty(value: string, name?: string) {
    if (value === null || value === undefined || value === '') {
      throw new Error(
        `Argument ${name || ''} cannot be null, undefined or empty`,
      );
    }
  }
}
