import * as React from "react";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { HeaderRendererProps, SortableHeaderCell } from "react-data-grid";
import { ConstructionPlanningProjectDto } from "../construction-planning-date.dto";

interface ConstructionPlanningSearchColumnProps {
  headerProps: React.PropsWithChildren<
    HeaderRendererProps<ConstructionPlanningProjectDto, any>
  >;
  value: string;
  onChange: (value: string) => void;
}

export const ConstructionPlanningSearchColumn = ({
  headerProps,
  value,
  onChange,
}: ConstructionPlanningSearchColumnProps) => {
  const [intermediate, setIntermediate] = useState(() => value);

  const emit = useMemo(
    () => debounce((newValue: string) => onChange(newValue), 250),
    [onChange]
  );

  const setValue = (newValue: string) => {
    setIntermediate(newValue);

    (!newValue || newValue.length >= 3) && emit(newValue);
  };

  return (
    <>
      <SortableHeaderCell {...headerProps}>
        {headerProps.column.name}
      </SortableHeaderCell>

      <input
        style={{
          width: "100%",
          borderRadius: 4,
          border: 0,
          background: "#eeeeee",
          padding: 2,
        }}
        placeholder="Zoeken"
        value={intermediate}
        onChange={(event) => setValue(event.target.value)}
      ></input>
    </>
  );
};
