import { Entity } from "../entity.service";
import { Project } from "../project/project.entity";
import { CostCategory } from "../audit-sheet-table/cost-category.entity";
import { User } from "../accessibility-users/user.entity";
import { AuditQuestion } from "../audit-sheet/audit-question.entity";
import { DocumentMeta } from "../documents/document-meta.entity";

export class AuditReport extends Entity {
  type = "audit_reports";

  userId: string;
  projectId: string;
  costCategoryId: string;
  signatureDocumentMetaId?: string;

  comment: string;
  lockedAt?: Date;
  inspectedAt?: Date | string;
  inspectedAtTime?: string;

  __user__?: User;
  __project__?: Project;
  __costCategory__?: CostCategory;
  __signatureDocumentMeta__?: DocumentMeta;
  __auditReportItems__?: AuditReportItem[];

  createdAt?: Date;
}

export class AuditReportItem extends Entity {
  type = "audit_report_items";

  userId: string;
  auditReportId: string;
  auditQuestionId: string;

  state: string;
  comment: string;

  __user__?: User;
  __auditReport__?: AuditReport;
  __auditQuestion__?: AuditQuestion;
  __auditReportItemImages__?: AuditReportItemImage[];
}

export class AuditReportItemImage extends Entity {
  type = "audit_report_item_images";

  documentMetaId: string;
  auditReportItemId: string;

  __documentMeta?: DocumentMeta;
  __auditReportItem__?: AuditReportItem;

  dataUrl?: string;
  dataFile?: File;
  isMarkedFormDeletion?: boolean;
}
