import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLocaleData } from "@angular/common";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/angular";

import localeNl from "@angular/common/locales/nl";
import "moment/locale/nl";

Sentry.init({
  dsn: "https://e917628419ff484c8490a0befce3c9bd@o4504129504018432.ingest.sentry.io/4504129613660160",
  integrations: [
    new BrowserTracing({
      tracingOrigins: [environment.baseUrl, environment.appApiBaseUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  enabled: environment.production,
  tracesSampleRate: 0.001,
});

registerLocaleData(localeNl);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ("serviceWorker" in navigator && environment.production) {
      navigator.serviceWorker.register("/ngsw-worker.js");
    }
  })
  .catch((err) => console.log(err));
