import { PriorityEntity } from "src/app/service-settings/react/priorities/priority.entity";
import { TypeEntity } from "src/app/service-settings/react/types/type.entity";
import { DeliveryListPointEntity } from "../../delivery-list.entity";

export class PointState {}

export class PointLoadInProgressState extends PointState {}

export class PointLoadFailureState extends PointState {
  constructor(
    readonly props: {
      readonly message: string;
    }
  ) {
    super();
  }
}

export class PointLoadSuccessState extends PointState {
  constructor(
    readonly props: {
      readonly points: DeliveryListPointEntity[];

      readonly defaultType: TypeEntity;
      readonly defaultPriority: PriorityEntity;
    }
  ) {
    super();
  }

  copyWith(newProps: { points: DeliveryListPointEntity[] }) {
    return new PointLoadSuccessState({
      points: newProps.points || this.props.points,
      defaultType: this.props.defaultType,
      defaultPriority: this.props.defaultPriority,
    });
  }
}
