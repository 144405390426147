import { Component, OnInit, Inject } from "@angular/core";
import { MDC_DIALOG_DATA } from "@angular-mdc/web";

@Component({
  selector: "app-information-dialog",
  templateUrl: "./information-dialog.component.html",
  styleUrls: ["./information-dialog.component.scss"],
})
export class InformationDialogComponent {
  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: InformationDialogComponentData
  ) {}
}

export interface InformationDialogComponentData {
  title?: string;
  message?: string;
}
