import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";
import { sumBy } from "lodash";
import { AppUser } from "../app-users/app-user.entity";
import { AppProject } from "./app-project.entity";
import { User } from "../accessibility-users/user.entity";

export enum AppProjectCostValueType {
  FixedOnParent,
  Calculation,
}

export class ProjectCostInfo extends Entity {
  type = "project_cost_infos";

  appProjectCostId: number;

  conceptUser?: User;
  conceptUserId: string;

  createdAt?: Date;
  updatedAt?: Date;

  set __conceptUser__(value: User) {
    this.conceptUser = this.entityManager
      .get(User)
      .healOneResponse(new RestResponse(value)).value;
  }
}

export enum AppProjectCostStage {
  Created = "created",
  Concept = "concept",
  Requested = "requested",
  Approved = "approved",
  Declined = "declined",
}

export class AppProjectCost extends Entity {
  static conceptPrefix = "concept_";

  type = "app.project_costs";

  user_id: number;
  project_id: string;
  approved_user_id?: number;
  declined_user_id?: number;

  parent_id?: number;
  parentHasValue: boolean;

  get isParent() {
    return !this.parent_id;
  }

  get isChild() {
    return this.isParent === false;
  }

  get isLocked() {
    return this.isApproved;
  }

  get isRoot() {
    return !this.id && !this.value;
  }

  get isApproved() {
    return !!this.approved_at;
  }

  get isDeclined() {
    return !!this.declined_at;
  }

  get isRequested() {
    return !!this.requested_at;
  }

  get stage() {
    if (this.isDeclined) {
      return AppProjectCostStage.Declined;
    }

    if (this.isApproved) {
      return AppProjectCostStage.Approved;
    }

    if (this.isRequested) {
      return AppProjectCostStage.Requested;
    }

    if (this.isPersistentConcept) {
      return AppProjectCostStage.Concept;
    }

    return AppProjectCostStage.Created;
  }

  children: AppProjectCost[];

  title: string;
  memo: string;

  value: number;

  approved_at?: Date;
  declined_at?: Date;
  requested_at?: Date;
  payed_at?: Date;

  set __children__(value: AppProjectCost[]) {
    this.children = this.entityManager
      .get(AppProjectCost)
      .healManyResponse(new RestResponse(value)).value;
  }

  user: AppUser;

  set __user__(value: AppUser) {
    this.user = this.entityManager
      .get(AppUser)
      .healOneResponse(new RestResponse(value)).value;
  }

  approvedUser: AppUser;

  set __approvedUser__(value: AppUser) {
    this.approvedUser = this.entityManager
      .get(AppUser)
      .healOneResponse(new RestResponse(value)).value;
  }

  declinedUser: AppUser;

  set __declinedUser__(value: AppUser) {
    this.declinedUser = this.entityManager
      .get(AppUser)
      .healOneResponse(new RestResponse(value)).value;
  }

  project: AppProject;

  set __project__(value: AppProject) {
    this.project = this.entityManager
      .get(AppProject)
      .healOneResponse(new RestResponse(value)).value;
  }

  get total() {
    return (
      this.value ||
      (this.children && this.children.length > 0
        ? sumBy(this.children, (c) => c.total)
        : 0)
    );
  }

  get isCalculated() {
    return this.value !== this.total;
  }

  get isPersistentConcept() {
    return (
      this.project_id &&
      this.project_id.startsWith(AppProjectCost.conceptPrefix)
    );
  }

  created_at?: Date;
  updated_at?: Date;
}
