import { Component, Inject } from "@angular/core";
import {
  AppCrudDialogBoilerplate,
  AppCrudDialogBoilerplateData,
} from "../boilerplates/app-crud.dialog.boilerplate";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { ProjectPayment } from "./project-payment.entity";
import * as moment from "moment";

@Component({
  selector: "app-project-payment-dialog",
  templateUrl: "./project-payment-dialog.component.html",
  styleUrls: ["./project-payment-dialog.component.scss"],
})
export class ProjectPaymentDialogComponent extends AppCrudDialogBoilerplate<
  ProjectPayment
> {
  get canSave() {
    return (
      !!this.data.model.description &&
      !!this.data.model.amount &&
      !!this.data.model.payedAt
    );
  }

  constructor(
    @Inject(MDC_DIALOG_DATA)
    readonly data: AppCrudDialogBoilerplateData<ProjectPayment>,
    protected readonly dialog: MdcDialogRef<ProjectPaymentDialogComponent>
  ) {
    super(data, dialog);
  }
}
