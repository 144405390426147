import * as React from "react";
import * as moment from "moment";

import DataGrid, {
  Column,
  SortableHeaderCell,
  SortColumn,
} from "react-data-grid";

import { useConstructionPlanningTableStyles } from "./construction-planning-table.styles";
import {
  ConstructionPlanningLoadInProgressState,
  ConstructionPlanningLoadSuccessState,
  ConstructionPlanningState,
} from "./construction-planning.state";
import { ConstructionPlanningBloc } from "./construction-planning.bloc";
import { BlocContext } from "@comceptum-software/react-bloc/dist/bloc.context";
import {
  BlocBuilder,
  BlocListener,
  useBloc,
} from "@comceptum-software/react-bloc";
import {
  ConstructionPlanningColorLineDto,
  ConstructionPlanningDateDto,
  ConstructionPlanningDateGroupDto,
  ConstructionPlanningProjectDateValueDto,
  ConstructionPlanningProjectDto,
} from "./construction-planning-date.dto";
import {
  constructionGetDate,
  constructionGetDateFormatted,
  constructionOpenShortcuts,
} from "./construction-planning.helpers";
import {
  ConstructionPlanningFilter,
  ConstructionPlanningFilterValue,
  ConstructionPlanningLoadEvent,
  ConstructionPlanningReloadEvent,
} from "./construction-planning.event";
import { useEffect, useMemo, useState } from "react";
import { ConstructionFoundationModal } from "./construction-foundation.modal/construction-foundation.modal";
import { Injector } from "@angular/core";
import {
  MoreHoriz as MoreHorizIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { PageService } from "../page.service";
import { ConstructionPlanningCommentsColumn } from "./components/construction-planning-comments.column";
import { ConstructionPlanningBuildingWeekColumn } from "./components/construction-planning-building-week.column";
import { ConstructionPlanningEditColumn } from "./components/construction-planning-edit.column";
import * as classNames from "classnames";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { ConstructionPlanningSearchColumn } from "./components/construction-planning-search.column";

export interface ConstructionPlanningDateEditRequest {
  project: ConstructionPlanningProjectDto;
  date: ConstructionPlanningDateDto;
  dateValue: ConstructionPlanningProjectDateValueDto;
}

export interface ConstructionPlanningCommentEditRequest {
  project: ConstructionPlanningProjectDto;
  commentDateGroup: ConstructionPlanningDateGroupDto;
}

interface ConstructionPlanningTableProps {
  injector: Injector;
  onDateEditRequest: (
    request: ConstructionPlanningDateEditRequest
  ) => Promise<any>;
  onCommentEditRequest: (
    request: ConstructionPlanningCommentEditRequest
  ) => Promise<any>;
}

export function ConstructionPlanningTable({
  injector,
  onDateEditRequest,
  onCommentEditRequest,
}: ConstructionPlanningTableProps) {
  const localStorageId = "ConstructionPlanningTable:FilterMemoStorage";

  const bloc = useBloc(ConstructionPlanningBloc);
  const tableStyles = useConstructionPlanningTableStyles();

  const [blocState, setBlocState] = useState<ConstructionPlanningState>();

  const loading = useMemo(
    () =>
      blocState instanceof ConstructionPlanningLoadInProgressState ||
      (blocState instanceof ConstructionPlanningLoadSuccessState &&
        blocState.isReloading),
    [blocState]
  );

  const blocStateLoaded = useMemo(
    () =>
      blocState instanceof ConstructionPlanningLoadSuccessState
        ? (blocState as ConstructionPlanningLoadSuccessState)
        : undefined,
    [blocState]
  );

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const rows = useMemo(
    () => (blocStateLoaded && blocStateLoaded.projects) || [],
    [blocStateLoaded]
  );
  const dates = useMemo(
    () => (blocStateLoaded && blocStateLoaded.dates) || [],
    [blocState]
  );

  const lines = useMemo(
    () => (blocStateLoaded && blocStateLoaded.lines) || [],
    [blocState]
  );

  const commentDateGroup = useMemo(
    () => blocStateLoaded && blocStateLoaded.commentDateGroup,
    [blocState]
  );

  const highlighted = useMemo(() => rows.find((h) => h.isHighlighted), [rows]);

  const sortedRows = useMemo((): readonly ConstructionPlanningProjectDto[] => {
    if (sortColumns.length === 0) return rows;

    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey, dates);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
    return sortedRows;
  }, [rows, sortColumns]);

  useEffect(() => {
    injector
      .get(PageService)
      .setTitle(`Planning Constructief (${rows.length})`);
  }, [rows]);

  useEffect(() => {
    const subscription = bloc.state.subscribe((newState) =>
      setBlocState(newState)
    );

    return () => subscription.unsubscribe();
  }, [bloc]);

  const initialFilter = () => {
    const stored = localStorage.getItem(localStorageId);

    if (stored) {
      return JSON.parse(stored);
    } else {
      return {
        fieldFilters: {},
        dateFilters: {},
      };
    }
  };

  const [filter, setFilter] = useState<ConstructionPlanningFilter>(
    initialFilter()
  );

  useEffect(() => {
    bloc.add(
      new ConstructionPlanningLoadEvent({
        filter,
      })
    );
    localStorage.setItem(
      localStorageId,
      JSON.stringify({
        fieldFilters: {},
        dateFilters: filter.dateFilters,
      })
    );
  }, [filter]);

  const [foundationModal, setFoundationModal] =
    useState<ConstructionPlanningProjectDto>(null);

  function onRowClick(
    dates: ConstructionPlanningDateDto[],
    commentDateGroup: ConstructionPlanningDateGroupDto
  ) {
    const dateFields = dates.map((_date) => _date.id);

    return async (
      row: ConstructionPlanningProjectDto,
      column: Column<ConstructionPlanningProjectDto, any>
    ) => {
      if (dateFields.indexOf(column.key) >= 0) {
        const dateId = column.key;
        const date = dates.find((_date) => _date.id === dateId);
        const dateValue = row.cachedValue.dateValues.find(
          (_value) => _value.dateId === dateId
        );

        if (date) {
          await onDateEditRequest({
            project: row,
            date,
            dateValue,
          });

          bloc.add(new ConstructionPlanningReloadEvent());
        }
      }

      if (column.key === "edit") {
        setFoundationModal(row);
      }

      if (column.key === "shortcuts") {
        await constructionOpenShortcuts(injector, row);
      }

      if (column.key === "comments") {
        await onCommentEditRequest({
          project: row,
          commentDateGroup,
        });

        bloc.add(new ConstructionPlanningReloadEvent());
      }
    };
  }

  function dateColumn(
    dateId: string,
    title: string
  ): Column<ConstructionPlanningProjectDto, any> {
    return {
      key: dateId,
      name: title,
      headerCellClass: classNames([
        tableStyles.header,
        tableStyles.headerFilterable,
      ]),
      cellClass: classNames([tableStyles.gridCell]),
      sortable: true,
      resizable: false,
      headerRenderer: (props) => (
        <>
          <SortableHeaderCell {...props}>{title}</SortableHeaderCell>

          <select
            style={{
              width: "100%",
              borderRadius: 4,
              background: "#eeeeee",
              padding: 2,
            }}
            value={filter.dateFilters[dateId]}
            onChange={(event) =>
              setFilter((prev) => ({
                ...prev,
                dateFilters: {
                  ...filter.dateFilters,
                  [dateId]: event.target
                    .value as ConstructionPlanningFilterValue,
                },
              }))
            }
          >
            <option value={ConstructionPlanningFilterValue.AllValues}>
              Alle
            </option>
            <option value={ConstructionPlanningFilterValue.EmptyValues}>
              Zonder datum
            </option>
            <option value={ConstructionPlanningFilterValue.Values}>
              Met datum
            </option>
          </select>
        </>
      ),
      formatter: (props) => {
        return <>{constructionGetDateFormatted(props.row, dateId)}</>;
      },
    };
  }

  function headerColumns(
    lines: ConstructionPlanningColorLineDto[],
    dates: ConstructionPlanningDateDto[],
    commentDateGroup: ConstructionPlanningDateGroupDto
  ): Column<ConstructionPlanningProjectDto, any>[] {
    return [
      {
        key: "shortcuts",
        name: undefined,
        sortable: false,
        resizable: false,
        headerCellClass: classNames([tableStyles.header]),
        cellClass: classNames([
          tableStyles.gridCell,
          tableStyles.gridCellCentered,
          tableStyles.whiteBackground,
        ]),
        formatter: () => (
          <MoreHorizIcon
            className={tableStyles.gridCellValueFilled}
            color="disabled"
          />
        ),
        width: 42,
        maxWidth: 42,
      },
      {
        key: "id",
        name: "Project",
        sortable: true,
        resizable: false,
        headerCellClass: classNames([
          tableStyles.header,
          tableStyles.headerFilterable,
        ]),
        cellClass: (props) =>
          classNames([
            tableStyles.gridCell,
            ...(props.isHighlighted ? [tableStyles.gridCellHighlighted] : []),
          ]),
        headerRenderer: (props) => (
          <ConstructionPlanningSearchColumn
            headerProps={props}
            value={filter.fieldFilters[props.column.key]}
            onChange={(newValue) =>
              setFilter((prev) => ({
                ...prev,
                fieldFilters: {
                  ...prev.fieldFilters,
                  [props.column.key]: newValue,
                },
              }))
            }
          />
        ),
        width: 64,
      },
      {
        key: "buildingWeek",
        name: "Opbouw",
        resizable: false,
        headerCellClass: classNames([
          tableStyles.header,
          tableStyles.headerFilterable,
        ]),
        cellClass: tableStyles.gridCell,
        headerRenderer: (props) => (
          <ConstructionPlanningSearchColumn
            headerProps={props}
            value={filter.fieldFilters[props.column.key]}
            onChange={(newValue) =>
              setFilter((prev) => ({
                ...prev,
                fieldFilters: {
                  ...prev.fieldFilters,
                  [props.column.key]: newValue,
                },
              }))
            }
          />
        ),
        formatter: (props) => (
          <ConstructionPlanningBuildingWeekColumn
            project={props.row}
            lines={lines}
          />
        ),
        width: 80,
      },
      {
        key: "description",
        name: "Omschrijving",
        resizable: false,
        headerCellClass: classNames([
          tableStyles.header,
          tableStyles.headerFilterable,
        ]),
        cellClass: tableStyles.gridCell,
        headerRenderer: (props) => (
          <ConstructionPlanningSearchColumn
            headerProps={props}
            value={filter.fieldFilters[props.column.key]}
            onChange={(newValue) =>
              setFilter((prev) => ({
                ...prev,
                fieldFilters: {
                  ...prev.fieldFilters,
                  [props.column.key]: newValue,
                },
              }))
            }
          />
        ),
        width: "auto",
        minWidth: 300,
      },
      ...dates.map((_date) => dateColumn(_date.id, _date.description)),
      {
        key: "foundation",
        name: "Fundering",
        resizable: false,
        sortable: true,
        headerCellClass: tableStyles.header,
        cellClass: tableStyles.gridCell,
        formatter: (props) => (
          <strong>
            {props.row.foundation &&
              props.row.foundation.charAt(0).toUpperCase()}
          </strong>
        ),
        width: "auto",
      },
      {
        key: "constructionUnavailable",
        name: "Tijdelijk niet beschikbaar",
        resizable: false,
        headerCellClass: tableStyles.header,
        cellClass: classNames([
          tableStyles.gridCell,
          tableStyles.gridCellCentered,
        ]),
        formatter: (props) =>
          props.row.constructionUnavailable === "true" ? (
            <CheckCircleIcon
              className={tableStyles.gridCellValueFilled}
              fontSize="small"
              color="error"
            />
          ) : (
            <></>
          ),
        width: "auto",
      },
      ...(!!commentDateGroup
        ? [
            {
              key: "comments",
              name: undefined,
              resizable: false,
              headerCellClass: tableStyles.header,
              sortable: false,
              cellClass: classNames([
                tableStyles.gridCell,
                tableStyles.gridCellCentered,
              ]),
              formatter: (props) => (
                <ConstructionPlanningCommentsColumn
                  project={props.row}
                  dateGroup={commentDateGroup}
                />
              ),
              width: 42,
              maxWidth: 42,
            },
          ]
        : []),
      {
        key: "edit",
        name: undefined,
        resizable: false,
        headerCellClass: tableStyles.header,
        cellClass: tableStyles.gridCell,
        sortable: false,
        formatter: (props) => (
          <ConstructionPlanningEditColumn project={props.row} />
        ),
        width: 42,
        maxWidth: 42,
      },
    ];
  }

  type Comparator = (
    a: ConstructionPlanningProjectDto,
    b: ConstructionPlanningProjectDto
  ) => number;

  function compareDates(dateId: string): Comparator {
    return (a, b) => {
      const value1 = constructionGetDate(a, dateId) || new Date(0);
      const value2 = constructionGetDate(b, dateId) || new Date(0);

      return moment(value1).diff(moment(value2));
    };
  }

  function getComparator(
    sortColumn: string,
    dates: ConstructionPlanningDateDto[]
  ): Comparator {
    const dateFields = dates.map((date) => date.id);
    const isDateField = dateFields.indexOf(sortColumn) >= 0;

    if (isDateField) {
      return compareDates(sortColumn);
    }

    switch (sortColumn) {
      default:
        return (a, b) => {
          const valueA = a[sortColumn];
          const valueB = b[sortColumn];

          return `${valueA}`.localeCompare(`${valueB}`);
        };
    }
  }

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Backdrop
          style={{
            width: "100%",
            height: "100%",
            color: "#fff",
            zIndex: 10,
            position: "absolute",
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DataGrid
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          rowHeight={32}
          headerRowHeight={52}
          className={classNames(["rdg-light", tableStyles.grid])}
          enableVirtualization={false}
          columns={headerColumns(lines, dates, commentDateGroup)}
          rows={highlighted ? [highlighted] : sortedRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowClick={onRowClick(dates, commentDateGroup)}
        />
        {foundationModal && (
          <ConstructionFoundationModal
            injector={injector}
            value={foundationModal}
            onClosed={() => setFoundationModal(null)}
            onUpdated={() => {
              setFoundationModal(null);
              bloc.add(new ConstructionPlanningReloadEvent());
            }}
          />
        )}
      </div>
    </>
  );
}
