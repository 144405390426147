import { PresetEntity } from "src/app/service-settings/react/presets/preset.entity";
import { DeliveryListPointEntity } from "../../delivery-list.entity";

export class PointEvent {}

export class PointLoadRequestEvent extends PointEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
    }
  ) {
    super();
  }
}

export class PointLoadSuccessEvent extends PointEvent {}

export class PointUpdatedEvent extends PointEvent {
  constructor(
    readonly props: {
      readonly point: DeliveryListPointEntity;
    }
  ) {
    super();
  }
}

export class PointRemovedEvent extends PointEvent {
  constructor(
    readonly props: {
      readonly point: DeliveryListPointEntity;
    }
  ) {
    super();
  }
}

export class PointUpdatedSuccessEvent extends PointEvent {
  constructor(
    readonly props: {
      readonly point: DeliveryListPointEntity;
    }
  ) {
    super();
  }
}

export class PointAddedFromPresetEvent extends PointEvent {
  constructor(
    readonly props: {
      readonly projectId: string;
      readonly preset: PresetEntity;
    }
  ) {
    super();
  }
}
