import { AuditOrderableEntity } from './audit-orderable.entity';
import { AuditReportItem } from '../audit-sheet-report/audit-report.entities';

export class AuditQuestion extends AuditOrderableEntity {
    type = 'audit_questions';

    description: string;
    auditCategoryId: any;

    item?: AuditReportItem;
}
