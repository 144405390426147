/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Query"]: AliasType<{
GrantEntityFindById?: [{	id?:string | null},ValueTypes["GrantEntity"]],
GrantEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["GrantEntity"]],
GrantEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["GrantEntity"]],
GrantEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
RoleGrantConfigEntityFindById?: [{	id?:string | null},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
RoleDocumentGrantEntityFindById?: [{	id?:string | null},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
RoleEntityFindById?: [{	id?:string | null},ValueTypes["RoleEntity"]],
RoleEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleEntity"]],
RoleEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["RoleEntity"]],
RoleEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserGrantConfigEntityFindById?: [{	id?:string | null},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserNotificationSubscriptionEntityFindById?: [{	id?:string | null},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserNotificationEntityFindById?: [{	id?:string | null},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserTemporaryAccessTokenEntityEntityFindById?: [{	id?:string | null},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserEntityFindById?: [{	id?:string | null},ValueTypes["UserEntity"]],
UserEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserEntity"]],
UserEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserEntity"]],
UserEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistDefinitionEntityFindById?: [{	id?:string | null},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistStateEntityFindById?: [{	id?:string | null},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HousePartGroupEntityFindById?: [{	id?:string | null},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HousePartEntityFindById?: [{	id?:string | null},ValueTypes["HousePartEntity"]],
HousePartEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HousePartEntity"]],
HousePartEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HousePartEntity"]],
HousePartEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CargoPhaseEntityFindById?: [{	id?:string | null},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CargoTypeEntityFindById?: [{	id?:string | null},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningHumanResourceEntityFindById?: [{	id?:string | null},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectAssignmentEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectCompetencePlacementEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectRequestPlacementEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectRequestEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectCompetenceEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningCompetenceEntityFindById?: [{	id?:string | null},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CargoStateEntityFindById?: [{	id?:string | null},ValueTypes["CargoStateEntity"]],
CargoStateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoStateEntity"]],
CargoStateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoStateEntity"]],
CargoStateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CargoEntityFindById?: [{	id?:string | null},ValueTypes["CargoEntity"]],
CargoEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoEntity"]],
CargoEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CargoEntity"]],
CargoEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectHousePartEntityFindById?: [{	id?:string | null},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistElementEntityFindById?: [{	id?:string | null},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistEntityFindById?: [{	id?:string | null},ValueTypes["PicklistEntity"]],
PicklistEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistEntity"]],
PicklistEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistEntity"]],
PicklistEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DrawOrderTypeEntityFindById?: [{	id?:string | null},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HouseModelEntityFindById?: [{	id?:string | null},ValueTypes["HouseModelEntity"]],
HouseModelEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HouseModelEntity"]],
HouseModelEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HouseModelEntity"]],
HouseModelEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SalesEmployeeEntityFindById?: [{	id?:string | null},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DrawerAbsentEntityFindById?: [{	id?:string | null},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DrawerEntityFindById?: [{	id?:string | null},ValueTypes["DrawerEntity"]],
DrawerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawerEntity"]],
DrawerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawerEntity"]],
DrawerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DrawOrderEntityFindById?: [{	id?:string | null},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateGroupCommentEntityFindById?: [{	id?:string | null},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateGroupResponsibleEntityFindById?: [{	id?:string | null},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
LogicFieldEntityFindById?: [{	id?:string | null},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateGroupEntityFindById?: [{	id?:string | null},ValueTypes["DateGroupEntity"]],
DateGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupEntity"]],
DateGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateGroupEntity"]],
DateGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateEntityFindById?: [{	id?:string | null},ValueTypes["DateEntity"]],
DateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateEntity"]],
DateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateEntity"]],
DateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateValueEntityFindById?: [{	id?:string | null},ValueTypes["DateValueEntity"]],
DateValueEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateValueEntity"]],
DateValueEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateValueEntity"]],
DateValueEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
LogicFieldValueEntityFindById?: [{	id?:string | null},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DateCacheEntityFindById?: [{	id?:string | null},ValueTypes["DateCacheEntity"]],
DateCacheEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateCacheEntity"]],
DateCacheEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DateCacheEntity"]],
DateCacheEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
YearPlanningModificationEntityFindById?: [{	id?:string | null},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectCachedValueEntityFindById?: [{	id?:string | null},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
TableLayoutCommentEntityFindById?: [{	id?:string | null},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectEntityFindById?: [{	id?:string | null},ValueTypes["ProjectEntity"]],
ProjectEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectEntity"]],
ProjectEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectEntity"]],
ProjectEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SyncCronEntityFindById?: [{	id?:string | null},ValueTypes["SyncCronEntity"]],
SyncCronEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SyncCronEntity"]],
SyncCronEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SyncCronEntity"]],
SyncCronEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
MailSchedulerEntityFindById?: [{	id?:string | null},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectPartnerEntityFindById?: [{	id?:string | null},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PartnerEntityFindById?: [{	id?:string | null},ValueTypes["PartnerEntity"]],
PartnerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PartnerEntity"]],
PartnerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PartnerEntity"]],
PartnerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DocumentMetaEntityFindById?: [{	id?:string | null},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
EntityAdvancementEntityFindById?: [{	id?:string | null},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
WorkActionCategoryEntityFindById?: [{	id?:string | null},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
WorkActionEntityFindById?: [{	id?:string | null},ValueTypes["WorkActionEntity"]],
WorkActionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionEntity"]],
WorkActionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionEntity"]],
WorkActionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
EntityImageEntityFindById?: [{	id?:string | null},ValueTypes["EntityImageEntity"]],
EntityImageEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EntityImageEntity"]],
EntityImageEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EntityImageEntity"]],
EntityImageEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CustomerQuestionEntityFindById?: [{	id?:string | null},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditCategoryEntityFindById?: [{	id?:string | null},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditQuestionEntityFindById?: [{	id?:string | null},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditQuestionCostCategoryEntityFindById?: [{	id?:string | null},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
CostCategoryEntityFindById?: [{	id?:string | null},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditReportItemImageEntityFindById?: [{	id?:string | null},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditReportItemEntityFindById?: [{	id?:string | null},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditReportEntityFindById?: [{	id?:string | null},ValueTypes["AuditReportEntity"]],
AuditReportEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportEntity"]],
AuditReportEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditReportEntity"]],
AuditReportEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AuditChecklistImageEntityFindById?: [{	id?:string | null},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InvoiceAttachmentEntityFindById?: [{	id?:string | null},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
EmployeeDocumentEntityFindById?: [{	id?:string | null},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
WorkActionTemplateGroupEntityFindById?: [{	id?:string | null},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
WorkActionTemplateEntityFindById?: [{	id?:string | null},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectCostInfoEntityFindById?: [{	id?:string | null},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
BlockedCalendarWeekEntityFindById?: [{	id?:string | null},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
MaintenanceReportEntityFindById?: [{	id?:string | null},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ConfigurationEntityFindById?: [{	id?:string | null},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServiceMeterEntityFindById?: [{	id?:string | null},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DeliveryListMeterEntityFindById?: [{	id?:string | null},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServiceSpaceEntityFindById?: [{	id?:string | null},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServiceTypeEntityFindById?: [{	id?:string | null},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DeliveryListPointImageEntityFindById?: [{	id?:string | null},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServicePriorityEntityFindById?: [{	id?:string | null},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DeliveryListPointEntityFindById?: [{	id?:string | null},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DeliveryListSignatureEntityFindById?: [{	id?:string | null},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DeliveryListEntityFindById?: [{	id?:string | null},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SupplyInspectionReportAnswerEntityFindById?: [{	id?:string | null},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SupplyInspectionReportEntityFindById?: [{	id?:string | null},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DepartmentEntityFindById?: [{	id?:string | null},ValueTypes["DepartmentEntity"]],
DepartmentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DepartmentEntity"]],
DepartmentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DepartmentEntity"]],
DepartmentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
DrawOrderDescriptionEntityFindById?: [{	id?:string | null},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
GreetingEntityFindById?: [{	id?:string | null},ValueTypes["GreetingEntity"]],
GreetingEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["GreetingEntity"]],
GreetingEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["GreetingEntity"]],
GreetingEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HourDeclarationTypeEntityFindById?: [{	id?:string | null},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HourDeclarationActivityEntityFindById?: [{	id?:string | null},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HourDeclarationEntityFindById?: [{	id?:string | null},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
HourDeclarationApprovalRequestEntityFindById?: [{	id?:string | null},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportCommentEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportDescriptionEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportFinalEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportMeasurementTemplateEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportMeasurementEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportQuestionGroupEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportQuestionEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InstallationReportQuestionAnswerEntityFindById?: [{	id?:string | null},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
LegacyProjectEntityFindById?: [{	id?:string | null},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistCommentEntityFindById?: [{	id?:string | null},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PicklistElementStateEntityFindById?: [{	id?:string | null},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
PlanningProjectItemEntityFindById?: [{	id?:string | null},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectCargoStateEntityFindById?: [{	id?:string | null},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ProjectPaymentEntityFindById?: [{	id?:string | null},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
QualityAssuranceEntityFindById?: [{	id?:string | null},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServiceMapColorEntityFindById?: [{	id?:string | null},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServicePlanningStageEntityFindById?: [{	id?:string | null},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServicePlanningEventEntityFindById?: [{	id?:string | null},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServicePlanningAssignmentEntityFindById?: [{	id?:string | null},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
ServicePresetEntityFindById?: [{	id?:string | null},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SupplierDocumentEntityFindById?: [{	id?:string | null},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SupplyInspectionQuestionEntityFindById?: [{	id?:string | null},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
SupplyInspectionQuestionGroupEntityFindById?: [{	id?:string | null},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
UserPasswordResetEntityFindById?: [{	id?:string | null},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
YearPlanningLineEntityFindById?: [{	id?:string | null},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
InvoicePaymentBatchEntityEntityFindById?: [{	id?:string | null},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPhaseContactEntityFindById?: [{	id?:string | null},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPhaseEntityFindById?: [{	id?:string | null},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPhaseSectionEntityFindById?: [{	id?:string | null},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppNotificationTemplateEntityFindById?: [{	id?:string | null},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPhaseSectionMarkEntityFindById?: [{	id?:string | null},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectDateEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppNotificationScheduleEntityFindById?: [{	id?:string | null},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPhaseSectionMarkValueEntityFindById?: [{	id?:string | null},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppInviteEntityFindById?: [{	id?:string | null},ValueTypes["AppInviteEntity"]],
AppInviteEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppInviteEntity"]],
AppInviteEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppInviteEntity"]],
AppInviteEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppLoginEntityFindById?: [{	id?:string | null},ValueTypes["AppLoginEntity"]],
AppLoginEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppLoginEntity"]],
AppLoginEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppLoginEntity"]],
AppLoginEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppNotificationEntityFindById?: [{	id?:string | null},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectEntity"]],
AppProjectEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectEntity"]],
AppProjectEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectEntity"]],
AppProjectEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppUserEntityFindById?: [{	id?:string | null},ValueTypes["AppUserEntity"]],
AppUserEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppUserEntity"]],
AppUserEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppUserEntity"]],
AppUserEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectCostEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectContactEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppBuildingWeekEntityFindById?: [{	id?:string | null},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppContentEntityFindById?: [{	id?:string | null},ValueTypes["AppContentEntity"]],
AppContentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppContentEntity"]],
AppContentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppContentEntity"]],
AppContentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppConversationMessagePresetEntityFindById?: [{	id?:string | null},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppDocumentSignatureEntityFindById?: [{	id?:string | null},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppDocumentTypeEntityFindById?: [{	id?:string | null},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppDocumentEntityFindById?: [{	id?:string | null},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppNotificationScheduleResourceEntityFindById?: [{	id?:string | null},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPlanCompetenceEntityFindById?: [{	id?:string | null},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppPlanCompetenceSettingEntityFindById?: [{	id?:string | null},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectCostPresetEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectFieldSheetEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectFieldGroupEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectFieldGroupAssignmentEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectFieldGroupAssignmentValueEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppProjectFieldEntityFindById?: [{	id?:string | null},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppServiceCategoryEntityFindById?: [{	id?:string | null},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppTextEntityFindById?: [{	id?:string | null},ValueTypes["AppTextEntity"]],
AppTextEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppTextEntity"]],
AppTextEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppTextEntity"]],
AppTextEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
AppRelationEntityFindById?: [{	id?:string | null},ValueTypes["AppRelationEntity"]],
AppRelationEntityFindOne?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppRelationEntity"]],
AppRelationEntityFindMany?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null},ValueTypes["AppRelationEntity"]],
AppRelationEntityAggregateGroupCount?: [{	options?:ValueTypes["JSON"] | null,	query?:ValueTypes["EntityQuery"] | null,	groupByField:string},ValueTypes["AggregateGroup"]],
bedrijvenMtcEntityFindMany?: [{	pageNr?:number | null,	pageSize?:number | null,	filter?:string | null},ValueTypes["bedrijvenMtcEntity"]],
		__typename?: boolean
}>;
	["GrantEntity"]: AliasType<{
	id?:boolean,
	permissionId?:boolean,
	roleId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	role?:ValueTypes["RoleEntity"],
		__typename?: boolean
}>;
	["Date"]:unknown;
	["RoleEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	grants?:ValueTypes["GrantEntity"],
	grantConfigs?:ValueTypes["RoleGrantConfigEntity"],
	documentGrants?:ValueTypes["RoleDocumentGrantEntity"],
		__typename?: boolean
}>;
	["RoleGrantConfigEntity"]: AliasType<{
	id?:boolean,
	roleId?:boolean,
	configId?:boolean,
	value?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	role?:ValueTypes["RoleEntity"],
		__typename?: boolean
}>;
	["RoleDocumentGrantEntity"]: AliasType<{
	id?:boolean,
	roleId?:boolean,
	match?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	role?:ValueTypes["RoleEntity"],
		__typename?: boolean
}>;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["EntityQuery"]: {
	filters:ValueTypes["EntityQueryFilter"][],
	orders?:ValueTypes["EntityQueryOrder"][],
	relations?:string[]
};
	["EntityQueryFilter"]: {
	field:string,
	operator:string,
	value?:string | null,
	valueComplex?:ValueTypes["JSON"] | null,
	isOr?:boolean | null,
	isNot?:boolean | null
};
	["EntityQueryOrder"]: {
	field:string,
	direction:string
};
	["AggregateGroup"]: AliasType<{
	key?:boolean,
	count?:boolean,
		__typename?: boolean
}>;
	["UserGrantConfigEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	configId?:boolean,
	value?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["UserEntity"]: AliasType<{
	id?:boolean,
	roleId?:boolean,
	companyId?:boolean,
	workAsUserId?:boolean,
	planningUserId?:boolean,
	abbreviation?:boolean,
	overwriteSignIn?:boolean,
	identity?:boolean,
	password?:boolean,
	passwordHash?:boolean,
	name?:boolean,
	stage?:boolean,
	email?:boolean,
	emailService?:boolean,
	emailDeclaration?:boolean,
	telephone?:boolean,
	isSupplier?:boolean,
	isSalesEmployee?:boolean,
	isServiceMechanic?:boolean,
	isBuyAdvisor?:boolean,
	isProjectMentor?:boolean,
	isProjectLeader?:boolean,
	addressStreet?:boolean,
	addressHouseNumber?:boolean,
	addressCity?:boolean,
	addressZipCode?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	lastSeenAt?:boolean,
	workAsUser?:ValueTypes["UserEntity"],
	planningUser?:ValueTypes["UserEntity"],
	role?:ValueTypes["RoleEntity"],
	temporaryAccessTokens?:ValueTypes["UserTemporaryAccessTokenEntityEntity"],
	grantConfigs?:ValueTypes["UserGrantConfigEntity"],
	notificationSubscriptions?:ValueTypes["UserNotificationSubscriptionEntity"],
	userNotifications?:ValueTypes["UserNotificationEntity"],
		__typename?: boolean
}>;
	["UserTemporaryAccessTokenEntityEntity"]: AliasType<{
	id?:boolean,
	value?:boolean,
	createdAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["UserNotificationSubscriptionEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	endpoint?:boolean,
	keys_auth?:boolean,
	keys_p256dh?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["UserNotificationEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	subject?:boolean,
	content?:boolean,
	url?:boolean,
	channels?:boolean,
	readAt?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["PicklistDefinitionEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	icon?:boolean,
	metacomListTable?:boolean,
	metacomLinesTable?:boolean,
	renameFilterName?:boolean,
	competenceNumber?:boolean,
	elementTypes?:boolean,
	hideColumns?:boolean,
	indeterminateToggles?:boolean,
	dateSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["PicklistStateEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	picklistDefinitionId?:boolean,
	completedByUserId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	picklistDefinition?:ValueTypes["PicklistDefinitionEntity"],
	completedByUser?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["ProjectEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	userId?:boolean,
	region?:boolean,
	regionId?:boolean,
	houseType?:boolean,
	houseShape?:boolean,
	condition?:boolean,
	buildingWeek?:boolean,
	buildingType?:boolean,
	buildingStreet?:boolean,
	buildingHouseNumber?:boolean,
	buildingCity?:boolean,
	buildingZipCode?:boolean,
	deliveryDate?:boolean,
	longitude?:boolean,
	latitude?:boolean,
	concrete?:boolean,
	customerEmail?:boolean,
	customerName?:boolean,
	foundation?:boolean,
	heating?:boolean,
	constructionProbeWork?:boolean,
	constructionAdvice?:boolean,
	constructionDrawingWork?:boolean,
	constructionSupplier?:boolean,
	constructionLevel?:boolean,
	constructionUnavailable?:boolean,
	score?:boolean,
	topicalDocumentRoot?:boolean,
	archiveDocumentRoot?:boolean,
	administrationType?:boolean,
	projectLeaderId?:boolean,
	salesEmployeeId?:boolean,
	buyAdvisorId?:boolean,
	projectMentorId?:boolean,
	installationReportDate?:boolean,
	installationPerformerName?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	projectLeader?:ValueTypes["UserEntity"],
	salesEmployee?:ValueTypes["UserEntity"],
	buyAdvisor?:ValueTypes["UserEntity"],
	projectMentor?:ValueTypes["UserEntity"],
	picklists?:ValueTypes["PicklistEntity"],
	picklistStates?:ValueTypes["PicklistStateEntity"],
	projectHouseParts?:ValueTypes["ProjectHousePartEntity"],
	tableLayoutComments?:ValueTypes["TableLayoutCommentEntity"],
	drawOrders?:ValueTypes["DrawOrderEntity"],
	logicFieldValues?:ValueTypes["LogicFieldValueEntity"],
	dateValues?:ValueTypes["DateValueEntity"],
	dateGroupComments?:ValueTypes["DateGroupCommentEntity"],
	dateGroupResponsibles?:ValueTypes["DateGroupResponsibleEntity"],
	cachedValue?:ValueTypes["ProjectCachedValueEntity"],
	dateCache?:ValueTypes["DateCacheEntity"],
	modifications?:ValueTypes["YearPlanningModificationEntity"],
		__typename?: boolean
}>;
	["PicklistEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	state?:boolean,
	note?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	project?:ValueTypes["ProjectEntity"],
	elements?:ValueTypes["PicklistElementEntity"],
		__typename?: boolean
}>;
	["PicklistElementEntity"]: AliasType<{
	id?:boolean,
	picklistId?:boolean,
	housePartGroupId?:boolean,
	pickedByUserId?:boolean,
	elementId?:boolean,
	elementType?:boolean,
	elementGroup?:boolean,
	elementName?:boolean,
	description?:boolean,
	quantity?:boolean,
	unit?:boolean,
	dimensions?:boolean,
	orderId?:boolean,
	pickState?:boolean,
	isPicked?:boolean,
	pickedAt?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	picklist?:ValueTypes["PicklistEntity"],
	housePartGroup?:ValueTypes["HousePartGroupEntity"],
	pickedByUser?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["HousePartGroupEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	isEnabled?:boolean,
	unit?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ProjectHousePartEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	cargoId?:boolean,
	supplierId?:boolean,
	partId?:boolean,
	partCodeId?:boolean,
	groupDivisionId?:boolean,
	quantity?:boolean,
	weight?:boolean,
	unit?:boolean,
	length?:boolean,
	width?:boolean,
	moldId?:boolean,
	wallId?:boolean,
	dateId?:boolean,
	costId?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	cargo?:ValueTypes["CargoEntity"],
	project?:ValueTypes["ProjectEntity"],
	housePart?:ValueTypes["HousePartEntity"],
		__typename?: boolean
}>;
	["CargoEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	competenceId?:boolean,
	phaseId?:boolean,
	cargoTypeId?:boolean,
	description?:boolean,
	timeAt?:boolean,
	requiresPermit?:boolean,
	routeId?:boolean,
	dateAt?:boolean,
	isLocked?:boolean,
	notes?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	competence?:ValueTypes["PlanningCompetenceEntity"],
	phase?:ValueTypes["CargoPhaseEntity"],
	cargoType?:ValueTypes["CargoTypeEntity"],
	projectHouseParts?:ValueTypes["ProjectHousePartEntity"],
	cargoStates?:ValueTypes["CargoStateEntity"],
		__typename?: boolean
}>;
	["PlanningCompetenceEntity"]: AliasType<{
	id?:boolean,
	number?:boolean,
	color?:boolean,
	description?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	planningProjectCompetences?:ValueTypes["PlanningProjectCompetenceEntity"],
		__typename?: boolean
}>;
	["PlanningProjectCompetenceEntity"]: AliasType<{
	id?:boolean,
	planningCompetenceId?:boolean,
	planningProjectRequestId?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	planningCompetence?:ValueTypes["PlanningCompetenceEntity"],
	planningProjectRequest?:ValueTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
		__typename?: boolean
}>;
	["PlanningProjectRequestEntity"]: AliasType<{
	id?:boolean,
	planningProjectId?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	planningProject?:ValueTypes["PlanningProjectEntity"],
	planningProjectRequestPlacements?:ValueTypes["PlanningProjectRequestPlacementEntity"],
	planningProjectCompetences?:ValueTypes["PlanningProjectCompetenceEntity"],
		__typename?: boolean
}>;
	["PlanningProjectEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	planningProjectRequests?:ValueTypes["PlanningProjectRequestEntity"],
		__typename?: boolean
}>;
	["PlanningProjectRequestPlacementEntity"]: AliasType<{
	id?:boolean,
	planningProjectRequestId?:boolean,
	startDate?:boolean,
	endDate?:boolean,
	status?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	planningProjectRequest?:ValueTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
		__typename?: boolean
}>;
	["PlanningProjectCompetencePlacementEntity"]: AliasType<{
	id?:boolean,
	planningProjectCompetenceId?:boolean,
	planningProjectRequestPlacementId?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	planningProjectCompetence?:ValueTypes["PlanningProjectCompetenceEntity"],
	planningProjectRequestPlacement?:ValueTypes["PlanningProjectRequestPlacementEntity"],
	planningCompetenceAssignments?:ValueTypes["PlanningProjectAssignmentEntity"],
		__typename?: boolean
}>;
	["PlanningProjectAssignmentEntity"]: AliasType<{
	id?:boolean,
	humanResourceId?:boolean,
	planningCompetencePlacementId?:boolean,
	comments?:boolean,
	startsAt?:boolean,
	endsAt?:boolean,
	hours?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	humanResource?:ValueTypes["PlanningHumanResourceEntity"],
	planningCompetencePlacement?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
		__typename?: boolean
}>;
	["PlanningHumanResourceEntity"]: AliasType<{
	id?:boolean,
	number?:boolean,
	resourceTypeId?:boolean,
	color?:boolean,
	firstName?:boolean,
	lastNamePrefix?:boolean,
	lastName?:boolean,
	email?:boolean,
	isNotProjectLeader?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["CargoPhaseEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	description?:boolean,
	date?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["CargoTypeEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	userId?:boolean,
	price?:boolean,
	unit?:boolean,
	colorCode?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["CargoStateEntity"]: AliasType<{
	id?:boolean,
	cargoId?:boolean,
	housePartGroupId?:boolean,
	divisionId?:boolean,
	value?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	cargo?:ValueTypes["CargoEntity"],
	housePartGroup?:ValueTypes["HousePartGroupEntity"],
		__typename?: boolean
}>;
	["HousePartEntity"]: AliasType<{
	id?:boolean,
	partId?:boolean,
	housePartGroupId?:boolean,
	supplierId?:boolean,
	description?:boolean,
	rootElement?:boolean,
	releaseTimeMins?:boolean,
	length?:boolean,
	width?:boolean,
	capacityFactor?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	housePartGroup?:ValueTypes["HousePartGroupEntity"],
		__typename?: boolean
}>;
	["TableLayoutCommentEntity"]: AliasType<{
	id?:boolean,
	taskId?:boolean,
	projectId?:boolean,
	userId?:boolean,
	comment?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["DrawOrderEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	kind?:boolean,
	drawOrderTypeId?:boolean,
	houseModelId?:boolean,
	salesEmployeeId?:boolean,
	drawerId?:boolean,
	description?:boolean,
	memo?:boolean,
	memoLong?:boolean,
	completedAt?:boolean,
	plannedAt?:boolean,
	plannedAtOrder?:boolean,
	totalHours?:boolean,
	totalHoursSpend?:boolean,
	percentageCompleted?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	drawOrderType?:ValueTypes["DrawOrderTypeEntity"],
	houseModel?:ValueTypes["HouseModelEntity"],
	salesEmployee?:ValueTypes["SalesEmployeeEntity"],
	drawer?:ValueTypes["DrawerEntity"],
		__typename?: boolean
}>;
	["DrawOrderTypeEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	condition?:boolean,
	defaultHours?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["HouseModelEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["SalesEmployeeEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	firstName?:boolean,
	condition?:boolean,
	isActive?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["DrawerEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	alias?:boolean,
	condition?:boolean,
	isHSB?:boolean,
	isBK?:boolean,
	isIT?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	drawerAbsents?:ValueTypes["DrawerAbsentEntity"],
		__typename?: boolean
}>;
	["DrawerAbsentEntity"]: AliasType<{
	id?:boolean,
	drawerId?:boolean,
	reason?:boolean,
	color?:boolean,
	date?:boolean,
	hours?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	drawer?:ValueTypes["DrawerEntity"],
		__typename?: boolean
}>;
	["LogicFieldValueEntity"]: AliasType<{
	id?:boolean,
	fieldId?:boolean,
	projectId?:boolean,
	value?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	isSyncUpdate?:boolean,
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["DateValueEntity"]: AliasType<{
	id?:boolean,
	dateId?:boolean,
	projectId?:boolean,
	userId?:boolean,
	value?:boolean,
	isActivated?:boolean,
	isCompleted?:boolean,
	isSynced?:boolean,
	syncSource?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	isSyncUpdate?:boolean,
	send_notification?:boolean,
	date?:ValueTypes["DateEntity"],
	project?:ValueTypes["ProjectEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["DateEntity"]: AliasType<{
	id?:boolean,
	dateGroupId?:boolean,
	description?:boolean,
	stackId?:boolean,
	iconId?:boolean,
	dateFormat?:boolean,
	colorCode?:boolean,
	orderId?:boolean,
	visibleOnDatePassed?:boolean,
	isOptional?:boolean,
	visibleOnConstructionPlanning?:boolean,
	phaseSectionMarkId?:boolean,
	notificationTemplateId?:boolean,
	drawOrderTypeId?:boolean,
	isSynced?:boolean,
	hash?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	dateGroup?:ValueTypes["DateGroupEntity"],
	dateValues?:ValueTypes["DateValueEntity"],
		__typename?: boolean
}>;
	["DateGroupEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	label?:boolean,
	hasResponsible?:boolean,
	hasComments?:boolean,
	visibleOnConstructionPlanning?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	dates?:ValueTypes["DateEntity"],
	logicFields?:ValueTypes["LogicFieldEntity"],
	comments?:ValueTypes["DateGroupCommentEntity"],
	responsibles?:ValueTypes["DateGroupResponsibleEntity"],
		__typename?: boolean
}>;
	["LogicFieldEntity"]: AliasType<{
	id?:boolean,
	fieldId?:boolean,
	fieldName?:boolean,
	dateGroupId?:boolean,
	conditionDescription?:boolean,
	expression?:boolean,
	iconId?:boolean,
	colorCode?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	dateGroup?:ValueTypes["DateGroupEntity"],
		__typename?: boolean
}>;
	["DateGroupCommentEntity"]: AliasType<{
	id?:boolean,
	dateGroupId?:boolean,
	projectId?:boolean,
	userId?:boolean,
	value?:boolean,
	referenceId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	dateGroup?:ValueTypes["DateGroupEntity"],
	project?:ValueTypes["ProjectEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["DateGroupResponsibleEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	projectId?:boolean,
	dateGroupId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	project?:ValueTypes["ProjectEntity"],
	dateGroup?:ValueTypes["DateGroupEntity"],
		__typename?: boolean
}>;
	["ProjectCachedValueEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	dateValues?:boolean,
	projectLeader?:boolean,
	salesEmployee?:boolean,
	dateGroupResponsibles?:boolean,
	buyAdvisor?:boolean,
	projectMentor?:boolean,
	dateGroupComments?:boolean,
	logicFieldValues?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["DateCacheEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	values?:boolean,
	comments?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["YearPlanningModificationEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	projectId?:boolean,
	oldWeek?:boolean,
	newWeek?:boolean,
	notes?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["SyncCronEntity"]: AliasType<{
	id?:boolean,
	cron?:boolean,
		__typename?: boolean
}>;
	["MailSchedulerEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	cron?:boolean,
	sourceType?:boolean,
	sourceTable?:boolean,
	condition?:boolean,
	mail?:boolean,
	mailCC?:boolean,
	subject?:boolean,
	template?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ProjectPartnerEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	partnerId?:boolean,
	mode?:boolean,
	isContactAllowed?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["PartnerEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	contentId?:boolean,
	contactUserId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	contactUser?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["DocumentMetaEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	mimeType?:boolean,
	projectId?:boolean,
	trackingId?:boolean,
	userId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["EntityAdvancementEntity"]: AliasType<{
	id?:boolean,
	entityType?:boolean,
	entityId?:boolean,
	userId?:boolean,
	comment?:boolean,
	date?:boolean,
	isCompleted?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	workAction?:ValueTypes["WorkActionEntity"],
		__typename?: boolean
}>;
	["WorkActionEntity"]: AliasType<{
	title?:boolean,
	description?:boolean,
	allowEmptyAdvancement?:boolean,
	disableImageUpload?:boolean,
	responsibleDateGroupId?:boolean,
	advancementApplyDateId?:boolean,
	id?:boolean,
	entityType?:boolean,
	userId?:boolean,
	projectId?:boolean,
	workActionCategoryId?:boolean,
	assignedUserId?:boolean,
	date?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	project?:ValueTypes["ProjectEntity"],
	workActionCategory?:ValueTypes["WorkActionCategoryEntity"],
	assignedUser?:ValueTypes["UserEntity"],
	entityAdvancements?:ValueTypes["EntityAdvancementEntity"],
	entityImages?:ValueTypes["EntityImageEntity"],
		__typename?: boolean
}>;
	["WorkActionCategoryEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	workActions?:ValueTypes["WorkActionEntity"],
		__typename?: boolean
}>;
	["EntityImageEntity"]: AliasType<{
	id?:boolean,
	entityType?:boolean,
	entityId?:boolean,
	userId?:boolean,
	documentMetaId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
	workAction?:ValueTypes["WorkActionEntity"],
		__typename?: boolean
}>;
	["CustomerQuestionEntity"]: AliasType<{
	id?:boolean,
	message?:boolean,
	kind?:boolean,
	projectId?:boolean,
	customerName?:boolean,
	city?:boolean,
	telephone?:boolean,
	space?:boolean,
	emailAddress?:boolean,
	internalComment?:boolean,
	imageIds?:boolean,
	completedAt?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["AuditCategoryEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	auditQuestions?:ValueTypes["AuditQuestionEntity"],
		__typename?: boolean
}>;
	["AuditQuestionEntity"]: AliasType<{
	id?:boolean,
	auditCategoryId?:boolean,
	description?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	auditCategory?:ValueTypes["AuditCategoryEntity"],
	auditQuestionCostCategories?:ValueTypes["AuditQuestionCostCategoryEntity"],
		__typename?: boolean
}>;
	["AuditQuestionCostCategoryEntity"]: AliasType<{
	id?:boolean,
	auditQuestionId?:boolean,
	costCategoryId?:boolean,
	auditQuestion?:ValueTypes["AuditQuestionEntity"],
	costCategory?:ValueTypes["CostCategoryEntity"],
		__typename?: boolean
}>;
	["CostCategoryEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	group?:boolean,
	condition?:boolean,
	isChecklist?:boolean,
	hasImages?:boolean,
	checklistOrder?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	auditQuestionCostCategories?:ValueTypes["AuditQuestionCostCategoryEntity"],
		__typename?: boolean
}>;
	["AuditReportItemImageEntity"]: AliasType<{
	id?:boolean,
	auditReportItemId?:boolean,
	documentMetaId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	auditReportItem?:ValueTypes["AuditReportItemEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["AuditReportItemEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	auditReportId?:boolean,
	auditQuestionId?:boolean,
	state?:boolean,
	comment?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	auditReport?:ValueTypes["AuditReportEntity"],
	auditQuestion?:ValueTypes["AuditQuestionEntity"],
	auditReportItemImages?:ValueTypes["AuditReportItemImageEntity"],
		__typename?: boolean
}>;
	["AuditReportEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	projectId?:boolean,
	costCategoryId?:boolean,
	signatureDocumentMetaId?:boolean,
	comment?:boolean,
	inspectedAt?:boolean,
	inspectedAtTime?:boolean,
	lockedAt?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	project?:ValueTypes["ProjectEntity"],
	costCategory?:ValueTypes["CostCategoryEntity"],
	signatureDocumentMeta?:ValueTypes["DocumentMetaEntity"],
	auditReportItems?:ValueTypes["AuditReportItemEntity"],
		__typename?: boolean
}>;
	["AuditChecklistImageEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	costCategoryId?:boolean,
	projectId?:boolean,
	documentMetaId?:boolean,
	comment?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	costCategory?:ValueTypes["CostCategoryEntity"],
	project?:ValueTypes["ProjectEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["InvoiceAttachmentEntity"]: AliasType<{
	id?:boolean,
	metacomOrderId?:boolean,
	documentMetaId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["EmployeeDocumentEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	relationId?:boolean,
	employeeId?:boolean,
	documentMetaId?:boolean,
	isInternal?:boolean,
	comment?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["WorkActionTemplateGroupEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	workActionTemplates?:ValueTypes["WorkActionTemplateEntity"],
		__typename?: boolean
}>;
	["WorkActionTemplateEntity"]: AliasType<{
	title?:boolean,
	description?:boolean,
	allowEmptyAdvancement?:boolean,
	disableImageUpload?:boolean,
	responsibleDateGroupId?:boolean,
	advancementApplyDateId?:boolean,
	id?:boolean,
	workActionTemplateGroupId?:boolean,
	workActionCategoryId?:boolean,
	targetDateOffset?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	workActionTemplateGroup?:ValueTypes["WorkActionTemplateGroupEntity"],
	workActionCategory?:ValueTypes["WorkActionCategoryEntity"],
		__typename?: boolean
}>;
	["ProjectCostInfoEntity"]: AliasType<{
	id?:boolean,
	appProjectCostId?:boolean,
	conceptUserId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	conceptUser?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["BlockedCalendarWeekEntity"]: AliasType<{
	id?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["MaintenanceReportEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	data?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ConfigurationEntity"]: AliasType<{
	id?:boolean,
	data?:boolean,
		__typename?: boolean
}>;
	["ServiceMeterEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	unit?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["DeliveryListMeterEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	meterId?:boolean,
	value?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	meter?:ValueTypes["ServiceMeterEntity"],
		__typename?: boolean
}>;
	["ServiceSpaceEntity"]: AliasType<{
	id?:boolean,
	code?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ServiceTypeEntity"]: AliasType<{
	id?:boolean,
	code?:boolean,
	description?:boolean,
	storeInSystem?:boolean,
	isStandard?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["DeliveryListPointImageEntity"]: AliasType<{
	id?:boolean,
	pointId?:boolean,
	documentMetaId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	point?:ValueTypes["DeliveryListPointEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["DeliveryListPointEntity"]: AliasType<{
	id?:boolean,
	code?:boolean,
	projectId?:boolean,
	primaryCategoryId?:boolean,
	secondaryCategoryId?:boolean,
	serviceTypeId?:boolean,
	serviceSpaceId?:boolean,
	serviceSpaceIds?:boolean,
	servicePriorityId?:boolean,
	assignedUserId?:boolean,
	serviceTicketId?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	serviceType?:ValueTypes["ServiceTypeEntity"],
	serviceSpace?:ValueTypes["ServiceSpaceEntity"],
	servicePriority?:ValueTypes["ServicePriorityEntity"],
	assignedUser?:ValueTypes["UserEntity"],
	images?:ValueTypes["DeliveryListPointImageEntity"],
		__typename?: boolean
}>;
	["ServicePriorityEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	offsetDays?:boolean,
	isStandard?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["DeliveryListSignatureEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	kind?:boolean,
	documentMetaId?:boolean,
	signDate?:boolean,
	signLocation?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["DeliveryListEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	assignedUserId?:boolean,
	hiredAgent?:boolean,
	keyAmount?:boolean,
	isDeliveredAt?:boolean,
	houseDocumentMetaId?:boolean,
	isFinal?:boolean,
	isFinalAt?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	assignedUser?:ValueTypes["UserEntity"],
	houseDocumentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["SupplyInspectionReportAnswerEntity"]: AliasType<{
	id?:boolean,
	supplyInspectionQuestionId?:boolean,
	supplyInspectionReportId?:boolean,
	status?:boolean,
	explanation?:boolean,
	imageDocumentMetaIds?:boolean,
	supplyInspectionReport?:ValueTypes["SupplyInspectionReportEntity"],
		__typename?: boolean
}>;
	["SupplyInspectionReportEntity"]: AliasType<{
	id?:boolean,
	timestamp?:boolean,
	departmentId?:boolean,
	executedByUserId?:boolean,
	signatureDocumentMetaId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	department?:ValueTypes["DepartmentEntity"],
	executedByUser?:ValueTypes["UserEntity"],
	supplyInspectionReportAnswers?:ValueTypes["SupplyInspectionReportAnswerEntity"],
		__typename?: boolean
}>;
	["DepartmentEntity"]: AliasType<{
	id?:boolean,
	departmentId?:boolean,
	description?:boolean,
	state?:boolean,
	isOrderDepartment?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	supplyInspectionReports?:ValueTypes["SupplyInspectionReportEntity"],
		__typename?: boolean
}>;
	["DrawOrderDescriptionEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["GreetingEntity"]: AliasType<{
	id?:boolean,
	html?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["HourDeclarationTypeEntity"]: AliasType<{
	id?:boolean,
	companyId?:boolean,
	administrationType?:boolean,
	kind?:boolean,
	kindDescription?:boolean,
	description?:boolean,
	hasActivities?:boolean,
	isExternal?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["HourDeclarationActivityEntity"]: AliasType<{
	id?:boolean,
	companyId?:boolean,
	administrationType?:boolean,
	description?:boolean,
	syncVersion?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["HourDeclarationEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	hourDeclarationTypeId?:boolean,
	projectId?:boolean,
	hourDeclarationActivityId?:boolean,
	priceAgreementId?:boolean,
	storedDocumentId?:boolean,
	date?:boolean,
	description?:boolean,
	amount?:boolean,
	baseHours?:boolean,
	savingHours?:boolean,
	overTimeHours?:boolean,
	travelHours?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	hourDeclarationType?:ValueTypes["HourDeclarationTypeEntity"],
	project?:ValueTypes["ProjectEntity"],
	hourDeclarationActivity?:ValueTypes["HourDeclarationActivityEntity"],
		__typename?: boolean
}>;
	["HourDeclarationApprovalRequestEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	period?:boolean,
	createdAt?:boolean,
		__typename?: boolean
}>;
	["InstallationReportCommentEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	stepId?:boolean,
	userId?:boolean,
	message?:boolean,
	date?:boolean,
	performerName?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["InstallationReportDescriptionEntity"]: AliasType<{
	id?:boolean,
	stepId?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["InstallationReportFinalEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	userId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["InstallationReportMeasurementTemplateEntity"]: AliasType<{
	id?:boolean,
	measureTableId?:boolean,
	measureId?:boolean,
	defaultFields?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["InstallationReportMeasurementEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	measureTableId?:boolean,
	measureId?:boolean,
	fields?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["InstallationReportQuestionGroupEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	stepId?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	questions?:ValueTypes["InstallationReportQuestionEntity"],
		__typename?: boolean
}>;
	["InstallationReportQuestionEntity"]: AliasType<{
	id?:boolean,
	questionGroupId?:boolean,
	content?:boolean,
	tooltip?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	questionGroup?:ValueTypes["InstallationReportQuestionGroupEntity"],
		__typename?: boolean
}>;
	["InstallationReportQuestionAnswerEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	questionId?:boolean,
	content?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	question?:ValueTypes["InstallationReportQuestionEntity"],
		__typename?: boolean
}>;
	["LegacyProjectEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	houseType?:boolean,
	regionId?:boolean,
	buildingStreet?:boolean,
	buildingCity?:boolean,
	buildingZipCode?:boolean,
	deliveryDate?:boolean,
	customerEmail?:boolean,
	syncVersion?:boolean,
	syncSource?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["PicklistCommentEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	picklistDefinitionId?:boolean,
	userId?:boolean,
	text?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	picklistDefinition?:ValueTypes["PicklistDefinitionEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["PicklistElementStateEntity"]: AliasType<{
	id?:boolean,
	elementId?:boolean,
	projectId?:boolean,
	picklistDefinitionId?:boolean,
	state?:boolean,
	pickedByUserId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	picklistDefinition?:ValueTypes["PicklistDefinitionEntity"],
	pickedByUser?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["PlanningProjectItemEntity"]: AliasType<{
	id?:boolean,
	parentId?:boolean,
	dimension?:boolean,
	projectId?:boolean,
	competenceId?:boolean,
	competenceIndex?:boolean,
	competenceNumber?:boolean,
	competenceStartsAt?:boolean,
	competenceEndsAt?:boolean,
	hours?:boolean,
	humanResourceId?:boolean,
	humanResourceNumber?:boolean,
	humanResourceStartsAt?:boolean,
	humanResourceEndsAt?:boolean,
	comments?:boolean,
	status?:boolean,
	version?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
	competence?:ValueTypes["PlanningCompetenceEntity"],
	humanResource?:ValueTypes["PlanningHumanResourceEntity"],
		__typename?: boolean
}>;
	["ProjectCargoStateEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	project?:ValueTypes["ProjectEntity"],
		__typename?: boolean
}>;
	["ProjectPaymentEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	userId?:boolean,
	description?:boolean,
	payedAt?:boolean,
	amount?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["QualityAssuranceEntity"]: AliasType<{
	id?:boolean,
	projectId?:boolean,
	data?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ServiceMapColorEntity"]: AliasType<{
	id?:boolean,
	days?:boolean,
	color?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ServicePlanningStageEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	colorCode?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["ServicePlanningEventEntity"]: AliasType<{
	id?:boolean,
	legacyProjectId?:boolean,
	stageId?:boolean,
	userId?:boolean,
	serviceTicketIds?:boolean,
	memo?:boolean,
	duration?:boolean,
	durationUnit?:boolean,
	durationPlanned?:boolean,
	durationSpend?:boolean,
	isFinalized?:boolean,
	startDate?:boolean,
	endDate?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	assignments?:ValueTypes["ServicePlanningAssignmentEntity"],
	legacyProject?:ValueTypes["LegacyProjectEntity"],
	stage?:ValueTypes["ServicePlanningStageEntity"],
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["ServicePlanningAssignmentEntity"]: AliasType<{
	id?:boolean,
	eventId?:boolean,
	resourceId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	event?:ValueTypes["ServicePlanningEventEntity"],
	resource?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["ServicePresetEntity"]: AliasType<{
	id?:boolean,
	primaryCategoryId?:boolean,
	secondaryCategoryId?:boolean,
	code?:boolean,
	description?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["SupplierDocumentEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	informedUserId?:boolean,
	informedContactTypes?:boolean,
	projectId?:boolean,
	documentMetaId?:boolean,
	contextId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
	informedUser?:ValueTypes["UserEntity"],
	project?:ValueTypes["ProjectEntity"],
	documentMeta?:ValueTypes["DocumentMetaEntity"],
		__typename?: boolean
}>;
	["SupplyInspectionQuestionEntity"]: AliasType<{
	id?:boolean,
	supplyInspectionQuestionGroupId?:boolean,
	departmentIds?:boolean,
	description?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	supplyInspectionQuestionGroup?:ValueTypes["SupplyInspectionQuestionGroupEntity"],
		__typename?: boolean
}>;
	["SupplyInspectionQuestionGroupEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	orderId?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	supplyInspectionQuestions?:ValueTypes["SupplyInspectionQuestionEntity"],
		__typename?: boolean
}>;
	["UserPasswordResetEntity"]: AliasType<{
	id?:boolean,
	userId?:boolean,
	expiresAt?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
	user?:ValueTypes["UserEntity"],
		__typename?: boolean
}>;
	["YearPlanningLineEntity"]: AliasType<{
	id?:boolean,
	description?:boolean,
	weeks?:boolean,
	colorCode?:boolean,
	visibleOnConstructionPlanning?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["InvoicePaymentBatchEntityEntity"]: AliasType<{
	id?:boolean,
	batch?:boolean,
	document?:boolean,
	name?:boolean,
	date?:boolean,
	amount?:boolean,
	amountBank?:boolean,
	companyId?:boolean,
	companyName?:boolean,
	relationId?:boolean,
	relationName?:boolean,
	createdAt?:boolean,
	updatedAt?:boolean,
		__typename?: boolean
}>;
	["AppPhaseContactEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	phase_id?:boolean,
	type?:boolean,
	title?:boolean,
	receive_notifications?:boolean,
	phase?:ValueTypes["AppPhaseEntity"],
		__typename?: boolean
}>;
	["AppPhaseEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	name?:boolean,
	order?:boolean,
	percentage?:boolean,
	is_visible_transferred?:boolean,
	start_project_date_type?:boolean,
	end_project_date_type?:boolean,
	days?:boolean,
	sections?:ValueTypes["AppPhaseSectionEntity"],
	contacts?:ValueTypes["AppPhaseContactEntity"],
		__typename?: boolean
}>;
	["AppPhaseSectionEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	phase_id?:boolean,
	more_info_content_id?:boolean,
	name?:boolean,
	order?:boolean,
	percentage?:boolean,
	phase?:ValueTypes["AppPhaseEntity"],
	marks?:ValueTypes["AppPhaseSectionMarkEntity"],
		__typename?: boolean
}>;
	["AppPhaseSectionMarkEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	phase_section_id?:boolean,
	document_type_id?:boolean,
	more_info_content_id?:boolean,
	notification_template_id?:boolean,
	project_field_group_id?:boolean,
	project_date_type?:boolean,
	responsible?:boolean,
	name?:boolean,
	order?:boolean,
	percentage?:boolean,
	is_milestone?:boolean,
	is_standard?:boolean,
	date_prompt?:boolean,
	duration_days?:boolean,
	timeline_origin_date_type?:boolean,
	timeline_competence_number?:boolean,
	timeline_origin_diff_days?:boolean,
	section?:ValueTypes["AppPhaseSectionEntity"],
	notificationTemplate?:ValueTypes["AppNotificationTemplateEntity"],
		__typename?: boolean
}>;
	["AppNotificationTemplateEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	content_id?:boolean,
	subject?:boolean,
	body?:boolean,
		__typename?: boolean
}>;
	["AppProjectDateEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	value?:boolean,
	type?:boolean,
		__typename?: boolean
}>;
	["AppNotificationScheduleEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	phase_section_mark_id?:boolean,
	phase_section_mark_cancel_id?:boolean,
	notification_template_id?:boolean,
	days_offset?:boolean,
	time_of_day?:boolean,
	mark?:ValueTypes["AppPhaseSectionMarkEntity"],
	markCancel?:ValueTypes["AppPhaseSectionMarkEntity"],
	notification?:ValueTypes["AppNotificationTemplateEntity"],
		__typename?: boolean
}>;
	["AppPhaseSectionMarkValueEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	phase_section_mark_id?:boolean,
	completed_user_id?:boolean,
	send_notification?:boolean,
	activated?:boolean,
	completed?:boolean,
	completed_at?:boolean,
		__typename?: boolean
}>;
	["AppInviteEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	email?:boolean,
	login?:ValueTypes["AppLoginEntity"],
		__typename?: boolean
}>;
	["AppLoginEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	relation_id?:boolean,
	project_id?:boolean,
	transferred_at?:boolean,
	email?:boolean,
	role_id?:boolean,
	user?:ValueTypes["AppUserEntity"],
	invite?:ValueTypes["AppInviteEntity"],
		__typename?: boolean
}>;
	["AppUserEntity"]: AliasType<{
	id?:boolean,
	project_id?:boolean,
	relation_id?:boolean,
	email?:boolean,
	telephone?:boolean,
	facebook_identity?:boolean,
	google_identity?:boolean,
	password?:boolean,
	first_name?:boolean,
	last_name_prefix?:boolean,
	last_name?:boolean,
	created_at?:boolean,
	updated_at?:boolean,
	project?:ValueTypes["AppProjectEntity"],
	login?:ValueTypes["AppLoginEntity"],
	notifications?:ValueTypes["AppNotificationEntity"],
		__typename?: boolean
}>;
	["AppProjectEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	contract_sum?:boolean,
	users?:ValueTypes["AppUserEntity"],
		__typename?: boolean
}>;
	["AppNotificationEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	type?:boolean,
	notifiable_id?:boolean,
	notifiable_type?:boolean,
	data?:boolean,
	read_at?:boolean,
	user?:ValueTypes["AppUserEntity"],
		__typename?: boolean
}>;
	["AppProjectCostEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	user_id?:boolean,
	approved_user_id?:boolean,
	declined_user_id?:boolean,
	project_id?:boolean,
	parent_id?:boolean,
	title?:boolean,
	memo?:boolean,
	value?:boolean,
	approved_at?:boolean,
	declined_at?:boolean,
	requested_at?:boolean,
	user?:ValueTypes["AppUserEntity"],
	approvedUser?:ValueTypes["AppUserEntity"],
	declinedUser?:ValueTypes["AppUserEntity"],
	parent?:ValueTypes["AppProjectCostEntity"],
	project?:ValueTypes["AppProjectEntity"],
	children?:ValueTypes["AppProjectCostEntity"],
		__typename?: boolean
}>;
	["AppProjectContactEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	relation_id?:boolean,
	type?:boolean,
		__typename?: boolean
}>;
	["AppBuildingWeekEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	isBlocked?:boolean,
	syncVersion?:boolean,
		__typename?: boolean
}>;
	["AppContentEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	title?:boolean,
	html?:boolean,
		__typename?: boolean
}>;
	["AppConversationMessagePresetEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	title?:boolean,
	message?:boolean,
		__typename?: boolean
}>;
	["AppDocumentSignatureEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	user_id?:boolean,
	document_id?:boolean,
	payload?:boolean,
	user?:ValueTypes["AppUserEntity"],
		__typename?: boolean
}>;
	["AppDocumentTypeEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	name?:boolean,
	description?:boolean,
	color?:boolean,
	document_kind?:boolean,
	order_id?:boolean,
	special_access?:boolean,
	project_contact_type?:boolean,
		__typename?: boolean
}>;
	["AppDocumentEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	user_id?:boolean,
	document_type_id?:boolean,
	document_kind?:boolean,
	description?:boolean,
	confidential?:boolean,
	mime_type?:boolean,
	extension?:boolean,
	storage_id?:boolean,
	user?:ValueTypes["AppUserEntity"],
	documentType?:ValueTypes["AppDocumentTypeEntity"],
	signature?:ValueTypes["AppDocumentSignatureEntity"],
		__typename?: boolean
}>;
	["AppNotificationScheduleResourceEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_id?:boolean,
	notification_schedule_id?:boolean,
	schedule_at?:boolean,
	schedule?:ValueTypes["AppNotificationScheduleEntity"],
		__typename?: boolean
}>;
	["AppPlanCompetenceEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	number?:boolean,
	description?:boolean,
	index?:boolean,
	color?:boolean,
		__typename?: boolean
}>;
	["AppPlanCompetenceSettingEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	plan_competence_id?:boolean,
	content_id?:boolean,
	is_visible?:boolean,
	name?:boolean,
	plan_competence?:ValueTypes["AppPlanCompetenceEntity"],
		__typename?: boolean
}>;
	["AppProjectCostPresetEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	title?:boolean,
	description?:boolean,
		__typename?: boolean
}>;
	["AppProjectFieldSheetEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_contact_type?:boolean,
	name?:boolean,
	order?:boolean,
	groups?:ValueTypes["AppProjectFieldGroupEntity"],
		__typename?: boolean
}>;
	["AppProjectFieldGroupEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_field_sheet_id?:boolean,
	name?:boolean,
	order?:boolean,
	sheet?:ValueTypes["AppProjectFieldSheetEntity"],
	assignments?:ValueTypes["AppProjectFieldGroupAssignmentEntity"],
		__typename?: boolean
}>;
	["AppProjectFieldGroupAssignmentEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_field_group_id?:boolean,
	project_field_type?:boolean,
	project_field_copy_type?:boolean,
	value_type?:boolean,
	is_required?:boolean,
	order?:boolean,
	tooltip?:boolean,
	group?:ValueTypes["AppProjectFieldGroupEntity"],
	values?:ValueTypes["AppProjectFieldGroupAssignmentValueEntity"],
		__typename?: boolean
}>;
	["AppProjectFieldGroupAssignmentValueEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	project_field_group_assignment_id?:boolean,
	value?:boolean,
	assignment?:ValueTypes["AppProjectFieldGroupAssignmentEntity"],
		__typename?: boolean
}>;
	["AppProjectFieldEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	value?:boolean,
	project_id?:boolean,
	type?:boolean,
		__typename?: boolean
}>;
	["AppServiceCategoryEntity"]: AliasType<{
	created_at?:boolean,
	updated_at?:boolean,
	id?:boolean,
	service_category_id?:boolean,
	name?:boolean,
	service_category?:ValueTypes["AppServiceCategoryEntity"],
	service_categories?:ValueTypes["AppServiceCategoryEntity"],
		__typename?: boolean
}>;
	["AppTextEntity"]: AliasType<{
	id?:boolean,
	value?:boolean,
	created_at?:boolean,
	updated_at?:boolean,
		__typename?: boolean
}>;
	["AppRelationEntity"]: AliasType<{
	id?:boolean,
	name?:boolean,
	email?:boolean,
	is_blacklisted?:boolean,
	sync_version?:boolean,
	created_at?:boolean,
	updated_at?:boolean,
		__typename?: boolean
}>;
	["bedrijvenMtcEntity"]: AliasType<{
	_rowid?:boolean,
	bedrijf?:boolean,
	bedrijf_oms?:boolean,
	bedrijf_straat?:boolean,
	bedrijf_huisnummer?:boolean,
	bedrijf_postcode?:boolean,
	bedrijf_plaats?:boolean,
	bedrijf_gln?:boolean,
	bedrijf_btw?:boolean,
	bedrijf_email?:boolean,
	bedrijf_email_adm?:boolean,
		__typename?: boolean
}>;
	["Mutation"]: AliasType<{
GrantEntityCreateOne?: [{	input?:ValueTypes["InputGrantEntity"] | null},ValueTypes["GrantEntity"]],
GrantEntityUpdateOne?: [{	input?:ValueTypes["InputGrantEntity"] | null},ValueTypes["GrantEntity"]],
GrantEntityUpdateMany?: [{	input?:(ValueTypes["InputGrantEntity"] | undefined | null)[]},ValueTypes["GrantEntity"]],
GrantEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteGrantEntity"] | null},boolean],
RoleGrantConfigEntityCreateOne?: [{	input?:ValueTypes["InputRoleGrantConfigEntity"] | null},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityUpdateOne?: [{	input?:ValueTypes["InputRoleGrantConfigEntity"] | null},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityUpdateMany?: [{	input?:(ValueTypes["InputRoleGrantConfigEntity"] | undefined | null)[]},ValueTypes["RoleGrantConfigEntity"]],
RoleGrantConfigEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteRoleGrantConfigEntity"] | null},boolean],
RoleDocumentGrantEntityCreateOne?: [{	input?:ValueTypes["InputRoleDocumentGrantEntity"] | null},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityUpdateOne?: [{	input?:ValueTypes["InputRoleDocumentGrantEntity"] | null},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityUpdateMany?: [{	input?:(ValueTypes["InputRoleDocumentGrantEntity"] | undefined | null)[]},ValueTypes["RoleDocumentGrantEntity"]],
RoleDocumentGrantEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteRoleDocumentGrantEntity"] | null},boolean],
RoleEntityCreateOne?: [{	input?:ValueTypes["InputRoleEntity"] | null},ValueTypes["RoleEntity"]],
RoleEntityUpdateOne?: [{	input?:ValueTypes["InputRoleEntity"] | null},ValueTypes["RoleEntity"]],
RoleEntityUpdateMany?: [{	input?:(ValueTypes["InputRoleEntity"] | undefined | null)[]},ValueTypes["RoleEntity"]],
RoleEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteRoleEntity"] | null},boolean],
UserGrantConfigEntityCreateOne?: [{	input?:ValueTypes["InputUserGrantConfigEntity"] | null},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityUpdateOne?: [{	input?:ValueTypes["InputUserGrantConfigEntity"] | null},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityUpdateMany?: [{	input?:(ValueTypes["InputUserGrantConfigEntity"] | undefined | null)[]},ValueTypes["UserGrantConfigEntity"]],
UserGrantConfigEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserGrantConfigEntity"] | null},boolean],
UserNotificationSubscriptionEntityCreateOne?: [{	input?:ValueTypes["InputUserNotificationSubscriptionEntity"] | null},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityUpdateOne?: [{	input?:ValueTypes["InputUserNotificationSubscriptionEntity"] | null},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityUpdateMany?: [{	input?:(ValueTypes["InputUserNotificationSubscriptionEntity"] | undefined | null)[]},ValueTypes["UserNotificationSubscriptionEntity"]],
UserNotificationSubscriptionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserNotificationSubscriptionEntity"] | null},boolean],
UserNotificationEntityCreateOne?: [{	input?:ValueTypes["InputUserNotificationEntity"] | null},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityUpdateOne?: [{	input?:ValueTypes["InputUserNotificationEntity"] | null},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityUpdateMany?: [{	input?:(ValueTypes["InputUserNotificationEntity"] | undefined | null)[]},ValueTypes["UserNotificationEntity"]],
UserNotificationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserNotificationEntity"] | null},boolean],
UserTemporaryAccessTokenEntityEntityCreateOne?: [{	input?:ValueTypes["InputUserTemporaryAccessTokenEntityEntity"] | null},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityUpdateOne?: [{	input?:ValueTypes["InputUserTemporaryAccessTokenEntityEntity"] | null},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityUpdateMany?: [{	input?:(ValueTypes["InputUserTemporaryAccessTokenEntityEntity"] | undefined | null)[]},ValueTypes["UserTemporaryAccessTokenEntityEntity"]],
UserTemporaryAccessTokenEntityEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserTemporaryAccessTokenEntityEntity"] | null},boolean],
UserEntityCreateOne?: [{	input?:ValueTypes["InputUserEntity"] | null},ValueTypes["UserEntity"]],
UserEntityUpdateOne?: [{	input?:ValueTypes["InputUserEntity"] | null},ValueTypes["UserEntity"]],
UserEntityUpdateMany?: [{	input?:(ValueTypes["InputUserEntity"] | undefined | null)[]},ValueTypes["UserEntity"]],
UserEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserEntity"] | null},boolean],
PicklistDefinitionEntityCreateOne?: [{	input?:ValueTypes["InputPicklistDefinitionEntity"] | null},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistDefinitionEntity"] | null},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistDefinitionEntity"] | undefined | null)[]},ValueTypes["PicklistDefinitionEntity"]],
PicklistDefinitionEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistDefinitionEntity"] | null},boolean],
PicklistStateEntityCreateOne?: [{	input?:ValueTypes["InputPicklistStateEntity"] | null},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistStateEntity"] | null},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistStateEntity"] | undefined | null)[]},ValueTypes["PicklistStateEntity"]],
PicklistStateEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistStateEntity"] | null},boolean],
HousePartGroupEntityCreateOne?: [{	input?:ValueTypes["InputHousePartGroupEntity"] | null},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityUpdateOne?: [{	input?:ValueTypes["InputHousePartGroupEntity"] | null},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputHousePartGroupEntity"] | undefined | null)[]},ValueTypes["HousePartGroupEntity"]],
HousePartGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHousePartGroupEntity"] | null},boolean],
HousePartEntityCreateOne?: [{	input?:ValueTypes["InputHousePartEntity"] | null},ValueTypes["HousePartEntity"]],
HousePartEntityUpdateOne?: [{	input?:ValueTypes["InputHousePartEntity"] | null},ValueTypes["HousePartEntity"]],
HousePartEntityUpdateMany?: [{	input?:(ValueTypes["InputHousePartEntity"] | undefined | null)[]},ValueTypes["HousePartEntity"]],
HousePartEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHousePartEntity"] | null},boolean],
CargoPhaseEntityCreateOne?: [{	input?:ValueTypes["InputCargoPhaseEntity"] | null},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityUpdateOne?: [{	input?:ValueTypes["InputCargoPhaseEntity"] | null},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityUpdateMany?: [{	input?:(ValueTypes["InputCargoPhaseEntity"] | undefined | null)[]},ValueTypes["CargoPhaseEntity"]],
CargoPhaseEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCargoPhaseEntity"] | null},boolean],
CargoTypeEntityCreateOne?: [{	input?:ValueTypes["InputCargoTypeEntity"] | null},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityUpdateOne?: [{	input?:ValueTypes["InputCargoTypeEntity"] | null},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityUpdateMany?: [{	input?:(ValueTypes["InputCargoTypeEntity"] | undefined | null)[]},ValueTypes["CargoTypeEntity"]],
CargoTypeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCargoTypeEntity"] | null},boolean],
PlanningHumanResourceEntityCreateOne?: [{	input?:ValueTypes["InputPlanningHumanResourceEntity"] | null},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningHumanResourceEntity"] | null},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningHumanResourceEntity"] | undefined | null)[]},ValueTypes["PlanningHumanResourceEntity"]],
PlanningHumanResourceEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningHumanResourceEntity"] | null},boolean],
PlanningProjectAssignmentEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectAssignmentEntity"] | null},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectAssignmentEntity"] | null},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectAssignmentEntity"] | undefined | null)[]},ValueTypes["PlanningProjectAssignmentEntity"]],
PlanningProjectAssignmentEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectAssignmentEntity"] | null},boolean],
PlanningProjectCompetencePlacementEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectCompetencePlacementEntity"] | null},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectCompetencePlacementEntity"] | null},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectCompetencePlacementEntity"] | undefined | null)[]},ValueTypes["PlanningProjectCompetencePlacementEntity"]],
PlanningProjectCompetencePlacementEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectCompetencePlacementEntity"] | null},boolean],
PlanningProjectRequestPlacementEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectRequestPlacementEntity"] | null},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectRequestPlacementEntity"] | null},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectRequestPlacementEntity"] | undefined | null)[]},ValueTypes["PlanningProjectRequestPlacementEntity"]],
PlanningProjectRequestPlacementEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectRequestPlacementEntity"] | null},boolean],
PlanningProjectEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectEntity"] | null},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectEntity"] | null},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectEntity"] | undefined | null)[]},ValueTypes["PlanningProjectEntity"]],
PlanningProjectEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectEntity"] | null},boolean],
PlanningProjectRequestEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectRequestEntity"] | null},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectRequestEntity"] | null},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectRequestEntity"] | undefined | null)[]},ValueTypes["PlanningProjectRequestEntity"]],
PlanningProjectRequestEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectRequestEntity"] | null},boolean],
PlanningProjectCompetenceEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectCompetenceEntity"] | null},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectCompetenceEntity"] | null},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectCompetenceEntity"] | undefined | null)[]},ValueTypes["PlanningProjectCompetenceEntity"]],
PlanningProjectCompetenceEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectCompetenceEntity"] | null},boolean],
PlanningCompetenceEntityCreateOne?: [{	input?:ValueTypes["InputPlanningCompetenceEntity"] | null},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningCompetenceEntity"] | null},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningCompetenceEntity"] | undefined | null)[]},ValueTypes["PlanningCompetenceEntity"]],
PlanningCompetenceEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningCompetenceEntity"] | null},boolean],
CargoStateEntityCreateOne?: [{	input?:ValueTypes["InputCargoStateEntity"] | null},ValueTypes["CargoStateEntity"]],
CargoStateEntityUpdateOne?: [{	input?:ValueTypes["InputCargoStateEntity"] | null},ValueTypes["CargoStateEntity"]],
CargoStateEntityUpdateMany?: [{	input?:(ValueTypes["InputCargoStateEntity"] | undefined | null)[]},ValueTypes["CargoStateEntity"]],
CargoStateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCargoStateEntity"] | null},boolean],
CargoEntityCreateOne?: [{	input?:ValueTypes["InputCargoEntity"] | null},ValueTypes["CargoEntity"]],
CargoEntityUpdateOne?: [{	input?:ValueTypes["InputCargoEntity"] | null},ValueTypes["CargoEntity"]],
CargoEntityUpdateMany?: [{	input?:(ValueTypes["InputCargoEntity"] | undefined | null)[]},ValueTypes["CargoEntity"]],
CargoEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCargoEntity"] | null},boolean],
ProjectHousePartEntityCreateOne?: [{	input?:ValueTypes["InputProjectHousePartEntity"] | null},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityUpdateOne?: [{	input?:ValueTypes["InputProjectHousePartEntity"] | null},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectHousePartEntity"] | undefined | null)[]},ValueTypes["ProjectHousePartEntity"]],
ProjectHousePartEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectHousePartEntity"] | null},boolean],
PicklistElementEntityCreateOne?: [{	input?:ValueTypes["InputPicklistElementEntity"] | null},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistElementEntity"] | null},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistElementEntity"] | undefined | null)[]},ValueTypes["PicklistElementEntity"]],
PicklistElementEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistElementEntity"] | null},boolean],
PicklistEntityCreateOne?: [{	input?:ValueTypes["InputPicklistEntity"] | null},ValueTypes["PicklistEntity"]],
PicklistEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistEntity"] | null},ValueTypes["PicklistEntity"]],
PicklistEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistEntity"] | undefined | null)[]},ValueTypes["PicklistEntity"]],
PicklistEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistEntity"] | null},boolean],
DrawOrderTypeEntityCreateOne?: [{	input?:ValueTypes["InputDrawOrderTypeEntity"] | null},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityUpdateOne?: [{	input?:ValueTypes["InputDrawOrderTypeEntity"] | null},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityUpdateMany?: [{	input?:(ValueTypes["InputDrawOrderTypeEntity"] | undefined | null)[]},ValueTypes["DrawOrderTypeEntity"]],
DrawOrderTypeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDrawOrderTypeEntity"] | null},boolean],
HouseModelEntityCreateOne?: [{	input?:ValueTypes["InputHouseModelEntity"] | null},ValueTypes["HouseModelEntity"]],
HouseModelEntityUpdateOne?: [{	input?:ValueTypes["InputHouseModelEntity"] | null},ValueTypes["HouseModelEntity"]],
HouseModelEntityUpdateMany?: [{	input?:(ValueTypes["InputHouseModelEntity"] | undefined | null)[]},ValueTypes["HouseModelEntity"]],
HouseModelEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHouseModelEntity"] | null},boolean],
SalesEmployeeEntityCreateOne?: [{	input?:ValueTypes["InputSalesEmployeeEntity"] | null},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityUpdateOne?: [{	input?:ValueTypes["InputSalesEmployeeEntity"] | null},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityUpdateMany?: [{	input?:(ValueTypes["InputSalesEmployeeEntity"] | undefined | null)[]},ValueTypes["SalesEmployeeEntity"]],
SalesEmployeeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSalesEmployeeEntity"] | null},boolean],
DrawerAbsentEntityCreateOne?: [{	input?:ValueTypes["InputDrawerAbsentEntity"] | null},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityUpdateOne?: [{	input?:ValueTypes["InputDrawerAbsentEntity"] | null},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityUpdateMany?: [{	input?:(ValueTypes["InputDrawerAbsentEntity"] | undefined | null)[]},ValueTypes["DrawerAbsentEntity"]],
DrawerAbsentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDrawerAbsentEntity"] | null},boolean],
DrawerEntityCreateOne?: [{	input?:ValueTypes["InputDrawerEntity"] | null},ValueTypes["DrawerEntity"]],
DrawerEntityUpdateOne?: [{	input?:ValueTypes["InputDrawerEntity"] | null},ValueTypes["DrawerEntity"]],
DrawerEntityUpdateMany?: [{	input?:(ValueTypes["InputDrawerEntity"] | undefined | null)[]},ValueTypes["DrawerEntity"]],
DrawerEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDrawerEntity"] | null},boolean],
DrawOrderEntityCreateOne?: [{	input?:ValueTypes["InputDrawOrderEntity"] | null},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityUpdateOne?: [{	input?:ValueTypes["InputDrawOrderEntity"] | null},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityUpdateMany?: [{	input?:(ValueTypes["InputDrawOrderEntity"] | undefined | null)[]},ValueTypes["DrawOrderEntity"]],
DrawOrderEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDrawOrderEntity"] | null},boolean],
DateGroupCommentEntityCreateOne?: [{	input?:ValueTypes["InputDateGroupCommentEntity"] | null},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityUpdateOne?: [{	input?:ValueTypes["InputDateGroupCommentEntity"] | null},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityUpdateMany?: [{	input?:(ValueTypes["InputDateGroupCommentEntity"] | undefined | null)[]},ValueTypes["DateGroupCommentEntity"]],
DateGroupCommentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateGroupCommentEntity"] | null},boolean],
DateGroupResponsibleEntityCreateOne?: [{	input?:ValueTypes["InputDateGroupResponsibleEntity"] | null},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityUpdateOne?: [{	input?:ValueTypes["InputDateGroupResponsibleEntity"] | null},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityUpdateMany?: [{	input?:(ValueTypes["InputDateGroupResponsibleEntity"] | undefined | null)[]},ValueTypes["DateGroupResponsibleEntity"]],
DateGroupResponsibleEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateGroupResponsibleEntity"] | null},boolean],
LogicFieldEntityCreateOne?: [{	input?:ValueTypes["InputLogicFieldEntity"] | null},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityUpdateOne?: [{	input?:ValueTypes["InputLogicFieldEntity"] | null},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityUpdateMany?: [{	input?:(ValueTypes["InputLogicFieldEntity"] | undefined | null)[]},ValueTypes["LogicFieldEntity"]],
LogicFieldEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteLogicFieldEntity"] | null},boolean],
DateGroupEntityCreateOne?: [{	input?:ValueTypes["InputDateGroupEntity"] | null},ValueTypes["DateGroupEntity"]],
DateGroupEntityUpdateOne?: [{	input?:ValueTypes["InputDateGroupEntity"] | null},ValueTypes["DateGroupEntity"]],
DateGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputDateGroupEntity"] | undefined | null)[]},ValueTypes["DateGroupEntity"]],
DateGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateGroupEntity"] | null},boolean],
DateEntityCreateOne?: [{	input?:ValueTypes["InputDateEntity"] | null},ValueTypes["DateEntity"]],
DateEntityUpdateOne?: [{	input?:ValueTypes["InputDateEntity"] | null},ValueTypes["DateEntity"]],
DateEntityUpdateMany?: [{	input?:(ValueTypes["InputDateEntity"] | undefined | null)[]},ValueTypes["DateEntity"]],
DateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateEntity"] | null},boolean],
DateValueEntityCreateOne?: [{	input?:ValueTypes["InputDateValueEntity"] | null},ValueTypes["DateValueEntity"]],
DateValueEntityUpdateOne?: [{	input?:ValueTypes["InputDateValueEntity"] | null},ValueTypes["DateValueEntity"]],
DateValueEntityUpdateMany?: [{	input?:(ValueTypes["InputDateValueEntity"] | undefined | null)[]},ValueTypes["DateValueEntity"]],
DateValueEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateValueEntity"] | null},boolean],
LogicFieldValueEntityCreateOne?: [{	input?:ValueTypes["InputLogicFieldValueEntity"] | null},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityUpdateOne?: [{	input?:ValueTypes["InputLogicFieldValueEntity"] | null},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityUpdateMany?: [{	input?:(ValueTypes["InputLogicFieldValueEntity"] | undefined | null)[]},ValueTypes["LogicFieldValueEntity"]],
LogicFieldValueEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteLogicFieldValueEntity"] | null},boolean],
DateCacheEntityCreateOne?: [{	input?:ValueTypes["InputDateCacheEntity"] | null},ValueTypes["DateCacheEntity"]],
DateCacheEntityUpdateOne?: [{	input?:ValueTypes["InputDateCacheEntity"] | null},ValueTypes["DateCacheEntity"]],
DateCacheEntityUpdateMany?: [{	input?:(ValueTypes["InputDateCacheEntity"] | undefined | null)[]},ValueTypes["DateCacheEntity"]],
DateCacheEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDateCacheEntity"] | null},boolean],
YearPlanningModificationEntityCreateOne?: [{	input?:ValueTypes["InputYearPlanningModificationEntity"] | null},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityUpdateOne?: [{	input?:ValueTypes["InputYearPlanningModificationEntity"] | null},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityUpdateMany?: [{	input?:(ValueTypes["InputYearPlanningModificationEntity"] | undefined | null)[]},ValueTypes["YearPlanningModificationEntity"]],
YearPlanningModificationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteYearPlanningModificationEntity"] | null},boolean],
ProjectCachedValueEntityCreateOne?: [{	input?:ValueTypes["InputProjectCachedValueEntity"] | null},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityUpdateOne?: [{	input?:ValueTypes["InputProjectCachedValueEntity"] | null},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectCachedValueEntity"] | undefined | null)[]},ValueTypes["ProjectCachedValueEntity"]],
ProjectCachedValueEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectCachedValueEntity"] | null},boolean],
TableLayoutCommentEntityCreateOne?: [{	input?:ValueTypes["InputTableLayoutCommentEntity"] | null},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityUpdateOne?: [{	input?:ValueTypes["InputTableLayoutCommentEntity"] | null},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityUpdateMany?: [{	input?:(ValueTypes["InputTableLayoutCommentEntity"] | undefined | null)[]},ValueTypes["TableLayoutCommentEntity"]],
TableLayoutCommentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteTableLayoutCommentEntity"] | null},boolean],
ProjectEntityCreateOne?: [{	input?:ValueTypes["InputProjectEntity"] | null},ValueTypes["ProjectEntity"]],
ProjectEntityUpdateOne?: [{	input?:ValueTypes["InputProjectEntity"] | null},ValueTypes["ProjectEntity"]],
ProjectEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectEntity"] | undefined | null)[]},ValueTypes["ProjectEntity"]],
ProjectEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectEntity"] | null},boolean],
SyncCronEntityCreateOne?: [{	input?:ValueTypes["InputSyncCronEntity"] | null},ValueTypes["SyncCronEntity"]],
SyncCronEntityUpdateOne?: [{	input?:ValueTypes["InputSyncCronEntity"] | null},ValueTypes["SyncCronEntity"]],
SyncCronEntityUpdateMany?: [{	input?:(ValueTypes["InputSyncCronEntity"] | undefined | null)[]},ValueTypes["SyncCronEntity"]],
SyncCronEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSyncCronEntity"] | null},boolean],
MailSchedulerEntityCreateOne?: [{	input?:ValueTypes["InputMailSchedulerEntity"] | null},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityUpdateOne?: [{	input?:ValueTypes["InputMailSchedulerEntity"] | null},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityUpdateMany?: [{	input?:(ValueTypes["InputMailSchedulerEntity"] | undefined | null)[]},ValueTypes["MailSchedulerEntity"]],
MailSchedulerEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteMailSchedulerEntity"] | null},boolean],
ProjectPartnerEntityCreateOne?: [{	input?:ValueTypes["InputProjectPartnerEntity"] | null},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityUpdateOne?: [{	input?:ValueTypes["InputProjectPartnerEntity"] | null},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectPartnerEntity"] | undefined | null)[]},ValueTypes["ProjectPartnerEntity"]],
ProjectPartnerEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectPartnerEntity"] | null},boolean],
PartnerEntityCreateOne?: [{	input?:ValueTypes["InputPartnerEntity"] | null},ValueTypes["PartnerEntity"]],
PartnerEntityUpdateOne?: [{	input?:ValueTypes["InputPartnerEntity"] | null},ValueTypes["PartnerEntity"]],
PartnerEntityUpdateMany?: [{	input?:(ValueTypes["InputPartnerEntity"] | undefined | null)[]},ValueTypes["PartnerEntity"]],
PartnerEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePartnerEntity"] | null},boolean],
DocumentMetaEntityCreateOne?: [{	input?:ValueTypes["InputDocumentMetaEntity"] | null},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityUpdateOne?: [{	input?:ValueTypes["InputDocumentMetaEntity"] | null},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityUpdateMany?: [{	input?:(ValueTypes["InputDocumentMetaEntity"] | undefined | null)[]},ValueTypes["DocumentMetaEntity"]],
DocumentMetaEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDocumentMetaEntity"] | null},boolean],
EntityAdvancementEntityCreateOne?: [{	input?:ValueTypes["InputEntityAdvancementEntity"] | null},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityUpdateOne?: [{	input?:ValueTypes["InputEntityAdvancementEntity"] | null},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityUpdateMany?: [{	input?:(ValueTypes["InputEntityAdvancementEntity"] | undefined | null)[]},ValueTypes["EntityAdvancementEntity"]],
EntityAdvancementEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteEntityAdvancementEntity"] | null},boolean],
WorkActionCategoryEntityCreateOne?: [{	input?:ValueTypes["InputWorkActionCategoryEntity"] | null},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityUpdateOne?: [{	input?:ValueTypes["InputWorkActionCategoryEntity"] | null},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityUpdateMany?: [{	input?:(ValueTypes["InputWorkActionCategoryEntity"] | undefined | null)[]},ValueTypes["WorkActionCategoryEntity"]],
WorkActionCategoryEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteWorkActionCategoryEntity"] | null},boolean],
WorkActionEntityCreateOne?: [{	input?:ValueTypes["InputWorkActionEntity"] | null},ValueTypes["WorkActionEntity"]],
WorkActionEntityUpdateOne?: [{	input?:ValueTypes["InputWorkActionEntity"] | null},ValueTypes["WorkActionEntity"]],
WorkActionEntityUpdateMany?: [{	input?:(ValueTypes["InputWorkActionEntity"] | undefined | null)[]},ValueTypes["WorkActionEntity"]],
WorkActionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteWorkActionEntity"] | null},boolean],
EntityImageEntityCreateOne?: [{	input?:ValueTypes["InputEntityImageEntity"] | null},ValueTypes["EntityImageEntity"]],
EntityImageEntityUpdateOne?: [{	input?:ValueTypes["InputEntityImageEntity"] | null},ValueTypes["EntityImageEntity"]],
EntityImageEntityUpdateMany?: [{	input?:(ValueTypes["InputEntityImageEntity"] | undefined | null)[]},ValueTypes["EntityImageEntity"]],
EntityImageEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteEntityImageEntity"] | null},boolean],
CustomerQuestionEntityCreateOne?: [{	input?:ValueTypes["InputCustomerQuestionEntity"] | null},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityUpdateOne?: [{	input?:ValueTypes["InputCustomerQuestionEntity"] | null},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityUpdateMany?: [{	input?:(ValueTypes["InputCustomerQuestionEntity"] | undefined | null)[]},ValueTypes["CustomerQuestionEntity"]],
CustomerQuestionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCustomerQuestionEntity"] | null},boolean],
AuditCategoryEntityCreateOne?: [{	input?:ValueTypes["InputAuditCategoryEntity"] | null},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityUpdateOne?: [{	input?:ValueTypes["InputAuditCategoryEntity"] | null},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditCategoryEntity"] | undefined | null)[]},ValueTypes["AuditCategoryEntity"]],
AuditCategoryEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditCategoryEntity"] | null},boolean],
AuditQuestionEntityCreateOne?: [{	input?:ValueTypes["InputAuditQuestionEntity"] | null},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityUpdateOne?: [{	input?:ValueTypes["InputAuditQuestionEntity"] | null},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditQuestionEntity"] | undefined | null)[]},ValueTypes["AuditQuestionEntity"]],
AuditQuestionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditQuestionEntity"] | null},boolean],
AuditQuestionCostCategoryEntityCreateOne?: [{	input?:ValueTypes["InputAuditQuestionCostCategoryEntity"] | null},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityUpdateOne?: [{	input?:ValueTypes["InputAuditQuestionCostCategoryEntity"] | null},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditQuestionCostCategoryEntity"] | undefined | null)[]},ValueTypes["AuditQuestionCostCategoryEntity"]],
AuditQuestionCostCategoryEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditQuestionCostCategoryEntity"] | null},boolean],
CostCategoryEntityCreateOne?: [{	input?:ValueTypes["InputCostCategoryEntity"] | null},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityUpdateOne?: [{	input?:ValueTypes["InputCostCategoryEntity"] | null},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityUpdateMany?: [{	input?:(ValueTypes["InputCostCategoryEntity"] | undefined | null)[]},ValueTypes["CostCategoryEntity"]],
CostCategoryEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteCostCategoryEntity"] | null},boolean],
AuditReportItemImageEntityCreateOne?: [{	input?:ValueTypes["InputAuditReportItemImageEntity"] | null},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityUpdateOne?: [{	input?:ValueTypes["InputAuditReportItemImageEntity"] | null},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditReportItemImageEntity"] | undefined | null)[]},ValueTypes["AuditReportItemImageEntity"]],
AuditReportItemImageEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditReportItemImageEntity"] | null},boolean],
AuditReportItemEntityCreateOne?: [{	input?:ValueTypes["InputAuditReportItemEntity"] | null},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityUpdateOne?: [{	input?:ValueTypes["InputAuditReportItemEntity"] | null},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditReportItemEntity"] | undefined | null)[]},ValueTypes["AuditReportItemEntity"]],
AuditReportItemEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditReportItemEntity"] | null},boolean],
AuditReportEntityCreateOne?: [{	input?:ValueTypes["InputAuditReportEntity"] | null},ValueTypes["AuditReportEntity"]],
AuditReportEntityUpdateOne?: [{	input?:ValueTypes["InputAuditReportEntity"] | null},ValueTypes["AuditReportEntity"]],
AuditReportEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditReportEntity"] | undefined | null)[]},ValueTypes["AuditReportEntity"]],
AuditReportEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditReportEntity"] | null},boolean],
AuditChecklistImageEntityCreateOne?: [{	input?:ValueTypes["InputAuditChecklistImageEntity"] | null},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityUpdateOne?: [{	input?:ValueTypes["InputAuditChecklistImageEntity"] | null},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityUpdateMany?: [{	input?:(ValueTypes["InputAuditChecklistImageEntity"] | undefined | null)[]},ValueTypes["AuditChecklistImageEntity"]],
AuditChecklistImageEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAuditChecklistImageEntity"] | null},boolean],
InvoiceAttachmentEntityCreateOne?: [{	input?:ValueTypes["InputInvoiceAttachmentEntity"] | null},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityUpdateOne?: [{	input?:ValueTypes["InputInvoiceAttachmentEntity"] | null},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityUpdateMany?: [{	input?:(ValueTypes["InputInvoiceAttachmentEntity"] | undefined | null)[]},ValueTypes["InvoiceAttachmentEntity"]],
InvoiceAttachmentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInvoiceAttachmentEntity"] | null},boolean],
EmployeeDocumentEntityCreateOne?: [{	input?:ValueTypes["InputEmployeeDocumentEntity"] | null},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityUpdateOne?: [{	input?:ValueTypes["InputEmployeeDocumentEntity"] | null},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityUpdateMany?: [{	input?:(ValueTypes["InputEmployeeDocumentEntity"] | undefined | null)[]},ValueTypes["EmployeeDocumentEntity"]],
EmployeeDocumentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteEmployeeDocumentEntity"] | null},boolean],
WorkActionTemplateGroupEntityCreateOne?: [{	input?:ValueTypes["InputWorkActionTemplateGroupEntity"] | null},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityUpdateOne?: [{	input?:ValueTypes["InputWorkActionTemplateGroupEntity"] | null},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputWorkActionTemplateGroupEntity"] | undefined | null)[]},ValueTypes["WorkActionTemplateGroupEntity"]],
WorkActionTemplateGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteWorkActionTemplateGroupEntity"] | null},boolean],
WorkActionTemplateEntityCreateOne?: [{	input?:ValueTypes["InputWorkActionTemplateEntity"] | null},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityUpdateOne?: [{	input?:ValueTypes["InputWorkActionTemplateEntity"] | null},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityUpdateMany?: [{	input?:(ValueTypes["InputWorkActionTemplateEntity"] | undefined | null)[]},ValueTypes["WorkActionTemplateEntity"]],
WorkActionTemplateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteWorkActionTemplateEntity"] | null},boolean],
ProjectCostInfoEntityCreateOne?: [{	input?:ValueTypes["InputProjectCostInfoEntity"] | null},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityUpdateOne?: [{	input?:ValueTypes["InputProjectCostInfoEntity"] | null},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectCostInfoEntity"] | undefined | null)[]},ValueTypes["ProjectCostInfoEntity"]],
ProjectCostInfoEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectCostInfoEntity"] | null},boolean],
BlockedCalendarWeekEntityCreateOne?: [{	input?:ValueTypes["InputBlockedCalendarWeekEntity"] | null},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityUpdateOne?: [{	input?:ValueTypes["InputBlockedCalendarWeekEntity"] | null},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityUpdateMany?: [{	input?:(ValueTypes["InputBlockedCalendarWeekEntity"] | undefined | null)[]},ValueTypes["BlockedCalendarWeekEntity"]],
BlockedCalendarWeekEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteBlockedCalendarWeekEntity"] | null},boolean],
MaintenanceReportEntityCreateOne?: [{	input?:ValueTypes["InputMaintenanceReportEntity"] | null},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityUpdateOne?: [{	input?:ValueTypes["InputMaintenanceReportEntity"] | null},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityUpdateMany?: [{	input?:(ValueTypes["InputMaintenanceReportEntity"] | undefined | null)[]},ValueTypes["MaintenanceReportEntity"]],
MaintenanceReportEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteMaintenanceReportEntity"] | null},boolean],
ConfigurationEntityCreateOne?: [{	input?:ValueTypes["InputConfigurationEntity"] | null},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityUpdateOne?: [{	input?:ValueTypes["InputConfigurationEntity"] | null},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityUpdateMany?: [{	input?:(ValueTypes["InputConfigurationEntity"] | undefined | null)[]},ValueTypes["ConfigurationEntity"]],
ConfigurationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteConfigurationEntity"] | null},boolean],
ServiceMeterEntityCreateOne?: [{	input?:ValueTypes["InputServiceMeterEntity"] | null},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityUpdateOne?: [{	input?:ValueTypes["InputServiceMeterEntity"] | null},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityUpdateMany?: [{	input?:(ValueTypes["InputServiceMeterEntity"] | undefined | null)[]},ValueTypes["ServiceMeterEntity"]],
ServiceMeterEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServiceMeterEntity"] | null},boolean],
DeliveryListMeterEntityCreateOne?: [{	input?:ValueTypes["InputDeliveryListMeterEntity"] | null},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityUpdateOne?: [{	input?:ValueTypes["InputDeliveryListMeterEntity"] | null},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityUpdateMany?: [{	input?:(ValueTypes["InputDeliveryListMeterEntity"] | undefined | null)[]},ValueTypes["DeliveryListMeterEntity"]],
DeliveryListMeterEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDeliveryListMeterEntity"] | null},boolean],
ServiceSpaceEntityCreateOne?: [{	input?:ValueTypes["InputServiceSpaceEntity"] | null},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityUpdateOne?: [{	input?:ValueTypes["InputServiceSpaceEntity"] | null},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityUpdateMany?: [{	input?:(ValueTypes["InputServiceSpaceEntity"] | undefined | null)[]},ValueTypes["ServiceSpaceEntity"]],
ServiceSpaceEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServiceSpaceEntity"] | null},boolean],
ServiceTypeEntityCreateOne?: [{	input?:ValueTypes["InputServiceTypeEntity"] | null},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityUpdateOne?: [{	input?:ValueTypes["InputServiceTypeEntity"] | null},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityUpdateMany?: [{	input?:(ValueTypes["InputServiceTypeEntity"] | undefined | null)[]},ValueTypes["ServiceTypeEntity"]],
ServiceTypeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServiceTypeEntity"] | null},boolean],
DeliveryListPointImageEntityCreateOne?: [{	input?:ValueTypes["InputDeliveryListPointImageEntity"] | null},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityUpdateOne?: [{	input?:ValueTypes["InputDeliveryListPointImageEntity"] | null},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityUpdateMany?: [{	input?:(ValueTypes["InputDeliveryListPointImageEntity"] | undefined | null)[]},ValueTypes["DeliveryListPointImageEntity"]],
DeliveryListPointImageEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDeliveryListPointImageEntity"] | null},boolean],
ServicePriorityEntityCreateOne?: [{	input?:ValueTypes["InputServicePriorityEntity"] | null},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityUpdateOne?: [{	input?:ValueTypes["InputServicePriorityEntity"] | null},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityUpdateMany?: [{	input?:(ValueTypes["InputServicePriorityEntity"] | undefined | null)[]},ValueTypes["ServicePriorityEntity"]],
ServicePriorityEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServicePriorityEntity"] | null},boolean],
DeliveryListPointEntityCreateOne?: [{	input?:ValueTypes["InputDeliveryListPointEntity"] | null},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityUpdateOne?: [{	input?:ValueTypes["InputDeliveryListPointEntity"] | null},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityUpdateMany?: [{	input?:(ValueTypes["InputDeliveryListPointEntity"] | undefined | null)[]},ValueTypes["DeliveryListPointEntity"]],
DeliveryListPointEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDeliveryListPointEntity"] | null},boolean],
DeliveryListSignatureEntityCreateOne?: [{	input?:ValueTypes["InputDeliveryListSignatureEntity"] | null},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityUpdateOne?: [{	input?:ValueTypes["InputDeliveryListSignatureEntity"] | null},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityUpdateMany?: [{	input?:(ValueTypes["InputDeliveryListSignatureEntity"] | undefined | null)[]},ValueTypes["DeliveryListSignatureEntity"]],
DeliveryListSignatureEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDeliveryListSignatureEntity"] | null},boolean],
DeliveryListEntityCreateOne?: [{	input?:ValueTypes["InputDeliveryListEntity"] | null},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityUpdateOne?: [{	input?:ValueTypes["InputDeliveryListEntity"] | null},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityUpdateMany?: [{	input?:(ValueTypes["InputDeliveryListEntity"] | undefined | null)[]},ValueTypes["DeliveryListEntity"]],
DeliveryListEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDeliveryListEntity"] | null},boolean],
SupplyInspectionReportAnswerEntityCreateOne?: [{	input?:ValueTypes["InputSupplyInspectionReportAnswerEntity"] | null},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityUpdateOne?: [{	input?:ValueTypes["InputSupplyInspectionReportAnswerEntity"] | null},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityUpdateMany?: [{	input?:(ValueTypes["InputSupplyInspectionReportAnswerEntity"] | undefined | null)[]},ValueTypes["SupplyInspectionReportAnswerEntity"]],
SupplyInspectionReportAnswerEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSupplyInspectionReportAnswerEntity"] | null},boolean],
SupplyInspectionReportEntityCreateOne?: [{	input?:ValueTypes["InputSupplyInspectionReportEntity"] | null},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityUpdateOne?: [{	input?:ValueTypes["InputSupplyInspectionReportEntity"] | null},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityUpdateMany?: [{	input?:(ValueTypes["InputSupplyInspectionReportEntity"] | undefined | null)[]},ValueTypes["SupplyInspectionReportEntity"]],
SupplyInspectionReportEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSupplyInspectionReportEntity"] | null},boolean],
DepartmentEntityCreateOne?: [{	input?:ValueTypes["InputDepartmentEntity"] | null},ValueTypes["DepartmentEntity"]],
DepartmentEntityUpdateOne?: [{	input?:ValueTypes["InputDepartmentEntity"] | null},ValueTypes["DepartmentEntity"]],
DepartmentEntityUpdateMany?: [{	input?:(ValueTypes["InputDepartmentEntity"] | undefined | null)[]},ValueTypes["DepartmentEntity"]],
DepartmentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDepartmentEntity"] | null},boolean],
DrawOrderDescriptionEntityCreateOne?: [{	input?:ValueTypes["InputDrawOrderDescriptionEntity"] | null},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityUpdateOne?: [{	input?:ValueTypes["InputDrawOrderDescriptionEntity"] | null},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityUpdateMany?: [{	input?:(ValueTypes["InputDrawOrderDescriptionEntity"] | undefined | null)[]},ValueTypes["DrawOrderDescriptionEntity"]],
DrawOrderDescriptionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteDrawOrderDescriptionEntity"] | null},boolean],
GreetingEntityCreateOne?: [{	input?:ValueTypes["InputGreetingEntity"] | null},ValueTypes["GreetingEntity"]],
GreetingEntityUpdateOne?: [{	input?:ValueTypes["InputGreetingEntity"] | null},ValueTypes["GreetingEntity"]],
GreetingEntityUpdateMany?: [{	input?:(ValueTypes["InputGreetingEntity"] | undefined | null)[]},ValueTypes["GreetingEntity"]],
GreetingEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteGreetingEntity"] | null},boolean],
HourDeclarationTypeEntityCreateOne?: [{	input?:ValueTypes["InputHourDeclarationTypeEntity"] | null},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityUpdateOne?: [{	input?:ValueTypes["InputHourDeclarationTypeEntity"] | null},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityUpdateMany?: [{	input?:(ValueTypes["InputHourDeclarationTypeEntity"] | undefined | null)[]},ValueTypes["HourDeclarationTypeEntity"]],
HourDeclarationTypeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHourDeclarationTypeEntity"] | null},boolean],
HourDeclarationActivityEntityCreateOne?: [{	input?:ValueTypes["InputHourDeclarationActivityEntity"] | null},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityUpdateOne?: [{	input?:ValueTypes["InputHourDeclarationActivityEntity"] | null},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityUpdateMany?: [{	input?:(ValueTypes["InputHourDeclarationActivityEntity"] | undefined | null)[]},ValueTypes["HourDeclarationActivityEntity"]],
HourDeclarationActivityEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHourDeclarationActivityEntity"] | null},boolean],
HourDeclarationEntityCreateOne?: [{	input?:ValueTypes["InputHourDeclarationEntity"] | null},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityUpdateOne?: [{	input?:ValueTypes["InputHourDeclarationEntity"] | null},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityUpdateMany?: [{	input?:(ValueTypes["InputHourDeclarationEntity"] | undefined | null)[]},ValueTypes["HourDeclarationEntity"]],
HourDeclarationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHourDeclarationEntity"] | null},boolean],
HourDeclarationApprovalRequestEntityCreateOne?: [{	input?:ValueTypes["InputHourDeclarationApprovalRequestEntity"] | null},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityUpdateOne?: [{	input?:ValueTypes["InputHourDeclarationApprovalRequestEntity"] | null},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityUpdateMany?: [{	input?:(ValueTypes["InputHourDeclarationApprovalRequestEntity"] | undefined | null)[]},ValueTypes["HourDeclarationApprovalRequestEntity"]],
HourDeclarationApprovalRequestEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteHourDeclarationApprovalRequestEntity"] | null},boolean],
InstallationReportCommentEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportCommentEntity"] | null},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportCommentEntity"] | null},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportCommentEntity"] | undefined | null)[]},ValueTypes["InstallationReportCommentEntity"]],
InstallationReportCommentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportCommentEntity"] | null},boolean],
InstallationReportDescriptionEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportDescriptionEntity"] | null},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportDescriptionEntity"] | null},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportDescriptionEntity"] | undefined | null)[]},ValueTypes["InstallationReportDescriptionEntity"]],
InstallationReportDescriptionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportDescriptionEntity"] | null},boolean],
InstallationReportFinalEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportFinalEntity"] | null},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportFinalEntity"] | null},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportFinalEntity"] | undefined | null)[]},ValueTypes["InstallationReportFinalEntity"]],
InstallationReportFinalEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportFinalEntity"] | null},boolean],
InstallationReportMeasurementTemplateEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportMeasurementTemplateEntity"] | null},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportMeasurementTemplateEntity"] | null},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportMeasurementTemplateEntity"] | undefined | null)[]},ValueTypes["InstallationReportMeasurementTemplateEntity"]],
InstallationReportMeasurementTemplateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportMeasurementTemplateEntity"] | null},boolean],
InstallationReportMeasurementEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportMeasurementEntity"] | null},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportMeasurementEntity"] | null},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportMeasurementEntity"] | undefined | null)[]},ValueTypes["InstallationReportMeasurementEntity"]],
InstallationReportMeasurementEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportMeasurementEntity"] | null},boolean],
InstallationReportQuestionGroupEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionGroupEntity"] | null},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionGroupEntity"] | null},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportQuestionGroupEntity"] | undefined | null)[]},ValueTypes["InstallationReportQuestionGroupEntity"]],
InstallationReportQuestionGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportQuestionGroupEntity"] | null},boolean],
InstallationReportQuestionEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionEntity"] | null},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionEntity"] | null},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportQuestionEntity"] | undefined | null)[]},ValueTypes["InstallationReportQuestionEntity"]],
InstallationReportQuestionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportQuestionEntity"] | null},boolean],
InstallationReportQuestionAnswerEntityCreateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionAnswerEntity"] | null},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityUpdateOne?: [{	input?:ValueTypes["InputInstallationReportQuestionAnswerEntity"] | null},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityUpdateMany?: [{	input?:(ValueTypes["InputInstallationReportQuestionAnswerEntity"] | undefined | null)[]},ValueTypes["InstallationReportQuestionAnswerEntity"]],
InstallationReportQuestionAnswerEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInstallationReportQuestionAnswerEntity"] | null},boolean],
LegacyProjectEntityCreateOne?: [{	input?:ValueTypes["InputLegacyProjectEntity"] | null},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityUpdateOne?: [{	input?:ValueTypes["InputLegacyProjectEntity"] | null},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityUpdateMany?: [{	input?:(ValueTypes["InputLegacyProjectEntity"] | undefined | null)[]},ValueTypes["LegacyProjectEntity"]],
LegacyProjectEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteLegacyProjectEntity"] | null},boolean],
PicklistCommentEntityCreateOne?: [{	input?:ValueTypes["InputPicklistCommentEntity"] | null},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistCommentEntity"] | null},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistCommentEntity"] | undefined | null)[]},ValueTypes["PicklistCommentEntity"]],
PicklistCommentEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistCommentEntity"] | null},boolean],
PicklistElementStateEntityCreateOne?: [{	input?:ValueTypes["InputPicklistElementStateEntity"] | null},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityUpdateOne?: [{	input?:ValueTypes["InputPicklistElementStateEntity"] | null},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityUpdateMany?: [{	input?:(ValueTypes["InputPicklistElementStateEntity"] | undefined | null)[]},ValueTypes["PicklistElementStateEntity"]],
PicklistElementStateEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePicklistElementStateEntity"] | null},boolean],
PlanningProjectItemEntityCreateOne?: [{	input?:ValueTypes["InputPlanningProjectItemEntity"] | null},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityUpdateOne?: [{	input?:ValueTypes["InputPlanningProjectItemEntity"] | null},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityUpdateMany?: [{	input?:(ValueTypes["InputPlanningProjectItemEntity"] | undefined | null)[]},ValueTypes["PlanningProjectItemEntity"]],
PlanningProjectItemEntityDeleteOne?: [{	input?:ValueTypes["InputDeletePlanningProjectItemEntity"] | null},boolean],
ProjectCargoStateEntityCreateOne?: [{	input?:ValueTypes["InputProjectCargoStateEntity"] | null},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityUpdateOne?: [{	input?:ValueTypes["InputProjectCargoStateEntity"] | null},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectCargoStateEntity"] | undefined | null)[]},ValueTypes["ProjectCargoStateEntity"]],
ProjectCargoStateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectCargoStateEntity"] | null},boolean],
ProjectPaymentEntityCreateOne?: [{	input?:ValueTypes["InputProjectPaymentEntity"] | null},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityUpdateOne?: [{	input?:ValueTypes["InputProjectPaymentEntity"] | null},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityUpdateMany?: [{	input?:(ValueTypes["InputProjectPaymentEntity"] | undefined | null)[]},ValueTypes["ProjectPaymentEntity"]],
ProjectPaymentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteProjectPaymentEntity"] | null},boolean],
QualityAssuranceEntityCreateOne?: [{	input?:ValueTypes["InputQualityAssuranceEntity"] | null},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityUpdateOne?: [{	input?:ValueTypes["InputQualityAssuranceEntity"] | null},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityUpdateMany?: [{	input?:(ValueTypes["InputQualityAssuranceEntity"] | undefined | null)[]},ValueTypes["QualityAssuranceEntity"]],
QualityAssuranceEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteQualityAssuranceEntity"] | null},boolean],
ServiceMapColorEntityCreateOne?: [{	input?:ValueTypes["InputServiceMapColorEntity"] | null},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityUpdateOne?: [{	input?:ValueTypes["InputServiceMapColorEntity"] | null},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityUpdateMany?: [{	input?:(ValueTypes["InputServiceMapColorEntity"] | undefined | null)[]},ValueTypes["ServiceMapColorEntity"]],
ServiceMapColorEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServiceMapColorEntity"] | null},boolean],
ServicePlanningStageEntityCreateOne?: [{	input?:ValueTypes["InputServicePlanningStageEntity"] | null},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityUpdateOne?: [{	input?:ValueTypes["InputServicePlanningStageEntity"] | null},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityUpdateMany?: [{	input?:(ValueTypes["InputServicePlanningStageEntity"] | undefined | null)[]},ValueTypes["ServicePlanningStageEntity"]],
ServicePlanningStageEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServicePlanningStageEntity"] | null},boolean],
ServicePlanningEventEntityCreateOne?: [{	input?:ValueTypes["InputServicePlanningEventEntity"] | null},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityUpdateOne?: [{	input?:ValueTypes["InputServicePlanningEventEntity"] | null},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityUpdateMany?: [{	input?:(ValueTypes["InputServicePlanningEventEntity"] | undefined | null)[]},ValueTypes["ServicePlanningEventEntity"]],
ServicePlanningEventEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServicePlanningEventEntity"] | null},boolean],
ServicePlanningAssignmentEntityCreateOne?: [{	input?:ValueTypes["InputServicePlanningAssignmentEntity"] | null},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityUpdateOne?: [{	input?:ValueTypes["InputServicePlanningAssignmentEntity"] | null},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityUpdateMany?: [{	input?:(ValueTypes["InputServicePlanningAssignmentEntity"] | undefined | null)[]},ValueTypes["ServicePlanningAssignmentEntity"]],
ServicePlanningAssignmentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServicePlanningAssignmentEntity"] | null},boolean],
ServicePresetEntityCreateOne?: [{	input?:ValueTypes["InputServicePresetEntity"] | null},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityUpdateOne?: [{	input?:ValueTypes["InputServicePresetEntity"] | null},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityUpdateMany?: [{	input?:(ValueTypes["InputServicePresetEntity"] | undefined | null)[]},ValueTypes["ServicePresetEntity"]],
ServicePresetEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteServicePresetEntity"] | null},boolean],
SupplierDocumentEntityCreateOne?: [{	input?:ValueTypes["InputSupplierDocumentEntity"] | null},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityUpdateOne?: [{	input?:ValueTypes["InputSupplierDocumentEntity"] | null},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityUpdateMany?: [{	input?:(ValueTypes["InputSupplierDocumentEntity"] | undefined | null)[]},ValueTypes["SupplierDocumentEntity"]],
SupplierDocumentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSupplierDocumentEntity"] | null},boolean],
SupplyInspectionQuestionEntityCreateOne?: [{	input?:ValueTypes["InputSupplyInspectionQuestionEntity"] | null},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityUpdateOne?: [{	input?:ValueTypes["InputSupplyInspectionQuestionEntity"] | null},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityUpdateMany?: [{	input?:(ValueTypes["InputSupplyInspectionQuestionEntity"] | undefined | null)[]},ValueTypes["SupplyInspectionQuestionEntity"]],
SupplyInspectionQuestionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSupplyInspectionQuestionEntity"] | null},boolean],
SupplyInspectionQuestionGroupEntityCreateOne?: [{	input?:ValueTypes["InputSupplyInspectionQuestionGroupEntity"] | null},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityUpdateOne?: [{	input?:ValueTypes["InputSupplyInspectionQuestionGroupEntity"] | null},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputSupplyInspectionQuestionGroupEntity"] | undefined | null)[]},ValueTypes["SupplyInspectionQuestionGroupEntity"]],
SupplyInspectionQuestionGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteSupplyInspectionQuestionGroupEntity"] | null},boolean],
UserPasswordResetEntityCreateOne?: [{	input?:ValueTypes["InputUserPasswordResetEntity"] | null},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityUpdateOne?: [{	input?:ValueTypes["InputUserPasswordResetEntity"] | null},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityUpdateMany?: [{	input?:(ValueTypes["InputUserPasswordResetEntity"] | undefined | null)[]},ValueTypes["UserPasswordResetEntity"]],
UserPasswordResetEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteUserPasswordResetEntity"] | null},boolean],
YearPlanningLineEntityCreateOne?: [{	input?:ValueTypes["InputYearPlanningLineEntity"] | null},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityUpdateOne?: [{	input?:ValueTypes["InputYearPlanningLineEntity"] | null},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityUpdateMany?: [{	input?:(ValueTypes["InputYearPlanningLineEntity"] | undefined | null)[]},ValueTypes["YearPlanningLineEntity"]],
YearPlanningLineEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteYearPlanningLineEntity"] | null},boolean],
InvoicePaymentBatchEntityEntityCreateOne?: [{	input?:ValueTypes["InputInvoicePaymentBatchEntityEntity"] | null},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityUpdateOne?: [{	input?:ValueTypes["InputInvoicePaymentBatchEntityEntity"] | null},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityUpdateMany?: [{	input?:(ValueTypes["InputInvoicePaymentBatchEntityEntity"] | undefined | null)[]},ValueTypes["InvoicePaymentBatchEntityEntity"]],
InvoicePaymentBatchEntityEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteInvoicePaymentBatchEntityEntity"] | null},boolean],
AppPhaseContactEntityCreateOne?: [{	input?:ValueTypes["InputAppPhaseContactEntity"] | null},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityUpdateOne?: [{	input?:ValueTypes["InputAppPhaseContactEntity"] | null},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPhaseContactEntity"] | undefined | null)[]},ValueTypes["AppPhaseContactEntity"]],
AppPhaseContactEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPhaseContactEntity"] | null},boolean],
AppPhaseEntityCreateOne?: [{	input?:ValueTypes["InputAppPhaseEntity"] | null},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityUpdateOne?: [{	input?:ValueTypes["InputAppPhaseEntity"] | null},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPhaseEntity"] | undefined | null)[]},ValueTypes["AppPhaseEntity"]],
AppPhaseEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPhaseEntity"] | null},boolean],
AppPhaseSectionEntityCreateOne?: [{	input?:ValueTypes["InputAppPhaseSectionEntity"] | null},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityUpdateOne?: [{	input?:ValueTypes["InputAppPhaseSectionEntity"] | null},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPhaseSectionEntity"] | undefined | null)[]},ValueTypes["AppPhaseSectionEntity"]],
AppPhaseSectionEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPhaseSectionEntity"] | null},boolean],
AppNotificationTemplateEntityCreateOne?: [{	input?:ValueTypes["InputAppNotificationTemplateEntity"] | null},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityUpdateOne?: [{	input?:ValueTypes["InputAppNotificationTemplateEntity"] | null},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityUpdateMany?: [{	input?:(ValueTypes["InputAppNotificationTemplateEntity"] | undefined | null)[]},ValueTypes["AppNotificationTemplateEntity"]],
AppNotificationTemplateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppNotificationTemplateEntity"] | null},boolean],
AppPhaseSectionMarkEntityCreateOne?: [{	input?:ValueTypes["InputAppPhaseSectionMarkEntity"] | null},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityUpdateOne?: [{	input?:ValueTypes["InputAppPhaseSectionMarkEntity"] | null},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPhaseSectionMarkEntity"] | undefined | null)[]},ValueTypes["AppPhaseSectionMarkEntity"]],
AppPhaseSectionMarkEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPhaseSectionMarkEntity"] | null},boolean],
AppProjectDateEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectDateEntity"] | null},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectDateEntity"] | null},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectDateEntity"] | undefined | null)[]},ValueTypes["AppProjectDateEntity"]],
AppProjectDateEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectDateEntity"] | null},boolean],
AppNotificationScheduleEntityCreateOne?: [{	input?:ValueTypes["InputAppNotificationScheduleEntity"] | null},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityUpdateOne?: [{	input?:ValueTypes["InputAppNotificationScheduleEntity"] | null},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityUpdateMany?: [{	input?:(ValueTypes["InputAppNotificationScheduleEntity"] | undefined | null)[]},ValueTypes["AppNotificationScheduleEntity"]],
AppNotificationScheduleEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppNotificationScheduleEntity"] | null},boolean],
AppPhaseSectionMarkValueEntityCreateOne?: [{	input?:ValueTypes["InputAppPhaseSectionMarkValueEntity"] | null},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityUpdateOne?: [{	input?:ValueTypes["InputAppPhaseSectionMarkValueEntity"] | null},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPhaseSectionMarkValueEntity"] | undefined | null)[]},ValueTypes["AppPhaseSectionMarkValueEntity"]],
AppPhaseSectionMarkValueEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPhaseSectionMarkValueEntity"] | null},boolean],
AppInviteEntityCreateOne?: [{	input?:ValueTypes["InputAppInviteEntity"] | null},ValueTypes["AppInviteEntity"]],
AppInviteEntityUpdateOne?: [{	input?:ValueTypes["InputAppInviteEntity"] | null},ValueTypes["AppInviteEntity"]],
AppInviteEntityUpdateMany?: [{	input?:(ValueTypes["InputAppInviteEntity"] | undefined | null)[]},ValueTypes["AppInviteEntity"]],
AppInviteEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppInviteEntity"] | null},boolean],
AppLoginEntityCreateOne?: [{	input?:ValueTypes["InputAppLoginEntity"] | null},ValueTypes["AppLoginEntity"]],
AppLoginEntityUpdateOne?: [{	input?:ValueTypes["InputAppLoginEntity"] | null},ValueTypes["AppLoginEntity"]],
AppLoginEntityUpdateMany?: [{	input?:(ValueTypes["InputAppLoginEntity"] | undefined | null)[]},ValueTypes["AppLoginEntity"]],
AppLoginEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppLoginEntity"] | null},boolean],
AppNotificationEntityCreateOne?: [{	input?:ValueTypes["InputAppNotificationEntity"] | null},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityUpdateOne?: [{	input?:ValueTypes["InputAppNotificationEntity"] | null},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityUpdateMany?: [{	input?:(ValueTypes["InputAppNotificationEntity"] | undefined | null)[]},ValueTypes["AppNotificationEntity"]],
AppNotificationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppNotificationEntity"] | null},boolean],
AppProjectEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectEntity"] | null},ValueTypes["AppProjectEntity"]],
AppProjectEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectEntity"] | null},ValueTypes["AppProjectEntity"]],
AppProjectEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectEntity"] | undefined | null)[]},ValueTypes["AppProjectEntity"]],
AppProjectEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectEntity"] | null},boolean],
AppUserEntityCreateOne?: [{	input?:ValueTypes["InputAppUserEntity"] | null},ValueTypes["AppUserEntity"]],
AppUserEntityUpdateOne?: [{	input?:ValueTypes["InputAppUserEntity"] | null},ValueTypes["AppUserEntity"]],
AppUserEntityUpdateMany?: [{	input?:(ValueTypes["InputAppUserEntity"] | undefined | null)[]},ValueTypes["AppUserEntity"]],
AppUserEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppUserEntity"] | null},boolean],
AppProjectCostEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectCostEntity"] | null},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectCostEntity"] | null},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectCostEntity"] | undefined | null)[]},ValueTypes["AppProjectCostEntity"]],
AppProjectCostEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectCostEntity"] | null},boolean],
AppProjectContactEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectContactEntity"] | null},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectContactEntity"] | null},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectContactEntity"] | undefined | null)[]},ValueTypes["AppProjectContactEntity"]],
AppProjectContactEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectContactEntity"] | null},boolean],
AppBuildingWeekEntityCreateOne?: [{	input?:ValueTypes["InputAppBuildingWeekEntity"] | null},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityUpdateOne?: [{	input?:ValueTypes["InputAppBuildingWeekEntity"] | null},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityUpdateMany?: [{	input?:(ValueTypes["InputAppBuildingWeekEntity"] | undefined | null)[]},ValueTypes["AppBuildingWeekEntity"]],
AppBuildingWeekEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppBuildingWeekEntity"] | null},boolean],
AppContentEntityCreateOne?: [{	input?:ValueTypes["InputAppContentEntity"] | null},ValueTypes["AppContentEntity"]],
AppContentEntityUpdateOne?: [{	input?:ValueTypes["InputAppContentEntity"] | null},ValueTypes["AppContentEntity"]],
AppContentEntityUpdateMany?: [{	input?:(ValueTypes["InputAppContentEntity"] | undefined | null)[]},ValueTypes["AppContentEntity"]],
AppContentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppContentEntity"] | null},boolean],
AppConversationMessagePresetEntityCreateOne?: [{	input?:ValueTypes["InputAppConversationMessagePresetEntity"] | null},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityUpdateOne?: [{	input?:ValueTypes["InputAppConversationMessagePresetEntity"] | null},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityUpdateMany?: [{	input?:(ValueTypes["InputAppConversationMessagePresetEntity"] | undefined | null)[]},ValueTypes["AppConversationMessagePresetEntity"]],
AppConversationMessagePresetEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppConversationMessagePresetEntity"] | null},boolean],
AppDocumentSignatureEntityCreateOne?: [{	input?:ValueTypes["InputAppDocumentSignatureEntity"] | null},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityUpdateOne?: [{	input?:ValueTypes["InputAppDocumentSignatureEntity"] | null},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityUpdateMany?: [{	input?:(ValueTypes["InputAppDocumentSignatureEntity"] | undefined | null)[]},ValueTypes["AppDocumentSignatureEntity"]],
AppDocumentSignatureEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppDocumentSignatureEntity"] | null},boolean],
AppDocumentTypeEntityCreateOne?: [{	input?:ValueTypes["InputAppDocumentTypeEntity"] | null},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityUpdateOne?: [{	input?:ValueTypes["InputAppDocumentTypeEntity"] | null},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityUpdateMany?: [{	input?:(ValueTypes["InputAppDocumentTypeEntity"] | undefined | null)[]},ValueTypes["AppDocumentTypeEntity"]],
AppDocumentTypeEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppDocumentTypeEntity"] | null},boolean],
AppDocumentEntityCreateOne?: [{	input?:ValueTypes["InputAppDocumentEntity"] | null},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityUpdateOne?: [{	input?:ValueTypes["InputAppDocumentEntity"] | null},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityUpdateMany?: [{	input?:(ValueTypes["InputAppDocumentEntity"] | undefined | null)[]},ValueTypes["AppDocumentEntity"]],
AppDocumentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppDocumentEntity"] | null},boolean],
AppNotificationScheduleResourceEntityCreateOne?: [{	input?:ValueTypes["InputAppNotificationScheduleResourceEntity"] | null},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityUpdateOne?: [{	input?:ValueTypes["InputAppNotificationScheduleResourceEntity"] | null},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityUpdateMany?: [{	input?:(ValueTypes["InputAppNotificationScheduleResourceEntity"] | undefined | null)[]},ValueTypes["AppNotificationScheduleResourceEntity"]],
AppNotificationScheduleResourceEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppNotificationScheduleResourceEntity"] | null},boolean],
AppPlanCompetenceEntityCreateOne?: [{	input?:ValueTypes["InputAppPlanCompetenceEntity"] | null},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityUpdateOne?: [{	input?:ValueTypes["InputAppPlanCompetenceEntity"] | null},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPlanCompetenceEntity"] | undefined | null)[]},ValueTypes["AppPlanCompetenceEntity"]],
AppPlanCompetenceEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPlanCompetenceEntity"] | null},boolean],
AppPlanCompetenceSettingEntityCreateOne?: [{	input?:ValueTypes["InputAppPlanCompetenceSettingEntity"] | null},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityUpdateOne?: [{	input?:ValueTypes["InputAppPlanCompetenceSettingEntity"] | null},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityUpdateMany?: [{	input?:(ValueTypes["InputAppPlanCompetenceSettingEntity"] | undefined | null)[]},ValueTypes["AppPlanCompetenceSettingEntity"]],
AppPlanCompetenceSettingEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppPlanCompetenceSettingEntity"] | null},boolean],
AppProjectCostPresetEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectCostPresetEntity"] | null},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectCostPresetEntity"] | null},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectCostPresetEntity"] | undefined | null)[]},ValueTypes["AppProjectCostPresetEntity"]],
AppProjectCostPresetEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectCostPresetEntity"] | null},boolean],
AppProjectFieldSheetEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectFieldSheetEntity"] | null},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectFieldSheetEntity"] | null},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectFieldSheetEntity"] | undefined | null)[]},ValueTypes["AppProjectFieldSheetEntity"]],
AppProjectFieldSheetEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectFieldSheetEntity"] | null},boolean],
AppProjectFieldGroupEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupEntity"] | null},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupEntity"] | null},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectFieldGroupEntity"] | undefined | null)[]},ValueTypes["AppProjectFieldGroupEntity"]],
AppProjectFieldGroupEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectFieldGroupEntity"] | null},boolean],
AppProjectFieldGroupAssignmentEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupAssignmentEntity"] | null},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupAssignmentEntity"] | null},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectFieldGroupAssignmentEntity"] | undefined | null)[]},ValueTypes["AppProjectFieldGroupAssignmentEntity"]],
AppProjectFieldGroupAssignmentEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectFieldGroupAssignmentEntity"] | null},boolean],
AppProjectFieldGroupAssignmentValueEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupAssignmentValueEntity"] | null},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectFieldGroupAssignmentValueEntity"] | null},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectFieldGroupAssignmentValueEntity"] | undefined | null)[]},ValueTypes["AppProjectFieldGroupAssignmentValueEntity"]],
AppProjectFieldGroupAssignmentValueEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectFieldGroupAssignmentValueEntity"] | null},boolean],
AppProjectFieldEntityCreateOne?: [{	input?:ValueTypes["InputAppProjectFieldEntity"] | null},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityUpdateOne?: [{	input?:ValueTypes["InputAppProjectFieldEntity"] | null},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityUpdateMany?: [{	input?:(ValueTypes["InputAppProjectFieldEntity"] | undefined | null)[]},ValueTypes["AppProjectFieldEntity"]],
AppProjectFieldEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppProjectFieldEntity"] | null},boolean],
AppServiceCategoryEntityCreateOne?: [{	input?:ValueTypes["InputAppServiceCategoryEntity"] | null},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityUpdateOne?: [{	input?:ValueTypes["InputAppServiceCategoryEntity"] | null},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityUpdateMany?: [{	input?:(ValueTypes["InputAppServiceCategoryEntity"] | undefined | null)[]},ValueTypes["AppServiceCategoryEntity"]],
AppServiceCategoryEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppServiceCategoryEntity"] | null},boolean],
AppTextEntityCreateOne?: [{	input?:ValueTypes["InputAppTextEntity"] | null},ValueTypes["AppTextEntity"]],
AppTextEntityUpdateOne?: [{	input?:ValueTypes["InputAppTextEntity"] | null},ValueTypes["AppTextEntity"]],
AppTextEntityUpdateMany?: [{	input?:(ValueTypes["InputAppTextEntity"] | undefined | null)[]},ValueTypes["AppTextEntity"]],
AppTextEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppTextEntity"] | null},boolean],
AppRelationEntityCreateOne?: [{	input?:ValueTypes["InputAppRelationEntity"] | null},ValueTypes["AppRelationEntity"]],
AppRelationEntityUpdateOne?: [{	input?:ValueTypes["InputAppRelationEntity"] | null},ValueTypes["AppRelationEntity"]],
AppRelationEntityUpdateMany?: [{	input?:(ValueTypes["InputAppRelationEntity"] | undefined | null)[]},ValueTypes["AppRelationEntity"]],
AppRelationEntityDeleteOne?: [{	input?:ValueTypes["InputDeleteAppRelationEntity"] | null},boolean],
UploadDocument?: [{	id?:string | null,	file:ValueTypes["Upload"],	projectId:string,	trackingId?:string | null},ValueTypes["DocumentMetaEntity"]],
CellaWriteCustomFields?: [{	CustomField:ValueTypes["CellaCustomField"][]},boolean],
		__typename?: boolean
}>;
	["InputGrantEntity"]: {
	id?:string | null,
	permissionId?:string | null,
	roleId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteGrantEntity"]: {
	id?:string | null
};
	["InputRoleGrantConfigEntity"]: {
	id?:string | null,
	roleId?:string | null,
	configId?:string | null,
	value?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteRoleGrantConfigEntity"]: {
	id?:string | null
};
	["InputRoleDocumentGrantEntity"]: {
	id?:string | null,
	roleId?:string | null,
	match?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteRoleDocumentGrantEntity"]: {
	id?:string | null
};
	["InputRoleEntity"]: {
	id?:string | null,
	name?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteRoleEntity"]: {
	id?:string | null
};
	["InputUserGrantConfigEntity"]: {
	id?:string | null,
	userId?:string | null,
	configId?:string | null,
	value?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteUserGrantConfigEntity"]: {
	id?:string | null
};
	["InputUserNotificationSubscriptionEntity"]: {
	id?:string | null,
	userId?:string | null,
	endpoint?:string | null,
	keys_auth?:string | null,
	keys_p256dh?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteUserNotificationSubscriptionEntity"]: {
	id?:string | null
};
	["InputUserNotificationEntity"]: {
	id?:string | null,
	userId?:string | null,
	subject?:string | null,
	content?:string | null,
	url?:string | null,
	channels?:ValueTypes["JSON"] | null,
	readAt?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteUserNotificationEntity"]: {
	id?:string | null
};
	["InputUserTemporaryAccessTokenEntityEntity"]: {
	id?:string | null,
	value?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	user?:string | null
};
	["InputDeleteUserTemporaryAccessTokenEntityEntity"]: {
	id?:string | null
};
	["InputUserEntity"]: {
	id?:string | null,
	roleId?:string | null,
	companyId?:string | null,
	workAsUserId?:string | null,
	planningUserId?:string | null,
	abbreviation?:string | null,
	overwriteSignIn?:boolean | null,
	identity?:string | null,
	password?:string | null,
	passwordHash?:string | null,
	name?:string | null,
	stage?:string | null,
	email?:string | null,
	emailService?:string | null,
	emailDeclaration?:string | null,
	telephone?:string | null,
	isSupplier?:string | null,
	isSalesEmployee?:string | null,
	isServiceMechanic?:string | null,
	isBuyAdvisor?:string | null,
	isProjectMentor?:string | null,
	isProjectLeader?:string | null,
	addressStreet?:string | null,
	addressHouseNumber?:string | null,
	addressCity?:string | null,
	addressZipCode?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null,
	lastSeenAt?:ValueTypes["Date"] | null
};
	["InputDeleteUserEntity"]: {
	id?:string | null
};
	["InputPicklistDefinitionEntity"]: {
	id?:string | null,
	name?:string | null,
	icon?:string | null,
	metacomListTable?:string | null,
	metacomLinesTable?:string | null,
	renameFilterName?:string | null,
	competenceNumber?:string | null,
	elementTypes?:ValueTypes["JSON"] | null,
	hideColumns?:ValueTypes["JSON"] | null,
	indeterminateToggles?:boolean | null,
	dateSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePicklistDefinitionEntity"]: {
	id?:string | null
};
	["InputPicklistStateEntity"]: {
	id?:string | null,
	projectId?:string | null,
	picklistDefinitionId?:string | null,
	completedByUserId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePicklistStateEntity"]: {
	id?:string | null
};
	["InputHousePartGroupEntity"]: {
	id?:string | null,
	name?:string | null,
	isEnabled?:boolean | null,
	unit?:string | null,
	syncSource?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHousePartGroupEntity"]: {
	id?:string | null
};
	["InputHousePartEntity"]: {
	id?:string | null,
	partId?:string | null,
	housePartGroupId?:string | null,
	supplierId?:string | null,
	description?:string | null,
	rootElement?:string | null,
	releaseTimeMins?:number | null,
	length?:string | null,
	width?:string | null,
	capacityFactor?:number | null,
	syncSource?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHousePartEntity"]: {
	id?:string | null
};
	["InputCargoPhaseEntity"]: {
	id?:string | null,
	projectId?:string | null,
	description?:string | null,
	date?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCargoPhaseEntity"]: {
	id?:string | null
};
	["InputCargoTypeEntity"]: {
	id?:string | null,
	description?:string | null,
	userId?:string | null,
	price?:string | null,
	unit?:string | null,
	colorCode?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCargoTypeEntity"]: {
	id?:string | null
};
	["InputPlanningHumanResourceEntity"]: {
	id?:string | null,
	number?:string | null,
	resourceTypeId?:string | null,
	color?:string | null,
	firstName?:string | null,
	lastNamePrefix?:string | null,
	lastName?:string | null,
	email?:string | null,
	isNotProjectLeader?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningHumanResourceEntity"]: {
	id?:string | null
};
	["InputPlanningProjectAssignmentEntity"]: {
	id?:string | null,
	humanResourceId?:string | null,
	planningCompetencePlacementId?:string | null,
	comments?:string | null,
	startsAt?:ValueTypes["Date"] | null,
	endsAt?:ValueTypes["Date"] | null,
	hours?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectAssignmentEntity"]: {
	id?:string | null
};
	["InputPlanningProjectCompetencePlacementEntity"]: {
	id?:string | null,
	planningProjectCompetenceId?:string | null,
	planningProjectRequestPlacementId?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectCompetencePlacementEntity"]: {
	id?:string | null
};
	["InputPlanningProjectRequestPlacementEntity"]: {
	id?:string | null,
	planningProjectRequestId?:string | null,
	startDate?:ValueTypes["Date"] | null,
	endDate?:ValueTypes["Date"] | null,
	status?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectRequestPlacementEntity"]: {
	id?:string | null
};
	["InputPlanningProjectEntity"]: {
	id?:string | null,
	projectId?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectEntity"]: {
	id?:string | null
};
	["InputPlanningProjectRequestEntity"]: {
	id?:string | null,
	planningProjectId?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectRequestEntity"]: {
	id?:string | null
};
	["InputPlanningProjectCompetenceEntity"]: {
	id?:string | null,
	planningCompetenceId?:string | null,
	planningProjectRequestId?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectCompetenceEntity"]: {
	id?:string | null
};
	["InputPlanningCompetenceEntity"]: {
	id?:string | null,
	number?:string | null,
	color?:string | null,
	description?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningCompetenceEntity"]: {
	id?:string | null
};
	["InputCargoStateEntity"]: {
	id?:string | null,
	cargoId?:string | null,
	housePartGroupId?:string | null,
	divisionId?:string | null,
	value?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCargoStateEntity"]: {
	id?:string | null
};
	["InputCargoEntity"]: {
	id?:string | null,
	projectId?:string | null,
	competenceId?:string | null,
	phaseId?:string | null,
	cargoTypeId?:string | null,
	description?:string | null,
	timeAt?:string | null,
	requiresPermit?:boolean | null,
	routeId?:string | null,
	dateAt?:ValueTypes["Date"] | null,
	isLocked?:boolean | null,
	notes?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCargoEntity"]: {
	id?:string | null
};
	["InputProjectHousePartEntity"]: {
	id?:string | null,
	projectId?:string | null,
	cargoId?:string | null,
	supplierId?:string | null,
	partId?:string | null,
	partCodeId?:string | null,
	groupDivisionId?:string | null,
	quantity?:string | null,
	weight?:string | null,
	unit?:string | null,
	length?:string | null,
	width?:string | null,
	moldId?:string | null,
	wallId?:string | null,
	dateId?:string | null,
	costId?:string | null,
	syncSource?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectHousePartEntity"]: {
	id?:string | null
};
	["InputPicklistElementEntity"]: {
	id?:string | null,
	picklistId?:string | null,
	housePartGroupId?:string | null,
	pickedByUserId?:string | null,
	elementId?:string | null,
	elementType?:string | null,
	elementGroup?:string | null,
	elementName?:string | null,
	description?:string | null,
	quantity?:number | null,
	unit?:string | null,
	dimensions?:string | null,
	orderId?:string | null,
	pickState?:string | null,
	isPicked?:boolean | null,
	pickedAt?:ValueTypes["Date"] | null,
	syncSource?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePicklistElementEntity"]: {
	id?:string | null
};
	["InputPicklistEntity"]: {
	id?:string | null,
	projectId?:string | null,
	state?:string | null,
	note?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null,
	syncSource?:string | null,
	syncVersion?:string | null
};
	["InputDeletePicklistEntity"]: {
	id?:string | null
};
	["InputDrawOrderTypeEntity"]: {
	id?:string | null,
	name?:string | null,
	condition?:string | null,
	defaultHours?:number | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDrawOrderTypeEntity"]: {
	id?:string | null
};
	["InputHouseModelEntity"]: {
	id?:string | null,
	name?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHouseModelEntity"]: {
	id?:string | null
};
	["InputSalesEmployeeEntity"]: {
	id?:string | null,
	name?:string | null,
	firstName?:string | null,
	condition?:string | null,
	isActive?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteSalesEmployeeEntity"]: {
	id?:string | null
};
	["InputDrawerAbsentEntity"]: {
	id?:string | null,
	drawerId?:string | null,
	reason?:string | null,
	color?:string | null,
	date?:ValueTypes["Date"] | null,
	hours?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDrawerAbsentEntity"]: {
	id?:string | null
};
	["InputDrawerEntity"]: {
	id?:string | null,
	userId?:string | null,
	alias?:string | null,
	condition?:string | null,
	isHSB?:string | null,
	isBK?:string | null,
	isIT?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDrawerEntity"]: {
	id?:string | null
};
	["InputDrawOrderEntity"]: {
	id?:string | null,
	projectId?:string | null,
	kind?:string | null,
	drawOrderTypeId?:string | null,
	houseModelId?:string | null,
	salesEmployeeId?:string | null,
	drawerId?:string | null,
	description?:string | null,
	memo?:string | null,
	memoLong?:string | null,
	completedAt?:ValueTypes["Date"] | null,
	plannedAt?:ValueTypes["Date"] | null,
	plannedAtOrder?:number | null,
	totalHours?:number | null,
	totalHoursSpend?:number | null,
	percentageCompleted?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDrawOrderEntity"]: {
	id?:string | null
};
	["InputDateGroupCommentEntity"]: {
	id?:string | null,
	dateGroupId?:string | null,
	projectId?:string | null,
	userId?:string | null,
	value?:string | null,
	referenceId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDateGroupCommentEntity"]: {
	id?:string | null
};
	["InputDateGroupResponsibleEntity"]: {
	id?:string | null,
	userId?:string | null,
	projectId?:string | null,
	dateGroupId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDateGroupResponsibleEntity"]: {
	id?:string | null
};
	["InputLogicFieldEntity"]: {
	id?:string | null,
	fieldId?:string | null,
	fieldName?:string | null,
	dateGroupId?:string | null,
	conditionDescription?:string | null,
	expression?:string | null,
	iconId?:string | null,
	colorCode?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteLogicFieldEntity"]: {
	id?:string | null
};
	["InputDateGroupEntity"]: {
	id?:string | null,
	description?:string | null,
	label?:string | null,
	hasResponsible?:boolean | null,
	hasComments?:boolean | null,
	visibleOnConstructionPlanning?:boolean | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDateGroupEntity"]: {
	id?:string | null
};
	["InputDateEntity"]: {
	id?:string | null,
	dateGroupId?:string | null,
	description?:string | null,
	stackId?:number | null,
	iconId?:string | null,
	dateFormat?:string | null,
	colorCode?:string | null,
	orderId?:number | null,
	visibleOnDatePassed?:boolean | null,
	isOptional?:boolean | null,
	visibleOnConstructionPlanning?:boolean | null,
	phaseSectionMarkId?:number | null,
	notificationTemplateId?:number | null,
	drawOrderTypeId?:string | null,
	isSynced?:boolean | null,
	hash?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDateEntity"]: {
	id?:string | null
};
	["InputDateValueEntity"]: {
	id?:string | null,
	dateId?:string | null,
	projectId?:string | null,
	userId?:string | null,
	value?:ValueTypes["JSON"] | null,
	isActivated?:boolean | null,
	isCompleted?:boolean | null,
	isSynced?:boolean | null,
	syncSource?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null,
	isSyncUpdate?:boolean | null,
	send_notification?:boolean | null
};
	["InputDeleteDateValueEntity"]: {
	id?:string | null
};
	["InputLogicFieldValueEntity"]: {
	id?:string | null,
	fieldId?:string | null,
	projectId?:string | null,
	value?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null,
	isSyncUpdate?:boolean | null
};
	["InputDeleteLogicFieldValueEntity"]: {
	id?:string | null
};
	["InputDateCacheEntity"]: {
	id?:string | null,
	projectId?:string | null,
	values?:ValueTypes["JSON"] | null,
	comments?:ValueTypes["JSON"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDateCacheEntity"]: {
	id?:string | null
};
	["InputYearPlanningModificationEntity"]: {
	id?:string | null,
	userId?:string | null,
	projectId?:string | null,
	oldWeek?:string | null,
	newWeek?:string | null,
	notes?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteYearPlanningModificationEntity"]: {
	id?:string | null
};
	["InputProjectCachedValueEntity"]: {
	id?:string | null,
	projectId?:string | null,
	dateValues?:ValueTypes["JSON"] | null,
	projectLeader?:ValueTypes["JSON"] | null,
	salesEmployee?:ValueTypes["JSON"] | null,
	dateGroupResponsibles?:ValueTypes["JSON"] | null,
	buyAdvisor?:ValueTypes["JSON"] | null,
	projectMentor?:ValueTypes["JSON"] | null,
	dateGroupComments?:ValueTypes["JSON"] | null,
	logicFieldValues?:ValueTypes["JSON"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectCachedValueEntity"]: {
	id?:string | null
};
	["InputTableLayoutCommentEntity"]: {
	id?:string | null,
	taskId?:string | null,
	projectId?:string | null,
	userId?:string | null,
	comment?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteTableLayoutCommentEntity"]: {
	id?:string | null
};
	["InputProjectEntity"]: {
	id?:string | null,
	description?:string | null,
	userId?:string | null,
	region?:string | null,
	regionId?:string | null,
	houseType?:string | null,
	houseShape?:string | null,
	condition?:string | null,
	buildingWeek?:string | null,
	buildingType?:string | null,
	buildingStreet?:string | null,
	buildingHouseNumber?:string | null,
	buildingCity?:string | null,
	buildingZipCode?:string | null,
	deliveryDate?:string | null,
	longitude?:string | null,
	latitude?:string | null,
	concrete?:string | null,
	customerEmail?:string | null,
	customerName?:string | null,
	foundation?:string | null,
	heating?:string | null,
	constructionProbeWork?:string | null,
	constructionAdvice?:string | null,
	constructionDrawingWork?:string | null,
	constructionSupplier?:string | null,
	constructionLevel?:string | null,
	constructionUnavailable?:string | null,
	score?:number | null,
	topicalDocumentRoot?:string | null,
	archiveDocumentRoot?:string | null,
	administrationType?:string | null,
	projectLeaderId?:string | null,
	salesEmployeeId?:string | null,
	buyAdvisorId?:string | null,
	projectMentorId?:string | null,
	installationReportDate?:string | null,
	installationPerformerName?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectEntity"]: {
	id?:string | null
};
	["InputSyncCronEntity"]: {
	id?:string | null,
	cron?:string | null
};
	["InputDeleteSyncCronEntity"]: {
	id?:string | null
};
	["InputMailSchedulerEntity"]: {
	id?:string | null,
	description?:string | null,
	cron?:string | null,
	sourceType?:string | null,
	sourceTable?:string | null,
	condition?:string | null,
	mail?:string | null,
	mailCC?:string | null,
	subject?:string | null,
	template?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteMailSchedulerEntity"]: {
	id?:string | null
};
	["InputProjectPartnerEntity"]: {
	id?:string | null,
	projectId?:string | null,
	partnerId?:string | null,
	mode?:string | null,
	isContactAllowed?:boolean | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectPartnerEntity"]: {
	id?:string | null
};
	["InputPartnerEntity"]: {
	id?:string | null,
	name?:string | null,
	contentId?:number | null,
	contactUserId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePartnerEntity"]: {
	id?:string | null
};
	["InputDocumentMetaEntity"]: {
	id?:string | null,
	description?:string | null,
	mimeType?:string | null,
	projectId?:string | null,
	trackingId?:string | null,
	userId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDocumentMetaEntity"]: {
	id?:string | null
};
	["InputEntityAdvancementEntity"]: {
	id?:string | null,
	entityType?:string | null,
	entityId?:string | null,
	userId?:string | null,
	comment?:string | null,
	date?:ValueTypes["Date"] | null,
	isCompleted?:boolean | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteEntityAdvancementEntity"]: {
	id?:string | null
};
	["InputWorkActionCategoryEntity"]: {
	id?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteWorkActionCategoryEntity"]: {
	id?:string | null
};
	["InputWorkActionEntity"]: {
	title?:string | null,
	description?:string | null,
	allowEmptyAdvancement?:boolean | null,
	disableImageUpload?:boolean | null,
	responsibleDateGroupId?:string | null,
	advancementApplyDateId?:string | null,
	id?:string | null,
	entityType?:string | null,
	userId?:string | null,
	projectId?:string | null,
	workActionCategoryId?:string | null,
	assignedUserId?:string | null,
	date?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteWorkActionEntity"]: {
	id?:string | null
};
	["InputEntityImageEntity"]: {
	id?:string | null,
	entityType?:string | null,
	entityId?:string | null,
	userId?:string | null,
	documentMetaId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteEntityImageEntity"]: {
	id?:string | null
};
	["InputCustomerQuestionEntity"]: {
	id?:string | null,
	message?:string | null,
	kind?:string | null,
	projectId?:string | null,
	customerName?:string | null,
	city?:string | null,
	telephone?:string | null,
	space?:string | null,
	emailAddress?:string | null,
	internalComment?:string | null,
	imageIds?:ValueTypes["JSON"] | null,
	completedAt?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCustomerQuestionEntity"]: {
	id?:string | null
};
	["InputAuditCategoryEntity"]: {
	id?:string | null,
	description?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditCategoryEntity"]: {
	id?:string | null
};
	["InputAuditQuestionEntity"]: {
	id?:string | null,
	auditCategoryId?:string | null,
	description?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditQuestionEntity"]: {
	id?:string | null
};
	["InputAuditQuestionCostCategoryEntity"]: {
	id?:string | null,
	auditQuestionId?:string | null,
	costCategoryId?:string | null
};
	["InputDeleteAuditQuestionCostCategoryEntity"]: {
	id?:string | null
};
	["InputCostCategoryEntity"]: {
	id?:string | null,
	description?:string | null,
	group?:string | null,
	condition?:string | null,
	isChecklist?:string | null,
	hasImages?:string | null,
	checklistOrder?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteCostCategoryEntity"]: {
	id?:string | null
};
	["InputAuditReportItemImageEntity"]: {
	id?:string | null,
	auditReportItemId?:string | null,
	documentMetaId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditReportItemImageEntity"]: {
	id?:string | null
};
	["InputAuditReportItemEntity"]: {
	id?:string | null,
	userId?:string | null,
	auditReportId?:string | null,
	auditQuestionId?:string | null,
	state?:string | null,
	comment?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditReportItemEntity"]: {
	id?:string | null
};
	["InputAuditReportEntity"]: {
	id?:string | null,
	userId?:string | null,
	projectId?:string | null,
	costCategoryId?:string | null,
	signatureDocumentMetaId?:string | null,
	comment?:string | null,
	inspectedAt?:ValueTypes["Date"] | null,
	inspectedAtTime?:string | null,
	lockedAt?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditReportEntity"]: {
	id?:string | null
};
	["InputAuditChecklistImageEntity"]: {
	id?:string | null,
	userId?:string | null,
	costCategoryId?:string | null,
	projectId?:string | null,
	documentMetaId?:string | null,
	comment?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteAuditChecklistImageEntity"]: {
	id?:string | null
};
	["InputInvoiceAttachmentEntity"]: {
	id?:string | null,
	metacomOrderId?:string | null,
	documentMetaId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInvoiceAttachmentEntity"]: {
	id?:string | null
};
	["InputEmployeeDocumentEntity"]: {
	id?:string | null,
	userId?:string | null,
	relationId?:string | null,
	employeeId?:string | null,
	documentMetaId?:string | null,
	isInternal?:boolean | null,
	comment?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteEmployeeDocumentEntity"]: {
	id?:string | null
};
	["InputWorkActionTemplateGroupEntity"]: {
	id?:string | null,
	name?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteWorkActionTemplateGroupEntity"]: {
	id?:string | null
};
	["InputWorkActionTemplateEntity"]: {
	title?:string | null,
	description?:string | null,
	allowEmptyAdvancement?:boolean | null,
	disableImageUpload?:boolean | null,
	responsibleDateGroupId?:string | null,
	advancementApplyDateId?:string | null,
	id?:string | null,
	workActionTemplateGroupId?:string | null,
	workActionCategoryId?:string | null,
	targetDateOffset?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteWorkActionTemplateEntity"]: {
	id?:string | null
};
	["InputProjectCostInfoEntity"]: {
	id?:number | null,
	appProjectCostId?:number | null,
	conceptUserId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectCostInfoEntity"]: {
	id?:number | null
};
	["InputBlockedCalendarWeekEntity"]: {
	id?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteBlockedCalendarWeekEntity"]: {
	id?:string | null
};
	["InputMaintenanceReportEntity"]: {
	id?:string | null,
	projectId?:string | null,
	data?:ValueTypes["JSON"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteMaintenanceReportEntity"]: {
	id?:string | null
};
	["InputConfigurationEntity"]: {
	id?:string | null,
	data?:ValueTypes["JSON"] | null
};
	["InputDeleteConfigurationEntity"]: {
	id?:string | null
};
	["InputServiceMeterEntity"]: {
	id?:string | null,
	description?:string | null,
	unit?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServiceMeterEntity"]: {
	id?:string | null
};
	["InputDeliveryListMeterEntity"]: {
	id?:string | null,
	projectId?:string | null,
	meterId?:string | null,
	value?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDeliveryListMeterEntity"]: {
	id?:string | null
};
	["InputServiceSpaceEntity"]: {
	id?:string | null,
	code?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServiceSpaceEntity"]: {
	id?:string | null
};
	["InputServiceTypeEntity"]: {
	id?:string | null,
	code?:string | null,
	description?:string | null,
	storeInSystem?:boolean | null,
	isStandard?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServiceTypeEntity"]: {
	id?:string | null
};
	["InputDeliveryListPointImageEntity"]: {
	id?:string | null,
	pointId?:string | null,
	documentMetaId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDeliveryListPointImageEntity"]: {
	id?:string | null
};
	["InputServicePriorityEntity"]: {
	id?:string | null,
	description?:string | null,
	offsetDays?:number | null,
	isStandard?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServicePriorityEntity"]: {
	id?:string | null
};
	["InputDeliveryListPointEntity"]: {
	id?:string | null,
	code?:string | null,
	projectId?:string | null,
	primaryCategoryId?:number | null,
	secondaryCategoryId?:number | null,
	serviceTypeId?:string | null,
	serviceSpaceId?:string | null,
	serviceSpaceIds?:ValueTypes["JSON"] | null,
	servicePriorityId?:string | null,
	assignedUserId?:string | null,
	serviceTicketId?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDeliveryListPointEntity"]: {
	id?:string | null
};
	["InputDeliveryListSignatureEntity"]: {
	id?:string | null,
	projectId?:string | null,
	kind?:string | null,
	documentMetaId?:string | null,
	signDate?:ValueTypes["Date"] | null,
	signLocation?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDeliveryListSignatureEntity"]: {
	id?:string | null
};
	["InputDeliveryListEntity"]: {
	id?:string | null,
	projectId?:string | null,
	assignedUserId?:string | null,
	hiredAgent?:string | null,
	keyAmount?:number | null,
	isDeliveredAt?:ValueTypes["Date"] | null,
	houseDocumentMetaId?:string | null,
	isFinal?:boolean | null,
	isFinalAt?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDeliveryListEntity"]: {
	id?:string | null
};
	["InputSupplyInspectionReportAnswerEntity"]: {
	id?:string | null,
	supplyInspectionQuestionId?:string | null,
	supplyInspectionReportId?:string | null,
	status?:string | null,
	explanation?:string | null,
	imageDocumentMetaIds?:ValueTypes["JSON"] | null
};
	["InputDeleteSupplyInspectionReportAnswerEntity"]: {
	id?:string | null
};
	["InputSupplyInspectionReportEntity"]: {
	id?:string | null,
	timestamp?:ValueTypes["Date"] | null,
	departmentId?:string | null,
	executedByUserId?:string | null,
	signatureDocumentMetaId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteSupplyInspectionReportEntity"]: {
	id?:string | null
};
	["InputDepartmentEntity"]: {
	id?:string | null,
	departmentId?:string | null,
	description?:string | null,
	state?:string | null,
	isOrderDepartment?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDepartmentEntity"]: {
	id?:string | null
};
	["InputDrawOrderDescriptionEntity"]: {
	id?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteDrawOrderDescriptionEntity"]: {
	id?:string | null
};
	["InputGreetingEntity"]: {
	id?:string | null,
	html?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteGreetingEntity"]: {
	id?:string | null
};
	["InputHourDeclarationTypeEntity"]: {
	id?:string | null,
	companyId?:string | null,
	administrationType?:string | null,
	kind?:string | null,
	kindDescription?:string | null,
	description?:string | null,
	hasActivities?:string | null,
	isExternal?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHourDeclarationTypeEntity"]: {
	id?:string | null
};
	["InputHourDeclarationActivityEntity"]: {
	id?:string | null,
	companyId?:string | null,
	administrationType?:string | null,
	description?:string | null,
	syncVersion?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHourDeclarationActivityEntity"]: {
	id?:string | null
};
	["InputHourDeclarationEntity"]: {
	id?:string | null,
	userId?:string | null,
	hourDeclarationTypeId?:string | null,
	projectId?:string | null,
	hourDeclarationActivityId?:string | null,
	priceAgreementId?:string | null,
	storedDocumentId?:string | null,
	date?:ValueTypes["Date"] | null,
	description?:string | null,
	amount?:string | null,
	baseHours?:string | null,
	savingHours?:string | null,
	overTimeHours?:string | null,
	travelHours?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteHourDeclarationEntity"]: {
	id?:string | null
};
	["InputHourDeclarationApprovalRequestEntity"]: {
	id?:string | null,
	userId?:string | null,
	period?:string | null,
	createdAt?:ValueTypes["Date"] | null
};
	["InputDeleteHourDeclarationApprovalRequestEntity"]: {
	id?:string | null
};
	["InputInstallationReportCommentEntity"]: {
	id?:string | null,
	projectId?:string | null,
	stepId?:string | null,
	userId?:string | null,
	message?:string | null,
	date?:string | null,
	performerName?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportCommentEntity"]: {
	id?:string | null
};
	["InputInstallationReportDescriptionEntity"]: {
	id?:string | null,
	stepId?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportDescriptionEntity"]: {
	id?:string | null
};
	["InputInstallationReportFinalEntity"]: {
	id?:string | null,
	projectId?:string | null,
	userId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportFinalEntity"]: {
	id?:string | null
};
	["InputInstallationReportMeasurementTemplateEntity"]: {
	id?:string | null,
	measureTableId?:string | null,
	measureId?:string | null,
	defaultFields?:ValueTypes["JSON"] | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportMeasurementTemplateEntity"]: {
	id?:string | null
};
	["InputInstallationReportMeasurementEntity"]: {
	id?:string | null,
	projectId?:string | null,
	measureTableId?:string | null,
	measureId?:string | null,
	fields?:ValueTypes["JSON"] | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportMeasurementEntity"]: {
	id?:string | null
};
	["InputInstallationReportQuestionGroupEntity"]: {
	id?:string | null,
	name?:string | null,
	stepId?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportQuestionGroupEntity"]: {
	id?:string | null
};
	["InputInstallationReportQuestionEntity"]: {
	id?:string | null,
	questionGroupId?:string | null,
	content?:string | null,
	tooltip?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportQuestionEntity"]: {
	id?:string | null
};
	["InputInstallationReportQuestionAnswerEntity"]: {
	id?:string | null,
	projectId?:string | null,
	questionId?:string | null,
	content?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInstallationReportQuestionAnswerEntity"]: {
	id?:string | null
};
	["InputLegacyProjectEntity"]: {
	id?:string | null,
	description?:string | null,
	houseType?:string | null,
	regionId?:string | null,
	buildingStreet?:string | null,
	buildingCity?:string | null,
	buildingZipCode?:string | null,
	deliveryDate?:string | null,
	customerEmail?:string | null,
	syncVersion?:string | null,
	syncSource?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteLegacyProjectEntity"]: {
	id?:string | null
};
	["InputPicklistCommentEntity"]: {
	id?:string | null,
	projectId?:string | null,
	picklistDefinitionId?:string | null,
	userId?:string | null,
	text?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePicklistCommentEntity"]: {
	id?:string | null
};
	["InputPicklistElementStateEntity"]: {
	id?:string | null,
	elementId?:string | null,
	projectId?:string | null,
	picklistDefinitionId?:string | null,
	state?:string | null,
	pickedByUserId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePicklistElementStateEntity"]: {
	id?:string | null
};
	["InputPlanningProjectItemEntity"]: {
	id?:string | null,
	parentId?:string | null,
	dimension?:string | null,
	projectId?:string | null,
	competenceId?:string | null,
	competenceIndex?:number | null,
	competenceNumber?:string | null,
	competenceStartsAt?:ValueTypes["Date"] | null,
	competenceEndsAt?:ValueTypes["Date"] | null,
	hours?:string | null,
	humanResourceId?:string | null,
	humanResourceNumber?:string | null,
	humanResourceStartsAt?:ValueTypes["Date"] | null,
	humanResourceEndsAt?:ValueTypes["Date"] | null,
	comments?:string | null,
	status?:string | null,
	version?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeletePlanningProjectItemEntity"]: {
	id?:string | null
};
	["InputProjectCargoStateEntity"]: {
	id?:string | null,
	projectId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectCargoStateEntity"]: {
	id?:string | null
};
	["InputProjectPaymentEntity"]: {
	id?:string | null,
	projectId?:string | null,
	userId?:string | null,
	description?:string | null,
	payedAt?:ValueTypes["Date"] | null,
	amount?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteProjectPaymentEntity"]: {
	id?:string | null
};
	["InputQualityAssuranceEntity"]: {
	id?:string | null,
	projectId?:string | null,
	data?:ValueTypes["JSON"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteQualityAssuranceEntity"]: {
	id?:string | null
};
	["InputServiceMapColorEntity"]: {
	id?:string | null,
	days?:number | null,
	color?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServiceMapColorEntity"]: {
	id?:string | null
};
	["InputServicePlanningStageEntity"]: {
	id?:string | null,
	name?:string | null,
	colorCode?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServicePlanningStageEntity"]: {
	id?:string | null
};
	["InputServicePlanningEventEntity"]: {
	id?:string | null,
	legacyProjectId?:string | null,
	stageId?:string | null,
	userId?:string | null,
	serviceTicketIds?:ValueTypes["JSON"] | null,
	memo?:string | null,
	duration?:number | null,
	durationUnit?:string | null,
	durationPlanned?:number | null,
	durationSpend?:number | null,
	isFinalized?:boolean | null,
	startDate?:ValueTypes["Date"] | null,
	endDate?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServicePlanningEventEntity"]: {
	id?:string | null
};
	["InputServicePlanningAssignmentEntity"]: {
	id?:string | null,
	eventId?:string | null,
	resourceId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServicePlanningAssignmentEntity"]: {
	id?:string | null
};
	["InputServicePresetEntity"]: {
	id?:string | null,
	primaryCategoryId?:number | null,
	secondaryCategoryId?:number | null,
	code?:string | null,
	description?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteServicePresetEntity"]: {
	id?:string | null
};
	["InputSupplierDocumentEntity"]: {
	id?:string | null,
	userId?:string | null,
	informedUserId?:string | null,
	informedContactTypes?:ValueTypes["JSON"] | null,
	projectId?:string | null,
	documentMetaId?:string | null,
	contextId?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteSupplierDocumentEntity"]: {
	id?:string | null
};
	["InputSupplyInspectionQuestionEntity"]: {
	id?:string | null,
	supplyInspectionQuestionGroupId?:string | null,
	departmentIds?:ValueTypes["JSON"] | null,
	description?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteSupplyInspectionQuestionEntity"]: {
	id?:string | null
};
	["InputSupplyInspectionQuestionGroupEntity"]: {
	id?:string | null,
	description?:string | null,
	orderId?:number | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteSupplyInspectionQuestionGroupEntity"]: {
	id?:string | null
};
	["InputUserPasswordResetEntity"]: {
	id?:string | null,
	userId?:string | null,
	expiresAt?:ValueTypes["Date"] | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteUserPasswordResetEntity"]: {
	id?:string | null
};
	["InputYearPlanningLineEntity"]: {
	id?:string | null,
	description?:string | null,
	weeks?:number | null,
	colorCode?:string | null,
	visibleOnConstructionPlanning?:boolean | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteYearPlanningLineEntity"]: {
	id?:string | null
};
	["InputInvoicePaymentBatchEntityEntity"]: {
	id?:string | null,
	batch?:number | null,
	document?:string | null,
	name?:string | null,
	date?:ValueTypes["Date"] | null,
	amount?:number | null,
	amountBank?:number | null,
	companyId?:string | null,
	companyName?:string | null,
	relationId?:string | null,
	relationName?:string | null,
	createdAt?:ValueTypes["Date"] | null,
	updatedAt?:ValueTypes["Date"] | null
};
	["InputDeleteInvoicePaymentBatchEntityEntity"]: {
	id?:string | null
};
	["InputAppPhaseContactEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	phase_id?:number | null,
	type?:string | null,
	title?:string | null,
	receive_notifications?:boolean | null
};
	["InputDeleteAppPhaseContactEntity"]: {
	id?:number | null
};
	["InputAppPhaseEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	name?:string | null,
	order?:number | null,
	percentage?:number | null,
	is_visible_transferred?:boolean | null,
	start_project_date_type?:string | null,
	end_project_date_type?:string | null,
	days?:number | null
};
	["InputDeleteAppPhaseEntity"]: {
	id?:number | null
};
	["InputAppPhaseSectionEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	phase_id?:number | null,
	more_info_content_id?:number | null,
	name?:string | null,
	order?:number | null,
	percentage?:number | null
};
	["InputDeleteAppPhaseSectionEntity"]: {
	id?:number | null
};
	["InputAppNotificationTemplateEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	content_id?:number | null,
	subject?:string | null,
	body?:string | null
};
	["InputDeleteAppNotificationTemplateEntity"]: {
	id?:number | null
};
	["InputAppPhaseSectionMarkEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	phase_section_id?:number | null,
	document_type_id?:number | null,
	more_info_content_id?:number | null,
	notification_template_id?:number | null,
	project_field_group_id?:number | null,
	project_date_type?:string | null,
	responsible?:string | null,
	name?:string | null,
	order?:number | null,
	percentage?:number | null,
	is_milestone?:boolean | null,
	is_standard?:boolean | null,
	date_prompt?:boolean | null,
	duration_days?:number | null,
	timeline_origin_date_type?:string | null,
	timeline_competence_number?:string | null,
	timeline_origin_diff_days?:number | null
};
	["InputDeleteAppPhaseSectionMarkEntity"]: {
	id?:number | null
};
	["InputAppProjectDateEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_id?:string | null,
	value?:string | null,
	type?:string | null
};
	["InputDeleteAppProjectDateEntity"]: {
	id?:number | null
};
	["InputAppNotificationScheduleEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	phase_section_mark_id?:number | null,
	phase_section_mark_cancel_id?:number | null,
	notification_template_id?:number | null,
	days_offset?:number | null,
	time_of_day?:string | null
};
	["InputDeleteAppNotificationScheduleEntity"]: {
	id?:number | null
};
	["InputAppPhaseSectionMarkValueEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_id?:string | null,
	phase_section_mark_id?:number | null,
	completed_user_id?:number | null,
	send_notification?:boolean | null,
	activated?:boolean | null,
	completed?:boolean | null,
	completed_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppPhaseSectionMarkValueEntity"]: {
	id?:number | null
};
	["InputAppInviteEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:string | null,
	project_id?:string | null,
	email?:string | null
};
	["InputDeleteAppInviteEntity"]: {
	id?:string | null
};
	["InputAppLoginEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	relation_id?:string | null,
	project_id?:string | null,
	transferred_at?:ValueTypes["Date"] | null,
	email?:string | null,
	role_id?:string | null
};
	["InputDeleteAppLoginEntity"]: {
	id?:number | null
};
	["InputAppNotificationEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:string | null,
	type?:string | null,
	notifiable_id?:number | null,
	notifiable_type?:string | null,
	data?:ValueTypes["JSON"] | null,
	read_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppNotificationEntity"]: {
	id?:string | null
};
	["InputAppProjectEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	contract_sum?:string | null
};
	["InputDeleteAppProjectEntity"]: {
	id?:number | null
};
	["InputAppUserEntity"]: {
	id?:number | null,
	project_id?:number | null,
	relation_id?:string | null,
	email?:string | null,
	telephone?:string | null,
	facebook_identity?:string | null,
	google_identity?:string | null,
	password?:string | null,
	first_name?:string | null,
	last_name_prefix?:string | null,
	last_name?:string | null,
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppUserEntity"]: {
	id?:number | null
};
	["InputAppProjectCostEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	user_id?:number | null,
	approved_user_id?:number | null,
	declined_user_id?:number | null,
	project_id?:number | null,
	parent_id?:number | null,
	title?:string | null,
	memo?:string | null,
	value?:string | null,
	approved_at?:ValueTypes["Date"] | null,
	declined_at?:ValueTypes["Date"] | null,
	requested_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppProjectCostEntity"]: {
	id?:number | null
};
	["InputAppProjectContactEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_id?:string | null,
	relation_id?:string | null,
	type?:string | null
};
	["InputDeleteAppProjectContactEntity"]: {
	id?:number | null
};
	["InputAppBuildingWeekEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:string | null,
	isBlocked?:boolean | null,
	syncVersion?:string | null
};
	["InputDeleteAppBuildingWeekEntity"]: {
	id?:string | null
};
	["InputAppContentEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	title?:string | null,
	html?:string | null
};
	["InputDeleteAppContentEntity"]: {
	id?:number | null
};
	["InputAppConversationMessagePresetEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	title?:string | null,
	message?:string | null
};
	["InputDeleteAppConversationMessagePresetEntity"]: {
	id?:number | null
};
	["InputAppDocumentSignatureEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	user_id?:number | null,
	document_id?:string | null,
	payload?:ValueTypes["JSON"] | null
};
	["InputDeleteAppDocumentSignatureEntity"]: {
	id?:number | null
};
	["InputAppDocumentTypeEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	name?:string | null,
	description?:string | null,
	color?:string | null,
	document_kind?:string | null,
	order_id?:number | null,
	special_access?:boolean | null,
	project_contact_type?:string | null
};
	["InputDeleteAppDocumentTypeEntity"]: {
	id?:number | null
};
	["InputAppDocumentEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:string | null,
	project_id?:string | null,
	user_id?:number | null,
	document_type_id?:number | null,
	document_kind?:string | null,
	description?:string | null,
	confidential?:boolean | null,
	mime_type?:string | null,
	extension?:string | null,
	storage_id?:string | null
};
	["InputDeleteAppDocumentEntity"]: {
	id?:string | null
};
	["InputAppNotificationScheduleResourceEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_id?:string | null,
	notification_schedule_id?:number | null,
	schedule_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppNotificationScheduleResourceEntity"]: {
	id?:number | null
};
	["InputAppPlanCompetenceEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	number?:string | null,
	description?:string | null,
	index?:number | null,
	color?:string | null
};
	["InputDeleteAppPlanCompetenceEntity"]: {
	id?:number | null
};
	["InputAppPlanCompetenceSettingEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	plan_competence_id?:number | null,
	content_id?:number | null,
	is_visible?:boolean | null,
	name?:string | null
};
	["InputDeleteAppPlanCompetenceSettingEntity"]: {
	id?:number | null
};
	["InputAppProjectCostPresetEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	title?:string | null,
	description?:string | null
};
	["InputDeleteAppProjectCostPresetEntity"]: {
	id?:number | null
};
	["InputAppProjectFieldSheetEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_contact_type?:string | null,
	name?:string | null,
	order?:number | null
};
	["InputDeleteAppProjectFieldSheetEntity"]: {
	id?:number | null
};
	["InputAppProjectFieldGroupEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_field_sheet_id?:number | null,
	name?:string | null,
	order?:number | null
};
	["InputDeleteAppProjectFieldGroupEntity"]: {
	id?:number | null
};
	["InputAppProjectFieldGroupAssignmentEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_field_group_id?:number | null,
	project_field_type?:string | null,
	project_field_copy_type?:string | null,
	value_type?:string | null,
	is_required?:boolean | null,
	order?:number | null,
	tooltip?:string | null
};
	["InputDeleteAppProjectFieldGroupAssignmentEntity"]: {
	id?:number | null
};
	["InputAppProjectFieldGroupAssignmentValueEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	project_field_group_assignment_id?:number | null,
	value?:string | null
};
	["InputDeleteAppProjectFieldGroupAssignmentValueEntity"]: {
	id?:number | null
};
	["InputAppProjectFieldEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	value?:string | null,
	project_id?:string | null,
	type?:string | null
};
	["InputDeleteAppProjectFieldEntity"]: {
	id?:number | null
};
	["InputAppServiceCategoryEntity"]: {
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null,
	id?:number | null,
	service_category_id?:number | null,
	name?:string | null
};
	["InputDeleteAppServiceCategoryEntity"]: {
	id?:number | null
};
	["InputAppTextEntity"]: {
	id?:number | null,
	value?:string | null,
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppTextEntity"]: {
	id?:number | null
};
	["InputAppRelationEntity"]: {
	id?:number | null,
	name?:string | null,
	email?:string | null,
	is_blacklisted?:string | null,
	sync_version?:string | null,
	created_at?:ValueTypes["Date"] | null,
	updated_at?:ValueTypes["Date"] | null
};
	["InputDeleteAppRelationEntity"]: {
	id?:number | null
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:unknown;
	["CellaCustomField"]: {
	Origin:string,
	Entity:string,
	LineId:number,
	Code:string,
	SerialNumber?:number | null,
	Contents:string
};
	["Subscription"]: AliasType<{
	GrantEntityOnCreatedOne?:ValueTypes["GrantEntity"],
	GrantEntityOnUpdatedOne?:ValueTypes["GrantEntity"],
	GrantEntityOnDeletedOne?:ValueTypes["GrantEntity"],
	RoleGrantConfigEntityOnCreatedOne?:ValueTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnUpdatedOne?:ValueTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnDeletedOne?:ValueTypes["RoleGrantConfigEntity"],
	RoleDocumentGrantEntityOnCreatedOne?:ValueTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnUpdatedOne?:ValueTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnDeletedOne?:ValueTypes["RoleDocumentGrantEntity"],
	RoleEntityOnCreatedOne?:ValueTypes["RoleEntity"],
	RoleEntityOnUpdatedOne?:ValueTypes["RoleEntity"],
	RoleEntityOnDeletedOne?:ValueTypes["RoleEntity"],
	UserGrantConfigEntityOnCreatedOne?:ValueTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnUpdatedOne?:ValueTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnDeletedOne?:ValueTypes["UserGrantConfigEntity"],
	UserNotificationSubscriptionEntityOnCreatedOne?:ValueTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnUpdatedOne?:ValueTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnDeletedOne?:ValueTypes["UserNotificationSubscriptionEntity"],
	UserNotificationEntityOnCreatedOne?:ValueTypes["UserNotificationEntity"],
	UserNotificationEntityOnUpdatedOne?:ValueTypes["UserNotificationEntity"],
	UserNotificationEntityOnDeletedOne?:ValueTypes["UserNotificationEntity"],
	UserTemporaryAccessTokenEntityEntityOnCreatedOne?:ValueTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnUpdatedOne?:ValueTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnDeletedOne?:ValueTypes["UserTemporaryAccessTokenEntityEntity"],
	UserEntityOnCreatedOne?:ValueTypes["UserEntity"],
	UserEntityOnUpdatedOne?:ValueTypes["UserEntity"],
	UserEntityOnDeletedOne?:ValueTypes["UserEntity"],
	PicklistDefinitionEntityOnCreatedOne?:ValueTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnUpdatedOne?:ValueTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnDeletedOne?:ValueTypes["PicklistDefinitionEntity"],
	PicklistStateEntityOnCreatedOne?:ValueTypes["PicklistStateEntity"],
	PicklistStateEntityOnUpdatedOne?:ValueTypes["PicklistStateEntity"],
	PicklistStateEntityOnDeletedOne?:ValueTypes["PicklistStateEntity"],
	HousePartGroupEntityOnCreatedOne?:ValueTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnUpdatedOne?:ValueTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnDeletedOne?:ValueTypes["HousePartGroupEntity"],
	HousePartEntityOnCreatedOne?:ValueTypes["HousePartEntity"],
	HousePartEntityOnUpdatedOne?:ValueTypes["HousePartEntity"],
	HousePartEntityOnDeletedOne?:ValueTypes["HousePartEntity"],
	CargoPhaseEntityOnCreatedOne?:ValueTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnUpdatedOne?:ValueTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnDeletedOne?:ValueTypes["CargoPhaseEntity"],
	CargoTypeEntityOnCreatedOne?:ValueTypes["CargoTypeEntity"],
	CargoTypeEntityOnUpdatedOne?:ValueTypes["CargoTypeEntity"],
	CargoTypeEntityOnDeletedOne?:ValueTypes["CargoTypeEntity"],
	PlanningHumanResourceEntityOnCreatedOne?:ValueTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnUpdatedOne?:ValueTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnDeletedOne?:ValueTypes["PlanningHumanResourceEntity"],
	PlanningProjectAssignmentEntityOnCreatedOne?:ValueTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnUpdatedOne?:ValueTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnDeletedOne?:ValueTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectCompetencePlacementEntityOnCreatedOne?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnUpdatedOne?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnDeletedOne?:ValueTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectRequestPlacementEntityOnCreatedOne?:ValueTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnUpdatedOne?:ValueTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnDeletedOne?:ValueTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectEntityOnCreatedOne?:ValueTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnUpdatedOne?:ValueTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnDeletedOne?:ValueTypes["PlanningProjectEntity"],
	PlanningProjectRequestEntityOnCreatedOne?:ValueTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnUpdatedOne?:ValueTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnDeletedOne?:ValueTypes["PlanningProjectRequestEntity"],
	PlanningProjectCompetenceEntityOnCreatedOne?:ValueTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnUpdatedOne?:ValueTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnDeletedOne?:ValueTypes["PlanningProjectCompetenceEntity"],
	PlanningCompetenceEntityOnCreatedOne?:ValueTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnUpdatedOne?:ValueTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnDeletedOne?:ValueTypes["PlanningCompetenceEntity"],
	CargoStateEntityOnCreatedOne?:ValueTypes["CargoStateEntity"],
	CargoStateEntityOnUpdatedOne?:ValueTypes["CargoStateEntity"],
	CargoStateEntityOnDeletedOne?:ValueTypes["CargoStateEntity"],
	CargoEntityOnCreatedOne?:ValueTypes["CargoEntity"],
	CargoEntityOnUpdatedOne?:ValueTypes["CargoEntity"],
	CargoEntityOnDeletedOne?:ValueTypes["CargoEntity"],
	ProjectHousePartEntityOnCreatedOne?:ValueTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnUpdatedOne?:ValueTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnDeletedOne?:ValueTypes["ProjectHousePartEntity"],
	PicklistElementEntityOnCreatedOne?:ValueTypes["PicklistElementEntity"],
	PicklistElementEntityOnUpdatedOne?:ValueTypes["PicklistElementEntity"],
	PicklistElementEntityOnDeletedOne?:ValueTypes["PicklistElementEntity"],
	PicklistEntityOnCreatedOne?:ValueTypes["PicklistEntity"],
	PicklistEntityOnUpdatedOne?:ValueTypes["PicklistEntity"],
	PicklistEntityOnDeletedOne?:ValueTypes["PicklistEntity"],
	DrawOrderTypeEntityOnCreatedOne?:ValueTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnUpdatedOne?:ValueTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnDeletedOne?:ValueTypes["DrawOrderTypeEntity"],
	HouseModelEntityOnCreatedOne?:ValueTypes["HouseModelEntity"],
	HouseModelEntityOnUpdatedOne?:ValueTypes["HouseModelEntity"],
	HouseModelEntityOnDeletedOne?:ValueTypes["HouseModelEntity"],
	SalesEmployeeEntityOnCreatedOne?:ValueTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnUpdatedOne?:ValueTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnDeletedOne?:ValueTypes["SalesEmployeeEntity"],
	DrawerAbsentEntityOnCreatedOne?:ValueTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnUpdatedOne?:ValueTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnDeletedOne?:ValueTypes["DrawerAbsentEntity"],
	DrawerEntityOnCreatedOne?:ValueTypes["DrawerEntity"],
	DrawerEntityOnUpdatedOne?:ValueTypes["DrawerEntity"],
	DrawerEntityOnDeletedOne?:ValueTypes["DrawerEntity"],
	DrawOrderEntityOnCreatedOne?:ValueTypes["DrawOrderEntity"],
	DrawOrderEntityOnUpdatedOne?:ValueTypes["DrawOrderEntity"],
	DrawOrderEntityOnDeletedOne?:ValueTypes["DrawOrderEntity"],
	DateGroupCommentEntityOnCreatedOne?:ValueTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnUpdatedOne?:ValueTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnDeletedOne?:ValueTypes["DateGroupCommentEntity"],
	DateGroupResponsibleEntityOnCreatedOne?:ValueTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnUpdatedOne?:ValueTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnDeletedOne?:ValueTypes["DateGroupResponsibleEntity"],
	LogicFieldEntityOnCreatedOne?:ValueTypes["LogicFieldEntity"],
	LogicFieldEntityOnUpdatedOne?:ValueTypes["LogicFieldEntity"],
	LogicFieldEntityOnDeletedOne?:ValueTypes["LogicFieldEntity"],
	DateGroupEntityOnCreatedOne?:ValueTypes["DateGroupEntity"],
	DateGroupEntityOnUpdatedOne?:ValueTypes["DateGroupEntity"],
	DateGroupEntityOnDeletedOne?:ValueTypes["DateGroupEntity"],
	DateEntityOnCreatedOne?:ValueTypes["DateEntity"],
	DateEntityOnUpdatedOne?:ValueTypes["DateEntity"],
	DateEntityOnDeletedOne?:ValueTypes["DateEntity"],
	DateValueEntityOnCreatedOne?:ValueTypes["DateValueEntity"],
	DateValueEntityOnUpdatedOne?:ValueTypes["DateValueEntity"],
	DateValueEntityOnDeletedOne?:ValueTypes["DateValueEntity"],
	LogicFieldValueEntityOnCreatedOne?:ValueTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnUpdatedOne?:ValueTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnDeletedOne?:ValueTypes["LogicFieldValueEntity"],
	DateCacheEntityOnCreatedOne?:ValueTypes["DateCacheEntity"],
	DateCacheEntityOnUpdatedOne?:ValueTypes["DateCacheEntity"],
	DateCacheEntityOnDeletedOne?:ValueTypes["DateCacheEntity"],
	YearPlanningModificationEntityOnCreatedOne?:ValueTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnUpdatedOne?:ValueTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnDeletedOne?:ValueTypes["YearPlanningModificationEntity"],
	ProjectCachedValueEntityOnCreatedOne?:ValueTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnUpdatedOne?:ValueTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnDeletedOne?:ValueTypes["ProjectCachedValueEntity"],
	TableLayoutCommentEntityOnCreatedOne?:ValueTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnUpdatedOne?:ValueTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnDeletedOne?:ValueTypes["TableLayoutCommentEntity"],
	ProjectEntityOnCreatedOne?:ValueTypes["ProjectEntity"],
	ProjectEntityOnUpdatedOne?:ValueTypes["ProjectEntity"],
	ProjectEntityOnDeletedOne?:ValueTypes["ProjectEntity"],
	SyncCronEntityOnCreatedOne?:ValueTypes["SyncCronEntity"],
	SyncCronEntityOnUpdatedOne?:ValueTypes["SyncCronEntity"],
	SyncCronEntityOnDeletedOne?:ValueTypes["SyncCronEntity"],
	MailSchedulerEntityOnCreatedOne?:ValueTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnUpdatedOne?:ValueTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnDeletedOne?:ValueTypes["MailSchedulerEntity"],
	ProjectPartnerEntityOnCreatedOne?:ValueTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnUpdatedOne?:ValueTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnDeletedOne?:ValueTypes["ProjectPartnerEntity"],
	PartnerEntityOnCreatedOne?:ValueTypes["PartnerEntity"],
	PartnerEntityOnUpdatedOne?:ValueTypes["PartnerEntity"],
	PartnerEntityOnDeletedOne?:ValueTypes["PartnerEntity"],
	DocumentMetaEntityOnCreatedOne?:ValueTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnUpdatedOne?:ValueTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnDeletedOne?:ValueTypes["DocumentMetaEntity"],
	EntityAdvancementEntityOnCreatedOne?:ValueTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnUpdatedOne?:ValueTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnDeletedOne?:ValueTypes["EntityAdvancementEntity"],
	WorkActionCategoryEntityOnCreatedOne?:ValueTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnUpdatedOne?:ValueTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnDeletedOne?:ValueTypes["WorkActionCategoryEntity"],
	WorkActionEntityOnCreatedOne?:ValueTypes["WorkActionEntity"],
	WorkActionEntityOnUpdatedOne?:ValueTypes["WorkActionEntity"],
	WorkActionEntityOnDeletedOne?:ValueTypes["WorkActionEntity"],
	EntityImageEntityOnCreatedOne?:ValueTypes["EntityImageEntity"],
	EntityImageEntityOnUpdatedOne?:ValueTypes["EntityImageEntity"],
	EntityImageEntityOnDeletedOne?:ValueTypes["EntityImageEntity"],
	CustomerQuestionEntityOnCreatedOne?:ValueTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnUpdatedOne?:ValueTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnDeletedOne?:ValueTypes["CustomerQuestionEntity"],
	AuditCategoryEntityOnCreatedOne?:ValueTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnUpdatedOne?:ValueTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnDeletedOne?:ValueTypes["AuditCategoryEntity"],
	AuditQuestionEntityOnCreatedOne?:ValueTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnUpdatedOne?:ValueTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnDeletedOne?:ValueTypes["AuditQuestionEntity"],
	AuditQuestionCostCategoryEntityOnCreatedOne?:ValueTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnUpdatedOne?:ValueTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnDeletedOne?:ValueTypes["AuditQuestionCostCategoryEntity"],
	CostCategoryEntityOnCreatedOne?:ValueTypes["CostCategoryEntity"],
	CostCategoryEntityOnUpdatedOne?:ValueTypes["CostCategoryEntity"],
	CostCategoryEntityOnDeletedOne?:ValueTypes["CostCategoryEntity"],
	AuditReportItemImageEntityOnCreatedOne?:ValueTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnUpdatedOne?:ValueTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnDeletedOne?:ValueTypes["AuditReportItemImageEntity"],
	AuditReportItemEntityOnCreatedOne?:ValueTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnUpdatedOne?:ValueTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnDeletedOne?:ValueTypes["AuditReportItemEntity"],
	AuditReportEntityOnCreatedOne?:ValueTypes["AuditReportEntity"],
	AuditReportEntityOnUpdatedOne?:ValueTypes["AuditReportEntity"],
	AuditReportEntityOnDeletedOne?:ValueTypes["AuditReportEntity"],
	AuditChecklistImageEntityOnCreatedOne?:ValueTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnUpdatedOne?:ValueTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnDeletedOne?:ValueTypes["AuditChecklistImageEntity"],
	InvoiceAttachmentEntityOnCreatedOne?:ValueTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnUpdatedOne?:ValueTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnDeletedOne?:ValueTypes["InvoiceAttachmentEntity"],
	EmployeeDocumentEntityOnCreatedOne?:ValueTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnUpdatedOne?:ValueTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnDeletedOne?:ValueTypes["EmployeeDocumentEntity"],
	WorkActionTemplateGroupEntityOnCreatedOne?:ValueTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnUpdatedOne?:ValueTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnDeletedOne?:ValueTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateEntityOnCreatedOne?:ValueTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnUpdatedOne?:ValueTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnDeletedOne?:ValueTypes["WorkActionTemplateEntity"],
	ProjectCostInfoEntityOnCreatedOne?:ValueTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnUpdatedOne?:ValueTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnDeletedOne?:ValueTypes["ProjectCostInfoEntity"],
	BlockedCalendarWeekEntityOnCreatedOne?:ValueTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnUpdatedOne?:ValueTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnDeletedOne?:ValueTypes["BlockedCalendarWeekEntity"],
	MaintenanceReportEntityOnCreatedOne?:ValueTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnUpdatedOne?:ValueTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnDeletedOne?:ValueTypes["MaintenanceReportEntity"],
	ConfigurationEntityOnCreatedOne?:ValueTypes["ConfigurationEntity"],
	ConfigurationEntityOnUpdatedOne?:ValueTypes["ConfigurationEntity"],
	ConfigurationEntityOnDeletedOne?:ValueTypes["ConfigurationEntity"],
	ServiceMeterEntityOnCreatedOne?:ValueTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnUpdatedOne?:ValueTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnDeletedOne?:ValueTypes["ServiceMeterEntity"],
	DeliveryListMeterEntityOnCreatedOne?:ValueTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnUpdatedOne?:ValueTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnDeletedOne?:ValueTypes["DeliveryListMeterEntity"],
	ServiceSpaceEntityOnCreatedOne?:ValueTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnUpdatedOne?:ValueTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnDeletedOne?:ValueTypes["ServiceSpaceEntity"],
	ServiceTypeEntityOnCreatedOne?:ValueTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnUpdatedOne?:ValueTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnDeletedOne?:ValueTypes["ServiceTypeEntity"],
	DeliveryListPointImageEntityOnCreatedOne?:ValueTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnUpdatedOne?:ValueTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnDeletedOne?:ValueTypes["DeliveryListPointImageEntity"],
	ServicePriorityEntityOnCreatedOne?:ValueTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnUpdatedOne?:ValueTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnDeletedOne?:ValueTypes["ServicePriorityEntity"],
	DeliveryListPointEntityOnCreatedOne?:ValueTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnUpdatedOne?:ValueTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnDeletedOne?:ValueTypes["DeliveryListPointEntity"],
	DeliveryListSignatureEntityOnCreatedOne?:ValueTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnUpdatedOne?:ValueTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnDeletedOne?:ValueTypes["DeliveryListSignatureEntity"],
	DeliveryListEntityOnCreatedOne?:ValueTypes["DeliveryListEntity"],
	DeliveryListEntityOnUpdatedOne?:ValueTypes["DeliveryListEntity"],
	DeliveryListEntityOnDeletedOne?:ValueTypes["DeliveryListEntity"],
	SupplyInspectionReportAnswerEntityOnCreatedOne?:ValueTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnUpdatedOne?:ValueTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnDeletedOne?:ValueTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportEntityOnCreatedOne?:ValueTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnUpdatedOne?:ValueTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnDeletedOne?:ValueTypes["SupplyInspectionReportEntity"],
	DepartmentEntityOnCreatedOne?:ValueTypes["DepartmentEntity"],
	DepartmentEntityOnUpdatedOne?:ValueTypes["DepartmentEntity"],
	DepartmentEntityOnDeletedOne?:ValueTypes["DepartmentEntity"],
	DrawOrderDescriptionEntityOnCreatedOne?:ValueTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnUpdatedOne?:ValueTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnDeletedOne?:ValueTypes["DrawOrderDescriptionEntity"],
	GreetingEntityOnCreatedOne?:ValueTypes["GreetingEntity"],
	GreetingEntityOnUpdatedOne?:ValueTypes["GreetingEntity"],
	GreetingEntityOnDeletedOne?:ValueTypes["GreetingEntity"],
	HourDeclarationTypeEntityOnCreatedOne?:ValueTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnUpdatedOne?:ValueTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnDeletedOne?:ValueTypes["HourDeclarationTypeEntity"],
	HourDeclarationActivityEntityOnCreatedOne?:ValueTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnUpdatedOne?:ValueTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnDeletedOne?:ValueTypes["HourDeclarationActivityEntity"],
	HourDeclarationEntityOnCreatedOne?:ValueTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnUpdatedOne?:ValueTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnDeletedOne?:ValueTypes["HourDeclarationEntity"],
	HourDeclarationApprovalRequestEntityOnCreatedOne?:ValueTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnUpdatedOne?:ValueTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnDeletedOne?:ValueTypes["HourDeclarationApprovalRequestEntity"],
	InstallationReportCommentEntityOnCreatedOne?:ValueTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnUpdatedOne?:ValueTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnDeletedOne?:ValueTypes["InstallationReportCommentEntity"],
	InstallationReportDescriptionEntityOnCreatedOne?:ValueTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnUpdatedOne?:ValueTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnDeletedOne?:ValueTypes["InstallationReportDescriptionEntity"],
	InstallationReportFinalEntityOnCreatedOne?:ValueTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnUpdatedOne?:ValueTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnDeletedOne?:ValueTypes["InstallationReportFinalEntity"],
	InstallationReportMeasurementTemplateEntityOnCreatedOne?:ValueTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnUpdatedOne?:ValueTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnDeletedOne?:ValueTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementEntityOnCreatedOne?:ValueTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnUpdatedOne?:ValueTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnDeletedOne?:ValueTypes["InstallationReportMeasurementEntity"],
	InstallationReportQuestionGroupEntityOnCreatedOne?:ValueTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnUpdatedOne?:ValueTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnDeletedOne?:ValueTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionEntityOnCreatedOne?:ValueTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnUpdatedOne?:ValueTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnDeletedOne?:ValueTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionAnswerEntityOnCreatedOne?:ValueTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnUpdatedOne?:ValueTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnDeletedOne?:ValueTypes["InstallationReportQuestionAnswerEntity"],
	LegacyProjectEntityOnCreatedOne?:ValueTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnUpdatedOne?:ValueTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnDeletedOne?:ValueTypes["LegacyProjectEntity"],
	PicklistCommentEntityOnCreatedOne?:ValueTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnUpdatedOne?:ValueTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnDeletedOne?:ValueTypes["PicklistCommentEntity"],
	PicklistElementStateEntityOnCreatedOne?:ValueTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnUpdatedOne?:ValueTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnDeletedOne?:ValueTypes["PicklistElementStateEntity"],
	PlanningProjectItemEntityOnCreatedOne?:ValueTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnUpdatedOne?:ValueTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnDeletedOne?:ValueTypes["PlanningProjectItemEntity"],
	ProjectCargoStateEntityOnCreatedOne?:ValueTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnUpdatedOne?:ValueTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnDeletedOne?:ValueTypes["ProjectCargoStateEntity"],
	ProjectPaymentEntityOnCreatedOne?:ValueTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnUpdatedOne?:ValueTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnDeletedOne?:ValueTypes["ProjectPaymentEntity"],
	QualityAssuranceEntityOnCreatedOne?:ValueTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnUpdatedOne?:ValueTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnDeletedOne?:ValueTypes["QualityAssuranceEntity"],
	ServiceMapColorEntityOnCreatedOne?:ValueTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnUpdatedOne?:ValueTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnDeletedOne?:ValueTypes["ServiceMapColorEntity"],
	ServicePlanningStageEntityOnCreatedOne?:ValueTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnUpdatedOne?:ValueTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnDeletedOne?:ValueTypes["ServicePlanningStageEntity"],
	ServicePlanningEventEntityOnCreatedOne?:ValueTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnUpdatedOne?:ValueTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnDeletedOne?:ValueTypes["ServicePlanningEventEntity"],
	ServicePlanningAssignmentEntityOnCreatedOne?:ValueTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnUpdatedOne?:ValueTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnDeletedOne?:ValueTypes["ServicePlanningAssignmentEntity"],
	ServicePresetEntityOnCreatedOne?:ValueTypes["ServicePresetEntity"],
	ServicePresetEntityOnUpdatedOne?:ValueTypes["ServicePresetEntity"],
	ServicePresetEntityOnDeletedOne?:ValueTypes["ServicePresetEntity"],
	SupplierDocumentEntityOnCreatedOne?:ValueTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnUpdatedOne?:ValueTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnDeletedOne?:ValueTypes["SupplierDocumentEntity"],
	SupplyInspectionQuestionEntityOnCreatedOne?:ValueTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnUpdatedOne?:ValueTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnDeletedOne?:ValueTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionGroupEntityOnCreatedOne?:ValueTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnUpdatedOne?:ValueTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnDeletedOne?:ValueTypes["SupplyInspectionQuestionGroupEntity"],
	UserPasswordResetEntityOnCreatedOne?:ValueTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnUpdatedOne?:ValueTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnDeletedOne?:ValueTypes["UserPasswordResetEntity"],
	YearPlanningLineEntityOnCreatedOne?:ValueTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnUpdatedOne?:ValueTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnDeletedOne?:ValueTypes["YearPlanningLineEntity"],
	InvoicePaymentBatchEntityEntityOnCreatedOne?:ValueTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnUpdatedOne?:ValueTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnDeletedOne?:ValueTypes["InvoicePaymentBatchEntityEntity"],
	AppPhaseContactEntityOnCreatedOne?:ValueTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnUpdatedOne?:ValueTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnDeletedOne?:ValueTypes["AppPhaseContactEntity"],
	AppPhaseEntityOnCreatedOne?:ValueTypes["AppPhaseEntity"],
	AppPhaseEntityOnUpdatedOne?:ValueTypes["AppPhaseEntity"],
	AppPhaseEntityOnDeletedOne?:ValueTypes["AppPhaseEntity"],
	AppPhaseSectionEntityOnCreatedOne?:ValueTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnUpdatedOne?:ValueTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnDeletedOne?:ValueTypes["AppPhaseSectionEntity"],
	AppNotificationTemplateEntityOnCreatedOne?:ValueTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnUpdatedOne?:ValueTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnDeletedOne?:ValueTypes["AppNotificationTemplateEntity"],
	AppPhaseSectionMarkEntityOnCreatedOne?:ValueTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnUpdatedOne?:ValueTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnDeletedOne?:ValueTypes["AppPhaseSectionMarkEntity"],
	AppProjectDateEntityOnCreatedOne?:ValueTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnUpdatedOne?:ValueTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnDeletedOne?:ValueTypes["AppProjectDateEntity"],
	AppNotificationScheduleEntityOnCreatedOne?:ValueTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnUpdatedOne?:ValueTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnDeletedOne?:ValueTypes["AppNotificationScheduleEntity"],
	AppPhaseSectionMarkValueEntityOnCreatedOne?:ValueTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnUpdatedOne?:ValueTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnDeletedOne?:ValueTypes["AppPhaseSectionMarkValueEntity"],
	AppInviteEntityOnCreatedOne?:ValueTypes["AppInviteEntity"],
	AppInviteEntityOnUpdatedOne?:ValueTypes["AppInviteEntity"],
	AppInviteEntityOnDeletedOne?:ValueTypes["AppInviteEntity"],
	AppLoginEntityOnCreatedOne?:ValueTypes["AppLoginEntity"],
	AppLoginEntityOnUpdatedOne?:ValueTypes["AppLoginEntity"],
	AppLoginEntityOnDeletedOne?:ValueTypes["AppLoginEntity"],
	AppNotificationEntityOnCreatedOne?:ValueTypes["AppNotificationEntity"],
	AppNotificationEntityOnUpdatedOne?:ValueTypes["AppNotificationEntity"],
	AppNotificationEntityOnDeletedOne?:ValueTypes["AppNotificationEntity"],
	AppProjectEntityOnCreatedOne?:ValueTypes["AppProjectEntity"],
	AppProjectEntityOnUpdatedOne?:ValueTypes["AppProjectEntity"],
	AppProjectEntityOnDeletedOne?:ValueTypes["AppProjectEntity"],
	AppUserEntityOnCreatedOne?:ValueTypes["AppUserEntity"],
	AppUserEntityOnUpdatedOne?:ValueTypes["AppUserEntity"],
	AppUserEntityOnDeletedOne?:ValueTypes["AppUserEntity"],
	AppProjectCostEntityOnCreatedOne?:ValueTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnUpdatedOne?:ValueTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnDeletedOne?:ValueTypes["AppProjectCostEntity"],
	AppProjectContactEntityOnCreatedOne?:ValueTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnUpdatedOne?:ValueTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnDeletedOne?:ValueTypes["AppProjectContactEntity"],
	AppBuildingWeekEntityOnCreatedOne?:ValueTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnUpdatedOne?:ValueTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnDeletedOne?:ValueTypes["AppBuildingWeekEntity"],
	AppContentEntityOnCreatedOne?:ValueTypes["AppContentEntity"],
	AppContentEntityOnUpdatedOne?:ValueTypes["AppContentEntity"],
	AppContentEntityOnDeletedOne?:ValueTypes["AppContentEntity"],
	AppConversationMessagePresetEntityOnCreatedOne?:ValueTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnUpdatedOne?:ValueTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnDeletedOne?:ValueTypes["AppConversationMessagePresetEntity"],
	AppDocumentSignatureEntityOnCreatedOne?:ValueTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnUpdatedOne?:ValueTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnDeletedOne?:ValueTypes["AppDocumentSignatureEntity"],
	AppDocumentTypeEntityOnCreatedOne?:ValueTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnUpdatedOne?:ValueTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnDeletedOne?:ValueTypes["AppDocumentTypeEntity"],
	AppDocumentEntityOnCreatedOne?:ValueTypes["AppDocumentEntity"],
	AppDocumentEntityOnUpdatedOne?:ValueTypes["AppDocumentEntity"],
	AppDocumentEntityOnDeletedOne?:ValueTypes["AppDocumentEntity"],
	AppNotificationScheduleResourceEntityOnCreatedOne?:ValueTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnUpdatedOne?:ValueTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnDeletedOne?:ValueTypes["AppNotificationScheduleResourceEntity"],
	AppPlanCompetenceEntityOnCreatedOne?:ValueTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnUpdatedOne?:ValueTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnDeletedOne?:ValueTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceSettingEntityOnCreatedOne?:ValueTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnUpdatedOne?:ValueTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnDeletedOne?:ValueTypes["AppPlanCompetenceSettingEntity"],
	AppProjectCostPresetEntityOnCreatedOne?:ValueTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnUpdatedOne?:ValueTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnDeletedOne?:ValueTypes["AppProjectCostPresetEntity"],
	AppProjectFieldSheetEntityOnCreatedOne?:ValueTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnUpdatedOne?:ValueTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnDeletedOne?:ValueTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldGroupEntityOnCreatedOne?:ValueTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnUpdatedOne?:ValueTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnDeletedOne?:ValueTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupAssignmentEntityOnCreatedOne?:ValueTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnUpdatedOne?:ValueTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnDeletedOne?:ValueTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnCreatedOne?:ValueTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnUpdatedOne?:ValueTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnDeletedOne?:ValueTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldEntityOnCreatedOne?:ValueTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnUpdatedOne?:ValueTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnDeletedOne?:ValueTypes["AppProjectFieldEntity"],
	AppServiceCategoryEntityOnCreatedOne?:ValueTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnUpdatedOne?:ValueTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnDeletedOne?:ValueTypes["AppServiceCategoryEntity"],
	AppTextEntityOnCreatedOne?:ValueTypes["AppTextEntity"],
	AppTextEntityOnUpdatedOne?:ValueTypes["AppTextEntity"],
	AppTextEntityOnDeletedOne?:ValueTypes["AppTextEntity"],
	AppRelationEntityOnCreatedOne?:ValueTypes["AppRelationEntity"],
	AppRelationEntityOnUpdatedOne?:ValueTypes["AppRelationEntity"],
	AppRelationEntityOnDeletedOne?:ValueTypes["AppRelationEntity"],
		__typename?: boolean
}>
  }

export type ModelTypes = {
    ["Query"]: {
		GrantEntityFindById?:ModelTypes["GrantEntity"],
	GrantEntityFindOne?:ModelTypes["GrantEntity"],
	GrantEntityFindMany?:(ModelTypes["GrantEntity"] | undefined)[],
	GrantEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	RoleGrantConfigEntityFindById?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityFindOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityFindMany?:(ModelTypes["RoleGrantConfigEntity"] | undefined)[],
	RoleGrantConfigEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	RoleDocumentGrantEntityFindById?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityFindOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityFindMany?:(ModelTypes["RoleDocumentGrantEntity"] | undefined)[],
	RoleDocumentGrantEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	RoleEntityFindById?:ModelTypes["RoleEntity"],
	RoleEntityFindOne?:ModelTypes["RoleEntity"],
	RoleEntityFindMany?:(ModelTypes["RoleEntity"] | undefined)[],
	RoleEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserGrantConfigEntityFindById?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityFindOne?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityFindMany?:(ModelTypes["UserGrantConfigEntity"] | undefined)[],
	UserGrantConfigEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserNotificationSubscriptionEntityFindById?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityFindOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityFindMany?:(ModelTypes["UserNotificationSubscriptionEntity"] | undefined)[],
	UserNotificationSubscriptionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserNotificationEntityFindById?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityFindOne?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityFindMany?:(ModelTypes["UserNotificationEntity"] | undefined)[],
	UserNotificationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserTemporaryAccessTokenEntityEntityFindById?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityFindOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityFindMany?:(ModelTypes["UserTemporaryAccessTokenEntityEntity"] | undefined)[],
	UserTemporaryAccessTokenEntityEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserEntityFindById?:ModelTypes["UserEntity"],
	UserEntityFindOne?:ModelTypes["UserEntity"],
	UserEntityFindMany?:(ModelTypes["UserEntity"] | undefined)[],
	UserEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistDefinitionEntityFindById?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityFindOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityFindMany?:(ModelTypes["PicklistDefinitionEntity"] | undefined)[],
	PicklistDefinitionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistStateEntityFindById?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityFindOne?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityFindMany?:(ModelTypes["PicklistStateEntity"] | undefined)[],
	PicklistStateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HousePartGroupEntityFindById?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityFindOne?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityFindMany?:(ModelTypes["HousePartGroupEntity"] | undefined)[],
	HousePartGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HousePartEntityFindById?:ModelTypes["HousePartEntity"],
	HousePartEntityFindOne?:ModelTypes["HousePartEntity"],
	HousePartEntityFindMany?:(ModelTypes["HousePartEntity"] | undefined)[],
	HousePartEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CargoPhaseEntityFindById?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityFindOne?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityFindMany?:(ModelTypes["CargoPhaseEntity"] | undefined)[],
	CargoPhaseEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CargoTypeEntityFindById?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityFindOne?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityFindMany?:(ModelTypes["CargoTypeEntity"] | undefined)[],
	CargoTypeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningHumanResourceEntityFindById?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityFindOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityFindMany?:(ModelTypes["PlanningHumanResourceEntity"] | undefined)[],
	PlanningHumanResourceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectAssignmentEntityFindById?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityFindOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityFindMany?:(ModelTypes["PlanningProjectAssignmentEntity"] | undefined)[],
	PlanningProjectAssignmentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectCompetencePlacementEntityFindById?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityFindOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityFindMany?:(ModelTypes["PlanningProjectCompetencePlacementEntity"] | undefined)[],
	PlanningProjectCompetencePlacementEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectRequestPlacementEntityFindById?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityFindOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityFindMany?:(ModelTypes["PlanningProjectRequestPlacementEntity"] | undefined)[],
	PlanningProjectRequestPlacementEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectEntityFindById?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityFindOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityFindMany?:(ModelTypes["PlanningProjectEntity"] | undefined)[],
	PlanningProjectEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectRequestEntityFindById?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityFindOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityFindMany?:(ModelTypes["PlanningProjectRequestEntity"] | undefined)[],
	PlanningProjectRequestEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectCompetenceEntityFindById?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityFindOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityFindMany?:(ModelTypes["PlanningProjectCompetenceEntity"] | undefined)[],
	PlanningProjectCompetenceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningCompetenceEntityFindById?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityFindOne?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityFindMany?:(ModelTypes["PlanningCompetenceEntity"] | undefined)[],
	PlanningCompetenceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CargoStateEntityFindById?:ModelTypes["CargoStateEntity"],
	CargoStateEntityFindOne?:ModelTypes["CargoStateEntity"],
	CargoStateEntityFindMany?:(ModelTypes["CargoStateEntity"] | undefined)[],
	CargoStateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CargoEntityFindById?:ModelTypes["CargoEntity"],
	CargoEntityFindOne?:ModelTypes["CargoEntity"],
	CargoEntityFindMany?:(ModelTypes["CargoEntity"] | undefined)[],
	CargoEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectHousePartEntityFindById?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityFindOne?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityFindMany?:(ModelTypes["ProjectHousePartEntity"] | undefined)[],
	ProjectHousePartEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistElementEntityFindById?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityFindOne?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityFindMany?:(ModelTypes["PicklistElementEntity"] | undefined)[],
	PicklistElementEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistEntityFindById?:ModelTypes["PicklistEntity"],
	PicklistEntityFindOne?:ModelTypes["PicklistEntity"],
	PicklistEntityFindMany?:(ModelTypes["PicklistEntity"] | undefined)[],
	PicklistEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DrawOrderTypeEntityFindById?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityFindOne?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityFindMany?:(ModelTypes["DrawOrderTypeEntity"] | undefined)[],
	DrawOrderTypeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HouseModelEntityFindById?:ModelTypes["HouseModelEntity"],
	HouseModelEntityFindOne?:ModelTypes["HouseModelEntity"],
	HouseModelEntityFindMany?:(ModelTypes["HouseModelEntity"] | undefined)[],
	HouseModelEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SalesEmployeeEntityFindById?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityFindOne?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityFindMany?:(ModelTypes["SalesEmployeeEntity"] | undefined)[],
	SalesEmployeeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DrawerAbsentEntityFindById?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityFindOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityFindMany?:(ModelTypes["DrawerAbsentEntity"] | undefined)[],
	DrawerAbsentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DrawerEntityFindById?:ModelTypes["DrawerEntity"],
	DrawerEntityFindOne?:ModelTypes["DrawerEntity"],
	DrawerEntityFindMany?:(ModelTypes["DrawerEntity"] | undefined)[],
	DrawerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DrawOrderEntityFindById?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityFindOne?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityFindMany?:(ModelTypes["DrawOrderEntity"] | undefined)[],
	DrawOrderEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateGroupCommentEntityFindById?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityFindOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityFindMany?:(ModelTypes["DateGroupCommentEntity"] | undefined)[],
	DateGroupCommentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateGroupResponsibleEntityFindById?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityFindOne?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityFindMany?:(ModelTypes["DateGroupResponsibleEntity"] | undefined)[],
	DateGroupResponsibleEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	LogicFieldEntityFindById?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityFindOne?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityFindMany?:(ModelTypes["LogicFieldEntity"] | undefined)[],
	LogicFieldEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateGroupEntityFindById?:ModelTypes["DateGroupEntity"],
	DateGroupEntityFindOne?:ModelTypes["DateGroupEntity"],
	DateGroupEntityFindMany?:(ModelTypes["DateGroupEntity"] | undefined)[],
	DateGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateEntityFindById?:ModelTypes["DateEntity"],
	DateEntityFindOne?:ModelTypes["DateEntity"],
	DateEntityFindMany?:(ModelTypes["DateEntity"] | undefined)[],
	DateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateValueEntityFindById?:ModelTypes["DateValueEntity"],
	DateValueEntityFindOne?:ModelTypes["DateValueEntity"],
	DateValueEntityFindMany?:(ModelTypes["DateValueEntity"] | undefined)[],
	DateValueEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	LogicFieldValueEntityFindById?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityFindOne?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityFindMany?:(ModelTypes["LogicFieldValueEntity"] | undefined)[],
	LogicFieldValueEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DateCacheEntityFindById?:ModelTypes["DateCacheEntity"],
	DateCacheEntityFindOne?:ModelTypes["DateCacheEntity"],
	DateCacheEntityFindMany?:(ModelTypes["DateCacheEntity"] | undefined)[],
	DateCacheEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	YearPlanningModificationEntityFindById?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityFindOne?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityFindMany?:(ModelTypes["YearPlanningModificationEntity"] | undefined)[],
	YearPlanningModificationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectCachedValueEntityFindById?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityFindOne?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityFindMany?:(ModelTypes["ProjectCachedValueEntity"] | undefined)[],
	ProjectCachedValueEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	TableLayoutCommentEntityFindById?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityFindOne?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityFindMany?:(ModelTypes["TableLayoutCommentEntity"] | undefined)[],
	TableLayoutCommentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectEntityFindById?:ModelTypes["ProjectEntity"],
	ProjectEntityFindOne?:ModelTypes["ProjectEntity"],
	ProjectEntityFindMany?:(ModelTypes["ProjectEntity"] | undefined)[],
	ProjectEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SyncCronEntityFindById?:ModelTypes["SyncCronEntity"],
	SyncCronEntityFindOne?:ModelTypes["SyncCronEntity"],
	SyncCronEntityFindMany?:(ModelTypes["SyncCronEntity"] | undefined)[],
	SyncCronEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	MailSchedulerEntityFindById?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityFindOne?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityFindMany?:(ModelTypes["MailSchedulerEntity"] | undefined)[],
	MailSchedulerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectPartnerEntityFindById?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityFindOne?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityFindMany?:(ModelTypes["ProjectPartnerEntity"] | undefined)[],
	ProjectPartnerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PartnerEntityFindById?:ModelTypes["PartnerEntity"],
	PartnerEntityFindOne?:ModelTypes["PartnerEntity"],
	PartnerEntityFindMany?:(ModelTypes["PartnerEntity"] | undefined)[],
	PartnerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DocumentMetaEntityFindById?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityFindOne?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityFindMany?:(ModelTypes["DocumentMetaEntity"] | undefined)[],
	DocumentMetaEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	EntityAdvancementEntityFindById?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityFindOne?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityFindMany?:(ModelTypes["EntityAdvancementEntity"] | undefined)[],
	EntityAdvancementEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	WorkActionCategoryEntityFindById?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityFindOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityFindMany?:(ModelTypes["WorkActionCategoryEntity"] | undefined)[],
	WorkActionCategoryEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	WorkActionEntityFindById?:ModelTypes["WorkActionEntity"],
	WorkActionEntityFindOne?:ModelTypes["WorkActionEntity"],
	WorkActionEntityFindMany?:(ModelTypes["WorkActionEntity"] | undefined)[],
	WorkActionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	EntityImageEntityFindById?:ModelTypes["EntityImageEntity"],
	EntityImageEntityFindOne?:ModelTypes["EntityImageEntity"],
	EntityImageEntityFindMany?:(ModelTypes["EntityImageEntity"] | undefined)[],
	EntityImageEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CustomerQuestionEntityFindById?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityFindOne?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityFindMany?:(ModelTypes["CustomerQuestionEntity"] | undefined)[],
	CustomerQuestionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditCategoryEntityFindById?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityFindOne?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityFindMany?:(ModelTypes["AuditCategoryEntity"] | undefined)[],
	AuditCategoryEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditQuestionEntityFindById?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityFindOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityFindMany?:(ModelTypes["AuditQuestionEntity"] | undefined)[],
	AuditQuestionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditQuestionCostCategoryEntityFindById?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityFindOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityFindMany?:(ModelTypes["AuditQuestionCostCategoryEntity"] | undefined)[],
	AuditQuestionCostCategoryEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	CostCategoryEntityFindById?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityFindOne?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityFindMany?:(ModelTypes["CostCategoryEntity"] | undefined)[],
	CostCategoryEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditReportItemImageEntityFindById?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityFindOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityFindMany?:(ModelTypes["AuditReportItemImageEntity"] | undefined)[],
	AuditReportItemImageEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditReportItemEntityFindById?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityFindOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityFindMany?:(ModelTypes["AuditReportItemEntity"] | undefined)[],
	AuditReportItemEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditReportEntityFindById?:ModelTypes["AuditReportEntity"],
	AuditReportEntityFindOne?:ModelTypes["AuditReportEntity"],
	AuditReportEntityFindMany?:(ModelTypes["AuditReportEntity"] | undefined)[],
	AuditReportEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AuditChecklistImageEntityFindById?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityFindOne?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityFindMany?:(ModelTypes["AuditChecklistImageEntity"] | undefined)[],
	AuditChecklistImageEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InvoiceAttachmentEntityFindById?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityFindOne?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityFindMany?:(ModelTypes["InvoiceAttachmentEntity"] | undefined)[],
	InvoiceAttachmentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	EmployeeDocumentEntityFindById?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityFindOne?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityFindMany?:(ModelTypes["EmployeeDocumentEntity"] | undefined)[],
	EmployeeDocumentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	WorkActionTemplateGroupEntityFindById?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityFindOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityFindMany?:(ModelTypes["WorkActionTemplateGroupEntity"] | undefined)[],
	WorkActionTemplateGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	WorkActionTemplateEntityFindById?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityFindOne?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityFindMany?:(ModelTypes["WorkActionTemplateEntity"] | undefined)[],
	WorkActionTemplateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectCostInfoEntityFindById?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityFindOne?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityFindMany?:(ModelTypes["ProjectCostInfoEntity"] | undefined)[],
	ProjectCostInfoEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	BlockedCalendarWeekEntityFindById?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityFindOne?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityFindMany?:(ModelTypes["BlockedCalendarWeekEntity"] | undefined)[],
	BlockedCalendarWeekEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	MaintenanceReportEntityFindById?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityFindOne?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityFindMany?:(ModelTypes["MaintenanceReportEntity"] | undefined)[],
	MaintenanceReportEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ConfigurationEntityFindById?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityFindOne?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityFindMany?:(ModelTypes["ConfigurationEntity"] | undefined)[],
	ConfigurationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServiceMeterEntityFindById?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityFindOne?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityFindMany?:(ModelTypes["ServiceMeterEntity"] | undefined)[],
	ServiceMeterEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DeliveryListMeterEntityFindById?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityFindOne?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityFindMany?:(ModelTypes["DeliveryListMeterEntity"] | undefined)[],
	DeliveryListMeterEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServiceSpaceEntityFindById?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityFindOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityFindMany?:(ModelTypes["ServiceSpaceEntity"] | undefined)[],
	ServiceSpaceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServiceTypeEntityFindById?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityFindOne?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityFindMany?:(ModelTypes["ServiceTypeEntity"] | undefined)[],
	ServiceTypeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DeliveryListPointImageEntityFindById?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityFindOne?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityFindMany?:(ModelTypes["DeliveryListPointImageEntity"] | undefined)[],
	DeliveryListPointImageEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServicePriorityEntityFindById?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityFindOne?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityFindMany?:(ModelTypes["ServicePriorityEntity"] | undefined)[],
	ServicePriorityEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DeliveryListPointEntityFindById?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityFindOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityFindMany?:(ModelTypes["DeliveryListPointEntity"] | undefined)[],
	DeliveryListPointEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DeliveryListSignatureEntityFindById?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityFindOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityFindMany?:(ModelTypes["DeliveryListSignatureEntity"] | undefined)[],
	DeliveryListSignatureEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DeliveryListEntityFindById?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityFindOne?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityFindMany?:(ModelTypes["DeliveryListEntity"] | undefined)[],
	DeliveryListEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SupplyInspectionReportAnswerEntityFindById?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityFindOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityFindMany?:(ModelTypes["SupplyInspectionReportAnswerEntity"] | undefined)[],
	SupplyInspectionReportAnswerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SupplyInspectionReportEntityFindById?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityFindOne?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityFindMany?:(ModelTypes["SupplyInspectionReportEntity"] | undefined)[],
	SupplyInspectionReportEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DepartmentEntityFindById?:ModelTypes["DepartmentEntity"],
	DepartmentEntityFindOne?:ModelTypes["DepartmentEntity"],
	DepartmentEntityFindMany?:(ModelTypes["DepartmentEntity"] | undefined)[],
	DepartmentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	DrawOrderDescriptionEntityFindById?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityFindOne?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityFindMany?:(ModelTypes["DrawOrderDescriptionEntity"] | undefined)[],
	DrawOrderDescriptionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	GreetingEntityFindById?:ModelTypes["GreetingEntity"],
	GreetingEntityFindOne?:ModelTypes["GreetingEntity"],
	GreetingEntityFindMany?:(ModelTypes["GreetingEntity"] | undefined)[],
	GreetingEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HourDeclarationTypeEntityFindById?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityFindOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityFindMany?:(ModelTypes["HourDeclarationTypeEntity"] | undefined)[],
	HourDeclarationTypeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HourDeclarationActivityEntityFindById?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityFindOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityFindMany?:(ModelTypes["HourDeclarationActivityEntity"] | undefined)[],
	HourDeclarationActivityEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HourDeclarationEntityFindById?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityFindOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityFindMany?:(ModelTypes["HourDeclarationEntity"] | undefined)[],
	HourDeclarationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	HourDeclarationApprovalRequestEntityFindById?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityFindOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityFindMany?:(ModelTypes["HourDeclarationApprovalRequestEntity"] | undefined)[],
	HourDeclarationApprovalRequestEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportCommentEntityFindById?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityFindOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityFindMany?:(ModelTypes["InstallationReportCommentEntity"] | undefined)[],
	InstallationReportCommentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportDescriptionEntityFindById?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityFindOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityFindMany?:(ModelTypes["InstallationReportDescriptionEntity"] | undefined)[],
	InstallationReportDescriptionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportFinalEntityFindById?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityFindOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityFindMany?:(ModelTypes["InstallationReportFinalEntity"] | undefined)[],
	InstallationReportFinalEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportMeasurementTemplateEntityFindById?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityFindOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityFindMany?:(ModelTypes["InstallationReportMeasurementTemplateEntity"] | undefined)[],
	InstallationReportMeasurementTemplateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportMeasurementEntityFindById?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityFindOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityFindMany?:(ModelTypes["InstallationReportMeasurementEntity"] | undefined)[],
	InstallationReportMeasurementEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportQuestionGroupEntityFindById?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityFindOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityFindMany?:(ModelTypes["InstallationReportQuestionGroupEntity"] | undefined)[],
	InstallationReportQuestionGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportQuestionEntityFindById?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityFindOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityFindMany?:(ModelTypes["InstallationReportQuestionEntity"] | undefined)[],
	InstallationReportQuestionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InstallationReportQuestionAnswerEntityFindById?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityFindOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityFindMany?:(ModelTypes["InstallationReportQuestionAnswerEntity"] | undefined)[],
	InstallationReportQuestionAnswerEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	LegacyProjectEntityFindById?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityFindOne?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityFindMany?:(ModelTypes["LegacyProjectEntity"] | undefined)[],
	LegacyProjectEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistCommentEntityFindById?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityFindOne?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityFindMany?:(ModelTypes["PicklistCommentEntity"] | undefined)[],
	PicklistCommentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PicklistElementStateEntityFindById?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityFindOne?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityFindMany?:(ModelTypes["PicklistElementStateEntity"] | undefined)[],
	PicklistElementStateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	PlanningProjectItemEntityFindById?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityFindOne?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityFindMany?:(ModelTypes["PlanningProjectItemEntity"] | undefined)[],
	PlanningProjectItemEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectCargoStateEntityFindById?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityFindOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityFindMany?:(ModelTypes["ProjectCargoStateEntity"] | undefined)[],
	ProjectCargoStateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ProjectPaymentEntityFindById?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityFindOne?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityFindMany?:(ModelTypes["ProjectPaymentEntity"] | undefined)[],
	ProjectPaymentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	QualityAssuranceEntityFindById?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityFindOne?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityFindMany?:(ModelTypes["QualityAssuranceEntity"] | undefined)[],
	QualityAssuranceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServiceMapColorEntityFindById?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityFindOne?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityFindMany?:(ModelTypes["ServiceMapColorEntity"] | undefined)[],
	ServiceMapColorEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServicePlanningStageEntityFindById?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityFindOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityFindMany?:(ModelTypes["ServicePlanningStageEntity"] | undefined)[],
	ServicePlanningStageEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServicePlanningEventEntityFindById?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityFindOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityFindMany?:(ModelTypes["ServicePlanningEventEntity"] | undefined)[],
	ServicePlanningEventEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServicePlanningAssignmentEntityFindById?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityFindOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityFindMany?:(ModelTypes["ServicePlanningAssignmentEntity"] | undefined)[],
	ServicePlanningAssignmentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	ServicePresetEntityFindById?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityFindOne?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityFindMany?:(ModelTypes["ServicePresetEntity"] | undefined)[],
	ServicePresetEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SupplierDocumentEntityFindById?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityFindOne?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityFindMany?:(ModelTypes["SupplierDocumentEntity"] | undefined)[],
	SupplierDocumentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SupplyInspectionQuestionEntityFindById?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityFindOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityFindMany?:(ModelTypes["SupplyInspectionQuestionEntity"] | undefined)[],
	SupplyInspectionQuestionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	SupplyInspectionQuestionGroupEntityFindById?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityFindOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityFindMany?:(ModelTypes["SupplyInspectionQuestionGroupEntity"] | undefined)[],
	SupplyInspectionQuestionGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	UserPasswordResetEntityFindById?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityFindOne?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityFindMany?:(ModelTypes["UserPasswordResetEntity"] | undefined)[],
	UserPasswordResetEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	YearPlanningLineEntityFindById?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityFindOne?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityFindMany?:(ModelTypes["YearPlanningLineEntity"] | undefined)[],
	YearPlanningLineEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	InvoicePaymentBatchEntityEntityFindById?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityFindOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityFindMany?:(ModelTypes["InvoicePaymentBatchEntityEntity"] | undefined)[],
	InvoicePaymentBatchEntityEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPhaseContactEntityFindById?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityFindOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityFindMany?:(ModelTypes["AppPhaseContactEntity"] | undefined)[],
	AppPhaseContactEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPhaseEntityFindById?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityFindOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityFindMany?:(ModelTypes["AppPhaseEntity"] | undefined)[],
	AppPhaseEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPhaseSectionEntityFindById?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityFindOne?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityFindMany?:(ModelTypes["AppPhaseSectionEntity"] | undefined)[],
	AppPhaseSectionEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppNotificationTemplateEntityFindById?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityFindOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityFindMany?:(ModelTypes["AppNotificationTemplateEntity"] | undefined)[],
	AppNotificationTemplateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPhaseSectionMarkEntityFindById?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityFindOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityFindMany?:(ModelTypes["AppPhaseSectionMarkEntity"] | undefined)[],
	AppPhaseSectionMarkEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectDateEntityFindById?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityFindOne?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityFindMany?:(ModelTypes["AppProjectDateEntity"] | undefined)[],
	AppProjectDateEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppNotificationScheduleEntityFindById?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityFindOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityFindMany?:(ModelTypes["AppNotificationScheduleEntity"] | undefined)[],
	AppNotificationScheduleEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPhaseSectionMarkValueEntityFindById?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityFindOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityFindMany?:(ModelTypes["AppPhaseSectionMarkValueEntity"] | undefined)[],
	AppPhaseSectionMarkValueEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppInviteEntityFindById?:ModelTypes["AppInviteEntity"],
	AppInviteEntityFindOne?:ModelTypes["AppInviteEntity"],
	AppInviteEntityFindMany?:(ModelTypes["AppInviteEntity"] | undefined)[],
	AppInviteEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppLoginEntityFindById?:ModelTypes["AppLoginEntity"],
	AppLoginEntityFindOne?:ModelTypes["AppLoginEntity"],
	AppLoginEntityFindMany?:(ModelTypes["AppLoginEntity"] | undefined)[],
	AppLoginEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppNotificationEntityFindById?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityFindOne?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityFindMany?:(ModelTypes["AppNotificationEntity"] | undefined)[],
	AppNotificationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectEntityFindById?:ModelTypes["AppProjectEntity"],
	AppProjectEntityFindOne?:ModelTypes["AppProjectEntity"],
	AppProjectEntityFindMany?:(ModelTypes["AppProjectEntity"] | undefined)[],
	AppProjectEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppUserEntityFindById?:ModelTypes["AppUserEntity"],
	AppUserEntityFindOne?:ModelTypes["AppUserEntity"],
	AppUserEntityFindMany?:(ModelTypes["AppUserEntity"] | undefined)[],
	AppUserEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectCostEntityFindById?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityFindOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityFindMany?:(ModelTypes["AppProjectCostEntity"] | undefined)[],
	AppProjectCostEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectContactEntityFindById?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityFindOne?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityFindMany?:(ModelTypes["AppProjectContactEntity"] | undefined)[],
	AppProjectContactEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppBuildingWeekEntityFindById?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityFindOne?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityFindMany?:(ModelTypes["AppBuildingWeekEntity"] | undefined)[],
	AppBuildingWeekEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppContentEntityFindById?:ModelTypes["AppContentEntity"],
	AppContentEntityFindOne?:ModelTypes["AppContentEntity"],
	AppContentEntityFindMany?:(ModelTypes["AppContentEntity"] | undefined)[],
	AppContentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppConversationMessagePresetEntityFindById?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityFindOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityFindMany?:(ModelTypes["AppConversationMessagePresetEntity"] | undefined)[],
	AppConversationMessagePresetEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppDocumentSignatureEntityFindById?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityFindOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityFindMany?:(ModelTypes["AppDocumentSignatureEntity"] | undefined)[],
	AppDocumentSignatureEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppDocumentTypeEntityFindById?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityFindOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityFindMany?:(ModelTypes["AppDocumentTypeEntity"] | undefined)[],
	AppDocumentTypeEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppDocumentEntityFindById?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityFindOne?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityFindMany?:(ModelTypes["AppDocumentEntity"] | undefined)[],
	AppDocumentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppNotificationScheduleResourceEntityFindById?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityFindOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityFindMany?:(ModelTypes["AppNotificationScheduleResourceEntity"] | undefined)[],
	AppNotificationScheduleResourceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPlanCompetenceEntityFindById?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityFindOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityFindMany?:(ModelTypes["AppPlanCompetenceEntity"] | undefined)[],
	AppPlanCompetenceEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppPlanCompetenceSettingEntityFindById?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityFindOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityFindMany?:(ModelTypes["AppPlanCompetenceSettingEntity"] | undefined)[],
	AppPlanCompetenceSettingEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectCostPresetEntityFindById?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityFindOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityFindMany?:(ModelTypes["AppProjectCostPresetEntity"] | undefined)[],
	AppProjectCostPresetEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectFieldSheetEntityFindById?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityFindOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityFindMany?:(ModelTypes["AppProjectFieldSheetEntity"] | undefined)[],
	AppProjectFieldSheetEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectFieldGroupEntityFindById?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityFindOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityFindMany?:(ModelTypes["AppProjectFieldGroupEntity"] | undefined)[],
	AppProjectFieldGroupEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectFieldGroupAssignmentEntityFindById?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityFindOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityFindMany?:(ModelTypes["AppProjectFieldGroupAssignmentEntity"] | undefined)[],
	AppProjectFieldGroupAssignmentEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectFieldGroupAssignmentValueEntityFindById?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityFindOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityFindMany?:(ModelTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined)[],
	AppProjectFieldGroupAssignmentValueEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppProjectFieldEntityFindById?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityFindOne?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityFindMany?:(ModelTypes["AppProjectFieldEntity"] | undefined)[],
	AppProjectFieldEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppServiceCategoryEntityFindById?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityFindOne?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityFindMany?:(ModelTypes["AppServiceCategoryEntity"] | undefined)[],
	AppServiceCategoryEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppTextEntityFindById?:ModelTypes["AppTextEntity"],
	AppTextEntityFindOne?:ModelTypes["AppTextEntity"],
	AppTextEntityFindMany?:(ModelTypes["AppTextEntity"] | undefined)[],
	AppTextEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	AppRelationEntityFindById?:ModelTypes["AppRelationEntity"],
	AppRelationEntityFindOne?:ModelTypes["AppRelationEntity"],
	AppRelationEntityFindMany?:(ModelTypes["AppRelationEntity"] | undefined)[],
	AppRelationEntityAggregateGroupCount?:(ModelTypes["AggregateGroup"] | undefined)[],
	bedrijvenMtcEntityFindMany?:(ModelTypes["bedrijvenMtcEntity"] | undefined)[]
};
	["GrantEntity"]: {
		id?:string,
	permissionId?:string,
	roleId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	role?:ModelTypes["RoleEntity"]
};
	["Date"]:any;
	["RoleEntity"]: {
		id?:string,
	name?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	grants?:(ModelTypes["GrantEntity"] | undefined)[],
	grantConfigs?:(ModelTypes["RoleGrantConfigEntity"] | undefined)[],
	documentGrants?:(ModelTypes["RoleDocumentGrantEntity"] | undefined)[]
};
	["RoleGrantConfigEntity"]: {
		id?:string,
	roleId?:string,
	configId?:string,
	value?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	role?:ModelTypes["RoleEntity"]
};
	["RoleDocumentGrantEntity"]: {
		id?:string,
	roleId?:string,
	match?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	role?:ModelTypes["RoleEntity"]
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["EntityQuery"]: GraphQLTypes["EntityQuery"];
	["EntityQueryFilter"]: GraphQLTypes["EntityQueryFilter"];
	["EntityQueryOrder"]: GraphQLTypes["EntityQueryOrder"];
	["AggregateGroup"]: {
		key:string,
	count:number
};
	["UserGrantConfigEntity"]: {
		id?:string,
	userId?:string,
	configId?:string,
	value?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["UserEntity"]: {
		id?:string,
	roleId?:string,
	companyId?:string,
	workAsUserId?:string,
	planningUserId?:string,
	abbreviation?:string,
	overwriteSignIn?:boolean,
	identity?:string,
	password?:string,
	passwordHash?:string,
	name?:string,
	stage?:string,
	email?:string,
	emailService?:string,
	emailDeclaration?:string,
	telephone?:string,
	isSupplier?:string,
	isSalesEmployee?:string,
	isServiceMechanic?:string,
	isBuyAdvisor?:string,
	isProjectMentor?:string,
	isProjectLeader?:string,
	addressStreet?:string,
	addressHouseNumber?:string,
	addressCity?:string,
	addressZipCode?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	lastSeenAt?:ModelTypes["Date"],
	workAsUser?:ModelTypes["UserEntity"],
	planningUser?:ModelTypes["UserEntity"],
	role?:ModelTypes["RoleEntity"],
	temporaryAccessTokens?:(ModelTypes["UserTemporaryAccessTokenEntityEntity"] | undefined)[],
	grantConfigs?:(ModelTypes["UserGrantConfigEntity"] | undefined)[],
	notificationSubscriptions?:(ModelTypes["UserNotificationSubscriptionEntity"] | undefined)[],
	userNotifications?:(ModelTypes["UserNotificationEntity"] | undefined)[]
};
	["UserTemporaryAccessTokenEntityEntity"]: {
		id?:string,
	value?:string,
	createdAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["UserNotificationSubscriptionEntity"]: {
		id?:string,
	userId?:string,
	endpoint?:string,
	keys_auth?:string,
	keys_p256dh?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["UserNotificationEntity"]: {
		id?:string,
	userId?:string,
	subject?:string,
	content?:string,
	url?:string,
	channels?:ModelTypes["JSON"],
	readAt?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["PicklistDefinitionEntity"]: {
		id?:string,
	name?:string,
	icon?:string,
	metacomListTable?:string,
	metacomLinesTable?:string,
	renameFilterName?:string,
	competenceNumber?:string,
	elementTypes?:ModelTypes["JSON"],
	hideColumns?:ModelTypes["JSON"],
	indeterminateToggles?:boolean,
	dateSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["PicklistStateEntity"]: {
		id?:string,
	projectId?:string,
	picklistDefinitionId?:string,
	completedByUserId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	picklistDefinition?:ModelTypes["PicklistDefinitionEntity"],
	completedByUser?:ModelTypes["UserEntity"]
};
	["ProjectEntity"]: {
		id?:string,
	description?:string,
	userId?:string,
	region?:string,
	regionId?:string,
	houseType?:string,
	houseShape?:string,
	condition?:string,
	buildingWeek?:string,
	buildingType?:string,
	buildingStreet?:string,
	buildingHouseNumber?:string,
	buildingCity?:string,
	buildingZipCode?:string,
	deliveryDate?:string,
	longitude?:string,
	latitude?:string,
	concrete?:string,
	customerEmail?:string,
	customerName?:string,
	foundation?:string,
	heating?:string,
	constructionProbeWork?:string,
	constructionAdvice?:string,
	constructionDrawingWork?:string,
	constructionSupplier?:string,
	constructionLevel?:string,
	constructionUnavailable?:string,
	score?:number,
	topicalDocumentRoot?:string,
	archiveDocumentRoot?:string,
	administrationType?:string,
	projectLeaderId?:string,
	salesEmployeeId?:string,
	buyAdvisorId?:string,
	projectMentorId?:string,
	installationReportDate?:string,
	installationPerformerName?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	projectLeader?:ModelTypes["UserEntity"],
	salesEmployee?:ModelTypes["UserEntity"],
	buyAdvisor?:ModelTypes["UserEntity"],
	projectMentor?:ModelTypes["UserEntity"],
	picklists?:(ModelTypes["PicklistEntity"] | undefined)[],
	picklistStates?:(ModelTypes["PicklistStateEntity"] | undefined)[],
	projectHouseParts?:(ModelTypes["ProjectHousePartEntity"] | undefined)[],
	tableLayoutComments?:(ModelTypes["TableLayoutCommentEntity"] | undefined)[],
	drawOrders?:(ModelTypes["DrawOrderEntity"] | undefined)[],
	logicFieldValues?:(ModelTypes["LogicFieldValueEntity"] | undefined)[],
	dateValues?:(ModelTypes["DateValueEntity"] | undefined)[],
	dateGroupComments?:(ModelTypes["DateGroupCommentEntity"] | undefined)[],
	dateGroupResponsibles?:(ModelTypes["DateGroupResponsibleEntity"] | undefined)[],
	cachedValue?:ModelTypes["ProjectCachedValueEntity"],
	dateCache?:ModelTypes["DateCacheEntity"],
	modifications?:(ModelTypes["YearPlanningModificationEntity"] | undefined)[]
};
	["PicklistEntity"]: {
		id?:string,
	projectId?:string,
	state?:string,
	note?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	syncSource?:string,
	syncVersion?:string,
	project?:ModelTypes["ProjectEntity"],
	elements?:(ModelTypes["PicklistElementEntity"] | undefined)[]
};
	["PicklistElementEntity"]: {
		id?:string,
	picklistId?:string,
	housePartGroupId?:string,
	pickedByUserId?:string,
	elementId?:string,
	elementType?:string,
	elementGroup?:string,
	elementName?:string,
	description?:string,
	quantity?:number,
	unit?:string,
	dimensions?:string,
	orderId?:string,
	pickState?:string,
	isPicked?:boolean,
	pickedAt?:ModelTypes["Date"],
	syncSource?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	picklist?:ModelTypes["PicklistEntity"],
	housePartGroup?:ModelTypes["HousePartGroupEntity"],
	pickedByUser?:ModelTypes["UserEntity"]
};
	["HousePartGroupEntity"]: {
		id?:string,
	name?:string,
	isEnabled?:boolean,
	unit?:string,
	syncSource?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ProjectHousePartEntity"]: {
		id?:string,
	projectId?:string,
	cargoId?:string,
	supplierId?:string,
	partId?:string,
	partCodeId?:string,
	groupDivisionId?:string,
	quantity?:string,
	weight?:string,
	unit?:string,
	length?:string,
	width?:string,
	moldId?:string,
	wallId?:string,
	dateId?:string,
	costId?:string,
	syncSource?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	cargo?:ModelTypes["CargoEntity"],
	project?:ModelTypes["ProjectEntity"],
	housePart?:ModelTypes["HousePartEntity"]
};
	["CargoEntity"]: {
		id?:string,
	projectId?:string,
	competenceId?:string,
	phaseId?:string,
	cargoTypeId?:string,
	description?:string,
	timeAt?:string,
	requiresPermit?:boolean,
	routeId?:string,
	dateAt?:ModelTypes["Date"],
	isLocked?:boolean,
	notes?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	competence?:ModelTypes["PlanningCompetenceEntity"],
	phase?:ModelTypes["CargoPhaseEntity"],
	cargoType?:ModelTypes["CargoTypeEntity"],
	projectHouseParts?:(ModelTypes["ProjectHousePartEntity"] | undefined)[],
	cargoStates?:(ModelTypes["CargoStateEntity"] | undefined)[]
};
	["PlanningCompetenceEntity"]: {
		id?:string,
	number?:string,
	color?:string,
	description?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	planningProjectCompetences?:(ModelTypes["PlanningProjectCompetenceEntity"] | undefined)[]
};
	["PlanningProjectCompetenceEntity"]: {
		id?:string,
	planningCompetenceId?:string,
	planningProjectRequestId?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	planningCompetence?:ModelTypes["PlanningCompetenceEntity"],
	planningProjectRequest?:ModelTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?:(ModelTypes["PlanningProjectCompetencePlacementEntity"] | undefined)[]
};
	["PlanningProjectRequestEntity"]: {
		id?:string,
	planningProjectId?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	planningProject?:ModelTypes["PlanningProjectEntity"],
	planningProjectRequestPlacements?:(ModelTypes["PlanningProjectRequestPlacementEntity"] | undefined)[],
	planningProjectCompetences?:(ModelTypes["PlanningProjectCompetenceEntity"] | undefined)[]
};
	["PlanningProjectEntity"]: {
		id?:string,
	projectId?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	planningProjectRequests?:(ModelTypes["PlanningProjectRequestEntity"] | undefined)[]
};
	["PlanningProjectRequestPlacementEntity"]: {
		id?:string,
	planningProjectRequestId?:string,
	startDate?:ModelTypes["Date"],
	endDate?:ModelTypes["Date"],
	status?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	planningProjectRequest?:ModelTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?:(ModelTypes["PlanningProjectCompetencePlacementEntity"] | undefined)[]
};
	["PlanningProjectCompetencePlacementEntity"]: {
		id?:string,
	planningProjectCompetenceId?:string,
	planningProjectRequestPlacementId?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	planningProjectCompetence?:ModelTypes["PlanningProjectCompetenceEntity"],
	planningProjectRequestPlacement?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	planningCompetenceAssignments?:(ModelTypes["PlanningProjectAssignmentEntity"] | undefined)[]
};
	["PlanningProjectAssignmentEntity"]: {
		id?:string,
	humanResourceId?:string,
	planningCompetencePlacementId?:string,
	comments?:string,
	startsAt?:ModelTypes["Date"],
	endsAt?:ModelTypes["Date"],
	hours?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	humanResource?:ModelTypes["PlanningHumanResourceEntity"],
	planningCompetencePlacement?:ModelTypes["PlanningProjectCompetencePlacementEntity"]
};
	["PlanningHumanResourceEntity"]: {
		id?:string,
	number?:string,
	resourceTypeId?:string,
	color?:string,
	firstName?:string,
	lastNamePrefix?:string,
	lastName?:string,
	email?:string,
	isNotProjectLeader?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["CargoPhaseEntity"]: {
		id?:string,
	projectId?:string,
	description?:string,
	date?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"]
};
	["CargoTypeEntity"]: {
		id?:string,
	description?:string,
	userId?:string,
	price?:string,
	unit?:string,
	colorCode?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["CargoStateEntity"]: {
		id?:string,
	cargoId?:string,
	housePartGroupId?:string,
	divisionId?:string,
	value?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	cargo?:ModelTypes["CargoEntity"],
	housePartGroup?:ModelTypes["HousePartGroupEntity"]
};
	["HousePartEntity"]: {
		id?:string,
	partId?:string,
	housePartGroupId?:string,
	supplierId?:string,
	description?:string,
	rootElement?:string,
	releaseTimeMins?:number,
	length?:string,
	width?:string,
	capacityFactor?:number,
	syncSource?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	housePartGroup?:ModelTypes["HousePartGroupEntity"]
};
	["TableLayoutCommentEntity"]: {
		id?:string,
	taskId?:string,
	projectId?:string,
	userId?:string,
	comment?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	user?:ModelTypes["UserEntity"]
};
	["DrawOrderEntity"]: {
		id?:string,
	projectId?:string,
	kind?:string,
	drawOrderTypeId?:string,
	houseModelId?:string,
	salesEmployeeId?:string,
	drawerId?:string,
	description?:string,
	memo?:string,
	memoLong?:string,
	completedAt?:ModelTypes["Date"],
	plannedAt?:ModelTypes["Date"],
	plannedAtOrder?:number,
	totalHours?:number,
	totalHoursSpend?:number,
	percentageCompleted?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	drawOrderType?:ModelTypes["DrawOrderTypeEntity"],
	houseModel?:ModelTypes["HouseModelEntity"],
	salesEmployee?:ModelTypes["SalesEmployeeEntity"],
	drawer?:ModelTypes["DrawerEntity"]
};
	["DrawOrderTypeEntity"]: {
		id?:string,
	name?:string,
	condition?:string,
	defaultHours?:number,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["HouseModelEntity"]: {
		id?:string,
	name?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["SalesEmployeeEntity"]: {
		id?:string,
	name?:string,
	firstName?:string,
	condition?:string,
	isActive?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["DrawerEntity"]: {
		id?:string,
	userId?:string,
	alias?:string,
	condition?:string,
	isHSB?:string,
	isBK?:string,
	isIT?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	drawerAbsents?:(ModelTypes["DrawerAbsentEntity"] | undefined)[]
};
	["DrawerAbsentEntity"]: {
		id?:string,
	drawerId?:string,
	reason?:string,
	color?:string,
	date?:ModelTypes["Date"],
	hours?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	drawer?:ModelTypes["DrawerEntity"]
};
	["LogicFieldValueEntity"]: {
		id?:string,
	fieldId?:string,
	projectId?:string,
	value?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	isSyncUpdate?:boolean,
	project?:ModelTypes["ProjectEntity"]
};
	["DateValueEntity"]: {
		id?:string,
	dateId?:string,
	projectId?:string,
	userId?:string,
	value?:ModelTypes["JSON"],
	isActivated?:boolean,
	isCompleted?:boolean,
	isSynced?:boolean,
	syncSource?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	isSyncUpdate?:boolean,
	send_notification?:boolean,
	date?:ModelTypes["DateEntity"],
	project?:ModelTypes["ProjectEntity"],
	user?:ModelTypes["UserEntity"]
};
	["DateEntity"]: {
		id?:string,
	dateGroupId?:string,
	description?:string,
	stackId?:number,
	iconId?:string,
	dateFormat?:string,
	colorCode?:string,
	orderId?:number,
	visibleOnDatePassed?:boolean,
	isOptional?:boolean,
	visibleOnConstructionPlanning?:boolean,
	phaseSectionMarkId?:number,
	notificationTemplateId?:number,
	drawOrderTypeId?:string,
	isSynced?:boolean,
	hash?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	dateGroup?:ModelTypes["DateGroupEntity"],
	dateValues?:(ModelTypes["DateValueEntity"] | undefined)[]
};
	["DateGroupEntity"]: {
		id?:string,
	description?:string,
	label?:string,
	hasResponsible?:boolean,
	hasComments?:boolean,
	visibleOnConstructionPlanning?:boolean,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	dates?:(ModelTypes["DateEntity"] | undefined)[],
	logicFields?:(ModelTypes["LogicFieldEntity"] | undefined)[],
	comments?:(ModelTypes["DateGroupCommentEntity"] | undefined)[],
	responsibles?:(ModelTypes["DateGroupResponsibleEntity"] | undefined)[]
};
	["LogicFieldEntity"]: {
		id?:string,
	fieldId?:string,
	fieldName?:string,
	dateGroupId?:string,
	conditionDescription?:string,
	expression?:string,
	iconId?:string,
	colorCode?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	dateGroup?:ModelTypes["DateGroupEntity"]
};
	["DateGroupCommentEntity"]: {
		id?:string,
	dateGroupId?:string,
	projectId?:string,
	userId?:string,
	value?:string,
	referenceId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	dateGroup?:ModelTypes["DateGroupEntity"],
	project?:ModelTypes["ProjectEntity"],
	user?:ModelTypes["UserEntity"]
};
	["DateGroupResponsibleEntity"]: {
		id?:string,
	userId?:string,
	projectId?:string,
	dateGroupId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	project?:ModelTypes["ProjectEntity"],
	dateGroup?:ModelTypes["DateGroupEntity"]
};
	["ProjectCachedValueEntity"]: {
		id?:string,
	projectId?:string,
	dateValues?:ModelTypes["JSON"],
	projectLeader?:ModelTypes["JSON"],
	salesEmployee?:ModelTypes["JSON"],
	dateGroupResponsibles?:ModelTypes["JSON"],
	buyAdvisor?:ModelTypes["JSON"],
	projectMentor?:ModelTypes["JSON"],
	dateGroupComments?:ModelTypes["JSON"],
	logicFieldValues?:ModelTypes["JSON"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"]
};
	["DateCacheEntity"]: {
		id?:string,
	projectId?:string,
	values?:ModelTypes["JSON"],
	comments?:ModelTypes["JSON"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"]
};
	["YearPlanningModificationEntity"]: {
		id?:string,
	userId?:string,
	projectId?:string,
	oldWeek?:string,
	newWeek?:string,
	notes?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	project?:ModelTypes["ProjectEntity"]
};
	["SyncCronEntity"]: {
		id?:string,
	cron?:string
};
	["MailSchedulerEntity"]: {
		id?:string,
	description?:string,
	cron?:string,
	sourceType?:string,
	sourceTable?:string,
	condition?:string,
	mail?:string,
	mailCC?:string,
	subject?:string,
	template?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ProjectPartnerEntity"]: {
		id?:string,
	projectId?:string,
	partnerId?:string,
	mode?:string,
	isContactAllowed?:boolean,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["PartnerEntity"]: {
		id?:string,
	name?:string,
	contentId?:number,
	contactUserId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	contactUser?:ModelTypes["UserEntity"]
};
	["DocumentMetaEntity"]: {
		id?:string,
	description?:string,
	mimeType?:string,
	projectId?:string,
	trackingId?:string,
	userId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	user?:ModelTypes["UserEntity"]
};
	["EntityAdvancementEntity"]: {
		id?:string,
	entityType?:string,
	entityId?:string,
	userId?:string,
	comment?:string,
	date?:ModelTypes["Date"],
	isCompleted?:boolean,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	workAction?:ModelTypes["WorkActionEntity"]
};
	["WorkActionEntity"]: {
		title?:string,
	description?:string,
	allowEmptyAdvancement?:boolean,
	disableImageUpload?:boolean,
	responsibleDateGroupId?:string,
	advancementApplyDateId?:string,
	id?:string,
	entityType?:string,
	userId?:string,
	projectId?:string,
	workActionCategoryId?:string,
	assignedUserId?:string,
	date?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	project?:ModelTypes["ProjectEntity"],
	workActionCategory?:ModelTypes["WorkActionCategoryEntity"],
	assignedUser?:ModelTypes["UserEntity"],
	entityAdvancements?:(ModelTypes["EntityAdvancementEntity"] | undefined)[],
	entityImages?:(ModelTypes["EntityImageEntity"] | undefined)[]
};
	["WorkActionCategoryEntity"]: {
		id?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	workActions?:(ModelTypes["WorkActionEntity"] | undefined)[]
};
	["EntityImageEntity"]: {
		id?:string,
	entityType?:string,
	entityId?:string,
	userId?:string,
	documentMetaId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"],
	workAction?:ModelTypes["WorkActionEntity"]
};
	["CustomerQuestionEntity"]: {
		id?:string,
	message?:string,
	kind?:string,
	projectId?:string,
	customerName?:string,
	city?:string,
	telephone?:string,
	space?:string,
	emailAddress?:string,
	internalComment?:string,
	imageIds?:ModelTypes["JSON"],
	completedAt?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["AuditCategoryEntity"]: {
		id?:string,
	description?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	auditQuestions?:(ModelTypes["AuditQuestionEntity"] | undefined)[]
};
	["AuditQuestionEntity"]: {
		id?:string,
	auditCategoryId?:string,
	description?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	auditCategory?:ModelTypes["AuditCategoryEntity"],
	auditQuestionCostCategories?:(ModelTypes["AuditQuestionCostCategoryEntity"] | undefined)[]
};
	["AuditQuestionCostCategoryEntity"]: {
		id?:string,
	auditQuestionId?:string,
	costCategoryId?:string,
	auditQuestion?:ModelTypes["AuditQuestionEntity"],
	costCategory?:ModelTypes["CostCategoryEntity"]
};
	["CostCategoryEntity"]: {
		id?:string,
	description?:string,
	group?:string,
	condition?:string,
	isChecklist?:string,
	hasImages?:string,
	checklistOrder?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	auditQuestionCostCategories?:(ModelTypes["AuditQuestionCostCategoryEntity"] | undefined)[]
};
	["AuditReportItemImageEntity"]: {
		id?:string,
	auditReportItemId?:string,
	documentMetaId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	auditReportItem?:ModelTypes["AuditReportItemEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["AuditReportItemEntity"]: {
		id?:string,
	userId?:string,
	auditReportId?:string,
	auditQuestionId?:string,
	state?:string,
	comment?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	auditReport?:ModelTypes["AuditReportEntity"],
	auditQuestion?:ModelTypes["AuditQuestionEntity"],
	auditReportItemImages?:(ModelTypes["AuditReportItemImageEntity"] | undefined)[]
};
	["AuditReportEntity"]: {
		id?:string,
	userId?:string,
	projectId?:string,
	costCategoryId?:string,
	signatureDocumentMetaId?:string,
	comment?:string,
	inspectedAt?:ModelTypes["Date"],
	inspectedAtTime?:string,
	lockedAt?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	project?:ModelTypes["ProjectEntity"],
	costCategory?:ModelTypes["CostCategoryEntity"],
	signatureDocumentMeta?:ModelTypes["DocumentMetaEntity"],
	auditReportItems?:(ModelTypes["AuditReportItemEntity"] | undefined)[]
};
	["AuditChecklistImageEntity"]: {
		id?:string,
	userId?:string,
	costCategoryId?:string,
	projectId?:string,
	documentMetaId?:string,
	comment?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	costCategory?:ModelTypes["CostCategoryEntity"],
	project?:ModelTypes["ProjectEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["InvoiceAttachmentEntity"]: {
		id?:string,
	metacomOrderId?:string,
	documentMetaId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["EmployeeDocumentEntity"]: {
		id?:string,
	userId?:string,
	relationId?:string,
	employeeId?:string,
	documentMetaId?:string,
	isInternal?:boolean,
	comment?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["WorkActionTemplateGroupEntity"]: {
		id?:string,
	name?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	workActionTemplates?:(ModelTypes["WorkActionTemplateEntity"] | undefined)[]
};
	["WorkActionTemplateEntity"]: {
		title?:string,
	description?:string,
	allowEmptyAdvancement?:boolean,
	disableImageUpload?:boolean,
	responsibleDateGroupId?:string,
	advancementApplyDateId?:string,
	id?:string,
	workActionTemplateGroupId?:string,
	workActionCategoryId?:string,
	targetDateOffset?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	workActionTemplateGroup?:ModelTypes["WorkActionTemplateGroupEntity"],
	workActionCategory?:ModelTypes["WorkActionCategoryEntity"]
};
	["ProjectCostInfoEntity"]: {
		id?:number,
	appProjectCostId?:number,
	conceptUserId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	conceptUser?:ModelTypes["UserEntity"]
};
	["BlockedCalendarWeekEntity"]: {
		id?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["MaintenanceReportEntity"]: {
		id?:string,
	projectId?:string,
	data?:ModelTypes["JSON"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ConfigurationEntity"]: {
		id?:string,
	data?:ModelTypes["JSON"]
};
	["ServiceMeterEntity"]: {
		id?:string,
	description?:string,
	unit?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["DeliveryListMeterEntity"]: {
		id?:string,
	projectId?:string,
	meterId?:string,
	value?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	meter?:ModelTypes["ServiceMeterEntity"]
};
	["ServiceSpaceEntity"]: {
		id?:string,
	code?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ServiceTypeEntity"]: {
		id?:string,
	code?:string,
	description?:string,
	storeInSystem?:boolean,
	isStandard?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["DeliveryListPointImageEntity"]: {
		id?:string,
	pointId?:string,
	documentMetaId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	point?:ModelTypes["DeliveryListPointEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["DeliveryListPointEntity"]: {
		id?:string,
	code?:string,
	projectId?:string,
	primaryCategoryId?:number,
	secondaryCategoryId?:number,
	serviceTypeId?:string,
	serviceSpaceId?:string,
	serviceSpaceIds?:ModelTypes["JSON"],
	servicePriorityId?:string,
	assignedUserId?:string,
	serviceTicketId?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	serviceType?:ModelTypes["ServiceTypeEntity"],
	serviceSpace?:ModelTypes["ServiceSpaceEntity"],
	servicePriority?:ModelTypes["ServicePriorityEntity"],
	assignedUser?:ModelTypes["UserEntity"],
	images?:(ModelTypes["DeliveryListPointImageEntity"] | undefined)[]
};
	["ServicePriorityEntity"]: {
		id?:string,
	description?:string,
	offsetDays?:number,
	isStandard?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["DeliveryListSignatureEntity"]: {
		id?:string,
	projectId?:string,
	kind?:string,
	documentMetaId?:string,
	signDate?:ModelTypes["Date"],
	signLocation?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["DeliveryListEntity"]: {
		id?:string,
	projectId?:string,
	assignedUserId?:string,
	hiredAgent?:string,
	keyAmount?:number,
	isDeliveredAt?:ModelTypes["Date"],
	houseDocumentMetaId?:string,
	isFinal?:boolean,
	isFinalAt?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	assignedUser?:ModelTypes["UserEntity"],
	houseDocumentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["SupplyInspectionReportAnswerEntity"]: {
		id?:string,
	supplyInspectionQuestionId?:string,
	supplyInspectionReportId?:string,
	status?:string,
	explanation?:string,
	imageDocumentMetaIds?:ModelTypes["JSON"],
	supplyInspectionReport?:ModelTypes["SupplyInspectionReportEntity"]
};
	["SupplyInspectionReportEntity"]: {
		id?:string,
	timestamp?:ModelTypes["Date"],
	departmentId?:string,
	executedByUserId?:string,
	signatureDocumentMetaId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	department?:ModelTypes["DepartmentEntity"],
	executedByUser?:ModelTypes["UserEntity"],
	supplyInspectionReportAnswers?:(ModelTypes["SupplyInspectionReportAnswerEntity"] | undefined)[]
};
	["DepartmentEntity"]: {
		id?:string,
	departmentId?:string,
	description?:string,
	state?:string,
	isOrderDepartment?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	supplyInspectionReports?:(ModelTypes["SupplyInspectionReportEntity"] | undefined)[]
};
	["DrawOrderDescriptionEntity"]: {
		id?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["GreetingEntity"]: {
		id?:string,
	html?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["HourDeclarationTypeEntity"]: {
		id?:string,
	companyId?:string,
	administrationType?:string,
	kind?:string,
	kindDescription?:string,
	description?:string,
	hasActivities?:string,
	isExternal?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["HourDeclarationActivityEntity"]: {
		id?:string,
	companyId?:string,
	administrationType?:string,
	description?:string,
	syncVersion?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["HourDeclarationEntity"]: {
		id?:string,
	userId?:string,
	hourDeclarationTypeId?:string,
	projectId?:string,
	hourDeclarationActivityId?:string,
	priceAgreementId?:string,
	storedDocumentId?:string,
	date?:ModelTypes["Date"],
	description?:string,
	amount?:string,
	baseHours?:string,
	savingHours?:string,
	overTimeHours?:string,
	travelHours?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	hourDeclarationType?:ModelTypes["HourDeclarationTypeEntity"],
	project?:ModelTypes["ProjectEntity"],
	hourDeclarationActivity?:ModelTypes["HourDeclarationActivityEntity"]
};
	["HourDeclarationApprovalRequestEntity"]: {
		id?:string,
	userId?:string,
	period?:string,
	createdAt?:ModelTypes["Date"]
};
	["InstallationReportCommentEntity"]: {
		id?:string,
	projectId?:string,
	stepId?:string,
	userId?:string,
	message?:string,
	date?:string,
	performerName?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["InstallationReportDescriptionEntity"]: {
		id?:string,
	stepId?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["InstallationReportFinalEntity"]: {
		id?:string,
	projectId?:string,
	userId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	user?:ModelTypes["UserEntity"]
};
	["InstallationReportMeasurementTemplateEntity"]: {
		id?:string,
	measureTableId?:string,
	measureId?:string,
	defaultFields?:ModelTypes["JSON"],
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["InstallationReportMeasurementEntity"]: {
		id?:string,
	projectId?:string,
	measureTableId?:string,
	measureId?:string,
	fields?:ModelTypes["JSON"],
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["InstallationReportQuestionGroupEntity"]: {
		id?:string,
	name?:string,
	stepId?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	questions?:(ModelTypes["InstallationReportQuestionEntity"] | undefined)[]
};
	["InstallationReportQuestionEntity"]: {
		id?:string,
	questionGroupId?:string,
	content?:string,
	tooltip?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	questionGroup?:ModelTypes["InstallationReportQuestionGroupEntity"]
};
	["InstallationReportQuestionAnswerEntity"]: {
		id?:string,
	projectId?:string,
	questionId?:string,
	content?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	question?:ModelTypes["InstallationReportQuestionEntity"]
};
	["LegacyProjectEntity"]: {
		id?:string,
	description?:string,
	houseType?:string,
	regionId?:string,
	buildingStreet?:string,
	buildingCity?:string,
	buildingZipCode?:string,
	deliveryDate?:string,
	customerEmail?:string,
	syncVersion?:string,
	syncSource?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["PicklistCommentEntity"]: {
		id?:string,
	projectId?:string,
	picklistDefinitionId?:string,
	userId?:string,
	text?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	picklistDefinition?:ModelTypes["PicklistDefinitionEntity"],
	user?:ModelTypes["UserEntity"]
};
	["PicklistElementStateEntity"]: {
		id?:string,
	elementId?:string,
	projectId?:string,
	picklistDefinitionId?:string,
	state?:string,
	pickedByUserId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	picklistDefinition?:ModelTypes["PicklistDefinitionEntity"],
	pickedByUser?:ModelTypes["UserEntity"]
};
	["PlanningProjectItemEntity"]: {
		id?:string,
	parentId?:string,
	dimension?:string,
	projectId?:string,
	competenceId?:string,
	competenceIndex?:number,
	competenceNumber?:string,
	competenceStartsAt?:ModelTypes["Date"],
	competenceEndsAt?:ModelTypes["Date"],
	hours?:string,
	humanResourceId?:string,
	humanResourceNumber?:string,
	humanResourceStartsAt?:ModelTypes["Date"],
	humanResourceEndsAt?:ModelTypes["Date"],
	comments?:string,
	status?:string,
	version?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"],
	competence?:ModelTypes["PlanningCompetenceEntity"],
	humanResource?:ModelTypes["PlanningHumanResourceEntity"]
};
	["ProjectCargoStateEntity"]: {
		id?:string,
	projectId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	project?:ModelTypes["ProjectEntity"]
};
	["ProjectPaymentEntity"]: {
		id?:string,
	projectId?:string,
	userId?:string,
	description?:string,
	payedAt?:ModelTypes["Date"],
	amount?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["QualityAssuranceEntity"]: {
		id?:string,
	projectId?:string,
	data?:ModelTypes["JSON"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ServiceMapColorEntity"]: {
		id?:string,
	days?:number,
	color?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ServicePlanningStageEntity"]: {
		id?:string,
	name?:string,
	colorCode?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["ServicePlanningEventEntity"]: {
		id?:string,
	legacyProjectId?:string,
	stageId?:string,
	userId?:string,
	serviceTicketIds?:ModelTypes["JSON"],
	memo?:string,
	duration?:number,
	durationUnit?:string,
	durationPlanned?:number,
	durationSpend?:number,
	isFinalized?:boolean,
	startDate?:ModelTypes["Date"],
	endDate?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	assignments?:(ModelTypes["ServicePlanningAssignmentEntity"] | undefined)[],
	legacyProject?:ModelTypes["LegacyProjectEntity"],
	stage?:ModelTypes["ServicePlanningStageEntity"],
	user?:ModelTypes["UserEntity"]
};
	["ServicePlanningAssignmentEntity"]: {
		id?:string,
	eventId?:string,
	resourceId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	event?:ModelTypes["ServicePlanningEventEntity"],
	resource?:ModelTypes["UserEntity"]
};
	["ServicePresetEntity"]: {
		id?:string,
	primaryCategoryId?:number,
	secondaryCategoryId?:number,
	code?:string,
	description?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["SupplierDocumentEntity"]: {
		id?:string,
	userId?:string,
	informedUserId?:string,
	informedContactTypes?:ModelTypes["JSON"],
	projectId?:string,
	documentMetaId?:string,
	contextId?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"],
	informedUser?:ModelTypes["UserEntity"],
	project?:ModelTypes["ProjectEntity"],
	documentMeta?:ModelTypes["DocumentMetaEntity"]
};
	["SupplyInspectionQuestionEntity"]: {
		id?:string,
	supplyInspectionQuestionGroupId?:string,
	departmentIds?:ModelTypes["JSON"],
	description?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	supplyInspectionQuestionGroup?:ModelTypes["SupplyInspectionQuestionGroupEntity"]
};
	["SupplyInspectionQuestionGroupEntity"]: {
		id?:string,
	description?:string,
	orderId?:number,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	supplyInspectionQuestions?:(ModelTypes["SupplyInspectionQuestionEntity"] | undefined)[]
};
	["UserPasswordResetEntity"]: {
		id?:string,
	userId?:string,
	expiresAt?:ModelTypes["Date"],
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"],
	user?:ModelTypes["UserEntity"]
};
	["YearPlanningLineEntity"]: {
		id?:string,
	description?:string,
	weeks?:number,
	colorCode?:string,
	visibleOnConstructionPlanning?:boolean,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["InvoicePaymentBatchEntityEntity"]: {
		id?:string,
	batch?:number,
	document?:string,
	name?:string,
	date?:ModelTypes["Date"],
	amount?:number,
	amountBank?:number,
	companyId?:string,
	companyName?:string,
	relationId?:string,
	relationName?:string,
	createdAt?:ModelTypes["Date"],
	updatedAt?:ModelTypes["Date"]
};
	["AppPhaseContactEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	phase_id?:number,
	type?:string,
	title?:string,
	receive_notifications?:boolean,
	phase?:ModelTypes["AppPhaseEntity"]
};
	["AppPhaseEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	name?:string,
	order?:number,
	percentage?:number,
	is_visible_transferred?:boolean,
	start_project_date_type?:string,
	end_project_date_type?:string,
	days?:number,
	sections?:(ModelTypes["AppPhaseSectionEntity"] | undefined)[],
	contacts?:(ModelTypes["AppPhaseContactEntity"] | undefined)[]
};
	["AppPhaseSectionEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	phase_id?:number,
	more_info_content_id?:number,
	name?:string,
	order?:number,
	percentage?:number,
	phase?:ModelTypes["AppPhaseEntity"],
	marks?:(ModelTypes["AppPhaseSectionMarkEntity"] | undefined)[]
};
	["AppPhaseSectionMarkEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	phase_section_id?:number,
	document_type_id?:number,
	more_info_content_id?:number,
	notification_template_id?:number,
	project_field_group_id?:number,
	project_date_type?:string,
	responsible?:string,
	name?:string,
	order?:number,
	percentage?:number,
	is_milestone?:boolean,
	is_standard?:boolean,
	date_prompt?:boolean,
	duration_days?:number,
	timeline_origin_date_type?:string,
	timeline_competence_number?:string,
	timeline_origin_diff_days?:number,
	section?:ModelTypes["AppPhaseSectionEntity"],
	notificationTemplate?:ModelTypes["AppNotificationTemplateEntity"]
};
	["AppNotificationTemplateEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	content_id?:number,
	subject?:string,
	body?:string
};
	["AppProjectDateEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_id?:string,
	value?:string,
	type?:string
};
	["AppNotificationScheduleEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	phase_section_mark_id?:number,
	phase_section_mark_cancel_id?:number,
	notification_template_id?:number,
	days_offset?:number,
	time_of_day?:string,
	mark?:ModelTypes["AppPhaseSectionMarkEntity"],
	markCancel?:ModelTypes["AppPhaseSectionMarkEntity"],
	notification?:ModelTypes["AppNotificationTemplateEntity"]
};
	["AppPhaseSectionMarkValueEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_id?:string,
	phase_section_mark_id?:number,
	completed_user_id?:number,
	send_notification?:boolean,
	activated?:boolean,
	completed?:boolean,
	completed_at?:ModelTypes["Date"]
};
	["AppInviteEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:string,
	project_id?:string,
	email?:string,
	login?:ModelTypes["AppLoginEntity"]
};
	["AppLoginEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	relation_id?:string,
	project_id?:string,
	transferred_at?:ModelTypes["Date"],
	email?:string,
	role_id?:string,
	user?:ModelTypes["AppUserEntity"],
	invite?:ModelTypes["AppInviteEntity"]
};
	["AppUserEntity"]: {
		id?:number,
	project_id?:number,
	relation_id?:string,
	email?:string,
	telephone?:string,
	facebook_identity?:string,
	google_identity?:string,
	password?:string,
	first_name?:string,
	last_name_prefix?:string,
	last_name?:string,
	created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	project?:ModelTypes["AppProjectEntity"],
	login?:ModelTypes["AppLoginEntity"],
	notifications?:(ModelTypes["AppNotificationEntity"] | undefined)[]
};
	["AppProjectEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	contract_sum?:string,
	users?:(ModelTypes["AppUserEntity"] | undefined)[]
};
	["AppNotificationEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:string,
	type?:string,
	notifiable_id?:number,
	notifiable_type?:string,
	data?:ModelTypes["JSON"],
	read_at?:ModelTypes["Date"],
	user?:ModelTypes["AppUserEntity"]
};
	["AppProjectCostEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	user_id?:number,
	approved_user_id?:number,
	declined_user_id?:number,
	project_id?:number,
	parent_id?:number,
	title?:string,
	memo?:string,
	value?:string,
	approved_at?:ModelTypes["Date"],
	declined_at?:ModelTypes["Date"],
	requested_at?:ModelTypes["Date"],
	user?:ModelTypes["AppUserEntity"],
	approvedUser?:ModelTypes["AppUserEntity"],
	declinedUser?:ModelTypes["AppUserEntity"],
	parent?:ModelTypes["AppProjectCostEntity"],
	project?:ModelTypes["AppProjectEntity"],
	children?:(ModelTypes["AppProjectCostEntity"] | undefined)[]
};
	["AppProjectContactEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_id?:string,
	relation_id?:string,
	type?:string
};
	["AppBuildingWeekEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:string,
	isBlocked?:boolean,
	syncVersion?:string
};
	["AppContentEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	title?:string,
	html?:string
};
	["AppConversationMessagePresetEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	title?:string,
	message?:string
};
	["AppDocumentSignatureEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	user_id?:number,
	document_id?:string,
	payload?:ModelTypes["JSON"],
	user?:ModelTypes["AppUserEntity"]
};
	["AppDocumentTypeEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	name?:string,
	description?:string,
	color?:string,
	document_kind?:string,
	order_id?:number,
	special_access?:boolean,
	project_contact_type?:string
};
	["AppDocumentEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:string,
	project_id?:string,
	user_id?:number,
	document_type_id?:number,
	document_kind?:string,
	description?:string,
	confidential?:boolean,
	mime_type?:string,
	extension?:string,
	storage_id?:string,
	user?:ModelTypes["AppUserEntity"],
	documentType?:ModelTypes["AppDocumentTypeEntity"],
	signature?:ModelTypes["AppDocumentSignatureEntity"]
};
	["AppNotificationScheduleResourceEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_id?:string,
	notification_schedule_id?:number,
	schedule_at?:ModelTypes["Date"],
	schedule?:ModelTypes["AppNotificationScheduleEntity"]
};
	["AppPlanCompetenceEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	number?:string,
	description?:string,
	index?:number,
	color?:string
};
	["AppPlanCompetenceSettingEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	plan_competence_id?:number,
	content_id?:number,
	is_visible?:boolean,
	name?:string,
	plan_competence?:ModelTypes["AppPlanCompetenceEntity"]
};
	["AppProjectCostPresetEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	title?:string,
	description?:string
};
	["AppProjectFieldSheetEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_contact_type?:string,
	name?:string,
	order?:number,
	groups?:(ModelTypes["AppProjectFieldGroupEntity"] | undefined)[]
};
	["AppProjectFieldGroupEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_field_sheet_id?:number,
	name?:string,
	order?:number,
	sheet?:ModelTypes["AppProjectFieldSheetEntity"],
	assignments?:(ModelTypes["AppProjectFieldGroupAssignmentEntity"] | undefined)[]
};
	["AppProjectFieldGroupAssignmentEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_field_group_id?:number,
	project_field_type?:string,
	project_field_copy_type?:string,
	value_type?:string,
	is_required?:boolean,
	order?:number,
	tooltip?:string,
	group?:ModelTypes["AppProjectFieldGroupEntity"],
	values?:(ModelTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined)[]
};
	["AppProjectFieldGroupAssignmentValueEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	project_field_group_assignment_id?:number,
	value?:string,
	assignment?:ModelTypes["AppProjectFieldGroupAssignmentEntity"]
};
	["AppProjectFieldEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	value?:string,
	project_id?:string,
	type?:string
};
	["AppServiceCategoryEntity"]: {
		created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"],
	id?:number,
	service_category_id?:number,
	name?:string,
	service_category?:ModelTypes["AppServiceCategoryEntity"],
	service_categories?:(ModelTypes["AppServiceCategoryEntity"] | undefined)[]
};
	["AppTextEntity"]: {
		id?:number,
	value?:string,
	created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"]
};
	["AppRelationEntity"]: {
		id?:number,
	name?:string,
	email?:string,
	is_blacklisted?:string,
	sync_version?:string,
	created_at?:ModelTypes["Date"],
	updated_at?:ModelTypes["Date"]
};
	["bedrijvenMtcEntity"]: {
		_rowid?:string,
	bedrijf?:string,
	bedrijf_oms?:string,
	bedrijf_straat?:string,
	bedrijf_huisnummer?:string,
	bedrijf_postcode?:string,
	bedrijf_plaats?:string,
	bedrijf_gln?:string,
	bedrijf_btw?:string,
	bedrijf_email?:string,
	bedrijf_email_adm?:string
};
	["Mutation"]: {
		GrantEntityCreateOne?:ModelTypes["GrantEntity"],
	GrantEntityUpdateOne?:ModelTypes["GrantEntity"],
	GrantEntityUpdateMany?:(ModelTypes["GrantEntity"] | undefined)[],
	GrantEntityDeleteOne:boolean,
	RoleGrantConfigEntityCreateOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityUpdateOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityUpdateMany?:(ModelTypes["RoleGrantConfigEntity"] | undefined)[],
	RoleGrantConfigEntityDeleteOne:boolean,
	RoleDocumentGrantEntityCreateOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityUpdateOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityUpdateMany?:(ModelTypes["RoleDocumentGrantEntity"] | undefined)[],
	RoleDocumentGrantEntityDeleteOne:boolean,
	RoleEntityCreateOne?:ModelTypes["RoleEntity"],
	RoleEntityUpdateOne?:ModelTypes["RoleEntity"],
	RoleEntityUpdateMany?:(ModelTypes["RoleEntity"] | undefined)[],
	RoleEntityDeleteOne:boolean,
	UserGrantConfigEntityCreateOne?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityUpdateOne?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityUpdateMany?:(ModelTypes["UserGrantConfigEntity"] | undefined)[],
	UserGrantConfigEntityDeleteOne:boolean,
	UserNotificationSubscriptionEntityCreateOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityUpdateOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityUpdateMany?:(ModelTypes["UserNotificationSubscriptionEntity"] | undefined)[],
	UserNotificationSubscriptionEntityDeleteOne:boolean,
	UserNotificationEntityCreateOne?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityUpdateOne?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityUpdateMany?:(ModelTypes["UserNotificationEntity"] | undefined)[],
	UserNotificationEntityDeleteOne:boolean,
	UserTemporaryAccessTokenEntityEntityCreateOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityUpdateOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityUpdateMany?:(ModelTypes["UserTemporaryAccessTokenEntityEntity"] | undefined)[],
	UserTemporaryAccessTokenEntityEntityDeleteOne:boolean,
	UserEntityCreateOne?:ModelTypes["UserEntity"],
	UserEntityUpdateOne?:ModelTypes["UserEntity"],
	UserEntityUpdateMany?:(ModelTypes["UserEntity"] | undefined)[],
	UserEntityDeleteOne:boolean,
	PicklistDefinitionEntityCreateOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityUpdateOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityUpdateMany?:(ModelTypes["PicklistDefinitionEntity"] | undefined)[],
	PicklistDefinitionEntityDeleteOne:boolean,
	PicklistStateEntityCreateOne?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityUpdateOne?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityUpdateMany?:(ModelTypes["PicklistStateEntity"] | undefined)[],
	PicklistStateEntityDeleteOne:boolean,
	HousePartGroupEntityCreateOne?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityUpdateOne?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityUpdateMany?:(ModelTypes["HousePartGroupEntity"] | undefined)[],
	HousePartGroupEntityDeleteOne:boolean,
	HousePartEntityCreateOne?:ModelTypes["HousePartEntity"],
	HousePartEntityUpdateOne?:ModelTypes["HousePartEntity"],
	HousePartEntityUpdateMany?:(ModelTypes["HousePartEntity"] | undefined)[],
	HousePartEntityDeleteOne:boolean,
	CargoPhaseEntityCreateOne?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityUpdateOne?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityUpdateMany?:(ModelTypes["CargoPhaseEntity"] | undefined)[],
	CargoPhaseEntityDeleteOne:boolean,
	CargoTypeEntityCreateOne?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityUpdateOne?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityUpdateMany?:(ModelTypes["CargoTypeEntity"] | undefined)[],
	CargoTypeEntityDeleteOne:boolean,
	PlanningHumanResourceEntityCreateOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityUpdateOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityUpdateMany?:(ModelTypes["PlanningHumanResourceEntity"] | undefined)[],
	PlanningHumanResourceEntityDeleteOne:boolean,
	PlanningProjectAssignmentEntityCreateOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityUpdateOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityUpdateMany?:(ModelTypes["PlanningProjectAssignmentEntity"] | undefined)[],
	PlanningProjectAssignmentEntityDeleteOne:boolean,
	PlanningProjectCompetencePlacementEntityCreateOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityUpdateOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityUpdateMany?:(ModelTypes["PlanningProjectCompetencePlacementEntity"] | undefined)[],
	PlanningProjectCompetencePlacementEntityDeleteOne:boolean,
	PlanningProjectRequestPlacementEntityCreateOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityUpdateOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityUpdateMany?:(ModelTypes["PlanningProjectRequestPlacementEntity"] | undefined)[],
	PlanningProjectRequestPlacementEntityDeleteOne:boolean,
	PlanningProjectEntityCreateOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityUpdateOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityUpdateMany?:(ModelTypes["PlanningProjectEntity"] | undefined)[],
	PlanningProjectEntityDeleteOne:boolean,
	PlanningProjectRequestEntityCreateOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityUpdateOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityUpdateMany?:(ModelTypes["PlanningProjectRequestEntity"] | undefined)[],
	PlanningProjectRequestEntityDeleteOne:boolean,
	PlanningProjectCompetenceEntityCreateOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityUpdateOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityUpdateMany?:(ModelTypes["PlanningProjectCompetenceEntity"] | undefined)[],
	PlanningProjectCompetenceEntityDeleteOne:boolean,
	PlanningCompetenceEntityCreateOne?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityUpdateOne?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityUpdateMany?:(ModelTypes["PlanningCompetenceEntity"] | undefined)[],
	PlanningCompetenceEntityDeleteOne:boolean,
	CargoStateEntityCreateOne?:ModelTypes["CargoStateEntity"],
	CargoStateEntityUpdateOne?:ModelTypes["CargoStateEntity"],
	CargoStateEntityUpdateMany?:(ModelTypes["CargoStateEntity"] | undefined)[],
	CargoStateEntityDeleteOne:boolean,
	CargoEntityCreateOne?:ModelTypes["CargoEntity"],
	CargoEntityUpdateOne?:ModelTypes["CargoEntity"],
	CargoEntityUpdateMany?:(ModelTypes["CargoEntity"] | undefined)[],
	CargoEntityDeleteOne:boolean,
	ProjectHousePartEntityCreateOne?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityUpdateOne?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityUpdateMany?:(ModelTypes["ProjectHousePartEntity"] | undefined)[],
	ProjectHousePartEntityDeleteOne:boolean,
	PicklistElementEntityCreateOne?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityUpdateOne?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityUpdateMany?:(ModelTypes["PicklistElementEntity"] | undefined)[],
	PicklistElementEntityDeleteOne:boolean,
	PicklistEntityCreateOne?:ModelTypes["PicklistEntity"],
	PicklistEntityUpdateOne?:ModelTypes["PicklistEntity"],
	PicklistEntityUpdateMany?:(ModelTypes["PicklistEntity"] | undefined)[],
	PicklistEntityDeleteOne:boolean,
	DrawOrderTypeEntityCreateOne?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityUpdateOne?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityUpdateMany?:(ModelTypes["DrawOrderTypeEntity"] | undefined)[],
	DrawOrderTypeEntityDeleteOne:boolean,
	HouseModelEntityCreateOne?:ModelTypes["HouseModelEntity"],
	HouseModelEntityUpdateOne?:ModelTypes["HouseModelEntity"],
	HouseModelEntityUpdateMany?:(ModelTypes["HouseModelEntity"] | undefined)[],
	HouseModelEntityDeleteOne:boolean,
	SalesEmployeeEntityCreateOne?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityUpdateOne?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityUpdateMany?:(ModelTypes["SalesEmployeeEntity"] | undefined)[],
	SalesEmployeeEntityDeleteOne:boolean,
	DrawerAbsentEntityCreateOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityUpdateOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityUpdateMany?:(ModelTypes["DrawerAbsentEntity"] | undefined)[],
	DrawerAbsentEntityDeleteOne:boolean,
	DrawerEntityCreateOne?:ModelTypes["DrawerEntity"],
	DrawerEntityUpdateOne?:ModelTypes["DrawerEntity"],
	DrawerEntityUpdateMany?:(ModelTypes["DrawerEntity"] | undefined)[],
	DrawerEntityDeleteOne:boolean,
	DrawOrderEntityCreateOne?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityUpdateOne?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityUpdateMany?:(ModelTypes["DrawOrderEntity"] | undefined)[],
	DrawOrderEntityDeleteOne:boolean,
	DateGroupCommentEntityCreateOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityUpdateOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityUpdateMany?:(ModelTypes["DateGroupCommentEntity"] | undefined)[],
	DateGroupCommentEntityDeleteOne:boolean,
	DateGroupResponsibleEntityCreateOne?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityUpdateOne?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityUpdateMany?:(ModelTypes["DateGroupResponsibleEntity"] | undefined)[],
	DateGroupResponsibleEntityDeleteOne:boolean,
	LogicFieldEntityCreateOne?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityUpdateOne?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityUpdateMany?:(ModelTypes["LogicFieldEntity"] | undefined)[],
	LogicFieldEntityDeleteOne:boolean,
	DateGroupEntityCreateOne?:ModelTypes["DateGroupEntity"],
	DateGroupEntityUpdateOne?:ModelTypes["DateGroupEntity"],
	DateGroupEntityUpdateMany?:(ModelTypes["DateGroupEntity"] | undefined)[],
	DateGroupEntityDeleteOne:boolean,
	DateEntityCreateOne?:ModelTypes["DateEntity"],
	DateEntityUpdateOne?:ModelTypes["DateEntity"],
	DateEntityUpdateMany?:(ModelTypes["DateEntity"] | undefined)[],
	DateEntityDeleteOne:boolean,
	DateValueEntityCreateOne?:ModelTypes["DateValueEntity"],
	DateValueEntityUpdateOne?:ModelTypes["DateValueEntity"],
	DateValueEntityUpdateMany?:(ModelTypes["DateValueEntity"] | undefined)[],
	DateValueEntityDeleteOne:boolean,
	LogicFieldValueEntityCreateOne?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityUpdateOne?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityUpdateMany?:(ModelTypes["LogicFieldValueEntity"] | undefined)[],
	LogicFieldValueEntityDeleteOne:boolean,
	DateCacheEntityCreateOne?:ModelTypes["DateCacheEntity"],
	DateCacheEntityUpdateOne?:ModelTypes["DateCacheEntity"],
	DateCacheEntityUpdateMany?:(ModelTypes["DateCacheEntity"] | undefined)[],
	DateCacheEntityDeleteOne:boolean,
	YearPlanningModificationEntityCreateOne?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityUpdateOne?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityUpdateMany?:(ModelTypes["YearPlanningModificationEntity"] | undefined)[],
	YearPlanningModificationEntityDeleteOne:boolean,
	ProjectCachedValueEntityCreateOne?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityUpdateOne?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityUpdateMany?:(ModelTypes["ProjectCachedValueEntity"] | undefined)[],
	ProjectCachedValueEntityDeleteOne:boolean,
	TableLayoutCommentEntityCreateOne?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityUpdateOne?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityUpdateMany?:(ModelTypes["TableLayoutCommentEntity"] | undefined)[],
	TableLayoutCommentEntityDeleteOne:boolean,
	ProjectEntityCreateOne?:ModelTypes["ProjectEntity"],
	ProjectEntityUpdateOne?:ModelTypes["ProjectEntity"],
	ProjectEntityUpdateMany?:(ModelTypes["ProjectEntity"] | undefined)[],
	ProjectEntityDeleteOne:boolean,
	SyncCronEntityCreateOne?:ModelTypes["SyncCronEntity"],
	SyncCronEntityUpdateOne?:ModelTypes["SyncCronEntity"],
	SyncCronEntityUpdateMany?:(ModelTypes["SyncCronEntity"] | undefined)[],
	SyncCronEntityDeleteOne:boolean,
	MailSchedulerEntityCreateOne?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityUpdateOne?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityUpdateMany?:(ModelTypes["MailSchedulerEntity"] | undefined)[],
	MailSchedulerEntityDeleteOne:boolean,
	ProjectPartnerEntityCreateOne?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityUpdateOne?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityUpdateMany?:(ModelTypes["ProjectPartnerEntity"] | undefined)[],
	ProjectPartnerEntityDeleteOne:boolean,
	PartnerEntityCreateOne?:ModelTypes["PartnerEntity"],
	PartnerEntityUpdateOne?:ModelTypes["PartnerEntity"],
	PartnerEntityUpdateMany?:(ModelTypes["PartnerEntity"] | undefined)[],
	PartnerEntityDeleteOne:boolean,
	DocumentMetaEntityCreateOne?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityUpdateOne?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityUpdateMany?:(ModelTypes["DocumentMetaEntity"] | undefined)[],
	DocumentMetaEntityDeleteOne:boolean,
	EntityAdvancementEntityCreateOne?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityUpdateOne?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityUpdateMany?:(ModelTypes["EntityAdvancementEntity"] | undefined)[],
	EntityAdvancementEntityDeleteOne:boolean,
	WorkActionCategoryEntityCreateOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityUpdateOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityUpdateMany?:(ModelTypes["WorkActionCategoryEntity"] | undefined)[],
	WorkActionCategoryEntityDeleteOne:boolean,
	WorkActionEntityCreateOne?:ModelTypes["WorkActionEntity"],
	WorkActionEntityUpdateOne?:ModelTypes["WorkActionEntity"],
	WorkActionEntityUpdateMany?:(ModelTypes["WorkActionEntity"] | undefined)[],
	WorkActionEntityDeleteOne:boolean,
	EntityImageEntityCreateOne?:ModelTypes["EntityImageEntity"],
	EntityImageEntityUpdateOne?:ModelTypes["EntityImageEntity"],
	EntityImageEntityUpdateMany?:(ModelTypes["EntityImageEntity"] | undefined)[],
	EntityImageEntityDeleteOne:boolean,
	CustomerQuestionEntityCreateOne?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityUpdateOne?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityUpdateMany?:(ModelTypes["CustomerQuestionEntity"] | undefined)[],
	CustomerQuestionEntityDeleteOne:boolean,
	AuditCategoryEntityCreateOne?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityUpdateOne?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityUpdateMany?:(ModelTypes["AuditCategoryEntity"] | undefined)[],
	AuditCategoryEntityDeleteOne:boolean,
	AuditQuestionEntityCreateOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityUpdateOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityUpdateMany?:(ModelTypes["AuditQuestionEntity"] | undefined)[],
	AuditQuestionEntityDeleteOne:boolean,
	AuditQuestionCostCategoryEntityCreateOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityUpdateOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityUpdateMany?:(ModelTypes["AuditQuestionCostCategoryEntity"] | undefined)[],
	AuditQuestionCostCategoryEntityDeleteOne:boolean,
	CostCategoryEntityCreateOne?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityUpdateOne?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityUpdateMany?:(ModelTypes["CostCategoryEntity"] | undefined)[],
	CostCategoryEntityDeleteOne:boolean,
	AuditReportItemImageEntityCreateOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityUpdateOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityUpdateMany?:(ModelTypes["AuditReportItemImageEntity"] | undefined)[],
	AuditReportItemImageEntityDeleteOne:boolean,
	AuditReportItemEntityCreateOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityUpdateOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityUpdateMany?:(ModelTypes["AuditReportItemEntity"] | undefined)[],
	AuditReportItemEntityDeleteOne:boolean,
	AuditReportEntityCreateOne?:ModelTypes["AuditReportEntity"],
	AuditReportEntityUpdateOne?:ModelTypes["AuditReportEntity"],
	AuditReportEntityUpdateMany?:(ModelTypes["AuditReportEntity"] | undefined)[],
	AuditReportEntityDeleteOne:boolean,
	AuditChecklistImageEntityCreateOne?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityUpdateOne?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityUpdateMany?:(ModelTypes["AuditChecklistImageEntity"] | undefined)[],
	AuditChecklistImageEntityDeleteOne:boolean,
	InvoiceAttachmentEntityCreateOne?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityUpdateOne?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityUpdateMany?:(ModelTypes["InvoiceAttachmentEntity"] | undefined)[],
	InvoiceAttachmentEntityDeleteOne:boolean,
	EmployeeDocumentEntityCreateOne?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityUpdateOne?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityUpdateMany?:(ModelTypes["EmployeeDocumentEntity"] | undefined)[],
	EmployeeDocumentEntityDeleteOne:boolean,
	WorkActionTemplateGroupEntityCreateOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityUpdateOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityUpdateMany?:(ModelTypes["WorkActionTemplateGroupEntity"] | undefined)[],
	WorkActionTemplateGroupEntityDeleteOne:boolean,
	WorkActionTemplateEntityCreateOne?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityUpdateOne?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityUpdateMany?:(ModelTypes["WorkActionTemplateEntity"] | undefined)[],
	WorkActionTemplateEntityDeleteOne:boolean,
	ProjectCostInfoEntityCreateOne?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityUpdateOne?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityUpdateMany?:(ModelTypes["ProjectCostInfoEntity"] | undefined)[],
	ProjectCostInfoEntityDeleteOne:boolean,
	BlockedCalendarWeekEntityCreateOne?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityUpdateOne?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityUpdateMany?:(ModelTypes["BlockedCalendarWeekEntity"] | undefined)[],
	BlockedCalendarWeekEntityDeleteOne:boolean,
	MaintenanceReportEntityCreateOne?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityUpdateOne?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityUpdateMany?:(ModelTypes["MaintenanceReportEntity"] | undefined)[],
	MaintenanceReportEntityDeleteOne:boolean,
	ConfigurationEntityCreateOne?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityUpdateOne?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityUpdateMany?:(ModelTypes["ConfigurationEntity"] | undefined)[],
	ConfigurationEntityDeleteOne:boolean,
	ServiceMeterEntityCreateOne?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityUpdateOne?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityUpdateMany?:(ModelTypes["ServiceMeterEntity"] | undefined)[],
	ServiceMeterEntityDeleteOne:boolean,
	DeliveryListMeterEntityCreateOne?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityUpdateOne?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityUpdateMany?:(ModelTypes["DeliveryListMeterEntity"] | undefined)[],
	DeliveryListMeterEntityDeleteOne:boolean,
	ServiceSpaceEntityCreateOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityUpdateOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityUpdateMany?:(ModelTypes["ServiceSpaceEntity"] | undefined)[],
	ServiceSpaceEntityDeleteOne:boolean,
	ServiceTypeEntityCreateOne?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityUpdateOne?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityUpdateMany?:(ModelTypes["ServiceTypeEntity"] | undefined)[],
	ServiceTypeEntityDeleteOne:boolean,
	DeliveryListPointImageEntityCreateOne?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityUpdateOne?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityUpdateMany?:(ModelTypes["DeliveryListPointImageEntity"] | undefined)[],
	DeliveryListPointImageEntityDeleteOne:boolean,
	ServicePriorityEntityCreateOne?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityUpdateOne?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityUpdateMany?:(ModelTypes["ServicePriorityEntity"] | undefined)[],
	ServicePriorityEntityDeleteOne:boolean,
	DeliveryListPointEntityCreateOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityUpdateOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityUpdateMany?:(ModelTypes["DeliveryListPointEntity"] | undefined)[],
	DeliveryListPointEntityDeleteOne:boolean,
	DeliveryListSignatureEntityCreateOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityUpdateOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityUpdateMany?:(ModelTypes["DeliveryListSignatureEntity"] | undefined)[],
	DeliveryListSignatureEntityDeleteOne:boolean,
	DeliveryListEntityCreateOne?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityUpdateOne?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityUpdateMany?:(ModelTypes["DeliveryListEntity"] | undefined)[],
	DeliveryListEntityDeleteOne:boolean,
	SupplyInspectionReportAnswerEntityCreateOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityUpdateOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityUpdateMany?:(ModelTypes["SupplyInspectionReportAnswerEntity"] | undefined)[],
	SupplyInspectionReportAnswerEntityDeleteOne:boolean,
	SupplyInspectionReportEntityCreateOne?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityUpdateOne?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityUpdateMany?:(ModelTypes["SupplyInspectionReportEntity"] | undefined)[],
	SupplyInspectionReportEntityDeleteOne:boolean,
	DepartmentEntityCreateOne?:ModelTypes["DepartmentEntity"],
	DepartmentEntityUpdateOne?:ModelTypes["DepartmentEntity"],
	DepartmentEntityUpdateMany?:(ModelTypes["DepartmentEntity"] | undefined)[],
	DepartmentEntityDeleteOne:boolean,
	DrawOrderDescriptionEntityCreateOne?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityUpdateOne?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityUpdateMany?:(ModelTypes["DrawOrderDescriptionEntity"] | undefined)[],
	DrawOrderDescriptionEntityDeleteOne:boolean,
	GreetingEntityCreateOne?:ModelTypes["GreetingEntity"],
	GreetingEntityUpdateOne?:ModelTypes["GreetingEntity"],
	GreetingEntityUpdateMany?:(ModelTypes["GreetingEntity"] | undefined)[],
	GreetingEntityDeleteOne:boolean,
	HourDeclarationTypeEntityCreateOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityUpdateOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityUpdateMany?:(ModelTypes["HourDeclarationTypeEntity"] | undefined)[],
	HourDeclarationTypeEntityDeleteOne:boolean,
	HourDeclarationActivityEntityCreateOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityUpdateOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityUpdateMany?:(ModelTypes["HourDeclarationActivityEntity"] | undefined)[],
	HourDeclarationActivityEntityDeleteOne:boolean,
	HourDeclarationEntityCreateOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityUpdateOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityUpdateMany?:(ModelTypes["HourDeclarationEntity"] | undefined)[],
	HourDeclarationEntityDeleteOne:boolean,
	HourDeclarationApprovalRequestEntityCreateOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityUpdateOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityUpdateMany?:(ModelTypes["HourDeclarationApprovalRequestEntity"] | undefined)[],
	HourDeclarationApprovalRequestEntityDeleteOne:boolean,
	InstallationReportCommentEntityCreateOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityUpdateOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityUpdateMany?:(ModelTypes["InstallationReportCommentEntity"] | undefined)[],
	InstallationReportCommentEntityDeleteOne:boolean,
	InstallationReportDescriptionEntityCreateOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityUpdateOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityUpdateMany?:(ModelTypes["InstallationReportDescriptionEntity"] | undefined)[],
	InstallationReportDescriptionEntityDeleteOne:boolean,
	InstallationReportFinalEntityCreateOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityUpdateOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityUpdateMany?:(ModelTypes["InstallationReportFinalEntity"] | undefined)[],
	InstallationReportFinalEntityDeleteOne:boolean,
	InstallationReportMeasurementTemplateEntityCreateOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityUpdateOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityUpdateMany?:(ModelTypes["InstallationReportMeasurementTemplateEntity"] | undefined)[],
	InstallationReportMeasurementTemplateEntityDeleteOne:boolean,
	InstallationReportMeasurementEntityCreateOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityUpdateOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityUpdateMany?:(ModelTypes["InstallationReportMeasurementEntity"] | undefined)[],
	InstallationReportMeasurementEntityDeleteOne:boolean,
	InstallationReportQuestionGroupEntityCreateOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityUpdateOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityUpdateMany?:(ModelTypes["InstallationReportQuestionGroupEntity"] | undefined)[],
	InstallationReportQuestionGroupEntityDeleteOne:boolean,
	InstallationReportQuestionEntityCreateOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityUpdateOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityUpdateMany?:(ModelTypes["InstallationReportQuestionEntity"] | undefined)[],
	InstallationReportQuestionEntityDeleteOne:boolean,
	InstallationReportQuestionAnswerEntityCreateOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityUpdateOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityUpdateMany?:(ModelTypes["InstallationReportQuestionAnswerEntity"] | undefined)[],
	InstallationReportQuestionAnswerEntityDeleteOne:boolean,
	LegacyProjectEntityCreateOne?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityUpdateOne?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityUpdateMany?:(ModelTypes["LegacyProjectEntity"] | undefined)[],
	LegacyProjectEntityDeleteOne:boolean,
	PicklistCommentEntityCreateOne?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityUpdateOne?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityUpdateMany?:(ModelTypes["PicklistCommentEntity"] | undefined)[],
	PicklistCommentEntityDeleteOne:boolean,
	PicklistElementStateEntityCreateOne?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityUpdateOne?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityUpdateMany?:(ModelTypes["PicklistElementStateEntity"] | undefined)[],
	PicklistElementStateEntityDeleteOne:boolean,
	PlanningProjectItemEntityCreateOne?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityUpdateOne?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityUpdateMany?:(ModelTypes["PlanningProjectItemEntity"] | undefined)[],
	PlanningProjectItemEntityDeleteOne:boolean,
	ProjectCargoStateEntityCreateOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityUpdateOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityUpdateMany?:(ModelTypes["ProjectCargoStateEntity"] | undefined)[],
	ProjectCargoStateEntityDeleteOne:boolean,
	ProjectPaymentEntityCreateOne?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityUpdateOne?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityUpdateMany?:(ModelTypes["ProjectPaymentEntity"] | undefined)[],
	ProjectPaymentEntityDeleteOne:boolean,
	QualityAssuranceEntityCreateOne?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityUpdateOne?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityUpdateMany?:(ModelTypes["QualityAssuranceEntity"] | undefined)[],
	QualityAssuranceEntityDeleteOne:boolean,
	ServiceMapColorEntityCreateOne?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityUpdateOne?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityUpdateMany?:(ModelTypes["ServiceMapColorEntity"] | undefined)[],
	ServiceMapColorEntityDeleteOne:boolean,
	ServicePlanningStageEntityCreateOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityUpdateOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityUpdateMany?:(ModelTypes["ServicePlanningStageEntity"] | undefined)[],
	ServicePlanningStageEntityDeleteOne:boolean,
	ServicePlanningEventEntityCreateOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityUpdateOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityUpdateMany?:(ModelTypes["ServicePlanningEventEntity"] | undefined)[],
	ServicePlanningEventEntityDeleteOne:boolean,
	ServicePlanningAssignmentEntityCreateOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityUpdateOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityUpdateMany?:(ModelTypes["ServicePlanningAssignmentEntity"] | undefined)[],
	ServicePlanningAssignmentEntityDeleteOne:boolean,
	ServicePresetEntityCreateOne?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityUpdateOne?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityUpdateMany?:(ModelTypes["ServicePresetEntity"] | undefined)[],
	ServicePresetEntityDeleteOne:boolean,
	SupplierDocumentEntityCreateOne?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityUpdateOne?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityUpdateMany?:(ModelTypes["SupplierDocumentEntity"] | undefined)[],
	SupplierDocumentEntityDeleteOne:boolean,
	SupplyInspectionQuestionEntityCreateOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityUpdateOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityUpdateMany?:(ModelTypes["SupplyInspectionQuestionEntity"] | undefined)[],
	SupplyInspectionQuestionEntityDeleteOne:boolean,
	SupplyInspectionQuestionGroupEntityCreateOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityUpdateOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityUpdateMany?:(ModelTypes["SupplyInspectionQuestionGroupEntity"] | undefined)[],
	SupplyInspectionQuestionGroupEntityDeleteOne:boolean,
	UserPasswordResetEntityCreateOne?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityUpdateOne?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityUpdateMany?:(ModelTypes["UserPasswordResetEntity"] | undefined)[],
	UserPasswordResetEntityDeleteOne:boolean,
	YearPlanningLineEntityCreateOne?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityUpdateOne?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityUpdateMany?:(ModelTypes["YearPlanningLineEntity"] | undefined)[],
	YearPlanningLineEntityDeleteOne:boolean,
	InvoicePaymentBatchEntityEntityCreateOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityUpdateOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityUpdateMany?:(ModelTypes["InvoicePaymentBatchEntityEntity"] | undefined)[],
	InvoicePaymentBatchEntityEntityDeleteOne:boolean,
	AppPhaseContactEntityCreateOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityUpdateOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityUpdateMany?:(ModelTypes["AppPhaseContactEntity"] | undefined)[],
	AppPhaseContactEntityDeleteOne:boolean,
	AppPhaseEntityCreateOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityUpdateOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityUpdateMany?:(ModelTypes["AppPhaseEntity"] | undefined)[],
	AppPhaseEntityDeleteOne:boolean,
	AppPhaseSectionEntityCreateOne?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityUpdateOne?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityUpdateMany?:(ModelTypes["AppPhaseSectionEntity"] | undefined)[],
	AppPhaseSectionEntityDeleteOne:boolean,
	AppNotificationTemplateEntityCreateOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityUpdateOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityUpdateMany?:(ModelTypes["AppNotificationTemplateEntity"] | undefined)[],
	AppNotificationTemplateEntityDeleteOne:boolean,
	AppPhaseSectionMarkEntityCreateOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityUpdateOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityUpdateMany?:(ModelTypes["AppPhaseSectionMarkEntity"] | undefined)[],
	AppPhaseSectionMarkEntityDeleteOne:boolean,
	AppProjectDateEntityCreateOne?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityUpdateOne?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityUpdateMany?:(ModelTypes["AppProjectDateEntity"] | undefined)[],
	AppProjectDateEntityDeleteOne:boolean,
	AppNotificationScheduleEntityCreateOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityUpdateOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityUpdateMany?:(ModelTypes["AppNotificationScheduleEntity"] | undefined)[],
	AppNotificationScheduleEntityDeleteOne:boolean,
	AppPhaseSectionMarkValueEntityCreateOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityUpdateOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityUpdateMany?:(ModelTypes["AppPhaseSectionMarkValueEntity"] | undefined)[],
	AppPhaseSectionMarkValueEntityDeleteOne:boolean,
	AppInviteEntityCreateOne?:ModelTypes["AppInviteEntity"],
	AppInviteEntityUpdateOne?:ModelTypes["AppInviteEntity"],
	AppInviteEntityUpdateMany?:(ModelTypes["AppInviteEntity"] | undefined)[],
	AppInviteEntityDeleteOne:boolean,
	AppLoginEntityCreateOne?:ModelTypes["AppLoginEntity"],
	AppLoginEntityUpdateOne?:ModelTypes["AppLoginEntity"],
	AppLoginEntityUpdateMany?:(ModelTypes["AppLoginEntity"] | undefined)[],
	AppLoginEntityDeleteOne:boolean,
	AppNotificationEntityCreateOne?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityUpdateOne?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityUpdateMany?:(ModelTypes["AppNotificationEntity"] | undefined)[],
	AppNotificationEntityDeleteOne:boolean,
	AppProjectEntityCreateOne?:ModelTypes["AppProjectEntity"],
	AppProjectEntityUpdateOne?:ModelTypes["AppProjectEntity"],
	AppProjectEntityUpdateMany?:(ModelTypes["AppProjectEntity"] | undefined)[],
	AppProjectEntityDeleteOne:boolean,
	AppUserEntityCreateOne?:ModelTypes["AppUserEntity"],
	AppUserEntityUpdateOne?:ModelTypes["AppUserEntity"],
	AppUserEntityUpdateMany?:(ModelTypes["AppUserEntity"] | undefined)[],
	AppUserEntityDeleteOne:boolean,
	AppProjectCostEntityCreateOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityUpdateOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityUpdateMany?:(ModelTypes["AppProjectCostEntity"] | undefined)[],
	AppProjectCostEntityDeleteOne:boolean,
	AppProjectContactEntityCreateOne?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityUpdateOne?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityUpdateMany?:(ModelTypes["AppProjectContactEntity"] | undefined)[],
	AppProjectContactEntityDeleteOne:boolean,
	AppBuildingWeekEntityCreateOne?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityUpdateOne?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityUpdateMany?:(ModelTypes["AppBuildingWeekEntity"] | undefined)[],
	AppBuildingWeekEntityDeleteOne:boolean,
	AppContentEntityCreateOne?:ModelTypes["AppContentEntity"],
	AppContentEntityUpdateOne?:ModelTypes["AppContentEntity"],
	AppContentEntityUpdateMany?:(ModelTypes["AppContentEntity"] | undefined)[],
	AppContentEntityDeleteOne:boolean,
	AppConversationMessagePresetEntityCreateOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityUpdateOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityUpdateMany?:(ModelTypes["AppConversationMessagePresetEntity"] | undefined)[],
	AppConversationMessagePresetEntityDeleteOne:boolean,
	AppDocumentSignatureEntityCreateOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityUpdateOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityUpdateMany?:(ModelTypes["AppDocumentSignatureEntity"] | undefined)[],
	AppDocumentSignatureEntityDeleteOne:boolean,
	AppDocumentTypeEntityCreateOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityUpdateOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityUpdateMany?:(ModelTypes["AppDocumentTypeEntity"] | undefined)[],
	AppDocumentTypeEntityDeleteOne:boolean,
	AppDocumentEntityCreateOne?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityUpdateOne?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityUpdateMany?:(ModelTypes["AppDocumentEntity"] | undefined)[],
	AppDocumentEntityDeleteOne:boolean,
	AppNotificationScheduleResourceEntityCreateOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityUpdateOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityUpdateMany?:(ModelTypes["AppNotificationScheduleResourceEntity"] | undefined)[],
	AppNotificationScheduleResourceEntityDeleteOne:boolean,
	AppPlanCompetenceEntityCreateOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityUpdateOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityUpdateMany?:(ModelTypes["AppPlanCompetenceEntity"] | undefined)[],
	AppPlanCompetenceEntityDeleteOne:boolean,
	AppPlanCompetenceSettingEntityCreateOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityUpdateOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityUpdateMany?:(ModelTypes["AppPlanCompetenceSettingEntity"] | undefined)[],
	AppPlanCompetenceSettingEntityDeleteOne:boolean,
	AppProjectCostPresetEntityCreateOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityUpdateOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityUpdateMany?:(ModelTypes["AppProjectCostPresetEntity"] | undefined)[],
	AppProjectCostPresetEntityDeleteOne:boolean,
	AppProjectFieldSheetEntityCreateOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityUpdateOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityUpdateMany?:(ModelTypes["AppProjectFieldSheetEntity"] | undefined)[],
	AppProjectFieldSheetEntityDeleteOne:boolean,
	AppProjectFieldGroupEntityCreateOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityUpdateOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityUpdateMany?:(ModelTypes["AppProjectFieldGroupEntity"] | undefined)[],
	AppProjectFieldGroupEntityDeleteOne:boolean,
	AppProjectFieldGroupAssignmentEntityCreateOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityUpdateOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityUpdateMany?:(ModelTypes["AppProjectFieldGroupAssignmentEntity"] | undefined)[],
	AppProjectFieldGroupAssignmentEntityDeleteOne:boolean,
	AppProjectFieldGroupAssignmentValueEntityCreateOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityUpdateOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityUpdateMany?:(ModelTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined)[],
	AppProjectFieldGroupAssignmentValueEntityDeleteOne:boolean,
	AppProjectFieldEntityCreateOne?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityUpdateOne?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityUpdateMany?:(ModelTypes["AppProjectFieldEntity"] | undefined)[],
	AppProjectFieldEntityDeleteOne:boolean,
	AppServiceCategoryEntityCreateOne?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityUpdateOne?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityUpdateMany?:(ModelTypes["AppServiceCategoryEntity"] | undefined)[],
	AppServiceCategoryEntityDeleteOne:boolean,
	AppTextEntityCreateOne?:ModelTypes["AppTextEntity"],
	AppTextEntityUpdateOne?:ModelTypes["AppTextEntity"],
	AppTextEntityUpdateMany?:(ModelTypes["AppTextEntity"] | undefined)[],
	AppTextEntityDeleteOne:boolean,
	AppRelationEntityCreateOne?:ModelTypes["AppRelationEntity"],
	AppRelationEntityUpdateOne?:ModelTypes["AppRelationEntity"],
	AppRelationEntityUpdateMany?:(ModelTypes["AppRelationEntity"] | undefined)[],
	AppRelationEntityDeleteOne:boolean,
	UploadDocument?:ModelTypes["DocumentMetaEntity"],
	CellaWriteCustomFields:boolean
};
	["InputGrantEntity"]: GraphQLTypes["InputGrantEntity"];
	["InputDeleteGrantEntity"]: GraphQLTypes["InputDeleteGrantEntity"];
	["InputRoleGrantConfigEntity"]: GraphQLTypes["InputRoleGrantConfigEntity"];
	["InputDeleteRoleGrantConfigEntity"]: GraphQLTypes["InputDeleteRoleGrantConfigEntity"];
	["InputRoleDocumentGrantEntity"]: GraphQLTypes["InputRoleDocumentGrantEntity"];
	["InputDeleteRoleDocumentGrantEntity"]: GraphQLTypes["InputDeleteRoleDocumentGrantEntity"];
	["InputRoleEntity"]: GraphQLTypes["InputRoleEntity"];
	["InputDeleteRoleEntity"]: GraphQLTypes["InputDeleteRoleEntity"];
	["InputUserGrantConfigEntity"]: GraphQLTypes["InputUserGrantConfigEntity"];
	["InputDeleteUserGrantConfigEntity"]: GraphQLTypes["InputDeleteUserGrantConfigEntity"];
	["InputUserNotificationSubscriptionEntity"]: GraphQLTypes["InputUserNotificationSubscriptionEntity"];
	["InputDeleteUserNotificationSubscriptionEntity"]: GraphQLTypes["InputDeleteUserNotificationSubscriptionEntity"];
	["InputUserNotificationEntity"]: GraphQLTypes["InputUserNotificationEntity"];
	["InputDeleteUserNotificationEntity"]: GraphQLTypes["InputDeleteUserNotificationEntity"];
	["InputUserTemporaryAccessTokenEntityEntity"]: GraphQLTypes["InputUserTemporaryAccessTokenEntityEntity"];
	["InputDeleteUserTemporaryAccessTokenEntityEntity"]: GraphQLTypes["InputDeleteUserTemporaryAccessTokenEntityEntity"];
	["InputUserEntity"]: GraphQLTypes["InputUserEntity"];
	["InputDeleteUserEntity"]: GraphQLTypes["InputDeleteUserEntity"];
	["InputPicklistDefinitionEntity"]: GraphQLTypes["InputPicklistDefinitionEntity"];
	["InputDeletePicklistDefinitionEntity"]: GraphQLTypes["InputDeletePicklistDefinitionEntity"];
	["InputPicklistStateEntity"]: GraphQLTypes["InputPicklistStateEntity"];
	["InputDeletePicklistStateEntity"]: GraphQLTypes["InputDeletePicklistStateEntity"];
	["InputHousePartGroupEntity"]: GraphQLTypes["InputHousePartGroupEntity"];
	["InputDeleteHousePartGroupEntity"]: GraphQLTypes["InputDeleteHousePartGroupEntity"];
	["InputHousePartEntity"]: GraphQLTypes["InputHousePartEntity"];
	["InputDeleteHousePartEntity"]: GraphQLTypes["InputDeleteHousePartEntity"];
	["InputCargoPhaseEntity"]: GraphQLTypes["InputCargoPhaseEntity"];
	["InputDeleteCargoPhaseEntity"]: GraphQLTypes["InputDeleteCargoPhaseEntity"];
	["InputCargoTypeEntity"]: GraphQLTypes["InputCargoTypeEntity"];
	["InputDeleteCargoTypeEntity"]: GraphQLTypes["InputDeleteCargoTypeEntity"];
	["InputPlanningHumanResourceEntity"]: GraphQLTypes["InputPlanningHumanResourceEntity"];
	["InputDeletePlanningHumanResourceEntity"]: GraphQLTypes["InputDeletePlanningHumanResourceEntity"];
	["InputPlanningProjectAssignmentEntity"]: GraphQLTypes["InputPlanningProjectAssignmentEntity"];
	["InputDeletePlanningProjectAssignmentEntity"]: GraphQLTypes["InputDeletePlanningProjectAssignmentEntity"];
	["InputPlanningProjectCompetencePlacementEntity"]: GraphQLTypes["InputPlanningProjectCompetencePlacementEntity"];
	["InputDeletePlanningProjectCompetencePlacementEntity"]: GraphQLTypes["InputDeletePlanningProjectCompetencePlacementEntity"];
	["InputPlanningProjectRequestPlacementEntity"]: GraphQLTypes["InputPlanningProjectRequestPlacementEntity"];
	["InputDeletePlanningProjectRequestPlacementEntity"]: GraphQLTypes["InputDeletePlanningProjectRequestPlacementEntity"];
	["InputPlanningProjectEntity"]: GraphQLTypes["InputPlanningProjectEntity"];
	["InputDeletePlanningProjectEntity"]: GraphQLTypes["InputDeletePlanningProjectEntity"];
	["InputPlanningProjectRequestEntity"]: GraphQLTypes["InputPlanningProjectRequestEntity"];
	["InputDeletePlanningProjectRequestEntity"]: GraphQLTypes["InputDeletePlanningProjectRequestEntity"];
	["InputPlanningProjectCompetenceEntity"]: GraphQLTypes["InputPlanningProjectCompetenceEntity"];
	["InputDeletePlanningProjectCompetenceEntity"]: GraphQLTypes["InputDeletePlanningProjectCompetenceEntity"];
	["InputPlanningCompetenceEntity"]: GraphQLTypes["InputPlanningCompetenceEntity"];
	["InputDeletePlanningCompetenceEntity"]: GraphQLTypes["InputDeletePlanningCompetenceEntity"];
	["InputCargoStateEntity"]: GraphQLTypes["InputCargoStateEntity"];
	["InputDeleteCargoStateEntity"]: GraphQLTypes["InputDeleteCargoStateEntity"];
	["InputCargoEntity"]: GraphQLTypes["InputCargoEntity"];
	["InputDeleteCargoEntity"]: GraphQLTypes["InputDeleteCargoEntity"];
	["InputProjectHousePartEntity"]: GraphQLTypes["InputProjectHousePartEntity"];
	["InputDeleteProjectHousePartEntity"]: GraphQLTypes["InputDeleteProjectHousePartEntity"];
	["InputPicklistElementEntity"]: GraphQLTypes["InputPicklistElementEntity"];
	["InputDeletePicklistElementEntity"]: GraphQLTypes["InputDeletePicklistElementEntity"];
	["InputPicklistEntity"]: GraphQLTypes["InputPicklistEntity"];
	["InputDeletePicklistEntity"]: GraphQLTypes["InputDeletePicklistEntity"];
	["InputDrawOrderTypeEntity"]: GraphQLTypes["InputDrawOrderTypeEntity"];
	["InputDeleteDrawOrderTypeEntity"]: GraphQLTypes["InputDeleteDrawOrderTypeEntity"];
	["InputHouseModelEntity"]: GraphQLTypes["InputHouseModelEntity"];
	["InputDeleteHouseModelEntity"]: GraphQLTypes["InputDeleteHouseModelEntity"];
	["InputSalesEmployeeEntity"]: GraphQLTypes["InputSalesEmployeeEntity"];
	["InputDeleteSalesEmployeeEntity"]: GraphQLTypes["InputDeleteSalesEmployeeEntity"];
	["InputDrawerAbsentEntity"]: GraphQLTypes["InputDrawerAbsentEntity"];
	["InputDeleteDrawerAbsentEntity"]: GraphQLTypes["InputDeleteDrawerAbsentEntity"];
	["InputDrawerEntity"]: GraphQLTypes["InputDrawerEntity"];
	["InputDeleteDrawerEntity"]: GraphQLTypes["InputDeleteDrawerEntity"];
	["InputDrawOrderEntity"]: GraphQLTypes["InputDrawOrderEntity"];
	["InputDeleteDrawOrderEntity"]: GraphQLTypes["InputDeleteDrawOrderEntity"];
	["InputDateGroupCommentEntity"]: GraphQLTypes["InputDateGroupCommentEntity"];
	["InputDeleteDateGroupCommentEntity"]: GraphQLTypes["InputDeleteDateGroupCommentEntity"];
	["InputDateGroupResponsibleEntity"]: GraphQLTypes["InputDateGroupResponsibleEntity"];
	["InputDeleteDateGroupResponsibleEntity"]: GraphQLTypes["InputDeleteDateGroupResponsibleEntity"];
	["InputLogicFieldEntity"]: GraphQLTypes["InputLogicFieldEntity"];
	["InputDeleteLogicFieldEntity"]: GraphQLTypes["InputDeleteLogicFieldEntity"];
	["InputDateGroupEntity"]: GraphQLTypes["InputDateGroupEntity"];
	["InputDeleteDateGroupEntity"]: GraphQLTypes["InputDeleteDateGroupEntity"];
	["InputDateEntity"]: GraphQLTypes["InputDateEntity"];
	["InputDeleteDateEntity"]: GraphQLTypes["InputDeleteDateEntity"];
	["InputDateValueEntity"]: GraphQLTypes["InputDateValueEntity"];
	["InputDeleteDateValueEntity"]: GraphQLTypes["InputDeleteDateValueEntity"];
	["InputLogicFieldValueEntity"]: GraphQLTypes["InputLogicFieldValueEntity"];
	["InputDeleteLogicFieldValueEntity"]: GraphQLTypes["InputDeleteLogicFieldValueEntity"];
	["InputDateCacheEntity"]: GraphQLTypes["InputDateCacheEntity"];
	["InputDeleteDateCacheEntity"]: GraphQLTypes["InputDeleteDateCacheEntity"];
	["InputYearPlanningModificationEntity"]: GraphQLTypes["InputYearPlanningModificationEntity"];
	["InputDeleteYearPlanningModificationEntity"]: GraphQLTypes["InputDeleteYearPlanningModificationEntity"];
	["InputProjectCachedValueEntity"]: GraphQLTypes["InputProjectCachedValueEntity"];
	["InputDeleteProjectCachedValueEntity"]: GraphQLTypes["InputDeleteProjectCachedValueEntity"];
	["InputTableLayoutCommentEntity"]: GraphQLTypes["InputTableLayoutCommentEntity"];
	["InputDeleteTableLayoutCommentEntity"]: GraphQLTypes["InputDeleteTableLayoutCommentEntity"];
	["InputProjectEntity"]: GraphQLTypes["InputProjectEntity"];
	["InputDeleteProjectEntity"]: GraphQLTypes["InputDeleteProjectEntity"];
	["InputSyncCronEntity"]: GraphQLTypes["InputSyncCronEntity"];
	["InputDeleteSyncCronEntity"]: GraphQLTypes["InputDeleteSyncCronEntity"];
	["InputMailSchedulerEntity"]: GraphQLTypes["InputMailSchedulerEntity"];
	["InputDeleteMailSchedulerEntity"]: GraphQLTypes["InputDeleteMailSchedulerEntity"];
	["InputProjectPartnerEntity"]: GraphQLTypes["InputProjectPartnerEntity"];
	["InputDeleteProjectPartnerEntity"]: GraphQLTypes["InputDeleteProjectPartnerEntity"];
	["InputPartnerEntity"]: GraphQLTypes["InputPartnerEntity"];
	["InputDeletePartnerEntity"]: GraphQLTypes["InputDeletePartnerEntity"];
	["InputDocumentMetaEntity"]: GraphQLTypes["InputDocumentMetaEntity"];
	["InputDeleteDocumentMetaEntity"]: GraphQLTypes["InputDeleteDocumentMetaEntity"];
	["InputEntityAdvancementEntity"]: GraphQLTypes["InputEntityAdvancementEntity"];
	["InputDeleteEntityAdvancementEntity"]: GraphQLTypes["InputDeleteEntityAdvancementEntity"];
	["InputWorkActionCategoryEntity"]: GraphQLTypes["InputWorkActionCategoryEntity"];
	["InputDeleteWorkActionCategoryEntity"]: GraphQLTypes["InputDeleteWorkActionCategoryEntity"];
	["InputWorkActionEntity"]: GraphQLTypes["InputWorkActionEntity"];
	["InputDeleteWorkActionEntity"]: GraphQLTypes["InputDeleteWorkActionEntity"];
	["InputEntityImageEntity"]: GraphQLTypes["InputEntityImageEntity"];
	["InputDeleteEntityImageEntity"]: GraphQLTypes["InputDeleteEntityImageEntity"];
	["InputCustomerQuestionEntity"]: GraphQLTypes["InputCustomerQuestionEntity"];
	["InputDeleteCustomerQuestionEntity"]: GraphQLTypes["InputDeleteCustomerQuestionEntity"];
	["InputAuditCategoryEntity"]: GraphQLTypes["InputAuditCategoryEntity"];
	["InputDeleteAuditCategoryEntity"]: GraphQLTypes["InputDeleteAuditCategoryEntity"];
	["InputAuditQuestionEntity"]: GraphQLTypes["InputAuditQuestionEntity"];
	["InputDeleteAuditQuestionEntity"]: GraphQLTypes["InputDeleteAuditQuestionEntity"];
	["InputAuditQuestionCostCategoryEntity"]: GraphQLTypes["InputAuditQuestionCostCategoryEntity"];
	["InputDeleteAuditQuestionCostCategoryEntity"]: GraphQLTypes["InputDeleteAuditQuestionCostCategoryEntity"];
	["InputCostCategoryEntity"]: GraphQLTypes["InputCostCategoryEntity"];
	["InputDeleteCostCategoryEntity"]: GraphQLTypes["InputDeleteCostCategoryEntity"];
	["InputAuditReportItemImageEntity"]: GraphQLTypes["InputAuditReportItemImageEntity"];
	["InputDeleteAuditReportItemImageEntity"]: GraphQLTypes["InputDeleteAuditReportItemImageEntity"];
	["InputAuditReportItemEntity"]: GraphQLTypes["InputAuditReportItemEntity"];
	["InputDeleteAuditReportItemEntity"]: GraphQLTypes["InputDeleteAuditReportItemEntity"];
	["InputAuditReportEntity"]: GraphQLTypes["InputAuditReportEntity"];
	["InputDeleteAuditReportEntity"]: GraphQLTypes["InputDeleteAuditReportEntity"];
	["InputAuditChecklistImageEntity"]: GraphQLTypes["InputAuditChecklistImageEntity"];
	["InputDeleteAuditChecklistImageEntity"]: GraphQLTypes["InputDeleteAuditChecklistImageEntity"];
	["InputInvoiceAttachmentEntity"]: GraphQLTypes["InputInvoiceAttachmentEntity"];
	["InputDeleteInvoiceAttachmentEntity"]: GraphQLTypes["InputDeleteInvoiceAttachmentEntity"];
	["InputEmployeeDocumentEntity"]: GraphQLTypes["InputEmployeeDocumentEntity"];
	["InputDeleteEmployeeDocumentEntity"]: GraphQLTypes["InputDeleteEmployeeDocumentEntity"];
	["InputWorkActionTemplateGroupEntity"]: GraphQLTypes["InputWorkActionTemplateGroupEntity"];
	["InputDeleteWorkActionTemplateGroupEntity"]: GraphQLTypes["InputDeleteWorkActionTemplateGroupEntity"];
	["InputWorkActionTemplateEntity"]: GraphQLTypes["InputWorkActionTemplateEntity"];
	["InputDeleteWorkActionTemplateEntity"]: GraphQLTypes["InputDeleteWorkActionTemplateEntity"];
	["InputProjectCostInfoEntity"]: GraphQLTypes["InputProjectCostInfoEntity"];
	["InputDeleteProjectCostInfoEntity"]: GraphQLTypes["InputDeleteProjectCostInfoEntity"];
	["InputBlockedCalendarWeekEntity"]: GraphQLTypes["InputBlockedCalendarWeekEntity"];
	["InputDeleteBlockedCalendarWeekEntity"]: GraphQLTypes["InputDeleteBlockedCalendarWeekEntity"];
	["InputMaintenanceReportEntity"]: GraphQLTypes["InputMaintenanceReportEntity"];
	["InputDeleteMaintenanceReportEntity"]: GraphQLTypes["InputDeleteMaintenanceReportEntity"];
	["InputConfigurationEntity"]: GraphQLTypes["InputConfigurationEntity"];
	["InputDeleteConfigurationEntity"]: GraphQLTypes["InputDeleteConfigurationEntity"];
	["InputServiceMeterEntity"]: GraphQLTypes["InputServiceMeterEntity"];
	["InputDeleteServiceMeterEntity"]: GraphQLTypes["InputDeleteServiceMeterEntity"];
	["InputDeliveryListMeterEntity"]: GraphQLTypes["InputDeliveryListMeterEntity"];
	["InputDeleteDeliveryListMeterEntity"]: GraphQLTypes["InputDeleteDeliveryListMeterEntity"];
	["InputServiceSpaceEntity"]: GraphQLTypes["InputServiceSpaceEntity"];
	["InputDeleteServiceSpaceEntity"]: GraphQLTypes["InputDeleteServiceSpaceEntity"];
	["InputServiceTypeEntity"]: GraphQLTypes["InputServiceTypeEntity"];
	["InputDeleteServiceTypeEntity"]: GraphQLTypes["InputDeleteServiceTypeEntity"];
	["InputDeliveryListPointImageEntity"]: GraphQLTypes["InputDeliveryListPointImageEntity"];
	["InputDeleteDeliveryListPointImageEntity"]: GraphQLTypes["InputDeleteDeliveryListPointImageEntity"];
	["InputServicePriorityEntity"]: GraphQLTypes["InputServicePriorityEntity"];
	["InputDeleteServicePriorityEntity"]: GraphQLTypes["InputDeleteServicePriorityEntity"];
	["InputDeliveryListPointEntity"]: GraphQLTypes["InputDeliveryListPointEntity"];
	["InputDeleteDeliveryListPointEntity"]: GraphQLTypes["InputDeleteDeliveryListPointEntity"];
	["InputDeliveryListSignatureEntity"]: GraphQLTypes["InputDeliveryListSignatureEntity"];
	["InputDeleteDeliveryListSignatureEntity"]: GraphQLTypes["InputDeleteDeliveryListSignatureEntity"];
	["InputDeliveryListEntity"]: GraphQLTypes["InputDeliveryListEntity"];
	["InputDeleteDeliveryListEntity"]: GraphQLTypes["InputDeleteDeliveryListEntity"];
	["InputSupplyInspectionReportAnswerEntity"]: GraphQLTypes["InputSupplyInspectionReportAnswerEntity"];
	["InputDeleteSupplyInspectionReportAnswerEntity"]: GraphQLTypes["InputDeleteSupplyInspectionReportAnswerEntity"];
	["InputSupplyInspectionReportEntity"]: GraphQLTypes["InputSupplyInspectionReportEntity"];
	["InputDeleteSupplyInspectionReportEntity"]: GraphQLTypes["InputDeleteSupplyInspectionReportEntity"];
	["InputDepartmentEntity"]: GraphQLTypes["InputDepartmentEntity"];
	["InputDeleteDepartmentEntity"]: GraphQLTypes["InputDeleteDepartmentEntity"];
	["InputDrawOrderDescriptionEntity"]: GraphQLTypes["InputDrawOrderDescriptionEntity"];
	["InputDeleteDrawOrderDescriptionEntity"]: GraphQLTypes["InputDeleteDrawOrderDescriptionEntity"];
	["InputGreetingEntity"]: GraphQLTypes["InputGreetingEntity"];
	["InputDeleteGreetingEntity"]: GraphQLTypes["InputDeleteGreetingEntity"];
	["InputHourDeclarationTypeEntity"]: GraphQLTypes["InputHourDeclarationTypeEntity"];
	["InputDeleteHourDeclarationTypeEntity"]: GraphQLTypes["InputDeleteHourDeclarationTypeEntity"];
	["InputHourDeclarationActivityEntity"]: GraphQLTypes["InputHourDeclarationActivityEntity"];
	["InputDeleteHourDeclarationActivityEntity"]: GraphQLTypes["InputDeleteHourDeclarationActivityEntity"];
	["InputHourDeclarationEntity"]: GraphQLTypes["InputHourDeclarationEntity"];
	["InputDeleteHourDeclarationEntity"]: GraphQLTypes["InputDeleteHourDeclarationEntity"];
	["InputHourDeclarationApprovalRequestEntity"]: GraphQLTypes["InputHourDeclarationApprovalRequestEntity"];
	["InputDeleteHourDeclarationApprovalRequestEntity"]: GraphQLTypes["InputDeleteHourDeclarationApprovalRequestEntity"];
	["InputInstallationReportCommentEntity"]: GraphQLTypes["InputInstallationReportCommentEntity"];
	["InputDeleteInstallationReportCommentEntity"]: GraphQLTypes["InputDeleteInstallationReportCommentEntity"];
	["InputInstallationReportDescriptionEntity"]: GraphQLTypes["InputInstallationReportDescriptionEntity"];
	["InputDeleteInstallationReportDescriptionEntity"]: GraphQLTypes["InputDeleteInstallationReportDescriptionEntity"];
	["InputInstallationReportFinalEntity"]: GraphQLTypes["InputInstallationReportFinalEntity"];
	["InputDeleteInstallationReportFinalEntity"]: GraphQLTypes["InputDeleteInstallationReportFinalEntity"];
	["InputInstallationReportMeasurementTemplateEntity"]: GraphQLTypes["InputInstallationReportMeasurementTemplateEntity"];
	["InputDeleteInstallationReportMeasurementTemplateEntity"]: GraphQLTypes["InputDeleteInstallationReportMeasurementTemplateEntity"];
	["InputInstallationReportMeasurementEntity"]: GraphQLTypes["InputInstallationReportMeasurementEntity"];
	["InputDeleteInstallationReportMeasurementEntity"]: GraphQLTypes["InputDeleteInstallationReportMeasurementEntity"];
	["InputInstallationReportQuestionGroupEntity"]: GraphQLTypes["InputInstallationReportQuestionGroupEntity"];
	["InputDeleteInstallationReportQuestionGroupEntity"]: GraphQLTypes["InputDeleteInstallationReportQuestionGroupEntity"];
	["InputInstallationReportQuestionEntity"]: GraphQLTypes["InputInstallationReportQuestionEntity"];
	["InputDeleteInstallationReportQuestionEntity"]: GraphQLTypes["InputDeleteInstallationReportQuestionEntity"];
	["InputInstallationReportQuestionAnswerEntity"]: GraphQLTypes["InputInstallationReportQuestionAnswerEntity"];
	["InputDeleteInstallationReportQuestionAnswerEntity"]: GraphQLTypes["InputDeleteInstallationReportQuestionAnswerEntity"];
	["InputLegacyProjectEntity"]: GraphQLTypes["InputLegacyProjectEntity"];
	["InputDeleteLegacyProjectEntity"]: GraphQLTypes["InputDeleteLegacyProjectEntity"];
	["InputPicklistCommentEntity"]: GraphQLTypes["InputPicklistCommentEntity"];
	["InputDeletePicklistCommentEntity"]: GraphQLTypes["InputDeletePicklistCommentEntity"];
	["InputPicklistElementStateEntity"]: GraphQLTypes["InputPicklistElementStateEntity"];
	["InputDeletePicklistElementStateEntity"]: GraphQLTypes["InputDeletePicklistElementStateEntity"];
	["InputPlanningProjectItemEntity"]: GraphQLTypes["InputPlanningProjectItemEntity"];
	["InputDeletePlanningProjectItemEntity"]: GraphQLTypes["InputDeletePlanningProjectItemEntity"];
	["InputProjectCargoStateEntity"]: GraphQLTypes["InputProjectCargoStateEntity"];
	["InputDeleteProjectCargoStateEntity"]: GraphQLTypes["InputDeleteProjectCargoStateEntity"];
	["InputProjectPaymentEntity"]: GraphQLTypes["InputProjectPaymentEntity"];
	["InputDeleteProjectPaymentEntity"]: GraphQLTypes["InputDeleteProjectPaymentEntity"];
	["InputQualityAssuranceEntity"]: GraphQLTypes["InputQualityAssuranceEntity"];
	["InputDeleteQualityAssuranceEntity"]: GraphQLTypes["InputDeleteQualityAssuranceEntity"];
	["InputServiceMapColorEntity"]: GraphQLTypes["InputServiceMapColorEntity"];
	["InputDeleteServiceMapColorEntity"]: GraphQLTypes["InputDeleteServiceMapColorEntity"];
	["InputServicePlanningStageEntity"]: GraphQLTypes["InputServicePlanningStageEntity"];
	["InputDeleteServicePlanningStageEntity"]: GraphQLTypes["InputDeleteServicePlanningStageEntity"];
	["InputServicePlanningEventEntity"]: GraphQLTypes["InputServicePlanningEventEntity"];
	["InputDeleteServicePlanningEventEntity"]: GraphQLTypes["InputDeleteServicePlanningEventEntity"];
	["InputServicePlanningAssignmentEntity"]: GraphQLTypes["InputServicePlanningAssignmentEntity"];
	["InputDeleteServicePlanningAssignmentEntity"]: GraphQLTypes["InputDeleteServicePlanningAssignmentEntity"];
	["InputServicePresetEntity"]: GraphQLTypes["InputServicePresetEntity"];
	["InputDeleteServicePresetEntity"]: GraphQLTypes["InputDeleteServicePresetEntity"];
	["InputSupplierDocumentEntity"]: GraphQLTypes["InputSupplierDocumentEntity"];
	["InputDeleteSupplierDocumentEntity"]: GraphQLTypes["InputDeleteSupplierDocumentEntity"];
	["InputSupplyInspectionQuestionEntity"]: GraphQLTypes["InputSupplyInspectionQuestionEntity"];
	["InputDeleteSupplyInspectionQuestionEntity"]: GraphQLTypes["InputDeleteSupplyInspectionQuestionEntity"];
	["InputSupplyInspectionQuestionGroupEntity"]: GraphQLTypes["InputSupplyInspectionQuestionGroupEntity"];
	["InputDeleteSupplyInspectionQuestionGroupEntity"]: GraphQLTypes["InputDeleteSupplyInspectionQuestionGroupEntity"];
	["InputUserPasswordResetEntity"]: GraphQLTypes["InputUserPasswordResetEntity"];
	["InputDeleteUserPasswordResetEntity"]: GraphQLTypes["InputDeleteUserPasswordResetEntity"];
	["InputYearPlanningLineEntity"]: GraphQLTypes["InputYearPlanningLineEntity"];
	["InputDeleteYearPlanningLineEntity"]: GraphQLTypes["InputDeleteYearPlanningLineEntity"];
	["InputInvoicePaymentBatchEntityEntity"]: GraphQLTypes["InputInvoicePaymentBatchEntityEntity"];
	["InputDeleteInvoicePaymentBatchEntityEntity"]: GraphQLTypes["InputDeleteInvoicePaymentBatchEntityEntity"];
	["InputAppPhaseContactEntity"]: GraphQLTypes["InputAppPhaseContactEntity"];
	["InputDeleteAppPhaseContactEntity"]: GraphQLTypes["InputDeleteAppPhaseContactEntity"];
	["InputAppPhaseEntity"]: GraphQLTypes["InputAppPhaseEntity"];
	["InputDeleteAppPhaseEntity"]: GraphQLTypes["InputDeleteAppPhaseEntity"];
	["InputAppPhaseSectionEntity"]: GraphQLTypes["InputAppPhaseSectionEntity"];
	["InputDeleteAppPhaseSectionEntity"]: GraphQLTypes["InputDeleteAppPhaseSectionEntity"];
	["InputAppNotificationTemplateEntity"]: GraphQLTypes["InputAppNotificationTemplateEntity"];
	["InputDeleteAppNotificationTemplateEntity"]: GraphQLTypes["InputDeleteAppNotificationTemplateEntity"];
	["InputAppPhaseSectionMarkEntity"]: GraphQLTypes["InputAppPhaseSectionMarkEntity"];
	["InputDeleteAppPhaseSectionMarkEntity"]: GraphQLTypes["InputDeleteAppPhaseSectionMarkEntity"];
	["InputAppProjectDateEntity"]: GraphQLTypes["InputAppProjectDateEntity"];
	["InputDeleteAppProjectDateEntity"]: GraphQLTypes["InputDeleteAppProjectDateEntity"];
	["InputAppNotificationScheduleEntity"]: GraphQLTypes["InputAppNotificationScheduleEntity"];
	["InputDeleteAppNotificationScheduleEntity"]: GraphQLTypes["InputDeleteAppNotificationScheduleEntity"];
	["InputAppPhaseSectionMarkValueEntity"]: GraphQLTypes["InputAppPhaseSectionMarkValueEntity"];
	["InputDeleteAppPhaseSectionMarkValueEntity"]: GraphQLTypes["InputDeleteAppPhaseSectionMarkValueEntity"];
	["InputAppInviteEntity"]: GraphQLTypes["InputAppInviteEntity"];
	["InputDeleteAppInviteEntity"]: GraphQLTypes["InputDeleteAppInviteEntity"];
	["InputAppLoginEntity"]: GraphQLTypes["InputAppLoginEntity"];
	["InputDeleteAppLoginEntity"]: GraphQLTypes["InputDeleteAppLoginEntity"];
	["InputAppNotificationEntity"]: GraphQLTypes["InputAppNotificationEntity"];
	["InputDeleteAppNotificationEntity"]: GraphQLTypes["InputDeleteAppNotificationEntity"];
	["InputAppProjectEntity"]: GraphQLTypes["InputAppProjectEntity"];
	["InputDeleteAppProjectEntity"]: GraphQLTypes["InputDeleteAppProjectEntity"];
	["InputAppUserEntity"]: GraphQLTypes["InputAppUserEntity"];
	["InputDeleteAppUserEntity"]: GraphQLTypes["InputDeleteAppUserEntity"];
	["InputAppProjectCostEntity"]: GraphQLTypes["InputAppProjectCostEntity"];
	["InputDeleteAppProjectCostEntity"]: GraphQLTypes["InputDeleteAppProjectCostEntity"];
	["InputAppProjectContactEntity"]: GraphQLTypes["InputAppProjectContactEntity"];
	["InputDeleteAppProjectContactEntity"]: GraphQLTypes["InputDeleteAppProjectContactEntity"];
	["InputAppBuildingWeekEntity"]: GraphQLTypes["InputAppBuildingWeekEntity"];
	["InputDeleteAppBuildingWeekEntity"]: GraphQLTypes["InputDeleteAppBuildingWeekEntity"];
	["InputAppContentEntity"]: GraphQLTypes["InputAppContentEntity"];
	["InputDeleteAppContentEntity"]: GraphQLTypes["InputDeleteAppContentEntity"];
	["InputAppConversationMessagePresetEntity"]: GraphQLTypes["InputAppConversationMessagePresetEntity"];
	["InputDeleteAppConversationMessagePresetEntity"]: GraphQLTypes["InputDeleteAppConversationMessagePresetEntity"];
	["InputAppDocumentSignatureEntity"]: GraphQLTypes["InputAppDocumentSignatureEntity"];
	["InputDeleteAppDocumentSignatureEntity"]: GraphQLTypes["InputDeleteAppDocumentSignatureEntity"];
	["InputAppDocumentTypeEntity"]: GraphQLTypes["InputAppDocumentTypeEntity"];
	["InputDeleteAppDocumentTypeEntity"]: GraphQLTypes["InputDeleteAppDocumentTypeEntity"];
	["InputAppDocumentEntity"]: GraphQLTypes["InputAppDocumentEntity"];
	["InputDeleteAppDocumentEntity"]: GraphQLTypes["InputDeleteAppDocumentEntity"];
	["InputAppNotificationScheduleResourceEntity"]: GraphQLTypes["InputAppNotificationScheduleResourceEntity"];
	["InputDeleteAppNotificationScheduleResourceEntity"]: GraphQLTypes["InputDeleteAppNotificationScheduleResourceEntity"];
	["InputAppPlanCompetenceEntity"]: GraphQLTypes["InputAppPlanCompetenceEntity"];
	["InputDeleteAppPlanCompetenceEntity"]: GraphQLTypes["InputDeleteAppPlanCompetenceEntity"];
	["InputAppPlanCompetenceSettingEntity"]: GraphQLTypes["InputAppPlanCompetenceSettingEntity"];
	["InputDeleteAppPlanCompetenceSettingEntity"]: GraphQLTypes["InputDeleteAppPlanCompetenceSettingEntity"];
	["InputAppProjectCostPresetEntity"]: GraphQLTypes["InputAppProjectCostPresetEntity"];
	["InputDeleteAppProjectCostPresetEntity"]: GraphQLTypes["InputDeleteAppProjectCostPresetEntity"];
	["InputAppProjectFieldSheetEntity"]: GraphQLTypes["InputAppProjectFieldSheetEntity"];
	["InputDeleteAppProjectFieldSheetEntity"]: GraphQLTypes["InputDeleteAppProjectFieldSheetEntity"];
	["InputAppProjectFieldGroupEntity"]: GraphQLTypes["InputAppProjectFieldGroupEntity"];
	["InputDeleteAppProjectFieldGroupEntity"]: GraphQLTypes["InputDeleteAppProjectFieldGroupEntity"];
	["InputAppProjectFieldGroupAssignmentEntity"]: GraphQLTypes["InputAppProjectFieldGroupAssignmentEntity"];
	["InputDeleteAppProjectFieldGroupAssignmentEntity"]: GraphQLTypes["InputDeleteAppProjectFieldGroupAssignmentEntity"];
	["InputAppProjectFieldGroupAssignmentValueEntity"]: GraphQLTypes["InputAppProjectFieldGroupAssignmentValueEntity"];
	["InputDeleteAppProjectFieldGroupAssignmentValueEntity"]: GraphQLTypes["InputDeleteAppProjectFieldGroupAssignmentValueEntity"];
	["InputAppProjectFieldEntity"]: GraphQLTypes["InputAppProjectFieldEntity"];
	["InputDeleteAppProjectFieldEntity"]: GraphQLTypes["InputDeleteAppProjectFieldEntity"];
	["InputAppServiceCategoryEntity"]: GraphQLTypes["InputAppServiceCategoryEntity"];
	["InputDeleteAppServiceCategoryEntity"]: GraphQLTypes["InputDeleteAppServiceCategoryEntity"];
	["InputAppTextEntity"]: GraphQLTypes["InputAppTextEntity"];
	["InputDeleteAppTextEntity"]: GraphQLTypes["InputDeleteAppTextEntity"];
	["InputAppRelationEntity"]: GraphQLTypes["InputAppRelationEntity"];
	["InputDeleteAppRelationEntity"]: GraphQLTypes["InputDeleteAppRelationEntity"];
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:any;
	["CellaCustomField"]: GraphQLTypes["CellaCustomField"];
	["Subscription"]: {
		GrantEntityOnCreatedOne?:ModelTypes["GrantEntity"],
	GrantEntityOnUpdatedOne?:ModelTypes["GrantEntity"],
	GrantEntityOnDeletedOne?:ModelTypes["GrantEntity"],
	RoleGrantConfigEntityOnCreatedOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnUpdatedOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnDeletedOne?:ModelTypes["RoleGrantConfigEntity"],
	RoleDocumentGrantEntityOnCreatedOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnUpdatedOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnDeletedOne?:ModelTypes["RoleDocumentGrantEntity"],
	RoleEntityOnCreatedOne?:ModelTypes["RoleEntity"],
	RoleEntityOnUpdatedOne?:ModelTypes["RoleEntity"],
	RoleEntityOnDeletedOne?:ModelTypes["RoleEntity"],
	UserGrantConfigEntityOnCreatedOne?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnUpdatedOne?:ModelTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnDeletedOne?:ModelTypes["UserGrantConfigEntity"],
	UserNotificationSubscriptionEntityOnCreatedOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnUpdatedOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnDeletedOne?:ModelTypes["UserNotificationSubscriptionEntity"],
	UserNotificationEntityOnCreatedOne?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityOnUpdatedOne?:ModelTypes["UserNotificationEntity"],
	UserNotificationEntityOnDeletedOne?:ModelTypes["UserNotificationEntity"],
	UserTemporaryAccessTokenEntityEntityOnCreatedOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnUpdatedOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnDeletedOne?:ModelTypes["UserTemporaryAccessTokenEntityEntity"],
	UserEntityOnCreatedOne?:ModelTypes["UserEntity"],
	UserEntityOnUpdatedOne?:ModelTypes["UserEntity"],
	UserEntityOnDeletedOne?:ModelTypes["UserEntity"],
	PicklistDefinitionEntityOnCreatedOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnUpdatedOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnDeletedOne?:ModelTypes["PicklistDefinitionEntity"],
	PicklistStateEntityOnCreatedOne?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityOnUpdatedOne?:ModelTypes["PicklistStateEntity"],
	PicklistStateEntityOnDeletedOne?:ModelTypes["PicklistStateEntity"],
	HousePartGroupEntityOnCreatedOne?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnUpdatedOne?:ModelTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnDeletedOne?:ModelTypes["HousePartGroupEntity"],
	HousePartEntityOnCreatedOne?:ModelTypes["HousePartEntity"],
	HousePartEntityOnUpdatedOne?:ModelTypes["HousePartEntity"],
	HousePartEntityOnDeletedOne?:ModelTypes["HousePartEntity"],
	CargoPhaseEntityOnCreatedOne?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnUpdatedOne?:ModelTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnDeletedOne?:ModelTypes["CargoPhaseEntity"],
	CargoTypeEntityOnCreatedOne?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityOnUpdatedOne?:ModelTypes["CargoTypeEntity"],
	CargoTypeEntityOnDeletedOne?:ModelTypes["CargoTypeEntity"],
	PlanningHumanResourceEntityOnCreatedOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnUpdatedOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnDeletedOne?:ModelTypes["PlanningHumanResourceEntity"],
	PlanningProjectAssignmentEntityOnCreatedOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnUpdatedOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnDeletedOne?:ModelTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectCompetencePlacementEntityOnCreatedOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnUpdatedOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnDeletedOne?:ModelTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectRequestPlacementEntityOnCreatedOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnUpdatedOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnDeletedOne?:ModelTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectEntityOnCreatedOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnUpdatedOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnDeletedOne?:ModelTypes["PlanningProjectEntity"],
	PlanningProjectRequestEntityOnCreatedOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnUpdatedOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnDeletedOne?:ModelTypes["PlanningProjectRequestEntity"],
	PlanningProjectCompetenceEntityOnCreatedOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnUpdatedOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnDeletedOne?:ModelTypes["PlanningProjectCompetenceEntity"],
	PlanningCompetenceEntityOnCreatedOne?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnUpdatedOne?:ModelTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnDeletedOne?:ModelTypes["PlanningCompetenceEntity"],
	CargoStateEntityOnCreatedOne?:ModelTypes["CargoStateEntity"],
	CargoStateEntityOnUpdatedOne?:ModelTypes["CargoStateEntity"],
	CargoStateEntityOnDeletedOne?:ModelTypes["CargoStateEntity"],
	CargoEntityOnCreatedOne?:ModelTypes["CargoEntity"],
	CargoEntityOnUpdatedOne?:ModelTypes["CargoEntity"],
	CargoEntityOnDeletedOne?:ModelTypes["CargoEntity"],
	ProjectHousePartEntityOnCreatedOne?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnUpdatedOne?:ModelTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnDeletedOne?:ModelTypes["ProjectHousePartEntity"],
	PicklistElementEntityOnCreatedOne?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityOnUpdatedOne?:ModelTypes["PicklistElementEntity"],
	PicklistElementEntityOnDeletedOne?:ModelTypes["PicklistElementEntity"],
	PicklistEntityOnCreatedOne?:ModelTypes["PicklistEntity"],
	PicklistEntityOnUpdatedOne?:ModelTypes["PicklistEntity"],
	PicklistEntityOnDeletedOne?:ModelTypes["PicklistEntity"],
	DrawOrderTypeEntityOnCreatedOne?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnUpdatedOne?:ModelTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnDeletedOne?:ModelTypes["DrawOrderTypeEntity"],
	HouseModelEntityOnCreatedOne?:ModelTypes["HouseModelEntity"],
	HouseModelEntityOnUpdatedOne?:ModelTypes["HouseModelEntity"],
	HouseModelEntityOnDeletedOne?:ModelTypes["HouseModelEntity"],
	SalesEmployeeEntityOnCreatedOne?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnUpdatedOne?:ModelTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnDeletedOne?:ModelTypes["SalesEmployeeEntity"],
	DrawerAbsentEntityOnCreatedOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnUpdatedOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnDeletedOne?:ModelTypes["DrawerAbsentEntity"],
	DrawerEntityOnCreatedOne?:ModelTypes["DrawerEntity"],
	DrawerEntityOnUpdatedOne?:ModelTypes["DrawerEntity"],
	DrawerEntityOnDeletedOne?:ModelTypes["DrawerEntity"],
	DrawOrderEntityOnCreatedOne?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityOnUpdatedOne?:ModelTypes["DrawOrderEntity"],
	DrawOrderEntityOnDeletedOne?:ModelTypes["DrawOrderEntity"],
	DateGroupCommentEntityOnCreatedOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnUpdatedOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnDeletedOne?:ModelTypes["DateGroupCommentEntity"],
	DateGroupResponsibleEntityOnCreatedOne?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnUpdatedOne?:ModelTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnDeletedOne?:ModelTypes["DateGroupResponsibleEntity"],
	LogicFieldEntityOnCreatedOne?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityOnUpdatedOne?:ModelTypes["LogicFieldEntity"],
	LogicFieldEntityOnDeletedOne?:ModelTypes["LogicFieldEntity"],
	DateGroupEntityOnCreatedOne?:ModelTypes["DateGroupEntity"],
	DateGroupEntityOnUpdatedOne?:ModelTypes["DateGroupEntity"],
	DateGroupEntityOnDeletedOne?:ModelTypes["DateGroupEntity"],
	DateEntityOnCreatedOne?:ModelTypes["DateEntity"],
	DateEntityOnUpdatedOne?:ModelTypes["DateEntity"],
	DateEntityOnDeletedOne?:ModelTypes["DateEntity"],
	DateValueEntityOnCreatedOne?:ModelTypes["DateValueEntity"],
	DateValueEntityOnUpdatedOne?:ModelTypes["DateValueEntity"],
	DateValueEntityOnDeletedOne?:ModelTypes["DateValueEntity"],
	LogicFieldValueEntityOnCreatedOne?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnUpdatedOne?:ModelTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnDeletedOne?:ModelTypes["LogicFieldValueEntity"],
	DateCacheEntityOnCreatedOne?:ModelTypes["DateCacheEntity"],
	DateCacheEntityOnUpdatedOne?:ModelTypes["DateCacheEntity"],
	DateCacheEntityOnDeletedOne?:ModelTypes["DateCacheEntity"],
	YearPlanningModificationEntityOnCreatedOne?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnUpdatedOne?:ModelTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnDeletedOne?:ModelTypes["YearPlanningModificationEntity"],
	ProjectCachedValueEntityOnCreatedOne?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnUpdatedOne?:ModelTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnDeletedOne?:ModelTypes["ProjectCachedValueEntity"],
	TableLayoutCommentEntityOnCreatedOne?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnUpdatedOne?:ModelTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnDeletedOne?:ModelTypes["TableLayoutCommentEntity"],
	ProjectEntityOnCreatedOne?:ModelTypes["ProjectEntity"],
	ProjectEntityOnUpdatedOne?:ModelTypes["ProjectEntity"],
	ProjectEntityOnDeletedOne?:ModelTypes["ProjectEntity"],
	SyncCronEntityOnCreatedOne?:ModelTypes["SyncCronEntity"],
	SyncCronEntityOnUpdatedOne?:ModelTypes["SyncCronEntity"],
	SyncCronEntityOnDeletedOne?:ModelTypes["SyncCronEntity"],
	MailSchedulerEntityOnCreatedOne?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnUpdatedOne?:ModelTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnDeletedOne?:ModelTypes["MailSchedulerEntity"],
	ProjectPartnerEntityOnCreatedOne?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnUpdatedOne?:ModelTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnDeletedOne?:ModelTypes["ProjectPartnerEntity"],
	PartnerEntityOnCreatedOne?:ModelTypes["PartnerEntity"],
	PartnerEntityOnUpdatedOne?:ModelTypes["PartnerEntity"],
	PartnerEntityOnDeletedOne?:ModelTypes["PartnerEntity"],
	DocumentMetaEntityOnCreatedOne?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnUpdatedOne?:ModelTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnDeletedOne?:ModelTypes["DocumentMetaEntity"],
	EntityAdvancementEntityOnCreatedOne?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnUpdatedOne?:ModelTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnDeletedOne?:ModelTypes["EntityAdvancementEntity"],
	WorkActionCategoryEntityOnCreatedOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnUpdatedOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnDeletedOne?:ModelTypes["WorkActionCategoryEntity"],
	WorkActionEntityOnCreatedOne?:ModelTypes["WorkActionEntity"],
	WorkActionEntityOnUpdatedOne?:ModelTypes["WorkActionEntity"],
	WorkActionEntityOnDeletedOne?:ModelTypes["WorkActionEntity"],
	EntityImageEntityOnCreatedOne?:ModelTypes["EntityImageEntity"],
	EntityImageEntityOnUpdatedOne?:ModelTypes["EntityImageEntity"],
	EntityImageEntityOnDeletedOne?:ModelTypes["EntityImageEntity"],
	CustomerQuestionEntityOnCreatedOne?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnUpdatedOne?:ModelTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnDeletedOne?:ModelTypes["CustomerQuestionEntity"],
	AuditCategoryEntityOnCreatedOne?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnUpdatedOne?:ModelTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnDeletedOne?:ModelTypes["AuditCategoryEntity"],
	AuditQuestionEntityOnCreatedOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnUpdatedOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnDeletedOne?:ModelTypes["AuditQuestionEntity"],
	AuditQuestionCostCategoryEntityOnCreatedOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnUpdatedOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnDeletedOne?:ModelTypes["AuditQuestionCostCategoryEntity"],
	CostCategoryEntityOnCreatedOne?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityOnUpdatedOne?:ModelTypes["CostCategoryEntity"],
	CostCategoryEntityOnDeletedOne?:ModelTypes["CostCategoryEntity"],
	AuditReportItemImageEntityOnCreatedOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnUpdatedOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnDeletedOne?:ModelTypes["AuditReportItemImageEntity"],
	AuditReportItemEntityOnCreatedOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnUpdatedOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnDeletedOne?:ModelTypes["AuditReportItemEntity"],
	AuditReportEntityOnCreatedOne?:ModelTypes["AuditReportEntity"],
	AuditReportEntityOnUpdatedOne?:ModelTypes["AuditReportEntity"],
	AuditReportEntityOnDeletedOne?:ModelTypes["AuditReportEntity"],
	AuditChecklistImageEntityOnCreatedOne?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnUpdatedOne?:ModelTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnDeletedOne?:ModelTypes["AuditChecklistImageEntity"],
	InvoiceAttachmentEntityOnCreatedOne?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnUpdatedOne?:ModelTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnDeletedOne?:ModelTypes["InvoiceAttachmentEntity"],
	EmployeeDocumentEntityOnCreatedOne?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnUpdatedOne?:ModelTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnDeletedOne?:ModelTypes["EmployeeDocumentEntity"],
	WorkActionTemplateGroupEntityOnCreatedOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnUpdatedOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnDeletedOne?:ModelTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateEntityOnCreatedOne?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnUpdatedOne?:ModelTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnDeletedOne?:ModelTypes["WorkActionTemplateEntity"],
	ProjectCostInfoEntityOnCreatedOne?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnUpdatedOne?:ModelTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnDeletedOne?:ModelTypes["ProjectCostInfoEntity"],
	BlockedCalendarWeekEntityOnCreatedOne?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnUpdatedOne?:ModelTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnDeletedOne?:ModelTypes["BlockedCalendarWeekEntity"],
	MaintenanceReportEntityOnCreatedOne?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnUpdatedOne?:ModelTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnDeletedOne?:ModelTypes["MaintenanceReportEntity"],
	ConfigurationEntityOnCreatedOne?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityOnUpdatedOne?:ModelTypes["ConfigurationEntity"],
	ConfigurationEntityOnDeletedOne?:ModelTypes["ConfigurationEntity"],
	ServiceMeterEntityOnCreatedOne?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnUpdatedOne?:ModelTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnDeletedOne?:ModelTypes["ServiceMeterEntity"],
	DeliveryListMeterEntityOnCreatedOne?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnUpdatedOne?:ModelTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnDeletedOne?:ModelTypes["DeliveryListMeterEntity"],
	ServiceSpaceEntityOnCreatedOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnUpdatedOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnDeletedOne?:ModelTypes["ServiceSpaceEntity"],
	ServiceTypeEntityOnCreatedOne?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnUpdatedOne?:ModelTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnDeletedOne?:ModelTypes["ServiceTypeEntity"],
	DeliveryListPointImageEntityOnCreatedOne?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnUpdatedOne?:ModelTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnDeletedOne?:ModelTypes["DeliveryListPointImageEntity"],
	ServicePriorityEntityOnCreatedOne?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnUpdatedOne?:ModelTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnDeletedOne?:ModelTypes["ServicePriorityEntity"],
	DeliveryListPointEntityOnCreatedOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnUpdatedOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnDeletedOne?:ModelTypes["DeliveryListPointEntity"],
	DeliveryListSignatureEntityOnCreatedOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnUpdatedOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnDeletedOne?:ModelTypes["DeliveryListSignatureEntity"],
	DeliveryListEntityOnCreatedOne?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityOnUpdatedOne?:ModelTypes["DeliveryListEntity"],
	DeliveryListEntityOnDeletedOne?:ModelTypes["DeliveryListEntity"],
	SupplyInspectionReportAnswerEntityOnCreatedOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnUpdatedOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnDeletedOne?:ModelTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportEntityOnCreatedOne?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnUpdatedOne?:ModelTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnDeletedOne?:ModelTypes["SupplyInspectionReportEntity"],
	DepartmentEntityOnCreatedOne?:ModelTypes["DepartmentEntity"],
	DepartmentEntityOnUpdatedOne?:ModelTypes["DepartmentEntity"],
	DepartmentEntityOnDeletedOne?:ModelTypes["DepartmentEntity"],
	DrawOrderDescriptionEntityOnCreatedOne?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnUpdatedOne?:ModelTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnDeletedOne?:ModelTypes["DrawOrderDescriptionEntity"],
	GreetingEntityOnCreatedOne?:ModelTypes["GreetingEntity"],
	GreetingEntityOnUpdatedOne?:ModelTypes["GreetingEntity"],
	GreetingEntityOnDeletedOne?:ModelTypes["GreetingEntity"],
	HourDeclarationTypeEntityOnCreatedOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnUpdatedOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnDeletedOne?:ModelTypes["HourDeclarationTypeEntity"],
	HourDeclarationActivityEntityOnCreatedOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnUpdatedOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnDeletedOne?:ModelTypes["HourDeclarationActivityEntity"],
	HourDeclarationEntityOnCreatedOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnUpdatedOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnDeletedOne?:ModelTypes["HourDeclarationEntity"],
	HourDeclarationApprovalRequestEntityOnCreatedOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnUpdatedOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnDeletedOne?:ModelTypes["HourDeclarationApprovalRequestEntity"],
	InstallationReportCommentEntityOnCreatedOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnUpdatedOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnDeletedOne?:ModelTypes["InstallationReportCommentEntity"],
	InstallationReportDescriptionEntityOnCreatedOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnUpdatedOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnDeletedOne?:ModelTypes["InstallationReportDescriptionEntity"],
	InstallationReportFinalEntityOnCreatedOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnUpdatedOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnDeletedOne?:ModelTypes["InstallationReportFinalEntity"],
	InstallationReportMeasurementTemplateEntityOnCreatedOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnUpdatedOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnDeletedOne?:ModelTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementEntityOnCreatedOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnUpdatedOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnDeletedOne?:ModelTypes["InstallationReportMeasurementEntity"],
	InstallationReportQuestionGroupEntityOnCreatedOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnUpdatedOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnDeletedOne?:ModelTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionEntityOnCreatedOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnUpdatedOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnDeletedOne?:ModelTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionAnswerEntityOnCreatedOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnUpdatedOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnDeletedOne?:ModelTypes["InstallationReportQuestionAnswerEntity"],
	LegacyProjectEntityOnCreatedOne?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnUpdatedOne?:ModelTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnDeletedOne?:ModelTypes["LegacyProjectEntity"],
	PicklistCommentEntityOnCreatedOne?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnUpdatedOne?:ModelTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnDeletedOne?:ModelTypes["PicklistCommentEntity"],
	PicklistElementStateEntityOnCreatedOne?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnUpdatedOne?:ModelTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnDeletedOne?:ModelTypes["PicklistElementStateEntity"],
	PlanningProjectItemEntityOnCreatedOne?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnUpdatedOne?:ModelTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnDeletedOne?:ModelTypes["PlanningProjectItemEntity"],
	ProjectCargoStateEntityOnCreatedOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnUpdatedOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnDeletedOne?:ModelTypes["ProjectCargoStateEntity"],
	ProjectPaymentEntityOnCreatedOne?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnUpdatedOne?:ModelTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnDeletedOne?:ModelTypes["ProjectPaymentEntity"],
	QualityAssuranceEntityOnCreatedOne?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnUpdatedOne?:ModelTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnDeletedOne?:ModelTypes["QualityAssuranceEntity"],
	ServiceMapColorEntityOnCreatedOne?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnUpdatedOne?:ModelTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnDeletedOne?:ModelTypes["ServiceMapColorEntity"],
	ServicePlanningStageEntityOnCreatedOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnUpdatedOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnDeletedOne?:ModelTypes["ServicePlanningStageEntity"],
	ServicePlanningEventEntityOnCreatedOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnUpdatedOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnDeletedOne?:ModelTypes["ServicePlanningEventEntity"],
	ServicePlanningAssignmentEntityOnCreatedOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnUpdatedOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnDeletedOne?:ModelTypes["ServicePlanningAssignmentEntity"],
	ServicePresetEntityOnCreatedOne?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityOnUpdatedOne?:ModelTypes["ServicePresetEntity"],
	ServicePresetEntityOnDeletedOne?:ModelTypes["ServicePresetEntity"],
	SupplierDocumentEntityOnCreatedOne?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnUpdatedOne?:ModelTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnDeletedOne?:ModelTypes["SupplierDocumentEntity"],
	SupplyInspectionQuestionEntityOnCreatedOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnUpdatedOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnDeletedOne?:ModelTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionGroupEntityOnCreatedOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnUpdatedOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnDeletedOne?:ModelTypes["SupplyInspectionQuestionGroupEntity"],
	UserPasswordResetEntityOnCreatedOne?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnUpdatedOne?:ModelTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnDeletedOne?:ModelTypes["UserPasswordResetEntity"],
	YearPlanningLineEntityOnCreatedOne?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnUpdatedOne?:ModelTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnDeletedOne?:ModelTypes["YearPlanningLineEntity"],
	InvoicePaymentBatchEntityEntityOnCreatedOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnUpdatedOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnDeletedOne?:ModelTypes["InvoicePaymentBatchEntityEntity"],
	AppPhaseContactEntityOnCreatedOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnUpdatedOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnDeletedOne?:ModelTypes["AppPhaseContactEntity"],
	AppPhaseEntityOnCreatedOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityOnUpdatedOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseEntityOnDeletedOne?:ModelTypes["AppPhaseEntity"],
	AppPhaseSectionEntityOnCreatedOne?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnUpdatedOne?:ModelTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnDeletedOne?:ModelTypes["AppPhaseSectionEntity"],
	AppNotificationTemplateEntityOnCreatedOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnUpdatedOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnDeletedOne?:ModelTypes["AppNotificationTemplateEntity"],
	AppPhaseSectionMarkEntityOnCreatedOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnUpdatedOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnDeletedOne?:ModelTypes["AppPhaseSectionMarkEntity"],
	AppProjectDateEntityOnCreatedOne?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnUpdatedOne?:ModelTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnDeletedOne?:ModelTypes["AppProjectDateEntity"],
	AppNotificationScheduleEntityOnCreatedOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnUpdatedOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnDeletedOne?:ModelTypes["AppNotificationScheduleEntity"],
	AppPhaseSectionMarkValueEntityOnCreatedOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnUpdatedOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnDeletedOne?:ModelTypes["AppPhaseSectionMarkValueEntity"],
	AppInviteEntityOnCreatedOne?:ModelTypes["AppInviteEntity"],
	AppInviteEntityOnUpdatedOne?:ModelTypes["AppInviteEntity"],
	AppInviteEntityOnDeletedOne?:ModelTypes["AppInviteEntity"],
	AppLoginEntityOnCreatedOne?:ModelTypes["AppLoginEntity"],
	AppLoginEntityOnUpdatedOne?:ModelTypes["AppLoginEntity"],
	AppLoginEntityOnDeletedOne?:ModelTypes["AppLoginEntity"],
	AppNotificationEntityOnCreatedOne?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityOnUpdatedOne?:ModelTypes["AppNotificationEntity"],
	AppNotificationEntityOnDeletedOne?:ModelTypes["AppNotificationEntity"],
	AppProjectEntityOnCreatedOne?:ModelTypes["AppProjectEntity"],
	AppProjectEntityOnUpdatedOne?:ModelTypes["AppProjectEntity"],
	AppProjectEntityOnDeletedOne?:ModelTypes["AppProjectEntity"],
	AppUserEntityOnCreatedOne?:ModelTypes["AppUserEntity"],
	AppUserEntityOnUpdatedOne?:ModelTypes["AppUserEntity"],
	AppUserEntityOnDeletedOne?:ModelTypes["AppUserEntity"],
	AppProjectCostEntityOnCreatedOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnUpdatedOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnDeletedOne?:ModelTypes["AppProjectCostEntity"],
	AppProjectContactEntityOnCreatedOne?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnUpdatedOne?:ModelTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnDeletedOne?:ModelTypes["AppProjectContactEntity"],
	AppBuildingWeekEntityOnCreatedOne?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnUpdatedOne?:ModelTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnDeletedOne?:ModelTypes["AppBuildingWeekEntity"],
	AppContentEntityOnCreatedOne?:ModelTypes["AppContentEntity"],
	AppContentEntityOnUpdatedOne?:ModelTypes["AppContentEntity"],
	AppContentEntityOnDeletedOne?:ModelTypes["AppContentEntity"],
	AppConversationMessagePresetEntityOnCreatedOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnUpdatedOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnDeletedOne?:ModelTypes["AppConversationMessagePresetEntity"],
	AppDocumentSignatureEntityOnCreatedOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnUpdatedOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnDeletedOne?:ModelTypes["AppDocumentSignatureEntity"],
	AppDocumentTypeEntityOnCreatedOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnUpdatedOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnDeletedOne?:ModelTypes["AppDocumentTypeEntity"],
	AppDocumentEntityOnCreatedOne?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityOnUpdatedOne?:ModelTypes["AppDocumentEntity"],
	AppDocumentEntityOnDeletedOne?:ModelTypes["AppDocumentEntity"],
	AppNotificationScheduleResourceEntityOnCreatedOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnUpdatedOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnDeletedOne?:ModelTypes["AppNotificationScheduleResourceEntity"],
	AppPlanCompetenceEntityOnCreatedOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnUpdatedOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnDeletedOne?:ModelTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceSettingEntityOnCreatedOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnUpdatedOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnDeletedOne?:ModelTypes["AppPlanCompetenceSettingEntity"],
	AppProjectCostPresetEntityOnCreatedOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnUpdatedOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnDeletedOne?:ModelTypes["AppProjectCostPresetEntity"],
	AppProjectFieldSheetEntityOnCreatedOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnUpdatedOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnDeletedOne?:ModelTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldGroupEntityOnCreatedOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnUpdatedOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnDeletedOne?:ModelTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupAssignmentEntityOnCreatedOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnUpdatedOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnDeletedOne?:ModelTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnCreatedOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnUpdatedOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnDeletedOne?:ModelTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldEntityOnCreatedOne?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnUpdatedOne?:ModelTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnDeletedOne?:ModelTypes["AppProjectFieldEntity"],
	AppServiceCategoryEntityOnCreatedOne?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnUpdatedOne?:ModelTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnDeletedOne?:ModelTypes["AppServiceCategoryEntity"],
	AppTextEntityOnCreatedOne?:ModelTypes["AppTextEntity"],
	AppTextEntityOnUpdatedOne?:ModelTypes["AppTextEntity"],
	AppTextEntityOnDeletedOne?:ModelTypes["AppTextEntity"],
	AppRelationEntityOnCreatedOne?:ModelTypes["AppRelationEntity"],
	AppRelationEntityOnUpdatedOne?:ModelTypes["AppRelationEntity"],
	AppRelationEntityOnDeletedOne?:ModelTypes["AppRelationEntity"]
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	GrantEntityFindById?: GraphQLTypes["GrantEntity"],
	GrantEntityFindOne?: GraphQLTypes["GrantEntity"],
	GrantEntityFindMany?: Array<GraphQLTypes["GrantEntity"] | undefined>,
	GrantEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	RoleGrantConfigEntityFindById?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityFindOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityFindMany?: Array<GraphQLTypes["RoleGrantConfigEntity"] | undefined>,
	RoleGrantConfigEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	RoleDocumentGrantEntityFindById?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityFindOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityFindMany?: Array<GraphQLTypes["RoleDocumentGrantEntity"] | undefined>,
	RoleDocumentGrantEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	RoleEntityFindById?: GraphQLTypes["RoleEntity"],
	RoleEntityFindOne?: GraphQLTypes["RoleEntity"],
	RoleEntityFindMany?: Array<GraphQLTypes["RoleEntity"] | undefined>,
	RoleEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserGrantConfigEntityFindById?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityFindOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityFindMany?: Array<GraphQLTypes["UserGrantConfigEntity"] | undefined>,
	UserGrantConfigEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserNotificationSubscriptionEntityFindById?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityFindOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityFindMany?: Array<GraphQLTypes["UserNotificationSubscriptionEntity"] | undefined>,
	UserNotificationSubscriptionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserNotificationEntityFindById?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityFindOne?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityFindMany?: Array<GraphQLTypes["UserNotificationEntity"] | undefined>,
	UserNotificationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserTemporaryAccessTokenEntityEntityFindById?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityFindOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityFindMany?: Array<GraphQLTypes["UserTemporaryAccessTokenEntityEntity"] | undefined>,
	UserTemporaryAccessTokenEntityEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserEntityFindById?: GraphQLTypes["UserEntity"],
	UserEntityFindOne?: GraphQLTypes["UserEntity"],
	UserEntityFindMany?: Array<GraphQLTypes["UserEntity"] | undefined>,
	UserEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistDefinitionEntityFindById?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityFindOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityFindMany?: Array<GraphQLTypes["PicklistDefinitionEntity"] | undefined>,
	PicklistDefinitionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistStateEntityFindById?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityFindOne?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityFindMany?: Array<GraphQLTypes["PicklistStateEntity"] | undefined>,
	PicklistStateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HousePartGroupEntityFindById?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityFindOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityFindMany?: Array<GraphQLTypes["HousePartGroupEntity"] | undefined>,
	HousePartGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HousePartEntityFindById?: GraphQLTypes["HousePartEntity"],
	HousePartEntityFindOne?: GraphQLTypes["HousePartEntity"],
	HousePartEntityFindMany?: Array<GraphQLTypes["HousePartEntity"] | undefined>,
	HousePartEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CargoPhaseEntityFindById?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityFindOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityFindMany?: Array<GraphQLTypes["CargoPhaseEntity"] | undefined>,
	CargoPhaseEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CargoTypeEntityFindById?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityFindOne?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityFindMany?: Array<GraphQLTypes["CargoTypeEntity"] | undefined>,
	CargoTypeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningHumanResourceEntityFindById?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityFindOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityFindMany?: Array<GraphQLTypes["PlanningHumanResourceEntity"] | undefined>,
	PlanningHumanResourceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectAssignmentEntityFindById?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityFindOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityFindMany?: Array<GraphQLTypes["PlanningProjectAssignmentEntity"] | undefined>,
	PlanningProjectAssignmentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectCompetencePlacementEntityFindById?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityFindOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityFindMany?: Array<GraphQLTypes["PlanningProjectCompetencePlacementEntity"] | undefined>,
	PlanningProjectCompetencePlacementEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectRequestPlacementEntityFindById?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityFindOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityFindMany?: Array<GraphQLTypes["PlanningProjectRequestPlacementEntity"] | undefined>,
	PlanningProjectRequestPlacementEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectEntityFindById?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityFindOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityFindMany?: Array<GraphQLTypes["PlanningProjectEntity"] | undefined>,
	PlanningProjectEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectRequestEntityFindById?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityFindOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityFindMany?: Array<GraphQLTypes["PlanningProjectRequestEntity"] | undefined>,
	PlanningProjectRequestEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectCompetenceEntityFindById?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityFindOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityFindMany?: Array<GraphQLTypes["PlanningProjectCompetenceEntity"] | undefined>,
	PlanningProjectCompetenceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningCompetenceEntityFindById?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityFindOne?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityFindMany?: Array<GraphQLTypes["PlanningCompetenceEntity"] | undefined>,
	PlanningCompetenceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CargoStateEntityFindById?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityFindOne?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityFindMany?: Array<GraphQLTypes["CargoStateEntity"] | undefined>,
	CargoStateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CargoEntityFindById?: GraphQLTypes["CargoEntity"],
	CargoEntityFindOne?: GraphQLTypes["CargoEntity"],
	CargoEntityFindMany?: Array<GraphQLTypes["CargoEntity"] | undefined>,
	CargoEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectHousePartEntityFindById?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityFindOne?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityFindMany?: Array<GraphQLTypes["ProjectHousePartEntity"] | undefined>,
	ProjectHousePartEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistElementEntityFindById?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityFindOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityFindMany?: Array<GraphQLTypes["PicklistElementEntity"] | undefined>,
	PicklistElementEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistEntityFindById?: GraphQLTypes["PicklistEntity"],
	PicklistEntityFindOne?: GraphQLTypes["PicklistEntity"],
	PicklistEntityFindMany?: Array<GraphQLTypes["PicklistEntity"] | undefined>,
	PicklistEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DrawOrderTypeEntityFindById?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityFindOne?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityFindMany?: Array<GraphQLTypes["DrawOrderTypeEntity"] | undefined>,
	DrawOrderTypeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HouseModelEntityFindById?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityFindOne?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityFindMany?: Array<GraphQLTypes["HouseModelEntity"] | undefined>,
	HouseModelEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SalesEmployeeEntityFindById?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityFindOne?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityFindMany?: Array<GraphQLTypes["SalesEmployeeEntity"] | undefined>,
	SalesEmployeeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DrawerAbsentEntityFindById?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityFindOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityFindMany?: Array<GraphQLTypes["DrawerAbsentEntity"] | undefined>,
	DrawerAbsentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DrawerEntityFindById?: GraphQLTypes["DrawerEntity"],
	DrawerEntityFindOne?: GraphQLTypes["DrawerEntity"],
	DrawerEntityFindMany?: Array<GraphQLTypes["DrawerEntity"] | undefined>,
	DrawerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DrawOrderEntityFindById?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityFindOne?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityFindMany?: Array<GraphQLTypes["DrawOrderEntity"] | undefined>,
	DrawOrderEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateGroupCommentEntityFindById?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityFindOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityFindMany?: Array<GraphQLTypes["DateGroupCommentEntity"] | undefined>,
	DateGroupCommentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateGroupResponsibleEntityFindById?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityFindOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityFindMany?: Array<GraphQLTypes["DateGroupResponsibleEntity"] | undefined>,
	DateGroupResponsibleEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	LogicFieldEntityFindById?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityFindOne?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityFindMany?: Array<GraphQLTypes["LogicFieldEntity"] | undefined>,
	LogicFieldEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateGroupEntityFindById?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityFindOne?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityFindMany?: Array<GraphQLTypes["DateGroupEntity"] | undefined>,
	DateGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateEntityFindById?: GraphQLTypes["DateEntity"],
	DateEntityFindOne?: GraphQLTypes["DateEntity"],
	DateEntityFindMany?: Array<GraphQLTypes["DateEntity"] | undefined>,
	DateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateValueEntityFindById?: GraphQLTypes["DateValueEntity"],
	DateValueEntityFindOne?: GraphQLTypes["DateValueEntity"],
	DateValueEntityFindMany?: Array<GraphQLTypes["DateValueEntity"] | undefined>,
	DateValueEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	LogicFieldValueEntityFindById?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityFindOne?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityFindMany?: Array<GraphQLTypes["LogicFieldValueEntity"] | undefined>,
	LogicFieldValueEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DateCacheEntityFindById?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityFindOne?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityFindMany?: Array<GraphQLTypes["DateCacheEntity"] | undefined>,
	DateCacheEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	YearPlanningModificationEntityFindById?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityFindOne?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityFindMany?: Array<GraphQLTypes["YearPlanningModificationEntity"] | undefined>,
	YearPlanningModificationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectCachedValueEntityFindById?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityFindOne?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityFindMany?: Array<GraphQLTypes["ProjectCachedValueEntity"] | undefined>,
	ProjectCachedValueEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	TableLayoutCommentEntityFindById?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityFindOne?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityFindMany?: Array<GraphQLTypes["TableLayoutCommentEntity"] | undefined>,
	TableLayoutCommentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectEntityFindById?: GraphQLTypes["ProjectEntity"],
	ProjectEntityFindOne?: GraphQLTypes["ProjectEntity"],
	ProjectEntityFindMany?: Array<GraphQLTypes["ProjectEntity"] | undefined>,
	ProjectEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SyncCronEntityFindById?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityFindOne?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityFindMany?: Array<GraphQLTypes["SyncCronEntity"] | undefined>,
	SyncCronEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	MailSchedulerEntityFindById?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityFindOne?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityFindMany?: Array<GraphQLTypes["MailSchedulerEntity"] | undefined>,
	MailSchedulerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectPartnerEntityFindById?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityFindOne?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityFindMany?: Array<GraphQLTypes["ProjectPartnerEntity"] | undefined>,
	ProjectPartnerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PartnerEntityFindById?: GraphQLTypes["PartnerEntity"],
	PartnerEntityFindOne?: GraphQLTypes["PartnerEntity"],
	PartnerEntityFindMany?: Array<GraphQLTypes["PartnerEntity"] | undefined>,
	PartnerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DocumentMetaEntityFindById?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityFindOne?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityFindMany?: Array<GraphQLTypes["DocumentMetaEntity"] | undefined>,
	DocumentMetaEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	EntityAdvancementEntityFindById?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityFindOne?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityFindMany?: Array<GraphQLTypes["EntityAdvancementEntity"] | undefined>,
	EntityAdvancementEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	WorkActionCategoryEntityFindById?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityFindOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityFindMany?: Array<GraphQLTypes["WorkActionCategoryEntity"] | undefined>,
	WorkActionCategoryEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	WorkActionEntityFindById?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityFindOne?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityFindMany?: Array<GraphQLTypes["WorkActionEntity"] | undefined>,
	WorkActionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	EntityImageEntityFindById?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityFindOne?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityFindMany?: Array<GraphQLTypes["EntityImageEntity"] | undefined>,
	EntityImageEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CustomerQuestionEntityFindById?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityFindOne?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityFindMany?: Array<GraphQLTypes["CustomerQuestionEntity"] | undefined>,
	CustomerQuestionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditCategoryEntityFindById?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityFindOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityFindMany?: Array<GraphQLTypes["AuditCategoryEntity"] | undefined>,
	AuditCategoryEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditQuestionEntityFindById?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityFindOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityFindMany?: Array<GraphQLTypes["AuditQuestionEntity"] | undefined>,
	AuditQuestionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditQuestionCostCategoryEntityFindById?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityFindOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityFindMany?: Array<GraphQLTypes["AuditQuestionCostCategoryEntity"] | undefined>,
	AuditQuestionCostCategoryEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	CostCategoryEntityFindById?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityFindOne?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityFindMany?: Array<GraphQLTypes["CostCategoryEntity"] | undefined>,
	CostCategoryEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditReportItemImageEntityFindById?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityFindOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityFindMany?: Array<GraphQLTypes["AuditReportItemImageEntity"] | undefined>,
	AuditReportItemImageEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditReportItemEntityFindById?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityFindOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityFindMany?: Array<GraphQLTypes["AuditReportItemEntity"] | undefined>,
	AuditReportItemEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditReportEntityFindById?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityFindOne?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityFindMany?: Array<GraphQLTypes["AuditReportEntity"] | undefined>,
	AuditReportEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AuditChecklistImageEntityFindById?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityFindOne?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityFindMany?: Array<GraphQLTypes["AuditChecklistImageEntity"] | undefined>,
	AuditChecklistImageEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InvoiceAttachmentEntityFindById?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityFindOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityFindMany?: Array<GraphQLTypes["InvoiceAttachmentEntity"] | undefined>,
	InvoiceAttachmentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	EmployeeDocumentEntityFindById?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityFindOne?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityFindMany?: Array<GraphQLTypes["EmployeeDocumentEntity"] | undefined>,
	EmployeeDocumentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	WorkActionTemplateGroupEntityFindById?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityFindOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityFindMany?: Array<GraphQLTypes["WorkActionTemplateGroupEntity"] | undefined>,
	WorkActionTemplateGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	WorkActionTemplateEntityFindById?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityFindOne?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityFindMany?: Array<GraphQLTypes["WorkActionTemplateEntity"] | undefined>,
	WorkActionTemplateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectCostInfoEntityFindById?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityFindOne?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityFindMany?: Array<GraphQLTypes["ProjectCostInfoEntity"] | undefined>,
	ProjectCostInfoEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	BlockedCalendarWeekEntityFindById?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityFindOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityFindMany?: Array<GraphQLTypes["BlockedCalendarWeekEntity"] | undefined>,
	BlockedCalendarWeekEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	MaintenanceReportEntityFindById?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityFindOne?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityFindMany?: Array<GraphQLTypes["MaintenanceReportEntity"] | undefined>,
	MaintenanceReportEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ConfigurationEntityFindById?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityFindOne?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityFindMany?: Array<GraphQLTypes["ConfigurationEntity"] | undefined>,
	ConfigurationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServiceMeterEntityFindById?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityFindOne?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityFindMany?: Array<GraphQLTypes["ServiceMeterEntity"] | undefined>,
	ServiceMeterEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DeliveryListMeterEntityFindById?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityFindOne?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityFindMany?: Array<GraphQLTypes["DeliveryListMeterEntity"] | undefined>,
	DeliveryListMeterEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServiceSpaceEntityFindById?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityFindOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityFindMany?: Array<GraphQLTypes["ServiceSpaceEntity"] | undefined>,
	ServiceSpaceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServiceTypeEntityFindById?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityFindOne?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityFindMany?: Array<GraphQLTypes["ServiceTypeEntity"] | undefined>,
	ServiceTypeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DeliveryListPointImageEntityFindById?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityFindOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityFindMany?: Array<GraphQLTypes["DeliveryListPointImageEntity"] | undefined>,
	DeliveryListPointImageEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServicePriorityEntityFindById?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityFindOne?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityFindMany?: Array<GraphQLTypes["ServicePriorityEntity"] | undefined>,
	ServicePriorityEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DeliveryListPointEntityFindById?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityFindOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityFindMany?: Array<GraphQLTypes["DeliveryListPointEntity"] | undefined>,
	DeliveryListPointEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DeliveryListSignatureEntityFindById?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityFindOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityFindMany?: Array<GraphQLTypes["DeliveryListSignatureEntity"] | undefined>,
	DeliveryListSignatureEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DeliveryListEntityFindById?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityFindOne?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityFindMany?: Array<GraphQLTypes["DeliveryListEntity"] | undefined>,
	DeliveryListEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SupplyInspectionReportAnswerEntityFindById?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityFindOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityFindMany?: Array<GraphQLTypes["SupplyInspectionReportAnswerEntity"] | undefined>,
	SupplyInspectionReportAnswerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SupplyInspectionReportEntityFindById?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityFindOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityFindMany?: Array<GraphQLTypes["SupplyInspectionReportEntity"] | undefined>,
	SupplyInspectionReportEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DepartmentEntityFindById?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityFindOne?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityFindMany?: Array<GraphQLTypes["DepartmentEntity"] | undefined>,
	DepartmentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	DrawOrderDescriptionEntityFindById?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityFindOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityFindMany?: Array<GraphQLTypes["DrawOrderDescriptionEntity"] | undefined>,
	DrawOrderDescriptionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	GreetingEntityFindById?: GraphQLTypes["GreetingEntity"],
	GreetingEntityFindOne?: GraphQLTypes["GreetingEntity"],
	GreetingEntityFindMany?: Array<GraphQLTypes["GreetingEntity"] | undefined>,
	GreetingEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HourDeclarationTypeEntityFindById?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityFindOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityFindMany?: Array<GraphQLTypes["HourDeclarationTypeEntity"] | undefined>,
	HourDeclarationTypeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HourDeclarationActivityEntityFindById?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityFindOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityFindMany?: Array<GraphQLTypes["HourDeclarationActivityEntity"] | undefined>,
	HourDeclarationActivityEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HourDeclarationEntityFindById?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityFindOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityFindMany?: Array<GraphQLTypes["HourDeclarationEntity"] | undefined>,
	HourDeclarationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	HourDeclarationApprovalRequestEntityFindById?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityFindOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityFindMany?: Array<GraphQLTypes["HourDeclarationApprovalRequestEntity"] | undefined>,
	HourDeclarationApprovalRequestEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportCommentEntityFindById?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityFindOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityFindMany?: Array<GraphQLTypes["InstallationReportCommentEntity"] | undefined>,
	InstallationReportCommentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportDescriptionEntityFindById?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityFindOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityFindMany?: Array<GraphQLTypes["InstallationReportDescriptionEntity"] | undefined>,
	InstallationReportDescriptionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportFinalEntityFindById?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityFindOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityFindMany?: Array<GraphQLTypes["InstallationReportFinalEntity"] | undefined>,
	InstallationReportFinalEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportMeasurementTemplateEntityFindById?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityFindOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityFindMany?: Array<GraphQLTypes["InstallationReportMeasurementTemplateEntity"] | undefined>,
	InstallationReportMeasurementTemplateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportMeasurementEntityFindById?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityFindOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityFindMany?: Array<GraphQLTypes["InstallationReportMeasurementEntity"] | undefined>,
	InstallationReportMeasurementEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportQuestionGroupEntityFindById?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityFindOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityFindMany?: Array<GraphQLTypes["InstallationReportQuestionGroupEntity"] | undefined>,
	InstallationReportQuestionGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportQuestionEntityFindById?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityFindOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityFindMany?: Array<GraphQLTypes["InstallationReportQuestionEntity"] | undefined>,
	InstallationReportQuestionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InstallationReportQuestionAnswerEntityFindById?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityFindOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityFindMany?: Array<GraphQLTypes["InstallationReportQuestionAnswerEntity"] | undefined>,
	InstallationReportQuestionAnswerEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	LegacyProjectEntityFindById?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityFindOne?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityFindMany?: Array<GraphQLTypes["LegacyProjectEntity"] | undefined>,
	LegacyProjectEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistCommentEntityFindById?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityFindOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityFindMany?: Array<GraphQLTypes["PicklistCommentEntity"] | undefined>,
	PicklistCommentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PicklistElementStateEntityFindById?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityFindOne?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityFindMany?: Array<GraphQLTypes["PicklistElementStateEntity"] | undefined>,
	PicklistElementStateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	PlanningProjectItemEntityFindById?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityFindOne?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityFindMany?: Array<GraphQLTypes["PlanningProjectItemEntity"] | undefined>,
	PlanningProjectItemEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectCargoStateEntityFindById?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityFindOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityFindMany?: Array<GraphQLTypes["ProjectCargoStateEntity"] | undefined>,
	ProjectCargoStateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ProjectPaymentEntityFindById?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityFindOne?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityFindMany?: Array<GraphQLTypes["ProjectPaymentEntity"] | undefined>,
	ProjectPaymentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	QualityAssuranceEntityFindById?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityFindOne?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityFindMany?: Array<GraphQLTypes["QualityAssuranceEntity"] | undefined>,
	QualityAssuranceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServiceMapColorEntityFindById?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityFindOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityFindMany?: Array<GraphQLTypes["ServiceMapColorEntity"] | undefined>,
	ServiceMapColorEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServicePlanningStageEntityFindById?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityFindOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityFindMany?: Array<GraphQLTypes["ServicePlanningStageEntity"] | undefined>,
	ServicePlanningStageEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServicePlanningEventEntityFindById?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityFindOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityFindMany?: Array<GraphQLTypes["ServicePlanningEventEntity"] | undefined>,
	ServicePlanningEventEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServicePlanningAssignmentEntityFindById?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityFindOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityFindMany?: Array<GraphQLTypes["ServicePlanningAssignmentEntity"] | undefined>,
	ServicePlanningAssignmentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	ServicePresetEntityFindById?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityFindOne?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityFindMany?: Array<GraphQLTypes["ServicePresetEntity"] | undefined>,
	ServicePresetEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SupplierDocumentEntityFindById?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityFindOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityFindMany?: Array<GraphQLTypes["SupplierDocumentEntity"] | undefined>,
	SupplierDocumentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SupplyInspectionQuestionEntityFindById?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityFindOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityFindMany?: Array<GraphQLTypes["SupplyInspectionQuestionEntity"] | undefined>,
	SupplyInspectionQuestionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	SupplyInspectionQuestionGroupEntityFindById?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityFindOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityFindMany?: Array<GraphQLTypes["SupplyInspectionQuestionGroupEntity"] | undefined>,
	SupplyInspectionQuestionGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	UserPasswordResetEntityFindById?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityFindOne?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityFindMany?: Array<GraphQLTypes["UserPasswordResetEntity"] | undefined>,
	UserPasswordResetEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	YearPlanningLineEntityFindById?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityFindOne?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityFindMany?: Array<GraphQLTypes["YearPlanningLineEntity"] | undefined>,
	YearPlanningLineEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	InvoicePaymentBatchEntityEntityFindById?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityFindOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityFindMany?: Array<GraphQLTypes["InvoicePaymentBatchEntityEntity"] | undefined>,
	InvoicePaymentBatchEntityEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPhaseContactEntityFindById?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityFindOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityFindMany?: Array<GraphQLTypes["AppPhaseContactEntity"] | undefined>,
	AppPhaseContactEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPhaseEntityFindById?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityFindOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityFindMany?: Array<GraphQLTypes["AppPhaseEntity"] | undefined>,
	AppPhaseEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPhaseSectionEntityFindById?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityFindOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityFindMany?: Array<GraphQLTypes["AppPhaseSectionEntity"] | undefined>,
	AppPhaseSectionEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppNotificationTemplateEntityFindById?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityFindOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityFindMany?: Array<GraphQLTypes["AppNotificationTemplateEntity"] | undefined>,
	AppNotificationTemplateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPhaseSectionMarkEntityFindById?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityFindOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityFindMany?: Array<GraphQLTypes["AppPhaseSectionMarkEntity"] | undefined>,
	AppPhaseSectionMarkEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectDateEntityFindById?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityFindOne?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityFindMany?: Array<GraphQLTypes["AppProjectDateEntity"] | undefined>,
	AppProjectDateEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppNotificationScheduleEntityFindById?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityFindOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityFindMany?: Array<GraphQLTypes["AppNotificationScheduleEntity"] | undefined>,
	AppNotificationScheduleEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPhaseSectionMarkValueEntityFindById?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityFindOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityFindMany?: Array<GraphQLTypes["AppPhaseSectionMarkValueEntity"] | undefined>,
	AppPhaseSectionMarkValueEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppInviteEntityFindById?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityFindOne?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityFindMany?: Array<GraphQLTypes["AppInviteEntity"] | undefined>,
	AppInviteEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppLoginEntityFindById?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityFindOne?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityFindMany?: Array<GraphQLTypes["AppLoginEntity"] | undefined>,
	AppLoginEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppNotificationEntityFindById?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityFindOne?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityFindMany?: Array<GraphQLTypes["AppNotificationEntity"] | undefined>,
	AppNotificationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectEntityFindById?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityFindOne?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityFindMany?: Array<GraphQLTypes["AppProjectEntity"] | undefined>,
	AppProjectEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppUserEntityFindById?: GraphQLTypes["AppUserEntity"],
	AppUserEntityFindOne?: GraphQLTypes["AppUserEntity"],
	AppUserEntityFindMany?: Array<GraphQLTypes["AppUserEntity"] | undefined>,
	AppUserEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectCostEntityFindById?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityFindOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityFindMany?: Array<GraphQLTypes["AppProjectCostEntity"] | undefined>,
	AppProjectCostEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectContactEntityFindById?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityFindOne?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityFindMany?: Array<GraphQLTypes["AppProjectContactEntity"] | undefined>,
	AppProjectContactEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppBuildingWeekEntityFindById?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityFindOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityFindMany?: Array<GraphQLTypes["AppBuildingWeekEntity"] | undefined>,
	AppBuildingWeekEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppContentEntityFindById?: GraphQLTypes["AppContentEntity"],
	AppContentEntityFindOne?: GraphQLTypes["AppContentEntity"],
	AppContentEntityFindMany?: Array<GraphQLTypes["AppContentEntity"] | undefined>,
	AppContentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppConversationMessagePresetEntityFindById?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityFindOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityFindMany?: Array<GraphQLTypes["AppConversationMessagePresetEntity"] | undefined>,
	AppConversationMessagePresetEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppDocumentSignatureEntityFindById?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityFindOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityFindMany?: Array<GraphQLTypes["AppDocumentSignatureEntity"] | undefined>,
	AppDocumentSignatureEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppDocumentTypeEntityFindById?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityFindOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityFindMany?: Array<GraphQLTypes["AppDocumentTypeEntity"] | undefined>,
	AppDocumentTypeEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppDocumentEntityFindById?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityFindOne?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityFindMany?: Array<GraphQLTypes["AppDocumentEntity"] | undefined>,
	AppDocumentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppNotificationScheduleResourceEntityFindById?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityFindOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityFindMany?: Array<GraphQLTypes["AppNotificationScheduleResourceEntity"] | undefined>,
	AppNotificationScheduleResourceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPlanCompetenceEntityFindById?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityFindOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityFindMany?: Array<GraphQLTypes["AppPlanCompetenceEntity"] | undefined>,
	AppPlanCompetenceEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppPlanCompetenceSettingEntityFindById?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityFindOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityFindMany?: Array<GraphQLTypes["AppPlanCompetenceSettingEntity"] | undefined>,
	AppPlanCompetenceSettingEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectCostPresetEntityFindById?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityFindOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityFindMany?: Array<GraphQLTypes["AppProjectCostPresetEntity"] | undefined>,
	AppProjectCostPresetEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectFieldSheetEntityFindById?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityFindOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityFindMany?: Array<GraphQLTypes["AppProjectFieldSheetEntity"] | undefined>,
	AppProjectFieldSheetEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectFieldGroupEntityFindById?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityFindOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityFindMany?: Array<GraphQLTypes["AppProjectFieldGroupEntity"] | undefined>,
	AppProjectFieldGroupEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectFieldGroupAssignmentEntityFindById?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityFindOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityFindMany?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentEntity"] | undefined>,
	AppProjectFieldGroupAssignmentEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectFieldGroupAssignmentValueEntityFindById?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityFindOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityFindMany?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined>,
	AppProjectFieldGroupAssignmentValueEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppProjectFieldEntityFindById?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityFindOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityFindMany?: Array<GraphQLTypes["AppProjectFieldEntity"] | undefined>,
	AppProjectFieldEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppServiceCategoryEntityFindById?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityFindOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityFindMany?: Array<GraphQLTypes["AppServiceCategoryEntity"] | undefined>,
	AppServiceCategoryEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppTextEntityFindById?: GraphQLTypes["AppTextEntity"],
	AppTextEntityFindOne?: GraphQLTypes["AppTextEntity"],
	AppTextEntityFindMany?: Array<GraphQLTypes["AppTextEntity"] | undefined>,
	AppTextEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	AppRelationEntityFindById?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityFindOne?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityFindMany?: Array<GraphQLTypes["AppRelationEntity"] | undefined>,
	AppRelationEntityAggregateGroupCount?: Array<GraphQLTypes["AggregateGroup"] | undefined>,
	bedrijvenMtcEntityFindMany?: Array<GraphQLTypes["bedrijvenMtcEntity"] | undefined>
};
	["GrantEntity"]: {
	__typename: "GrantEntity",
	id?: string,
	permissionId?: string,
	roleId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	role?: GraphQLTypes["RoleEntity"]
};
	["Date"]:any;
	["RoleEntity"]: {
	__typename: "RoleEntity",
	id?: string,
	name?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	grants?: Array<GraphQLTypes["GrantEntity"] | undefined>,
	grantConfigs?: Array<GraphQLTypes["RoleGrantConfigEntity"] | undefined>,
	documentGrants?: Array<GraphQLTypes["RoleDocumentGrantEntity"] | undefined>
};
	["RoleGrantConfigEntity"]: {
	__typename: "RoleGrantConfigEntity",
	id?: string,
	roleId?: string,
	configId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	role?: GraphQLTypes["RoleEntity"]
};
	["RoleDocumentGrantEntity"]: {
	__typename: "RoleDocumentGrantEntity",
	id?: string,
	roleId?: string,
	match?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	role?: GraphQLTypes["RoleEntity"]
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["EntityQuery"]: {
		filters: Array<GraphQLTypes["EntityQueryFilter"]>,
	orders?: Array<GraphQLTypes["EntityQueryOrder"]>,
	relations?: Array<string>
};
	["EntityQueryFilter"]: {
		field: string,
	operator: string,
	value?: string,
	valueComplex?: GraphQLTypes["JSON"],
	isOr?: boolean,
	isNot?: boolean
};
	["EntityQueryOrder"]: {
		field: string,
	direction: string
};
	["AggregateGroup"]: {
	__typename: "AggregateGroup",
	key: string,
	count: number
};
	["UserGrantConfigEntity"]: {
	__typename: "UserGrantConfigEntity",
	id?: string,
	userId?: string,
	configId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["UserEntity"]: {
	__typename: "UserEntity",
	id?: string,
	roleId?: string,
	companyId?: string,
	workAsUserId?: string,
	planningUserId?: string,
	abbreviation?: string,
	overwriteSignIn?: boolean,
	identity?: string,
	password?: string,
	passwordHash?: string,
	name?: string,
	stage?: string,
	email?: string,
	emailService?: string,
	emailDeclaration?: string,
	telephone?: string,
	isSupplier?: string,
	isSalesEmployee?: string,
	isServiceMechanic?: string,
	isBuyAdvisor?: string,
	isProjectMentor?: string,
	isProjectLeader?: string,
	addressStreet?: string,
	addressHouseNumber?: string,
	addressCity?: string,
	addressZipCode?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	lastSeenAt?: GraphQLTypes["Date"],
	workAsUser?: GraphQLTypes["UserEntity"],
	planningUser?: GraphQLTypes["UserEntity"],
	role?: GraphQLTypes["RoleEntity"],
	temporaryAccessTokens?: Array<GraphQLTypes["UserTemporaryAccessTokenEntityEntity"] | undefined>,
	grantConfigs?: Array<GraphQLTypes["UserGrantConfigEntity"] | undefined>,
	notificationSubscriptions?: Array<GraphQLTypes["UserNotificationSubscriptionEntity"] | undefined>,
	userNotifications?: Array<GraphQLTypes["UserNotificationEntity"] | undefined>
};
	["UserTemporaryAccessTokenEntityEntity"]: {
	__typename: "UserTemporaryAccessTokenEntityEntity",
	id?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["UserNotificationSubscriptionEntity"]: {
	__typename: "UserNotificationSubscriptionEntity",
	id?: string,
	userId?: string,
	endpoint?: string,
	keys_auth?: string,
	keys_p256dh?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["UserNotificationEntity"]: {
	__typename: "UserNotificationEntity",
	id?: string,
	userId?: string,
	subject?: string,
	content?: string,
	url?: string,
	channels?: GraphQLTypes["JSON"],
	readAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["PicklistDefinitionEntity"]: {
	__typename: "PicklistDefinitionEntity",
	id?: string,
	name?: string,
	icon?: string,
	metacomListTable?: string,
	metacomLinesTable?: string,
	renameFilterName?: string,
	competenceNumber?: string,
	elementTypes?: GraphQLTypes["JSON"],
	hideColumns?: GraphQLTypes["JSON"],
	indeterminateToggles?: boolean,
	dateSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["PicklistStateEntity"]: {
	__typename: "PicklistStateEntity",
	id?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	completedByUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	picklistDefinition?: GraphQLTypes["PicklistDefinitionEntity"],
	completedByUser?: GraphQLTypes["UserEntity"]
};
	["ProjectEntity"]: {
	__typename: "ProjectEntity",
	id?: string,
	description?: string,
	userId?: string,
	region?: string,
	regionId?: string,
	houseType?: string,
	houseShape?: string,
	condition?: string,
	buildingWeek?: string,
	buildingType?: string,
	buildingStreet?: string,
	buildingHouseNumber?: string,
	buildingCity?: string,
	buildingZipCode?: string,
	deliveryDate?: string,
	longitude?: string,
	latitude?: string,
	concrete?: string,
	customerEmail?: string,
	customerName?: string,
	foundation?: string,
	heating?: string,
	constructionProbeWork?: string,
	constructionAdvice?: string,
	constructionDrawingWork?: string,
	constructionSupplier?: string,
	constructionLevel?: string,
	constructionUnavailable?: string,
	score?: number,
	topicalDocumentRoot?: string,
	archiveDocumentRoot?: string,
	administrationType?: string,
	projectLeaderId?: string,
	salesEmployeeId?: string,
	buyAdvisorId?: string,
	projectMentorId?: string,
	installationReportDate?: string,
	installationPerformerName?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	projectLeader?: GraphQLTypes["UserEntity"],
	salesEmployee?: GraphQLTypes["UserEntity"],
	buyAdvisor?: GraphQLTypes["UserEntity"],
	projectMentor?: GraphQLTypes["UserEntity"],
	picklists?: Array<GraphQLTypes["PicklistEntity"] | undefined>,
	picklistStates?: Array<GraphQLTypes["PicklistStateEntity"] | undefined>,
	projectHouseParts?: Array<GraphQLTypes["ProjectHousePartEntity"] | undefined>,
	tableLayoutComments?: Array<GraphQLTypes["TableLayoutCommentEntity"] | undefined>,
	drawOrders?: Array<GraphQLTypes["DrawOrderEntity"] | undefined>,
	logicFieldValues?: Array<GraphQLTypes["LogicFieldValueEntity"] | undefined>,
	dateValues?: Array<GraphQLTypes["DateValueEntity"] | undefined>,
	dateGroupComments?: Array<GraphQLTypes["DateGroupCommentEntity"] | undefined>,
	dateGroupResponsibles?: Array<GraphQLTypes["DateGroupResponsibleEntity"] | undefined>,
	cachedValue?: GraphQLTypes["ProjectCachedValueEntity"],
	dateCache?: GraphQLTypes["DateCacheEntity"],
	modifications?: Array<GraphQLTypes["YearPlanningModificationEntity"] | undefined>
};
	["PicklistEntity"]: {
	__typename: "PicklistEntity",
	id?: string,
	projectId?: string,
	state?: string,
	note?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	syncSource?: string,
	syncVersion?: string,
	project?: GraphQLTypes["ProjectEntity"],
	elements?: Array<GraphQLTypes["PicklistElementEntity"] | undefined>
};
	["PicklistElementEntity"]: {
	__typename: "PicklistElementEntity",
	id?: string,
	picklistId?: string,
	housePartGroupId?: string,
	pickedByUserId?: string,
	elementId?: string,
	elementType?: string,
	elementGroup?: string,
	elementName?: string,
	description?: string,
	quantity?: number,
	unit?: string,
	dimensions?: string,
	orderId?: string,
	pickState?: string,
	isPicked?: boolean,
	pickedAt?: GraphQLTypes["Date"],
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	picklist?: GraphQLTypes["PicklistEntity"],
	housePartGroup?: GraphQLTypes["HousePartGroupEntity"],
	pickedByUser?: GraphQLTypes["UserEntity"]
};
	["HousePartGroupEntity"]: {
	__typename: "HousePartGroupEntity",
	id?: string,
	name?: string,
	isEnabled?: boolean,
	unit?: string,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ProjectHousePartEntity"]: {
	__typename: "ProjectHousePartEntity",
	id?: string,
	projectId?: string,
	cargoId?: string,
	supplierId?: string,
	partId?: string,
	partCodeId?: string,
	groupDivisionId?: string,
	quantity?: string,
	weight?: string,
	unit?: string,
	length?: string,
	width?: string,
	moldId?: string,
	wallId?: string,
	dateId?: string,
	costId?: string,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	cargo?: GraphQLTypes["CargoEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	housePart?: GraphQLTypes["HousePartEntity"]
};
	["CargoEntity"]: {
	__typename: "CargoEntity",
	id?: string,
	projectId?: string,
	competenceId?: string,
	phaseId?: string,
	cargoTypeId?: string,
	description?: string,
	timeAt?: string,
	requiresPermit?: boolean,
	routeId?: string,
	dateAt?: GraphQLTypes["Date"],
	isLocked?: boolean,
	notes?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	competence?: GraphQLTypes["PlanningCompetenceEntity"],
	phase?: GraphQLTypes["CargoPhaseEntity"],
	cargoType?: GraphQLTypes["CargoTypeEntity"],
	projectHouseParts?: Array<GraphQLTypes["ProjectHousePartEntity"] | undefined>,
	cargoStates?: Array<GraphQLTypes["CargoStateEntity"] | undefined>
};
	["PlanningCompetenceEntity"]: {
	__typename: "PlanningCompetenceEntity",
	id?: string,
	number?: string,
	color?: string,
	description?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	planningProjectCompetences?: Array<GraphQLTypes["PlanningProjectCompetenceEntity"] | undefined>
};
	["PlanningProjectCompetenceEntity"]: {
	__typename: "PlanningProjectCompetenceEntity",
	id?: string,
	planningCompetenceId?: string,
	planningProjectRequestId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	planningCompetence?: GraphQLTypes["PlanningCompetenceEntity"],
	planningProjectRequest?: GraphQLTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?: Array<GraphQLTypes["PlanningProjectCompetencePlacementEntity"] | undefined>
};
	["PlanningProjectRequestEntity"]: {
	__typename: "PlanningProjectRequestEntity",
	id?: string,
	planningProjectId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	planningProject?: GraphQLTypes["PlanningProjectEntity"],
	planningProjectRequestPlacements?: Array<GraphQLTypes["PlanningProjectRequestPlacementEntity"] | undefined>,
	planningProjectCompetences?: Array<GraphQLTypes["PlanningProjectCompetenceEntity"] | undefined>
};
	["PlanningProjectEntity"]: {
	__typename: "PlanningProjectEntity",
	id?: string,
	projectId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	planningProjectRequests?: Array<GraphQLTypes["PlanningProjectRequestEntity"] | undefined>
};
	["PlanningProjectRequestPlacementEntity"]: {
	__typename: "PlanningProjectRequestPlacementEntity",
	id?: string,
	planningProjectRequestId?: string,
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	status?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	planningProjectRequest?: GraphQLTypes["PlanningProjectRequestEntity"],
	planningProjectCompetencePlacements?: Array<GraphQLTypes["PlanningProjectCompetencePlacementEntity"] | undefined>
};
	["PlanningProjectCompetencePlacementEntity"]: {
	__typename: "PlanningProjectCompetencePlacementEntity",
	id?: string,
	planningProjectCompetenceId?: string,
	planningProjectRequestPlacementId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	planningProjectCompetence?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	planningProjectRequestPlacement?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	planningCompetenceAssignments?: Array<GraphQLTypes["PlanningProjectAssignmentEntity"] | undefined>
};
	["PlanningProjectAssignmentEntity"]: {
	__typename: "PlanningProjectAssignmentEntity",
	id?: string,
	humanResourceId?: string,
	planningCompetencePlacementId?: string,
	comments?: string,
	startsAt?: GraphQLTypes["Date"],
	endsAt?: GraphQLTypes["Date"],
	hours?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	humanResource?: GraphQLTypes["PlanningHumanResourceEntity"],
	planningCompetencePlacement?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"]
};
	["PlanningHumanResourceEntity"]: {
	__typename: "PlanningHumanResourceEntity",
	id?: string,
	number?: string,
	resourceTypeId?: string,
	color?: string,
	firstName?: string,
	lastNamePrefix?: string,
	lastName?: string,
	email?: string,
	isNotProjectLeader?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["CargoPhaseEntity"]: {
	__typename: "CargoPhaseEntity",
	id?: string,
	projectId?: string,
	description?: string,
	date?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"]
};
	["CargoTypeEntity"]: {
	__typename: "CargoTypeEntity",
	id?: string,
	description?: string,
	userId?: string,
	price?: string,
	unit?: string,
	colorCode?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["CargoStateEntity"]: {
	__typename: "CargoStateEntity",
	id?: string,
	cargoId?: string,
	housePartGroupId?: string,
	divisionId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	cargo?: GraphQLTypes["CargoEntity"],
	housePartGroup?: GraphQLTypes["HousePartGroupEntity"]
};
	["HousePartEntity"]: {
	__typename: "HousePartEntity",
	id?: string,
	partId?: string,
	housePartGroupId?: string,
	supplierId?: string,
	description?: string,
	rootElement?: string,
	releaseTimeMins?: number,
	length?: string,
	width?: string,
	capacityFactor?: number,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	housePartGroup?: GraphQLTypes["HousePartGroupEntity"]
};
	["TableLayoutCommentEntity"]: {
	__typename: "TableLayoutCommentEntity",
	id?: string,
	taskId?: string,
	projectId?: string,
	userId?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["DrawOrderEntity"]: {
	__typename: "DrawOrderEntity",
	id?: string,
	projectId?: string,
	kind?: string,
	drawOrderTypeId?: string,
	houseModelId?: string,
	salesEmployeeId?: string,
	drawerId?: string,
	description?: string,
	memo?: string,
	memoLong?: string,
	completedAt?: GraphQLTypes["Date"],
	plannedAt?: GraphQLTypes["Date"],
	plannedAtOrder?: number,
	totalHours?: number,
	totalHoursSpend?: number,
	percentageCompleted?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	drawOrderType?: GraphQLTypes["DrawOrderTypeEntity"],
	houseModel?: GraphQLTypes["HouseModelEntity"],
	salesEmployee?: GraphQLTypes["SalesEmployeeEntity"],
	drawer?: GraphQLTypes["DrawerEntity"]
};
	["DrawOrderTypeEntity"]: {
	__typename: "DrawOrderTypeEntity",
	id?: string,
	name?: string,
	condition?: string,
	defaultHours?: number,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["HouseModelEntity"]: {
	__typename: "HouseModelEntity",
	id?: string,
	name?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["SalesEmployeeEntity"]: {
	__typename: "SalesEmployeeEntity",
	id?: string,
	name?: string,
	firstName?: string,
	condition?: string,
	isActive?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["DrawerEntity"]: {
	__typename: "DrawerEntity",
	id?: string,
	userId?: string,
	alias?: string,
	condition?: string,
	isHSB?: string,
	isBK?: string,
	isIT?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	drawerAbsents?: Array<GraphQLTypes["DrawerAbsentEntity"] | undefined>
};
	["DrawerAbsentEntity"]: {
	__typename: "DrawerAbsentEntity",
	id?: string,
	drawerId?: string,
	reason?: string,
	color?: string,
	date?: GraphQLTypes["Date"],
	hours?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	drawer?: GraphQLTypes["DrawerEntity"]
};
	["LogicFieldValueEntity"]: {
	__typename: "LogicFieldValueEntity",
	id?: string,
	fieldId?: string,
	projectId?: string,
	value?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	isSyncUpdate?: boolean,
	project?: GraphQLTypes["ProjectEntity"]
};
	["DateValueEntity"]: {
	__typename: "DateValueEntity",
	id?: string,
	dateId?: string,
	projectId?: string,
	userId?: string,
	value?: GraphQLTypes["JSON"],
	isActivated?: boolean,
	isCompleted?: boolean,
	isSynced?: boolean,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	isSyncUpdate?: boolean,
	send_notification?: boolean,
	date?: GraphQLTypes["DateEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["DateEntity"]: {
	__typename: "DateEntity",
	id?: string,
	dateGroupId?: string,
	description?: string,
	stackId?: number,
	iconId?: string,
	dateFormat?: string,
	colorCode?: string,
	orderId?: number,
	visibleOnDatePassed?: boolean,
	isOptional?: boolean,
	visibleOnConstructionPlanning?: boolean,
	phaseSectionMarkId?: number,
	notificationTemplateId?: number,
	drawOrderTypeId?: string,
	isSynced?: boolean,
	hash?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	dateGroup?: GraphQLTypes["DateGroupEntity"],
	dateValues?: Array<GraphQLTypes["DateValueEntity"] | undefined>
};
	["DateGroupEntity"]: {
	__typename: "DateGroupEntity",
	id?: string,
	description?: string,
	label?: string,
	hasResponsible?: boolean,
	hasComments?: boolean,
	visibleOnConstructionPlanning?: boolean,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	dates?: Array<GraphQLTypes["DateEntity"] | undefined>,
	logicFields?: Array<GraphQLTypes["LogicFieldEntity"] | undefined>,
	comments?: Array<GraphQLTypes["DateGroupCommentEntity"] | undefined>,
	responsibles?: Array<GraphQLTypes["DateGroupResponsibleEntity"] | undefined>
};
	["LogicFieldEntity"]: {
	__typename: "LogicFieldEntity",
	id?: string,
	fieldId?: string,
	fieldName?: string,
	dateGroupId?: string,
	conditionDescription?: string,
	expression?: string,
	iconId?: string,
	colorCode?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	dateGroup?: GraphQLTypes["DateGroupEntity"]
};
	["DateGroupCommentEntity"]: {
	__typename: "DateGroupCommentEntity",
	id?: string,
	dateGroupId?: string,
	projectId?: string,
	userId?: string,
	value?: string,
	referenceId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	dateGroup?: GraphQLTypes["DateGroupEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["DateGroupResponsibleEntity"]: {
	__typename: "DateGroupResponsibleEntity",
	id?: string,
	userId?: string,
	projectId?: string,
	dateGroupId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	dateGroup?: GraphQLTypes["DateGroupEntity"]
};
	["ProjectCachedValueEntity"]: {
	__typename: "ProjectCachedValueEntity",
	id?: string,
	projectId?: string,
	dateValues?: GraphQLTypes["JSON"],
	projectLeader?: GraphQLTypes["JSON"],
	salesEmployee?: GraphQLTypes["JSON"],
	dateGroupResponsibles?: GraphQLTypes["JSON"],
	buyAdvisor?: GraphQLTypes["JSON"],
	projectMentor?: GraphQLTypes["JSON"],
	dateGroupComments?: GraphQLTypes["JSON"],
	logicFieldValues?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"]
};
	["DateCacheEntity"]: {
	__typename: "DateCacheEntity",
	id?: string,
	projectId?: string,
	values?: GraphQLTypes["JSON"],
	comments?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"]
};
	["YearPlanningModificationEntity"]: {
	__typename: "YearPlanningModificationEntity",
	id?: string,
	userId?: string,
	projectId?: string,
	oldWeek?: string,
	newWeek?: string,
	notes?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	project?: GraphQLTypes["ProjectEntity"]
};
	["SyncCronEntity"]: {
	__typename: "SyncCronEntity",
	id?: string,
	cron?: string
};
	["MailSchedulerEntity"]: {
	__typename: "MailSchedulerEntity",
	id?: string,
	description?: string,
	cron?: string,
	sourceType?: string,
	sourceTable?: string,
	condition?: string,
	mail?: string,
	mailCC?: string,
	subject?: string,
	template?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ProjectPartnerEntity"]: {
	__typename: "ProjectPartnerEntity",
	id?: string,
	projectId?: string,
	partnerId?: string,
	mode?: string,
	isContactAllowed?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["PartnerEntity"]: {
	__typename: "PartnerEntity",
	id?: string,
	name?: string,
	contentId?: number,
	contactUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	contactUser?: GraphQLTypes["UserEntity"]
};
	["DocumentMetaEntity"]: {
	__typename: "DocumentMetaEntity",
	id?: string,
	description?: string,
	mimeType?: string,
	projectId?: string,
	trackingId?: string,
	userId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["EntityAdvancementEntity"]: {
	__typename: "EntityAdvancementEntity",
	id?: string,
	entityType?: string,
	entityId?: string,
	userId?: string,
	comment?: string,
	date?: GraphQLTypes["Date"],
	isCompleted?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	workAction?: GraphQLTypes["WorkActionEntity"]
};
	["WorkActionEntity"]: {
	__typename: "WorkActionEntity",
	title?: string,
	description?: string,
	allowEmptyAdvancement?: boolean,
	disableImageUpload?: boolean,
	responsibleDateGroupId?: string,
	advancementApplyDateId?: string,
	id?: string,
	entityType?: string,
	userId?: string,
	projectId?: string,
	workActionCategoryId?: string,
	assignedUserId?: string,
	date?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	workActionCategory?: GraphQLTypes["WorkActionCategoryEntity"],
	assignedUser?: GraphQLTypes["UserEntity"],
	entityAdvancements?: Array<GraphQLTypes["EntityAdvancementEntity"] | undefined>,
	entityImages?: Array<GraphQLTypes["EntityImageEntity"] | undefined>
};
	["WorkActionCategoryEntity"]: {
	__typename: "WorkActionCategoryEntity",
	id?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	workActions?: Array<GraphQLTypes["WorkActionEntity"] | undefined>
};
	["EntityImageEntity"]: {
	__typename: "EntityImageEntity",
	id?: string,
	entityType?: string,
	entityId?: string,
	userId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"],
	workAction?: GraphQLTypes["WorkActionEntity"]
};
	["CustomerQuestionEntity"]: {
	__typename: "CustomerQuestionEntity",
	id?: string,
	message?: string,
	kind?: string,
	projectId?: string,
	customerName?: string,
	city?: string,
	telephone?: string,
	space?: string,
	emailAddress?: string,
	internalComment?: string,
	imageIds?: GraphQLTypes["JSON"],
	completedAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["AuditCategoryEntity"]: {
	__typename: "AuditCategoryEntity",
	id?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	auditQuestions?: Array<GraphQLTypes["AuditQuestionEntity"] | undefined>
};
	["AuditQuestionEntity"]: {
	__typename: "AuditQuestionEntity",
	id?: string,
	auditCategoryId?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	auditCategory?: GraphQLTypes["AuditCategoryEntity"],
	auditQuestionCostCategories?: Array<GraphQLTypes["AuditQuestionCostCategoryEntity"] | undefined>
};
	["AuditQuestionCostCategoryEntity"]: {
	__typename: "AuditQuestionCostCategoryEntity",
	id?: string,
	auditQuestionId?: string,
	costCategoryId?: string,
	auditQuestion?: GraphQLTypes["AuditQuestionEntity"],
	costCategory?: GraphQLTypes["CostCategoryEntity"]
};
	["CostCategoryEntity"]: {
	__typename: "CostCategoryEntity",
	id?: string,
	description?: string,
	group?: string,
	condition?: string,
	isChecklist?: string,
	hasImages?: string,
	checklistOrder?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	auditQuestionCostCategories?: Array<GraphQLTypes["AuditQuestionCostCategoryEntity"] | undefined>
};
	["AuditReportItemImageEntity"]: {
	__typename: "AuditReportItemImageEntity",
	id?: string,
	auditReportItemId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	auditReportItem?: GraphQLTypes["AuditReportItemEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["AuditReportItemEntity"]: {
	__typename: "AuditReportItemEntity",
	id?: string,
	userId?: string,
	auditReportId?: string,
	auditQuestionId?: string,
	state?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	auditReport?: GraphQLTypes["AuditReportEntity"],
	auditQuestion?: GraphQLTypes["AuditQuestionEntity"],
	auditReportItemImages?: Array<GraphQLTypes["AuditReportItemImageEntity"] | undefined>
};
	["AuditReportEntity"]: {
	__typename: "AuditReportEntity",
	id?: string,
	userId?: string,
	projectId?: string,
	costCategoryId?: string,
	signatureDocumentMetaId?: string,
	comment?: string,
	inspectedAt?: GraphQLTypes["Date"],
	inspectedAtTime?: string,
	lockedAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	costCategory?: GraphQLTypes["CostCategoryEntity"],
	signatureDocumentMeta?: GraphQLTypes["DocumentMetaEntity"],
	auditReportItems?: Array<GraphQLTypes["AuditReportItemEntity"] | undefined>
};
	["AuditChecklistImageEntity"]: {
	__typename: "AuditChecklistImageEntity",
	id?: string,
	userId?: string,
	costCategoryId?: string,
	projectId?: string,
	documentMetaId?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	costCategory?: GraphQLTypes["CostCategoryEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["InvoiceAttachmentEntity"]: {
	__typename: "InvoiceAttachmentEntity",
	id?: string,
	metacomOrderId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["EmployeeDocumentEntity"]: {
	__typename: "EmployeeDocumentEntity",
	id?: string,
	userId?: string,
	relationId?: string,
	employeeId?: string,
	documentMetaId?: string,
	isInternal?: boolean,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["WorkActionTemplateGroupEntity"]: {
	__typename: "WorkActionTemplateGroupEntity",
	id?: string,
	name?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	workActionTemplates?: Array<GraphQLTypes["WorkActionTemplateEntity"] | undefined>
};
	["WorkActionTemplateEntity"]: {
	__typename: "WorkActionTemplateEntity",
	title?: string,
	description?: string,
	allowEmptyAdvancement?: boolean,
	disableImageUpload?: boolean,
	responsibleDateGroupId?: string,
	advancementApplyDateId?: string,
	id?: string,
	workActionTemplateGroupId?: string,
	workActionCategoryId?: string,
	targetDateOffset?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	workActionTemplateGroup?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	workActionCategory?: GraphQLTypes["WorkActionCategoryEntity"]
};
	["ProjectCostInfoEntity"]: {
	__typename: "ProjectCostInfoEntity",
	id?: number,
	appProjectCostId?: number,
	conceptUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	conceptUser?: GraphQLTypes["UserEntity"]
};
	["BlockedCalendarWeekEntity"]: {
	__typename: "BlockedCalendarWeekEntity",
	id?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["MaintenanceReportEntity"]: {
	__typename: "MaintenanceReportEntity",
	id?: string,
	projectId?: string,
	data?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ConfigurationEntity"]: {
	__typename: "ConfigurationEntity",
	id?: string,
	data?: GraphQLTypes["JSON"]
};
	["ServiceMeterEntity"]: {
	__typename: "ServiceMeterEntity",
	id?: string,
	description?: string,
	unit?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["DeliveryListMeterEntity"]: {
	__typename: "DeliveryListMeterEntity",
	id?: string,
	projectId?: string,
	meterId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	meter?: GraphQLTypes["ServiceMeterEntity"]
};
	["ServiceSpaceEntity"]: {
	__typename: "ServiceSpaceEntity",
	id?: string,
	code?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ServiceTypeEntity"]: {
	__typename: "ServiceTypeEntity",
	id?: string,
	code?: string,
	description?: string,
	storeInSystem?: boolean,
	isStandard?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["DeliveryListPointImageEntity"]: {
	__typename: "DeliveryListPointImageEntity",
	id?: string,
	pointId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	point?: GraphQLTypes["DeliveryListPointEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["DeliveryListPointEntity"]: {
	__typename: "DeliveryListPointEntity",
	id?: string,
	code?: string,
	projectId?: string,
	primaryCategoryId?: number,
	secondaryCategoryId?: number,
	serviceTypeId?: string,
	serviceSpaceId?: string,
	serviceSpaceIds?: GraphQLTypes["JSON"],
	servicePriorityId?: string,
	assignedUserId?: string,
	serviceTicketId?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	serviceType?: GraphQLTypes["ServiceTypeEntity"],
	serviceSpace?: GraphQLTypes["ServiceSpaceEntity"],
	servicePriority?: GraphQLTypes["ServicePriorityEntity"],
	assignedUser?: GraphQLTypes["UserEntity"],
	images?: Array<GraphQLTypes["DeliveryListPointImageEntity"] | undefined>
};
	["ServicePriorityEntity"]: {
	__typename: "ServicePriorityEntity",
	id?: string,
	description?: string,
	offsetDays?: number,
	isStandard?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["DeliveryListSignatureEntity"]: {
	__typename: "DeliveryListSignatureEntity",
	id?: string,
	projectId?: string,
	kind?: string,
	documentMetaId?: string,
	signDate?: GraphQLTypes["Date"],
	signLocation?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["DeliveryListEntity"]: {
	__typename: "DeliveryListEntity",
	id?: string,
	projectId?: string,
	assignedUserId?: string,
	hiredAgent?: string,
	keyAmount?: number,
	isDeliveredAt?: GraphQLTypes["Date"],
	houseDocumentMetaId?: string,
	isFinal?: boolean,
	isFinalAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	assignedUser?: GraphQLTypes["UserEntity"],
	houseDocumentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["SupplyInspectionReportAnswerEntity"]: {
	__typename: "SupplyInspectionReportAnswerEntity",
	id?: string,
	supplyInspectionQuestionId?: string,
	supplyInspectionReportId?: string,
	status?: string,
	explanation?: string,
	imageDocumentMetaIds?: GraphQLTypes["JSON"],
	supplyInspectionReport?: GraphQLTypes["SupplyInspectionReportEntity"]
};
	["SupplyInspectionReportEntity"]: {
	__typename: "SupplyInspectionReportEntity",
	id?: string,
	timestamp?: GraphQLTypes["Date"],
	departmentId?: string,
	executedByUserId?: string,
	signatureDocumentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	department?: GraphQLTypes["DepartmentEntity"],
	executedByUser?: GraphQLTypes["UserEntity"],
	supplyInspectionReportAnswers?: Array<GraphQLTypes["SupplyInspectionReportAnswerEntity"] | undefined>
};
	["DepartmentEntity"]: {
	__typename: "DepartmentEntity",
	id?: string,
	departmentId?: string,
	description?: string,
	state?: string,
	isOrderDepartment?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	supplyInspectionReports?: Array<GraphQLTypes["SupplyInspectionReportEntity"] | undefined>
};
	["DrawOrderDescriptionEntity"]: {
	__typename: "DrawOrderDescriptionEntity",
	id?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["GreetingEntity"]: {
	__typename: "GreetingEntity",
	id?: string,
	html?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["HourDeclarationTypeEntity"]: {
	__typename: "HourDeclarationTypeEntity",
	id?: string,
	companyId?: string,
	administrationType?: string,
	kind?: string,
	kindDescription?: string,
	description?: string,
	hasActivities?: string,
	isExternal?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["HourDeclarationActivityEntity"]: {
	__typename: "HourDeclarationActivityEntity",
	id?: string,
	companyId?: string,
	administrationType?: string,
	description?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["HourDeclarationEntity"]: {
	__typename: "HourDeclarationEntity",
	id?: string,
	userId?: string,
	hourDeclarationTypeId?: string,
	projectId?: string,
	hourDeclarationActivityId?: string,
	priceAgreementId?: string,
	storedDocumentId?: string,
	date?: GraphQLTypes["Date"],
	description?: string,
	amount?: string,
	baseHours?: string,
	savingHours?: string,
	overTimeHours?: string,
	travelHours?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	hourDeclarationType?: GraphQLTypes["HourDeclarationTypeEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	hourDeclarationActivity?: GraphQLTypes["HourDeclarationActivityEntity"]
};
	["HourDeclarationApprovalRequestEntity"]: {
	__typename: "HourDeclarationApprovalRequestEntity",
	id?: string,
	userId?: string,
	period?: string,
	createdAt?: GraphQLTypes["Date"]
};
	["InstallationReportCommentEntity"]: {
	__typename: "InstallationReportCommentEntity",
	id?: string,
	projectId?: string,
	stepId?: string,
	userId?: string,
	message?: string,
	date?: string,
	performerName?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InstallationReportDescriptionEntity"]: {
	__typename: "InstallationReportDescriptionEntity",
	id?: string,
	stepId?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InstallationReportFinalEntity"]: {
	__typename: "InstallationReportFinalEntity",
	id?: string,
	projectId?: string,
	userId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["InstallationReportMeasurementTemplateEntity"]: {
	__typename: "InstallationReportMeasurementTemplateEntity",
	id?: string,
	measureTableId?: string,
	measureId?: string,
	defaultFields?: GraphQLTypes["JSON"],
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InstallationReportMeasurementEntity"]: {
	__typename: "InstallationReportMeasurementEntity",
	id?: string,
	projectId?: string,
	measureTableId?: string,
	measureId?: string,
	fields?: GraphQLTypes["JSON"],
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InstallationReportQuestionGroupEntity"]: {
	__typename: "InstallationReportQuestionGroupEntity",
	id?: string,
	name?: string,
	stepId?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	questions?: Array<GraphQLTypes["InstallationReportQuestionEntity"] | undefined>
};
	["InstallationReportQuestionEntity"]: {
	__typename: "InstallationReportQuestionEntity",
	id?: string,
	questionGroupId?: string,
	content?: string,
	tooltip?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	questionGroup?: GraphQLTypes["InstallationReportQuestionGroupEntity"]
};
	["InstallationReportQuestionAnswerEntity"]: {
	__typename: "InstallationReportQuestionAnswerEntity",
	id?: string,
	projectId?: string,
	questionId?: string,
	content?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	question?: GraphQLTypes["InstallationReportQuestionEntity"]
};
	["LegacyProjectEntity"]: {
	__typename: "LegacyProjectEntity",
	id?: string,
	description?: string,
	houseType?: string,
	regionId?: string,
	buildingStreet?: string,
	buildingCity?: string,
	buildingZipCode?: string,
	deliveryDate?: string,
	customerEmail?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["PicklistCommentEntity"]: {
	__typename: "PicklistCommentEntity",
	id?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	userId?: string,
	text?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	picklistDefinition?: GraphQLTypes["PicklistDefinitionEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["PicklistElementStateEntity"]: {
	__typename: "PicklistElementStateEntity",
	id?: string,
	elementId?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	state?: string,
	pickedByUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	picklistDefinition?: GraphQLTypes["PicklistDefinitionEntity"],
	pickedByUser?: GraphQLTypes["UserEntity"]
};
	["PlanningProjectItemEntity"]: {
	__typename: "PlanningProjectItemEntity",
	id?: string,
	parentId?: string,
	dimension?: string,
	projectId?: string,
	competenceId?: string,
	competenceIndex?: number,
	competenceNumber?: string,
	competenceStartsAt?: GraphQLTypes["Date"],
	competenceEndsAt?: GraphQLTypes["Date"],
	hours?: string,
	humanResourceId?: string,
	humanResourceNumber?: string,
	humanResourceStartsAt?: GraphQLTypes["Date"],
	humanResourceEndsAt?: GraphQLTypes["Date"],
	comments?: string,
	status?: string,
	version?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"],
	competence?: GraphQLTypes["PlanningCompetenceEntity"],
	humanResource?: GraphQLTypes["PlanningHumanResourceEntity"]
};
	["ProjectCargoStateEntity"]: {
	__typename: "ProjectCargoStateEntity",
	id?: string,
	projectId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	project?: GraphQLTypes["ProjectEntity"]
};
	["ProjectPaymentEntity"]: {
	__typename: "ProjectPaymentEntity",
	id?: string,
	projectId?: string,
	userId?: string,
	description?: string,
	payedAt?: GraphQLTypes["Date"],
	amount?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["QualityAssuranceEntity"]: {
	__typename: "QualityAssuranceEntity",
	id?: string,
	projectId?: string,
	data?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ServiceMapColorEntity"]: {
	__typename: "ServiceMapColorEntity",
	id?: string,
	days?: number,
	color?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ServicePlanningStageEntity"]: {
	__typename: "ServicePlanningStageEntity",
	id?: string,
	name?: string,
	colorCode?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["ServicePlanningEventEntity"]: {
	__typename: "ServicePlanningEventEntity",
	id?: string,
	legacyProjectId?: string,
	stageId?: string,
	userId?: string,
	serviceTicketIds?: GraphQLTypes["JSON"],
	memo?: string,
	duration?: number,
	durationUnit?: string,
	durationPlanned?: number,
	durationSpend?: number,
	isFinalized?: boolean,
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	assignments?: Array<GraphQLTypes["ServicePlanningAssignmentEntity"] | undefined>,
	legacyProject?: GraphQLTypes["LegacyProjectEntity"],
	stage?: GraphQLTypes["ServicePlanningStageEntity"],
	user?: GraphQLTypes["UserEntity"]
};
	["ServicePlanningAssignmentEntity"]: {
	__typename: "ServicePlanningAssignmentEntity",
	id?: string,
	eventId?: string,
	resourceId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	event?: GraphQLTypes["ServicePlanningEventEntity"],
	resource?: GraphQLTypes["UserEntity"]
};
	["ServicePresetEntity"]: {
	__typename: "ServicePresetEntity",
	id?: string,
	primaryCategoryId?: number,
	secondaryCategoryId?: number,
	code?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["SupplierDocumentEntity"]: {
	__typename: "SupplierDocumentEntity",
	id?: string,
	userId?: string,
	informedUserId?: string,
	informedContactTypes?: GraphQLTypes["JSON"],
	projectId?: string,
	documentMetaId?: string,
	contextId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"],
	informedUser?: GraphQLTypes["UserEntity"],
	project?: GraphQLTypes["ProjectEntity"],
	documentMeta?: GraphQLTypes["DocumentMetaEntity"]
};
	["SupplyInspectionQuestionEntity"]: {
	__typename: "SupplyInspectionQuestionEntity",
	id?: string,
	supplyInspectionQuestionGroupId?: string,
	departmentIds?: GraphQLTypes["JSON"],
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	supplyInspectionQuestionGroup?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"]
};
	["SupplyInspectionQuestionGroupEntity"]: {
	__typename: "SupplyInspectionQuestionGroupEntity",
	id?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	supplyInspectionQuestions?: Array<GraphQLTypes["SupplyInspectionQuestionEntity"] | undefined>
};
	["UserPasswordResetEntity"]: {
	__typename: "UserPasswordResetEntity",
	id?: string,
	userId?: string,
	expiresAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	user?: GraphQLTypes["UserEntity"]
};
	["YearPlanningLineEntity"]: {
	__typename: "YearPlanningLineEntity",
	id?: string,
	description?: string,
	weeks?: number,
	colorCode?: string,
	visibleOnConstructionPlanning?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InvoicePaymentBatchEntityEntity"]: {
	__typename: "InvoicePaymentBatchEntityEntity",
	id?: string,
	batch?: number,
	document?: string,
	name?: string,
	date?: GraphQLTypes["Date"],
	amount?: number,
	amountBank?: number,
	companyId?: string,
	companyName?: string,
	relationId?: string,
	relationName?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["AppPhaseContactEntity"]: {
	__typename: "AppPhaseContactEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_id?: number,
	type?: string,
	title?: string,
	receive_notifications?: boolean,
	phase?: GraphQLTypes["AppPhaseEntity"]
};
	["AppPhaseEntity"]: {
	__typename: "AppPhaseEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	name?: string,
	order?: number,
	percentage?: number,
	is_visible_transferred?: boolean,
	start_project_date_type?: string,
	end_project_date_type?: string,
	days?: number,
	sections?: Array<GraphQLTypes["AppPhaseSectionEntity"] | undefined>,
	contacts?: Array<GraphQLTypes["AppPhaseContactEntity"] | undefined>
};
	["AppPhaseSectionEntity"]: {
	__typename: "AppPhaseSectionEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_id?: number,
	more_info_content_id?: number,
	name?: string,
	order?: number,
	percentage?: number,
	phase?: GraphQLTypes["AppPhaseEntity"],
	marks?: Array<GraphQLTypes["AppPhaseSectionMarkEntity"] | undefined>
};
	["AppPhaseSectionMarkEntity"]: {
	__typename: "AppPhaseSectionMarkEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_section_id?: number,
	document_type_id?: number,
	more_info_content_id?: number,
	notification_template_id?: number,
	project_field_group_id?: number,
	project_date_type?: string,
	responsible?: string,
	name?: string,
	order?: number,
	percentage?: number,
	is_milestone?: boolean,
	is_standard?: boolean,
	date_prompt?: boolean,
	duration_days?: number,
	timeline_origin_date_type?: string,
	timeline_competence_number?: string,
	timeline_origin_diff_days?: number,
	section?: GraphQLTypes["AppPhaseSectionEntity"],
	notificationTemplate?: GraphQLTypes["AppNotificationTemplateEntity"]
};
	["AppNotificationTemplateEntity"]: {
	__typename: "AppNotificationTemplateEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	content_id?: number,
	subject?: string,
	body?: string
};
	["AppProjectDateEntity"]: {
	__typename: "AppProjectDateEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	value?: string,
	type?: string
};
	["AppNotificationScheduleEntity"]: {
	__typename: "AppNotificationScheduleEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_section_mark_id?: number,
	phase_section_mark_cancel_id?: number,
	notification_template_id?: number,
	days_offset?: number,
	time_of_day?: string,
	mark?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	markCancel?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	notification?: GraphQLTypes["AppNotificationTemplateEntity"]
};
	["AppPhaseSectionMarkValueEntity"]: {
	__typename: "AppPhaseSectionMarkValueEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	phase_section_mark_id?: number,
	completed_user_id?: number,
	send_notification?: boolean,
	activated?: boolean,
	completed?: boolean,
	completed_at?: GraphQLTypes["Date"]
};
	["AppInviteEntity"]: {
	__typename: "AppInviteEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	project_id?: string,
	email?: string,
	login?: GraphQLTypes["AppLoginEntity"]
};
	["AppLoginEntity"]: {
	__typename: "AppLoginEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	relation_id?: string,
	project_id?: string,
	transferred_at?: GraphQLTypes["Date"],
	email?: string,
	role_id?: string,
	user?: GraphQLTypes["AppUserEntity"],
	invite?: GraphQLTypes["AppInviteEntity"]
};
	["AppUserEntity"]: {
	__typename: "AppUserEntity",
	id?: number,
	project_id?: number,
	relation_id?: string,
	email?: string,
	telephone?: string,
	facebook_identity?: string,
	google_identity?: string,
	password?: string,
	first_name?: string,
	last_name_prefix?: string,
	last_name?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	project?: GraphQLTypes["AppProjectEntity"],
	login?: GraphQLTypes["AppLoginEntity"],
	notifications?: Array<GraphQLTypes["AppNotificationEntity"] | undefined>
};
	["AppProjectEntity"]: {
	__typename: "AppProjectEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	contract_sum?: string,
	users?: Array<GraphQLTypes["AppUserEntity"] | undefined>
};
	["AppNotificationEntity"]: {
	__typename: "AppNotificationEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	type?: string,
	notifiable_id?: number,
	notifiable_type?: string,
	data?: GraphQLTypes["JSON"],
	read_at?: GraphQLTypes["Date"],
	user?: GraphQLTypes["AppUserEntity"]
};
	["AppProjectCostEntity"]: {
	__typename: "AppProjectCostEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	user_id?: number,
	approved_user_id?: number,
	declined_user_id?: number,
	project_id?: number,
	parent_id?: number,
	title?: string,
	memo?: string,
	value?: string,
	approved_at?: GraphQLTypes["Date"],
	declined_at?: GraphQLTypes["Date"],
	requested_at?: GraphQLTypes["Date"],
	user?: GraphQLTypes["AppUserEntity"],
	approvedUser?: GraphQLTypes["AppUserEntity"],
	declinedUser?: GraphQLTypes["AppUserEntity"],
	parent?: GraphQLTypes["AppProjectCostEntity"],
	project?: GraphQLTypes["AppProjectEntity"],
	children?: Array<GraphQLTypes["AppProjectCostEntity"] | undefined>
};
	["AppProjectContactEntity"]: {
	__typename: "AppProjectContactEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	relation_id?: string,
	type?: string
};
	["AppBuildingWeekEntity"]: {
	__typename: "AppBuildingWeekEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	isBlocked?: boolean,
	syncVersion?: string
};
	["AppContentEntity"]: {
	__typename: "AppContentEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	html?: string
};
	["AppConversationMessagePresetEntity"]: {
	__typename: "AppConversationMessagePresetEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	message?: string
};
	["AppDocumentSignatureEntity"]: {
	__typename: "AppDocumentSignatureEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	user_id?: number,
	document_id?: string,
	payload?: GraphQLTypes["JSON"],
	user?: GraphQLTypes["AppUserEntity"]
};
	["AppDocumentTypeEntity"]: {
	__typename: "AppDocumentTypeEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	name?: string,
	description?: string,
	color?: string,
	document_kind?: string,
	order_id?: number,
	special_access?: boolean,
	project_contact_type?: string
};
	["AppDocumentEntity"]: {
	__typename: "AppDocumentEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	project_id?: string,
	user_id?: number,
	document_type_id?: number,
	document_kind?: string,
	description?: string,
	confidential?: boolean,
	mime_type?: string,
	extension?: string,
	storage_id?: string,
	user?: GraphQLTypes["AppUserEntity"],
	documentType?: GraphQLTypes["AppDocumentTypeEntity"],
	signature?: GraphQLTypes["AppDocumentSignatureEntity"]
};
	["AppNotificationScheduleResourceEntity"]: {
	__typename: "AppNotificationScheduleResourceEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	notification_schedule_id?: number,
	schedule_at?: GraphQLTypes["Date"],
	schedule?: GraphQLTypes["AppNotificationScheduleEntity"]
};
	["AppPlanCompetenceEntity"]: {
	__typename: "AppPlanCompetenceEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	number?: string,
	description?: string,
	index?: number,
	color?: string
};
	["AppPlanCompetenceSettingEntity"]: {
	__typename: "AppPlanCompetenceSettingEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	plan_competence_id?: number,
	content_id?: number,
	is_visible?: boolean,
	name?: string,
	plan_competence?: GraphQLTypes["AppPlanCompetenceEntity"]
};
	["AppProjectCostPresetEntity"]: {
	__typename: "AppProjectCostPresetEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	description?: string
};
	["AppProjectFieldSheetEntity"]: {
	__typename: "AppProjectFieldSheetEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_contact_type?: string,
	name?: string,
	order?: number,
	groups?: Array<GraphQLTypes["AppProjectFieldGroupEntity"] | undefined>
};
	["AppProjectFieldGroupEntity"]: {
	__typename: "AppProjectFieldGroupEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_sheet_id?: number,
	name?: string,
	order?: number,
	sheet?: GraphQLTypes["AppProjectFieldSheetEntity"],
	assignments?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentEntity"] | undefined>
};
	["AppProjectFieldGroupAssignmentEntity"]: {
	__typename: "AppProjectFieldGroupAssignmentEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_group_id?: number,
	project_field_type?: string,
	project_field_copy_type?: string,
	value_type?: string,
	is_required?: boolean,
	order?: number,
	tooltip?: string,
	group?: GraphQLTypes["AppProjectFieldGroupEntity"],
	values?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined>
};
	["AppProjectFieldGroupAssignmentValueEntity"]: {
	__typename: "AppProjectFieldGroupAssignmentValueEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_group_assignment_id?: number,
	value?: string,
	assignment?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"]
};
	["AppProjectFieldEntity"]: {
	__typename: "AppProjectFieldEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	value?: string,
	project_id?: string,
	type?: string
};
	["AppServiceCategoryEntity"]: {
	__typename: "AppServiceCategoryEntity",
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	service_category_id?: number,
	name?: string,
	service_category?: GraphQLTypes["AppServiceCategoryEntity"],
	service_categories?: Array<GraphQLTypes["AppServiceCategoryEntity"] | undefined>
};
	["AppTextEntity"]: {
	__typename: "AppTextEntity",
	id?: number,
	value?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"]
};
	["AppRelationEntity"]: {
	__typename: "AppRelationEntity",
	id?: number,
	name?: string,
	email?: string,
	is_blacklisted?: string,
	sync_version?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"]
};
	["bedrijvenMtcEntity"]: {
	__typename: "bedrijvenMtcEntity",
	_rowid?: string,
	bedrijf?: string,
	bedrijf_oms?: string,
	bedrijf_straat?: string,
	bedrijf_huisnummer?: string,
	bedrijf_postcode?: string,
	bedrijf_plaats?: string,
	bedrijf_gln?: string,
	bedrijf_btw?: string,
	bedrijf_email?: string,
	bedrijf_email_adm?: string
};
	["Mutation"]: {
	__typename: "Mutation",
	GrantEntityCreateOne?: GraphQLTypes["GrantEntity"],
	GrantEntityUpdateOne?: GraphQLTypes["GrantEntity"],
	GrantEntityUpdateMany?: Array<GraphQLTypes["GrantEntity"] | undefined>,
	GrantEntityDeleteOne: boolean,
	RoleGrantConfigEntityCreateOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityUpdateOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityUpdateMany?: Array<GraphQLTypes["RoleGrantConfigEntity"] | undefined>,
	RoleGrantConfigEntityDeleteOne: boolean,
	RoleDocumentGrantEntityCreateOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityUpdateOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityUpdateMany?: Array<GraphQLTypes["RoleDocumentGrantEntity"] | undefined>,
	RoleDocumentGrantEntityDeleteOne: boolean,
	RoleEntityCreateOne?: GraphQLTypes["RoleEntity"],
	RoleEntityUpdateOne?: GraphQLTypes["RoleEntity"],
	RoleEntityUpdateMany?: Array<GraphQLTypes["RoleEntity"] | undefined>,
	RoleEntityDeleteOne: boolean,
	UserGrantConfigEntityCreateOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityUpdateOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityUpdateMany?: Array<GraphQLTypes["UserGrantConfigEntity"] | undefined>,
	UserGrantConfigEntityDeleteOne: boolean,
	UserNotificationSubscriptionEntityCreateOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityUpdateOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityUpdateMany?: Array<GraphQLTypes["UserNotificationSubscriptionEntity"] | undefined>,
	UserNotificationSubscriptionEntityDeleteOne: boolean,
	UserNotificationEntityCreateOne?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityUpdateOne?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityUpdateMany?: Array<GraphQLTypes["UserNotificationEntity"] | undefined>,
	UserNotificationEntityDeleteOne: boolean,
	UserTemporaryAccessTokenEntityEntityCreateOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityUpdateOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityUpdateMany?: Array<GraphQLTypes["UserTemporaryAccessTokenEntityEntity"] | undefined>,
	UserTemporaryAccessTokenEntityEntityDeleteOne: boolean,
	UserEntityCreateOne?: GraphQLTypes["UserEntity"],
	UserEntityUpdateOne?: GraphQLTypes["UserEntity"],
	UserEntityUpdateMany?: Array<GraphQLTypes["UserEntity"] | undefined>,
	UserEntityDeleteOne: boolean,
	PicklistDefinitionEntityCreateOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityUpdateOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityUpdateMany?: Array<GraphQLTypes["PicklistDefinitionEntity"] | undefined>,
	PicklistDefinitionEntityDeleteOne: boolean,
	PicklistStateEntityCreateOne?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityUpdateOne?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityUpdateMany?: Array<GraphQLTypes["PicklistStateEntity"] | undefined>,
	PicklistStateEntityDeleteOne: boolean,
	HousePartGroupEntityCreateOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityUpdateOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityUpdateMany?: Array<GraphQLTypes["HousePartGroupEntity"] | undefined>,
	HousePartGroupEntityDeleteOne: boolean,
	HousePartEntityCreateOne?: GraphQLTypes["HousePartEntity"],
	HousePartEntityUpdateOne?: GraphQLTypes["HousePartEntity"],
	HousePartEntityUpdateMany?: Array<GraphQLTypes["HousePartEntity"] | undefined>,
	HousePartEntityDeleteOne: boolean,
	CargoPhaseEntityCreateOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityUpdateOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityUpdateMany?: Array<GraphQLTypes["CargoPhaseEntity"] | undefined>,
	CargoPhaseEntityDeleteOne: boolean,
	CargoTypeEntityCreateOne?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityUpdateOne?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityUpdateMany?: Array<GraphQLTypes["CargoTypeEntity"] | undefined>,
	CargoTypeEntityDeleteOne: boolean,
	PlanningHumanResourceEntityCreateOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityUpdateOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityUpdateMany?: Array<GraphQLTypes["PlanningHumanResourceEntity"] | undefined>,
	PlanningHumanResourceEntityDeleteOne: boolean,
	PlanningProjectAssignmentEntityCreateOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityUpdateOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectAssignmentEntity"] | undefined>,
	PlanningProjectAssignmentEntityDeleteOne: boolean,
	PlanningProjectCompetencePlacementEntityCreateOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityUpdateOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectCompetencePlacementEntity"] | undefined>,
	PlanningProjectCompetencePlacementEntityDeleteOne: boolean,
	PlanningProjectRequestPlacementEntityCreateOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityUpdateOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectRequestPlacementEntity"] | undefined>,
	PlanningProjectRequestPlacementEntityDeleteOne: boolean,
	PlanningProjectEntityCreateOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityUpdateOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectEntity"] | undefined>,
	PlanningProjectEntityDeleteOne: boolean,
	PlanningProjectRequestEntityCreateOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityUpdateOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectRequestEntity"] | undefined>,
	PlanningProjectRequestEntityDeleteOne: boolean,
	PlanningProjectCompetenceEntityCreateOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityUpdateOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectCompetenceEntity"] | undefined>,
	PlanningProjectCompetenceEntityDeleteOne: boolean,
	PlanningCompetenceEntityCreateOne?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityUpdateOne?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityUpdateMany?: Array<GraphQLTypes["PlanningCompetenceEntity"] | undefined>,
	PlanningCompetenceEntityDeleteOne: boolean,
	CargoStateEntityCreateOne?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityUpdateOne?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityUpdateMany?: Array<GraphQLTypes["CargoStateEntity"] | undefined>,
	CargoStateEntityDeleteOne: boolean,
	CargoEntityCreateOne?: GraphQLTypes["CargoEntity"],
	CargoEntityUpdateOne?: GraphQLTypes["CargoEntity"],
	CargoEntityUpdateMany?: Array<GraphQLTypes["CargoEntity"] | undefined>,
	CargoEntityDeleteOne: boolean,
	ProjectHousePartEntityCreateOne?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityUpdateOne?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityUpdateMany?: Array<GraphQLTypes["ProjectHousePartEntity"] | undefined>,
	ProjectHousePartEntityDeleteOne: boolean,
	PicklistElementEntityCreateOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityUpdateOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityUpdateMany?: Array<GraphQLTypes["PicklistElementEntity"] | undefined>,
	PicklistElementEntityDeleteOne: boolean,
	PicklistEntityCreateOne?: GraphQLTypes["PicklistEntity"],
	PicklistEntityUpdateOne?: GraphQLTypes["PicklistEntity"],
	PicklistEntityUpdateMany?: Array<GraphQLTypes["PicklistEntity"] | undefined>,
	PicklistEntityDeleteOne: boolean,
	DrawOrderTypeEntityCreateOne?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityUpdateOne?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityUpdateMany?: Array<GraphQLTypes["DrawOrderTypeEntity"] | undefined>,
	DrawOrderTypeEntityDeleteOne: boolean,
	HouseModelEntityCreateOne?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityUpdateOne?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityUpdateMany?: Array<GraphQLTypes["HouseModelEntity"] | undefined>,
	HouseModelEntityDeleteOne: boolean,
	SalesEmployeeEntityCreateOne?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityUpdateOne?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityUpdateMany?: Array<GraphQLTypes["SalesEmployeeEntity"] | undefined>,
	SalesEmployeeEntityDeleteOne: boolean,
	DrawerAbsentEntityCreateOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityUpdateOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityUpdateMany?: Array<GraphQLTypes["DrawerAbsentEntity"] | undefined>,
	DrawerAbsentEntityDeleteOne: boolean,
	DrawerEntityCreateOne?: GraphQLTypes["DrawerEntity"],
	DrawerEntityUpdateOne?: GraphQLTypes["DrawerEntity"],
	DrawerEntityUpdateMany?: Array<GraphQLTypes["DrawerEntity"] | undefined>,
	DrawerEntityDeleteOne: boolean,
	DrawOrderEntityCreateOne?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityUpdateOne?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityUpdateMany?: Array<GraphQLTypes["DrawOrderEntity"] | undefined>,
	DrawOrderEntityDeleteOne: boolean,
	DateGroupCommentEntityCreateOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityUpdateOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityUpdateMany?: Array<GraphQLTypes["DateGroupCommentEntity"] | undefined>,
	DateGroupCommentEntityDeleteOne: boolean,
	DateGroupResponsibleEntityCreateOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityUpdateOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityUpdateMany?: Array<GraphQLTypes["DateGroupResponsibleEntity"] | undefined>,
	DateGroupResponsibleEntityDeleteOne: boolean,
	LogicFieldEntityCreateOne?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityUpdateOne?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityUpdateMany?: Array<GraphQLTypes["LogicFieldEntity"] | undefined>,
	LogicFieldEntityDeleteOne: boolean,
	DateGroupEntityCreateOne?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityUpdateOne?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityUpdateMany?: Array<GraphQLTypes["DateGroupEntity"] | undefined>,
	DateGroupEntityDeleteOne: boolean,
	DateEntityCreateOne?: GraphQLTypes["DateEntity"],
	DateEntityUpdateOne?: GraphQLTypes["DateEntity"],
	DateEntityUpdateMany?: Array<GraphQLTypes["DateEntity"] | undefined>,
	DateEntityDeleteOne: boolean,
	DateValueEntityCreateOne?: GraphQLTypes["DateValueEntity"],
	DateValueEntityUpdateOne?: GraphQLTypes["DateValueEntity"],
	DateValueEntityUpdateMany?: Array<GraphQLTypes["DateValueEntity"] | undefined>,
	DateValueEntityDeleteOne: boolean,
	LogicFieldValueEntityCreateOne?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityUpdateOne?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityUpdateMany?: Array<GraphQLTypes["LogicFieldValueEntity"] | undefined>,
	LogicFieldValueEntityDeleteOne: boolean,
	DateCacheEntityCreateOne?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityUpdateOne?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityUpdateMany?: Array<GraphQLTypes["DateCacheEntity"] | undefined>,
	DateCacheEntityDeleteOne: boolean,
	YearPlanningModificationEntityCreateOne?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityUpdateOne?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityUpdateMany?: Array<GraphQLTypes["YearPlanningModificationEntity"] | undefined>,
	YearPlanningModificationEntityDeleteOne: boolean,
	ProjectCachedValueEntityCreateOne?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityUpdateOne?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityUpdateMany?: Array<GraphQLTypes["ProjectCachedValueEntity"] | undefined>,
	ProjectCachedValueEntityDeleteOne: boolean,
	TableLayoutCommentEntityCreateOne?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityUpdateOne?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityUpdateMany?: Array<GraphQLTypes["TableLayoutCommentEntity"] | undefined>,
	TableLayoutCommentEntityDeleteOne: boolean,
	ProjectEntityCreateOne?: GraphQLTypes["ProjectEntity"],
	ProjectEntityUpdateOne?: GraphQLTypes["ProjectEntity"],
	ProjectEntityUpdateMany?: Array<GraphQLTypes["ProjectEntity"] | undefined>,
	ProjectEntityDeleteOne: boolean,
	SyncCronEntityCreateOne?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityUpdateOne?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityUpdateMany?: Array<GraphQLTypes["SyncCronEntity"] | undefined>,
	SyncCronEntityDeleteOne: boolean,
	MailSchedulerEntityCreateOne?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityUpdateOne?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityUpdateMany?: Array<GraphQLTypes["MailSchedulerEntity"] | undefined>,
	MailSchedulerEntityDeleteOne: boolean,
	ProjectPartnerEntityCreateOne?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityUpdateOne?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityUpdateMany?: Array<GraphQLTypes["ProjectPartnerEntity"] | undefined>,
	ProjectPartnerEntityDeleteOne: boolean,
	PartnerEntityCreateOne?: GraphQLTypes["PartnerEntity"],
	PartnerEntityUpdateOne?: GraphQLTypes["PartnerEntity"],
	PartnerEntityUpdateMany?: Array<GraphQLTypes["PartnerEntity"] | undefined>,
	PartnerEntityDeleteOne: boolean,
	DocumentMetaEntityCreateOne?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityUpdateOne?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityUpdateMany?: Array<GraphQLTypes["DocumentMetaEntity"] | undefined>,
	DocumentMetaEntityDeleteOne: boolean,
	EntityAdvancementEntityCreateOne?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityUpdateOne?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityUpdateMany?: Array<GraphQLTypes["EntityAdvancementEntity"] | undefined>,
	EntityAdvancementEntityDeleteOne: boolean,
	WorkActionCategoryEntityCreateOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityUpdateOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityUpdateMany?: Array<GraphQLTypes["WorkActionCategoryEntity"] | undefined>,
	WorkActionCategoryEntityDeleteOne: boolean,
	WorkActionEntityCreateOne?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityUpdateOne?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityUpdateMany?: Array<GraphQLTypes["WorkActionEntity"] | undefined>,
	WorkActionEntityDeleteOne: boolean,
	EntityImageEntityCreateOne?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityUpdateOne?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityUpdateMany?: Array<GraphQLTypes["EntityImageEntity"] | undefined>,
	EntityImageEntityDeleteOne: boolean,
	CustomerQuestionEntityCreateOne?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityUpdateOne?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityUpdateMany?: Array<GraphQLTypes["CustomerQuestionEntity"] | undefined>,
	CustomerQuestionEntityDeleteOne: boolean,
	AuditCategoryEntityCreateOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityUpdateOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityUpdateMany?: Array<GraphQLTypes["AuditCategoryEntity"] | undefined>,
	AuditCategoryEntityDeleteOne: boolean,
	AuditQuestionEntityCreateOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityUpdateOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityUpdateMany?: Array<GraphQLTypes["AuditQuestionEntity"] | undefined>,
	AuditQuestionEntityDeleteOne: boolean,
	AuditQuestionCostCategoryEntityCreateOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityUpdateOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityUpdateMany?: Array<GraphQLTypes["AuditQuestionCostCategoryEntity"] | undefined>,
	AuditQuestionCostCategoryEntityDeleteOne: boolean,
	CostCategoryEntityCreateOne?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityUpdateOne?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityUpdateMany?: Array<GraphQLTypes["CostCategoryEntity"] | undefined>,
	CostCategoryEntityDeleteOne: boolean,
	AuditReportItemImageEntityCreateOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityUpdateOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityUpdateMany?: Array<GraphQLTypes["AuditReportItemImageEntity"] | undefined>,
	AuditReportItemImageEntityDeleteOne: boolean,
	AuditReportItemEntityCreateOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityUpdateOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityUpdateMany?: Array<GraphQLTypes["AuditReportItemEntity"] | undefined>,
	AuditReportItemEntityDeleteOne: boolean,
	AuditReportEntityCreateOne?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityUpdateOne?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityUpdateMany?: Array<GraphQLTypes["AuditReportEntity"] | undefined>,
	AuditReportEntityDeleteOne: boolean,
	AuditChecklistImageEntityCreateOne?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityUpdateOne?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityUpdateMany?: Array<GraphQLTypes["AuditChecklistImageEntity"] | undefined>,
	AuditChecklistImageEntityDeleteOne: boolean,
	InvoiceAttachmentEntityCreateOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityUpdateOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityUpdateMany?: Array<GraphQLTypes["InvoiceAttachmentEntity"] | undefined>,
	InvoiceAttachmentEntityDeleteOne: boolean,
	EmployeeDocumentEntityCreateOne?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityUpdateOne?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityUpdateMany?: Array<GraphQLTypes["EmployeeDocumentEntity"] | undefined>,
	EmployeeDocumentEntityDeleteOne: boolean,
	WorkActionTemplateGroupEntityCreateOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityUpdateOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityUpdateMany?: Array<GraphQLTypes["WorkActionTemplateGroupEntity"] | undefined>,
	WorkActionTemplateGroupEntityDeleteOne: boolean,
	WorkActionTemplateEntityCreateOne?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityUpdateOne?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityUpdateMany?: Array<GraphQLTypes["WorkActionTemplateEntity"] | undefined>,
	WorkActionTemplateEntityDeleteOne: boolean,
	ProjectCostInfoEntityCreateOne?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityUpdateOne?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityUpdateMany?: Array<GraphQLTypes["ProjectCostInfoEntity"] | undefined>,
	ProjectCostInfoEntityDeleteOne: boolean,
	BlockedCalendarWeekEntityCreateOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityUpdateOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityUpdateMany?: Array<GraphQLTypes["BlockedCalendarWeekEntity"] | undefined>,
	BlockedCalendarWeekEntityDeleteOne: boolean,
	MaintenanceReportEntityCreateOne?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityUpdateOne?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityUpdateMany?: Array<GraphQLTypes["MaintenanceReportEntity"] | undefined>,
	MaintenanceReportEntityDeleteOne: boolean,
	ConfigurationEntityCreateOne?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityUpdateOne?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityUpdateMany?: Array<GraphQLTypes["ConfigurationEntity"] | undefined>,
	ConfigurationEntityDeleteOne: boolean,
	ServiceMeterEntityCreateOne?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityUpdateOne?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityUpdateMany?: Array<GraphQLTypes["ServiceMeterEntity"] | undefined>,
	ServiceMeterEntityDeleteOne: boolean,
	DeliveryListMeterEntityCreateOne?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityUpdateOne?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityUpdateMany?: Array<GraphQLTypes["DeliveryListMeterEntity"] | undefined>,
	DeliveryListMeterEntityDeleteOne: boolean,
	ServiceSpaceEntityCreateOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityUpdateOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityUpdateMany?: Array<GraphQLTypes["ServiceSpaceEntity"] | undefined>,
	ServiceSpaceEntityDeleteOne: boolean,
	ServiceTypeEntityCreateOne?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityUpdateOne?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityUpdateMany?: Array<GraphQLTypes["ServiceTypeEntity"] | undefined>,
	ServiceTypeEntityDeleteOne: boolean,
	DeliveryListPointImageEntityCreateOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityUpdateOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityUpdateMany?: Array<GraphQLTypes["DeliveryListPointImageEntity"] | undefined>,
	DeliveryListPointImageEntityDeleteOne: boolean,
	ServicePriorityEntityCreateOne?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityUpdateOne?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityUpdateMany?: Array<GraphQLTypes["ServicePriorityEntity"] | undefined>,
	ServicePriorityEntityDeleteOne: boolean,
	DeliveryListPointEntityCreateOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityUpdateOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityUpdateMany?: Array<GraphQLTypes["DeliveryListPointEntity"] | undefined>,
	DeliveryListPointEntityDeleteOne: boolean,
	DeliveryListSignatureEntityCreateOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityUpdateOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityUpdateMany?: Array<GraphQLTypes["DeliveryListSignatureEntity"] | undefined>,
	DeliveryListSignatureEntityDeleteOne: boolean,
	DeliveryListEntityCreateOne?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityUpdateOne?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityUpdateMany?: Array<GraphQLTypes["DeliveryListEntity"] | undefined>,
	DeliveryListEntityDeleteOne: boolean,
	SupplyInspectionReportAnswerEntityCreateOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityUpdateOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityUpdateMany?: Array<GraphQLTypes["SupplyInspectionReportAnswerEntity"] | undefined>,
	SupplyInspectionReportAnswerEntityDeleteOne: boolean,
	SupplyInspectionReportEntityCreateOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityUpdateOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityUpdateMany?: Array<GraphQLTypes["SupplyInspectionReportEntity"] | undefined>,
	SupplyInspectionReportEntityDeleteOne: boolean,
	DepartmentEntityCreateOne?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityUpdateOne?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityUpdateMany?: Array<GraphQLTypes["DepartmentEntity"] | undefined>,
	DepartmentEntityDeleteOne: boolean,
	DrawOrderDescriptionEntityCreateOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityUpdateOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityUpdateMany?: Array<GraphQLTypes["DrawOrderDescriptionEntity"] | undefined>,
	DrawOrderDescriptionEntityDeleteOne: boolean,
	GreetingEntityCreateOne?: GraphQLTypes["GreetingEntity"],
	GreetingEntityUpdateOne?: GraphQLTypes["GreetingEntity"],
	GreetingEntityUpdateMany?: Array<GraphQLTypes["GreetingEntity"] | undefined>,
	GreetingEntityDeleteOne: boolean,
	HourDeclarationTypeEntityCreateOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityUpdateOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityUpdateMany?: Array<GraphQLTypes["HourDeclarationTypeEntity"] | undefined>,
	HourDeclarationTypeEntityDeleteOne: boolean,
	HourDeclarationActivityEntityCreateOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityUpdateOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityUpdateMany?: Array<GraphQLTypes["HourDeclarationActivityEntity"] | undefined>,
	HourDeclarationActivityEntityDeleteOne: boolean,
	HourDeclarationEntityCreateOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityUpdateOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityUpdateMany?: Array<GraphQLTypes["HourDeclarationEntity"] | undefined>,
	HourDeclarationEntityDeleteOne: boolean,
	HourDeclarationApprovalRequestEntityCreateOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityUpdateOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityUpdateMany?: Array<GraphQLTypes["HourDeclarationApprovalRequestEntity"] | undefined>,
	HourDeclarationApprovalRequestEntityDeleteOne: boolean,
	InstallationReportCommentEntityCreateOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityUpdateOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityUpdateMany?: Array<GraphQLTypes["InstallationReportCommentEntity"] | undefined>,
	InstallationReportCommentEntityDeleteOne: boolean,
	InstallationReportDescriptionEntityCreateOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityUpdateOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityUpdateMany?: Array<GraphQLTypes["InstallationReportDescriptionEntity"] | undefined>,
	InstallationReportDescriptionEntityDeleteOne: boolean,
	InstallationReportFinalEntityCreateOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityUpdateOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityUpdateMany?: Array<GraphQLTypes["InstallationReportFinalEntity"] | undefined>,
	InstallationReportFinalEntityDeleteOne: boolean,
	InstallationReportMeasurementTemplateEntityCreateOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityUpdateOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityUpdateMany?: Array<GraphQLTypes["InstallationReportMeasurementTemplateEntity"] | undefined>,
	InstallationReportMeasurementTemplateEntityDeleteOne: boolean,
	InstallationReportMeasurementEntityCreateOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityUpdateOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityUpdateMany?: Array<GraphQLTypes["InstallationReportMeasurementEntity"] | undefined>,
	InstallationReportMeasurementEntityDeleteOne: boolean,
	InstallationReportQuestionGroupEntityCreateOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityUpdateOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityUpdateMany?: Array<GraphQLTypes["InstallationReportQuestionGroupEntity"] | undefined>,
	InstallationReportQuestionGroupEntityDeleteOne: boolean,
	InstallationReportQuestionEntityCreateOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityUpdateOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityUpdateMany?: Array<GraphQLTypes["InstallationReportQuestionEntity"] | undefined>,
	InstallationReportQuestionEntityDeleteOne: boolean,
	InstallationReportQuestionAnswerEntityCreateOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityUpdateOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityUpdateMany?: Array<GraphQLTypes["InstallationReportQuestionAnswerEntity"] | undefined>,
	InstallationReportQuestionAnswerEntityDeleteOne: boolean,
	LegacyProjectEntityCreateOne?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityUpdateOne?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityUpdateMany?: Array<GraphQLTypes["LegacyProjectEntity"] | undefined>,
	LegacyProjectEntityDeleteOne: boolean,
	PicklistCommentEntityCreateOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityUpdateOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityUpdateMany?: Array<GraphQLTypes["PicklistCommentEntity"] | undefined>,
	PicklistCommentEntityDeleteOne: boolean,
	PicklistElementStateEntityCreateOne?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityUpdateOne?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityUpdateMany?: Array<GraphQLTypes["PicklistElementStateEntity"] | undefined>,
	PicklistElementStateEntityDeleteOne: boolean,
	PlanningProjectItemEntityCreateOne?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityUpdateOne?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityUpdateMany?: Array<GraphQLTypes["PlanningProjectItemEntity"] | undefined>,
	PlanningProjectItemEntityDeleteOne: boolean,
	ProjectCargoStateEntityCreateOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityUpdateOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityUpdateMany?: Array<GraphQLTypes["ProjectCargoStateEntity"] | undefined>,
	ProjectCargoStateEntityDeleteOne: boolean,
	ProjectPaymentEntityCreateOne?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityUpdateOne?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityUpdateMany?: Array<GraphQLTypes["ProjectPaymentEntity"] | undefined>,
	ProjectPaymentEntityDeleteOne: boolean,
	QualityAssuranceEntityCreateOne?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityUpdateOne?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityUpdateMany?: Array<GraphQLTypes["QualityAssuranceEntity"] | undefined>,
	QualityAssuranceEntityDeleteOne: boolean,
	ServiceMapColorEntityCreateOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityUpdateOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityUpdateMany?: Array<GraphQLTypes["ServiceMapColorEntity"] | undefined>,
	ServiceMapColorEntityDeleteOne: boolean,
	ServicePlanningStageEntityCreateOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityUpdateOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityUpdateMany?: Array<GraphQLTypes["ServicePlanningStageEntity"] | undefined>,
	ServicePlanningStageEntityDeleteOne: boolean,
	ServicePlanningEventEntityCreateOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityUpdateOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityUpdateMany?: Array<GraphQLTypes["ServicePlanningEventEntity"] | undefined>,
	ServicePlanningEventEntityDeleteOne: boolean,
	ServicePlanningAssignmentEntityCreateOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityUpdateOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityUpdateMany?: Array<GraphQLTypes["ServicePlanningAssignmentEntity"] | undefined>,
	ServicePlanningAssignmentEntityDeleteOne: boolean,
	ServicePresetEntityCreateOne?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityUpdateOne?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityUpdateMany?: Array<GraphQLTypes["ServicePresetEntity"] | undefined>,
	ServicePresetEntityDeleteOne: boolean,
	SupplierDocumentEntityCreateOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityUpdateOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityUpdateMany?: Array<GraphQLTypes["SupplierDocumentEntity"] | undefined>,
	SupplierDocumentEntityDeleteOne: boolean,
	SupplyInspectionQuestionEntityCreateOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityUpdateOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityUpdateMany?: Array<GraphQLTypes["SupplyInspectionQuestionEntity"] | undefined>,
	SupplyInspectionQuestionEntityDeleteOne: boolean,
	SupplyInspectionQuestionGroupEntityCreateOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityUpdateOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityUpdateMany?: Array<GraphQLTypes["SupplyInspectionQuestionGroupEntity"] | undefined>,
	SupplyInspectionQuestionGroupEntityDeleteOne: boolean,
	UserPasswordResetEntityCreateOne?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityUpdateOne?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityUpdateMany?: Array<GraphQLTypes["UserPasswordResetEntity"] | undefined>,
	UserPasswordResetEntityDeleteOne: boolean,
	YearPlanningLineEntityCreateOne?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityUpdateOne?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityUpdateMany?: Array<GraphQLTypes["YearPlanningLineEntity"] | undefined>,
	YearPlanningLineEntityDeleteOne: boolean,
	InvoicePaymentBatchEntityEntityCreateOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityUpdateOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityUpdateMany?: Array<GraphQLTypes["InvoicePaymentBatchEntityEntity"] | undefined>,
	InvoicePaymentBatchEntityEntityDeleteOne: boolean,
	AppPhaseContactEntityCreateOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityUpdateOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityUpdateMany?: Array<GraphQLTypes["AppPhaseContactEntity"] | undefined>,
	AppPhaseContactEntityDeleteOne: boolean,
	AppPhaseEntityCreateOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityUpdateOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityUpdateMany?: Array<GraphQLTypes["AppPhaseEntity"] | undefined>,
	AppPhaseEntityDeleteOne: boolean,
	AppPhaseSectionEntityCreateOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityUpdateOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityUpdateMany?: Array<GraphQLTypes["AppPhaseSectionEntity"] | undefined>,
	AppPhaseSectionEntityDeleteOne: boolean,
	AppNotificationTemplateEntityCreateOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityUpdateOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityUpdateMany?: Array<GraphQLTypes["AppNotificationTemplateEntity"] | undefined>,
	AppNotificationTemplateEntityDeleteOne: boolean,
	AppPhaseSectionMarkEntityCreateOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityUpdateOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityUpdateMany?: Array<GraphQLTypes["AppPhaseSectionMarkEntity"] | undefined>,
	AppPhaseSectionMarkEntityDeleteOne: boolean,
	AppProjectDateEntityCreateOne?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityUpdateOne?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityUpdateMany?: Array<GraphQLTypes["AppProjectDateEntity"] | undefined>,
	AppProjectDateEntityDeleteOne: boolean,
	AppNotificationScheduleEntityCreateOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityUpdateOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityUpdateMany?: Array<GraphQLTypes["AppNotificationScheduleEntity"] | undefined>,
	AppNotificationScheduleEntityDeleteOne: boolean,
	AppPhaseSectionMarkValueEntityCreateOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityUpdateOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityUpdateMany?: Array<GraphQLTypes["AppPhaseSectionMarkValueEntity"] | undefined>,
	AppPhaseSectionMarkValueEntityDeleteOne: boolean,
	AppInviteEntityCreateOne?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityUpdateOne?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityUpdateMany?: Array<GraphQLTypes["AppInviteEntity"] | undefined>,
	AppInviteEntityDeleteOne: boolean,
	AppLoginEntityCreateOne?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityUpdateOne?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityUpdateMany?: Array<GraphQLTypes["AppLoginEntity"] | undefined>,
	AppLoginEntityDeleteOne: boolean,
	AppNotificationEntityCreateOne?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityUpdateOne?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityUpdateMany?: Array<GraphQLTypes["AppNotificationEntity"] | undefined>,
	AppNotificationEntityDeleteOne: boolean,
	AppProjectEntityCreateOne?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityUpdateOne?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityUpdateMany?: Array<GraphQLTypes["AppProjectEntity"] | undefined>,
	AppProjectEntityDeleteOne: boolean,
	AppUserEntityCreateOne?: GraphQLTypes["AppUserEntity"],
	AppUserEntityUpdateOne?: GraphQLTypes["AppUserEntity"],
	AppUserEntityUpdateMany?: Array<GraphQLTypes["AppUserEntity"] | undefined>,
	AppUserEntityDeleteOne: boolean,
	AppProjectCostEntityCreateOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityUpdateOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityUpdateMany?: Array<GraphQLTypes["AppProjectCostEntity"] | undefined>,
	AppProjectCostEntityDeleteOne: boolean,
	AppProjectContactEntityCreateOne?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityUpdateOne?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityUpdateMany?: Array<GraphQLTypes["AppProjectContactEntity"] | undefined>,
	AppProjectContactEntityDeleteOne: boolean,
	AppBuildingWeekEntityCreateOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityUpdateOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityUpdateMany?: Array<GraphQLTypes["AppBuildingWeekEntity"] | undefined>,
	AppBuildingWeekEntityDeleteOne: boolean,
	AppContentEntityCreateOne?: GraphQLTypes["AppContentEntity"],
	AppContentEntityUpdateOne?: GraphQLTypes["AppContentEntity"],
	AppContentEntityUpdateMany?: Array<GraphQLTypes["AppContentEntity"] | undefined>,
	AppContentEntityDeleteOne: boolean,
	AppConversationMessagePresetEntityCreateOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityUpdateOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityUpdateMany?: Array<GraphQLTypes["AppConversationMessagePresetEntity"] | undefined>,
	AppConversationMessagePresetEntityDeleteOne: boolean,
	AppDocumentSignatureEntityCreateOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityUpdateOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityUpdateMany?: Array<GraphQLTypes["AppDocumentSignatureEntity"] | undefined>,
	AppDocumentSignatureEntityDeleteOne: boolean,
	AppDocumentTypeEntityCreateOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityUpdateOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityUpdateMany?: Array<GraphQLTypes["AppDocumentTypeEntity"] | undefined>,
	AppDocumentTypeEntityDeleteOne: boolean,
	AppDocumentEntityCreateOne?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityUpdateOne?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityUpdateMany?: Array<GraphQLTypes["AppDocumentEntity"] | undefined>,
	AppDocumentEntityDeleteOne: boolean,
	AppNotificationScheduleResourceEntityCreateOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityUpdateOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityUpdateMany?: Array<GraphQLTypes["AppNotificationScheduleResourceEntity"] | undefined>,
	AppNotificationScheduleResourceEntityDeleteOne: boolean,
	AppPlanCompetenceEntityCreateOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityUpdateOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityUpdateMany?: Array<GraphQLTypes["AppPlanCompetenceEntity"] | undefined>,
	AppPlanCompetenceEntityDeleteOne: boolean,
	AppPlanCompetenceSettingEntityCreateOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityUpdateOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityUpdateMany?: Array<GraphQLTypes["AppPlanCompetenceSettingEntity"] | undefined>,
	AppPlanCompetenceSettingEntityDeleteOne: boolean,
	AppProjectCostPresetEntityCreateOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityUpdateOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityUpdateMany?: Array<GraphQLTypes["AppProjectCostPresetEntity"] | undefined>,
	AppProjectCostPresetEntityDeleteOne: boolean,
	AppProjectFieldSheetEntityCreateOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityUpdateOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityUpdateMany?: Array<GraphQLTypes["AppProjectFieldSheetEntity"] | undefined>,
	AppProjectFieldSheetEntityDeleteOne: boolean,
	AppProjectFieldGroupEntityCreateOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityUpdateOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityUpdateMany?: Array<GraphQLTypes["AppProjectFieldGroupEntity"] | undefined>,
	AppProjectFieldGroupEntityDeleteOne: boolean,
	AppProjectFieldGroupAssignmentEntityCreateOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityUpdateOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityUpdateMany?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentEntity"] | undefined>,
	AppProjectFieldGroupAssignmentEntityDeleteOne: boolean,
	AppProjectFieldGroupAssignmentValueEntityCreateOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityUpdateOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityUpdateMany?: Array<GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"] | undefined>,
	AppProjectFieldGroupAssignmentValueEntityDeleteOne: boolean,
	AppProjectFieldEntityCreateOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityUpdateOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityUpdateMany?: Array<GraphQLTypes["AppProjectFieldEntity"] | undefined>,
	AppProjectFieldEntityDeleteOne: boolean,
	AppServiceCategoryEntityCreateOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityUpdateOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityUpdateMany?: Array<GraphQLTypes["AppServiceCategoryEntity"] | undefined>,
	AppServiceCategoryEntityDeleteOne: boolean,
	AppTextEntityCreateOne?: GraphQLTypes["AppTextEntity"],
	AppTextEntityUpdateOne?: GraphQLTypes["AppTextEntity"],
	AppTextEntityUpdateMany?: Array<GraphQLTypes["AppTextEntity"] | undefined>,
	AppTextEntityDeleteOne: boolean,
	AppRelationEntityCreateOne?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityUpdateOne?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityUpdateMany?: Array<GraphQLTypes["AppRelationEntity"] | undefined>,
	AppRelationEntityDeleteOne: boolean,
	UploadDocument?: GraphQLTypes["DocumentMetaEntity"],
	CellaWriteCustomFields: boolean
};
	["InputGrantEntity"]: {
		id?: string,
	permissionId?: string,
	roleId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteGrantEntity"]: {
		id?: string
};
	["InputRoleGrantConfigEntity"]: {
		id?: string,
	roleId?: string,
	configId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteRoleGrantConfigEntity"]: {
		id?: string
};
	["InputRoleDocumentGrantEntity"]: {
		id?: string,
	roleId?: string,
	match?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteRoleDocumentGrantEntity"]: {
		id?: string
};
	["InputRoleEntity"]: {
		id?: string,
	name?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteRoleEntity"]: {
		id?: string
};
	["InputUserGrantConfigEntity"]: {
		id?: string,
	userId?: string,
	configId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteUserGrantConfigEntity"]: {
		id?: string
};
	["InputUserNotificationSubscriptionEntity"]: {
		id?: string,
	userId?: string,
	endpoint?: string,
	keys_auth?: string,
	keys_p256dh?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteUserNotificationSubscriptionEntity"]: {
		id?: string
};
	["InputUserNotificationEntity"]: {
		id?: string,
	userId?: string,
	subject?: string,
	content?: string,
	url?: string,
	channels?: GraphQLTypes["JSON"],
	readAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteUserNotificationEntity"]: {
		id?: string
};
	["InputUserTemporaryAccessTokenEntityEntity"]: {
		id?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	user?: string
};
	["InputDeleteUserTemporaryAccessTokenEntityEntity"]: {
		id?: string
};
	["InputUserEntity"]: {
		id?: string,
	roleId?: string,
	companyId?: string,
	workAsUserId?: string,
	planningUserId?: string,
	abbreviation?: string,
	overwriteSignIn?: boolean,
	identity?: string,
	password?: string,
	passwordHash?: string,
	name?: string,
	stage?: string,
	email?: string,
	emailService?: string,
	emailDeclaration?: string,
	telephone?: string,
	isSupplier?: string,
	isSalesEmployee?: string,
	isServiceMechanic?: string,
	isBuyAdvisor?: string,
	isProjectMentor?: string,
	isProjectLeader?: string,
	addressStreet?: string,
	addressHouseNumber?: string,
	addressCity?: string,
	addressZipCode?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	lastSeenAt?: GraphQLTypes["Date"]
};
	["InputDeleteUserEntity"]: {
		id?: string
};
	["InputPicklistDefinitionEntity"]: {
		id?: string,
	name?: string,
	icon?: string,
	metacomListTable?: string,
	metacomLinesTable?: string,
	renameFilterName?: string,
	competenceNumber?: string,
	elementTypes?: GraphQLTypes["JSON"],
	hideColumns?: GraphQLTypes["JSON"],
	indeterminateToggles?: boolean,
	dateSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePicklistDefinitionEntity"]: {
		id?: string
};
	["InputPicklistStateEntity"]: {
		id?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	completedByUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePicklistStateEntity"]: {
		id?: string
};
	["InputHousePartGroupEntity"]: {
		id?: string,
	name?: string,
	isEnabled?: boolean,
	unit?: string,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHousePartGroupEntity"]: {
		id?: string
};
	["InputHousePartEntity"]: {
		id?: string,
	partId?: string,
	housePartGroupId?: string,
	supplierId?: string,
	description?: string,
	rootElement?: string,
	releaseTimeMins?: number,
	length?: string,
	width?: string,
	capacityFactor?: number,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHousePartEntity"]: {
		id?: string
};
	["InputCargoPhaseEntity"]: {
		id?: string,
	projectId?: string,
	description?: string,
	date?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCargoPhaseEntity"]: {
		id?: string
};
	["InputCargoTypeEntity"]: {
		id?: string,
	description?: string,
	userId?: string,
	price?: string,
	unit?: string,
	colorCode?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCargoTypeEntity"]: {
		id?: string
};
	["InputPlanningHumanResourceEntity"]: {
		id?: string,
	number?: string,
	resourceTypeId?: string,
	color?: string,
	firstName?: string,
	lastNamePrefix?: string,
	lastName?: string,
	email?: string,
	isNotProjectLeader?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningHumanResourceEntity"]: {
		id?: string
};
	["InputPlanningProjectAssignmentEntity"]: {
		id?: string,
	humanResourceId?: string,
	planningCompetencePlacementId?: string,
	comments?: string,
	startsAt?: GraphQLTypes["Date"],
	endsAt?: GraphQLTypes["Date"],
	hours?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectAssignmentEntity"]: {
		id?: string
};
	["InputPlanningProjectCompetencePlacementEntity"]: {
		id?: string,
	planningProjectCompetenceId?: string,
	planningProjectRequestPlacementId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectCompetencePlacementEntity"]: {
		id?: string
};
	["InputPlanningProjectRequestPlacementEntity"]: {
		id?: string,
	planningProjectRequestId?: string,
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	status?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectRequestPlacementEntity"]: {
		id?: string
};
	["InputPlanningProjectEntity"]: {
		id?: string,
	projectId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectEntity"]: {
		id?: string
};
	["InputPlanningProjectRequestEntity"]: {
		id?: string,
	planningProjectId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectRequestEntity"]: {
		id?: string
};
	["InputPlanningProjectCompetenceEntity"]: {
		id?: string,
	planningCompetenceId?: string,
	planningProjectRequestId?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectCompetenceEntity"]: {
		id?: string
};
	["InputPlanningCompetenceEntity"]: {
		id?: string,
	number?: string,
	color?: string,
	description?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningCompetenceEntity"]: {
		id?: string
};
	["InputCargoStateEntity"]: {
		id?: string,
	cargoId?: string,
	housePartGroupId?: string,
	divisionId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCargoStateEntity"]: {
		id?: string
};
	["InputCargoEntity"]: {
		id?: string,
	projectId?: string,
	competenceId?: string,
	phaseId?: string,
	cargoTypeId?: string,
	description?: string,
	timeAt?: string,
	requiresPermit?: boolean,
	routeId?: string,
	dateAt?: GraphQLTypes["Date"],
	isLocked?: boolean,
	notes?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCargoEntity"]: {
		id?: string
};
	["InputProjectHousePartEntity"]: {
		id?: string,
	projectId?: string,
	cargoId?: string,
	supplierId?: string,
	partId?: string,
	partCodeId?: string,
	groupDivisionId?: string,
	quantity?: string,
	weight?: string,
	unit?: string,
	length?: string,
	width?: string,
	moldId?: string,
	wallId?: string,
	dateId?: string,
	costId?: string,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectHousePartEntity"]: {
		id?: string
};
	["InputPicklistElementEntity"]: {
		id?: string,
	picklistId?: string,
	housePartGroupId?: string,
	pickedByUserId?: string,
	elementId?: string,
	elementType?: string,
	elementGroup?: string,
	elementName?: string,
	description?: string,
	quantity?: number,
	unit?: string,
	dimensions?: string,
	orderId?: string,
	pickState?: string,
	isPicked?: boolean,
	pickedAt?: GraphQLTypes["Date"],
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePicklistElementEntity"]: {
		id?: string
};
	["InputPicklistEntity"]: {
		id?: string,
	projectId?: string,
	state?: string,
	note?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	syncSource?: string,
	syncVersion?: string
};
	["InputDeletePicklistEntity"]: {
		id?: string
};
	["InputDrawOrderTypeEntity"]: {
		id?: string,
	name?: string,
	condition?: string,
	defaultHours?: number,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDrawOrderTypeEntity"]: {
		id?: string
};
	["InputHouseModelEntity"]: {
		id?: string,
	name?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHouseModelEntity"]: {
		id?: string
};
	["InputSalesEmployeeEntity"]: {
		id?: string,
	name?: string,
	firstName?: string,
	condition?: string,
	isActive?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteSalesEmployeeEntity"]: {
		id?: string
};
	["InputDrawerAbsentEntity"]: {
		id?: string,
	drawerId?: string,
	reason?: string,
	color?: string,
	date?: GraphQLTypes["Date"],
	hours?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDrawerAbsentEntity"]: {
		id?: string
};
	["InputDrawerEntity"]: {
		id?: string,
	userId?: string,
	alias?: string,
	condition?: string,
	isHSB?: string,
	isBK?: string,
	isIT?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDrawerEntity"]: {
		id?: string
};
	["InputDrawOrderEntity"]: {
		id?: string,
	projectId?: string,
	kind?: string,
	drawOrderTypeId?: string,
	houseModelId?: string,
	salesEmployeeId?: string,
	drawerId?: string,
	description?: string,
	memo?: string,
	memoLong?: string,
	completedAt?: GraphQLTypes["Date"],
	plannedAt?: GraphQLTypes["Date"],
	plannedAtOrder?: number,
	totalHours?: number,
	totalHoursSpend?: number,
	percentageCompleted?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDrawOrderEntity"]: {
		id?: string
};
	["InputDateGroupCommentEntity"]: {
		id?: string,
	dateGroupId?: string,
	projectId?: string,
	userId?: string,
	value?: string,
	referenceId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDateGroupCommentEntity"]: {
		id?: string
};
	["InputDateGroupResponsibleEntity"]: {
		id?: string,
	userId?: string,
	projectId?: string,
	dateGroupId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDateGroupResponsibleEntity"]: {
		id?: string
};
	["InputLogicFieldEntity"]: {
		id?: string,
	fieldId?: string,
	fieldName?: string,
	dateGroupId?: string,
	conditionDescription?: string,
	expression?: string,
	iconId?: string,
	colorCode?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteLogicFieldEntity"]: {
		id?: string
};
	["InputDateGroupEntity"]: {
		id?: string,
	description?: string,
	label?: string,
	hasResponsible?: boolean,
	hasComments?: boolean,
	visibleOnConstructionPlanning?: boolean,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDateGroupEntity"]: {
		id?: string
};
	["InputDateEntity"]: {
		id?: string,
	dateGroupId?: string,
	description?: string,
	stackId?: number,
	iconId?: string,
	dateFormat?: string,
	colorCode?: string,
	orderId?: number,
	visibleOnDatePassed?: boolean,
	isOptional?: boolean,
	visibleOnConstructionPlanning?: boolean,
	phaseSectionMarkId?: number,
	notificationTemplateId?: number,
	drawOrderTypeId?: string,
	isSynced?: boolean,
	hash?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDateEntity"]: {
		id?: string
};
	["InputDateValueEntity"]: {
		id?: string,
	dateId?: string,
	projectId?: string,
	userId?: string,
	value?: GraphQLTypes["JSON"],
	isActivated?: boolean,
	isCompleted?: boolean,
	isSynced?: boolean,
	syncSource?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	isSyncUpdate?: boolean,
	send_notification?: boolean
};
	["InputDeleteDateValueEntity"]: {
		id?: string
};
	["InputLogicFieldValueEntity"]: {
		id?: string,
	fieldId?: string,
	projectId?: string,
	value?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"],
	isSyncUpdate?: boolean
};
	["InputDeleteLogicFieldValueEntity"]: {
		id?: string
};
	["InputDateCacheEntity"]: {
		id?: string,
	projectId?: string,
	values?: GraphQLTypes["JSON"],
	comments?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDateCacheEntity"]: {
		id?: string
};
	["InputYearPlanningModificationEntity"]: {
		id?: string,
	userId?: string,
	projectId?: string,
	oldWeek?: string,
	newWeek?: string,
	notes?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteYearPlanningModificationEntity"]: {
		id?: string
};
	["InputProjectCachedValueEntity"]: {
		id?: string,
	projectId?: string,
	dateValues?: GraphQLTypes["JSON"],
	projectLeader?: GraphQLTypes["JSON"],
	salesEmployee?: GraphQLTypes["JSON"],
	dateGroupResponsibles?: GraphQLTypes["JSON"],
	buyAdvisor?: GraphQLTypes["JSON"],
	projectMentor?: GraphQLTypes["JSON"],
	dateGroupComments?: GraphQLTypes["JSON"],
	logicFieldValues?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectCachedValueEntity"]: {
		id?: string
};
	["InputTableLayoutCommentEntity"]: {
		id?: string,
	taskId?: string,
	projectId?: string,
	userId?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteTableLayoutCommentEntity"]: {
		id?: string
};
	["InputProjectEntity"]: {
		id?: string,
	description?: string,
	userId?: string,
	region?: string,
	regionId?: string,
	houseType?: string,
	houseShape?: string,
	condition?: string,
	buildingWeek?: string,
	buildingType?: string,
	buildingStreet?: string,
	buildingHouseNumber?: string,
	buildingCity?: string,
	buildingZipCode?: string,
	deliveryDate?: string,
	longitude?: string,
	latitude?: string,
	concrete?: string,
	customerEmail?: string,
	customerName?: string,
	foundation?: string,
	heating?: string,
	constructionProbeWork?: string,
	constructionAdvice?: string,
	constructionDrawingWork?: string,
	constructionSupplier?: string,
	constructionLevel?: string,
	constructionUnavailable?: string,
	score?: number,
	topicalDocumentRoot?: string,
	archiveDocumentRoot?: string,
	administrationType?: string,
	projectLeaderId?: string,
	salesEmployeeId?: string,
	buyAdvisorId?: string,
	projectMentorId?: string,
	installationReportDate?: string,
	installationPerformerName?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectEntity"]: {
		id?: string
};
	["InputSyncCronEntity"]: {
		id?: string,
	cron?: string
};
	["InputDeleteSyncCronEntity"]: {
		id?: string
};
	["InputMailSchedulerEntity"]: {
		id?: string,
	description?: string,
	cron?: string,
	sourceType?: string,
	sourceTable?: string,
	condition?: string,
	mail?: string,
	mailCC?: string,
	subject?: string,
	template?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteMailSchedulerEntity"]: {
		id?: string
};
	["InputProjectPartnerEntity"]: {
		id?: string,
	projectId?: string,
	partnerId?: string,
	mode?: string,
	isContactAllowed?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectPartnerEntity"]: {
		id?: string
};
	["InputPartnerEntity"]: {
		id?: string,
	name?: string,
	contentId?: number,
	contactUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePartnerEntity"]: {
		id?: string
};
	["InputDocumentMetaEntity"]: {
		id?: string,
	description?: string,
	mimeType?: string,
	projectId?: string,
	trackingId?: string,
	userId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDocumentMetaEntity"]: {
		id?: string
};
	["InputEntityAdvancementEntity"]: {
		id?: string,
	entityType?: string,
	entityId?: string,
	userId?: string,
	comment?: string,
	date?: GraphQLTypes["Date"],
	isCompleted?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteEntityAdvancementEntity"]: {
		id?: string
};
	["InputWorkActionCategoryEntity"]: {
		id?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteWorkActionCategoryEntity"]: {
		id?: string
};
	["InputWorkActionEntity"]: {
		title?: string,
	description?: string,
	allowEmptyAdvancement?: boolean,
	disableImageUpload?: boolean,
	responsibleDateGroupId?: string,
	advancementApplyDateId?: string,
	id?: string,
	entityType?: string,
	userId?: string,
	projectId?: string,
	workActionCategoryId?: string,
	assignedUserId?: string,
	date?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteWorkActionEntity"]: {
		id?: string
};
	["InputEntityImageEntity"]: {
		id?: string,
	entityType?: string,
	entityId?: string,
	userId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteEntityImageEntity"]: {
		id?: string
};
	["InputCustomerQuestionEntity"]: {
		id?: string,
	message?: string,
	kind?: string,
	projectId?: string,
	customerName?: string,
	city?: string,
	telephone?: string,
	space?: string,
	emailAddress?: string,
	internalComment?: string,
	imageIds?: GraphQLTypes["JSON"],
	completedAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCustomerQuestionEntity"]: {
		id?: string
};
	["InputAuditCategoryEntity"]: {
		id?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditCategoryEntity"]: {
		id?: string
};
	["InputAuditQuestionEntity"]: {
		id?: string,
	auditCategoryId?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditQuestionEntity"]: {
		id?: string
};
	["InputAuditQuestionCostCategoryEntity"]: {
		id?: string,
	auditQuestionId?: string,
	costCategoryId?: string
};
	["InputDeleteAuditQuestionCostCategoryEntity"]: {
		id?: string
};
	["InputCostCategoryEntity"]: {
		id?: string,
	description?: string,
	group?: string,
	condition?: string,
	isChecklist?: string,
	hasImages?: string,
	checklistOrder?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteCostCategoryEntity"]: {
		id?: string
};
	["InputAuditReportItemImageEntity"]: {
		id?: string,
	auditReportItemId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditReportItemImageEntity"]: {
		id?: string
};
	["InputAuditReportItemEntity"]: {
		id?: string,
	userId?: string,
	auditReportId?: string,
	auditQuestionId?: string,
	state?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditReportItemEntity"]: {
		id?: string
};
	["InputAuditReportEntity"]: {
		id?: string,
	userId?: string,
	projectId?: string,
	costCategoryId?: string,
	signatureDocumentMetaId?: string,
	comment?: string,
	inspectedAt?: GraphQLTypes["Date"],
	inspectedAtTime?: string,
	lockedAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditReportEntity"]: {
		id?: string
};
	["InputAuditChecklistImageEntity"]: {
		id?: string,
	userId?: string,
	costCategoryId?: string,
	projectId?: string,
	documentMetaId?: string,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteAuditChecklistImageEntity"]: {
		id?: string
};
	["InputInvoiceAttachmentEntity"]: {
		id?: string,
	metacomOrderId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInvoiceAttachmentEntity"]: {
		id?: string
};
	["InputEmployeeDocumentEntity"]: {
		id?: string,
	userId?: string,
	relationId?: string,
	employeeId?: string,
	documentMetaId?: string,
	isInternal?: boolean,
	comment?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteEmployeeDocumentEntity"]: {
		id?: string
};
	["InputWorkActionTemplateGroupEntity"]: {
		id?: string,
	name?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteWorkActionTemplateGroupEntity"]: {
		id?: string
};
	["InputWorkActionTemplateEntity"]: {
		title?: string,
	description?: string,
	allowEmptyAdvancement?: boolean,
	disableImageUpload?: boolean,
	responsibleDateGroupId?: string,
	advancementApplyDateId?: string,
	id?: string,
	workActionTemplateGroupId?: string,
	workActionCategoryId?: string,
	targetDateOffset?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteWorkActionTemplateEntity"]: {
		id?: string
};
	["InputProjectCostInfoEntity"]: {
		id?: number,
	appProjectCostId?: number,
	conceptUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectCostInfoEntity"]: {
		id?: number
};
	["InputBlockedCalendarWeekEntity"]: {
		id?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteBlockedCalendarWeekEntity"]: {
		id?: string
};
	["InputMaintenanceReportEntity"]: {
		id?: string,
	projectId?: string,
	data?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteMaintenanceReportEntity"]: {
		id?: string
};
	["InputConfigurationEntity"]: {
		id?: string,
	data?: GraphQLTypes["JSON"]
};
	["InputDeleteConfigurationEntity"]: {
		id?: string
};
	["InputServiceMeterEntity"]: {
		id?: string,
	description?: string,
	unit?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServiceMeterEntity"]: {
		id?: string
};
	["InputDeliveryListMeterEntity"]: {
		id?: string,
	projectId?: string,
	meterId?: string,
	value?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDeliveryListMeterEntity"]: {
		id?: string
};
	["InputServiceSpaceEntity"]: {
		id?: string,
	code?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServiceSpaceEntity"]: {
		id?: string
};
	["InputServiceTypeEntity"]: {
		id?: string,
	code?: string,
	description?: string,
	storeInSystem?: boolean,
	isStandard?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServiceTypeEntity"]: {
		id?: string
};
	["InputDeliveryListPointImageEntity"]: {
		id?: string,
	pointId?: string,
	documentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDeliveryListPointImageEntity"]: {
		id?: string
};
	["InputServicePriorityEntity"]: {
		id?: string,
	description?: string,
	offsetDays?: number,
	isStandard?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServicePriorityEntity"]: {
		id?: string
};
	["InputDeliveryListPointEntity"]: {
		id?: string,
	code?: string,
	projectId?: string,
	primaryCategoryId?: number,
	secondaryCategoryId?: number,
	serviceTypeId?: string,
	serviceSpaceId?: string,
	serviceSpaceIds?: GraphQLTypes["JSON"],
	servicePriorityId?: string,
	assignedUserId?: string,
	serviceTicketId?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDeliveryListPointEntity"]: {
		id?: string
};
	["InputDeliveryListSignatureEntity"]: {
		id?: string,
	projectId?: string,
	kind?: string,
	documentMetaId?: string,
	signDate?: GraphQLTypes["Date"],
	signLocation?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDeliveryListSignatureEntity"]: {
		id?: string
};
	["InputDeliveryListEntity"]: {
		id?: string,
	projectId?: string,
	assignedUserId?: string,
	hiredAgent?: string,
	keyAmount?: number,
	isDeliveredAt?: GraphQLTypes["Date"],
	houseDocumentMetaId?: string,
	isFinal?: boolean,
	isFinalAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDeliveryListEntity"]: {
		id?: string
};
	["InputSupplyInspectionReportAnswerEntity"]: {
		id?: string,
	supplyInspectionQuestionId?: string,
	supplyInspectionReportId?: string,
	status?: string,
	explanation?: string,
	imageDocumentMetaIds?: GraphQLTypes["JSON"]
};
	["InputDeleteSupplyInspectionReportAnswerEntity"]: {
		id?: string
};
	["InputSupplyInspectionReportEntity"]: {
		id?: string,
	timestamp?: GraphQLTypes["Date"],
	departmentId?: string,
	executedByUserId?: string,
	signatureDocumentMetaId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteSupplyInspectionReportEntity"]: {
		id?: string
};
	["InputDepartmentEntity"]: {
		id?: string,
	departmentId?: string,
	description?: string,
	state?: string,
	isOrderDepartment?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDepartmentEntity"]: {
		id?: string
};
	["InputDrawOrderDescriptionEntity"]: {
		id?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteDrawOrderDescriptionEntity"]: {
		id?: string
};
	["InputGreetingEntity"]: {
		id?: string,
	html?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteGreetingEntity"]: {
		id?: string
};
	["InputHourDeclarationTypeEntity"]: {
		id?: string,
	companyId?: string,
	administrationType?: string,
	kind?: string,
	kindDescription?: string,
	description?: string,
	hasActivities?: string,
	isExternal?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHourDeclarationTypeEntity"]: {
		id?: string
};
	["InputHourDeclarationActivityEntity"]: {
		id?: string,
	companyId?: string,
	administrationType?: string,
	description?: string,
	syncVersion?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHourDeclarationActivityEntity"]: {
		id?: string
};
	["InputHourDeclarationEntity"]: {
		id?: string,
	userId?: string,
	hourDeclarationTypeId?: string,
	projectId?: string,
	hourDeclarationActivityId?: string,
	priceAgreementId?: string,
	storedDocumentId?: string,
	date?: GraphQLTypes["Date"],
	description?: string,
	amount?: string,
	baseHours?: string,
	savingHours?: string,
	overTimeHours?: string,
	travelHours?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteHourDeclarationEntity"]: {
		id?: string
};
	["InputHourDeclarationApprovalRequestEntity"]: {
		id?: string,
	userId?: string,
	period?: string,
	createdAt?: GraphQLTypes["Date"]
};
	["InputDeleteHourDeclarationApprovalRequestEntity"]: {
		id?: string
};
	["InputInstallationReportCommentEntity"]: {
		id?: string,
	projectId?: string,
	stepId?: string,
	userId?: string,
	message?: string,
	date?: string,
	performerName?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportCommentEntity"]: {
		id?: string
};
	["InputInstallationReportDescriptionEntity"]: {
		id?: string,
	stepId?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportDescriptionEntity"]: {
		id?: string
};
	["InputInstallationReportFinalEntity"]: {
		id?: string,
	projectId?: string,
	userId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportFinalEntity"]: {
		id?: string
};
	["InputInstallationReportMeasurementTemplateEntity"]: {
		id?: string,
	measureTableId?: string,
	measureId?: string,
	defaultFields?: GraphQLTypes["JSON"],
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportMeasurementTemplateEntity"]: {
		id?: string
};
	["InputInstallationReportMeasurementEntity"]: {
		id?: string,
	projectId?: string,
	measureTableId?: string,
	measureId?: string,
	fields?: GraphQLTypes["JSON"],
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportMeasurementEntity"]: {
		id?: string
};
	["InputInstallationReportQuestionGroupEntity"]: {
		id?: string,
	name?: string,
	stepId?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportQuestionGroupEntity"]: {
		id?: string
};
	["InputInstallationReportQuestionEntity"]: {
		id?: string,
	questionGroupId?: string,
	content?: string,
	tooltip?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportQuestionEntity"]: {
		id?: string
};
	["InputInstallationReportQuestionAnswerEntity"]: {
		id?: string,
	projectId?: string,
	questionId?: string,
	content?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInstallationReportQuestionAnswerEntity"]: {
		id?: string
};
	["InputLegacyProjectEntity"]: {
		id?: string,
	description?: string,
	houseType?: string,
	regionId?: string,
	buildingStreet?: string,
	buildingCity?: string,
	buildingZipCode?: string,
	deliveryDate?: string,
	customerEmail?: string,
	syncVersion?: string,
	syncSource?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteLegacyProjectEntity"]: {
		id?: string
};
	["InputPicklistCommentEntity"]: {
		id?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	userId?: string,
	text?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePicklistCommentEntity"]: {
		id?: string
};
	["InputPicklistElementStateEntity"]: {
		id?: string,
	elementId?: string,
	projectId?: string,
	picklistDefinitionId?: string,
	state?: string,
	pickedByUserId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePicklistElementStateEntity"]: {
		id?: string
};
	["InputPlanningProjectItemEntity"]: {
		id?: string,
	parentId?: string,
	dimension?: string,
	projectId?: string,
	competenceId?: string,
	competenceIndex?: number,
	competenceNumber?: string,
	competenceStartsAt?: GraphQLTypes["Date"],
	competenceEndsAt?: GraphQLTypes["Date"],
	hours?: string,
	humanResourceId?: string,
	humanResourceNumber?: string,
	humanResourceStartsAt?: GraphQLTypes["Date"],
	humanResourceEndsAt?: GraphQLTypes["Date"],
	comments?: string,
	status?: string,
	version?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeletePlanningProjectItemEntity"]: {
		id?: string
};
	["InputProjectCargoStateEntity"]: {
		id?: string,
	projectId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectCargoStateEntity"]: {
		id?: string
};
	["InputProjectPaymentEntity"]: {
		id?: string,
	projectId?: string,
	userId?: string,
	description?: string,
	payedAt?: GraphQLTypes["Date"],
	amount?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteProjectPaymentEntity"]: {
		id?: string
};
	["InputQualityAssuranceEntity"]: {
		id?: string,
	projectId?: string,
	data?: GraphQLTypes["JSON"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteQualityAssuranceEntity"]: {
		id?: string
};
	["InputServiceMapColorEntity"]: {
		id?: string,
	days?: number,
	color?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServiceMapColorEntity"]: {
		id?: string
};
	["InputServicePlanningStageEntity"]: {
		id?: string,
	name?: string,
	colorCode?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServicePlanningStageEntity"]: {
		id?: string
};
	["InputServicePlanningEventEntity"]: {
		id?: string,
	legacyProjectId?: string,
	stageId?: string,
	userId?: string,
	serviceTicketIds?: GraphQLTypes["JSON"],
	memo?: string,
	duration?: number,
	durationUnit?: string,
	durationPlanned?: number,
	durationSpend?: number,
	isFinalized?: boolean,
	startDate?: GraphQLTypes["Date"],
	endDate?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServicePlanningEventEntity"]: {
		id?: string
};
	["InputServicePlanningAssignmentEntity"]: {
		id?: string,
	eventId?: string,
	resourceId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServicePlanningAssignmentEntity"]: {
		id?: string
};
	["InputServicePresetEntity"]: {
		id?: string,
	primaryCategoryId?: number,
	secondaryCategoryId?: number,
	code?: string,
	description?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteServicePresetEntity"]: {
		id?: string
};
	["InputSupplierDocumentEntity"]: {
		id?: string,
	userId?: string,
	informedUserId?: string,
	informedContactTypes?: GraphQLTypes["JSON"],
	projectId?: string,
	documentMetaId?: string,
	contextId?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteSupplierDocumentEntity"]: {
		id?: string
};
	["InputSupplyInspectionQuestionEntity"]: {
		id?: string,
	supplyInspectionQuestionGroupId?: string,
	departmentIds?: GraphQLTypes["JSON"],
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteSupplyInspectionQuestionEntity"]: {
		id?: string
};
	["InputSupplyInspectionQuestionGroupEntity"]: {
		id?: string,
	description?: string,
	orderId?: number,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteSupplyInspectionQuestionGroupEntity"]: {
		id?: string
};
	["InputUserPasswordResetEntity"]: {
		id?: string,
	userId?: string,
	expiresAt?: GraphQLTypes["Date"],
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteUserPasswordResetEntity"]: {
		id?: string
};
	["InputYearPlanningLineEntity"]: {
		id?: string,
	description?: string,
	weeks?: number,
	colorCode?: string,
	visibleOnConstructionPlanning?: boolean,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteYearPlanningLineEntity"]: {
		id?: string
};
	["InputInvoicePaymentBatchEntityEntity"]: {
		id?: string,
	batch?: number,
	document?: string,
	name?: string,
	date?: GraphQLTypes["Date"],
	amount?: number,
	amountBank?: number,
	companyId?: string,
	companyName?: string,
	relationId?: string,
	relationName?: string,
	createdAt?: GraphQLTypes["Date"],
	updatedAt?: GraphQLTypes["Date"]
};
	["InputDeleteInvoicePaymentBatchEntityEntity"]: {
		id?: string
};
	["InputAppPhaseContactEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_id?: number,
	type?: string,
	title?: string,
	receive_notifications?: boolean
};
	["InputDeleteAppPhaseContactEntity"]: {
		id?: number
};
	["InputAppPhaseEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	name?: string,
	order?: number,
	percentage?: number,
	is_visible_transferred?: boolean,
	start_project_date_type?: string,
	end_project_date_type?: string,
	days?: number
};
	["InputDeleteAppPhaseEntity"]: {
		id?: number
};
	["InputAppPhaseSectionEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_id?: number,
	more_info_content_id?: number,
	name?: string,
	order?: number,
	percentage?: number
};
	["InputDeleteAppPhaseSectionEntity"]: {
		id?: number
};
	["InputAppNotificationTemplateEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	content_id?: number,
	subject?: string,
	body?: string
};
	["InputDeleteAppNotificationTemplateEntity"]: {
		id?: number
};
	["InputAppPhaseSectionMarkEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_section_id?: number,
	document_type_id?: number,
	more_info_content_id?: number,
	notification_template_id?: number,
	project_field_group_id?: number,
	project_date_type?: string,
	responsible?: string,
	name?: string,
	order?: number,
	percentage?: number,
	is_milestone?: boolean,
	is_standard?: boolean,
	date_prompt?: boolean,
	duration_days?: number,
	timeline_origin_date_type?: string,
	timeline_competence_number?: string,
	timeline_origin_diff_days?: number
};
	["InputDeleteAppPhaseSectionMarkEntity"]: {
		id?: number
};
	["InputAppProjectDateEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	value?: string,
	type?: string
};
	["InputDeleteAppProjectDateEntity"]: {
		id?: number
};
	["InputAppNotificationScheduleEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	phase_section_mark_id?: number,
	phase_section_mark_cancel_id?: number,
	notification_template_id?: number,
	days_offset?: number,
	time_of_day?: string
};
	["InputDeleteAppNotificationScheduleEntity"]: {
		id?: number
};
	["InputAppPhaseSectionMarkValueEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	phase_section_mark_id?: number,
	completed_user_id?: number,
	send_notification?: boolean,
	activated?: boolean,
	completed?: boolean,
	completed_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppPhaseSectionMarkValueEntity"]: {
		id?: number
};
	["InputAppInviteEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	project_id?: string,
	email?: string
};
	["InputDeleteAppInviteEntity"]: {
		id?: string
};
	["InputAppLoginEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	relation_id?: string,
	project_id?: string,
	transferred_at?: GraphQLTypes["Date"],
	email?: string,
	role_id?: string
};
	["InputDeleteAppLoginEntity"]: {
		id?: number
};
	["InputAppNotificationEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	type?: string,
	notifiable_id?: number,
	notifiable_type?: string,
	data?: GraphQLTypes["JSON"],
	read_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppNotificationEntity"]: {
		id?: string
};
	["InputAppProjectEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	contract_sum?: string
};
	["InputDeleteAppProjectEntity"]: {
		id?: number
};
	["InputAppUserEntity"]: {
		id?: number,
	project_id?: number,
	relation_id?: string,
	email?: string,
	telephone?: string,
	facebook_identity?: string,
	google_identity?: string,
	password?: string,
	first_name?: string,
	last_name_prefix?: string,
	last_name?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppUserEntity"]: {
		id?: number
};
	["InputAppProjectCostEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	user_id?: number,
	approved_user_id?: number,
	declined_user_id?: number,
	project_id?: number,
	parent_id?: number,
	title?: string,
	memo?: string,
	value?: string,
	approved_at?: GraphQLTypes["Date"],
	declined_at?: GraphQLTypes["Date"],
	requested_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppProjectCostEntity"]: {
		id?: number
};
	["InputAppProjectContactEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	relation_id?: string,
	type?: string
};
	["InputDeleteAppProjectContactEntity"]: {
		id?: number
};
	["InputAppBuildingWeekEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	isBlocked?: boolean,
	syncVersion?: string
};
	["InputDeleteAppBuildingWeekEntity"]: {
		id?: string
};
	["InputAppContentEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	html?: string
};
	["InputDeleteAppContentEntity"]: {
		id?: number
};
	["InputAppConversationMessagePresetEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	message?: string
};
	["InputDeleteAppConversationMessagePresetEntity"]: {
		id?: number
};
	["InputAppDocumentSignatureEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	user_id?: number,
	document_id?: string,
	payload?: GraphQLTypes["JSON"]
};
	["InputDeleteAppDocumentSignatureEntity"]: {
		id?: number
};
	["InputAppDocumentTypeEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	name?: string,
	description?: string,
	color?: string,
	document_kind?: string,
	order_id?: number,
	special_access?: boolean,
	project_contact_type?: string
};
	["InputDeleteAppDocumentTypeEntity"]: {
		id?: number
};
	["InputAppDocumentEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: string,
	project_id?: string,
	user_id?: number,
	document_type_id?: number,
	document_kind?: string,
	description?: string,
	confidential?: boolean,
	mime_type?: string,
	extension?: string,
	storage_id?: string
};
	["InputDeleteAppDocumentEntity"]: {
		id?: string
};
	["InputAppNotificationScheduleResourceEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_id?: string,
	notification_schedule_id?: number,
	schedule_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppNotificationScheduleResourceEntity"]: {
		id?: number
};
	["InputAppPlanCompetenceEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	number?: string,
	description?: string,
	index?: number,
	color?: string
};
	["InputDeleteAppPlanCompetenceEntity"]: {
		id?: number
};
	["InputAppPlanCompetenceSettingEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	plan_competence_id?: number,
	content_id?: number,
	is_visible?: boolean,
	name?: string
};
	["InputDeleteAppPlanCompetenceSettingEntity"]: {
		id?: number
};
	["InputAppProjectCostPresetEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	title?: string,
	description?: string
};
	["InputDeleteAppProjectCostPresetEntity"]: {
		id?: number
};
	["InputAppProjectFieldSheetEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_contact_type?: string,
	name?: string,
	order?: number
};
	["InputDeleteAppProjectFieldSheetEntity"]: {
		id?: number
};
	["InputAppProjectFieldGroupEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_sheet_id?: number,
	name?: string,
	order?: number
};
	["InputDeleteAppProjectFieldGroupEntity"]: {
		id?: number
};
	["InputAppProjectFieldGroupAssignmentEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_group_id?: number,
	project_field_type?: string,
	project_field_copy_type?: string,
	value_type?: string,
	is_required?: boolean,
	order?: number,
	tooltip?: string
};
	["InputDeleteAppProjectFieldGroupAssignmentEntity"]: {
		id?: number
};
	["InputAppProjectFieldGroupAssignmentValueEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	project_field_group_assignment_id?: number,
	value?: string
};
	["InputDeleteAppProjectFieldGroupAssignmentValueEntity"]: {
		id?: number
};
	["InputAppProjectFieldEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	value?: string,
	project_id?: string,
	type?: string
};
	["InputDeleteAppProjectFieldEntity"]: {
		id?: number
};
	["InputAppServiceCategoryEntity"]: {
		created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"],
	id?: number,
	service_category_id?: number,
	name?: string
};
	["InputDeleteAppServiceCategoryEntity"]: {
		id?: number
};
	["InputAppTextEntity"]: {
		id?: number,
	value?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppTextEntity"]: {
		id?: number
};
	["InputAppRelationEntity"]: {
		id?: number,
	name?: string,
	email?: string,
	is_blacklisted?: string,
	sync_version?: string,
	created_at?: GraphQLTypes["Date"],
	updated_at?: GraphQLTypes["Date"]
};
	["InputDeleteAppRelationEntity"]: {
		id?: number
};
	/** The `Upload` scalar type represents a file upload. */
["Upload"]:any;
	["CellaCustomField"]: {
		Origin: string,
	Entity: string,
	LineId: number,
	Code: string,
	SerialNumber?: number,
	Contents: string
};
	["Subscription"]: {
	__typename: "Subscription",
	GrantEntityOnCreatedOne?: GraphQLTypes["GrantEntity"],
	GrantEntityOnUpdatedOne?: GraphQLTypes["GrantEntity"],
	GrantEntityOnDeletedOne?: GraphQLTypes["GrantEntity"],
	RoleGrantConfigEntityOnCreatedOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnUpdatedOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleGrantConfigEntityOnDeletedOne?: GraphQLTypes["RoleGrantConfigEntity"],
	RoleDocumentGrantEntityOnCreatedOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnUpdatedOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleDocumentGrantEntityOnDeletedOne?: GraphQLTypes["RoleDocumentGrantEntity"],
	RoleEntityOnCreatedOne?: GraphQLTypes["RoleEntity"],
	RoleEntityOnUpdatedOne?: GraphQLTypes["RoleEntity"],
	RoleEntityOnDeletedOne?: GraphQLTypes["RoleEntity"],
	UserGrantConfigEntityOnCreatedOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnUpdatedOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserGrantConfigEntityOnDeletedOne?: GraphQLTypes["UserGrantConfigEntity"],
	UserNotificationSubscriptionEntityOnCreatedOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnUpdatedOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationSubscriptionEntityOnDeletedOne?: GraphQLTypes["UserNotificationSubscriptionEntity"],
	UserNotificationEntityOnCreatedOne?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityOnUpdatedOne?: GraphQLTypes["UserNotificationEntity"],
	UserNotificationEntityOnDeletedOne?: GraphQLTypes["UserNotificationEntity"],
	UserTemporaryAccessTokenEntityEntityOnCreatedOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnUpdatedOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserTemporaryAccessTokenEntityEntityOnDeletedOne?: GraphQLTypes["UserTemporaryAccessTokenEntityEntity"],
	UserEntityOnCreatedOne?: GraphQLTypes["UserEntity"],
	UserEntityOnUpdatedOne?: GraphQLTypes["UserEntity"],
	UserEntityOnDeletedOne?: GraphQLTypes["UserEntity"],
	PicklistDefinitionEntityOnCreatedOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnUpdatedOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistDefinitionEntityOnDeletedOne?: GraphQLTypes["PicklistDefinitionEntity"],
	PicklistStateEntityOnCreatedOne?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityOnUpdatedOne?: GraphQLTypes["PicklistStateEntity"],
	PicklistStateEntityOnDeletedOne?: GraphQLTypes["PicklistStateEntity"],
	HousePartGroupEntityOnCreatedOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnUpdatedOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartGroupEntityOnDeletedOne?: GraphQLTypes["HousePartGroupEntity"],
	HousePartEntityOnCreatedOne?: GraphQLTypes["HousePartEntity"],
	HousePartEntityOnUpdatedOne?: GraphQLTypes["HousePartEntity"],
	HousePartEntityOnDeletedOne?: GraphQLTypes["HousePartEntity"],
	CargoPhaseEntityOnCreatedOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnUpdatedOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoPhaseEntityOnDeletedOne?: GraphQLTypes["CargoPhaseEntity"],
	CargoTypeEntityOnCreatedOne?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityOnUpdatedOne?: GraphQLTypes["CargoTypeEntity"],
	CargoTypeEntityOnDeletedOne?: GraphQLTypes["CargoTypeEntity"],
	PlanningHumanResourceEntityOnCreatedOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnUpdatedOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningHumanResourceEntityOnDeletedOne?: GraphQLTypes["PlanningHumanResourceEntity"],
	PlanningProjectAssignmentEntityOnCreatedOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectAssignmentEntityOnDeletedOne?: GraphQLTypes["PlanningProjectAssignmentEntity"],
	PlanningProjectCompetencePlacementEntityOnCreatedOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectCompetencePlacementEntityOnDeletedOne?: GraphQLTypes["PlanningProjectCompetencePlacementEntity"],
	PlanningProjectRequestPlacementEntityOnCreatedOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectRequestPlacementEntityOnDeletedOne?: GraphQLTypes["PlanningProjectRequestPlacementEntity"],
	PlanningProjectEntityOnCreatedOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectEntityOnDeletedOne?: GraphQLTypes["PlanningProjectEntity"],
	PlanningProjectRequestEntityOnCreatedOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectRequestEntityOnDeletedOne?: GraphQLTypes["PlanningProjectRequestEntity"],
	PlanningProjectCompetenceEntityOnCreatedOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningProjectCompetenceEntityOnDeletedOne?: GraphQLTypes["PlanningProjectCompetenceEntity"],
	PlanningCompetenceEntityOnCreatedOne?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnUpdatedOne?: GraphQLTypes["PlanningCompetenceEntity"],
	PlanningCompetenceEntityOnDeletedOne?: GraphQLTypes["PlanningCompetenceEntity"],
	CargoStateEntityOnCreatedOne?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityOnUpdatedOne?: GraphQLTypes["CargoStateEntity"],
	CargoStateEntityOnDeletedOne?: GraphQLTypes["CargoStateEntity"],
	CargoEntityOnCreatedOne?: GraphQLTypes["CargoEntity"],
	CargoEntityOnUpdatedOne?: GraphQLTypes["CargoEntity"],
	CargoEntityOnDeletedOne?: GraphQLTypes["CargoEntity"],
	ProjectHousePartEntityOnCreatedOne?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnUpdatedOne?: GraphQLTypes["ProjectHousePartEntity"],
	ProjectHousePartEntityOnDeletedOne?: GraphQLTypes["ProjectHousePartEntity"],
	PicklistElementEntityOnCreatedOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityOnUpdatedOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistElementEntityOnDeletedOne?: GraphQLTypes["PicklistElementEntity"],
	PicklistEntityOnCreatedOne?: GraphQLTypes["PicklistEntity"],
	PicklistEntityOnUpdatedOne?: GraphQLTypes["PicklistEntity"],
	PicklistEntityOnDeletedOne?: GraphQLTypes["PicklistEntity"],
	DrawOrderTypeEntityOnCreatedOne?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnUpdatedOne?: GraphQLTypes["DrawOrderTypeEntity"],
	DrawOrderTypeEntityOnDeletedOne?: GraphQLTypes["DrawOrderTypeEntity"],
	HouseModelEntityOnCreatedOne?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityOnUpdatedOne?: GraphQLTypes["HouseModelEntity"],
	HouseModelEntityOnDeletedOne?: GraphQLTypes["HouseModelEntity"],
	SalesEmployeeEntityOnCreatedOne?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnUpdatedOne?: GraphQLTypes["SalesEmployeeEntity"],
	SalesEmployeeEntityOnDeletedOne?: GraphQLTypes["SalesEmployeeEntity"],
	DrawerAbsentEntityOnCreatedOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnUpdatedOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerAbsentEntityOnDeletedOne?: GraphQLTypes["DrawerAbsentEntity"],
	DrawerEntityOnCreatedOne?: GraphQLTypes["DrawerEntity"],
	DrawerEntityOnUpdatedOne?: GraphQLTypes["DrawerEntity"],
	DrawerEntityOnDeletedOne?: GraphQLTypes["DrawerEntity"],
	DrawOrderEntityOnCreatedOne?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityOnUpdatedOne?: GraphQLTypes["DrawOrderEntity"],
	DrawOrderEntityOnDeletedOne?: GraphQLTypes["DrawOrderEntity"],
	DateGroupCommentEntityOnCreatedOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnUpdatedOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupCommentEntityOnDeletedOne?: GraphQLTypes["DateGroupCommentEntity"],
	DateGroupResponsibleEntityOnCreatedOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnUpdatedOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	DateGroupResponsibleEntityOnDeletedOne?: GraphQLTypes["DateGroupResponsibleEntity"],
	LogicFieldEntityOnCreatedOne?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityOnUpdatedOne?: GraphQLTypes["LogicFieldEntity"],
	LogicFieldEntityOnDeletedOne?: GraphQLTypes["LogicFieldEntity"],
	DateGroupEntityOnCreatedOne?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityOnUpdatedOne?: GraphQLTypes["DateGroupEntity"],
	DateGroupEntityOnDeletedOne?: GraphQLTypes["DateGroupEntity"],
	DateEntityOnCreatedOne?: GraphQLTypes["DateEntity"],
	DateEntityOnUpdatedOne?: GraphQLTypes["DateEntity"],
	DateEntityOnDeletedOne?: GraphQLTypes["DateEntity"],
	DateValueEntityOnCreatedOne?: GraphQLTypes["DateValueEntity"],
	DateValueEntityOnUpdatedOne?: GraphQLTypes["DateValueEntity"],
	DateValueEntityOnDeletedOne?: GraphQLTypes["DateValueEntity"],
	LogicFieldValueEntityOnCreatedOne?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnUpdatedOne?: GraphQLTypes["LogicFieldValueEntity"],
	LogicFieldValueEntityOnDeletedOne?: GraphQLTypes["LogicFieldValueEntity"],
	DateCacheEntityOnCreatedOne?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityOnUpdatedOne?: GraphQLTypes["DateCacheEntity"],
	DateCacheEntityOnDeletedOne?: GraphQLTypes["DateCacheEntity"],
	YearPlanningModificationEntityOnCreatedOne?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnUpdatedOne?: GraphQLTypes["YearPlanningModificationEntity"],
	YearPlanningModificationEntityOnDeletedOne?: GraphQLTypes["YearPlanningModificationEntity"],
	ProjectCachedValueEntityOnCreatedOne?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnUpdatedOne?: GraphQLTypes["ProjectCachedValueEntity"],
	ProjectCachedValueEntityOnDeletedOne?: GraphQLTypes["ProjectCachedValueEntity"],
	TableLayoutCommentEntityOnCreatedOne?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnUpdatedOne?: GraphQLTypes["TableLayoutCommentEntity"],
	TableLayoutCommentEntityOnDeletedOne?: GraphQLTypes["TableLayoutCommentEntity"],
	ProjectEntityOnCreatedOne?: GraphQLTypes["ProjectEntity"],
	ProjectEntityOnUpdatedOne?: GraphQLTypes["ProjectEntity"],
	ProjectEntityOnDeletedOne?: GraphQLTypes["ProjectEntity"],
	SyncCronEntityOnCreatedOne?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityOnUpdatedOne?: GraphQLTypes["SyncCronEntity"],
	SyncCronEntityOnDeletedOne?: GraphQLTypes["SyncCronEntity"],
	MailSchedulerEntityOnCreatedOne?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnUpdatedOne?: GraphQLTypes["MailSchedulerEntity"],
	MailSchedulerEntityOnDeletedOne?: GraphQLTypes["MailSchedulerEntity"],
	ProjectPartnerEntityOnCreatedOne?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnUpdatedOne?: GraphQLTypes["ProjectPartnerEntity"],
	ProjectPartnerEntityOnDeletedOne?: GraphQLTypes["ProjectPartnerEntity"],
	PartnerEntityOnCreatedOne?: GraphQLTypes["PartnerEntity"],
	PartnerEntityOnUpdatedOne?: GraphQLTypes["PartnerEntity"],
	PartnerEntityOnDeletedOne?: GraphQLTypes["PartnerEntity"],
	DocumentMetaEntityOnCreatedOne?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnUpdatedOne?: GraphQLTypes["DocumentMetaEntity"],
	DocumentMetaEntityOnDeletedOne?: GraphQLTypes["DocumentMetaEntity"],
	EntityAdvancementEntityOnCreatedOne?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnUpdatedOne?: GraphQLTypes["EntityAdvancementEntity"],
	EntityAdvancementEntityOnDeletedOne?: GraphQLTypes["EntityAdvancementEntity"],
	WorkActionCategoryEntityOnCreatedOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnUpdatedOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionCategoryEntityOnDeletedOne?: GraphQLTypes["WorkActionCategoryEntity"],
	WorkActionEntityOnCreatedOne?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityOnUpdatedOne?: GraphQLTypes["WorkActionEntity"],
	WorkActionEntityOnDeletedOne?: GraphQLTypes["WorkActionEntity"],
	EntityImageEntityOnCreatedOne?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityOnUpdatedOne?: GraphQLTypes["EntityImageEntity"],
	EntityImageEntityOnDeletedOne?: GraphQLTypes["EntityImageEntity"],
	CustomerQuestionEntityOnCreatedOne?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnUpdatedOne?: GraphQLTypes["CustomerQuestionEntity"],
	CustomerQuestionEntityOnDeletedOne?: GraphQLTypes["CustomerQuestionEntity"],
	AuditCategoryEntityOnCreatedOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnUpdatedOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditCategoryEntityOnDeletedOne?: GraphQLTypes["AuditCategoryEntity"],
	AuditQuestionEntityOnCreatedOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnUpdatedOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionEntityOnDeletedOne?: GraphQLTypes["AuditQuestionEntity"],
	AuditQuestionCostCategoryEntityOnCreatedOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnUpdatedOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	AuditQuestionCostCategoryEntityOnDeletedOne?: GraphQLTypes["AuditQuestionCostCategoryEntity"],
	CostCategoryEntityOnCreatedOne?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityOnUpdatedOne?: GraphQLTypes["CostCategoryEntity"],
	CostCategoryEntityOnDeletedOne?: GraphQLTypes["CostCategoryEntity"],
	AuditReportItemImageEntityOnCreatedOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnUpdatedOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemImageEntityOnDeletedOne?: GraphQLTypes["AuditReportItemImageEntity"],
	AuditReportItemEntityOnCreatedOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnUpdatedOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportItemEntityOnDeletedOne?: GraphQLTypes["AuditReportItemEntity"],
	AuditReportEntityOnCreatedOne?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityOnUpdatedOne?: GraphQLTypes["AuditReportEntity"],
	AuditReportEntityOnDeletedOne?: GraphQLTypes["AuditReportEntity"],
	AuditChecklistImageEntityOnCreatedOne?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnUpdatedOne?: GraphQLTypes["AuditChecklistImageEntity"],
	AuditChecklistImageEntityOnDeletedOne?: GraphQLTypes["AuditChecklistImageEntity"],
	InvoiceAttachmentEntityOnCreatedOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnUpdatedOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	InvoiceAttachmentEntityOnDeletedOne?: GraphQLTypes["InvoiceAttachmentEntity"],
	EmployeeDocumentEntityOnCreatedOne?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnUpdatedOne?: GraphQLTypes["EmployeeDocumentEntity"],
	EmployeeDocumentEntityOnDeletedOne?: GraphQLTypes["EmployeeDocumentEntity"],
	WorkActionTemplateGroupEntityOnCreatedOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnUpdatedOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateGroupEntityOnDeletedOne?: GraphQLTypes["WorkActionTemplateGroupEntity"],
	WorkActionTemplateEntityOnCreatedOne?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnUpdatedOne?: GraphQLTypes["WorkActionTemplateEntity"],
	WorkActionTemplateEntityOnDeletedOne?: GraphQLTypes["WorkActionTemplateEntity"],
	ProjectCostInfoEntityOnCreatedOne?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnUpdatedOne?: GraphQLTypes["ProjectCostInfoEntity"],
	ProjectCostInfoEntityOnDeletedOne?: GraphQLTypes["ProjectCostInfoEntity"],
	BlockedCalendarWeekEntityOnCreatedOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnUpdatedOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	BlockedCalendarWeekEntityOnDeletedOne?: GraphQLTypes["BlockedCalendarWeekEntity"],
	MaintenanceReportEntityOnCreatedOne?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnUpdatedOne?: GraphQLTypes["MaintenanceReportEntity"],
	MaintenanceReportEntityOnDeletedOne?: GraphQLTypes["MaintenanceReportEntity"],
	ConfigurationEntityOnCreatedOne?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityOnUpdatedOne?: GraphQLTypes["ConfigurationEntity"],
	ConfigurationEntityOnDeletedOne?: GraphQLTypes["ConfigurationEntity"],
	ServiceMeterEntityOnCreatedOne?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnUpdatedOne?: GraphQLTypes["ServiceMeterEntity"],
	ServiceMeterEntityOnDeletedOne?: GraphQLTypes["ServiceMeterEntity"],
	DeliveryListMeterEntityOnCreatedOne?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnUpdatedOne?: GraphQLTypes["DeliveryListMeterEntity"],
	DeliveryListMeterEntityOnDeletedOne?: GraphQLTypes["DeliveryListMeterEntity"],
	ServiceSpaceEntityOnCreatedOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnUpdatedOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceSpaceEntityOnDeletedOne?: GraphQLTypes["ServiceSpaceEntity"],
	ServiceTypeEntityOnCreatedOne?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnUpdatedOne?: GraphQLTypes["ServiceTypeEntity"],
	ServiceTypeEntityOnDeletedOne?: GraphQLTypes["ServiceTypeEntity"],
	DeliveryListPointImageEntityOnCreatedOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnUpdatedOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	DeliveryListPointImageEntityOnDeletedOne?: GraphQLTypes["DeliveryListPointImageEntity"],
	ServicePriorityEntityOnCreatedOne?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnUpdatedOne?: GraphQLTypes["ServicePriorityEntity"],
	ServicePriorityEntityOnDeletedOne?: GraphQLTypes["ServicePriorityEntity"],
	DeliveryListPointEntityOnCreatedOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnUpdatedOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListPointEntityOnDeletedOne?: GraphQLTypes["DeliveryListPointEntity"],
	DeliveryListSignatureEntityOnCreatedOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnUpdatedOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListSignatureEntityOnDeletedOne?: GraphQLTypes["DeliveryListSignatureEntity"],
	DeliveryListEntityOnCreatedOne?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityOnUpdatedOne?: GraphQLTypes["DeliveryListEntity"],
	DeliveryListEntityOnDeletedOne?: GraphQLTypes["DeliveryListEntity"],
	SupplyInspectionReportAnswerEntityOnCreatedOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnUpdatedOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportAnswerEntityOnDeletedOne?: GraphQLTypes["SupplyInspectionReportAnswerEntity"],
	SupplyInspectionReportEntityOnCreatedOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnUpdatedOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	SupplyInspectionReportEntityOnDeletedOne?: GraphQLTypes["SupplyInspectionReportEntity"],
	DepartmentEntityOnCreatedOne?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityOnUpdatedOne?: GraphQLTypes["DepartmentEntity"],
	DepartmentEntityOnDeletedOne?: GraphQLTypes["DepartmentEntity"],
	DrawOrderDescriptionEntityOnCreatedOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnUpdatedOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	DrawOrderDescriptionEntityOnDeletedOne?: GraphQLTypes["DrawOrderDescriptionEntity"],
	GreetingEntityOnCreatedOne?: GraphQLTypes["GreetingEntity"],
	GreetingEntityOnUpdatedOne?: GraphQLTypes["GreetingEntity"],
	GreetingEntityOnDeletedOne?: GraphQLTypes["GreetingEntity"],
	HourDeclarationTypeEntityOnCreatedOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnUpdatedOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationTypeEntityOnDeletedOne?: GraphQLTypes["HourDeclarationTypeEntity"],
	HourDeclarationActivityEntityOnCreatedOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnUpdatedOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationActivityEntityOnDeletedOne?: GraphQLTypes["HourDeclarationActivityEntity"],
	HourDeclarationEntityOnCreatedOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnUpdatedOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationEntityOnDeletedOne?: GraphQLTypes["HourDeclarationEntity"],
	HourDeclarationApprovalRequestEntityOnCreatedOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnUpdatedOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	HourDeclarationApprovalRequestEntityOnDeletedOne?: GraphQLTypes["HourDeclarationApprovalRequestEntity"],
	InstallationReportCommentEntityOnCreatedOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnUpdatedOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportCommentEntityOnDeletedOne?: GraphQLTypes["InstallationReportCommentEntity"],
	InstallationReportDescriptionEntityOnCreatedOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnUpdatedOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportDescriptionEntityOnDeletedOne?: GraphQLTypes["InstallationReportDescriptionEntity"],
	InstallationReportFinalEntityOnCreatedOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnUpdatedOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportFinalEntityOnDeletedOne?: GraphQLTypes["InstallationReportFinalEntity"],
	InstallationReportMeasurementTemplateEntityOnCreatedOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnUpdatedOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementTemplateEntityOnDeletedOne?: GraphQLTypes["InstallationReportMeasurementTemplateEntity"],
	InstallationReportMeasurementEntityOnCreatedOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnUpdatedOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportMeasurementEntityOnDeletedOne?: GraphQLTypes["InstallationReportMeasurementEntity"],
	InstallationReportQuestionGroupEntityOnCreatedOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnUpdatedOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionGroupEntityOnDeletedOne?: GraphQLTypes["InstallationReportQuestionGroupEntity"],
	InstallationReportQuestionEntityOnCreatedOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnUpdatedOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionEntityOnDeletedOne?: GraphQLTypes["InstallationReportQuestionEntity"],
	InstallationReportQuestionAnswerEntityOnCreatedOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnUpdatedOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	InstallationReportQuestionAnswerEntityOnDeletedOne?: GraphQLTypes["InstallationReportQuestionAnswerEntity"],
	LegacyProjectEntityOnCreatedOne?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnUpdatedOne?: GraphQLTypes["LegacyProjectEntity"],
	LegacyProjectEntityOnDeletedOne?: GraphQLTypes["LegacyProjectEntity"],
	PicklistCommentEntityOnCreatedOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnUpdatedOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistCommentEntityOnDeletedOne?: GraphQLTypes["PicklistCommentEntity"],
	PicklistElementStateEntityOnCreatedOne?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnUpdatedOne?: GraphQLTypes["PicklistElementStateEntity"],
	PicklistElementStateEntityOnDeletedOne?: GraphQLTypes["PicklistElementStateEntity"],
	PlanningProjectItemEntityOnCreatedOne?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnUpdatedOne?: GraphQLTypes["PlanningProjectItemEntity"],
	PlanningProjectItemEntityOnDeletedOne?: GraphQLTypes["PlanningProjectItemEntity"],
	ProjectCargoStateEntityOnCreatedOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnUpdatedOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectCargoStateEntityOnDeletedOne?: GraphQLTypes["ProjectCargoStateEntity"],
	ProjectPaymentEntityOnCreatedOne?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnUpdatedOne?: GraphQLTypes["ProjectPaymentEntity"],
	ProjectPaymentEntityOnDeletedOne?: GraphQLTypes["ProjectPaymentEntity"],
	QualityAssuranceEntityOnCreatedOne?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnUpdatedOne?: GraphQLTypes["QualityAssuranceEntity"],
	QualityAssuranceEntityOnDeletedOne?: GraphQLTypes["QualityAssuranceEntity"],
	ServiceMapColorEntityOnCreatedOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnUpdatedOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServiceMapColorEntityOnDeletedOne?: GraphQLTypes["ServiceMapColorEntity"],
	ServicePlanningStageEntityOnCreatedOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnUpdatedOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningStageEntityOnDeletedOne?: GraphQLTypes["ServicePlanningStageEntity"],
	ServicePlanningEventEntityOnCreatedOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnUpdatedOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningEventEntityOnDeletedOne?: GraphQLTypes["ServicePlanningEventEntity"],
	ServicePlanningAssignmentEntityOnCreatedOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnUpdatedOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePlanningAssignmentEntityOnDeletedOne?: GraphQLTypes["ServicePlanningAssignmentEntity"],
	ServicePresetEntityOnCreatedOne?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityOnUpdatedOne?: GraphQLTypes["ServicePresetEntity"],
	ServicePresetEntityOnDeletedOne?: GraphQLTypes["ServicePresetEntity"],
	SupplierDocumentEntityOnCreatedOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnUpdatedOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplierDocumentEntityOnDeletedOne?: GraphQLTypes["SupplierDocumentEntity"],
	SupplyInspectionQuestionEntityOnCreatedOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnUpdatedOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionEntityOnDeletedOne?: GraphQLTypes["SupplyInspectionQuestionEntity"],
	SupplyInspectionQuestionGroupEntityOnCreatedOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnUpdatedOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	SupplyInspectionQuestionGroupEntityOnDeletedOne?: GraphQLTypes["SupplyInspectionQuestionGroupEntity"],
	UserPasswordResetEntityOnCreatedOne?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnUpdatedOne?: GraphQLTypes["UserPasswordResetEntity"],
	UserPasswordResetEntityOnDeletedOne?: GraphQLTypes["UserPasswordResetEntity"],
	YearPlanningLineEntityOnCreatedOne?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnUpdatedOne?: GraphQLTypes["YearPlanningLineEntity"],
	YearPlanningLineEntityOnDeletedOne?: GraphQLTypes["YearPlanningLineEntity"],
	InvoicePaymentBatchEntityEntityOnCreatedOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnUpdatedOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	InvoicePaymentBatchEntityEntityOnDeletedOne?: GraphQLTypes["InvoicePaymentBatchEntityEntity"],
	AppPhaseContactEntityOnCreatedOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnUpdatedOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseContactEntityOnDeletedOne?: GraphQLTypes["AppPhaseContactEntity"],
	AppPhaseEntityOnCreatedOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityOnUpdatedOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseEntityOnDeletedOne?: GraphQLTypes["AppPhaseEntity"],
	AppPhaseSectionEntityOnCreatedOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnUpdatedOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppPhaseSectionEntityOnDeletedOne?: GraphQLTypes["AppPhaseSectionEntity"],
	AppNotificationTemplateEntityOnCreatedOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnUpdatedOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppNotificationTemplateEntityOnDeletedOne?: GraphQLTypes["AppNotificationTemplateEntity"],
	AppPhaseSectionMarkEntityOnCreatedOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnUpdatedOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppPhaseSectionMarkEntityOnDeletedOne?: GraphQLTypes["AppPhaseSectionMarkEntity"],
	AppProjectDateEntityOnCreatedOne?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnUpdatedOne?: GraphQLTypes["AppProjectDateEntity"],
	AppProjectDateEntityOnDeletedOne?: GraphQLTypes["AppProjectDateEntity"],
	AppNotificationScheduleEntityOnCreatedOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnUpdatedOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppNotificationScheduleEntityOnDeletedOne?: GraphQLTypes["AppNotificationScheduleEntity"],
	AppPhaseSectionMarkValueEntityOnCreatedOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnUpdatedOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppPhaseSectionMarkValueEntityOnDeletedOne?: GraphQLTypes["AppPhaseSectionMarkValueEntity"],
	AppInviteEntityOnCreatedOne?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityOnUpdatedOne?: GraphQLTypes["AppInviteEntity"],
	AppInviteEntityOnDeletedOne?: GraphQLTypes["AppInviteEntity"],
	AppLoginEntityOnCreatedOne?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityOnUpdatedOne?: GraphQLTypes["AppLoginEntity"],
	AppLoginEntityOnDeletedOne?: GraphQLTypes["AppLoginEntity"],
	AppNotificationEntityOnCreatedOne?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityOnUpdatedOne?: GraphQLTypes["AppNotificationEntity"],
	AppNotificationEntityOnDeletedOne?: GraphQLTypes["AppNotificationEntity"],
	AppProjectEntityOnCreatedOne?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityOnUpdatedOne?: GraphQLTypes["AppProjectEntity"],
	AppProjectEntityOnDeletedOne?: GraphQLTypes["AppProjectEntity"],
	AppUserEntityOnCreatedOne?: GraphQLTypes["AppUserEntity"],
	AppUserEntityOnUpdatedOne?: GraphQLTypes["AppUserEntity"],
	AppUserEntityOnDeletedOne?: GraphQLTypes["AppUserEntity"],
	AppProjectCostEntityOnCreatedOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnUpdatedOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectCostEntityOnDeletedOne?: GraphQLTypes["AppProjectCostEntity"],
	AppProjectContactEntityOnCreatedOne?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnUpdatedOne?: GraphQLTypes["AppProjectContactEntity"],
	AppProjectContactEntityOnDeletedOne?: GraphQLTypes["AppProjectContactEntity"],
	AppBuildingWeekEntityOnCreatedOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnUpdatedOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppBuildingWeekEntityOnDeletedOne?: GraphQLTypes["AppBuildingWeekEntity"],
	AppContentEntityOnCreatedOne?: GraphQLTypes["AppContentEntity"],
	AppContentEntityOnUpdatedOne?: GraphQLTypes["AppContentEntity"],
	AppContentEntityOnDeletedOne?: GraphQLTypes["AppContentEntity"],
	AppConversationMessagePresetEntityOnCreatedOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnUpdatedOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppConversationMessagePresetEntityOnDeletedOne?: GraphQLTypes["AppConversationMessagePresetEntity"],
	AppDocumentSignatureEntityOnCreatedOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnUpdatedOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentSignatureEntityOnDeletedOne?: GraphQLTypes["AppDocumentSignatureEntity"],
	AppDocumentTypeEntityOnCreatedOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnUpdatedOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentTypeEntityOnDeletedOne?: GraphQLTypes["AppDocumentTypeEntity"],
	AppDocumentEntityOnCreatedOne?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityOnUpdatedOne?: GraphQLTypes["AppDocumentEntity"],
	AppDocumentEntityOnDeletedOne?: GraphQLTypes["AppDocumentEntity"],
	AppNotificationScheduleResourceEntityOnCreatedOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnUpdatedOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppNotificationScheduleResourceEntityOnDeletedOne?: GraphQLTypes["AppNotificationScheduleResourceEntity"],
	AppPlanCompetenceEntityOnCreatedOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnUpdatedOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceEntityOnDeletedOne?: GraphQLTypes["AppPlanCompetenceEntity"],
	AppPlanCompetenceSettingEntityOnCreatedOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnUpdatedOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppPlanCompetenceSettingEntityOnDeletedOne?: GraphQLTypes["AppPlanCompetenceSettingEntity"],
	AppProjectCostPresetEntityOnCreatedOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnUpdatedOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectCostPresetEntityOnDeletedOne?: GraphQLTypes["AppProjectCostPresetEntity"],
	AppProjectFieldSheetEntityOnCreatedOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnUpdatedOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldSheetEntityOnDeletedOne?: GraphQLTypes["AppProjectFieldSheetEntity"],
	AppProjectFieldGroupEntityOnCreatedOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnUpdatedOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupEntityOnDeletedOne?: GraphQLTypes["AppProjectFieldGroupEntity"],
	AppProjectFieldGroupAssignmentEntityOnCreatedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnUpdatedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentEntityOnDeletedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnCreatedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnUpdatedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldGroupAssignmentValueEntityOnDeletedOne?: GraphQLTypes["AppProjectFieldGroupAssignmentValueEntity"],
	AppProjectFieldEntityOnCreatedOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnUpdatedOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppProjectFieldEntityOnDeletedOne?: GraphQLTypes["AppProjectFieldEntity"],
	AppServiceCategoryEntityOnCreatedOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnUpdatedOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppServiceCategoryEntityOnDeletedOne?: GraphQLTypes["AppServiceCategoryEntity"],
	AppTextEntityOnCreatedOne?: GraphQLTypes["AppTextEntity"],
	AppTextEntityOnUpdatedOne?: GraphQLTypes["AppTextEntity"],
	AppTextEntityOnDeletedOne?: GraphQLTypes["AppTextEntity"],
	AppRelationEntityOnCreatedOne?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityOnUpdatedOne?: GraphQLTypes["AppRelationEntity"],
	AppRelationEntityOnDeletedOne?: GraphQLTypes["AppRelationEntity"]
}
    }

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "Query",
    "mutation": "Mutation",
    "subscription": "Subscription"
}

export type GenericOperation<O> = O extends 'query'
  ? "Query"
  : O extends 'mutation'
  ? "Mutation"
  : "Subscription"

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('http://localhost:3000/graphql')