import { PicklistElementStateMode } from "./dtos/picklist-element-state.dto";

export const determineNextPickState = (
  current: PicklistElementStateMode,
  indeterminateToggles: boolean
) => {
  switch (current) {
    case PicklistElementStateMode.Idle:
      return indeterminateToggles
        ? PicklistElementStateMode.Indeterminate
        : PicklistElementStateMode.Picked;
    case PicklistElementStateMode.Indeterminate:
      return PicklistElementStateMode.Picked;
    case PicklistElementStateMode.Picked:
      return PicklistElementStateMode.Idle;
  }
};
