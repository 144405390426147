import { Entity } from "../entity.service";

export class MetacomInvoicePaymentBatchEntity extends Entity {
  type = 'invoice_payment_batches';

  batch: number;
  name: string;
  date: Date;
  amount: number;
  amountBank: number;
  companyId: string;
  companyName: string;
  relationId: string;
  relationName: string;
}
