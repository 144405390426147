import { Injector } from "@angular/core";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Apollo } from "apollo-angular";
import { first } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { CellaService } from "src/app/cella.service";
import { MetacomService } from "src/app/metacom.service";
import { useApolloZeusMutation } from "src/app/react-component/useApolloZeusMutation";
import { useApolloZeusQuery } from "src/app/react-component/useApolloZeusQuery";
import { $ } from "src/app/zeus";
import { ConstructionPlanningProjectDto } from "../construction-planning-date.dto";
import { constructionFoundationMutation } from "./construction-foundation.mutation";

export interface ConstructionFoundationModalProps {
  injector: Injector;
  value: ConstructionPlanningProjectDto;
  onClosed: () => void;
  onUpdated: () => void;
}

export function ConstructionFoundationModal({
  injector,
  value,
  onClosed,
  onUpdated,
}: ConstructionFoundationModalProps) {
  const metacom = useMemo(() => injector.get(MetacomService), []);
  const apollo = useMemo(() => injector.get(Apollo), []);
  const cella = useMemo(() => injector.get(CellaService), []);

  const { data: projectContact } = useApolloZeusQuery(injector, {
    AppProjectContactEntityFindOne: [
      {
        query: {
          filters: [
            { field: "project_id", operator: "Equal", value: value.id },
            { field: "type", operator: "Equal", value: "constructeur" },
          ],
        },
      },
      { relation_id: true },
    ],
  });

  const { data: contactEmail, refetch } = useApolloZeusQuery(
    injector,
    {
      UserEntityFindById: [
        {
          id: $`id`,
        },
        { email: true },
      ],
    },
    {
      id: projectContact?.AppProjectContactEntityFindOne?.relation_id,
    }
  );

  const [storeNotification] = useApolloZeusMutation(injector, {
    UserNotificationEntityUpdateOne: [
      {
        input: $`input`,
      },
      { id: true },
    ],
  });

  useEffect(() => {
    refetch();
  }, [projectContact]);

  const [options, setOptions] = useState<string[]>(null);
  const [saving, setSaving] = useState(false);

  const [foundationType, setFoundationType] = useState<string>(
    () => value.foundation
  );
  const [probeWork, setProbeWork] = useState<string>(
    () => value.constructionProbeWork
  );
  const [advice, setAdvice] = useState<string>(() => value.constructionAdvice);
  const [drawingWork, setDrawingWork] = useState<string>(
    () => value.constructionDrawingWork
  );
  const [supplier, setSupplier] = useState<string>(
    () => value.constructionSupplier
  );

  const [level, setLevel] = useState<string>(() => value.constructionLevel);
  const [unavailable, setUnavailable] = useState<string>(
    () => value.constructionUnavailable
  );

  const composeField = (code: string, contents: any) => ({
    Entity: "0401",
    Origin: `bdr:920¡adm:${value.regionId}¡prj:${value.id}`,
    LineId: 1,
    Code: code,
    SerialNumber: 1,
    Contents: contents,
  });

  function composeFields() {
    return [
      composeField("PRJ-064", foundationType),
      composeField("PRJ-IJB-001", probeWork),
      composeField("PRJ-IJB-002", advice),
      composeField("PRJ-IJB-003", drawingWork),
      composeField("PRJ-IJB-004", supplier),
      composeField("PRJ-IJB-005", level),
      composeField("PRJ-IJB-006", unavailable),
    ];
  }

  async function store() {
    setSaving(true);

    const response = await apollo
      .mutate({
        mutation: constructionFoundationMutation,
        variables: {
          customFields: composeFields(),
          input: {
            id: value.id,
            foundation: foundationType,
            constructionProbeWork: probeWork,
            constructionAdvice: advice,
            constructionDrawingWork: advice,
            constructionSupplier: supplier,
            constructionLevel: level,
            constructionUnavailable: unavailable,
          },
        },
      })
      .toPromise();

    if (foundationType !== value.foundation) {
      const subject = `Type Fundatie aangepast (${value.id}) ${value.description}`;
      const message = `Het type fundatie van project: (${value.id}) ${
        value.description
      } is aangepast van: ${value.foundation ?? "-"} naar: ${foundationType}.`;

      contactEmail.UserEntityFindById.email &&
        (await cella.sendEmail({
          from: {
            address: "administratie@groothuisbouw.nl",
            name: "Groothuisbouw",
          },
          to: contactEmail.UserEntityFindById.email,
          text: "Om deze email te lezen, gebruik een HTML viewer",
          subject,
          html: message,
        }));

      value.salesEmployeeId &&
        (await storeNotification({
          input: {
            userId: value.salesEmployeeId,
            subject,
            content: message,
            channels: ["notification"],
            url: `${location.origin}/#/planning/construction-planning`,
          },
        }));
    }

    setSaving(false);

    if (!response.errors) {
      onUpdated();
    }
  }

  async function fetchOptions() {
    const response = await metacom.queryTableAsync<{ type_fundering: string }>({
      setName: "metacom",
      tableName: "fundering_type",
    });

    if (!response.hasError()) {
      const options = first(response.value)
        .type_fundering.split(",")
        .filter((_segment) => _segment.length > 0);

      setOptions(options);
    }
  }

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Dialog open onClose={onClosed}>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl variant="outlined">
              <InputLabel shrink id="foundation-select-label">
                Type fundering
              </InputLabel>
              {options && (
                <Select
                  style={{ minWidth: 400 }}
                  label="Type fundering"
                  labelId="foundation-select-label"
                  value={foundationType}
                  onChange={(event) =>
                    setFoundationType(event.target.value as string)
                  }
                >
                  {options.map((_option) => (
                    <MenuItem key={_option} value={_option}>
                      {_option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <FieldEditor
              label="Sondeerwerk"
              value={probeWork}
              onChange={(event) => setProbeWork(event.target.value)}
            />
          </Grid>
          <Grid item>
            <FieldEditor
              label="Advies"
              value={advice}
              onChange={(event) => setAdvice(event.target.value)}
            />
          </Grid>
          <Grid item>
            <FieldEditor
              label="Tekenwerk"
              value={drawingWork}
              onChange={(event) => setDrawingWork(event.target.value)}
            />
          </Grid>
          <Grid item>
            <FieldEditor
              label="E-construct"
              value={supplier}
              onChange={(event) => setSupplier(event.target.value)}
            />
          </Grid>
          <Grid item>
            <FieldEditor
              label="Peil"
              value={level}
              onChange={(event) => setLevel(event.target.value)}
              type="number"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={unavailable === "true"}
                  onChange={(event) =>
                    setUnavailable(event.target.checked ? "true" : "false")
                  }
                />
              }
              label="Tijdelijk niet bereikbaar"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClosed}>
          Sluiten
        </Button>
        <Button disabled={saving} variant="outlined" onClick={store}>
          Opslaan
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface FieldEditorProps {
  label: string;
  value: string;
  type?: string;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const FieldEditor = ({ label, value, onChange, type }: FieldEditorProps) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={label}
      type={type || "text"}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
