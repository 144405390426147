import { DateWrapper } from "../date-stack-dialog.component";

export interface DateState {
  isFieldEnabled(wrapper: DateWrapper, field: DateStateField): boolean;
  isFieldToggled(wrapper: DateWrapper, field: DateStateField): boolean;

  toggleField(
    wrapper: DateWrapper,
    field: DateStateField,
    value?: boolean
  ): void;
}

export enum DateStateField {
  Activated = "activated",
  Completed = "completed",
  SendNotification = "sendNotification",
  ViewContent = "viewContent",
}
