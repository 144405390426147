import { CellaService } from "./../cella.service";
import { generateProjectCostsTransaction } from "./app-project-cost.transaction-generator";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AppProjectCost, AppProjectCostStage } from "./app-project-cost.entity";
import {
  AppCrudBoilerplateInstance,
  AppCrudBoilerplateInstanceData,
} from "../boilerplates/app-crud.boilerplate";
import { TransactionService } from "../transaction.service";
import { EntityManager, Ops } from "../entity.service";
import { DialogService } from "../dialog.service";
import { RestResponse } from "../rest.service";
import { ActivatedRoute } from "@angular/router";
import { InformationDialogComponent } from "../information-dialog/information-dialog.component";
import { AppProjectCostDialogComponent } from "../app-project-cost-dialog/app-project-cost-dialog.component";
import { AppService } from "../app.service";
import { MdcSnackbar, MdcTab } from "@angular-mdc/web";
import { AppRestService } from "../app-rest.service";
import { saveAs } from "file-saver";
import { first, orderBy, sumBy } from "lodash";
import { AppProjectCostCsvGenerator } from "./app-project-cost.csv-generator";
import { AppProjectCostHistoryDialogComponent } from "../app-project-cost-history-dialog/app-project-cost-history-dialog.component";
import { AppProject } from "./app-project.entity";
import { ProjectPayment } from "../project-payment-dialog/project-payment.entity";
import { ProjectPaymentDialogComponent } from "../project-payment-dialog/project-payment-dialog.component";
import { AuthService } from "../auth.service";
import { GrantService } from "../grant.service";
import { grants } from "../app-grant-config";
import { Project } from "../project/project.entity";
import { UserNotification } from "../notification/user-notification.entity";
import { NotificationGuard } from "../work-actions/notification-guard";

enum AppProjectCostMode {
  ConceptOnly = "concept_only",
  FullAccess = "full_access",
}

enum AppProjectCostMutation {
  Edit = "edit",
  Delete = "delete",
  AddChild = "add_child",
  Approve = "approve",
  ApproveConcept = "approve_concept",
}

@Component({
  selector: "app-app-project-costs",
  templateUrl: "./app-project-costs.component.html",
  styleUrls: ["./app-project-costs.component.scss"],
})
export class AppProjectCostsComponent implements OnInit {
  activeTabId = "default";

  responseProject: RestResponse<Project>;

  conceptNotified = false;

  response: RestResponse<AppProjectCost[]>;
  treeExpansions = new Map<string, boolean>();

  crud: AppCrudBoilerplateInstance<AppProjectCost>;
  crudPayment: AppCrudBoilerplateInstance<ProjectPayment>;

  get responsePayments() {
    return this.crudPayment.response;
  }

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  get showAll() {
    return this.grantService.varIs(grants.app_project_costs.show_all, "true");
  }

  get mode() {
    const approveRequired = this.grantService.varIs(
      grants.app_project_costs.requires_approvement,
      "true"
    );
    return approveRequired
      ? AppProjectCostMode.ConceptOnly
      : AppProjectCostMode.FullAccess;
  }

  get showContractSum() {
    return this.grantService.varIs(
      grants.app_project_costs.show_contract_sum,
      "true"
    );
  }

  get allowPayments() {
    return this.grantService.varIs(
      grants.app_project_costs.allow_payments,
      "true"
    );
  }

  get notifyConceptAllowed() {
    return (
      this.responseProject &&
      this.responseProject.value.projectLeaderId &&
      !this.conceptNotified
    );
  }

  constructor(
    readonly app: AppService,
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly grantService: GrantService,
    protected readonly auth: AuthService,
    protected readonly route: ActivatedRoute,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly snackbar: MdcSnackbar,
    protected readonly appRest: AppRestService,
    protected readonly cellaService: CellaService
  ) {
    this.crud = this.makeCrud();
    this.crudPayment = this.makeCrudPayment();

    this.crud.onUpdated.subscribe(() => this.initialize());
  }

  /**
   * Activates the requested tab.
   * @param event Event provided by the MdcTabBar
   */
  activateTab(event: { tab: MdcTab }) {
    this.activeTabId = event.tab.id;
  }

  async ngOnInit() {
    await this.crud.fetch();
    await this.crudPayment.fetch();
    await this.fetchProject();
  }

  get totalPayments() {
    return this.responsePayments
      ? sumBy(this.responsePayments.value, (p) => p.amount)
      : 0;
  }

  isMutationAllowed(
    item: AppProjectCost,
    parent: AppProjectCost,
    mutation: AppProjectCostMutation
  ) {
    const isLocked = item.isLocked === true || (!!parent && parent.isLocked);
    const isTouchable = this.isAllowedToTouch(item);
    const isConnectWithApp = this.app.isAuthenticated;
    const isDenied = isLocked || !isTouchable;
    const isConceptOnly = this.mode === AppProjectCostMode.ConceptOnly;

    switch (mutation) {
      case AppProjectCostMutation.Edit:
      case AppProjectCostMutation.Delete:
        return [isDenied, item.isRoot].includes(true) === false;
      case AppProjectCostMutation.AddChild:
        return [isDenied, !item.isParent].includes(true) === false;
      case AppProjectCostMutation.Approve:
        return (
          [
            isDenied,
            !item.isParent,
            item.isRoot,
            isConceptOnly,
            !isConnectWithApp,
          ].includes(true) === false
        );
      case AppProjectCostMutation.ApproveConcept:
        return (
          [!item.isParent, item.isRoot, isConceptOnly].includes(true) === false
        );
    }
  }

  edit(item: AppProjectCost, parent: AppProjectCost) {
    item.parentHasValue = parent ? !!parent.value : false;

    return this.crud.edit(item);
  }

  addPayment() {
    this.crudPayment.edit(
      this.crudPayment.entityService.concept({
        userId: this.auth.user.id,
        projectId: this.projectId,
      })
    );
  }

  protected async initialize() {
    const response = await this.entityManager
      .get(AppProject)
      .findOne(this.projectId);

    const contractSum = this.crud.entityService.concept({
      id: null,
      title: "Aanneemsom",
      value: parseFloat(response.value.contract_sum),
      approved_at: new Date(),
    });

    const children = (
      !response.hasError() && this.showContractSum ? [contractSum] : []
    ).concat(this.crud.response.value);

    const root = this.crud.entityService.concept({
      id: null,
      value: null,
      title: "Totaal",
      project_id: this.projectId,
      children: orderBy(
        children,
        (child) => (child.isPersistentConcept ? 1 : 0),
        "asc"
      ),
    });

    this.response = new RestResponse([root]);
    this.setExpand(root, true);
  }

  protected getExpandId(item: AppProjectCost) {
    return item.id;
  }

  isStage(stage: string, item: AppProjectCost, parent?: AppProjectCost) {
    const _stage = stage as AppProjectCostStage;

    return (parent && parent.stage === _stage) || item.stage === _stage;
  }

  isExpanded(item: AppProjectCost) {
    return this.treeExpansions.get(this.getExpandId(item)) || false;
  }

  toggleExpand(item: AppProjectCost) {
    this.setExpand(item, !this.isExpanded(item));
  }

  setExpand(item: AppProjectCost, value: boolean) {
    this.treeExpansions.set(this.getExpandId(item), value);
  }

  addChild(item: AppProjectCost) {
    const concept = this.crud.entityService.concept({
      parent_id: item.id,
      user_id: this.composeUserId(),
      project_id: item.isPersistentConcept
        ? item.project_id
        : this.composeProjectId(),
      parentHasValue: !!item.value,
    });

    return this.crud.edit(concept);
  }

  exportMetacom() {
    const data = new AppProjectCostCsvGenerator(this.getRoot()).generate();
    const blob = new Blob([data], { type: "text/csv" });

    saveAs(blob, `financieel-overzicht_${this.projectId}.csv`);
  }

  async exportMetacomAsTransaction() {
    const transaction = generateProjectCostsTransaction({
      root: this.getRoot(),
      project: this.responseProject.value,
    });

    const response = await this.transactions.perform(() =>
      this.cellaService.writeTransactionDocument(transaction)
    );

    if (!response.hasError()) {
      this.snackbar.open("Geëxporteerd naar Metacom", "Ok");
    }
  }

  async requestApproval(item: AppProjectCost) {
    if (item.isPersistentConcept) {
      const family = Array.from([...(item.children || []), item]);

      for (const familyItem of family) {
        familyItem.user_id = this.composeUserId();
        familyItem.project_id = this.composeProjectId();
      }

      await this.crud.entityService.modifyMany(family);

      this.snackbar.open("Concept goedgekeurd", "Ok");
    } else {
      const response = await this.callApiForApprove(item);
      const responseOk = !response.hasError();

      item.requested_at = responseOk ? new Date() : null;

      this.snackbar.open(
        responseOk ? "Goedkeuring klant aangevraagd" : response.error,
        "Ok"
      );
    }
  }

  openMemo(item: AppProjectCost) {
    this.dialogs.open(this.changeDetector, InformationDialogComponent, {
      data: {
        title: "Memo",
        message: item.memo,
      },
    });
  }

  openDetails(item: AppProjectCost) {
    this.dialogs.open(
      this.changeDetector,
      AppProjectCostHistoryDialogComponent,
      {
        data: { model: item },
      }
    );
  }

  protected getRoot() {
    return first(this.response.value);
  }

  protected makeCrud() {
    return new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: AppProjectCost,
        editDialog: AppProjectCostDialogComponent,
        filters: [
          Ops.Field("parent_id").IsNull(),
          Ops.Field("project_id").In([
            this.projectId,
            `${AppProjectCost.conceptPrefix}${this.projectId}`,
          ]),
        ].concat(
          this.showAll ? [] : [Ops.Field("user_id").Equals(this.auth.user.id)]
        ),
        relations: ["children", "user", "approvedUser", "declinedUser"],
      }),
      this.transactions,
      this.entityManager,
      this.dialogs,
      this.changeDetector
    );
  }

  protected makeCrudPayment() {
    return new AppCrudBoilerplateInstance(
      new AppCrudBoilerplateInstanceData({
        entity: ProjectPayment,
        editDialog: ProjectPaymentDialogComponent,
        filters: [Ops.Field("projectId").Equals(this.projectId)],
        relations: [],
        orderByField: "payedAt",
      }),
      this.transactions,
      this.entityManager,
      this.dialogs,
      this.changeDetector
    );
  }

  protected isAllowedToTouch(item: AppProjectCost) {
    switch (this.mode) {
      default:
      case AppProjectCostMode.ConceptOnly:
        return item.isRoot || item.isPersistentConcept;
      case AppProjectCostMode.FullAccess:
        return true;
    }
  }

  protected composeUserId() {
    switch (this.mode) {
      default:
      case AppProjectCostMode.ConceptOnly:
        return this.auth.user.id;
      case AppProjectCostMode.FullAccess:
        return this.app.userId;
    }
  }

  protected composeProjectId() {
    switch (this.mode) {
      default:
      case AppProjectCostMode.ConceptOnly:
        return `${AppProjectCost.conceptPrefix}${this.projectId}`;
      case AppProjectCostMode.FullAccess:
        return this.projectId;
    }
  }

  protected callApiForApprove(item: AppProjectCost) {
    return this.appRest.request({
      url: `v2/project-costs/${item.id}`,
      method: "PUT",
      body: Object.assign(
        {
          action: "request",
        },
        item.toJson()
      ),
      headers: {
        "X-Project": this.projectId,
      },
    });
  }

  protected async fetchProject() {
    this.responseProject = await this.entityManager.get(Project).queryFirst({
      filters: [Ops.Field("id").Equals(this.projectId)],
      select: ["projectLeaderId", "id", "description", "userId"],
    });
  }

  async notifyProjectLeader() {
    const project = this.responseProject.value;
    const userNotificationService = this.entityManager.get(UserNotification);

    const guard = new NotificationGuard({
      userId: this.auth.user.id,
      notifications: userNotificationService,
    });

    await guard.sendIfNotMe(
      userNotificationService.concept({
        userId: this.responseProject.value.projectLeaderId,
        subject: `(${project.id}) ${project.description} Meerwerk regels aangeboden`,
        content: [
          `Meerwerk regels aangeboden.`,
          `Aangeboden door: ${this.auth.user.name}`,
          `Project: (${project.id}) ${project.description}`,
        ].join("\n"),
        channels: ["notification"],
        url: `${location.origin}/#/app/project-costs/${this.projectId}`,
      })
    );

    this.snackbar.open("Meerwerk aangeboden");

    this.conceptNotified = true;
  }
}
