import * as moment from "moment";
import { Injector } from "@angular/core";
import { DialogService } from "../dialog.service";
import {
  ProjectShortcutsDialogComponent,
  ProjectShortcutsDialogComponentModel,
} from "../project-shortcuts-dialog/project-shortcuts-dialog.component";
import { ConstructionPlanningProjectDto } from "./construction-planning-date.dto";
import { ConstructionPlanningFilter } from "./construction-planning.event";

export const constructionPlanningDateFormat = "DD-MM-YYYY";

export function constructionGetDate(
  project: ConstructionPlanningProjectDto,
  dateId: string
) {
  const query = (project.cachedValue.dateValues || []).find(
    (_date) => _date.dateId === dateId
  );

  return query ? query.value : null;
}

export function constructionGetDateFormatted(
  project: ConstructionPlanningProjectDto,
  dateId: string
) {
  const date = constructionGetDate(project, dateId);

  return date ? moment(date).format(constructionPlanningDateFormat) : "-";
}

export async function constructionOpenShortcuts(
  injector: Injector,
  project: ConstructionPlanningProjectDto
) {
  return await injector
    .get(DialogService)
    .openStateless(ProjectShortcutsDialogComponent, {
      data: new ProjectShortcutsDialogComponentModel(project.id as string, {
        subject: project.id,
        description: project.description,
      }),
    });
}

export const createConstructionPlanningFilters = (
  filter: ConstructionPlanningFilter,
  maxBuildingWeek?: string
) => {
  if (filter.fieldFilters && Object.keys(filter.fieldFilters).length > 0) {
    const filters = Object.entries(filter.fieldFilters)
      .map((fieldFilter) => {
        const [field, value] = fieldFilter;

        return { field, operator: "Like", value };
      })
      .filter((fieldFilter) => fieldFilter.value.length >= 3);

    if (filters.length > 0) {
      return filters;
    }
  }

  const buildingWeek = moment().format("GGGG-WW");

  return [
    {
      field: "userId",
      operator: "MoreThanOrEqual",
      valueComplex: 1000,
    },
    maxBuildingWeek == null
      ? {
          field: "buildingWeek",
          operator: "MoreThanOrEqual",
          value: buildingWeek,
        }
      : {
          field: "buildingWeek",
          operator: "Between",
          valueComplex: [buildingWeek, maxBuildingWeek],
        },
  ];
};
