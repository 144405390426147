import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EntityManager } from "../entity.service";
import {
  Picklist,
  PicklistElement,
  PicklistComment,
  PicklistState,
  PicklistDefinition,
} from "./picklist.entity";
import { RestResponse } from "../rest.service";
import { MdcSnackbar, MdcDialog } from "@angular-mdc/web";
import { AuthService } from "../auth.service";
import { PicklistCommentDialogComponent } from "../picklist-comment-dialog/picklist-comment-dialog.component";
import { Project } from "../project/project.entity";
import * as _ from "lodash";

@Component({
  selector: "app-picklist",
  templateUrl: "./picklist.component.html",
  styleUrls: ["./picklist.component.scss"],
})
export class PicklistComponent implements OnInit {
  view = "all";
  material = "all";

  response: RestResponse<Project>;
  responseMaterials: RestResponse<string[]>;
  responseDefinition: RestResponse<PicklistDefinition>;

  protected responseElements: RestResponse<PicklistElement[]>;
  responseElementsFiltered: RestResponse<PicklistElement[]>;

  responseComments: RestResponse<PicklistComment[]>;

  protected state: PicklistState;
  protected headerId = "990999";

  protected get projectService() {
    return this.entityManager.get(Project);
  }

  protected get picklistService() {
    return this.entityManager.get(Picklist);
  }

  protected get picklistElementService() {
    return this.entityManager.get(PicklistElement);
  }

  protected get picklistCommentService() {
    return this.entityManager.get(PicklistComment);
  }

  protected get picklistStateService() {
    return this.entityManager.get(PicklistState);
  }

  protected get picklistDefinitionService() {
    return this.entityManager.get(PicklistDefinition);
  }

  constructor(
    protected readonly mdcDialog: MdcDialog,
    protected readonly snackbar: MdcSnackbar,
    protected readonly route: ActivatedRoute,
    protected readonly authService: AuthService,
    protected readonly entityManager: EntityManager
  ) {}

  ngOnInit() {
    this.fetchMetaInfo()
      .then(() =>
        this.fetchElements()
          .then(() => this.fetchMaterials())
          .then(() => this.createFilteredElements())
      )
      .then(() => this.fetchComments());
  }

  get projectId() {
    return this.route.snapshot.paramMap.get("projectId");
  }

  get picklistDefinitionId() {
    return this.route.snapshot.paramMap.get("picklistDefinitionId");
  }

  get isCompleted() {
    return !!this.state;
  }

  get description() {
    return this.response
      ? this.response.value.__picklists__.map((p) => p.note).join()
      : "Geen commentaar";
  }

  get isAllPicked() {
    const elements = this.getElements();

    return elements.length && elements.every((d) => d.isPicked);
  }

  overview() {
    window.history.back();
  }

  show(column: string) {
    return !(
      this.responseDefinition &&
      this.responseDefinition.value.hideColumns &&
      this.responseDefinition.value.hideColumns.indexOf(column) >= 0
    );
  }

  async markCompleted() {
    const state = this.picklistStateService.concept({
      projectId: this.projectId,
      picklistDefinitionId: this.picklistDefinitionId,
      completedByUserId: this.authService.user.id,
    });

    const response = await this.picklistStateService.save(state);

    if (response && !response.hasError()) {
      this.state = response.value;

      await this.snackbar
        .open("Gereed gemeld", "OK")
        .afterDismiss()
        .toPromise();
    }
  }

  async pickElement(element: PicklistElement, toggle: boolean = false) {
    if (!this.isCompleted) {
      if (toggle) {
        element.isPicked = !element.isPicked;
      }

      if (this.responseDefinition.value.indeterminateToggles) {
        if (element.isPicked) {
          if (element.pickState !== "indeterminate") {
            element.isPicked = false;
            element.pickState = "indeterminate";
          } else {
            element.pickState = null;
          }
        }
      }

      element.pickedAt = element.isPicked ? new Date() : null;
      element.pickedByUserId = element.isPicked
        ? this.authService.user.id
        : null;

      const response = await this.picklistElementService.save(element);

      if (response && !response.hasError()) {
        this.snackbar.open("Opgeslagen", "OK");
      }
    }
  }

  async createComment() {
    const dialogResult = await this.mdcDialog
      .open(PicklistCommentDialogComponent, {
        data: this.picklistCommentService.concept(),
      })
      .afterClosed()
      .toPromise();

    if (dialogResult instanceof PicklistComment) {
      await this.addComment(dialogResult);
    }
  }

  async addComment(comment: PicklistComment) {
    comment.userId = this.authService.user.id;
    comment.projectId = this.projectId;
    comment.picklistDefinitionId = this.picklistDefinitionId;

    const response = await this.picklistCommentService.save(comment);

    if (response && !response.hasError()) {
      response.value.setStale("user", this.authService.user);
      this.responseComments.value.push(response.value);

      await this.snackbar
        .open("Commentaar toegevoegd", "OK")
        .afterDismiss()
        .toPromise();
    }
  }

  print() {
    window.print();
  }

  protected async fetchMetaInfo() {
    this.responseDefinition = await this.picklistDefinitionService.findOne(
      this.picklistDefinitionId
    );

    this.response = await this.projectService.queryFirst({
      filters: [{ field: "id", operator: "Equal", value: this.projectId }],
      relations: ["picklists", "picklistStates"],
      take: 1,
    });

    if (!this.response.hasError()) {
      this.state = this.response.value
        .manyStale(PicklistState, "picklistStates")
        .value.find(
          (s) => s.picklistDefinitionId === this.picklistDefinitionId
        );
    }
  }

  protected async fetchElements() {
    if (!this.response.hasError()) {
      const picklistIds = this.response.value.__picklists__.map((p) => p.id);

      if (picklistIds.length) {
        const filters = [
          { field: "picklistId", operator: "In", value: picklistIds },
          { field: "elementId", operator: "IsNull", isNot: true },
          { field: "quantity", operator: "MoreThan", value: 0 },
          this.getElementFilter(),
        ];

        const response = await this.picklistElementService.query({
          filters,
          orders: [{ field: "orderId", direction: "ASC" }],
          relations: ["housePartGroup"],
        });

        this.responseElements = new RestResponse<PicklistElement[]>(
          _.orderBy(response.value, (e) => <any>e.orderId * 1)
        );
      }
    }
  }

  protected async fetchComments() {
    if (!this.response.hasError()) {
      const filters = [
        { field: "project", operator: "Equal", value: this.projectId },
        {
          field: "picklistDefinitionId",
          operator: "Equal",
          value: this.picklistDefinitionId,
        },
      ];

      this.responseComments = await this.picklistCommentService.query({
        filters,
        relations: ["user"],
      });
    }
  }

  protected *evalFilters() {
    if (this.responseElements) {
      for (const item of this.responseElements.value) {
        if (
          this.material !== "all" ? item.elementId !== this.material : false
        ) {
          continue;
        }

        if (
          this.view !== "all"
            ? item.isPicked !== (this.view === "picked")
            : false
        ) {
          continue;
        }

        yield item;
      }
    }
  }

  protected getElementFilter() {
    return {
      field: "elementType",
      operator: "In",
      value: this.responseDefinition.value.elementTypes,
    };
  }

  protected isHeader(element: PicklistElement) {
    return element.elementId === this.headerId;
  }

  protected getElements() {
    return this.responseElements
      ? this.responseElements.value.filter((e) => !this.isHeader(e))
      : [];
  }

  protected fetchMaterials() {
    if (this.responseElements && !this.responseElements.hasError()) {
      const materials = _.uniq(
        this.responseElements.value
          .map((e) => e.elementId)
          .filter((e) => e !== this.headerId)
      );

      this.responseMaterials = new RestResponse(materials);
    }
  }

  createFilteredElements() {
    this.responseElementsFiltered = null;

    const result = Array.from(this.evalFilters());

    this.responseElementsFiltered = new RestResponse(result);
  }
}
