import { DeliveryListSignatureEntity } from "../../delivery-list.entity";

export class SignatureState {}

export class SignatureLoadInProgressState extends SignatureState {}

export class SignatureLoadSuccessState extends SignatureState {
  constructor(
    readonly props: {
      signatureEmployee: DeliveryListSignatureEntity;
      signatureCustomer: DeliveryListSignatureEntity;
    }
  ) {
    super();
  }
}

export class SignatureLoadFailureState extends SignatureState {
  constructor(
    readonly props: {
      readonly message: string;
    }
  ) {
    super();
  }
}
