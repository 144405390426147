import { Entity } from "src/app/entity.service";

export class TypeEntity extends Entity {
  type = "service_types";

  code: string;
  description: string;

  storeInSystem: boolean;
  isStandard: boolean;

  createdAt: Date;
}
