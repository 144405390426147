export class MenuGroup {
  isExpanded = false;

  constructor(readonly name: string, readonly children: MenuItem[]) {}
}

export class MenuItem {
  constructor(
    readonly name: string,
    readonly icon: string,
    readonly route: string,
    readonly isDesktopOnly: boolean = false
  ) {}
}

export const menuDefinition = [
  new MenuGroup("Verkoop", [
    new MenuItem("Offertes", "monetization_on", "/office/offers"),
    new MenuItem(
      "Contracten",
      "import_contacts",
      "/office/contracts"
    ) /** change route & perms */,
  ]),
  new MenuGroup("Planning", [
    new MenuItem(
      "Jaarplanning",
      "calendar_view_day",
      "/planning/year-planning"
    ),
    new MenuItem("Tekenaars BK", "gesture", "/planning/drawers", true),
    new MenuItem("Tekenaars IT", "gesture", "/planning/drawers/it", true),
    new MenuItem("Tekenaars HSB", "gesture", "/planning/drawers/hsb", true),
    new MenuItem("Tekenaars PBK", "gesture", "/planning/drawers/pbk", true),
    // new MenuItem(
    //   "Tekenaars overzicht",
    //   "gesture",
    //   "/planning/drawers/overview",
    //   true
    // ),
    new MenuItem("Activiteiten", "local_activity", "/planning/competence"),
    new MenuItem("Personeel", "person_pin", "/planning/employee"),
    new MenuItem("Constructief", "build", "/planning/construction-planning"),
  ]),
  new MenuGroup("Prefabin", [
    new MenuItem(
      "Planning wanden",
      "view_module",
      "/planning/table-layout/walls"
    ),
    new MenuItem(
      "Planning overige",
      "view_module",
      "/planning/table-layout/misc"
    ),
    new MenuItem("Bestellingen", "shopping_cart", "/prefabin/orders"),
    new MenuItem("Leveringen", "beenhere", "/prefabin/deliveries"),
    new MenuItem("Bestellijst", "shopping_cart", "/prefabin/p-shop"),
  ]),
  new MenuGroup("Bouw", [
    new MenuItem(
      "WPI structuur",
      "format_list_numbered",
      "/construction/audit-sheet"
    ),
    new MenuItem(
      "WPI configuratie",
      "table_chart",
      "/construction/audit-sheet-table"
    ),
    new MenuItem("Personeel", "people_outline", "/construction/employees"),
    new MenuItem("Bestellijst", "shopping_cart", "/construction/c-shop"),
  ]),
  new MenuGroup("Service", [
    new MenuItem("Overzicht", "room_service", "/service/overview"),
    new MenuItem("Bestellijst", "shopping_cart", "/service/s-shop"),
    new MenuItem(
      "Klant Meldingen",
      "contact_support",
      "/service/customer-questions"
    ),
    new MenuItem("Planning", "insert_invitation", "/service/planning"),
  ]),
  new MenuGroup("Toelevering", [
    new MenuItem("Picklijsten", "how_to_vote", "/supply/picklist-overview"),
    new MenuItem("Vrachten", "local_shipping", "/supply/cargo-overview"),
    new MenuItem("Bestellingen", "shopping_cart", "/supply/orders"),
    new MenuItem("Leveringen", "beenhere", "/supply/deliveries"),
    new MenuItem(
      "Inspectie vragen",
      "live_help",
      "/supply/inspection-questions"
    ),
    new MenuItem(
      "Inspectie afdelingen",
      "grid_on",
      "/supply/inspection-departments"
    ),
    new MenuItem(
      "Inspectie overzicht",
      "table_rows",
      "/supply/inspection-overview"
    ),
  ]),
  new MenuGroup("GBI", [
    new MenuItem(
      "Picklijsten",
      "how_to_vote",
      "/installations/picklist-overview"
    ),
    new MenuItem("Bestellijst", "shopping_cart", "/installations/i-shop"),
  ]),
  new MenuGroup("Administratie", [
    new MenuItem(
      "Facturen maken",
      "receipt",
      "/office/invoice-creator"
    ) /** change route & perms */,
    new MenuItem(
      "Facturen controleren",
      "gavel",
      "/office/invoices"
    ) /** change route & perms */,
    new MenuItem(
      "Werkopdrachten",
      "play_for_work",
      "/office/work-orders"
    ) /** change route & perms */,
    new MenuItem(
      "Urenregistratie",
      "av_timer",
      "/office/hour-declarations"
    ) /** change route & perms */,
    new MenuItem(
      "Actielijst",
      "work",
      "/office/work-actions"
    ) /** change route & perms */,
    new MenuItem(
      "Factuur betaalbatches",
      "how_to_vote",
      "/office/metacom-payments"
    ) /** change route & perms */,
  ]),
  new MenuGroup("Overige", [
    new MenuItem(
      "Projecten",
      "archive",
      "/office/metacom-project-list"
    ) /** change route & perms */,
    new MenuItem(
      "Prijsafspraken",
      "assignment",
      "/office/metacom-price"
    ) /** change route & perms */,
    new MenuItem(
      "Relaties",
      "people",
      "/office/metacom-relation"
    ) /** change route & perms */,
    new MenuItem(
      "Personeel",
      "mood",
      "/office/metacom-staff"
    ) /** change route & perms */,
    new MenuItem(
      "Facturen",
      "payment",
      "/office/metacom-invoice"
    ) /** change route & perms */,
  ]),
  new MenuGroup("Systeem", [
    new MenuItem(
      "Gebruikers",
      "accessibility",
      "/accessibility/users"
    ) /** change route & perms */,
    new MenuItem(
      "Rollen",
      "assignment_ind",
      "/accessibility/roles"
    ) /** change route & perms */,
    new MenuItem(
      "Taken",
      "settings_system_daydream",
      "/jobs"
    ) /** change route & perms */,
    new MenuItem("Homepage teksten", "chrome_reader_mode", "/system/greetings"),
    new MenuItem("Mailing", "mail_outline", "/system/mail-schedulers"),
    new MenuItem(
      "Actielijst templates",
      "present_to_all",
      "/system/work-action-templates"
    ),
    new MenuItem(
      "Service Instellingen",
      "room_service",
      "/system/service-settings"
    ),
    new MenuItem(
      "Picklijst Definities",
      "how_to_vote",
      "/system/picklist-definitions"
    ),
    new MenuItem(
      "Inregel. Instellingen",
      "power",
      "/system/installation-report-settings"
    ),

    new MenuItem(
      "Tekenaars standaard omschrijvingen",
      "description",
      "/system/draw-order-descriptions"
    ),

    new MenuItem(
      "Tekenkamerplanning presets",
      "draw",
      "/system/drawer-presets"
    ),

    new MenuItem(
      "Onderhoud Instellingen",
      "engineering",
      "/system/maintenance-presets"
    ),

    new MenuItem(
      "WKB Instellingen",
      "engineering",
      "/system/quality-assurance-presets"
    ),

    new MenuItem(
      "Bestellijst Instellingen",
      "engineering",
      "/system/shop-presets"
    ),
  ]),
  new MenuGroup("App", [
    new MenuItem("Inloggegevens", "vpn_key", "/app/users"),
    new MenuItem(
      "Chatberichten",
      "chat_bubble_outline",
      "/app/conversation-message-presets"
    ),
    new MenuItem("Documentsoorten", "find_in_page", "/app/document-types"),
    new MenuItem("Activiteiten", "local_activity", "/app/activities"),
    new MenuItem("Informatie Pagina's", "pageview", "/app/contents"),
    new MenuItem(
      "Service Categorieën",
      "room_service",
      "/app/service-categories"
    ),
    new MenuItem(
      "Notificaties",
      "notifications_none",
      "/app/notification-templates"
    ),
    new MenuItem("Project Gegevens", "assignment", "/app/project-fields"),
    new MenuItem("Fases", "table_chart", "/app/phases"),
    new MenuItem(
      "Notificatie Rooster",
      "notifications_active",
      "/app/notification-schedules"
    ),

    new MenuItem("Partners", "connect_without_contact", "/app/partners"),
    new MenuItem("Inloggen", "exit_to_app", "/app/sso"),
  ]),
];
