import { EntityManager } from "src/app/entity.service";
import { CrudBloc } from "../../bloc/crud/crud.bloc";
import { CrudEvent } from "../../bloc/crud/crud.event";
import { CrudInitialState } from "../../bloc/crud/crud.state";
import { PresetEntity } from "./preset.entity";

export class PresetBloc extends CrudBloc<PresetEntity> {
  constructor(protected readonly entities: EntityManager) {
    super(new CrudInitialState());
  }

  get orders() {
    return [{ field: "code", direction: "ASC" }];
  }

  get repo() {
    return this.entities.get(PresetEntity);
  }

  async *mapEventToState(event: CrudEvent) {
    yield* super.mapEventToState(event);
  }
}
