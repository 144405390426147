import { ProjectHousePart } from "src/app/cargo/house-part.entity";
import {
  composeCapacity,
  composeLength,
  composeWidth,
} from "./compose-capacity";

export const composeTask = (
  item: ProjectHousePart,
  subTasks?: ProjectHousePart[]
) => ({
  id: item.id,
  partCodeId: item.partCodeId,
  name: item.__housePart__ && item.__housePart__.description,
  wallId: item.wallId,
  projectId: item.projectId,
  projectName: item.__project__.description,
  length: composeLength(item, subTasks),
  width: composeWidth(item, subTasks),
  capacity: composeCapacity(item, subTasks),
  moldId: item.moldId,
  dateId: item.dateId,
  subTasks: subTasks ? subTasks.map((subItem) => composeTask(subItem)) : [],
  comment: (item.project.__tableLayoutComments__ || []).find(
    (_comment) => _comment.taskId === item.wallId
  ),
});
