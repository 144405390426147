import * as _ from "lodash";
import { User } from "../../accessibility-users/user.entity";
import { Entity } from "../../entity.service";
import { Project } from "../../project/project.entity";

export class DateDefinition extends Entity {
  type = "dates";

  dateGroupId: string;

  description: string;

  iconId: string;
  stackId: number;
  colorCode: string;
  dateFormat: string;
  isSynced: boolean;
  orderId: number;
  hash: string;

  phaseSectionMarkId: number;
  notificationTemplateId: number;
  drawOrderTypeId: string;

  visibleOnDatePassed: boolean;
  visibleOnConstructionPlanning: boolean;

  isOptional?: boolean;

  __dateGroup__?: DateGroup;

  createdAt?: Date;
}

export class LogicField extends Entity {
  type = "logic_fields";

  fieldId: string;
  fieldName: string;

  expression: string;
  dateGroupId: string;

  conditionDescription: string;

  iconId: string;
  colorCode: string;
  orderId: number;

  createdAt?: Date;
}

export class LogicFieldValue extends Entity {
  type = "logic_field_values";

  fieldId: string;
  projectId: string;

  value: string;

  __project__?: Project;
}

export class DateValue extends Entity {
  type = "date_values";

  userId: string;
  dateId: string;
  projectId: string;

  value?: Date;
  isSynced: boolean;

  isActivated?: boolean;
  isCompleted?: boolean;

  send_notification?: boolean;

  key?: string;

  syncSource: string;

  __user__: User;
  __date__?: DateDefinition;
  __project__?: Project;
}

export class DateGroup extends Entity {
  type = "date_groups";

  label: string;
  description: string;

  alwaysVisible: boolean;
  hasComments: boolean;
  hasResponsible: boolean;
  visibleOnConstructionPlanning: boolean;

  orderId: number;

  __dates__?: DateDefinition[];
  __logicFields__?: LogicField[];

  isChecked?: boolean;
  isCollapsed?: boolean;
  columns?: string[];

  createdAt?: Date;

  stackStructure: DateGroupStackStructure[];

  get isActiveCheck() {
    return this.isChecked || this.alwaysVisible;
  }
}

export interface DateGroupContainer {
  group: DateGroup;
  stacks: DateGroupStackStructure[];
  responsible: DateGroupResponsible;
  comments: DateGroupComment[];
  activeComment: DateGroupComment;
  logicFields: LogicField[];
}

export interface DateGroupStackStructure {
  id: string;
  title: string;

  dates: DateGroupStackStructureDate[];
  active?: DateGroupStackStructureDate;
  editable?: boolean;
}

export interface DateGroupStackStructureDate {
  key: string;
  value?: DateValue;
  isValid?: boolean;
  date: DateDefinition;
}

export class DateGroupComment extends Entity {
  type = "date_group_comments";

  userId: string;
  projectId: string;
  dateGroupId: string;

  value: string;

  __user__?: User;
  __project__?: Project;
  __dateGroup__?: DateGroup;

  createdAt?: Date;
}

export class DateGroupResponsible extends Entity {
  type = "date_group_responsibles";

  userId: string;
  projectId: string;
  dateGroupId: string;

  __user__?: User;
  __project__?: Project;
  __dateGroup__?: DateGroup;
}

export class ProjectDateGroup {
  constructor(
    public readonly group: DateGroup,
    public readonly stacks: ProjectDateStack[],
    public readonly responsible: DateGroupResponsible,
    public readonly comments: DateGroupComment[],
    public readonly logicFields: LogicField[]
  ) {}

  get columns() {
    return this.group.description.split(",");
  }

  get activeComment() {
    return _.first(_.orderBy(this.comments, (c) => c.createdAt, "desc"));
  }
}

export class ProjectDateStack {
  constructor(
    public readonly dates: ProjectDateCombo[],
    public readonly active: ProjectDateCombo
  ) {}
}

export class ProjectDateCombo {
  constructor(
    public readonly date: DateDefinition,
    public readonly value: DateValue
  ) {}

  get isMtc() {
    return this.date.isSynced;
  }

  get isValid() {
    return this.value ? this.checkValue(this.value.value) : false;
  }

  protected checkValue(val) {
    return !!val && !isNaN(new Date(val).getTime());
  }
}

export interface CachedValue {
  id?: string;
  projectId?: string;
  projectLeader?: User;
  projectMentorId: string;
  projectMentor?: User;
  salesEmployee?: User;
  buyAdvisor?: User;
  dateValues?: DateValue[];
  dateGroupResponsibles?: DateGroupResponsible[];
  dateGroupComments?: DateGroupComment[];
  logicFieldValues?: LogicFieldValue[];
}
