import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  /**
   * Gets a value out the storage for the given type.
   *
   * @template T Type of result.
   * @param {string} key Key of pair.
   * @param {T} defaultValue Default value of item doesn't exist.
   * @returns T
   * @memberof StorageService
   */
  get<T>(key: string, defaultValue: T) {
    const json = localStorage.getItem(key);

    return json ? JSON.parse(json) as T : defaultValue;
  }

  /**
   * Sets a value on the storage.
   *
   * @template T Type of value.
   * @param {string} key Key of pair.
   * @param {T} value Value of pair.
   * @memberof StorageService
   */
  set<T>(key: string, value: T) {
    const json = JSON.stringify(value);

    localStorage.setItem(key, json);
  }

  /**
   * Remove the given value out of the storage.
   *
   * @param {string} key Key to remove.
   * @memberof StorageService
   */
  remove(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Makes a new context for the given type.
   *
   * @template T
   * @param {new (storage: StorageService) => T} context
   * @returns
   * @memberof StorageService
   */
  make<T extends StorageObject<{}>>(context: new (storage: StorageService) => T) {
    return new context(this);
  }
}

export interface IStorageObject<T> {
  key: string;
  value: T;
}

export abstract class StorageObject<T> implements IStorageObject<T> {
  abstract key: string;
  abstract defaultValue: T;

  constructor(protected readonly storage: StorageService) { }

  get value() {
    return this.storage.get(this.key, this.defaultValue);
  }

  set value(item) {
    this.storage.set(this.key, item);
  }

  clear() {
    this.storage.remove(this.key);
  }
}
