import * as React from "react";
import { Bloc } from "./bloc";

export interface BlocBuilderProps {
    bloc: Bloc<{}, {}>;
    builder: (state: {}) => React.ReactNode;
}

export const BlocBuilder: React.FC<BlocBuilderProps> = ({ bloc, builder }) => {
    const [state, setState] = React.useState(null);

    React.useEffect(() => {
        bloc.state.subscribe((newState) => setState(newState));
    }, []);

    return (
        <React.Fragment>
            {state && builder(state)}
        </React.Fragment>
    );
}