import * as React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import { chain, first, orderBy } from "lodash";
import { useMemo } from "react";
import {
  ConstructionPlanningDateGroupDto,
  ConstructionPlanningProjectDto,
} from "../construction-planning-date.dto";

import { ChatBubbleOutline as ChatBubbleOutlineIcon } from "@material-ui/icons";
import { ConstructionPlanningCommentsTooltip } from "./construction-planning-comments.tooltip";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#0099cc",
    color: "white",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid white",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: 4,
    padding: 8,
  },
  arrow: {
    color: "#0099cc",
  },
}))(Tooltip);

export interface ConstructionPlanningCommentsColumnProps {
  dateGroup: ConstructionPlanningDateGroupDto;
  project: ConstructionPlanningProjectDto;
}

export function ConstructionPlanningCommentsColumn({
  project,
  dateGroup,
}: ConstructionPlanningCommentsColumnProps) {
  const comments = useMemo(() => getComments(), [project, dateGroup]);

  function getComments() {
    const dateGroupId = dateGroup ? dateGroup.id : null;

    const comments = (project.cachedValue.dateGroupComments || []).filter(
      (_comment) => _comment.dateGroupId === dateGroupId
    );

    return chain(comments)
      .groupBy((_comment) => _comment.dateGroupId)
      .map((_comments, _key) =>
        first(orderBy(_comments, (_comment) => _comment.createdAt, "desc"))
      )
      .value();
  }

  return (
    <HtmlTooltip
      placement="left"
      arrow
      title={<ConstructionPlanningCommentsTooltip comments={comments} />}
    >
      <ChatBubbleOutlineIcon
        style={{ width: "100%", height: "100%" }}
        color={comments.length > 0 ? "primary" : "disabled"}
      />
    </HtmlTooltip>
  );
}
