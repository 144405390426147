import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { omit } from "lodash";
import * as React from "react";
import { EntitySelect } from "src/app/entity-select/entity-select";
import { Ops } from "src/app/entity.service";
import { RestService } from "src/app/rest.service";
import { TypeEntity } from "src/app/service-settings/react/types/type.entity";
import {
  DeliveryListPointEntity,
  DeliveryListPointImageEntity,
} from "../../delivery-list.entity";
import { PointBloc } from "./point.bloc";
import {
  PointRemovedEvent,
  PointUpdatedEvent,
  PointUpdatedSuccessEvent,
} from "./point.event";
import Dropzone from "react-dropzone";
import { SecureImage } from "src/app/secured-image/secure-image";
import { HttpClient } from "@angular/common/http";
import Lightbox from "react-image-lightbox";
import { Delete, ExpandMore } from "@material-ui/icons";
import { PriorityEntity } from "src/app/service-settings/react/priorities/priority.entity";
import { formatNumber } from "@angular/common";
import { SpaceEntity } from "src/app/service-settings/react/spaces/space.entity";
import { EntitySelectMulti } from "src/app/entity-select/entity-select-multi";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  textField: {
    flex: 1,
  },
  textFieldLong: {
    flex: 3,
  },
  textDivider: {
    width: 16,
  },
  row: {
    padding: 6,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  dropzone: {
    flex: 1,
  },
  dropzoneUpload: {},
  images: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // maxWidth: "50%",
    padding: 0,
    paddingTop: 0,
  },
  imagePreview: {
    flex: 1,
    width: 100,
    height: 100,
    maxWidth: 100,
    // margin: 4,
    objectFit: "cover",
    backgroundColor: "grey",
    border: "1px solid #eeeeee",
    borderRadius: 0,
    "&:hover": {
      cursor: "pointer",
      opactiy: "0.5",
    },
    position: "relative",
  },
  imageDelete: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: "white",
    // width: 32,
    // height: 32,
    // backgroundColor: "red",
    zIndex: 999,
  },
}));

interface PointEditorComponentProps {
  rest: RestService;
  http: HttpClient;
  bloc: PointBloc;
  pointIndex: number;
  point: DeliveryListPointEntity;
  disabled: boolean;
}

export const PointEditorComponent: React.FC<PointEditorComponentProps> = ({
  rest,
  http,
  bloc,
  pointIndex,
  point: basePoint,
  disabled,
}) => {
  (window as any).global = window;

  const classes = useStyles();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);

  const [point, setPoint] = React.useState(basePoint);
  const [isExpanded, setIsExpanded] = React.useState(
    basePoint.__isExpanded || false
  );

  const makeHash = (_point: DeliveryListPointEntity) => {
    return JSON.stringify(omit(_point, ["__isExpanded", "__refId"]));
  };

  const [baseHash, setBaseHash] = React.useState(
    basePoint.id ? makeHash(basePoint) : "{}"
  );

  React.useEffect(() => {
    bloc.eventOf(PointUpdatedSuccessEvent).subscribe((event) => {
      if (event.props.point.__refId === point.__refId) {
        const withFormat = event.props.point;

        setPoint(withFormat);
        setBaseHash(makeHash(withFormat));

        onChangeExpand(false, true);
      }
    });
  }, []);

  const onChangeExpand = (expanded: boolean, force = false) => {
    if (force || !isPendingChanges()) {
      setIsExpanded(expanded);
    } else {
      alert(
        "Er zijn wijzigingen gemaakt aan dit punt, gelieve deze op te slaan of ongedaan maken."
      );
    }

    setIsSaving(false);
  };

  const isPendingChanges = (): boolean => {
    return makeHash(point) !== baseHash;
  };

  const isSaveDisabled = (): boolean => {
    return (
      isSaving ||
      !(
        !!point.code &&
        !!point.description &&
        !!point.primaryCategoryId &&
        !!point.serviceTypeId &&
        (!!point.serviceSpaceId ||
          (point.serviceSpaceIds && point.serviceSpaceIds.length)) &&
        !!point.assignedUserId &&
        !!point.servicePriorityId
      )
    );
  };

  const isNew = (): boolean => {
    return point.id === null || point.id === undefined;
  };

  const undoChanges = (): void => {
    if (isNew()) {
      remove();
    } else {
      (basePoint.images || []).forEach(
        (_image) => (_image.isPendingDelete = false)
      );

      setPoint(basePoint);
      setBaseHash(makeHash(basePoint));

      onChangeExpand(false, true);
    }
  };

  const remove = (): void => {
    bloc.add(new PointRemovedEvent({ point: basePoint }));
  };

  const renderImage = (image: DeliveryListPointImageEntity) => {
    return (
      <div className={classes.imagePreview} style={{ margin: 4 }}>
        <React.Fragment>
          {!!image.documentMetaId ? (
            <SecureImage
              src={`documents/view/${image.documentMetaId}`}
              noCache
              noDebounce
              http={http}
              className={classes.imagePreview}
              placeholder={
                <div style={{ padding: 6, color: "white" }}>Laden..</div>
              }
            />
          ) : (
            <img
              src={image.__staging.preview}
              className={classes.imagePreview}
            />
          )}
          <div className={classes.imageDelete}>
            <IconButton
              disabled={disabled}
              size="small"
              color="secondary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                image.isPendingDelete = true;

                setPoint({
                  ...point,
                  images: [...point.images],
                });
              }}
            >
              <Delete fontSize="inherit" color="action" />
            </IconButton>
          </div>
        </React.Fragment>
      </div>
    );
  };

  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(0);
  const [lightboxImages, setLightboxImages] = React.useState([]);

  const selectImage = async (index: number) => {
    const images = await Promise.all(
      point.images.map((image) => loadImage(image))
    );

    setLightboxImages(images);
    setLightboxIndex(index);

    setLightboxOpen(true);
  };

  const loadImage = async (image: DeliveryListPointImageEntity) => {
    if (!!image.documentMetaId) {
      const response = await http
        .get(`documents/view/${image.documentMetaId}`, { responseType: "blob" })
        .toPromise();

      return response ? URL.createObjectURL(response) : "";
    } else {
      return image.__staging.preview;
    }
  };

  return (
    <React.Fragment>
      <Accordion
        expanded={isExpanded}
        onChange={(_, expanded) => onChangeExpand(expanded)}
        className={classes.root}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>
            <strong>{formatNumber(pointIndex + 1, "nl", "3.0-0")}</strong>{" "}
            &mdash; {basePoint.description}{" "}
          </Typography>

          {point.serviceTicketId && (
            <Typography style={{ color: "grey" }}>
              {point.serviceTicketId}
            </Typography>
          )}
        </AccordionSummary>
        {isExpanded && (
          <AccordionDetails style={{ flexDirection: "column" }}>
            <div className={classes.row}>
              <TextField
                disabled={disabled}
                className={classes.textFieldLong}
                variant="outlined"
                label="Omschrijving*"
                value={point.description}
                onChange={(event) =>
                  setPoint({ ...point, description: event.target.value })
                }
              />
            </div>

            <div className={classes.row}>
              <EntitySelect
                disabled={disabled}
                className={classes.textField}
                title="Type*"
                type="service_types"
                labelSelector={(type: TypeEntity) =>
                  `${type.code} - ${type.description}`
                }
                searchFields={["description"]}
                entityId={point.serviceTypeId}
                restService={rest}
                onSelect={(type) =>
                  setPoint({ ...point, serviceTypeId: type ? type.id : null })
                }
              />

              <div className={classes.textDivider}></div>

              <EntitySelectMulti
                disabled={disabled}
                className={classes.textField}
                title="Ruimtes*"
                type="service_spaces"
                labelSelector={(type: SpaceEntity) =>
                  `${type.code} - ${type.description}`
                }
                searchFields={["code", "description"]}
                orders={[{ field: "code", direction: "ASC" }]}
                entityIds={
                  !!point.serviceSpaceId
                    ? [point.serviceSpaceId]
                    : point.serviceSpaceIds || []
                }
                restService={rest}
                onSelect={(spaces) => {
                  setPoint((prev) => ({
                    ...prev,
                    serviceSpaceId: null,
                    serviceSpaceIds: spaces
                      ? spaces.map((space) => space.id)
                      : [],
                  }));
                }}
              />
            </div>

            <div className={classes.row}>
              <EntitySelect
                disabled={disabled}
                className={classes.textField}
                title="Uitvoerende*"
                type="users"
                labelSelector="name"
                searchFields={["name"]}
                entityId={point.assignedUserId}
                restService={rest}
                filters={[Ops.Field("roleId").IsNull(true)]}
                onSelect={(user) =>
                  setPoint({
                    ...point,
                    assignedUserId: user ? user.id : null,
                  })
                }
              />

              <div className={classes.textDivider}></div>

              <EntitySelect
                disabled={disabled}
                className={classes.textField}
                title="Prioriteit*"
                type="service_priorities"
                labelSelector={(type: PriorityEntity) => `${type.description}`}
                searchFields={["description"]}
                entityId={point.servicePriorityId}
                restService={rest}
                onSelect={(priority) =>
                  setPoint({
                    ...point,
                    servicePriorityId: priority ? priority.id : null,
                  })
                }
              />
            </div>
            <div className={classes.row}>
              <div className={classes.images}>
                {(point.images || [])
                  .filter((image) => !image.isPendingDelete)
                  .map((image, index) => (
                    <React.Fragment key={`image-${index}`}>
                      <div onClick={() => selectImage(index)}>
                        {renderImage(image)}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </AccordionDetails>
        )}

        <AccordionActions>
          <Dropzone
            disabled={disabled}
            accept="image/*"
            multiple
            onDrop={(acceptedFiles) => {
              setPoint({
                ...point,
                images: (point.images || []).concat(
                  acceptedFiles.map((file) => ({
                    __staging: {
                      file,
                      preview: URL.createObjectURL(file),
                    },
                  }))
                ),
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={classes.dropzoneUpload}>
                    <Button
                      disabled={disabled}
                      style={{ width: "100%" }}
                      size="small"
                      variant="outlined"
                    >
                      Afbeelding toevoegen
                    </Button>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>

          <Button disabled={disabled} size="small" onClick={() => remove()}>
            Verwijder
          </Button>
          <Button
            size="small"
            disabled={disabled || !isPendingChanges()}
            onClick={() => undoChanges()}
          >
            Ongedaan maken
          </Button>
          <Button
            size="small"
            color="primary"
            disabled={disabled || isSaveDisabled()}
            onClick={() => {
              setIsSaving(true);
              bloc.add(new PointUpdatedEvent({ point }));
            }}
          >
            Opslaan{" "}
            {isSaving && (
              <CircularProgress size="1.5rem" style={{ marginLeft: 8 }} />
            )}
          </Button>
        </AccordionActions>
      </Accordion>

      {lightboxOpen && (
        <Lightbox
          mainSrc={lightboxImages[lightboxIndex]}
          nextSrc={lightboxImages[(lightboxIndex + 1) % lightboxImages.length]}
          prevSrc={
            lightboxImages[
              (lightboxIndex + lightboxImages.length - 1) %
                lightboxImages.length
            ]
          }
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + lightboxImages.length - 1) %
                lightboxImages.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % lightboxImages.length)
          }
        />
      )}
    </React.Fragment>
  );
};
