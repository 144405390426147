import gql from 'graphql-tag';

export const customerQuestionsOverviewQuery = gql`
  query customerQuestionsOverviewQuery($query: EntityQuery!) {
    categories: AppServiceCategoryEntityFindMany {
      id
      name
    }
    questions: CustomerQuestionEntityFindMany(query: $query) {
      id
      kind
      projectId
      customerName
      city
      emailAddress
      telephone
      space
      imageIds
      message
      internalComment
      createdAt
      updatedAt
      completedAt
    }
  }
`;
