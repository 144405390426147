import { Component, OnInit } from "@angular/core";
import { EntityManager } from "../entity.service";
import { ActivatedRoute } from "@angular/router";
import { RestResponse } from "../rest.service";
import { AppContent } from "../app-contents/app-content.entity";

@Component({
  selector: "app-app-content-preview",
  templateUrl: "./app-content-preview.component.html",
  styleUrls: ["./app-content-preview.component.scss"],
})
export class AppContentPreviewComponent implements OnInit {
  response: RestResponse<AppContent>;

  protected get service() {
    return this.entityManager.get(AppContent);
  }

  protected get id() {
    return this.route.snapshot.paramMap.get("id");
  }

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly entityManager: EntityManager
  ) {}

  async ngOnInit() {
    this.response = await this.service.findOne(this.id);
  }
}
