import * as moment from "moment";
import { DateWrapper } from "../date-stack-dialog.component";

export function setToday(item: DateWrapper, ifEmpty = false) {
  if (!ifEmpty || !item.compatibleModel) {
    item.compatibleModel = moment().format("YYYY-MM-DD");
    item.updateModel();
  }
}

export function isValueSet(value?: boolean) {
  return !(value == null || value === undefined);
}

export function valueOrToggle(current: boolean, value?: boolean) {
  return isValueSet(value) ? value : !current;
}
