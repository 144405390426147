import { AppUser } from "../app-users/app-user.entity";
import { Entity } from "../entity.service";
import { RestResponse } from "../rest.service";

export class AppProject extends Entity {
  type = "app.projects";

  contract_sum: string;

  users: AppUser[];

  set __users__(value: AppUser[]) {
    this.users = this.entityManager
      .get(AppUser)
      .healManyResponse(new RestResponse(value)).value;
  }
}
