export interface PicklistElementStateDto {
  id?: string;
  pickedByUserId: string;
  elementId: string;
  state: PicklistElementStateMode;
}

export enum PicklistElementStateMode {
  Idle = "idle",
  Indeterminate = "indeterminate",
  Picked = "picked",
}

export enum PicklistElementStateModeFilter {
  All = "all",
  IdleOrIndeterminate = "idle,indeterminate",
  Picked = "picked",
}
