import { Project } from "./../project/project.entity";
import { orderBy, uniqBy } from "lodash";
import { PlanningProjectItem } from "./planning-project-item.entity";
import { EntityManager } from "./../entity.service";
import { MetacomService } from "./../metacom.service";
import { Injector } from "@angular/core";
import { PicklistDefinition } from "../picklist/picklist.entity";
import * as moment from "moment";

const MAX_LINES_PER_VIEW = 20;
const METACOM_DATE_FORMAT = "YYYY-MM-DD";
const METACOM_DATE_FILTER_FORMAT = "DD-MM-YYYY";

export interface PicklistOverviewLine {
  readonly definitionId: string;
  readonly documentId?: string;

  readonly project: Project;
  readonly projectIdLabel?: string;

  readonly date: Date;
  readonly memo?: string;
}

export enum PicklistOverviewLineFetchMode {
  Planning = "planning",
  Metacom = "metacom",
}

interface PicklistOverviewLinesProps {
  readonly injector: Injector;
  readonly definition: PicklistDefinition;

  readonly mode: PicklistOverviewLineFetchMode;
  readonly dateFrom: Date;
}

export const getPicklistOverviewLines = async (
  props: PicklistOverviewLinesProps
): Promise<PicklistOverviewLine[]> => {
  const { mode, definition, dateFrom } = props;

  switch (mode) {
    case PicklistOverviewLineFetchMode.Metacom: {
      const records = await getPicklistMetacomRecords({
        ...props,
        filter: `reg_doc.datum > "${moment(dateFrom).format(
          METACOM_DATE_FILTER_FORMAT
        )}"`,
      });

      const projectIds = uniqBy(records, (record) => record.prj).map(
        (record) => record.prj
      );

      const projects = await getProjects({
        ...props,
        projectIds,
      });

      return sortByDate(
        records.map((record) => ({
          definitionId: definition.id,
          documentId: record.docnr,
          project:
            projects.find((project) => project.id === record.prj) ||
            ({
              id: record.prj,
              description: `Project: ${record.project}`,
              __picklistStates__: [],
            } as Project),
          projectIdLabel: record.project,
          date: moment(record.datum, METACOM_DATE_FORMAT, true).toDate(),
          memo: record.koptekst,
        }))
      );
    }
    case PicklistOverviewLineFetchMode.Planning: {
      const records = await getPicklistPlanningRecords(props);
      const filters = uniqBy(records, (record) => record.projectId).map(
        (record) => `reg_mutatie.naar_kpl = '${record.projectId}'`
      );
      const memoRecords = await getPicklistMetacomRecords({
        ...props,
        filter: `(${filters.join(" OR ")})`,
      });

      return sortByDate(
        records.map((record) => ({
          definitionId: definition.id,
          project: record.__project__,
          date: record.competenceStartsAt,
          memo: (
            memoRecords.find((memo) => memo.prj === record.projectId) || {
              koptekst: "",
            }
          ).koptekst,
        }))
      );
    }
  }
};

export const sortByDate = (lines: PicklistOverviewLine[]) => {
  return orderBy(lines, (line) => moment(line.date).unix(), "asc");
};

const getPicklistPlanningRecords = async ({
  injector,
  dateFrom,
  definition,
}: PicklistOverviewLinesProps) => {
  const entities = injector.get(EntityManager);
  const repo = entities.get(PlanningProjectItem);
  const response = await repo.query({
    filters: [
      { field: "projectId", operator: "Equal", value: "null", isNot: true },
      { field: "dimension", operator: "Equal", value: "competence" },
      {
        field: "competenceStartsAt",
        operator: "MoreThan",
        value: dateFrom,
      },
      {
        field: "competenceNumber",
        operator: "Equal",
        value: definition.competenceNumber,
      },
      { field: "competenceIndex", operator: "Equal", value: "0" },
    ],
    orders: [{ field: "competenceStartsAt", direction: "ASC" }],
    relations: ["project", "project.picklistStates"],
    take: MAX_LINES_PER_VIEW,
  });

  return response.hasError() ? [] : response.value;
};

const getPicklistMetacomRecords = async ({
  injector,
  definition,
  filter,
}: PicklistOverviewLinesProps & {
  readonly filter: string;
}) => {
  const metacom = injector.get(MetacomService);
  const response = await metacom.queryTableAsync<RawMetacomPicklistRecord>({
    setName: "metacom",
    tableName: definition.metacomListTable,
    filter,
  });

  return response.hasError() ? [] : response.value;
};

interface RawMetacomPicklistRecord {
  readonly datum: string;
  readonly prj: string;
  readonly docnr: string;
  readonly project: string;
  readonly koptekst: string;
}

const getProjects = async ({
  injector,
  projectIds,
}: PicklistOverviewLinesProps & {
  readonly projectIds: string[];
}) => {
  const entities = injector.get(EntityManager);
  const repo = entities.get(Project);
  const response = await repo.query({
    filters: [{ field: "id", operator: "In", valueComplex: projectIds }],
    relations: ["picklistStates"],
  });

  return response.hasError() ? [] : response.value;
};
