import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EntityManager } from '../entity.service';
import { BlockedCalendarWeek } from './blocked-calendar-week.entity';
import { RestResponse } from '../rest.service';
import { MdcDialogRef } from '@angular-mdc/web';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-blocked-calendar-weeks-dialog',
  templateUrl: './blocked-calendar-weeks-dialog.component.html',
  styleUrls: ['./blocked-calendar-weeks-dialog.component.scss']
})
export class BlockedCalendarWeeksDialogComponent implements OnInit, AfterViewInit {
  static WEEK_FORMAT = 'GGGG-WW';

  isDirty: boolean;

  weeks: Week[] = [];
  blockedWeeksResponse: RestResponse<BlockedCalendarWeek[]>;

  protected get blockedCalendarWeekService() {
    return this.entityManager.get(BlockedCalendarWeek);
  }

  constructor(
    protected readonly entityManager: EntityManager,
    protected readonly dialog: MdcDialogRef<BlockedCalendarWeeksDialogComponent>) { }

  ngOnInit() {
    this.fetch().then(() => this.scroll(20));
  }

  ngAfterViewInit() {
    setTimeout(() => window.dispatchEvent(
      new Event('resize')), 300);
  }

  close() {
    this.dialog.close(false);
  }

  setDirty(week: Week) {
    this.isDirty = true;
    week.isDirty = true;
  }

  async save() {
    if (this.isDirty) {
      const potentials = this.weeks.filter(e => e.isDirty);

      for (const week of potentials) {
        if (week.isBlocked && !week.isSaved) {
          await this.blockedCalendarWeekService.save(
            this.blockedCalendarWeekService.concept({ id: week.id }));
        } else if (!week.isBlocked && week.isSaved) {
          await this.blockedCalendarWeekService.delete(week.id);
        }
      }
    }

    this.dialog.close(true);
  }

  scroll(size: number = 10) {
    const origin = _.last(this.weeks) || {
      id: moment().format(BlockedCalendarWeeksDialogComponent.WEEK_FORMAT), isBlocked: false
    };
    const increment = Array.from(this.makeWeeks(origin.id, size));
    this.weeks.push(...increment);
  }

  protected async fetch() {
    this.blockedWeeksResponse = await this.blockedCalendarWeekService.query();
  }

  protected isBlocked(id: string) {
    return !!this.blockedWeeksResponse.value.find(v => v.id === id) || false;
  }

  protected makeWeek(id: string) {
    const isBlocked = this.isBlocked(id);
    return ({ id, isBlocked, isSaved: isBlocked });
  }

  protected * makeWeeks(fromWeekNr: string, size: number = 10) {
    const origin = moment(fromWeekNr, BlockedCalendarWeeksDialogComponent.WEEK_FORMAT);

    for (let i = 1; i <= size; i++) {
      yield this.makeWeek(origin.clone().add(i, 'weeks').endOf('week')
        .format(BlockedCalendarWeeksDialogComponent.WEEK_FORMAT)
      );
    }
  }
}

interface Week {
  id: string;
  isSaved: boolean;
  isBlocked: boolean;
  isDirty?: boolean;
}
