import { Component, OnInit, Inject } from "@angular/core";
import { AppService } from "../app.service";
import { AppRestService } from "../app-rest.service";
import { first } from "lodash";
import { FileUploadService } from "../file-upload.service";
import { MDC_DIALOG_DATA, MdcDialogRef } from "@angular-mdc/web";
import { DialogService } from "../dialog.service";
import { RestResponse } from "../rest.service";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";

@Component({
  selector: "app-app-document-upload-dialog",
  templateUrl: "./app-document-upload-dialog.component.html",
  styleUrls: ["./app-document-upload-dialog.component.scss"],
})
export class AppDocumentUploadDialogComponent {
  files: AppFile[] = [];
  documentTypeId: string = null;

  get needsDocumentType() {
    return !!this.files.find((f) => f.kind === "document");
  }

  get canUpload() {
    return (
      (this.needsDocumentType ? !!this.documentTypeId : true) &&
      this.files.length > 0
    );
  }

  documentTypeFilter = {
    allowNothing: false,
    title: "Documentsoort",
    icon: "pageview",
    entityName: "app.document_types",
    nameField: "description",
    descriptionField: "name",
    sortField: "name",
    sortDirection: "ASC",
    filterFields: ["name", "description"],
  };

  constructor(
    @Inject(MDC_DIALOG_DATA)
    protected readonly args: AppDocumentUploadDialogComponentArgs,
    protected readonly appService: AppService,
    protected readonly appRestService: AppRestService,
    protected readonly fileUploads: FileUploadService,
    protected readonly dialogService: DialogService,
    protected readonly dialogRef: MdcDialogRef<AppDocumentUploadDialogComponent>
  ) {}

  toggleConfidential(item: AppFile) {
    item.confidential = !item.confidential;
  }

  async addFiles(files: NgxFileDropEntry[]) {
    const items = await Promise.all(
      (files || [])
        .filter((e) => e.fileEntry.isFile)
        .map((e) => e.fileEntry as FileSystemFileEntry)
        .map((e) => this.destructFile(e))
    );

    this.files.push(...items.map((e) => new AppFile(e)));
  }

  remove(item: AppFile) {
    this.files.splice(this.files.indexOf(item), 1);
  }

  protected destructFile(entry: FileSystemFileEntry) {
    return new Promise<File>((resolve) => entry.file((f) => resolve(f)));
  }

  protected async promptForDocuments() {
    return new Promise<FileList>((resolve) => {
      const element = document.createElement("input");
      element.setAttribute("type", "file");
      element.setAttribute("multiple", "true");
      element.style.display = "none";
      document.body.appendChild(element);
      element.onchange = () => resolve(element.files);
      element.click();
    });
  }

  async upload() {
    const filesToUpload = this.files.filter((f) => !f.isOk);

    for (const file of filesToUpload) {
      await this.uploadFile(file);
    }

    if (this.files.every((f) => f.isOk)) {
      this.dialogRef.close("save");
    }
  }

  protected async uploadFile(context: AppFile) {
    return (context.response = await this.appRestService.request({
      url: "documents",
      method: "POST",
      body: {
        description: context.description,
        document_kind: context.kind,
        document_type_id:
          context.kind === "document" ? this.documentTypeId : null,
        confidential: context.confidential,
      },
      headers: {
        "X-Project": this.args.projectId,
      },
      file: context.file,
    }));
  }
}

class AppFile {
  description: string;
  response: RestResponse<string>;

  confidential: boolean;

  constructor(readonly file: File) {
    this.description = this.fileName;
  }

  get fileName() {
    return this.file.name.replace(`.${this.extension}`, "");
  }

  get extension() {
    return first(/[^.]+$/.exec(this.file.name));
  }

  get kind() {
    return this.extension.toLowerCase() === "pdf" ? "document" : "photo";
  }

  get isOk() {
    return this.response && !this.response.hasError();
  }
}

export class AppDocumentUploadDialogComponentArgs {
  constructor(readonly projectId: string) {}
}
