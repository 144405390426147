import { sumBy } from "lodash";
import { ProjectHousePart } from "src/app/cargo/house-part.entity";

export const composeCapacity = (
  item: ProjectHousePart,
  subTasks?: ProjectHousePart[]
) =>
  subTasks
    ? sumBy(subTasks, (t) => composeCapacity(t))
    : parseFloat(`${item.quantity}`) *
      ((item.__housePart__ ? item.__housePart__.capacityFactor : null) || 0);

export const composeLength = (
  item: ProjectHousePart,
  subTasks?: ProjectHousePart[]
) =>
  subTasks ? sumBy(subTasks, (t) => composeLength(t)) : +(item.length ?? 0);

export const composeWidth = (
  item: ProjectHousePart,
  subTasks?: ProjectHousePart[]
) => (subTasks ? sumBy(subTasks, (t) => composeWidth(t)) : +(item.width ?? 0));
