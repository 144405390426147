import * as moment from "moment";

export class Invoice {
  bedrijf: string;
  factuur_nummer: string;
  factuur_datum: string;
  factuur_bedrag: number;
  factuur_opmerking: string;
  omschrijving: string;
  relatie: string;
  relatie_naam: string;
  fiatteur: string;
  herkomst_factuur: string;
  herkomst_rubriek: string;
  document: string;
  jaar: string;
  dagboek: string;

  /** New Fields */
  doknr: string;

  constructor(protected readonly data: any) {
    Object.assign(this, data);

    this.factuur_datum = moment(this.factuur_datum, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
  }
}
