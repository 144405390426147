import {
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import {
  MailTrafficItemMessageAttachmentDto,
  MailTrafficItemMessageDto,
  MailTrafficItemMessageRecipientDto,
} from "../dto/mail-traffic-item-message.dto";
import {
  Attachment as AttachmentIcon,
  Launch as LaunchIcon,
} from "@material-ui/icons";
import { useMemo } from "react";
import { first } from "lodash";
import { RestService } from "src/app/rest.service";
import {
  useOpenMailAttachment,
  useStoreMailToInbox,
} from "../mail-traffic.hooks";
import { AuthService } from "src/app/auth.service";
import { CellaService } from "src/app/cella.service";

const useStyles = makeStyles(() => ({
  list: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    borderBottom: "1px solid #eeeeee",
  },
  recipients: {
    display: "block",
  },
  attachments: {
    padding: 16,
    paddingTop: 0,
    paddingBottom: 16,
  },
}));

export interface MailTrafficMessageHeaderProps {
  message: MailTrafficItemMessageDto;
  restService: RestService;
  authService: AuthService;
  cellaService: CellaService;
}

export function MailTrafficMessageHeader({
  message,
  restService,
  authService,
  cellaService,
}: MailTrafficMessageHeaderProps) {
  const classes = useStyles();
  const from = useMemo(() => first(message.from), [message]);
  const {
    loading: loadingAttachment,
    downloadIdentifier,
    openMailAttachment,
  } = useOpenMailAttachment(restService);

  const { loading: loadingStoreInInbox, storeMailToInbox } =
    useStoreMailToInbox(authService, cellaService);

  const renderAddressList = (
    name: string,
    recipients: MailTrafficItemMessageRecipientDto[]
  ) => {
    return recipients.length > 0 ? (
      <span className={classes.recipients}>
        <strong>{name}:</strong>{" "}
        {recipients
          .map((recipient) => recipient.displayName || recipient.emailAddress)
          .join(", ")}
      </span>
    ) : (
      <></>
    );
  };

  return !!message ? (
    <List className={classes.list}>
      <ListItem>
        <ListItemText
          primary={
            from ? `${from.displayName} (${from.emailAddress})` : "Onbekend"
          }
          secondary={message.subject}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          secondary={
            <>
              {renderAddressList("Geadresseerde", message.to)}
              {renderAddressList("Cc", message.cc)}
              {renderAddressList("Bcc", message.bcc)}
            </>
          }
        />
        <ListItemSecondaryAction>
          {loadingStoreInInbox && <CircularProgress size={24} />}
          {!loadingStoreInInbox && (
            <IconButton onClick={() => storeMailToInbox(message)}>
              <LaunchIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Grid className={classes.attachments} container spacing={1}>
        {message.attachments
          .filter((attachment) => !attachment.contentID)
          .map((attachment) => (
            <Grid key={attachment.downloadIdentifier} item>
              <Chip
                onClick={() =>
                  openMailAttachment(
                    message.groupId,
                    message.mailId,
                    attachment
                  )
                }
                icon={
                  downloadIdentifier === attachment.downloadIdentifier ? (
                    <CircularProgress size={16} />
                  ) : (
                    <AttachmentIcon />
                  )
                }
                size="small"
                label={attachment.fileName}
              />
            </Grid>
          ))}
      </Grid>
    </List>
  ) : (
    <></>
  );
}
