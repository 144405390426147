import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { MailTrafficItemMessageDto } from "../dto/mail-traffic-item-message.dto";
import { RestService } from "src/app/rest.service";
import { useEffect } from "react";
import { useReadMailImage } from "../mail-traffic.hooks";

const useStyles = makeStyles(() => ({
  root: { padding: 24 },
}));

export interface MailTrafficMessageBodyProps {
  message: MailTrafficItemMessageDto;
  fuzzyText?: string;
  restService: RestService;
}

export function MailTrafficMessageBody({
  message,
  fuzzyText,
  restService,
}: MailTrafficMessageBodyProps) {
  const classes = useStyles();

  const { readMailImage } = useReadMailImage(restService);

  const setImageAttachment = async (element: Element) => {
    const contentId = element.getAttribute("src").replace("cid:", "");
    const match = message.attachments.find(
      (attachment) => attachment.contentID === contentId
    );

    if (match) {
      const newSrc = await readMailImage(
        message.groupId,
        message.mailId,
        match
      );

      element.setAttribute("src", newSrc);
    }
  };

  useEffect(() => {
    const images = document.querySelectorAll('img[src*="cid:"]');
    images.forEach((image) => setImageAttachment(image));
  }, [message]);

  const highlight = (input: string) => {
    return fuzzyText
      ? input.replace(
          new RegExp(fuzzyText, "gmi"),
          `<span style="background-color: yellow;">${fuzzyText}</span>`
        )
      : input;
  };

  return (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={{
        __html: highlight(message.htmlBody || message.plainTextBody),
      }}
    />
  );
}
