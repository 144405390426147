import * as React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { EntityAdvancementExtensionProps } from "../entity-advancement-dialog/entity-advancement-dialog.component";
import { EntitySelect } from "../entity-select/entity-select";
import { EntityManager, Ops } from "../entity.service";
import { RestService } from "../rest.service";
import { DateDefinition } from "../year-planning/entities/date-entities";
import { WorkAction } from "./work-action.entity";
import { InfoOutlined } from "@material-ui/icons";

export interface WorkActionAdvancementExtensionProps
  extends EntityAdvancementExtensionProps {
  action: WorkAction;
  restService: RestService;
  entities: EntityManager;
  onSelected: (date: DateDefinition) => void | Promise<void>;
}

const useStyles = makeStyles(() => ({
  paragraph: {
    fontSize: 12,
  },
  paper: {
    marginTop: 8,
    padding: 6,
    border: "1px solid #eeeeee",
  },
}));

export const WorkActionAdvancementExtension: React.FC<WorkActionAdvancementExtensionProps> = (
  props
) => {
  const classes = useStyles();
  const [date, setDate] = React.useState<DateDefinition>();

  const renderApplyColumn = () => {
    return (
      date && (
        <Paper elevation={0} className={classes.paper}>
          <List>
            <ListItem dense>
              <ListItemIcon>
                <InfoOutlined color="action" />
              </ListItemIcon>
              <ListItemText
                primary={`(Jaarplanning) voortgang wordt doorgezet naar: (${date.__dateGroup__.description}) ${date.description}`}
              />
            </ListItem>
          </List>
        </Paper>
      )
    );
  };

  const renderApplyColumnOption = () => {
    return (
      <EntitySelect
        style={{ width: "100%", marginTop: 16 }}
        title="(Jaarplanning) voortgang doorzetten voor kolom-datum:"
        type="dates"
        relations={["dateGroup"]}
        labelSelector={(date: DateDefinition) =>
          `(${date.__dateGroup__.description}) ${date.description}`
        }
        searchFields={["description"]}
        entityId={props.action.advancementApplyDateId}
        restService={props.restService}
        onSelect={(date: DateDefinition) => props.onSelected(date)}
      />
    );
  };

  const fetchDate = async () => {
    const repo = props.entities.get(DateDefinition);
    const response = await repo.queryFirst({
      filters: [Ops.Field("id").Equals(props.action.advancementApplyDateId)],
      relations: ["dateGroup"],
    });

    if (!response.hasError()) {
      setDate(response.value);
    }
  };

  React.useEffect(() => {
    if (props.action && props.action.advancementApplyDateId) {
      fetchDate();
    }
  }, [props.action]);

  return props.composeCompleted ? (
    <React.Fragment>
      {props.action.advancementApplyDateId && renderApplyColumn()}
      {!props.action.advancementApplyDateId && renderApplyColumnOption()}
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
};
