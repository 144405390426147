import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AppCrudBoilerplate } from "../boilerplates/app-crud.boilerplate";
import { AppNotificationSchedule } from "./app-notification-schedule.entity";
import { TransactionService } from "../transaction.service";
import { EntityManager } from "../entity.service";
import { DialogService } from "../dialog.service";
import { first, chain } from "lodash";
import { AppNotificationScheduleDialogComponent } from "../app-notification-schedule-dialog/app-notification-schedule-dialog.component";

@Component({
  selector: "app-app-notification-schedules",
  templateUrl: "./app-notification-schedules.component.html",
  styleUrls: ["./app-notification-schedules.component.scss"],
})
export class AppNotificationSchedulesComponent extends AppCrudBoilerplate<
  AppNotificationSchedule
> {
  entity = AppNotificationSchedule;
  editDialog = AppNotificationScheduleDialogComponent;

  get relations() {
    return ["notification", "mark", "mark.section", "mark.section.phase"];
  }

  phases: AppNotificationSchedulePhase[];

  constructor(
    readonly transactions: TransactionService,
    readonly entityManager: EntityManager,
    protected readonly dialogs: DialogService,
    protected readonly changeDetector: ChangeDetectorRef
  ) {
    super(transactions, entityManager, dialogs, changeDetector);
  }

  async fetch() {
    await super.fetch();

    this.phases = chain(this.response.value)
      .groupBy((schedule) => this.phaseId(schedule))
      .map((items, key) => new AppNotificationSchedulePhase(items))
      .orderBy((phase) => phase.orderId)
      .value();
  }

  protected phaseId(schedule: AppNotificationSchedule) {
    return schedule.mark
      ? schedule.mark.section
        ? schedule.mark.section.phase_id
        : null
      : null;
  }
}

class AppNotificationSchedulePhase {
  constructor(readonly items: AppNotificationSchedule[]) {}

  get phase() {
    const item = first(this.items);

    return item.mark ? (item.mark.section ? item.mark.section : null) : null;
  }

  get orderId() {
    const phase = this.phase;

    return phase ? phase.order : Number.MAX_VALUE;
  }
}
