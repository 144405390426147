import { Entity } from "../entity.service";

export class AppProjectField extends Entity {
  type = "app.project_fields";

  value: any;

  created_at?: Date;
  updated_at?: Date;
}
