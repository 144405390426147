import { PicklistDefinitionDto } from "./picklist-definition.dto";
import * as React from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { AddCircle as AddCircleIcon } from "@material-ui/icons";
import { orderBy } from "lodash";

export interface PicklistDefinitionEditorProps {
  model: PicklistDefinitionDto;

  onSave: (update: PicklistDefinitionDto) => void;
  onDelete: () => void;
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
  },
}));

export function PicklistDefinitionEditor(props: PicklistDefinitionEditorProps) {
  function getInitialState() {
    return {
      ...props.model,
      elementTypes: props.model.elementTypes || [],
      hideColumns: props.model.hideColumns || [],
    };
  }

  const fields = ["aantal", "afmeting"];
  const [update, setUpdate] = useState(getInitialState());
  const [expanded, setExpanded] = useState(!props.model.id);
  const [composeElementType, setComposeElementType] = useState("");

  const handleExpand = () => (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  function cancelEditing() {
    setUpdate(getInitialState());
    setExpanded(false);
  }

  function _updateAllowedToSave() {
    return !!update.id && !!update.name && !!update.competenceNumber;
  }

  return (
    <Accordion expanded={expanded} onChange={handleExpand()}>
      <AccordionSummary>{props.model.name}</AccordionSummary>
      <Divider style={{ marginBottom: 16 }} />
      <AccordionDetails>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              disabled={!!props.model.id}
              label="Sleutel"
              value={update.id}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  id: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Visibox Nummer"
              value={update.competenceNumber}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  competenceNumber: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="date-source-label">
                Datums baseren op:
              </InputLabel>
              <Select
                style={{ width: "100%" }}
                variant="outlined"
                label="Datums baseren op:"
                inputProps={{
                  id: "date-source-label",
                }}
                value={update.dateSource}
                onChange={(event) =>
                  setUpdate({
                    ...update,
                    dateSource: event.target.value as string,
                  })
                }
              >
                <MenuItem value="planning">Planning.nl</MenuItem>
                <MenuItem value="metacom">Metacom</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Omschrijving"
              value={update.name}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  name: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Metacom lijst tabel"
              value={update.metacomListTable}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  metacomListTable: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Metacom regel tabel"
              value={update.metacomLinesTable}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  metacomLinesTable: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            {orderBy(update.elementTypes, (type) => type, "asc").map(
              (_type) => (
                <Chip
                  style={{ marginRight: 4 }}
                  key={`element-type-${_type}`}
                  size={"small"}
                  label={_type}
                  onDelete={() =>
                    setUpdate({
                      ...update,
                      elementTypes: update.elementTypes.filter(
                        (_sibling) => _sibling !== _type
                      ),
                    })
                  }
                />
              )
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="F-nummer"
              value={composeElementType}
              onChange={(event) => setComposeElementType(event.target.value)}
            />

            <Button
              style={{ margin: "auto", height: 56 }}
              size={"large"}
              disabled={composeElementType.length === 0}
              onClick={() => {
                setUpdate({
                  ...update,
                  elementTypes: [...update.elementTypes, composeElementType],
                });
                setComposeElementType("");
              }}
            >
              <AddCircleIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>Kolommen verbergen</InputLabel>
              <Select
                multiple
                value={update.hideColumns}
                onChange={(event) =>
                  setUpdate({
                    ...update,
                    hideColumns: event.target.value as string[],
                  })
                }
                input={<Input />}
              >
                {fields.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={update.indeterminateToggles}
                  onChange={(event) =>
                    setUpdate({
                      ...update,
                      indeterminateToggles: event.target.checked,
                    })
                  }
                  name="isStandard"
                  color="primary"
                />
              }
              label="Dubbel afvinken"
            />
          </Grid>{" "}
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              variant="outlined"
              label="Filternaam"
              placeholder="Materiaal"
              value={update.renameFilterName}
              onChange={(event) =>
                setUpdate({
                  ...update,
                  renameFilterName: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" onClick={() => cancelEditing()}>
          Ongedaan maken
        </Button>
        <Button
          size="small"
          color={"secondary"}
          onClick={() => props.onDelete()}
        >
          Verwijder
        </Button>
        <Button
          size="small"
          color="primary"
          disabled={!_updateAllowedToSave()}
          onClick={() => {
            props.onSave(update);
            setExpanded(false);
          }}
        >
          Opslaan
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
